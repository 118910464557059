import { Button, FormControlLabel, RadioGroup } from "@material-ui/core";
import { FastField, Field, FieldArray, Form, Formik, FormikErrors, FormikHelpers } from "formik";
import { isValid } from "iban";
import { useRef } from "react";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { IEmployee } from "../../types/models";
import {
    getMinMaxWorkingHours,
    isValidBic,
    toOptions,
    workingHoursIntervalToString,
    YupLocalizedNumber,
} from "../../util/helpers";
import { useReligions } from "../hooks/useReligions";
import { useTitles } from "../hooks/useTitles";
import { CustomAutoComplete } from "../ui/CustomAutoComplete";
import { CustomInputField } from "../ui/CustomInputField";
import { CustomRadio } from "../ui/CustomRadio";
import { CustomSelect } from "../ui/CustomSelect";
import { SectionContainer, Subtitle } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { hrStore } from "./HrStore";

const validateDesignator = (value: string) => {
    let error;
    if (!value) {
        error = t("screen.hr.additionaldata.designator_error");
    }
    return error;
};

const validateAmount = (value: number) => {
    let error;
    if (!value) {
        error = t("screen.hr.additionaldata.amount_error");
    }
    return error;
};

export const HrAdditionalDataForm = (props: {
    initialValues: IEmployee;
    onSubmit: (employee: IEmployee, formikHelpers: FormikHelpers<IEmployee>) => Promise<void>;
    isLoading: boolean;
    buttonText: string;
}) => {
    const titlesPrefix = useTitles()
        ?.filter(title => title.isPrefix)
        .map(title => title.title);
    const titlesSuffix = useTitles()
        ?.filter(title => title.isSuffix)
        .map(title => title.title);
    const religions = useReligions()?.map(religion => religion.religion);

    const initialized = useRef(false);

    if (
        !titlesPrefix ||
        !titlesSuffix ||
        !religions ||
        hrStore.costCenters.state !== "success" ||
        hrStore.funders.state !== "success"
    ) {
        return null;
    }

    const { costCenters } = hrStore.costCenters.data;
    const { funders, byName: fundersByName } = hrStore.funders.data;

    const initialValues = props.initialValues;

    if (!initialized.current) {
        initialized.current = true;

        // Clear values to null to silent mui warning about invalid values
        if (!initialValues.religiousConfession) {
            initialValues.religiousConfession = null;
        }
        if (!initialValues.titlePrefix) {
            initialValues.titlePrefix = null;
        }
        if (!initialValues.titleSuffix) {
            initialValues.titleSuffix = null;
        }

        // find the costCenter and funder, first by ID then by name
        const costCenter = hrStore.getCostCenter(props.initialValues.costCenter, true);
        initialValues.costCenter = costCenter?.id ?? "";
        const funder = hrStore.getFunder(props.initialValues.funder, true);
        initialValues.funder = funder?.id ?? "";
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={props.onSubmit}
            validationSchema={Yup.object().shape({
                bonuses: Yup.array().of(
                    Yup.object().shape({
                        amount: YupLocalizedNumber().typeError(t("screen.hr.additionaldata.number_error")),
                        designator: Yup.string(),
                    }),
                ),
                email: Yup.string().email(t("screen.hr.additionaldata.email_error")),
                monthlySalary: YupLocalizedNumber().moreThan(0, t("screen.hr.additionaldata.monthlySalary_error")),
                kilometersForCommuterEuro: Yup.number()
                    .typeError(t("screen.hr.additionaldata.kilometersForCommuterEuro_typeerror"))
                    .integer(t("screen.hr.additionaldata.kilometersForCommuterEuro_error"))
                    .min(0, t("screen.hr.additionaldata.kilometersForCommuterEuro_error")),
                familyBonusPlusAmount: YupLocalizedNumber().min(
                    0,
                    t("screen.hr.additionaldata.familyBonusPlusAmount_error"),
                ),
                workingHours: YupLocalizedNumber().when("workingHoursInterval", ([workingHoursInterval], schema) => {
                    if (typeof workingHoursInterval !== "string") {
                        return schema;
                    }
                    const { minHours, maxHours } = getMinMaxWorkingHours(workingHoursInterval);

                    return schema
                        .min(minHours, t("screen.hr.additionaldata.hours_error", { minHours, maxHours }))
                        .max(maxHours, t("screen.hr.additionaldata.hours_error", { minHours, maxHours }));
                }),
            })}
            validate={values => {
                const errors: FormikErrors<typeof values> = {};
                if (values.iban && !isValid(values.iban)) {
                    errors.iban = t("screen.hr.additionaldata.iban_error");
                }

                if (values.bic && !isValidBic(values.bic)) {
                    errors.bic = t("screen.hr.additionaldata.bic.validation");
                }
                return errors;
            }}
            validateOnBlur
            validateOnChange
        >
            {formProps => {
                const lastBonus = formProps.values.bonuses[0]
                    ? formProps.values.bonuses[formProps.values.bonuses.length - 1]
                    : { designator: "", amount: "" };

                const nameValid = !!lastBonus?.designator;
                const amountValid = !!lastBonus?.amount;
                const renderAddBonus = nameValid && amountValid;

                return (
                    <Form noValidate>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Subtitle>{t("screen.hr.additionaldata.titelAndAcademicDegrees")}</Subtitle>

                            <SectionContainer style={{ flexDirection: "row" }}>
                                <FastField
                                    data-id="additionalData_titlePrefix"
                                    component={CustomAutoComplete}
                                    options={titlesPrefix}
                                    label={t("screen.hr.additionaldata.titlePrefix")}
                                    name="titlePrefix"
                                    style={{ marginRight: 16, flexGrow: 1 }}
                                    hideOptional
                                />
                                <FastField
                                    data-id="additionalData_titleSuffix"
                                    component={CustomAutoComplete}
                                    options={titlesSuffix}
                                    label={t("screen.hr.additionaldata.titleSuffix")}
                                    name="titleSuffix"
                                    style={{ flexGrow: 1 }}
                                    hideOptional
                                />
                            </SectionContainer>

                            <Subtitle>{t("screen.hr.additionaldata.personaldata")}</Subtitle>

                            <SectionContainer>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <FastField
                                        component={CustomAutoComplete}
                                        data-id="additionalData_religiousConfession"
                                        label={t("screen.hr.additionaldata.religiousConfession")}
                                        name="religiousConfession"
                                        style={{ marginRight: 16, flex: 1 }}
                                        options={religions}
                                        hideOptional
                                    />

                                    <FastField
                                        component={CustomSelect}
                                        data-id="additionalData_maritalStatus"
                                        label={t("screen.hr.additionaldata.maritalStatus")}
                                        name="maritalStatus"
                                        style={{ flex: 1 }}
                                        options={hrStore.maritalStatus.map(status => ({
                                            value: status,
                                            label: t(`maritalStatus.${status}`),
                                        }))}
                                        hideOptional
                                    />
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <FastField
                                        component={CustomSelect}
                                        data-id="additionalData_soleEarnerStatus"
                                        label={t("screen.hr.additionaldata.soleEarnerStatus")}
                                        name="soleEarnerStatus"
                                        style={{ marginRight: 16, flex: 1 }}
                                        options={hrStore.soleEarnerStatus.map(status => ({
                                            value: status,
                                            label: t(`soleEarnerStatus.${status}`),
                                        }))}
                                        hideOptional
                                    />
                                    <FastField
                                        data-id="additionalData_familyBonusPlusAmount"
                                        component={CustomInputField}
                                        label={t("screen.hr.additionaldata.familyBonusPlusAmount")}
                                        name="familyBonusPlusAmount"
                                        type="text"
                                        style={{ flex: 1 }}
                                        hideOptional
                                    />
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <FastField
                                        component={CustomSelect}
                                        data-id="additionalData_commuterAllowance"
                                        label={t("screen.hr.additionaldata.commuterAllowance")}
                                        name="commuterAllowance"
                                        style={{ marginRight: 16, flex: 1 }}
                                        options={hrStore.commuterAllowance.map(ca => ({
                                            value: ca,
                                            label: t(`commuterAllowance.${ca}`),
                                        }))}
                                        hideOptional
                                    />
                                    <FastField
                                        data-id="additionalData_kilometersForCommuterEuro"
                                        component={CustomInputField}
                                        label={t("screen.hr.additionaldata.kilometersForCommuterEuro")}
                                        name="kilometersForCommuterEuro"
                                        type="number"
                                        style={{ flex: 1 }}
                                        hideOptional
                                    />
                                </div>
                                <FastField
                                    data-id="additionalData_email"
                                    component={CustomInputField}
                                    label={t("screen.hr.additionaldata.emailAddress")}
                                    name="email"
                                    type="email"
                                    style={{ flexGrow: 1 }}
                                    hideOptional
                                />
                            </SectionContainer>

                            <Subtitle>{t("screen.hr.additionaldata.salarayAndPosition")}</Subtitle>

                            <SectionContainer>
                                <FastField
                                    data-id="additionalData_jobDescription"
                                    component={CustomInputField}
                                    label={t("screen.hr.additionaldata.jobDescription")}
                                    name="jobDescription"
                                    type="text"
                                    style={{ flexGrow: 1 }}
                                    hideOptional
                                />
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <FastField
                                        data-id="additionalData_monthlySalary"
                                        component={CustomInputField}
                                        label={t("screen.hr.additionaldata.monthlySalary", {
                                            currency: "EUR",
                                        })}
                                        name="monthlySalary"
                                        type="text"
                                        style={{ marginRight: 16, flex: 2 }}
                                        hideOptional
                                    />
                                    <RadioGroup
                                        name="isGrossSalary"
                                        data-id="additionalData_isGrossSalary"
                                        value={formProps.values.isGrossSalary?.toString()}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",

                                            flex: 1,
                                            height: 48,
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <FastField
                                                    component={CustomRadio}
                                                    name="isGrossSalary"
                                                    value="true"
                                                    data-id="gross_salary"
                                                />
                                            }
                                            label={t("screen.hr.additionaldata.gross")}
                                            style={{ marginRight: 32 }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <FastField
                                                    component={CustomRadio}
                                                    name="isGrossSalary"
                                                    value="false"
                                                    data-id="net-salary"
                                                />
                                            }
                                            label={t("screen.hr.additionaldata.net")}
                                        />
                                    </RadioGroup>
                                </div>

                                <FieldArray
                                    name="bonuses"
                                    render={arrayHelpers => (
                                        <div>
                                            {formProps.values.bonuses.map((bonus, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                            }}
                                                        >
                                                            <Field
                                                                data-id={`additionalData_bonuses_${index}_designator`}
                                                                component={CustomInputField}
                                                                label={t("screen.hr.additionaldata.designator")}
                                                                name={`bonuses[${index}].designator`}
                                                                type="text"
                                                                style={{
                                                                    marginRight: 16,
                                                                    flexGrow: 1,
                                                                }}
                                                                validate={
                                                                    formProps.values.bonuses[index].amount &&
                                                                    validateDesignator
                                                                }
                                                                hideOptional
                                                                onBlur={() => {
                                                                    formProps.setFieldTouched(
                                                                        `bonuses[${index}].amount`,
                                                                        true,
                                                                    );
                                                                    formProps.validateField(`bonuses[${index}].amount`);
                                                                }}
                                                            />
                                                            <Field
                                                                data-id={`additionalData_bonuses_${index}_amount`}
                                                                component={CustomInputField}
                                                                label={t("screen.hr.additionaldata.amount", {
                                                                    currency: "EUR",
                                                                })}
                                                                name={`bonuses[${index}].amount`}
                                                                type="text"
                                                                style={{ flexGrow: 1 }}
                                                                validate={
                                                                    formProps.values.bonuses[index].designator &&
                                                                    validateAmount
                                                                }
                                                                hideOptional
                                                                onBlur={() => {
                                                                    formProps.setFieldTouched(
                                                                        `bonuses[${index}].designator`,
                                                                        true,
                                                                    );
                                                                    formProps.validateField(
                                                                        `bonuses[${index}].designator`,
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {renderAddBonus && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        cursor: "pointer",
                                                    }}
                                                    role="button"
                                                    onClick={() => {
                                                        arrayHelpers.push({
                                                            designator: "",
                                                            amount: "",
                                                        });
                                                    }}
                                                    data-id="add_bonus"
                                                >
                                                    <div>
                                                        <Icon name="add" style={{ color: customColors.primaryColor }} />
                                                    </div>

                                                    <p
                                                        style={{
                                                            color: customColors.primaryColor,
                                                            marginLeft: 28,
                                                        }}
                                                    >
                                                        {t("screen.hr.additionaldata.addBonus")}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            </SectionContainer>

                            <Subtitle>{t("screen.hr.additionaldata.bankDetails")}</Subtitle>

                            <SectionContainer style={{ flexDirection: "row" }}>
                                <FastField
                                    data-id="additionalData_iban"
                                    component={CustomInputField}
                                    label={t("screen.hr.additionaldata.iban")}
                                    name="iban"
                                    type="text"
                                    style={{ flex: 2 }}
                                    hideOptional
                                />
                                <FastField
                                    data-id="additionalData_bic"
                                    component={CustomInputField}
                                    label={t("screen.hr.additionaldata.bic")}
                                    name="bic"
                                    type="text"
                                    style={{ marginLeft: 16, flex: 1 }}
                                    hideOptional
                                />
                            </SectionContainer>

                            <Subtitle>{t("screen.hr.additionaldata.workingTimes")}</Subtitle>

                            <SectionContainer>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <FastField
                                        data-id="additionalData_workingHours"
                                        component={CustomInputField}
                                        label={t("screen.hr.additionaldata.workingHours")}
                                        name="workingHours"
                                        type="text"
                                        style={{ marginRight: 16, flex: 1 }}
                                        hideOptional
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "Center",
                                            height: 48,
                                        }}
                                    >
                                        <p style={{ color: customColors.headingsDark }}>
                                            {t("screen.hr.additionaldata.per")}
                                        </p>
                                    </div>
                                    <FastField
                                        data-id="additionalData_workingHoursInterval"
                                        component={CustomSelect}
                                        label={t("screen.hr.additionaldata.workingHoursInterval")}
                                        options={hrStore.workingHoursInterval.map(interval => ({
                                            value: interval,
                                            label: workingHoursIntervalToString(interval),
                                        }))}
                                        name="workingHoursInterval"
                                        style={{ marginLeft: 16, flex: 1 }}
                                        hideOptional
                                    />
                                </div>
                                <FastField
                                    data-id="additionalData_weeklyWorkDays"
                                    component={CustomSelect}
                                    label={t("screen.hr.additionaldata.weeklyWorkDays")}
                                    options={toOptions(hrStore.weeklyWorkDays)}
                                    name="weeklyWorkDays"
                                    style={{ flexGrow: 1 }}
                                    hideOptional
                                />
                            </SectionContainer>

                            <Subtitle>{t("screen.hr.additionaldata.additionalInformation")}</Subtitle>

                            <SectionContainer>
                                <div style={{ display: "flex", gap: 16 }}>
                                    {costCenters.length > 0 && (
                                        <FastField
                                            component={CustomSelect}
                                            data-id="additionalData_costCenter"
                                            label={t("screen.hr.additionaldata.costCenter")}
                                            name="costCenter"
                                            style={{ flex: 1 }}
                                            options={costCenters.map(costCenter => {
                                                return {
                                                    value: costCenter.id,
                                                    label: hrStore.getCostCenterLabel(costCenter),
                                                };
                                            })}
                                            hideOptional
                                        />
                                    )}
                                    {funders.length > 0 && (
                                        <FastField
                                            component={CustomSelect}
                                            data-id="additionalData_funder"
                                            label={t("screen.hr.additionaldata.funder")}
                                            name="funder"
                                            style={{ flex: 1 }}
                                            options={funders.map(funder => {
                                                let label = funder.name;
                                                if (funder.id && fundersByName[funder.name]?.length > 1) {
                                                    label += ` (${funder.id})`;
                                                }
                                                return {
                                                    value: funder.id,
                                                    label,
                                                };
                                            })}
                                            hideOptional
                                        />
                                    )}
                                </div>
                                <FastField
                                    data-id="additionalData_costCenterNote"
                                    component={CustomInputField}
                                    label={t("screen.hr.additionaldata.costCenterNote")}
                                    name="costCenterNote"
                                    type="string"
                                    style={{ flexGrow: 1 }}
                                    hideOptional
                                />
                                <FastField
                                    data-id="additionalData_wageAgreementClassification"
                                    component={CustomInputField}
                                    label={t("screen.hr.additionaldata.wageAgreementClassification")}
                                    name="wageAgreementClassification"
                                    type="string"
                                    style={{ flexGrow: 1 }}
                                    hideOptional
                                />
                            </SectionContainer>

                            <div style={{ textAlign: "right" }}>
                                <Button
                                    data-id="additionalData_submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={props.isLoading}
                                    type="submit"
                                    style={{ marginBottom: 26 }}
                                >
                                    {props.buttonText}
                                </Button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
