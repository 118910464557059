import { Button, Card, CardContent, withStyles } from "@material-ui/core";
import * as React from "react";
import LineEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { EventsFeedEntry } from "../../network/APITypes";
import { formatDate } from "../../util/helpers";
import { BOX_SHADOW_LIGHT, customColors } from "../util/Theme";

const ResponsiveEllipsis = responsiveHOC<React.ComponentProps<typeof LineEllipsis>>(0)(LineEllipsis);

export const EVENT_CARD_WIDTH = 272;

const StyledCard = withStyles({
    root: {
        position: "relative",
        display: "flex",
        height: 158,
        backgroundColor: customColors.white,
        boxShadow: BOX_SHADOW_LIGHT,
        marginBottom: 6, // needed in order to swipeable-views not cutting off the boxShadow
    },
})(Card);

const StyledCardContent = withStyles({
    root: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        padding: "16px 12px",
        "&:last-child": {
            paddingBottom: 16,
        },
    },
})(CardContent);

const StyledButton = withStyles({
    root: {
        height: 30,
        padding: 0,
    },
})(Button);

const LocationTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    max-height: 30px;
    border: 1px solid #b4b8b8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: 16px;
`;

const TitleContainer = styled.div`
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
`;

interface IProps {
    event: EventsFeedEntry;
    onClick: (event: EventsFeedEntry) => void;
    style?: React.CSSProperties;
}

export const CockpitEventCard = ({ event, onClick, style }: IProps) => {
    const handleClick = () => {
        onClick(event);
    };

    return (
        <StyledCard style={{ cursor: "pointer", ...style }} onClick={handleClick}>
            <StyledCardContent>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <span className="caption" style={{ flexShrink: 0 }}>
                        {formatDate(event.eventDate)}
                    </span>
                    {event.location && (
                        <LocationTag className="caption">
                            <ResponsiveEllipsis maxLine={2} trimRight basedOn="letters" text={event.location} />
                        </LocationTag>
                    )}
                </div>
                <TitleContainer>
                    <h4 style={{ marginTop: 16, marginBottom: 16 }}>
                        <ResponsiveEllipsis maxLine={2} trimRight basedOn="letters" text={event.title} />
                    </h4>
                </TitleContainer>
                <StyledButton color="primary">{t("screen.cockpit.event.moreInformation")}</StyledButton>
            </StyledCardContent>
        </StyledCard>
    );
};
