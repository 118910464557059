import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { getModuleStore } from "../../../stores/moduleStores";
import { Module } from "../../../types/models";
import { getModuleRoutes } from "../../../types/moduleRoutes";
import { withParams } from "../../app/router/history";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { ModuleRecordsSite } from "../../shared/sites/ModuleRecordsSite";
import { TicketsDetailsSite } from "../../tickets/sites/TicketsDetailsSite";
import { ModuleRecordsNavBar } from "../ModuleRecordsNavBar";
import { ModuleReportUploadSite } from "../ModuleReportUploadSite";
import { TicketsListSite } from "../TicketsListSite";
import { ModuleOverviewSite } from "../sites/ModuleOverviewSite";
import { ModuleRecordsDetailsEditSite } from "../sites/ModuleRecordsDetailsEditSite";
import { ModuleRecordsDetailsUploadSite } from "../sites/ModuleRecordsDetailsUploadSite";
import { ModuleRecordsListSite } from "../sites/ModuleRecordsListSite";
import { ModuleRecordsUploadSite } from "../sites/ModuleRecordsUploadSite";
import { ModuleReportTicketSite } from "../sites/ModuleReportTicketSite";

const RecordsListRouter = ({ module }: { module: Module }) => {
    const moduleRoutes = getModuleRoutes(module);
    const store = getModuleStore(module);
    const { recordTypeId } = useParams<{ recordTypeId: string }>();
    if (!recordTypeId) {
        return null;
    }

    return (
        <>
            <ModuleRecordsNavBar module={module} />
            <Switch>
                <Route exact path={moduleRoutes.RECORDS.LIST}>
                    <ModuleRecordsListSite module={module} />
                </Route>
                <Route exact path={moduleRoutes.RECORDS.TICKETS}>
                    {store.hasRecordTickets ? (
                        <TicketsListSite module={module} recordTypeId={recordTypeId} />
                    ) : (
                        <Redirect to={withParams(moduleRoutes.RECORDS.LIST, { recordTypeId })} />
                    )}
                </Route>
            </Switch>
        </>
    );
};

export const ModuleRouter = ({ module }: { module: Module }) => {
    const moduleRoutes = getModuleRoutes(module);
    return (
        <Switch>
            <Route exact path={moduleRoutes.ROOT}>
                <ModuleOverviewSite module={module} />
            </Route>
            <Route exact path={moduleRoutes.RECORDS.ROOT}>
                <ModuleRecordsSite module={module} />
            </Route>
            <Route exact path={moduleRoutes.RECORDS.UPLOAD}>
                <ModuleRecordsUploadSite module={module} />
            </Route>
            <Route exact path={moduleRoutes.RECORDS.DETAILS_UPLOAD}>
                <ModuleRecordsDetailsUploadSite module={module} />
            </Route>
            <Route exact path={moduleRoutes.RECORDS.DETAILS_EDIT}>
                <ModuleRecordsDetailsEditSite module={module} />
            </Route>
            <Route exact path={[moduleRoutes.RECORDS.LIST, moduleRoutes.RECORDS.TICKETS]}>
                <RecordsListRouter module={module} />
            </Route>
            <Route exact path={moduleRoutes.RECORDS.TICKET_DETAILS}>
                <TicketsDetailsSite module={module} />
            </Route>
            <Route exact path={moduleRoutes.REPORT_TICKET}>
                <ModuleReportTicketSite module={module} />
            </Route>
            <Route exact path={moduleRoutes.REPORT_UPLOAD}>
                <ModuleReportUploadSite module={module} />
            </Route>
            <Route component={NotFoundSite} />
        </Switch>
    );
};
