import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { SEARCH_DEBOUNCE_MS } from "../../config";
import { IMessageIDS, t } from "../../i18n/util";
import { TableStore } from "../../stores/TableStore";
import { ITableFiltersConfig } from "../hooks/useTableFilters";
import { SearchBarLabel } from "../ui/SearchBarLabel";
import { MobileContext } from "../util/MobileContext";
import { TableFilterToggleButton } from "./filter/TableFilterToggleButton";
import { SearchField } from "./SearchField";

export const TableSearchBar = (props: {
    label: IMessageIDS;
    placeholder: IMessageIDS;
    search: string;
    totalCount: number;
    onChangeSearch: (search: string) => void;
}) => {
    const isMobile = React.useContext(MobileContext);
    const searchBarLabel = <SearchBarLabel count={props.totalCount} label={props.label} />;
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: isMobile ? undefined : "center",
                    flexDirection: isMobile ? "column" : "row",
                }}
            >
                <SearchField
                    value={props.search}
                    data-id="table_search"
                    onChange={props.onChangeSearch}
                    placeholder={t(props.placeholder)}
                    style={{ flexGrow: 1 }}
                    debounceMs={SEARCH_DEBOUNCE_MS}
                />
                {isMobile && searchBarLabel}
            </div>
            {!isMobile && searchBarLabel}
        </>
    );
};

interface TableSearchBarWithActionProps {
    label: IMessageIDS;
    labelSelected?: IMessageIDS;
    placeholder: IMessageIDS;
    select?:
        | {
              selectedIds: string[]; // useTableSelection
          }
        | {
              selectedItems: TableStore["selectedItems"]; // TableStore
          };
    everythingSelected?: boolean; // prop for the select all banner
    search: string;
    totalCount: number;
    onChangeSearch: (search: string) => void;
    disabled?: boolean;
    disabledSecondary?: boolean;
    buttonLabel?: string;
    buttonLabelSecondary?: string;
    onAction?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onActionSecondary?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    bulkAction?: React.ReactNode;
    action?: React.ReactNode;
    tableFilters?: ITableFiltersConfig;
    showTableFiltersAsButton?: boolean;
}

export const TableSearchBarWithAction = observer(function TableSearchBarWithAction(
    props: TableSearchBarWithActionProps,
) {
    const isMobile = React.useContext(MobileContext);
    let searchLabel = <SearchBarLabel count={props.totalCount} label={props.label} />;

    const select = props.select;
    const countSelected = select && ("selectedIds" in select ? select.selectedIds.length : select.selectedItems.size);
    if (props.labelSelected) {
        if (props.everythingSelected) {
            searchLabel = <SearchBarLabel count={props.totalCount} label={props.labelSelected} />;
        } else if (countSelected && countSelected > 0) {
            searchLabel = <SearchBarLabel count={countSelected} label={props.labelSelected} />;
        }
    }

    let action = null;
    const bulkAction = !!props.bulkAction && !!countSelected && countSelected > 0;
    const singleAction = !!props.buttonLabel && !!props.onAction && !bulkAction;
    if (singleAction) {
        action = (
            <>
                <TableSearchBarButton data-id="table_action_button" disabled={props.disabled} onClick={props.onAction}>
                    {props.buttonLabel}
                </TableSearchBarButton>
                {props.buttonLabelSecondary && props.onActionSecondary && (
                    <TableSearchBarButton
                        data-id="table_action_button_secondary"
                        disabled={props.disabledSecondary}
                        onClick={props.onActionSecondary}
                    >
                        {props.buttonLabelSecondary}
                    </TableSearchBarButton>
                )}
            </>
        );
    } else if (bulkAction) {
        action = props.bulkAction;
    } else if (props.action) {
        action = props.action;
    } // There is no else and that's intentional, some screens have no single action

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: isMobile ? undefined : "center",
                    flexDirection: isMobile ? "column" : "row",
                    width: "100%",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                    <SearchField
                        value={props.search}
                        data-id="table_search"
                        onChange={props.onChangeSearch}
                        placeholder={t(props.placeholder)}
                        style={{ flexGrow: 1 }}
                        debounceMs={SEARCH_DEBOUNCE_MS}
                    />
                    {(isMobile || props.showTableFiltersAsButton) && props.tableFilters && (
                        <div style={{ marginLeft: 16 }}>
                            <TableFilterToggleButton onClick={props.tableFilters.open} />
                        </div>
                    )}
                </div>
                {isMobile && searchLabel}
                {action}
            </div>
            {!isMobile && searchLabel}
            {!props.showTableFiltersAsButton && !isMobile && props.tableFilters?.Buttons}
            {props.tableFilters?.Components}
        </>
    );
});

export const TableSearchBarButton = ({
    onClick,
    "data-id": dataId,
    disabled,
    style,
    children,
}: {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    "data-id"?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    children: React.ReactNode;
}) => {
    const isMobile = React.useContext(MobileContext);

    return (
        <Button
            data-id={dataId}
            disabled={disabled}
            variant="contained"
            color="primary"
            style={{
                minWidth: 176,
                marginLeft: isMobile ? undefined : 16,
                marginTop: isMobile ? 8 : undefined,
                width: isMobile ? "100%" : undefined,
                ...style,
            }}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};
