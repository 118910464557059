import { Button, Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { LocationDescriptor } from "history";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, useLocation } from "react-router";
import { ZOOM_IN_THRESHOLD, ZOOM_OUT_THRESHOLD } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { TermsOfUseDownload } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { getScrollbarWidth, replaceTextWithElements } from "../../../util/helpers";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { LazyDocument, LazyPage } from "../../libs/react-pdf";
import { CenteredContent } from "../../ui/CenteredContent";
import { FieldError } from "../../ui/CustomInputField";
import { DocumentDownloadLine } from "../../ui/DocumentLine/DocumentLine";
import { LoadingOverlay } from "../../ui/LoadingOverlay";
import { NavBarBack } from "../../ui/NavBarBack";
import { Dotted, TooltipBiggerFont, VCenter } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { MobileContext } from "../../util/MobileContext";
import { Routes } from "../router/Routes";
import { pushLocation } from "../router/history";

export interface TermsOfUseSiteState {
    backLabel?: string;
    backTarget?: LocationDescriptor;
}

export const TermsOfUseSite = () => {
    const { token } = useQueryParams<{ token: string }>();

    if (!token) {
        return <Redirect to={Routes.ROOT} />;
    }

    return <TermsOfUse token={token} />;
};

const TermsOfUse = observer(function TermsOfUse({ token }: { token: string }) {
    const [numPages, setNumPages] = React.useState(0);
    const [zoomFactor, setZoomFactor] = React.useState(1);
    const [scrollBarWidth, setScrollBarWidth] = React.useState(0);
    const [error, setError] = React.useState("");
    const [initialWidth, setInitialWidth] = React.useState(0);
    const [userScrolledToBottom, setUserScrolledToBottom] = React.useState(false);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const documentContainerRef = React.useRef<HTMLDivElement | null>(null);
    const isMobile = React.useContext(MobileContext);
    const [termsOfUseDownload, setTermsOfUseDownload] = React.useState<TermsOfUseDownload>();
    const [showSignError, setShowSignError] = React.useState(false);
    useHideSideBar();

    const location = useLocation<TermsOfUseSiteState | undefined>();

    React.useEffect(() => {
        const load = async () => {
            try {
                generalStore.isLoading = true;

                const response = await API.putReceiveTermsOfUseDownloadLink(token);
                setTermsOfUseDownload(response);
            } catch (error) {
                const apiError = getApiError(error);
                if (
                    apiError?.response.type === "TERMS_OF_USE_NOT_FOUND" &&
                    apiError.statusCode === HttpStatusCode.NotFound_404
                ) {
                    setShowSignError(true);
                } else {
                    generalStore.setError(t("error.downloadTermsOfUseDocument"), error);
                }
            } finally {
                generalStore.isLoading = false;
            }
        };

        load();
    }, [token]);

    React.useLayoutEffect(() => {
        if (documentContainerRef.current) {
            setInitialWidth(documentContainerRef.current.clientWidth);
        }
    }, [isMobile]);

    React.useEffect(() => {
        setScrollBarWidth(getScrollbarWidth());

        if (!isMobile) {
            setZoomFactor(1);
        }
    }, [isMobile]);

    const successDialog = useSuccessDialog({
        title: t("screen.termsOfUse.success.sign"),
        onClose: () => {
            pushLocation(location.state?.backTarget ?? Routes.ROOT);
        },
    });

    const onDocumentLoadSuccess = (numPages: number) => {
        setNumPages(numPages);
    };

    const handleSubmit = async () => {
        if (termsAccepted) {
            setError("");

            try {
                generalStore.isLoading = true;
                await API.putTermsOfUseSign(token);
            } catch (error) {
                const apiError = getApiError(error);
                if (
                    apiError?.response.type === "TERMS_OF_USE_NOT_FOUND" &&
                    apiError.statusCode === HttpStatusCode.NotFound_404
                ) {
                    setShowSignError(true);
                } else {
                    generalStore.setError(t("error.signTermsOfUse"), error);
                }

                return;
            } finally {
                generalStore.isLoading = false;
            }

            try {
                generalStore.isLoading = true;
                await companiesStore.reloadCompany();
            } catch (error) {
                generalStore.setError(t("error.loadCompany"), error);
                return;
            } finally {
                generalStore.isLoading = false;
            }

            successDialog.openDialog();
        } else {
            setError(t("termsOfUse.checkbox.error"));
        }
    };

    const handleBack = () => {
        pushLocation(location.state?.backTarget ?? Routes.ROOT);
    };

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        // modified version of https://stackoverflow.com/a/42860948 to account for various browser, OS and device differences
        const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight < 10;

        if (bottom) {
            setUserScrolledToBottom(true);
        }
    };

    const handleZoomIn = () => {
        if (zoomFactor < ZOOM_IN_THRESHOLD) {
            setZoomFactor(zoomFactor + 0.1);
        }
    };

    const handleZoomOut = () => {
        if (zoomFactor > ZOOM_OUT_THRESHOLD) {
            setZoomFactor(zoomFactor - 0.1);
        }
    };

    const handleClickAcceptTermsCheckbox = () => {
        setTermsAccepted(!termsAccepted);
    };

    const handleDownloadTermsOfUseDocument = async () => {
        try {
            generalStore.isLoading = true;
            await API.putDownloadTermsOfUseDocument(token);
        } catch (error) {
            generalStore.setError(t("error.downloadTermsOfUseDocument"));
        } finally {
            generalStore.isLoading = false;
        }
    };

    const termsDocument = (
        <React.Suspense fallback={<LoadingOverlay />}>
            <LazyDocument
                file={termsOfUseDownload?.downloadUrl}
                onLoadSuccess={document => {
                    onDocumentLoadSuccess(document.numPages);
                }}
                onLoadProgress={() => {
                    if (!showSignError) {
                        return <LoadingOverlay />;
                    }
                }}
            >
                {Array.from(new Array(numPages), (element, index) => (
                    <LazyPage
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        renderAnnotationLayer={false}
                        width={initialWidth - scrollBarWidth}
                        scale={zoomFactor}
                    />
                ))}
            </LazyDocument>
        </React.Suspense>
    );

    const zoomControls = (
        <>
            <IconButton aria-label="zoom-out" onClick={handleZoomOut}>
                <ZoomOutIcon fontSize="large" />
            </IconButton>
            <IconButton aria-label="zoom-in" onClick={handleZoomIn}>
                <ZoomInIcon fontSize="large" />
            </IconButton>
        </>
    );

    let title: React.ReactNode;
    if (termsOfUseDownload?.companyNames) {
        if (termsOfUseDownload.companyNames.length === 1) {
            title = t("screen.termsOfUseWithCompanyName.title", { companyName: termsOfUseDownload.companyNames[0] });
        } else {
            const rawTitle = t("screen.termsOfUseWithCompanyNames.title");
            // replace the [count] with the number and a tooltip containing the remaining company names
            const names = (
                <ul style={{ marginLeft: 16 }}>
                    {termsOfUseDownload.companyNames
                        .slice()
                        .sort()
                        .map(name => (
                            <li key={name}>{name}</li>
                        ))}
                </ul>
            );
            title = replaceTextWithElements(rawTitle, {
                "[count]": (
                    <TooltipBiggerFont key="tooltip" title={names}>
                        <Dotted>{termsOfUseDownload.companyNames.length}</Dotted>
                    </TooltipBiggerFont>
                ),
            });
        }
    } else if (termsOfUseDownload?.companyName) {
        title = t("screen.termsOfUseWithCompanyName.title", { companyName: termsOfUseDownload.companyName });
    } else {
        // terms of use title without company name will be only shown when terms of use are already accepted or token is not valid anymore
        title = t("screen.termsOfUse.title");
    }

    let backLabel, backTarget;
    if (location.state?.backLabel && location.state.backTarget) {
        backLabel = location.state.backLabel;
        backTarget = location.state.backTarget;
    }

    return (
        <>
            <NavBarBack
                title={title}
                controlComponent={isMobile ? zoomControls : undefined}
                backLabel={backLabel}
                backTarget={backTarget}
                showCancel={false}
            />

            <CenteredContent>
                {showSignError ? (
                    <VCenter>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <h3>{t("screen.termsOfUse.alreadySignedInfo")}</h3>
                            <Button color="primary" variant="contained" style={{ marginTop: 24 }} onClick={handleBack}>
                                {t("screen.termsOfUse.backToLogin")}
                            </Button>
                        </div>
                    </VCenter>
                ) : (
                    <SiteContent
                        style={{
                            padding: isMobile ? "0px 0px 16px 0px" : "24px 24px 16px 24px",
                        }}
                    >
                        {!isMobile && (
                            <div
                                style={{ overflow: "auto", height: "70vh" }}
                                onScroll={handleScroll}
                                ref={documentContainerRef}
                            >
                                {termsDocument}
                            </div>
                        )}
                        {isMobile && (
                            <div ref={documentContainerRef} style={{ overflow: "auto" }}>
                                {termsDocument}
                            </div>
                        )}
                        <div
                            style={{
                                display: "flex",
                                alignItems: isMobile ? "center" : "flex-end",
                                flexDirection: "column",
                                marginTop: 32,
                            }}
                        >
                            <DocumentDownloadLine
                                onDownload={handleDownloadTermsOfUseDocument}
                                documentId={token}
                                fileName={termsOfUseDownload?.fileName}
                            />
                            <FormControlLabel
                                style={{ marginTop: 24 }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        onChange={handleClickAcceptTermsCheckbox}
                                        checked={termsAccepted}
                                    />
                                }
                                label={t("screen.termsOfUse.checkbox")}
                            />
                            <FieldError style={{ width: "unset" }}>{error}</FieldError>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ marginTop: 24 }}
                                disabled={!isMobile ? !userScrolledToBottom : false}
                                onClick={handleSubmit}
                            >
                                {t("screen.termsOfUse.accept")}
                            </Button>
                        </div>
                    </SiteContent>
                )}
                {successDialog.dialog}
            </CenteredContent>
        </>
    );
});
