import { createTheme } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";

export const CONTENT_PADDING_HORIZONTAL = 24;
export const CONTENT_PADDING_HORIZONTAL_MOBILE = 16;
export const CONTENT_MIN_WIDTH = 800;
export const CONTENT_MAX_WIDTH = 1000;

export const BOX_SHADOW = "4px 6px 12px rgba(54, 75, 141, 0.2), -4px 4px 12px rgba(96, 89, 89, 0.15)";
export const BOX_SHADOW_LIGHT = "0px 3px 6px rgba(54, 75, 141, 0.1)";

export const STEP_PADDING_LEFT = 16;
export const DIALOG_WIDTH = 560;

export const customColors = {
    body1Dark: "#444756", //"var(--body1-dark)", -> can't use var, because this confuses Material UI
    body2Dark: "var(--body2-dark)",
    headingsDark: "var(--headings-dark)",
    placeholder: "var(--placeholder)",
    primaryColor: "#3DA35D",
    primaryColorLight: "#1FC18F",
    primaryColorDark: "#007D58",
    primaryShade: "#F0FAF7",
    secondaryColor: "#3C4E99",
    secondaryColorLight: "#495EA0",
    secondaryColorDark: "#173178",
    white: "#FFFFFF",
    orangeOpen: "#EE8033",
    orangeOpenBackground: "#FDF2EB",
    yellowInProgress: "#FFD616",
    yellowInProgressDisabled: "#FFF1B3",
    yellowBackgroundInProgress: "#FFFDF5",
    blueFinished: "#3C4E99",
    blueFinishedBackground: "#F8F9FC",
    redUrgent: "#B3364C",
    redUrgentBackground: "#FFF9FA",
    error: "#F44336", // TODO: these two red shades remain for error snackbar?
    errorBackground: "#FFF2F2",
    greyBackground: "#F6F8F9",
    greyLight: "#EDEDED",
    greyTextfields: "#B4B8B8",
    disabled: "#B4B8B8",
    greyDarkIcons: "#666A7C",
    greyDarkIntense: "#4F4F4F",

    // ChatMessage colors
    chatMessageBackgroundPrimary: "#ECF6EF",
    chatMessageBackgroundSecondary: "#F5F5F5",

    //additional colors personnel accounting customer view

    greyButton: "#A5AAA9",
    greenBanner: "#22B87C",
};

export const theme = createTheme({
    palette: {
        background: {
            default: customColors.greyBackground,
        },
        primary: {
            main: customColors.primaryColor,
            dark: customColors.primaryColorDark,
            light: customColors.primaryColorLight,
        },
        secondary: {
            main: customColors.secondaryColor,
            dark: customColors.secondaryColorDark,
            light: customColors.secondaryColorLight,
        },
        text: {
            primary: customColors.body1Dark,
            secondary: "#76777D",
        },
    },
    typography: {
        body1: { fontSize: 14 }, // override for MuiFormControlLabel
    },
    overrides: {
        MuiDialog: {
            paperWidthMd: {
                maxWidth: DIALOG_WIDTH,
            },
        },
        MuiButton: {
            contained: {
                fontSize: 16,
                lineHeight: "18px",
                padding: "13px 16px",
                boxShadow: "none",
                "&:hover": {
                    // backgroundColor: Styles.MAIN_COLOR_HOVERED,
                    boxShadow: "none",
                },
            },
            outlinedPrimary: {
                fontSize: 16,
                lineHeight: "18px",
                padding: "11px 16px",
                border: `2px solid ${customColors.primaryColor}`,
                backgroundColor: "white",
                transition:
                    "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                "&:hover": {
                    color: customColors.primaryColorDark,
                    border: `2px solid ${customColors.primaryColorDark}`,
                    backgroundColor: "white",
                },
            },
            text: {
                fontSize: 14,
                lineHeight: "16px",
                padding: "14px 16px",
            },
            root: {
                fontWeight: "bold",
                textTransform: "none",
            },
        },
        MuiCheckbox: {
            indeterminate: {
                "&$colorPrimary:not($disabled)": {
                    color: customColors.primaryColor,
                },
            },
        },
        MuiFab: {
            extended: {
                height: 56,
                borderRadius: 28,
                fontSize: 16,
                fontWeight: "bold",
                "&:hover": {
                    height: 56,
                    borderRadius: 28,
                },
                "&$disabled": {
                    backgroundColor: customColors.greyLight,
                },
            },
        },
        MuiDrawer: {
            root: {
                boxShadow: "3px 0px 30px rgba(139, 142, 143, 0.2)",
            },
            paperAnchorDockedLeft: {
                borderRight: `1px solid ${customColors.greyLight}`,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: customColors.greyLight,
            },
        },
        MuiSelect: {
            icon: {
                color: customColors.primaryColor,
            },
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        MuiListItem: {
            root: {
                paddingTop: 16,
                paddingBottom: 16,
            },

            gutters: {
                paddingLeft: 20,
            },
        },
        MuiMenuItem: {
            root: {
                lineHeight: "22px",
                fontSize: 12,
                color: "var(--body1-dark)",
            },
        },
        MuiPaper: {
            elevation4: {
                boxShadow: "none",
            },
            elevation6: {
                boxShadow: "none",
            },
            elevation1: {
                boxShadow: "none",
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 40, // 24 for icon + 16 margin
            },
        },

        // Make input boxes smaller
        MuiOutlinedInput: {
            input: {
                padding: "15px 14px",
            },
        },

        // Make autocomplete boxes smaller
        MuiAutocomplete: {
            input: {
                padding: "6px 4px !important",
            },
        },

        // Move input label up, now that boxes are smaller
        MuiInputLabel: {
            outlined: {
                transform: "translate(14px, 18px) scale(1)",
            },
        },

        MuiSnackbarContent: {
            root: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid transparent`,

                "[data-severity='info'] &": {
                    backgroundColor: customColors.blueFinishedBackground,
                    color: customColors.blueFinished,
                    borderColor: customColors.blueFinished,
                },
                "[data-severity='warning'] &": {
                    backgroundColor: customColors.orangeOpenBackground,
                    color: customColors.orangeOpen,
                    borderColor: customColors.orangeOpen,
                },
                "[data-severity='error'] &": {
                    backgroundColor: customColors.errorBackground,
                    color: customColors.error,
                    borderColor: customColors.error,
                },
            },
        },

        MuiAccordion: {
            root: {
                "&$disabled": {
                    backgroundColor: "white",
                },
            },
        },

        MuiTableCell: {
            head: {
                fontWeight: "normal",
                fontSize: 10,
                color: customColors.body2Dark,
                borderBottomStyle: "none",
                padding: "0 13px",
                height: 50,
            },
            root: {
                padding: 13,
            },
        },

        MuiTab: {
            root: {
                minHeight: "unset",
                textTransform: "none",
                fontWeight: "normal",
                height: 36,
                "@media (min-width:600px)": {
                    minWidth: 144,
                },
                "&.Mui-selected": {
                    fontWeight: "bold",
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: "#ffffff",
                color: "#797979",
                boxShadow: BOX_SHADOW,
                padding: 16,
            },
        },
        MuiTabs: {
            root: {
                padding: 0,
                minHeight: "unset",
            },
        },

        MuiMenu: {
            paper: {
                boxShadow: BOX_SHADOW,
            },
        },

        MuiFormControlLabel: {
            root: {
                marginRight: 0,
            },
        },

        MuiFormLabel: {
            filled: {
                color: customColors.primaryColor,
            },
        },

        MuiSlider: {
            root: {
                color: customColors.greyButton,
                height: 4,
            },
            thumb: {
                color: customColors.primaryColor,
                height: 22,
                width: 22,
                marginTop: -9,
                marginLeft: -11,
            },
            valueLabel: {
                left: "calc(-50% + 4px)",
            },
            track: {
                color: customColors.primaryColor,
                height: 4,
                borderRadius: 2,
            },
            rail: {
                height: 4,
                borderRadius: 4,
            },
        },

        MuiLink: {
            root: {
                fontFamily: "inherit",
            },
        },
    } as Overrides,
});

export const ZINDEX_DRAWER_OFFSET = theme.zIndex.drawer + 51;
