import { observable } from "mobx";
import { addStoreToWindow } from "../util/debug";

// Bound to window to toggle debug settings at runtime
class DebugStore {
    @observable showStringKeys = false;
}

export const debugStore = new DebugStore();

addStoreToWindow("debugStore", debugStore);
