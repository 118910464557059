import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import debounce from "lodash/debounce";
import React from "react";
import { SEARCH_DEBOUNCE_MS } from "../../config";
import { t } from "../../i18n/util";
import { UserPermissions } from "../../network/APITypes";
import { debug } from "../../util/debug";
import { getFullName } from "../../util/user";
import { useUsers } from "../hooks/useUsers";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

interface IProps {
    companyId?: string;
    onSelectUser?: (user: UserPermissions | null) => void;
    style?: React.CSSProperties;
    disabled?: boolean;
    disableSuperAdmins?: boolean;
    disableRoleNone?: boolean;
    disabledUserIds?: string[];
}

interface IOption {
    user?: UserPermissions;
    action?: "next";
}

export const AutocompleteUsers = ({
    companyId,
    onSelectUser,
    style,
    disabled,
    disableSuperAdmins,
    disableRoleNone,
    disabledUserIds,
}: IProps) => {
    const [search, setSearch] = React.useState("");
    // Have to initialize with {} instead of null, because selecting "next" triggers setSelected({})
    // which won't be a state change and therefore put "+X Weitere" into the input field.
    const [selected, setSelected] = React.useState<IOption>({});
    const [load, setLoad] = React.useState(!disabled);

    // On first enable -> load users
    React.useEffect(() => {
        if (!disabled) {
            setLoad(true);
        }
    }, [disabled]);

    const users = useUsers({
        companyId: load ? companyId : undefined,
        limit: 50,
        search,
        orderBy: "lastName",
        orderDir: "asc",
        showLoading: false,
    });
    const options = users.users.map<IOption>(user => ({ user }));
    const onChangeSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }, SEARCH_DEBOUNCE_MS);

    return (
        <Autocomplete
            style={style}
            getOptionLabel={option => {
                if (option.user) {
                    return getFullName(option.user);
                } else if (option.action) {
                    return t("companyList.remainingCompanies", { remainingCompanies: users.remainingCount });
                } else return "";
            }}
            getOptionDisabled={option => {
                if (!option.user || (!disableSuperAdmins && !disabledUserIds)) {
                    return false;
                }

                let disabled = false;
                if (disableSuperAdmins) {
                    disabled = option.user.roles.includes("super-admin");
                }

                // do not disable "none" role users if a permission change needs to be released
                if (!disabled && disableRoleNone && !option.user.needsRelease) {
                    disabled = option.user.roles.includes("none");
                }

                if (!disabled && disabledUserIds) {
                    disabled = disabledUserIds.includes(option.user.id);
                }

                return disabled;
            }}
            getOptionSelected={(option, value) => {
                // Compare entries by id
                return !option.user || !value.user ? false : option.user.id === value.user.id;
            }}
            options={options}
            noOptionsText={t("common.noSearchResults")}
            onChange={(_, option) => {
                debug.log("### select", option);
                if (option.action === "next") {
                    users.loadNext();
                    setSelected({}); // don't select "+X Weitere"
                    onSelectUser?.(null);
                } else if (option.user) {
                    setSelected(option);
                    onSelectUser?.(option.user);
                }
            }}
            value={selected}
            disableClearable
            renderInput={params => (
                <TextField
                    {...params}
                    label={t("config.roles.option.copy.placeholder")}
                    variant="outlined"
                    onChange={onChangeSearch}
                    // error={showError}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon name="search" style={{ color: customColors.greyDarkIcons }} />
                            </InputAdornment>
                        ),
                        endAdornment: users.isLoading ? (
                            <React.Fragment>
                                <CircularProgress style={{ color: customColors.greyTextfields }} size={24} />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                        ),
                    }}
                />
            )}
            filterOptions={(options, params) => {
                if (users.remainingCount > 0) {
                    options.push({ action: "next" });
                }
                return options;
            }}
            onOpen={() => {
                setLoad(true);
            }}
            disabled={disabled}
        />
    );
};
