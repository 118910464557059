import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { POPUP_WINDOW_FEATURES } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { UnpaidAccountInvoice, UnpaidAccounts } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useInMemoryTableStore, useTableStore } from "../../../stores/TableStore";
import { formatDate, getMinMaxDate } from "../../../util/date";
import { replaceTextWithElements } from "../../../util/helpers";
import { Comparer } from "../../../util/sort";
import { Routes } from "../../app/router/Routes";
import { useExternalWindow } from "../../hooks/useExternalWindow";
import { useTableFilters } from "../../hooks/useTableFilters";
import { Currency } from "../../results/ResultsValue";
import { CenteredContent } from "../../ui/CenteredContent";
import { GridTable, GridTableColumn, RenderCell } from "../../ui/GridTable";
import { NavBarBack } from "../../ui/NavBarBack";
import { FormattedCurrency, TableLabel } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { DATE_RANGE_FILTER_TYPE, DateRange, ITableFilters } from "../../ui/filter/types";
import { Icon } from "../../util/Icon";
import { LastCompletedUnpaidAccountPayment } from "../LastCompletedUnpaidAccountPayment";

export const KpisUnpaidAccountsInvoicesSite = observer(function KpisUnpaidAccountsInvoicesSite() {
    const kpiStore = companiesStore.selectedCompanyStore?.kpiStore;

    const unpaidAccounts = kpiStore?.unpaidAccounts;

    useEffect(() => {
        kpiStore?.loadUnpaidAccounts();
    }, [kpiStore]);

    if (!unpaidAccounts) {
        return null; // a loading indicator is displayed by the `generalStore`
    }

    const value = (
        <FormattedCurrency
            key="value"
            value={unpaidAccounts.unpaidItem}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
        />
    );
    const title = replaceTextWithElements(t("unpaidAccounts.invoices.title"), { "[value]": value });
    const date = t("screen.cockpit.kpis.unpaidAccounts.kpi.label", { date: formatDate(new Date()) });

    return (
        <>
            <NavBarBack
                title={title}
                controlComponent={date}
                backLabel={t("sidebar.list.cockpit")}
                backTarget={Routes.COCKPIT}
                showCancel={false}
            />
            <KpisUnpaidAccountsInvoices unpaidAccounts={unpaidAccounts} />
        </>
    );
});

type InvoiceWithId = UnpaidAccountInvoice & {
    id: string;
};

const withId = (invoice: UnpaidAccountInvoice): InvoiceWithId => {
    return {
        ...invoice,
        id: `${invoice.tpaCompanyId}-${invoice.financialAccountancyId}-${invoice.bookingId}`,
    };
};

const KpisUnpaidAccountsInvoices = ({ unpaidAccounts }: { unpaidAccounts: UnpaidAccounts }) => {
    const invoices = useMemo(() => {
        return unpaidAccounts.invoices?.map<InvoiceWithId>(withId) ?? [];
    }, [unpaidAccounts.invoices]);

    return (
        <CenteredContent>
            <SiteContent>
                <InvoicesTable invoices={invoices ?? []} />
            </SiteContent>
        </CenteredContent>
    );
};

const handlePayUnpaidAccountClicked = async (
    tpaCompanyID: number,
    financialAccountancyID: number,
    bookingID: string,
) => {
    try {
        generalStore.isLoading = true;
        const { url } = await API.postUnpaidAccountPayment(
            companiesStore.selectedCompanyStore?.id ?? "",
            tpaCompanyID.toString(),
            financialAccountancyID.toString(),
            bookingID,
        );
        return url;
    } catch (error) {
        generalStore.setError(t("error.payUnpaidAccount"), error);
    } finally {
        generalStore.isLoading = false;
    }
};

const renderCell: RenderCell<InvoiceWithId> = (invoice, column) => {
    switch (column.column) {
        case "receiptDate":
            return formatDate(invoice.receiptDate);
        case "receiptNumber":
            return <TableLabel style={{ maxWidth: undefined }}>{String(invoice.receiptNumber ?? "")}</TableLabel>;
        case "receiptText":
            return <TableLabel style={{ maxWidth: undefined }}>{invoice.receiptText ?? ""}</TableLabel>;
        case "netAmount":
            return <Currency value={invoice.netAmount ?? 0} />;
        case "opAmount":
            return <Currency value={invoice.opAmount ?? 0} />;
        case "isPaymentDisabled":
            return <Payment invoice={invoice} />;
        default:
            return null;
    }
};

const Payment = ({ invoice }: { invoice: InvoiceWithId }) => {
    const externalWindow = useExternalWindow();

    return (
        <>
            <Button
                color="primary"
                startIcon={<Icon name="payment" data-id="unpaidaccount-pay" />}
                onClick={async () => {
                    const url = await handlePayUnpaidAccountClicked(
                        invoice.tpaCompanyId,
                        invoice.financialAccountancyId,
                        invoice.bookingId,
                    );
                    if (url) {
                        externalWindow.open(url, "TPA", POPUP_WINDOW_FEATURES);
                    }
                }}
                disabled={
                    !companiesStore.selectedCompanyStore?.canPayUnpaidAccount ||
                    !!invoice.isPaymentDisabled ||
                    invoice.lastCompletedPayment != null
                }
            >
                {t("unpaidAccounts.invoices.pay")}
            </Button>
            {invoice.lastCompletedPayment ? (
                <LastCompletedUnpaidAccountPayment lastCompletedPayment={invoice.lastCompletedPayment} />
            ) : null}
            {externalWindow.components}
        </>
    );
};

const baseColumns: GridTableColumn<InvoiceWithId>[] = [
    { column: "receiptDate", label: "unpaidAccounts.invoices.table.label.receiptDate", gridTemplateColumn: "100px" },
    {
        column: "receiptNumber",
        label: "unpaidAccounts.invoices.table.label.receiptNumber",
        gridTemplateColumn: "100px",
    },
    { column: "receiptText", label: "unpaidAccounts.invoices.table.label.receiptText", gridTemplateColumn: "auto" },
    {
        column: "netAmount",
        label: "unpaidAccounts.invoices.table.label.netAmount",
        gridTemplateColumn: "min-content",
        align: "right",
    },
    {
        column: "opAmount",
        label: "unpaidAccounts.invoices.table.label.opAmount",
        gridTemplateColumn: "min-content",
        align: "right",
    },
    {
        column: "isPaymentDisabled",
        gridTemplateColumn: "min-content",
    },
];
const columns: GridTableColumn<InvoiceWithId>[] = [...baseColumns];

const searchFn = (invoice: InvoiceWithId, search: string) => {
    return (
        !!invoice.receiptText?.toLocaleLowerCase().includes(search) ||
        !!invoice.receiptNumber?.toString().includes(search) ||
        false
    );
};
const sortComparer: Comparer<InvoiceWithId> = {
    receiptText: (a, b) => {
        const textA = a.receiptText ?? "";
        const textB = b.receiptText ?? "";
        return textA.localeCompare(textB);
    },
};

const InvoicesTable = observer(function InvoicesTable({ invoices }: { invoices: InvoiceWithId[] }) {
    const tableStore = useTableStore<InvoiceWithId>("InvoicesTable", {
        orderBy: "receiptDate",
        orderDir: "asc",
    });

    const { filterFn, tableFilters } = useFilters(invoices);

    useInMemoryTableStore({
        tableStore,
        items: invoices,
        filterFn,
        searchFn,
        sortComparer,
    });

    return (
        <>
            <TableSearchBarWithAction
                label="unpaidAccounts.invoices.table.search.count"
                placeholder="unpaidAccounts.invoices.table.search.placeholder"
                search={tableStore.search}
                totalCount={tableStore.items.length}
                onChangeSearch={tableStore.handleSearchChange}
                tableFilters={tableFilters}
            />
            <GridTable columns={columns} tableStore={tableStore} renderCell={renderCell} />
        </>
    );
});

function useFilters(invoices: InvoiceWithId[]) {
    const [dateRange, setDateRange] = useState<DateRange | null>(null);

    const { min: minDate, max: maxDate } = useMemo(() => {
        return getMinMaxDate(
            invoices.map(invoice => (invoice.receiptDate ? new Date(invoice.receiptDate) : undefined)),
        );
    }, [invoices]);

    const tableFilters = useTableFilters({
        filters: [
            {
                category: "dateRange",
                entries: [
                    {
                        type: DATE_RANGE_FILTER_TYPE,
                        name: "dateRange",
                        label: t("unpaidAccounts.invoices.table.filters.date"),
                        onChange: range => {
                            setDateRange(range);
                        },
                        minDate: minDate,
                        maxDate: maxDate,
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            if (!selected.dateRange) {
                setDateRange(null);
            }
        },
    });

    const filterFn = useCallback(
        (invoice: InvoiceWithId) => {
            if (dateRange) {
                const date = invoice.receiptDate && new Date(invoice.receiptDate);
                if (!date || (dateRange.from && dateRange.from > date)) {
                    return false;
                }
                if (!date || (dateRange.to && dateRange.to < date)) {
                    return false;
                }
            }

            return true;
        },
        [dateRange],
    );

    return { filterFn, tableFilters };
}
