import { Radio } from "@material-ui/core";
import React from "react";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import {
    checkForOverlappingSignatures,
    getSignatureUserName,
    ISignatureUser,
    ISignatureUserSignature,
} from "../../util/qes";
import { BOX_SHADOW, BOX_SHADOW_LIGHT, customColors } from "../util/Theme";
import { PdfViewer } from "./PdfViewer";
import { User } from "./User";

interface Props {
    loadUrl?: () => Promise<string | undefined>;
    file?: File;
    users: ISignatureUser[];
    /** Whether all `users` need to sign the document, hence need a signature position */
    collectiveRelease: boolean;
    onChange: (signatures: ISignatureUserSignature[], overlapping: boolean) => void;
    hideUsers?: boolean;
}

export const SignaturePositions = ({ loadUrl, file, users, collectiveRelease, onChange, hideUsers }: Props) => {
    const [currentUserIndex, setCurrentUserIndex] = React.useState(0);
    const currentUser: ISignatureUser | undefined = users[currentUserIndex];

    const handleSelectSignaturePosition = (signaturePositions: ISignatureUserSignature[]) => {
        const overlappingSignatures = checkForOverlappingSignatures(collectiveRelease, signaturePositions);

        // CONNECT-502: Check if there are overlapping signatures
        // See e.g.: https://stackoverflow.com/a/306332/677910
        if (collectiveRelease && overlappingSignatures) {
            generalStore.setError(t("error.overlappingSignature"));
        }

        onChange(signaturePositions, overlappingSignatures);

        // Collective release -> advance to next user
        if (collectiveRelease && currentUserIndex < users.length - 1 && signaturePositions.length !== users.length) {
            setCurrentUserIndex(currentUserIndex + 1);
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", marginTop: 24 }}>
            {(!collectiveRelease || currentUser) && (
                <PdfViewer
                    loadUrl={loadUrl}
                    file={file}
                    onSelectSignaturePosition={handleSelectSignaturePosition}
                    currentUser={currentUser && (collectiveRelease || users.length === 1) ? currentUser : undefined}
                    hideUserName={hideUsers}
                />
            )}
            {!hideUsers && collectiveRelease && users.length > 0 && (
                <div style={{ display: "flex", flexDirection: "column", marginLeft: 32 }}>
                    {users.map((signee, index) => {
                        const { firstName, lastName } = getSignatureUserName(signee);
                        return (
                            <div
                                key={signee.id}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 12,
                                    backgroundColor: customColors.white,
                                    padding: 16,
                                    borderRadius: 8,
                                    boxShadow: signee.id === currentUser?.id ? BOX_SHADOW : BOX_SHADOW_LIGHT,
                                    justifyContent: "space-between",
                                    textOverflow: "ellipsis",
                                    maxWidth: 300,
                                }}
                                onClick={() => {
                                    setCurrentUserIndex(index);
                                }}
                            >
                                <User firstName={firstName} lastName={lastName} />
                                <Radio
                                    style={{ marginLeft: 8 }}
                                    color="primary"
                                    checked={signee.id === currentUser?.id}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
