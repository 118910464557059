import { Button, CircularProgress } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { ProjectItemPermission } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { CustomDialog } from "../ui/CustomDialog";
import { CustomInputField } from "../ui/CustomInputField";
import { TpaWhiteButton } from "../ui/Primitives";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { PermissionUsersList } from "./PermissionUsersList";

const initalValues = {
    name: "",
};

type IValues = typeof initalValues;

export const AddFolderDialog = observer(function AddFolderDialog({
    open,
    onClose,
    onSubmit,
    permissionUsers,
}: {
    open: boolean;
    permissionUsers?: ProjectItemPermission[];
    onClose: () => void;
    onSubmit: (name: string) => void;
}) {
    const handleSubmit = (values: IValues) => {
        onSubmit(values.name);
    };

    return (
        <CustomDialog open={open} onClose={onClose}>
            <div style={{ padding: "42px 42px 0 42px" }}>
                <h1>{t("addFolderDialog.title")}</h1>
                <Formik
                    initialValues={initalValues}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required(t("error.requiredField")),
                    })}
                >
                    <Form id="addFolderDialogForm">
                        <Field
                            component={CustomInputField}
                            name="name"
                            data-id="add_folder_dialog_folderName"
                            label={t("addFolderDialog.folderName")}
                            required
                            style={{ marginTop: 24 }}
                        />
                    </Form>
                </Formik>
                <h4>
                    {permissionUsers?.length} {t("common.members")}
                </h4>
            </div>
            {!permissionUsers && (
                <div style={{ textAlign: "center", padding: "16px 0" }}>
                    <CircularProgress color="primary" />
                </div>
            )}
            {permissionUsers && <PermissionUsersList permissionUsers={permissionUsers} />}
            <div style={{ padding: "0 42px 42px 42px" }}>
                {authStore.isTpa && (
                    <p style={{ display: "flex", alignItems: "center" }}>
                        <Icon name="info" style={{ color: customColors.primaryColor }} />
                        <span className="body2" style={{ marginLeft: 4 }}>
                            {t("addFolderDialog.info")}
                        </span>
                    </p>
                )}
                <ResponsiveButtonContainer style={{ marginTop: authStore.isTpa ? 16 : undefined }}>
                    <TpaWhiteButton onClick={onClose} data-id="button_cancel">
                        {t("common.cancel")}
                    </TpaWhiteButton>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        form="addFolderDialogForm"
                        data-id="button_create"
                    >
                        {t("addFolderDialog.button.text.create")}
                    </Button>
                </ResponsiveButtonContainer>
            </div>
        </CustomDialog>
    );
});
