import { AccordionSummary, withStyles } from "@material-ui/core";
import * as React from "react";
import { Status } from "../../stores/Status";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { CustomDivider } from "./CustomDivider";
import { Deadline, IDueDate } from "./Deadline";

const StyledAccordionSummary = withStyles({
    root: {
        padding: 0,
        display: "flex",
    },
    content: {
        minHeight: 48,
        margin: 0,
        "&.Mui-expanded": {
            margin: 0,
        },
    },
})(AccordionSummary);

export const ExpansionCardHeader = (props: {
    expanded: boolean;
    title: string;
    date?: IDueDate;
    status: Status;
    showChevron?: boolean;
}) => {
    const isMobile = React.useContext(MobileContext);

    const deadline = props.expanded && props.date && (
        <Deadline labelId={props.date.labelId} date={props.date.date} overdue={props.date.overdue} />
    );

    return (
        <StyledAccordionSummary>
            <div style={{ width: "100%", paddingLeft: 32 }}>
                <div
                    style={{
                        display: "flex",
                        flexGrow: 1,
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        padding: 24,
                        paddingLeft: 0,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                        }}
                    >
                        <h4 style={{ lineHeight: "24px" }}>{props.title}</h4>
                        {!isMobile && deadline}
                    </div>
                    {!!props.showChevron && (
                        <div style={{ display: "flex", alignItems: "center", marginLeft: 24 }}>
                            <Icon
                                name={props.expanded ? "chevronUp" : "chevronDown"}
                                style={{ color: customColors.greyDarkIcons }}
                            />
                        </div>
                    )}
                </div>
                {isMobile && deadline && <div style={{ marginBottom: 16 }}>{deadline}</div>}
                {props.expanded ? <CustomDivider /> : null}
            </div>
        </StyledAccordionSummary>
    );
};
