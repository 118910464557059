import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { ChatUserInfo } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useChatColleagues = (companyId?: string, inactive?: boolean) => {
    const [users, setUsers] = React.useState<ChatUserInfo[]>([]);

    const load = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const response = inactive
                ? await API.getErstwhileChatUsers({ companyId, userType: "tpa" })
                : await API.getChatColleagues(companyId);
            setUsers(response);
            return response;
        } catch (error) {
            generalStore.setError(t("error.loadTeam"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, inactive]);

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        users,
        reload: load,

        // Call this if you change user data programmatically to trigger a rerender
        refresh() {
            const newUsers = [...users];
            setUsers(newUsers);
        },
    };
};
