import * as React from "react";
import { useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { ReportTypeEnum } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { Module } from "../../../types/models";
import { handlePostTicketError } from "../../../util/helpers";
import { hasUserReleasedReport, isReportReleased } from "../../../util/reports";
import { pushRoute } from "../../app/router/history";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useReport } from "../../hooks/useReport";
import { NavBarBack } from "../../ui/NavBarBack";
import { TicketSiteContent } from "../TicketSiteContent";

export const ModuleReportTicketSite = ({ module }: { module: Module }) => {
    useHideSideBar();
    const store = getModuleStore(module);
    const { reportId } = useParams<{ reportId: string }>();
    const {
        global,
        reportType,
        ticketId: queryTicketId,
    } = useQueryParams<{
        global: boolean;
        reportType: ReportTypeEnum;
        ticketId: string;
    }>();
    const [ticketId, setTicketId] = React.useState(queryTicketId ?? "");

    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = store.selectedSubsidiaryId;

    const { report, canRelease, release, components } = useReport({
        companyId,
        module,
        periodId: store.selectedPeriodId,
        subsidiaryId,
        reportId,
        global: global ?? false,
        reportType,
    });

    // If we didn't get the ticketId in the query, try the report details
    // fixes https://jira.allaboutapps.at/browse/TPAPORTAL-1081
    React.useEffect(() => {
        if (report?.ticket) {
            setTicketId(report.ticket.id);
        }
    }, [report]);

    const document = report?.document;

    const handleRelease =
        report &&
        report.needsRelease &&
        canRelease &&
        !isReportReleased(report) &&
        !hasUserReleasedReport(report.releasers, authStore.userId ?? "")
            ? release
            : undefined;

    const handleCreateTicket = async (message: string): Promise<string | undefined> => {
        let ret = undefined;
        if (companyId && store.selectedPeriodId && subsidiaryId && module && reportId && !ticketId) {
            try {
                generalStore.isLoading = true;
                const ticket = await API.postTicket(companyId, {
                    message: message ?? "",
                    report: {
                        periodId: store.selectedPeriodId,
                        subsidiaryId,
                        moduleType: module,
                        reportId,
                    },
                });
                setTicketId(ticket.id);
                ret = ticket.id;

                // Update badge count
                await companiesStore.selectedCompanyStore?.startPollingBadges();
            } catch (error) {
                handlePostTicketError(error);
            } finally {
                generalStore.isLoading = false;
            }
        }

        return ret;
    };

    const handleDownloadReport = async () => {
        if (!companyId) {
            generalStore.setError(t("error.noCompanySelected"));
            return;
        }

        if (!store.selectedPeriodId || !reportId) {
            return;
        }

        try {
            await API.putDownloadReport({
                companyId,
                module,
                periodId: store.selectedPeriodId,
                subsidiaryId: store.selectedSubsidiaryId,
                reportIds: [reportId],
            });
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.statusCode === HttpStatusCode.Locked_423 &&
                apiError.response.type === "DOCUMENT_LOCKED_TRANSFER_IN_PROGRESS"
            ) {
                generalStore.setError(t("error.documentInProgress"), error);
            } else {
                generalStore.setError(t("error.download"), error);
            }
        }
    };

    return (
        <>
            <NavBarBack
                backTarget={store.routes.ROOT}
                backLabel={store.t("common.accounting")}
                title={t("webhook.tickets.navbar", { document: document?.name })}
                showCancel={false}
            />
            <TicketSiteContent
                companyId={companyId}
                document={document}
                onRelease={handleRelease}
                onCloseTicket={() => {
                    pushRoute(store.routes.ROOT);
                }}
                onCreateTicket={handleCreateTicket}
                ticketId={ticketId}
                onDownloadDocument={handleDownloadReport}
                uploadedAt={report?.uploadedAt}
                uploader={report?.uploader}
            />
            {components}
        </>
    );
};
