import { Button } from "@material-ui/core";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { PatchCompanyPayload } from "../../../network/APITypes";
import { configStore } from "../../../stores/ConfigStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { permissionsIsAdvisor } from "../../../util/permissionHelpers";
import { pushRoute, withParams } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { usePermissions } from "../../hooks/usePermissions";
import { UserTable } from "../../shared/UserTable";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { AdvisorRoutes } from "../router/AdvisorRoutes";

export const AdvisorConfigUsersSite = () => {
    useHideSideBar();

    const { companyId } = useParams<{ companyId?: string }>();
    const { company } = useCompany({ companyId });
    const { permissions, parsed } = usePermissions(companyId);
    const [isSaving, setIsSaving] = React.useState(false);

    React.useEffect(() => {
        configStore.wipe();
    }, []);

    if (!companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    if (!company) {
        // Company not loaded yet -> wait
        return null;
    }

    if (!permissions) {
        // Permissions for this company not loaded yet -> wait
        return null;
    } else if (!permissionsIsAdvisor(permissions)) {
        generalStore.setError(t("error.notAdvisor"));
        // Should not happen, but to make sure we check if not advisor for this company -> go back to overview
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    const handleFinishUserSetup = async () => {
        try {
            setIsSaving(true);
            company.status = "userSetupDone";
            await API.patchCompany(company.id, company as PatchCompanyPayload);
            pushRoute(withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId }));
            setIsSaving(false);
        } catch (err) {
            setIsSaving(false);
            generalStore.setError(t("error.edit"), err);
        }
    };

    return (
        <>
            <NavBarBack
                title={t("config.users")}
                backLabel={t("config.overview")}
                backTarget={withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId })}
                cancelComponent={
                    <Button color="primary" disabled={isSaving} onClick={handleFinishUserSetup} data-id="cancel">
                        {t("config.finishSetup")}
                    </Button>
                }
                companyName={company.name}
            />
            <CenteredContent>
                <UserTable
                    companyId={companyId}
                    companyName={company.name}
                    permissions={permissions}
                    canUpdateUserSettings={parsed?.canUpdateUserSettings}
                />
            </CenteredContent>
        </>
    );
};
