import DOMPurify from "dompurify";
import escapeLodash from "lodash/escape";
import * as platform from "platform";
import React from "react";
import { FileRejection } from "react-dropzone";
import * as Yup from "yup";
import { HrRoutes } from "../components/hr/router/HrRoutes";
import {
    ACCEPTED_FILE_FORMATS,
    ACCEPTED_IMAGE_FORMATS,
    CHAT_SUPPORTED_URL_PROTOCOLS,
    MAX_CHAT_CHARACTERS,
    MFA_CODE_LENGTH,
} from "../config";
import { IMessageIDS, i18n, t } from "../i18n/util";
import { CompanyAddress, CompanyStatus, EmployeeStatus } from "../network/APITypes";
import { getApiError, getValidationError } from "../network/NetworkStapler";
import { HttpStatusCode } from "../network/httpStatusCode";
import { generalStore } from "../stores/GeneralStore";
import { bicCountries } from "./bicCountries";
import { getFileExtension } from "./files";

// deprecated, but kept to avoid >100 changes. Use the `date.ts` functions instead.
export {
    formatDate,
    formatDateTime,
    formatISODateOnly,
    formatMonth,
    formatTime,
    getFormattedISODateOnlyRange,
    getFormattedISODateRange,
    getInitialPeriodRange,
} from "./date";

export function sleep(timeMs: number) {
    return new Promise(resolve => {
        setTimeout(resolve, timeMs);
    });
}

export function callDelayed<T>(callback: () => Promise<T>, timeMs: number): Promise<T> {
    return new Promise(resolve => {
        setTimeout(async () => {
            const ret = await callback();
            resolve(ret);
        }, timeMs);
    });
}

export function getFileIconName(fileName?: string) {
    if (!fileName) {
        return "documentNeutral";
    }

    const ext = getFileExtension(fileName).toLowerCase();

    switch (ext) {
        case "doc":
        case "docx":
        case "odt":
            return "doc";

        case "xls":
        case "xlsx":
        case "ods":
            return "xls";

        case "ppt":
        case "pptx":
        case "odp":
            return "ppt";

        case "camt.053":
        case "xml":
            return "xml";

        case "jpg":
        case "jpeg":
            return "jpg";

        case "png":
            return "png";
        case "csv":
            return "csv";
        case "pdf":
            return "pdf";
        case "eml":
            return "eml";
        case "fib":
            return "fib";
        case "msg":
            return "msg";
        case "url":
            return "open";

        default:
            return "documentNeutral";
    }
}

export function genderToString(value?: string) {
    switch (value) {
        case "male":
            return t("screen.hr.personaldata.male");
        case "female":
            return t("screen.hr.personaldata.female");
        case "diverse":
            return t("screen.hr.personaldata.diverse");
        case "notSpecified":
            return t("screen.hr.personaldata.notSpecified");
        default:
            return t("screen.hr.personaldata.notSpecified");
    }
}

export function workingHoursIntervalToString(value?: string) {
    switch (value) {
        case "day":
            return t("screen.hr.summary.day");
        case "week":
            return t("screen.hr.summary.week");
        case "month":
            return t("screen.hr.summary.month");
        default:
            return t("common.notSpecified");
    }
}

export const missingDataToString = (missingDetails: string[] = []) => {
    const missingData: string[] = [];

    missingDetails?.forEach(missingDetail => {
        if (missingDetail === "personalInformation") {
            missingData.push(t("screen.hr.additionaldata.personaldata"));
        } else if (missingDetail === "compensation") {
            missingData.push(t("screen.hr.additionaldata.salarayAndPosition"));
        } else if (missingDetail === "banking") {
            missingData.push(t("screen.hr.additionaldata.bankDetails"));
        } else if (missingDetail === "workingHours") {
            missingData.push(t("screen.hr.additionaldata.workingTimes"));
        } else if (missingDetail === "furtherInformation") {
            missingData.push(t("screen.hr.additionaldata.additionalInformation"));
        }
    });

    return missingData.join(", ");
};

export const employeeStatusToString = (status?: EmployeeStatus | null) => {
    switch (status) {
        case "inRegistration":
            return t("table.employees.status.inRegistration");
        case "inDeregistration":
            return t("table.employees.status.inDeregistration");
        case "inDataChange":
            return t("table.employees.status.inDataChange");
        case "inRegistrationNotTransferred":
            return t("table.employees.status.inRegistrationNotTransferred");
        case "inDeregistrationNotTransferred":
            return t("table.employees.status.inDeregistrationNotTransferred");
        case "inDataChangeNotTransferred":
            return t("table.employees.status.inDataChangeNotTransferred");
        case "active":
            return t("table.employees.status.active");
        case "inactive":
            return t("table.employees.status.inactive");
        default:
            break;
    }
};

export const employeeStatusToList = (status?: EmployeeStatus | null) => {
    switch (status) {
        case "inRegistration":
            return t("screen.hr.navbar.tabbar.inprogress");
        case "inDeregistration":
            return t("screen.hr.navbar.tabbar.inprogress");
        case "inDataChange":
            return t("screen.hr.navbar.tabbar.inprogress");
        case "inRegistrationNotTransferred":
            return t("screen.hr.navbar.tabbar.inprogress");
        case "inDeregistrationNotTransferred":
            return t("screen.hr.navbar.tabbar.inprogress");
        case "inDataChangeNotTransferred":
            return t("screen.hr.navbar.tabbar.inprogress");
        case "active":
            return t("screen.hr.navbar.tabbar.current");
        case "inactive":
            return t("screen.hr.navbar.tabbar.former");
        default:
            break;
    }
};

export const employeeStatusToListUrl = (status?: EmployeeStatus | null) => {
    switch (status) {
        case "inRegistration":
            return HrRoutes.EMPLOYEES.IN_PROGRESS;
        case "inDeregistration":
            return HrRoutes.EMPLOYEES.IN_PROGRESS;
        case "inDataChange":
            return HrRoutes.EMPLOYEES.IN_PROGRESS;
        case "inRegistrationNotTransferred":
            return HrRoutes.EMPLOYEES.IN_PROGRESS;
        case "inDeregistrationNotTransferred":
            return HrRoutes.EMPLOYEES.IN_PROGRESS;
        case "inDataChangeNotTransferred":
            return HrRoutes.EMPLOYEES.IN_PROGRESS;
        case "active":
            return HrRoutes.EMPLOYEES.CURRENT;
        case "inactive":
            return HrRoutes.EMPLOYEES.FORMER;
        default:
            break;
    }
};

export function toCapitalizedLowerCase(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function toOptions(items: (string | number)[], castToString?: boolean) {
    return items.map(item => {
        const value = castToString ? String(item) : item;

        return {
            value,
            label: value,
        };
    });
}

export function fileRejectionToErrorMessage(failedFile: FileRejection) {
    const hasInvalidType = failedFile.errors.findIndex(error => error.code === "file-invalid-type") >= 0;
    const isTooLarge = failedFile.errors.findIndex(error => error.code === "file-too-large") >= 0;
    const isTooSmall = failedFile.errors.findIndex(error => error.code === "file-too-small") >= 0;
    const isEmpty = failedFile.errors.findIndex(error => error.code === "file-empty") >= 0;
    let errorMessage = t("screen.accounting.records.unknown.error");
    if (hasInvalidType && isTooLarge) {
        errorMessage = t("screen.accounting.records.sizeAndFormat.error");
    } else if (hasInvalidType) {
        errorMessage = t("screen.accounting.records.format.error");
    } else if (isTooLarge) {
        errorMessage = t("screen.accounting.records.size.error");
    } else if (isEmpty || isTooSmall) {
        errorMessage = t("screen.accounting.records.empty");
    }
    return errorMessage;
}

export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.onerror = () => {
            reject(new Error("Unexpected error reading file"));
        };
    });
};

/**
 * Converts a data URI to a blob.
 * See https://github.com/graingert/datauritoblob/blob/master/dataURItoBlob.js.
 */
export function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1]);
    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const dw = new DataView(ab);
    for (let i = 0; i < byteString.length; i++) {
        dw.setUint8(i, byteString.charCodeAt(i));
    }
    // write the ArrayBuffer to a blob, and you're done

    return new Blob([ab], { type: mimeString });
}

export function canvasToBlob(canvas: HTMLCanvasElement, type?: string, maxSizeBytes?: number) {
    const url = canvas.toDataURL(type);
    let blob = dataURItoBlob(url);

    if (type === "image/jpeg" && maxSizeBytes && blob.size > maxSizeBytes) {
        // default quality of canvas.toDataURL() is 0.92
        // Reduce quality in 0.1 steps until we hit target size
        let quality = 0.92;
        while (blob.size > maxSizeBytes && quality > 0) {
            quality -= 0.1;
            const newUrl = canvas.toDataURL(type, quality);
            blob = dataURItoBlob(newUrl);
        }
    }

    return blob;
}

export function isValidBic(value: string) {
    if (!value) {
        return false;
    }

    const capsValue = value.toUpperCase();

    const countryCode = capsValue.slice(4, 6);
    // Check country code length
    if (countryCode.length < 2) {
        return false;
    }

    // Check if correct country code
    if (!bicCountries.includes(countryCode)) {
        return false;
    }

    // Now apply regex check from here: https://gist.github.com/Fedik/f050c65fa6cc93973fc65df9d00357f5
    return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(capsValue.toUpperCase());
}

export function getMinMaxWorkingHours(workingHoursInterval: string) {
    switch (workingHoursInterval) {
        case "day":
            return { minHours: 0, maxHours: 12 };
        case "week":
            return { minHours: 0, maxHours: 60 };
        case "month":
        default:
            return { minHours: 0, maxHours: 240 };
    }
}

export function companyStatusToString(status?: CompanyStatus) {
    if (!status) {
        return t("companyStatus.inactive");
    }
    return t(`companyStatus.${status}`);
}

export function YupLocalizedNumber() {
    return Yup.number()
        .transform((_, value) => {
            if (typeof value === "number") {
                return value;
            }

            if (typeof value !== "string") {
                return null;
            }

            if (value.includes(".")) {
                if (i18n.decimalSeparator === ".") {
                    return Number(value);
                } else {
                    return null;
                }
            }

            return Number(value.replace(i18n.decimalSeparator, "."));
        })
        .typeError(t("screen.hr.additionaldata.number_error"));
}

export function numberToLocaleString(value: string | number) {
    return value.toString().replace(".", i18n.decimalSeparator);
}

function hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

export function stringToRandomColor(str: string) {
    return `hsl(${hashCode(str) % 360}, 100%, 60%)`;
}

export function isHtml(str: string) {
    return str.includes("</") || str.includes("/>");
}

// Helper function because I always forget the regex syntax
export function replaceAll(str: string, search: string, replace: string) {
    return str.replace(new RegExp(search, "g"), replace);
}

// In preparation for WYSIWIG editor -> convert all messages to html
export function escapeHtmlAndConvertLineBreaks(str?: string) {
    if (str) {
        const escaped = escapeLodash(str);
        return escaped.replace(/\n/g, "<br/>");
    }
    return "";
}

/** Convert `<br />` to `\n` */
export function brToLineBreaks(str?: string) {
    if (str) {
        return str.replace(/<br\s*\/?>/g, "\n");
    }
    return "";
}

export function sanitizeHtml(html?: string) {
    if (!html) {
        return "";
    }
    return DOMPurify.sanitize(html, { ADD_ATTR: ["target"] });
}

export function clearHtmlLineBreaks(message: string) {
    return replaceAll(replaceAll(sanitizeHtml(message), "<br>", " "), "<br/>", " ");
}

export function getFilenameFromPath(path: string) {
    if (path.includes("\\")) {
        const elements = path.split("\\");
        return elements[elements.length - 1];
    }

    if (path.includes("/")) {
        const elements = path.split("/");
        return elements[elements.length - 1];
    }

    return path;
}

export const mailValidation = Yup.object().shape({
    username: Yup.string().email().required(),
});

export function getMfaValidation() {
    return Yup.object().shape({
        code: Yup.string() // https://stackoverflow.com/a/60345586
            .required(t("mfa.validation.code.required"))
            .matches(/^[0-9]+$/, t("mfa.validation.code.type"))
            .min(MFA_CODE_LENGTH, t("mfa.validation.code.length"))
            .max(MFA_CODE_LENGTH, t("mfa.validation.code.length")),
    });
}

export function getSupportedFormatsString(acceptedFormats: string) {
    const prefix = t("documentUpload.type.file.formats");
    return `${prefix}: ${acceptedFormats.split(",").join(", ").toUpperCase()}`;
}

export function getSupportedImageFormatsString() {
    return getSupportedFormatsString(ACCEPTED_IMAGE_FORMATS);
}

export function getSupportedFileFormatsString() {
    return getSupportedFormatsString(ACCEPTED_FILE_FORMATS);
}

export function isIos(): boolean {
    /* eslint-disable @typescript-eslint/no-deprecated */
    // Hack from here: https://stackoverflow.com/a/57924983/677910
    const iPadOS = navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
    if (iPadOS) {
        return true;
    }

    if (platform.os) {
        return platform.os.family === "iOS";
    } else {
        const iDevices = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"];

        if (navigator.platform) {
            return iDevices.includes(navigator.platform);
        }

        return false;
    }
    /* eslint-enable @typescript-eslint/no-deprecated */
}

const regexChatMessageTextTooLong = /text in formData should be at most (\d+) chars long/;
const regexChatMessageMessageTooLong = /message in body should be at most (\d+) chars long/;

export function handleChatMessageError(unknownError: unknown, errorMessage?: string) {
    const error = getApiError(unknownError);
    const validationError = getValidationError(error);
    const message = errorMessage ?? t("error.send");
    if (error?.statusCode === HttpStatusCode.BadRequest_400 && validationError?.validationErrors?.length) {
        const instance = validationError.validationErrors.find(
            error => error.key === "text" || error.key === "message",
        );
        const matches =
            instance?.error.match(regexChatMessageTextTooLong) ?? instance?.error.match(regexChatMessageMessageTooLong);
        if (matches) {
            generalStore.setError(t("error.tooManyCharacters", { maxCharacters: matches[1] ?? MAX_CHAT_CHARACTERS }));
        } else {
            generalStore.setError(message, error);
        }
    } else {
        generalStore.setError(message, unknownError);
    }
}

export function handlePostTicketError(error: unknown) {
    handleChatMessageError(error, t("error.postTicket"));
}

export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

// At least one lower case, one upper case, one number, one special character
export function isValidPassword(password: string) {
    return passwordRegex.test(password);
}

export function getCountryName(countryCode: string) {
    return t(`country.${countryCode.toLowerCase()}` as IMessageIDS);
}

export function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    (outer.style as unknown as Record<string, unknown>).msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode?.removeChild(outer);

    return scrollbarWidth;
}

/**
 * Inserts the value of each `elements` key if the key is found within `text`.
 *
 * NOTE: Make sure to add a `key` to each element.
 *
 * @example
 * ```tsx
 * const result = replaceTextWithElements("foo [bar] baz", { "[bar]": <b>BAR!</b> })
 * // results == ["foo ", <b>BAR!</b>, " baz"];
 * ```
 */
export function replaceTextWithElements(text: string, elements: Record<string, React.ReactNode>): React.ReactNode[] {
    const texts: React.ReactNode[] = [text];
    Object.keys(elements).forEach(key => {
        const index = texts.findIndex(t => typeof t === "string" && t.includes(key));
        if (index < 0) {
            return;
        }
        const t = texts[index] as string;
        const parts = t.split(key);
        texts.splice(index, 1, parts[0], elements[key], parts[1]);
    });
    return texts;
}

/**
 * Takes an ISO 4217 currency code and returns nothing for EUR (as it's unnecessary to display) or the currency code itself.
 */
export function toCurrency(currency: string): string {
    if (!currency || currency === "EUR") {
        return ""; // EUR is the standard and therefore unnecessary to show
    }
    return currency;
}

export function formatCompanyAddress(address: CompanyAddress): string {
    let s = "";
    if (address.street) {
        s += address.street;
    }
    if (address.zipCode || address.city) {
        s += `, ${address.zipCode ?? ""} ${address.city ?? ""}`;
    }
    return s.trim();
}

/**
 * Adds a protocol to URLs in a message if they don't already have one.
 *
 * @example
 * ```ts
 * const message = "Check out this link: <a href="www.example.com">www.example.com</a>";
 * const fixedMessage = fixUrlsInMessage(message);
 * // fixedMessage == "Check out this link: <a href="http://www.example.com">www.example.com</a>";
 * ```
 */
export const fixUrlsInMessage = (message: string) => {
    // Define a regular expression pattern to match <a> tags with URLs.
    // Contains two capture groups:
    // 1. The attributes of the <a> tag that come before the href attribute.
    // 2. The URL inside the href attribute.
    const anchorPattern = /<a\s+([^>]*?)href=["']([^"']+)["']/g;

    // Use the replace method to modify the matched <a> tags.
    // The callback function receives the matched substring as the first argument,
    // followed by the captured groups as the next arguments.
    const fixedMessage = message.replace(anchorPattern, (match, attributes: string, url: string) => {
        if (
            CHAT_SUPPORTED_URL_PROTOCOLS.some(protocol => {
                return url.startsWith(protocol);
            }) ||
            !url.includes(".") // Allow relative URLs within the webapp
        ) {
            // URL already has a protocol, so leave it as is.
            return match;
        } else {
            // URL is missing a protocol, add "http://" and keep other attributes.
            return `<a ${attributes}href="http://${url}"`;
        }
    });

    return fixedMessage;
};
