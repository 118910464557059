import { RootRef } from "@material-ui/core";
import * as React from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { ACCEPTED_FILE_FORMATS, MAX_UPLOAD_SIZE } from "../../config";
import { t } from "../../i18n/util";
import { isIos } from "../../util/helpers";
import { DataUploadImage } from "../util/Images";

const UploadOverlay = () => (
    <div
        style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <DataUploadImage style={{ marginBottom: 32 }} />
        <h2>{t("button.upload")} ...</h2>
    </div>
);

export const useChatDropzone = (
    disabled: boolean,
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void,
) => {
    const [showUploadOverlay, setShowUploadOverlay] = React.useState(false);
    const [fileRejections, setFileRejections] = React.useState<FileRejection[]>([]);
    const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);

    const onDragOver = () => {
        setShowUploadOverlay(true);
    };

    const onDragLeave = () => {
        setShowUploadOverlay(false);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: isIos() ? undefined : ACCEPTED_FILE_FORMATS, // ios blocks msg, eml, fib
        noKeyboard: true,
        minSize: 1,
        maxSize: MAX_UPLOAD_SIZE,
        disabled,
        noClick: true,
        onDragOver,
        onDragLeave,
        onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
            setShowUploadOverlay(false);
            setFileRejections(fileRejections);
            setAcceptedFiles(acceptedFiles);
            onDrop(acceptedFiles, fileRejections, event);
        },
    });
    const { ref, ...rootProps } = getRootProps();

    const Container = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-deprecated
        (props: { children: React.ReactNode }) => <RootRef rootRef={ref}>{props.children}</RootRef>,
        [ref],
    );

    const input = (
        <>
            <input {...getInputProps()} />
            {showUploadOverlay && <UploadOverlay />}
        </>
    );
    return {
        Container,
        input,
        rootProps,
        open,
        acceptedFiles,
        clearAcceptedFiles() {
            setAcceptedFiles([]);
        },
        fileRejections,
        clearFileRejections() {
            setFileRejections([]);
        },
    };
};
