import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetSubsidiaryInsuranceCarriersResponse } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { generalStore } from "../../stores/GeneralStore";

export const useInsuranceCarriersForSubsidiary = (companyId?: string, subsidiaryId?: string) => {
    const [carriers, setCarriers] = React.useState<GetSubsidiaryInsuranceCarriersResponse[]>([]);

    React.useEffect(() => {
        const load = async () => {
            if (!companyId || !subsidiaryId || !authStore.canEditEmployees(subsidiaryId)) {
                return;
            }
            try {
                const response = await API.getInsuranceCarriersForSubsidiary(companyId, subsidiaryId);
                setCarriers(response);
            } catch (err) {
                generalStore.setError(t("error.loadInsuranceCarriers"), err);
            }
        };

        load();
    }, [companyId, subsidiaryId]);

    return carriers;
};
