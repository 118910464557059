import { IMessageIDS, t } from "../i18n/util";
import { GetRecordTypesResponse, PermissionsRecordTypes, RecordType } from "../network/APITypes";
import { Module } from "../types/models";
import { debug } from "./debug";

// the HR record types are hard coded on the backend, see `internal/data/recordtypes_fixtures.go`
const hrRecordTypeIdToMessageId: Record<string, IMessageIDS> = {
    "773f4d03-bd69-4041-90e3-c5412d266fb2": "hr.recordTypes.names.nonWorkingTimes",
    "c928425e-3d0d-45a1-9339-17bc7d93a435": "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments",
    "08f3423b-e89a-4750-93f3-65e781d84b6f": "hr.recordTypes.names.benefitsInKindCar",
    "f7d27648-2153-4f76-a26a-b15e5d2994fc": "hr.recordTypes.names.travelExpenseReport",
    "479daf76-2b64-48d0-8fce-c7227b640b2b": "hr.recordTypes.names.overtimeAndBonusPayments",
    "06220692-bcbc-48fc-aaca-3cd55cee4073": "hr.recordTypes.names.mealDeductions",
    "303893cd-f0ee-4dee-b612-139c393e8bb9": "hr.recordTypes.names.supplementaryDocuments",
    "afd6ae2d-07d8-4f02-87a5-6dcc5544e4e4": "hr.recordTypes.names.timeRecords",
};

export function getRecordTypeName(
    module: Module,
    recordType: RecordType | GetRecordTypesResponse | PermissionsRecordTypes,
): string {
    if (module === "accounting") {
        return recordType.name ?? "";
    }

    const id: IMessageIDS | undefined = hrRecordTypeIdToMessageId[recordType.id];
    if (!id) {
        debug.error("No translation found for record type:", recordType);
        return recordType.name ?? "";
    }
    return t(id);
}
