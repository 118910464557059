import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";
import { t } from "../../../i18n/util";
import { companiesStore } from "../../../stores/CompaniesStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { Module } from "../../../types/models";
import { debug } from "../../../util/debug";
import { pushRoute, withParams } from "../../app/router/history";
import { useRecord } from "../../hooks/useRecord";
import { refreshRecordTypes } from "../../hooks/useRefreshRecordTypes";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { ModuleRecordsDetails } from "../ModuleRecordsDetails";

export const ModuleRecordsDetailsUploadSite = observer(function ModuleRecordsDetailsUploadSite({
    module,
}: {
    module: Module;
}) {
    useHideSideBar();

    const store = getModuleStore(module);

    const { recordTypeId, recordId } = useParams<{ recordTypeId: string; recordId: string }>();

    const recordType = store.getRecordTypeForId(recordTypeId);

    // No booking type -> get out
    React.useEffect(() => {
        if (!recordType || !recordTypeId) {
            pushRoute(store.routes.ROOT);
        }
    }, [recordType, recordTypeId, store.routes.ROOT]);

    // No record -> get out
    const recordIndex = store.uploadedRecords.findIndex(record => record.recordId === recordId);
    React.useEffect(() => {
        if (recordTypeId && (!recordId || recordIndex < 0)) {
            pushRoute(withParams(store.routes.RECORDS.LIST, { recordTypeId }));
        }
    }, [recordTypeId, recordId, recordIndex, store.routes.RECORDS.LIST]);

    const record = useRecord(
        module,
        companiesStore.selectedCompanyId,
        store.selectedSubsidiaryId,
        store.selectedPeriodId,
        recordTypeId,
        recordId,
    );

    const onCancel = async () => {
        await refreshRecordTypes(module);
        pushRoute(withParams(store.routes.RECORDS.LIST, { recordTypeId: recordTypeId ?? "" }));
        store.uploadedRecords = []; // clearing the records when cancel the process
    };

    const nextRecord = recordIndex < store.uploadedRecords.length - 1 ? store.uploadedRecords[recordIndex + 1] : null;

    const onSubmit = () => {
        if (nextRecord) {
            debug.log("### going to nextRecord", toJS(nextRecord), recordIndex, store.uploadedRecords.length);
            pushRoute(
                withParams(store.routes.RECORDS.DETAILS_UPLOAD, {
                    recordTypeId: recordTypeId ?? "",
                    recordId: nextRecord.recordId ?? "",
                }),
            );
        } else {
            debug.log("### no next record -> canceling");
            onCancel();
        }
    };

    const navBar = (
        <NavBarBack
            title={t("records.details.upload.header", {
                recordType: recordType ? store.getRecordTypeName(recordType) : "",
            })}
            moduleInfo={module}
            onCancel={onCancel}
        />
    );

    if (!recordType || !recordTypeId || !record?.document || !recordId) {
        return navBar;
    }

    return (
        <>
            {navBar}

            <CenteredContent>
                <SiteContent>
                    <ModuleRecordsDetails
                        module={module}
                        current={recordIndex + 1}
                        total={store.uploadedRecords.length}
                        document={record.document}
                        recordId={recordId}
                        recordTypeId={recordTypeId}
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                    />
                </SiteContent>
            </CenteredContent>
        </>
    );
});
