import { Button, Checkbox, Collapse, DialogActions, FormControlLabel, RadioGroup } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    EldaProtocol,
    GetSubsidiaryInsuranceCarriersResponse,
    PutEmployeePreRegistrationTransferPayload,
} from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { IEmployee } from "../../../types/models";
import { pushRoute } from "../../app/router/history";
import { useInsuranceCarriersForSubsidiary } from "../../hooks/useInsuranceCarriersForSubsidiary";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { CustomDatePicker } from "../../ui/CustomDatePicker";
import { CustomDialog, CustomDialogContent } from "../../ui/CustomDialog";
import { CustomInputField } from "../../ui/CustomInputField";
import { CustomSelect } from "../../ui/CustomSelect";
import { TpaRadio, TpaWhiteButton } from "../../ui/Primitives";
import { EldaTransferSuccessDialog } from "../HrDialogs";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

type TransferType = "tpa" | "direct";

export const useDisclaimer = (employee: IEmployee | null) => {
    const [openDisclaimer, setOpenDisclaimer] = React.useState(false);

    const [transferType, setTransferType] = React.useState<TransferType>("tpa");
    const [casualEmployment, setCasualEmployment] = React.useState(false);
    const carriers = useInsuranceCarriersForSubsidiary(companiesStore.selectedCompanyId, hrStore.selectedSubsidiaryId);
    const [eldaProtocols, setEldaProtocols] = React.useState<EldaProtocol[]>();

    const isATCompany = companiesStore.selectedCompany?.countryCode === "AT";

    const handleOpenDisclaimer = () => {
        setOpenDisclaimer(true);
    };
    const handleCloseDisclaimer = () => {
        setOpenDisclaimer(false);
    };

    const handleOpenEldaTransferSuccess = (eldaProtocols: EldaProtocol[]) => {
        setEldaProtocols(eldaProtocols);
    };

    const handleCloseEldaTransferSuccess = () => {
        setEldaProtocols(undefined);
        pushRoute(HrRoutes.EMPLOYEES.IN_PROGRESS);
    };

    const employeeFirstName = employee?.firstName ?? "";
    const employeeLastName = employee?.lastName ?? "";

    const name = t("screen.hr.summary.name", {
        firstName: employeeFirstName,
        lastName: employeeLastName,
    });
    const successMessage =
        transferType === "direct"
            ? t("screen.hr.summary.transferEmployeeDialog.success.subtitle.elda", { name })
            : t("screen.hr.summary.transferEmployeeDialog.success.subtitle", { name });

    const transferSuccess = useSuccessDialog({
        title: t("dialog.transfer.success.header"),
        message: successMessage,
        onClose() {
            pushRoute(HrRoutes.EMPLOYEES.IN_PROGRESS);
        },
    });

    const handleTransferSuccess = (eldaProtocols?: EldaProtocol[]) => {
        if (eldaProtocols && eldaProtocols.length > 0) {
            handleOpenEldaTransferSuccess(eldaProtocols);
        } else {
            transferSuccess.openDialog();
        }
    };

    const handleTransfer = async (directTransfer?: PutEmployeePreRegistrationTransferPayload) => {
        if (!employee) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const response = await API.transferPreRegistration(
                companiesStore.selectedCompanyId,
                hrStore.selectedSubsidiaryId,
                employee,
                directTransfer ?? {},
            );

            handleTransferSuccess(response.eldaProtocols);
        } catch (error) {
            generalStore.setError(t(directTransfer ? "error.transferEmployeeDirect" : "error.transferEmployee"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const handleSubmit = async (values: { date: string; carrier: string; location: string; zip: string }) => {
        // debug.log("### submit", values);

        if (transferType === "tpa") {
            await handleTransfer();
        } else {
            const index = parseInt(values.carrier, 10);
            const carrier = carriers[index];
            if (carrier) {
                await handleTransfer({
                    insuranceCarrier: {
                        carrierNumber: carrier.carrierNumber,
                        accountNumber: carrier.accountNumber,
                    },
                    casualEmployment: casualEmployment
                        ? {
                              date: moment(values.date).format("YYYY-MM-DD"),
                              location: values.location,
                              zipCode: values.zip,
                          }
                        : undefined,
                });
            }
        }

        handleCloseDisclaimer();
    };

    // Validation only needed for direct transfer
    let schema = undefined;
    if (transferType === "direct") {
        let shape: Record<string, Yup.Schema> = {
            carrier: Yup.number().integer().required(t("error.requiredField")),
        };

        if (casualEmployment) {
            // Casual employment needs additional data
            shape = {
                ...shape,
                date: Yup.date().required(t("error.requiredField")).typeError(t("common.dateValidation")).nullable(),
                location: Yup.string().required(t("error.requiredField")),
                zip: Yup.string().required(t("error.requiredField")),
            };
        }

        schema = Yup.object().shape(shape);
    }

    const component = (
        <>
            <CustomDialog open={openDisclaimer} onClose={handleCloseDisclaimer} showCloseIcon>
                <CustomDialogContent>
                    <h1 style={{ hyphens: "auto" }}>{t("hr.disclaimer.title")}</h1>
                    <p style={{ marginTop: 16 }}>{t("hr.disclaimer.subtitle")}</p>
                    <RadioGroup
                        name="transferType"
                        value={transferType}
                        onChange={event => {
                            setTransferType(event.target.value as TransferType);
                        }}
                        style={{ marginTop: 24 }}
                    >
                        <TpaRadio
                            value="tpa"
                            title={t("hr.disclaimer.transfer.tpa")}
                            subtitle={t("hr.disclaimer.transfer.tpa.subtitle")}
                        />
                        <TpaRadio
                            value="direct"
                            title={t("hr.disclaimer.transfer.direct")}
                            subtitle={t("hr.disclaimer.transfer.direct.subtitle")}
                            style={{ marginTop: 16 }}
                            disabled={!isATCompany || employee?.status !== "inRegistrationNotTransferred"}
                        />
                    </RadioGroup>

                    <Formik
                        initialValues={{
                            date: new Date().toISOString(),
                            carrier: "0",
                            location: "",
                            zip: "",
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={schema}
                        validateOnBlur
                        validateOnChange
                    >
                        <Form>
                            <Collapse in={transferType === "direct"} style={{ marginTop: 32 }}>
                                <p>{t("hr.disclaimer.additionalData")}</p>
                                <Field
                                    style={{ marginTop: 16 }}
                                    component={CustomSelect}
                                    name="carrier"
                                    label={t("hr.insuranceCarrier")}
                                    options={carriers.map(
                                        (carrier: GetSubsidiaryInsuranceCarriersResponse, index: number) => ({
                                            value: index.toString(),
                                            label: `${carrier.name} (${carrier.accountNumber})`,
                                        }),
                                    )}
                                    required
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(event, checked) => {
                                                setCasualEmployment(checked);
                                            }}
                                            color="primary"
                                            checked={casualEmployment}
                                        />
                                    }
                                    label={t("hr.casualEmployment")}
                                />

                                <Collapse in={casualEmployment} style={{ marginTop: 16 }}>
                                    <Field
                                        component={CustomDatePicker}
                                        label={t("hr.disclaimer.employmentDate")}
                                        name="date"
                                        required
                                    />
                                    <Field
                                        component={CustomInputField}
                                        name="location"
                                        label={t("screen.hr.personaldata.city")}
                                        disabled={!casualEmployment}
                                        required
                                    />
                                    <Field
                                        component={CustomInputField}
                                        name="zip"
                                        label={t("screen.hr.personaldata.zipCode")}
                                        disabled={!casualEmployment}
                                        required
                                    />
                                </Collapse>
                            </Collapse>

                            <DialogActions style={{ padding: "48px 0 0 0" }}>
                                <TpaWhiteButton style={{ marginRight: 16 }} onClick={handleCloseDisclaimer}>
                                    {t("common.cancel")}
                                </TpaWhiteButton>
                                <Button color="primary" variant="contained" type="submit">
                                    {t("button.transfer")}
                                </Button>
                            </DialogActions>
                        </Form>
                    </Formik>
                </CustomDialogContent>
            </CustomDialog>
            {transferSuccess.dialog}
            <EldaTransferSuccessDialog
                open={!!eldaProtocols}
                onClose={handleCloseEldaTransferSuccess}
                caption={successMessage}
                eldaProtocols={eldaProtocols}
            />
        </>
    );

    return {
        open() {
            if (employee?.status !== "inRegistrationNotTransferred") {
                // Not registration -> TPA transfer
                handleTransfer();
            } else {
                // Only registrations allow ELDA transfer
                handleOpenDisclaimer();
            }
        },
        component,
    };
};
