import { observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";
import { t } from "../../../i18n/util";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { Module } from "../../../types/models";
import { pushRoute, withParams } from "../../app/router/history";
import { useRecord } from "../../hooks/useRecord";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { ModuleRecordsDetails } from "../ModuleRecordsDetails";

export const ModuleRecordsDetailsEditSite = observer(function ModuleRecordsDetailsEditSite({
    module,
}: {
    module: Module;
}) {
    useHideSideBar();

    const store = getModuleStore(module);

    const { recordTypeId, recordId } = useParams<{ recordTypeId: string; recordId: string }>();
    const recordType = store.getRecordTypeForId(recordTypeId);

    React.useEffect(() => {
        if (!recordType || !recordTypeId) {
            pushRoute(store.routes.ROOT);
        }
    }, [recordType, recordTypeId, store.routes.ROOT]);

    const record = useRecord(
        module,
        companiesStore.selectedCompanyId,
        store.selectedSubsidiaryId,
        store.selectedPeriodId,
        recordTypeId,
        recordId,
    );

    React.useEffect(() => {
        if (!record && recordTypeId && !generalStore.isLoading) {
            pushRoute(withParams(store.routes.RECORDS.LIST, { recordTypeId }));
        }
    }, [recordTypeId, record, store.routes.RECORDS.LIST]);

    const onCancel = () => {
        if (recordTypeId) {
            pushRoute(withParams(store.routes.RECORDS.LIST, { recordTypeId }));
        } else {
            pushRoute(store.routes.RECORDS.ROOT);
        }
    };

    const navBar = <NavBarBack title={t("accounting.details.edit.header")} moduleInfo={module} onCancel={onCancel} />;

    if (!recordType || !recordTypeId || !record?.document) {
        return navBar;
    }

    return (
        <>
            {navBar}
            <CenteredContent>
                <SiteContent>
                    <ModuleRecordsDetails
                        module={module}
                        document={record.document}
                        recordId={record.id}
                        recordTypeId={recordTypeId}
                        onSubmit={onCancel}
                        updatedAt={record.updatedAt}
                        costCenter={record.costCenter}
                        comment={record.comment}
                    />
                </SiteContent>
            </CenteredContent>
        </>
    );
});
