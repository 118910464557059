import { useCallback, useMemo, useState } from "react";
import { t } from "../../../i18n/util";
import { useTableFilters } from "../../hooks/useTableFilters";
import {
    DATE_RANGE_FILTER_TYPE,
    DROPDOWN_FILTER_TYPE,
    DateRange,
    ITableFilterOption,
    ITableFilters,
    NUMBER_RANGE_FILTER_TYPE,
    NumberRange,
} from "../../ui/filter/types";
import { EnhancedAccountTransaction } from "./useAccountTransactions";

export function useResultsAccountSheetTableFilters(accountTransactions: EnhancedAccountTransaction[]) {
    const [postingSymbol, setPostingSymbol] = useState<string | null>(null);
    const [documentDateRange, setDocumentDateRange] = useState<DateRange | null>(null);
    const [amountRange, setAmountRange] = useState<NumberRange | null>(null);

    const postingSymbolOptions = useMemo(() => {
        const postingSymbols = new Map<string, ITableFilterOption>();
        accountTransactions.forEach(accountTransaction => {
            const { postingSymbol } = accountTransaction;
            if (postingSymbol && !postingSymbols.has(postingSymbol)) {
                postingSymbols.set(postingSymbol, { label: postingSymbol, value: postingSymbol });
            }
        });
        return Array.from(postingSymbols.values());
    }, [accountTransactions]);

    const { minDate, maxDate } = useMemo(() => {
        let minDate: Date | undefined, maxDate: Date | undefined;
        accountTransactions.forEach(at => {
            const d = new Date(at.documentDate);
            if (!minDate || minDate > d) {
                minDate = d;
            }
            if (!maxDate || maxDate < d) {
                maxDate = d;
            }
        });
        return { minDate, maxDate };
    }, [accountTransactions]);

    const tableFilters = useTableFilters({
        filters: [
            {
                category: "postingSymbol",
                entries: [
                    {
                        type: DROPDOWN_FILTER_TYPE,
                        name: "postingSymbol",
                        label: t("results.accountSheet.filters.postingSymbol"),
                        options: postingSymbolOptions,
                        onChange: postingSymbol => {
                            setPostingSymbol(postingSymbol);
                        },
                    },
                ],
            },
            {
                category: "documentDateRange",
                entries: [
                    {
                        type: DATE_RANGE_FILTER_TYPE,
                        name: "documentDateRange",
                        label: t("results.accountSheet.filters.documentDate"),
                        onChange: range => {
                            setDocumentDateRange(range);
                        },
                        minDate: minDate,
                        maxDate: maxDate,
                    },
                ],
            },
            {
                category: "amountRange",
                entries: [
                    {
                        type: NUMBER_RANGE_FILTER_TYPE,
                        name: "amountRange",
                        label: t("results.accountSheet.filters.amount"),
                        onChange: range => {
                            setAmountRange(range);
                        },
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            if (!selected.postingSymbol) {
                setPostingSymbol(null);
            }
            if (!selected.documentDateRange) {
                setDocumentDateRange(null);
            }
            if (!selected.amountRange) {
                setAmountRange(null);
            }
        },
    });

    const filterFn = useCallback(
        (at: EnhancedAccountTransaction) => {
            if (postingSymbol !== null && at.postingSymbol !== postingSymbol) {
                return false;
            }

            const documentDate = new Date(at.documentDate);
            if (documentDateRange?.from && documentDateRange.from > documentDate) {
                return false;
            }
            if (documentDateRange?.to && documentDateRange.to < documentDate) {
                return false;
            }

            const amount = at.amount.toNumber();
            if (typeof amountRange?.from === "number" && amountRange.from > amount) {
                return false;
            }
            if (typeof amountRange?.to === "number" && amountRange.to < amount) {
                return false;
            }

            return true;
        },
        [amountRange, documentDateRange, postingSymbol],
    );

    return { filterFn, tableFilters };
}
