import * as React from "react";
import { getFileIconName } from "../../util/helpers";
import { Icon } from "./Icon";

type Props = { name?: string } & Omit<React.ComponentProps<typeof Icon>, "name">;

/**
 * Displays an icon based on the extension of the (file)name
 */
export const FileIcon = React.forwardRef<SVGSVGElement, Props>(function FileIcon({ name, ...rest }, ref) {
    const iconName = getFileIconName(name);
    return <Icon ref={ref} name={iconName} {...rest} />;
});
