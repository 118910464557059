import * as React from "react";
import { GetResponsibleUsersResponse } from "../../network/APITypes";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { t } from "../../i18n/util";
import { companiesStore } from "../../stores/CompaniesStore";
import { authStore } from "../../stores/AuthStore";

export const useResponsibleUsers = ({
    companyId,
    preloaded,
    includeBadges,
}: { companyId?: string; preloaded?: GetResponsibleUsersResponse; includeBadges?: boolean } = {}) => {
    const [responsibleUsers, setResponsibleUsers] = React.useState<GetResponsibleUsersResponse>(
        preloaded ?? {
            partners: [],
            responsible: [],
        },
    );

    const load = React.useCallback(async () => {
        if (preloaded) {
            return;
        }

        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const response = await API.getResponsibleUsers(companyId, !!includeBadges);

            // Filter responsible users who are also partners -> partner role takes precedence
            response.responsible = response.responsible.filter(
                user => !response.partners.find(partner => partner.id === user.id),
            );

            setResponsibleUsers(response);
        } catch (error) {
            generalStore.setError(t("error.loadResponsibleUsers"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, includeBadges, preloaded]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { users: responsibleUsers, reload: load };
};
