import { Button } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { observer } from "mobx-react";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { CompanyData, CompanyImages, ToggleCockpitKpiActive } from "../../advisor/sites/AdvisorConfigCompanyDataSite";
import { CenteredContent } from "../../ui/CenteredContent";
import { SiteContent } from "../../ui/SiteContent";
import { DIALOG_WIDTH } from "../../util/Theme";

export const SettingsCompanyDataSite = observer(function SettingsCompanyDataSite() {
    const canEdit = authStore.canEditCompanySettings;

    if (!companiesStore.selectedCompany) {
        return null;
    }

    return (
        <CenteredContent>
            <SiteContent style={{ maxWidth: DIALOG_WIDTH, paddingTop: 40 }}>
                <div
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 8 }}
                >
                    <h3 style={{ hyphens: "auto", wordBreak: "break-all" }}>{t("config.companyData.title")}</h3>
                    <Button
                        color="primary"
                        onClick={() => companiesStore.reloadCompany(true)}
                        endIcon={<RefreshIcon />}
                        style={{ marginLeft: 16 }}
                    >
                        {t("common.refresh")}
                    </Button>
                </div>
                <CompanyData style={{ marginTop: 40 }} company={companiesStore.selectedCompany} />
                <ToggleCockpitKpiActive
                    company={companiesStore.selectedCompany}
                    canEdit={canEdit}
                    style={{ marginTop: 40 }}
                    onIsCheckedChanged={() => {
                        companiesStore.reloadCompany();
                    }}
                />
                <CompanyImages
                    style={{ marginTop: 40 }}
                    company={companiesStore.selectedCompany}
                    onImagesChanged={() => {
                        companiesStore.reloadCompany();
                    }}
                    disabled={!authStore.canEditCompanyImages}
                />
            </SiteContent>
        </CenteredContent>
    );
});
