import { Button, CircularProgress } from "@material-ui/core";
import { useState } from "react";
import styled from "styled-components";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { GetProjectsCompaniesResponseItem, PostProjectPayload } from "../../../network/APITypes";
import { APIClientStatusCodeError, getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { companiesStore } from "../../../stores/CompaniesStore";
import { IProjectUser } from "../../../types/models";
import { withQuery } from "../../app/router/history";
import { ConfirmInternationalDialog } from "../../hooks/useConfirmInternationalProject";
import { useQueryParams } from "../../hooks/useQueryParams";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { RouterLinkButton, TableLabel } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { Icon } from "../../util/Icon";
import { BOX_SHADOW_LIGHT, DIALOG_WIDTH, customColors } from "../../util/Theme";
import { ProjectsRoutes } from "../router/ProjectsRoutes";
import { ProjectConfig } from "./types";

interface Props {
    project: ProjectConfig;
    projectUsers: IProjectUser[];
    projectCompanies: GetProjectsCompaniesResponseItem[];
    confirmInternational: ConfirmInternationalDialog;
}

type CompanyStatus = { type: "idle" } | { type: "creating"; error?: APIClientStatusCodeError } | { type: "done" };

interface Company extends GetProjectsCompaniesResponseItem {
    status: CompanyStatus;
}

export const ProjectsDataProjectFinishSite = ({
    project,
    projectUsers,
    projectCompanies,
    confirmInternational,
}: Props) => {
    const queryParams = useQueryParams<Record<string, unknown>>();

    const company = companiesStore.selectedCompany;
    const [companies, setCompanies] = useState<Company[]>(() => {
        const companies = company ? [company, ...projectCompanies] : projectCompanies;
        return companies.map(company => ({ ...company, status: { type: "idle" } }));
    });

    const [status, setStatus] = useState<"idle" | "creating" | "done">("idle");

    const handleStart = async () => {
        setStatus("creating");

        const body: PostProjectPayload = {
            name: "",
            ...project,
            international: confirmInternational.isInternational,
            users: projectUsers,
        };

        const progress = (company: Company, status: CompanyStatus) => {
            setCompanies(companies => {
                return companies.map(c => (c.id === company.id ? { ...c, status } : c));
            });
        };

        for (const company of companies) {
            progress(company, { type: "creating" });
            try {
                await API.postProject(company.id, body);
            } catch (error) {
                progress(company, { type: "creating", error: getApiError(error) ?? undefined });
                continue;
            }
            progress(company, { type: "done" });
        }

        setStatus("done");
    };

    return (
        <>
            <NavBarBack
                title={t("projects.new.createNewProject")}
                companyName={companiesStore.selectedCompany?.name}
                backLabel={t("projects.new")}
                backTarget={withQuery(ProjectsRoutes.NEW_PROJECT, queryParams)}
            />
            <CenteredContent>
                <SiteContent style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "100%", maxWidth: DIALOG_WIDTH }}>
                        <p style={{ marginTop: 16, marginBottom: 16, fontSize: 12 }}>
                            {t("projects.create.bulk.description")}
                        </p>
                        <Button
                            style={{ marginBottom: 16 }}
                            color="primary"
                            variant="contained"
                            onClick={handleStart}
                            disabled={status !== "idle"}
                            startIcon={status === "creating" ? <CircularProgress size={16} /> : null}
                        >
                            {t("projects.create.bulk.start")}
                        </Button>
                        <Companies companies={companies} showIcons={status !== "idle"} />
                        <div style={{ marginTop: 32, textAlign: "right" }}>
                            <RouterLinkButton
                                color="primary"
                                variant="contained"
                                to={ProjectsRoutes.ACTIVE.ROOT}
                                disabled={status !== "done"}
                            >
                                {t("projects.create.bulk.backToProjects")}
                            </RouterLinkButton>
                        </div>
                    </div>
                </SiteContent>
            </CenteredContent>
        </>
    );
};

interface CompaniesProps {
    companies: Company[];
    showIcons: boolean;
}

const CompanyRoot = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: 40px;
    grid-template-areas:
        "icon name"
        ". error";
    // similar style as "StyledTableRow"
    background: white;
    box-shadow: ${BOX_SHADOW_LIGHT};
    border-radius: 4px;
    margin-bottom: 8px;

    & > * {
        display: flex;
        align-items: center;
        padding: 8px 4px;
    }
`;

const Companies = ({ companies, showIcons }: CompaniesProps) => {
    return (
        <>
            {companies.map(company => {
                const translatedError = translateError(company.status);

                let icon;
                if (!showIcons) {
                    icon = null;
                } else if (company.status.type === "done") {
                    icon = <Icon name="checkmark" style={{ color: customColors.primaryColor }} />;
                } else if (translatedError) {
                    icon = <Icon name="close" style={{ color: customColors.error }} />;
                } else if (company.status.type === "idle") {
                    icon = <Icon name="hours" />;
                } else {
                    icon = <CircularProgress size={24} />;
                }
                return (
                    <CompanyRoot key={company.id}>
                        <div style={{ gridArea: "icon" }}>{icon}</div>
                        <div style={{ gridArea: "name" }}>
                            <TableLabel style={{ maxWidth: 450 }} tooltip={company.name}>
                                {company.name}
                            </TableLabel>
                        </div>
                        {translatedError ? (
                            <div style={{ gridArea: "error", color: customColors.error, paddingTop: 0 }}>
                                {translatedError}
                            </div>
                        ) : null}
                    </CompanyRoot>
                );
            })}
        </>
    );
};

const translateError = (status: CompanyStatus) => {
    if (!("error" in status) || !status.error) {
        return null;
    }

    const error = status.error;
    const errorType = error.response.type;

    if (error.statusCode === HttpStatusCode.Conflict_409) {
        if (errorType === "NO_REFRESH_TOKEN_FOUND") {
            return t("error.msTokenExpired");
        } else if (errorType === "PROJECT_NAME_EXISTS_CONFLICT") {
            return t("error.create.alreadyExists.project");
        } else if (errorType === "AZURE_CANT_INVITE_DOMAIN_EMAIL") {
            return t("error.noMsAccountAvailable");
        }
    }
    return t("error.general");
};
