import { Routes } from "../../app/router/Routes";

const prefix = (route: string) => Routes.COCKPIT + route;

export const CockpitRoutes = {
    ROOT: prefix("/"),
    TICKET_OVERVIEW: prefix("/tickets"),
    DUE_DATES: prefix("/due-dates"),
    NEWS_LIST: prefix("/news/list"),
    NEWS_LIST_ARTICLE: prefix("/news/list/:newsId"),
    NEWS_ARTICLE: prefix("/news/:newsId"),
    EVENTS_LIST: prefix("/events/list"),
    EVENTS_LIST_ARTICLE: prefix("/events/list/:eventId"),
    EVENT_ARTICLE: prefix("/events/:eventId"),
};
