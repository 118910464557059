import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import debounce from "lodash/debounce";
import * as React from "react";
import { COMPANY_LIMIT, SEARCH_DEBOUNCE_MS } from "../../config";
import { t } from "../../i18n/util";
import { useCompanies } from "../hooks/useCompanies";
import { useCompany } from "../hooks/useCompany";

export interface ICompanyListItem {
    name: string;
    id: string;
}
interface ICompanyOptions {
    company: ICompanyListItem | null;
    action: string | null;
}

export const AutocompleteCompanies = ({
    companyId,
    onChangeCompany,
    style,
    disabled,
}: {
    companyId?: string;
    onChangeCompany: (company: ICompanyListItem | null) => void;
    style?: React.CSSProperties;
    disabled?: boolean;
}) => {
    const [search, setSearch] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState<ICompanyOptions | null>(null);

    // Load companies
    const { company: initialCompany } = useCompany({ companyId });
    const companiesResponse = useCompanies({ limit: COMPANY_LIMIT, search: search });
    let remainingCount = companiesResponse.remainingCount;
    let companies: ICompanyListItem[];
    if (!initialCompany || companiesResponse.companies.find(company => company.id === companyId) || !!search) {
        companies = companiesResponse.companies ?? [];
    } else {
        companies = [{ name: initialCompany.name, id: initialCompany.id }].concat(companiesResponse.companies);
        remainingCount = Math.max(0, remainingCount - 1);
    }
    const options = companies.map<ICompanyOptions>(company => ({ company: company, action: null }));

    React.useEffect(() => {
        if (!selected && companyId && companies) {
            const company = companies.find(company => company.id === companyId);
            if (company) {
                setSelected({ company, action: null });
            }
        }
    }, [companies, companyId, selected]);

    const onChangeSearch = debounce((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }, SEARCH_DEBOUNCE_MS);

    return (
        <Autocomplete
            style={style}
            getOptionLabel={option =>
                option.company?.name ?? t("companyList.remainingCompanies", { remainingCompanies: remainingCount })
            }
            getOptionSelected={(option, value) => option.company?.id === value.company?.id}
            options={options}
            noOptionsText={t(search ? "common.noSearchResults" : "webhook.noCompanies")}
            disableCloseOnSelect
            onChange={(_, option) => {
                if (option.action === "next") {
                    companiesResponse.loadNext();
                    setSelected(null); //Damit +X Weitere nicht Selected wird
                } else {
                    onChangeCompany(option.company);
                    setSelected(option);
                    setOpen(false);
                    setSearch("");
                }
            }}
            onBlur={() => {
                setSearch("");
            }}
            value={selected ?? { action: null, company: null }}
            disableClearable
            renderInput={params => (
                <TextField
                    {...params}
                    label={t("common.company")}
                    variant="outlined"
                    onChange={onChangeSearch}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                    }}
                    onClick={() => {
                        setOpen(!disabled);
                    }}
                />
            )}
            filterOptions={(companies, params) => {
                if (remainingCount > 0) {
                    companies.push({ company: null, action: "next" });
                }
                return companies;
            }}
            open={open}
            onClose={reason => {
                setOpen(false);
            }}
            openOnFocus
            disabled={disabled}
        />
    );
};
