import { IconButton, Tab, Tabs } from "@material-ui/core";
import last from "lodash/last";
import take from "lodash/take";
import { observer } from "mobx-react";
import * as React from "react";
import { Route, useLocation, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { ProjectItem, ProjectItemPermission } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute, withParams } from "../../app/router/history";
import { ClipboardHook } from "../../hooks/useCopyToClipBoard";
import { useProject } from "../../hooks/useProject";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../../ui/ContextMenu";
import { NavBarBack } from "../../ui/NavBarBack";
import { Icon } from "../../util/Icon";
import { ProjectPermissionAvatars } from "../ProjectPermissionAvatars";
import { ProjectsRoutes } from "../router/ProjectsRoutes";
import { ProjectsContentSite } from "./ProjectsContentSite";
import { ProjectsReleasesSite } from "./ProjectsReleasesSite";
import { ProjectsTicketSite } from "./ProjectsTicketSite";

export const ProjectsSite = observer(function ProjectsSite({ clipboard }: { clipboard: ClipboardHook }) {
    const { projectId, projectFolderId, projectItemId, projectItemDraftId } = useParams<{
        projectId?: string;
        projectFolderId?: string;
        projectItemId?: string;
        projectItemDraftId?: string;
    }>();
    const { pathname } = useLocation();
    const [page, setPage] = React.useState<string | null>("fileTree");
    const [path, setPath] = React.useState<string[]>([]);
    const [parentId, setParentId] = React.useState<string | undefined>(undefined);
    const [parentPermissionUsers, setParentPermissionUsers] = React.useState<ProjectItemPermission[] | undefined>(
        undefined,
    );
    const navBarContextMenu = useContextMenu<{ id: string }>();
    const [lastProjectFolderId, setLastProjectFolderId] = React.useState(projectFolderId);

    const companyId = companiesStore.selectedCompanyId;

    const { project, reload } = useProject(companyId, projectId);

    // TPAPORTAL_1779: Reload project and knoedels to be in sync after draft action
    const reloadProject = async () => {
        await reload();
        await companiesStore.selectedCompanyStore?.startPollingBadges();
    };

    React.useEffect(() => {
        if (projectId && pathname.startsWith(withParams(ProjectsRoutes.ACTIVE.PROJECT, { projectId }))) {
            setLastProjectFolderId(projectFolderId);
        }
    }, [projectId, projectFolderId, pathname]);

    const isProjectReleasesSite = projectId && pathname === withParams(ProjectsRoutes.RELEASES, { projectId });

    const isProjectItemReleasesSite =
        projectId &&
        projectItemId &&
        pathname === withParams(ProjectsRoutes.RELEASES_PROJECT_ITEM, { projectId, projectItemId });

    const isProjectItemTicketSite =
        projectId &&
        projectItemId &&
        projectItemDraftId &&
        pathname ===
            withParams(ProjectsRoutes.RELEASES_PROJECT_ITEM_TICKET, { projectId, projectItemId, projectItemDraftId });

    const isProjectTicketSiteFromReleases =
        projectId &&
        projectItemId &&
        projectItemDraftId &&
        pathname === withParams(ProjectsRoutes.RELEASES_TICKET, { projectId, projectItemId, projectItemDraftId });

    const isProjectFileTreeSite = projectId && pathname === withParams(ProjectsRoutes.ACTIVE.PROJECT, { projectId });

    const isProjectFolderFileTreeSite =
        projectId &&
        lastProjectFolderId &&
        pathname ===
            withParams(ProjectsRoutes.ACTIVE.PROJECT_FOLDER, {
                projectId,
                projectFolderId: lastProjectFolderId,
            });

    React.useEffect(() => {
        const loadProjectItem = async () => {
            if (projectId && companyId && projectItemId) {
                try {
                    generalStore.isLoading = true;
                    const projectItemResponse = await API.getProjectItems(companyId, projectId, projectItemId);
                    setPath(projectItemResponse.path ?? []);
                    setParentId(projectItemResponse.parentId);
                } catch (error) {
                    // TPAPORTAL-2109: If item was deleted for a ticket -> ignore error
                    if (!isProjectItemTicketSite && !isProjectTicketSiteFromReleases) {
                        generalStore.setError(t("error.general"), error);
                    }
                } finally {
                    generalStore.isLoading = false;
                }
            }
        };

        loadProjectItem();
    }, [projectId, companyId, projectItemId, isProjectItemTicketSite, isProjectTicketSiteFromReleases]);

    React.useEffect(() => {
        if (isProjectFileTreeSite || isProjectFolderFileTreeSite) {
            setPage("fileTree");
        } else if (
            isProjectReleasesSite ||
            isProjectItemReleasesSite ||
            isProjectItemTicketSite ||
            isProjectTicketSiteFromReleases
        ) {
            setPage("releases");
        }
    }, [
        isProjectFileTreeSite,
        isProjectFolderFileTreeSite,
        isProjectItemReleasesSite,
        isProjectReleasesSite,
        isProjectItemTicketSite,
        isProjectTicketSiteFromReleases,
    ]);

    // Load parent permissions to display the avatars in nav bar
    React.useEffect(() => {
        const loadParentPermissions = async () => {
            if (companyId && projectId && page === "fileTree") {
                setParentPermissionUsers(undefined);
                try {
                    const parentPermissions = await API.getProjectItemPermissions(
                        companyId,
                        projectId,
                        projectFolderId,
                    );
                    setParentPermissionUsers(parentPermissions.users);
                } catch (error) {
                    // TODO add errormessage add empty array to hide loading spinner?
                }
            }
        };

        loadParentPermissions();
    }, [companyId, projectFolderId, projectId, page]);

    const handleChangeFolder = React.useCallback((path: string[], parentId?: string) => {
        setPath(path);
        setParentId(parentId);
    }, []);

    if (!companyId) {
        // No company selected -> get out
        return null;
    }

    if (!projectId || !project) {
        // No project selected -> get out
        return null;
    }

    const handlePageChange = (_: React.ChangeEvent<unknown>, page: string | null) => {
        setPage(page);
    };

    // Check if folder or project is empty
    const isItemEmpty = async (item: ProjectItem) => {
        let empty = false;
        if (item.isFolder) {
            const projectItemsResponse = await API.getProjectItems(
                companyId,
                projectId,
                item.id === projectId ? undefined : item.id,
            );

            empty = !!projectItemsResponse.items && projectItemsResponse.items.length === 0;
        }
        return empty;
    };

    const downloadItem = async (itemId: string) => {
        try {
            generalStore.isLoading = true;

            // Download folder from navbar
            const item = {
                id: itemId,
                name: last(path) ?? "",
                isFolder: true,
                createdAt: new Date(),
                updatedAt: new Date(),
            };

            const empty = await isItemEmpty(item);
            if (!empty) {
                // Not empty -> download
                if (item.id === projectId) {
                    await API.putDownloadProject(companyId, projectId);
                } else {
                    await API.putDownloadProject(companyId, projectId, [itemId]);
                }
            } else {
                // Empty -> display error
                if (item.id === projectId) {
                    generalStore.setError(t("error.projectIsEmpty"));
                } else {
                    generalStore.setError(t("error.folderIsEmpty"));
                }
            }
        } catch (err) {
            generalStore.setError(t("error.download"), err);
        } finally {
            generalStore.isLoading = false;
        }
    };

    let navBarContextMenuItems: ContextMenuItem[] = [];
    if (navBarContextMenu.contextElement) {
        const item = navBarContextMenu.contextElement;
        navBarContextMenuItems = [
            {
                title: t("menu.download"),
                icon: "download",
                onClick: async () => {
                    navBarContextMenu.close();
                    await downloadItem(item.id);
                },
                "data-id": "context_menu_download",
            },
        ];
    }

    const getBackTarget = () => {
        if (path.length === 1) {
            return ProjectsRoutes.ROOT;
        }

        if (isProjectItemTicketSite && projectItemId) {
            return withParams(ProjectsRoutes.RELEASES_PROJECT_ITEM, { projectId, projectItemId });
        }

        if (isProjectTicketSiteFromReleases) {
            return withParams(ProjectsRoutes.RELEASES, { projectId });
        }

        return parentId === projectId
            ? withParams(ProjectsRoutes.ACTIVE.PROJECT, { projectId })
            : withParams(ProjectsRoutes.ACTIVE.PROJECT_FOLDER, { projectId, projectFolderId: parentId ?? "" });
    };

    const getBackLabel = () => {
        if (isProjectTicketSiteFromReleases) {
            return t("projects.navbar.overview", { path: ` / ${project.name}` });
        }

        if (isProjectItemTicketSite) {
            return t("projects.navbar.overview", {
                path: ` / ${take(path, path.length - 1).join(" / ")} / ${t("projects.navbar.releases.heading", {
                    fileName: last(path),
                })}`,
            });
        }

        return t("projects.navbar.overview", {
            path:
                path.length > 1 && (page === "fileTree" || isProjectItemReleasesSite || isProjectItemTicketSite)
                    ? ` / ${take(path, path.length - 1).join(" / ")}`
                    : "",
        });
    };

    const getTitle = () => {
        if (isProjectFileTreeSite || isProjectFolderFileTreeSite) {
            return project && !projectFolderId
                ? t("projects.navbar.project.heading", { projectName: project.name ?? path[0] })
                : last(path);
        } else if (isProjectReleasesSite) {
            return t("projects.navbar.project.heading", { projectName: project.name });
        } else if (isProjectItemReleasesSite) {
            return t("projects.navbar.releases.heading", { fileName: last(path) });
        } else if (isProjectItemTicketSite || isProjectTicketSiteFromReleases) {
            return t("projects.navbar.ticket.heading", { fileName: last(path) });
        }
    };

    const releasesTabCount = (project.openDrafts ?? 0) + (project.ticketCount ?? 0);

    return (
        <>
            <NavBarBack
                title={getTitle()}
                backLabel={getBackLabel()}
                onCancel={() => {
                    // DUMMY FUNCTION - because otherwise cancelcomponent is not shown
                }}
                onBack={() => {
                    if (!isProjectItemTicketSite && !isProjectTicketSiteFromReleases) {
                        // optimistic update path
                        setPath(path.slice(0, path.length - 1));
                    }
                    pushRoute(getBackTarget());
                }}
                tabs={
                    isProjectItemReleasesSite ||
                    isProjectItemTicketSite ||
                    isProjectTicketSiteFromReleases ? undefined : (
                        <Tabs
                            value={page}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handlePageChange}
                            variant="scrollable"
                            scrollButtons="off"
                        >
                            <Tab
                                label={t("projects.navbar.project.fileTree")}
                                value="fileTree"
                                onClick={() => {
                                    if (lastProjectFolderId) {
                                        pushRoute(
                                            withParams(ProjectsRoutes.ACTIVE.PROJECT_FOLDER, {
                                                projectId,
                                                projectFolderId: lastProjectFolderId,
                                            }),
                                        );
                                    } else {
                                        pushRoute(withParams(ProjectsRoutes.ACTIVE.PROJECT, { projectId }));
                                    }
                                }}
                                data-id="project_tab_fileTree"
                            />
                            <Tab
                                label={`${t("projects.navbar.project.releases")}${
                                    releasesTabCount ? ` (${releasesTabCount})` : ""
                                }`}
                                value="releases"
                                onClick={() => {
                                    pushRoute(withParams(ProjectsRoutes.RELEASES, { projectId }));
                                }}
                                data-id="project_tab_releases"
                            />
                        </Tabs>
                    )
                }
                showCancel={page === "fileTree"}
                cancelComponent={
                    page === "fileTree" ? (
                        <>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ProjectPermissionAvatars
                                    permissionUsers={parentPermissionUsers}
                                    align="right"
                                    variant="async"
                                />
                                <IconButton
                                    data-id="context_menu_navbar"
                                    style={{ padding: 0, marginLeft: 24 }}
                                    onClick={event => {
                                        navBarContextMenu.open(event, { id: projectFolderId ?? projectId });
                                    }}
                                >
                                    <Icon name="more" />
                                </IconButton>
                            </div>
                            <ContextMenu
                                data-id="context_menu"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                config={navBarContextMenu}
                                items={navBarContextMenuItems}
                            />
                        </>
                    ) : undefined
                }
            />
            <Route exact path={[ProjectsRoutes.ACTIVE.PROJECT, ProjectsRoutes.ACTIVE.PROJECT_FOLDER]}>
                <ProjectsContentSite
                    key={`${projectId}_${projectFolderId ?? ""}`}
                    path={path}
                    parentPermissionUsers={parentPermissionUsers}
                    onChangeFolder={handleChangeFolder}
                    reloadProject={reloadProject}
                    clipboard={clipboard}
                />
            </Route>
            <Route exact path={[ProjectsRoutes.RELEASES, ProjectsRoutes.RELEASES_PROJECT_ITEM]}>
                <ProjectsReleasesSite reloadProject={reloadProject} onOpenTicketSite={handleChangeFolder} />
            </Route>
            <Route exact path={[ProjectsRoutes.RELEASES_TICKET, ProjectsRoutes.RELEASES_PROJECT_ITEM_TICKET]}>
                <ProjectsTicketSite />
            </Route>
        </>
    );
});
