import * as React from "react";
import ic_document_upload_error from "../../assets/icons/ic_document_upload_error.svg";
import ic_microsoft from "../../assets/icons/ic_microsoft.svg";
import im_dataupload from "../../assets/images/im_dataupload.svg";
import im_emptystate_documents from "../../assets/images/im_emptystate_documents.svg";
import im_error from "../../assets/images/im_error.svg";
import logo_tpa from "../../assets/images/logo_tpa.svg";
import tpa_connect_landscape from "../../assets/images/tpa_connect_landscape.svg";
import tpa_connect_portrait from "../../assets/images/tpa_connect_portrait.svg";

// Filter
import ic_filter_active from "../../assets/icons/ic_filter_active.svg";
import ic_filter_inactive from "../../assets/icons/ic_filter_inactive.svg";

export { ic_document_upload_error, ic_filter_active, ic_filter_inactive, ic_microsoft };

export const ImageLogo = (props: { className?: string; style?: React.CSSProperties }) => (
    <img data-id="logo_tpa" alt="Logo" src={logo_tpa} className={props.className} style={props.style} />
);

export const TpaConnectPortrait = (props: { className?: string; style?: React.CSSProperties }) => (
    <img
        data-id="logo_tpa_connect"
        alt="TPA Connect Logo"
        src={tpa_connect_portrait}
        className={props.className}
        style={props.style}
    />
);

export const TpaConnectLandscape = (props: { className?: string; style?: React.CSSProperties }) => (
    <img
        data-id="logo_tpa_connect"
        alt="TPA Connect Logo"
        src={tpa_connect_landscape}
        className={props.className}
        style={props.style}
    />
);

export const EmptyDocumentsImage = (props: { style?: React.CSSProperties }) => (
    <img data-id="emptystate_documents" alt="empty_documents" src={im_emptystate_documents} style={props.style} />
);

export const DataUploadImage = (props: { style?: React.CSSProperties }) => (
    <img
        data-id="dataupload_image"
        alt="dataupload_image"
        width={props.style?.width ?? 160}
        height={props.style?.height ?? 97}
        src={im_dataupload}
        style={props.style}
    />
);

export const ErrorImage = (props: { style?: React.CSSProperties }) => (
    <img data-id="im_error" alt="im_error" src={im_error} style={props.style} />
);
