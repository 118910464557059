import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import * as React from "react";
import { t } from "../../i18n/util";
import { TermsOfUseSignee } from "../../network/APITypes";
import { useConfirmationDialog } from "../hooks/useConfirmationDialog";
import { useSuccessDialog } from "../hooks/useSuccessDialog";
import { Icon } from "../util/Icon";

export const SigneeList = ({
    onResendEmail,
    onDelete,
    signees,
    fileName,
    disabled,
    disableResendMail,
}: {
    onResendEmail: (id: string) => Promise<void>;
    onDelete: (email: string) => void;
    signees?: TermsOfUseSignee[];
    fileName?: string;
    disabled: boolean;
    disableResendMail?: boolean;
}) => {
    const [signeeEmail, setSigneeEmail] = React.useState("");

    const handleDeleteSignee = () => {
        onDelete(signeeEmail);
    };

    const handleResendEmail = (id: string) => {
        onResendEmail(id);
        successDialog.openDialog();
    };

    const deleteEmailDialog = useConfirmationDialog({
        message: t("config.terms.deleteEmailDialog.message"),
        confirmLabel: t("config.terms.deleteEmailDialog.confirmLabel"),
        onConfirm: () => {
            handleDeleteSignee();
        },
        title: t("config.terms.deleteEmailDialog.title"),
    });

    const successDialog = useSuccessDialog({
        title: t("config.terms.resendEmail.header"),
        message: t("config.terms.resendEmail.message"),
    });

    if (signees?.length === 0) {
        return <h4>{t("config.terms.noSigneesAvailable")}</h4>;
    }

    return (
        <List>
            {signees?.map(signee => {
                return (
                    <ListItem key={signee.email} divider disableGutters>
                        {signee.signedAt ? (
                            <Tooltip title={`${t("config.terms.signedVersion")} ${fileName ?? ""}`}>
                                <Icon name="checkmark" />
                            </Tooltip>
                        ) : (
                            <HourglassEmptyIcon />
                        )}
                        <ListItemText primary={signee.email} style={{ paddingLeft: 16 }} />
                        {!disabled && (
                            <ListItemSecondaryAction>
                                {!!signee.id && !signee.signedAt && (
                                    <Tooltip title={t("config.terms.resendMail")} placement="top">
                                        <IconButton
                                            disabled={disableResendMail}
                                            edge="end"
                                            aria-label="mail"
                                            onClick={() => {
                                                handleResendEmail(signee.id);
                                            }}
                                        >
                                            <Icon name="mail" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title={t("config.terms.removeEmail")} placement="top">
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            setSigneeEmail(signee.email);
                                            deleteEmailDialog.open();
                                        }}
                                    >
                                        <Icon name="delete" />
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                );
            })}
            {deleteEmailDialog.dialog}
            {successDialog.dialog}
        </List>
    );
};
