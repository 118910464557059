import { Button, Checkbox, TableBody } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { GetCompaniesResponseItem } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { statusColors } from "../../../stores/Status";
import { useTableStore } from "../../../stores/TableStore";
import { companyStatusToString, formatDate } from "../../../util/helpers";
import { pushRoute, withParams } from "../../app/router/history";
import { EmptyState } from "../../ui/EmptyState";
import {
    TableLabel,
    TableRowButton,
    TpaTable,
    TpaTableCell,
    TpaTableContainer,
    TpaTableRow,
} from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { Icon, IIconNames } from "../../util/Icon";
import { AdvisorRoutes } from "../router/AdvisorRoutes";
import { BulkState } from "../util/bulk";

function getNotConfiguredStatus(company: GetCompaniesResponseItem) {
    if (company.status === "inactive") {
        return {
            color: statusColors.open.color,
            background: statusColors.open.background,
            icon: "companyConfiguration" as IIconNames,
        };
    } else {
        const status = company.status === "userSetupDone" ? "closed" : "inProgress";
        const icon: IIconNames =
            company.status === "hrSetupDone" || company.status === "userSetupDone"
                ? "userConfiguration"
                : "companyConfiguration";
        return {
            color: statusColors[status].color,
            background: statusColors[status].background,
            icon: icon,
        };
    }
}

export const AdvisorCompaniesInactiveSite = observer(function AdvisorCompaniesInactiveSite() {
    const tableStore = useTableStore<GetCompaniesResponseItem>("AdvisorCompaniesInactiveSite", {
        orderBy: "name",
        orderDir: "asc",
    });

    const companies = tableStore.items;

    const handleRowClick = (companyId: string) => {
        pushRoute(withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId }));
    };

    const handleBulkActionClick = () => {
        const companies = tableStore.getAllSelectedItems();
        if (companies.length === 1) {
            handleRowClick(companies[0].id);
            return;
        }

        const state: BulkState = {
            companies: companies.map(({ id, name }) => ({ id, name })).sort((c1, c2) => c1.name.localeCompare(c2.name)),
            coverImage: undefined,
            logoImage: undefined,
            documentVersion: undefined,
            signees: undefined,
        };
        pushRoute(AdvisorRoutes.CONFIG.BULK.DATA, { state });
    };

    const { tableParams } = tableStore;
    const reloadCompanies = React.useCallback(
        async (noCache?: boolean) => {
            try {
                generalStore.isLoading = true;
                if (noCache) {
                    authStore.loadUserInfo(true); // necessary to sync permissions to get the correct inactive companies the user is allowed to configure
                }
                const response = await API.getCompanies({ ...tableParams, toConfigure: true }, noCache);
                tableStore.totalCount = response.total;
                tableStore.items = response.companies ?? [];
            } catch (err) {
                generalStore.setError(t("error.loadCompany"), err);
            } finally {
                generalStore.isLoading = false;
            }
        },
        [tableParams, tableStore],
    );

    React.useEffect(() => {
        reloadCompanies();
    }, [reloadCompanies]);

    const headerFields: ITableHeaderConfig[] = [
        { column: "logo" },
        { column: "name", label: "table.label.companyName" },
        { column: "updatedAt", label: "table.label.updatedAt" },
        { column: "status", label: "table.label.status", sort: false },
        { column: "configure" },
    ];

    const tableBody = (
        <TableBody>
            {companies.map((company, index) => {
                return (
                    <TpaTableRow
                        key={company.id}
                        onClick={() => {
                            handleRowClick(company.id);
                        }}
                        role="button"
                        style={{ cursor: "pointer" }}
                        data-id={`company_${index}`}
                    >
                        <TpaTableCell padding="checkbox">
                            <Checkbox
                                data-id={`company_checkbox_${index}`}
                                onClick={event => {
                                    // do not call `onClick` on `TpaTableRow`
                                    event.stopPropagation();
                                }}
                                onChange={(event, checked) => {
                                    event.stopPropagation();
                                    tableStore.toggleSelection(company);
                                }}
                                color="primary"
                                checked={tableStore.isSelected(company)}
                            />
                        </TpaTableCell>
                        {headerFields.map(hf => {
                            let label = "";
                            if (hf.column === "name") {
                                label = company.name;
                            } else if (hf.column === "updatedAt") {
                                label = company.status === "inactive" ? "" : formatDate(company.updatedAt);
                            } else if (hf.column === "status") {
                                label = companyStatusToString(company.status);
                            }

                            const { color, background, icon } = getNotConfiguredStatus(company);
                            if (hf.column === "logo") {
                                return (
                                    <TpaTableCell
                                        key={hf.column}
                                        style={{
                                            backgroundColor: background,
                                            textAlign: "center",
                                            width: 56,
                                        }}
                                    >
                                        <Icon name={icon} style={{ color: color }} />
                                    </TpaTableCell>
                                );
                            } else if (hf.column === "configure") {
                                return (
                                    <TpaTableCell key={hf.column} style={{ textAlign: "right" }}>
                                        <TableRowButton color="primary">{t("button.configure")}</TableRowButton>
                                    </TpaTableCell>
                                );
                            } else {
                                return (
                                    <TpaTableCell key={hf.column}>
                                        <TableLabel style={{ maxWidth: 430 }}>{label}</TableLabel>
                                    </TpaTableCell>
                                );
                            }
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    return (
        <>
            <SiteContent>
                <div style={{ textAlign: "right", marginBottom: 8 }}>
                    <Button color="primary" onClick={() => reloadCompanies(true)} endIcon={<RefreshIcon />}>
                        {t("common.refresh")}
                    </Button>
                </div>
                <TableSearchBarWithAction
                    label="search.caption.numCompanies"
                    labelSelected="search.caption.numSelected"
                    placeholder="search.placeholder.companies"
                    select={tableStore}
                    search={tableStore.search}
                    totalCount={tableStore.totalCount}
                    onChangeSearch={tableStore.handleSearchChange}
                    bulkAction={
                        <Button
                            onClick={handleBulkActionClick}
                            variant="contained"
                            color="primary"
                            style={{
                                marginLeft: 16,
                            }}
                        >
                            {t("config.bulk.button.configure")}
                        </Button>
                    }
                />
                <TpaTableContainer>
                    <TpaTable>
                        <TableHeader headerFields={headerFields} tableStore={tableStore} select={tableStore} />
                        {tableBody}
                    </TpaTable>
                </TpaTableContainer>

                <tableStore.Pagination />
            </SiteContent>
            {tableStore.getIsNoResultState(generalStore.isLoading) && (
                <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
            )}
        </>
    );
});
