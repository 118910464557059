import * as React from "react";
import ReactSwipeableViews from "react-swipeable-views";

export const SwipeableViews = ({
    currentSwipeIndex,
    onChangeSwipeIndex,
    elementWidth,
    gap = 16,
    children,
    style,
    className,
}: {
    currentSwipeIndex?: number;
    onChangeSwipeIndex?: (index: number) => void;
    elementWidth: number;
    gap?: number;
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
}) => (
    <div style={{ position: "relative", ...style }} className={className}>
        <div style={{ width: "100%", overflow: "hidden" }}>
            <ReactSwipeableViews
                disableLazyLoading
                enableMouseEvents
                index={currentSwipeIndex}
                onChangeIndex={onChangeSwipeIndex}
                style={{ width: elementWidth + gap, overflow: "visible" }}
                slideStyle={{ width: elementWidth, marginRight: gap }}
            >
                {children}
            </ReactSwipeableViews>
        </div>
        <div
            style={{
                position: "absolute",
                right: 0,
                top: 0,
                bottom: 0,
                width: 8,
                background: "linear-gradient(270deg, #F6F8F9 50%, rgba(246, 248, 249, 0) 100%)",
            }}
        />
    </div>
);
