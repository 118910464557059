import { observer } from "mobx-react";
import * as React from "react";
import { CENTERED_CONTENT_RIGHT_OFFSET } from "../../../config";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { clearTableStore } from "../../../stores/TableStore";
import { IRecordType, Module } from "../../../types/models";
import { formatDate } from "../../../util/helpers";
import { AccountingNavBar } from "../../accounting/AccountingNavBar";
import { pushRoute, withParams } from "../../app/router/history";
import { MISSING_REQUEST_DATA, useAPI } from "../../hooks/useAPI";
import { useGlobalQuickActionsOffset } from "../../hooks/useGlobalQuickActionsOffset";
import { useWindowSize } from "../../hooks/useWindowSize";
import { HrNavBar } from "../../hr/HrNavBar";
import { hrStore } from "../../hr/HrStore";
import { HrRoutes } from "../../hr/router/HrRoutes";
import { CenteredContent } from "../../ui/CenteredContent";
import { FolderRow } from "../../ui/FolderRow";
import { SiteContent } from "../../ui/SiteContent";
import { RecordTypesList } from "../RecordTypeList";
import { useTransferRecords } from "./hooks/useTransferRecords";

export const ModuleRecordsSite = observer(function ModuleRecordsSite({ module }: { module: Module }) {
    const store = getModuleStore(module);

    const [rightWidth, setRightWidth] = React.useState(0);

    const resize = (width: number) => {
        setRightWidth(width);
    };

    const handleSelectRecordType = (recordType: IRecordType) => {
        const recordTypeId = recordType.id;
        clearTableStore();

        if (!!recordType.ticketCount && GLOBAL_FEATURES.tickets) {
            pushRoute(withParams(store.routes.RECORDS.TICKETS, { recordTypeId }));
        } else {
            pushRoute(withParams(store.routes.RECORDS.LIST, { recordTypeId }));
        }
    };

    const { button, dialogs } = useTransferRecords({
        store,
        disabled: !store.canReleaseAllSubsidiaryRecords(),
        transfer: async () => {
            await store.transferRecords();
        },
    });

    const size = useWindowSize(true);

    useGlobalQuickActionsOffset(button && !!size.width && size.width < CENTERED_CONTENT_RIGHT_OFFSET + 85 ? 70 : 0);

    return (
        <>
            {module === "accounting" && <AccountingNavBar />}
            {module === "hr" && <HrNavBar />}
            <CenteredContent getRightWidth={resize}>
                <SiteContent style={{ paddingBottom: 72 }}>
                    <RecordTypesList module={module} onRecordTypeSelected={handleSelectRecordType} />
                    {module === "hr" && authStore.canReadEmployees(store.selectedSubsidiaryId) ? (
                        <EmployeesDocumentsRecordType />
                    ) : null}
                    {button && (
                        <div
                            className="mui-fixed"
                            style={{
                                position: "fixed",
                                bottom: 16,
                                right: rightWidth + 10,
                            }}
                        >
                            {button}
                        </div>
                    )}
                </SiteContent>
            </CenteredContent>
            {dialogs}
        </>
    );
});

const EmployeesDocumentsRecordType = observer(function EmployeesDocumentsRecordType() {
    const companyId = companiesStore.selectedCompanyId;
    const period = hrStore.selectedPeriod;
    const subsidiaryId = hrStore.selectedSubsidiaryId;

    const loader = React.useCallback(async () => {
        if (!companyId || !subsidiaryId) {
            return MISSING_REQUEST_DATA;
        }

        return API.getEmployeesDocuments(companyId, subsidiaryId, {
            startDate: period?.periodStart,
            endDate: period?.periodEnd,
        });
    }, [companyId, period?.periodEnd, period?.periodStart, subsidiaryId]);

    const result = useAPI(loader, { generalStoreErrorMessage: "error.loadEmployeeDocuments" });

    return (
        <FolderRow
            title={t("screen.hr.employees.documents.recordType.title", {
                date: formatDate(period?.periodStart, "MMMM YYYY"),
            })}
            documentCount={result.state === "success" ? result.data.total : 0}
            statusTitle=""
            status="closed"
            onClick={() => {
                pushRoute(HrRoutes.EMPLOYEES.ALL_DOCUMENTS);
            }}
            data-id={"employees_documents_record_type"}
        />
    );
});
