import { Badge, Avatar as MuiAvatar, withStyles } from "@material-ui/core";
import * as React from "react";
import logo_tpa from "../../assets/images/logo_tpa.svg";
import { customColors } from "../util/Theme";

interface IProps {
    firstName: string;
    lastName: string;
    caption?: string; // if no caption is provided, name will be displayed
    imageUrl?: string;
    role?: string;
    isTpaStaff?: boolean;
    showAvatarOnly?: boolean;
    highlight?: boolean;
    size?: number;
    style?: React.CSSProperties;
    smallName?: boolean;
    avatarStyle?: React.CSSProperties;
}

const StyledAvatar = withStyles({
    root: {
        fontSize: 17,
        fontWeight: 900,
    },
})(MuiAvatar);

const SmallAvatar = withStyles({
    root: {
        width: 16,
        height: 16,
        padding: 2,
        backgroundColor: customColors.white,
        boxShadow: "0px 3px 6px 0px #364B8D 10%",
    },
})(MuiAvatar);

const getBackgroundColor = (imageUrl?: string, highlight?: boolean) => {
    if (imageUrl) {
        return "white";
    } else if (highlight) {
        return customColors.greyButton;
    }
    return customColors.greyTextfields;
};

interface AvatarProps {
    alt: string;
    children?: React.ReactNode;
    highlight?: boolean;
    imageUrl?: string;
    size?: number;
    style?: React.CSSProperties;
}

export const Avatar = ({ alt, size, imageUrl, highlight, style, children }: AvatarProps) => {
    return (
        <StyledAvatar
            alt={alt}
            style={{
                width: size,
                height: size,
                fontSize: size ? size * 0.425 : undefined,
                backgroundColor: getBackgroundColor(imageUrl, highlight),
                ...style,
            }}
            src={imageUrl}
        >
            {!imageUrl && children}
        </StyledAvatar>
    );
};

export const User = ({
    firstName,
    lastName,
    caption,
    imageUrl,
    role,
    isTpaStaff,
    showAvatarOnly,
    highlight,
    size,
    style,
    smallName,
    avatarStyle,
}: IProps) => {
    const name = `${firstName} ${lastName}`;
    const avatar = (
        <Avatar alt={name} style={avatarStyle} imageUrl={imageUrl} highlight={highlight} size={size}>
            {!imageUrl && (
                <>
                    {firstName[0]}
                    {lastName[0]}
                </>
            )}
        </Avatar>
    );

    return (
        <div style={{ display: "flex", alignItems: "center", ...style }}>
            {isTpaStaff ? (
                <Badge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    badgeContent={<SmallAvatar src={logo_tpa} />}
                >
                    {avatar}
                </Badge>
            ) : (
                avatar
            )}
            {!showAvatarOnly && (
                <div style={{ marginLeft: 12 }}>
                    <p
                        style={{
                            fontWeight: "bold",
                            whiteSpace: "normal",
                            color: customColors.body1Dark,
                        }}
                        className={smallName ? "caption" : undefined}
                    >
                        {caption ?? name}
                    </p>
                    {role && <p className="caption">{role}</p>}
                </div>
            )}
        </div>
    );
};
