import { useAccountListing } from "./hooks/useAccountListing";
import { ResultsTable } from "./ResultsTable";
import { ResultsSiteViewProps } from "./types";

interface ResultsAccountListingProps extends ResultsSiteViewProps {}

export const ResultsAccountListing = ({ companyId, settings, financialAccountancy }: ResultsAccountListingProps) => {
    const state = useAccountListing(companyId, settings);
    return state && financialAccountancy ? (
        <ResultsTable
            type="accountListing"
            columns={state.columns}
            rows={state.rows}
            stylesheet={state.stylesheet}
            financialAccountancy={financialAccountancy}
        />
    ) : null;
};
