import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import * as MuiStyles from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableRow from "@material-ui/core/TableRow";
import flow from "lodash/flow";
import { action } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { DragSourceHookSpec, DropTargetHookSpec, FactoryOrInstance, useDrag, useDrop } from "react-dnd";
import { getId, ItemWithId, TableStore } from "../../stores/TableStore";
import { Icon, IIconNames } from "../util/Icon";
import { customColors } from "../util/Theme";
import { ContextMenu, ContextMenuItem, useContextMenu } from "./ContextMenu";
import {
    TpaExpandedTableRowContainer,
    TpaTable,
    TpaTableCell,
    TpaTableCellMinWidth,
    TpaTableCellMinWidthNoWrap,
} from "./Primitives";
import { ITableHeaderConfig, TableHeader as TheTableHeader } from "./TableHeader";

const noopDropSpec: GetDropSpec = item => ({
    spec: () => ({
        accept: "NOTHING",
        drop: () => null,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: () => false,
    }),
    deps: [],
});
const noopDragSpec: GetDragSpec = item => ({
    spec: () => ({
        type: "NOTHING",
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
            canDrag: monitor.canDrag(),
        }),
        canDrag: false,
    }),
    deps: [],
});

interface TableRowProps<Item extends ItemWithId = ItemWithId> {
    item: Item;
    columns: TableColumn<Item>[];
    tableStore: TableStore<Item>;
    renderCell: RenderCell<Item>;
    renderExpanded?: RenderExpanded<Item>;
    getContextMenuItems?: GetContextMenuItems<Item>;
    getDropSpec?: GetDropSpec<Item>;
    getDragSpec?: GetDragSpec<Item>;
    onRowClick?: (item: Item) => void;
}
const TableRow = observer(function TableRow<Item extends ItemWithId = ItemWithId>({
    item,
    columns,
    tableStore,
    renderCell,
    renderExpanded,
    getContextMenuItems,
    getDropSpec,
    getDragSpec,
    onRowClick,
}: TableRowProps<Item>) {
    const id = getId(item);

    const expanded = tableStore.expandedItems.get(id);

    let colSpan = columns.length;
    if (tableStore.canSelect) {
        colSpan++;
    }
    if (columns.some(column => column.column === expandColumn)) {
        colSpan++;
    }
    if (columns.some(column => column.column === contextMenuColumn)) {
        colSpan++;
    }

    const dropSpec = (getDropSpec ?? noopDropSpec)(item);
    const [dropProps, drop] = useDrop(dropSpec.spec, [item, ...(dropSpec.deps ?? [])]);
    const dropRef = getDropSpec ? drop : null;

    const dragSpec = (getDragSpec ?? noopDragSpec)(item);
    const [, drag] = useDrag(dragSpec.spec, [item, ...(dragSpec.deps ?? [])]);
    const dragRef = getDragSpec ? drag : null;

    const ref = dropRef && dragRef ? flow(dropRef, dragRef) : (dropRef ?? dragRef ?? undefined);

    return (
        <TpaExpandedTableRowContainer
            ref={ref}
            style={{
                backgroundColor:
                    dropProps.canDrop && dropProps.isOver ? MuiStyles.alpha(customColors.primaryColor, 0.1) : undefined,
            }}
        >
            <MuiTableRow
                style={{ height: 56, cursor: onRowClick ? "pointer" : undefined }}
                onClick={() => {
                    onRowClick?.(item);
                }}
            >
                {tableStore.canSelect ? <TableCheckbox item={item} tableStore={tableStore} /> : null}
                {columns.map(column => {
                    let content;
                    if (column.column === expandColumn) {
                        const disabled = tableStore.canExpand ? !tableStore.canExpand(item) : false;
                        content = (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    tableStore.expandedItems.set(id, expanded === true ? "collapsing" : true);
                                }}
                                disabled={disabled}
                            >
                                <Icon name={expanded ? "chevronUp" : "chevronDown"} />
                            </IconButton>
                        );
                    } else if (column.column === contextMenuColumn) {
                        content = <TableContextMenu item={item} getContextMenuItems={getContextMenuItems} />;
                    } else {
                        content = renderCell(item, column);
                    }

                    return (
                        <TableCell
                            key={column.column.toString()}
                            width={column.width}
                            align={column.align}
                            style={column.style}
                        >
                            {content}
                        </TableCell>
                    );
                })}
            </MuiTableRow>
            <MuiTableRow>
                <MuiTableCell colSpan={colSpan} style={{ padding: 0 }}>
                    <Collapse
                        in={expanded === true} // "collapsing" will trigger the closing animation
                        timeout="auto"
                        onExited={() => {
                            tableStore.expandedItems.delete(id);
                        }}
                    >
                        {/* render the expanded content as long as the row is expanded or is "collapsing" */}
                        {expanded ? renderExpanded?.(item) : null}
                    </Collapse>
                </MuiTableCell>
            </MuiTableRow>
        </TpaExpandedTableRowContainer>
    );
});

interface TableCellProps {
    width: TableColumn["width"];
    align?: TableColumn["align"];
    className?: string;
    style?: TableColumn["style"];
    padding?: "checkbox";
    children?: React.ReactNode;
}
const TableCell = ({ align, className, width, style, padding, children }: TableCellProps) => {
    style = { ...style };
    if (align === "right") {
        style.textAlign = align;
        style.flexDirection = "row-reverse"; // required to display the TableSort correctly
    }
    if (padding === "checkbox") {
        // same as @material-ui TableCell padding
        style.width = "48px";
        style.padding = "0 0 0 4px";
    }
    let Component = TpaTableCell;
    if (width === "min-width") {
        Component = TpaTableCellMinWidth;
    } else if (width === "min-width-nowrap") {
        Component = TpaTableCellMinWidthNoWrap;
    }
    return (
        <Component className={className} style={style}>
            {children}
        </Component>
    );
};

interface TableCheckboxProps<Item extends ItemWithId = ItemWithId> {
    item: Item;
    tableStore: TableStore<Item>;
}
const TableCheckbox = observer(function TableCheckbox<Item extends ItemWithId = ItemWithId>({
    item,
    tableStore,
}: TableCheckboxProps<Item>) {
    return (
        <TableCell width="min-width" padding="checkbox">
            <Checkbox
                onChange={(event, checked) => {
                    tableStore.toggleSelection(item);
                }}
                color="primary"
                checked={tableStore.isSelected(item)}
                disabled={tableStore.canSelect?.(item) === false}
            />
        </TableCell>
    );
});

interface TableContextMenuProps<Item extends ItemWithId = ItemWithId> {
    item: Item;
    getContextMenuItems?: GetContextMenuItems<Item>;
}
const TableContextMenu = <Item extends ItemWithId = ItemWithId>({
    item,
    getContextMenuItems,
}: TableContextMenuProps<Item>) => {
    const contextMenu = useContextMenu<Item>();
    const contextMenuItems = getContextMenuItems?.(item) ?? [];
    const disabled = contextMenuItems.length === 0;

    return (
        <>
            <IconButton
                size="small"
                onClick={event => {
                    contextMenu.open(event, item);
                }}
                disabled={disabled}
            >
                <Icon name="more" />
            </IconButton>
            <ContextMenu
                data-id="context_menu"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                config={contextMenu}
                items={contextMenuItems}
            />
        </>
    );
};

interface TableHeaderProps<Item extends ItemWithId = ItemWithId> {
    headerFields: ITableHeaderConfig<Item>[];
    tableStore: TableStore<Item>;
    renderLabel?: (column: ITableHeaderConfig<Item>) => React.ReactNode;
}
const TableHeader = observer(function TableHeader<Item extends ItemWithId = ItemWithId>(props: TableHeaderProps<Item>) {
    return <TheTableHeader {...props} select={props.tableStore.canSelect ? props.tableStore : undefined} />;
});

interface TableExpandAllButtonProps<Item extends ItemWithId = ItemWithId> {
    tableStore: TableStore<Item>;
}
const TableExpandAllButton = observer(function TableExpandAllButton<Item extends ItemWithId = ItemWithId>({
    tableStore,
}: TableExpandAllButtonProps<Item>) {
    const all = tableStore.allExpanded;
    const some = tableStore.someExpanded;

    let iconName: IIconNames = "chevronDown";
    if (all) {
        iconName = "chevronUp";
    } else if (some) {
        iconName = "chevronRight";
    }
    return (
        <IconButton
            size="small"
            onClick={action(() => {
                tableStore.expandedItems.clear();
                if (some || !all) {
                    // expand all expandable items
                    tableStore.expandableItems.forEach(item => {
                        tableStore.expandedItems.set(getId(item), true);
                    });
                } else if (all) {
                    // collapse everything
                }
            })}
        >
            <Icon name={iconName} />
        </IconButton>
    );
});

export interface TableColumn<Item extends ItemWithId = ItemWithId> extends ITableHeaderConfig<Item> {
    width?: "min-width" | "min-width-nowrap" | number;
}

const expandColumn = `expand${Math.random()}` as never; // "unique" name to avoid clashes

export function createExpandColumn<Item extends ItemWithId = ItemWithId>(
    props: Partial<TableColumn<Item>>,
): TableColumn<Item> {
    return {
        column: expandColumn,
        width: "min-width",
        sort: false,
        style: { padding: 8, ...props.style },
        ...props,
    };
}

const contextMenuColumn = `contextMenu${Math.random()}` as never; // "unique" name to avoid clashes

export function createContextMenuColumn<Item extends ItemWithId = ItemWithId>(
    props: Partial<TableColumn<Item>>,
): TableColumn<Item> {
    return {
        column: contextMenuColumn,
        width: "min-width",
        sort: false,
        style: { padding: 8, ...props.style },
        ...props,
    };
}

export type RenderCell<Item extends ItemWithId = ItemWithId> = (
    item: Item,
    column: TableColumn<Item>,
) => React.ReactNode;
export type RenderExpanded<Item extends ItemWithId = ItemWithId> = (item: Item) => React.ReactNode;
export type GetContextMenuItems<Item extends ItemWithId = ItemWithId> = (item: Item) => ContextMenuItem[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetDropSpec<Item extends ItemWithId = ItemWithId, DragObject = any, DropResult = any> = (item: Item) => {
    spec: FactoryOrInstance<DropTargetHookSpec<DragObject, DropResult, { isOver: boolean; canDrop: boolean }>>;
    /** Passed to `useDrop` as `deps` arguments. Basically anything that is used in the `useCallback` deps should be part if it. */
    deps?: unknown[];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetDragSpec<Item extends ItemWithId = ItemWithId, DragObject = any, DropResult = any> = (item: Item) => {
    spec: FactoryOrInstance<DragSourceHookSpec<DragObject, DropResult, { isDragging: boolean; canDrag: boolean }>>;
    /** Passed to `useDrag` as `deps` arguments. Basically anything that is used in the `useCallback` deps should be part if it. */
    deps?: unknown[];
};

interface TableProps<Item extends ItemWithId = ItemWithId> {
    columns: TableColumn<Item>[];
    tableStore: TableStore<Item>;

    renderCell: RenderCell<Item>;
    renderExpanded?: RenderExpanded<Item>;
    getContextMenuItems?: GetContextMenuItems<Item>;

    getDropSpec?: GetDropSpec<Item>;
    getDragSpec?: GetDragSpec<Item>;

    onRowClick?: (item: Item) => void;

    className?: string;
}

export const Table = observer(function Table<Item extends ItemWithId = ItemWithId>(props: TableProps<Item>) {
    const {
        columns,
        tableStore,
        renderCell,
        renderExpanded,
        getContextMenuItems,
        getDropSpec,
        getDragSpec,
        onRowClick,
        className,
    } = props;

    const renderLabel = useCallback(
        (column: ITableHeaderConfig<Item>) => {
            if (column.column !== expandColumn) {
                return undefined; // leave it to the TableHeader to render the label
            }

            return <TableExpandAllButton tableStore={tableStore} />;
        },
        [tableStore],
    );

    return (
        <TpaTable className={className}>
            <TableHeader headerFields={columns} tableStore={tableStore} renderLabel={renderLabel} />
            {tableStore.items.map(item => {
                const id = getId(item);
                return (
                    <TableRow
                        key={id}
                        item={item}
                        columns={columns}
                        tableStore={tableStore}
                        renderCell={renderCell}
                        renderExpanded={renderExpanded}
                        getContextMenuItems={getContextMenuItems}
                        getDropSpec={getDropSpec}
                        getDragSpec={getDragSpec}
                        onRowClick={onRowClick}
                    />
                );
            })}
        </TpaTable>
    );
});
