import chunk from "lodash/chunk";
import takeRight from "lodash/takeRight";
import * as React from "react";
import Highlighter from "react-highlight-words";
import { sanitizeHtml } from "../../util/helpers";
import { useResize } from "../hooks/useResize";
import { customColors } from "../util/Theme";

interface Props {
    lines: number;
    text: string;
    search: string;
    minWidth?: number;
}

const parsePxToInt = (value: string) => parseInt(value.split("px")[0]);

export const SearchWordEllipsis = ({ lines, text, search, minWidth = 0 }: Props) => {
    const [clampedText, setClampedText] = React.useState("");
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    const { width } = useResize(containerRef);

    React.useEffect(() => {
        if (containerRef.current) {
            // render text like dangerouslySetInnerHTML
            const textDiv = document.createElement("div");
            textDiv.innerHTML = sanitizeHtml(text);
            const renderedText = textDiv.innerText;
            textDiv.remove();

            // Create an element
            const measureDiv = document.createElement("div");

            // Set styles
            measureDiv.style.position = "absolute";
            measureDiv.style.visibility = "hidden";
            measureDiv.style.whiteSpace = "nowrap";
            measureDiv.style.left = "-9999px";
            // measure all text in bold so we can be sure the text will fit when highlighted - can lead to shorter lines
            measureDiv.style.fontWeight = "700";

            // Append to the body
            document.body.appendChild(measureDiv);

            const ellipsis = "...";
            measureDiv.innerHTML = ellipsis;
            const ellipsisWidth = parsePxToInt(getComputedStyle(measureDiv).width);
            const necessaryEllipsisWidth = lines === 1 ? ellipsisWidth * 2 : ellipsisWidth;

            const parts = renderedText.split(" ");

            const textLines = [];
            let fittingText = "";

            for (let i = 0; i < parts.length; i++) {
                const nextWord = parts[i];
                measureDiv.innerHTML = `${fittingText}${fittingText ? ` ${nextWord}` : nextWord}`;
                const nextTextWidth = parsePxToInt(getComputedStyle(measureDiv).width);

                if (nextTextWidth >= width - necessaryEllipsisWidth) {
                    textLines.push(fittingText);
                    fittingText = nextWord;
                } else {
                    fittingText = `${fittingText}${fittingText ? ` ${nextWord}` : nextWord}`;
                }
            }

            if (fittingText) {
                textLines.push(fittingText);
            }

            // Remove the elements
            document.body.removeChild(measureDiv);

            const allChunks = chunk(textLines, lines);

            const chunkIndex = allChunks.findIndex(chunk =>
                chunk.some(line => line.toLowerCase().includes(search.toLowerCase())),
            );

            const containingChunk = allChunks[chunkIndex === -1 ? 0 : chunkIndex];

            const finalChunk =
                containingChunk.length < lines && allChunks.length > 1
                    ? takeRight(allChunks[chunkIndex - 1], lines - containingChunk.length).concat(containingChunk)
                    : containingChunk;

            let finalText = "";

            finalText = `${chunkIndex > 0 ? "..." : ""}${finalChunk.join(" ")}${
                chunkIndex < allChunks.length - 1 ? "..." : ""
            }`;

            setClampedText(finalText);
        }
    }, [lines, minWidth, search, text, width]);

    return (
        <div ref={containerRef} style={{ minWidth }}>
            <Highlighter
                textToHighlight={clampedText}
                searchWords={[search]}
                highlightStyle={{
                    color: customColors.primaryColor,
                    backgroundColor: customColors.primaryShade,
                    fontWeight: 700,
                    padding: "0 2px", // Remove this, if a nonintended break leading to one more line as expected occurs
                }}
                autoEscape
            />
        </div>
    );
};
