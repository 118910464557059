// Countries taken from https://github.com/jbub/banking/blob/master/country/data.go which is used at our backend
export const bicCountries = [
    "AL",
    "AD",
    "AT",
    "AZ",
    "BY",
    "BH",
    "BE",
    "BA",
    "BR",
    "VG",
    "BG",
    "CR",
    "HR",
    "CY",
    "CZ",
    "DK",
    "DO",
    "EE",
    "EG",
    "FI",
    "FO",
    "FR",
    "GE",
    "DE",
    "GI",
    "GL",
    "GR",
    "GT",
    "HU",
    "IS",
    "IE",
    "IL",
    "IT",
    "IQ",
    "JO",
    "XK",
    "KZ",
    "KW",
    "LV",
    "LC",
    "LB",
    "LI",
    "LT",
    "LU",
    "MK",
    "MT",
    "MR",
    "MU",
    "MD",
    "MC",
    "ME",
    "NL",
    "NO",
    "PK",
    "PS",
    "PL",
    "PT",
    "QA",
    "RO",
    "SM",
    "SA",
    "RS",
    "SC",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
    "TL",
    "TN",
    "TR",
    "AE",
    "GB",
    "VA",
    "UA",
];
