import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { Module } from "../../../types/models";
import { AdvisorConfigAccountingContent, ConfigSubmit } from "../../advisor/sites/AdvisorConfigAccountingSite";
import { AdvisorConfigHrContent } from "../../advisor/sites/AdvisorConfigHrSite";
import { pushRoute } from "../../app/router/history";
import { useModuleConfig } from "../../hooks/useModuleConfig";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { CenteredContent } from "../../ui/CenteredContent";
import { SiteContent } from "../../ui/SiteContent";
import { DIALOG_WIDTH } from "../../util/Theme";
import { SettingsRoutes } from "../router/SettingsRoutes";

export const CompanyConfigView = ({ module }: { module: Module }) => {
    const companyId = companiesStore.selectedCompanyId;
    const canEdit = authStore.canEditCompanySettings;
    const config = useModuleConfig(companyId, module, canEdit);

    const successDialog = useSuccessDialog({
        title: t("dialog.companyConfig.success.header"),
        onClose: () => {
            pushRoute(SettingsRoutes.COMPANY.DATA);
        },
    });

    if (!companyId || !config.company) {
        return null;
    }

    return (
        <>
            {module === "accounting" && <AdvisorConfigAccountingContent config={config} isSettings />}
            {module === "hr" && <AdvisorConfigHrContent config={config} isSettings />}
            {canEdit && (
                <ConfigSubmit
                    style={{ marginTop: 32, textAlign: "right" }}
                    companyId={companyId}
                    config={config}
                    title={t("common.save")}
                    onSubmit={async () => {
                        try {
                            await coordinator.reloadCompany();
                        } catch (err) {
                            generalStore.setError(t("error.loadCompany"));
                        }

                        successDialog.openDialog();
                    }}
                />
            )}
            {successDialog.dialog}
        </>
    );
};

export const SettingsCompanyAccountingSite = () => {
    return (
        <CenteredContent>
            <SiteContent style={{ maxWidth: DIALOG_WIDTH, paddingTop: 40 }}>
                <CompanyConfigView module="accounting" />
            </SiteContent>
        </CenteredContent>
    );
};
