import { Checkbox, IconButton, Link, TableBody, TableCell, TableRow, withStyles } from "@material-ui/core";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import { CENTERED_CONTENT_RIGHT_OFFSET } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { getApiError } from "../../../network/NetworkStapler";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { getRecordTypeStatus } from "../../../stores/ModuleStore";
import { useTableStore } from "../../../stores/TableStore";
import { ViewerFile, viewerStore } from "../../../stores/ViewerStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { IRecord, Module } from "../../../types/models";
import { formatDate } from "../../../util/date";
import { debug } from "../../../util/debug";
import { getFullName } from "../../../util/user";
import { pushRoute, withParams } from "../../app/router/history";
import { useDeleteRecords } from "../../hooks/useDeleteRecords";
import { useGlobalQuickActionsOffset } from "../../hooks/useGlobalQuickActionsOffset";
import { useTableFilters } from "../../hooks/useTableFilters";
import { useTableSelection } from "../../hooks/useTableSelection";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Currency } from "../../results/ResultsValue";
import { BulkActionButtonsFiles } from "../../ui/BulkActionButtonsFiles";
import { CenteredContent } from "../../ui/CenteredContent";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../../ui/ContextMenu";
import { EmptyState } from "../../ui/EmptyState";
import { TableLabel, TpaTable, TpaTableCell, TpaTableContainer, TpaTableRow } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { FileIcon } from "../../util/FileIcon";
import { Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { customColors } from "../../util/Theme";
import { IMoveRecordsModel, MoveRecordsDialog } from "../ModuleDialogs";
import { ModuleRecordsEmptyState } from "../ModuleRecordsEmptyState";
import { useTransferRecords } from "./hooks/useTransferRecords";

const SelectBanner = withStyles({
    root: {
        backgroundColor: customColors.primaryShade,
        border: `1px solid ${customColors.primaryColorLight}`,
        borderRadius: 4,
        padding: 0,
        height: 40,
    },
})(TableCell);

export const ModuleRecordsListSite = observer(function ModuleRecordsListSite({ module }: { module: Module }) {
    const store = getModuleStore(module);

    const companyId = companiesStore.selectedCompanyId;
    const periodId = store.selectedPeriodId;
    const subsidiaryId = store.selectedSubsidiaryId;

    // We shouldn't be here if one of those is missing
    if (!companyId || !periodId || !subsidiaryId) {
        return <Redirect to={store.routes.ROOT} />;
    }

    return <ModuleRecordsList module={module} companyId={companyId} periodId={periodId} subsidiaryId={subsidiaryId} />;
});

const ModuleRecordsList = observer(function ModuleRecordsList({
    module,
    companyId,
    periodId,
    subsidiaryId,
}: {
    module: Module;
    companyId: string;
    periodId: string;
    subsidiaryId: string;
}) {
    const { recordTypeId } = useParams<{ recordTypeId: string }>();

    const store = getModuleStore(module);

    const tableStore = useTableStore(recordTypeId ?? "ModuleRecordsListSite", {
        orderBy: "updatedAt",
        orderDir: "desc",
    });

    const recordType = store.getRecordTypeForId(recordTypeId);

    React.useEffect(() => {
        if (!recordType) {
            pushRoute(store.routes.ROOT);
        }
    }, [recordType, recordTypeId, store.routes.ROOT]);

    const [records, setRecords] = React.useState<IRecord[]>([]);
    const [showMoveRecordsDialog, setShowMoveRecordsDialog] = React.useState(false);
    const [selectedRecords, setSelectedRecords] = React.useState<IRecord[] | null>(null);
    const contextMenu = useContextMenu<IRecord>();
    const { clearSelection, ...select } = useTableSelection(records);
    const [isInitialized, setIsInitialized] = React.useState(false);
    const isMobile = React.useContext(MobileContext);

    const [everythingSelected, setEverythingSelected] = React.useState(false);
    React.useEffect(() => {
        if (!select.allSelected) {
            // If one record is deselected clear everythingSelected
            setEverythingSelected(false);
        }
    }, [select.allSelected]);

    const canUpload = store.canEditRecords(subsidiaryId, recordTypeId);

    const filters = useTableFilters({
        filters: [
            {
                category: "general",
                entries: [{ name: "transferFailed", label: t("screen.accounting.records.filter.transferFailed") }],
            },
        ],
        onChangeFilters: () => {
            tableStore.resetOffset();
        },
    });

    const transferFailed = filters.isActive("transferFailed");
    const { tableParams } = tableStore;
    const reloadRecords = React.useCallback(async () => {
        if (!subsidiaryId || !periodId || !recordTypeId || !companyId) {
            setRecords([]);
            tableStore.resetOffset();
            return;
        }

        try {
            clearSelection();
            generalStore.isLoading = true;

            const records = await API.getRecords({
                companyId,
                module,
                periodId,
                subsidiaryId,
                recordTypeId,
                options: {
                    ...tableParams,
                    failedOnly: transferFailed ? true : undefined,
                },
            });

            setRecords(records.records);
            tableStore.totalCount = records.total;
        } catch (err) {
            generalStore.setError(t("error.loadRecords"), err);
        } finally {
            setIsInitialized(true);
            generalStore.isLoading = false;
        }
    }, [
        clearSelection,
        companyId,
        module,
        periodId,
        recordTypeId,
        subsidiaryId,
        tableParams,
        tableStore,
        transferFailed,
    ]);

    React.useEffect(() => {
        reloadRecords();
    }, [reloadRecords]);

    React.useEffect(() => {
        if (isMobile) {
            clearSelection();
        }
    }, [clearSelection, isMobile]);

    const costCenters = store.costCenters.state === "success" ? store.costCenters.data.costCenters : [];

    const headerFields = compact<ITableHeaderConfig>(
        isMobile
            ? [
                  { column: "uploadedAt", label: "table.label.createdAt" },
                  { column: "documentName", label: "table.label.documentName" },
                  !authStore.isTpa && { column: "releasedAt", label: "table.label.released", sort: false },
                  authStore.isTpa && { column: "transferStatus", label: "table.label.transferStatus" },
                  { column: "contextMenu" },
              ]
            : [
                  { column: "uploadedAt", label: "table.label.createdAt" },
                  { column: "documentType", label: "table.label.documentType", sort: false },
                  { column: "documentName", label: "table.label.documentName" },
                  module === "accounting" &&
                      costCenters.length > 0 && { column: "costCenter", label: "table.label.costCenter" },
                  { column: "comment", label: "table.label.comment" },
                  { column: "uploader", label: "table.label.uploadedBy" },
                  module === "accounting" && {
                      column: "totalGrossAmount",
                      label: "accounting.table.label.totalGrossAmount",
                      align: "right",
                  },
                  { column: "releasedAt", label: "table.label.releasedAt" },
                  authStore.isTpa && { column: "transferStatus", label: "table.label.transferStatus" },
                  { column: "contextMenu" },
              ],
    );

    const deleteRecords = useDeleteRecords({
        companyId,
        module,
        subsidiaryId,
        periodId,
        recordTypeId,
        onDelete: numDeleted => {
            // If after deletion there are not enough records left for the current offset -> go to start page
            if (tableStore.offset >= tableStore.totalCount - numDeleted) {
                tableStore.resetOffset();
            }

            reloadRecords();
        },
    });

    const handleView = (record: IRecord) => {
        if (!companyId || !periodId || !subsidiaryId || !recordTypeId) {
            return;
        }

        const files = records.map<ViewerFile>(r => {
            return {
                id: r.id,
                name: r.document?.name ?? "",
                src: () => {
                    return API.getRecordDownloadUrl({
                        companyId,
                        module,
                        subsidiaryId,
                        periodId,
                        recordTypeId,
                        recordId: r.id,
                    });
                },
                download: () => {
                    return API.putDownloadRecords({
                        companyId,
                        module,
                        subsidiaryId,
                        periodId,
                        recordTypeId,
                        recordIds: [r.id],
                    });
                },
            };
        });
        viewerStore.open(files, record.id);
    };

    let contextMenuItems: ContextMenuItem[] = [];
    if (contextMenu.contextElement) {
        const record = contextMenu.contextElement;
        contextMenuItems = [
            {
                title: t("menu.view"),
                icon: "open",
                onClick: () => {
                    contextMenu.close();
                    handleView(record);
                },
                "data-id": "context_menu_view",
            },
            {
                title: t("menu.download"),
                icon: "download",
                onClick: () => {
                    if (record.document) {
                        contextMenu.close();
                        downloadRecords([record.id]);
                    }
                },
                disabled: select.selectedIds.length > 0,
                "data-id": "context_menu_download",
            },
            {
                title: t("menu.delete"),
                icon: "archive",
                onClick: () => {
                    deleteRecords.handleDeleteRecords([record]);
                    contextMenu.close();
                },
                disabled: !!record.releasedAt || !canUpload || select.selectedIds.length > 0,
                "data-id": "context_menu_delete",
            },
            {
                title: t("menu.edit"),
                icon: "pen",
                onClick: () => {
                    contextMenu.close();
                    if (recordTypeId) {
                        pushRoute(
                            withParams(store.routes.RECORDS.DETAILS_EDIT, {
                                recordTypeId,
                                recordId: record.id,
                            }),
                        );
                    }
                },
                disabled: !!record.releasedAt || !canUpload || select.selectedIds.length > 0,
                "data-id": "context_menu_edit",
            },
            {
                title: t("menu.move"),
                icon: "kategorieSw",
                onClick: () => {
                    debug.log("move", record.id);
                    setSelectedRecords([record]);
                    setShowMoveRecordsDialog(true);
                    contextMenu.close();
                },
                disabled: !!record.releasedAt || !canUpload || select.selectedIds.length > 0,
                "data-id": "context_menu_move",
            },
        ];
    }

    const onUploadRecords = () => {
        if (recordTypeId) {
            pushRoute(withParams(store.routes.RECORDS.UPLOAD, { recordTypeId }));
        }
    };

    const handleDeleteSelected = () => {
        deleteRecords.handleDeleteRecords(records.filter(record => select.isSelected(record.id) && !record.releasedAt));
    };

    const handleMoveSelected = () => {
        setSelectedRecords(records.filter(record => select.isSelected(record.id) && !record.releasedAt));
        setShowMoveRecordsDialog(true);
    };

    const handleCloseMoveRecordsDialog = () => {
        setShowMoveRecordsDialog(false);
        setSelectedRecords(null);
    };

    const handleMoveRecords = async (model: IMoveRecordsModel) => {
        const subsidiaryId = store.selectedSubsidiaryId;
        const periodId = store.selectedPeriod?.id;

        if (
            selectedRecords &&
            (subsidiaryId !== model.newSubsidiaryId ||
                periodId !== model.newPeriodId ||
                recordTypeId !== model.newRecordTypeId)
        ) {
            try {
                generalStore.isLoading = true;

                await Promise.all(
                    selectedRecords.map(record =>
                        API.patchRecord(model, {
                            companyId,
                            module,
                            subsidiaryId,
                            periodId,
                            recordTypeId,
                            recordId: record.id,
                        }),
                    ),
                );
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.response.type === "PERIOD_FINISHED") {
                    generalStore.setError(t("error.periodClosed"), error);
                } else {
                    generalStore.setError(t("error.move"), error);
                }
            } finally {
                generalStore.isLoading = false;
                reloadRecords();
                setShowMoveRecordsDialog(false);
                setSelectedRecords(null);
            }
        }
    };

    const handleSelectAll = () => {
        if (companyId && periodId && subsidiaryId && recordTypeId) {
            setEverythingSelected(true);
        }
    };

    const handleDeselectAll = () => {
        if (companyId && periodId && subsidiaryId && recordTypeId) {
            setEverythingSelected(false);
            clearSelection();
        }
    };

    const linkStyle = {
        color: customColors.primaryColor,
        cursor: "pointer",
        marginLeft: 8,
    };

    const bannerPostfix = filters.isActive("transferFailed") ? ".transferFailed" : "";
    const selectAllBanner = (
        <>
            <TableRow>
                {/* plus 1 since the checkbox is not included in the headerFields */}
                <SelectBanner colSpan={headerFields.length + 1}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {!everythingSelected ? (
                            <>
                                {t("screen.accounting.records.banner.selectedCount", {
                                    count: select.selectedIds.length,
                                })}
                                <Link style={linkStyle} onClick={handleSelectAll}>
                                    {t(`screen.accounting.records.banner.totalCount${bannerPostfix}`, {
                                        count: tableStore.totalCount,
                                        recordType: recordType ? store.getRecordTypeName(recordType) : "",
                                    })}
                                </Link>
                            </>
                        ) : (
                            <>
                                {t(`screen.accounting.records.banner.selectedAll${bannerPostfix}`, {
                                    count: tableStore.totalCount,
                                    recordType: recordType ? store.getRecordTypeName(recordType) : "",
                                })}
                                <Link style={linkStyle} onClick={handleDeselectAll}>
                                    {t("screen.accounting.records.banner.unselect")}
                                </Link>
                            </>
                        )}
                    </div>
                </SelectBanner>
            </TableRow>
            <TableRow style={{ height: 8 }} />
        </>
    );

    const tableBody = (
        <TableBody>
            {select.allSelected &&
                tableStore.totalCount > select.selectedIds.length &&
                !tableStore.search &&
                selectAllBanner}

            {records.map((record, index) => {
                return (
                    <TpaTableRow key={record.id}>
                        {!isMobile && (
                            <TpaTableCell padding="checkbox">
                                <Checkbox
                                    data-id={`record_checkbox_${index}`}
                                    onChange={(event, checked) => {
                                        select.toggleSelection(record.id);
                                    }}
                                    color="primary"
                                    checked={select.isSelected(record.id)}
                                />
                            </TpaTableCell>
                        )}
                        {headerFields.map(({ column }, index) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = record[column as keyof typeof record];
                            if (column === "uploadedAt") {
                                label = formatDate(record.document?.uploadedAt);
                            } else if (column === "releasedAt" && record.releasedAt) {
                                return isMobile ? (
                                    <TpaTableCell key={column} style={{ textAlign: "right" }}>
                                        <Icon name="checkmark" />
                                    </TpaTableCell>
                                ) : (
                                    <TpaTableCell key={column}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Icon name="checkmark" /> <span>{formatDate(record.releasedAt)}</span>
                                        </div>
                                    </TpaTableCell>
                                );
                            } else if (column === "transferStatus") {
                                return (
                                    <TpaTableCell key={column}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {record.transferStatus === "success" && <Icon name="checkmark" />}
                                            {record.transferStatus === "failed" && (
                                                <Icon style={{ color: customColors.error }} name="closeSmall" />
                                            )}
                                        </div>
                                    </TpaTableCell>
                                );
                            } else if (column === "documentName") {
                                label = record.document?.name;
                            } else if (column === "costCenter") {
                                label = store.getCostCenterLabel(store.getCostCenter(record.costCenter, true));
                            } else if (column === "uploader") {
                                label = getFullName(record.uploader);
                            } else if (column === "documentType") {
                                return (
                                    <TpaTableCell key={column}>
                                        <FileIcon name={record.document?.name} style={{ display: "block" }} />
                                    </TpaTableCell>
                                );
                            } else if (column === "totalGrossAmount") {
                                return (
                                    <TpaTableCell key={column} align="right">
                                        {record.invoiceMetadata?.totalGrossAmount != null && (
                                            <Currency value={record.invoiceMetadata.totalGrossAmount} />
                                        )}
                                    </TpaTableCell>
                                );
                            } else if (column === "contextMenu") {
                                return (
                                    <TpaTableCell key={column} style={{ textAlign: "right", width: 1 }}>
                                        <IconButton
                                            data-id={`context_menu_${index}`}
                                            style={{ padding: 0 }}
                                            onClick={event => {
                                                contextMenu.open(event, record);
                                            }}
                                        >
                                            <Icon name="more" />
                                        </IconButton>
                                    </TpaTableCell>
                                );
                            }

                            return (
                                <TpaTableCell key={column}>
                                    <TableLabel>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    // No records and NOT caused by a search term
    const tableIsEmpty = tableStore.getIsEmptyState(generalStore.isLoading, !isEmpty(filters.activeFilters));

    // If selection contains released records -> disable delete and move
    const selectedContainsReleased =
        records.filter(record => select.isSelected(record.id) && !!record.releasedAt).length > 0;

    const downloadRecords = async (ids?: string[]) => {
        if (!companyId || !periodId || !subsidiaryId || !recordTypeId) {
            return;
        }

        try {
            generalStore.isLoading = true;

            const downloadIds = ids ?? select.selectedIds;
            await API.putDownloadRecords({
                companyId,
                module,
                subsidiaryId,
                periodId,
                recordTypeId,
                recordIds: everythingSelected ? [] : downloadIds,
                failedOnly: everythingSelected && filters.isActive("transferFailed"),
            });
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const handleDownload = () => {
        downloadRecords();
    };

    const [rightWidth, setRightWidth] = React.useState(0);

    const { button, dialogs } = useTransferRecords({
        recordType,
        store,
        disabled: !store.canReleaseAllSubsidiaryRecordTypeRecords(recordTypeId),
        transfer: async () => {
            await store.transferRecordTypeRecords(recordTypeId);
        },
    });

    const showTransferButton =
        records.length > 0 && recordType && getRecordTypeStatus(recordType).status !== "closed" && button;

    const size = useWindowSize(true);

    const offsetFab = showTransferButton && size.width && size.width < CENTERED_CONTENT_RIGHT_OFFSET + 85;

    useGlobalQuickActionsOffset(offsetFab ? 70 : 0);

    if (!recordType || !isInitialized) {
        return null;
    }

    const resize = (width: number) => {
        setRightWidth(width);
    };

    return (
        <>
            <CenteredContent getRightWidth={resize}>
                {tableIsEmpty && (
                    <ModuleRecordsEmptyState module={module} subsidiaryId={subsidiaryId} recordType={recordType} />
                )}
                {!tableIsEmpty && (
                    <>
                        <SiteContent style={{ paddingBottom: 72 }}>
                            <TableSearchBarWithAction
                                label="search.caption.numUploads"
                                labelSelected="search.caption.numSelected"
                                placeholder="search.placeholder.searchForFiles"
                                select={select}
                                everythingSelected={everythingSelected}
                                search={tableStore.search}
                                totalCount={tableStore.totalCount}
                                onChangeSearch={tableStore.handleSearchChange}
                                disabled={!canUpload || store.selectedPeriod?.finished}
                                buttonLabel={t(
                                    store.module === "accounting"
                                        ? "welcomeCard.accounting.addRecords.button"
                                        : "common.uploadFiles",
                                )}
                                onAction={onUploadRecords}
                                bulkAction={
                                    <BulkActionButtonsFiles
                                        onDownload={handleDownload}
                                        onDelete={
                                            everythingSelected || selectedContainsReleased || !canUpload
                                                ? undefined
                                                : handleDeleteSelected
                                        }
                                        onMove={
                                            everythingSelected || selectedContainsReleased || !canUpload
                                                ? undefined
                                                : handleMoveSelected
                                        }
                                    />
                                }
                                tableFilters={authStore.isTpa ? filters : undefined}
                            />
                            <TpaTableContainer>
                                <TpaTable>
                                    <TableHeader headerFields={headerFields} tableStore={tableStore} select={select} />
                                    {tableBody}
                                </TpaTable>
                            </TpaTableContainer>

                            <tableStore.Pagination />

                            <ContextMenu
                                data-id="context_menu"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                config={contextMenu}
                                items={contextMenuItems}
                            />
                        </SiteContent>
                        {tableStore.getIsNoResultState(generalStore.isLoading, !isEmpty(filters.activeFilters)) && (
                            <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                        )}
                    </>
                )}
                {showTransferButton && (
                    <div
                        className="mui-fixed"
                        style={{
                            position: "fixed",
                            bottom: 16,
                            right: rightWidth + 10,
                        }}
                    >
                        {button}
                    </div>
                )}
            </CenteredContent>
            {dialogs}
            <MoveRecordsDialog
                module={module}
                open={showMoveRecordsDialog}
                onClose={handleCloseMoveRecordsDialog}
                onSubmit={handleMoveRecords}
                records={selectedRecords ?? []}
                defaultRecordTypeId={recordTypeId}
            />
            {deleteRecords.dialog}
        </>
    );
});
