import { ButtonProps, Divider, IconButton, Menu, MenuItem, Popover, TextField, Tooltip } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import moment from "moment";
import * as React from "react";
import { SEARCH_DEBOUNCE_MS } from "../../../config";
import { t } from "../../../i18n/util";
import { formatMonth } from "../../../util/helpers";
import { Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";
import { TpaTableFilterButton } from "../Primitives";
import { getRequiredText } from "./filterHelper";
import {
    DATE_FILTER_TYPE,
    DATE_RANGE_FILTER_TYPE,
    DROPDOWN_FILTER_TYPE,
    DateRange,
    IDateRangeFilterItem,
    IFilterItem,
    INumberRangeFilterItem,
    ITableFilterCategory,
    ITableFilterOption,
    ITableFilters,
    NUMBER_RANGE_FILTER_TYPE,
    NumberRange,
    TableFilterValue,
} from "./types";

type ITableFilterButtonProps = ButtonProps & {
    isSelected: boolean;
    "data-id": string;
    disabled?: boolean;
};

interface IProps {
    filters: ITableFilterCategory[];
    onChange: (filters: ITableFilters) => void;
    activeFilters: ITableFilters;
    showAllButton?: boolean;
}

interface ITableDatePickerFilterButtonProps {
    children: string;
    onClick: () => void;
    isSelected: boolean;
    onClose?: () => void;
    onChange: (date: Date) => void;
    disabled?: boolean;
}

interface ITableDateRangePickerFilterButtonProps {
    dateRange: DateRange | null;
    onClick: () => void;
    isSelected: boolean;
    onClose?: () => void;
    onChange: (date: DateRange) => void;
    disabled?: boolean;
    filter: IDateRangeFilterItem;
}

interface ITableDropdownFilterButtonProps {
    children: React.ReactNode;
    onClick: () => void;
    isSelected: boolean;
    onChange: (value: string) => void;
    disabled?: boolean;
    options: ITableFilterOption[];
    listSettings?: React.ReactNode[];
    toggle?: boolean;
    "data-id": string;
}

interface ITableNumberRangePickerFilterButtonProps {
    onClick: () => void;
    isSelected: boolean;
    onClose?: () => void;
    onChange: (value: NumberRange) => void;
    disabled?: boolean;
    filter: INumberRangeFilterItem;
}

export const TableFilterButtons = ({ filters, onChange, activeFilters, showAllButton }: IProps) => {
    const isFilterSelected = (name: string) => {
        return !!activeFilters[name];
    };

    const handleToggleFilter = (name: string, type?: IFilterItem["type"]) => () => {
        if (name === "all") {
            onChange({});
        } else if (isFilterSelected(name)) {
            onChange(omit(activeFilters, name));
        } else {
            onChange({
                ...activeFilters,
                [name]: {
                    value:
                        type === DATE_FILTER_TYPE ||
                        type === DATE_RANGE_FILTER_TYPE ||
                        type === NUMBER_RANGE_FILTER_TYPE ||
                        type === DROPDOWN_FILTER_TYPE
                            ? null
                            : true,
                },
            });
        }
    };

    const handleChangeDate = (name: string, onChangeCallback?: (date: Date) => void) => (date: Date) => {
        onChange({
            ...activeFilters,
            [name]: {
                value: date,
            },
        });

        if (onChangeCallback) {
            onChangeCallback(date);
        }
    };

    const handleChangeDateRange =
        (name: string, onChangeCallback?: (dateRange: DateRange) => void) => (dateRange: DateRange) => {
            onChange({
                ...activeFilters,
                [name]: {
                    value: dateRange,
                },
            });

            if (onChangeCallback) {
                onChangeCallback(dateRange);
            }
        };

    const handleChangeDropdownValue = (name: string, onChangeCallback?: (value: string) => void) => (value: string) => {
        onChange({
            ...activeFilters,
            [name]: {
                value,
            },
        });

        if (onChangeCallback) {
            onChangeCallback(value);
        }
    };

    const handleChangeNumberRange =
        (name: string, onChangeCallback?: (numberRange: NumberRange) => void) => (numberRange: NumberRange) => {
            onChange({
                ...activeFilters,
                [name]: {
                    value: numberRange,
                },
            });

            if (onChangeCallback) {
                onChangeCallback(numberRange);
            }
        };

    const renderRequired = (filter: IFilterItem, value: TableFilterValue) => {
        const text = getRequiredText({ required: filter.required, requiredText: filter.requiredText, value });
        if (!text) {
            return null;
        }

        return (
            <Tooltip title={text}>
                <ErrorIcon style={{ color: customColors.error, marginLeft: -4, marginRight: 4 }} />
            </Tooltip>
        );
    };

    return (
        <div style={{ display: "flex", marginTop: 24, flexWrap: "wrap", gap: 8, alignItems: "center" }}>
            {showAllButton !== false && (
                <TableFilterButton
                    key="all"
                    isSelected={isEmpty(activeFilters)}
                    onClick={handleToggleFilter("all")}
                    data-id={`sort_button_all`}
                >
                    {t("screen.hr.employee.current.all")}
                </TableFilterButton>
            )}
            {filters.map(category =>
                category.entries.map(filter => {
                    const isSelected = isFilterSelected(filter.name);
                    const value = activeFilters[filter.name]?.value;
                    const currentDateValue = (value ?? null) as Date | null;
                    const currentDateRangeValue = (value ?? null) as DateRange | null;
                    const currentDropdownValue = (value ?? null) as string | null;
                    const disabled =
                        typeof filter.disabled === "function" ? filter.disabled(activeFilters) : filter.disabled;

                    let content;
                    if (filter.type === DATE_FILTER_TYPE) {
                        content = (
                            <TableDatePickerFilterButton
                                onClick={handleToggleFilter(filter.name, filter.type)}
                                isSelected={isSelected}
                                onChange={handleChangeDate(filter.name, filter.onChange)}
                                disabled={disabled}
                            >
                                {currentDateValue ? formatMonth(currentDateValue) : filter.label}
                            </TableDatePickerFilterButton>
                        );
                    } else if (filter.type === DATE_RANGE_FILTER_TYPE) {
                        content = (
                            <TableDateRangePickerFilterButton
                                dateRange={currentDateRangeValue}
                                onClick={handleToggleFilter(filter.name, filter.type)}
                                isSelected={isSelected}
                                onChange={handleChangeDateRange(filter.name, filter.onChange)}
                                disabled={disabled}
                                filter={filter}
                            />
                        );
                    } else if (filter.type === DROPDOWN_FILTER_TYPE) {
                        let label: React.ReactNode = filter.label;
                        if (currentDropdownValue) {
                            const option = filter.options.find(option => option.value === currentDropdownValue);
                            if (option) {
                                label = option.label;
                            }
                        }

                        content = (
                            <TableDropdownFilterButton
                                onClick={handleToggleFilter(filter.name, filter.type)}
                                isSelected={isSelected}
                                onChange={handleChangeDropdownValue(filter.name, filter.onChange)}
                                options={filter.options}
                                listSettings={filter.listSettings}
                                disabled={disabled}
                                toggle={filter.toggle}
                                data-id={`sort_button_${filter.name}`}
                            >
                                {label}
                            </TableDropdownFilterButton>
                        );
                    } else if (filter.type === NUMBER_RANGE_FILTER_TYPE) {
                        content = (
                            <TableNumberRangePickerFilterButton
                                onClick={handleToggleFilter(filter.name, filter.type)}
                                isSelected={isSelected}
                                onChange={handleChangeNumberRange(filter.name, filter.onChange)}
                                disabled={disabled}
                                filter={filter}
                            />
                        );
                    } else {
                        content = (
                            <TableFilterButton
                                isSelected={isSelected}
                                onClick={handleToggleFilter(filter.name, filter.type)}
                                disabled={disabled}
                                data-id={`sort_button_${filter.name}`}
                            >
                                {filter.label}
                            </TableFilterButton>
                        );
                    }

                    return (
                        <React.Fragment key={filter.name}>
                            {content}
                            {renderRequired(filter, value)}
                        </React.Fragment>
                    );
                }),
            )}
        </div>
    );
};

const TableDatePickerFilterButton = ({
    children,
    onClick,
    isSelected,
    onChange,
    disabled,
}: ITableDatePickerFilterButtonProps) => {
    const [showDatePicker, setShowDatePicker] = React.useState(false);
    const [dateString, setDateString] = React.useState("");
    const [selectedDate, setDate] = React.useState(new Date());
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClickTableFilterButton = () => {
        if (isSelected) {
            // Reset states when next click will lead to deselection
            setDateString("");
            setDate(new Date());
        }

        onClick();
    };

    const handleOpenDatePicker = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget.firstChild as HTMLButtonElement);
        if (!isSelected) {
            setShowDatePicker(!showDatePicker);
        }
    };

    const handleChangeDateString = (date: MaterialUiPickersDate, value?: string | null) => {
        if (date) {
            setDateString(formatMonth(date));
            setShowDatePicker(false);
        }
    };

    return (
        <>
            <div onClick={disabled ? undefined : handleOpenDatePicker}>
                <TableFilterButton
                    isSelected={isSelected}
                    onClick={handleClickTableFilterButton}
                    disabled={disabled}
                    data-id="button_date"
                >
                    {dateString === "" || !isSelected ? children : dateString}
                    <Icon
                        size={20}
                        style={{
                            marginLeft: 1,
                            marginRight: -3,
                        }}
                        name={showDatePicker ? "dropUp" : "dropDown"}
                    />
                </TableFilterButton>
            </div>
            <Popover
                anchorEl={anchorEl}
                open={showDatePicker}
                onClose={() => {
                    setShowDatePicker(false);
                    if (!dateString) {
                        // untoggle Button in case date was not fully picked
                        onClick();
                    }
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <DatePicker
                    variant="static"
                    openTo="year"
                    views={["year", "month"]}
                    value={selectedDate}
                    onMonthChange={date => {
                        if (onChange && date) {
                            onChange(date?.toDate());
                        }
                        handleChangeDateString(date);
                    }}
                    onChange={date => {
                        if (date) {
                            setDate(date?.toDate());
                        }
                    }}
                    data-id="date_picker"
                    cancelLabel={t("common.cancel")}
                />
            </Popover>
        </>
    );
};

const TableDateRangePickerFilterButton = ({
    dateRange,
    onClick,
    isSelected,
    onChange,
    disabled,
    filter,
}: ITableDateRangePickerFilterButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = !!anchorEl;

    const from = dateRange?.from ?? null;
    const to = dateRange?.to ?? null;

    const handleTableFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (!from && !to) {
            onClick();
        }
    };

    return (
        <>
            <TableFilterButton
                isSelected={isSelected}
                onClick={handleTableFilterButtonClick}
                disabled={disabled}
                data-id="button_date_range"
            >
                {(isSelected && dateRange && formatDateRange(dateRange)) ?? filter.label}
                <Icon
                    size={20}
                    style={{
                        marginLeft: 1,
                        marginRight: -3,
                    }}
                    name={open ? "dropUp" : "dropDown"}
                />
            </TableFilterButton>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null);
                    if (!from && !to) {
                        // untoggle Button in case no date range was picked
                        onClick();
                    }
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <DateRangeFilter
                    dateRange={dateRange}
                    onChange={onChange}
                    filter={filter}
                    style={{ padding: 16, paddingRight: 8 }}
                />
            </Popover>
        </>
    );
};

interface DateRangeFilterProps {
    dateRange: DateRange | null;
    onChange: (value: DateRange) => void;
    filter: IDateRangeFilterItem;
    style?: React.CSSProperties;
}

export const DateRangeFilter = ({ dateRange, onChange, filter, style }: DateRangeFilterProps) => {
    const from = dateRange?.from ?? null;
    const to = dateRange?.to ?? null;

    const min = React.useMemo(() => {
        return filter.minDate ? moment(new Date(filter.minDate)).startOf("day") : filter.minDate;
    }, [filter.minDate]);
    const max = React.useMemo(() => {
        return filter.maxDate ? moment(new Date(filter.maxDate)).endOf("day") : filter.maxDate;
    }, [filter.maxDate]);

    return (
        <div style={style}>
            <DatePicker
                value={from}
                onChange={value => {
                    const from = value?.startOf("day").toDate() ?? null;
                    onChange({ from, to });
                }}
                inputVariant="outlined"
                format="DD.MM.YYYY"
                minDate={min}
                maxDate={to ? to : max}
                clearable
                label={t("common.from")}
                cancelLabel={t("common.cancel")}
                clearLabel={t("common.delete")}
                style={{ width: 110, marginRight: 8, flex: 1 }}
                data-id="date_range_picker_from"
            />
            <DatePicker
                value={to}
                onChange={value => {
                    const to = value?.endOf("day").toDate() ?? null;
                    onChange({ from, to });
                }}
                inputVariant="outlined"
                format="DD.MM.YYYY"
                minDate={from ? from : min}
                maxDate={max}
                clearable
                label={t("common.to")}
                cancelLabel={t("common.cancel")}
                clearLabel={t("common.delete")}
                style={{ width: 110, marginRight: 8, flex: 1 }}
                data-id="date_range_picker_to"
            />
            <IconButton
                onClick={() => {
                    onChange({ from: null, to: null });
                }}
            >
                <Icon name="delete" />
            </IconButton>
        </div>
    );
};

export function formatDateRange(range: DateRange) {
    const f = range.from ? moment(range.from).format("DD.MM.YYYY") : null;
    const t = range.to ? moment(range.to).format("DD.MM.YYYY") : null;
    if (f && t) {
        return `${f} - ${t}`;
    }
    return f ?? t;
}

const TableNumberRangePickerFilterButton = ({
    onClick,
    isSelected,
    onChange,
    disabled,
    filter,
}: ITableNumberRangePickerFilterButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = !!anchorEl;

    const [numberRange, setNumberRange] = React.useState<NumberRange | null>(null);

    const from = numberRange?.from ?? null;
    const to = numberRange?.to ?? null;

    React.useEffect(() => {
        if (!isSelected) {
            // clear the local value if the filter is no longer selected (e.g. the user clicked the "all" filter button)
            setNumberRange(null);
        }
    }, [isSelected]);

    const refOnChange = React.useRef(onChange);
    refOnChange.current = onChange;

    // Quick typing or using the number "wheel" in the input would cause a lot of onChange calls which would result
    // in a lot of network requests or high CPU to filter the table. Therefor the number range is kept locally
    // and only passed to onChange after some pause.
    const debouncedOnChange = React.useMemo(
        () =>
            debounce((v: NumberRange) => {
                refOnChange.current(v);
            }, SEARCH_DEBOUNCE_MS),
        [],
    );

    const handleTableFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (typeof from !== "number" && typeof to !== "number") {
            debouncedOnChange.cancel();
            onClick();
        }
    };

    return (
        <>
            <TableFilterButton
                isSelected={isSelected}
                onClick={handleTableFilterButtonClick}
                disabled={disabled}
                data-id="button_number_range"
            >
                {(isSelected && numberRange && formatNumberRange(numberRange)) ?? filter.label}
                <Icon
                    size={20}
                    style={{
                        marginLeft: 1,
                        marginRight: -3,
                    }}
                    name={open ? "dropUp" : "dropDown"}
                />
            </TableFilterButton>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null);
                    if (typeof from !== "number" && typeof to !== "number") {
                        // untoggle Button in case no number range was picked
                        debouncedOnChange.cancel();
                        onClick();
                    }
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <NumberRangeFilter
                    numberRange={numberRange}
                    onChange={numberRange => {
                        setNumberRange(numberRange);
                        debouncedOnChange(numberRange);
                    }}
                    filter={filter}
                    style={{ padding: 16, paddingRight: 8 }}
                />
            </Popover>
        </>
    );
};

interface NumberRangeFilterProps {
    numberRange: NumberRange | null;
    onChange: (value: NumberRange) => void;
    filter: INumberRangeFilterItem;
    style?: React.CSSProperties;
}

export const NumberRangeFilter = ({ numberRange, onChange, style }: NumberRangeFilterProps) => {
    const from = numberRange?.from ?? null;
    const to = numberRange?.to ?? null;

    return (
        <div style={style}>
            <TextField
                value={from ?? ""}
                onChange={event => {
                    onChange({ from: parseNumberRangeValue(event.target.value), to });
                }}
                variant="outlined"
                type="number"
                label={t("common.from")}
                style={{ width: 110, marginRight: 8, flex: 1 }}
                data-id="number_range_picker_from"
            />
            <TextField
                value={to ?? ""}
                onChange={event => {
                    onChange({ from, to: parseNumberRangeValue(event.target.value) });
                }}
                variant="outlined"
                type="number"
                label={t("common.to")}
                style={{ width: 110, marginRight: 8, flex: 1 }}
                data-id="number_range_picker_to"
            />
            <IconButton
                onClick={() => {
                    onChange({ from: null, to: null });
                }}
            >
                <Icon name="delete" />
            </IconButton>
        </div>
    );
};

function parseNumberRangeValue(value: string) {
    const parsed = value ? parseInt(value, 10) : null;
    return parsed && isNaN(parsed) ? null : parsed;
}

export function formatNumberRange(range: NumberRange) {
    const f = range.from ? range.from : null;
    const t = range.to ? range.to : null;
    if (typeof f === "number" && typeof t === "number") {
        return `${f} - ${t}`;
    }
    if (typeof f === "number") {
        return `${f}`;
    }
    if (typeof t === "number") {
        return `${t}`;
    }
    return null;
}

export const TableFilterButton = React.forwardRef<HTMLButtonElement, ITableFilterButtonProps>(
    function TableFilterButton(
        { isSelected, onClick, children, "data-id": dataId, disabled, style: styleProp, ...props },
        ref,
    ) {
        let style;
        if (disabled) {
            style = {
                color: customColors.primaryColor,
                border: `1px solid ${customColors.primaryColor}`,
                opacity: 0.5,
            };
        } else if (isSelected) {
            style = {
                color: customColors.white,
                backgroundColor: customColors.primaryColor,
                border: `1px solid ${customColors.primaryColor}`,
            };
        } else {
            style = {
                color: customColors.primaryColor,
                border: `1px solid ${customColors.primaryColor}`,
            };
        }
        if (styleProp) {
            style = { ...style, ...styleProp };
        }
        return (
            <TpaTableFilterButton
                {...props}
                ref={ref}
                disabled={disabled}
                data-id={dataId}
                variant={isSelected ? "contained" : "outlined"}
                style={style}
                onClick={onClick}
            >
                {children}
            </TpaTableFilterButton>
        );
    },
);

export const TableDropdownFilterButton = ({
    children,
    onClick,
    isSelected,
    onChange,
    disabled,
    "data-id": dataId,
    options,
    listSettings,
    toggle,
}: ITableDropdownFilterButtonProps) => {
    const [showDropdown, setShowDropdown] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleOpenDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget.firstChild as HTMLButtonElement);
        if (!isSelected || toggle === false) {
            setShowDropdown(!showDropdown);
        }
    };

    const handleCloseDropdown = (option?: ITableFilterOption) => {
        setAnchorEl(null);
        setShowDropdown(false);

        if (option) {
            onChange(option.value);
        } else {
            if (toggle !== false) {
                onClick();
            }
        }
    };

    return (
        <>
            <div onClick={disabled ? undefined : handleOpenDropdown}>
                <TableFilterButton
                    isSelected={isSelected}
                    onClick={toggle !== false ? onClick : undefined}
                    disabled={disabled}
                    data-id={dataId}
                >
                    {children}
                    <Icon
                        size={20}
                        style={{
                            marginLeft: 1,
                            marginRight: -3,
                        }}
                        name={showDropdown ? "dropUp" : "dropDown"}
                    />
                </TableFilterButton>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={showDropdown}
                onClose={() => {
                    handleCloseDropdown();
                }}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {listSettings?.map((listSetting, index) => (
                    <MenuItem disableRipple key={index} style={{ cursor: "unset" }}>
                        {listSetting}
                    </MenuItem>
                ))}
                {listSettings && <Divider />}
                {options.map(option => (
                    <MenuItem
                        key={option.value}
                        onClick={() => {
                            handleCloseDropdown(option);
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
