import { Checkbox, TableBody } from "@material-ui/core";
import { observer } from "mobx-react";
import { useCallback } from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { GetProjectsCompaniesResponseItem } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useInMemoryTableStore, useTableStore } from "../../../stores/TableStore";
import { pushRoute, withQuery } from "../../app/router/history";
import { useAPI } from "../../hooks/useAPI";
import { useQueryParams } from "../../hooks/useQueryParams";
import { CenteredContent } from "../../ui/CenteredContent";
import { EmptyState } from "../../ui/EmptyState";
import { NavBarBack } from "../../ui/NavBarBack";
import { TableLabel, TpaTable, TpaTableCell, TpaTableContainer, TpaTableRow } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { customColors } from "../../util/Theme";
import { ProjectsRoutes } from "../router/ProjectsRoutes";

const loader = async () => {
    return (await API.getProjectsCompanies()).companies;
};
const searchFn = (company: GetProjectsCompaniesResponseItem, search: string) => {
    return company.name.toLowerCase().includes(search);
};
const useCompaniesTableStore = () => {
    const companiesResponse = useAPI(loader, {
        generalStoreErrorMessage: "error.loadProjectsCompanies",
    });

    const tableStore = useTableStore<GetProjectsCompaniesResponseItem>("ProjectCompanies", {
        orderBy: "name",
        orderDir: "asc",
        limit: Number.MAX_SAFE_INTEGER,
    });

    useInMemoryTableStore({
        tableStore,
        items: companiesResponse,
        searchFn,
    });

    return tableStore;
};

interface Props {
    projectCompanies: GetProjectsCompaniesResponseItem[];
    setProjectCompanies: (companies: GetProjectsCompaniesResponseItem[]) => void;
}

export const ProjectsDataProjectCompaniesSite = observer(function ProjectsDataProjectCompaniesSite(props: Props) {
    const { projectCompanies, setProjectCompanies } = props;

    const queryParams = useQueryParams<Record<string, unknown>>();

    const tableStore = useCompaniesTableStore();

    const canSelect = useCallback(
        (company: GetProjectsCompaniesResponseItem) => {
            return company.id !== companiesStore.selectedCompanyId && !projectCompanies.find(c => c.id === company.id);
        },
        [projectCompanies],
    );
    tableStore.canSelect = canSelect;

    const headerFields: ITableHeaderConfig<GetProjectsCompaniesResponseItem>[] = [
        { column: "name", label: "table.label.companyName" },
    ];

    const tableBody = (
        <TableBody>
            {tableStore.items.map((company, index) => {
                const disabled = !canSelect(company);
                const color = disabled ? customColors.disabled : undefined;

                return (
                    <TpaTableRow key={company.id}>
                        <TpaTableCell padding="checkbox">
                            <Checkbox
                                data-id={`company_checkbox_${index}`}
                                onChange={(event, checked) => {
                                    tableStore.toggleSelection(company);
                                }}
                                color="primary"
                                checked={tableStore.isSelected(company)}
                                disabled={disabled}
                            />
                        </TpaTableCell>
                        {headerFields.map(({ column }, index) => {
                            const label = company[column];

                            return (
                                <TpaTableCell key={column}>
                                    <TableLabel style={{ color, maxWidth: 700 }}>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    const isEmpty = tableStore.getIsEmptyState(generalStore.isLoading, false);

    const backLabel = t("projects.new");
    const backTarget = withQuery(ProjectsRoutes.NEW_PROJECT, queryParams);

    const handleClickSetCompanies = () => {
        const selectedCompanies = tableStore.getAllSelectedItems();
        const companies = [...projectCompanies, ...selectedCompanies].sort((c1, c2) => c1.name.localeCompare(c2.name));
        setProjectCompanies(companies);

        pushRoute(backTarget);
    };

    return (
        <>
            <NavBarBack
                title={t("projects.addCompanies")}
                companyName={companiesStore.selectedCompany?.name}
                backLabel={backLabel}
                backTarget={backTarget}
            />
            <CenteredContent>
                {isEmpty && <EmptyState title={t("projects.companies.emptyState.title")} />}
                {!isEmpty && (
                    <SiteContent>
                        <TableSearchBarWithAction
                            label="search.caption.numCompanies"
                            placeholder="search.placeholder.companies"
                            labelSelected="search.caption.numSelected"
                            select={tableStore}
                            search={tableStore.search}
                            totalCount={tableStore.totalCount}
                            onChangeSearch={tableStore.handleSearchChange}
                            buttonLabel={t("projects.companies.submit")}
                            onAction={tableStore.selectedItems.size > 0 ? handleClickSetCompanies : undefined}
                        />
                        <TpaTableContainer>
                            <TpaTable>
                                <TableHeader
                                    allowMultiSelectAlways
                                    headerFields={headerFields}
                                    tableStore={tableStore}
                                    select={tableStore}
                                />
                                {tableBody}
                            </TpaTable>
                        </TpaTableContainer>
                        <tableStore.Pagination />
                    </SiteContent>
                )}
                {tableStore.getIsNoResultState(generalStore.isLoading, false) && (
                    <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                )}
            </CenteredContent>
        </>
    );
});
