import * as React from "react";
import { t } from "../../i18n/util";
import { MobileNavBarContainer, NavBarContainer, NavBarTitle } from "../ui/NavBarContainer";
import { MobileContext } from "../util/MobileContext";

interface IProps {
    title: string;
    backTarget: string;
    backLabel?: string;
}

export const CockpitNavBar = (props: IProps) => {
    const isMobile = React.useContext(MobileContext);

    const title = <NavBarTitle>{props.title}</NavBarTitle>;
    const backLabel = props.backLabel ?? t("common.cockpit");

    return isMobile ? (
        <MobileNavBarContainer backLabel={backLabel} backTarget={props.backTarget}>
            {title}
        </MobileNavBarContainer>
    ) : (
        <NavBarContainer backLabel={backLabel} backTarget={props.backTarget}>
            {title}
        </NavBarContainer>
    );
};
