// The following components define a basic table layout using CSS grid that resembles the one in `Primitives`.
// It does not provide a default column layout, this is up to you to define via the `gridTemplateColumn` on the `GridTableColumn`.
//
// The problem of CSS table layout is that it either provides:
// * a dynamic layout using `table-layout: auto` (the default)
//   which grows indefinitely based on its content - which sometimes is not desired
// * a fixed layout using `table-layout: fixed` which solves the above problem
//   but requires pixel/%/... on all cells in order to work correctly, hence loosing the dynamic aspect
//
// A grid layout on the other hand solves all these problems:
// fixed width on the root, dynamic and fixed cell size

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { action } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useImperativeHandle, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { getId, ItemId, ItemWithId, TableStore } from "../../stores/TableStore";
import { Icon, IIconNames } from "../util/Icon";
import { BOX_SHADOW_LIGHT, theme } from "../util/Theme";
import { ContextMenu, ContextMenuItem, useContextMenu } from "./ContextMenu";
import { ITableHeaderConfig, TableHeader } from "./TableHeader";

interface GridTableRootProps {
    gridTemplateColumns: React.CSSProperties["gridTemplateColumns"];
}
const GridTableRoot = styled.div<GridTableRootProps>`
    display: grid;
    grid-template-columns: ${props => props.gridTemplateColumns};
`;

const rowGap = 8;

const highlight = keyframes`
    to {
        opacity: 1;
    }
`;

const RowStyle = styled.div`
    box-shadow: ${BOX_SHADOW_LIGHT};
    border-radius: 4px;
    background-color: white;
    margin-bottom: ${rowGap}px;
    position: relative;
    z-index: 0;

    &::after {
        content: "";
        box-shadow: 0 0px 5px 1px #3da35d;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    &.highlight::after {
        animation: ${highlight} 2s;
        animation-fill-mode: forwards;
    }
`;

interface GridTableRowProps<Item extends ItemWithId = ItemWithId> {
    rowIndex: number;
    item: Item;
    columns: GridTableColumn<Item>[];
    tableStore: TableStore<Item>;
    renderCell: RenderCell<Item>;
    renderExpanded?: RenderExpanded<Item>;
    getContextMenuItems?: GetContextMenuItems<Item>;
}
const GridTableRow = observer(function GridTableRow<Item extends ItemWithId = ItemWithId>({
    rowIndex,
    item,
    columns,
    tableStore,
    renderCell,
    renderExpanded,
    getContextMenuItems,
}: GridTableRowProps<Item>) {
    const id = getId(item);

    const expanded = tableStore.expandedItems.get(id);

    const rowStart = rowIndex + 1;
    const expandedRowStart = rowIndex + 2;
    const expandedRowEnd = rowIndex + 3;
    const columnEnd = columns.length + 1;
    return (
        <>
            <RowStyle
                data-id={id}
                style={{
                    gridRowStart: rowStart,
                    gridRowEnd: expanded ? expandedRowEnd : expandedRowStart,
                    gridColumnStart: 1,
                    gridColumnEnd: columnEnd,
                }}
            />
            {columns.map((column, columnIndex) => {
                let content;
                if (column.column === expandColumn) {
                    const disabled = tableStore.canExpand ? !tableStore.canExpand(item) : false;
                    content = (
                        <IconButton
                            size="small"
                            onClick={() => {
                                tableStore.expandedItems.set(id, expanded === true ? "collapsing" : true);
                            }}
                            disabled={disabled}
                        >
                            <Icon name={expanded ? "chevronUp" : "chevronDown"} />
                        </IconButton>
                    );
                } else if (column.column === contextMenuColumn) {
                    content = <GridTableContextMenu item={item} getContextMenuItems={getContextMenuItems} />;
                } else {
                    content = renderCell(item, column);
                }

                return (
                    <GridTableCell
                        key={column.column.toString()}
                        rowIndex={rowIndex}
                        columnIndex={columnIndex}
                        align={column.align}
                        style={column.style}
                    >
                        {content}
                    </GridTableCell>
                );
            })}
            <Collapse
                in={expanded === true} // "collapsing" will trigger the closing animation
                timeout="auto"
                style={{
                    gridRowStart: expandedRowStart,
                    gridRowEnd: expandedRowEnd,
                    gridColumnStart: 1,
                    gridColumnEnd: columnEnd,
                    marginTop: -rowGap, // remove the gap between the expanded content and the row above it
                    marginBottom: rowGap, // but keep the gap to the next row
                    zIndex: 1,
                }}
                onExited={() => {
                    tableStore.expandedItems.delete(id);
                }}
            >
                {/* render the expanded content as long as the row is expanded or is "collapsing" */}
                {expanded ? renderExpanded?.(item) : null}
            </Collapse>
        </>
    );
});

const GridTableCellWrapper = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
    height: 56px;
    padding: 0 13px;
    margin-bottom: ${rowGap}px;
    white-space: nowrap;
    z-index: 1;
`;
interface GridTableCellProps {
    rowIndex: number;
    columnIndex: number;
    align?: "right";
    className?: string;
    style?: React.CSSProperties;
    padding?: "checkbox";
    children?: React.ReactNode;
}
const GridTableCell = ({ rowIndex, columnIndex, align, className, style, padding, children }: GridTableCellProps) => {
    style = { ...style, gridRow: rowIndex + 1, gridColumn: columnIndex + 1 };
    if (align === "right") {
        style.textAlign = align;
        style.flexDirection = "row-reverse"; // required to display the TableSort correctly
    }
    if (padding === "checkbox") {
        // same as @material-ui TableCell padding
        style.width = "48px";
        style.padding = "0 0 0 4px";
    }
    return (
        <GridTableCellWrapper className={className} style={style}>
            {children}
        </GridTableCellWrapper>
    );
};

const GridTableHeaderCell = styled(GridTableCell).attrs<GridTableCellProps>(({ style }) => {
    return {
        rowIndex: 0,
        style: {
            ...theme.overrides?.MuiTableCell?.head, // same style as the table header
            ...style,
        },
    };
})<GridTableCellProps>`
    margin-bottom: 0;
    position: sticky;
    background: ${theme.palette.background.default};
    z-index: 2;
`;

interface GridTableContextMenuProps<Item extends ItemWithId = ItemWithId> {
    item: Item;
    getContextMenuItems?: GetContextMenuItems<Item>;
}
const GridTableContextMenu = observer(function GridTableContextMenu<Item extends ItemWithId = ItemWithId>({
    item,
    getContextMenuItems,
}: GridTableContextMenuProps<Item>) {
    const contextMenu = useContextMenu<Item>();
    const contextMenuItems = getContextMenuItems?.(item) ?? [];
    const disabled = contextMenuItems.length === 0;

    return (
        <>
            <IconButton
                size="small"
                onClick={event => {
                    contextMenu.open(event, item);
                }}
                disabled={disabled}
            >
                <Icon name="more" />
            </IconButton>
            <ContextMenu
                data-id="context_menu"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                config={contextMenu}
                items={contextMenuItems}
            />
        </>
    );
});

interface GridTableHeaderProps<Item extends ItemWithId = ItemWithId> {
    headerFields: ITableHeaderConfig<Item>[];
    tableStore: TableStore<Item>;
    renderLabel?: (column: ITableHeaderConfig<Item>) => React.ReactNode;
}
const GridTableHeader = observer(function GridTableHeader<Item extends ItemWithId = ItemWithId>(
    props: GridTableHeaderProps<Item>,
) {
    return (
        <TableHeader
            {...props}
            slots={{
                Head: React.Fragment,
                Row: React.Fragment,
                Cell: GridTableHeaderCell,
            }}
        />
    );
});

interface GridTableExpandAllButtonProps<Item extends ItemWithId = ItemWithId> {
    tableStore: TableStore<Item>;
}
const GridTableExpandAllButton = observer(function GridTableExpandAllButton<Item extends ItemWithId = ItemWithId>({
    tableStore,
}: GridTableExpandAllButtonProps<Item>) {
    const all = tableStore.allExpanded;
    const some = tableStore.someExpanded;

    let iconName: IIconNames = "chevronDown";
    if (all) {
        iconName = "chevronUp";
    } else if (some) {
        iconName = "chevronRight";
    }
    return (
        <IconButton
            size="small"
            onClick={action(() => {
                tableStore.expandedItems.clear();
                if (some || !all) {
                    // expand all expandable items
                    tableStore.expandableItems.forEach(item => {
                        tableStore.expandedItems.set(getId(item), true);
                    });
                } else if (all) {
                    // collapse everything
                }
            })}
        >
            <Icon name={iconName} />
        </IconButton>
    );
});

export interface GridTableColumn<Item extends ItemWithId = ItemWithId> extends ITableHeaderConfig<Item> {
    gridTemplateColumn: React.CSSProperties["gridTemplateColumns"];
}

const expandColumn = `expand${Math.random()}` as never; // "unique" name to avoid clashes

export function createExpandColumn<Item extends ItemWithId = ItemWithId>(
    props: Partial<GridTableColumn<Item>>,
): GridTableColumn<Item> {
    return {
        column: expandColumn,
        gridTemplateColumn: "min-content",
        sort: false,
        style: { padding: 8, ...props.style },
        ...props,
    };
}

const contextMenuColumn = `contextMenu${Math.random()}` as never; // "unique" name to avoid clashes

export function createContextMenuColumn<Item extends ItemWithId = ItemWithId>(
    props: Partial<GridTableColumn<Item>>,
): GridTableColumn<Item> {
    return {
        column: contextMenuColumn,
        gridTemplateColumn: "min-content",
        sort: false,
        style: { padding: 8, ...props.style },
        ...props,
    };
}

export type RenderCell<Item extends ItemWithId = ItemWithId> = (
    item: Item,
    column: GridTableColumn<Item>,
) => React.ReactNode;
export type RenderExpanded<Item extends ItemWithId = ItemWithId> = (item: Item) => React.ReactNode;
export type GetContextMenuItems<Item extends ItemWithId = ItemWithId> = (item: Item) => ContextMenuItem[];

export interface GridTableRef {
    focus(id: ItemId): void;
}

interface GridTableProps<Item extends ItemWithId = ItemWithId> {
    columns: GridTableColumn<Item>[];
    tableStore: TableStore<Item>;

    renderCell: RenderCell<Item>;
    renderExpanded?: RenderExpanded<Item>;
    getContextMenuItems?: GetContextMenuItems<Item>;

    renderFooterCell?: RenderFooterCell<Item>;

    className?: string;
    gridTableRef?: React.Ref<GridTableRef>;
}

export const GridTable = observer(function GridTable<Item extends ItemWithId = ItemWithId>(
    props: GridTableProps<Item>,
) {
    const { columns, tableStore, renderCell, renderExpanded, getContextMenuItems, renderFooterCell, className } = props;

    const rootRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(props.gridTableRef, () => {
        return {
            focus(id) {
                const element = rootRef.current?.querySelector(`[data-id="${id}"]`);
                if (!element) {
                    return;
                }

                element.scrollIntoView({ block: "center" });
                element.classList.add("highlight");
            },
        };
    });

    const renderLabel = useCallback(
        (column: ITableHeaderConfig<Item>) => {
            if (column.column !== expandColumn) {
                return undefined; // leave it to the TableHeader to render the label
            }

            return <GridTableExpandAllButton tableStore={tableStore} />;
        },
        [tableStore],
    );

    const gridTemplateColumns = columns.map(column => column.gridTemplateColumn).join(" ");

    let rowIndex = 1; // 0 is the header row

    return (
        <GridTableRoot gridTemplateColumns={gridTemplateColumns} ref={rootRef} className={className}>
            <GridTableHeader headerFields={columns} tableStore={tableStore} renderLabel={renderLabel} />
            {tableStore.items.map(item => {
                const id = getId(item);
                const expanded = tableStore.expandedItems.get(id) ?? false;
                const rowIndexWithHeaderRow = rowIndex++;
                if (expanded) {
                    rowIndex++; // the expanded content takes up another row
                }
                return (
                    <GridTableRow
                        key={id}
                        rowIndex={rowIndexWithHeaderRow}
                        item={item}
                        columns={columns}
                        tableStore={tableStore}
                        renderCell={renderCell}
                        renderExpanded={renderExpanded}
                        getContextMenuItems={getContextMenuItems}
                    />
                );
            })}
            {renderFooterCell ? (
                <GridTableFooterRow
                    rowIndex={rowIndex + 1}
                    columns={columns}
                    tableStore={tableStore}
                    renderFooterCell={renderFooterCell}
                />
            ) : null}
        </GridTableRoot>
    );
});

interface GridTableFooterRowProps<Item extends ItemWithId = ItemWithId> {
    rowIndex: number;
    columns: GridTableColumn<Item>[];
    tableStore: TableStore<Item>;
    renderFooterCell: RenderFooterCell<Item>;
}

const GridTableFooterRow = observer(function GridTableFooterRow<Item extends ItemWithId = ItemWithId>({
    rowIndex,
    columns,
    tableStore,
    renderFooterCell,
}: GridTableFooterRowProps<Item>) {
    const rowStart = rowIndex + 1;
    const expandedRowStart = rowIndex + 2;
    const columnEnd = columns.length + 1;
    return (
        <>
            <RowStyle
                style={{
                    gridRowStart: rowStart,
                    gridRowEnd: expandedRowStart,
                    gridColumnStart: 1,
                    gridColumnEnd: columnEnd,
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1,
                }}
            />

            {columns.map((column, columnIndex) => (
                <GridTableCell
                    key={column.column.toString()}
                    rowIndex={rowIndex}
                    columnIndex={columnIndex}
                    align={column.align}
                    style={{
                        position: "sticky",
                        bottom: 0,
                    }}
                >
                    {renderFooterCell(tableStore.items, column)}
                </GridTableCell>
            ))}
        </>
    );
});

export type RenderFooterCell<Item extends ItemWithId = ItemWithId> = (
    items: Item[],
    column: GridTableColumn<Item>,
) => React.ReactNode;
