import compact from "lodash/compact";
import { t } from "../../i18n/util";
import { withParamsAndQuery, withQuery } from "../app/router/history";
import { Stepper } from "../ui/Stepper";
import { HrRoutes } from "./router/HrRoutes";
import { IRegistrationQuery } from "./types";

export const HrEmployeesRegistrationStepper = ({
    employeeId,
    query,
}: {
    employeeId: string;
    query: IRegistrationQuery;
}) => {
    const hrEmployeesRegistrationSteps = compact([
        {
            label: t("screen.hr.employee.registration.stepper.personal"),
            url: withQuery(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA, { employeeId, ...query }),
        },
        {
            label: t("screen.hr.employee.registration.stepper.additional"),
            optional: true,
            url: withParamsAndQuery(HrRoutes.EMPLOYEES.REGISTRATION_ADDITIONAL_DATA, { employeeId }, query),
        },
        // During edit -> hide document upload from flow
        !query.isEdit && {
            label: t("screen.hr.employee.registration.stepper.documents"),
            optional: true,
            url: withParamsAndQuery(HrRoutes.EMPLOYEES.REGISTRATION_DOCUMENTS, { employeeId }, query),
        },
        {
            label: t("screen.hr.employee.registration.stepper.summary"),
            url: withParamsAndQuery(HrRoutes.EMPLOYEES.REGISTRATION_SUMMARY, { employeeId }, query),
        },
    ]);

    return <Stepper steps={hrEmployeesRegistrationSteps} />;
};
