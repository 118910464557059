import { observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { Module } from "../../../types/models";
import { handlePostTicketError } from "../../../util/helpers";
import { Routes } from "../../app/router/Routes";
import { pushRoute, withQuery } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { useExternalDocuments } from "../../hooks/useExternalDocuments";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useRecordTicket } from "../../hooks/useRecordTicket";
import { TicketSiteContent } from "../../shared/TicketSiteContent";
import { TicketsRoutes } from "../../tickets/router/TicketsRoutes";
import { NavBarBack } from "../../ui/NavBarBack";
import { webhookStore } from "../WebhookStore";
import { WebhookRoutes } from "../router/WebhookRoutes";

export const WebhookDocumentTicketsDetailsSite = observer(function WebhookDocumentTicketsDetailsSite() {
    useHideSideBar();

    const { companyId, module, periodId, subsidiaryId, recordTypeId } = useParams<{
        recordTypeId: string;
        companyId: string;
        periodId: string;
        module: string;
        subsidiaryId: string;
    }>();
    const { archive, documentId, recordId, databox, dueDate, taskTitle } = useQueryParams<{
        archive: string;
        documentId: string[];
        recordId: string;
        databox: string[];
        dueDate: string;
        taskTitle: string;
    }>({
        arrayKeys: ["documentId", "databox"],
    });

    const [ticketId, setTicketId] = React.useState("");

    const { documents, errorDialog } = useExternalDocuments({
        archive,
        documentIds: documentId,
        databoxIds: databox,
        initialState: webhookStore.document ? [webhookStore.document] : [],
        generateThumbnails: true,
    });
    const document = documents?.[0];
    const { company } = useCompany({ companyId, preloaded: webhookStore.company });

    const ticket = useRecordTicket({
        companyId,
        module: module as Module,
        periodId,
        subsidiaryId,
        recordTypeId,
        recordId: recordId ?? document?.record?.id,
        showError: false,
    });

    React.useEffect(() => {
        if (ticket) {
            setTicketId(ticket.id);
        }
    }, [ticket]);

    const handleCreateTicket = async (message: string): Promise<string | undefined> => {
        let ret = undefined;

        if (companyId && periodId && subsidiaryId && recordTypeId && module && document.document?.id && !ticketId) {
            try {
                generalStore.isLoading = true;
                const ticket = await API.postTicket(companyId, {
                    message: message ?? "",
                    record: {
                        periodId,
                        subsidiaryId,
                        recordTypeId,
                        moduleType: module as Module,
                        documentId: document.document.id,
                    },
                    dueDate,
                    taskTitle,
                });
                setTicketId(ticket.id);
                ret = ticket.id;

                // Update badge count
                if (companyId === companiesStore.selectedCompanyStore?.id) {
                    await companiesStore.selectedCompanyStore.startPollingBadges();
                }
            } catch (error) {
                handlePostTicketError(error);
            } finally {
                generalStore.isLoading = false;
            }
        }

        return ret;
    };

    const handleRecordDownload = async () => {
        try {
            await API.putDownloadRecords({
                companyId,
                module: module as Module,
                subsidiaryId,
                periodId,
                recordTypeId,
                recordIds: [recordId ?? document?.record?.id ?? ""],
            });
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        }
    };

    return (
        <>
            <NavBarBack
                backLabel={t("webhook.tickets.title")}
                backTarget={withQuery(WebhookRoutes.TICKETS, {
                    archive,
                    documentId,
                    databox,
                    dueDate,
                    companyId,
                    module,
                    periodId,
                    subsidiaryId,
                    taskTitle,
                    recordTypeId,
                })}
                cancelLabel={t("button.toOverview").toUpperCase()}
                onCancel={() => {
                    if (companyId) {
                        pushRoute(TicketsRoutes.OPEN);
                        coordinator.selectCompanyById(companyId);
                    } else {
                        pushRoute(Routes.COMPANY_OVERVIEW);
                    }
                }}
                title={t("webhook.tickets.navbar", { document: documents[0]?.name })}
                companyName={company?.name}
            />
            <TicketSiteContent
                companyId={companyId}
                document={document?.document}
                record={document?.record}
                onCreateTicket={handleCreateTicket}
                ticketId={ticketId}
                onDownloadDocument={handleRecordDownload}
            />
            {errorDialog}
        </>
    );
});
