import compact from "lodash/compact";
import sortBy from "lodash/sortBy";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { IMessageIDS, t } from "../../../i18n/util";
import { GetCompanyDeadlinesResponse, TicketReferenceType } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { periodToString } from "../../../stores/ModuleStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { IBasicPeriod } from "../../../types/models";
import { AccountingRoutes } from "../../accounting/router/AccountingRoutes";
import { pushRoute, withParams } from "../../app/router/history";
import { useDueDates } from "../../hooks/useDueDates";
import { HrRoutes } from "../../hr/router/HrRoutes";
import { ProjectsRoutes } from "../../projects/router/ProjectsRoutes";
import { CenteredContent } from "../../ui/CenteredContent";
import { ConfirmationDialog } from "../../ui/ConfirmationDialog";
import { LinkCard } from "../../ui/LinkCard";
import { SiteContent } from "../../ui/SiteContent";
import { IIconNames } from "../../util/Icon";
import { CockpitNavBar } from "../CockpitNavBar";
import { CockpitRoutes } from "../router/CockpitRoutes";

type DueDateType = keyof GetCompanyDeadlinesResponse;

const getTitleFromType = (
    type: DueDateType,
    module: string,
    subsidiaryName?: string,
    period?: IBasicPeriod,
    documentName?: string,
) => {
    if (module === "accounting" || module === "hr") {
        switch (type) {
            case "recordsAccounting":
                return t("screen.cockpit.dueDates.records.accounting.text", {
                    subsidiaryName,
                    period: periodToString(period),
                });
            case "recordsPersonnel":
                return t("screen.cockpit.dueDates.records.hr.text", { subsidiaryName, period: periodToString(period) });
            case "reportsAccounting":
            case "reportsPersonnel":
                if (subsidiaryName) {
                    return t(
                        authStore.isTpa
                            ? "screen.cockpit.dueDates.reportUpload.subsidiary.text"
                            : "screen.cockpit.dueDates.reports.subsidiary.text",
                        {
                            subsidiaryName,
                            period: periodToString(period),
                        },
                    );
                } else {
                    return t(
                        authStore.isTpa
                            ? "screen.cockpit.dueDates.reportUpload.global.text"
                            : "screen.cockpit.dueDates.reports.global.text",
                        { period: periodToString(period) },
                    );
                }
            case "ticketsAccounting":
            case "ticketsPersonnel":
                return subsidiaryName
                    ? t("screen.cockpit.dueDates.tickets.subsidiary.text", { subsidiaryName, documentName })
                    : t("screen.cockpit.dueDates.tickets.global.text", { documentName });
        }
    } else if (module === "projects") {
        return t("screen.cockpit.dueDates.projects.text", { documentName });
    }

    return "";
};

const dueDatesConfig: Record<string, { title: IMessageIDS; icon: IIconNames }> = {
    accounting: { title: "common.accounting", icon: "accounting" },
    hr: { title: "common.hr", icon: "personnel" },
    projects: { title: "common.projects", icon: "projects" },
};

interface DueDateLinkCardProps {
    title: string;
    module: string;
    dueDate: string;
    onClick: () => void;
}

const DueDateLinkCard = ({ title, module, dueDate, onClick }: DueDateLinkCardProps) => {
    const overdue = moment() > moment(dueDate);

    return (
        <LinkCard
            onClick={onClick}
            name={dueDatesConfig[module].icon}
            status={overdue ? "overdue" : "open"}
            title={title}
            style={{ marginTop: 8 }}
            date={{
                date: new Date(dueDate),
                overdue,
                labelId: "dueDate.until",
            }}
        />
    );
};

const useBmdDialog = () => {
    const [bmdDialogOpen, setBmdDialogOpen] = React.useState(false);
    const [bmdDialogTarget, setBmdDialogTarget] = React.useState<string>("");

    const bmdDialog = (
        <ConfirmationDialog
            open={bmdDialogOpen}
            title={t("screen.cockpit.dueDates.reportUpload.bmd.title")}
            message={t("screen.cockpit.dueDates.reportUpload.bmd.message")}
            onConfirm={() => {
                setBmdDialogOpen(false);
                setBmdDialogTarget("");
            }}
            confirmLabel={t("common.ok")}
            cancelLabel={t("button.toOverview")}
            onCancel={() => {
                setBmdDialogOpen(false);
                pushRoute(bmdDialogTarget);
                setBmdDialogTarget("");
            }}
        />
    );

    return {
        openBmdDialog: (target: string) => {
            setBmdDialogOpen(true);
            setBmdDialogTarget(target);
        },
        bmdDialog,
    };
};

export const CockpitDueDatesSite = observer(function CockpitDueDatesSite() {
    useHideSideBar();
    const { dueDates } = useDueDates();
    const { openBmdDialog, bmdDialog } = useBmdDialog();

    interface Params {
        subsidiaryId?: string;
        periodId?: string;
        projectId?: string;
        projectItemId?: string;
        recordTypeId?: string;
        referenceType?: TicketReferenceType;
        ticketId?: string;
    }

    const handleClickDueDate = (module: string, type: DueDateType, params: Params) => async () => {
        if (module === "accounting" || module === "hr") {
            const moduleStore = getModuleStore(module);
            if (
                type === "recordsAccounting" ||
                type === "reportsAccounting" ||
                type === "recordsPersonnel" ||
                type === "reportsPersonnel"
            ) {
                let paramsOk = false;
                if (params.periodId) {
                    paramsOk = await moduleStore.selectPeriodById(params.periodId, params.subsidiaryId);
                }

                if (!paramsOk) {
                    return;
                }
            }

            if (type === "recordsAccounting" || type === "recordsPersonnel") {
                pushRoute(moduleStore.routes.ROOT, { query: { activeCard: "welcome" } });
            } else if (type === "reportsAccounting" || type === "reportsPersonnel") {
                if (authStore.isTpa) {
                    openBmdDialog(moduleStore.routes.ROOT);
                } else {
                    pushRoute(moduleStore.routes.ROOT, {
                        query: { activeCard: params.subsidiaryId ? "reports" : "global-reports" },
                    });
                }
            } else if (type === "ticketsAccounting") {
                if (params.referenceType === "accountingRecord" && params.ticketId && params.recordTypeId) {
                    pushRoute(
                        withParams(AccountingRoutes.RECORDS.TICKET_DETAILS, {
                            recordTypeId: params.recordTypeId,
                            ticketId: params.ticketId,
                        }),
                    );
                }
            } else if (type === "ticketsPersonnel") {
                if (params.referenceType === "hrRecord" && params.ticketId && params.recordTypeId) {
                    pushRoute(
                        withParams(HrRoutes.RECORDS.TICKET_DETAILS, {
                            recordTypeId: params.recordTypeId,
                            ticketId: params.ticketId,
                        }),
                    );
                } else if (params.referenceType === "employeePreRegistration" && params.ticketId) {
                    pushRoute(HrRoutes.EMPLOYEES.TICKET_DETAILS, {
                        params: { ticketId: params.ticketId },
                        query: { genericBackLabel: true },
                    });
                }
            }
        } else if (module === "projects") {
            if (params.projectId && params.projectItemId) {
                pushRoute(
                    withParams(ProjectsRoutes.RELEASES_PROJECT_ITEM, {
                        projectId: params.projectId,
                        projectItemId: params.projectItemId,
                    }),
                );
            }
        }
    };

    const recordsAccountingDueDates = dueDates.recordsAccounting.map(dueDate => ({
        dueDate: dueDate.dueDate,
        onClick: handleClickDueDate("accounting", "recordsAccounting", dueDate),
        title: getTitleFromType("recordsAccounting", dueDate.module, dueDate.subsidiaryName, dueDate),
        module: "accounting",
    }));

    const reportsAccountingDueDates = dueDates.reportsAccounting.map(dueDate => ({
        dueDate: dueDate.dueDate,
        onClick: handleClickDueDate("accounting", "reportsAccounting", dueDate),
        title: getTitleFromType("reportsAccounting", dueDate.module, dueDate.subsidiaryName, dueDate),
        module: "accounting",
    }));

    const ticketsAccountingDueDates = dueDates.ticketsAccounting.map(dueDate => ({
        dueDate: dueDate.dueDate,
        onClick: handleClickDueDate("accounting", "ticketsAccounting", dueDate),
        title: getTitleFromType("ticketsAccounting", "accounting", undefined, undefined, dueDate.subject), //, dueDate.subsidiaryName),
        module: "accounting",
    }));

    const recordsPersonnelDueDates = dueDates.recordsPersonnel.map(dueDate => ({
        dueDate: dueDate.dueDate,
        onClick: handleClickDueDate("hr", "recordsPersonnel", dueDate),
        title: getTitleFromType("recordsPersonnel", dueDate.module, dueDate.subsidiaryName, dueDate),
        module: "hr",
    }));

    const reportsPersonnelDueDates = dueDates.reportsPersonnel.map(dueDate => ({
        dueDate: dueDate.dueDate,
        onClick: handleClickDueDate("hr", "reportsPersonnel", dueDate),
        title: getTitleFromType("reportsPersonnel", dueDate.module, dueDate.subsidiaryName, dueDate),
        module: "hr",
    }));

    const ticketsPersonnelDueDates = dueDates.ticketsPersonnel.map(dueDate => ({
        dueDate: dueDate.dueDate,
        onClick: handleClickDueDate("hr", "ticketsPersonnel", dueDate),
        title: getTitleFromType("ticketsPersonnel", "hr", undefined, undefined, dueDate.subject), //, dueDate.subsidiaryName),
        module: "hr",
    }));

    const projectsDueDates = dueDates.projects.map(dueDate => ({
        dueDate: dueDate.dueDate,
        onClick: handleClickDueDate("projects", "projects", dueDate),
        title: getTitleFromType("projects", "projects", undefined, undefined, dueDate.name), //, dueDate.subsidiaryName),
        module: "projects",
    }));

    const outputConfig = [
        {
            module: "accounting",
            title: t("common.accounting"),
            dueDates: sortBy(
                [...recordsAccountingDueDates, ...reportsAccountingDueDates, ...ticketsAccountingDueDates],
                config => new Date(config.dueDate),
            ),
        },
        {
            module: "hr",
            title: t("common.hr"),
            dueDates: sortBy(
                [...recordsPersonnelDueDates, ...reportsPersonnelDueDates, ...ticketsPersonnelDueDates],
                config => new Date(config.dueDate),
            ),
        },
        {
            module: "projects",
            title: t("common.projects"),
            dueDates: sortBy(projectsDueDates, config => new Date(config.dueDate)),
        },
    ];

    return (
        <>
            <CockpitNavBar title={t("screen.cockpit.dueDates.navbar.heading")} backTarget={CockpitRoutes.ROOT} />
            <CenteredContent>
                <SiteContent style={{ display: "flex", flexDirection: "column" }}>
                    {compact(
                        outputConfig.map((config, configIndex) => {
                            if (config.dueDates.length === 0) {
                                return null;
                            }

                            return (
                                <div key={config.module} style={{ marginTop: configIndex > 0 ? 24 : undefined }}>
                                    <h4 style={{ paddingBottom: 8 }}>{config.title}</h4>
                                    {config.dueDates.map((dueDateConfig, index) => (
                                        <DueDateLinkCard
                                            key={index}
                                            dueDate={dueDateConfig.dueDate}
                                            title={dueDateConfig.title}
                                            onClick={dueDateConfig.onClick}
                                            module={dueDateConfig.module}
                                        />
                                    ))}
                                </div>
                            );
                        }),
                    )}
                </SiteContent>
            </CenteredContent>
            {bmdDialog}
        </>
    );
});
