import { TableBody } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Message } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { TableStore } from "../../../stores/TableStore";
import { clearHtmlLineBreaks, formatDate } from "../../../util/helpers";
import { pushRoute } from "../../app/router/history";
import { useCompanyContextMenu } from "../../hooks/useCompanyContextMenu";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useTableFilters } from "../../hooks/useTableFilters";
import { CenteredContent } from "../../ui/CenteredContent";
import { ContextMenu } from "../../ui/ContextMenu";
import { EmptyState } from "../../ui/EmptyState";
import { NavBarBack } from "../../ui/NavBarBack";
import {
    TableLabel,
    TpaTable,
    TpaTableCell,
    TpaTableContainer,
    TpaTableFilterButton,
    TpaTableRow,
} from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { User } from "../../ui/User";
import { BOOLEAN_FILTER_TYPE, IFilterItem, ITableFilters } from "../../ui/filter/types";
import { MobileContext } from "../../util/MobileContext";
import { SearchWordEllipsis } from "../SearchWordEllipsis";
import { SupportRoutes } from "../router/SupportRoutes";

export const SupportSearchSite = observer(function SupportSearchSite() {
    const { search, selectedColleagueId, selectedColleagueName } = useQueryParams<{
        search: string;
        selectedColleagueId: string;
        selectedColleagueName: string;
    }>();
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [filterItems, setFilterItems] = React.useState<IFilterItem[]>([]);
    const isMobile = React.useContext(MobileContext);
    // used to prevend flickering no result state when search changes from "" to nonempty value
    const [previousSearch, setPreviousSearch] = React.useState(search);

    // Do not use useTableStore(), because we want a new store with each mount to avoid multiple API calls due to query changes
    const [tableStore] = React.useState(
        new TableStore(
            {
                search,
                orderBy: "sentAt",
                orderDir: "desc",
            },
            "SupportSearchSite",
        ),
    );

    React.useEffect(() => {
        tableStore.handleSearchChange(search ?? "");
    }, [search, tableStore]);

    const tableFilters = useTableFilters({
        showAllButton: true,
        filters: [
            {
                category: "companies",
                entries: filterItems,
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            // pass
        },
    });

    const { tableParams } = tableStore;
    React.useEffect(() => {
        const loadMessages = async () => {
            const companyId = companiesStore.selectedCompanyId;
            if (!companyId) {
                return;
            }

            if (!tableStore.search) {
                setMessages([]);
                tableStore.totalCount = 0;
                return;
            }

            try {
                generalStore.isLoading = true;
                const contextCompanyIds = Object.keys(tableFilters.activeFilters);

                const messages = await API.searchChatMessages({
                    companyId,
                    params: tableParams,
                    colleagueId: selectedColleagueId,
                    contextCompanyIds,
                });

                if (messages.messages) {
                    messages.messages.forEach(m => (m.text = clearHtmlLineBreaks(m.text)));
                    setMessages(messages.messages);
                }

                tableStore.totalCount = messages.total;
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.response.type === "CHAT_FORBIDDEN") {
                    generalStore.setError(t("error.chatForbidden"));
                } else {
                    generalStore.setError(t("error.chatSearch"), error);
                }
            } finally {
                generalStore.isLoading = false;
            }
        };

        loadMessages();
    }, [selectedColleagueId, tableFilters.activeFilters, tableParams, tableStore]);

    const { initialized, contextMenu, contextMenuProps } = useCompanyContextMenu({
        getSelectedCompany: (companyId: string) => !!filterItems.find(filterItem => filterItem.name === companyId),
        onClickCompanyItem: (companyId: string, companyName: string) => {
            const filterExists = !!filterItems.find(filterItem => filterItem.name === companyId);
            if (filterExists) {
                if (tableFilters.activeFilters[companyId]) {
                    // disable if active
                    tableFilters.changeFilter(companyId, false);
                }

                // remove filter
                setFilterItems(filterItems.filter(filterItem => filterItem.name !== companyId));
            } else {
                // add filter
                setFilterItems([...filterItems, { type: BOOLEAN_FILTER_TYPE, name: companyId, label: companyName }]);
            }
        },
    });

    const headerFields: ITableHeaderConfig[] = [
        { column: "author", label: "table.label.sentBy", sort: false },
        { column: "text", label: "table.label.message", sort: false, style: { width: "65%" } },
        { column: "sentAt", label: "table.label.date" },
    ];

    const handleClickRow = async (message: Message) => {
        // TODO:
        // - load previous messages

        if (!message.recipient) {
            generalStore.setError(t("error.chatNoRecipient"));
            return;
        }

        if (!message.companyID) {
            generalStore.setError(t("error.chatNoCompany"));
            return;
        }

        if (message.companyID !== companiesStore.selectedCompanyId) {
            await coordinator.selectCompanyById(message.companyID);
        }

        // Am I impersonating someone?
        const myCurrentId = selectedColleagueId ?? authStore.userId;

        // Choose chat partner depending on if I am the author or recipient of the selected message
        const partnerId = message.author.id === myCurrentId ? message.recipient.id : message.author.id;

        // Go to thread
        pushRoute(SupportRoutes.ROOT, {
            query: { selectedContactId: partnerId, selectedColleagueId, messageId: message.id },
        });
    };

    const tableBody = (
        <TableBody>
            {messages.map((message, index) => {
                return (
                    <TpaTableRow
                        key={message.id}
                        onClick={() => handleClickRow(message)}
                        role="button"
                        style={{ cursor: "pointer" }}
                        data-id={`company_${index}`}
                    >
                        {headerFields.map(({ column }) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = message[column as keyof Message];

                            if (column === "author") {
                                label = (
                                    <User
                                        firstName={message.author.firstName}
                                        lastName={message.author.lastName}
                                        isTpaStaff={message.author.isTpaEmployee}
                                    />
                                );
                            } else if (column === "text") {
                                return (
                                    <TpaTableCell key={column} style={{ verticalAlign: "top", padding: 16 }}>
                                        <SearchWordEllipsis
                                            lines={2}
                                            text={message.text}
                                            search={tableStore.search}
                                            minWidth={250}
                                        />
                                    </TpaTableCell>
                                );
                            } else if (column === "sentAt") {
                                label = formatDate(message.sentAt);
                            }

                            return (
                                <TpaTableCell key={column} style={{ verticalAlign: "top", padding: 16 }}>
                                    <TableLabel>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    if (!initialized) {
        return null;
    }

    return (
        <>
            <NavBarBack
                title={t("support.search.navbar.title")}
                onBack={() => {
                    pushRoute(SupportRoutes.ROOT, { query: { selectedColleagueId } });
                }}
                backLabel={t("sidebar.list.faceToFace")}
                navBarInfo={
                    selectedColleagueId ? (
                        <div style={{ flex: 1 }}>
                            <FormattedHTMLMessage
                                id={isMobile ? "support.substitute.warning.mobile" : "support.substitute.warning"}
                                values={{ name: selectedColleagueName }}
                            />
                        </div>
                    ) : undefined
                }
            />
            <CenteredContent>
                <SiteContent>
                    <TableSearchBarWithAction
                        label="search.caption.numResults"
                        placeholder="search.placeholder.search"
                        search={tableStore.search}
                        totalCount={tableStore.totalCount}
                        onChangeSearch={search => {
                            setPreviousSearch(tableStore.search);
                            tableStore.handleSearchChange(search);
                        }}
                    />
                    <TpaTableFilterButton
                        variant={contextMenu.isOpen ? "contained" : "outlined"}
                        onClick={event => {
                            contextMenu.open(event);
                        }}
                        style={{ marginTop: 16, marginRight: 8, marginBottom: 0 }}
                    >
                        Filterauswahl
                    </TpaTableFilterButton>
                    {filterItems.length > 0 ? tableFilters.Buttons : undefined}
                    <TpaTableContainer>
                        <TpaTable>
                            <TableHeader headerFields={headerFields} tableStore={tableStore} />
                            {tableBody}
                        </TpaTable>
                    </TpaTableContainer>
                    <tableStore.Pagination />
                </SiteContent>
                {previousSearch && tableStore.getIsNoResultState(generalStore.isLoading) && (
                    <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                )}
            </CenteredContent>
            <ContextMenu
                {...contextMenuProps}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            />
        </>
    );
});
