import { Button } from "@material-ui/core";
import siteNotFoundImage from "../../../assets/images/im_error_404.png";
import { t } from "../../../i18n/util";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { pushRoute } from "../../app/router/history";
import { NavBarCenteredLogoOnly } from "../../ui/NavBarContainer";
import { VCenter } from "../../ui/Primitives";
import { ImageLogo } from "../../util/Images";
import { Routes } from "../router/Routes";

export const NotFoundSite = () => {
    const handleButtonClick = () => {
        pushRoute(Routes.COCKPIT);
    };

    useHideSideBar();

    return (
        <>
            <NavBarCenteredLogoOnly>
                <ImageLogo />
            </NavBarCenteredLogoOnly>
            <VCenter
                style={{
                    alignItems: "center",
                    background: `url(${siteNotFoundImage}) center no-repeat`,
                }}
            >
                <h1 style={{ margin: 24 }}>{t("screen.not_found.header")}</h1>
                <p style={{ marginBottom: 24 }}>{t("screen.not_found.message")}</p>
                <Button variant="contained" color="primary" onClick={handleButtonClick}>
                    {t("screen.not_found.button.text")}
                </Button>
            </VCenter>
        </>
    );
};
