import { Button, CircularProgress, InputAdornment, Menu, MenuItem, TextField, Tooltip } from "@material-ui/core";
import { observer } from "mobx-react";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import { ACCEPTED_FILE_FORMATS, MAX_UPLOAD_SIZE } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { BankAccount, BankAccountTransaction, BankConnection, PostRecordResponse } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useInMemoryTableStore, useTableStore } from "../../../stores/TableStore";
import { viewerStore } from "../../../stores/ViewerStore";
import { formatDate, toCurrency } from "../../../util/helpers";
import { UseParams, pushRoute } from "../../app/router/history";
import { useRecordTypes } from "../../hooks/useRecordTypes";
import { BoldCurrency } from "../../results/ResultsValue";
import { CenteredContent } from "../../ui/CenteredContent";
import { ContextMenuItem } from "../../ui/ContextMenu";
import { CustomDialog, CustomDialogContent } from "../../ui/CustomDialog";
import { useDocumentUpload } from "../../ui/DocumentUpload";
import { EmptyState } from "../../ui/EmptyState";
import { NavBarBack } from "../../ui/NavBarBack";
import {
    MultiLineEllipsis,
    RouterLink,
    SecondaryText,
    TableLabel,
    TpaTableContainer,
    TpaWhiteButton,
} from "../../ui/Primitives";
import { Progress } from "../../ui/Progress";
import { ResponsiveButtonContainer } from "../../ui/ResponsiveButtonContainer";
import { SiteContent } from "../../ui/SiteContent";
import {
    GetContextMenuItems,
    GetDropSpec,
    RenderCell,
    Table,
    TableColumn,
    createContextMenuColumn,
} from "../../ui/Table";
import { TableSearchBarButton, TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { FileIcon } from "../../util/FileIcon";
import { Icon } from "../../util/Icon";
import { accountingStore } from "../AccountingStore";
import { useBankAccountTransactions } from "../hooks/useBankAccountTransactions";
import { AccountingRoutes } from "../router/AccountingRoutes";
import { AssignInvoicesToBankAccountTransactionsState } from "./assignInvoicesToBankAccountTransactions/types";

interface BankAccountTransactionsTableContext {
    bankConnection: BankConnection | undefined;
    bankAccount: BankAccount | undefined;
}
const Context = createContext<BankAccountTransactionsTableContext>(null as never);

const module = accountingStore.module;

export const BankAccountTransactionsSite = observer(function BankAccountTransactionsSite() {
    const params = useParams<UseParams<typeof AccountingRoutes.BANKING.CONNECTIONS.ACCOUNTS.TRANSACTIONS>>();

    const companyId = companiesStore.selectedCompanyId;
    const bankConnectionId = params.bankConnectionId ? parseInt(params.bankConnectionId, 10) : undefined;
    const bankAccountId = params.bankAccountId ? parseInt(params.bankAccountId, 10) : undefined;
    const periodId = accountingStore.selectedPeriodId;

    const canAssignInvoices = companiesStore.selectedCompanyStore?.canAssignBankAccountTransactionInvoices();
    const canDownloadBankStatement = authStore.canDownloadBankAccountTransactionsBankStatement();
    const canDownloadBuerf = authStore.canDownloadBankAccountTransactionsBuerf();

    const { result, externalWindow } = useBankAccountTransactions(companyId, bankConnectionId, bankAccountId, {
        periodId,
        includeInvoices: true,
        updateTransactions: true,
    });

    const bankConnection = companiesStore.bankConnections.connections?.find(
        c => c.bankConnectionId === bankConnectionId,
    );
    const bankAccount = bankConnection?.accounts?.find(a => a.accountId === bankAccountId);

    const contextValue = useMemo(() => ({ bankConnection, bankAccount }), [bankConnection, bankAccount]);

    const tableStore = useTableStore<BankAccountTransaction>("BankAccountTransactionsTable", {
        orderBy: "bankBookingDate",
        orderDir: "asc",
    });

    useInMemoryTableStore({
        tableStore,
        items: result,
        searchFn,
    });

    const location = useLocation();

    const isEmpty = tableStore.getIsEmptyState(false);

    const getContextMenuItems = useCallback<GetContextMenuItems<BankAccountTransaction>>(
        transaction => {
            const items: ContextMenuItem[] = [];

            if (canAssignInvoices) {
                if (transaction.invoice) {
                    items.push({
                        title: t("accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice"),
                        icon: "delete",
                        onClick: async ({ close }) => {
                            close();
                            try {
                                await API.deleteBankAccountTransaction(
                                    companyId ?? "",
                                    bankConnectionId ?? 0,
                                    bankAccountId ?? 0,
                                    transaction.id,
                                );

                                transaction.invoice = undefined;
                            } catch (error) {
                                generalStore.setError(t("error.deleteBankAccountTransactionInvoices"), error);
                            }
                        },
                    });
                } else if (transaction.isPrivate) {
                    items.push({
                        title: t("accounting.bankAccount.transactions.table.menu.removePrivate"),
                        icon: "delete",
                        onClick: async ({ close }) => {
                            close();
                            try {
                                await API.deleteBankAccountTransaction(
                                    companyId ?? "",
                                    bankConnectionId ?? 0,
                                    bankAccountId ?? 0,
                                    transaction.id,
                                );

                                transaction.isPrivate = false;
                            } catch (error) {
                                generalStore.setError(t("error.removeBankAccountTransactionPrivateMark"), error);
                            }
                        },
                    });
                } else {
                    items.push({
                        title: t("accounting.bankAccount.transactions.table.menu.markAsPrivate"),
                        onClick: async ({ close }) => {
                            close();
                            try {
                                await API.postBankAccountTransactionPrivate(
                                    companyId ?? "",
                                    bankConnectionId ?? 0,
                                    bankAccountId ?? 0,
                                    transaction.id,
                                );

                                transaction.isPrivate = true;
                            } catch (error) {
                                generalStore.setError(t("error.markBankAccountTransactionAsPrivate"), error);
                            }
                        },
                    });
                }
            }

            return items;
        },
        [bankAccountId, bankConnectionId, canAssignInvoices, companyId],
    );

    const { getDropSpec, components } = useFileUpload({ companyId, periodId, bankConnectionId, bankAccountId });

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleDownloadBankStatement = async () => {
        const period = accountingStore.selectedPeriod;
        if (!period) {
            return;
        }

        try {
            generalStore.isLoading = true;

            await API.putDownloadBankAccountTransactionsBankStatement(
                companyId ?? "",
                bankConnectionId ?? 0,
                bankAccountId ?? 0,
                {
                    startDate: period.periodStart,
                    endDate: period.periodEnd,
                },
            );
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };
    const handleDownloadBuerf = async () => {
        const period = accountingStore.selectedPeriod;
        if (!period) {
            return;
        }

        try {
            generalStore.isLoading = true;

            await API.putDownloadBankAccountTransactionsBuerf(
                companyId ?? "",
                bankConnectionId ?? 0,
                bankAccountId ?? 0,
                {
                    startDate: period.periodStart,
                    endDate: period.periodEnd,
                },
            );
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    let menuAction;
    if (canDownloadBankStatement || canDownloadBuerf) {
        menuAction = (
            <>
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        width: 44,
                        height: 44,
                        padding: 0,
                        minWidth: 0,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                    onClick={handleClick}
                >
                    <Icon name={anchorEl ? "dropUp" : "dropDown"} />
                </Button>
                <Menu
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                    onClick={handleCloseMenu}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                >
                    {canDownloadBuerf && (
                        <MenuItem onClick={handleDownloadBuerf}>
                            {t("accounting.bankAccount.transactions.table.action.downloadBuerf")}
                        </MenuItem>
                    )}
                    {canDownloadBankStatement && (
                        <MenuItem onClick={handleDownloadBankStatement}>
                            {t("accounting.bankAccount.transactions.table.action.downloadBankStatement")}
                        </MenuItem>
                    )}
                </Menu>
            </>
        );
    }

    let content;
    if (isEmpty) {
        content = <EmptyState title={t("accounting.bankAccount.transactions.table.emptyState.title")} />;
    } else {
        const handleAction = () => {
            const state: AssignInvoicesToBankAccountTransactionsState = {
                backLabel: bankAccount?.name ?? "",
                backTarget: location,
                bankConnectionId: bankConnection?.bankConnectionId,
                bankAccountId: bankAccount?.accountId,
            };
            pushRoute(AccountingRoutes.BANKING.CONNECTIONS.ACCOUNTS.ASSIGN_INVOICES_TO_TRANSACTIONS, { state });
        };
        content = (
            <SiteContent>
                <TableSearchBarWithAction
                    label="accounting.bankAccount.transactions.table.search.count"
                    placeholder="accounting.bankAccount.transactions.table.search.placeholder"
                    search={tableStore.search}
                    totalCount={tableStore.items.length}
                    onChangeSearch={tableStore.handleSearchChange}
                    action={
                        canAssignInvoices ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <TableSearchBarButton
                                    onClick={handleAction}
                                    style={
                                        menuAction && {
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                        }
                                    }
                                >
                                    {t("accounting.bankAccount.transactions.table.action.assignOpenInvoices")}
                                </TableSearchBarButton>
                                {menuAction}
                            </div>
                        ) : undefined
                    }
                />
                <Context.Provider value={contextValue}>
                    <TpaTableContainer>
                        <StyledTable
                            columns={columns}
                            tableStore={tableStore}
                            renderCell={renderCell}
                            getContextMenuItems={getContextMenuItems}
                            getDropSpec={getDropSpec}
                        />
                    </TpaTableContainer>
                </Context.Provider>
            </SiteContent>
        );
    }

    return (
        <>
            <NavBarBack
                title={bankAccount?.name}
                moduleInfo={accountingStore.module}
                moduleInfoReadOnly={false} // let the user change the periods
                showSubsidiaries={false} // subsidiaries are not needed here
                backLabel={t("common.bankConnections")}
                backTarget={AccountingRoutes.BANKING.CONNECTIONS.ROOT}
                showCancel={false}
            />
            <CenteredContent>{content}</CenteredContent>
            {components}
            {externalWindow.components}
        </>
    );
});

const searchFn = (transaction: BankAccountTransaction, search: string) => {
    const recipientParts = getRecipientParts(transaction);
    return (
        !!transaction.purpose?.toLocaleLowerCase().includes(search) ||
        !!recipientParts?.name?.toLocaleLowerCase().includes(search) ||
        !!recipientParts?.iban?.toLocaleLowerCase().includes(search) ||
        !!recipientParts?.bic?.toLocaleLowerCase().includes(search) ||
        false
    );
};

const columns: TableColumn<BankAccountTransaction>[] = [
    {
        column: "bankBookingDate",
        label: "accounting.bankAccount.transactions.table.label.date",
        width: "min-width-nowrap",
    },
    {
        column: "counterpartName",
        label: "accounting.bankAccount.transactions.table.label.recipient",
        style: { width: 250 },
    },
    {
        column: "purpose",
        label: "accounting.bankAccount.transactions.table.label.text",
    },
    {
        column: "invoice",
        label: "accounting.bankAccount.transactions.table.label.invoice",
        style: { width: 200 },
        sort: false,
    },
    {
        column: "amount",
        label: "accounting.bankAccount.transactions.table.label.amount",
        width: "min-width-nowrap",
        align: "right",
    },
    createContextMenuColumn({}),
];

const renderCell: RenderCell<BankAccountTransaction> = (transaction, column) => {
    switch (column.column) {
        case "bankBookingDate":
            return (
                <div>
                    <div>{formatDate(transaction.bankBookingDate)}</div>
                    {transaction.bankBookingDate !== transaction.valueDate && (
                        <Tooltip title={t("accounting.bankAccount.transactions.table.label.valueDate")}>
                            <SecondaryText>{formatDate(transaction.valueDate)}</SecondaryText>
                        </Tooltip>
                    )}
                </div>
            );
        case "valueDate":
            return <div>{formatDate(transaction.valueDate)}</div>;
        case "purpose":
            return <TableLabel style={{ maxWidth: 200 }}>{transaction.purpose ?? ""}</TableLabel>;
        case "counterpartName":
            return <Recipient transaction={transaction} />;
        case "invoice":
            return <InvoiceOrPrivate transaction={transaction} />;
        case "amount":
            return (
                <BoldCurrency
                    value={transaction.amount}
                    currency={transaction.currency ? toCurrency(transaction.currency) : undefined}
                    currencyPosition="start"
                />
            );
        default:
            return null;
    }
};

const StyledTable: typeof Table<BankAccountTransaction> = styled(Table)`
    margin-top: 16px;
`;

const InvoiceRoot = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: normal;
    gap: 8px;
    &:hover {
        text-decoration: underline;
    }
`;

const InvoiceOrPrivate = observer(function InvoiceOrPrivate({ transaction }: { transaction: BankAccountTransaction }) {
    const { bankConnection, bankAccount } = useContext(Context);
    const location = useLocation();

    if (transaction.isPrivate) {
        return <span>{t("accounting.bankAccount.transactions.table.label.isPrivate")}</span>;
    }

    if (!transaction.invoice) {
        if (!companiesStore.selectedCompanyStore?.canAssignBankAccountTransactionInvoices()) {
            return null;
        }

        const state: AssignInvoicesToBankAccountTransactionsState = {
            backLabel: bankAccount?.name ?? "",
            backTarget: location,
            bankConnectionId: bankConnection?.bankConnectionId,
            bankAccountId: bankAccount?.accountId,
            bankAccountTransactionId: transaction.id,
        };
        return (
            <RouterLink
                to={{
                    pathname: AccountingRoutes.BANKING.CONNECTIONS.ACCOUNTS.ASSIGN_INVOICES_TO_TRANSACTIONS,
                    state,
                }}
                style={{ color: "inherit", textDecoration: "underline" }}
            >
                {t("accounting.bankAccount.transactions.table.label.assignInvoice")}
            </RouterLink>
        );
    }

    const { invoice } = transaction;
    const handleClick = () => {
        const files = [
            {
                id: invoice.id,
                name: invoice.document?.name ?? "",
                src: () => {
                    return API.getRecordDownloadUrl({
                        companyId: companiesStore.selectedCompanyId ?? "",
                        module: "accounting",
                        subsidiaryId: invoice.subsidiaryId ?? "",
                        periodId: invoice.periodId ?? "",
                        recordTypeId: invoice.recordTypeId ?? "",
                        recordId: invoice.id,
                    });
                },
                download: () => {
                    return API.putDownloadRecords({
                        companyId: companiesStore.selectedCompanyId ?? "",
                        module: "accounting",
                        subsidiaryId: invoice.subsidiaryId ?? "",
                        periodId: invoice.periodId ?? "",
                        recordTypeId: invoice.recordTypeId ?? "",
                        recordIds: [invoice.id],
                    });
                },
            },
        ];
        viewerStore.open(files, invoice.id);
    };

    return (
        <InvoiceRoot onClick={handleClick}>
            <FileIcon name={invoice.document?.name} />
            <MultiLineEllipsis text={invoice.document?.name ?? ""} maxLine={2} />
        </InvoiceRoot>
    );
});

export function getRecipientParts(transaction: BankAccountTransaction) {
    const { counterpartName: name, counterpartIBAN: iban, counterpartBic: bic } = transaction;
    if (!name && !iban && !bic) {
        return undefined;
    }
    return { name, iban, bic };
}

/**
 * Display the recipient (aka "counterpart" in finAPI) of a transaction, e.g. another person name and their IBAN/BIC.
 */
export const Recipient = ({ transaction }: { transaction: BankAccountTransaction }) => {
    const parts = getRecipientParts(transaction);
    if (!parts) {
        return null;
    }
    const { name, iban, bic } = parts;
    return (
        <div>
            {name && <div>{name}</div>}
            {(!!iban || !!bic) && <SecondaryText>{iban && bic ? `${iban} / ${bic}` : (iban ?? bic)}</SecondaryText>}
        </div>
    );
};

interface UseFileUploadOptions {
    companyId: string | undefined;
    periodId: string | undefined;
    bankConnectionId: number | undefined;
    bankAccountId: number | undefined;
}

const useFileUpload = ({ companyId, periodId, bankConnectionId, bankAccountId }: UseFileUploadOptions) => {
    const [subsidiaryId, setSubsidiaryId] = useState<string | undefined>();
    const [recordTypeId, setRecordTypeId] = useState<string | undefined>();
    const [transactionAndFile, setTransactionAndFile] = useState<{ transaction: BankAccountTransaction; file: File }>();

    const documentUpload = useDocumentUpload<PostRecordResponse>({
        acceptedFileTypes: ACCEPTED_FILE_FORMATS,
        filterBigImages: true,
        maxFileSizeBytes: MAX_UPLOAD_SIZE,
    });

    const progressDialog = (
        <CustomDialog
            open={!!documentUpload.progress}
            // dialog cannot be closed by clicking outside or pressing escape
            disableEscapeKeyDown
        >
            <CustomDialogContent>
                <h1 style={{ marginBottom: 16 }}>
                    {t("accounting.bankAccount.transactions.table.uploadInvoice.progress.title")}
                </h1>
                {documentUpload.progress && (
                    <Progress current={documentUpload.progress.current} total={documentUpload.progress.total} />
                )}
            </CustomDialogContent>
        </CustomDialog>
    );

    const onDrop = documentUpload.onDrop;

    const uploadFile = useCallback(
        async (file: File) => {
            return API.postRecords({
                file,
                companyId: companyId ?? "",
                module,
                periodId: periodId ?? "",
                subsidiaryId: subsidiaryId ?? "",
                recordTypeId: recordTypeId ?? "",
            });
        },
        [companyId, periodId, recordTypeId, subsidiaryId],
    );

    const addBankAccountTransactionInvoices = useCallback(
        async (transaction: BankAccountTransaction, invoiceId: string) => {
            if (!companyId || !periodId || !subsidiaryId || !recordTypeId || !bankConnectionId || !bankAccountId) {
                return;
            }

            try {
                await API.postBankAccountTransactionInvoice(
                    companyId,
                    bankConnectionId,
                    bankAccountId,
                    transaction.id,
                    { invoiceId },
                );
                const invoice = await API.getRecord({
                    companyId,
                    module,
                    periodId,
                    subsidiaryId,
                    recordTypeId,
                    recordId: invoiceId,
                });
                transaction.invoice = invoice;
            } catch (error) {
                generalStore.setError(t("error.addBankAccountTransactionInvoices"), error);
            }
        },
        [bankAccountId, bankConnectionId, companyId, periodId, recordTypeId, subsidiaryId],
    );

    const handleSelectSubsidiaryRecordType = () => {
        const transaction = transactionAndFile?.transaction;
        const file = transactionAndFile?.file;
        if (!transaction || !file) {
            return;
        }

        handleClose();

        onDrop([file], [], uploadFile, uploads => {
            const invoiceIds = uploads.map(u => u.response.recordId);
            addBankAccountTransactionInvoices(transaction, invoiceIds[0]);
        });
    };

    const { isLoading, recordTypes } = useRecordTypes({
        companyId,
        module,
        subsidiaryId,
        periodId,
    });
    const editableRecordTypes = recordTypes.filter(recordType =>
        accountingStore.canEditRecords(subsidiaryId, recordType.id),
    );
    const canSelectRecordType = !isLoading && editableRecordTypes.length > 0;

    const handleClose = () => {
        setTransactionAndFile(undefined);
        setSubsidiaryId(undefined);
        setRecordTypeId(undefined);
    };

    const subsidiaryRecordTypeDialog = (
        <CustomDialog open={!!transactionAndFile}>
            <CustomDialogContent>
                <h1 style={{ marginBottom: 16 }}>
                    {t("accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title")}
                </h1>
                <TextField
                    value={subsidiaryId ?? ""}
                    label={t("common.accounting.subsidiary")}
                    variant="outlined"
                    select
                    fullWidth
                    style={{ marginBottom: 32 }}
                    onChange={event => {
                        setSubsidiaryId(event.target.value);
                        setRecordTypeId(undefined);
                    }}
                >
                    {accountingStore.subsidiaries.map(subsidiary => (
                        <MenuItem key={subsidiary.id} value={subsidiary.id}>
                            {subsidiary.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    value={recordTypeId ?? ""}
                    label={t("common.accounting.recordType")}
                    variant="outlined"
                    select
                    fullWidth
                    style={{ marginBottom: 32 }}
                    onChange={event => {
                        setRecordTypeId(event.target.value);
                    }}
                    disabled={!canSelectRecordType}
                    InputProps={
                        isLoading
                            ? {
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          <CircularProgress size={24} />
                                      </InputAdornment>
                                  ),
                              }
                            : undefined
                    }
                >
                    {!canSelectRecordType ? (
                        <MenuItem value="">{t("error.noRecordTypes")}</MenuItem>
                    ) : (
                        editableRecordTypes.map(recordType => (
                            <MenuItem key={recordType.id} value={recordType.id}>
                                {accountingStore.getRecordTypeName(recordType)}
                            </MenuItem>
                        ))
                    )}
                </TextField>
                <ResponsiveButtonContainer>
                    <TpaWhiteButton onClick={handleClose}>{t("common.cancel")}</TpaWhiteButton>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!subsidiaryId || !recordTypeId}
                        onClick={handleSelectSubsidiaryRecordType}
                    >
                        {t("common.upload")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );

    const handleDropFile = useCallback((transaction: BankAccountTransaction, file: File) => {
        setTransactionAndFile({ transaction, file });
    }, []);

    interface NativeFileDrop {
        files: File[];
        items: DataTransferItemList;
    }

    const getDropSpec: GetDropSpec<BankAccountTransaction, NativeFileDrop, void> = useCallback(
        transaction => ({
            spec: () => ({
                accept: [NativeTypes.FILE],
                drop: (item, monitor) => {
                    handleDropFile(transaction, item.files[0]);
                },
                collect: monitor => ({
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                    isDragging: monitor.getItem() !== null,
                }),
                canDrop(item, monitor) {
                    if (transaction.invoice) {
                        return false;
                    }
                    if (item.items.length > 1) {
                        return false; // only 1 file upload allowed
                    }
                    // the file will be validated in `handleSelectSubsidiaryRecordType`
                    return true;
                },
            }),
            deps: [handleDropFile, subsidiaryId, recordTypeId],
        }),
        [handleDropFile, subsidiaryId, recordTypeId],
    );

    if (!companiesStore.selectedCompanyStore?.canAssignBankAccountTransactionInvoices()) {
        // not allowed to assign invoices disables the file upload entirely.
        return {
            getDropSpec: undefined,
            components: undefined,
        };
    }

    if (!accountingStore.canEditAnyRecords()) {
        // cannot upload any records at all
        return {
            getDropSpec: undefined,
            components: undefined,
        };
    }

    // we cannot check if the user is allowed to upload records here, because we don't have the subsidiaryId and recordTypeId yet.

    return {
        getDropSpec,
        components: (
            <>
                {documentUpload.dialogs}
                {progressDialog}
                {subsidiaryRecordTypeDialog}
            </>
        ),
    };
};
