import * as React from "react";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { API } from "../../network/API";
import { CustomMenuItem } from "../../network/APITypes";

export const useOptionalSideBarMenuItems = (companyId?: string) => {
    const [optionalSideBarMenuItems, setOptionalSideBarMenuItems] = React.useState<CustomMenuItem[] | null>(null);

    const load = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            const response = await API.getOptionalMenuItems(companyId);
            setOptionalSideBarMenuItems(response);
        } catch (err) {
            generalStore.setError(t("error.loadOptionalMenuItems"), err);
        }
    }, [companyId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        reload: load,
        optionalSideBarMenuItems,
    };
};
