import { IconButton } from "@material-ui/core";
import * as React from "react";
import { DropEvent, FileRejection } from "react-dropzone";
import { FormattedHTMLMessage } from "react-intl";
import { ACCEPTED_IMAGE_FORMATS, MAX_UPLOAD_SIZE, MAX_UPLOAD_SIZE_MB, MB_SIZE } from "../../config";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { filterEmptyFiles, filterInvalidFiles } from "../../util/files";
import { fileRejectionToErrorMessage, getSupportedImageFormatsString, isIos } from "../../util/helpers";
import { Icon } from "../util/Icon";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { UploadSmallPaper } from "./DocumentUploadSmall";
import { DropZoneSmall } from "./DropZoneSmall";
import { DialogErrorLine } from "./FailedUploadDialog";
import { ImageCropDialog } from "./ImageCropDialog";

export const ImageUploadSmall = (props: {
    title?: string;
    uploadFile: (file: File) => void | Promise<void>;
    deleteFile?: () => void;
    "data-id"?: string;
    cropCircle?: boolean;
    imageUrl?: string;
    disabled?: boolean;
    fallbackErrorMessage?: string;
    maxUploadSizeMb?: number;
}) => {
    const [image, setImage] = React.useState<File | null>(null);
    const [isUploading, setIsUploading] = React.useState(false);
    const [rejectedFiles, setRejectedFiles] = React.useState<FileRejection[]>([]);
    const [imageUrl, setImageUrl] = React.useState(props.imageUrl);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    // TPAPORTAL-1936: Clear imageUrl, if cleared from props
    React.useEffect(() => {
        if (!props.imageUrl) {
            setImageUrl(undefined);
        }
    }, [props.imageUrl]);

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        if (isIos()) {
            const filtered = filterInvalidFiles(acceptedFiles, fileRejections, ACCEPTED_IMAGE_FORMATS);
            acceptedFiles = filtered.accepted;
            fileRejections = filtered.rejected;
        }
        const { accepted, rejected } = filterEmptyFiles(acceptedFiles, fileRejections);

        if (!props.disabled) {
            if (accepted.length === 1 && rejected.length === 0) {
                // Can only add single file
                setImage(accepted[0]);
                setRejectedFiles([]);
            } else if (rejected) {
                setRejectedFiles(rejected);
            }
        }
    };

    const onSave = async (blob: Blob) => {
        if (!image) {
            return;
        }

        const file = new File([blob], image.name, { type: image.type });
        try {
            setIsUploading(true);
            await props.uploadFile(file);
            setImage(null);

            // Use local preview after upload (much faster than redownloading the image from the server)
            const url = URL.createObjectURL(blob);
            setImageUrl(url);
        } catch (err) {
            generalStore.setError(t("error.upload"), err);
        } finally {
            setIsUploading(false);
        }
    };

    const maxSizeBytes = props.maxUploadSizeMb ? props.maxUploadSizeMb * MB_SIZE : MAX_UPLOAD_SIZE;

    return (
        <>
            <UploadSmallPaper>
                <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16 }}
                >
                    {props.title && (
                        <h4 style={{ hyphens: "manual" }}>
                            <FormattedHTMLMessage id={props.title} />
                        </h4>
                    )}
                    {props.deleteFile && !props.disabled && (
                        <IconButton
                            style={{ padding: 0 }}
                            onClick={event => {
                                setDeleteDialogOpen(true);
                            }}
                        >
                            <Icon name="delete" />
                        </IconButton>
                    )}
                </div>
                <DropZoneSmall
                    isUploading={false}
                    onDrop={onDrop}
                    data-id={props["data-id"]}
                    infoText={"dropzone.image.text"}
                    fileTypes={ACCEPTED_IMAGE_FORMATS}
                    imageUrl={imageUrl}
                    imageStyle={props.cropCircle ? "circle" : "cover"}
                    disabled={props.disabled}
                    fallbackErrorMessage={props.fallbackErrorMessage}
                    maxUploadSize={maxSizeBytes}
                />
                {rejectedFiles.length > 0 && (
                    <div style={{ marginTop: 18, marginBottom: 2 }}>
                        {rejectedFiles.map((failedFile, index) => {
                            return (
                                <div style={{ display: "flex", alignItems: "center" }} key={failedFile.file.name}>
                                    <DialogErrorLine
                                        errorMessage={fileRejectionToErrorMessage(failedFile)}
                                        fileName={failedFile.file.name}
                                        infoTitle={getSupportedImageFormatsString()}
                                        infoBody={t("documentUpload.max.size", {
                                            maxUploadSizeMb: props.maxUploadSizeMb ?? MAX_UPLOAD_SIZE_MB,
                                        })}
                                        key={index}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </UploadSmallPaper>
            <ImageCropDialog
                image={image}
                maxSizeBytes={maxSizeBytes}
                onSave={onSave}
                onCancel={() => {
                    setImage(null);
                }}
                disableSave={isUploading}
                cropCircle={props.cropCircle}
            />
            <ConfirmationDialog
                open={deleteDialogOpen}
                title={t("companyImages.delete.title")}
                message={t("companyImages.delete.message")}
                confirmLabel={t("common.delete")}
                onCancel={() => {
                    setDeleteDialogOpen(false);
                }}
                onConfirm={() => {
                    setDeleteDialogOpen(false);
                    props.deleteFile?.();
                }}
            />
        </>
    );
};
