import { FileRejection } from "react-dropzone";
import { MAX_IMAGE_DIMENSION } from "../config";

export const fileNameRegex = /^[^/<>|?*:"\\]+$/;

export function sanitizeFilename(name: string) {
    return name.replace(/[/<>|?*:"\\]/g, "");
}

export function getFileNameWithoutExtension(fileName: string) {
    const ext = getFileExtension(fileName);
    if (ext === "") {
        return fileName;
    }
    return fileName.slice(0, -ext.length - 1);
}

/**
 * Returns the file extension as it is.
 *
 * @example
 * getFileExtension("file.TXT") === "TXT"
 */
export function getFileExtension(fileName: string) {
    if (!fileName) {
        return "";
    }

    const components = fileName.split(".");
    if (components.length === 1) {
        return "";
    }

    let ext = components[components.length - 1];

    // special handling for `camt.053` files
    if (ext === "053" && components.length > 1 && components[components.length - 2] === "camt") {
        ext = components[components.length - 2] + "." + ext;
    }

    return ext;
}

/**
 * Returns true if the file has the given extension (case insensitive).
 *
 * @example
 * hasFileExtension("file.TXT", "txt") === true
 */
export function hasFileExtension(fileName: string, extension: string) {
    return getFileExtension(fileName).toLowerCase() === extension.toLowerCase();
}

/**
 * Filters files using {@link filterMaxFiles}, {@link filterInvalidFiles}, {@link filterEmptyFiles} and {@link filterMaxSize}.
 */
export function filterFilesSync(
    accepted: File[],
    rejected: FileRejection[],
    {
        maxFiles,
        acceptedFileTypes,
        maxFileSizeBytes,
    }: { maxFiles?: number; acceptedFileTypes?: string; maxFileSizeBytes?: number },
) {
    if (typeof maxFiles === "number") {
        ({ accepted, rejected } = filterMaxFiles(accepted, rejected, maxFiles));
        if (accepted.length === 0) {
            // it does not make sense to continue here
            return { accepted, rejected };
        }
    }

    if (acceptedFileTypes) {
        ({ accepted, rejected } = filterInvalidFiles(accepted, rejected, acceptedFileTypes));
    }

    ({ accepted, rejected } = filterEmptyFiles(accepted, rejected));

    if (maxFileSizeBytes) {
        ({ accepted, rejected } = filterMaxSize(accepted, rejected, maxFileSizeBytes));
    }

    return { accepted, rejected };
}

/**
 * Moves all `accepted` files into `rejected` files if the number of files exceeds `maxFiles`.
 */
export function filterMaxFiles(accepted: File[], rejected: FileRejection[], maxFiles: number) {
    if (accepted.length > maxFiles) {
        return {
            accepted: [],
            rejected: rejected.concat(
                accepted.map(file => ({
                    file,
                    errors: [{ message: "too many files", code: "too-many-files" }],
                })),
            ),
        };
    }

    return { accepted, rejected };
}

/**
 * Moves `accepted` files that do not match the extensions provided in `acceptedFileTypes` to `rejected` files.
 */
export function filterInvalidFiles(accepted: File[], rejected: FileRejection[], acceptedFileTypes: string) {
    const extensions = acceptedFileTypes.split(",");

    const invalidFiles = accepted.filter(file => {
        const extension = getFileExtension(file.name).toLowerCase();
        return !extensions.includes(`.${extension}`);
    });

    return {
        // Accept only files with correct extensions
        accepted: accepted.filter(file => {
            const extension = getFileExtension(file.name).toLowerCase();
            return extensions.includes(`.${extension}`);
        }),

        // Add invalid files to rejected
        rejected: rejected.concat(
            // Convert to FileRejection
            invalidFiles.map(file => ({
                file,
                errors: [{ message: "invalid file type", code: "file-invalid-type" }],
            })),
        ),
    };
}

/**
 * Moves empty files from `accepted` to `rejected` files.
 */
export function filterEmptyFiles(accepted: File[], rejected: FileRejection[]) {
    const emptyFiles = accepted.filter(file => file.size === 0);
    return {
        // Accept only non empty files
        accepted: accepted.filter(file => file.size > 0),

        // Add empty files to rejected
        rejected: rejected.concat(
            // Convert empty to FileRejection
            emptyFiles.map(file => ({
                file,
                errors: [{ message: "file is empty", code: "file-empty" }],
            })),
        ),
    };
}

/**
 * Moves files that are too big from `accepted` to `rejected` files.
 */
export function filterMaxSize(accepted: File[], rejected: FileRejection[], maxSize: number) {
    const tooLargeFiles = accepted.filter(file => file.size > maxSize);
    return {
        accepted: accepted.filter(file => file.size <= maxSize),
        rejected: rejected.concat(
            tooLargeFiles.map(file => ({
                file,
                errors: [{ message: "file is too large", code: "file-too-large" }],
            })),
        ),
    };
}

/**
 * Gets the dimensions of an image file.
 */
export function getImageDimensions(file: File): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(file);
        const img = new Image();

        img.onload = () => {
            resolve({ width: img.width, height: img.height });
            URL.revokeObjectURL(img.src);
        };

        img.onerror = () => {
            resolve({ width: 0, height: 0 });
            URL.revokeObjectURL(img.src);
        };

        img.src = url;
    });
}

export function isImage(file: File) {
    return file.type.split("/")[0] === "image";
}

/**
 * Returns the files that are too big.
 */
export async function filterTooBigImages(files: File[], maxDimension = MAX_IMAGE_DIMENSION) {
    const images = files.filter(file => isImage(file));
    const dimensions = await Promise.all(images.map(image => getImageDimensions(image)));
    return images.filter((image, index) => {
        return dimensions[index].width > maxDimension || dimensions[index].height > maxDimension;
    });
}
