import { t } from "../../i18n/util";
import { StepType, Stepper } from "../ui/Stepper";
import { AdvisorRoutes } from "./router/AdvisorRoutes";
import { BulkState } from "./util/bulk";

export const AdvisorConfigCompaniesStepper = ({ state, disabled }: { state: BulkState; disabled?: boolean }) => {
    const steps: StepType[] = [
        {
            label: t("config.bulk.data.title"),
            url: AdvisorRoutes.CONFIG.BULK.DATA,
            urlState: state,
        },
        {
            label: t("config.terms.termsOfUseTitle"),
            url: AdvisorRoutes.CONFIG.BULK.TERMS_OF_USE,
            urlState: state,
        },
        {
            label: t("config.bulk.finish.title"),
            url: AdvisorRoutes.CONFIG.BULK.FINISH,
            urlState: state,
        },
    ];

    return <Stepper steps={steps} disabled={disabled} />;
};
