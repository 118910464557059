import { useCallback } from "react";
import { API } from "../../../network/API";
import { MISSING_REQUEST_DATA, useAPI } from "../../hooks/useAPI";
import { ResultsSettings } from "../types";
import { variantToQuery } from "../utils";
import { useTransformedResult } from "./useTransformedResult";

export function useAccountListing(companyId: string | undefined, settings: ResultsSettings) {
    const { financialAccountancyId, variant, useFullPreviousYears } = settings;

    const loader = useCallback(async () => {
        if (!companyId || !financialAccountancyId) {
            return MISSING_REQUEST_DATA;
        }
        const result = await API.getAccountListing({
            companyId,
            financialAccountancyId,
            query: {
                ...variantToQuery[variant],
                interval: "Year",
                useFullPreviousYears,
            },
        });

        return result.result;
    }, [companyId, financialAccountancyId, useFullPreviousYears, variant]);

    const result = useAPI(loader, {
        generalStoreErrorMessage: "error.loadAccountListing",
    });

    return useTransformedResult("accountListing", result, settings);
}
