import { Decimal } from "decimal.js";
import { useMemo } from "react";
import { t } from "../../../i18n/util";
import {
    CompanyAddress,
    FinancialAccountancyResult,
    FinancialAccountancyResultPeriod,
    FinancialAccountancyResultRow,
    FinancialAccountancyResultYearValue,
} from "../../../network/APITypes";
import { formatDate } from "../../../util/date";
import { isNever } from "../../../util/ts";
import { APIResult } from "../../hooks/useAPI";
import { AccountingViews, ResultsSettings } from "../types";

export interface ValueCurrency {
    type: "currency";
    value: Decimal;
}
export interface ValueRatio {
    type: "ratio";
    value?: number;
}
export interface ValueCurrencyPill {
    type: "currency-pill";
    value: Decimal;
    flipSign?: boolean;
}
export interface ValuePercentagePill {
    type: "percentage-pill";
    value: Decimal;
    flipSign?: boolean;
}
export type ResultValue = ValueCurrency | ValueRatio | ValueCurrencyPill | ValuePercentagePill;

export interface ResultColumn {
    key: string;
    label: React.ReactNode;
    getValue: (
        values: FinancialAccountancyResultYearValue[],
        flipSign?: boolean,
        accountType?: string,
    ) => ResultValue | null;
    financialAccountancyId?: number;
}

export interface ResultRow {
    key: string;
    className: string;
    label: string | null;
    level: number | "account";
    isShare?: boolean;
    numbering?: FinancialAccountancyResultRow["numbering"];
    sumType?: FinancialAccountancyResultRow["sumType"];
    values: ResultRowValue[];
    children: ResultRow[];
    accounts: ResultAccount[];
}

export interface ResultAccount {
    key: string;
    className: string;
    accountNumber: number;
    address: CompanyAddress;
    label: string;
    values: ResultRowValue[];
}

export interface ResultRowValue {
    key: string;
    value: ResultValue | null;
    financialAccountancyId?: number;
}

export interface TransformedResult {
    columns: ResultColumn[];
    rows: ResultRow[];
    stylesheet: string;
}

interface TransformSettings {
    financialAccountancyId: ResultsSettings["financialAccountancyId"];
    hideAccounts: boolean;
    variant: ResultsSettings["variant"];
    orderBy: ResultsSettings["orderBy"];
}

export function useTransformedResult(
    view: AccountingViews,
    result: APIResult<FinancialAccountancyResult>,
    settings: TransformSettings,
) {
    const { financialAccountancyId, hideAccounts, variant, orderBy } = settings;

    return useMemo(() => {
        if (result.state !== "success") {
            return null;
        }
        return transform(view, result.data, { financialAccountancyId, hideAccounts, variant, orderBy });
    }, [financialAccountancyId, hideAccounts, orderBy, result, variant, view]);
}

function transform(
    view: AccountingViews,
    { periods: rawPeriods, rows }: FinancialAccountancyResult,
    settings: TransformSettings,
): TransformedResult {
    // sort from new year/month (2023/12) to old year/month (2022/01)
    // TODO: should be done by server?
    const periods = sortPeriods(rawPeriods);

    const columns = buildColumns(view, periods, settings);

    // create a stylesheet on the fly that allows us to highlight the current row and all it's "children"
    // without having to listen to mouse events in JS manually.
    let stylesheet = "";
    function addToStylesheet(className: string) {
        const lastClassName = className.split(" ").pop();
        if (!lastClassName) {
            return;
        }
        stylesheet += `
            [class$="${lastClassName}"]:hover,
            [class$="${lastClassName}"]:hover ~ .${lastClassName} {
                background: #f6f6f6;
            }
        `;
    }

    function buildRows(rows: FinancialAccountancyResultRow[], key: string, className: string): ResultRow[] {
        addToStylesheet(className);

        return rows.map((row, index) => buildRow(row, `${key}-${index}`, `${className} ${key}-${index}`));
    }
    function buildRow(row: FinancialAccountancyResultRow, key: string, className: string): ResultRow {
        let accounts: ResultAccount[] = [];
        if (!settings.hideAccounts && row.accounts) {
            accounts = row.accounts.map<ResultAccount>((account, index) => {
                const accountKey = `${key}-values-${index}`;
                const accountClassName = `${className} ${accountKey}`;
                addToStylesheet(accountClassName);

                const values = account.values;
                return {
                    key: accountKey,
                    className: accountClassName,
                    accountNumber: account.accountNumber,
                    address: account.address,
                    label: account.name,
                    values: columns.map(({ key, getValue, financialAccountancyId }): ResultRowValue => {
                        return {
                            key,
                            value: getValue(values, row.flipSign, account.accountType),
                            financialAccountancyId,
                        };
                    }),
                };
            });

            const { financialAccountancyId, orderBy } = settings;
            const modifier = orderBy.endsWith("-asc") ? 1 : -1;
            accounts.sort((a1, a2) => {
                switch (orderBy) {
                    case "amount-asc":
                    case "amount-desc": {
                        // sort by amount of the current financial accountancy
                        const findValue = (r: Decimal | null, value: ResultRowValue): Decimal | null =>
                            value.financialAccountancyId?.toString() === financialAccountancyId &&
                            value.value?.type === "currency"
                                ? value.value.value
                                : r;
                        const valueA1: Decimal | null = a1.values.reduce(findValue, null);
                        const valueA2: Decimal | null = a2.values.reduce(findValue, null);
                        return valueA1 && valueA2 ? valueA1.comparedTo(valueA2) * modifier : 0;
                    }
                    case "accountNr-asc":
                    case "accountNr-desc":
                        return (a1.accountNumber - a2.accountNumber) * modifier;
                    case "accountName-asc":
                    case "accountName-desc":
                        return a1.label.localeCompare(a2.label) * modifier;
                    case "none":
                        return 0;
                    default:
                        isNever(orderBy);
                        return 0;
                }
            });
        }

        const children: ResultRow[] = row.children ? buildRows(row.children, key, className) : [];

        addToStylesheet(className);

        const totals = row.totals;
        return {
            key,
            className,
            label: row.text,
            level: row.level,
            isShare: row.isShare,
            numbering: row.numbering,
            sumType: row.sumType,
            children,
            accounts,
            values: columns.map(({ key, getValue, financialAccountancyId }): ResultRowValue => {
                return {
                    key,
                    value: getValue(totals, row.flipSign),
                    financialAccountancyId,
                };
            }),
        };
    }

    const transformedRows = buildRows(rows, "result", "result");

    return { columns, rows: transformedRows, stylesheet };
}

function sortPeriods(periods: FinancialAccountancyResultPeriod[]) {
    return periods.slice().sort((p1, p2) => {
        const diff = p1.year - p2.year;
        if (diff !== 0) {
            return diff;
        }
        if (!p1.month) {
            return 1;
        }
        if (!p2.month) {
            return -1;
        }
        return p1.month - p2.month;
    });
}

function buildColumns(view: AccountingViews, periods: FinancialAccountancyResultPeriod[], settings: TransformSettings) {
    const { variant } = settings;

    function findValue(values: FinancialAccountancyResultYearValue[], year: number, month: number | undefined) {
        const yearValue = values.find(value => value.year === year);
        if (typeof month !== "number") {
            return yearValue;
        }
        return yearValue?.months.find(value => value.month === month);
    }
    function findPreviousPeriod(periods: FinancialAccountancyResultPeriod[], year: number, month: number | undefined) {
        if (typeof month !== "number") {
            return periods.find(p => p.year === year - 1 && p.interval === "Year");
        }
        return periods.find(p => p.year === year && p.month === month - 1 && p.interval === "Month");
    }
    function periodLabel(period: FinancialAccountancyResultPeriod) {
        if (period.interval === "Month") {
            return formatDate(period.from, "MM/Y");
        } else if (period.interval === "Year") {
            return formatDate(period.to);
        }
    }

    return periods.reduce<ResultColumn[]>((columns, period, index, periods) => {
        const { year, month } = period;

        const key = `${year}${typeof month === "number" ? `/${month}` : ""}`;

        if (view === "customerListing" || view === "vendorListing") {
            columns.push({
                key: `${key}.debit`,
                label: t("results.columns.debit"),
                getValue: values => {
                    const current = findValue(values, year, month);
                    if (!current) {
                        return null;
                    }

                    return { type: "currency", value: new Decimal(current.debit) };
                },
            });
            columns.push({
                key: `${key}.credit`,
                label: t("results.columns.credit"),
                getValue: values => {
                    const current = findValue(values, year, month);
                    if (!current) {
                        return null;
                    }

                    return { type: "currency", value: new Decimal(current.credit) };
                },
            });
        }

        columns.push({
            key,
            label: periodLabel(period),
            getValue: values => {
                const current = findValue(values, year, month);
                if (!current) {
                    return null;
                }

                return { type: "currency", value: new Decimal(current.value) };
            },
            financialAccountancyId: period.financialAccountancyPeriod,
        });

        if (view === "accountListing") {
            // hide ratio for account listing because correct calculation does not rely on the same calculation as the other views
        } else {
            columns.push({
                key: `${key}.ratio`,
                label: "%",
                getValue: values => {
                    const current = findValue(values, year, month);
                    if (!current) {
                        return null;
                    }

                    return { type: "ratio", value: current.ratio };
                },
            });
        }

        const previousPeriod = index > 0 ? findPreviousPeriod(periods, year, month) : undefined;

        if (previousPeriod && variant === "currentAndPastYearAbsoluteChange") {
            // calculate the absolute difference between the previous (e.g 2023) and the current period (e.g. 2022)
            // e.g.
            // previous (2022) =  1000 €
            // current  (2023) =  2000 €
            // change          =  1000 €
            columns.push({
                key: `${key}.changeAbsolute`,
                label: t("results.columns.changeAbsolute"),
                getValue: (values, flipSign, accountType) => {
                    const previous = findValue(values, previousPeriod.year, previousPeriod.month);
                    const current = findValue(values, year, month);
                    if (!previous && !current) {
                        return null;
                    }

                    const previousValue = previous?.value ? new Decimal(previous.value) : new Decimal(0);
                    const currentValue = current?.value ? new Decimal(current.value) : new Decimal(0);
                    const change = currentValue.sub(previousValue);
                    return {
                        type: "currency-pill",
                        value: change,
                        flipSign: accountType === "Expense" || accountType === "Passive" || flipSign === true,
                    };
                },
            });
        }
        if (previousPeriod && variant === "currentAndPastYearPercentageChange") {
            // calculate the percentage of the difference between the previous (e.g 2023) and the current period (e.g. 2022) compared to the current period
            // e.g.
            // previous (2022) =  1000 €
            // current  (2023) =  2000 €
            // change          =  1000 €
            // percentage      =   500 %
            columns.push({
                key: `${key}.changePercentage`,
                label: t("results.columns.changePercentage"),
                getValue: (values, flipSign, accountType) => {
                    const previous = findValue(values, previousPeriod.year, previousPeriod.month);
                    const current = findValue(values, year, month);
                    if (!previous && !current) {
                        return null;
                    }

                    const previousValue = previous?.value ? new Decimal(previous.value) : new Decimal(0);
                    const currentValue = current?.value ? new Decimal(current.value) : new Decimal(0);
                    if (currentValue.isZero()) {
                        return null; // cannot divide by 0
                    }

                    const change = currentValue.sub(previousValue);
                    if (change.isZero()) {
                        return {
                            type: "percentage-pill",
                            value: new Decimal("0"),
                            flipSign: accountType === "Expense" || accountType === "Passive" || flipSign === true,
                        };
                    }

                    // we dont want to show NaN or infinite percentage values
                    // e.g.
                    // previous =  0 €
                    // current  =  1000 €
                    // percentage would be infinite and show '> 9.999,99%'
                    const percentage = change.times(100).dividedBy(previousValue);
                    if (percentage.isNaN() || !percentage.isFinite()) {
                        return null;
                    }

                    return {
                        type: "percentage-pill",
                        value: percentage,
                        flipSign: accountType === "Expense" || accountType === "Passive" || flipSign === true,
                    };
                },
            });
        }

        return columns;
    }, []);
}
