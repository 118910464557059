import { Button } from "@material-ui/core";
import React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { ProjectUser } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { ISignatureUserSignature } from "../../util/qes";
import { CustomDialog, CustomDialogContent } from "../ui/CustomDialog";
import { TpaWhiteButton } from "../ui/Primitives";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";
import { SignaturePositions } from "../ui/SignaturePositions";

export interface ISignatureDocument {
    documentId: string;
    signatures: ISignatureUserSignature[];
}

interface ProjectItem {
    id: string;
}

export const usePdfSignaturePositionDraftDialog = ({
    projectItems,
    companyId,
    projectId,
    collectiveRelease,
    onSubmit,
}: {
    projectItems: ProjectItem[];
    companyId: string;
    projectId: string;
    collectiveRelease?: boolean;
    onSubmit: (signatureDocuments: ISignatureDocument[]) => void;
}) => {
    const [open, setOpen] = React.useState(false);
    const [currentProjectItem, setCurrentProjectItem] = React.useState<ProjectItem>(projectItems[0]);
    const [responsibleUsers, setResponsibleUsers] = React.useState<ProjectUser[]>([]);
    const [signatureDocuments, setSignatureDocuments] = React.useState<ISignatureDocument[]>([]);
    const [overlapping, setOverlapping] = React.useState(false);

    const getCurrentSignatureDocumentIndex = () => {
        return signatureDocuments.findIndex(document => {
            return document.documentId === currentProjectItem.id;
        });
    };
    const getCurrentSignatureDocument = () => {
        const index = getCurrentSignatureDocumentIndex();
        return index === -1 ? undefined : signatureDocuments[index];
    };

    const handleChangeSignaturePositions = (signatures: ISignatureUserSignature[], overlapping: boolean) => {
        const index = getCurrentSignatureDocumentIndex();
        if (index !== -1) {
            // - update document
            const newSignatureDocuments = [...signatureDocuments];
            newSignatureDocuments.splice(index, 1, { ...newSignatureDocuments[index], signatures });
            setSignatureDocuments(newSignatureDocuments);
        } else {
            // - add document
            setSignatureDocuments([...signatureDocuments, { documentId: currentProjectItem.id, signatures }]);
        }
        setOverlapping(overlapping);
    };

    const loadUrl = React.useCallback(async () => {
        try {
            const pdfDownload = await API.putDownloadProjectUrl(companyId, projectId, [currentProjectItem.id]);
            return pdfDownload.downloadUrl;
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        }
    }, [companyId, currentProjectItem.id, projectId]);

    const openDialog = (responsibleUsers: ProjectUser[]) => {
        setResponsibleUsers(responsibleUsers);

        // Reset state, so when the user comes back from a cancelled dialog
        // and changed some data (e.g. responsible users), the old positions are not used.
        // Also the flow is reset to the beginning.
        setCurrentProjectItem(projectItems[0]);
        setSignatureDocuments([]);

        setOpen(true);
    };

    const submitSignature = () => {
        const isFinalDocument = signatureDocuments.length === projectItems.length;
        if (isFinalDocument) {
            onSubmit(signatureDocuments);
            setOpen(false);
        } else {
            setCurrentProjectItem(projectItems[signatureDocuments.length]);
        }
    };

    const getCaption = () => {
        if (collectiveRelease) {
            return t("selectQesPosition.message.collective", { button: t("common.ok") });
        } else {
            return t("selectQesPosition.message", { button: t("common.ok") });
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const document = getCurrentSignatureDocument();

    const continueButtonEnabled =
        !overlapping &&
        (document?.signatures.length === responsibleUsers.length ||
            (!collectiveRelease && document?.signatures.length === 1));

    const component = (
        <CustomDialog open={open} maxWidth="lg" fullWidth={false}>
            <CustomDialogContent>
                <h1>{t("selectQesPosition.title")}</h1>
                <p style={{ marginTop: 24 }}>{getCaption()}</p>
                <SignaturePositions
                    key={currentProjectItem.id}
                    loadUrl={loadUrl}
                    users={responsibleUsers}
                    collectiveRelease={collectiveRelease ?? false}
                    onChange={handleChangeSignaturePositions}
                />
                <ResponsiveButtonContainer style={{ marginTop: 24 }}>
                    <TpaWhiteButton onClick={handleClose}>{t("common.cancel")}</TpaWhiteButton>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submitSignature}
                        disabled={!continueButtonEnabled}
                    >
                        {t("common.ok")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );

    return {
        component,
        open: openDialog,
    };
};
