import { MenuItem, TextField } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { EmployeeDocumentType, GeneralDocumentType, ReportTypeEnum } from "../../network/APITypes";
import { DocumentModule, Module, generalDocumentTypes } from "../../types/models";
import { debug } from "../../util/debug";
import { isModuleActive } from "../../util/modules";
import { usePermissions } from "../hooks/usePermissions";
import { AutocompleteCompanies, ICompanyListItem } from "../shared/AutocompleteCompanies";
import { useCompany } from "./useCompany";

export type DocumentCategory = "report" | EmployeeDocumentType | ReportTypeEnum | GeneralDocumentType;

const hrSupportedWebhookDocumentTypes: EmployeeDocumentType[] = [
    "contractOfEmployment",
    "certificateOfCitizenship",
    "identityCard",
    "certificateOfRegistration",
    "employmentPermit",
    "registrationConfirmation",
    "deregistrationConfirmation",
    "dataChangeConfirmation",
    "other",
];

const hrSupportedUploadDocumentTypes: EmployeeDocumentType[] = [...hrSupportedWebhookDocumentTypes, "payslip"];

export function isSupportedHrWebhookDocumentType(type: string) {
    return !!hrSupportedWebhookDocumentTypes.find(element => element === type);
}

function moduleToString(value: DocumentModule) {
    switch (value) {
        case "accounting":
            return t("sidebar.list.accounting");
        case "hr":
            return t("sidebar.list.hr");
        case "generalDocuments":
            return t("common.generalDocuments");
        default:
            break;
    }
}

export const useWebhookConfiguration = (options: {
    showCompany?: boolean;
    chooseCompany: boolean;
    companyId?: string;
    modules?: DocumentModule[];
    module?: DocumentModule;
    chooseModule?: boolean;
    showCategory?: boolean;
    chooseCategory?: boolean;
    category?: DocumentCategory;
    reportsOnly?: boolean;
    disabled?: boolean;
    isUpload?: boolean;
}) => {
    const [companyId, setCompanyId] = React.useState("");
    const [category, setCategory] = React.useState<DocumentCategory | "">("");
    const [module, setModule] = React.useState<DocumentModule | "">("");

    // Load company and permissions to configure which modules are usable
    const { company } = useCompany({ companyId });
    const permissions = usePermissions(companyId);
    let modules: DocumentModule[];
    if (options.modules) {
        modules = options.modules;
    } else {
        modules = ["accounting", "hr", "generalDocuments"];
    }

    // Now for accounting and hr check if user has permission, if not remove module
    modules = modules.filter(entry => {
        if (entry === "accounting" || entry === "hr") {
            const allowed = !!company && isModuleActive(company, entry) && !!permissions.parsed?.hasModule(entry);
            return allowed;
        }
        return true;
    });

    // Preselect companyId
    React.useEffect(() => {
        if (!companyId && options.companyId) {
            setCompanyId(options.companyId);
        }
    }, [companyId, options.companyId]);

    React.useEffect(() => {
        setCategory(options.category ?? "");
    }, [options.category]);

    // Preselect module
    React.useEffect(() => {
        if (!!permissions.permissions && !module && modules.length > 0 && options.module) {
            setModule(options.module);
        }
    }, [module, modules, modules.length, options.module, permissions.permissions]);

    const supportedHrDocumentTypes = options.isUpload
        ? hrSupportedUploadDocumentTypes
        : hrSupportedWebhookDocumentTypes;

    const additionalHrDocumentTypes = options.reportsOnly ? [] : supportedHrDocumentTypes;

    const moduleCategories: { [key in DocumentModule]: DocumentCategory[] } = {
        accounting: ["report"],
        hr:
            company?.releaseSteps === "twoSteps"
                ? ["billing", "analysis", ...additionalHrDocumentTypes]
                : ["analysis", ...additionalHrDocumentTypes],
        generalDocuments: generalDocumentTypes,
    };

    function moduleCategoryToString(value: DocumentCategory) {
        return t(`upload.category.${value}`);
    }

    const categoryString = category ? moduleCategoryToString(category) : "-";

    const onChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCategory(event.target.value as DocumentCategory);
    };

    const onChangeModule = (event: React.ChangeEvent<HTMLInputElement>) => {
        const module = event.target.value as Module;
        setModule(module);
        if (moduleCategories[module].length === 1) {
            // Hack to prevent mui invalid select warning -> delete category to unmount
            // whatever is relying on it, then set category to remount
            setCategory("");
            setTimeout(() => {
                setCategory(moduleCategories[module][0]);
            }, 0);
        } else {
            setCategory("");
        }
    };

    const onChangeCompany = (company: ICompanyListItem | null) => {
        debug.log("### selected company", company);
        setCompanyId(company?.id ?? "");
        setModule("");
    };

    const uploadConfiguration = (
        <>
            {options.showCompany && (
                <AutocompleteCompanies
                    companyId={companyId}
                    onChangeCompany={onChangeCompany}
                    style={{ marginBottom: 16 }}
                    disabled={!!options.disabled || !options.chooseCompany}
                />
            )}

            {(!options.showCompany || !options.chooseCompany || !!companyId) && (
                <TextField
                    value={module}
                    name="module"
                    data-id="upload_module"
                    label={t("screen.advisor.uploads.upload.module")}
                    variant="outlined"
                    select
                    style={{ marginBottom: 18 }}
                    onChange={onChangeModule}
                    disabled={!!options.disabled || (!options.chooseModule && !!options.module)}
                >
                    {modules.map(module => (
                        <MenuItem key={module} value={module}>
                            {moduleToString(module)}
                        </MenuItem>
                    ))}
                </TextField>
            )}

            {module !== "" && !!options.showCategory && (
                <TextField
                    value={category}
                    name="category"
                    data-id="upload_category"
                    label={t("screen.advisor.uploads.upload.category")}
                    variant="outlined"
                    select
                    style={{ marginBottom: 18 }}
                    onChange={onChangeCategory}
                    disabled={!options.chooseCategory}
                >
                    {moduleCategories[module].map((category, index) => (
                        <MenuItem key={index} value={category}>
                            {moduleCategoryToString(category)}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </>
    );

    const isEmployeeDocument = module === "hr" && isSupportedHrWebhookDocumentType(category);

    return {
        companyId,
        setCompanyId,
        company,
        category,
        categoryString,
        module,
        uploadConfiguration,
        isReport: category === "report" || category === "billing" || category === "analysis",
        isEmployeeDocument,
        isGeneralDocument: module === "generalDocuments",
        isBaseDataSelected: !!module && !!companyId && !!category,
    };
};
