import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { IPermissions } from "../../types/models";
import { IParsedPermissions, parsePermissions } from "../../util/permissionParser";

export const usePermissions = (companyId?: string) => {
    const [permissions, setPermissions] = React.useState<IPermissions | null>(null);
    const [parsed, setParsed] = React.useState<IParsedPermissions | null>(null);

    React.useEffect(() => {
        const load = async () => {
            if (!companyId) {
                return;
            }

            try {
                const response = await API.getCurrentUserPermissions(companyId);
                setPermissions(response);

                const parsedPermissions = parsePermissions(response);
                setParsed(parsedPermissions);
            } catch (err) {
                generalStore.setError(t("error.loadPermissions"), err);
            }
        };

        load();
    }, [companyId]);

    return { permissions, parsed };
};
