import { observer } from "mobx-react";
import { Redirect, Route, Switch } from "react-router-dom";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { Routes } from "../../app/router/Routes";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { ClipboardHook } from "../../hooks/useCopyToClipBoard";
import { MicrosoftUpgradeSite } from "../../shared/MicrosoftUpgradeSite";
import { ProjectsNavBar } from "../ProjectsNavBar";
import { ProjectsUnlockInternationalBar } from "../ProjectsUnlockInternationalBar";
import { ProjectsBulkAddUsersSite } from "../sites/ProjectsBulkAddUsersSite";
import { ProjectsDataSite } from "../sites/ProjectsDataSite";
import { ProjectsFileUploadSite } from "../sites/ProjectsFileUploadSite";
import { ProjectsOverviewSite } from "../sites/ProjectsOverviewSite";
import { ProjectsSite } from "../sites/ProjectsSite";
import { ProjectsToConfigureOverviewSite } from "../sites/ProjectsToConfigureOverviewSite";
import { ProjectsRoutes } from "./ProjectsRoutes";

const Projects = observer(function Projects() {
    const hasMicrosoftLogin = authStore.userInfo?.has_microsoft_login;

    return (
        <>
            <ProjectsNavBar showTabs={hasMicrosoftLogin} />
            {!hasMicrosoftLogin && <MicrosoftUpgradeSite text={t("screen.upgradeToMicrosoft.feature.projects.text")} />}
            {hasMicrosoftLogin && (
                <>
                    <Route exact path={ProjectsRoutes.ACTIVE.ROOT}>
                        <ProjectsUnlockInternationalBar />
                        <ProjectsOverviewSite status="active" />
                    </Route>
                    <Route exact path={ProjectsRoutes.TO_CONFIGURE.ROOT}>
                        <ProjectsToConfigureOverviewSite />
                    </Route>
                    <Route exact path={ProjectsRoutes.FINISHED}>
                        <ProjectsOverviewSite status="closed" />
                    </Route>
                    <Route exact path={ProjectsRoutes.DELETED}>
                        <ProjectsOverviewSite status="deleted" />
                    </Route>
                </>
            )}
        </>
    );
});

export const ProjectsRouter = ({ clipboard }: { clipboard: ClipboardHook }) => (
    <Switch>
        <Route exact path={Routes.PROJECTS}>
            <Redirect to={ProjectsRoutes.ACTIVE.ROOT} />
        </Route>
        <Route
            exact
            path={[
                ProjectsRoutes.ACTIVE.ROOT,
                ProjectsRoutes.TO_CONFIGURE.ROOT,
                ProjectsRoutes.FINISHED,
                ProjectsRoutes.DELETED,
            ]}
        >
            <Projects />
        </Route>
        <Route
            exact
            path={[
                ProjectsRoutes.NEW_PROJECT,
                ProjectsRoutes.EDIT_PROJECT,
                ProjectsRoutes.TO_CONFIGURE.PROJECT,
                ProjectsRoutes.NEW_PROJECT_USERS,
                ProjectsRoutes.EDIT_PROJECT_USERS,
                ProjectsRoutes.TO_CONFIGURE.PROJECT_USERS,
                ProjectsRoutes.NEW_PROJECT_COMPANIES,
                ProjectsRoutes.NEW_PROJECT_FINISH,
            ]}
        >
            <ProjectsDataSite />
        </Route>
        <Route exact path={[ProjectsRoutes.BULK_ADD_USERS]}>
            <ProjectsBulkAddUsersSite />
        </Route>
        <Route exact path={[ProjectsRoutes.UPLOAD_FILES_TO_PROJECT, ProjectsRoutes.UPLOAD_FILES_TO_PROJECT_FOLDER]}>
            <ProjectsFileUploadSite />
        </Route>
        <Route
            exact
            path={[
                ProjectsRoutes.ACTIVE.PROJECT,
                ProjectsRoutes.ACTIVE.PROJECT_FOLDER,
                ProjectsRoutes.RELEASES,
                ProjectsRoutes.RELEASES_TICKET,
                ProjectsRoutes.RELEASES_PROJECT_ITEM,
                ProjectsRoutes.RELEASES_PROJECT_ITEM_TICKET,
            ]}
        >
            <ProjectsSite clipboard={clipboard} />
        </Route>
        <Route component={NotFoundSite} />
    </Switch>
);
