import { makeStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import { PowerBIEmbed } from "powerbi-client-react";
import * as models from "powerbi-models";
import { useCallback, useContext } from "react";
import { useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { companiesStore } from "../../../stores/CompaniesStore";
import { UseParams } from "../../app/router/history";
import { MISSING_REQUEST_DATA, useAPI } from "../../hooks/useAPI";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { MobileContext } from "../../util/MobileContext";
import { AnalysisRoutes } from "../router/AnalysisRoutes";

const useStyles = makeStyles({
    root: {
        height: "calc(100vh - var(--navBarHeight))",
        width: "100%",
        lineHeight: 0,

        "&.mobile": {
            height: "100%",
        },

        "& iframe": {
            border: 0,
        },
    },
});

export const PowerBIReportSite = observer(function AnalysisReportSite() {
    const classes = useStyles();
    const isMobile = useContext(MobileContext);

    const companyId = companiesStore.selectedCompanyId;

    const { workspaceId, reportId } = useParams<UseParams<typeof AnalysisRoutes.POWERBI_REPORT>>();

    const loader = useCallback(async () => {
        if (!companyId || !workspaceId || !reportId) {
            return MISSING_REQUEST_DATA;
        }

        return API.postPowerBIReportEmbedToken(companyId, workspaceId, reportId);
    }, [companyId, reportId, workspaceId]);
    const result = useAPI(loader, { generalStoreErrorMessage: "error.loadPowerBIReportEmbedToken" });

    let content;
    let name;
    if (result.state !== "success") {
        name = "";
        content = null;
    } else {
        name = result.data.report.name;
        content = (
            <PowerBIEmbed
                embedConfig={{
                    type: "report",
                    id: reportId ?? "",
                    embedUrl: result.data.report.embedUrl,
                    accessToken: result.data.token,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            },
                        },
                        background: models.BackgroundType.Transparent,
                    },
                }}
                cssClassName={classes.root + (isMobile ? " mobile" : "")}
            />
        );
    }

    return (
        <>
            <NavBarBack
                title={t("analysis.powerBI.report.title", { name })}
                backLabel={t("analysis.powerBI.reports.title")}
                backTarget={AnalysisRoutes.POWERBI_REPORTS}
                showCancel={false}
            />
            <CenteredContent>{content}</CenteredContent>
        </>
    );
});
