import { Button } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { ProjectConfiguration } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { pushRoute, withParams } from "../../app/router/history";
import { CenteredContent } from "../../ui/CenteredContent";
import { EmptyState } from "../../ui/EmptyState";
import { ProjectToConfigureRow } from "../../ui/ProjectRow";
import { SiteContent } from "../../ui/SiteContent";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { ProjectsRoutes } from "../router/ProjectsRoutes";

export const ProjectsToConfigureOverviewSite = observer(function ProjectsToConfigureOverviewSite() {
    const [projects, setProjects] = React.useState<ProjectConfiguration[]>([]);
    const [isInitialized, setIsInitialized] = React.useState(false);
    const tableStore = useTableStore("ProjectsToConfigureOverviewSite", {
        orderBy: "name",
        orderDir: "asc",
        limit: 0,
    });

    const companyId = companiesStore.selectedCompanyId;

    const { tableParams } = tableStore;
    const reloadProjects = React.useCallback(
        async (noCache?: boolean) => {
            if (!companyId) {
                return;
            }

            try {
                generalStore.isLoading = true;
                // TODO check tableparams (eg should sorting be possible or be omitted?)
                const response = await API.getProjectsToConfigure(companyId, tableParams, noCache);
                tableStore.totalCount = response.projects.length;

                if (response.projects) {
                    setProjects(response.projects);
                    setIsInitialized(true);
                }
            } catch (error) {
                generalStore.setError(t("error.loadProjects"), error);
            } finally {
                generalStore.isLoading = false;
            }
        },
        [companyId, tableParams, tableStore],
    );

    React.useEffect(() => {
        reloadProjects();
    }, [reloadProjects]);

    if (!companyId) {
        // No company selected -> get out
        return null;
    }

    if (!authStore.canCreateProjects) {
        return <Redirect to={ProjectsRoutes.TO_CONFIGURE.ROOT} />;
    }

    const handleClickProject = (externalId: string) => () => {
        pushRoute(withParams(ProjectsRoutes.TO_CONFIGURE.PROJECT, { externalId }));
    };

    if (!isInitialized) {
        return null;
    }

    const empty = tableStore.getIsEmptyState(generalStore.isLoading);
    const noResult = tableStore.getIsNoResultState(generalStore.isLoading);

    return (
        <>
            {empty && (
                <EmptyState
                    title={t("projects.emptystate.title")}
                    message={t("projects.notConfigured.emptystate.message")}
                    buttonLabel={t("common.refresh")}
                    onAddEntry={() => reloadProjects(true)}
                />
            )}
            {!empty && (
                <CenteredContent>
                    <SiteContent>
                        <div style={{ textAlign: "right", marginBottom: 8 }}>
                            <Button color="primary" onClick={() => reloadProjects(true)} endIcon={<RefreshIcon />}>
                                {t("common.refresh")}
                            </Button>
                        </div>
                        <TableSearchBarWithAction
                            label="search.caption.numProjects"
                            placeholder="search.placeholder.projects"
                            search={tableStore.search}
                            totalCount={tableStore.totalCount}
                            onChangeSearch={tableStore.handleSearchChange}
                            buttonLabel={authStore.canCreateProjects ? t("projects.new") : undefined}
                            onAction={() => {
                                pushRoute(ProjectsRoutes.NEW_PROJECT);
                            }}
                        />
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                marginTop: 24,
                            }}
                        >
                            <div style={{ alignSelf: "center", width: "100%" }}>
                                {noResult && (
                                    <EmptyState
                                        title={t("table.noResults.title")}
                                        message={t("table.noResults.message")}
                                    />
                                )}
                                {!noResult &&
                                    projects.map((project, index) => {
                                        return (
                                            <ProjectToConfigureRow
                                                key={project.externalId}
                                                onClick={handleClickProject(project.externalId)}
                                                data-id={`projects_${index}`}
                                                project={project}
                                                // TODO use data from projects
                                                // fileCount={project.fileCount}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </SiteContent>
                </CenteredContent>
            )}
        </>
    );
});
