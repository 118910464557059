import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetReligionsResponse } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useReligions = () => {
    const [religions, setReligions] = React.useState<GetReligionsResponse | null>(null);

    React.useEffect(() => {
        const load = async () => {
            try {
                const response = await API.getReligions();
                setReligions(response);
            } catch (err) {
                generalStore.setError(t("error.loadFormData"), err);
            }
        };

        load();
    }, []);

    return religions;
};
