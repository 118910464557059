import * as React from "react";
import { CenteredContent } from "../../ui/CenteredContent";
import { SiteContent } from "../../ui/SiteContent";
import { DIALOG_WIDTH } from "../../util/Theme";
import { CompanyConfigView } from "./SettingsCompanyAccountingSite";

export const SettingsCompanyHrSite = () => {
    return (
        <CenteredContent>
            <SiteContent style={{ maxWidth: DIALOG_WIDTH, paddingTop: 40 }}>
                <CompanyConfigView module="hr" />
            </SiteContent>
        </CenteredContent>
    );
};
