import { useCallback } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetCompaniesProjectsResponseItem } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { Autocomplete, Loader } from "../ui/Autocomplete";

export interface AutocompleteProjectOption extends GetCompaniesProjectsResponseItem {}

interface Props {
    value: AutocompleteProjectOption | null;
    onChange: (project: AutocompleteProjectOption | null) => void;
    includeInternational: boolean;
    filterIds?: string[];
}

export const AutocompleteProject = ({ value, onChange, includeInternational, filterIds }: Props) => {
    const loader: Loader<GetCompaniesProjectsResponseItem> = useCallback(
        async ({ cursor, search }) => {
            try {
                const response = await API.getCompaniesProjects({
                    includeInternational,
                    limit: 9,
                    cursor,
                    search,
                });
                return {
                    items: response.projects ?? [],
                    cursor: response.cursor,
                };
            } catch (error) {
                generalStore.setError(t("error.loadProjects"), error);
                return null;
            }
        },
        [includeInternational],
    );

    return (
        <Autocomplete<AutocompleteProjectOption>
            value={value}
            onChange={onChange}
            loader={loader}
            getOptionLabel={option => `${option.project.name} (${option.company.name.trim()})`}
            isSelected={(option, value) => option.project.id === value.project.id}
            isDisabled={option => (filterIds ? filterIds.includes(option.project.id) : false)}
            placeholder={t("search.placeholder.projects")}
        />
    );
};
