import sortBy from "lodash/sortBy";
import { computed, observable } from "mobx";
import moment from "moment";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { EventsFeedEntry, NewsFeedEntry, PublicationCategory, PublicationsFeedEntry } from "../network/APITypes";
import { addStoreToWindow } from "../util/debug";
import { companiesStore } from "./CompaniesStore";

export type INewsImageMap = Record<
    string,
    {
        linkedImage: boolean;
        src: string;
    }
>;

export function newestPublicationsCategory(): PublicationCategory {
    return {
        id: "newest",
        name: t("screen.cockpit.overview.newestPublications"),
    };
}

class NewsStore {
    @observable news: NewsFeedEntry[] = [];

    @observable promotions: NewsFeedEntry[] = [];
    @observable promotionsLoaded = false;

    @observable events: EventsFeedEntry[] = [];
    @observable eventsLoaded = false;

    @observable publicationCategories: PublicationCategory[] = [];
    @observable publications: PublicationsFeedEntry[] = [];

    @computed get isInitialized() {
        return this.promotionsLoaded && this.eventsLoaded;
    }

    async loadPromotions() {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId) {
            return;
        }

        const promotions = await API.getPromotions(companyId);
        this.promotions = promotions;
        this.promotionsLoaded = true;
    }

    async loadEvents() {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId) {
            return;
        }

        this.events = await API.getEvents(companyId);
        this.eventsLoaded = true;

        if (this.events && this.events.length > 0) {
            // Only show future events
            const today = new Date();
            this.events = this.events.filter(e => new Date(e.eventDate) > today);

            // Sort events by date
            this.events = sortBy(this.events, ["eventDate"]);
        }
    }

    async loadPublications() {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId) {
            return;
        }

        const response = await API.getPublications(companyId);

        // Throw away publications without downloads -> phase 1 does not allow ordering publications via mail
        this.publications = response.publications.filter(p => p.documents.length > 0);

        // Throw away empty categories
        this.publicationCategories = response.categories.filter(c => this.getPublicationsForCategory(c.id).length > 0);

        // Add "newest" pseudo category
        this.publicationCategories.unshift(newestPublicationsCategory());
    }

    getPublicationsForCategory(categoryId?: string) {
        if (!categoryId) {
            return [];
        }

        if (categoryId === "newest") {
            // not older than 1.5 years
            const now = moment();
            return this.publications.filter(
                p => now.diff(moment(p.publishedAt), "month") < 18 && p.documents.length > 0,
            );
        }

        return this.publications.filter(p => {
            return !!p.categories.find(c => c.id === categoryId && p.documents.length > 0);
        });
    }

    getSelectedNews(id?: string) {
        // Promotions and news are from the same feed, so we can be sure, the id really is unique
        return id ? this.promotions.concat(this.news).find(newsItem => newsItem.id === id) : undefined;
    }

    wipe() {
        this.news = [];
        this.promotions = [];
        this.promotionsLoaded = false;
        this.events = [];
        this.eventsLoaded = false;
        this.publications = [];
        this.publicationCategories = [];
    }
}

const newsStore = new NewsStore();

export { newsStore };

addStoreToWindow("newsStore", newsStore);
