import { observer } from "mobx-react";
import { useParams } from "react-router";
import {
    ACCEPTED_PROJECT_FORMATS,
    PROJECT_UPLOAD_FILE_SIZE_CUTOFF,
    PROJECT_UPLOAD_FRAGMENT_SIZE,
} from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { pushRoute, withParams } from "../../app/router/history";
import { DocumentUpload } from "../../ui/DocumentUpload";
import { FullScreenDocumentUploadContainer } from "../../ui/FullScreenDocumentUploadContainer";
import { NavBarBack } from "../../ui/NavBarBack";
import { ProjectsRoutes } from "../router/ProjectsRoutes";

export const ProjectsFileUploadSite = observer(function ProjectsFileUploadSite() {
    const { projectId, projectFolderId } = useParams<{ projectId?: string; projectFolderId?: string }>();
    useHideSideBar();

    const companyId = companiesStore.selectedCompanyId;

    if (!projectId || !companyId) {
        return null;
    }

    const backToFolder = () => {
        if (projectFolderId) {
            pushRoute(withParams(ProjectsRoutes.ACTIVE.PROJECT_FOLDER, { projectId, projectFolderId }));
        } else {
            pushRoute(withParams(ProjectsRoutes.ACTIVE.PROJECT, { projectId }));
        }
    };

    const handleUploadSessionItem = async (file: File, uploadSessionUrl: string) => {
        const fileSize = file.size;
        const fragmentCount = Math.ceil(fileSize / PROJECT_UPLOAD_FRAGMENT_SIZE);

        // Upload each fragment sequentially
        for (let i = 0; i < fragmentCount; i++) {
            const start = i * PROJECT_UPLOAD_FRAGMENT_SIZE;
            const end = Math.min(start + PROJECT_UPLOAD_FRAGMENT_SIZE, fileSize);
            const fragment = file.slice(start, end);
            const fragmentSize = fragment.size;
            const contentRange = `bytes ${start}-${end - 1}/${fileSize}`;

            const response = await API.putProjectUploadSessionItem({
                url: uploadSessionUrl,
                contentLength: fragmentSize,
                contentRange,
                body: fragment,
            });

            if (response.status === HttpStatusCode.Created_201 || response.status === HttpStatusCode.Ok_200) {
                // see https://learn.microsoft.com/en-us/graph/api/driveitem-createuploadsession?view=graph-rest-1.0#completing-a-file for the response
                // We only need the id of the created item
                interface ProjectItem {
                    id: string;
                }
                const body = (await response.json()) as ProjectItem;
                return body.id;
            }
        }
    };

    const handleUploadFile = async (file: File) => {
        try {
            generalStore.isLoading = true;

            if (file.size > PROJECT_UPLOAD_FILE_SIZE_CUTOFF) {
                const response = await API.postProjectUploadSession({
                    companyId,
                    projectId,
                    file,
                    projectItemId: projectFolderId,
                });
                const projectItemId = await handleUploadSessionItem(file, response.uploadUrl);
                if (projectItemId) {
                    await API.postProjectUploadSessionComplete({
                        companyId,
                        projectId,
                        projectItemId,
                    });
                }
            } else {
                await API.postProjectItem({
                    companyId,
                    projectId,
                    projectItemId: projectFolderId,
                    file,
                });
            }
        } catch (error) {
            generalStore.setError(t("error.upload"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    return (
        <>
            <NavBarBack
                title={t("projects.navbar.fileUpload")}
                companyName={companiesStore.selectedCompany?.name}
                onCancel={backToFolder}
            />
            <FullScreenDocumentUploadContainer>
                <DocumentUpload
                    multipleFiles
                    fileTypes={ACCEPTED_PROJECT_FORMATS}
                    uploadFile={handleUploadFile}
                    onSuccess={backToFolder}
                    data-id="project_upload_files"
                />
            </FullScreenDocumentUploadContainer>
        </>
    );
});
