import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { FormattedHTMLMessage } from "react-intl";
import { ACCEPTED_FILE_FORMATS, MAX_IMAGE_DIMENSION, MAX_UPLOAD_SIZE_MB, MB_SIZE } from "../../config";
import { t } from "../../i18n/util";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { filterFilesSync, filterTooBigImages } from "../../util/files";
import { getSupportedFormatsString, getSupportedImageFormatsString, isIos } from "../../util/helpers";
import { DataUploadImage } from "../util/Images";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { DialogErrorLine, FailedDialog, FileRejections } from "./FailedUploadDialog";
import { Progress } from "./Progress";
import { SymmetricBorderBox } from "./SymmetricBorderBox";

const TEST_FAILED_UPLOADS = false;

export interface IUpload<T> {
    file: File;
    response: T;
}

export interface IFailedUpload {
    file: File;
    errorMessage?: string;
    info?: string;
}

export const DocumentUpload = observer(function DocumentUpload<T>(props: {
    maxFileSizeBytes?: number;
    fileTypes?: string;
    multipleFiles?: boolean;
    filterBigImages?: boolean;
    uploadFile: UploadFile<T>;
    onSuccess: OnSuccess<T>;
}) {
    const isMobile = React.useContext(MobileContext);

    const acceptedFileTypes = props.fileTypes ?? ACCEPTED_FILE_FORMATS;
    const acceptedFileTypesLabel = getSupportedFormatsString(acceptedFileTypes);

    const { isUploading, onDrop, rejectedFiles, progress, dialogs } = useDocumentUpload<T>({
        acceptedFileTypes,
        filterBigImages: props.filterBigImages,
        maxFileSizeBytes: props.maxFileSizeBytes,
        maxFiles: props.multipleFiles === false ? 1 : undefined,
    });

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        accept: isIos() ? undefined : acceptedFileTypes, // ios blocks msg, eml, fib
        noClick: true,
        noKeyboard: true,
        multiple: props.multipleFiles,
        maxSize: props.maxFileSizeBytes,
        disabled: isUploading,
        onDrop: (acceptedFiles, rejectedFiles) => {
            onDrop(acceptedFiles, rejectedFiles, props.uploadFile, props.onSuccess);
        },
    });

    const hasRejectedFiles = rejectedFiles.length > 0;

    return (
        <>
            <SymmetricBorderBox
                color={isUploading && !hasRejectedFiles ? customColors.secondaryColor : customColors.primaryColor}
            >
                <div
                    style={{
                        cursor: "pointer",
                        backgroundColor: isDragActive ? "#F6F8F9" : "",
                        padding: isMobile ? "84px 16px 84px 16px" : "84px 108px 84px 108px",
                    }}
                    {...getRootProps()}
                    role="button"
                    onClick={open}
                >
                    <div
                        style={{
                            display: "flex",
                            minHeight: 0,
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <DataUploadImage style={{ marginBottom: 12 }} />
                        <p style={{ textAlign: "center" }}>
                            {t("dropzone.info.text")} <br />
                            <Button color="primary" data-id="document_upload_search_button">
                                {t("dropzone.button.label.search")}
                            </Button>
                        </p>
                        <p className="caption" style={{ color: customColors.greyTextfields, paddingTop: 4 }}>
                            {acceptedFileTypesLabel}
                        </p>
                        {props.maxFileSizeBytes && (
                            <p
                                style={{ color: customColors.greyTextfields, paddingTop: 4, marginBottom: 16 }}
                                className="caption"
                            >
                                {t("documentUpload.max.size", {
                                    maxUploadSizeMb: props.maxFileSizeBytes / MB_SIZE,
                                })}
                            </p>
                        )}
                        {progress && <Progress current={progress.current} total={progress.total} />}

                        <input {...getInputProps()} />
                    </div>
                </div>
            </SymmetricBorderBox>

            {dialogs}
        </>
    );
});

export type UploadFile<T> = (file: File) => Promise<T>;
export type OnSuccess<T> = (uploads: IUpload<T>[]) => void;

export const useDocumentUpload = <T,>({
    acceptedFileTypes,
    filterBigImages,
    maxFileSizeBytes,
    maxFiles,
}: {
    acceptedFileTypes: string;
    filterBigImages?: boolean;
    maxFileSizeBytes?: number;
    maxFiles?: number;
}) => {
    const [uploadFile, setUploadFile] = React.useState<UploadFile<T>>();
    const [onSuccess, setOnSuccess] = React.useState<OnSuccess<T>>();
    const [rejectedFiles, setRejectedFiles] = React.useState<FileRejection[]>([]);
    const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);
    const [failedUploads, setFailedUploads] = React.useState<IFailedUpload[]>([]);
    const [tooBigImages, setTooBigImages] = React.useState<File[]>([]);
    const [successfulUploads, setSuccessfulUploads] = React.useState<IUpload<T>[]>([]);
    const [currentUploadFile, setCurrentUploadFile] = React.useState(0);
    const [isUploading, setIsUploading] = React.useState(false);

    const resetState = React.useCallback(() => {
        setUploadFile(undefined);
        setOnSuccess(undefined);
        setRejectedFiles([]);
        setAcceptedFiles([]);
        setFailedUploads([]);
        setTooBigImages([]);
        setSuccessfulUploads([]);
        setCurrentUploadFile(0);
        setIsUploading(false);
    }, []);

    const uploadFiles = React.useCallback(
        async (files: File[], uploadFile: UploadFile<T>, onSuccess: OnSuccess<T>) => {
            if (!files || files.length === 0) {
                return;
            }

            const failed: IFailedUpload[] = [];
            const success: IUpload<T>[] = [];

            setIsUploading(true);

            // Reset progress bar
            setCurrentUploadFile(0);

            // Iterate over file list and upload each file
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                try {
                    if (TEST_FAILED_UPLOADS && i % 2 === 1) {
                        throw new Error("upload failed");
                    }

                    const response = await uploadFile(file);

                    // Update progress bar
                    setCurrentUploadFile(i + 1);

                    success.push({ file, response });
                } catch (error) {
                    const apiError = getApiError(error);
                    if (apiError?.statusCode === HttpStatusCode.BadRequest_400) {
                        try {
                            // Show info if pdf is too big
                            if (apiError.response.type === "ACCOUNTING_PDF_DIMENSIONS") {
                                failed.push({
                                    file,
                                    errorMessage: t("error.imageTooBig"),
                                    info: t("documentUpload.error.tooBigImages.pdf", {
                                        maxImageDimension: MAX_IMAGE_DIMENSION,
                                    }),
                                });
                            } else {
                                failed.push({ file });
                            }
                        } catch {
                            failed.push({ file });
                        }
                    } else {
                        failed.push({ file });
                    }
                }
            }

            setIsUploading(false);

            // Append new successful uploads to previous ones
            const newSuccess = successfulUploads.concat(success);
            setSuccessfulUploads(newSuccess);
            setFailedUploads(failed);

            if (failed.length === 0) {
                resetState();
                onSuccess(newSuccess);
            }
        },
        [resetState, successfulUploads],
    );

    const onDrop = React.useCallback(
        async (
            acceptedFiles: File[],
            fileRejections: FileRejection[],
            uploadFile: UploadFile<T>,
            onSuccess: OnSuccess<T>,
        ) => {
            setSuccessfulUploads([]);
            setUploadFile(() => uploadFile);
            setOnSuccess(() => onSuccess);

            const { accepted, rejected } = filterFilesSync(acceptedFiles, fileRejections, {
                acceptedFileTypes,
                maxFileSizeBytes,
                maxFiles,
            });

            setAcceptedFiles(accepted);
            setRejectedFiles(rejected);

            if (filterBigImages && acceptedFiles.length > 0) {
                const tooBig = await filterTooBigImages(acceptedFiles);
                if (tooBig.length > 0) {
                    setTooBigImages(tooBig);
                    return;
                }
            }

            // No images too big, no rejected files -> proceed with upload
            if (!rejected || rejected.length === 0) {
                await uploadFiles(acceptedFiles, uploadFile, onSuccess);
            }
        },
        [acceptedFileTypes, filterBigImages, maxFileSizeBytes, maxFiles, uploadFiles],
    );

    const hasTooManyFiles = rejectedFiles.some(f => f.errors.some(e => e.code === "too-many-files"));
    const hasRejectedFiles = rejectedFiles.length > 0;
    const hasFailedUploads = failedUploads.length > 0;
    const hasTooBigImages = tooBigImages.length > 0;

    let dialogOpen;
    if (hasTooManyFiles) {
        dialogOpen = "tooManyFiles" as const;
    } else if (hasRejectedFiles) {
        dialogOpen = "rejected" as const;
    } else if (hasTooBigImages) {
        dialogOpen = "tooBig" as const;
    } else if (hasFailedUploads) {
        dialogOpen = "failed" as const;
    }

    const progress = !isUploading ? null : { current: currentUploadFile, total: acceptedFiles.length };

    const dialogs = (
        <>
            <FailedDialog // Failed dialog for too many files
                open={dialogOpen === "tooManyFiles"}
                message={
                    <FormattedHTMLMessage
                        id={
                            maxFiles === 1
                                ? "documentUpload.error.tooManyFiles.singular"
                                : "documentUpload.error.tooManyFiles.plural"
                        }
                        values={{
                            count: maxFiles ?? 0,
                        }}
                    />
                }
                totalFiles={acceptedFiles.length + rejectedFiles.length}
                onClose={() => {
                    setRejectedFiles([]);
                    setTooBigImages([]);
                    setAcceptedFiles([]);
                }}
            >
                {rejectedFiles.map((failedFile, index) => (
                    <DialogErrorLine fileName={failedFile.file.name} key={index} />
                ))}
            </FailedDialog>

            <FailedDialog // Failed dialog for too big images
                open={dialogOpen === "tooBig"}
                title={t("documentUpload.tooBigImages.message")}
                message={
                    <FormattedHTMLMessage
                        id={
                            tooBigImages.length === 1
                                ? "documentUpload.error.tooBigImages.amount.singular"
                                : "documentUpload.error.tooBigImages.amount"
                        }
                        values={{ failedUploads: tooBigImages.length, maxImageDimension: MAX_IMAGE_DIMENSION }}
                    />
                }
                submitText={t("documentUpload.error.tooBigImages.button.text.continue")}
                totalFiles={acceptedFiles.length + rejectedFiles.length}
                onClose={() => {
                    setRejectedFiles([]);
                    setTooBigImages([]);
                    setAcceptedFiles([]);
                }}
                onSubmit={() => {
                    setTooBigImages([]);
                    if (acceptedFiles.length > 0 && !hasRejectedFiles) {
                        if (uploadFile && onSuccess) {
                            uploadFiles(acceptedFiles, uploadFile, onSuccess);
                        }
                    }
                }}
            >
                {tooBigImages.map((failedFile, index) => {
                    return (
                        <DialogErrorLine
                            fileName={failedFile.name}
                            infoTitle={getSupportedImageFormatsString()}
                            infoBody={t("documentUpload.max.size", {
                                maxUploadSizeMb: MAX_UPLOAD_SIZE_MB,
                            })}
                            key={index}
                        />
                    );
                })}
            </FailedDialog>

            <FailedDialog // Failed dialog for rejected files
                open={dialogOpen === "rejected"}
                message={
                    <FormattedHTMLMessage
                        id="documentUpload.error.rejection.amount"
                        values={{
                            failedUploads: rejectedFiles.length,
                            uploads: acceptedFiles.length + rejectedFiles.length,
                        }}
                    />
                }
                totalFiles={acceptedFiles.length + rejectedFiles.length}
                onClose={() => {
                    setRejectedFiles([]);
                    setTooBigImages([]);
                    setAcceptedFiles([]);
                }}
                onSubmit={() => {
                    setRejectedFiles([]);
                    if (tooBigImages.length === 0) {
                        if (uploadFile && onSuccess) {
                            uploadFiles(acceptedFiles, uploadFile, onSuccess);
                        }
                    }
                }}
            >
                <FileRejections
                    fileRejections={rejectedFiles}
                    rejectionInfoText={getSupportedFormatsString(acceptedFileTypes)}
                    maxFileSizeBytes={maxFileSizeBytes}
                />
            </FailedDialog>

            <FailedDialog // Failed dialog for failed uploads
                open={dialogOpen === "failed"}
                message={
                    <FormattedHTMLMessage
                        id="documentUpload.error.amount"
                        values={{
                            failedUploads: failedUploads.length,
                            uploads: acceptedFiles.length,
                        }}
                    />
                }
                onClose={() => {
                    resetState();
                    if (successfulUploads.length > 0) {
                        onSuccess?.(successfulUploads);
                    }
                }}
                totalFiles={acceptedFiles.length}
                onSubmit={
                    successfulUploads.length > 0
                        ? () => {
                              resetState();
                              onSuccess?.(successfulUploads);
                          }
                        : undefined
                }
                onRetry={() => {
                    setFailedUploads([]);
                    const failedFiles = failedUploads.map(failed => failed.file);
                    setAcceptedFiles(failedFiles);
                    if (uploadFile && onSuccess) {
                        uploadFiles(failedFiles, uploadFile, onSuccess);
                    }
                }}
            >
                {failedUploads.map((failedFile, index) => {
                    return (
                        <DialogErrorLine
                            errorMessage={failedFile.errorMessage}
                            fileName={failedFile.file.name}
                            infoTitle={failedFile.info ?? t("screen.accounting.records.serverUpload.error")}
                            key={index}
                        />
                    );
                })}
            </FailedDialog>
        </>
    );

    return {
        isUploading,
        onDrop,
        rejectedFiles,
        progress,
        dialogs,
    };
};
