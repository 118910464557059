import React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { FeedCountry } from "../../network/APITypes";

let cache: { countryCode: string; iconUrl: string }[] = [];

// Load feed countries. Only done once, then cached.
export const useFeedCountries = () => {
    const [countries, setCountries] = React.useState<FeedCountry[]>([]);
    React.useEffect(() => {
        const load = async () => {
            try {
                generalStore.isLoading = true;

                cache = await API.getFeedCountries();
                setCountries(cache);
            } catch (err) {
                generalStore.setError(t("error.loadFeedCountries"), err);
            } finally {
                generalStore.isLoading = false;
            }
        };

        if (!cache || cache.length === 0) {
            load();
        } else {
            setCountries(cache);
        }
    }, []);

    return {
        countries,
        getCountryByCode: (countryCode?: string) =>
            countries.find(c => c.countryCode.toLowerCase() === countryCode?.toLowerCase()),
    };
};
