import { Button, Collapse, IconButton } from "@material-ui/core";
import { useContext, useState } from "react";
import { POPUP_WINDOW_FEATURES } from "../../config";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { BankConnection } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { formatDate } from "../../util/helpers";
import { useConfirmationDialog } from "../hooks/useConfirmationDialog";
import { useExternalWindow } from "../hooks/useExternalWindow";
import { InfoButton, TableRowButton } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { BOX_SHADOW_LIGHT, customColors } from "../util/Theme";
import { BankConnectionAccountListItem } from "./BankConnectionAccountListItem";

export const BankConnectionListItem = ({
    bankConnection,
    expiresDuringPeriod,
    expired,
    refreshPending,
    onRefresh,
}: {
    bankConnection: BankConnection;
    expiresDuringPeriod?: boolean;
    expired?: boolean;
    refreshPending?: boolean;
    onRefresh: () => void;
}) => {
    const isMobile = useContext(MobileContext);
    const [showAccounts, setShowAccounts] = useState(false);
    const externalWindow = useExternalWindow();

    const handleDeleteBankConnection = async () => {
        try {
            generalStore.isLoading = true;
            if (companiesStore.selectedCompanyId) {
                await API.deleteBankConnections(companiesStore.selectedCompanyId, bankConnection.bankConnectionId);
                await companiesStore.loadBankConnections();
            }
        } catch (e) {
            generalStore.setError(t("error.deleteBankConnection"), e);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const deleteDialog = useConfirmationDialog({
        title: t("accounting.bankConnection.delete.title"),
        message: t("accounting.bankConnection.delete.message", { name: bankConnection.name }),
        confirmLabel: t("common.delete"),
        onConfirm: handleDeleteBankConnection,
    });

    const handleRefreshBankConnection = async () => {
        try {
            generalStore.isLoading = true;
            if (companiesStore.selectedCompanyId) {
                const response = await API.putBankConnections(
                    companiesStore.selectedCompanyId,
                    bankConnection.bankConnectionId,
                );
                if (response.url) {
                    externalWindow.open(response.url, "TPA", POPUP_WINDOW_FEATURES);
                    onRefresh();
                }
            }
        } catch (e) {
            generalStore.setError(t("error.refreshBankConnection"), e);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const handleToggleAccounts = () => {
        setShowAccounts(!showAccounts);
    };

    const expirationLabel = refreshPending
        ? t("accounting.bankConnections.refreshPending.label")
        : t(expired ? "accounting.bankConnections.expiredAt" : "accounting.bankConnections.expiresAt", {
              date: formatDate(bankConnection.expiresAt),
          });

    const showRefreshButton = !!refreshPending || !!expired || !!expiresDuringPeriod;

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "16px 0px",
                    backgroundColor: "white",
                    borderRadius: 4,
                    boxShadow: BOX_SHADOW_LIGHT,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 8,
                    }}
                    onClick={handleToggleAccounts}
                >
                    <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", flexDirection: "column", marginLeft: 8 }}>
                            <h4>{bankConnection.name}</h4>
                            <p>{expirationLabel}</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {!isMobile && showRefreshButton && (
                            <TableRowButton
                                onClick={e => {
                                    handleRefreshBankConnection();
                                    e.stopPropagation();
                                }}
                                disabled={!authStore.canEditBanking()}
                                color="primary"
                            >
                                {t("accounting.bankConnections.refreshBankConnection").toUpperCase()}
                            </TableRowButton>
                        )}

                        {refreshPending && <InfoButton title={t("accounting.bankConnections.refreshPending.info")} />}

                        <IconButton
                            onClick={e => {
                                deleteDialog.open();
                                e.stopPropagation();
                            }}
                            disabled={!authStore.canDeleteBanking()}
                        >
                            <Icon
                                style={{
                                    color: authStore.canDeleteBanking()
                                        ? customColors.greyDarkIcons
                                        : customColors.disabled,
                                }}
                                name="delete"
                            />
                        </IconButton>
                        <Icon style={{ margin: 8 }} name={showAccounts ? "chevronUp" : "chevronDown"} />
                    </div>
                </div>
                <Collapse style={{ marginLeft: 8 }} in={showAccounts}>
                    {bankConnection.accounts?.map(account => {
                        return <BankConnectionAccountListItem key={account.accountId} bankAccount={account} />;
                    })}
                </Collapse>
                {isMobile && showRefreshButton && (
                    <Button
                        style={{ margin: 8 }}
                        onClick={e => {
                            handleRefreshBankConnection();
                            e.stopPropagation();
                        }}
                        variant="outlined"
                        color="primary"
                        disabled={!authStore.canEditBanking()}
                    >
                        {t("accounting.bankConnections.refreshBankConnection")}
                    </Button>
                )}
            </div>
            {deleteDialog.dialog}
            {externalWindow.components}
        </>
    );
};
