import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { toJS } from "mobx";
import moment from "moment";
import * as React from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { IEmployee, IEmployeeRouteParams } from "../../../types/models";
import { pushRoute, withParamsAndQuery } from "../../app/router/history";
import { useEmployee } from "../../hooks/useEmployee";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useReasonsForLeaving } from "../../hooks/useReasonsForLeaving";
import { CustomAutoComplete } from "../../ui/CustomAutoComplete";
import { CustomDatePicker } from "../../ui/CustomDatePicker";
import { CustomInputField } from "../../ui/CustomInputField";
import { MobileContext } from "../../util/MobileContext";
import { HrDeregistrationSiteContainer } from "../HrDeregistrationSiteContainer";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

const SectionContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
`;

export const HrEmployeesDeregistrationDataSite = () => {
    useHideSideBar();

    const isMobile = React.useContext(MobileContext);

    const [isLoading, setIsLoading] = React.useState(false);

    const query = useQueryParams<{ isPreRegistration: boolean }>();
    const isPreRegistration = !!query.isPreRegistration;

    const { employeeId } = useParams<IEmployeeRouteParams>();
    const { employee } = useEmployee(employeeId, isPreRegistration);

    const reasonsForLeaving = useReasonsForLeaving();

    if (!reasonsForLeaving) {
        // Wait until reasons have loaded
        return null;
    }

    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = hrStore.selectedSubsidiaryId;

    const onSubmit = async (deregistration: IEmployee) => {
        if (employeeId) {
            try {
                const payload = { ...deregistration };
                if (payload.reasonForLeaving) {
                    // Replace reason for leaving by id
                    payload.reasonForLeaving = reasonsForLeaving.stringToId(payload.reasonForLeaving);
                }

                setIsLoading(true);
                let newEmployeeId = employeeId;
                if (isPreRegistration) {
                    await API.patchPreRegistration(companyId, subsidiaryId, payload);
                } else {
                    const preRegistration = await API.postEmployeeDeregistration(companyId, subsidiaryId, payload);
                    newEmployeeId = preRegistration.id;
                }

                setIsLoading(false);

                pushRoute(HrRoutes.EMPLOYEES.DEREGISTRATION_DOCUMENTS, {
                    params: { employeeId: newEmployeeId },
                    query: { isPreRegistration: true },
                });
            } catch (error) {
                setIsLoading(false);
                generalStore.setError(t("error.deregisterEmployee"), error);
            }
        }
    };

    const reasonsForLeavingStrings = reasonsForLeaving.strings;

    if (!employee || !employee.dateOfJoining || !reasonsForLeaving.reasons) {
        return null;
    }

    const initialValues = toJS(employee);

    const reason = initialValues.reasonForLeaving ? reasonsForLeaving.findById(initialValues.reasonForLeaving) : null;
    initialValues.reasonForLeaving = reason ? reason.reason : null;

    if (employee.dateOfLeaving === undefined) {
        employee.dateOfLeaving = null;
        employee.reasonForLeaving = "";
        employee.remainingOvertime = "";
        employee.remainingVacationDays = "";
    }

    const joinDate = moment(employee.dateOfJoining).endOf("day").toDate();

    let backTarget = withParamsAndQuery(HrRoutes.EMPLOYEES.DETAILS, { employeeId }, query);
    if (employee.status === "inDeregistrationNotTransferred") {
        backTarget = HrRoutes.EMPLOYEES.IN_PROGRESS;
    }

    return (
        <HrDeregistrationSiteContainer
            backTarget={backTarget}
            backLabel={t("screen.hr.deregistration.data.navback", {
                firstname: employee.firstName,
                lastname: employee.lastName,
            })}
            employee={employee}
            query={query}
        >
            <h1 style={{ marginBottom: 16 }}>{t("screen.hr.employee.deregistration.data.header")}</h1>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                    reasonForLeaving: Yup.string()
                        .required(t("screen.hr.deregistration.validation.deregistrationReason"))
                        .nullable()
                        .trim(),
                    dateOfLeaving: Yup.date()
                        .required(t("screen.hr.deregistration.validation.deregistrationDate"))
                        .typeError(t("common.dateValidation"))
                        .nullable()
                        .min(joinDate, t("screen.hr.deregistration.validation.deregistrationDateMin")),
                    remainingVacationDays: Yup.string()
                        .required(t("screen.hr.deregistration.validation.remainingHolidays"))
                        .trim()
                        .matches(/^[0-9]+([,|.][0-9]+)?$/, t("common.numberValidation"))
                        .nullable(),
                    remainingOvertime: Yup.string()
                        .required(t("screen.hr.deregistration.validation.remainingOvertime"))
                        .trim()
                        .matches(/^[0-9]+([,|.][0-9]+)?$/, t("common.numberValidation"))
                        .nullable(),
                })}
                validateOnBlur
                validateOnChange
            >
                {formProps => {
                    return (
                        <Form>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <SectionContainer>
                                    <Field
                                        style={{ flexGrow: 1 }}
                                        component={CustomAutoComplete}
                                        name="reasonForLeaving"
                                        data-id="reason_for_leaving"
                                        label={t("screen.hr.employee.deregistration.data.reason")}
                                        options={reasonsForLeavingStrings}
                                        required
                                    />
                                    <Field
                                        component={CustomDatePicker}
                                        data-id="date_of_leaving"
                                        label={t("screen.hr.employee.deregistration.data.date")}
                                        name="dateOfLeaving"
                                        minDate={
                                            employee.dateOfJoining
                                                ? moment(employee.dateOfJoining).add(1, "day").toDate()
                                                : undefined
                                        }
                                        required
                                    />
                                    <div
                                        style={
                                            isMobile
                                                ? { display: "flex", flexDirection: "column" }
                                                : {
                                                      display: "flex",
                                                      justifyContent: "space-between",
                                                  }
                                        }
                                    >
                                        <Field
                                            style={isMobile ? { flexGrow: 1 } : { width: "50%", marginRight: 16 }}
                                            name="remainingVacationDays"
                                            component={CustomInputField}
                                            data-id="remaining_vacation_days"
                                            label={t("screen.hr.employee.deregistration.data.remainingHolidays")}
                                            required
                                        />
                                        <Field
                                            style={isMobile ? { flexGrow: 1 } : { width: "50%" }}
                                            name="remainingOvertime"
                                            component={CustomInputField}
                                            data-id="remaining_overtime"
                                            label={t("screen.hr.employee.deregistration.data.remainingOvertime")}
                                            required
                                        />
                                    </div>
                                </SectionContainer>

                                <div style={{ textAlign: "right" }}>
                                    <Button
                                        data-id="deregistration_submit"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        {t("screen.hr.personaldata.nextpage")}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </HrDeregistrationSiteContainer>
    );
};
