import { Redirect, Route, Switch } from "react-router-dom";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { PowerBIReportSite } from "../sites/PowerBIReportSite";
import { PowerBIReportsSite } from "../sites/PowerBIReportsSite";
import { AnalysisRoutes } from "./AnalysisRoutes";

export const AnalysisRouter = () => {
    return (
        <Switch>
            <Route exact path={AnalysisRoutes.ROOT}>
                <Redirect to={AnalysisRoutes.POWERBI_REPORTS} />
            </Route>
            <Route exact path={AnalysisRoutes.POWERBI_REPORTS} component={PowerBIReportsSite} />
            <Route exact path={AnalysisRoutes.POWERBI_REPORT} component={PowerBIReportSite} />
            <Route component={NotFoundSite} />
        </Switch>
    );
};
