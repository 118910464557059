import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../config";

export const ResponsiveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * + * {
        margin-left: 16px;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        flex-direction: column;
        align-items: initial;

        & > * + * {
            margin: 0;
            margin-top: 16px;
        }
    }
`;
