import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Routes } from "../../app/router/Routes";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { HrEmployeesDocumentsSite } from "../../hr/sites/HrEmployeesDocumentsSite";
import { HrEmployeesRegistrationDocumentsSite } from "../../hr/sites/HrEmployeesRegistrationDocumentsSite";
import { StaffPersonnelFileSite } from "../sites/StaffPersonnelFileSite";
import { StaffRoutes } from "./StaffRoutes";

export const StaffRouter = () => (
    <Switch>
        <Route exact path={Routes.STAFF}>
            <StaffPersonnelFileSite />
        </Route>
        <Route path={StaffRoutes.DOCUMENTS}>
            <HrEmployeesDocumentsSite staffDocuments />
        </Route>
        <Route path={StaffRoutes.UPLOAD_DOCUMENTS}>
            <HrEmployeesRegistrationDocumentsSite isStaffUpload />
        </Route>
        <Route>
            <NotFoundSite />
        </Route>
    </Switch>
);
