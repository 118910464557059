import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { ChatUserInfo } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useErstwhileChatUsers = (companyId?: string, userType?: "tpa" | "customer" | "all") => {
    const [users, setUsers] = React.useState<ChatUserInfo[]>([]);

    const load = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const response = await API.getErstwhileChatUsers({ companyId, userType });
            setUsers(response);
        } catch (error) {
            generalStore.setError(t("error.loadContacts"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, userType]);

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        users,
        reload: load,
        isInactive(userId: string) {
            return users.find(u => u.id === userId);
        },
    };
};
