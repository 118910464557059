import * as React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { companiesStore } from "../../../stores/CompaniesStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { useQueryParams } from "../../hooks/useQueryParams";
import { AuthGeneralContainerSite } from "./AuthGeneralContainerSite";

// MS OAuth login redirects here
export const AuthOAuthSite = () => {
    const {
        client_id,
        redirect_uri,
        scope,
        state,
    }: { client_id?: string; redirect_uri?: string; scope?: string; state?: string } = useQueryParams();

    const [showError, setShowError] = React.useState<"error_url" | "error_api" | "error_missingCompany" | undefined>();

    useHideSideBar();

    React.useEffect(() => {
        const authorize = async () => {
            try {
                if (client_id && redirect_uri) {
                    let companyId = companiesStore.selectedCompanyId;
                    if (!companyId) {
                        // This should never happen for users that have companies assigned,
                        // but in case it does -> load first company and use it
                        const res = await API.getCompanies({
                            limit: 1,
                        });

                        companyId = res.companies?.[0]?.id;
                    }

                    // CONNECT-225: We now allow users to login even without a company assigned
                    const response = await API.postOAuthAuthorize({
                        client_id,
                        company_id: companyId,
                        redirect_uri,
                        response_type: "code",
                        state,
                        scope,
                    });

                    window.open(response.redirect_uri, "_self");
                } else {
                    setShowError("error_url");
                }
            } catch (err) {
                setShowError("error_api");
            }
        };

        authorize();
    }, [client_id, redirect_uri, scope, state]);

    if (!showError) {
        return null;
    }

    return (
        <AuthGeneralContainerSite showNavBar={false}>
            <h1>{t("common.errorOccurred")}</h1>
            {showError === "error_url" && <p style={{ marginTop: 32 }}>{t("error.oauthUrl")}</p>}
            {showError === "error_api" && <p style={{ marginTop: 32 }}>{t("error.oauthApi")}</p>}
            {showError === "error_missingCompany" && <p style={{ marginTop: 32 }}>{t("error.oauthCompany")}</p>}
        </AuthGeneralContainerSite>
    );
};
