import compact from "lodash/compact";
import flatMap from "lodash/flatMap";
import intersection from "lodash/intersection";
import { observer } from "mobx-react";
import * as React from "react";
import { IMessageIDS, t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { GetTicketCountGroupedResponse } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { periodToString } from "../../../stores/ModuleStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { AccountingRoutes } from "../../accounting/router/AccountingRoutes";
import { pushRoute, withParams } from "../../app/router/history";
import { hrStore } from "../../hr/HrStore";
import { HrRoutes } from "../../hr/router/HrRoutes";
import { ProjectsRoutes } from "../../projects/router/ProjectsRoutes";
import { CenteredContent } from "../../ui/CenteredContent";
import { LinkCard } from "../../ui/LinkCard";
import { SiteContent } from "../../ui/SiteContent";
import { IIconNames } from "../../util/Icon";
import { CockpitNavBar } from "../CockpitNavBar";
import { CockpitRoutes } from "../router/CockpitRoutes";

type ICategory = keyof GetTicketCountGroupedResponse;

const categorySortOrder: ICategory[] = ["accounting", "hr", "hrPreRegistrations", "projects"];

const ticketCategoryNames: { [key in ICategory]: IMessageIDS } = {
    accounting: "common.accounting",
    hr: "common.hr",
    hrPreRegistrations: "common.hrPreRegistrations",
    projects: "common.projects",
};

export const ticketCategoryIcons: { [key in ICategory]: IIconNames } = {
    accounting: "accounting",
    hr: "personnel",
    hrPreRegistrations: "personnel",
    projects: "projects", // TODO where is this type?
};

export const CockpitOpenTicketsSite = observer(function CockpitOpenTicketsSite() {
    useHideSideBar();
    const [tickets, setTickets] = React.useState<GetTicketCountGroupedResponse>({});

    React.useEffect(() => {
        const loadTickets = async () => {
            if (!companiesStore.selectedCompanyId) {
                return;
            }

            try {
                generalStore.isLoading = true;

                const ticketsResponse = await API.getTicketsGrouped(companiesStore.selectedCompanyId);

                setTickets(ticketsResponse);
            } catch (error) {
                // TODO more specific error
                generalStore.setError(t("error.general"), error);
            } finally {
                generalStore.isLoading = false;
            }
        };

        loadTickets();
    }, []);

    // Need this so that badges match with list
    React.useEffect(() => {
        companiesStore.selectedCompanyStore?.startPollingBadges();
    }, []);

    const categories = intersection<ICategory>(categorySortOrder, Object.keys(tickets) as ICategory[]);

    const handleClickGroup = (category: ICategory, periodId: string, subsidiaryId?: string) => async () => {
        let moduleStore;
        if (category === "accounting") {
            pushRoute(AccountingRoutes.ROOT, { query: { activeCard: subsidiaryId ? "tickets" : undefined } });
            moduleStore = getModuleStore("accounting");
        } else if (category === "hr") {
            pushRoute(HrRoutes.ROOT, { query: { activeCard: subsidiaryId ? "tickets" : undefined } });
            moduleStore = getModuleStore("hr");
        }

        if (moduleStore) {
            await moduleStore.selectPeriodById(periodId, subsidiaryId);
        }
    };

    const handleClickProjectGroup = (projectId: string) => () => {
        pushRoute(withParams(ProjectsRoutes.RELEASES, { projectId }));
    };

    const handleClickPreRegistration = (subsidiaryId: string) => async () => {
        if (await hrStore.selectCurrentPeriod(subsidiaryId)) {
            pushRoute(HrRoutes.EMPLOYEES.TICKETS);
        }
    };

    return (
        <>
            <CockpitNavBar title={t("screen.cockpit.tickets.navbar.heading")} backTarget={CockpitRoutes.ROOT} />
            <CenteredContent>
                <SiteContent>
                    {compact(
                        categories.map((category, categoryIndex) => {
                            const categoryHasTickets =
                                (category === "projects" && (tickets[category]?.length ?? 0) > 0) ||
                                (category !== "projects" &&
                                    ((tickets[category]?.global?.length ?? 0) > 0 ||
                                        (tickets[category]?.subsidiaries.length ?? 0) > 0));

                            if (!categoryHasTickets) {
                                // Not tickets in this category -> get out
                                return null;
                            }

                            return (
                                <div key={category} style={{ marginTop: categoryIndex > 0 ? 24 : undefined }}>
                                    <h4 style={{ paddingBottom: 8 }}>{t(ticketCategoryNames[category])}</h4>
                                    {category === "projects" &&
                                        tickets[category]?.map(project => (
                                            <LinkCard
                                                key={project.id}
                                                onClick={handleClickProjectGroup(project.id)}
                                                name={ticketCategoryIcons[category]}
                                                status="inProgress"
                                                title={project.name}
                                                ticketCount={project.ticketCount}
                                                style={{ marginTop: 8 }}
                                            />
                                        ))}
                                    {category !== "projects" && (
                                        <>
                                            {tickets[category]?.global?.map(period => {
                                                return (
                                                    <LinkCard
                                                        key={period.id}
                                                        onClick={handleClickGroup(category, period.id)}
                                                        name={ticketCategoryIcons[category]}
                                                        status="inProgress"
                                                        title={`${t(ticketCategoryNames[category])} ${periodToString(
                                                            period,
                                                        )}, ${t("common.global")}`}
                                                        ticketCount={period.ticketCount}
                                                        style={{ marginTop: 8 }}
                                                    />
                                                );
                                            })}
                                            {flatMap(tickets[category]?.subsidiaries, subsidiary => {
                                                if (category === "hrPreRegistrations") {
                                                    return (
                                                        <LinkCard
                                                            key={subsidiary.id}
                                                            onClick={handleClickPreRegistration(subsidiary.id)}
                                                            name={ticketCategoryIcons[category]}
                                                            status="inProgress"
                                                            title={`${t(ticketCategoryNames[category])}, ${
                                                                subsidiary.name
                                                            }`}
                                                            ticketCount={subsidiary.ticketCount}
                                                            style={{ marginTop: 8 }}
                                                        />
                                                    );
                                                } else {
                                                    return subsidiary.periods?.map(period => (
                                                        <LinkCard
                                                            key={period.id + subsidiary.id}
                                                            onClick={handleClickGroup(
                                                                category,
                                                                period.id,
                                                                subsidiary.id,
                                                            )}
                                                            name={ticketCategoryIcons[category]}
                                                            status="inProgress"
                                                            title={`${t(
                                                                ticketCategoryNames[category],
                                                            )} ${periodToString(period)}, ${subsidiary.name}`}
                                                            ticketCount={period.ticketCount}
                                                            style={{ marginTop: 8 }}
                                                        />
                                                    ));
                                                }
                                            })}
                                        </>
                                    )}
                                </div>
                            );
                        }),
                    )}
                </SiteContent>
            </CenteredContent>
        </>
    );
});
