import { Button, List } from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { MAX_OPTIONAL_ITEM_NAME_LENGTH } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { CustomMenuItem } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { useOptionalSideBarMenuItems } from "../../hooks/useOptionalSideMenuItems";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { CenteredContent } from "../../ui/CenteredContent";
import { CustomInputField } from "../../ui/CustomInputField";
import { OptionalMenuItem } from "../../ui/OptionalMenuItem";
import { SiteContent } from "../../ui/SiteContent";
import { DIALOG_WIDTH } from "../../util/Theme";
import { SettingsRoutes } from "../router/SettingsRoutes";

export const SettingsCompanyOptionalMenuItemsSite = () => {
    const [currentItems, setCurrentItems] = React.useState<CustomMenuItem[]>([]);
    const { optionalSideBarMenuItems } = useOptionalSideBarMenuItems(companiesStore.selectedCompanyId);

    React.useEffect(() => {
        setCurrentItems(optionalSideBarMenuItems ?? []);
    }, [optionalSideBarMenuItems]);

    const successDialog = useSuccessDialog({
        title: t("config.optionalMenuPoints.success.title"),
        onClose: () => {
            companiesStore.forceReloadOptionalSideBarItems = true;
            pushRoute(SettingsRoutes.COMPANY.DATA);
        },
    });

    const handleAddMenuItem = (model: CustomMenuItem, formikHelpers: FormikHelpers<CustomMenuItem>) => {
        const duplicateName = currentItems.filter(item => item.name === model.name);
        const duplicateUrl = currentItems.filter(item => item.url === model.url);

        if (duplicateName.length > 0) {
            formikHelpers.setFieldError("name", t("company.optionalSideBarMenuItems.nameAlreadyinUse"));
        } else if (duplicateUrl.length > 0) {
            formikHelpers.setFieldError("url", t("company.optionalSideBarMenuItems.urlAlreadyinUse"));
        } else {
            const newItems = [...currentItems, model];
            setCurrentItems(newItems);
            formikHelpers.resetForm();
        }
    };

    const handleSubmit = async () => {
        const companyId = companiesStore.selectedCompanyId;

        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            await API.putOptionalMenuItems(companyId, currentItems);
            successDialog.openDialog();
        } catch (error) {
            generalStore.setError(t("error.saveOptionalMenuItems"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const handleDeleteItem = (menuItem: { name: string; url: string }) => {
        const newItems = currentItems.filter(item => item.name !== menuItem.name);
        setCurrentItems(newItems);
    };

    const handleSortUp = (index: number) => {
        if (index > 0) {
            const newItems = [...currentItems];
            const temp = newItems[index];
            newItems.splice(index, 1, newItems[index - 1]);
            newItems.splice(index - 1, 1, temp);
            setCurrentItems(newItems);
        }
    };

    const handleSortDown = (index: number) => {
        if (index < currentItems.length - 1) {
            const newItems = [...currentItems];
            const temp = newItems[index];
            newItems.splice(index, 1, newItems[index + 1]);
            newItems.splice(index + 1, 1, temp);
            setCurrentItems(newItems);
        }
    };

    return (
        <CenteredContent>
            <SiteContent style={{ maxWidth: DIALOG_WIDTH, paddingTop: 40 }}>
                <h4 style={{ marginBottom: 24 }}>{t("company.optionalSideBarMenuItems.title")}</h4>
                <Formik
                    initialValues={{
                        name: "",
                        url: "",
                    }}
                    onSubmit={handleAddMenuItem}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required(t("company.optionalSideBarMenuItems.required.name"))
                            .max(MAX_OPTIONAL_ITEM_NAME_LENGTH, t("company.optionalSideBarMenuItems.maxLength.name")),
                        url: Yup.string()
                            .url(t("company.optionalSideBarMenuItems.urlNotValid"))
                            .required(t("company.optionalSideBarMenuItems.required.url")),
                    })}
                >
                    <Form>
                        <Field
                            component={CustomInputField}
                            label={t("company.optionalSideBarMenuItems.name.label")}
                            name="name"
                            required
                        />
                        <Field
                            component={CustomInputField}
                            label={t("company.optionalSideBarMenuItems.url.label")}
                            name="url"
                            required
                        />
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button color="primary" type="submit">
                                {t("common.add")}
                            </Button>
                        </div>
                        <List>
                            {currentItems.length === 0 && (
                                <h3 style={{ textAlign: "center", marginTop: 32 }}>
                                    {t("config.customMenuPoints.noMenuPointsAvailable")}
                                </h3>
                            )}
                            {currentItems.map((item, index) => {
                                return (
                                    <OptionalMenuItem
                                        key={item.name}
                                        item={item}
                                        onDeleteItem={handleDeleteItem}
                                        onSortDown={() => {
                                            handleSortDown(index);
                                        }}
                                        onSortUp={() => {
                                            handleSortUp(index);
                                        }}
                                        isFirst={index === 0}
                                        isLast={index === currentItems.length - 1}
                                    />
                                );
                            })}
                        </List>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                style={{ marginTop: 40 }}
                            >
                                {t("common.save")}
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </SiteContent>
            {successDialog.dialog}
        </CenteredContent>
    );
};
