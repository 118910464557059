import * as React from "react";
import { t } from "../../i18n/util";
import { IEmployee } from "../../types/models";

export const HrEmployeeOverviewHeader = ({ employee, button }: { employee: IEmployee; button?: React.ReactNode }) => (
    <>
        <p className="caption" style={{ fontWeight: "bold", marginBottom: 16 }}>
            {t("screen.hr.employee.details.personnelNumber") + (employee.personnelNumber ?? "")}
        </p>
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 24,
                flexWrap: "wrap",
            }}
        >
            <h1>{t("screen.hr.employee.details.personnelFile")}</h1>
            {button}
        </div>
    </>
);
