import { Button, Divider, Drawer, Fab, SwipeableDrawer } from "@material-ui/core";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import OndemandVideoOutlinedIcon from "@material-ui/icons/OndemandVideoOutlined";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import React, { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { HELP_CENTER_WIDTH } from "../../config";
import { GLOBAL_FEATURES } from "../../features";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { useSuccessDialog } from "../hooks/useSuccessDialog";
import { CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle } from "../ui/CustomDialog";
import { CustomInputField } from "../ui/CustomInputField";
import { Subtitle, TpaWhiteButton } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors, ZINDEX_DRAWER_OFFSET } from "../util/Theme";

export const HelpCenterElements = observer(function HelpCenterElements() {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const isMobile = React.useContext(MobileContext);

    if (authStore.isStaffOnly) {
        return null;
    }

    return (
        <>
            <Fab color="primary" onClick={handleOpen}>
                <Icon name="helpcenter" />
            </Fab>
            {isMobile ? (
                <SwipeableDrawer
                    anchor="right"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    style={{ zIndex: ZINDEX_DRAWER_OFFSET }} // zIndex 51 to stay above mui drawer and footer
                    PaperProps={{ style: { width: "100%" } }}
                >
                    <HelpCenter onClose={handleClose} isMobile />
                </SwipeableDrawer>
            ) : (
                <Drawer anchor="right" open={open} onClose={handleClose} style={{ zIndex: ZINDEX_DRAWER_OFFSET }}>
                    <HelpCenter onClose={handleClose} />
                </Drawer>
            )}
        </>
    );
});

const HelpCenter = observer(function HelpCenter({ onClose, isMobile }: { onClose: () => void; isMobile?: boolean }) {
    const [problemDialogOpen, setProblemDialogOpen] = useState(false);

    const handleProblemDialogClose = () => {
        setProblemDialogOpen(false);
    };
    const handleProblemDialogOpen = () => {
        setProblemDialogOpen(true);
    };

    return (
        <HelpCenterWrapper style={{ width: isMobile ? "100%" : HELP_CENTER_WIDTH }}>
            <div style={{ padding: "24px 16px", display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <h2>{t("helpCenter.title")}</h2>
                    {isMobile && (
                        <div style={{ alignSelf: "flex-end" }}>
                            <Icon
                                name="close"
                                style={{ color: customColors.primaryColor, cursor: "pointer", display: "block" }}
                                onClick={onClose}
                            />
                        </div>
                    )}
                </div>
                <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<OndemandVideoOutlinedIcon />}
                    onClick={() => window.open("https://www.tpa-connect.app/de/videos/", "_blank")}
                >
                    {t("helpcenter.videos")}
                </Button>
                {authStore.isTpa && (
                    <>
                        <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<OndemandVideoOutlinedIcon />}
                            onClick={() =>
                                window.open(
                                    "https://tpagroupat.sharepoint.com/sites/TPAConnectInfo/SitePages/Erklärvideos-der-TPA-Connect-App.aspx",
                                    "_blank",
                                )
                            }
                        >
                            {t("helpcenter.videos.tpaonly")}
                        </Button>
                    </>
                )}
                {authStore.isTpa && (
                    <>
                        <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EmojiObjectsOutlinedIcon />}
                            onClick={() => window.open("https://minijobs.its-tpa.com/uservoice/?tpa-connect", "_blank")}
                        >
                            {t("helpcenter.ideas")}
                        </Button>
                    </>
                )}

                {GLOBAL_FEATURES.helpCenterProblems && (
                    <>
                        <Divider style={{ marginBottom: 16, marginTop: 16 }} />
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Icon name="warning" />}
                            onClick={handleProblemDialogOpen}
                        >
                            {t("helpcenter.problems")}
                        </Button>
                        <HelpCenterDialog
                            key={problemDialogOpen ? "helpcenterdialog-open" : "helpcenterdialog-closed"}
                            isOpen={problemDialogOpen}
                            onClose={handleProblemDialogClose}
                        />
                    </>
                )}
            </div>
        </HelpCenterWrapper>
    );
});

const HelpCenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100%;
`;

interface IHelpCenterDialogData {
    optionalUserEmail: string;
    textInformation: string;
    textWhatShouldHappen: string;
    textWhatHappened: string;
}

const HelpCenterDialog = observer(function HelpCenterDialog({
    isMobile,
    isOpen,
    onClose,
}: {
    isMobile?: boolean;
    isOpen?: boolean;
    onClose: () => void;
}) {
    const successDialog = useSuccessDialog({
        title: t("helpcenter.problems.upload.success"),
        onClose: () => {
            onClose();
        },
    });

    const handleSubmit = async (props: IHelpCenterDialogData) => {
        try {
            generalStore.isLoading = true;

            // upload ticket
            await API.postBugs({
                textInformation: props.textInformation,
                textWhatShouldHappen: props.textWhatShouldHappen,
                textWhatHappened: props.textWhatHappened,
                companyId: companiesStore.selectedCompany?.id,
                optionalUserEmail: props.optionalUserEmail,
            });

            // show success dialog after successful upload
            successDialog.openDialog();
        } catch (e) {
            generalStore.setError(t("helpcenter.problems.upload.failed"), e);
        } finally {
            generalStore.isLoading = false;
        }
    };

    if (!GLOBAL_FEATURES.helpCenterProblems) {
        return null;
    }

    if (!isOpen) {
        return null;
    }

    return (
        <CustomDialog open={true}>
            <CustomDialogContent>
                <CustomDialogTitle>{t("helpcenter.problems")}</CustomDialogTitle>
                <Formik
                    initialValues={{
                        optionalUserEmail: "",
                        textInformation: "",
                        textWhatShouldHappen: "",
                        textWhatHappened: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object().shape({
                        optionalUserEmail: !authStore.isAuthenticated
                            ? Yup.string()
                                  .email(t("screen.auth.forgot_password_email.validation_error"))
                                  .required(t("screen.auth.forgot_password_email.validation_error"))
                            : Yup.string().nullable(),
                        textInformation: Yup.string().required(t("error.requiredField")),
                        textWhatShouldHappen: Yup.string().required(t("error.requiredField")),
                        textWhatHappened: Yup.string().required(t("error.requiredField")),
                    })}
                >
                    {() => {
                        return (
                            <Form style={{ width: "100%", textAlign: "left" }} noValidate>
                                {!authStore.isAuthenticated && (
                                    <div>
                                        <Subtitle>{t("helpcenter.problems.email")}</Subtitle>
                                        <Field component={CustomInputField} name="optionalUserEmail" required />
                                    </div>
                                )}
                                <Subtitle style={{ marginTop: 8 }}>{t("helpcenter.problems.whatwasdone")}</Subtitle>
                                <Field component={CustomInputField} name="textInformation" required />

                                <Subtitle style={{ marginTop: 8 }}>
                                    {t("helpcenter.problems.whatshouldhappen")}
                                </Subtitle>
                                <Field component={CustomInputField} name="textWhatShouldHappen" required />

                                <Subtitle style={{ marginTop: 8 }}>{t("helpcenter.problems.whathappened")}</Subtitle>
                                <Field component={CustomInputField} name="textWhatHappened" required />

                                <CustomDialogActions>
                                    <TpaWhiteButton onClick={onClose}>{t("common.cancel")}</TpaWhiteButton>
                                    <Button variant="contained" color="primary" type="submit">
                                        {t("helpcenter.problems.send")}
                                    </Button>
                                </CustomDialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </CustomDialogContent>
            {successDialog.dialog}
        </CustomDialog>
    );
});
