import * as React from "react";

/**
 * Manages the selection in a table.
 *
 * This is a less powerful alternative to the selection model built into the `TableStore`. Try using that one first
 * and fall back to this if it's impossible to manage the selection with it.
 */
export const useTableSelection = (elements: { id: string }[], onSelect?: (selected: string[]) => void) => {
    const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

    const toggleSelection = (id: string) => {
        const index = selectedIds.indexOf(id);
        let newSelected: string[] = [];
        if (index < 0) {
            newSelected = newSelected.concat(selectedIds, id);
        } else {
            newSelected = newSelected.concat(selectedIds.slice(0, index), selectedIds.slice(index + 1));
        }

        setSelectedIds(newSelected);
        if (onSelect) {
            onSelect(newSelected);
        }
    };

    const toggleSelectAll = () => {
        let selected: string[] = [];
        if (selectedIds.length === elements.length) {
            selected = [];
        } else {
            selected = elements.map(element => element.id);
        }

        setSelectedIds(selected);
        if (onSelect) {
            onSelect(selected);
        }
    };

    const isSelected = (id: string) => selectedIds.includes(id);
    const someSelected = selectedIds.length > 0 && selectedIds.length < elements.length;
    const allSelected = selectedIds.length === elements.length && elements.length > 0;
    const clearSelection = React.useCallback(() => {
        setSelectedIds([]);
    }, []);

    const getSelectedElements = <T,>() => {
        return elements.filter(e => selectedIds.includes(e.id)) as unknown as T[];
    };

    return {
        selectedIds,
        getSelectedElements,
        toggleSelection,
        toggleSelectAll,
        isSelected,
        someSelected,
        allSelected,
        clearSelection,
    };
};

export type TableSelection = ReturnType<typeof useTableSelection>;
