import { Tab, Tabs } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router";
import { t } from "../../i18n/util";
import { companiesStore } from "../../stores/CompaniesStore";
import { pushRoute } from "../app/router/history";
import { HrNavBar } from "./HrNavBar";
import { hrStore } from "./HrStore";
import { HrRoutes } from "./router/HrRoutes";

export const HrNavBarEmployees = observer(function HrNavBarEmployees() {
    const { pathname } = useLocation();
    const [page, setPage] = React.useState<string | null>("current");

    React.useEffect(() => {
        if (pathname === HrRoutes.EMPLOYEES.IN_PROGRESS) {
            setPage("in-progress");
        } else if (pathname === HrRoutes.EMPLOYEES.FORMER) {
            setPage("former");
        } else if (pathname === HrRoutes.EMPLOYEES.TICKETS) {
            setPage("preregistration-tickets");
        } else if (pathname === HrRoutes.EMPLOYEES.CURRENT) {
            setPage("current");
        }
    }, [pathname]);

    const handlePageChange = (_: React.ChangeEvent<unknown>, page: string | null) => {
        setPage(page);
    };

    const showPreRegistrationTicketListSite = () => {
        pushRoute(HrRoutes.EMPLOYEES.TICKETS);
    };

    const showEmployeesInProgessSite = () => {
        pushRoute(HrRoutes.EMPLOYEES.IN_PROGRESS);
    };

    const showEmployeesCurrentSite = () => {
        pushRoute(HrRoutes.EMPLOYEES.CURRENT);
    };

    const showEmployeesFormerSite = () => {
        pushRoute(HrRoutes.EMPLOYEES.FORMER);
    };

    const hasPreRegistration = companiesStore.selectedCompany?.preRegistrationEnabled;

    return (
        <HrNavBar
            showPeriods={false}
            useEmployeeSubsidiaries
            tabs={
                <Tabs
                    value={page}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handlePageChange}
                    variant="scrollable"
                    scrollButtons="on"
                >
                    {hasPreRegistration && hrStore.preRegistrationTicketCount && (
                        <Tab
                            label={t("screen.hr.navbar.tabbar.preRegistartionTickets", {
                                preRegistrationTicketCount: hrStore.preRegistrationTicketCount,
                            })}
                            value="preregistration-tickets"
                            onClick={showPreRegistrationTicketListSite}
                            data-id="hr_tab_bar_preregistartion_tickets"
                        />
                    )}

                    {hasPreRegistration && (
                        <Tab
                            label={t("screen.hr.navbar.tabbar.inprogress")}
                            value="in-progress"
                            onClick={showEmployeesInProgessSite}
                            data-id="hr_tab_bar_in_progress"
                        />
                    )}
                    <Tab
                        label={t("screen.hr.navbar.tabbar.current")}
                        value="current"
                        onClick={showEmployeesCurrentSite}
                        data-id="hr_tab_bar_current"
                    />
                    <Tab
                        label={t("screen.hr.navbar.tabbar.former")}
                        value="former"
                        onClick={showEmployeesFormerSite}
                        data-id="hr_tab_bar_former"
                    />
                </Tabs>
            }
        />
    );
});
