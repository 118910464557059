import * as React from "react";
import { t } from "../../../i18n/util";
import { Routes } from "../router/Routes";
import { MicrosoftUpgradeSite } from "../../shared/MicrosoftUpgradeSite";
import { NavBarBack } from "../../ui/NavBarBack";

export const UserUpgradeSite = () => {
    return (
        <>
            <NavBarBack
                title={t("userProfile.upgrade.navbar.heading")}
                backLabel={t("userProfile.navbar.heading")}
                backTarget={Routes.USER_PROFILE}
                showCancel={false}
            />
            <MicrosoftUpgradeSite />
        </>
    );
};
