import { Button } from "@material-ui/core";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Company } from "../../../network/APITypes";
import { isApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { Status } from "../../../stores/Status";
import { permissionsIsAdvisor } from "../../../util/permissionHelpers";
import { Routes } from "../../app/router/Routes";
import { pushRoute, withParams } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { useErrorDialog } from "../../hooks/useErrorDialog";
import { usePermissions } from "../../hooks/usePermissions";
import { CenteredContent } from "../../ui/CenteredContent";
import { ConfirmationDialog } from "../../ui/ConfirmationDialog";
import { ExpansionCard } from "../../ui/ExpansionCard";
import { NavBarBack } from "../../ui/NavBarBack";
import { RouterLinkButton } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { AdvisorRoutes } from "../router/AdvisorRoutes";

function calculateConfigStatus(company: Company) {
    const status = company.status;

    let companyConfigStatus: Status = "open";
    let userConfigStatus: Status = "open";

    if (status === "accountingSetupDone") {
        if (company.hrEnabled) {
            companyConfigStatus = "inProgress";
        } else {
            companyConfigStatus = "closed";
        }
    } else if (status === "hrSetupDone") {
        companyConfigStatus = "closed";
        userConfigStatus = "inProgress";
    } else if (status === "userSetupDone") {
        companyConfigStatus = "closed";
        userConfigStatus = "closed";
    }

    // We have not accounting and hr -> we are done
    if (!company.accountingEnabled && !company.hrEnabled) {
        companyConfigStatus = "closed";
    }

    return {
        companyConfigStatus,
        userConfigStatus,
    };
}

export const AdvisorConfigOverviewSite = () => {
    useHideSideBar();

    const { companyId } = useParams<{ companyId: string }>();
    const { company } = useCompany({ companyId });
    const { permissions } = usePermissions(companyId);
    const [activateCompanyOpen, setActivateCompanyOpen] = React.useState(false);
    const [activating, setActivating] = React.useState(false);

    const activationFailed = useErrorDialog({
        message: t("error.config.activateCompanyFailed"),
    });

    if (!companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    if (!permissions) {
        // Permissions for this company not loaded yet -> wait
        return null;
    } else if (!permissionsIsAdvisor(permissions)) {
        generalStore.setError(t("error.notAdvisor"));
        // Should not happen, but to make sure we check if not advisor for this company -> go back to overview
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    if (!company) {
        // Company not loaded yet -> wait
        return null;
    }

    const { companyConfigStatus, userConfigStatus } = calculateConfigStatus(company);

    const handleActivateCompany = async () => {
        try {
            setActivating(true);
            await API.putActivateCompany(companyId, { sendTermsOfUseEmail: true });
            setActivateCompanyOpen(true);
            setActivating(false);
        } catch (error) {
            setActivating(false);
            if (isApiError(error) && error.statusCode === HttpStatusCode.Conflict_409) {
                activationFailed.open();
            } else {
                generalStore.setError(t("error.general"), error);
            }
        }
    };

    const handleEndActivation = async (goToCockpit: boolean) => {
        if (goToCockpit) {
            // Select new company
            await coordinator.selectCompanyById(companyId);
            pushRoute(Routes.COCKPIT);
        } else {
            pushRoute(AdvisorRoutes.COMPANIES.INACTIVE);
        }
    };

    return (
        <>
            <NavBarBack
                title={t("config.overview")}
                backLabel={t("config.companyOverview.inactive")}
                backTarget={AdvisorRoutes.COMPANIES.INACTIVE}
                cancelComponent={
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={company.status !== "userSetupDone" || activating}
                        onClick={handleActivateCompany}
                    >
                        {t("config.finishConfiguration")}
                    </Button>
                }
                companyName={company.name}
            />
            <CenteredContent>
                <SiteContent>
                    <ExpansionCard
                        data-id="config-companies-card"
                        title={t("config.companies")}
                        expanded
                        status={companyConfigStatus}
                        iconName={companyConfigStatus === "closed" ? "finished" : "companyConfiguration"}
                    >
                        <p>{t("config.companies.description")}</p>
                        <div style={{ marginTop: 16, textAlign: "right" }}>
                            <RouterLinkButton to={withParams(AdvisorRoutes.CONFIG.COMPANY_DATA, { companyId })}>
                                {t("config.companies.buttonConfig")}
                            </RouterLinkButton>
                        </div>
                    </ExpansionCard>
                    <ExpansionCard
                        data-id="config-users-card"
                        title={t("config.users")}
                        expanded
                        status={userConfigStatus}
                        iconName="userConfiguration"
                    >
                        <p>{t("config.users.description")}</p>
                        <div style={{ marginTop: 16, textAlign: "right" }}>
                            <RouterLinkButton
                                disabled={company.status !== "userSetupDone" && companyConfigStatus !== "closed"}
                                to={withParams(AdvisorRoutes.CONFIG.USERS, { companyId })}
                            >
                                {t("config.users.buttonConfig")}
                            </RouterLinkButton>
                        </div>
                    </ExpansionCard>
                </SiteContent>
            </CenteredContent>
            <ConfirmationDialog
                open={activateCompanyOpen}
                title={t("config.finishConfiguration")}
                message={t("config.finishConfiguration.message", { company: company.name })}
                cancelLabel={t("config.finishConfiguration.cockpit")}
                onCancel={() => handleEndActivation(true)}
                confirmLabel={t("config.finishConfiguration.continue")}
                onConfirm={() => handleEndActivation(false)}
                // decision -> no send email checkbox
            />
            {activationFailed.dialog}
        </>
    );
};
