import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { getApiError } from "../../network/NetworkStapler";
import { generalStore } from "../../stores/GeneralStore";
import { Routes } from "../app/router/Routes";
import { pushRoute } from "../app/router/history";

// Check validity of invite token. If already used or no token -> go to login
export function useInviteTokenValid(inviteToken: string): boolean {
    // If no token -> no check
    const [tokenCheckComplete, setTokenCheckComplete] = React.useState(!inviteToken);

    React.useEffect(() => {
        const checkToken = async () => {
            try {
                await API.getInviteTokenValid(inviteToken);
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.response.type === "TOKEN_NOT_FOUND") {
                    // CONNECT-30: We assume the invite token was already accepted -> go to login
                    pushRoute(Routes.ROOT);
                } else if (apiError?.response.type === "TOKEN_EXPIRED") {
                    generalStore.setError(t("error.linkExpired"), error);
                } else {
                    generalStore.setError(t("error.general"), error);
                }
            } finally {
                setTokenCheckComplete(true);
            }
        };

        checkToken();
    }, [inviteToken]);

    return tokenCheckComplete;
}
