import { Button, Checkbox, IconButton, TableBody } from "@material-ui/core";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { CompanyDocumentScope, GeneralDocumentType, GetCompanyDocumentsResponseItem } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { ViewerFile, viewerStore } from "../../../stores/ViewerStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { DocumentModule, generalDocumentTypes } from "../../../types/models";
import { formatDate, getFormattedISODateOnlyRange } from "../../../util/date";
import { getFullName } from "../../../util/user";
import { accountingStore } from "../../accounting/AccountingStore";
import { useCompany } from "../../hooks/useCompany";
import { SignGeneralDocument, SignReport, SignReportConfig, useDocumentSign } from "../../hooks/useDocumentSign";
import { useCompanySubsidiaries } from "../../hooks/useSubsidiaries";
import { useTableFilters } from "../../hooks/useTableFilters";
import { hrStore } from "../../hr/HrStore";
import { BulkDownloadButton } from "../../ui/BulkActionButton";
import { CenteredContent } from "../../ui/CenteredContent";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../../ui/ContextMenu";
import { CustomDialog, CustomDialogContent } from "../../ui/CustomDialog";
import { EmptyState } from "../../ui/EmptyState";
import { NavBarBack } from "../../ui/NavBarBack";
import {
    TableLabel,
    TableRowButton,
    TpaIconInfoButton,
    TpaTable,
    TpaTableCell,
    TpaTableContainer,
    TpaTableRow,
} from "../../ui/Primitives";
import { ReportReleaseDetails } from "../../ui/ReportReleaseDetails";
import { ResponsiveButtonContainer } from "../../ui/ResponsiveButtonContainer";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { DATE_FILTER_TYPE, DROPDOWN_FILTER_TYPE, ITableFilterOption, ITableFilters } from "../../ui/filter/types";
import { FileIcon } from "../../util/FileIcon";
import { Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { customColors } from "../../util/Theme";

const moduleOptions = () => [
    {
        value: "all",
        label: t("common.all"),
    },
];

function createGeneralDocsFilterOptions() {
    const options: ITableFilterOption[] = generalDocumentTypes
        .filter(category => !!companiesStore.selectedCompanyStore?.canReadGeneralDocuments(category))
        .map(category => ({
            value: category,
            label: t(`upload.category.${category}`),
        }));

    options.unshift({
        value: "all",
        label: t("common.all"),
    });

    return options;
}

// This is a special case, because document ids are not unique. The same document can be uploaded
// in various contexts (as report, general document, etc.) and thus have the same id.
// So we need to create unique ids for each document by concatenating the reportId and generalDocumentId
function uniqueDocumentId(d: GetCompanyDocumentsResponseItem) {
    return `${d.id},${d.report?.id ?? ""},${d.generalDocumentId ?? ""}`;
}

interface DocumentUniqueId extends GetCompanyDocumentsResponseItem {
    uniqueId: string;
}

function addUniqueId(d: GetCompanyDocumentsResponseItem): DocumentUniqueId {
    return { ...d, uniqueId: uniqueDocumentId(d) };
}

export const DocsListSite = observer(function DocsListSite() {
    const tableStore = useTableStore<DocumentUniqueId>("DocsListSite", {
        orderBy: "uploadedAt",
        orderDir: "desc",
    });

    const documents = tableStore.items;
    const companyId = companiesStore.selectedCompanyId;

    const isMobile = React.useContext(MobileContext);
    const contextMenu = useContextMenu<DocumentUniqueId>();
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [hrSubsidiaryId, setHrSubsidiaryId] = React.useState("");
    const [accountingSubsidiaryId, setAccountingSubsidiaryId] = React.useState("");
    const [generalDocumentType, setGeneralDocumentType] = React.useState("");
    const [generalDocumentComment, setGeneralDocumentComment] = React.useState<string | null>(null);

    const { subsidiaries: accountingSubsidiaries } = useCompanySubsidiaries({
        companyId,
        module: "accounting",
        allSubsidiaries: true,
    });

    const accountingFilterOptions = accountingSubsidiaries.map(subsidiary => ({
        value: subsidiary.id,
        label: subsidiary.name,
    }));

    const { subsidiaries: hrSubsidiaries } = useCompanySubsidiaries({
        companyId,
        module: "hr",
        allSubsidiaries: true,
    });

    const hrFilterOptions = hrSubsidiaries.map(subsidiary => ({ value: subsidiary.id, label: subsidiary.name }));

    const generalDocumentsFilterOptions = createGeneralDocsFilterOptions();

    const { company } = useCompany({
        companyId,
        preloaded: undefined,
        includeReportsExist: true,
        onError: React.useCallback((err: unknown) => {
            const apiError = getApiError(err);
            if (apiError?.statusCode === HttpStatusCode.ServiceUnavailable_503) {
                generalStore.setError(t("error.loadGeneralDocumentsTimeout"));
                return true;
            }
            return false;
        }, []),
    });

    const tableFilters = useTableFilters({
        filters: [
            {
                category: "module",
                entries: compact([
                    companiesStore.selectedCompanyStore?.hasModule("accounting")
                        ? {
                              type: DROPDOWN_FILTER_TYPE,
                              name: "accounting",
                              label: t("common.accounting"),
                              options: moduleOptions().concat(
                                  company?.hasGlobalAccountingReports && accountingStore.canReadGlobalReports()
                                      ? [
                                            {
                                                value: "global",
                                                label: t("common.global"),
                                            },
                                            ...accountingFilterOptions,
                                        ]
                                      : accountingFilterOptions,
                              ),
                              onChange: (subsidiaryId: string) => {
                                  setAccountingSubsidiaryId(subsidiaryId);
                              },
                              disabled: (selected: ITableFilters) =>
                                  accountingSubsidiaries.length === 0 || !!selected.hr || !!selected.generalDocuments,
                          }
                        : undefined,
                    companiesStore.selectedCompanyStore?.hasModule("hr")
                        ? {
                              type: DROPDOWN_FILTER_TYPE,
                              name: "hr",
                              label: t("common.hr.short"),
                              options: moduleOptions().concat(
                                  company?.hasGlobalHRReports && hrStore.canReadGlobalReports()
                                      ? [
                                            {
                                                value: "global",
                                                label: t("common.global"),
                                            },
                                            ...hrFilterOptions,
                                        ]
                                      : hrFilterOptions,
                              ),
                              onChange: (subsidiaryId: string) => {
                                  setHrSubsidiaryId(subsidiaryId);
                              },
                              disabled: (selected: ITableFilters) =>
                                  hrSubsidiaries.length === 0 || !!selected.accounting || !!selected.generalDocuments,
                          }
                        : undefined,
                    generalDocumentsFilterOptions.length > 1 // hide if no general document categories allowed
                        ? {
                              type: DROPDOWN_FILTER_TYPE,
                              name: "generalDocuments",
                              label: t("common.generalDocuments"),
                              options: generalDocumentsFilterOptions,
                              onChange: (documentCategory: string) => {
                                  setGeneralDocumentType(documentCategory);
                              },
                              disabled: (selected: ITableFilters) => !!selected.accounting || !!selected.hr,
                          }
                        : undefined,

                    // Not needed in MVP
                    // { name: "projects", label: t("common.projects") },
                ]),
            },
            {
                category: "general",
                entries: [
                    {
                        name: "dateRange",
                        label: t("common.anyDateRange"),
                        type: DATE_FILTER_TYPE,
                        onChange: (date: Date) => {
                            const { startDate, endDate } = getFormattedISODateOnlyRange(date, "month");
                            setStartDate(startDate);
                            setEndDate(endDate);
                        },
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            tableStore.resetOffset();

            if (!selected.hr) {
                setHrSubsidiaryId("");
            }

            if (!selected.accounting) {
                setAccountingSubsidiaryId("");
            }

            if (!selected.generalDocuments) {
                setGeneralDocumentType("");
            }

            if (!selected.dateRange) {
                setStartDate("");
                setEndDate("");
            }
        },
    });

    const handleDownload = async (documents: DocumentUniqueId[]) => {
        if (!companyId) {
            return;
        }

        const { generalDocumentIds, reportIds } = getDownloadIds(documents);

        try {
            await API.putDownloadGeneralDocument(companyId, generalDocumentIds, reportIds);
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.statusCode === HttpStatusCode.Locked_423 &&
                apiError.response.type === "DOCUMENT_LOCKED_TRANSFER_IN_PROGRESS"
            ) {
                generalStore.setError(t("error.download.transferInProgress"));
            } else {
                generalStore.setError(t("error.download"), error);
            }
        }
    };
    const handleView = (document: DocumentUniqueId) => {
        if (!companyId) {
            return;
        }

        const files = documents.map<ViewerFile>(doc => {
            const { generalDocumentIds, reportIds } = getDownloadIds([doc]);
            return {
                id: doc.uniqueId,
                name: doc.name,
                src: () => {
                    return API.getGeneralDocumentDownloadUrl(companyId, generalDocumentIds, reportIds);
                },
                download: () => {
                    return API.putDownloadGeneralDocument(companyId, generalDocumentIds, reportIds);
                },
            };
        });
        viewerStore.open(files, document.uniqueId);
    };

    let contextMenuItems: ContextMenuItem[] = [];
    if (contextMenu.contextElement) {
        const document = contextMenu.contextElement;
        contextMenuItems = compact([
            {
                title: t("menu.view"),
                icon: "open",
                onClick: () => {
                    contextMenu.close();
                    handleView(document);
                },
                "data-id": "context_menu_view",
            },
            {
                disabled: tableStore.selectedItems.size > 0,
                title: t("menu.download"),
                icon: "download",
                onClick: () => {
                    if (document) {
                        contextMenu.close();
                        handleDownload([document]);
                    }
                },
                "data-id": "context_menu_download",
            },
            !!document.type && authStore.isTpa
                ? {
                      title: t("menu.delete"),
                      icon: "archive",
                      onClick: () => {
                          deleteDocument(document);
                          contextMenu.close();
                      },
                      disabled:
                          tableStore.selectedItems.size > 0 || !authStore.canDeleteGeneralDocuments(document.type),
                      "data-id": "context_menu_delete",
                  }
                : undefined,
        ]);
    }

    const deleteDocument = async (document: DocumentUniqueId) => {
        if (!companyId || !document.generalDocumentId) {
            return;
        }
        try {
            await API.deleteGeneralDocument(companyId, document.generalDocumentId);
            await reloadDocuments();
        } catch (error) {
            generalStore.setError(t("error.delete"), error);
        }
    };

    const { tableParams } = tableStore;
    const reloadDocuments = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;

            let module: DocumentModule | "all" = "all";
            let scope: CompanyDocumentScope = "all";
            let subsidiaryID = undefined;
            let generalDocumentTypes: GeneralDocumentType[] = [];

            if (accountingSubsidiaryId) {
                module = "accounting";

                if (accountingSubsidiaryId === "all" || accountingSubsidiaryId === "global") {
                    scope = accountingSubsidiaryId;
                } else {
                    scope = "subsidiary";
                    subsidiaryID = accountingSubsidiaryId;
                }
            } else if (hrSubsidiaryId) {
                module = "hr";

                if (hrSubsidiaryId === "all" || hrSubsidiaryId === "global") {
                    scope = hrSubsidiaryId;
                } else {
                    scope = "subsidiary";
                    subsidiaryID = hrSubsidiaryId;
                }
            } else if (generalDocumentType) {
                module = "generalDocuments";
                if (generalDocumentType !== "all") {
                    generalDocumentTypes = [generalDocumentType as GeneralDocumentType];
                }
            }

            const res = await API.getDocuments(companyId, {
                ...tableParams,
                module,
                scope,
                subsidiaryID,
                generalDocumentTypes,
                startDate: startDate || undefined,
                endDate: endDate || undefined,
            });

            tableStore.items = (res.documents ?? []).map(addUniqueId);
            tableStore.totalCount = res.total;
        } catch (err) {
            generalStore.setError(t("error.loadDocuments"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [
        accountingSubsidiaryId,
        companyId,
        endDate,
        generalDocumentType,
        hrSubsidiaryId,
        startDate,
        tableParams,
        tableStore,
    ]);

    const handleReportClickRelease = async (report: SignReport, config: SignReportConfig) => {
        const moduleStore = getModuleStore(config.module);
        await moduleStore.releaseReport({
            companyId: config.companyId,
            reportId: report.id,
            isGlobal: !config.subsidiaryId,
            periodId: config.periodId,
            subsidiaryId: config.subsidiaryId,
        });
        await reloadDocuments();
    };

    const handleGeneralDocumentClickRelease = async (document: SignGeneralDocument) => {
        if (!companyId) return;
        try {
            generalStore.isLoading = true;
            await API.releaseGeneralDocument(companyId, document.generalDocumentId);
            await reloadDocuments();
        } catch (e) {
            generalStore.setError(t("error.releaseReport"), e);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const sign = useDocumentSign({
        companyId,
        onReleaseReport: handleReportClickRelease,
        onReleaseGeneralDocument: handleGeneralDocumentClickRelease,
        onReload: reloadDocuments,
        isDocList: true,
    });

    const prepareGeneralDocumentRelease = (document: DocumentUniqueId) => {
        if (document.generalDocumentId) {
            sign.openQesDialog({
                signContext: "generalDocument",
                itemToRelease: {
                    documentId: document.id,
                    requiresQes: document.documentReleaseDetails?.requiresQes,
                    generalDocumentId: document.generalDocumentId,
                },
                mimeType: document.mimeType,
            });
        }
    };
    const prepareReportRelease = (document: DocumentUniqueId) => {
        if (document.report) {
            sign.openQesDialog({
                signContext: "report",
                itemToRelease: {
                    id: document.report.id,
                    requiresQes: document.documentReleaseDetails?.requiresQes,
                    document: { id: document.id },
                },
                reportConfig: {
                    companyId: companyId,
                    subsidiaryId: document.report.subsidiaryId,
                    module: document.report.moduleType,
                    global: !document.report.subsidiaryId,
                    periodId: document.report.periodId,
                },
                mimeType: document.mimeType,
            });
        }
    };

    React.useEffect(() => {
        reloadDocuments();
    }, [reloadDocuments]);

    const headerFields: ITableHeaderConfig[] = compact(
        isMobile
            ? [
                  { column: "uploadedAt", label: "table.label.createdAt" },
                  { column: "documentName", label: "table.label.documentName" },
                  { column: "contextMenu" },
              ]
            : [
                  { column: "uploadedAt", label: "table.label.createdAt" },
                  { column: "documentType", label: "table.label.documentType", sort: false },
                  { column: "documentName", label: "table.label.documentName" },
                  { column: "uploader", label: "table.label.uploadedBy" },
                  { column: "release", label: "table.label.release", sort: false },
                  {
                      column: "comment",
                      label: "table.label.info",
                      sort: false,
                      style: { width: "0%", textAlign: "center" },
                  },
                  { column: "contextMenu" },
              ],
    );

    const tableBody = (
        <TableBody>
            {documents.map((document, index) => {
                return (
                    <TpaTableRow key={document.uniqueId}>
                        {!isMobile && (
                            <TpaTableCell padding="checkbox">
                                <Checkbox
                                    data-id={`record_checkbox_${index}`}
                                    onChange={(event, checked) => {
                                        tableStore.toggleSelection(document);
                                    }}
                                    color="primary"
                                    checked={tableStore.isSelected(document)}
                                />
                            </TpaTableCell>
                        )}
                        {headerFields.map(({ column }, index) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = document[column as keyof DocumentUniqueId];
                            if (column === "uploadedAt") {
                                label = formatDate(document.uploadedAt);
                            } else if (column === "documentName") {
                                return (
                                    <TpaTableCell key={column}>
                                        <TableLabel style={{ maxWidth: 300 }}>{document.name}</TableLabel>
                                    </TpaTableCell>
                                );
                            } else if (column === "uploader") {
                                label = getFullName(document.uploader);
                            } else if (column === "documentType") {
                                return (
                                    <TpaTableCell key={column}>
                                        <FileIcon name={document.name} style={{ display: "block" }} />
                                    </TpaTableCell>
                                );
                            } else if (column === "comment") {
                                return (
                                    <TpaTableCell key={column}>
                                        {document.generalDocumentComment ? (
                                            <TpaIconInfoButton
                                                disableRipple
                                                style={{ color: customColors.primaryColor }}
                                                onClick={() => {
                                                    setGeneralDocumentComment(document.generalDocumentComment ?? "");
                                                }}
                                                size="small"
                                            >
                                                <Icon name="info" />
                                            </TpaIconInfoButton>
                                        ) : null}
                                    </TpaTableCell>
                                );
                            } else if (column === "contextMenu") {
                                return (
                                    <TpaTableCell key={column} style={{ textAlign: "right", width: 1 }}>
                                        <IconButton
                                            data-id={`context_menu_${index}`}
                                            style={{ padding: 0 }}
                                            onClick={event => {
                                                contextMenu.open(event, document);
                                            }}
                                        >
                                            <Icon name="more" />
                                        </IconButton>
                                    </TpaTableCell>
                                );
                            } else if (column === "release") {
                                const releaseDetails = document.documentReleaseDetails;
                                if (!releaseDetails?.needsRelease) {
                                    return <TpaTableCell key={column} />;
                                }

                                const { report } = document;
                                if (report) {
                                    const moduleStore = getModuleStore(report.moduleType);
                                    let canRelease = false;
                                    if (report.subsidiaryId) {
                                        canRelease = moduleStore.canReleaseReports(report.subsidiaryId);
                                    } else {
                                        canRelease = moduleStore.canReleaseGlobalReports();
                                    }

                                    return (
                                        <TpaTableCell key={column} style={{ maxWidth: 140 }}>
                                            <ReportReleaseDetails
                                                report={{
                                                    needsRelease: releaseDetails.needsRelease,
                                                    released: report.released,
                                                    releasers: releaseDetails.releasers,
                                                }}
                                                onRelease={
                                                    canRelease
                                                        ? () => {
                                                              prepareReportRelease(document);
                                                          }
                                                        : undefined
                                                }
                                            />
                                        </TpaTableCell>
                                    );
                                }

                                if (!releaseDetails.releasedAt && document.type) {
                                    if (!authStore.canReleaseGeneralDocuments(document.type)) {
                                        return (
                                            <TpaTableCell key={column} style={{ maxWidth: 140 }}>
                                                <p className="body2">{t("common.releaseRequired.singular")}</p>
                                            </TpaTableCell>
                                        );
                                    }
                                    return (
                                        <TpaTableCell key={column} style={{ maxWidth: 140 }}>
                                            <TableRowButton
                                                color="primary"
                                                onClick={() => {
                                                    prepareGeneralDocumentRelease(document);
                                                }}
                                            >
                                                {t("table.button.release")}
                                            </TableRowButton>
                                        </TpaTableCell>
                                    );
                                } else if (releaseDetails.releasedAt) {
                                    return (
                                        <TpaTableCell key={column} style={{ maxWidth: 140 }}>
                                            <p className="body2">
                                                {t("common.releasedAtBy", {
                                                    date: formatDate(releaseDetails.releasedAt),
                                                    name: getFullName(releaseDetails.releasedBy),
                                                })}
                                            </p>
                                        </TpaTableCell>
                                    );
                                }
                            }
                            return (
                                <TpaTableCell key={column}>
                                    <TableLabel>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    const empty = tableStore.getIsEmptyState(generalStore.isLoading, !isEmpty(tableFilters.activeFilters));

    return (
        <>
            <NavBarBack title={t("sidebar.list.documents")} />
            {empty && (
                <CenteredContent>
                    <EmptyState title={t("screen.hr.documents.emptystate.title")} />
                </CenteredContent>
            )}
            {!empty && (
                <>
                    <CenteredContent>
                        <SiteContent>
                            <TableSearchBarWithAction
                                label="search.caption.numDocuments"
                                labelSelected="search.caption.numSelected"
                                placeholder="search.placeholder.searchForFileName"
                                select={tableStore}
                                search={tableStore.search}
                                totalCount={tableStore.totalCount}
                                onChangeSearch={tableStore.handleSearchChange}
                                tableFilters={tableFilters}
                                bulkAction={
                                    <BulkDownloadButton
                                        onClick={() => handleDownload(tableStore.getAllSelectedItems())}
                                    />
                                }
                            />
                            <TpaTableContainer>
                                <TpaTable>
                                    <TableHeader
                                        headerFields={headerFields}
                                        tableStore={tableStore}
                                        select={tableStore}
                                    />
                                    {tableBody}
                                </TpaTable>
                            </TpaTableContainer>
                            <tableStore.Pagination />
                        </SiteContent>
                        {tableStore.getIsNoResultState(
                            generalStore.isLoading,
                            !isEmpty(tableFilters.activeFilters),
                        ) && <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />}
                    </CenteredContent>
                    <ContextMenu
                        data-id="context_menu"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        config={contextMenu}
                        items={contextMenuItems}
                    />
                    <CustomDialog
                        open={generalDocumentComment !== null}
                        onClose={() => {
                            setGeneralDocumentComment(null);
                        }}
                    >
                        <CustomDialogContent>
                            <h1>{t("table.label.info")}</h1>
                            <p style={{ marginTop: 16, whiteSpace: "pre-wrap" }}>{generalDocumentComment}</p>
                            <ResponsiveButtonContainer style={{ marginTop: 32 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setGeneralDocumentComment(null);
                                    }}
                                >
                                    {t("common.ok")}
                                </Button>
                            </ResponsiveButtonContainer>
                        </CustomDialogContent>
                    </CustomDialog>
                </>
            )}
            {sign.qesDialog}
            {sign.qesBlockedDialog}
        </>
    );
});

function getDownloadIds(documents: DocumentUniqueId[]) {
    const generalDocumentIds = compact(documents.map(d => d.generalDocumentId));

    // Some documents have a generalDocumentId AND a reportId -> filter generalDocuments, so we don't download twice
    const notGeneralDocs = documents.filter(d => !d.generalDocumentId);
    const reportIds = compact(notGeneralDocs.map(d => d.report?.id));
    return { generalDocumentIds, reportIds };
}
