import { Button } from "@material-ui/core";
import { saveAs } from "file-saver";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { EldaProtocol } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { IEmployee } from "../../types/models";
import { pushRoute } from "../app/router/history";
import { CustomDialog, CustomDialogContent } from "../ui/CustomDialog";
import { DocumentDownloadLine } from "../ui/DocumentLine/DocumentLine";
import { TpaWhiteButton } from "../ui/Primitives";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { HrRoutes } from "./router/HrRoutes";

const EldaProtocolScrollContainer = styled.div({
    margin: "16px 0",
    padding: 8,
    border: `2px solid ${customColors.greyLight}`,
    borderRadius: 4,
    maxHeight: 200,
    overflowY: "scroll",
    whiteSpace: "pre-wrap",
});

export function checkAdditionalData(employee: IEmployee) {
    return (
        employee.religiousConfession &&
        employee.maritalStatus &&
        employee.email &&
        employee.jobDescription &&
        employee.monthlySalary &&
        employee.iban &&
        employee.bic &&
        employee.workingHours &&
        employee.workingHoursInterval &&
        employee.weeklyWorkDays &&
        employee.costCenter &&
        employee.funder &&
        employee.wageAgreementClassification
    );
}

export const EldaTransferSuccessDialog = ({
    open,
    caption,
    onClose,
    eldaProtocols,
}: {
    open: boolean;
    caption: string;
    onClose: () => void;
    eldaProtocols?: EldaProtocol[];
}) => {
    const [protocolIndex, setProtocolIndex] = React.useState(0);
    if (!eldaProtocols || eldaProtocols.length === 0) {
        return null;
    }
    const protocol = eldaProtocols[protocolIndex];
    const filename = `ELDA_protokol_${protocol.number}.txt`;
    const hasNextProtocol = eldaProtocols.length > protocolIndex + 1;

    const handleDownloadProtocol = () => {
        const file = new Blob([protocol.protocol], { type: "text/plain" });
        saveAs(file, filename);
    };

    const handleNextProtocol = () => {
        if (hasNextProtocol) {
            setProtocolIndex(protocolIndex + 1);
        } else {
            onClose();
        }
    };

    return (
        <CustomDialog open={open} onClose={onClose}>
            <CustomDialogContent>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <h1 style={{ marginBottom: 32, hyphens: "auto" }}>{t("dialog.eldaTransfer.success.header")}</h1>
                        <p>{caption}</p>
                    </div>
                    <EldaProtocolScrollContainer>
                        <p>{protocol.protocol}</p>
                    </EldaProtocolScrollContainer>
                </div>
                <DocumentDownloadLine
                    style={{ padding: "32px 0" }}
                    textStyle={{ fontSize: 14, color: customColors.body1Dark }}
                    fileName={filename}
                    downloadOnClick
                    documentId={protocol.number}
                    onDownload={handleDownloadProtocol}
                />

                <ResponsiveButtonContainer>
                    <Button color="primary" variant="contained" onClick={handleNextProtocol}>
                        {hasNextProtocol ? t("common.continue") : t("common.ok")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
};

export const AddAdditionalDataDialog = (props: {
    open: boolean;
    onClose: () => void;
    onTransferEmployee: () => void;
    employee: IEmployee;
}) => {
    return (
        <CustomDialog onClose={props.onClose} open={props.open}>
            <CustomDialogContent>
                <h1 style={{ marginBottom: 24 }}>{t("screen.hr.summary.additionalinformation")}</h1>
                <p style={{ marginBottom: 24 }}>
                    {t("screen.hr.summary.dialogQuestion1")}
                    <b>
                        {t("screen.hr.summary.name", {
                            firstName: props.employee.firstName,
                            lastName: props.employee.lastName,
                        })}
                    </b>
                    {t("screen.hr.summary.dialogQuestion2")}
                </p>
                <div style={{ display: "flex" }}>
                    <div style={{ margin: "6px 12px 0 0", height: 24, width: 24 }}>
                        <Icon name="checkmark" style={{ color: customColors.blueFinished }} />
                    </div>

                    <p style={{ marginTop: 8 }}>{t("screen.hr.summary.completePersonalData")}</p>
                </div>
                <div style={{ display: "flex", marginBottom: 42 }}>
                    <div style={{ margin: "9px 16px 0 3px", height: 17, width: 17 }}>
                        <Icon name="info" style={{ color: customColors.blueFinished }} />
                    </div>

                    <p style={{ marginTop: 8 }}>{t("screen.hr.summary.incompleteAdditionalData")}</p>
                </div>
                <ResponsiveButtonContainer>
                    <TpaWhiteButton onClick={props.onClose} data-id="button_add_additional_data_change_data">
                        {t("screen.hr.summary.changeData")}
                    </TpaWhiteButton>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onTransferEmployee}
                        data-id="button_add_additional_data_transfer"
                    >
                        {t("screen.hr.summary.openDialog")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
};

export const CancelRegistrationDialog = (props: {
    open: boolean;
    onClose: () => void;
    onTransferEmployee: () => void;
    employee: IEmployee;
    isPreRegistration?: boolean;
    isReRegistration?: boolean;
}) => {
    return (
        <CustomDialog onClose={props.onClose} open={props.open}>
            <CustomDialogContent>
                <h1 style={{ marginBottom: 24 }}>{t("screen.hr.cancelRegistration.header")}</h1>
                <p style={{ marginBottom: 24 }}>
                    {t("screen.hr.cancelRegistration.question1")}
                    <b>
                        {t("screen.hr.summary.name", {
                            firstName: props.employee.firstName,
                            lastName: props.employee.lastName,
                        })}
                    </b>
                    {t("screen.hr.cancelRegistration.question2")}
                </p>
                <div style={{ display: "flex" }}>
                    <div style={{ margin: "6px 12px 0 0", height: 24, width: 24 }}>
                        <Icon name="checkmark" style={{ color: customColors.blueFinished }} />
                    </div>

                    <p style={{ marginTop: 8 }}>{t("screen.hr.summary.completePersonalData")}</p>
                </div>
                <div style={{ display: "flex", marginBottom: 24 }}>
                    {checkAdditionalData(props.employee) ? (
                        <div style={{ display: "flex" }}>
                            <div style={{ margin: "6px 12px 0 0", height: 24, width: 24 }}>
                                <Icon name="checkmark" style={{ color: customColors.blueFinished }} />
                            </div>

                            <p style={{ marginTop: 8 }}>{t("screen.hr.summary.completeAdditionalData")}</p>
                        </div>
                    ) : (
                        <div style={{ display: "flex" }}>
                            <div style={{ margin: "9px 16px 0 3px", height: 17, width: 17 }}>
                                <Icon name="info" style={{ color: customColors.blueFinished }} />
                            </div>

                            <p style={{ marginTop: 8 }}>{t("screen.hr.summary.incompleteAdditionalData")}</p>
                        </div>
                    )}
                </div>
                <p style={{ marginBottom: 42 }}>{t("screen.hr.cancelRegistration.dataSaved")}</p>

                <ResponsiveButtonContainer>
                    <TpaWhiteButton
                        onClick={() => {
                            if (props.isPreRegistration) {
                                pushRoute(HrRoutes.EMPLOYEES.IN_PROGRESS);
                            } else if (props.isReRegistration) {
                                pushRoute(HrRoutes.EMPLOYEES.FORMER);
                            } else {
                                pushRoute(HrRoutes.EMPLOYEES.CURRENT);
                            }
                        }}
                        data-id="button_cancel_registration_discard_data"
                    >
                        {t("common.cancel")}
                    </TpaWhiteButton>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onTransferEmployee}
                        data-id="button_cancel_registration_transfer"
                        disabled={generalStore.isLoading}
                    >
                        {t("screen.hr.summary.openDialog")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
};
