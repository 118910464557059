import {
    Button,
    FormControlLabel,
    IconButton,
    MenuItem,
    PropTypes,
    Radio,
    Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableSortLabel,
    Tooltip,
    withStyles,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { LocationDescriptor } from "history";
import * as React from "react";
import { FormattedNumber } from "react-intl";
import LineEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Ticket } from "../../network/APITypes";
import { sanitizeHtml } from "../../util/helpers";
import { useResize } from "../hooks/useResize";
import { IIconNames, Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { BOX_SHADOW_LIGHT, DIALOG_WIDTH, customColors } from "../util/Theme";

const ResponsiveLineEllipsis = responsiveHOC<React.ComponentProps<typeof LineEllipsis>>(0)(LineEllipsis);

// Center content vertically (works within a parent that takes up full height and has column flex)
export const VCenter = (props: { children: React.ReactNode; style?: React.CSSProperties }) => (
    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "center", ...props.style }}>
        {props.children}
    </div>
);

/** A wrapper to horizontally and vertically center the children (works best if only one child is provided) */
export const Centered = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const TpaToggleButton = withStyles({
    root: {
        padding: "0 24px",
        color: customColors.primaryColor,
        textAlign: "center",
        height: 40,
        textTransform: "none",
        fontWeight: "normal",
        border: "none",
        lineHeight: "normal",

        // First remove padding
        "@media (max-width:600px)": {
            padding: 0,
        },

        // Then add hyphenation
        "@media (max-width:400px)": {
            hyphens: "auto",
        },

        "&.Mui-selected": {
            backgroundColor: customColors.primaryShade,
            color: customColors.primaryColor,
            fontWeight: "bold",
        },
    },
})(ToggleButton);

export const TpaToggleButtonGroup = withStyles({
    root: {
        border: `1px solid ${customColors.primaryColor}`,
        height: 42,
    },
})(ToggleButtonGroup);

export const UserProfileFormControlLabel = withStyles({
    root: {
        marginTop: 25,
    },
})(FormControlLabel);

export const TpaMenuItem = withStyles({
    root: {
        paddingBottom: 8,
        paddingTop: 8,
        paddingLeft: 16,
        paddingRight: 16,
        lineHeight: "24px",
        minHeight: 0, // because of mui mobile
    },
})(MenuItem);

export const TpaWhiteButton = withStyles({
    root: {
        backgroundColor: customColors.white,
        color: customColors.primaryColor,
        border: `2px solid ${customColors.primaryColor}`,
        padding: "12px 16px",
    },
})(Button);

export const TableRowButton = withStyles({ root: { padding: "4px 16px" } })(Button);

export const TpaTableSortLabel = withStyles({
    root: {
        "&.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
            color: customColors.primaryColor,
        },
    },
})(TableSortLabel);

export const TpaTableCell = withStyles({
    root: {
        borderBottom: "none",
        paddingTop: 0,
        paddingBottom: 0,
        "&:first-child:not(.MuiTableCell-paddingCheckbox)": {
            paddingLeft: 16,
        },
        "&:last-child:not(.MuiTableCell-paddingCheckbox)": {
            paddingRight: 16,
        },
        "&:first-of-type": {
            borderRadius: "4px 0 0 4px",
        },
        "&:last-of-type": {
            borderRadius: "0 4px 4px 0",
        },
    },
})(TableCell);

export const TpaTableCellMinWidth = withStyles({
    root: {
        width: 1 /* width 1 limits to content */,
    },
})(TpaTableCell);

export const TpaTableCellMinWidthNoWrap = withStyles({
    root: {
        width: 1 /* width 1 limits to content */,
        whiteSpace: "nowrap",
    },
})(TpaTableCell);

const StyledTableRow = withStyles({
    root: {
        boxShadow: BOX_SHADOW_LIGHT,
        borderRadius: 4,
        backgroundColor: "white",
        height: 56,
    },
})(TableRow);

export const StyledSelect = withStyles({
    root: {
        "&.MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },
    },
    select: {
        padding: "6px 0 5px", // center the text to align it with other elements next to it
    },
})(Select);

export const TpaTableRow = React.forwardRef<HTMLTableRowElement, React.ComponentProps<typeof StyledTableRow>>(
    function TpaTableRow({ children, ...rowProps }, ref) {
        return (
            <>
                <StyledTableRow ref={ref} {...rowProps} style={{ cursor: rowProps.onClick ? "pointer" : undefined }}>
                    {children}
                </StyledTableRow>
                <TableRow style={{ height: 8 }} />
            </>
        );
    },
);

const StyledTableBody = withStyles({
    root: {
        boxShadow: BOX_SHADOW_LIGHT,
        borderRadius: 4,
        backgroundColor: "white",
    },
})(TableBody);

export const TpaExpandedTableRowContainer = React.forwardRef<
    HTMLTableSectionElement,
    React.ComponentProps<typeof TableBody>
>(function TpaExpandedTableRowContainer(props, ref) {
    return (
        <>
            <StyledTableBody {...props} ref={ref} />
            <TableBody>
                <TableRow style={{ height: 8 }} />
            </TableBody>
        </>
    );
});

export const TpaTable = withStyles({
    root: {
        borderCollapse: "separate",
        borderSpacing: "0 0",
    },
})(Table);

export const TpaTableContainer = ({ style, children }: { style?: React.CSSProperties; children: React.ReactNode }) => {
    const isMobile = React.useContext(MobileContext);
    return <div style={{ ...style, overflow: isMobile ? "auto" : "visible" }}>{children}</div>;
};

export const TpaTableFilterButton = withStyles({
    root: {
        textTransform: "none",
        fontWeight: "normal",
        padding: "0px 8px",
        height: 28,
        minWidth: 0,
        fontSize: 10,
        lineHeight: "14px",
    },
})(Button);

export const TpaTableDatePicker = withStyles({
    root: {
        content: "normal",
    },
})(Button);

export const TpaIconInfoButton = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
})(IconButton);

export const InfoButton = (props: {
    title: React.ReactNode;
    body?: React.ReactNode;
    style?: React.CSSProperties;
    color?: "primary" | "inherit";
}) => {
    return (
        <Tooltip
            style={{
                backgroundColor: "#ffffff",
            }}
            title={
                <React.Fragment>
                    {props.title}
                    {props.body && (
                        <>
                            <br />
                            <br />
                            {props.body}
                        </>
                    )}
                </React.Fragment>
            }
            placement="right-start"
        >
            <TpaIconInfoButton disableRipple style={props.style} color={props.color}>
                <Icon name="info" />
            </TpaIconInfoButton>
        </Tooltip>
    );
};

export const OptionalTooltip = ({
    title,
    children,
    className,
}: {
    title?: React.ReactNode;
    children: React.ReactElement;
    className?: string;
}) =>
    title ? (
        <Tooltip PopperProps={{ style: { whiteSpace: "pre-line" } }} title={<div className={className}>{title}</div>}>
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );

/** ⚠️ This component is dangerous because it allows any HTML content to be rendered. */
export const DangerousOptionalTooltip = ({
    title,
    children,
    className,
}: {
    title?: string;
    children: React.ReactElement;
    className?: string;
}) =>
    title ? (
        <Tooltip
            PopperProps={{ style: { whiteSpace: "pre-line" } }}
            title={<div className={className} dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }} />}
        >
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );

/** A tooltip where the content is the same font size and weight as the default body text */
export const TooltipBiggerFont = withStyles({
    tooltip: {
        fontSize: "inherit",
        fontWeight: "inherit",
    },
})(Tooltip);

const TicketBadge = styled.div<{ isOpen: boolean }>`
    position: absolute;
    right: -2px;
    top: -2px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: ${props => (props.isOpen ? customColors.orangeOpen : customColors.greyDarkIcons)};
`;

export const TicketButton = ({
    ticket,
    onClick,
    style,
}: {
    ticket?: Ticket;
    onClick: () => void;
    style?: React.CSSProperties;
}) => {
    const ticketOpen = ticket?.status === "open";
    return (
        <div
            style={{
                cursor: "pointer",
                position: "relative",
                display: "inline-block",
                width: 24,
                height: 24,
                ...style,
            }}
            role="button"
            onClick={onClick}
        >
            <Icon
                name="rueckfrage"
                style={{
                    color: ticketOpen ? customColors.orangeOpen : customColors.greyDarkIcons,
                    display: "block",
                }}
            />
            {ticket && <TicketBadge isOpen={ticketOpen} />}
        </div>
    );
};

export const Subtitle = styled.h4`
    margin-bottom: 16px;
`;

export const SectionContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
`;

export const OverviewContainer = styled.div<{ fullWidth?: boolean; noBackground?: boolean }>`
    background-color: ${props => (props.noBackground ? undefined : customColors.white)};
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 32px;
    max-width: ${props => (props.fullWidth ? undefined : "656px")};
    margin-bottom: 16px;
`;

export const RouterLink = (props: {
    to: LocationDescriptor;
    children: React.ReactNode;
    style?: React.CSSProperties;
}) => (
    <Link to={props.to} style={{ textDecoration: "none", ...props.style }}>
        {props.children}
    </Link>
);

export const Dotted = styled.span`
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-style: dotted;
`;
export const DottedLink = styled(Link)`
    color: inherit;
    text-decoration-style: dotted;
`;

export const RouterLinkButton = (props: {
    to: LocationDescriptor;
    variant?: "text" | "outlined" | "contained";
    color?: PropTypes.Color;
    children: React.ReactNode;
    disabled?: boolean;
    style?: React.CSSProperties;
}) => {
    const children = (
        <Button disabled={props.disabled} color={props.color ?? "primary"} variant={props.variant ?? "contained"}>
            {props.children}
        </Button>
    );

    if (props.disabled) {
        return <span style={props.style}>{children}</span>;
    }

    return (
        <RouterLink to={props.to} style={props.style}>
            {children}
        </RouterLink>
    );
};

export const Ellipsis = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const multiLineEllipsisStyle: React.CSSProperties = { overflowWrap: "break-word", hyphens: "auto" };

export const MultiLineEllipsis = ({
    text,
    maxLine,
    basedOn,
    style,
}: {
    text: string;
    maxLine: number;
    basedOn?: "letters" | "words";
    style?: React.CSSProperties;
}) => {
    const [clamped, setClamped] = React.useState(false);
    return (
        <Tooltip title={clamped ? text : ""}>
            <ResponsiveLineEllipsis
                text={text}
                maxLine={maxLine}
                basedOn={basedOn ?? "letters"}
                onReflow={({ clamped }) => {
                    setClamped(clamped);
                }}
                // NOTE: ideally we would add `hyphens: auto` to the style to let the browser add hyphens where appropriate.
                //       this is not possible because react-lines-ellipsis adds a `<wbr>` tag at the end which breaks `hyphens: auto`...
                style={style ? { ...multiLineEllipsisStyle, ...style } : multiLineEllipsisStyle}
            />
        </Tooltip>
    );
};

export const SecondaryText = styled.span`
    font-size: 10px;
`;

export const TableLabelSmall = styled(Ellipsis)`
    max-width: 200px;
    font-size: 10px;
    color: var(--body2-dark);
`;

export const TableLabel = ({
    children,
    style,
    tooltip,
    className,
}: {
    style?: React.CSSProperties;
    className?: string;
    children: React.ReactNode;
    tooltip?: React.ReactNode;
}) => {
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const ref = React.useRef<HTMLDivElement>(null);
    const dimensions = useResize(ref);

    React.useEffect(() => {
        if (ref.current) {
            setIsOverflowing(ref.current.offsetWidth < ref.current.scrollWidth);
        }
    }, [children, dimensions]);

    const tooltipMessage = tooltip ?? children;

    return (
        <OptionalTooltip title={isOverflowing || tooltip ? tooltipMessage : undefined} className={className}>
            <Ellipsis
                style={{
                    maxWidth: 150,
                    ...style,
                }}
                ref={ref}
                className={className}
            >
                {children}
            </Ellipsis>
        </OptionalTooltip>
    );
};

/** ⚠️ This component is dangerous because it allows any HTML content to be rendered. */
export const DangerousTableLabel = ({
    children,
    style,
    tooltip,
    className,
}: {
    style?: React.CSSProperties;
    className?: string;
    children: string;
    tooltip?: string;
}) => {
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const ref = React.useRef<HTMLDivElement>(null);
    const dimensions = useResize(ref);

    React.useEffect(() => {
        if (ref.current) {
            setIsOverflowing(ref.current.offsetWidth < ref.current.scrollWidth);
        }
    }, [children, dimensions]);

    const tooltipMessage = tooltip ?? children;

    return (
        <DangerousOptionalTooltip title={isOverflowing || tooltip ? tooltipMessage : undefined} className={className}>
            <Ellipsis
                style={{
                    maxWidth: 150,
                    ...style,
                }}
                ref={ref}
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(children) }}
                className={className}
            />
        </DangerousOptionalTooltip>
    );
};

export const H4WithIcon = (props: { icon: IIconNames; children: React.ReactNode; style?: React.CSSProperties }) => (
    <div style={{ display: "flex", alignItems: "center", ...props.style }}>
        <Icon name={props.icon} style={{ color: customColors.greyDarkIcons }} />
        <h4 style={{ marginLeft: 16 }}>{props.children}</h4>
    </div>
);

interface IconToggleButtonProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    checkedIcon: React.ReactNode;
    uncheckedICon: React.ReactNode;
    checkedTitle: React.ReactNode;
    uncheckedTitle: React.ReactNode;
    style?: React.CSSProperties;
    size?: React.ComponentProps<typeof IconButton>["size"];
}

/**
 * A toggle button that switches between two icons and titles depending on the `checked` value.
 */
export const IconToggleButton = (props: IconToggleButtonProps) => {
    const { checked } = props;
    return (
        <Tooltip title={<>{checked ? props.checkedTitle : props.uncheckedTitle}</>}>
            <IconButton
                onClick={() => {
                    props.onChange(!checked);
                }}
                size={props.size}
                style={props.style}
            >
                {checked ? props.checkedIcon : props.uncheckedICon}
            </IconButton>
        </Tooltip>
    );
};

export const TpaRadio = (props: {
    value: string;
    title: string;
    subtitle?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
}) => {
    const style: React.CSSProperties = { color: props.disabled ? customColors.disabled : undefined };
    return (
        <FormControlLabel
            value={props.value}
            control={<Radio color="primary" disabled={props.disabled} />}
            label={
                <div>
                    <p style={style}>{props.title}</p>
                    {props.subtitle && (
                        <p className="caption" style={style}>
                            {props.subtitle}
                        </p>
                    )}
                </div>
            }
            style={props.style}
        />
    );
};

export const LinkAction = styled.div`
    margin-right: 8px;
    margin-left: 17px;
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-decoration-line: underline;
    cursor: pointer;
    min-width: 47px;
    color: #444756;
`;

export const CenteredForm = (props: { children: React.ReactNode }) => (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 32 }}>
        <div style={{ width: "100%", maxWidth: DIALOG_WIDTH, display: "flex", flexDirection: "column" }}>
            {props.children}
        </div>
    </div>
);

export const TpaBadge = styled.div`
    display: inline-block;
    min-width: 16px;
    height: 16px;
    border-radius: 8px;
    font-size: 10px;
    padding: 3px;
    padding-left: 3px;
    text-align: center;
    line-height: 11px;
    font-weight: bold;
    color: ${customColors.white};
    background-color: ${customColors.orangeOpen};
`;

/**
 * A small wrapper around FormattedNumber that ensures that -0 is displayed as 0.
 */
export const FormattedCurrency = (props: React.ComponentProps<typeof FormattedNumber>) => {
    const value = Object.is(props.value, -0) ? 0 : props.value;
    return <FormattedNumber style="currency" currency="EUR" {...props} value={value} />;
};
