import * as React from "react";
import { NAVBAR_HEIGHT, TABBAR_HEIGHT } from "../../config";
import { SIDEBAR_WIDTH_OPEN } from "../../stores/SideBarStore";
import { CONTENT_PADDING_HORIZONTAL, customColors } from "../util/Theme";
import { CenteredContent } from "./CenteredContent";

interface IProps {
    children: React.ReactNode;
}

export const TabBarFrame = (props: { children: React.ReactNode; style?: React.CSSProperties }) => (
    <>
        <div
            className="mui-fixed"
            style={{
                width: "100%",
                background: customColors.white,
                borderBottom: `1px solid ${customColors.greyLight}`,
                position: "fixed",
                display: "flex",
                height: TABBAR_HEIGHT,
                alignItems: "center",
                zIndex: 100,
                marginTop: NAVBAR_HEIGHT,
                ...props.style,
            }}
        >
            {props.children}
        </div>
        <div style={{ height: TABBAR_HEIGHT }} />
    </>
);

export const TabBarContainer = (props: IProps) => {
    const [leftWidth, setLeftWidth] = React.useState(SIDEBAR_WIDTH_OPEN);
    const resize = (width: number) => {
        setLeftWidth(width);
    };

    return (
        <TabBarFrame>
            {/* CenteredContent is only used for measuring */}
            <CenteredContent getLeftWidth={resize} />

            <div style={{ position: "absolute", left: leftWidth, width: `calc(100vw - ${leftWidth}px` }}>
                <div style={{ padding: `0 ${CONTENT_PADDING_HORIZONTAL}px`, height: TABBAR_HEIGHT }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </TabBarFrame>
    );
};
