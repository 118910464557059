import { Decimal } from "decimal.js";

export type CompareFn<Item> = (item1: Item, item2: Item) => number;
export type Comparer<Item> = { [key in keyof Item]?: CompareFn<Item> };

export function sort<Item extends object>(
    items: Item[],
    orderBy: keyof Item,
    orderDir: "asc" | "desc",
    comparer?: Comparer<Item>,
): Item[] {
    const modifier = orderDir === "asc" ? 1 : -1;

    return items.slice().sort((item1, item2) => {
        const fn = comparer?.[orderBy];
        if (fn) {
            return fn(item1, item2) * modifier;
        }

        const v1: unknown = item1[orderBy];
        const v2: unknown = item2[orderBy];
        return compare(v1, v2) * modifier;
    });
}

export const compare: CompareFn<unknown> = (v1, v2) => {
    if (typeof v1 === "string" && typeof v2 === "string") {
        return v1.localeCompare(v2);
    }
    if (typeof v1 === "number" && typeof v2 === "number") {
        return v1 - v2;
    }
    if (v1 instanceof Date && v2 instanceof Date) {
        return v1.getTime() - v2.getTime();
    }
    if (v1 instanceof Decimal && v2 instanceof Decimal) {
        return v1.comparedTo(v2);
    }
    return 0;
};
