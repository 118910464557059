import { useCallback, useEffect, useState } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { authStore } from "../../stores/AuthStore";
import { CompanyStore } from "../../stores/CompanyStore";
import { generalStore } from "../../stores/GeneralStore";
import { parsePermissions } from "../../util/permissionParser";

export function useCompanyStore({
    companyId,
    includeReportsExist,
}: {
    companyId?: string;
    includeReportsExist?: boolean;
}) {
    const [companyStore, setCompanyStore] = useState<CompanyStore | null>();

    const loadCompany = useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            return await API.getCompany(companyId, false, includeReportsExist);
        } catch (err) {
            generalStore.setError(t("error.loadCompany"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, includeReportsExist]);

    const loadPermissions = useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            return await API.getCurrentUserPermissions(companyId);
        } catch (err) {
            generalStore.setError(t("error.loadPermissions"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId]);

    const loadFeatureFlags = useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            return await API.getCompanyFeatureFlags(companyId);
        } catch (err) {
            generalStore.setError(t("error.loadFeatureFlags"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId]);

    useEffect(() => {
        Promise.all([loadCompany(), loadPermissions(), loadFeatureFlags()]).then(
            ([company, permissions, featureFlags]) => {
                if (!company || !permissions || !featureFlags) {
                    return;
                }

                const store = new CompanyStore(
                    company,
                    parsePermissions(permissions, authStore.userInfo?.gender),
                    featureFlags.featureFlags,
                );
                setCompanyStore(store);
            },
        );
    }, [loadCompany, loadFeatureFlags, loadPermissions]);

    useEffect(() => {
        return () => {
            if (companyStore) {
                companyStore.dispose();
            }
        };
    }, [companyStore]);

    return { companyStore };
}
