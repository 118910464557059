import { PrimitiveType } from "intl-messageformat";
import moment from "moment";
import { createIntl, createIntlCache } from "react-intl";
import { Gender, TPAJobTitle } from "../network/APITypes";
import { debugStore } from "../stores/DebugStore";
import { Equals } from "../util/test";
import { Flatten } from "../util/ts";
import { ILocales } from "./ILocales";

import { bulgarian } from "./bg";
import { czech } from "./cs";
import { german } from "./de";
import { english } from "./en";
import { croatian } from "./hr";
import { hungarian } from "./hu";
import { polish } from "./pl";
import { romanian } from "./ro";
import { russian } from "./ru";
import { slovak } from "./sk";
import { slovene } from "./sl";
import { albanian } from "./sq";
import { serbian } from "./sr";

import "moment/dist/locale/bg";
import "moment/dist/locale/cs";
import "moment/dist/locale/de";
// import "moment/dist/locale/en"; // loaded by default in moment
import "moment/dist/locale/hr";
import "moment/dist/locale/hu";
import "moment/dist/locale/pl";
import "moment/dist/locale/ro";
import "moment/dist/locale/ru";
import "moment/dist/locale/sk";
import "moment/dist/locale/sl";
import "moment/dist/locale/sq";
import "moment/dist/locale/sr";

moment.updateLocale("de", {
    months: [
        "Jänner",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ],
});

const intlMessages = {
    bg: bulgarian,
    cs: czech,
    en: english,
    de: german,
    hr: croatian,
    hu: hungarian,
    pl: polish,
    ro: romanian,
    ru: russian,
    sk: slovak,
    sl: slovene,
    sr: serbian,
    sq: albanian,
};

export function localeToStringKey(locale: ILocales): IMessageIDS {
    const mapping: Record<string, IMessageIDS> = {
        bg: "common.bulgarian",
        cs: "common.czech",
        en: "common.english",
        de: "common.german",
        hr: "common.croatian",
        hu: "common.hungarian",
        pl: "common.polish",
        ro: "common.romanian",
        ru: "common.russian",
        sk: "common.slovak",
        sl: "common.slovene",
        sr: "common.serbian",
        sq: "common.albanian",
    };

    return mapping[locale];
}

const cache = createIntlCache();

const _createIntl = (locale: ILocales) => {
    return createIntl(
        {
            locale,
            messages: intlMessages[locale as keyof typeof intlMessages],
        },
        cache,
    );
};

/** Contains instances and properties that relate to the current locale */
export const i18n = {
    intl: _createIntl("de"),
    decimalSeparator: ",",
}

export type IMessageIDS = keyof typeof german;
export type TranslationForID<ID extends IMessageIDS> = (typeof german)[ID];

export const setLocale = (locale: ILocales) => {
    i18n.intl = _createIntl(locale);

    let decimalSeparator;
    const format = new Intl.NumberFormat(locale);
    if (format.formatToParts) {
        // Idea taken from here: https://observablehq.com/@mbostock/localized-number-parsing
        const parts = format.formatToParts(12345.6);
        decimalSeparator = parts.find((d) => d.type === "decimal")?.value;
    }

    if (!decimalSeparator) {
        // Fallback from https://stackoverflow.com/a/51411310/677910
        const numberWithDecimalSeparator = 1.1;
        decimalSeparator = numberWithDecimalSeparator.toLocaleString(locale).substring(1, 2);
    }

    i18n.decimalSeparator = decimalSeparator

    moment.locale(locale);

    console.log(`%cSet locale to "${locale}".`, "background: #eee; color: #666;");
};

function returnString(messageId: string, translation: string) {
    const showStringKeys = debugStore.showStringKeys;
    return showStringKeys ? `${messageId} (${translation})` : translation;
}

/**
 * Returns a dictionary where the keys match the placeholders (look like `{name}`) inside the text `T`
 * and the values are of type `PrimitiveType`.
 */
type TextToPlaceholders<T extends string, Remainder = never> = T extends `${string}{${infer Placeholder}}${infer Rest}`
    ? Record<Placeholder, PrimitiveType> & TextToPlaceholders<Rest, Record<never, string>>
    : Remainder;
/**
 * Returns a dictionary where the keys match the placeholders (look like `{name}`) inside the message `ID`
 * and the values are of type `PrimitiveType`.
 * If `ID` matches `IMessageIDS` (i.e. no specific text) then `never` is returned (which is handled in function `t`)
 */
type IDToPlaceholders<ID extends IMessageIDS> = Equals<ID, IMessageIDS> extends true
    ? never // It matches *all* message IDs... no chance to infer the placeholders
    : Flatten<TextToPlaceholders<(typeof german)[ID]>> // continue parsing the text

/**
 * Returns the translation for the given `messageId` using an optional dictionary to replace placeholders in the text.
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters -- _ is used to show the german translation on hover
export function t<ID extends IMessageIDS, P extends IDToPlaceholders<ID>, _ = TranslationForID<ID>>(
    ...parameters: [P] extends [never] ? [messageId: ID, values?: Record<string, PrimitiveType>] : [messageId: ID, values: P]
) {
    const [messageId, values] = parameters;
    return returnString(messageId, i18n.intl.formatMessage({ id: messageId }, values));
}

export function translateOrDefault(messageId: string, values: Record<string, PrimitiveType> | undefined, defaultMessage: string): string {
    if (messageId in i18n.intl.messages) {
        return i18n.intl.formatMessage({ id: messageId }, values);
    }
    return defaultMessage;
}

export function getJobTitle(tpaJobTitle: TPAJobTitle[] | undefined, gender: Gender): string | undefined {
    if (!tpaJobTitle) {
        return;
    }

    return tpaJobTitle
        .map(title => {
            let ret = "";
            switch (title) {
                case "partner":
                    ret = t(gender === "male" ? "role.tpaPartner" : "role.tpaPartner.female");
                    break;
                case "annual-accountant":
                    ret = t("role.tpaAnnualAccounts");
                    break;
                case "tax-accountant":
                    ret = t("role.tpaTaxAccountant");
                    break;
                case "special-advisor":
                    ret = t("role.tpaSpecialAdvisor");
                    break;
                case "management-consultant":
                    ret = t("role.tpaBusinessConsultant");
                    break;
                case "reporting-specialist":
                    ret = t("role.tpaReporting");
                    break;
                case "accountant":
                    ret = t("role.tpaAccounting");
                    break;
                case "auditor":
                    ret = t("role.tpaAuditor");
                    break;
                case "payroll-accountant":
                    ret = t("role.tpaPayrollAccountant");
                    break;
                case "legal-advisor":
                    ret = t("role.legalAdvisor");
                    break;
            }
            return ret;
        })
        .join(", ");
}
