import compact from "lodash/compact";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { Routes } from "../app/router/Routes";
import { ModuleNavBar } from "../ui/ModuleNavBar";
import { hrStore } from "./HrStore";
import { HrRoutes } from "./router/HrRoutes";

export const HrNavBar = observer(function HrNavBar(props: {
    showPeriods?: boolean;
    useEmployeeSubsidiaries?: boolean;
    tabs?: React.ReactNode;
}) {
    const buttons = compact([
        hrStore.canSeeOverviewSite() && {
            title: t("screen.hr.navbar.overview"),
            value: "overview",
            route: Routes.HR,
        },
        authStore.canReadAnyEmployees && {
            title: t("screen.hr.navbar.employees"),
            value: "employees",
            route: HrRoutes.EMPLOYEES.ROOT,
        },
        hrStore.canReadAnySubsidiaryRecords(hrStore.selectedSubsidiaryId) && {
            title: t("screen.hr.navbar.documents"),
            value: "documents",
            route: HrRoutes.RECORDS.ROOT,
        },
    ]);

    return (
        <ModuleNavBar
            title={t("screen.hr.navbar.heading")}
            moduleStore={hrStore}
            buttons={buttons}
            showPeriods={props.showPeriods}
            useEmployeeSubsidiaries={props.useEmployeeSubsidiaries}
            tabs={props.tabs}
        />
    );
});
