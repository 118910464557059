import { Card, CardContent, Link, withStyles } from "@material-ui/core";
import * as React from "react";
import LinesEllipsisLoose from "react-lines-ellipsis/lib/loose";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { NewsFeedEntry } from "../../network/APITypes";
import { Icon } from "../util/Icon";
import { BOX_SHADOW_LIGHT, customColors } from "../util/Theme";

export const PROMOTION_CARD_WIDTH = 560;

const StyledCard = withStyles({
    root: {
        position: "relative",
        display: "flex",
        width: PROMOTION_CARD_WIDTH,
        height: 137,
        backgroundColor: customColors.white,
        boxShadow: BOX_SHADOW_LIGHT,
        marginBottom: 6, // needed in order to swipeable-views not cutting off the boxShadow
    },
})(Card);

const StyledCardContent = withStyles({
    root: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        padding: "12px 24px",
    },
})(CardContent);

export interface IProps {
    promotion: NewsFeedEntry;
    onClose?: (promotion: NewsFeedEntry) => void;
    onClick?: (promotion: NewsFeedEntry) => void;
    style?: React.CSSProperties;
}

const DescriptionContainer = styled.div`
    flex-grow: 1;
    overflow: hidden;

    & * {
        font-size: unset;
        margin: unset;
        padding: unset;
        color: unset;
    }
`;

export const CockpitPromotionCard = ({ promotion, onClose, onClick, style }: IProps) => {
    const [descriptionText, setDescriptionText] = React.useState("");
    const [descriptionLineHeight, setDescriptionLineHeight] = React.useState("");
    const descriptionRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (descriptionRef.current) {
            const computedStyle = getComputedStyle(descriptionRef.current);

            setDescriptionLineHeight(computedStyle.lineHeight);
            setDescriptionText(descriptionRef.current.innerText);
        }
    }, [promotion.description]);

    const handleClick = () => {
        if (onClick) {
            onClick(promotion);
        }
    };

    const handleClose = () => {
        if (onClose) {
            onClose(promotion);
        }
    };

    return (
        <StyledCard style={style}>
            {onClose && (
                <Icon
                    name="closeSmall"
                    onClick={handleClose}
                    style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        cursor: "pointer",
                        color: customColors.greyDarkIcons,
                    }}
                />
            )}
            <div
                style={{
                    height: 137,
                    width: 200,
                    minWidth: 200,
                    background: promotion.imageUrl ? `url(${promotion.imageUrl}) center / cover no-repeat` : undefined,
                }}
            />
            <StyledCardContent>
                <h4>{promotion.title}</h4>
                <DescriptionContainer className="body2" style={{ marginTop: 8 }}>
                    <div
                        ref={descriptionRef}
                        style={{ display: "none" }}
                        dangerouslySetInnerHTML={{ __html: promotion.description }}
                    />
                    <LinesEllipsisLoose text={descriptionText} maxLine={2} lineHeight={descriptionLineHeight} />
                </DescriptionContainer>
                <Link
                    component="button"
                    onClick={handleClick}
                    style={{ fontSize: 10, fontWeight: 500 }}
                    underline="always"
                    color="inherit"
                >
                    {t("common.moreInformation")}
                </Link>
            </StyledCardContent>
        </StyledCard>
    );
};
