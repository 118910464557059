import { Checkbox, IconButton, TableBody } from "@material-ui/core";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { t } from "../../i18n/util";
import { CompanyDocumentScope, Ticket, TicketRelation } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { useTableStore } from "../../stores/TableStore";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";
import { formatDate, getFormattedISODateOnlyRange } from "../../util/date";
import { getFullName } from "../../util/user";
import { pushRoute } from "../app/router/history";
import { useCloseTicket } from "../hooks/useCloseTicket";
import { TicketType, useListTickets } from "../hooks/useListTickets";
import { useCompanySubsidiaries } from "../hooks/useSubsidiaries";
import { useTableFilters } from "../hooks/useTableFilters";
import { HrRoutes } from "../hr/router/HrRoutes";
import { TicketsRoutes } from "../tickets/router/TicketsRoutes";
import { BulkCloseTicketButton } from "../ui/BulkActionButton";
import { CenteredContent } from "../ui/CenteredContent";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../ui/ContextMenu";
import { EmptyState } from "../ui/EmptyState";
import {
    DangerousTableLabel,
    TableLabel,
    TpaTable,
    TpaTableCell,
    TpaTableContainer,
    TpaTableRow,
} from "../ui/Primitives";
import { SiteContent } from "../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../ui/TableHeader";
import { TableSearchBarWithAction } from "../ui/TableSearchBar";
import { DATE_FILTER_TYPE, DROPDOWN_FILTER_TYPE, ITableFilters } from "../ui/filter/types";
import { FileIcon } from "../util/FileIcon";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";

const moduleOptions = () => [
    {
        value: "all",
        label: t("common.all"),
    },
    {
        value: "global",
        label: t("common.global"),
    },
];

const OLDER_THAN_A_WEEK = "OLDER_THAN_A_WEEK";
const OLDER_THAN_A_MONTH = "OLDER_THAN_A_MONTH";
const OLDER_THAN_6_MONTHS = "OLDER_THAN_6_MONTHS";
const OLDER_THAN_A_YEAR = "OLDER_THAN_A_YEAR";

export const TicketsListSite = observer(function TicketsListSite({
    relation,
    module,
    recordTypeId,
    isPreregistration,
}: {
    relation?: TicketRelation;
    module?: Module;
    recordTypeId?: string;
    isPreregistration?: boolean;
}) {
    let ticketListType: TicketType;
    let tableId;
    if (isPreregistration) {
        ticketListType = "preRegistration";
        tableId = "TicketsListSite_preRegistration";
    } else if (recordTypeId) {
        ticketListType = "recordType";
        tableId = `TicketsListSite_${module ?? ""}_records`;
    } else {
        ticketListType = "all";
        tableId = `TicketsListSite_${relation ?? ""}`;
    }

    const tableStore = useTableStore<Ticket & { createdAt?: unknown }>(tableId, {
        orderBy: "createdAt",
        orderDir: "desc",
    });

    // Need this so that badges match with list
    React.useEffect(() => {
        companiesStore.selectedCompanyStore?.startPollingBadges();
    }, []);

    const companyId = companiesStore.selectedCompanyId;
    const isMobile = React.useContext(MobileContext);
    const contextMenu = useContextMenu<Ticket>();
    const [timePeriodDate, setTimePeriodDate] = React.useState("");
    const [dateRangeStartDate, setDateRangeStartDate] = React.useState("");
    const [dateRangeEndDate, setDateRangeEndDate] = React.useState("");
    const [hrSubsidiaryId, setHrSubsidiaryId] = React.useState("");
    const [accountingSubsidiaryId, setAccountingSubsidiaryId] = React.useState("");

    const hasAccounting = companiesStore.selectedCompanyStore?.hasModule("accounting");
    const hasHR = companiesStore.selectedCompanyStore?.hasModule("hr");

    const { subsidiaries: accountingSubsidiaries } = useCompanySubsidiaries({
        companyId: ticketListType === "all" && hasAccounting ? companyId : undefined,
        module: "accounting",
        allSubsidiaries: true,
    });

    const { subsidiaries: hrSubsidiaries } = useCompanySubsidiaries({
        companyId: ticketListType === "all" && hasHR ? companyId : undefined,
        module: "hr",
        allSubsidiaries: true,
    });

    const accountingFilterOptions = accountingSubsidiaries.map(subsidiary => ({
        value: subsidiary.id,
        label: subsidiary.name,
    }));

    const hrFilterOptions = hrSubsidiaries.map(subsidiary => ({ value: subsidiary.id, label: subsidiary.name }));

    const tableFilters = useTableFilters({
        filters: [
            {
                category: "module",
                entries: compact([
                    hasAccounting
                        ? {
                              type: DROPDOWN_FILTER_TYPE,
                              name: "accounting",
                              label: t("common.accounting"),
                              options: moduleOptions().concat(accountingFilterOptions),
                              onChange: (subsidiaryId: string) => {
                                  setAccountingSubsidiaryId(subsidiaryId);
                              },
                              disabled: (selected: ITableFilters) =>
                                  accountingSubsidiaries.length === 0 || !!selected.hr || !!selected.projects,
                          }
                        : undefined,
                    hasHR
                        ? {
                              type: DROPDOWN_FILTER_TYPE,
                              name: "hr",
                              label: t("common.hr.short"),
                              options: moduleOptions().concat(hrFilterOptions),
                              onChange: (subsidiaryId: string) => {
                                  setHrSubsidiaryId(subsidiaryId);
                              },
                              disabled: (selected: ITableFilters) =>
                                  hrSubsidiaries.length === 0 || !!selected.accounting || !!selected.projects,
                          }
                        : undefined,
                    authStore.canReadProjects
                        ? {
                              name: "projects",
                              label: t("common.projects"),
                              disabled: (selected: ITableFilters) => !!selected.hr || !!selected.accounting,
                          }
                        : undefined,
                ]),
            },
            {
                category: "general",
                entries: [
                    {
                        name: "dateRange",
                        label: t("common.anyDateRange"),
                        type: DATE_FILTER_TYPE,
                        onChange: (date: Date) => {
                            const { startDate, endDate } = getFormattedISODateOnlyRange(date, "month");
                            setDateRangeStartDate(startDate);
                            setDateRangeEndDate(endDate);
                        },
                        disabled: (selected: ITableFilters) => !!selected.timePeriod,
                    },
                    {
                        name: "timePeriod",
                        label: t("common.timePeriod"),
                        type: DROPDOWN_FILTER_TYPE,
                        options: [
                            {
                                value: OLDER_THAN_A_WEEK,
                                label: t("common.timePeriod.olderThan1Week"),
                            },
                            {
                                value: OLDER_THAN_A_MONTH,
                                label: t("common.timePeriod.olderThan1Month"),
                            },
                            {
                                value: OLDER_THAN_6_MONTHS,
                                label: t("common.timePeriod.olderThan6Months"),
                            },
                            {
                                value: OLDER_THAN_A_YEAR,
                                label: t("common.timePeriod.olderThan1Year"),
                            },
                        ],
                        onChange: (timePeriod: string) => {
                            const dateFromTimePeriod = moment();

                            switch (timePeriod) {
                                case OLDER_THAN_A_WEEK:
                                    dateFromTimePeriod.subtract(1, "weeks");
                                    break;
                                case OLDER_THAN_A_MONTH:
                                    dateFromTimePeriod.subtract(1, "months");
                                    break;
                                case OLDER_THAN_6_MONTHS:
                                    dateFromTimePeriod.subtract(6, "months");
                                    break;
                                case OLDER_THAN_A_YEAR:
                                    dateFromTimePeriod.subtract(1, "years");
                                    break;
                            }

                            setTimePeriodDate(dateFromTimePeriod.format("YYYY-MM-DD"));
                        },
                        disabled: (selected: ITableFilters) => !!selected.dateRange,
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            tableStore.resetOffset();

            if (!selected.hr) {
                setHrSubsidiaryId("");
            }

            if (!selected.accounting) {
                setAccountingSubsidiaryId("");
            }

            if (!selected.dateRange) {
                setDateRangeStartDate("");
                setDateRangeEndDate("");
            }

            if (!selected.timePeriod) {
                setTimePeriodDate("");
            }
        },
    });

    let filterModule: Module | "projects" | undefined = undefined;
    let scope: CompanyDocumentScope = "all"; // TODO use TicketScope if available
    let subsidiaryId = undefined;

    let filterSubsidiaryId: string | undefined = undefined;
    if (accountingSubsidiaryId) {
        filterModule = "accounting";
        filterSubsidiaryId = accountingSubsidiaryId;
    } else if (hrSubsidiaryId) {
        filterModule = "hr";
        filterSubsidiaryId = hrSubsidiaryId;
    } else if (tableFilters.activeFilters.projects) {
        filterModule = "projects";
    }

    if (filterModule) {
        if (filterSubsidiaryId === "all" || filterSubsidiaryId === "global") {
            scope = filterSubsidiaryId;
        } else {
            scope = "subsidiary";
            subsidiaryId = filterSubsidiaryId;
        }
    }

    const {
        tickets,
        reload: reloadTickets,
        isInitialized,
        total,
    } = useListTickets({
        companyId,
        ticketType: ticketListType,
        module: module ?? filterModule,
        tableParams: tableStore.tableParams,
        recordTypeParams: {
            recordTypeId,
        },
        ticketParams: {
            // used for tpa employee's ticket list
            subsidiaryId,
            startDate: dateRangeStartDate || undefined,
            endDate: dateRangeEndDate || timePeriodDate || undefined,
            relation,
            scope,
        },
    });

    React.useEffect(() => {
        tableStore.items = tickets;
        tableStore.totalCount = total;
    }, [tableStore, tickets, total]);

    const reload = React.useCallback(async () => {
        await reloadTickets();
        tableStore.selectedItems.clear();
    }, [reloadTickets, tableStore]);

    const closeTicket = useCloseTicket(companyId, reload);

    const hasSelect = relation !== "closed";
    const hasFilters = ticketListType === "all";

    const openTicket = (ticket: Ticket) => {
        if (isPreregistration) {
            pushRoute(HrRoutes.EMPLOYEES.TICKET_DETAILS, {
                params: { ticketId: ticket.id },
            });
        } else if (module && recordTypeId) {
            const moduleStore = getModuleStore(module);
            pushRoute(moduleStore.routes.RECORDS.TICKET_DETAILS, {
                params: { ticketId: ticket.id, recordTypeId },
            });
        } else {
            pushRoute(TicketsRoutes.DETAILS, { params: { ticketId: ticket.id } });
        }
    };

    let contextMenuItems: ContextMenuItem[] = [];
    if (contextMenu.contextElement) {
        const ticket = contextMenu.contextElement;
        contextMenuItems = [
            {
                title: t("menu.markAsDone"),
                icon: "pin",
                onClick: () => {
                    if (ticket) {
                        if (!authStore.canCloseTicket(ticket)) {
                            generalStore.setError(t("error.markAsDone"));
                        } else {
                            contextMenu.close();
                            closeTicket.close([ticket.id]);
                        }
                    }
                },
                disabled: ticket.status === "closed" || tableStore.selectedItems.size > 0,
                "data-id": "context_menu_markAsDone",
            },
            // { -> disable assign for phase 1
            //     title: t("menu.assign"),
            //     icon: "assign",
            //     onClick: () => {
            //         if (ticket) {
            //             contextMenu.close();
            //             // TODO
            //         }
            //     },
            //      disabled: tableStore.selectedItems.size > 0,
            //     "data-id": "context_menu_assign",
            // },
            {
                title: t("menu.answer"),
                icon: "send",
                onClick: () => {
                    if (ticket) {
                        contextMenu.close();
                        openTicket(ticket);
                    }
                },
                disabled: tableStore.selectedItems.size > 0,
                "data-id": "context_menu_answer",
            },
        ];
    }

    const headerFields: ITableHeaderConfig[] = compact([
        ticketListType === "all" ? { column: "ticketType" } : undefined,
        ticketListType !== "preRegistration"
            ? { column: "documentType", label: "table.label.documentType" }
            : undefined,
        { column: "description", label: "table.label.documentName" },
        { column: "lastMessageSent", label: "table.label.date" },
        { column: "ticket", label: "table.label.ticket", sort: false },
        { column: "author", label: "table.label.ticketBy" },
        { column: "dueDate", label: "table.label.ticketDueDate" },
        { column: "contextMenu" },
    ]);

    if (!tickets) {
        return null;
    }

    const tableBody = (
        <TableBody>
            {tickets.map((ticket, index) => {
                return (
                    <TpaTableRow
                        key={ticket.id}
                        onClick={() => {
                            openTicket(ticket);
                        }}
                    >
                        {!isMobile && hasSelect && (
                            <TpaTableCell padding="checkbox">
                                <Checkbox
                                    data-id={`record_checkbox_${index}`}
                                    onChange={(event, checked) => {
                                        tableStore.toggleSelection(ticket);
                                    }}
                                    onClick={event => {
                                        event.stopPropagation();
                                    }}
                                    color="primary"
                                    checked={tableStore.isSelected(ticket)}
                                />
                            </TpaTableCell>
                        )}
                        {headerFields.map(({ column }, index) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = ticket[column as keyof Ticket];
                            if (column === "ticketType") {
                                const getIcon = () => {
                                    if (ticket.module === "hr") {
                                        return "personnel";
                                    } else if (ticket.module === "accounting") {
                                        return "accounting";
                                    } else if (ticket.referenceType === "projectItemDraft") {
                                        return "projects";
                                    }
                                };

                                const iconName = getIcon();

                                return iconName ? (
                                    <TpaTableCell key={column}>
                                        <Icon name={iconName} style={{ display: "block" }} />
                                    </TpaTableCell>
                                ) : null;
                            } else if (column === "documentType") {
                                return (
                                    <TpaTableCell key={column}>
                                        <FileIcon name={ticket.description} style={{ display: "block" }} />
                                    </TpaTableCell>
                                );
                            } else if (column === "description") {
                                // TPAPORTAL-2177: We show full path name, not only file name
                                // label = getFilenameFromPath(ticket.description ?? "");
                                label = ticket.description ?? "";
                            } else if (column === "lastMessageSent") {
                                label = formatDate(ticket.lastMessageSent);
                            } else if (column === "ticket") {
                                return (
                                    <TpaTableCell key={column}>
                                        <DangerousTableLabel className="chat-message">
                                            {ticket.messagePreview}
                                        </DangerousTableLabel>
                                    </TpaTableCell>
                                );
                            } else if (column === "author") {
                                label = getFullName(ticket.author);
                            } else if (column === "dueDate") {
                                label = formatDate(ticket.dueDate);
                            } else if (column === "contextMenu") {
                                return (
                                    <TpaTableCell key={column} style={{ textAlign: "right", width: 1 }}>
                                        <IconButton
                                            data-id={`context_menu_${index}`}
                                            style={{ padding: 0 }}
                                            onClick={event => {
                                                event.stopPropagation();
                                                contextMenu.open(event, ticket);
                                            }}
                                        >
                                            <Icon name="more" />
                                        </IconButton>
                                    </TpaTableCell>
                                );
                            }

                            return (
                                <TpaTableCell key={column}>
                                    <TableLabel>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    if (!isInitialized) {
        return null;
    }

    const empty = tableStore.getIsEmptyState(generalStore.findLoading("tickets"), !isEmpty(tableFilters.activeFilters));

    const userCanCloseSelectedTickets = () => {
        return tableStore.getAllSelectedItems().every(ticket => authStore.canCloseTicket(ticket));
    };

    return (
        <>
            {empty && <EmptyState title={t("ticketList.emptystate.title")} />}
            {!empty && (
                <>
                    <CenteredContent>
                        <SiteContent>
                            <TableSearchBarWithAction
                                label="search.caption.numTickets"
                                labelSelected="search.caption.numSelected"
                                placeholder="search.placeholder.searchForUsersFileName"
                                select={tableStore}
                                search={tableStore.search}
                                totalCount={tableStore.totalCount}
                                onChangeSearch={tableStore.handleSearchChange}
                                tableFilters={hasFilters ? tableFilters : undefined}
                                bulkAction={
                                    <>
                                        {/* <BulkAnswerButton -> not in phase 1
                                            onClick={() => {
                                                // TODO
                                            }}
                                        /> */}
                                        <BulkCloseTicketButton
                                            onClick={
                                                userCanCloseSelectedTickets()
                                                    ? () =>
                                                          closeTicket.close(
                                                              tableStore.getAllSelectedItems().map(t => t.id),
                                                          )
                                                    : undefined
                                            }
                                        />
                                        {/* <BulkAssignButton -> not in phase 1
                                            onClick={() => {
                                                // TODO
                                            }}
                                        /> */}
                                    </>
                                }
                            />
                            <TpaTableContainer>
                                <TpaTable>
                                    <TableHeader
                                        headerFields={headerFields}
                                        tableStore={tableStore}
                                        select={hasSelect ? tableStore : undefined}
                                    />
                                    {tableBody}
                                </TpaTable>
                            </TpaTableContainer>
                            <tableStore.Pagination />
                        </SiteContent>
                        {tableStore.getIsNoResultState(
                            generalStore.findLoading("tickets"),
                            !isEmpty(tableFilters.activeFilters),
                        ) && <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />}
                    </CenteredContent>
                    <ContextMenu
                        data-id="context_menu"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        config={contextMenu}
                        items={contextMenuItems}
                    />
                    {closeTicket.successDialog.dialog}
                </>
            )}
        </>
    );
});
