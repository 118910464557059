import { Button } from "@material-ui/core";
import { FormattedHTMLMessage } from "react-intl";
import { CARD_SEND_DATE } from "../../config";
import { t } from "../../i18n/util";
import { Report } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { ModuleStore, periodToString } from "../../stores/ModuleStore";
import { Status } from "../../stores/Status";
import { formatDate } from "../../util/date";
import { getPeriodActionsDatesForSubsidiary } from "../../util/periods";
import { pushRoute } from "../app/router/history";
import { useDeleteReport } from "../hooks/useDeleteReport";
import { SignReport } from "../hooks/useDocumentSign";
import { IReportsConfig } from "../hooks/useReports";
import { DocumentCategory } from "../hooks/useWebhookConfiguration";
import { ModuleMainResponsible } from "../shared/ModuleMainResponsible";
import { EmptyState } from "../ui/EmptyState";
import { ExpansionCard } from "../ui/ExpansionCard";
import { ReportList } from "../ui/ReportList";
import { IIconNames } from "../util/Icon";

interface IProps {
    expanded: boolean;
    moduleStore: ModuleStore;
    title: string;
    onExpand: (isExpanded: boolean) => void;
    reportsConfig: IReportsConfig;
    showRelease?: boolean;
    showDelete?: boolean;
    showTicket?: boolean;
    createTicketAllowed?: boolean;
    iconName: IIconNames;
    "data-id": string;
}

export const ModuleReportCard = ({
    moduleStore,
    title,
    expanded,
    iconName,
    onExpand,
    reportsConfig,
    showRelease,
    showDelete,
    showTicket,
    createTicketAllowed,
    "data-id": dataId,
}: IProps) => {
    const reports = reportsConfig.reports?.reports ?? [];
    const deleteReport = useDeleteReport(moduleStore.module);

    const canUploadReports =
        companiesStore.selectedCompany?.localReportUploadEnabled &&
        (authStore.isAdvisor || authStore.isResponsibleForModule(moduleStore.module));

    const handleUploadReports = (category: DocumentCategory) => {
        pushRoute(moduleStore.routes.REPORT_UPLOAD, {
            query: {
                companyId: reportsConfig.companyId,
                subsidiaryId: reportsConfig.subsidiaryId,
                periodId: reportsConfig.periodId,
                module: moduleStore.module,
                category: moduleStore.module === "hr" ? category : "report",
            },
        });
    };

    let status: Status = "open";
    if (reports.length > 0) {
        if (reportsConfig.needRelease) {
            status = "inProgress";
        } else {
            status = "closed";
        }
    }

    let lastDate: Date | null = null;
    reports.forEach(report => {
        if (lastDate === null || report.document.uploadedAt > lastDate) {
            lastDate = report.document.uploadedAt;
        }
    });

    const disabled = status === "open" && !canUploadReports;

    const dueDate = moduleStore.selectedPeriod?.dueDateReportsRelease
        ? new Date(moduleStore.selectedPeriod.dueDateReportsRelease)
        : undefined;

    const pads = moduleStore.selectedPeriod?.periodActionsDates;
    const comment = getPeriodActionsDatesForSubsidiary(
        pads,
        moduleStore.selectedSubsidiaryId,
        reportsConfig.global,
    )?.reportsComment;

    const handleRelease = async (report: SignReport) => {
        await moduleStore.releaseReport({
            companyId: reportsConfig.companyId,
            reportId: report.id,
            isGlobal: reportsConfig.global,
            periodId: reportsConfig.periodId,
            subsidiaryId: reportsConfig.subsidiaryId,
        });
        await reportsConfig.loadReports();
    };

    const handleTicket = (report: Report) => {
        pushRoute(moduleStore.routes.REPORT_TICKET, {
            params: { reportId: report.id },
            query: {
                global: reportsConfig.global,
                reportType: reportsConfig.reportType,
                ticketId: report.ticket?.id,
            },
        });
    };

    return (
        <>
            <ExpansionCard
                data-id={dataId}
                expanded={expanded}
                title={title}
                onExpand={onExpand}
                status={status}
                iconName={iconName}
                disabled={disabled}
                date={dueDate ? { labelId: "dueDate.until", date: dueDate } : undefined}
            >
                {status !== "open" ? (
                    <>
                        <ModuleMainResponsible module={moduleStore.module} />
                        <p style={{ marginTop: 16, whiteSpace: "pre-line" }}>
                            {comment ? (
                                <>
                                    {authStore.greeting},
                                    <br />
                                    <br />
                                    {comment}
                                </>
                            ) : (
                                <FormattedHTMLMessage
                                    id="screen.accounting.overview.reports"
                                    values={{
                                        salutation: authStore.greeting,
                                        period: periodToString(moduleStore.selectedPeriod),
                                        signature: companiesStore.mainResponsibleSignatureName(moduleStore.module),
                                    }}
                                />
                            )}
                        </p>
                        {CARD_SEND_DATE && (
                            <p className="caption" style={{ marginTop: 8 }}>
                                {t("dueDate.sentAt", { date: formatDate(lastDate) })}
                            </p>
                        )}

                        <ReportList
                            reportsConfig={reportsConfig}
                            onRelease={showRelease === true ? handleRelease : undefined}
                            onDelete={showDelete === true ? deleteReport.handleDelete(reportsConfig) : undefined}
                            onTicket={showTicket === true ? handleTicket : undefined}
                            createTicketAllowed={createTicketAllowed}
                        />
                        {canUploadReports && (
                            <div style={{ margin: "16px 0", display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        handleUploadReports(reportsConfig.reportType);
                                    }}
                                >
                                    {t("screen.module.overview.report.upload")}
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    <EmptyState
                        title={t("screen.module.overview.report.empty.title")}
                        message={t("screen.module.overview.report.empty.message")}
                        buttonLabel={t("screen.module.overview.report.upload")}
                        onAddEntry={() => {
                            handleUploadReports(reportsConfig.reportType);
                        }}
                    />
                )}
            </ExpansionCard>
            {deleteReport.dialog}
        </>
    );
};
