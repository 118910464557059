import React from "react";
import { t } from "../../../i18n/util";
import {
    DATE_FILTER_TYPE,
    DATE_RANGE_FILTER_TYPE,
    DROPDOWN_FILTER_TYPE,
    DateRange,
    IFilterItem,
    ITableFilters,
    NUMBER_RANGE_FILTER_TYPE,
    NumberRange,
    TableFilterValue,
} from "./types";

export function canSubmit(filterDefinitions: IFilterItem[], filterItems: ITableFilters) {
    // check if all required filter values have been selected
    return filterDefinitions.every(filterDefinition => {
        const filter = filterItems[filterDefinition.name];

        if (filterDefinition.required) {
            if (!filter?.value) {
                return false;
            }
            if (typeof filterDefinition.required === "function") {
                const isRequired = filterDefinition.required(filter.value as never); // never because the combination of the different required functions results in a never type
                return !isRequired;
            }
        }

        return true;
    });
}

export function callOnChange(filterDefinitions: IFilterItem[], filterItems: ITableFilters) {
    filterDefinitions.forEach(filterDefinition => {
        const filter = filterItems[filterDefinition.name];

        if (filterDefinition.type === DATE_FILTER_TYPE && filterDefinition.onChange) {
            if (filter?.value) {
                filterDefinition.onChange(filter.value as Date);
            }
        }

        if (filterDefinition.type === DATE_RANGE_FILTER_TYPE && filterDefinition.onChange) {
            if (filter?.value) {
                filterDefinition.onChange(filter.value as DateRange);
            }
        }

        if (filterDefinition.type === DROPDOWN_FILTER_TYPE && filterDefinition.onChange) {
            if (filter?.value) {
                filterDefinition.onChange(filter.value as string);
            }
        }

        if (filterDefinition.type === NUMBER_RANGE_FILTER_TYPE && filterDefinition.onChange) {
            if (filter?.value) {
                filterDefinition.onChange(filter.value as NumberRange);
            }
        }
    });
}

export function getRequiredText({
    required,
    requiredText,
    value,
}: {
    required: IFilterItem["required"];
    requiredText: IFilterItem["requiredText"];
    value: TableFilterValue;
}): null | React.ReactNode {
    if (!required) {
        // not required or no required function given
        return null;
    }

    let requiredContent;
    if (typeof required === "function") {
        // any is required because the required function is a combination of all `required` functions defined on `IFilterItem`.
        // This results in type `undefined` for the `value` argument which would not match...
        const anyValue = value as never;
        if (!required(anyValue)) {
            // the function returned false, so the value is not required
            return null;
        }

        requiredContent = requiredText?.(anyValue);
    } else if (value) {
        return null;
    }

    return requiredContent ?? t("filterDialog.required");
}
