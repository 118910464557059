import TextField from "@material-ui/core/TextField";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { getModuleStore } from "../../stores/moduleStores";
import { ApprovalOption, Module } from "../../types/models";
import { debug } from "../../util/debug";
import { getPeriodActionsDatesForSubsidiary } from "../../util/periods";
import { ISignatureUserSignature, createAnonymousSignatureUsers } from "../../util/qes";
import { getRequiredReportReleasers } from "../../util/reports";
import { Routes } from "../app/router/Routes";
import { pushRoute } from "../app/router/history";
import { usePdfSignaturePositionDocumentUploadDialog } from "../hooks/usePdfSignaturePositionDocumentUploadDialog";
import { useQueryParams } from "../hooks/useQueryParams";
import { useTransferSuccessDialog } from "../hooks/useTransferSuccessDialog";
import { DocumentCategory, useWebhookConfiguration } from "../hooks/useWebhookConfiguration";
import { ModuleConfiguration, useWebhookModuleConfiguration } from "../hooks/useWebhookModuleConfiguration";
import { DocumentLine } from "../ui/DocumentLine/DocumentLine";
import { IAcceptedFile, useDocumentUploadSmall } from "../ui/DocumentUploadSmall";
import { LoadingOverlay } from "../ui/LoadingOverlay";
import { DocumentUploadSite } from "./DocumentUploadSite";

interface DocumentUploadFileMetaData {
    approvalOption?: ApprovalOption;
    signaturePositions?: ISignatureUserSignature[];
}

type FileWithMetaData = IAcceptedFile<DocumentUploadFileMetaData>;

export const ModuleReportUploadSite = observer(function ModuleReportUploadSite({ module }: { module: Module }) {
    const { companyId, subsidiaryId, periodId, category } = useQueryParams<{
        companyId: string;
        subsidiaryId: string;
        periodId: string;
        category: DocumentCategory;
    }>();
    const moduleStore = getModuleStore(module);

    const getSignatureUsers = (file: FileWithMetaData) => {
        if (!uploadConfig.company) {
            return [];
        }
        const requiredReleasers = getRequiredReportReleasers(uploadConfig.company, module);
        const countSignatures = file.metaData?.signaturePositions?.length ?? 0;
        const count = Math.max(requiredReleasers, countSignatures);
        return createAnonymousSignatureUsers(count);
    };

    const handleSelectSignaturePosition = (file: FileWithMetaData, signaturePositions: ISignatureUserSignature[]) => {
        file.metaData = { ...file.metaData, signaturePositions };
    };
    const pdfSignatureDialog = usePdfSignaturePositionDocumentUploadDialog(handleSelectSignaturePosition);

    const openPdfSignatureDialog = (file: FileWithMetaData) => {
        pdfSignatureDialog.open(file, getSignatureUsers(file), file.metaData?.signaturePositions);
    };

    const renderAccepted = (file: FileWithMetaData, onDelete: () => void) => {
        return (
            <DocumentLine
                fileName={file.file.name}
                showReleaseOptions
                defaultApprovalOption={file.metaData?.approvalOption ?? "none"}
                onChangeReleaseOption={option => {
                    file.metaData = { ...file.metaData, approvalOption: option };

                    if (option === "qes") {
                        openPdfSignatureDialog(file);
                    }
                }}
                onDelete={onDelete}
                key={file.file.name}
            />
        );
    };

    const [comment, setComment] = useState("");

    const uploadFile = async (file: FileWithMetaData) => {
        if (!moduleConfig.periodId || !companyId || !uploadConfig.module || !moduleConfig.subsidiaryId) {
            generalStore.setError(t("error.upload"));
            return;
        }

        try {
            return await API.postReport({
                companyId: companyId,
                module: module,
                periodId: moduleConfig.periodId,
                subsidiaryId: moduleConfig.globalReport ? undefined : moduleConfig.subsidiaryId,
                file: file.file,
                needsRelease: file.metaData?.approvalOption === "click" || file.metaData?.approvalOption === "qes",
                reportType: uploadConfig.category === "billing" ? "billing" : undefined,
                sendEmail: moduleConfig.notifyCustomer,
                requiresQes: file.metaData?.approvalOption === "qes",
                qesPositions: file.metaData?.signaturePositions?.map(s => s.signaturePosition),
                comment,
            });
        } catch (error) {
            generalStore.setError(t("error.upload"), error);
        }
    };

    const handleSubmit = async () => {
        const success = await documentUploadSmallConfig.uploadFiles(documentUploadSmallConfig.acceptedFiles);
        if (success) {
            try {
                generalStore.isLoading = true;
                // reload the current period so that the `comment` appears on the module card
                await moduleStore.reloadCurrentPeriod();
            } catch (error) {
                generalStore.setError(t("error.loadPeriod"), error);
            } finally {
                generalStore.isLoading = false;
            }

            successDialog.openDialog();
        }
    };

    const successDialog = useTransferSuccessDialog(t("dialog.transferReports.success.message"), () => {
        pushRoute(moduleStore.routes.ROOT);
    });

    const uploadConfig = useWebhookConfiguration({
        showCompany: true,
        chooseCompany: false,
        companyId,
        chooseModule: false,
        module: module,
        showCategory: true,
        chooseCategory: true,
        reportsOnly: true,
        category,
    });

    const moduleConfig = useWebhookModuleConfiguration({
        companyId,
        company: uploadConfig.company,
        module,
        periodId,
        isGlobal: subsidiaryId === undefined,
        subsidiaryId,
    });

    // update the comment when the period or subsidiary changes
    useEffect(() => {
        const period = moduleConfig.periods.find(p => p.id === moduleConfig.periodId);
        if (period) {
            const pad = getPeriodActionsDatesForSubsidiary(
                period.periodActionsDates,
                moduleConfig.subsidiaryId,
                moduleConfig.globalReport,
            );
            if (pad?.reportsComment) {
                setComment(pad.reportsComment);
            }
        }
    }, [moduleConfig.globalReport, moduleConfig.periodId, moduleConfig.periods, moduleConfig.subsidiaryId]);

    const documentUploadSmallConfig = useDocumentUploadSmall(uploadFile, true);

    const validQuery = !!companyId;
    const canUploadReports =
        companiesStore.selectedCompany?.localReportUploadEnabled &&
        (authStore.isAdvisor || authStore.isResponsibleForModule(module));

    if (!canUploadReports) {
        debug.error("### user has no permission to upload reports");
        return <Redirect to={Routes.COCKPIT} />;
    }

    if (!validQuery) {
        debug.error("### invalid report Upload query");
        return <Redirect to={Routes.COCKPIT} />;
    }

    if (!moduleConfig.initialized) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <DocumentUploadSite
                title={t("screen.moduleReportUpload.title")}
                cancelTarget={moduleStore.routes.ROOT}
                uploadConfig={uploadConfig}
                documentUploadSmallConfig={documentUploadSmallConfig}
                renderAccepted={renderAccepted}
            >
                {documentUploadElement => {
                    return (
                        <ModuleConfiguration
                            {...moduleConfig}
                            onSubmit={handleSubmit}
                            showGlobal
                            submitButtonLabel={t("common.uploadFiles")}
                        >
                            {documentUploadElement}
                            <TextField
                                value={comment}
                                onChange={event => {
                                    setComment(event.target.value);
                                }}
                                variant="outlined"
                                label={t("screen.moduleReportUpload.comment")}
                                fullWidth
                                multiline
                                style={{ marginTop: 40 }}
                                inputProps={{ style: { minHeight: 50 /* 3 lines */ } }}
                            />
                        </ModuleConfiguration>
                    );
                }}
            </DocumentUploadSite>
            {pdfSignatureDialog.component}
            {successDialog.component}
        </>
    );
});
