import { accountingStore } from "../components/accounting/AccountingStore";
import { hrStore } from "../components/hr/HrStore";
import { Module } from "../types/models";

export function getModuleStore(module: Module) {
    switch (module) {
        case "accounting":
            return accountingStore;
        case "hr":
            return hrStore;
    }
}
