import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { companiesStore } from "../../../stores/CompaniesStore";
import { Routes } from "../../app/router/Routes";
import { useInitModule } from "../../hooks/useInitModule";
import { accountingStore } from "../AccountingStore";
import { AccountingRouter } from "../router/AccountingRouter";

export const AccountingContainerSite = observer(function AccountingContainerSite() {
    useInitModule("accounting");

    if (!companiesStore.selectedCompanyId) {
        return null;
    }

    if (!accountingStore.canAccessSection()) {
        return <Redirect to={Routes.COCKPIT} />;
    }

    if (!accountingStore.isInitialized) {
        return null;
    }

    return <AccountingRouter />;
});
