import { API } from "../../../network/API";
import { APIClientStatusCodeError, getApiError } from "../../../network/NetworkStapler";

export interface BulkCompany {
    id: string;
    name: string;
}

export interface FileAndUrl {
    file: File;
    url: string;
}

export function toFileAndUrl(file: File): FileAndUrl {
    return {
        file,
        url: URL.createObjectURL(file),
    };
}

/** Router state that is passed around in the various bulk config screens */
export interface BulkState {
    companies: BulkCompany[];
    coverImage: FileAndUrl | undefined;
    logoImage: FileAndUrl | undefined;
    documentVersion: string | undefined;
    signees: string[] | undefined;
    skipTermsOfUse?: boolean;
}

export type ActivateCompaniesProgressStatus =
    | { type: "idle" }
    | { type: "settingTermsOfUseAndSignees"; error?: APIClientStatusCodeError }
    | { type: "uploadingCoverImage"; error?: APIClientStatusCodeError }
    | { type: "uploadingLogoImage"; error?: APIClientStatusCodeError }
    | { type: "activating"; error?: APIClientStatusCodeError }
    | { type: "done" };

interface ActivateCompaniesOptions {
    companies: BulkCompany[];
    coverImage: File | undefined;
    logoImage: File | undefined;
    documentVersion: string;
    signees: string[];
    skipTermsOfUse: boolean;
    progress: (company: BulkCompany, status: ActivateCompaniesProgressStatus) => void;
}

export async function activateCompanies({
    companies,
    coverImage,
    logoImage,
    documentVersion,
    signees,
    skipTermsOfUse,
    progress,
}: ActivateCompaniesOptions) {
    if (!skipTermsOfUse) {
        progress(companies[0], { type: "settingTermsOfUseAndSignees" });
        try {
            await API.putBulkTermsOfUseSignees({
                companyIDs: companies.map(c => c.id),
                fileName: documentVersion,
                signees,
            });
        } catch (error) {
            // cannot continue here as it affects all companies
            progress(companies[0], { type: "settingTermsOfUseAndSignees", error: getApiError(error) ?? undefined });
            return;
        }
    }

    for (const company of companies) {
        if (coverImage) {
            progress(company, { type: "uploadingCoverImage" });
            try {
                await API.putCoverImage(company.id, coverImage);
            } catch (error) {
                progress(company, { type: "uploadingCoverImage", error: getApiError(error) ?? undefined });
                continue;
            }
        }
        if (logoImage) {
            progress(company, { type: "uploadingLogoImage" });
            try {
                await API.putLogoImage(company.id, logoImage);
            } catch (error) {
                progress(company, { type: "uploadingLogoImage", error: getApiError(error) ?? undefined });
                continue;
            }
        }

        progress(company, { type: "activating" });
        try {
            await API.putActivateCompany(company.id);
        } catch (error) {
            const apiError = getApiError(error) ?? undefined;
            if (apiError?.response.type === "COMPANY_ALREADY_ACTIVE") {
                // the company has already been activated, so not an error - continue to "done"
            } else {
                progress(company, { type: "activating", error: apiError });
                continue;
            }
        }

        progress(company, { type: "done" });
    }
}
