import { Button } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { GLOBAL_FEATURES } from "../../features";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Report } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { generalStore } from "../../stores/GeneralStore";
import { hasFileExtension } from "../../util/files";
import { isReportReleased } from "../../util/reports";
import { SignReport, useDocumentSign } from "../hooks/useDocumentSign";
import { IReportsConfig } from "../hooks/useReports";
import { FileIcon } from "../util/FileIcon";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { TicketButton } from "./Primitives";
import { ReportReleaseDetails, getReportReleasedInformation } from "./ReportReleaseDetails";

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 16px;
`;

const DownloadButton = ({ onClick }: { onClick: () => void }) => (
    <div style={{ cursor: "pointer", marginLeft: 26 }} role="button" onClick={onClick}>
        <Icon name="download" style={{ color: customColors.greyDarkIcons }} />
    </div>
);

const DeleteButton = ({ onClick }: { onClick: () => void }) => (
    <div style={{ cursor: "pointer", marginLeft: 26 }} role="button" onClick={onClick}>
        <Icon name="delete" style={{ color: customColors.greyDarkIcons }} />
    </div>
);

const ReportTitle = ({ report }: { report: Report }) => {
    const isMobile = React.useContext(MobileContext);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
            }}
        >
            <FileIcon name={report.document.name} />
            <p className="body2" style={{ marginLeft: isMobile ? 8 : 24, wordBreak: "break-all" }}>
                {report.document.name}
            </p>
        </div>
    );
};

const ReportLine = ({ isMobile, children }: { isMobile: boolean; children: React.ReactNode }) => (
    <div
        style={{
            display: "flex",
            alignItems: isMobile ? "flex-start" : "center",
            flexDirection: isMobile ? "column" : "row",
            padding: 16,
            border: `1px solid ${customColors.greyLight}`,
        }}
    >
        {children}
    </div>
);

export const ReportList = (props: {
    reportsConfig: IReportsConfig;
    title?: string;
    onDelete?: (report: Report) => void;
    onRelease?: (report: SignReport) => void;
    onTicket?: (report: Report) => void;
    createTicketAllowed?: boolean;
    releasedOnly?: boolean;
}) => {
    const isMobile = React.useContext(MobileContext);
    const disableDelete = !props.onDelete;
    const disableRelease = !props.onRelease;

    const reports = props.reportsConfig.reports?.reports;

    const sign = useDocumentSign({
        onReleaseReport: props.onRelease,
        onReload: props.reportsConfig.loadReports,
    });

    const handleReportRelease = (report: Report) => {
        if (!generalStore.isLoading) {
            if (hasFileExtension(report.document.name, "pdf")) {
                sign.openQesDialog({
                    signContext: "report",
                    itemToRelease: report,
                    reportConfig: props.reportsConfig,
                    mimeType: "application/pdf",
                });
            } else {
                props.onRelease?.(report);
            }
        }
    };

    const displayedReports = React.useMemo(() => {
        let displayedReports = reports ?? [];
        if (props.releasedOnly) {
            displayedReports = displayedReports.filter(report => !report.needsRelease || isReportReleased(report));
        }
        return displayedReports;
    }, [props.releasedOnly, reports]);

    const downloadReports = async (reportIds: string[]) => {
        const config = props.reportsConfig;
        if (!config.companyId || !config.periodId) {
            return;
        }

        try {
            await API.putDownloadReport({
                companyId: config.companyId,
                module: config.module,
                periodId: config.periodId,
                subsidiaryId: config.subsidiaryId,
                reportIds,
            });
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.statusCode === HttpStatusCode.Locked_423 &&
                apiError.response.type === "DOCUMENT_LOCKED_TRANSFER_IN_PROGRESS"
            ) {
                generalStore.setError(t("error.documentsInProgress"), error);
            } else {
                generalStore.setError(t("error.download"), error);
            }
        }
    };

    return (
        <>
            {displayedReports.length > 0 && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                    }}
                >
                    <h4>{props.title ? props.title : ""}</h4>
                    <div style={{ margin: "16px 0" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            data-id="download_all"
                            onClick={() => downloadReports(displayedReports.map(report => report.id))}
                        >
                            {t("screen.accounting.overview.finished.download")}
                        </Button>
                    </div>
                </div>
            )}
            {displayedReports.map(report => {
                const releaseInformation = getReportReleasedInformation(report, isMobile);

                return (
                    <ReportLine key={report.id} isMobile={isMobile}>
                        <ReportTitle report={report} />

                        {isMobile && releaseInformation}
                        <ButtonContainer
                            style={{
                                marginTop: isMobile ? 8 : undefined,
                                alignSelf: isMobile ? "flex-end" : undefined,
                            }}
                        >
                            {(!isMobile || !releaseInformation) && (
                                <ReportReleaseDetails
                                    report={report}
                                    onRelease={
                                        disableRelease
                                            ? undefined
                                            : () => {
                                                  handleReportRelease(report);
                                              }
                                    }
                                />
                            )}

                            <div style={{ display: "flex", alignItems: "center" }}>
                                {props.onTicket &&
                                    (!!props.createTicketAllowed || !!report.ticket) &&
                                    GLOBAL_FEATURES.tickets && (
                                        <TicketButton
                                            ticket={report.ticket}
                                            onClick={() => props.onTicket?.(report)}
                                            style={{ marginLeft: 26 }}
                                        />
                                    )}

                                <DownloadButton onClick={() => downloadReports([report.id])} />

                                {!disableDelete && (
                                    <DeleteButton
                                        onClick={() => {
                                            if (!generalStore.isLoading) {
                                                props.onDelete?.(report);
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        </ButtonContainer>
                    </ReportLine>
                );
            })}
            {sign.qesDialog}
            {sign.qesBlockedDialog}
        </>
    );
};
