import * as React from "react";
import { t } from "../../i18n/util";
import { API, ITableParams } from "../../network/API";
import { Project, ProjectStatusEnum } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { generalStore } from "../../stores/GeneralStore";

export const useProjects = ({
    companyId,
    status,
    tableParams,
}: {
    companyId?: string;
    status?: ProjectStatusEnum;
    tableParams: ITableParams;
}) => {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [projects, setProjects] = React.useState<Project[]>([]);
    const [total, setTotal] = React.useState(0);

    const reloadProjects = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            // TODO check tableparams (eg should sorting be possible or be omitted?)
            const response = await API.getProjects(companyId, { ...tableParams, status });
            setTotal(response.total);

            if (response.projects) {
                setProjects(response.projects);
            }
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.Forbidden_403) {
                generalStore.setError(t("error.projectForbidden"), error);
                // } else if (error.statusCode === HttpStatusCode.Conflict_409 && error.response.type === "NO_REFRESH_TOKEN_FOUND") {
                //     generalStore.setError(t("error.projectForbidden"), error);
            } else {
                generalStore.setError(t("error.loadProjects"), error);
            }
        } finally {
            generalStore.isLoading = false;

            // TPAPORTAL-1289 We always want empty state after init
            setIsInitialized(true);
        }
    }, [companyId, status, tableParams]);

    React.useEffect(() => {
        reloadProjects();
    }, [reloadProjects]);

    return {
        isInitialized,
        projects,
        getProjectName: (projectId: string) => {
            return projects.find(project => project.id === projectId)?.name ?? "";
        },
        total,
        reloadProjects,
    };
};
