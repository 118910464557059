import { t } from "../../i18n/util";
import { UnpaidAccountPayment } from "../../network/APITypes";
import { formatDate } from "../../util/helpers";
import { InfoButton } from "../ui/Primitives";

interface Props {
    lastCompletedPayment: UnpaidAccountPayment;
}

export const LastCompletedUnpaidAccountPayment = ({ lastCompletedPayment }: Props) => {
    const title = t("screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment", {
        date: formatDate(lastCompletedPayment.date),
    });

    return <InfoButton title={title} color="primary" style={{ padding: 0, marginLeft: 4 }} />;
};
