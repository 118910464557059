import moment from "moment";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { t } from "../../../../i18n/util";
import { companiesStore } from "../../../../stores/CompaniesStore";
import { useRecordTypes } from "../../../hooks/useRecordTypes";
import { useTableFilters } from "../../../hooks/useTableFilters";
import { SecondaryText } from "../../../ui/Primitives";
import {
    DATE_RANGE_FILTER_TYPE,
    DROPDOWN_FILTER_TYPE,
    DateRange,
    NUMBER_RANGE_FILTER_TYPE,
} from "../../../ui/filter/types";
import { accountingStore } from "../../AccountingStore";
import { AssignInvoicesToBankAccountTransactionsState, BankConnectionAndAccount } from "./types";

const module = accountingStore.module;

export const useCombinedTableFilters = ({
    companyId,
    periodId,
    subsidiaryId,
}: {
    companyId: string | undefined;
    periodId: string | undefined;
    subsidiaryId: string | undefined;
}) => {
    const initialDateRange = useMemo<DateRange | null>(() => {
        const period = accountingStore.selectedPeriod;
        if (period) {
            const from = new Date(period.periodStart);
            const to = new Date(period.periodEnd);
            return { from, to };
        }
        const from = moment().startOf("month").toDate();
        const to = moment().endOf("month").toDate();
        return { from, to };
    }, []);

    const bankConnections = companiesStore.bankConnections.connections;
    const bankConnectionsAndAccounts: BankConnectionAndAccount[] = useMemo(() => {
        const bankConnectionsAndAccounts: BankConnectionAndAccount[] = [];

        bankConnections?.forEach(bankConnection => {
            bankConnection.accounts?.forEach(bankAccount => {
                bankConnectionsAndAccounts.push({
                    id: `${bankConnection.bankConnectionId}/${bankAccount.accountId}`,
                    bankConnection: bankConnection,
                    bankAccount: bankAccount,
                });
            });
        });

        return bankConnectionsAndAccounts;
    }, [bankConnections]);

    const location = useLocation<AssignInvoicesToBankAccountTransactionsState | undefined>();
    const { state } = location;

    let initialBankConnectionAndAccount;
    if (state?.bankConnectionId && state.bankAccountId) {
        initialBankConnectionAndAccount = bankConnectionsAndAccounts.find(
            b =>
                b.bankConnection.bankConnectionId === state.bankConnectionId &&
                b.bankAccount.accountId === state.bankAccountId,
        );
    }
    if (!initialBankConnectionAndAccount) {
        initialBankConnectionAndAccount = bankConnectionsAndAccounts[0];
    }

    const { recordTypes } = useRecordTypes({
        module,
        companyId,
        periodId,
        subsidiaryId,
    });
    const initialRecordType = recordTypes[0];

    const tableFilters = useTableFilters({
        filters: [
            {
                label: t("accounting.bankAccount.transactions.assignInvoices.filters.records.title"),
                category: "records",
                entries: [
                    {
                        name: "recordType",
                        label: t(
                            "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title",
                        ),
                        type: DROPDOWN_FILTER_TYPE,
                        required: true,
                        options: recordTypes.map(recordType => ({
                            value: recordType.id,
                            label: accountingStore.getRecordTypeName(recordType),
                        })),
                    },
                ],
            },
            {
                label: t("accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title"),
                category: "bankAccountTransactions",
                entries: [
                    {
                        name: "account",
                        label: t("common.bankConnection"),
                        type: DROPDOWN_FILTER_TYPE,
                        required: true,
                        options: bankConnectionsAndAccounts.map(b => ({
                            value: b.id,
                            label: (
                                <div>
                                    <div>{b.bankAccount.name}</div>
                                    <SecondaryText>{b.bankConnection.name}</SecondaryText>
                                </div>
                            ),
                        })),
                    },
                    {
                        name: "dateRange",
                        label: t("accounting.bankAccount.transactions.table.label.date"),
                        type: DATE_RANGE_FILTER_TYPE,
                        required: range => !range?.from || !range.to,
                        requiredText: _ => {
                            return t(
                                "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required",
                            );
                        },
                    },
                    {
                        name: "orderBy",
                        label: t("common.orderBy"),
                        type: DROPDOWN_FILTER_TYPE,
                        required: true,
                        options: [
                            {
                                value: "bankBookingDate",
                                label: t("accounting.bankAccount.transactions.table.label.date"),
                            },
                            { value: "amount", label: t("accounting.bankAccount.transactions.table.label.amount") },
                            { value: "purpose", label: t("accounting.bankAccount.transactions.table.label.text") },
                        ],
                    },
                    {
                        name: "amount",
                        label: t("accounting.bankAccount.transactions.table.label.amount"),
                        type: NUMBER_RANGE_FILTER_TYPE,
                    },
                ],
            },
        ],
        initialFilters: {
            recordType: { value: initialRecordType?.id },
            account: { value: initialBankConnectionAndAccount?.id },
            dateRange: { value: initialDateRange },
            orderBy: { value: "bankBookingDate" },
        },
        showAllButton: false,
        showAsDrawer: true,
    });

    // the initialRecordType changes when the subsidiary changes, so we need to update the current filter value
    const changeFilter = tableFilters.changeFilter;
    useEffect(() => {
        if (initialRecordType) {
            changeFilter("recordType", initialRecordType.id);
        }
    }, [changeFilter, initialRecordType]);

    const selectedBankConnectionAndAccountId = tableFilters.activeFilters.account?.value;
    const selectedRecordTypeId = tableFilters.activeFilters.recordType?.value as string | undefined;

    return {
        tableFilters,
        bankConnectionAndAccount: selectedBankConnectionAndAccountId
            ? bankConnectionsAndAccounts.find(item => item.id === selectedBankConnectionAndAccountId)
            : undefined,
        recordType: recordTypes.find(rt => rt.id === selectedRecordTypeId),
    };
};
