import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { ChatUserInfo, Role } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useChatUsers = (companyId?: string, role?: Role, colleagueId?: string) => {
    const [users, setUsers] = React.useState<ChatUserInfo[]>([]);

    // State, so we know whose chat users are currently loaded
    const [loadedColleagueId, setLoadedColleagueId] = React.useState<string>();

    const load = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const response = await API.getChatUsers({ companyId, role, colleagueId });
            setLoadedColleagueId(colleagueId);
            setUsers(response);
        } catch (error) {
            generalStore.setError(t("error.loadContacts"), error);
            setUsers([]);
        } finally {
            generalStore.isLoading = false;
        }
    }, [colleagueId, companyId, role]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { users, reload: load, loadedColleagueId };
};
