import { saveAs } from "file-saver";
import { observer } from "mobx-react";
import * as React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { POLLING_ACTIVE_CHAT_MESSAGES_MS } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Message } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { handleChatMessageError, replaceAll } from "../../../util/helpers";
import { getFullName } from "../../../util/user";
import { pushRoute, withQuery } from "../../app/router/history";
import { useChatMessages } from "../../hooks/useChatMessages";
import { useInterval } from "../../hooks/useInterval";
import { usePrivacyDialog } from "../../hooks/usePrivacyDialog";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { Chat, IChatContext } from "../../shared/Chat";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { TableRowButton } from "../../ui/Primitives";
import { SearchField } from "../../ui/SearchField";
import { SplitContainer } from "../../ui/SplitContainer";
import { MobileContext } from "../../util/MobileContext";
import { CONTENT_PADDING_HORIZONTAL, CONTENT_PADDING_HORIZONTAL_MOBILE } from "../../util/Theme";
import { useContactList } from "../ContactList";
import { SupportRoutes } from "../router/SupportRoutes";

export const SupportSite = observer(function SupportSite() {
    const isMobile = React.useContext(MobileContext);

    const contactsWidth = 300 + 2 * (isMobile ? CONTENT_PADDING_HORIZONTAL_MOBILE : CONTENT_PADDING_HORIZONTAL);

    const { messageId }: { messageId?: string } = useQueryParams();

    // TPAPORTAL-1515 persist input between mobile and desktop variants
    const [inputMessage, setInputMessage] = React.useState("");

    // Initiate contact list
    const contactList = useContactList();

    // Initiate message list
    const unreadChatMessages = contactList.selectedContact()?.unreadChatMessages;
    const reloadContactList = contactList.reload;
    const readingOwnChat = !contactList.selectedColleagueId;

    const successDialog = useSuccessDialog({
        title: t("dialog.archive.success.title"),
        message: <FormattedMessage id="dialog.archive.success.text" values={{ bmd: <b>{t("common.bmd")}</b> }} />,
    });

    const handleMessagesLoaded = React.useCallback(
        async (userId: string) => {
            // Current selected user has unread chat messages -> update badges if it's
            // our own chat because then all messages will be marked as read
            if (contactList.selectedContactId === userId && unreadChatMessages && readingOwnChat) {
                await companiesStore.selectedCompanyStore?.startPollingBadges();
                await reloadContactList();
            }
        },
        // We do not want unreadChatMessages change to trigger a new callback because
        // that would trigger a new message load in the useChatMessages hook
        // which is an unnecessary API call
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contactList.selectedContactId, reloadContactList],
    );

    const companyId = companiesStore.selectedCompanyId;
    const userId = contactList.selectedContactId;
    const colleagueId = contactList.selectedColleagueId;
    const chatContext: IChatContext = { companyId, userId, colleagueId };
    const messages = useChatMessages({
        companyId,
        userId,
        onLoad: handleMessagesLoaded,
        colleagueId,
        messageId,
    });

    // Initiate regular polling to see if new messages have arrived
    const poll = React.useCallback(async () => {
        const companyStore = companiesStore.selectedCompanyStore;
        if (!companyStore) {
            return;
        }
        const beforeOwnUnreadChatMessages = companyStore.badgeCounts.unreadChatMessages;
        await companyStore.startPollingBadges();
        const afterOwnUnreadChatMessages = companyStore.badgeCounts.unreadChatMessages;

        // Poll colleagues to get updated colleague badges
        const beforeColleagueUnreadChatMessages = contactList.selectedColleague?.unreadChatMessages ?? 0;
        const newColleagues = await contactList.reloadColleagues();
        // Cant' use selectedColleague().unreadChatMessages for after because setState() is not in same tick
        const afterColleagueUnreadChatMessages =
            newColleagues?.find(c => c.id === contactList.selectedColleagueId)?.unreadChatMessages ?? 0;

        if (readingOwnChat) {
            // Own chat -> only poll other stuff, if global count has changed
            if (beforeOwnUnreadChatMessages !== afterOwnUnreadChatMessages) {
                await messages.poll(newMessageCount => {
                    // If the message poll resulted in new messages -> subtract from total
                    // (I know we should treat the server as single source of truth, but in this
                    // case I think client side logic ok, because we just polled the companyBadges);
                    const newUnreadCount = (companyStore.badgeCounts.unreadChatMessages ?? 0) - newMessageCount;
                    companyStore.badgeCounts = {
                        ...companyStore.badgeCounts,
                        unreadChatMessages: Math.max(newUnreadCount, 0),
                    };
                });
                await contactList.reload();
            }
        } else {
            if (beforeColleagueUnreadChatMessages !== afterColleagueUnreadChatMessages) {
                // We are impersonating someone else -> poll contactList and messages if necessary
                await messages.poll();
                await contactList.reload();
            }
        }
    }, [contactList, messages, readingOwnChat]);
    useInterval(poll, POLLING_ACTIVE_CHAT_MESSAGES_MS);

    const mobileChatScreen = isMobile && !!contactList.selectedContactId;
    useHideSideBar(mobileChatScreen);

    const handleSend = async (message?: string, file?: File) => {
        if (!contactList.selectedContactId || !companiesStore.selectedCompanyId) {
            return false;
        }

        // Nothing to send
        if (!message && !file) {
            return false;
        }

        try {
            await API.postChatMessage({
                companyId: companiesStore.selectedCompanyId,
                userId: contactList.selectedContactId,
                colleagueId: contactList.selectedColleagueId,
                message: {
                    text: message,
                    file,
                },
            });
            await messages.reload();

            if (contactList.selectedColleagueId && unreadChatMessages) {
                // We are answering a colleagues chat -> this will mark
                // the messages as read
                await reloadContactList();
                await contactList.reloadColleagues();
            }
        } catch (error) {
            handleChatMessageError(getApiError(error));
            return false;
        }

        return true;
    };

    const handleArchiveSelection = async (
        selectedMessageIds: string[],
        companyId: string,
        subject: string,
        downloadPDF: boolean,
    ) => {
        try {
            generalStore.isLoading = true;

            const res = await API.putArchiveChatMessages({
                substituteId: contactList.selectedColleagueId ?? "",
                downloadPDF,
                payload: { messageIDs: selectedMessageIds, subject },
                companyId,
                userId: contactList.selectedContactId,
            });

            if (downloadPDF) {
                // Set a default filename
                let filename = `chat_protocol_${new Date().valueOf()}.pdf`;
                const disposition = res.headers.get("content-disposition");
                if (disposition) {
                    // If we have a disposition header, we can extract the filename
                    const entries = disposition.split("=");
                    if (entries.length === 2) {
                        filename = replaceAll(entries[1].trim(), '"', "");
                    }
                }

                const blob = await res.blob();
                saveAs(blob, filename);
            }

            successDialog.openDialog();
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.response.type === "CHAT_FORBIDDEN") {
                generalStore.setError(t("error.chatForbidden"));
            } else if (apiError?.response.type === "ARCHIVE_NOT_FOUND") {
                generalStore.setError(t("error.noArchive"));
            } else if (apiError?.response.type === "MULTIPLE_COMPANY_ARCHIVES") {
                generalStore.setError(t("error.multipleArchives"));
            } else {
                generalStore.setError(t("error.archiveFailed"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    };

    const handleMessageDownload = async (message: Message) => {
        if (!chatContext.companyId) {
            return;
        }

        try {
            await API.putDownloadChatAttachment({
                companyId: chatContext.companyId,
                userId: chatContext.userId,
                substituteId: chatContext.colleagueId,
                attachmentIds: [message.id],
            });
        } catch (error) {
            generalStore.setError(t("error.download"));
        }
    };

    const privacyDialog = usePrivacyDialog(contactList.reload, contactList.selectedContact());

    // You can toggle private chats only if there is at least one message, you are a partner and not impersonating someone else
    const canToggleChatPrivacy =
        messages.messages.length > 0 && authStore.canChatSecret && !contactList.selectedColleagueId;

    const chatProps: React.ComponentProps<typeof Chat> = {
        messages: messages.messages,
        loadOlder: () => messages.loadAdditional(true),
        loadNewer: () => messages.loadAdditional(false),
        onSend: handleSend,
        disableInput: !contactList.selectedContactId || (privacyDialog.isPrivateChat && !canToggleChatPrivacy),
        initialInputMessage: inputMessage,
        onChangeInputMessage: setInputMessage,
        onArchiveSelection:
            authStore.isTpa && companiesStore.selectedCompany?.archiveEnabled ? handleArchiveSelection : undefined,
        onTogglePrivate: canToggleChatPrivacy ? privacyDialog.open : undefined,
        isActiveChat: contactList.isActiveChat,
        isPrivateChat: privacyDialog.isPrivateChat,
        onMessageDownload: handleMessageDownload,
    };

    const handleEnterSearch = (value: string) => {
        const query: Record<string, unknown> = {
            search: value,
        };

        if (contactList.selectedColleagueId) {
            query.selectedColleagueId = contactList.selectedColleagueId;
            query.selectedColleagueName = getFullName(contactList.selectedColleague);
        }
        pushRoute(withQuery(SupportRoutes.SEARCH, query));
    };

    const chatSearch = (
        <SearchField
            data-id="chat_search"
            onChange={() => {
                // DO NOTHING - prop is required
            }}
            onEnter={handleEnterSearch}
            placeholder={t("search.placeholder.search")}
            style={{
                width: isMobile ? "100%" : `calc(100% - ${contactsWidth}px + ${CONTENT_PADDING_HORIZONTAL}px)`,
                paddingBottom: isMobile ? 16 : undefined,
                paddingTop: isMobile && contactList.selectedColleagueId ? 16 : undefined,
            }}
        />
    );

    const searchNavbarProps = isMobile
        ? {
              tabs: chatSearch,
          }
        : {
              cancelComponent: chatSearch,
              onCancel: () => {
                  // DO NOTING - needed to show cancelComponent
              },
          };

    return (
        <>
            <NavBarBack
                title={t(mobileChatScreen ? "sidebar.list.faceToFace" : "sidebar.faceToFace.title")}
                onBack={
                    mobileChatScreen
                        ? () => {
                              contactList.setSelectedContactId("");
                          }
                        : undefined
                }
                backLabel={t("common.back")}
                {...searchNavbarProps}
                navBarInfo={
                    contactList.selectedColleagueId ? (
                        <>
                            <div style={{ flex: 1 }}>
                                <FormattedHTMLMessage
                                    id={isMobile ? "support.substitute.warning.mobile" : "support.substitute.warning"}
                                    values={{ name: getFullName(contactList.selectedColleague) }}
                                />
                            </div>
                            <TableRowButton style={{ color: "white" }} onClick={contactList.clearSelectedColleague}>
                                {t(isMobile ? "support.substitute.back.mobile" : "support.substitute.back")}
                            </TableRowButton>
                        </>
                    ) : undefined
                }
            />
            <CenteredContent ticketBackground={!isMobile}>
                {!isMobile && (
                    <SplitContainer leftWidth={contactsWidth} leftChildren={contactList.contactList}>
                        <Chat {...chatProps} />
                    </SplitContainer>
                )}
                {/* mobile design with contacts and chat on separate page */}
                {isMobile && !contactList.selectedContactId && contactList.contactList}
                {mobileChatScreen && <Chat {...chatProps} mobileFullScreen />}
            </CenteredContent>
            {successDialog.dialog}
            {privacyDialog.dialog}
        </>
    );
});
