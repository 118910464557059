import { Menu, MenuProps, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { GLOBAL_FEATURES } from "../../features";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { ModuleStore } from "../../stores/ModuleStore";
import { hrStore } from "../hr/HrStore";
import { TpaMenuItem } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";

const StyledMenuAddress = withStyles({
    paper: {
        marginTop: 4,
        width: 208,
    },
})((props: MenuProps) => (
    <Menu
        elevation={20}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

export const NavBarChooseSubsidiary = observer(function NavBarChooseSubsidiary({
    moduleStore,
    useEmployeeSubsidiaries,
    redirectOnChange,
    readOnly,
    style,
}: {
    moduleStore: ModuleStore;
    useEmployeeSubsidiaries?: boolean;
    redirectOnChange?: boolean;
    readOnly?: boolean;
    style?: React.CSSProperties;
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMobile = React.useContext(MobileContext);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectSubsidiary = async (subsidiaryId: string) => {
        if (!useEmployeeSubsidiaries) {
            try {
                generalStore.isLoading = true;
                await moduleStore.selectSubsidiaryById(subsidiaryId, redirectOnChange !== false);
            } catch (error) {
                generalStore.setError(t("error.loadSubsidiary"), error);
            } finally {
                generalStore.isLoading = false;
            }
        } else {
            // We are looking at employee lists -> just change the id and we are fine.
            // No need to load records types, etc.
            hrStore.selectedSubsidiaryId = subsidiaryId;
        }

        setAnchorEl(null);
    };

    const subsidiaries = useEmployeeSubsidiaries ? hrStore.employeeSubsidiaries : moduleStore.subsidiaries;
    const selectedSubsidiaryId = moduleStore.selectedSubsidiaryId;
    const selectedSubsidiary = moduleStore.selectedSubsidiary();

    const disableButton = !!readOnly || subsidiaries.length === 1;

    const iconColor = !isMobile && disableButton ? customColors.greyButton : customColors.primaryColor;

    if (subsidiaries.length === 0 || (subsidiaries.length === 1 && !GLOBAL_FEATURES.singleSubsidiarySelection)) {
        return null;
    }

    return (
        <>
            <div
                data-id="open_menu"
                style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: disableButton && !isMobile ? undefined : "pointer",
                    ...(isMobile
                        ? {}
                        : {
                              maxWidth: disableButton ? undefined : 200,
                          }),
                    ...style,
                }}
                onClick={disableButton && !isMobile ? undefined : handleOpen}
            >
                <div>
                    <Icon name="subsidiary" style={{ display: "block", color: iconColor }} />
                </div>
                {!isMobile && (
                    <span
                        style={{
                            color: customColors.body2Dark,
                            marginLeft: 8,
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            fontSize: 16,
                        }}
                    >
                        {selectedSubsidiary?.name}
                    </span>
                )}
            </div>
            <StyledMenuAddress
                data-id="lock_menu"
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
            >
                {subsidiaries.map((subsidiary, index) => (
                    <TpaMenuItem
                        data-id={`menu_item_subsidiary_${index}`}
                        key={subsidiary.id}
                        selected={subsidiary.id === selectedSubsidiaryId}
                        onClick={disableButton ? undefined : () => selectSubsidiary(subsidiary.id)}
                        disabled={disableButton}
                    >
                        {subsidiary.name}
                    </TpaMenuItem>
                ))}
            </StyledMenuAddress>
        </>
    );
});
