import isNil from "lodash/isNil";
import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { CompanyReleaseStepsEnum, PatchCompanyPayload, RecordType, SubsidiaryType } from "../../network/APITypes";
import { Module, PeriodType } from "../../types/models";
import { debug } from "../../util/debug";
import { getRequiredReportReleasers } from "../../util/reports";
import { useCompany } from "./useCompany";
import { useCompanyBranches } from "./useCompanyBranches";
import { useCompanyRecordTypes } from "./useCompanyRecordTypes";
import { useCompanySubsidiaries } from "./useSubsidiaries";

export interface ISetupSubsidiary {
    id?: string;

    // branch data
    name: string;
    type?: SubsidiaryType;
    typeId?: string;

    // configured record types
    recordTypes: RecordType[];

    // needed for form
    duplicate?: boolean;
    reactKey?: number;
    touched?: boolean;
}

export interface IConfigDueDate {
    dueDate: number;
    noDueDate: boolean;
}

// Contains all data needed to configure a company
export function useModuleConfig(companyId: string | undefined, module: Module, canEdit: boolean) {
    const { company } = useCompany({ companyId });

    const DEFAULT_DUE = 5;

    const [moduleActive, setModuleActive] = React.useState(true);
    const [hasAccountingResults, setHasAccountingResults] = React.useState(false);
    const [hasHRResults, setHasHRResults] = React.useState(false);
    const [requiredReportReleasers, setRequiredReportReleasers] = React.useState(1);
    const [hasGlobalReports, setHasGlobalReports] = React.useState(false);
    const [sendPayroll, setSendPayroll] = React.useState(true);
    const [releaseSteps, setReleaseSteps] = React.useState<CompanyReleaseStepsEnum>("oneStep");
    const [periodType, setPeriodType] = React.useState<PeriodType>("month");
    const [accountingByCustomer, setAccountingByCustomer] = React.useState(true);
    const [dueTransfer, setDueTransfer] = React.useState<IConfigDueDate>({ dueDate: DEFAULT_DUE, noDueDate: false });
    const [dueReports, setDueReports] = React.useState<IConfigDueDate>({ dueDate: DEFAULT_DUE, noDueDate: false });
    const [dueReportRelease, setDueReportRelease] = React.useState<IConfigDueDate>({
        dueDate: DEFAULT_DUE,
        noDueDate: false,
    });
    const companyRecordTypes = useCompanyRecordTypes(companyId, module);
    const { subsidiaries: loadedSubsidiaries } = useCompanySubsidiaries({ companyId, module, withRecordTypes: true });
    const [subsidiaries, setSubsidiaries] = React.useState<ISetupSubsidiary[]>([]);
    const [hrRelativeDueDates, setHrRelativeDueDates] = React.useState(false);
    const [canChat, setCanChat] = React.useState(false);
    const [canUpload, setCanUpload] = React.useState(false);
    const [hasBankAccountTransactionInvoices, setHasBankAccountTransactionInvoices] = React.useState(false);

    const branches = useCompanyBranches(companyId, module, canEdit);

    // Prefill subsidiaries with loaded subsidiaries
    useDeepCompareEffect(() => {
        let setupSubsidiaries: ISetupSubsidiary[] = [];

        if (loadedSubsidiaries.length > 0) {
            // Either use preloaded recordTypes or default (= all selected)
            setupSubsidiaries = loadedSubsidiaries.map(subsidiary => ({
                ...subsidiary,
                recordTypes: subsidiary.recordTypes ?? companyRecordTypes.map(b => b),
            }));
        }

        if (branches.fixed) {
            // Find all wageCompanies that are not in setup yet
            const notAddedWageCompanies = branches.branches.filter(
                branch =>
                    setupSubsidiaries.findIndex(
                        subsidiary =>
                            subsidiary.name === branch.name &&
                            subsidiary.type === branch.type &&
                            subsidiary.typeId === branch.typeId,
                    ) < 0,
            );

            // Add them to existing subsidiaries
            setupSubsidiaries = setupSubsidiaries.concat(
                notAddedWageCompanies.map(wageCompany => ({
                    ...wageCompany,
                    reactKey: Math.random(),
                    recordTypes: companyRecordTypes.map(b => b),
                })),
            );

            // TODO: Should non wageCompany subsidiaries be deleted?
        }

        setSubsidiaries(setupSubsidiaries);
    }, [companyRecordTypes, loadedSubsidiaries, branches]);

    // Prefill config with loaded company
    React.useEffect(() => {
        const numberToDueDate = (due?: number) => ({ dueDate: !due ? DEFAULT_DUE : due, noDueDate: due === 0 });

        if (company) {
            switch (module) {
                case "accounting":
                    // A company can have a module disabled by TPA layer -> that's why we have two values
                    // accountingEnabled -> cannot be enabled/disabled in UI
                    // hasAccounting -> toggled in setting UI
                    setModuleActive(
                        !!company.accountingEnabled && (isNil(company.hasAccounting) || company.hasAccounting),
                    );
                    setHasAccountingResults(company.hasAccountingResults === true);

                    debug.log(
                        "### init accounting",
                        company.hasAccounting,
                        branches.branches.length,
                        canEdit,
                        companyRecordTypes.length,
                    );

                    // No record types -> no accounting
                    if (companyRecordTypes.length === 0) {
                        setModuleActive(false);
                    }

                    setRequiredReportReleasers(getRequiredReportReleasers(company, module));
                    setHasGlobalReports(
                        isNil(company.hasGlobalAccountingReports) || company.hasGlobalAccountingReports,
                    );

                    if (company.bookingPeriodType) {
                        setPeriodType(company.bookingPeriodType);
                    }

                    setAccountingByCustomer(isNil(company.accountingByCustomer) || company.accountingByCustomer);
                    setDueTransfer(numberToDueDate(company.dueAccounting));
                    setDueReports(numberToDueDate(company.dueAccountingReports));
                    setDueReportRelease(numberToDueDate(company.dueAccountingReportsRelease));
                    setHasBankAccountTransactionInvoices(company.hasBankAccountTransactionInvoices === true);
                    break;

                case "hr":
                    setModuleActive(!!company.hrEnabled && (isNil(company.hasHR) || company.hasHR));
                    setHasHRResults(company.hasHRResults === true);

                    debug.log(
                        "### init HR",
                        company.hasHR,
                        branches.branches.length,
                        canEdit,
                        companyRecordTypes.length,
                    );

                    // No branches -> no HR
                    if (branches.branches.length === 0 && canEdit) {
                        setModuleActive(false);
                    }

                    setRequiredReportReleasers(getRequiredReportReleasers(company, module));
                    setHasGlobalReports(isNil(company.hasGlobalHRReports) || company.hasGlobalHRReports);

                    setDueTransfer(numberToDueDate(company.dueHR));
                    setDueReports(numberToDueDate(company.dueHRReports));
                    setDueReportRelease(numberToDueDate(company.dueHRReportsRelease));
                    setSendPayroll(!!company.sendPayroll);
                    setReleaseSteps(company.releaseSteps ?? "oneStep");
                    setHrRelativeDueDates(!!company.hrRelativeDueDates);
                    setCanChat(!!company.staffCanChat);
                    setCanUpload(!!company.staffCanUpload);
                    break;
            }
        }
    }, [canEdit, branches.branches.length, company, module, companyRecordTypes.length]);

    const setModuleActiveWithEffects = React.useCallback(
        (active: boolean) => {
            setModuleActive(active);

            if (active && module === "accounting") {
                // CONNECT-342: enable accounting by customer by default when accounting is enabled
                setAccountingByCustomer(true);
            }
        },
        [module],
    );

    // TPAPORTAL-1508: Take the first defined subsidiary type to define the type of all HR subsidiaries
    const subsidiaryType = subsidiaries.length > 0 ? subsidiaries.find(s => !!s.type)?.type : undefined;

    // Write back data after configuration
    const patchCompanyPayload: PatchCompanyPayload =
        module === "accounting"
            ? {
                  accountingByCustomer: accountingByCustomer,
                  bookingPeriodType: periodType,
                  dueAccounting: dueTransfer.noDueDate ? 0 : dueTransfer.dueDate,
                  dueAccountingReports: dueReports.noDueDate ? 0 : dueReports.dueDate,
                  dueAccountingReportsRelease: dueReportRelease.noDueDate ? 0 : dueReportRelease.dueDate,
                  hasAccounting: moduleActive,
                  requiredAccountingReportReleasers: requiredReportReleasers,
                  hasGlobalAccountingReports: hasGlobalReports,
                  status: company?.status === "inactive" ? "accountingSetupDone" : undefined,
                  hasAccountingResults: hasAccountingResults,
                  hasBankAccountTransactionInvoices,
              }
            : {
                  dueHR: dueTransfer.noDueDate ? 0 : dueTransfer.dueDate,
                  hrRelativeDueDates,
                  dueHRReports: dueReports.noDueDate ? 0 : dueReports.dueDate,
                  dueHRReportsRelease: dueReportRelease.noDueDate ? 0 : dueReportRelease.dueDate,
                  hasHR: moduleActive,
                  requiredHRReportReleasers: requiredReportReleasers,
                  hasGlobalHRReports: hasGlobalReports,
                  hasHRResults: hasHRResults,
                  sendPayroll,
                  releaseSteps,
                  status: company?.status === "accountingSetupDone" ? "hrSetupDone" : undefined,
                  staffCanChat: canChat,
                  staffCanUpload: canUpload,
                  relevantSubsidiaryType: subsidiaryType,
              };

    return {
        company,
        module,
        moduleActive,
        setModuleActive: setModuleActiveWithEffects,
        hasAccountingResults,
        setHasAccountingResults,
        hasHRResults,
        setHasHRResults,
        requiredReportReleasers,
        setRequiredReportReleasers,
        hasGlobalReports,
        setHasGlobalReports,
        sendPayroll,
        setSendPayroll,
        periodType,
        setPeriodType,
        accountingByCustomer,
        setAccountingByCustomer,
        dueTransfer,
        setDueTransfer,
        dueReports,
        setDueReports,
        dueReportRelease,
        setDueReportRelease,
        subsidiaries,
        setSubsidiaries,
        subsidiaryType,
        releaseSteps,
        setReleaseSteps,
        hrRelativeDueDates,
        setHrHasRelativeDueDates: setHrRelativeDueDates,
        companyRecordTypes,
        branches,
        patchCompanyPayload,
        canEdit,
        canChat,
        setCanChat,
        canUpload,
        setCanUpload,
        hasBankAccountTransactionInvoices,
        setHasBankAccountTransactionInvoices,
    };
}

export type ModuleConfig = ReturnType<typeof useModuleConfig>;
