import { Button, ButtonProps, withStyles } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { IIconNames, Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

const StyledButton = withStyles({
    root: {
        textTransform: "none",
        marginLeft: 16,
        fontWeight: "normal",
        fontSize: 12,
        padding: "10px 16px",
    },
})(Button);

export const BulkActionButton = ({
    iconName,
    onClick,
    children,
    ...props
}: ButtonProps & {
    iconName?: IIconNames;
    onClick?: () => void;
    children?: React.ReactNode;
}) => (
    <StyledButton
        onClick={onClick}
        startIcon={
            iconName ? (
                <Icon style={{ color: customColors.primaryColor, opacity: props.disabled ? 0.5 : 1 }} name={iconName} />
            ) : null
        }
        {...props}
    >
        {children}
    </StyledButton>
);

export const BulkDownloadButton = ({ onClick }: { onClick?: () => void }) => (
    <BulkActionButton onClick={onClick} iconName="download" disabled={!onClick}>
        {t("menu.download")}
    </BulkActionButton>
);

export const BulkDeleteButton = ({ onClick }: { onClick?: () => void }) => (
    <BulkActionButton onClick={onClick} iconName="archive" disabled={!onClick}>
        {t("menu.delete")}
    </BulkActionButton>
);

export const BulkDraftButton = ({ onClick, disabled }: { onClick?: () => void; disabled: boolean }) => (
    <BulkActionButton onClick={onClick} iconName="checkmark" disabled={!onClick || disabled}>
        {t("menu.draft")}
    </BulkActionButton>
);

export const BulkMoveButton = ({ onClick }: { onClick?: () => void }) => (
    <BulkActionButton onClick={onClick} iconName="kategorieSw" disabled={!onClick}>
        {t("menu.move")}
    </BulkActionButton>
);

export const BulkReleaseButton = ({ onClick }: { onClick?: () => void }) => (
    <BulkActionButton onClick={onClick} iconName="checkmark" disabled={!onClick}>
        {t("config.users.release")}
    </BulkActionButton>
);

export const BulkRejectButton = ({ onClick }: { onClick?: () => void }) => (
    <BulkActionButton onClick={onClick} iconName="closeSmall" disabled={!onClick}>
        {t("config.users.reject")}
    </BulkActionButton>
);

export const BulkAnswerButton = ({ onClick }: { onClick?: () => void }) => (
    <BulkActionButton onClick={onClick} iconName="send" disabled={!onClick}>
        {t("menu.answer")}
    </BulkActionButton>
);

export const BulkCloseTicketButton = ({ onClick }: { onClick?: () => void }) => (
    <BulkActionButton onClick={onClick} iconName="pin" disabled={!onClick}>
        {t("menu.markAsDone")}
    </BulkActionButton>
);

export const BulkAssignButton = ({ onClick }: { onClick?: () => void }) => (
    <BulkActionButton onClick={onClick} iconName="assign" disabled={!onClick}>
        {t("menu.assign")}
    </BulkActionButton>
);
