import { TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { FieldInputProps, FormikProps, getIn } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";

type IProps = Omit<AutocompleteProps<string, false, false, false>, "onChange"> &
    Omit<TextFieldProps, "onChange"> & {
        errorMessage?: string;
        field: FieldInputProps<string>;
        isTouched?: boolean;
        options: string[];
        hideOptional?: boolean;
        form: FormikProps<unknown>;
        "data-id": string;
        onChange?: (event: React.ChangeEvent<{} | HTMLInputElement>) => void;
    };

export const CustomAutoComplete = ({
    options,
    style,
    label,
    type,
    required,
    field,
    form,
    hideOptional,
    onChange,
    onBlur,
    disabled,
    "aria-label": ariaLabel,
    "data-id": dataId,
}: IProps) => {
    const fieldError = getIn(form.errors, field.name) as React.ReactNode;
    const showError = (getIn(form.touched, field.name) as boolean) && !!fieldError;

    return (
        <div style={style}>
            <Autocomplete
                value={field.value}
                onChange={(event, value) => {
                    if (value) {
                        form.setFieldValue(field.name, value);
                    } else {
                        form.setFieldValue(field.name, null);
                    }

                    if (onChange) {
                        onChange(event);
                    }
                }}
                disabled={disabled}
                options={options}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        aria-label={ariaLabel}
                        // inputProps={{ "data-id": dataId }}
                        error={showError}
                        helperText={!required && !hideOptional ? t("common.optional") : undefined}
                        onChange={event => {
                            if (onChange) {
                                onChange(event);
                            }
                            field.onChange(event);
                        }}
                        onBlur={event => {
                            if (onBlur) {
                                onBlur(event);
                            }
                            field.onBlur(event);
                        }}
                    />
                )}
            />
            <span
                style={{
                    color: "#f00",
                    display: "block",
                    minHeight: 18,
                    width: "100%",
                    marginTop: 4,
                    marginBottom: 2,
                    fontSize: 12,
                }}
            >
                {showError && fieldError}
            </span>
        </div>
    );
};
