import { Button } from "@material-ui/core";
import * as React from "react";
import { t } from "../../../i18n/util";
import { Status, statusColors } from "../../../stores/Status";
import { pushRoute } from "../../app/router/history";
import { CenteredContent } from "../../ui/CenteredContent";
import { RouterLink } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { StatusBar } from "../../ui/StatusBar";
import { IIconNames, Icon } from "../../util/Icon";
import { BOX_SHADOW_LIGHT, customColors } from "../../util/Theme";
import { AdvisorUploadsNavBar } from "../AdvisorUploadsNavBar";
import { AdvisorRoutes } from "../router/AdvisorRoutes";

// TODO: Proper implementation as table. Currently just adapted LinkCard so we have some link functionality
// Design not approved yet, so no need to do proper implementation for now.
const Entry = (props: { status: Status; iconName: IIconNames; title: string; numFiles: number; route: string }) => {
    return (
        <RouterLink to={props.route}>
            <div
                style={{
                    display: "flex",
                    backgroundColor: customColors.white,
                    alignItems: "center",
                    borderRadius: 4,
                    boxShadow: BOX_SHADOW_LIGHT,
                    cursor: "pointer",
                }}
            >
                <StatusBar minHeight={64} marginBottom={0} status={props.status} />
                <div style={{ color: statusColors[props.status].color, padding: "16px 16px" }}>
                    <Icon name={props.iconName} />
                </div>
                <p className="body2" style={{ flexGrow: 1, padding: "16px 16px", fontWeight: "bold" }}>
                    {props.title}
                </p>
            </div>
        </RouterLink>
    );
};

const UploadButton = () => (
    <div style={{ textAlign: "right", marginBottom: 32 }}>
        <Button
            data-id="advisor_uploads_button"
            variant="contained"
            color="primary"
            onClick={() => {
                pushRoute(AdvisorRoutes.UPLOADS.UPLOAD);
            }}
        >
            {t("common.uploadFiles")}
        </Button>
    </div>
);

export const AdvisorUploadsSite = () => {
    return (
        <>
            <AdvisorUploadsNavBar />
            <CenteredContent>
                <SiteContent>
                    <UploadButton />
                    <Entry
                        title={t("advisor.uploads.uploadReports.title")}
                        status="open"
                        iconName="accounting"
                        numFiles={0}
                        route={AdvisorRoutes.UPLOADS.UPLOAD}
                    />
                </SiteContent>
            </CenteredContent>
        </>
    );
};
