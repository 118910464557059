import * as React from "react";

export const useRerenderKey = () => {
    const [rerenderKey, setRerenderKey] = React.useState(0);
    const rerender = () => {
        setRerenderKey(rerenderKey + 1);
    };
    return {
        rerender,
        rerenderKey,
    };
};
