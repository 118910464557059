import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { customColors } from "../util/Theme";
import { CustomDialog, CustomDialogContent } from "./CustomDialog";
import { TpaWhiteButton } from "./Primitives";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";

export const ConfirmationDialog = (props: {
    title: string;
    message: React.ReactNode;
    open: boolean;

    confirmLabel?: string;
    onConfirm?: () => void;

    cancelLabel?: string;
    onCancel?: () => void;

    secondaryActionLabel?: string;
    onSecondaryAction?: () => void;

    checkBox?: {
        checked: boolean;
        label: string;
        onCheck: (checked: boolean) => void;
    };

    // Adds a close X icon
    onClose?: () => void;

    danger?: boolean;
}) => {
    return (
        <CustomDialog open={props.open} onClose={props.onClose} showCloseIcon={!!props.onClose}>
            <CustomDialogContent style={{ paddingTop: props.onClose ? 0 : 42 }}>
                {props.title ? <h1>{props.title}</h1> : null}
                <div className="body1" style={{ marginTop: props.title ? 24 : undefined }}>
                    {props.message}
                </div>
                {props.checkBox && (
                    <FormControlLabel
                        style={{ marginTop: 16 }}
                        checked={props.checkBox.checked}
                        control={<Checkbox color="primary" />}
                        label={props.checkBox.label}
                        onChange={(_, checked) => props.checkBox?.onCheck(checked)}
                    />
                )}
                <ResponsiveButtonContainer style={{ marginTop: 24 }}>
                    {props.onCancel && (
                        <TpaWhiteButton onClick={props.onCancel}>
                            {props.cancelLabel ?? t("common.cancel")}
                        </TpaWhiteButton>
                    )}
                    {props.onSecondaryAction && props.secondaryActionLabel && (
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={props.onSecondaryAction}
                            data-id="button_secondary_action"
                        >
                            {props.secondaryActionLabel}
                        </Button>
                    )}
                    {props.onConfirm && (
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                backgroundColor: props.danger ? customColors.error : undefined,
                                color: props.danger ? "white" : undefined,
                            }}
                            fullWidth={!!(props.onSecondaryAction && props.secondaryActionLabel)}
                            onClick={props.onConfirm}
                            data-id="button_confirm"
                        >
                            {props.confirmLabel ?? t("common.ok")}
                        </Button>
                    )}
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
};
