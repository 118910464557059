import React, { useCallback } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { AppNotification, NotificationModule, NotificationType } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useNotifications = ({
    companyId,
    type,
    module,
    read,
    offset = 0,
    limit = 50,
}: {
    companyId?: string;
    type?: NotificationType;
    module?: NotificationModule;
    read?: boolean;
    offset?: number;
    limit?: number;
}) => {
    const [notifications, setNotifications] = React.useState<AppNotification[]>();
    const [total, setTotal] = React.useState(0);
    const count = notifications?.length ?? 0;

    const loadNotifications = useCallback(
        async (refresh?: boolean) => {
            try {
                generalStore.isLoading = true;

                const response = await API.getNotifications({
                    companyId,
                    type,
                    module,
                    state: read ? "all" : "unread",
                    pagination: {
                        offset,
                        limit: refresh && total > 0 ? total : limit,
                    },
                });

                if (response.notifications) {
                    setNotifications(response.notifications);
                    setTotal(response.total);
                }
            } catch (error) {
                generalStore.setError(t("error.notificationCenter.load"));
            } finally {
                generalStore.isLoading = false;
            }
        },
        [companyId, limit, module, offset, read, total, type],
    );

    const loadAdditionalNotifications = useCallback(async () => {
        try {
            generalStore.isLoading = true;
            // Only load more if there are more notifications to load
            if (notifications && count < total) {
                const response = await API.getNotifications({
                    companyId,
                    type,
                    module,
                    state: read ? "all" : "unread",
                    pagination: { limit, offset: count },
                });

                if (response.notifications) {
                    const newNotifications = notifications.concat(response.notifications);
                    setNotifications(newNotifications);
                    setTotal(response.total);
                }
            }
        } catch (error) {
            generalStore.setError(t("error.notificationCenter.load"));
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, count, limit, module, notifications, read, total, type]);

    const markNotificationAsRead = useCallback(
        async (notificationId: string) => {
            try {
                generalStore.isLoading = true;
                await API.putMarkNotificationAsRead({ notificationId });
                await loadNotifications(true);
            } catch (error) {
                generalStore.setError(t("error.notificationCenter.markAsRead"));
            } finally {
                generalStore.isLoading = false;
            }
        },
        [loadNotifications],
    );

    const markAllNotificationsAsRead = useCallback(async () => {
        try {
            generalStore.isLoading = true;
            await API.putMarkAllNotificationAsRead();
            await loadNotifications(true);
        } catch (error) {
            generalStore.setError(t("error.notificationCenter.markAllAsRead"));
        } finally {
            generalStore.isLoading = false;
        }
    }, [loadNotifications]);

    React.useEffect(() => {
        loadNotifications();
    }, [loadNotifications]);

    return {
        notifications: notifications,
        markNotificationAsRead,
        markAllNotificationsAsRead,
        loadAdditionalNotifications,
        hasMore: count < total,
    };
};
