import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../../app/router/Routes";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { UserPermissionsSite } from "../../shared/sites/UserPermissionsSite";
import { UserRolesSite } from "../../shared/sites/UserRolesSite";
import { CenteredContent } from "../../ui/CenteredContent";
import { CompanyOverview } from "../../ui/CompanyOverview";
import { AdvisorCompanyOverviewNavBar } from "../AdvisorCompanyOverviewNavBar";
import { AdvisorCompaniesInactiveSite } from "../sites/AdvisorCompaniesInactiveSite";
import { AdvisorConfigAccountingSite } from "../sites/AdvisorConfigAccountingSite";
import { AdvisorConfigBulkDataSite } from "../sites/AdvisorConfigBulkData";
import { AdvisorConfigBulkFinishSite } from "../sites/AdvisorConfigBulkFinish";
import { AdvisorConfigBulkTermsOfUseSite } from "../sites/AdvisorConfigBulkTermsOfUse";
import { AdvisorConfigCompanyDataSite } from "../sites/AdvisorConfigCompanyDataSite";
import { AdvisorConfigHrSite } from "../sites/AdvisorConfigHrSite";
import { AdvisorConfigKPIsSite } from "../sites/AdvisorConfigKPIsSite";
import { AdvisorConfigNewUsersSite } from "../sites/AdvisorConfigNewUsersSite";
import { AdvisorConfigOverviewSite } from "../sites/AdvisorConfigOverviewSite";
import { AdvisorConfigTermsOfUseSite } from "../sites/AdvisorConfigTermsOfUseSite";
import { AdvisorConfigUsersSite } from "../sites/AdvisorConfigUsersSite";
import { AdvisorUploadsDocumentsSite } from "../sites/AdvisorUploadsDocumentsSite";
import { AdvisorUploadsSite } from "../sites/AdvisorUploadsSite";
import { AdvisorUploadsUploadSite } from "../sites/AdvisorUploadsUploadSite";
import { AdvisorRoutes } from "./AdvisorRoutes";

const CompanyContainer = () => (
    <>
        <AdvisorCompanyOverviewNavBar />
        <CenteredContent>
            <Route path={AdvisorRoutes.COMPANIES.ACTIVE}>
                <CompanyOverview />
            </Route>
            <Route path={AdvisorRoutes.COMPANIES.INACTIVE}>
                <AdvisorCompaniesInactiveSite />
            </Route>
        </CenteredContent>
    </>
);

export const AdvisorRouter = () => (
    <Switch>
        <Route exact path={Routes.ADVISOR}>
            <Redirect to={AdvisorRoutes.COMPANIES.ACTIVE} />
        </Route>

        <Route exact path={AdvisorRoutes.UPLOADS.ROOT}>
            <AdvisorUploadsSite />
        </Route>
        <Route path={AdvisorRoutes.UPLOADS.UPLOAD}>
            <AdvisorUploadsUploadSite />
        </Route>
        <Route path={AdvisorRoutes.UPLOADS.DOCUMENTS}>
            <AdvisorUploadsDocumentsSite />
        </Route>

        <Route exact path={AdvisorRoutes.COMPANIES.ROOT}>
            <Redirect to={AdvisorRoutes.COMPANIES.ACTIVE} />
        </Route>
        <Route path={AdvisorRoutes.COMPANIES.ROOT}>
            <CompanyContainer />
        </Route>

        <Route exact path={AdvisorRoutes.CONFIG.ROOT}>
            <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />
        </Route>
        <Route exact path={AdvisorRoutes.CONFIG.BULK.DATA}>
            <AdvisorConfigBulkDataSite />
        </Route>
        <Route exact path={AdvisorRoutes.CONFIG.BULK.TERMS_OF_USE}>
            <AdvisorConfigBulkTermsOfUseSite />
        </Route>
        <Route exact path={AdvisorRoutes.CONFIG.BULK.FINISH}>
            <AdvisorConfigBulkFinishSite />
        </Route>
        <Route path={AdvisorRoutes.CONFIG.OVERVIEW}>
            <AdvisorConfigOverviewSite />
        </Route>
        <Route path={AdvisorRoutes.CONFIG.COMPANY_DATA}>
            <AdvisorConfigCompanyDataSite />
        </Route>
        <Route path={AdvisorRoutes.CONFIG.TERMS_OF_USE}>
            <AdvisorConfigTermsOfUseSite />
        </Route>
        <Route path={AdvisorRoutes.CONFIG.ACCOUNTING}>
            <AdvisorConfigAccountingSite />
        </Route>
        <Route path={AdvisorRoutes.CONFIG.HR}>
            <AdvisorConfigHrSite />
        </Route>
        <Route path={AdvisorRoutes.CONFIG.KPIS}>
            <AdvisorConfigKPIsSite />
        </Route>
        <Route exact path={AdvisorRoutes.CONFIG.USERS}>
            <AdvisorConfigUsersSite />
        </Route>
        <Route path={AdvisorRoutes.CONFIG.USERS_EMPLOYEES}>
            <AdvisorConfigNewUsersSite />
        </Route>
        <Route path={AdvisorRoutes.CONFIG.USER_ROLES}>
            <UserRolesSite />
        </Route>
        <Route path={AdvisorRoutes.CONFIG.USER_PERMISSIONS}>
            <UserPermissionsSite />
        </Route>
        <Route>
            <NotFoundSite />
        </Route>
    </Switch>
);
