import * as React from "react";
import { Redirect } from "react-router";
import { IS_PROD } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { authStore } from "../../../stores/AuthStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { debug } from "../../../util/debug";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useHash } from "../../hooks/useHash";
import { AuthRoutes } from "../router/AuthRoutes";

// MS OAuth login redirects here
export const AuthMSSite = () => {
    const {
        code,
        state,
        session_state,
        error,
        error_description,
    }: {
        code?: string;
        state?: string;
        session_state?: string;
        error?: string;
        error_description?: string;
    } = useHash();

    React.useEffect(() => {
        const handleMSLogin = async (code: string, state: string) => {
            generalStore.isLoading = true;
            try {
                const response = await API.postMSLogin(code, state);

                if (!IS_PROD) {
                    debug.log(
                        "### OAuth redirect",
                        `http://localhost:3000${AuthRoutes.LOCAL_CREDENTIALS}#access_token=${response.access_token}&refresh_token=${response.refresh_token}`,
                    );
                }

                authStore.credentials = response;
                await coordinator.refreshAfterLogin();
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.response.type === "USER_DEACTIVATED") {
                    generalStore.setError(t("error.userDeactivated"));
                } else if (apiError?.response.type === "UNKNOWN_USER") {
                    authStore.showUnknownMSLoginDialog = true;
                } else if (apiError?.response.type === "MS_AUTH_FAILED") {
                    generalStore.setError(t("error.microsoftLogin"));
                } else if (
                    apiError?.response.type === "COMPANIES_SYNC_TIMEOUT" ||
                    apiError?.statusCode === HttpStatusCode.GatewayTimeout_504
                ) {
                    generalStore.setError(t("error.loginAccountSetup"));
                } else {
                    generalStore.setError(t("error.general"), error);
                }

                pushRoute(Routes.ROOT);
            } finally {
                generalStore.isLoading = false;
            }
        };

        if (code && state) {
            handleMSLogin(code, state);
        }
    }, [code, state]);

    if (!!error || !!error_description) {
        generalStore.setError(`${t("error.invalid_credentials")}: ${error ?? ""} (${error_description ?? ""})`);
        return <Redirect to={Routes.ROOT} />;
    }

    return null;
};
