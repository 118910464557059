import { observer } from "mobx-react";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { Routes } from "../app/router/Routes";
import { NavBarInfo, NavBarInfoLinkButton, NavBarInfoMessage } from "./NavBarInfo";

export const CompaniesUserReleaseInfoAdminActionsBar = observer(function CompaniesUserReleaseInfoAdminActionsBar() {
    const count = companiesStore.waitingUsers?.reduce((count, company) => count + company.users.length, 0);
    if (!authStore.isAuthenticated || !!companiesStore.selectedCompanyId || !count) {
        return null;
    }

    return (
        <NavBarInfo>
            <NavBarInfoMessage>{t("companiesUserReleaseInfoActionsBar.message", { count })}</NavBarInfoMessage>
            <NavBarInfoLinkButton to={Routes.COMPANIES_USERS_RELEASE}>
                {t("companiesUserReleaseInfoActionsBar.submit")}
            </NavBarInfoLinkButton>
        </NavBarInfo>
    );
});
