import { Accordion, AccordionDetails, AccordionSummary, withStyles } from "@material-ui/core";
import * as React from "react";
import { Icon } from "../util/Icon";
import { BOX_SHADOW_LIGHT, customColors } from "../util/Theme";

const StyledAccordionSummary = withStyles({
    root: {
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: BOX_SHADOW_LIGHT,
        minHeight: 56,
        padding: "0 16px",
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        alignItems: "center",
        margin: 0,
        "&$expanded": {
            margin: 0,
        },
    },
    expanded: {},
})(AccordionSummary);

export const ConfigAccordionSummary = ({ children, ...props }: React.ComponentProps<typeof StyledAccordionSummary>) => (
    <StyledAccordionSummary expandIcon={<Icon name="chevronDown" />} {...props}>
        {children}
    </StyledAccordionSummary>
);

export const ConfigAccordion = withStyles({
    root: {
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(Accordion);

export const ConfigAccordionDetails = withStyles({
    root: {
        backgroundColor: customColors.greyBackground,
        padding: 0,
    },
})(AccordionDetails);
