import * as React from "react";

// Taken from https://javascript.plainenglish.io/a-react-hook-to-share-state-between-browser-windows-a672470f66ff
export function useCrossTabState<T>(stateKey: string, defaultValue: T) {
    const [state, setState] = React.useState(defaultValue);
    const isNewSession = React.useRef(true);
    React.useEffect(() => {
        if (isNewSession.current) {
            const currentState = localStorage.getItem(stateKey);
            if (currentState) {
                setState(JSON.parse(currentState) as T);
            } else {
                setState(defaultValue);
            }
            isNewSession.current = false;
            return;
        }

        try {
            localStorage.setItem(stateKey, JSON.stringify(state));
        } catch (error) {
            /* empty */
        }
    }, [state, stateKey, defaultValue]);

    React.useEffect(() => {
        const onReceiveMessage = (e: StorageEvent) => {
            const { key, newValue } = e;
            const parsed = newValue ? (JSON.parse(newValue) as T) : undefined;

            if (key === stateKey && state !== parsed && parsed !== undefined) {
                setState(parsed);
            }
        };

        window.addEventListener("storage", onReceiveMessage);
        return () => {
            window.removeEventListener("storage", onReceiveMessage);
        };
    }, [stateKey, setState, state]);

    return [state, setState] as const;
}
