import { useBalanceSheet } from "./hooks/useBalanceSheet";
import { ResultsTable } from "./ResultsTable";
import { ResultsSiteViewProps } from "./types";

interface ResultsBalanceSheetProps extends ResultsSiteViewProps {}

export const ResultsBalanceSheet = ({ companyId, settings, financialAccountancy }: ResultsBalanceSheetProps) => {
    const state = useBalanceSheet(companyId, settings);
    return state && financialAccountancy ? (
        <ResultsTable
            type="balanceSheet"
            columns={state.columns}
            rows={state.rows}
            stylesheet={state.stylesheet}
            financialAccountancy={financialAccountancy}
        />
    ) : null;
};
