import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { companiesStore } from "../../../stores/CompaniesStore";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { ModuleRouter } from "../../shared/router/ModuleRouter";
import { TicketsListSite } from "../../shared/TicketsListSite";
import { CenteredContent } from "../../ui/CenteredContent";
import { HrNavBarEmployees } from "../HrNavBarEmployees";
import { hrStore } from "../HrStore";
import { HrEmployeeDetailsSite } from "../sites/HrEmployeeDetailsSite";
import { HrEmployeePayslipUploadSite } from "../sites/HrEmployeePayslipUploadSite";
import { HrEmployeesAllDocumentsSite } from "../sites/HrEmployeesAllDocumentsSite";
import { HrEmployeesCurrentSite } from "../sites/HrEmployeesCurrentSite";
import { HrEmployeesDeregistrationDataSite } from "../sites/HrEmployeesDeregistrationDataSite";
import { HrEmployeesDeregistrationDocumentsSite } from "../sites/HrEmployeesDeregistrationDocumentsSite";
import { HrEmployeesDocumentsSite } from "../sites/HrEmployeesDocumentsSite";
import { HrEmployeesFormerSite } from "../sites/HrEmployeesFormerSite";
import { HrEmployeesInProgressSite } from "../sites/HrEmployeesInProgressSite";
import { HrEmployeesRegistrationAdditionalDataSite } from "../sites/HrEmployeesRegistrationAdditionalDataSite";
import { HrEmployeesRegistrationDocumentsSite } from "../sites/HrEmployeesRegistrationDocumentsSite";
import { HrEmployeesRegistrationPersonalDataSite } from "../sites/HrEmployeesRegistrationPersonalDataSite";
import { HrEmployeesRegistrationSummarySite } from "../sites/HrEmployeesRegistrationSummarySite";
import { HrPreRegistrationTicketSite } from "../sites/HrPreRegistrationTicketSite";
import { HrRoutes } from "./HrRoutes";

const EmployeesList = () => {
    const hasPreRegistration = companiesStore.selectedCompany?.preRegistrationEnabled;
    return (
        <>
            <HrNavBarEmployees />
            <Route exact path={HrRoutes.EMPLOYEES.TICKETS}>
                {hasPreRegistration && hrStore.preRegistrationTicketCount > 0 ? (
                    <TicketsListSite isPreregistration />
                ) : (
                    <Redirect to={HrRoutes.EMPLOYEES.CURRENT} />
                )}
            </Route>
            <CenteredContent>
                <Route path={HrRoutes.EMPLOYEES.IN_PROGRESS}>
                    {hasPreRegistration ? <HrEmployeesInProgressSite /> : <Redirect to={HrRoutes.EMPLOYEES.CURRENT} />}
                </Route>
                <Route path={HrRoutes.EMPLOYEES.CURRENT} component={HrEmployeesCurrentSite} />
                <Route path={HrRoutes.EMPLOYEES.FORMER} component={HrEmployeesFormerSite} />
            </CenteredContent>
        </>
    );
};

const EmployeesRouter = observer(function EmployeesRouter() {
    React.useEffect(() => {
        // When navigating to employee lists -> load employee subsidiaries
        hrStore.loadEmployeeSubsidiaries();

        // When leaving employee lists -> match subsidiary back to normal subsidiary
        return () => {
            hrStore.cleanupEmployeeSubsidiaries();
        };
    }, []);

    const employeeSubsidiariesLoaded =
        !hrStore.isLoadingEmployeeSubsidiaries && hrStore.employeeSubsidiaries.length > 0;

    if (!employeeSubsidiariesLoaded) {
        return <HrNavBarEmployees />;
    }

    return (
        <>
            <Route
                exact
                path={HrRoutes.EMPLOYEES.ROOT}
                render={() => (
                    <Redirect
                        to={
                            hrStore.preRegistrationTicketCount > 0
                                ? HrRoutes.EMPLOYEES.TICKETS
                                : HrRoutes.EMPLOYEES.CURRENT
                        }
                    />
                )}
            />
            <Route exact path={HrRoutes.EMPLOYEES.LIST} render={() => <Redirect to={HrRoutes.EMPLOYEES.CURRENT} />} />
            <Route path={HrRoutes.EMPLOYEES.LIST} component={EmployeesList} />
            <Route path={HrRoutes.EMPLOYEES.DETAILS} component={HrEmployeeDetailsSite} />
            <Route path={HrRoutes.EMPLOYEES.DOCUMENTS} component={HrEmployeesDocumentsSite} />
            <Route path={HrRoutes.EMPLOYEES.ALL_DOCUMENTS} component={HrEmployeesAllDocumentsSite} />
            <Route
                path={HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA}
                component={HrEmployeesRegistrationPersonalDataSite}
            />
            <Route
                path={HrRoutes.EMPLOYEES.REGISTRATION_ADDITIONAL_DATA}
                component={HrEmployeesRegistrationAdditionalDataSite}
            />
            <Route path={HrRoutes.EMPLOYEES.REGISTRATION_DOCUMENTS} component={HrEmployeesRegistrationDocumentsSite} />
            <Route path={HrRoutes.EMPLOYEES.REGISTRATION_SUMMARY} component={HrEmployeesRegistrationSummarySite} />
            <Route path={HrRoutes.EMPLOYEES.DEREGISTRATION_DATA} component={HrEmployeesDeregistrationDataSite} />
            <Route
                path={HrRoutes.EMPLOYEES.DEREGISTRATION_DOCUMENTS}
                component={HrEmployeesDeregistrationDocumentsSite}
            />

            <Route path={HrRoutes.EMPLOYEES.TICKET_DETAILS} component={HrPreRegistrationTicketSite} />

            <Route path={HrRoutes.EMPLOYEES.UPLOAD_PAYSLIP} component={HrEmployeePayslipUploadSite} />
        </>
    );
});

export const HrRouter = () => (
    <Switch>
        <Route path={HrRoutes.EMPLOYEES.ROOT} component={EmployeesRouter} />
        <Route path={HrRoutes.ROOT}>
            <ModuleRouter module="hr" />
        </Route>

        <Route component={NotFoundSite} />
    </Switch>
);
