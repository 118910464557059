import { Checkbox, TableCell, TableHead, TableRow } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { IMessageIDS, t } from "../../i18n/util";
import { ItemWithId, TableStore } from "../../stores/TableStore";
import { MobileContext } from "../util/MobileContext";
import { TpaTableSortLabel } from "./Primitives";

export interface ITableHeaderConfig<Item extends ItemWithId = ItemWithId> {
    column: keyof Item;
    label?: IMessageIDS | React.ReactElement;
    sort?: boolean;
    style?: React.CSSProperties;
    headerStyle?: React.CSSProperties;
    align?: "right";
}

interface Slots {
    Head?: React.ComponentType;
    Row?: React.ComponentType;
    Cell?: React.ComponentType<{
        padding?: "checkbox";
        align?: "right";
        style?: React.CSSProperties;
        columnIndex: number;
    }>;
}

const TableHeaderCell: Slots["Cell"] = ({ columnIndex, ...props }) => <TableCell {...props} />;

export const TableHeader = observer(function TableHeader<Item extends ItemWithId = ItemWithId>(props: {
    headerFields: ITableHeaderConfig<Item>[];
    tableStore: TableStore<Item>;
    select?: { someSelected: boolean; allSelected: boolean; toggleSelectAll: (selectAll: boolean) => void };
    allowMultiSelectAlways?: boolean;
    slots?: Slots;
    renderLabel?: (headerField: ITableHeaderConfig<Item>) => React.ReactNode;
}) {
    const { renderLabel, select, tableStore } = props;

    const isMobile = React.useContext(MobileContext);

    const Head = props.slots?.Head ?? TableHead;
    const Row = props.slots?.Row ?? TableRow;
    const Cell = props.slots?.Cell ?? TableHeaderCell;

    let columnIndex = 0;

    return (
        <>
            <Head>
                <Row>
                    {(!isMobile || props.allowMultiSelectAlways) && select && (
                        <Cell padding="checkbox" columnIndex={columnIndex++}>
                            <Checkbox
                                color="primary"
                                onChange={(_, checked) => {
                                    select.toggleSelectAll(checked);
                                }}
                                checked={select.allSelected}
                                indeterminate={select.someSelected}
                                data-id="select-all-checkbox"
                            />
                        </Cell>
                    )}
                    {props.headerFields.map(headerField => {
                        const { column: col, label, sort, style, headerStyle, align } = headerField;
                        const column = col.toString();
                        const sortDisabled = sort === false;

                        let content = renderLabel ? renderLabel(headerField) : undefined;
                        if (content === undefined && label) {
                            content = (
                                <TpaTableSortLabel
                                    data-id={"sort_label_" + column}
                                    disabled={sortDisabled}
                                    hideSortIcon={sortDisabled}
                                    active={tableStore.orderBy === col}
                                    direction={tableStore.orderDir}
                                    onClick={() => {
                                        tableStore.onChangeSort(col);
                                    }}
                                >
                                    {typeof label === "string" ? t(label) : label}
                                </TpaTableSortLabel>
                            );
                        }

                        return (
                            <Cell
                                key={column}
                                style={{
                                    textAlign: isMobile && col === "releasedAt" ? "right" : undefined,
                                    ...style,
                                    ...headerStyle,
                                }}
                                align={align}
                                columnIndex={columnIndex++}
                            >
                                {content}
                            </Cell>
                        );
                    })}
                </Row>
            </Head>
        </>
    );
});
