import * as React from "react";
import { t } from "../../i18n/util";
import { IEmployee } from "../../types/models";
import { employeeStatusToString } from "../../util/helpers";
import { OverviewContainer } from "../ui/Primitives";

export const HrEmployeeStatus = ({ employee }: { employee: IEmployee }) => {
    return (
        <OverviewContainer style={{ marginBottom: 16 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <h3 style={{ width: "24%", marginRight: "9%" }}>{t("screen.hr.employee.details.status")}</h3>

                <div className="body1">{employeeStatusToString(employee && employee.status)}</div>
            </div>
        </OverviewContainer>
    );
};
