import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { DeleteEmployeeDocumentDialog, IDocumentToDelete } from "../shared/ModuleDialogs";

export const useDeleteEmployeeDocument = ({
    companyId,
    subsidiaryId,
    employeeId,
    isPreRegistration,
    onDelete,
}: {
    companyId?: string;
    subsidiaryId?: string;
    employeeId: string;
    isPreRegistration?: boolean;
    onDelete?: (numDeleted: number) => void;
}) => {
    const [documentsToDelete, setDocumentsToDelete] = React.useState<IDocumentToDelete[] | null>(null);

    const handleDeleteDocuments = (documents: IDocumentToDelete[]) => {
        setDocumentsToDelete(documents);
    };

    const doDeleteDocuments = async (documentIds: string[]) => {
        try {
            await Promise.all(
                documentIds.map(documentId => {
                    return API.deleteEmployeeDocument(
                        companyId,
                        subsidiaryId,
                        documentId,
                        employeeId,
                        isPreRegistration,
                    );
                }),
            );

            setDocumentsToDelete(null);
            onDelete?.(documentIds.length);
        } catch (err) {
            generalStore.setError(t("error.delete"), err);
        }
    };

    const dialog = (
        <DeleteEmployeeDocumentDialog
            open={!!documentsToDelete}
            documents={documentsToDelete ?? []}
            onCancel={() => {
                setDocumentsToDelete(null);
            }}
            onDelete={() => {
                if (documentsToDelete) {
                    doDeleteDocuments(documentsToDelete.map(document => document.id));
                }
            }}
        />
    );

    return {
        handleDeleteDocuments,
        dialog,
    };
};
