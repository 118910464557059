import { observer } from "mobx-react";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { AdvisorRoutes } from "../../advisor/router/AdvisorRoutes";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useCompanyContextMenu } from "../../hooks/useCompanyContextMenu";
import { Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";
import { ContextMenu, ContextMenuItem } from "../ContextMenu";

export const ChooseCompanyDialog = observer(function ChooseCompanyDialog() {
    const handleMenuCompanyItemClick = async (companyId: string) => {
        contextMenu.close();

        try {
            // If companyId is changed in companyStore, the current component will refresh and try to reload data with the newly selected company.
            // If this component is not available for the newly selected company, it will cause an error, as it is trying to load data for a component that is disabled. (The new company has no access on this component.)
            // e.g. first we load BankAccounts for a company in /account-sheet. If we now switch the company to one that has no /account-sheet, it will still try to load BankAccounts for the new company, causing an error.
            // This is why we first push to loading route, which opens empty LoadingSite component. Then we set the new company, causing the current component(LoadingSite) to refresh. This will not load any data, as LoadingSite is empty with no hooks or effects.
            // After that we push to Cockpit Route, which will load data for Cockpit component with newly selected company.
            pushRoute(Routes.LOADING);
            await coordinator.selectCompanyById(companyId);
            pushRoute(Routes.COCKPIT);
        } catch (err) {
            generalStore.setError(t("error.general"), err);
        }
    };

    const handleOverviewSelect = () => {
        contextMenu.close();

        const target = authStore.isTpa ? AdvisorRoutes.COMPANIES.ACTIVE : Routes.COMPANY_OVERVIEW;
        pushRoute(target);
    };

    const { initialized, contextMenu, hasMoreThanOneCompany, contextMenuProps } = useCompanyContextMenu({
        getSelectedCompany: (companyId: string) => companyId === companiesStore.selectedCompanyId,
        disableSelected: true,
        onClickCompanyItem: handleMenuCompanyItemClick,
        onClickRemainingCompanyItems: handleOverviewSelect,
    });

    // Company overview (tpa users always can go to overview since there might
    // be inactive companies waiting for configuration)
    const getCompanyOverviewFooter = (): ContextMenuItem | undefined => {
        if (hasMoreThanOneCompany || authStore.isTpa) {
            return {
                title: t("common.companyOverview"),
                onClick: handleOverviewSelect,
                selected: false,
                "data-id": "context_menu_companies_overview",
                borderTop: true,
                icon: "suitcase",
            };
        } else {
            return undefined;
        }
    };

    if (!initialized) {
        return null;
    }

    const showDropDown = hasMoreThanOneCompany || authStore.isTpa;

    return (
        <>
            <div
                onClick={showDropDown ? contextMenu.open : undefined}
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    cursor: showDropDown ? "pointer" : undefined,
                    padding: "12px 16px",
                }}
            >
                <div
                    style={{
                        maxWidth: "80%",
                        fontSize: 10,
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                        fontWeight: "bold",
                    }}
                >
                    {companiesStore.selectedCompany?.name}
                </div>
                {showDropDown && (
                    <Icon
                        name={contextMenu.isOpen ? "dropUp" : "dropDown"}
                        style={{ color: customColors.primaryColor }}
                    />
                )}
            </div>

            <ContextMenu
                {...contextMenuProps}
                data-id="choose_company_context_menu"
                footerItem={getCompanyOverviewFooter()}
            />
        </>
    );
});
