import { useHistory, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { getFullName } from "../../../util/user";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useEmployee } from "../../hooks/useEmployee";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useTicketDetails } from "../../hooks/useTicketDetails";
import { TicketSiteContent } from "../../shared/TicketSiteContent";
import { TicketsRoutes } from "../../tickets/router/TicketsRoutes";
import { NavBarBack } from "../../ui/NavBarBack";
import { HrRoutes } from "../router/HrRoutes";

export const HrPreRegistrationTicketSite = () => {
    useHideSideBar();
    const history = useHistory();
    const module = "hr";
    const store = getModuleStore(module);
    const { preRegistrationId, genericBackLabel } = useQueryParams<{
        preRegistrationId: string;
        genericBackLabel: string;
    }>();
    const { ticketId } = useParams<{ ticketId: string }>();

    const companyId = companiesStore.selectedCompanyId;

    const preRegistration = useEmployee(preRegistrationId, true).employee ?? undefined;

    const ticket = useTicketDetails({ companyId, ticketId });

    const handleCloseTicket = () => {
        if (module && preRegistrationId) {
            // User UI
            pushRoute(HrRoutes.EMPLOYEES.TICKETS);
        } else if (authStore.isTpa) {
            // tpa user
            pushRoute(TicketsRoutes.OPEN);
        } else {
            // If all fails go to cockpit
            pushRoute(Routes.COCKPIT);
        }
    };

    const handleBack = () => {
        if (generalStore.lastLocation) {
            // Best case we can go back (e.g. to webhook, record ticket list, advisor ticket list)
            // If not then it was a deep link -> handle back for those cases below
            history.goBack();
        } else {
            handleCloseTicket();
        }
    };

    return (
        <>
            <NavBarBack
                backLabel={genericBackLabel ? t("common.back") : store.t("hr.tickets.preRegistration.NavBarLabel")}
                title={t("hr.tickets.preRegistration.NavBar", {
                    preRegistration: ticket.details?.description ?? getFullName(preRegistration),
                })}
                onBack={handleBack}
                showCancel={false}
            />
            {ticket.details && (
                <TicketSiteContent
                    companyId={companyId}
                    preRegistration={preRegistration}
                    onCloseTicket={handleCloseTicket}
                    ticketId={ticketId}
                    ticketDetails={ticket.details}
                />
            )}
        </>
    );
};
