import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { TicketsListSite } from "../../shared/TicketsListSite";
import { TicketsNavBar } from "../TicketsNavBar";
import { TicketsRoutes } from "./TicketsRoutes";
import { TicketsDetailsSite } from "../sites/TicketsDetailsSite";

const Tickets = () => {
    return (
        <>
            <TicketsNavBar />
            <Route path={TicketsRoutes.OPEN}>
                <TicketsListSite relation="open" />
            </Route>
            <Route path={TicketsRoutes.AUTHORED}>
                <TicketsListSite relation="authored" />
            </Route>
            <Route path={TicketsRoutes.CLOSED}>
                <TicketsListSite relation="closed" />
            </Route>
        </>
    );
};

export const TicketsRouter = () => (
    <Switch>
        <Route exact path={TicketsRoutes.ROOT}>
            <Redirect to={TicketsRoutes.OPEN} />
        </Route>
        <Route exact path={[TicketsRoutes.OPEN, TicketsRoutes.AUTHORED, TicketsRoutes.CLOSED]}>
            <Tickets />
        </Route>
        <Route exact path={TicketsRoutes.DETAILS}>
            <TicketsDetailsSite />
        </Route>
        <Route>
            <NotFoundSite />
        </Route>
    </Switch>
);
