import { observable } from "mobx";
import { ViewerFile, ViewerFileSrc } from "../components/viewer/types";
import { ZOOM_DEFAULT } from "../config";
import { addStoreToWindow } from "../util/debug";

export type { ViewerFile, ViewerFileSrc };

class ViewerStore {
    @observable.shallow files: ViewerFile[] = [];
    @observable selectedFileId: ViewerFile["id"] = "";

    @observable zoomLevel = ZOOM_DEFAULT;

    open(files: ViewerFile[], selectedFileId: ViewerFile["id"] | null | undefined) {
        this.files = files;
        this.selectedFileId = selectedFileId ?? files[0]?.id ?? "";
    }
    close() {
        this.files = [];
        this.selectedFileId = "";
        this.zoomLevel = ZOOM_DEFAULT;
    }
    wipe() {
        this.close();
    }
}

export const viewerStore = new ViewerStore();

addStoreToWindow("viewerStore", viewerStore);
