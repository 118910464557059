import * as React from "react";
import { Status, statusColors } from "../../stores/Status";

export const StatusBar = (props: {
    status: Status;
    style?: React.CSSProperties;
    marginBottom?: number;
    minHeight?: number;
}) => {
    return (
        <div
            style={{
                width: 6,
                flexShrink: 0,
                background: statusColors[props.status].color,
                borderRadius: "4px 0px 0px 4px",
                marginBottom: props.marginBottom,
                minHeight: props.minHeight,
            }}
        />
    );
};
