import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { fileNameRegex, getFileNameWithoutExtension } from "../../util/files";
import { CustomDialog } from "../ui/CustomDialog";
import { CustomInputField } from "../ui/CustomInputField";
import { TpaWhiteButton } from "../ui/Primitives";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";

type RenameDialogVariant = "folder" | "file" | "document";

interface IProps {
    open: boolean;
    currentName: string;
    variant: RenameDialogVariant;
    onClose: () => void;
    onSubmit: (name: string) => void;
}

const getTitle = (variant: RenameDialogVariant) => {
    switch (variant) {
        case "file":
            return t("renameDialog.title.file");
        case "folder":
            return t("renameDialog.title.folder");
        case "document":
        default:
            return t("renameDialog.title.document");
    }
};

const getInputLabel = (variant: RenameDialogVariant) => {
    switch (variant) {
        case "file":
            return t("renameDialog.label.file");
        case "folder":
            return t("renameDialog.label.folder");
        case "document":
        default:
            return t("renameDialog.label.document");
    }
};

export const RenameDialog = ({ open, currentName, variant, onClose, onSubmit }: IProps) => {
    const handleSubmit = (values: { name: string }) => {
        onSubmit(values.name);
    };

    const MAX_CHARS = 500;

    return (
        <CustomDialog open={open} onClose={onClose}>
            <div style={{ padding: "42px 42px 0 42px" }}>
                <h1>{getTitle(variant)}</h1>
                <Formik
                    initialValues={{
                        name: variant === "folder" ? currentName : getFileNameWithoutExtension(currentName),
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required(t("error.requiredField"))
                            .matches(fileNameRegex, t("error.invalidCharacters"))
                            .matches(/^[\s\S]+[^.]$/, t("error.noDotAtEnd"))
                            .max(MAX_CHARS, t("error.maxCharacters", { count: MAX_CHARS })),
                    })}
                >
                    <Form id="renameForm">
                        <Field
                            component={CustomInputField}
                            name="name"
                            data-id="rename_dialog_name"
                            label={getInputLabel(variant)}
                            required
                            style={{ marginTop: 24 }}
                        />
                    </Form>
                </Formik>
            </div>
            <div style={{ padding: "0 42px 42px 42px" }}>
                <ResponsiveButtonContainer style={{ marginTop: 16 }}>
                    <TpaWhiteButton onClick={onClose} data-id="button_cancel">
                        {t("common.cancel")}
                    </TpaWhiteButton>
                    <Button variant="contained" color="primary" type="submit" form="renameForm" data-id="button_rename">
                        {t("renameDialog.button.text.rename")}
                    </Button>
                </ResponsiveButtonContainer>
            </div>
        </CustomDialog>
    );
};
