import * as React from "react";
import { IDocument } from "../../types/models";
import { PageIndicator } from "../ui/PageIndicator";
import { BOX_SHADOW_LIGHT } from "../util/Theme";

// Thumbnails with pagination for a document
export const Thumbnails = (props: { document: Partial<IDocument> }) => {
    const [thumbnailIndex, setThumbnailIndex] = React.useState(0);
    const numThumbnails = props.document?.thumbnailUrls?.length ?? 0;

    if (numThumbnails === 0) {
        return null;
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <img
                style={{ width: "100%", boxShadow: BOX_SHADOW_LIGHT }}
                src={props.document.thumbnailUrls?.[thumbnailIndex]}
                alt="document preview"
                data-id="thumbnail"
            />
            {numThumbnails > 1 && (
                <PageIndicator
                    current={thumbnailIndex + 1}
                    total={numThumbnails}
                    onNext={() => {
                        if (thumbnailIndex < numThumbnails - 1) {
                            setThumbnailIndex(thumbnailIndex + 1);
                        }
                    }}
                    onPrevious={() => {
                        if (thumbnailIndex > 0) {
                            setThumbnailIndex(thumbnailIndex - 1);
                        }
                    }}
                />
            )}
        </div>
    );
};
