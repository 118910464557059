import { observer } from "mobx-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { t } from "../../../i18n/util";
import { FinancialAccountancy } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { CenteredContent } from "../../ui/CenteredContent";
import { SiteContent } from "../../ui/SiteContent";
import { ResultsAccountListing } from "../ResultsAccountListing";
import { ResultsBalanceSheet } from "../ResultsBalanceSheet";
import { ResultsCashAccounting } from "../ResultsCashAccounting";
import { ResultsCustomerListing } from "../ResultsCustomerListing";
import { ResultsProfitAndLoss } from "../ResultsProfitAndLoss";
import { ResultsSettings, fromQuery, toQuery } from "../ResultsSettings";
import { ResultsVendorListing } from "../ResultsVendorListing";
import { useFinancialAccountancies } from "../hooks/useFinancialAccountancies";
import { ResultsRoutes } from "../router/ResultsRoutes";
import { AccountingViews, ResultsSettings as ResultsSettingsType, ResultsSiteViewProps } from "../types";
import { supportsOrderBy } from "../utils";
import { ResultsNavBar } from "./ResultsNavBar";

const pathToView: Record<string, AccountingViews> = {
    [ResultsRoutes.ACCOUNT_LISTING]: "accountListing",
    [ResultsRoutes.CUSTOMER_LISTING]: "customerListing",
    [ResultsRoutes.VENDOR_LISTING]: "vendorListing",
    [ResultsRoutes.BALANCE_SHEET]: "balanceSheet",
    [ResultsRoutes.CASH_ACCOUNTING]: "cashAccounting",
    [ResultsRoutes.PROFIT_AND_LOSS]: "profitAndLoss",
};

const viewComponents: Record<AccountingViews, React.ComponentType<ResultsSiteViewProps>> = {
    accountListing: ResultsAccountListing,
    customerListing: ResultsCustomerListing,
    vendorListing: ResultsVendorListing,
    balanceSheet: ResultsBalanceSheet,
    cashAccounting: ResultsCashAccounting,
    profitAndLoss: ResultsProfitAndLoss,
};

export const ResultsAccountingSite = () => {
    // get the current view from the URL
    const match = useRouteMatch();
    const view: AccountingViews | undefined = pathToView[match.path];
    if (!view) {
        return null;
    }

    return <ResultsAccounting view={view} />;
};

const ResultsAccounting = observer(function ResultsAccounting({ view }: { view: AccountingViews }) {
    const companyId = companiesStore.selectedCompanyId;

    const history = useHistory();

    const [settings, setSettings] = useState<ResultsSettingsType>(() => {
        return fromQuery(history.location.search);
    });
    const settingsRef = useRef(settings);
    settingsRef.current = settings;

    const handleSettingsChange = useCallback(
        (clientSettings: ResultsSettingsType) => {
            setSettings(clientSettings);

            const query = toQuery(clientSettings);
            history.replace({ ...history.location, search: query });
        },
        [history],
    );

    useEffect(() => {
        const settings = settingsRef.current;
        if (!supportsOrderBy[view]) {
            // the accountListing, customerListing and vendorListing view supports sorting, the other views just display the data as received from the API
            handleSettingsChange({ ...settings, orderBy: "none" });
        } else if (settings.orderBy === "none") {
            handleSettingsChange({ ...settings, orderBy: "accountNr-asc" });
        }
    }, [view, handleSettingsChange]);

    const financialAccountancies = useFinancialAccountancies();
    let financialAccountancy: FinancialAccountancy | undefined;

    let content;
    if (!financialAccountancies) {
        content = null; // a loading indicator is displayed via `generalStore.isLoading`
    } else {
        financialAccountancy = settings.financialAccountancyId
            ? financialAccountancies.find(fa => fa.id === settings.financialAccountancyId)
            : undefined;

        const ViewComponent = viewComponents[view];
        content = (
            <CenteredContent>
                <SiteContent>
                    <ResultsSettings
                        view={view}
                        financialAccountancies={financialAccountancies}
                        settings={settings}
                        onChange={handleSettingsChange}
                    />
                    <ViewComponent
                        companyId={companyId}
                        settings={settings}
                        financialAccountancies={financialAccountancies}
                        financialAccountancy={financialAccountancy}
                    />
                </SiteContent>
            </CenteredContent>
        );
    }

    useEffect(() => {
        setSettings(settings => {
            if (!financialAccountancies) {
                return settings;
            }
            let { financialAccountancyId } = settings;
            // select the previously selected one again
            if (financialAccountancyId) {
                const id = financialAccountancyId;
                financialAccountancyId = financialAccountancies.find(fa => fa.id === id)?.id;
            }
            // or fall back to the current one (isLatest)
            if (!financialAccountancyId) {
                financialAccountancyId = financialAccountancies.find(fa => fa.isLatest)?.id;
            }
            // or the most recent one
            if (!financialAccountancyId) {
                financialAccountancyId = financialAccountancies.reduce<FinancialAccountancy | null>((recent, fa) => {
                    return !recent || new Date(fa.startsAt) > new Date(recent.startsAt) ? fa : recent;
                }, null)?.id;
            }
            return { ...settings, financialAccountancyId: financialAccountancyId ?? null };
        });
    }, [financialAccountancies]);

    const query = toQuery(settings);

    return (
        <>
            <ResultsNavBar
                title={t(`results.navbar.buttons.${view}`)}
                financialAccountancy={financialAccountancy}
                query={query}
            />
            {content}
        </>
    );
});
