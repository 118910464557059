import cloneDeep from "lodash/cloneDeep";
import * as React from "react";
import { Route, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    GetProjectsCompaniesResponseItem,
    ProjectConfiguration,
    ProjectPermissionRequest,
} from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { IProjectUser } from "../../../types/models";
import { useConfirmInternationalDialog } from "../../hooks/useConfirmInternationalProject";
import { ProjectsRoutes } from "../router/ProjectsRoutes";
import { ProjectsDataProjectCompaniesSite } from "./ProjectsDataProjectCompaniesSite";
import { ProjectsDataProjectDetailsSite } from "./ProjectsDataProjectDetailsSite";
import { ProjectsDataProjectFinishSite } from "./ProjectsDataProjectFinishSite";
import { ProjectsDataProjectUsersSite } from "./ProjectsDataProjectUsersSite";
import { ProjectConfig } from "./types";

export const ProjectsDataSite = () => {
    useHideSideBar();

    const { projectId, externalId } = useParams<{ projectId?: string; externalId?: string }>();

    const [project, setProject] = React.useState<ProjectConfig>(null);
    const [projectUsers, setProjectUsers] = React.useState<IProjectUser[]>([]);
    const [originalProjectUsers, setOriginalProjectUsers] = React.useState<IProjectUser[]>([]);
    const [projectCompanies, setProjectCompanies] = React.useState<GetProjectsCompaniesResponseItem[]>([]);

    const companyId = companiesStore.selectedCompanyId;
    const country = companiesStore.selectedCompany?.countryCode;

    const confirmInternational = useConfirmInternationalDialog(country ?? "AT");

    const setIsInternational = confirmInternational.setIsInternational;
    React.useEffect(() => {
        const loadProject = async () => {
            if (!companyId) {
                return;
            }

            if (project !== null) {
                // We have already loaded the project
                return;
            }

            try {
                generalStore.isLoading = true;
                let loadedUsers: IProjectUser[] = [];

                if (externalId) {
                    const projectItem = await API.getProjectToConfigure(companyId, externalId);
                    setProject(projectItem);
                    loadedUsers = [
                        // Show project creator on top
                        {
                            userId: authStore.userId,
                            profile_picture_url: authStore.userInfo?.profile_picture_url,
                            name: `${authStore.userInfo?.given_name ?? ""} ${authStore.userInfo?.family_name ?? ""}`,
                            role: "owner",
                            username: authStore.userInfo?.email,
                            isTpaEmployee: authStore.userInfo?.is_tpa_employee,
                        } as ProjectPermissionRequest,
                    ].concat((projectItem.users ?? []).filter(user => user.userId !== authStore.userId));
                } else if (projectId) {
                    const project = await API.getProject(companyId, projectId);
                    const projectItem = project as ProjectConfiguration;
                    setProject(projectItem);
                    loadedUsers = projectItem.users ?? [];
                    setIsInternational(!!project.international);
                } else {
                    // create a brand new empty project
                    const project = {
                        name: "",
                    } as ProjectConfiguration;
                    setProject(project);
                }

                setProjectUsers(loadedUsers);
                setOriginalProjectUsers(cloneDeep(loadedUsers));
            } catch (error) {
                generalStore.setError(t("error.general"), error);
            } finally {
                generalStore.isLoading = false;
            }
        };

        loadProject();
    }, [companyId, projectId, externalId, project, setIsInternational]);

    const resetProjectUsers = () => {
        setProjectUsers(cloneDeep(originalProjectUsers));
    };

    return (
        <>
            <Route
                exact
                path={[ProjectsRoutes.NEW_PROJECT, ProjectsRoutes.EDIT_PROJECT, ProjectsRoutes.TO_CONFIGURE.PROJECT]}
            >
                <ProjectsDataProjectDetailsSite
                    project={project}
                    projectId={projectId}
                    externalId={externalId}
                    projectUsers={projectUsers}
                    setProjectUsers={setProjectUsers}
                    projectCompanies={projectCompanies}
                    setProjectCompanies={setProjectCompanies}
                    confirmInternational={confirmInternational}
                    resetProjectUsers={resetProjectUsers}
                />
            </Route>
            <Route
                exact
                path={[
                    ProjectsRoutes.NEW_PROJECT_USERS,
                    ProjectsRoutes.EDIT_PROJECT_USERS,
                    ProjectsRoutes.TO_CONFIGURE.PROJECT_USERS,
                ]}
            >
                <ProjectsDataProjectUsersSite
                    isInternational={confirmInternational.isInternational}
                    project={project}
                    projectId={projectId}
                    externalId={externalId}
                    projectUsers={projectUsers}
                    setProjectUsers={setProjectUsers}
                    projectCompanies={projectCompanies}
                />
            </Route>
            <Route exact path={[ProjectsRoutes.NEW_PROJECT_COMPANIES]}>
                <ProjectsDataProjectCompaniesSite
                    projectCompanies={projectCompanies}
                    setProjectCompanies={setProjectCompanies}
                />
            </Route>
            <Route exact path={[ProjectsRoutes.NEW_PROJECT_FINISH]}>
                <ProjectsDataProjectFinishSite
                    project={project}
                    projectUsers={projectUsers}
                    projectCompanies={projectCompanies}
                    confirmInternational={confirmInternational}
                />
            </Route>
        </>
    );
};
