import { Button } from "@material-ui/core";
import { useState } from "react";
import { Redirect, useLocation } from "react-router";
import { t } from "../../../i18n/util";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { pushRoute } from "../../app/router/history";
import { NavBarBack } from "../../ui/NavBarBack";
import { PageWithStepper } from "../../ui/PageWithStepper";
import { AdvisorConfigCompaniesStepper } from "../AdvisorConfigCompaniesStepper";
import { AdvisorRoutes } from "../router/AdvisorRoutes";
import { BulkState, FileAndUrl, toFileAndUrl } from "../util/bulk";
import { CompanyImagesLocal } from "./AdvisorConfigCompanyDataSite";

export const AdvisorConfigBulkDataSite = () => {
    useHideSideBar();

    const { state } = useLocation<BulkState | undefined>();
    const companies = state?.companies;

    if (!state || !companies?.length) {
        // No companies in state -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    return <AdvisorConfigBulkDataSiteContent state={state} />;
};

const AdvisorConfigBulkDataSiteContent = ({ state }: { state: BulkState }) => {
    const [coverImage, setCoverImage] = useState<FileAndUrl | undefined>(state.coverImage);
    const [logoImage, setLogoImage] = useState<FileAndUrl | undefined>(state.logoImage);

    const { companies } = state;

    const updatedState: BulkState = {
        ...state,
        coverImage,
        logoImage,
    };

    return (
        <>
            <NavBarBack
                title={t("config.companies")}
                backLabel={t("config.companyOverview.inactive")}
                backTarget={AdvisorRoutes.COMPANIES.INACTIVE}
                companyName={t("config.companies.count", { count: companies.length })}
            />
            <PageWithStepper stepper={<AdvisorConfigCompaniesStepper state={updatedState} />}>
                <h1 style={{ marginBottom: 8, hyphens: "auto", wordBreak: "break-all" }}>
                    {t("config.bulk.data.title")}
                </h1>
                <p style={{ marginTop: 16, fontSize: 12 }}>{t("config.bulk.data.description")}</p>
                <CompanyImagesLocal
                    coverImageUrl={coverImage?.url}
                    onCoverImageChange={file => {
                        setCoverImage(toFileAndUrl(file));
                    }}
                    onCoverImageDelete={() => {
                        setCoverImage(undefined);
                    }}
                    logoImageUrl={logoImage?.url}
                    onLogoImageChange={file => {
                        setLogoImage(toFileAndUrl(file));
                    }}
                    onLogoImageDelete={() => {
                        setLogoImage(undefined);
                    }}
                    style={{ marginTop: 40 }}
                />
                <div style={{ marginTop: 32, textAlign: "right" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const termsOfUseState: BulkState = {
                                ...state,
                                companies,
                                coverImage,
                                logoImage,
                            };
                            pushRoute(AdvisorRoutes.CONFIG.BULK.TERMS_OF_USE, { state: termsOfUseState });
                        }}
                    >
                        {t("button.nextStep")}
                    </Button>
                </div>
            </PageWithStepper>
        </>
    );
};
