import compact from "lodash/compact";
import * as queryString from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router";

export function useQueryParams<
    Input = undefined,
    Result = Input extends undefined ? "Please provide a type" : Input,
>(options?: { arrayKeys?: string[] }) {
    const location = useLocation();
    return useMemo(() => {
        const result = queryString.parse(location.search, { parseBooleans: true });
        if (options?.arrayKeys) {
            options.arrayKeys.forEach(key => {
                let v = result[key];
                if (!Array.isArray(v)) {
                    // Convert single elements to array
                    v = v != null ? [v] : [];
                }
                result[key] = compact(v);
            });
        }

        return result as Partial<Result>;
        // eslint-disable-next-line react-hooks/exhaustive-deps -- arrayKeys is usually not memoized, hence directly insert into deps to react on changes
    }, [location.search, ...(options?.arrayKeys ?? [])]);
}
