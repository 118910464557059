import { Button } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { t } from "../../../i18n/util";
import { CompanyUsersToRelease } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { configStore } from "../../../stores/ConfigStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useCompaniesWaitingUsersTable, WaitingUserTable } from "../../shared/WaitingUserTable";
import { BulkRejectButton, BulkReleaseButton } from "../../ui/BulkActionButton";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { pushRoute, withParams } from "../router/history";
import { Routes } from "../router/Routes";

export const CompaniesUserReleaseSite = observer(function CompaniesUserReleaseSite() {
    const waitingUsers = companiesStore.waitingUsers;
    if (!waitingUsers) {
        // not loaded, keep waiting
        return null;
    }
    if (!authStore.isAuthenticated || !waitingUsers.length) {
        // not authenticated or no waiting users, redirect to company overview
        return <Redirect to={Routes.COMPANY_OVERVIEW} />;
    }

    return <CompaniesUserReleaseContent waitingUsers={waitingUsers} />;
});

interface CompaniesUserReleaseContentProps {
    waitingUsers: CompanyUsersToRelease[];
}

const CompaniesUserReleaseContent = observer(function CompaniesUserReleaseContent({
    waitingUsers,
}: CompaniesUserReleaseContentProps) {
    const loadWaitingUsers = async () => {
        try {
            generalStore.isLoading = true;
            await companiesStore.loadWaitingUsers();

            tableStore.selectedItems.clear();
        } catch (err) {
            generalStore.setError(t("error.loadUsers"), err);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const config = useCompaniesWaitingUsersTable(waitingUsers, loadWaitingUsers);
    const { tableStore, releaseUsers, rejectUsers, releaseSuccessDialog, rejectSuccessDialog } = config;

    return (
        <>
            <NavBarBack
                title={t("companiesUserRelease.title")}
                backLabel={t("common.companyOverview")}
                backTarget={Routes.COMPANY_OVERVIEW}
            />
            <CenteredContent>
                <SiteContent>
                    <div style={{ textAlign: "right", marginBottom: 8 }}>
                        <Button color="primary" onClick={() => loadWaitingUsers()} endIcon={<RefreshIcon />}>
                            {t("common.refresh")}
                        </Button>
                    </div>
                    <TableSearchBarWithAction
                        label="search.caption.numUsers"
                        labelSelected="search.caption.numSelected"
                        placeholder="search.placeholder.searchForUsers"
                        select={tableStore}
                        search={tableStore.search}
                        totalCount={tableStore.totalCount}
                        onChangeSearch={tableStore.handleSearchChange}
                        bulkAction={
                            <>
                                <BulkReleaseButton
                                    onClick={() => {
                                        releaseUsers(tableStore.getAllSelectedItems());
                                    }}
                                />
                                <BulkRejectButton
                                    onClick={() => {
                                        rejectUsers(tableStore.getAllSelectedItems());
                                    }}
                                />
                            </>
                        }
                    />
                    <WaitingUserTable
                        config={config}
                        onEdit={user => {
                            configStore.selectedEmployees = [{ ...user, type: "user" }];
                            pushRoute(withParams(Routes.COMPANIES_USERS_RELEASE_ROLES, { companyId: user.company.id }));
                        }}
                    />
                    <tableStore.Pagination />
                </SiteContent>
            </CenteredContent>
            {releaseSuccessDialog.dialog}
            {rejectSuccessDialog.dialog}
        </>
    );
});
