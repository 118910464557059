import { Button } from "@material-ui/core";
import * as React from "react";
import { VCenter } from "../ui/Primitives";
import { EmptyDocumentsImage } from "../util/Images";
import { customColors } from "../util/Theme";

export const EmptyState = (props: {
    title: string;
    message?: React.ReactNode;
    buttonLabel?: string;
    onAddEntry?: () => void;
    secondButtonLabel?: string;
    secondOnAddEntry?: () => void;
    disabled?: boolean;
    image?: React.ReactNode;
}) => {
    const showButton = props.buttonLabel && props.onAddEntry;
    const secondButton = showButton && props.secondButtonLabel && props.secondOnAddEntry;
    return (
        <VCenter style={{ alignItems: "center" }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <div style={{ marginBottom: 40 }}>{props.image ? props.image : <EmptyDocumentsImage />}</div>

                <h2 style={{ color: customColors.body1Dark }}>{props.title}</h2>
                {props.message && (
                    <p className="body1" style={{ margin: "16px 0px 24px 0px", textAlign: "center" }}>
                        {props.message}
                    </p>
                )}
                {secondButton && (
                    <>
                        <div style={{ display: "flex" }}>
                            <Button
                                onClick={props.onAddEntry}
                                color="primary"
                                variant="outlined"
                                style={{ marginRight: 11 }}
                                disabled={props.disabled}
                            >
                                {props.buttonLabel}
                            </Button>
                            <Button
                                onClick={props.secondOnAddEntry}
                                color="primary"
                                variant="contained"
                                disabled={props.disabled}
                            >
                                {props.secondButtonLabel}
                            </Button>
                        </div>
                    </>
                )}
                {!secondButton && showButton && (
                    <Button onClick={props.onAddEntry} color="primary" variant="contained" disabled={props.disabled}>
                        {props.buttonLabel}
                    </Button>
                )}
            </div>
        </VCenter>
    );
};
