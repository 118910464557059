import { observable } from "mobx";
import { ExternalDocument } from "../../network/APITypes";
import { ICompany } from "../../types/models";

class WebhookStore {
    // Don't need to be observable, only used for data sharing to detail site
    company?: ICompany;
    document?: ExternalDocument;
}

export const webhookStore = new WebhookStore();
