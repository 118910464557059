import * as React from "react";
import { t } from "../../i18n/util";
import { ConfirmationDialog } from "../ui/ConfirmationDialog";

export const useNoPermissionSelectedDialog = () => {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const component = (
        <ConfirmationDialog
            open={open}
            title={t("config.permissions.noPermissionSelected.title")}
            message={t("config.permissions.noPermissionSelected.message")}
            confirmLabel={t("common.ok")}
            onConfirm={() => {
                handleClose();
            }}
        />
    );

    return {
        open() {
            setOpen(true);
        },

        component,
    };
};
