import { Button } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { ic_microsoft } from "../util/Images";
import { customColors } from "../util/Theme";

export const MicrosoftSignInButton = ({
    variant = "login",
    type,
    onClick,
    style,
    disabled,
}: {
    variant?: "login" | "upgrade" | "send_code";
    type?: "submit";
    onClick?: () => void;
    style?: React.CSSProperties;
    disabled?: boolean;
}) => {
    return (
        <Button
            variant="contained"
            fullWidth
            style={{
                color: "#FFFFFF",
                backgroundColor: disabled ? customColors.disabled : "#2F2F2F",
                flexShrink: 0,
                ...style,
            }}
            type={type}
            disabled={disabled}
            onClick={!disabled ? onClick : undefined}
        >
            <img src={ic_microsoft} alt="" />
            <div
                style={{
                    fontWeight: "normal",
                    fontSize: 15,
                    marginLeft: 13,
                }}
            >
                {variant === "login" && t("microsoft_oauth.button.login.title")}
                {variant === "upgrade" && t("microsoft_oauth.button.upgrade.title")}
                {variant === "send_code" && t("microsoft_oauth.button.send_code.title")}
            </div>
        </Button>
    );
};
