import { Fab } from "@material-ui/core";
import * as React from "react";
import { t } from "../../../../i18n/util";
import { Record } from "../../../../network/APITypes";
import { getApiError } from "../../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../../network/httpStatusCode";
import { companiesStore } from "../../../../stores/CompaniesStore";
import { generalStore } from "../../../../stores/GeneralStore";
import { ModuleStore } from "../../../../stores/ModuleStore";
import { IRecordType } from "../../../../types/models";
import { pushRoute } from "../../../app/router/history";
import { useSuccessDialog } from "../../../hooks/useSuccessDialog";
import { ErrorDialog } from "../../../ui/ErrorDialog";
import { Icon } from "../../../util/Icon";
import { TransferRecordsDialog } from "../../ModuleDialogs";

interface UseTransferRecordsProps {
    recordType?: IRecordType;
    store: ModuleStore;
    disabled: boolean;
    transfer: () => Promise<void>;
}

export function useTransferRecords({ recordType, store, disabled, transfer }: UseTransferRecordsProps) {
    const [showTransferDialog, setShowTransferDialog] = React.useState(false);
    const [showRetryTransferDialog, setShowRetryTransferDialog] = React.useState(false);
    const [failedFile, setFailedFile] = React.useState("");

    const successDialog = useSuccessDialog({
        title: t("screen.accounting.records.transfer.successDialog.title"),
        message: t("screen.accounting.records.transfer.successDialog.text"),
        onClose: () => {
            pushRoute(store.routes.ROOT);
        },
    });

    const handleOpenTransferDialog = () => {
        setShowTransferDialog(true);
    };

    const handleCloseTransferDialog = () => {
        setShowTransferDialog(false);
    };

    const handleCloseRetryTransferDialog = () => {
        setShowRetryTransferDialog(false);
    };

    const handleTransferRecords = async () => {
        try {
            setShowTransferDialog(false);
            setShowRetryTransferDialog(false);
            generalStore.isLoading = true;

            await transfer();

            try {
                // Reload period and record types to reflect correct state in WelcomeCard
                await store.loadRecordTypes(companiesStore.selectedCompanyId);
                await store.reloadCurrentPeriod();
            } catch (err) {
                generalStore.setError(t("error.loadRecordTypes"), err);
            }

            // Reload badges to reflect due dates
            await companiesStore.selectedCompanyStore?.startPollingBadges();

            successDialog.openDialog();
        } catch (error) {
            const apiError = getApiError<Record>(error);
            if (apiError?.statusCode === HttpStatusCode.UnprocessableEntity_422 && "document" in apiError.response) {
                setFailedFile(`"${apiError.response.document?.name ?? ""}"`);
            } else {
                setFailedFile("");
            }

            setShowTransferDialog(false);
            setShowRetryTransferDialog(true);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const subsidiaryStatus = store.calculateSubsidiaryStatus();

    let button: React.ReactNode = null;
    if (subsidiaryStatus?.status !== "closed") {
        button = (
            <Fab
                onClick={handleOpenTransferDialog}
                color="primary"
                variant="extended"
                data-id="open_transfer_dialog_fab"
                disabled={disabled || store.selectedPeriod?.finished === true}
            >
                <Icon name="submit" style={{ marginRight: 8 }} />
                <div style={{ fontSize: 14 }}>{t("screen.accounting.records.transfer.button.text")}</div>
            </Fab>
        );
    }

    const dialogs = (
        <>
            <TransferRecordsDialog
                moduleStore={store}
                open={showTransferDialog}
                onClose={handleCloseTransferDialog}
                onSubmit={handleTransferRecords}
                period={store.selectedPeriod}
                recordType={recordType}
            />
            <ErrorDialog
                open={showRetryTransferDialog}
                onClose={handleCloseRetryTransferDialog}
                onSubmit={handleTransferRecords}
                title={t("screen.accounting.records.transfer.retryDialog.title")}
                message={
                    failedFile
                        ? t("screen.accounting.records.transfer.retryDialog.file", { failedFile })
                        : t("screen.accounting.records.transfer.retryDialog.text")
                }
            />
            {successDialog.dialog}
        </>
    );

    return {
        button,
        dialogs,
    };
}
