import { Button } from "@material-ui/core";
import * as React from "react";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { CustomDialog } from "./CustomDialog";

export const SuccessDialog = (props: {
    open: boolean;
    title: React.ReactNode;
    message: React.ReactNode;
    buttonLabel?: string;
    onClose: () => void;
}) => {
    return (
        <CustomDialog
            open={props.open}
            mobileFullScreen={false}
            onClose={props.buttonLabel ? undefined : props.onClose}
            showCloseIcon
        >
            <div
                style={{
                    padding: "36px 42px 56px 42px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Icon style={{ width: 200, height: 130, marginBottom: 24 }} name="success" />
                <h1 style={{ marginBottom: 16, textAlign: "center", hyphens: "auto" }}>{props.title}</h1>
                <p style={{ textAlign: "center" }}>{props.message}</p>
            </div>
            {props.buttonLabel && (
                <Button
                    variant="contained"
                    fullWidth
                    style={{
                        marginTop: 8,
                        borderRadius: 0,
                        backgroundColor: customColors.primaryColor,
                        color: "white",
                    }}
                    onClick={props.onClose}
                >
                    {props.buttonLabel}
                </Button>
            )}
        </CustomDialog>
    );
};
