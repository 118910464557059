import { Routes } from "../../app/router/Routes";

const prefix = Routes.prefixed(Routes.HR);

export const HrRoutes = {
    ROOT: prefix("/"),
    EMPLOYEES: {
        ROOT: prefix("/employees"),
        DETAILS: prefix("/employees/:employeeId/details"),
        TICKET_DETAILS: prefix("/employees/tickets/:ticketId/details"),
        DOCUMENTS: prefix("/employees/:employeeId/documents"),

        LIST: prefix("/employees/list"),
        TICKETS: prefix("/employees/list/tickets"),
        IN_PROGRESS: prefix("/employees/list/in-progress"),
        CURRENT: prefix("/employees/list/current"),
        FORMER: prefix("/employees/list/former"),
        ALL_DOCUMENTS: prefix("/employees/documents"),

        // employeeId is passed as query param here because initial registration has not employeeId
        REGISTRATION_PERSONAL_DATA: prefix("/employees/registration/personal"),
        REGISTRATION_ADDITIONAL_DATA: prefix("/employees/:employeeId/registration/additional"),
        REGISTRATION_DOCUMENTS: prefix("/employees/:employeeId/registration/documents"),
        REGISTRATION_SUMMARY: prefix("/employees/:employeeId/registration/summary"),

        DEREGISTRATION_DATA: prefix("/employees/:employeeId/deregistration/data"),
        DEREGISTRATION_DOCUMENTS: prefix("/employees/:employeeId/deregistration/documents"),

        UPLOAD_PAYSLIP: prefix("/employees/upload-payslip"),
    },
    RECORDS: {
        ROOT: prefix("/records"),
        LIST: prefix("/records/:recordTypeId/list"),
        TICKETS: prefix("/records/:recordTypeId/tickets"),
        TICKET_DETAILS: prefix("/records/:recordTypeId/tickets/:ticketId"),
        TYPES: prefix("/records/types"),
        UPLOAD: prefix("/records/:recordTypeId/upload"),
        DETAILS_UPLOAD: prefix("/records/:recordTypeId/details/:recordId/upload"),
        DETAILS_EDIT: prefix("/records/:recordTypeId/details/:recordId/edit"),
    },
    REPORT_TICKET: prefix("/reports/:reportId/ticket"),
    REPORT_UPLOAD: prefix("/reports/upload"),
};
