import { InputAdornment, TextField } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import debounce from "lodash/debounce";
import * as React from "react";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

export const SearchField = (props: {
    placeholder?: string;
    value?: string;
    "data-id": string;
    style?: React.CSSProperties;
    debounceMs?: number;
    onChange: (value: string) => void;
    onFocus?: () => void;
    onEnter?: (value: string) => void;
}) => {
    const [value, setValue] = React.useState(props.value ?? "");

    // Optional debounce
    const { debounceMs, onChange } = props;
    const onSearchChanged = React.useMemo(
        () =>
            debounceMs
                ? debounce((value: string) => {
                      onChange(value);
                  }, debounceMs)
                : onChange,
        [onChange, debounceMs],
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        onSearchChanged(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && props.onEnter) {
            props.onEnter(value);
        }
    };

    const handleReset = () => {
        setValue("");
        props.onChange("");
    };

    return (
        <TextField
            inputProps={{ "data-id": props["data-id"], maxLength: 500 }}
            onChange={handleChange}
            onFocus={props.onFocus}
            onKeyPress={handleKeyPress}
            variant="outlined"
            placeholder={props.placeholder}
            autoComplete="off"
            value={value}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Icon name="search" style={{ color: customColors.greyDarkIcons }} />
                    </InputAdornment>
                ),
                endAdornment: value ? (
                    <InputAdornment position="end" style={{ cursor: "pointer" }} onClick={handleReset}>
                        <Close color="primary" fontSize="small" />
                    </InputAdornment>
                ) : null,
            }}
            style={props.style}
        />
    );
};
