import { useVendorListing } from "./hooks/useVendorListing";
import { ResultsTable } from "./ResultsTable";
import { ResultsSiteViewProps } from "./types";

interface ResultsVendorListingProps extends ResultsSiteViewProps {}

export const ResultsVendorListing = ({ companyId, settings, financialAccountancy }: ResultsVendorListingProps) => {
    const state = useVendorListing(companyId, settings);
    return state && financialAccountancy ? (
        <ResultsTable
            type="vendorListing"
            columns={state.columns}
            rows={state.rows}
            stylesheet={state.stylesheet}
            financialAccountancy={financialAccountancy}
        />
    ) : null;
};
