import isEmpty from "lodash/isEmpty";

// Application specific configuration variables
// potentially sourced from import.meta.env.VITE_APP_*

// Variables defined in `public/config.js`, created from environment variables via `docker-entrypoint.sh` script on Docker container start
// Will override "env" variables defined at runtime if set
declare const ENV_API_BASE_URL: string;
declare const ENV_BASE_NAME: string;

export const API_BASE_URL = (
    isEmpty(ENV_API_BASE_URL) ? import.meta.env.VITE_APP_API_BASE_URL : ENV_API_BASE_URL
) as string;
export const BASE_NAME = (
    isEmpty(ENV_BASE_NAME) ? (import.meta.env.VITE_APP_BASE_NAME ?? "") : ENV_BASE_NAME
) as string;
export const PUBLIC_URL = window.location.origin + BASE_NAME;

export const DEPLOYMENT_ENV = import.meta.env.VITE_APP_DEPLOYMENT_ENV as string;
export const IS_DEV = DEPLOYMENT_ENV === "dev:optimized";
export const IS_STAGING = DEPLOYMENT_ENV === "staging:optimized";
export const IS_PROD = DEPLOYMENT_ENV === "prod:optimized";
export const IS_FINAL = DEPLOYMENT_ENV === "final:optimized";
export const IS_LOCAL = window.location.href.startsWith("http://localhost");

// TPAPORTAL-1947: If we are not using any of the known environments then it's a demo
export const IS_DEMO =
    API_BASE_URL !== "https://tpa-portal-dev.allaboutapps.at" &&
    API_BASE_URL !== "https://portal-staging.its-tpa.com" &&
    API_BASE_URL !== "https://tpa-portal-final.allaboutapps.at" &&
    API_BASE_URL !== "https://portal.tpa-connect.app";

export const DEBUG = true;

export const ROWS_PER_PAGE = 9;

export const AUTO_REDIRECT_TIMEOUT = 2000;

export const SNACKBAR_DURATION = 5000;

export const MAX_IMAGE_DIMENSION = 3000;

export const SEARCH_DEBOUNCE_MS = 500;
export const RESIZE_DEBOUNCE_MS = 500;
export const SCROLL_DEBOUNCE_MS = 100;

export const LOADING_INDICATOR_DELAY_MS = 300;

export const MOBILE_BREAKPOINT = 1000;
export const CENTERED_CONTENT_RIGHT_OFFSET = 1200;

export const NOTIFICATION_CENTER_SCROLL_LOAD_THRESHOLD = 100;
export const NOTIFICATION_CENTER_WIDTH = 450;
export const HELP_CENTER_WIDTH = 450;

export const NAVBAR_HEIGHT = 100;
export const NAVBAR_HEIGHT_MOBILE = 86;
export const NAVBAR_INFO_HEIGHT = 56;
export const TABBAR_HEIGHT = 36;

export const KB_SIZE = 1024;
export const MB_SIZE = 1024 * KB_SIZE;

export const MAX_UPLOAD_SIZE_MB = 50;
export const MAX_UPLOAD_SIZE = MAX_UPLOAD_SIZE_MB * MB_SIZE;

export const MAX_IMAGE_UPLOAD_SIZE_MB = 10;
export const MAX_IMAGE_UPLOAD_SIZE = MAX_IMAGE_UPLOAD_SIZE_MB * MB_SIZE;
/** Amount of MB when to create an upload session for project item uploads instead of sending the file directly to the backend */
export const PROJECT_UPLOAD_FILE_SIZE_CUTOFF = 40 * MB_SIZE;
/** Amount of MB transferred per chunk in an upload session */
export const PROJECT_UPLOAD_FRAGMENT_SIZE = 50 * MB_SIZE;

export const MAX_OPTIONAL_ITEM_NAME_LENGTH = 15;

export const MFA_CODE_LENGTH = 6;

const ACCEPTED_DOC_FORMATS = ".doc,.docx,.odt";
const ACCEPTED_XLS_FORMATS = ".xls,.xlsx,.ods,.csv";
const ACCEPTED_PPT_FORMATS = ".ppt,.pptx,.odp";
const ACCEPTED_MAIL_FORMATS = ".eml,.msg";

// Used for image only uploads
export const ACCEPTED_IMAGE_FORMATS = ".jpg,.jpeg,.png";

// Used for all uploads except projects (CANNOT put isIos() check here because we need the list for the help text)
export const ACCEPTED_FILE_FORMATS = `${ACCEPTED_IMAGE_FORMATS},.pdf,.txt,.xml,.json,${ACCEPTED_MAIL_FORMATS},${ACCEPTED_DOC_FORMATS},${ACCEPTED_XLS_FORMATS}`;

// Used for project uploads (CANNOT put isIos() check here because we need the list for the help text)
export const ACCEPTED_PROJECT_FORMATS = `${ACCEPTED_FILE_FORMATS},${ACCEPTED_PPT_FORMATS},.fib,.fibdoc,.url`;

export const CARD_SEND_DATE = false;

export const COMPANY_LIMIT = 50; //Default Limit of Loaded Companies
export const MAX_SUBSIDIARIES = 99; //Max amount of Subsidiaries per Company

export const DEFAULT_STAFF_CAN_READ = true;

// The backend specifies a max length of 15000 characters for various chat/ticket/draft messages.
// However the client sends a string that might contain HTML because of our WYSIWYG editor.
// Since this HTML is hidden to the user, we reserve around 50% of the characters for this HTML markup.
export const MAX_CHAT_CHARACTERS = 15000 / 2;

export const SHARE_POINT_CALL_DELAY_MS = 1000;

// Ignore finished flag for finished periods from BE
// Set to false, once BE implements correct FIBU handling
export const IGNORE_PERIOD_FINISHED = false;

export const LINK_LEGAL = "https://www.tpa-connect.app/impressum/";
export const LINK_PRIVACY = "https://www.tpa-connect.app/datenschutz-tpa-connect-app/";
export const LINK_COOKIES = "https://www.tpa-connect.app/cookie-erklarung-tpa-connect-app/";

export const TELEPHONE_NUMBER = "+43 1 588 35-0";
export const FAX_NUMBER = "+43 1 588 35-500";

// Toggle to test MS token refresh easier without needing an expired token
// Useful for e.g. TPAPORTAL-2415
export const TEST_MS_TOKEN_REFRESH_OFFLINE = false;

const second = 1000;
const minute = 60 * second;
export const POLL_PENDING_MS_ACTIONS_MS = 5 * second; // 5 seconds
export const POLLING_COMPANY_BADGES_MS = 5 * minute; // 5 minutes
export const POLLING_ACTIVE_CHAT_MESSAGES_MS = 1 * minute; // 1 minute
export const POLLING_MIGRATING_PROJECTS_MS = 10 * second; // 10 seconds
export const POLLING_BANK_CONNECTIONS_MS = 10 * second; // 10 seconds

// Used for signing terms page to limit the zoom factor
export const ZOOM_DEFAULT = 1;
export const ZOOM_IN_THRESHOLD = 4.7;
export const ZOOM_OUT_THRESHOLD = 0.2;

export const WEBHOOK_CURRENT_EMPLOYEES_ACTIVE_DAYS = 30;

export const BANKING_REFRESHING_CONNECTION_DATE = "0001-01-01T00:00:00.000Z";

export const CHAT_SUPPORTED_URL_PROTOCOLS = ["http://", "https://", "ftp://", "mailto:", "tel:", "file://"];

export const POPUP_WINDOW_FEATURES = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

export const BMD_DESTINATION_ID = "81542026-c164-4eb8-9098-5898b0458d75";
