import { getFileExtension } from "../../../util/files";
import { Renderer, ViewerFile } from "../types";
import { ImageRenderer } from "./ImageRenderer";
import { OfficeRenderer } from "./OfficeRenderer";
import { PdfRenderer } from "./PdfRenderer";
import { TextRenderer } from "./TextRenderer";
import { UnsupportedRenderer } from "./UnsupportedRenderer";

export const renderers: Record<string, Renderer> = {
    // pdf
    pdf: {
        Component: PdfRenderer,
        canZoom: true,
    },

    // images
    jpg: {
        Component: ImageRenderer,
        canZoom: true,
    },
    jpeg: {
        Component: ImageRenderer,
        canZoom: true,
    },
    png: {
        Component: ImageRenderer,
        canZoom: true,
    },
    gif: {
        Component: ImageRenderer,
        canZoom: true,
    },
    bmp: {
        Component: ImageRenderer,
        canZoom: true,
    },

    // office files
    docx: {
        Component: OfficeRenderer,
        canZoom: false,
        fullscreen: true,
    },
    doc: {
        Component: OfficeRenderer,
        canZoom: false,
        fullscreen: true,
    },
    xlsx: {
        Component: OfficeRenderer,
        canZoom: false,
        fullscreen: true,
    },
    xls: {
        Component: OfficeRenderer,
        canZoom: false,
        fullscreen: true,
    },
    pptx: {
        Component: OfficeRenderer,
        canZoom: false,
        fullscreen: true,
    },
    ppt: {
        Component: OfficeRenderer,
        canZoom: false,
        fullscreen: true,
    },
    odt: {
        Component: OfficeRenderer,
        canZoom: false,
        fullscreen: true,
    },
    ods: {
        Component: OfficeRenderer,
        canZoom: false,
        fullscreen: true,
    },
    odp: {
        Component: OfficeRenderer,
        canZoom: false,
        fullscreen: true,
    },

    // text based
    txt: {
        Component: TextRenderer,
        canZoom: false,
    },
    xml: {
        Component: TextRenderer,
        canZoom: false,
    },
    json: {
        Component: TextRenderer,
        canZoom: false,
    },

    // unsupported
    "*": {
        Component: UnsupportedRenderer,
        canZoom: false,
    },
};

export function rendererForFile(file: ViewerFile): Renderer {
    return rendererForFileName(file.name);
}
export function rendererForFileName(fileName: string): Renderer {
    const ext = getFileExtension(fileName).toLowerCase();
    const renderer = renderers[ext];
    return renderer || renderers["*"];
}
