import { useCashAccounting } from "./hooks/useCashAccounting";
import { ResultsTable } from "./ResultsTable";
import { ResultsSiteViewProps } from "./types";

interface ResultsCashAccountingProps extends ResultsSiteViewProps {}

export const ResultsCashAccounting = ({ companyId, settings, financialAccountancy }: ResultsCashAccountingProps) => {
    const state = useCashAccounting(companyId, settings);
    return state && financialAccountancy ? (
        <ResultsTable
            type="cashAccounting"
            columns={state.columns}
            rows={state.rows}
            stylesheet={state.stylesheet}
            financialAccountancy={financialAccountancy}
        />
    ) : null;
};
