import * as React from "react";
import { Redirect, useParams } from "react-router-dom";
import { companiesStore } from "../../../stores/CompaniesStore";
import { SIDEBAR_WIDTH_OPEN } from "../../../stores/SideBarStore";
import { debug } from "../../../util/debug";
import { useOptionalSideBarMenuItems } from "../../hooks/useOptionalSideMenuItems";
import { NavBarBack } from "../../ui/NavBarBack";
import { MobileContext } from "../../util/MobileContext";
import { Routes } from "../router/Routes";

export const OptionalMenuItemSite = () => {
    const isMobile = React.useContext(MobileContext);
    const { optionalSideBarMenuItems } = useOptionalSideBarMenuItems(companiesStore.selectedCompanyId);
    const { name } = useParams<{ name: string }>();

    const currentItem = optionalSideBarMenuItems?.find(
        optionalSideBarMenuItem => optionalSideBarMenuItem.name === name,
    );

    debug.log("### connecting to", currentItem?.url);

    if (optionalSideBarMenuItems && !currentItem) {
        return <Redirect to={Routes.NOT_FOUND} />;
    }

    return (
        <>
            <NavBarBack title={name} />
            {currentItem && (
                <iframe
                    seamless
                    style={{
                        paddingLeft: isMobile ? undefined : SIDEBAR_WIDTH_OPEN,
                        height: "100%",
                        width: "100%",
                        border: "none",
                        flexGrow: 1,
                    }}
                    title="externaliframe"
                    src={currentItem.url}
                    sandbox="allow-popups allow-same-origin allow-scripts"
                />
            )}
        </>
    );
};
