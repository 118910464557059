import { Button } from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { PatchRecordPayload } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { getModuleStore } from "../../stores/moduleStores";
import { IDocument, Module } from "../../types/models";
import { getFileExtension } from "../../util/files";
import { useDeleteRecords } from "../hooks/useDeleteRecords";
import { CustomInputField } from "../ui/CustomInputField";
import { CustomSelect } from "../ui/CustomSelect";
import { DocumentDetails } from "../ui/DocumentDetails";
import { RenameDialog } from "../ui/RenameDialog";

interface IRecordDetails {
    costCenter: string;
    comment: string;
}

export const ModuleRecordsDetails = observer(function ModuleRecordsDetails(props: {
    module: Module;
    document: Partial<IDocument>;
    recordId: string;
    recordTypeId: string;
    costCenter?: string;
    comment?: string;
    current?: number;
    updatedAt?: Date;
    total?: number;
    onSubmit: () => void;
    onCancel?: () => void;
}) {
    const moduleStore = getModuleStore(props.module);
    const [showRenameDialog, setShowRenameDialog] = React.useState(false);
    const [documentName, setDocumentName] = React.useState(props.document.name ?? "");
    const companyId = companiesStore.selectedCompanyId;

    const propsName = props.document.name;
    React.useEffect(() => {
        if (propsName) {
            setDocumentName(propsName);
        }
    }, [propsName]);

    const recordTypeId = props.recordTypeId;
    const subsidiaryId = moduleStore.selectedSubsidiaryId;
    const periodId = moduleStore.selectedPeriodId;
    const deleteRecord = useDeleteRecords({
        companyId,
        module: props.module,
        subsidiaryId,
        periodId,
        recordTypeId,
        onDelete() {
            props.onSubmit();
        },
    });

    const handleShowRenameDialog = () => {
        setShowRenameDialog(true);
    };

    const handleCloseRenameDialog = () => {
        setShowRenameDialog(false);
    };

    const handleSubmitRenameDialog = (name: string) => {
        const extension = getFileExtension(props.document.name ?? "");
        if (extension) {
            setDocumentName(`${name}.${extension}`);
        } else {
            setDocumentName(name);
        }

        setShowRenameDialog(false);
    };

    const handleRecordDownload = async () => {
        if (!companyId || !subsidiaryId || !periodId || !recordTypeId) {
            return;
        }

        try {
            await API.putDownloadRecords({
                companyId,
                module: props.module,
                subsidiaryId,
                periodId,
                recordTypeId,
                recordIds: [props.recordId],
            });
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        }
    };

    const onSubmit = async (model: IRecordDetails, formikHelpers: FormikHelpers<IRecordDetails>) => {
        try {
            generalStore.isLoading = true;
            const body: PatchRecordPayload = {
                costCenter: model.costCenter ? model.costCenter : undefined,
                comment: model.comment,
                documentName: documentName,
            };

            await API.patchRecord(body, {
                companyId,
                module: props.module,
                subsidiaryId,
                periodId,
                recordTypeId,
                recordId: props.recordId,
            });

            formikHelpers.resetForm();
            props.onSubmit();
        } catch (err) {
            generalStore.setError(t("error.edit"), err);
        } finally {
            generalStore.isLoading = false;
        }
    };

    if (moduleStore.costCenters.state !== "success") {
        return null;
    }

    const { costCenters } = moduleStore.costCenters.data;

    let initialCostCenter = props.costCenter;
    if (initialCostCenter) {
        initialCostCenter = moduleStore.getCostCenter(initialCostCenter, true)?.id;
    }
    if (!initialCostCenter) {
        if (costCenters.length > 0) {
            initialCostCenter = costCenters[0].id;
        }
    }

    const isLoading = generalStore.isLoading;

    return (
        <DocumentDetails
            document={{ ...props.document, name: documentName }}
            current={props.current}
            updatedAt={props.updatedAt}
            total={props.total}
            onDelete={() => {
                deleteRecord.handleDeleteRecords([{ id: props.recordId, document: props.document }]);
            }}
            onEdit={handleShowRenameDialog}
            onDownload={handleRecordDownload}
        >
            <Formik
                initialValues={{
                    costCenter: initialCostCenter ?? "",
                    comment: props.comment ?? "",
                }}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                    costCenter: Yup.string().trim(),
                    comment: Yup.string().trim(),
                })}
                validateOnBlur
                validateOnChange
            >
                {formikProps => {
                    return (
                        <Form>
                            {props.module === "accounting" && costCenters.length > 0 && (
                                <Field
                                    data-id="cost_center"
                                    component={CustomSelect}
                                    label={t("table.label.costCenter")}
                                    name="costCenter"
                                    options={costCenters.map(costCenter => {
                                        return {
                                            value: costCenter.id,
                                            label: moduleStore.getCostCenterLabel(costCenter),
                                        };
                                    })}
                                    required
                                />
                            )}
                            <Field
                                data-id="comment"
                                component={CustomInputField}
                                label={t("table.label.comment")}
                                name="comment"
                                required
                                style={{ marginBottom: 8 }}
                            />
                            <div style={{ textAlign: "right" }}>
                                {props.total && (
                                    <Button
                                        data-id="skip"
                                        color="primary"
                                        style={{ marginRight: 16, textTransform: "uppercase" }}
                                        onClick={props.onCancel}
                                    >
                                        {t("accounting.details.upload.skip")}
                                    </Button>
                                )}
                                <Button
                                    data-id="upload_apply"
                                    variant="contained"
                                    color="primary"
                                    disabled={isLoading}
                                    type="submit"
                                >
                                    {t("common.apply")}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {deleteRecord.dialog}
            <RenameDialog
                open={showRenameDialog}
                currentName={documentName}
                variant="document"
                onClose={handleCloseRenameDialog}
                onSubmit={handleSubmitRenameDialog}
            />
        </DocumentDetails>
    );
});
