import * as React from "react";
import { t } from "../../../i18n/util";
import { generalStore } from "../../../stores/GeneralStore";
import { useQueryParams } from "../../hooks/useQueryParams";
import { WebhookDocumentTicketsSite } from "./WebhookDocumentTicketsSite";
import { WebhookPreRegistrationTicketsSite } from "./WebhookPreRegistrationTicketsSite";

export const WebhookTicketsSite = () => {
    const query: {
        archive?: string;
        documentId?: string[];
        databox?: string[];
        employeeRegistration?: string;
    } = useQueryParams({ arrayKeys: ["documentId", "databox"] });

    const isArchive = query.archive && query.documentId && query.documentId.length > 0;
    const isDatabox = query.databox && query.databox.length > 0;
    if (isArchive || isDatabox) {
        return <WebhookDocumentTicketsSite />;
    } else if (query.employeeRegistration) {
        return <WebhookPreRegistrationTicketsSite />;
    } else {
        generalStore.setError(t("error.invalidWebhookParams"));
        return null;
    }
};
