import CircularProgress from "@material-ui/core/CircularProgress";
import * as React from "react";
import styled from "styled-components";
import { BOX_SHADOW_LIGHT } from "../util/Theme";

const LoadingOverlayRoot = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 9999;
`;

export const LoadingOverlay = (props: { delayMs?: number }) => {
    const [show, setShow] = React.useState(false);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, props.delayMs ?? 0);
        return () => {
            clearTimeout(timeout);
        };
    }, [props.delayMs]);

    if (!show) {
        return null;
    }

    return (
        <LoadingOverlayRoot className="mui-fixed">
            <LoadingIndicator />
        </LoadingOverlayRoot>
    );
};

const LoadingIndicatorRoot = styled.div`
    background-color: white;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${BOX_SHADOW_LIGHT};
`;

export const LoadingIndicator = () => (
    <LoadingIndicatorRoot>
        <CircularProgress color="primary" />
    </LoadingIndicatorRoot>
);
