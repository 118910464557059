import { Button } from "@material-ui/core";
import React, { Suspense, useEffect } from "react";
import LineEllipsis from "react-lines-ellipsis";
import { t } from "../../i18n/util";
import { debug } from "../../util/debug";
import {
    A4_WIDTH,
    ISignatureUser,
    ISignatureUserSignature,
    SIGNATURE_HEIGHT,
    SIGNATURE_WIDTH,
    getSignatureUserName,
} from "../../util/qes";
import { getFullName } from "../../util/user";
import { useWindowSize } from "../hooks/useWindowSize";
import { LazyDocument, LazyPage } from "../libs/react-pdf";
import { DocumentPagination } from "../ui/DocumentPagination";
import { LoadingOverlay } from "../ui/LoadingOverlay";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";

export const PdfViewer = ({
    onSelectSignaturePosition,
    loadUrl,
    file,
    currentUser,
    hideUserName,
}: {
    loadUrl?: () => Promise<string | undefined>;
    file?: File;
    onSelectSignaturePosition: (signaturePositions: ISignatureUserSignature[]) => void;
    currentUser?: ISignatureUser;
    hideUserName?: boolean;
}) => {
    const [numPages, setNumPages] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [pageSizes, setPageSizes] = React.useState<number[][]>([]);
    const [pdfUrl, setPdfUrl] = React.useState<string>();

    const onDocumentLoadSuccess = (numPages: number) => {
        setNumPages(numPages);
    };

    const size = useWindowSize();
    const isMobile = React.useContext(MobileContext);

    const loadDocument = React.useCallback(async () => {
        if (!loadUrl) {
            return;
        }
        const url = await loadUrl();
        setPdfUrl(url);
        setSignaturePositions([]);
        setPage(0);
    }, [loadUrl]);

    useEffect(() => {
        loadDocument();
    }, [loadDocument]);

    const width = isMobile ? (size.width ?? 0) : 500;

    let pageWidth = pageSizes[page]?.[0];
    // Fallback to A4 if you couldn't read the width or it is 0
    if (!pageWidth) {
        pageWidth = A4_WIDTH;
    }

    const scale = width / pageWidth;
    const signatureWidth = SIGNATURE_WIDTH * scale;
    const signatureHeight = SIGNATURE_HEIGHT * scale;

    const [signaturePositions, setSignaturePositions] = React.useState<ISignatureUserSignature[]>([]);

    if (!loadUrl && !file) {
        debug.error("You must specify either loadUrl or file");
    }

    if (!!loadUrl && !!file) {
        debug.error("You can't specify both loadUrl and file");
    }

    if (!pdfUrl && !!loadUrl) {
        return null;
    }

    const errorState = (
        <div style={{ padding: 32, display: "flex", flexDirection: "column", alignItems: "start" }}>
            <h1>{t("common.errorOccurred")}</h1>
            <p style={{ marginTop: 16 }}>{t("pdfView.error.caption")}</p>
            <Button
                color="primary"
                variant="contained"
                style={{ marginTop: 32, alignSelf: "end" }}
                onClick={loadDocument}
            >
                {t("common.retry")}
            </Button>
        </div>
    );

    return (
        <Suspense fallback={<LoadingOverlay />}>
            <div
                style={{ position: "relative", boxShadow: "0px 2px 8px rgba(54, 75, 141, 0.2)" }}
                onMouseDown={e => {
                    const target = e.target as HTMLElement;
                    const rect = target.getBoundingClientRect();
                    const left = rect.left;
                    const bottom = rect.bottom;

                    // Calculate the position of the signature and clamp to document bounds

                    let x = e.clientX - left;
                    x = Math.max(0, x);
                    x = Math.min(x, rect.width - signatureWidth);

                    let y = bottom - e.clientY;
                    y = Math.max(0, y);
                    y = Math.min(y, rect.height - signatureHeight);

                    // Convert to scale independent coordinates and save for local use
                    const position = { page, x: Math.floor(x / scale), y: Math.floor(y / scale) };

                    let newSignaturePositions;
                    if (signaturePositions.some(({ user }) => user?.id === currentUser?.id)) {
                        // If the user already has a signature position, update it
                        newSignaturePositions = signaturePositions.map(item => {
                            if (item.user?.id === currentUser?.id) {
                                return { ...item, signaturePosition: position };
                            }
                            return item;
                        });
                    } else {
                        // Otherwise, add a new signature position
                        newSignaturePositions = [
                            ...signaturePositions,
                            { user: currentUser, signaturePosition: position },
                        ];
                    }

                    setSignaturePositions(newSignaturePositions);
                    onSelectSignaturePosition(newSignaturePositions);
                }}
            >
                <LazyDocument
                    file={pdfUrl ?? file}
                    onLoadSuccess={async document => {
                        const pageSizes = [];
                        // CONNECT-493: Use document dimensions instead of hard coding to A4
                        //
                        // Store page sizes for later reference when we convert from screen position
                        // and scale to absolute document position.
                        // ATTENTION: Index of pages is 0-based BUT for getPage() it is 1-based!
                        for (let i = 1; i <= document.numPages; i++) {
                            const page = await document.getPage(i);
                            const viewport = page.getViewport({ scale: 1 });
                            pageSizes.push([viewport.width, viewport.height]);
                        }

                        setPageSizes(pageSizes);

                        onDocumentLoadSuccess(document.numPages);
                    }}
                    onLoadProgress={() => {
                        return <LoadingOverlay />;
                    }}
                    error={loadUrl ? errorState : undefined}
                    loading={<LoadingOverlay />}
                >
                    <LazyPage
                        key={`page_${page}`}
                        pageIndex={page}
                        renderAnnotationLayer={false}
                        width={width}
                        scale={1}
                        renderTextLayer={false}
                    />
                </LazyDocument>
                {signaturePositions.map(
                    ({ user, signaturePosition }) =>
                        page === signaturePosition.page && (
                            <div
                                key={`signature_${user?.id ?? ""}`}
                                style={{
                                    position: "absolute",
                                    left: signaturePosition.x * scale,
                                    bottom: signaturePosition.y * scale,
                                    width: signatureWidth,
                                    height: signatureHeight,
                                    backgroundColor: "rgba(255,255,255, 0.8)",
                                    padding: 4,
                                    borderRadius: 4,
                                    pointerEvents: "none",
                                    border: `${user?.id === currentUser?.id ? "2px" : "1px"} solid ${
                                        customColors.primaryColor
                                    }`,
                                    fontSize: 12,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {!hideUserName && (
                                    <LineEllipsis
                                        maxLine={2}
                                        trimRight
                                        basedOn="letters"
                                        text={getFullName(user ? getSignatureUserName(user) : undefined)}
                                    />
                                )}
                            </div>
                        ),
                )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <DocumentPagination
                    pageCount={numPages}
                    page={page}
                    onChangePage={(page: number) => {
                        setPage(page);
                    }}
                />
            </div>
        </Suspense>
    );
};
