import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { t } from "../../i18n/util";
import { withParams } from "../app/router/history";
import { EmptyState } from "../ui/EmptyState";
import { useResultViews } from "./hooks/useResultViews";
import { ResultsRoutes } from "./router/ResultsRoutes";
import { Views } from "./types";

export const viewsToUrl = {
    accountListing: ResultsRoutes.ACCOUNT_LISTING,
    customerListing: ResultsRoutes.CUSTOMER_LISTING,
    vendorListing: ResultsRoutes.VENDOR_LISTING,
    balanceSheet: ResultsRoutes.BALANCE_SHEET,
    cashAccounting: ResultsRoutes.CASH_ACCOUNTING,
    profitAndLoss: ResultsRoutes.PROFIT_AND_LOSS,
    employerPayrollAccount: withParams(ResultsRoutes.EMPLOYER_PAYROLL_ACCOUNT, { year: new Date().getFullYear() }),
} as const satisfies Record<Views, string>;

export const ResultsIndex = observer(function ResultsIndex() {
    const views = useResultViews({ financialAccountancy: undefined });
    if (!views) {
        return null;
    }

    if (views.length > 0) {
        const url = viewsToUrl[views[0]];
        if (url) {
            return <Redirect to={url} />;
        }
    }

    return (
        <EmptyState
            title={t("results.noFinancialAccountancies.title")}
            message={t("results.noFinancialAccountancies.message")}
        />
    );
});
