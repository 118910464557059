import { t } from "../../i18n/util";
import { IEmployee } from "../../types/models";
import { workingHoursIntervalToString } from "../../util/helpers";
import { OverviewLine } from "../ui/OverviewLine";
import { OverviewSection } from "../ui/OverviewSection";
import { hrStore } from "./HrStore";

export const HrEmployeeAdditionalDataOverview = (props: { employee: IEmployee; onEdit?: () => void }) => {
    const workingTimes = () => {
        let workingTimes = "";
        if (props.employee.workingHours) {
            workingTimes += t("screen.hr.employee.details.workingHours", {
                workingHours: props.employee.workingHours,
                workingHoursInterval: workingHoursIntervalToString(props.employee.workingHoursInterval),
            });
        }
        if (props.employee.weeklyWorkDays) {
            workingTimes += t("screen.hr.employee.details.weeklyWorkDays", {
                weeklyWorkDays: props.employee.weeklyWorkDays,
            });
        }
        return workingTimes;
    };

    const canEdit = !!props.onEdit;

    const costCenter = hrStore.getCostCenter(props.employee.costCenter, true);
    const funder = hrStore.getFunder(props.employee.funder, true);

    return (
        <>
            <OverviewSection
                title={t("screen.hr.additionaldata.additionaldata")}
                onEdit={props.onEdit}
                editDisabled={!canEdit}
            >
                <OverviewLine
                    title={t("screen.hr.additionaldata.titelAndAcademicDegrees")}
                    label={
                        props.employee.titlePrefix || props.employee.titleSuffix
                            ? (props.employee.titlePrefix ?? "") + " " + (props.employee.titleSuffix ?? "")
                            : undefined
                    }
                />
                <OverviewLine
                    title={t("screen.hr.additionaldata.religiousConfession")}
                    label={props.employee.religiousConfession}
                />
                <OverviewLine
                    title={t("screen.hr.additionaldata.maritalStatus")}
                    label={
                        props.employee.maritalStatus ? t(`maritalStatus.${props.employee.maritalStatus}`) : undefined
                    }
                />

                <OverviewLine
                    title={t("screen.hr.additionaldata.commuterAllowance")}
                    label={
                        props.employee.commuterAllowance
                            ? t(`commuterAllowance.${props.employee.commuterAllowance}`)
                            : undefined
                    }
                />
                <OverviewLine
                    title={t("screen.hr.additionaldata.kilometersForCommuterEuro")}
                    number={props.employee.kilometersForCommuterEuro}
                />
                <OverviewLine
                    title={t("screen.hr.additionaldata.soleEarnerStatus")}
                    label={
                        props.employee.soleEarnerStatus
                            ? t(`soleEarnerStatus.${props.employee.soleEarnerStatus}`)
                            : undefined
                    }
                />
                <OverviewLine
                    title={t("screen.hr.additionaldata.familyBonusPlusAmount")}
                    number={props.employee.familyBonusPlusAmount}
                />

                <OverviewLine title={t("screen.hr.additionaldata.emailAddress")} label={props.employee.email} />
                <OverviewLine
                    title={t("screen.hr.additionaldata.jobDescription")}
                    label={props.employee.jobDescription}
                />
                <OverviewLine
                    title={
                        props.employee.isGrossSalary
                            ? t("screen.hr.summary.grossSalary", { currency: "EUR" })
                            : t("screen.hr.summary.netSalary", { currency: "EUR" })
                    }
                    number={props.employee.monthlySalary}
                />
                <OverviewLine title={t("screen.hr.summary.bonuses")} bonuses={props.employee.bonuses} />
                <OverviewLine title={t("screen.hr.additionaldata.bankDetails")} label={props.employee.iban} />
                <OverviewLine title={t("screen.hr.additionaldata.bic")} label={props.employee.bic} />
                <OverviewLine title={t("screen.hr.additionaldata.workingTimes")} label={workingTimes()} />

                <OverviewLine
                    title={t("screen.hr.additionaldata.costCenter")}
                    label={hrStore.getCostCenterLabel(costCenter)}
                />
                <OverviewLine title={t("screen.hr.additionaldata.funder")} label={funder?.name} />
                <OverviewLine
                    title={t("screen.hr.additionaldata.wageAgreementClassification")}
                    label={props.employee.wageAgreementClassification}
                />
            </OverviewSection>
        </>
    );
};
