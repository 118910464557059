import { Button, DialogActions } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React from "react";
import { t } from "../../i18n/util";
import { CustomDialog, CustomDialogContent } from "../ui/CustomDialog";
import { CustomInputField } from "../ui/CustomInputField";
import { TpaWhiteButton } from "../ui/Primitives";
import { getMfaValidation } from "../../util/helpers";

interface MfaActivationDialogProps {
    onSubmit: (model: { code: string }) => void;
    onResendCode: () => void;
    onClose?: () => void;
    isMfaActivated?: boolean;
}

export const useMfaSettingChangeDialog = ({
    onSubmit,
    onResendCode,
    onClose,
    isMfaActivated,
}: MfaActivationDialogProps) => {
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (model: { code: string }) => {
        onSubmit(model);
    };

    const component = (
        <CustomDialog open={open} onClose={onClose} showCloseIcon>
            <CustomDialogContent>
                <h1 style={{ hyphens: "auto" }}>
                    {isMfaActivated ? t("mfa.deactivation.dialog.title") : t("mfa.activation.dialog.title")}
                </h1>
                <p style={{ marginTop: 16 }}>
                    {isMfaActivated ? t("mfa.deactivation.dialog.description") : t("mfa.activation.dialog.description")}
                </p>
                <Formik
                    initialValues={{
                        code: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={getMfaValidation()}
                    validateOnBlur
                    validateOnChange
                >
                    <Form>
                        <Field
                            data-id="2FA_code"
                            component={CustomInputField}
                            label={t("mfa.changeSetting.dialog.code.field.label")}
                            name="code"
                            required
                            style={{ marginTop: 32 }}
                            autoFocus
                        />
                        <Button onClick={onResendCode} color="primary" data-id="resend_code">
                            {t("mfa.changeSetting.dialog.resendCode").toUpperCase()}
                        </Button>
                        <DialogActions style={{ padding: "48px 0 0 0" }}>
                            <TpaWhiteButton style={{ marginRight: 16 }} onClick={onClose}>
                                {t("common.cancel")}
                            </TpaWhiteButton>
                            <Button color="primary" variant="contained" type="submit">
                                {isMfaActivated ? t("button.deactivateMfa") : t("button.activateMfa")}
                            </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </CustomDialogContent>
        </CustomDialog>
    );

    return {
        open() {
            setOpen(true);
        },
        close() {
            setOpen(false);
        },
        component,
    };
};
