import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

// Returns whether two arrays are equal independent of element order
export function containSameEntries<T>(lhs: T[], rhs: T[]) {
    if (lhs.length !== rhs.length) {
        // Early out on length mismatch
        return false;
    }

    return isEqual(sortBy(lhs), sortBy(rhs));
}
