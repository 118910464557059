import { computed, observable } from "mobx";
import * as React from "react";
import { addStoreToWindow } from "../util/debug";

export const SIDEBAR_WIDTH_OPEN = 200;
export const SIDEBAR_WIDTH_MOBILE = 600;
export const SIDEBAR_WIDTH_CLOSED = 88;

class SideBarStore {
    @observable _sideBarHidden = 0;
    @computed get sideBarHidden() {
        return this._sideBarHidden > 0;
    }
    @observable mobileMenuOpen = false;
}

export const sideBarStore = new SideBarStore();

export const useHideSideBar = (hide = true) => {
    React.useEffect(() => {
        if (hide) {
            sideBarStore._sideBarHidden++;
        }

        return function cleanup() {
            if (hide) {
                sideBarStore._sideBarHidden--;
            }
        };
    }, [hide]);
};

addStoreToWindow("sideBarStore", sideBarStore);
