import { IconButton, Link as MuiLink, Tooltip } from "@material-ui/core";
import { Location } from "history";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../config";
import { t } from "../../i18n/util";
import { CompanyAddress, FinancialAccountancy } from "../../network/APITypes";
import { SIDEBAR_WIDTH_OPEN } from "../../stores/SideBarStore";
import { formatCompanyAddress } from "../../util/helpers";
import { withParams } from "../app/router/history";
import { LabelAndIcon } from "../ui/LabelAndIcon";
import { DottedLink } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { Value } from "./ResultsValue";
import { ResultAccount, ResultRow, TransformedResult } from "./hooks/useTransformedResult";
import { ResultsRoutes } from "./router/ResultsRoutes";
import { formatAccountNumber } from "./utils";

const Label = styled.div`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const iconWidth = 24;
const iconMarginRight = 8;
const indentSize = iconWidth + iconMarginRight;

const ExpandIconPlaceholder = styled.span`
    display: inline-block;
    width: ${iconWidth}px;
    margin-right: ${iconMarginRight}px;
    margin-left: -${indentSize}px;
    text-align: center;
`;

const Cell = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
    min-height: 35px;
    padding: 5px 8px;
    background: inherit;
`;

const tableSpacing = 24;
const SpacerCellLeft = styled(Cell)`
    min-width: ${tableSpacing}px;
    width: ${tableSpacing}px;
    // stick to the left side if scrolling horizontally
    position: sticky;
    left: ${SIDEBAR_WIDTH_OPEN}px;
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        left: 0px;
    }
    // without this, the arrow of the pills would overlay when scrolling horizontally
    z-index: 1;
`;
const SpacerCellRight = styled(Cell)`
    min-width: ${tableSpacing}px;
    width: ${tableSpacing}px;
`;

const NameCell = styled(Cell)`
    cursor: pointer;
    // stick to the left side if scrolling horizontally
    position: sticky;
    left: ${SIDEBAR_WIDTH_OPEN + tableSpacing}px;
    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        left: ${tableSpacing}px;
    }
    // without this, the arrow of the pills would overlay when scrolling horizontally
    z-index: 1;
`;

const ValueCell = styled(Cell)`
    text-align: right;
    white-space: nowrap;
    justify-content: flex-end;
`;

const TableRow = styled.div`
    display: contents;
    background: white;

    .ratio-cell {
        color: #999;
    }

    &[data-level="1"] {
        color: ${customColors.primaryColor};
        background: ${customColors.primaryShade};
        font-weight: bold;
        .ratio-cell {
            color: ${customColors.primaryColor};
        }
    }
    &[data-level="2"] {
        font-weight: bold;
    }
    &[data-level="3"] {
        font-weight: bold;
        font-style: italic;
    }
    &[data-level="4"] {
        font-style: italic;
    }
    &[data-is-account="true"] {
        font-size: 12px;
    }

    &[data-sum-type="Total"] {
        color: ${customColors.primaryColor};
        background: ${customColors.primaryShade};
        font-weight: bold;

        ${ValueCell} {
            border-top: 2px solid ${customColors.primaryColor};
        }
        .ratio-cell {
            color: ${customColors.primaryColor};
        }
    }
    &[data-sum-type="SubTotal"] {
        color: ${customColors.primaryColor};
        font-weight: bold;
        font-style: italic;

        ${ValueCell} {
            border-top: 1px solid ${customColors.primaryColor};
        }
        .ratio-cell {
            color: ${customColors.primaryColor};
        }
    }
`;

const TableHeaderRow = styled.div`
    display: contents;
`;

const TableHeader = styled.div<{ $isMobile: boolean }>`
    display: contents;

    // the header sticks right under the navbar when the view is scrolled
    ${Cell} {
        position: sticky;
        top: ${props => (props.$isMobile ? "0" : "var(--navBarHeight)")};
        z-index: 2;
        background: ${customColors.primaryColor};
        color: white;
        font-weight: bold;
    }
`;

const borderColor = "#eee";
const TableBody = styled.div`
    display: contents;

    ${Cell} {
        border-top: 1px solid ${borderColor};
    }
`;

const Table = styled.div`
    display: grid;
    width: 100%;
`;

interface ResultsTableProps {
    type: "accountListing" | "customerListing" | "vendorListing" | "balanceSheet" | "cashAccounting" | "profitAndLoss";
    columns: TransformedResult["columns"];
    rows: TransformedResult["rows"];
    stylesheet: TransformedResult["stylesheet"];
    financialAccountancy: FinancialAccountancy;
}

function getMaxTreeDepth(rows: ResultRow[]): number {
    let maxDepth = 0;
    rows.forEach(row => {
        const depth = row.children.length === 0 ? 1 : getMaxTreeDepth(row.children) + 1;
        if (depth > maxDepth) maxDepth = depth;
    });
    return maxDepth;
}

/**
 * Creates a key-value-mapping of expanded states for the rows up to the specified depth
 * @param rows A list of rows on the current depth
 * @param depth The depth up to which the rows should be expanded (excluding accounts). Use -1 to expand the whole tree including accounts
 * @param currentDepth Tracks the current depth in the tree for recursion
 * @returns
 */
function getExpandedStateForDepth(rows: ResultRow[], depth: number, currentDepth = 0): Record<string, boolean> {
    let result: Record<string, boolean> = {};
    rows.forEach(row => {
        if (row.children != null) {
            result = {
                ...result,
                ...getExpandedStateForDepth(row.children, depth, currentDepth + 1),
            };
        }

        // only expand if the row either has no accounts or has any sub-row that isn't a row of type 'isShare'
        result[row.key] =
            depth === -1 ||
            (currentDepth < depth &&
                (row.accounts.length === 0 || row.children.filter(i => i.isShare !== true).length > 0));
    });

    return result;
}

interface StateWithResultsTable {
    resultsTable: {
        expanded: string[];
    };
}

function isResultsTableState(historyState: unknown): historyState is StateWithResultsTable {
    return !!historyState && typeof historyState === "object" && "resultsTable" in historyState;
}
function historyStateToExpandedState(historyState: unknown): Record<string, boolean> | null | undefined {
    if (!isResultsTableState(historyState)) {
        return null;
    }
    // extract the expanded nodes from the history state
    return historyState.resultsTable.expanded.reduce<Record<string, boolean>>((obj, key) => {
        obj[key] = true;
        return obj;
    }, {});
}
function expandedStateToHistoryState(expanded: Record<string, boolean>): StateWithResultsTable {
    return {
        resultsTable: {
            // just storing the expanded nodes is more efficient than storing the whole object
            expanded: Object.keys(expanded).reduce<string[]>((arr, key) => {
                if (expanded[key]) {
                    arr.push(key);
                }
                return arr;
            }, []),
        },
    };
}

interface ResultsTableContext {
    type: ResultsTableProps["type"];
    financialAccountancy: FinancialAccountancy;
    columns: ResultsTableProps["columns"];
    expanded: Record<string, boolean>;
    onExpandCollapse: (key: string) => void;
}

const Context = createContext<ResultsTableContext>(null as never);

const MAX_EXPAND_DEPTH = 999;

export const ResultsTable = ({ type, columns, rows, stylesheet, financialAccountancy }: ResultsTableProps) => {
    const history = useHistory<object>();
    const isMobile = useContext(MobileContext);

    // by default every row should be expanded (excluding accounts)
    // TODO reset state if rows object changes
    const [expanded, setExpanded] = useState<Record<string, boolean>>(() => {
        const expanded = historyStateToExpandedState(history.location.state);
        if (expanded) {
            return expanded;
        }
        return getExpandedStateForDepth(
            rows,
            type === "customerListing" || type === "vendorListing"
                ? -1 // open all rows for customer and vendor listings
                : MAX_EXPAND_DEPTH,
        );
    });
    const maxLevel = getMaxTreeDepth(rows);

    const depths: { depth: number; text: string }[] = [...new Array<unknown>(maxLevel)].map((_, i) => ({
        depth: i,
        text:
            i === 0
                ? t("results.common.quickExpand.expandRoot")
                : t("results.common.quickExpand.expandNthDepth", { depth: `${i + 1}` }),
    }));
    depths.push({ depth: -1, text: t("results.common.quickExpand.expandAccounts") });

    const quickExpandTextStyle: React.CSSProperties = {
        fontSize: 10,
        fontWeight: 500,
        whiteSpace: "nowrap",
        lineHeight: "normal",
    };
    const quickExpand = (
        <div style={{ marginBottom: "12px", display: "flex", justifyContent: "flex-end" }}>
            <span style={quickExpandTextStyle}>{t("results.common.quickExpand.expandRows")}</span>
            {depths.map(depth => (
                <MuiLink
                    key={depth.depth}
                    component="button"
                    color="inherit"
                    underline="always"
                    onClick={() => {
                        setExpanded(_ => getExpandedStateForDepth(rows, depth.depth));
                    }}
                    style={{ ...quickExpandTextStyle, marginLeft: 12 }}
                >
                    {depth.text}
                </MuiLink>
            ))}
        </div>
    );

    useEffect(() => {
        const state = expandedStateToHistoryState(expanded);
        history.replace({ ...history.location, state: { ...history.location.state, doNotScroll: true, ...state } });
    }, [expanded, history]);

    const context = useMemo<ResultsTableContext>(
        () => ({
            type,
            financialAccountancy,
            columns,
            expanded,
            onExpandCollapse(key) {
                setExpanded(expanded => {
                    return { ...expanded, [key]: !expanded[key] };
                });
            },
        }),
        [columns, expanded, financialAccountancy, type],
    );

    return (
        <Context.Provider value={context}>
            <style>{stylesheet}</style>
            {quickExpand}
            <Table
                style={{ gridTemplateColumns: `24px minmax(300px, auto) repeat(${columns.length}, min-content) 24px` }}
            >
                <TableHeader $isMobile={isMobile}>
                    <TableHeaderRow>
                        <SpacerCellLeft />
                        <NameCell></NameCell>
                        {columns.map(c => (
                            <ValueCell key={c.key}>{c.label}</ValueCell>
                        ))}
                        <SpacerCellRight />
                    </TableHeaderRow>
                </TableHeader>
                <TableBody>
                    <Rows rows={rows} indent={0} />
                </TableBody>
            </Table>
        </Context.Provider>
    );
};

interface RowsProps {
    rows: ResultRow[];
    accounts?: ResultAccount[];
    indent: number;
}

const Rows = ({ rows, accounts, indent }: RowsProps) => {
    return (
        <>
            {accounts?.map(account => <Account key={account.key} account={account} indent={indent} />)}
            {rows.map(row => (
                <Row key={row.key} row={row} indent={indent} />
            ))}
        </>
    );
};

interface RowProps {
    row: ResultRow;
    indent: number;
}

const Row = ({ row, indent }: RowProps) => {
    const { columns, expanded, onExpandCollapse } = useContext(Context);

    const isExpanded = expanded[row.key];
    const canExpand = row.children.length > 0 || row.accounts.length > 0;

    const handleExpandCollapseClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        onExpandCollapse(row.key);
    };

    // add an additional ident that is "reverted" via `margin-left: -indentSize` on the collapse button.
    // this is required to make long labels break and correctly indent after the button
    const paddingLeft = indent * indentSize + indentSize;

    const label = (row.numbering ? row.numbering + ". " : "") + (row.label ?? "");

    let sumRow;
    let cells;

    const values = row.values.map(value => (
        <ValueCell key={value.key}>
            <Value value={value.value} />
        </ValueCell>
    ));

    if (canExpand && isExpanded) {
        // show the sum values below the rows if they are visible
        sumRow = (
            <TableRow className={row.className} data-level={row.level}>
                <SpacerCellLeft />
                <NameCell style={{ paddingLeft }} onClick={handleExpandCollapseClick} title={row.label ?? ""}>
                    <ExpandIconPlaceholder>Σ</ExpandIconPlaceholder>
                    <Label>{label}</Label>
                </NameCell>
                {values}
                <SpacerCellRight />
            </TableRow>
        );
        // and show nothing inline
        cells = columns.map((_, index) => <ValueCell key={index}></ValueCell>);
    } else {
        // otherwise show the sum values inline
        cells = values;
    }

    return (
        <>
            <TableRow className={row.className} data-sum-type={row.sumType} data-level={row.level}>
                <SpacerCellLeft />
                <NameCell style={{ paddingLeft }} onClick={handleExpandCollapseClick} title={label}>
                    {canExpand ? (
                        <IconButton
                            style={{
                                color: "inherit",
                                padding: 0,
                                marginRight: iconMarginRight,
                                marginLeft: -indentSize,
                            }}
                            onClick={handleExpandCollapseClick}
                        >
                            {isExpanded ? <Icon name="chevronDown" /> : <Icon name="chevronRight" />}
                        </IconButton>
                    ) : (
                        <ExpandIconPlaceholder />
                    )}
                    <Label>{label}</Label>
                </NameCell>
                {cells}
                <SpacerCellRight />
            </TableRow>
            {(row.children || row.accounts) && isExpanded ? (
                <Rows rows={row.children} accounts={row.accounts} indent={indent + 1} />
            ) : null}
            {sumRow}
        </>
    );
};

interface AccountProps {
    account: ResultAccount;
    indent: number;
}

const Account = ({ account, indent }: AccountProps) => {
    const { financialAccountancy } = useContext(Context);

    // add an additional ident that is "reverted" via `margin-left: -indentSize` on the collapse button.
    // this is required to make long labels break and correctly indent after the button
    const paddingLeft = indent * indentSize + indentSize;

    const label = `${formatAccountNumber(account.accountNumber)} ${account.label}`;

    return (
        <TableRow className={account.className} data-is-account={true}>
            <SpacerCellLeft />
            <NameCell style={{ paddingLeft }} title={label}>
                <ExpandIconPlaceholder />
                <AccountLink
                    financialAccountancyId={parseInt(financialAccountancy.id, 10)}
                    account={account}
                    style={{ flex: 1 }}
                >
                    <LabelAndIcon label={label} icon={<AddressIcon address={account.address} />} />
                </AccountLink>
            </NameCell>
            {account.values.map(value => (
                <ValueCell key={value.key}>
                    {value.financialAccountancyId ? (
                        <AccountLink financialAccountancyId={value.financialAccountancyId} account={account}>
                            <Value value={value.value} />
                        </AccountLink>
                    ) : (
                        <Value value={value.value} />
                    )}
                </ValueCell>
            ))}
            <SpacerCellRight />
        </TableRow>
    );
};

interface AccountLinkProps {
    financialAccountancyId: number;
    account: ResultAccount;
    style?: React.CSSProperties;
    children: React.ReactNode;
}

const AccountLink = ({ financialAccountancyId, account, style, children }: AccountLinkProps) => {
    const { type } = useContext(Context);
    const location = useLocation();
    const pathname = withParams(ResultsRoutes.ACCOUNT_SHEET, {
        financialAccountancyId,
        accountNr: account.accountNumber,
    });
    const state: ResultsNavigationState = {
        type: "results",
        location,
        resultsType: type,
        initialTab: type === "customerListing" || type === "vendorListing" ? "open" : undefined,
    };
    return (
        <DottedLink to={{ pathname, state }} style={style}>
            {children}
        </DottedLink>
    );
};

export interface ResultsNavigationState {
    type: "results";
    location: Location;
    resultsType: ResultsTableProps["type"];
    initialTab?: "open";
}

const AddressIcon = ({ address }: { address: CompanyAddress }) => {
    const formattedAddress = formatCompanyAddress(address);
    if (!formattedAddress) {
        return null;
    }

    return (
        <Tooltip title={formattedAddress}>
            <Icon name="location" style={{ marginLeft: 8 }} size={14} />
        </Tooltip>
    );
};
