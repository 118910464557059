import { IconButton, TableBody } from "@material-ui/core";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import { observer } from "mobx-react";
import * as React from "react";
import { ROWS_PER_PAGE } from "../../../config";
import { t } from "../../../i18n/util";
import { API, IGetEmployeesParams } from "../../../network/API";
import { EmployeePreRegistration, EmployeeStatus } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { formatDate, getFormattedISODateOnlyRange } from "../../../util/date";
import { employeeStatusToString, missingDataToString } from "../../../util/helpers";
import { getFullName } from "../../../util/user";
import { pushRoute } from "../../app/router/history";
import { useTableFilters } from "../../hooks/useTableFilters";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../../ui/ContextMenu";
import { EmptyState } from "../../ui/EmptyState";
import { TableLabel, TpaTable, TpaTableCell, TpaTableContainer, TpaTableRow } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { DATE_FILTER_TYPE, IFilterItem, ITableFilters } from "../../ui/filter/types";
import { Icon } from "../../util/Icon";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

export const HrEmployeesInProgressSite = observer(function HrEmployeesInProgressSite() {
    const [employees, setEmployees] = React.useState<EmployeePreRegistration[]>([]);
    const [status, setStatus] = React.useState<EmployeeStatus[]>([]);
    const [onlyMissingDetails, setOnlyMissingDetails] = React.useState(false);
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [isInitialized, setIsInitialized] = React.useState(false);
    const tableStore = useTableStore("HrEmployeesInProgressSite", { orderBy: "name", orderDir: "asc" });
    const { offset, orderBy, orderDir, search } = tableStore;

    const onRegisterEmployee = () => {
        pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA);
    };

    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = hrStore.selectedSubsidiaryId;

    const canEdit = authStore.canEditEmployees(subsidiaryId);

    const handleRowClick = async (preRegistrationLine: EmployeePreRegistration) => {
        try {
            const preRegistration = await API.getPreRegistration(companyId, subsidiaryId, preRegistrationLine.id);

            if (!preRegistration) {
                throw new Error("Empty Employee");
            }

            if (!canEdit) {
                // Can't edit -> show details
                pushRoute(HrRoutes.EMPLOYEES.DETAILS, {
                    params: { employeeId: preRegistration.id },
                    query: { isPreRegistration: true },
                });
            } else {
                if (preRegistration.status === "inRegistrationNotTransferred") {
                    pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA, {
                        query: { employeeId: preRegistration.id, isPreRegistration: true },
                    });
                } else if (preRegistration.status === "inDataChangeNotTransferred") {
                    pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA, {
                        query: { employeeId: preRegistration.id, isPreRegistration: true, isEdit: true },
                    });
                } else if (preRegistration.status === "inDeregistrationNotTransferred") {
                    pushRoute(HrRoutes.EMPLOYEES.DEREGISTRATION_DATA, {
                        params: { employeeId: preRegistration.id },
                        query: { isPreRegistration: true },
                    });
                } else {
                    // Already transferred -> show details
                    pushRoute(HrRoutes.EMPLOYEES.DETAILS, {
                        params: { employeeId: preRegistration.id },
                        query: { isPreRegistration: true },
                    });
                }
            }
        } catch (err) {
            generalStore.setError(t("error.loadEmployee"), err);
        }
    };

    const reloadEmployees = React.useCallback(async () => {
        try {
            generalStore.isLoading = true;

            const options: IGetEmployeesParams["options"] = {
                offset,
                limit: ROWS_PER_PAGE,
                orderBy,
                orderDir,
            };

            if (search) {
                options.search = search;
            }

            if (status) {
                const optionsStatus = [...status];
                if (status.includes("inDataChangeNotTransferred")) {
                    optionsStatus.push("inRegistrationNotTransferred");
                    optionsStatus.push("inDeregistrationNotTransferred");
                }
                if (status.includes("inRegistration")) {
                    optionsStatus.push("inRegistrationNotTransferred");
                }
                if (status.includes("inDeregistration")) {
                    optionsStatus.push("inDeregistrationNotTransferred");
                }
                if (status.includes("inDataChange")) {
                    optionsStatus.push("inDataChangeNotTransferred");
                }
                options.status = uniq(optionsStatus);
            }

            if (onlyMissingDetails) {
                options.onlyMissingDetails = onlyMissingDetails;
            }

            if (startDate) {
                options.startDate = startDate;
            }

            if (endDate) {
                options.endDate = endDate;
            }

            const employees = await API.getPreRegistrations(companyId, subsidiaryId, {
                options: options,
            });

            if (employees) {
                setEmployees(employees.employees ?? []);
                tableStore.totalCount = employees.total;
            }
        } catch (err) {
            generalStore.setError(t("error.loadEmployees"), err);
        } finally {
            setIsInitialized(true);
            generalStore.isLoading = false;
        }
    }, [
        offset,
        orderBy,
        orderDir,
        search,
        status,
        onlyMissingDetails,
        startDate,
        endDate,
        companyId,
        subsidiaryId,
        tableStore,
    ]);

    React.useEffect(() => {
        reloadEmployees();
    }, [reloadEmployees]);

    const contextMenu = useContextMenu<EmployeePreRegistration>();
    let contextMenuItems: ContextMenuItem[] = [];
    if (contextMenu.contextElement) {
        const preRegistration = contextMenu.contextElement;
        const transferred = !!preRegistration.transferredAt;
        contextMenuItems = [
            {
                title: t(transferred ? "menu.details" : "menu.edit"),
                icon: "pen",
                onClick: () => {
                    handleRowClick(preRegistration);
                    contextMenu.close();
                },
                "data-id": "context_menu_edit",
            },
            {
                title: t("menu.delete"),
                icon: "delete",
                onClick: async () => {
                    contextMenu.close();
                    try {
                        await API.deletePreRegistration(companyId, subsidiaryId, preRegistration.id);
                        await reloadEmployees();
                    } catch (err) {
                        generalStore.setError(t("error.delete"), err);
                    }
                },
                disabled: transferred || !authStore.canDeleteEmployees(subsidiaryId),
                "data-id": "context_menu_delete",
            },
        ];
    }

    const headerFields: ITableHeaderConfig[] = [
        { column: "name", label: "table.label.employee" },
        { column: "jobDescription", label: "table.label.jobDescription" },
        { column: "transferredAt", label: "table.label.dateOfTransmitting" },
        { column: "missingDetails", label: "table.label.missingDetails" },
        {
            column: "dateOfChange",
            label: "table.label.dateOfJoiningExiting",
        },
        { column: "status", label: "table.label.status" },
        { column: "contextMenu" },
    ];

    const handleChangeDate = (date: Date) => {
        const { startDate, endDate } = getFormattedISODateOnlyRange(date, "month");
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const statusFilterEntries: IFilterItem[] = [
        { name: "inRegistration", label: t("screen.hr.employee.current.registration") },
        { name: "inDeregistration", label: t("screen.hr.employee.current.cancellation") },
        { name: "inDataChange", label: t("screen.hr.employee.current.dataChange") },
        { name: "inDataChangeNotTransferred", label: t("screen.hr.employee.current.notTransferred") },
    ];

    const filters = useTableFilters({
        filters: [
            {
                category: "status",
                entries: statusFilterEntries,
            },
            {
                category: "general",
                entries: [
                    { name: "missingDetails", label: t("table.label.missingDetails") },
                    {
                        name: "anyDateOfJoiningExiting",
                        label: t("screen.hr.employee.current.anyDateOfJoiningExiting"),
                        type: DATE_FILTER_TYPE,
                        onChange: handleChangeDate,
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            tableStore.resetOffset();
            setOnlyMissingDetails(!!selected.missingDetails);

            if (!selected.anyDateOfJoiningExiting) {
                setStartDate("");
                setEndDate("");
            }

            const statusFilters = statusFilterEntries.map(entry => entry.name) ?? [];
            setStatus(intersection(statusFilters, Object.keys(selected)) as EmployeeStatus[]);
        },
    });

    const tableBody = (
        <TableBody>
            {employees.map((employee, index) => {
                return (
                    <TpaTableRow
                        key={employee.id}
                        onClick={() => handleRowClick(employee)}
                        role="button"
                        style={{ cursor: "pointer" }}
                        data-id={`employee_${index}`}
                    >
                        {headerFields.map(hf => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = employee[hf.column as keyof EmployeePreRegistration];
                            if (hf.column === "name") {
                                label = getFullName(employee);
                            } else if (hf.column === "jobDescription") {
                                label = employee.jobDescription;
                            } else if (hf.column === "dateOfChange") {
                                label = formatDate(employee.dateOfChange);
                            } else if (hf.column === "missingDetails") {
                                label = missingDataToString(employee.missingDetails);
                            } else if (hf.column === "status") {
                                label = employeeStatusToString(employee.status);
                            } else if (hf.column === "transferredAt") {
                                label = employee.transferredAt
                                    ? formatDate(employee.transferredAt)
                                    : t("screen.hr.employee.current.notTransferred");
                            } else if (hf.column === "contextMenu") {
                                return (
                                    <TpaTableCell key={hf.column} style={{ textAlign: "right", width: 1 }}>
                                        <IconButton
                                            data-id={`context_menu_${index}`}
                                            style={{ padding: 0 }}
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                contextMenu.open(event, employee);
                                            }}
                                        >
                                            <Icon name="more" />
                                        </IconButton>
                                    </TpaTableCell>
                                );
                            }

                            return (
                                <TpaTableCell key={hf.column} style={{ padding: 16 }}>
                                    <TableLabel>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    if (!isInitialized) {
        return null;
    }

    return (
        <>
            {tableStore.getIsEmptyState(generalStore.isLoading, !isEmpty(filters.activeFilters)) ? (
                <EmptyState
                    title={t("screen.hr.employee.emptystate.title")}
                    message={t("screen.hr.employee.emptystate.message")}
                    buttonLabel={t("screen.hr.employee.emptystate.buttonlabel")}
                    onAddEntry={onRegisterEmployee}
                    data-id="empty_state"
                    disabled={!canEdit}
                />
            ) : (
                <>
                    <SiteContent>
                        <TableSearchBarWithAction
                            label="search.caption.numPersons"
                            placeholder="search.placeholder.searchForEmployees"
                            search={tableStore.search}
                            totalCount={tableStore.totalCount}
                            onChangeSearch={tableStore.handleSearchChange}
                            disabled={!canEdit}
                            buttonLabel={t("screen.hr.employees.current.registerEmployee")}
                            onAction={onRegisterEmployee}
                            tableFilters={filters}
                        />
                        <TpaTableContainer>
                            <TpaTable>
                                <TableHeader headerFields={headerFields} tableStore={tableStore} />
                                {tableBody}
                            </TpaTable>
                        </TpaTableContainer>
                        <tableStore.Pagination />
                        <ContextMenu
                            data-id="context_menu"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            config={contextMenu}
                            items={contextMenuItems}
                        />
                    </SiteContent>
                    {tableStore.getIsNoResultState(generalStore.isLoading, !isEmpty(filters.activeFilters)) && (
                        <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                    )}
                </>
            )}
        </>
    );
});
