import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SEARCH_DEBOUNCE_MS } from "../../config";
import { t } from "../../i18n/util";
import { Icon } from "../util/Icon";
import { FieldError } from "./CustomInputField";

interface LoadNextOption {
    action: "next";
}

type AnObject = object;

type LoaderResult<Item extends AnObject> =
    | { items: Item[]; total: number }
    | { items: Item[]; cursor: string | undefined }
    | null;

export type Loader<Item extends AnObject> = (options: {
    offset: number;
    cursor: string | undefined;
    search: string;
}) => Promise<LoaderResult<Item> | null>;

interface Props<Item extends AnObject> {
    value: Item | null;
    onChange: (item: Item | null) => void;
    loader: Loader<Item>;
    getOptionLabel: (item: Item) => string;
    getNoOptionsText?: (search: string) => string;
    isSelected: (item: Item, value: Item) => boolean;
    isDisabled?: (item: Item) => boolean;
    label?: string;
    placeholder?: string;
    error?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    disableClearable?: boolean;
}

export const Autocomplete = <Item extends AnObject>({
    value,
    onChange,
    loader,
    getOptionLabel,
    getNoOptionsText,
    isSelected,
    isDisabled,
    label,
    placeholder,
    error,
    style,
    disabled,
    disableClearable = true,
}: Props<Item>) => {
    type Option = Item | LoadNextOption;

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const [items, setItems] = useState<Item[]>([]);
    const [total, setTotal] = useState(0);
    const [cursor, setCursor] = useState<string>();
    const [loading, setLoading] = useState(false);
    const loadingCounterRef = useRef(0);

    const load = useCallback(
        (options: { offset: number; cursor: string | undefined; search: string }) => {
            const loadingCounter = ++loadingCounterRef.current;
            setLoading(true);
            loader(options).then(response => {
                if (loadingCounter !== loadingCounterRef.current) {
                    return;
                }
                setLoading(false);
                if (!response) {
                    // most likely an error that should be handled by `loader`
                    return;
                }
                setItems(items => (options.offset === 0 ? response.items : items.concat(response.items)));
                if ("total" in response) {
                    setTotal(response.total);
                } else if ("cursor" in response) {
                    setCursor(response.cursor);
                }
            });
        },
        [loader],
    );

    useEffect(() => {
        load({ offset: 0, cursor: undefined, search });
    }, [load, search]);

    let remainingCount = cursor ? Infinity : total - items.length;

    let options: Option[] = items;

    // prepend the current value if not part of the items (yet)
    if (value && !items.some(item => isSelected(item, value))) {
        options = [value, ...options];
        remainingCount--; // assume that the value will be part of the remaining items
    }

    // add a "+{count} more" entry to load the next items
    if (remainingCount > 0) {
        options = options.concat({ action: "next" });
    }

    const onChangeSearch = useMemo(() => {
        return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value);
        }, SEARCH_DEBOUNCE_MS);
    }, []);

    return (
        <>
            <MuiAutocomplete
                value={value}
                onChange={(event, option) => {
                    if (option && "action" in option) {
                        // load the next page
                        load({ offset: items.length, cursor, search });
                    } else {
                        onChange(option);
                        setOpen(false);
                        setSearch("");
                    }
                }}
                options={options}
                renderOption={option => {
                    if ("action" in option) {
                        return remainingCount === Infinity
                            ? t("common.remainingItemsUnknown")
                            : t("common.remainingItems", { count: remainingCount });
                    }
                    return getOptionLabel(option);
                }}
                // since we provide a custom `renderOption`, this function is only used to get the label for the selected value
                getOptionLabel={option => {
                    if ("action" in option) {
                        // in case the user clicks the "+{count} more" entry, just return the current search to keep it's value
                        // otherwise the <Autocomplete /> would reset it internally, hence break pagination with searching
                        return search;
                    }
                    return getOptionLabel(option);
                }}
                getOptionSelected={(option, value) => {
                    if ("action" in option || "action" in value) {
                        return false;
                    }
                    return isSelected(option, value);
                }}
                getOptionDisabled={option => {
                    if ("action" in option) {
                        return false;
                    }
                    return isDisabled?.(option) ?? false;
                }}
                noOptionsText={getNoOptionsText?.(search) ?? t("common.noSearchResults")}
                filterOptions={options => options} // turn off built-in filtering, because it is done on the server
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={placeholder}
                        variant="outlined"
                        onChange={onChangeSearch}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {loading ? <CircularProgress size={24} /> : <Icon name="search" />}
                                </InputAdornment>
                            ),
                        }}
                        onClick={() => {
                            setOpen(!disabled);
                        }}
                        error={!!error}
                    />
                )}
                style={style}
                disableClearable={disableClearable}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onBlur={() => {
                    setSearch("");
                }}
                openOnFocus
                disableCloseOnSelect
            />
            {error ? <FieldError>{error}</FieldError> : null}
        </>
    );
};

type InMemoryProps<Item extends AnObject> = Props<Item> & {
    searchFn: (item: Item, lowerSearch: string, search: string) => boolean;
};

export const InMemoryAutocomplete = <Item extends AnObject>({ loader, searchFn, ...rest }: InMemoryProps<Item>) => {
    const loaderPromiseRef = useRef<ReturnType<typeof loader>>();

    const inMemoryLoader: Loader<Item> = useCallback(
        async ({ search }) => {
            if (!loaderPromiseRef.current) {
                // we only need to load the items once
                loaderPromiseRef.current = loader({ offset: 0, search: "", cursor: undefined });
            }
            return loaderPromiseRef.current.then(result => {
                if (!result) {
                    return null;
                }
                let { items } = result;
                if (search) {
                    const lowerSearch = search.toLowerCase();
                    items = items.filter(item => searchFn(item, lowerSearch, search));
                }
                return {
                    items,
                    total: items.length,
                };
            });
        },
        [loader, searchFn],
    );

    return <Autocomplete<Item> {...rest} loader={inMemoryLoader} />;
};
