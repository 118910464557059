import noop from "lodash/noop";
import { useState } from "react";
import { Redirect, useLocation } from "react-router";
import { t } from "../../../i18n/util";
import { TermsOfUseSignee } from "../../../network/APITypes";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { pushRoute } from "../../app/router/history";
import { NavBarBack } from "../../ui/NavBarBack";
import { PageWithStepper } from "../../ui/PageWithStepper";
import { AdvisorConfigCompaniesStepper } from "../AdvisorConfigCompaniesStepper";
import { AdvisorRoutes } from "../router/AdvisorRoutes";
import { BulkState } from "../util/bulk";
import { AdvisorConfigTermsOfUseContent } from "./AdvisorConfigTermsOfUseSite";

export const AdvisorConfigBulkTermsOfUseSite = () => {
    useHideSideBar();

    const { state } = useLocation<BulkState | undefined>();
    const companies = state?.companies;

    if (!state || !companies?.length) {
        // No companies in state -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    return <AdvisorConfigBulkTermsOfUseContent state={state} />;
};

const AdvisorConfigBulkTermsOfUseContent = ({ state }: { state: BulkState }) => {
    const [signees, setSignees] = useState<TermsOfUseSignee[]>(() => {
        return state.signees?.map(email => ({ id: "", email })) ?? [];
    });
    const [documentVersion, setDocumentVersion] = useState<string>(state.documentVersion ?? "");

    const { companies } = state;

    const updatedState: BulkState = {
        ...state,
        documentVersion,
        signees: signees.map(s => s.email),
    };

    const handleSubmit = () => {
        pushRoute(AdvisorRoutes.CONFIG.BULK.FINISH, { state: updatedState });
    };

    const handleSkip = () => {
        const state: BulkState = { ...updatedState, skipTermsOfUse: true };
        pushRoute(AdvisorRoutes.CONFIG.BULK.FINISH, { state });
    };

    return (
        <>
            <NavBarBack
                title={t("config.terms.termsOfUseTitle")}
                backLabel={t("config.bulk.data.title")}
                backTarget={{ pathname: AdvisorRoutes.CONFIG.BULK.DATA, state: updatedState }}
                companyName={t("config.companies.count", { count: companies.length })}
            />
            <PageWithStepper stepper={<AdvisorConfigCompaniesStepper state={updatedState} />}>
                <AdvisorConfigTermsOfUseContent
                    variant="current"
                    companyId={companies[0].id} // it is only used to check if the user is an advisor in the terms of use endpoint
                    documentVersion={documentVersion}
                    onDocumentVersionChange={setDocumentVersion}
                    signees={signees}
                    onSigneesChange={setSignees}
                    onSubmit={handleSubmit}
                    onSkip={handleSkip}
                    onAlreadyAcceptedSubmit={noop}
                />
            </PageWithStepper>
        </>
    );
};
