import { observer } from "mobx-react";
import { useState } from "react";
import { API } from "../../network/API";
import { GetRecordTypesResponse, PostRecordResponse } from "../../network/APITypes";
import { Module } from "../../types/models";
import { useRecord } from "../hooks/useRecord";
import { ModuleRecordsDetails } from "../shared/ModuleRecordsDetails";
import { ModuleRecordsUpload } from "../shared/sites/ModuleRecordsUploadSite";
import { IUpload } from "../ui/DocumentUpload";

export const InlineUploadRecords = observer(function InlineUploadRecords({
    companyId,
    module,
    periodId,
    subsidiaryId,
    recordType,
    multipleFiles,
    skipDetails,
    onDone,
}: {
    companyId: string;
    module: Module;
    periodId: string;
    subsidiaryId: string;
    recordType: GetRecordTypesResponse;
    multipleFiles?: boolean;
    skipDetails?: boolean;
    onDone: () => void;
}) {
    const [recordIds, setRecordIds] = useState<string[]>();

    if (!recordType) {
        return null;
    }

    const recordTypeId = recordType.id;

    const handleSuccessUpload = (uploads: IUpload<PostRecordResponse>[]) => {
        if (skipDetails) {
            onDone();
        } else {
            setRecordIds(uploads.map(upload => upload.response.recordId));
        }
    };

    const handleDone = () => {
        setRecordIds(undefined);
        onDone();
    };

    const uploadFile = async (file: File) => {
        return API.postRecords({
            file,
            companyId,
            module,
            periodId,
            subsidiaryId,
            recordTypeId,
        });
    };

    return !recordIds ? (
        <div style={{ margin: "auto" }}>
            <ModuleRecordsUpload
                module={module}
                recordType={recordType}
                uploadFile={uploadFile}
                onSuccess={handleSuccessUpload}
                multipleFiles={multipleFiles}
            />
        </div>
    ) : (
        <InlineUploadRecordsDetails
            recordIds={recordIds}
            companyId={companyId}
            module={module}
            periodId={periodId}
            subsidiaryId={subsidiaryId}
            recordType={recordType}
            onDone={handleDone}
        />
    );
});

const InlineUploadRecordsDetails = ({
    recordIds,
    companyId,
    module,
    periodId,
    subsidiaryId,
    recordType,
    onDone,
}: {
    recordIds: string[];
    companyId: string;
    module: Module;
    periodId: string;
    subsidiaryId: string;
    recordType: GetRecordTypesResponse;
    onDone: () => void;
}) => {
    const [index, setIndex] = useState(0);

    const recordId = recordIds[index];
    const nextUpload = index < recordIds.length - 1 ? recordIds[index + 1] : null;

    const handleSubmit = () => {
        if (nextUpload) {
            setIndex(index + 1);
        } else {
            onDone();
        }
    };

    const record = useRecord(module, companyId, subsidiaryId, periodId, recordType.id, recordId);

    if (!record?.document) {
        return null;
    }

    return (
        <ModuleRecordsDetails
            module={module}
            current={index + 1}
            total={recordIds.length}
            document={record.document}
            recordId={record.id}
            recordTypeId={recordType.id}
            onSubmit={handleSubmit}
            onCancel={onDone}
        />
    );
};
