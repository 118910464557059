import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { WebhookRoutes } from "./WebhookRoutes";
import { WebhookTicketsSite } from "../sites/WebhookTicketsSite";
import { WebhookUploadsSite } from "../sites/WebhookUploadsSite";
import { Routes } from "../../app/router/Routes";
import { WebhookDocumentTicketsDetailsSite } from "../sites/WebhookDocumentTicketsDetailSite";
import { WebhookPreRegistrationTicketsDetailsSite } from "../sites/WebhookPreRegistrationTicketsDetailSite";

export const WebhookRouter = () => (
    <Switch>
        <Route exact path={WebhookRoutes.ROOT}>
            <Redirect to={Routes.ROOT} />
        </Route>
        <Route exact path={WebhookRoutes.TICKETS}>
            <WebhookTicketsSite />
        </Route>
        <Route exact path={WebhookRoutes.DOCUMENT_TICKETS_DETAILS}>
            <WebhookDocumentTicketsDetailsSite />
        </Route>
        <Route exact path={WebhookRoutes.PRE_REGISTRATION_TICKETS_DETAILS}>
            <WebhookPreRegistrationTicketsDetailsSite />
        </Route>
        <Route exact path={WebhookRoutes.UPLOADS}>
            <WebhookUploadsSite />
        </Route>
        <Route>
            <NotFoundSite />
        </Route>
    </Switch>
);
