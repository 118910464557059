import React, { useEffect } from "react";
import { TELEPHONE_NUMBER } from "../../config";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { getApiError } from "../../network/NetworkStapler";
import { authStore } from "../../stores/AuthStore";
import { generalStore } from "../../stores/GeneralStore";
import { AuthAction, AuthStep, InvalidLogin, IPassword } from "../../types/models";
import { useConfirmationDialog } from "./useConfirmationDialog";
import { useSuccessDialog } from "./useSuccessDialog";

export const useMultiFactorAuth = (onAuthActionComplete: () => Promise<void> | void, isMSUpgrade?: boolean) => {
    const [currentPassword, setCurrentPassword] = React.useState<string>("");
    const [flowToken, setFlowToken] = React.useState<string>("");
    const [currentAuthStep, setCurrentAuthStep] = React.useState<AuthStep>("email");
    const [invalidLogin, setInvalidLogin] = React.useState<InvalidLogin>();

    const mfaResendSuccessDialog = useSuccessDialog({
        title: t("mfa.codeResend.success.dialog.title"),
    });
    const passwordExpiredDialog = useConfirmationDialog({
        title: t("dialog.passwordExpired.title"),
        message: t("dialog.passwordExpired.message", { telephoneNumber: TELEPHONE_NUMBER }),
        onCancel: false, // no cancel, only "OK"
    });

    const openPasswordExpiredDialog = passwordExpiredDialog.open;
    useEffect(() => {
        if (authStore.error === "PasswordExpired") {
            openPasswordExpiredDialog();
        }
    }, [openPasswordExpiredDialog]);

    const submitPassword = async (username?: string, password?: string) => {
        if (!username) {
            return;
        }

        generalStore.isLoading = true;

        try {
            const currentFlowToken = await authStore.loginWithPassword(username, password ?? currentPassword);

            if (!!currentFlowToken && !authStore.error) {
                // when flowtoken is set then user has requested a new code
                if (flowToken) {
                    mfaResendSuccessDialog.openDialog();
                }
                if (password) {
                    setCurrentPassword(password);
                }
                setFlowToken(currentFlowToken);
                setCurrentAuthStep("multiFactorAuth");
                return;
            }

            if (authStore.error) {
                if (authStore.error === "PasswordWrong") {
                    setInvalidLogin("credentials");
                } else if (authStore.error === "PasswordExpired") {
                    passwordExpiredDialog.open();
                } else if (authStore.error === "mfaCodeAlreadySent") {
                    generalStore.setError(t("error.mfaCodeAlreadySent"));
                } else if (authStore.error === "CompaniesSyncTimeout") {
                    generalStore.setError(t("error.loginAccountSetup"));
                } else if (authStore.error === "Unknown") {
                    generalStore.setError(t("error.general"));
                }
            } else {
                await onAuthActionComplete();
            }
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.Unauthorized_401 && isMSUpgrade) {
                generalStore.setError(t("error.upgradeToMicrosoft"), error);
            } else {
                generalStore.setError(t("error.general"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    };

    const submitMfaCode = async (code: string) => {
        generalStore.isLoading = true;

        try {
            await authStore.loginWithMultiFactorAuthCode(flowToken, code);

            if (authStore.error) {
                if (authStore.error === "mfaCodeWrong") {
                    setInvalidLogin("code");
                } else if (authStore.error === "CompaniesSyncTimeout") {
                    generalStore.setError(t("error.loginAccountSetup"));
                } else if (authStore.error === "Unknown") {
                    generalStore.setError(t("error.general"));
                }
            } else {
                setCurrentPassword("");
                setFlowToken("");
                await onAuthActionComplete();
            }
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const onSubmitAuthAction = async (action: AuthAction, token: string, model?: IPassword) => {
        generalStore.isLoading = true;
        let response;

        try {
            if (action === "registration") {
                response = await API.postCompleteRegistration(model?.password ?? currentPassword, token);
            } else if (action === "passwordReset") {
                response = await API.postChangePassword(model?.password ?? currentPassword, token);
            }

            if (response && !response.loginCompleted && !!response.flowToken) {
                // when flowtoken is set then user has requested a new code
                if (flowToken) {
                    mfaResendSuccessDialog.openDialog();
                }
                setFlowToken(response.flowToken);
                if (model?.password) {
                    setCurrentPassword(model.password);
                }
                setCurrentAuthStep("multiFactorAuth");
                return;
            } else if (response && response.loginCompleted && response.tokens) {
                authStore.credentials = response.tokens ?? null;
                await onAuthActionComplete();
            }
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.response.type === "MFA_CODE_SENT_ALREADY") {
                generalStore.setError(t("error.mfaCodeAlreadySent"), error);
            } else if (apiError?.response.type === "TOKEN_NOT_FOUND") {
                generalStore.setError(t("error.linkInvalid"), error);
            } else if (apiError?.response.type === "TOKEN_EXPIRED") {
                generalStore.setError(t("error.linkExpired"), error);
            } else {
                generalStore.setError(t("error.general"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    };

    const onSubmitAuthActionCompleteMfa = async (action: AuthAction, model: { code: string }, token: string) => {
        generalStore.isLoading = true;
        let response;

        try {
            if (action === "registration") {
                response = await API.postCompleteRegistrationMfa({
                    flowToken,
                    password: currentPassword,
                    token,
                    code: model.code,
                });
            } else if (action === "passwordReset") {
                response = await API.postCompleteForgotPasswordMfa({
                    flowToken,
                    password: currentPassword,
                    token,
                    code: model.code,
                });
            }

            authStore.credentials = response ?? null;
            await onAuthActionComplete();
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.statusCode === HttpStatusCode.Unauthorized_401 ||
                apiError?.statusCode === HttpStatusCode.BadRequest_400
            ) {
                generalStore.setError(t("error.mfaCodeWrong"), error);
            } else {
                generalStore.setError(t("error.general"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    };

    const resetLogin = () => {
        setCurrentAuthStep("email");
        setCurrentPassword("");
        setFlowToken("");
    };

    return {
        submitPassword,
        submitMfaCode,
        onSubmitAuthAction,
        onSubmitAuthActionCompleteMfa,
        invalidLogin,
        setInvalidLogin,
        currentAuthStep,
        setCurrentAuthStep,
        flowToken,
        resetLogin,
        mfaResendSuccessDialog,
        passwordExpiredDialog,
    };
};
