import { Routes } from "../../app/router/Routes";

const prefix = Routes.prefixed(Routes.WEBHOOKS);

export const WebhookRoutes = {
    ROOT: prefix("/"),
    TICKETS: prefix("/tickets"),
    DOCUMENT_TICKETS_DETAILS: prefix(
        "/tickets/company/:companyId/:module/period/:periodId/subsidiary/:subsidiaryId/recordType/:recordTypeId",
    ),
    PRE_REGISTRATION_TICKETS_DETAILS: prefix("/tickets/pre-registration/details"),
    UPLOADS: prefix("/uploads"),
};
