import memoize from "lodash/memoize";
import { lazy } from "react";

// required for text and annotations to work
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const importPDFJS = memoize(async () => {
    const module = await import("react-pdf");

    // init service worker settings
    module.pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        "pdfjs-dist/build/pdf.worker.min.mjs",
        import.meta.url,
    ).toString();

    return module;
});

export const LazyDocument = lazy(() => importPDFJS().then(module => ({ default: module.Document })));
export const LazyPage = lazy(() => importPDFJS().then(module => ({ default: module.Page })));
