import { observer } from "mobx-react";
import { t } from "../../../i18n/util";
import { DetailedEmployee } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { IEmployee } from "../../../types/models";
import { pushRoute } from "../../app/router/history";
import { usePersonnelFile } from "../../hooks/usePersonnelFile";
import { useStaffDocuments } from "../../hooks/useStaffDocuments";
import { HrEmployeeAdditionalDataOverview } from "../../hr/HrEmployeeAdditionalDataOverview";
import { HrEmployeeOverviewFileBar } from "../../hr/HrEmployeeOverviewFileBar";
import { HrEmployeeOverviewHeader } from "../../hr/HrEmployeeOverviewHeader";
import { HrEmployeeOverviewPastEmployments } from "../../hr/HrEmployeeOverviewPastEmployments";
import { HrEmployeePersonalDataOverview } from "../../hr/HrEmployeePersonalDataOverview";
import { HrEmployeeStatus } from "../../hr/HrEmployeeStatus";
import { hrStore } from "../../hr/HrStore";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { StaffRoutes } from "../router/StaffRoutes";

export const StaffPersonnelFileSite = observer(function StaffPersonnelFileSite() {
    const companyId = companiesStore.selectedCompanyId;
    const employee: DetailedEmployee | undefined = usePersonnelFile(companyId);
    const masterDataEmployeeDocuments = useStaffDocuments(
        { category: "masterData", orderBy: "updatedAt", orderDir: "desc" },
        companyId,
    );
    const ongoingEmployeeDocuments = useStaffDocuments(
        { category: "ongoing", orderBy: "updatedAt", orderDir: "desc" },
        companyId,
    );

    const onEdit = () => {
        if (employee) {
            const params = { employeeId: employee.id };
            const query = { isEdit: true, isDocumentUpload: true, subsidiaryId: employee.subsidiaryId };
            pushRoute(StaffRoutes.UPLOAD_DOCUMENTS, { params, query });
        }
    };

    return (
        <>
            <NavBarBack title={t("staff.personnelFile.navbar.heading", { name: authStore.salutation })} />
            <CenteredContent>
                {employee && (
                    <SiteContent style={{ padding: 32, maxWidth: 656 }}>
                        <HrEmployeeOverviewHeader employee={employee as IEmployee} />
                        <HrEmployeeStatus employee={employee as IEmployee} />
                        <HrEmployeePersonalDataOverview employee={employee as IEmployee} showInsuranceType={false} />
                        <HrEmployeeOverviewPastEmployments employee={employee as IEmployee} />
                        <HrEmployeeAdditionalDataOverview employee={employee as IEmployee} />
                        <HrEmployeeOverviewFileBar
                            masterDocuments={masterDataEmployeeDocuments}
                            ongoingDocuments={ongoingEmployeeDocuments}
                            onShowAll={() => {
                                hrStore.selectedSubsidiaryId = employee.subsidiaryId;
                                pushRoute(StaffRoutes.DOCUMENTS, {
                                    params: { employeeId: employee.id },
                                });
                            }}
                            onDownloadFiles={documentIds =>
                                hrStore.downloadEmployeeFiles({
                                    employeeId: employee.id,
                                    isPreRegistration: false,
                                    documentIds,
                                    subsidiaryId: employee.subsidiaryId,
                                })
                            }
                            onAddFiles={companiesStore.selectedCompany?.staffCanUpload ? onEdit : undefined}
                            canEdit
                        />
                    </SiteContent>
                )}
            </CenteredContent>
        </>
    );
});
