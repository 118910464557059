import * as React from "react";
import styled from "styled-components";
import { TpaBadge } from "../ui/Primitives";
import { Icon, IIconNames } from "../util/Icon";
import { customColors } from "../util/Theme";

interface NotificationButtonProps {
    icon: IIconNames;
    text: string;
    count: number;
    onClick: () => void;
    "data-id": string;
    style?: React.CSSProperties;
}

const NotificationButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    padding: 16px;
    background-color: ${customColors.white};
    box-shadow: 0px 3px 6px rgba(54, 75, 141, 0.1);
    margin-bottom: 6px; // needed in order to swipeable-views not cutting off the boxShadow
    border-radius: 4px;
    cursor: pointer;
`;

export const CockpitNotificationButton = ({
    icon,
    text,
    count,
    onClick,
    "data-id": dataId,
    style,
}: NotificationButtonProps) => (
    <NotificationButtonContainer role="button" onClick={onClick} data-id={dataId} style={style}>
        <div>
            <Icon name={icon} style={{ display: "block" }} />
        </div>
        <div style={{ display: "flex", alignItems: "center", flexGrow: 1, marginLeft: 16 }}>
            {text}
            {count > 0 && (
                <TpaBadge
                    style={{
                        backgroundColor: customColors.primaryColor,
                        height: 18,
                        minWidth: 18,
                        borderRadius: 9,
                        lineHeight: "15px",
                        fontSize: 14,
                        marginLeft: 8,
                        paddingTop: 2,
                        paddingLeft: 3,
                        paddingRight: 4,
                    }}
                >
                    {count}
                </TpaBadge>
            )}
        </div>
        <div>
            <Icon name="chevronRight" style={{ display: "block" }} />
        </div>
    </NotificationButtonContainer>
);
