import { observer } from "mobx-react";
import * as React from "react";
import { ModuleStore } from "../../stores/ModuleStore";
import { NavBarChoosePeriod } from "../ui/NavBarChoosePeriod";
import { NavBarChooseSubsidiary } from "../ui/NavBarChooseSubsidiary";
import { FullWidthNavBarContainer, MobileNavBarContainer, NavBarTitle } from "../ui/NavBarContainer";
import { IToggleButtonRoute, NavBarToggleButtons } from "../ui/NavBarToggleButtons";
import { MobileContext } from "../util/MobileContext";
import { TabBarContainer } from "./TabBar";

// ModuleNavBar have full width and no back button. They are usually the navbars at the root of a feature.
export const ModuleNavBar = observer(function ModuleNavBar(props: {
    title: React.ReactNode;
    moduleStore?: ModuleStore;
    buttons: IToggleButtonRoute[] | React.ReactElement;
    showPeriods?: boolean;
    showSubsidiaries?: boolean;
    useEmployeeSubsidiaries?: boolean;
    tabs?: React.ReactNode;
}) {
    const isMobile = React.useContext(MobileContext);

    const title = <NavBarTitle>{props.title}</NavBarTitle>;

    const choosePeriod = props.moduleStore
        ? props.showPeriods !== false && (
              <NavBarChoosePeriod
                  moduleStore={props.moduleStore}
                  data-id="nav_bar_choose_booking_period"
                  style={{ marginRight: isMobile ? 16 : 32 }}
              />
          )
        : null;

    const chooseSubsidiary = props.moduleStore
        ? props.showSubsidiaries !== false && (
              <NavBarChooseSubsidiary
                  moduleStore={props.moduleStore}
                  useEmployeeSubsidiaries={props.useEmployeeSubsidiaries}
                  style={{ marginRight: isMobile ? undefined : 32 }}
              />
          )
        : null;

    let buttonsComponent;
    const { buttons } = props;
    if (Array.isArray(buttons)) {
        buttonsComponent = buttons.length > 0 ? <NavBarToggleButtons buttons={buttons} /> : null;
    } else {
        buttonsComponent = buttons;
    }

    return isMobile ? (
        <MobileNavBarContainer tabs={props.tabs}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 16 }}>
                {title}
                <div style={{ display: "flex" }}>
                    {choosePeriod}
                    {chooseSubsidiary}
                </div>
            </div>
            {buttonsComponent}
        </MobileNavBarContainer>
    ) : (
        <>
            <FullWidthNavBarContainer hideBorder={!!props.tabs}>
                <div style={{ display: "flex", alignItems: "center", flexGrow: 1, flexWrap: "wrap" }}>
                    {title}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            // padding is for wrap case
                            paddingTop: 8,
                            paddingBottom: 8,
                        }}
                    >
                        {choosePeriod}
                        {chooseSubsidiary}
                    </div>
                </div>
                {buttonsComponent}
            </FullWidthNavBarContainer>
            {props.tabs && <TabBarContainer>{props.tabs}</TabBarContainer>}
        </>
    );
});
