import { Divider } from "@material-ui/core";
import sampleSize from "lodash/sampleSize";
import take from "lodash/take";
import { observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { EventsFeedEntry, NewsFeedEntry } from "../../network/APITypes";
import { newsStore } from "../../stores/NewsStore";
import { useHideSideBar } from "../../stores/SideBarStore";
import { pushRoute, withParams } from "../app/router/history";
import { CenteredContent } from "../ui/CenteredContent";
import { MobileContext } from "../util/MobileContext";
import { customColors, DIALOG_WIDTH } from "../util/Theme";
import { CockpitEventCard } from "./CockpitEventCard";
import { CockpitEventDetailsCard } from "./CockpitEventDetailsCard";
import { CockpitNewsCard } from "./CockpitNewsCard";
import { CockpitNewsInformation } from "./CockpitNewsInformation";
import { CockpitRoutes } from "./router/CockpitRoutes";

const NewsContainer = styled.div`
    & h1,
    & h2,
    & h3,
    & h4 {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    & p {
        margin-top: 8px;
    }

    & ul,
    & ol {
        margin-top: 24px;
        padding-left: 32px;
    }

    & ul > li + li {
        margin-top: 8px;
    }

    & ul + p,
    & ol + p {
        margin-top: 24px;
    }

    & a {
        color: ${customColors.primaryColor};
        text-decoration: none;
    }

    & a:hover {
        text-decoration: underline;
    }

    & iframe {
        margin: 24px 0;
    }
`;

interface IProps {
    rightColumnTitle: string;
}

export const CockpitNewsContent = observer(function CockpitNewsContent({ rightColumnTitle }: IProps) {
    const { newsId, eventId } = useParams<{ newsId?: string; eventId?: string }>();
    const news = newsStore.news;
    const promotions = newsStore.promotions;
    const events = newsStore.events;
    const selectedNews = newsStore.getSelectedNews(newsId);
    const selectedEvent = events.find(event => event.id === eventId);
    useHideSideBar();
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [isPromotion, setIsPromotion] = React.useState(false);
    const isMobile = React.useContext(MobileContext);

    const randomNews = React.useMemo(
        () =>
            sampleSize(
                take(
                    news.filter(newsItem => newsItem.id !== newsId),
                    10,
                ),
                2,
            ),
        [news, newsId],
    );

    const randomEvents = React.useMemo(
        () =>
            sampleSize(
                take(
                    events.filter(event => event.id !== eventId),
                    10,
                ),
                2,
            ),
        [events, eventId],
    );

    const showRandomNews = selectedNews && randomNews.length > 0;
    const showRandomEvents = selectedNews && randomEvents.length > 0;

    React.useEffect(() => {
        if (newsId) {
            const selectedPromotionItem = promotions.find(promotion => promotion.id === newsId);

            if (selectedPromotionItem) {
                setIsPromotion(true);
            }
        }
    }, [newsId, promotions]);

    const newsContainerRef = React.useRef<HTMLDivElement | null>(null);

    React.useLayoutEffect(() => {
        if (newsContainerRef.current && isInitialized) {
            const el = newsContainerRef.current;
            const images = el.getElementsByTagName("img");

            Array.from(images).forEach((image, index) => {
                if (image.src.startsWith("https://i.ytimg.com")) {
                    const imageSrcParts = image.src.split("/");
                    const youtubeId = imageSrcParts[imageSrcParts.length - 2];

                    const containerWidth = el.clientWidth;

                    const youtubeFrame = document.createElement("iframe");
                    youtubeFrame.width = `${containerWidth}`;
                    youtubeFrame.height = `${containerWidth ? containerWidth / 1.6 : containerWidth}`;
                    youtubeFrame.src = `https://www.youtube.com/embed/${youtubeId}`;
                    youtubeFrame.style.border = "none";
                    youtubeFrame.allow =
                        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
                    youtubeFrame.allowFullscreen = true;

                    const parent = image.parentElement;

                    if (parent?.tagName === "A") {
                        parent.replaceWith(youtubeFrame);
                    } else {
                        image.replaceWith(youtubeFrame);
                    }
                } else {
                    // use first image as header image if it is not linking somewhere
                    if (index === 0 && image.parentElement?.tagName !== "A") {
                        image.remove();
                    }
                }
            });

            const links = el.getElementsByTagName("a");

            if (links.length > 0) {
                Array.from(links).forEach(link => {
                    link.setAttribute("target", "_blank");
                });
            }
        }
    }, [isInitialized, selectedNews, selectedEvent]);

    if (!newsId && !eventId) {
        // No news id in route -> get out
        return null;
    }

    const selectedItem = selectedNews ?? selectedEvent;

    if (!selectedItem) {
        // Have to check for isInitialized because promos and events CAN be reloaded, only news can't
        if (newsStore.isInitialized) {
            // newsStore is loaded but we have not found a selectedItem -> get out
            if (newsId) {
                pushRoute(CockpitRoutes.NEWS_LIST);
            } else if (eventId) {
                pushRoute(CockpitRoutes.EVENTS_LIST);
            } else {
                pushRoute(CockpitRoutes.ROOT);
            }
        }

        return null;
    } else {
        if (!isInitialized) {
            setIsInitialized(true);
        }
    }

    const handleClickNewsItem = (newsItem: NewsFeedEntry) => {
        pushRoute(withParams(CockpitRoutes.NEWS_ARTICLE, { newsId: newsItem.id }));
    };

    const handleClickEvent = (event: EventsFeedEntry) => {
        pushRoute(withParams(CockpitRoutes.EVENT_ARTICLE, { eventId: event.id }));
    };

    return (
        <CenteredContent>
            <div
                style={{
                    padding: isMobile ? "48px 16px 16px 16px" : "48px 24px 24px 24px",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ flexGrow: 1, maxWidth: isMobile ? undefined : DIALOG_WIDTH }}>
                    <h2>{selectedItem.title}</h2>
                    <img
                        src={selectedItem.imageUrl}
                        alt=""
                        style={{
                            width: "100%",
                            borderRadius: 4,
                            marginTop: 24,
                        }}
                    />
                    <CockpitNewsInformation
                        details={selectedItem}
                        hidePublishDate={isPromotion || !!selectedEvent}
                        hideReadingTime={!!selectedEvent}
                        style={{ marginTop: 16, marginBottom: 24 }}
                    />
                    <NewsContainer ref={newsContainerRef} dangerouslySetInnerHTML={{ __html: selectedItem.content }} />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: isMobile ? undefined : 272,
                        marginLeft: isMobile ? undefined : 16,
                        marginTop: isMobile ? 32 : undefined,
                        flexShrink: 0,
                    }}
                >
                    {selectedEvent && <CockpitEventDetailsCard event={selectedEvent} />}
                    {showRandomNews && (
                        <>
                            <h3 style={{ marginBottom: 24 }}>{rightColumnTitle}</h3>
                            {randomNews.map((newsItem, index) => (
                                <div key={newsItem.id}>
                                    <CockpitNewsCard
                                        news={newsItem}
                                        onClick={handleClickNewsItem}
                                        style={{ marginTop: index === 0 ? undefined : 24 }}
                                    />
                                </div>
                            ))}
                        </>
                    )}
                    {showRandomNews && showRandomEvents && <Divider style={{ marginTop: 32, marginBottom: 32 }} />}
                    {showRandomEvents && (
                        <>
                            <h3 style={{ marginBottom: 24 }}>{t("common.events")}</h3>
                            {randomEvents.map((event, index) => (
                                <div key={event.id}>
                                    <CockpitEventCard
                                        event={event}
                                        onClick={handleClickEvent}
                                        style={{ marginTop: index === 0 ? undefined : 24 }}
                                    />
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </CenteredContent>
    );
});
