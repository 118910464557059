import { AccountingRoutes } from "../components/accounting/router/AccountingRoutes";
import { HrRoutes } from "../components/hr/router/HrRoutes";
import { Module } from "./models";

export interface IModuleRoutes {
    ROOT: string;
    RECORDS: {
        ROOT: string;
        LIST: string;
        TICKETS: string;
        TICKET_DETAILS: string;
        UPLOAD: string;
        DETAILS_UPLOAD: string;
        DETAILS_EDIT: string;
    };
    REPORT_TICKET: string;
    REPORT_UPLOAD: string;
}
export function getModuleRoutes(module: Module): IModuleRoutes {
    return module === "accounting" ? AccountingRoutes : HrRoutes;
}
