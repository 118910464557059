import * as React from "react";
import { Status, statusColors } from "../../stores/Status";
import { IIconNames, Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";

export const StatusBarIcon = (props: { name: IIconNames; status: Status }) => {
    const c = statusColors[props.status];
    const isMobile = React.useContext(MobileContext);
    return (
        <div
            style={{
                background: c.background,
                display: "flex",
                justifyContent: "flex-start",
                padding: isMobile ? "16px 8px 0px 8px" : "24px 16px 0px 16px",
                color: c.color,
            }}
        >
            <Icon name={props.name} />
        </div>
    );
};
