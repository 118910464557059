import { observer } from "mobx-react";
import { useCallback } from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { PowerBIReport } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useInMemoryTableStore, useTableStore } from "../../../stores/TableStore";
import { pushRoute } from "../../app/router/history";
import { MISSING_REQUEST_DATA, useAPI } from "../../hooks/useAPI";
import { CenteredContent } from "../../ui/CenteredContent";
import { EmptyState } from "../../ui/EmptyState";
import { NavBarBack } from "../../ui/NavBarBack";
import { MultiLineEllipsis, TableLabel, TpaTableContainer } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { RenderCell, Table, TableColumn } from "../../ui/Table";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { AnalysisRoutes } from "../router/AnalysisRoutes";

const searchFn = (report: PowerBIReport, search: string) => {
    return report.name.toLowerCase().includes(search);
};

const columns: TableColumn<PowerBIReport>[] = [
    {
        column: "name",
        label: "analysis.powerBI.reports.table.label.name",
    },
    {
        column: "description",
        label: "analysis.powerBI.reports.table.label.description",
    },
];

const renderCell: RenderCell<PowerBIReport> = (report, column) => {
    switch (column.column) {
        case "name":
            return <TableLabel style={{ maxWidth: 500 }}>{report.name}</TableLabel>;
        case "description":
            return report.description ? <MultiLineEllipsis maxLine={2} text={report.description} /> : undefined;
        default:
            return null;
    }
};

export const PowerBIReportsSite = observer(function PowerBIReportsSite() {
    const companyId = companiesStore.selectedCompanyId;

    const loader = useCallback(async () => {
        if (!companyId) {
            return MISSING_REQUEST_DATA;
        }

        const response = await API.getPowerBIReports(companyId);
        return response.reports;
    }, [companyId]);
    const result = useAPI(loader, { generalStoreErrorMessage: "error.loadPowerBIReports" });

    const tableStore = useTableStore<PowerBIReport>("PowerBIReportsSite", {
        orderBy: "name",
        orderDir: "asc",
    });

    useInMemoryTableStore({
        tableStore,
        items: result,
        searchFn,
    });

    const handleRowClick = (report: PowerBIReport) => {
        pushRoute(AnalysisRoutes.POWERBI_REPORT, {
            params: { workspaceId: report.workspaceId, reportId: report.id },
        });
    };

    return (
        <>
            <NavBarBack title={t("analysis.powerBI.reports.title")} />
            <CenteredContent>
                <SiteContent>
                    <TableSearchBarWithAction
                        label="analysis.powerBI.reports.table.search.count"
                        placeholder="analysis.powerBI.reports.table.search.placeholder"
                        search={tableStore.search}
                        totalCount={tableStore.items.length}
                        onChangeSearch={tableStore.handleSearchChange}
                    />
                    <TpaTableContainer>
                        <Table
                            columns={columns}
                            tableStore={tableStore}
                            renderCell={renderCell}
                            onRowClick={handleRowClick}
                        />
                    </TpaTableContainer>
                </SiteContent>
                {tableStore.getIsNoResultState(generalStore.isLoading) && (
                    <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                )}
            </CenteredContent>
        </>
    );
});
