import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetCountriesResponse } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useCountries = () => {
    const [countries, setCountries] = React.useState<GetCountriesResponse | null>(null);

    const isoToString = (iso?: string | null) => {
        if (!countries) {
            return "";
        }

        if (!iso) {
            return "-";
        }

        return countries.find(country => country.isoCode === iso)?.country ?? "-";
    };

    const selectOptions =
        countries?.map(country => ({
            value: country.isoCode,
            label: country.country,
        })) ?? [];

    React.useEffect(() => {
        const load = async () => {
            try {
                const response = await API.getCountries();
                setCountries(response);
            } catch (err) {
                generalStore.setError(t("error.loadCountries"), err);
            }
        };

        load();
    }, []);

    return {
        isoToString,
        selectOptions,
    };
};
