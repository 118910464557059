import { Accordion, withStyles } from "@material-ui/core";
import * as React from "react";
import { Status } from "../../stores/Status";
import { IIconNames } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { IDueDate } from "./Deadline";
import { ExpansionCardHeader } from "./ExpansionCardHeader";
import { StatusBar } from "./StatusBar";
import { StatusBarIcon } from "./StatusBarIcon";

const StyledAccordion = withStyles({
    root: {
        width: "100%",
        fontSize: 16,
        lineHeight: "17px",
        "&:before": {
            height: 0,
        },
        "&.Mui-expanded": {
            margin: 0,
        },
    },
    rounded: {
        borderRadius: 4,
    },
})(Accordion);

export const ExpansionCard = (props: {
    expanded: boolean;
    onExpand?: (isExpanded: boolean) => void;
    title: string;
    iconName: IIconNames;
    date?: IDueDate;
    children: React.ReactNode;
    status: Status;
    disabled?: boolean;
    "data-id": string;
}) => {
    const isMobile = React.useContext(MobileContext);

    return (
        <div style={{ display: "flex", marginBottom: 16 }}>
            <StatusBar status={props.status} />
            {!isMobile && <StatusBarIcon name={props.iconName} status={props.status} />}
            <StyledAccordion
                expanded={props.expanded}
                onChange={(_, isExpanded) => {
                    if (props.onExpand) {
                        props.onExpand(isExpanded);
                    }
                }}
                disabled={props.disabled}
                data-id={props["data-id"]}
            >
                <ExpansionCardHeader
                    expanded={props.expanded}
                    title={props.title}
                    date={props.date}
                    status={props.status}
                    showChevron={!!props.onExpand}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 32,
                        paddingRight: isMobile ? 24 : 64,
                    }}
                >
                    {props.children}
                </div>
            </StyledAccordion>
        </div>
    );
};
