import * as React from "react";
import { useLocation, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Draft } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { handlePostTicketError } from "../../../util/helpers";
import { pushRoute, withParams } from "../../app/router/history";
import { useQueryParams } from "../../hooks/useQueryParams";
import { TicketSiteContent } from "../../shared/TicketSiteContent";
import { ProjectsRoutes } from "../router/ProjectsRoutes";

export const ProjectsTicketSite = () => {
    useHideSideBar();
    const { projectId, projectItemId, projectItemDraftId } = useParams<{
        projectId?: string;
        projectItemId?: string;
        projectItemDraftId?: string;
    }>();
    const { pathname } = useLocation();
    const { ticketId: queryTicketId } = useQueryParams<{ ticketId: string }>();
    const [ticketId, setTicketId] = React.useState(queryTicketId ?? "");
    const [draft, setDraft] = React.useState<Draft | undefined>(undefined);

    const companyId = companiesStore.selectedCompanyId;

    React.useEffect(() => {
        const loadDraft = async () => {
            if (!companyId || !projectId || !projectItemId || !projectItemDraftId) {
                return;
            }

            try {
                const draft = await API.getProjectItemDraft(companyId, projectId, projectItemId, projectItemDraftId);
                setDraft(draft);

                if (draft.ticket) {
                    setTicketId(draft.ticket.id);
                }
            } catch (error) {
                generalStore.setError(t("error.loadDraft"), error);
            }
        };

        loadDraft();
    }, [companyId, projectId, projectItemDraftId, projectItemId]);

    const document = draft?.document;

    const handleCreateTicket = async (message: string): Promise<string | undefined> => {
        let ret = undefined;
        if (companyId && projectId && projectItemId && projectItemDraftId && !ticketId) {
            try {
                generalStore.isLoading = true;
                const ticket = await API.postTicket(companyId, {
                    message: message ?? "",
                    projectItemDraft: {
                        draftId: projectItemDraftId,
                        projectId,
                        projectItemId,
                    },
                });
                setTicketId(ticket.id);
                ret = ticket.id;
                // Update badge count
                await companiesStore.selectedCompanyStore?.startPollingBadges();
            } catch (error) {
                handlePostTicketError(error);
            } finally {
                generalStore.isLoading = false;
            }
        }
        return ret;
    };

    const handleDownloadDraft = async () => {
        if (!companyId) {
            generalStore.setError(t("error.noCompanySelected"));
            return;
        }

        if (!projectId || !projectItemDraftId) {
            return;
        }

        try {
            await API.putDownloadProjectDraft(companyId, projectId, [projectItemDraftId]);
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        }
    };

    const isProjectItemTicketSite =
        projectId &&
        projectItemId &&
        projectItemDraftId &&
        pathname ===
            withParams(ProjectsRoutes.RELEASES_PROJECT_ITEM_TICKET, { projectId, projectItemId, projectItemDraftId });

    const isProjectTicketSiteFromReleases =
        projectId &&
        projectItemId &&
        projectItemDraftId &&
        pathname === withParams(ProjectsRoutes.RELEASES_TICKET, { projectId, projectItemId, projectItemDraftId });

    return (
        <TicketSiteContent
            companyId={companyId}
            document={document}
            onCloseTicket={() => {
                if (isProjectItemTicketSite && projectId && projectItemId) {
                    pushRoute(withParams(ProjectsRoutes.RELEASES_PROJECT_ITEM, { projectId, projectItemId }));
                }

                if (isProjectTicketSiteFromReleases && projectId) {
                    pushRoute(withParams(ProjectsRoutes.RELEASES, { projectId }));
                }
            }}
            onCreateTicket={handleCreateTicket}
            ticketId={ticketId}
            onDownloadDocument={handleDownloadDraft}
            uploadedAt={draft?.document.uploadedAt}
            uploader={draft?.document.uploader}
            draft={draft}
            dummyMessage={
                draft?.comment
                    ? {
                          author: draft.requestor,
                          id: "dummyMessage",
                          sentAt: draft.createdAt,
                          text: draft.comment,
                      }
                    : undefined
            }
        />
    );
};
