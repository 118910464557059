import uniqueId from "lodash/uniqueId";
import moment from "moment";
import { IMessageIDS } from "../../../i18n/util";
import { KPIMetric, KPIMonthlyValue } from "../../../network/APITypes";
import {
    KPIConfiguration,
    KPIConfigurationMetric,
    LargeKPIConfiguration,
    SmallKPIConfiguration,
} from "../../../types/kpis";
import { formatDate } from "../../../util/date";
import { isNever } from "../../../util/ts";

export type Size = "small" | "large";

export const metricToSize: Record<KPIConfigurationMetric, Size> = {
    [KPIConfigurationMetric.ebitValue]: "small",
    [KPIConfigurationMetric.insuranceCarrierBalance]: "small",
    [KPIConfigurationMetric.revenueValue]: "small",
    [KPIConfigurationMetric.taxAccountBalance]: "small",
    [KPIConfigurationMetric.unpaidAccounts]: "small",
    [KPIConfigurationMetric.ebitBarChart]: "large",
    [KPIConfigurationMetric.revenueBarChart]: "large",
};
export const metricsBySize: Record<Size, KPIConfigurationMetric[]> = {
    small: [
        KPIConfigurationMetric.ebitValue,
        KPIConfigurationMetric.insuranceCarrierBalance,
        KPIConfigurationMetric.revenueValue,
        KPIConfigurationMetric.taxAccountBalance,
        KPIConfigurationMetric.unpaidAccounts,
    ],
    large: [KPIConfigurationMetric.ebitBarChart, KPIConfigurationMetric.revenueBarChart],
};

export const metricLabels: Record<KPIConfigurationMetric, IMessageIDS> = {
    [KPIConfigurationMetric.ebitValue]: "screen.cockpit.kpis.ebit.kpi.name",
    [KPIConfigurationMetric.insuranceCarrierBalance]: "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name",
    [KPIConfigurationMetric.revenueValue]: "screen.cockpit.kpis.revenue.kpi.name",
    [KPIConfigurationMetric.taxAccountBalance]: "screen.cockpit.kpis.taxAccount.kpi.name",
    [KPIConfigurationMetric.unpaidAccounts]: "screen.cockpit.kpis.unpaidAccounts.kpi.name",
    [KPIConfigurationMetric.ebitBarChart]: "screen.cockpit.kpis.ebit.chart.name",
    [KPIConfigurationMetric.revenueBarChart]: "screen.cockpit.kpis.revenue.chart.name",
};

export const isSmallKPI = (config: KPIConfiguration): config is SmallKPIConfiguration => {
    return metricToSize[config.metric] === "small";
};
export const isLargeKPI = (config: KPIConfiguration): config is LargeKPIConfiguration => {
    return metricToSize[config.metric] === "large";
};

export const formatMetricDateRange = ({ from, to }: KPIMetric) => {
    return `${formatDate(from)} - ${formatDate(to)}`;
};

export const formatMonthlyValuesDateRange = (values: KPIMonthlyValue[]) => {
    const from = moment(values[0].from);
    const to = moment(values[values.length - 1].to);
    return `${formatDate(from)} - ${formatDate(to)}`;
};

export const formatMonthlyValueDate = (mv: KPIMonthlyValue, includeYear: boolean) => {
    return moment(mv.from).format(includeYear ? "MMM YYYY" : "MMM");
};

const defaultMonths = 5;

export const getDefaultConfig = (metric: KPIConfigurationMetric): KPIConfiguration => {
    switch (metric) {
        case KPIConfigurationMetric.ebitValue:
            return { id: uniqueId(), metric, settings: { months: defaultMonths } };
        case KPIConfigurationMetric.insuranceCarrierBalance:
            return { id: uniqueId(), metric, settings: { carrierNumber: "", accountNumber: "" } };
        case KPIConfigurationMetric.revenueValue:
            return { id: uniqueId(), metric, settings: { months: defaultMonths } };
        case KPIConfigurationMetric.taxAccountBalance:
            return { id: uniqueId(), metric };
        case KPIConfigurationMetric.unpaidAccounts:
            return { id: uniqueId(), metric };
        case KPIConfigurationMetric.ebitBarChart:
            return { id: uniqueId(), metric, settings: { months: defaultMonths } };
        case KPIConfigurationMetric.revenueBarChart:
            return { id: uniqueId(), metric, settings: { months: defaultMonths } };
        default:
            isNever(metric);
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error(`Unknown metric: ${metric}`);
    }
};
