import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { MAX_CHAT_CHARACTERS } from "../../config";
import { t } from "../../i18n/util";
import { FieldError } from "../ui/CustomInputField";
import { TableRowButton } from "../ui/Primitives";
import { User } from "../ui/User";
import { customColors } from "../util/Theme";

const InputArea = styled.div`
    padding-top: 8px;
    border-top: 1px solid ${customColors.greyTextfields};
    display: flex;
    align-items: flex-start;
`;

const modules = {
    toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
        ["clean"],
    ],
};

export const ChatInput = ({
    user,
    message,
    onChange,
    onSend,
    disableSend,
    disableInput,
    style,
}: {
    user: { firstName: string; lastName: string; imageUrl?: string; isTpaEmployee?: boolean };
    message: string;
    onChange: (message: string) => void;
    onSend: () => void;
    disableSend?: boolean;
    disableInput?: boolean;
    style?: React.CSSProperties;
}) => {
    const [showMaxInputError, setShowMaxInputError] = React.useState(false);

    React.useEffect(() => {
        // i18n unfortunately does not work with react-quill (see https://github.com/quilljs/quill/issues/2922).
        // So do it a bit awkward here
        const r = document.documentElement;
        r.style.setProperty("--chat-input-i18n-enter-link", `"${t("chatInput.enterLink")}"`);
        r.style.setProperty("--chat-input-i18n-save-link", `"${t("chatInput.saveLink")}"`);
        r.style.setProperty("--chat-input-i18n-open-link", `"${t("chatInput.openLink")}"`);
        r.style.setProperty("--chat-input-i18n-edit-link", `"${t("chatInput.editLink")}"`);
        r.style.setProperty("--chat-input-i18n-delete-link", `"${t("chatInput.deleteLink")}"`);
    }, []);

    return (
        <InputArea style={style}>
            <User
                firstName={user.firstName}
                lastName={user.lastName}
                imageUrl={user.imageUrl}
                isTpaStaff={user.isTpaEmployee}
                showAvatarOnly
                size={32}
                style={{ marginRight: 16 }}
            />
            <div style={{ marginTop: 2, flex: 1 }}>
                <ReactQuill
                    className="chat-input"
                    theme="snow"
                    modules={modules}
                    value={message}
                    placeholder={t("support.privateChat.placeholder")}
                    onKeyUp={(event: React.KeyboardEvent) => {
                        // Ctrl + Enter to send, Cmd + Enter on Mac does not work since keyup is not fired when CMD is pressed
                        if (!disableSend && event.key === "Enter" && event.ctrlKey) {
                            onSend();
                            event.stopPropagation();
                            event.preventDefault();
                        }
                    }}
                    onChange={message => {
                        // Simple html tag stripping for character counting
                        const regex = /(<([^>]+)>)/gi;
                        const stripped = message.replace(regex, "");

                        if (stripped.length <= MAX_CHAT_CHARACTERS) {
                            onChange(message);
                            setShowMaxInputError(false);
                        } else {
                            setShowMaxInputError(true);
                        }
                    }}
                    // We differentiate between disableSend and disableInput
                    // because putting a TextField to disabled loses focus.
                    // Also we want the user to be able to continue typing while the previous message is
                    // being sent
                    readOnly={disableInput}
                />
                {showMaxInputError && (
                    <FieldError>{t("error.tooManyCharacters", { maxCharacters: MAX_CHAT_CHARACTERS })}</FieldError>
                )}
            </div>
            <TableRowButton
                style={{ marginTop: 4 }}
                color="primary"
                onClick={onSend}
                disabled={!!disableSend || showMaxInputError}
                data-id="chat_send"
            >
                {t("common.sendCaps")}
            </TableRowButton>
        </InputArea>
    );
};
