import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useState } from "react";
import { Redirect, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { KPIConfiguration } from "../../../types/kpis";
import { UseParams, pushRoute, withParams } from "../../app/router/history";
import { KPIs } from "../../cockpit/kpis/KPIs";
import { useCompanyStore } from "../../hooks/useCompanyStore";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { NavBarBack } from "../../ui/NavBarBack";
import { PageWithStepper } from "../../ui/PageWithStepper";
import { AdvisorConfigCompanyStepper } from "../AdvisorConfigCompanyStepper";
import { AdvisorRoutes } from "../router/AdvisorRoutes";

export const AdvisorConfigKPIsSite = observer(function AdvisorConfigKPIsSite() {
    useHideSideBar();

    const { companyId } = useParams<UseParams<(typeof AdvisorRoutes)["CONFIG"]["KPIS"]>>();
    const { companyStore } = useCompanyStore({ companyId });

    const successDialog = useSuccessDialog({
        title: t("dialog.companyConfig.success.header"),
        message: t("dialog.companyConfig.success.message", { name: companyStore?.name }),
        onClose: () => {
            if (companyId) {
                pushRoute(withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId }));
            } else {
                pushRoute(AdvisorRoutes.COMPANIES.INACTIVE);
            }
        },
    });

    const [configurations, setConfigurations] = useState<KPIConfiguration[] | null>(null);

    const handleSubmit = async () => {
        if (configurations) {
            await companyStore?.kpiStore.saveConfigurations(configurations);
        }

        successDialog.openDialog();
    };

    if (!companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    if (!companyStore) {
        // Company not loaded yet -> wait
        return null;
    }

    return (
        <>
            <NavBarBack
                title={t("config.companies")}
                backLabel={`${t("config.overview")}/${t("common.hr")}`}
                backTarget={withParams(AdvisorRoutes.CONFIG.HR, { companyId })}
                cancelTarget={withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId })}
                companyName={companyStore.name}
            />
            <PageWithStepper stepper={<AdvisorConfigCompanyStepper company={companyStore.company} />} fullWidth>
                <KPIs
                    kpiStore={companyStore.kpiStore}
                    startInEditMode
                    onChange={setConfigurations}
                    header={<h1>{t("common.kpi")}</h1>}
                />
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}>
                    <Button color="primary" variant="contained" onClick={handleSubmit}>
                        {t("button.nextStep")}
                    </Button>
                </div>
            </PageWithStepper>
            {successDialog.dialog}
        </>
    );
});
