import { Button } from "@material-ui/core";
import React from "react";
import { t } from "../../i18n/util";
import { ISignatureUser, ISignatureUserSignature } from "../../util/qes";
import { CustomDialog, CustomDialogContent } from "../ui/CustomDialog";
import { IAcceptedFile } from "../ui/DocumentUploadSmall";
import { TpaWhiteButton } from "../ui/Primitives";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";
import { SignaturePositions } from "../ui/SignaturePositions";

// Use this to specify the signature position for a document uploaded via DocumentUploadSmall
export const usePdfSignaturePositionDocumentUploadDialog = <FileWithMetaData extends IAcceptedFile>(
    onSelectSignaturePositions: (file: FileWithMetaData, signaturePositions: ISignatureUserSignature[]) => void,
) => {
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState<FileWithMetaData>();
    const [responsibleUsers, setResponsibleUsers] = React.useState<ISignatureUser[]>([]);
    const [signaturePositions, setSignaturePositions] = React.useState<ISignatureUserSignature[]>([]);
    const [overlapping, setOverlapping] = React.useState(false);

    const openDialog = (
        file: FileWithMetaData,
        users: ISignatureUser[],
        initialSignaturePositions: ISignatureUserSignature[] | undefined,
    ) => {
        setFile(file);
        setResponsibleUsers(users);
        if (initialSignaturePositions) {
            setSignaturePositions(initialSignaturePositions);
        } else {
            setSignaturePositions([]);
        }
        setOpen(true);
    };

    const handleChangeSignaturePositions = (signatures: ISignatureUserSignature[], overlapping: boolean) => {
        setSignaturePositions(signatures);
        setOverlapping(overlapping);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const submitSignature = () => {
        if (!file) {
            return;
        }
        onSelectSignaturePositions(file, signaturePositions);
        setOpen(false);
    };

    const continueButtonEnabled = !overlapping && signaturePositions.length === responsibleUsers.length;

    const component = (
        <CustomDialog open={open} maxWidth={"lg"} fullWidth={false}>
            <CustomDialogContent>
                <h1>{t("selectQesPosition.title")}</h1>
                <p style={{ marginTop: 24 }}>{t("selectQesPosition.message.collective", { button: t("common.ok") })}</p>
                <SignaturePositions
                    file={file?.file}
                    users={responsibleUsers}
                    collectiveRelease={true}
                    onChange={handleChangeSignaturePositions}
                    hideUsers={responsibleUsers.length === 1}
                />
                <ResponsiveButtonContainer style={{ marginTop: 24 }}>
                    <TpaWhiteButton onClick={handleClose}>{t("common.cancel")}</TpaWhiteButton>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submitSignature}
                        disabled={!continueButtonEnabled}
                    >
                        {t("common.ok")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );

    return {
        component,
        open: openDialog,
    };
};
