import { Checkbox, FormControlLabel } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { getCurrentPeriodId, periodToString } from "../../stores/ModuleStore";
import { getModuleStore } from "../../stores/moduleStores";
import { ICompany, IPeriod, Module } from "../../types/models";
import { formatDate, formatISODateOnly } from "../../util/helpers";
import { accountingStore } from "../accounting/AccountingStore";
import { CustomDatePicker } from "../ui/CustomDatePicker";
import { CustomInputField } from "../ui/CustomInputField";
import { CustomSelect } from "../ui/CustomSelect";
import { customColors } from "../util/Theme";
import { ConfirmWebhook } from "../webhooks/ConfirmWebhook";
import { usePeriods } from "./usePeriods";
import { useRecordTypes } from "./useRecordTypes";
import { usePeriodSubsidiaries } from "./useSubsidiaries";

interface IFormValues {
    dueDate?: MaterialUiPickersDate;
    period: string;
    subsidiary: string;
    taskTitle?: string;
}

// Had to extract outside of component. useCallback lead to infinite loading loop
const getInitialPeriod = (periods: IPeriod[], module: Module, company?: ICompany | null) => {
    const moduleStore = getModuleStore(module);
    if (periods.findIndex(period => period.id === moduleStore.selectedPeriodId) >= 0) {
        return moduleStore.selectedPeriodId;
    } else {
        return getCurrentPeriodId(module, periods, company);
    }
};

export const useWebhookModuleConfiguration = ({
    companyId,
    company,
    module,
    isGlobal,
    ...options
}: {
    companyId?: string;
    company?: ICompany | null;
    module?: Module | "";
    isGlobal?: boolean;
    periodId?: string;
    subsidiaryId?: string;
    recordTypeId?: string;
}) => {
    const [currentModule, setCurrentModule] = React.useState(module);
    const [currentCompanyId, setCurrentCompanyId] = React.useState(companyId);

    const [periodId, setPeriodId] = React.useState("");
    const [subsidiaryId, setSubsidiaryId] = React.useState("");
    const [recordTypeId, setRecordTypeId] = React.useState("");

    const [globalReport, setGlobalReport] = React.useState(isGlobal ?? false);
    const [notifyCustomer, setNotifyCustomer] = React.useState(true);

    const { periods, reset: resetPeriods } = usePeriods(currentCompanyId, currentModule);
    // Preselect period
    React.useEffect(() => {
        if (periods.length > 0 && currentModule && !periodId) {
            if (options.periodId && periods.find(p => p.id === options.periodId)) {
                setPeriodId(options.periodId);
            } else {
                const initialPeriod = getInitialPeriod(periods, currentModule, company);
                if (initialPeriod) {
                    setPeriodId(initialPeriod);
                }
            }
        }
    }, [company, currentModule, options.periodId, periodId, periods]);

    const { subsidiaries, reset: resetSubsidiaries } = usePeriodSubsidiaries({
        companyId: currentCompanyId,
        module: currentModule,
        periodId,
    });
    // Preselect subsidiary
    React.useEffect(() => {
        // Current subsidiary doesn't exist in new array -> set current to option or first subsidiary
        if (subsidiaries.length > 0 && (!subsidiaryId || !subsidiaries.find(s => s.id === subsidiaryId))) {
            if (options.subsidiaryId && subsidiaries.find(s => s.id === options.subsidiaryId)) {
                setSubsidiaryId(options.subsidiaryId);
            } else {
                setSubsidiaryId(subsidiaries[0].id);
            }
        }
    }, [options.subsidiaryId, subsidiaries, subsidiaryId]);

    const { recordTypes, reset: resetRecordTypes } = useRecordTypes({
        companyId: currentCompanyId,
        module: currentModule,
        periodId,
        subsidiaryId,
    });
    // Preselect record type
    React.useEffect(() => {
        if (recordTypes.length > 0 && (!recordTypeId || !recordTypes.find(rt => rt.id === recordTypeId))) {
            if (options.recordTypeId && recordTypes.find(rt => rt.id === options.recordTypeId)) {
                setRecordTypeId(options.recordTypeId);
            } else {
                setRecordTypeId(recordTypes[0].id);
            }
        }
    }, [options.recordTypeId, recordTypeId, recordTypes]);

    // Reset period/subsidiary if module/company changes
    React.useEffect(() => {
        if (module !== currentModule || companyId !== currentCompanyId) {
            setPeriodId("");
            setSubsidiaryId("");
            setRecordTypeId("");

            resetPeriods();
            resetSubsidiaries();
            resetRecordTypes();

            setCurrentModule(module);
            setCurrentCompanyId(companyId);
        }
    }, [companyId, currentCompanyId, currentModule, module, resetPeriods, resetRecordTypes, resetSubsidiaries]);

    return {
        initialized: periods.length > 0 && subsidiaries.length > 0,
        company,
        currentModule,
        setCurrentModule,
        currentCompanyId,
        setCurrentCompanyId,
        periodId,
        setPeriodId,
        periods,
        subsidiaryId,
        setSubsidiaryId,
        subsidiaries,
        recordTypeId,
        setRecordTypeId,
        recordTypes,
        globalReport,
        setGlobalReport,
        notifyCustomer,
        setNotifyCustomer,
    };
};

interface ModuleConfigurationProps extends ReturnType<typeof useWebhookModuleConfiguration> {
    dueDate?: Date | string;
    showGlobal?: boolean;
    showNotifyCustomer?: boolean;
    showDueDate?: boolean;
    chooseRecordTypes?: boolean;
    initialTaskTitle?: string;
    submitButtonLabel?: string;
    disabled?: boolean;
    onSubmit: (dueDate?: Date, taskTitle?: string) => void | Promise<void>;
    children?: React.ReactNode;
}

export const ModuleConfiguration = (props: ModuleConfigurationProps) => {
    const {
        company,
        currentModule,
        periodId,
        setPeriodId,
        periods,
        subsidiaryId,
        setSubsidiaryId,
        subsidiaries,
        recordTypeId,
        setRecordTypeId,
        recordTypes,
        globalReport,
        setGlobalReport,
        notifyCustomer,
        setNotifyCustomer,
        dueDate,
        showGlobal,
        showNotifyCustomer,
        showDueDate,
        chooseRecordTypes,
        initialTaskTitle,
        submitButtonLabel,
        onSubmit,
        disabled,
        children,
    } = props;

    const [isLoading, setIsLoading] = React.useState(false);

    const hasGlobal =
        currentModule === "accounting" ? company?.hasGlobalAccountingReports : company?.hasGlobalHRReports;

    const initialValues = {
        dueDate: dueDate ? moment(dueDate) : null,
        period: periodId,
        subsidiary: subsidiaryId,
        taskTitle: initialTaskTitle,
        recordType: recordTypeId,
    };

    const currentDate = moment().startOf("day");

    const handleSubmit = async (values: IFormValues) => {
        if (!values.period) {
            return;
        }

        setIsLoading(true);
        await onSubmit(values.dueDate?.toDate(), values.taskTitle);
        setIsLoading(false);
    };

    const moduleStore = currentModule ? getModuleStore(currentModule) : accountingStore;

    const currentDateString = formatISODateOnly(new Date());

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
                period: Yup.string().required(t("screen.advisor.uploads.upload.dueDate.validation")).nullable(),
                dueDate: Yup.date()
                    .min(currentDateString, t("error.dueDate.minDate", { dueDate: formatDate(new Date()) }))
                    .typeError(t("error.invalidDateFormat"))
                    .nullable(),
            })}
            validateOnBlur
            validateOnChange
            enableReinitialize
        >
            {formikProps => {
                const isInvalidDueDate =
                    formikProps.values.dueDate === null ? false : !formikProps.values.dueDate.isValid();

                const isPastDate = !!formikProps.values.dueDate && formikProps.values.dueDate.isBefore(currentDate);

                return (
                    <Form>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Field
                                component={CustomSelect}
                                name="period"
                                data-id="select_period"
                                label={moduleStore.t("common.accounting.period")}
                                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                    const periodId = event.target.value;
                                    // Set directly and not in submit, so that uploadFile has all data available on callback time
                                    setPeriodId(periodId);
                                    setSubsidiaryId("");
                                    setRecordTypeId("");
                                }}
                                options={periods.map(period => ({
                                    value: period.id,
                                    label: periodToString(period, true),
                                }))}
                                disabled={disabled}
                                required
                            />
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                                <Field
                                    component={CustomSelect}
                                    value={subsidiaryId}
                                    name="subsidiary"
                                    data-id="select_subsidiary"
                                    label={moduleStore.t("common.accounting.subsidiary")}
                                    variant="outlined"
                                    select
                                    style={{ flexGrow: 1 }}
                                    onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                        setSubsidiaryId(event.target.value);
                                        setRecordTypeId("");
                                    }}
                                    options={subsidiaries.map(subsidiary => ({
                                        value: subsidiary.id,
                                        label: subsidiary.name,
                                    }))}
                                    required
                                    disabled={globalReport || disabled}
                                />
                                {hasGlobal && showGlobal !== false && (
                                    <FormControlLabel
                                        style={{ marginLeft: 16 }}
                                        checked={globalReport}
                                        control={<Checkbox color="primary" />}
                                        label={t("screen.advisor.uploads.upload.global")}
                                        onChange={(_, checked) => {
                                            setGlobalReport(checked);
                                        }}
                                    />
                                )}
                            </div>
                            {chooseRecordTypes && (
                                <Field
                                    component={CustomSelect}
                                    name="recordType"
                                    data-id="select_recordType"
                                    label={moduleStore.t("screen.advisor.uploads.upload.category")}
                                    onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                        const recordTypeId = event.target.value;
                                        // Set directly and not in submit, so that uploadFile has all data available on callback time
                                        setRecordTypeId(recordTypeId);
                                    }}
                                    options={recordTypes.map(recordType => ({
                                        value: recordType.id,
                                        label: moduleStore.getRecordTypeName(recordType),
                                    }))}
                                    disabled={disabled}
                                    required
                                />
                            )}

                            {initialTaskTitle && (
                                <Field
                                    component={CustomInputField}
                                    name="taskTitle"
                                    label={t("common.taskTitle")}
                                    hideOptional
                                />
                            )}

                            {showDueDate && (
                                <Field
                                    component={CustomDatePicker}
                                    name="dueDate"
                                    label={t("common.dueDate")}
                                    disablePast
                                    hideOptional
                                    iconStyle={{ color: customColors.primaryColor }}
                                />
                            )}

                            {children}

                            <ConfirmWebhook
                                notifyCustomer={notifyCustomer}
                                setNotifyCustomer={showNotifyCustomer !== false ? setNotifyCustomer : undefined}
                                disabled={
                                    isLoading ||
                                    !periodId ||
                                    !subsidiaryId ||
                                    (!recordTypeId && !!chooseRecordTypes) ||
                                    isInvalidDueDate ||
                                    isPastDate
                                }
                                buttonLabel={submitButtonLabel}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
