import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { getCountryName } from "../../util/helpers";
import { useFeedCountries } from "../hooks/useFeedCountries";
import { ContextMenu, ContextMenuLine, useContextMenu } from "../ui/ContextMenu";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

export const CockpitCountries = observer(function CockpitCountries({ style }: { style: React.CSSProperties }) {
    const contextMenu = useContextMenu();
    const countries = useFeedCountries();

    const currentCountryCode = companiesStore.userCompanySettings?.feedCountryCode;
    const currentFeedCountry = countries.getCountryByCode(currentCountryCode);

    return (
        <>
            <div
                onClick={contextMenu.open}
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    ...style,
                }}
            >
                <div
                    style={{
                        maxWidth: "80%",
                        fontSize: 10,
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                        fontWeight: "bold",
                    }}
                >
                    {currentFeedCountry && (
                        <ContextMenuLine
                            item={{
                                title: getCountryName(currentFeedCountry.countryCode),
                                icon: <img src={currentFeedCountry.iconUrl} alt="" />,
                            }}
                            config={contextMenu}
                        />
                    )}
                </div>
                <Icon name={contextMenu.isOpen ? "dropUp" : "dropDown"} style={{ color: customColors.primaryColor }} />
            </div>

            <ContextMenu
                config={contextMenu}
                data-id="choose_country_context_menu"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                items={countries.countries.map(c => ({
                    title: getCountryName(c.countryCode),
                    "data-id": `country_${c.countryCode.toLowerCase()}`,
                    icon: <img src={c.iconUrl} alt="" />,
                    onClick: async () => {
                        // No company selected
                        if (!companiesStore.selectedCompanyId) {
                            return;
                        }

                        // No country change
                        if (companiesStore.userCompanySettings?.feedCountryCode === c.countryCode) {
                            return;
                        }

                        try {
                            companiesStore.userCompanySettings = await API.patchUserCompanySettings(
                                companiesStore.selectedCompanyId,
                                {
                                    feedCountryCode: c.countryCode,
                                },
                            );
                            contextMenu.close();
                        } catch (err) {
                            generalStore.setError(t("error.save"), err);
                        }
                    },
                }))}
            />
        </>
    );
});
