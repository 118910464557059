import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { t } from "../../../i18n/util";
import { TermsOfUseSignee } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import {
    AdvisorConfigTermsOfUseContentWithCurrentSignees,
    setDocumentVersionAndSignees,
} from "../../advisor/sites/AdvisorConfigTermsOfUseSite";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { CenteredContent } from "../../ui/CenteredContent";
import { SiteContent } from "../../ui/SiteContent";
import { DIALOG_WIDTH } from "../../util/Theme";
import { SettingsRoutes } from "../router/SettingsRoutes";

type Variant = "current" | "upcoming";

export const SettingsCompanyTermsSite = observer(function SettingsCompanyTermsSite({ variant }: { variant: Variant }) {
    const successDialog = useSuccessDialog({
        title: t("config.terms.success.header"),
        onClose: async () => {
            try {
                await companiesStore.reloadCompany();
                pushRoute(SettingsRoutes.COMPANY.DATA);
            } catch (error) {
                generalStore.setError(t("error.loadCompany"), error);
            }
        },
    });

    if (!authStore.isAdvisor) {
        return <Redirect to={Routes.ROOT} />;
    }

    const companyId = companiesStore.selectedCompanyId;
    const companyTerms =
        variant === "current"
            ? companiesStore.selectedCompany?.companyTerms
            : companiesStore.selectedCompany?.upcomingCompanyTerms;
    if (!companyId || !companyTerms) {
        return null;
    }

    const companyTermsId = companyTerms.id;

    const handleSubmit = async (documentVersion: string, signees: TermsOfUseSignee[]) => {
        const success = await setDocumentVersionAndSignees(companyId, companyTermsId, {
            fileName: documentVersion,
            signees: signees.map(signee => signee.email),
            sendEmail: variant !== "upcoming", // only send email for current terms, upcoming terms are still in flux
        });

        if (!success) {
            return;
        }

        successDialog.openDialog();
    };

    return (
        <CenteredContent>
            <SiteContent style={{ maxWidth: DIALOG_WIDTH, paddingTop: 40 }}>
                <AdvisorConfigTermsOfUseContentWithCurrentSignees
                    variant={variant}
                    isSettings
                    companyId={companyId}
                    companyTermsId={companyTermsId}
                    termsAccepted={!!companyTerms.acceptedAt}
                    onSubmit={handleSubmit}
                    onSkip={noop}
                    onAlreadyAcceptedSubmit={noop}
                />
                {successDialog.dialog}
            </SiteContent>
        </CenteredContent>
    );
});

function noop() {
    // noop
}
