import { TableBody } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import * as React from "react";
import { ROWS_PER_PAGE } from "../../../config";
import { t } from "../../../i18n/util";
import { API, IGetEmployeesParams } from "../../../network/API";
import { Employee } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { formatDate, getFormattedISODateOnlyRange } from "../../../util/date";
import { getFullName } from "../../../util/user";
import { pushRoute } from "../../app/router/history";
import { useReasonsForLeaving } from "../../hooks/useReasonsForLeaving";
import { useTableFilters } from "../../hooks/useTableFilters";
import { EmptyState } from "../../ui/EmptyState";
import { TableLabel, TpaTable, TpaTableCell, TpaTableContainer, TpaTableRow } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { DATE_FILTER_TYPE, ITableFilters } from "../../ui/filter/types";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

export const HrEmployeesFormerSite = observer(function HrEmployeesFormerSite() {
    const [employees, setEmployees] = React.useState<Employee[]>([]);
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [isInitialized, setIsInitialized] = React.useState(false);
    const reasonsForLeaving = useReasonsForLeaving();
    const tableStore = useTableStore("HrEmployeesFormerSite", { orderBy: "name", orderDir: "asc" });
    const { offset, orderBy, orderDir, search } = tableStore;

    const hasPreRegistration = companiesStore.selectedCompany?.preRegistrationEnabled;

    const onRegisterEmployee = () => {
        pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA);
    };

    const companyId = companiesStore.selectedCompanyId;

    const reloadEmployees = React.useCallback(async () => {
        try {
            generalStore.isLoading = true;

            const options: IGetEmployeesParams["options"] = {
                offset,
                limit: ROWS_PER_PAGE,
                orderBy,
                orderDir,
            };

            if (search) {
                options.search = search;
            }

            if (startDate) {
                options.startDate = startDate;
            }

            if (endDate) {
                options.endDate = endDate;
            }

            const employees = await API.getPastEmployees(companyId, hrStore.selectedSubsidiaryId, {
                options: options,
            });

            if (employees) {
                setEmployees(employees.employees ?? []);
                tableStore.totalCount = employees.total;
            }
        } catch (err) {
            generalStore.setError(t("error.loadEmployees"), err);
        } finally {
            setIsInitialized(true);
            generalStore.isLoading = false;
        }
    }, [offset, orderBy, orderDir, search, startDate, endDate, companyId, tableStore]);

    React.useEffect(() => {
        reloadEmployees();
    }, [reloadEmployees]);

    const headerFields: ITableHeaderConfig[] = [
        { column: "name", label: "table.label.employee" },
        { column: "jobDescription", label: "table.label.jobDescription" },
        { column: "reasonForLeaving", label: "table.label.reasonForLeaving" },
        { column: "dateOfLeaving", label: "table.label.dateOfLeaving" },
        { column: "personnelNumber", label: "table.label.personalId" },
        { column: "lastChangedAt", label: "table.label.updatedAt", sort: false },
    ];

    const filters = useTableFilters({
        filters: [
            {
                category: "general",
                entries: [
                    {
                        name: "anyDateOfLeaving",
                        label: t("screen.hr.employee.former.anyDateOfLeaving"),
                        type: DATE_FILTER_TYPE,
                        onChange: (date: Date) => {
                            const { startDate, endDate } = getFormattedISODateOnlyRange(date, "month");
                            setStartDate(startDate);
                            setEndDate(endDate);
                        },
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            tableStore.resetOffset();

            if (!selected.anyDateOfLeaving) {
                setStartDate("");
                setEndDate("");
            }
        },
    });

    const tableBody = (
        <TableBody>
            {employees.map((employee, index) => {
                return (
                    <TpaTableRow
                        key={employee.id}
                        onClick={() => {
                            pushRoute(HrRoutes.EMPLOYEES.DETAILS.replace(":employeeId", employee.id));
                        }}
                        role="button"
                        style={{ cursor: "pointer" }}
                        data-id={`employee_${index}`}
                    >
                        {headerFields.map(hf => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = employee[hf.column as keyof Employee];
                            if (hf.column === "name") {
                                label = getFullName(employee);
                            } else if (hf.column === "jobDescription") {
                                label = employee.jobDescription ?? "";
                            } else if (hf.column === "dateOfLeaving") {
                                label = formatDate(employee.dateOfLeaving);
                            } else if (hf.column === "reasonForLeaving") {
                                label = reasonsForLeaving.idToString(employee.reasonForLeaving);
                            } else if (hf.column === "lastChangedAt") {
                                label = formatDate(employee.lastChangedAt);
                            }

                            return (
                                <TpaTableCell key={hf.column} style={{ padding: 16 }}>
                                    <TableLabel>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    if (!isInitialized) {
        return null;
    }

    const canEdit = authStore.canEditEmployees(hrStore.selectedSubsidiaryId);

    return (
        <>
            {tableStore.getIsEmptyState(generalStore.isLoading, !isEmpty(filters.activeFilters)) ? (
                <EmptyState
                    title={t("screen.hr.employee.emptystate.title")}
                    message={t("screen.hr.employee.emptystate.message")}
                    buttonLabel={hasPreRegistration ? t("screen.hr.employee.emptystate.buttonlabel") : undefined}
                    onAddEntry={hasPreRegistration ? onRegisterEmployee : undefined}
                    disabled={!canEdit}
                    data-id="empty_state"
                />
            ) : (
                <>
                    <SiteContent>
                        <TableSearchBarWithAction
                            label="search.caption.numPersons"
                            placeholder="search.placeholder.searchForEmployees"
                            search={tableStore.search}
                            totalCount={tableStore.totalCount}
                            onChangeSearch={tableStore.handleSearchChange}
                            disabled={!canEdit}
                            buttonLabel={
                                hasPreRegistration ? t("screen.hr.employees.current.registerEmployee") : undefined
                            }
                            onAction={hasPreRegistration ? onRegisterEmployee : undefined}
                            tableFilters={filters}
                        />
                        <TpaTableContainer>
                            <TpaTable>
                                <TableHeader headerFields={headerFields} tableStore={tableStore} />
                                {tableBody}
                            </TpaTable>
                        </TpaTableContainer>
                        <tableStore.Pagination />
                    </SiteContent>
                    {tableStore.getIsNoResultState(generalStore.isLoading, !isEmpty(filters.activeFilters)) && (
                        <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                    )}
                </>
            )}
        </>
    );
});
