import { ITableParams } from "../../network/API";
import { CompanyDocumentScope, TicketRelation } from "../../network/APITypes";
import { Module } from "../../types/models";
import { usePreRegistrationTickets } from "./usePreRegistrationTickets";
import { useRecordTypeTickets } from "./useRecordTypeTickets";
import { useTickets } from "./useTickets";

export type TicketType = "all" | "preRegistration" | "recordType";

export const useListTickets = ({
    companyId,
    ticketType,
    recordTypeParams,
    tableParams,
    module,
    ticketParams,
}: {
    companyId?: string;
    ticketType: TicketType;
    tableParams: ITableParams;
    recordTypeParams?: {
        recordTypeId?: string;
    };
    ticketParams?: {
        subsidiaryId?: string;
        startDate?: string;
        endDate?: string;
        relation?: TicketRelation;
        scope?: CompanyDocumentScope;
    };
    module?: Module | "projects";
}) => {
    const tickets = useTickets({
        companyId: ticketType === "all" ? companyId : undefined,
        tableParams,
        module: module ?? "all",
        ...ticketParams,
    });

    const recordTypeTickets = useRecordTypeTickets({
        companyId: ticketType === "recordType" ? companyId : undefined,
        module: module === "projects" ? undefined : module,
        tableParams,
        ...recordTypeParams,
    });

    const preRegistrationTickets = usePreRegistrationTickets({
        companyId: ticketType === "preRegistration" ? companyId : undefined,
        tableParams,
    });

    if (ticketType === "recordType") {
        return recordTypeTickets;
    } else if (ticketType === "preRegistration") {
        return preRegistrationTickets;
    } else {
        return tickets;
    }
};
