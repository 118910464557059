import * as React from "react";
import { t } from "../../i18n/util";
import { GetEmployeeDocumentsResponse } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { hrStore } from "../hr/HrStore";
import { IGetEmployeeDocumentsParams, API } from "../../network/API";
import { companiesStore } from "../../stores/CompaniesStore";

export const useEmployeeDocuments = (
    params: IGetEmployeeDocumentsParams,
    isPreRegistration: boolean,
    employeeId: string,
    companyId?: string,
    subsidiaryId?: string,
) => {
    const [documents, setDocuments] = React.useState<GetEmployeeDocumentsResponse | null>(null);

    React.useEffect(() => {
        const loadEmployeeDocuments = async () => {
            if (!employeeId) {
                return;
            }

            try {
                generalStore.isLoading = true;
                const documents = await API.getEmployeeDocuments(
                    companyId ?? companiesStore.selectedCompanyId,
                    subsidiaryId ?? hrStore.selectedSubsidiaryId,
                    employeeId,
                    isPreRegistration,
                    params,
                );
                if (documents) {
                    setDocuments(documents);
                }
            } catch (error) {
                generalStore.setError(t("error.loadEmployeeDocuments"), error);
            } finally {
                generalStore.isLoading = false;
            }
        };

        loadEmployeeDocuments();
        // Needed because params should not be dependency, otherwise you run into an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeId, companyId]);
    return documents;
};
