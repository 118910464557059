import { observer } from "mobx-react";
import React from "react";
import { t } from "../../../i18n/util";
import { FinancialAccountancy } from "../../../network/APITypes";
import { withParams } from "../../app/router/history";
import { ModuleNavBar } from "../../ui/ModuleNavBar";
import { IToggleButtonRoute, NavBarToggleButtons } from "../../ui/NavBarToggleButtons";
import { useResultViews } from "../hooks/useResultViews";
import { Disclaimer } from "../ResultsDisclaimer";
import { ResultsRoutes } from "../router/ResultsRoutes";
import { Views } from "../types";

interface Props {
    title: React.ReactNode;
    financialAccountancy?: FinancialAccountancy;
    query?: string;
}

const buttonFactory: Record<Views, (query: string) => IToggleButtonRoute> = {
    cashAccounting: query => ({
        title: t("results.navbar.buttons.cashAccounting"),
        value: "cashAccounting",
        route: ResultsRoutes.CASH_ACCOUNTING + query,
        matchRoute: ResultsRoutes.CASH_ACCOUNTING,
    }),
    accountListing: query => ({
        title: t("results.navbar.buttons.accountListing"),
        value: "accountListing",
        route: ResultsRoutes.ACCOUNT_LISTING + query,
        matchRoute: ResultsRoutes.ACCOUNT_LISTING,
    }),
    customerListing: query => ({
        title: t("results.navbar.buttons.customerListing"),
        value: "customerListing",
        route: ResultsRoutes.CUSTOMER_LISTING + query,
        matchRoute: ResultsRoutes.CUSTOMER_LISTING,
    }),
    vendorListing: query => ({
        title: t("results.navbar.buttons.vendorListing"),
        value: "vendorListing",
        route: ResultsRoutes.VENDOR_LISTING + query,
        matchRoute: ResultsRoutes.VENDOR_LISTING,
    }),
    balanceSheet: query => ({
        title: t("results.navbar.buttons.balanceSheet"),
        value: "balanceSheet",
        route: ResultsRoutes.BALANCE_SHEET + query,
        matchRoute: ResultsRoutes.BALANCE_SHEET,
    }),
    profitAndLoss: query => ({
        title: t("results.navbar.buttons.profitAndLoss"),
        value: "profitAndLoss",
        route: ResultsRoutes.PROFIT_AND_LOSS + query,
        matchRoute: ResultsRoutes.PROFIT_AND_LOSS,
    }),
    employerPayrollAccount: query => {
        const currentYear = new Date().getFullYear();
        return {
            title: t("results.navbar.buttons.payrollAccount"),
            value: "employerPayrollAccount",
            route: withParams(ResultsRoutes.EMPLOYER_PAYROLL_ACCOUNT, { year: currentYear }),
            matchRoute: withParams(ResultsRoutes.EMPLOYER_PAYROLL_ACCOUNT, { year: "" }),
        };
    },
};

const emptyButtons: IToggleButtonRoute[] = [];

export const ResultsNavBar = observer(function ResultsNavBar({ title, financialAccountancy, query }: Props) {
    query = query ? "?" + query : "";

    let buttons = emptyButtons;
    const views = useResultViews({ financialAccountancy });
    if (views) {
        buttons = views.map(view => buttonFactory[view](query));
    }

    return (
        <>
            <ModuleNavBar title={title} buttons={<NavBarToggleButtons buttons={buttons} maxVisible={2} />} />
            <Disclaimer />
        </>
    );
});
