import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Redirect } from "react-router";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { coordinator } from "../../../stores/Coordinator";
import { getMfaValidation, passwordRegex } from "../../../util/helpers";
import { Routes } from "../../app/router/Routes";
import { useMultiFactorAuth } from "../../hooks/useMultiFactorAuth";
import { useQueryParams } from "../../hooks/useQueryParams";
import { CustomInputField } from "../../ui/CustomInputField";
import { AuthGeneralContainerSite } from "./AuthGeneralContainerSite";

export const AuthRegistrationSite = () => {
    const { token } = useQueryParams<{ token: string }>();

    const { onSubmitAuthAction, onSubmitAuthActionCompleteMfa, currentAuthStep, mfaResendSuccessDialog } =
        useMultiFactorAuth(coordinator.refreshAfterLogin);

    if (!token) {
        return <Redirect to={Routes.ROOT} />;
    }

    return (
        <>
            <AuthGeneralContainerSite>
                <h1 style={{ marginBottom: 16 }}>{t("screen.auth.registration.title")}</h1>
                {currentAuthStep !== "multiFactorAuth" && (
                    <>
                        <p style={{ marginBottom: 32 }}>{t("screen.auth.registration.subtitle")}</p>

                        <Formik
                            initialValues={{
                                password: "",
                                repeatPassword: "",
                            }}
                            onSubmit={model => onSubmitAuthAction("registration", token, model)}
                            validationSchema={Yup.object().shape({
                                password: Yup.string()
                                    .matches(passwordRegex, t("screen.auth.forgot_password_reset.error.password_weak"))
                                    .required(t("screen.auth.forgot_password_reset.error.password_not_set")),
                                repeatPassword: Yup.string()
                                    .oneOf(
                                        [Yup.ref("password")],
                                        t("screen.auth.forgot_password_reset.error.passwords_do_not_match"),
                                    )
                                    .required(t("screen.auth.forgot_password_reset.error.passwords_do_not_match")),
                            })}
                        >
                            <Form style={{ width: "100%", textAlign: "left" }}>
                                <Field
                                    component={CustomInputField}
                                    label={t("screen.auth.registration.form.label.password")}
                                    name="password"
                                    type="password"
                                    required
                                    style={{ marginBottom: 16 }}
                                />

                                <Field
                                    component={CustomInputField}
                                    label={t("screen.auth.registration.form.label.repeat_password")}
                                    name="repeatPassword"
                                    type="password"
                                    required
                                    style={{ marginBottom: 8 }}
                                />

                                <div style={{ fontSize: 12, marginBottom: 24 }}>
                                    <p>{t("screen.auth.registration.form.section_1")}</p>
                                    <p style={{ marginTop: 8, marginBottom: 8 }}>
                                        {t("screen.auth.registration.form.section_2")}
                                    </p>
                                    <p>{t("screen.auth.registration.form.section_3")}</p>
                                </div>

                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    {t("screen.auth.registration.form.button")}
                                </Button>
                            </Form>
                        </Formik>
                    </>
                )}
                {currentAuthStep === "multiFactorAuth" && (
                    <>
                        <p style={{ marginBottom: 32 }}>{t("screen.auth.registration.mfa.subtitle")}</p>
                        <Formik
                            initialValues={{
                                code: "",
                            }}
                            onSubmit={model => onSubmitAuthActionCompleteMfa("registration", model, token)}
                            validationSchema={getMfaValidation()}
                        >
                            <Form style={{ width: "100%", textAlign: "left" }}>
                                <Field
                                    component={CustomInputField}
                                    label={t("screen.auth.registration.form.label.mfa")}
                                    name="code"
                                    required
                                    style={{ marginBottom: 16 }}
                                />
                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    {t("screen.auth.registration.form.button.mfa")}
                                </Button>
                                <Button
                                    style={{ marginTop: 24 }}
                                    onClick={() => onSubmitAuthAction("registration", token)}
                                    color="primary"
                                    data-id="resend_code"
                                >
                                    {t("screen.auth.registration.form.button.mfa.resendCode").toUpperCase()}
                                </Button>
                            </Form>
                        </Formik>
                    </>
                )}
                {mfaResendSuccessDialog.dialog}
            </AuthGeneralContainerSite>
        </>
    );
};
