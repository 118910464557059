import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { t } from "../../i18n/util";

export const ConfirmWebhook = (props: {
    disabled?: boolean;
    notifyCustomer?: boolean;
    buttonLabel?: string;
    setNotifyCustomer?: (notify: boolean) => void;
    onSubmit?: () => void;
}) => {
    return (
        <div
            style={{
                marginTop: 40,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
            }}
        >
            {props.setNotifyCustomer ? (
                <FormControlLabel
                    checked={props.notifyCustomer}
                    control={<Checkbox color="primary" />}
                    label={t("screen.advisor.uploads.upload.notifyCustomer")}
                    onChange={(_, checked) => props.setNotifyCustomer?.(checked)}
                />
            ) : (
                <div />
            )}
            <Button
                data-id="upload_submit"
                variant="contained"
                color="primary"
                type="submit"
                onClick={props.onSubmit}
                disabled={props.disabled}
            >
                {props.buttonLabel ?? t("screen.advisor.uploads.upload.buttonText")}
            </Button>
        </div>
    );
};
