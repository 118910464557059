import React, { Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import { LazyDocument, LazyPage } from "../../libs/react-pdf";
import { LoadingIndicator } from "../../ui/LoadingOverlay";
import { RendererProps, ViewerFileSrc } from "../types";
import { ErrorRenderer } from "./ErrorRenderer";
import { Centered } from "./Helper";

const StyledDocument = styled(LazyDocument)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    min-height: 100px; // enough to display the loading indicator
`;

const LoadingIndicatorRoot = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

interface PdfRendererProps extends RendererProps {}

export const PdfRenderer = ({ file, zoomLevel, align }: PdfRendererProps) => {
    const [src, setSrc] = useState<ViewerFileSrc | null>(typeof file.src === "function" ? null : file.src);

    const [error, setError] = useState<unknown>(null);

    const rawFileSrc = file.src;
    useEffect(() => {
        if (typeof rawFileSrc !== "function") {
            return; // already set in the use state above
        }
        const load = async () => {
            try {
                const src = await rawFileSrc();
                setSrc(src);
            } catch (error) {
                setError(error);
            }
        };
        load();
    }, [rawFileSrc]);

    const [pages, setPages] = useState(0);

    if (error) {
        return <ErrorRenderer error={error} />;
    }

    if (!src) {
        return null;
    }

    const renderedPages: React.ReactNode[] = [];
    for (let i = 0; i < pages; i++) {
        renderedPages.push(<LazyPage key={`page_${i + 1}`} pageNumber={i + 1} scale={zoomLevel} loading={<span />} />);
    }

    const loading = pages === 0;

    return (
        <Centered style={align === "top" ? { height: "unset" } : undefined}>
            <Suspense
                fallback={
                    <LoadingIndicatorRoot>
                        <LoadingIndicator />
                    </LoadingIndicatorRoot>
                }
            >
                <StyledDocument
                    file={src}
                    onLoadSuccess={document => {
                        setPages(document.numPages);
                    }}
                    onLoadError={setError}
                    // hide the default loading text
                    loading={<span />}
                >
                    {renderedPages}
                </StyledDocument>
            </Suspense>
            {loading ? (
                <LoadingIndicatorRoot>
                    <LoadingIndicator />
                </LoadingIndicatorRoot>
            ) : null}
        </Centered>
    );
};
