import range from "lodash/range";
import * as React from "react";
import { useResize } from "../hooks/useResize";

export const BORDER_WIDTH = 2;

const AbsoluteBorder = ({
    children,
    variant,
}: {
    children: React.ReactNode;
    variant: "left" | "right" | "top" | "bottom";
}) => (
    <div
        style={{
            display: "flex",
            justifyContent: "space-around",
            position: "absolute",
            flexDirection: variant === "top" || variant === "bottom" ? "row" : "column",
            [variant === "top" || variant === "bottom" ? "width" : "height"]: "100%",
            [variant === "top" || variant === "bottom" ? "left" : "top"]: 0,
            [variant]: 0,
        }}
    >
        {children}
    </div>
);

const Dash = ({
    vertical,
    width,
    height,
    color,
}: {
    vertical?: boolean;
    width: number;
    height: number;
    color: string;
}) => {
    return (
        <div
            style={{
                width: vertical ? height : width,
                height: vertical ? width : height,
                backgroundColor: color,
            }}
        />
    );
};

interface IProps {
    borderWidth?: number;
    dashLength?: number;
    children: React.ReactNode;
    padding?: number;
    paddingLeft?: number;
    paddingRight?: number;
    paddingTop?: number;
    paddingBottom?: number;
    color: string;
    style?: React.CSSProperties;
}

export const SymmetricBorderBox = ({
    borderWidth = BORDER_WIDTH,
    dashLength = 8,
    children,
    padding = 0,
    paddingLeft = 0,
    paddingRight = 0,
    paddingTop = 0,
    paddingBottom = 0,
    color,
    style,
}: IProps) => {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const dimensions = useResize(containerRef);

    const dashProps = {
        width: dashLength,
        height: borderWidth,
        color: color,
    };

    const horizontalCount = Math.round(dimensions.width / 18);
    const verticalCount = Math.round(dimensions.height / 18);

    const horizontalDashes = range(horizontalCount).map((_, index) => <Dash key={index} {...dashProps} />);
    const verticalDashes = range(verticalCount).map((_, index) => <Dash key={index} vertical {...dashProps} />);

    return (
        <div
            ref={containerRef}
            style={{
                position: "relative",
                padding: padding + borderWidth,
                paddingLeft: paddingLeft + borderWidth,
                paddingRight: paddingRight + borderWidth,
                paddingTop: paddingTop + borderWidth,
                paddingBottom: paddingBottom + borderWidth,
                ...style,
            }}
        >
            <AbsoluteBorder variant="top">{horizontalDashes}</AbsoluteBorder>
            <AbsoluteBorder variant="right">{verticalDashes}</AbsoluteBorder>
            <AbsoluteBorder variant="bottom">{horizontalDashes}</AbsoluteBorder>
            <AbsoluteBorder variant="left">{verticalDashes}</AbsoluteBorder>
            {children}
        </div>
    );
};
