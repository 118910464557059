import { Tab, Tabs } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { pushRoute } from "../app/router/history";
import { SettingsNavBar } from "./SettingsNavBar";
import { SettingsRoutes } from "./router/SettingsRoutes";

export const SettingsCompanyNavBar = observer(function SettingsCompanyNavBar() {
    const { pathname } = useLocation();
    const [page, setPage] = React.useState<string | null>("data");

    React.useEffect(() => {
        if (pathname === SettingsRoutes.COMPANY.DATA) {
            setPage("data");
        } else if (pathname === SettingsRoutes.COMPANY.ACCOUNTING) {
            setPage("accounting");
        } else if (pathname === SettingsRoutes.COMPANY.HR) {
            setPage("hr");
        } else if (pathname === SettingsRoutes.COMPANY.KPIS) {
            setPage("kpis");
        } else if (pathname === SettingsRoutes.COMPANY.TERMS) {
            setPage("terms");
        } else if (pathname === SettingsRoutes.COMPANY.UPCOMING_TERMS) {
            setPage("upcoming-terms");
        } else if (pathname === SettingsRoutes.COMPANY.OPTIONAL_SIDEBAR_ITEMS) {
            setPage("menu-items");
        } else if (pathname === SettingsRoutes.COMPANY.FEATURES) {
            setPage("features");
        }
    }, [pathname]);

    const handlePageChange = (_: React.ChangeEvent<unknown>, page: string | null) => {
        setPage(page);
    };

    const companyStore = companiesStore.selectedCompanyStore;

    const showAccountingTab =
        companyStore?.company.accountingEnabled && (companyStore.hasModule("accounting") || authStore.isAdvisor);

    const showHrTab = companyStore?.company.hrEnabled && (companyStore.hasModule("hr") || authStore.isAdvisor);

    return (
        <SettingsNavBar
            tabs={
                !authStore.canReadCompanySettings ? undefined : (
                    <Tabs
                        value={page}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handlePageChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab
                            label={t("settings.navbar.companyData")}
                            value="data"
                            onClick={() => {
                                pushRoute(SettingsRoutes.COMPANY.DATA);
                            }}
                            data-id="settings_tab_companyData"
                        />
                        {showAccountingTab && (
                            <Tab
                                label={t("common.accounting")}
                                value="accounting"
                                onClick={() => {
                                    pushRoute(SettingsRoutes.COMPANY.ACCOUNTING);
                                }}
                                data-id="settings_tab_accounting"
                            />
                        )}
                        {showHrTab && (
                            <Tab
                                label={t("common.hr.short")}
                                value="hr"
                                onClick={() => {
                                    pushRoute(SettingsRoutes.COMPANY.HR);
                                }}
                                data-id="settings_tab_hr"
                            />
                        )}
                        {!!companyStore?.kpiStore.canEdit && (
                            <Tab
                                label={t("common.kpi")}
                                value="kpis"
                                onClick={() => {
                                    pushRoute(SettingsRoutes.COMPANY.KPIS);
                                }}
                                data-id="settings_tab_KPIs"
                            />
                        )}
                        {authStore.isAdvisor && !!companiesStore.selectedCompany?.companyTerms && (
                            <Tab
                                label={t("common.termsOfUse")}
                                value="terms"
                                onClick={() => {
                                    pushRoute(SettingsRoutes.COMPANY.TERMS);
                                }}
                                data-id="settings_tab_terms"
                            />
                        )}
                        {authStore.isAdvisor && !!companiesStore.selectedCompany?.upcomingCompanyTerms && (
                            <Tab
                                label={t("common.upcomingTermsOfUse")}
                                value="upcoming-terms"
                                onClick={() => {
                                    pushRoute(SettingsRoutes.COMPANY.UPCOMING_TERMS);
                                }}
                                data-id="settings_tab_upcoming_terms"
                            />
                        )}
                        {authStore.isAdvisor && (
                            <Tab
                                label={t("settings.navbar.optionalSideBarItems")}
                                value="menu-items"
                                onClick={() => {
                                    pushRoute(SettingsRoutes.COMPANY.OPTIONAL_SIDEBAR_ITEMS);
                                }}
                                data-id="settings_tab_optional_sidebar_items"
                            />
                        )}
                        {companyStore?.canReadFeatures && (
                            <Tab
                                label={t("settings.navbar.features")}
                                value="features"
                                onClick={() => {
                                    pushRoute(SettingsRoutes.COMPANY.FEATURES);
                                }}
                                data-id="settings_tab_features"
                            />
                        )}
                    </Tabs>
                )
            }
        />
    );
});
