import * as React from "react";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { coordinator } from "../../stores/Coordinator";
import { generalStore } from "../../stores/GeneralStore";
import { Module } from "../../types/models";

export const useInitModule = (module: Module) => {
    const companyId = companiesStore.selectedCompanyId;
    const companyLoaded = !!companiesStore.selectedCompany;
    const canInitialize = authStore.isAuthenticated && !!authStore.userInfo;

    // debug.log("### useInitModule", module, canInitialize, companyId);

    React.useEffect(() => {
        const load = async () => {
            if (canInitialize && companyId && companyLoaded) {
                try {
                    await coordinator.initModule(module);
                } catch (err) {
                    generalStore.setError(t("error.general"), err);
                }
            }
        };
        load();
    }, [canInitialize, companyId, companyLoaded, module]);
};
