import moment from "moment";
import styled from "styled-components";
import { IMessageIDS, t } from "../../i18n/util";
import { FinancialAccountancy } from "../../network/APITypes";
import { isNever } from "../../util/ts";
import { TableDropdownFilterButton } from "../ui/filter/TableFilter";
import { AccountingViews, OrderBy, ResultsSettings as ResultsSettingsType, Variant } from "./types";
import { supportsOrderBy } from "./utils";

const defaultSettings: ResultsSettingsType = {
    financialAccountancyId: undefined,
    hideAccounts: false,
    useFullPreviousYears: true,
    variant: "currentYear",
    orderBy: "accountNr-asc",
};

const SettingsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    gap: 8px;
`;

interface FinancialAccountanciesPickerProps {
    financialAccountancies: FinancialAccountancy[] | undefined;
    id: ResultsSettingsType["financialAccountancyId"];
    onChange: (value: FinancialAccountancy["id"]) => void;
}

const FinancialAccountanciesPicker = ({ financialAccountancies, id, onChange }: FinancialAccountanciesPickerProps) => {
    if (!financialAccountancies?.length) {
        return null;
    }

    const years = Array.from(new Set(financialAccountancies.map(v => v.validYear))).sort((a, b) => b - a);

    const selected = id !== undefined ? financialAccountancies.find(v => v.id === id) : undefined;
    const selectedYear = selected?.validYear ?? years[0];
    const financialAccountanciesInYear = financialAccountancies.filter(v => v.validYear === selectedYear);

    const handleYearChange = (yearString: string) => {
        const selectedYear = parseInt(yearString, 10);
        const financialAccountanciesInYear = financialAccountancies.find(v => v.validYear === selectedYear);
        if (financialAccountanciesInYear) {
            onChange(financialAccountanciesInYear.id);
        }
    };
    const handleValueChange = (id: string) => {
        onChange(id);
    };

    return (
        <>
            <TableDropdownFilterButton
                onClick={noop}
                onChange={handleYearChange}
                options={years.map(year => {
                    const yearString = year.toString();
                    return { label: yearString, value: yearString };
                })}
                isSelected
                toggle={false}
                data-id="financial-accountancies-picker-year"
            >
                {selectedYear.toString()}
            </TableDropdownFilterButton>
            <TableDropdownFilterButton
                onClick={noop}
                onChange={handleValueChange}
                options={financialAccountanciesInYear.map(v => {
                    return { label: v.name || "&nbsp;", value: v.id };
                })}
                isSelected
                toggle={false}
                data-id="financial-accountancies-picker"
            >
                {selected?.name ? selected.name : "&nbsp;"}
            </TableDropdownFilterButton>
        </>
    );
};

interface ResultsSettingsProps {
    view: AccountingViews;
    financialAccountancies: FinancialAccountancy[] | undefined;
    settings: ResultsSettingsType;
    onChange: (settings: ResultsSettingsType) => void;
}

export const variantTextKeys: Record<Variant, IMessageIDS> = {
    currentYear: "results.settings.variant.currentYear",
    currentAndPastYear: "results.settings.variant.currentAndPastYear",
    currentAndPastYearAbsoluteChange: "results.settings.variant.currentAndPastYearAbsoluteChange",
    currentAndPastYearPercentageChange: "results.settings.variant.currentAndPastYearPercentageChange",
};
const variants = Object.keys(variantTextKeys) as Variant[];

type AccountsVisibility = "showAccounts" | "hideAccounts";
const accountsVisibilityTextKeys: Record<AccountsVisibility, IMessageIDS> = {
    showAccounts: "results.settings.accountsVisibility.showAccounts",
    hideAccounts: "results.settings.accountsVisibility.hideAccounts",
};
const accountsVisibilities = Object.keys(accountsVisibilityTextKeys) as AccountsVisibility[];

type PreviousYearRange = "fullYear" | "partialYear";
const previousYearRangesTextKeys: Record<PreviousYearRange, IMessageIDS> = {
    fullYear: "results.settings.previousYearRanges.fullYear",
    partialYear: "results.settings.previousYearRanges.partialYear",
};
const previousYearRanges = Object.keys(previousYearRangesTextKeys) as PreviousYearRange[];

const orderByTextKeys: Record<OrderBy, IMessageIDS> = {
    none: "results.settings.orderBy.amountAsc", // doesn't matter, not displayed anyway
    "amount-asc": "results.settings.orderBy.amountAsc",
    "amount-desc": "results.settings.orderBy.amountDesc",
    "accountNr-asc": "results.settings.orderBy.accountNrAsc",
    "accountNr-desc": "results.settings.orderBy.accountNrDesc",
    "accountName-asc": "results.settings.orderBy.accountNameAsc",
    "accountName-desc": "results.settings.orderBy.accountNameDesc",
};
const orderByValues = (Object.keys(orderByTextKeys) as OrderBy[]).filter(orderBy => orderBy !== "none");

export const ResultsSettings = ({ view, financialAccountancies, settings, onChange }: ResultsSettingsProps) => {
    const handleFinancialAccountanciesChange = (financialAccountancyId: FinancialAccountancy["id"]) => {
        onChange({ ...settings, financialAccountancyId });
    };
    const handleVariantChange = (variantString: string): void => {
        onChange({ ...settings, variant: variantString as Variant });
    };
    const handleAccountsVisibilityChange = (accountsVisibilityString: string): void => {
        const accountsVisibility = accountsVisibilityString as AccountsVisibility;
        onChange({ ...settings, hideAccounts: accountsVisibility === "hideAccounts" });
    };
    const handleOrderByChange = (orderByString: string): void => {
        const orderBy = orderByString as OrderBy;
        onChange({ ...settings, orderBy });
    };

    return (
        <SettingsRow>
            <FinancialAccountanciesPicker
                financialAccountancies={financialAccountancies}
                id={settings.financialAccountancyId}
                onChange={handleFinancialAccountanciesChange}
            />
            <TableDropdownFilterButton
                onClick={noop}
                onChange={handleVariantChange}
                options={variants.map(variant => {
                    return { label: t(variantTextKeys[variant]), value: variant };
                })}
                isSelected
                toggle={false}
                data-id="variant-picker"
            >
                {t(variantTextKeys[settings.variant])}
            </TableDropdownFilterButton>
            <TableDropdownFilterButton
                onClick={noop}
                onChange={handleAccountsVisibilityChange}
                options={accountsVisibilities.map(accountsVisibility => {
                    return { label: t(accountsVisibilityTextKeys[accountsVisibility]), value: accountsVisibility };
                })}
                isSelected
                toggle={false}
                data-id="accounts-visibility-picker"
            >
                {t(
                    settings.hideAccounts
                        ? accountsVisibilityTextKeys.hideAccounts
                        : accountsVisibilityTextKeys.showAccounts,
                )}
            </TableDropdownFilterButton>
            <EntirePreviousYearsPicker
                settings={settings}
                financialAccountancies={financialAccountancies}
                onChange={onChange}
            />
            {supportsOrderBy[view] ? (
                <TableDropdownFilterButton
                    onClick={noop}
                    onChange={handleOrderByChange}
                    options={orderByValues.map(orderBy => {
                        return { label: t(orderByTextKeys[orderBy]), value: orderBy };
                    })}
                    isSelected
                    toggle={false}
                    data-id="order-by-picker"
                >
                    {t("results.settings.orderBy.title", { orderBy: t(orderByTextKeys[settings.orderBy]) })}
                </TableDropdownFilterButton>
            ) : null}
        </SettingsRow>
    );
};

const EntirePreviousYearsPicker = ({
    settings,
    financialAccountancies,
    onChange,
}: Omit<ResultsSettingsProps, "view">) => {
    // the picker for the "useFullPreviousYears" setting is only visible if we are showing previous years
    if (settings.variant === "currentYear" || !financialAccountancies) {
        return null;
    }

    const financialAccountancy = financialAccountancies.find(fa => fa.id === settings.financialAccountancyId);
    if (!financialAccountancy) {
        return null;
    }

    // and the current financial accountancy does not contain data till it's end.
    const resultsAvailableUntil = new Date(financialAccountancy.resultsAvailableUntil);
    const endsAt = new Date(financialAccountancy.endsAt);
    if (resultsAvailableUntil.getTime() === endsAt.getTime()) {
        return null;
    }

    const handlePreviousYearRangeChange = (previousYearRangeString: string): void => {
        const previousYearRange = previousYearRangeString as PreviousYearRange;
        onChange({ ...settings, useFullPreviousYears: previousYearRange === "fullYear" });
    };

    const previousYearRangeUntil = moment(resultsAvailableUntil).format("DD. MMMM");
    return (
        <TableDropdownFilterButton
            onClick={noop}
            onChange={handlePreviousYearRangeChange}
            options={previousYearRanges.map(previousYearRange => {
                return {
                    label: t(previousYearRangesTextKeys[previousYearRange], { previousYearRangeUntil }),
                    value: previousYearRange,
                };
            })}
            isSelected
            toggle={false}
            data-id="previous-year-range-picker"
        >
            {t(
                settings.useFullPreviousYears
                    ? previousYearRangesTextKeys.fullYear
                    : previousYearRangesTextKeys.partialYear,
                { previousYearRangeUntil },
            )}
        </TableDropdownFilterButton>
    );
};

function noop() {
    //
}

export function toQuery(settings: ResultsSettingsType): string {
    const query = new URLSearchParams();

    const keys = Object.keys(settings) as (keyof ResultsSettingsType)[];
    keys.forEach(k => {
        const v = settings[k];
        if (v != null) {
            query.set(k, v.toString());
        }
    });

    query.sort();
    return query.toString();
}

export function fromQuery(rawQuery: string): ResultsSettingsType {
    const settings: ResultsSettingsType = { ...defaultSettings };
    const query = new URLSearchParams(rawQuery);

    const keys = Object.keys(settings) as (keyof ResultsSettingsType)[];
    keys.forEach(k => {
        const v = query.get(k);
        if (!v) {
            return;
        }
        switch (k) {
            case "financialAccountancyId":
                settings[k] = v;
                break;
            case "hideAccounts":
            case "useFullPreviousYears":
                settings[k] = v === "true" ? true : false;
                break;
            case "variant":
                settings[k] = v as Variant;
                break;
            case "orderBy":
                settings[k] = v as OrderBy;
                break;
            default:
                isNever(k);
                break;
        }
    });

    return settings;
}
