import * as React from "react";
import { t } from "../../i18n/util";
import { IEmployee } from "../../types/models";
import { NavBarBack } from "../ui/NavBarBack";
import { PageWithStepper } from "../ui/PageWithStepper";
import { CancelRegistrationDialog } from "./HrDialogs";
import { HrEmployeesRegistrationStepper } from "./HrEmployeesRegistrationStepper";
import { useDisclaimer } from "./hooks/useDisclaimer";
import { IRegistrationQuery } from "./types";

export const HrRegistrationSite = (props: {
    employee: IEmployee;
    children: React.ReactNode;
    fullWidth?: boolean;
    rightBarContent?: React.ReactNode;
    cancelDialog?: boolean;
    backTarget?: string;
    backLabel?: string;
    cancelTarget?: string;
    showCancel?: boolean;
    query: IRegistrationQuery;
}) => {
    const [openCancelRegistration, setOpenCancelRegistration] = React.useState(false);

    const disclaimer = useDisclaimer(props.employee);

    const hasName = props.employee.firstName && props.employee.lastName;

    const handleCancelClickOpen = () => {
        setOpenCancelRegistration(true);
    };

    const handleCancelRegistrationClose = () => {
        setOpenCancelRegistration(false);
    };

    const isEdit = !!props.query.isEdit;

    return (
        <>
            <NavBarBack
                title={
                    hasName
                        ? t(isEdit ? "screen.hr.edit.flow.navheader" : "screen.hr.registration.flow.navheader", {
                              firstName: props.employee.firstName,
                              lastName: props.employee.lastName,
                          })
                        : t("screen.hr.personaldata.navheader")
                }
                backLabel={props.backLabel ? props.backLabel : t("screen.hr.registration.flow.navback")}
                backTarget={props.backTarget}
                cancelTarget={props.cancelTarget}
                showCancel={props.showCancel}
                onCancel={props.cancelDialog ? handleCancelClickOpen : undefined}
            />
            <PageWithStepper
                stepper={
                    <div>
                        {props.query.isDocumentUpload ? null : (
                            <HrEmployeesRegistrationStepper employeeId={props.employee.id} query={props.query} />
                        )}
                        <div style={{ marginTop: 32, paddingLeft: 16 }}>{props.rightBarContent}</div>
                    </div>
                }
            >
                {props.children}
            </PageWithStepper>
            {disclaimer.component}
            <CancelRegistrationDialog
                open={openCancelRegistration}
                onClose={handleCancelRegistrationClose}
                onTransferEmployee={() => {
                    handleCancelRegistrationClose();
                    disclaimer.open();
                }}
                employee={props.employee}
                isPreRegistration={props.query.isPreRegistration}
                isReRegistration={props.query.isReRegistration}
            />
        </>
    );
};
