import { useEffect } from "react";
import { FinancialAccountancy } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";

export function useFinancialAccountancies(): FinancialAccountancy[] | undefined {
    const companyStore = companiesStore.selectedCompanyStore;
    useEffect(() => {
        if (!companyStore?.hasAccountingResults()) {
            if (companyStore?.resultsStore) {
                companyStore.resultsStore.financialAccountancies = [];
            }
            return;
        }
        companyStore?.resultsStore.loadFinancialAccountancies();
    }, [companyStore]);

    return companyStore?.resultsStore.financialAccountancies;
}
