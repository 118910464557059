import { Routes } from "../../app/router/Routes";

const prefix = Routes.prefixed(Routes.PROJECTS);

export const ProjectsRoutes = {
    ROOT: prefix("/"),
    NEW_PROJECT: prefix("/new"),
    NEW_PROJECT_USERS: prefix("/new/users"),
    NEW_PROJECT_COMPANIES: prefix("/new/companies"),
    NEW_PROJECT_FINISH: prefix("/new/finish"),
    EDIT_PROJECT: prefix("/edit/:projectId"),
    EDIT_PROJECT_USERS: prefix("/edit/:projectId/users"),
    BULK_ADD_USERS: prefix("/bulk/add/users"),
    UPLOAD_FILES_TO_PROJECT: prefix("/upload/:projectId"),
    UPLOAD_FILES_TO_PROJECT_FOLDER: prefix("/upload/:projectId/:projectFolderId"),
    RELEASES: prefix("/releases/:projectId"),
    RELEASES_TICKET: prefix("/releases/:projectId/tickets/:projectItemId/:projectItemDraftId"),
    RELEASES_PROJECT_ITEM: prefix("/releases/:projectId/:projectItemId"),
    RELEASES_PROJECT_ITEM_TICKET: prefix("/releases/:projectId/:projectItemId/:projectItemDraftId/ticket"),
    ACTIVE: {
        ROOT: prefix("/active"),
        PROJECT: prefix("/active/:projectId"),
        PROJECT_FOLDER: prefix("/active/:projectId/:projectFolderId"),
    },
    TO_CONFIGURE: {
        ROOT: prefix("/configure"),
        PROJECT: prefix("/configure/:externalId"),
        PROJECT_USERS: prefix("/configure/:externalId/users"),
    },
    FINISHED: prefix("/finished"),
    DELETED: prefix("/deleted"),
};
