import React from "react";
import { POLLING_ACTIVE_CHAT_MESSAGES_MS } from "../../config";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Message } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { useInterval } from "./useInterval";

export const useTicketMessages = ({
    companyId,
    ticketId,
    limit = 50,
    pollingIntervalMs = POLLING_ACTIVE_CHAT_MESSAGES_MS,
    dummyMessage,
}: {
    companyId?: string;
    ticketId?: string;
    limit?: number;
    pollingIntervalMs?: number;
    dummyMessage?: Message;
}) => {
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [total, setTotal] = React.useState(0);
    const [count, setCount] = React.useState(0);

    const load = React.useCallback(async () => {
        if (!companyId || !ticketId) {
            return;
        }
        try {
            generalStore.isLoading = true;
            const response = await API.getTicketMessages(companyId, ticketId, { limit });

            if (response.messages) {
                setMessages(response.messages);
                setTotal(response.total);
                setCount(response.messages.length);
            }
        } catch (error) {
            generalStore.setError(t("error.loadMessages"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, limit, ticketId]);

    const loadOlder = React.useCallback(async () => {
        if (!companyId || !ticketId) {
            return;
        }
        try {
            generalStore.isLoading = true;
            if (messages && count < total) {
                const response = await API.getTicketMessages(companyId, ticketId, { limit, offset: count });
                if (response.messages) {
                    const newMessages = messages.concat(response.messages);
                    setMessages(newMessages);
                    setCount(newMessages.length);
                }
            }
        } catch (error) {
            generalStore.setError(t("error.loadMessages"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, count, limit, messages, ticketId, total]);

    // Used for polling
    const loadUnread = React.useCallback(async () => {
        if (!companyId || !ticketId) {
            return;
        }

        if (!messages || messages.length === 0) {
            // We haven't loaded any messages yet -> do a simple load
            await load();
            return;
        }

        try {
            generalStore.isLoading = true;
            const response = await API.getTicketMessages(companyId, ticketId, { limit });
            if (response.messages) {
                const startIndex = response.messages.findIndex(message => message.id === messages[0].id);
                // startIndex === 0 means -> no new messages
                if (startIndex > 0) {
                    // Prepend new messages to current ones
                    const newMessages = response.messages.slice(0, startIndex).concat(messages);
                    setMessages(newMessages);
                    setCount(newMessages.length);
                    setTotal(response.total);
                }
            }
        } catch (error) {
            generalStore.setError(t("error.loadMessages"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, limit, load, messages, ticketId]);

    React.useEffect(() => {
        load();
    }, [load]);

    useInterval(loadUnread, pollingIntervalMs);

    return {
        messages: dummyMessage && messages.length === 0 ? [dummyMessage] : messages,
        reload: load,
        loadOlder,
    };
};
