import * as React from "react";
import { generalStore } from "../../stores/GeneralStore";

interface IProps {
    src: string;
    fallbackSrc?: string;
    fallbackComponent?: React.ReactNode;
    style?: React.CSSProperties;
    alt?: string;
    errorMessage?: string;
}

export const FallbackImage = ({ src, fallbackSrc, fallbackComponent, style, alt, errorMessage }: IProps) => {
    const [useFallback, setUseFallback] = React.useState<boolean>(false);

    if (!useFallback) {
        return (
            <img
                alt={alt}
                style={style}
                src={src}
                onError={() => {
                    if (errorMessage) {
                        generalStore.setError(errorMessage);
                    }
                    setUseFallback(true);
                }}
            />
        );
    } else if (fallbackComponent) {
        return <>{fallbackComponent}</>;
    } else if (fallbackSrc) {
        return <img alt={alt} style={style} src={fallbackSrc} />;
    } else {
        return null;
    }
};
