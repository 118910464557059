import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GeneralDocumentType, GeneralDocumentWebhookDocuments } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { ApprovalOption } from "../../types/models";
import { ISignatureUserSignature, createAnonymousSignatureUsers } from "../../util/qes";
import { Routes } from "../app/router/Routes";
import { pushRoute } from "../app/router/history";
import { usePdfSignaturePositionExternalDocumentDialog } from "../hooks/usePdfSignaturePositionExternalDocumentDialog";
import { DocumentLine } from "../ui/DocumentLine/DocumentLine";
import { UploadSmallPaper } from "../ui/DocumentUploadSmall";
import { Comment } from "./Comment";
import { ConfirmWebhook } from "./ConfirmWebhook";
import { IUploadDocument } from "./types";

const GENERAL_DOCUMENTS_REQUIRED_RELEASERS = 1;

export const UploadGeneralDocumentList = (props: {
    companyId?: string;
    category: GeneralDocumentType;
    documents: IUploadDocument[];
    namesOnly?: boolean;
    setDocuments: (documents: IUploadDocument[]) => void;
    onSubmit?: () => void;
}) => {
    const { documents, setDocuments } = props;
    const [notify, setNotify] = React.useState(true);
    const [comment, setComment] = React.useState("");

    const handleSelectSignaturePositions = (
        archiveId: string,
        documentId: string,
        signaturePositions: ISignatureUserSignature[],
    ) => {
        const found = documents.find(
            document => document.archiveId === archiveId && document.documentId === documentId,
        );
        if (!found) {
            return;
        }

        found.signaturePositions = signaturePositions.map(s => s.signaturePosition);
    };
    const pdfSignatureDialog = usePdfSignaturePositionExternalDocumentDialog(handleSelectSignaturePositions);

    const openPdfSignatureDialog = (document: IUploadDocument) => {
        if (document.requiresQes && document.archiveId && document.documentId) {
            const requiredReleasers = GENERAL_DOCUMENTS_REQUIRED_RELEASERS;
            const countSignatures = document.signaturePositions?.length ?? 0;
            const count = Math.max(requiredReleasers, countSignatures);
            const users = createAnonymousSignatureUsers(count);
            pdfSignatureDialog.open(
                document.archiveId,
                document.documentId,
                users,
                document.signaturePositions?.map((s, i) => ({ user: users[i], signaturePosition: s })),
            );
        }
    };

    const onSubmit = async () => {
        if (!props.companyId) {
            return;
        }

        // check that all reports have the required QES signature positions
        const hasMissingSignaturePositions = documents.some(doc => {
            return (
                doc.requiresQes &&
                (!doc.signaturePositions || doc.signaturePositions.length < GENERAL_DOCUMENTS_REQUIRED_RELEASERS)
            );
        });
        if (hasMissingSignaturePositions) {
            generalStore.setError(t("error.qes.missingSignaturePositions"));
            return;
        }

        generalStore.isLoading = true;

        try {
            await API.postDocumentsWebhook({
                companyId: props.companyId,
                body: {
                    documents: props.documents.map<GeneralDocumentWebhookDocuments>(document => ({
                        archiveID: document.archiveId ?? "",
                        documentID: document.documentId ?? "", // this is safe, documentId is always set for NTCS docs
                        name: document.name,
                        type: props.category,
                        needsRelease: document.needsRelease,
                        requiresQes: document.requiresQes,
                        qesPositions: document.signaturePositions,
                    })),
                    sendEmail: notify,
                    comment,
                },
            });

            props.onSubmit?.();
        } catch (err) {
            generalStore.setError(t("error.upload"), err);
        }

        generalStore.isLoading = false;
    };

    return (
        <>
            <UploadSmallPaper>
                {documents.map((document, index) => {
                    let approvalOption: ApprovalOption = "none";
                    if (document.needsRelease) {
                        if (document.requiresQes) {
                            approvalOption = "qes";
                        } else {
                            approvalOption = "click";
                        }
                    }
                    return (
                        <DocumentLine
                            fileName={document.name}
                            defaultApprovalOption={approvalOption}
                            showReleaseOptions
                            onChangeReleaseOption={
                                props.namesOnly
                                    ? undefined
                                    : option => {
                                          document.needsRelease = option === "click" || option === "qes";
                                          document.requiresQes = option === "qes";
                                          openPdfSignatureDialog(document);
                                      }
                            }
                            onClickReleaseOption={() => {
                                openPdfSignatureDialog(document);
                            }}
                            onDelete={() => {
                                if (documents.length === 1) {
                                    // Last doc deleted -> go to cockpit
                                    pushRoute(Routes.COCKPIT);
                                } else {
                                    const newDocs = [...documents];
                                    newDocs.splice(index, 1);
                                    setDocuments(newDocs);
                                }
                            }}
                            key={document.documentId ?? index}
                        />
                    );
                })}
                {pdfSignatureDialog.component}
            </UploadSmallPaper>
            <Comment value={comment} onChange={setComment} />
            <ConfirmWebhook notifyCustomer={notify} setNotifyCustomer={setNotify} onSubmit={onSubmit} />
        </>
    );
};
