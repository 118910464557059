import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import * as React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { Redirect } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { getApiError } from "../../../network/NetworkStapler";
import { generalStore } from "../../../stores/GeneralStore";
import { debug } from "../../../util/debug";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useQueryParams } from "../../hooks/useQueryParams";
import { FieldError } from "../../ui/CustomInputField";
import { AuthGeneralContainerSite } from "./AuthGeneralContainerSite";

export const AuthConfirmUsernameChangeSite = () => {
    const { token, newUsername } = useQueryParams<{ token: string; newUsername: string }>();
    const [isNewUsernameConfirmed, setIsNewUsernameConfirmed] = React.useState(false);
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        if (isNewUsernameConfirmed) {
            setError("");
        }
    }, [isNewUsernameConfirmed]);

    debug.log("### username change", token, newUsername);

    if (!token || !newUsername) {
        return <Redirect to={Routes.ROOT} />;
    }

    const handleConfirmAndBack = async () => {
        if (isNewUsernameConfirmed) {
            try {
                generalStore.isLoading = true;
                await API.postChangeUsernameConfirm(token, newUsername);
                pushRoute(Routes.ROOT);
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.response.type === "TOKEN_NOT_FOUND") {
                    generalStore.setError(t("error.changeUsername.tokenNotFound"), error);
                } else if (apiError?.response.type === "TOKEN_EXPIRED") {
                    generalStore.setError(t("error.changeUsername.tokenExpired"), error);
                } else if (apiError?.response.type === "NO_NEW_USERNAME") {
                    generalStore.setError(t("error.changeUsername.noNewUsername"), error);
                } else if (apiError?.response.type === "NEW_USERNAME_CONFLICT") {
                    generalStore.setError(t("error.changeUsername.newUsernameConflict"), error);
                } else if (apiError?.response.type === "NEW_USERNAME_DUPLICATED") {
                    generalStore.setError(t("error.changeUsername.newUsernameDuplicate"), error);
                } else {
                    generalStore.setError(t("error.general"), error);
                }
            } finally {
                generalStore.isLoading = false;
            }
        } else {
            setError(t("newUsername.checkbox.error"));
        }
    };

    const handleClickConfirmNewUsernameCheckbox = () => {
        setIsNewUsernameConfirmed(!isNewUsernameConfirmed);
    };

    return (
        <AuthGeneralContainerSite>
            <h1 style={{ marginBottom: 24 }}>{t("screen.changeUsername.title")}</h1>
            <p style={{ marginBottom: 32 }}>
                <FormattedHTMLMessage id="screen.changeUsername.message" values={{ newUsername: newUsername }} />
            </p>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        onChange={handleClickConfirmNewUsernameCheckbox}
                        checked={isNewUsernameConfirmed}
                    />
                }
                label={t("screen.changeUsername.checkbox")}
            />
            <FieldError style={{ marginBottom: 16 }}>{error}</FieldError>
            <Button variant="contained" color="primary" onClick={handleConfirmAndBack}>
                {t("button.back_to_login")}
            </Button>
        </AuthGeneralContainerSite>
    );
};
