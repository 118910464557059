const german = {
  "accounting.bankAccount.account.label": "Konto",
  "accounting.bankAccount.account.placeholder": "Konto",
  "accounting.bankAccount.account.title": "Konto auswählen",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "Belegkategorien konnten nicht geladen werden",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "Belegkategorie",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "Bitte ein Start- und Enddatum auswählen.",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "Banktransaktionen",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "Belegssymbol",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "Belege",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "Keine Belege gefunden",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "Nächster Beleg",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "Vorheriger Beleg",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "Bitte ordnen Sie den Beleg einer Banktransaktion zu.",
  "accounting.bankAccount.transactions.assignInvoices.title": "Zuordnung Belege",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "Offene Belege zuordnen",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "Export für Bankauszugsverbuchung",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "Export für Buchungsimport in NTCS",
  "accounting.bankAccount.transactions.table.emptyState.title": "Keine Transaktionen gefunden",
  "accounting.bankAccount.transactions.table.label.amount": "Betrag",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "Beleg zuordnen",
  "accounting.bankAccount.transactions.table.label.date": "Buchungsdatum",
  "accounting.bankAccount.transactions.table.label.invoice": "Beleg",
  "accounting.bankAccount.transactions.table.label.isPrivate": "Privat",
  "accounting.bankAccount.transactions.table.label.recipient": "Empfänger",
  "accounting.bankAccount.transactions.table.label.text": "Buchungstext",
  "accounting.bankAccount.transactions.table.label.valueDate": "Valutadatum",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "Belegverknüpfung entfernen",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "Als private Transaktion markieren",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "Private Markierung aufheben",
  "accounting.bankAccount.transactions.table.search.count": "Transaktionen: <b>{count}</b>",
  "accounting.bankAccount.transactions.table.search.placeholder": "nach Transaktionen suchen",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "Beleg wird hochgeladen",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "Buchungskreis und Belegssymbol auswählen",
  "accounting.bankAccount.transactions.title": "Transaktionen",
  "accounting.bankConnection.delete.message": "Wollen Sie die Bankverbindung {name} wirklich löschen?",
  "accounting.bankConnection.delete.title": "Bankverbindung löschen",
  "accounting.bankConnections.accountHolder": "InhaberIn:",
  "accounting.bankConnections.accountType": "Kontotyp:",
  "accounting.bankConnections.accountType.Bausparen": "Bausparen",
  "accounting.bankConnections.accountType.Checking": "Girokonto",
  "accounting.bankConnections.accountType.CreditCard": "Kreditkarte",
  "accounting.bankConnections.accountType.Loan": "Darlehenskonto",
  "accounting.bankConnections.accountType.Membership": "Genossenschaftsanteile",
  "accounting.bankConnections.accountType.Savings": "Sparkonto",
  "accounting.bankConnections.accountType.Security": "Wertpapierkonto",
  "accounting.bankConnections.addBankConnection": "Bankverbindung hinzufügen",
  "accounting.bankConnections.balance": "Kontostand:",
  "accounting.bankConnections.emptyState.description": "Fügen Sie eine neue Bankverbindung hinzu.",
  "accounting.bankConnections.emptyState.title": "Keine Bankverbindungen",
  "accounting.bankConnections.expired": "Abgelaufen",
  "accounting.bankConnections.expiredAt": "Ist am {date} abgelaufen",
  "accounting.bankConnections.expiresAt": "Läuft am {date} ab",
  "accounting.bankConnections.iban": "IBAN:",
  "accounting.bankConnections.lastSuccessfulUpdate": "per {date}",
  "accounting.bankConnections.pending": "Ausstehend",
  "accounting.bankConnections.refreshBankConnection": "Bankverbindung erneuern",
  "accounting.bankConnections.refreshPending": "Laufende Erneuerungen",
  "accounting.bankConnections.refreshPending.info": "Die Erneuerung der Bankverbindung läuft in einem seperaten Fenster. Sollte das Fenster vor dem Abschluss der Aktualisierung geschlossen worden sein, müssen Sie erneut auf \"Bankverbindung erneuern\" klicken",
  "accounting.bankConnections.refreshPending.label": "Erneuerungsvorgang läuft",
  "accounting.bankConnections.upToDate": "Bestätigt",
  "accounting.details.edit.header": "Beleg bearbeiten",
  "accounting.details.updatedAt": "zuletzt geändert am",
  "accounting.details.upload.files": "Dateien",
  "accounting.details.upload.skip": "Alle Überspringen",
  "accounting.finished.reports": "Reports",
  "accounting.table.label.totalGrossAmount": "Rechnungsbetrag",
  "addFolderDialog.button.text.create": "Erstellen",
  "addFolderDialog.folderName": "Ordnerbezeichnung",
  "addFolderDialog.info": "Gehen Sie zu Sharepoint, um Mitglieder zu verwalten.",
  "addFolderDialog.title": "Ordner erstellen",
  "advisor.uploads.uploadReports.title": "Übermittlung von Reports",
  "analysis.powerBI.reports.table.label.description": "Beschreibung",
  "analysis.powerBI.reports.table.label.name": "Name",
  "analysis.powerBI.reports.table.search.count": "Berichte: <b>{count}</b>",
  "analysis.powerBI.reports.table.search.placeholder": "nach Berichten suchen",
  "analysis.powerBI.reports.title": "Berichte",
  "analysis.powerBI.report.title": "Bericht: {name}",
  "appNotificationDialog.button.accept": "Verstanden",
  "appNotificationDialog.title.maintenance": "Wartungsarbeiten",
  "appNotificationDialog.title.newFeature": "Neues Feature",
  "appNotificationDialog.title.update": "Aktualisierung / Update",
  "branchType.allocationGroup": "Abrechnungskreis",
  "branchType.costCenter": "Kostenstamm",
  "branchType.department": "Abteilung",
  "branchType.financialAccountancyCircle": "FIBU-Kreis",
  "branchType.other": "Andere",
  "branchType.wageCompany": "Lohnfirma",
  "branchType.workplace": "Arbeitsstätte",
  "button.activateMfa": "Jetzt aktivieren",
  "button.answer": "BEANTWORTEN",
  "button.archive": "Archivieren",
  "button.assign": "Zuweisen",
  "button.back_to_login": "Zurück zum Login",
  "button.closeTicket": "Als erledigt markieren",
  "button.configure": "KONFIGURIEREN",
  "button.continueInBackground": "Im Hintergrund fortfahren",
  "button.continue_without_microsoft": "Ohne Microsoft-Konto fortfahren",
  "button.copy": "Kopieren/Verschieben",
  "button.deactivateMfa": "Jetzt deaktivieren",
  "button.enter": "EINSTEIGEN",
  "button.markAsPrivate": "Als vertraulich markieren",
  "button.moreInfo": "MEHR INFOS",
  "button.move": "Hierher verschieben",
  "button.nextStep": "nächster Schritt",
  "button.paste": "Hierher kopieren",
  "button.refresh": "Erneuern",
  "button.release": "Freigeben",
  "button.submitArchiveMultiple": "{count} Nachrichten archivieren",
  "button.submitArchiveSingle": "{count} Nachricht archivieren",
  "button.toOverview": "Zur Übersicht",
  "button.transfer": "Übermitteln",
  "button.unlock": "Freischalten",
  "button.upload": "Datei hochladen",
  "chatInput.deleteLink": "Löschen",
  "chatInput.editLink": "Bearbeiten",
  "chatInput.enterLink": "Link",
  "chatInput.openLink": "URL öffnen",
  "chatInput.saveLink": "Speichern",
  "common.abacus": "Abacus",
  "common.accounting": "Buchhaltung",
  "common.accounting.period": "Buchungszeitraum",
  "common.accounting.recordType": "Belegssymbol",
  "common.accounting.subsidiary": "Buchungskreis",
  "common.add": "Hinzufügen",
  "common.albanian": "Shqiptare",
  "common.all": "Alle",
  "common.amount": "Anzahl",
  "common.anyDateRange": "Beliebiger Zeitraum",
  "common.apply": "Übernehmen",
  "common.archive": "Archiv",
  "common.back": "Zurück",
  "common.bankConnection": "Bankverbindung",
  "common.bankConnections": "Bankverbindungen",
  "common.bankContact": "Bankverbindung",
  "common.bic": "BIC",
  "common.bmd": "BMD",
  "common.bulgarian": "Български",
  "common.cancel": "Abbrechen",
  "common.cancelCaps": "ABBRECHEN",
  "common.chat": "Chat",
  "common.clientNumber": "Kundennummer",
  "common.cockpit": "Cockpit",
  "common.companies": "Unternehmen",
  "common.company": "Unternehmen",
  "common.companyNumber": "Firmennummer",
  "common.companyOverview": "Unternehmensübersicht",
  "common.companyRegisterNumber": "Firmenbuchnummer",
  "common.continue": "Weiter",
  "common.country": "Land",
  "common.croatian": "Hrvatski",
  "common.czech": "Česky",
  "common.dateformat": "DD.MM.YYYY",
  "common.dateValidation": "Bitte geben Sie ein gültiges Datum an",
  "common.defaultSignature": "Ihre TPA",
  "common.delete": "Löschen",
  "common.done": "Fertig",
  "common.download": "Herunterladen",
  "common.dueDate": "Frist",
  "common.dueUntil": " bis spätestens {dueDate}",
  "common.emailAddress": "E-Mail Adresse",
  "common.english": "English",
  "common.errorOccurred": "Ein Fehler ist aufgetreten",
  "common.events": "Events",
  "common.files": "Dateien",
  "common.finished": "Abgeschlossen",
  "common.folder": "Ordner",
  "common.from": "Von",
  "common.fullName": "{firstName} {lastName}",
  "common.generalData": "Allgemeine Daten",
  "common.generalDocuments": "Allgemeine Dateien",
  "common.german": "Deutsch",
  "common.global": "Unternehmensweit",
  "common.hr": "Personalverrechnung",
  "common.hr.period": "Abrechnungszeitraum",
  "common.hrPreRegistrations": "Personal - Vorerfassungen",
  "common.hr.short": "Personal",
  "common.hr.subsidiary": "Abrechnungskreis",
  "common.hungarian": "Magyar",
  "common.invite": "Einladen",
  "common.kpi": "Zahlen & Fakten",
  "common.kpi.long": "Zahlen und Fakten",
  "common.later": "Später",
  "common.logout": "Logout",
  "common.me": "Ich",
  "common.members": "Mitglieder",
  "common.moreInformation": "mehr erfahren",
  "common.mr": "Herr {name}",
  "common.mrs": "Frau {name}",
  "common.name": "Name",
  "common.no": "Nein",
  "common.none": "keine",
  "common.noSearchResults": "Keine Ergebnisse gefunden",
  "common.notAvailable": "N/A",
  "common.notSpecified": "k.A.",
  "common.numberValidation": "Bitte geben Sie eine gültige Zahl an",
  "common.ok": "Ok",
  "common.optional": "(optional)",
  "common.orderBy": "Sortieren nach",
  "common.partial": "teilweise",
  "common.people": "Personen",
  "common.polish": "Polski",
  "common.profile": "Profil",
  "common.project": "Projekt",
  "common.projects": "Projekte",
  "common.readingTimeMinutes": "Lesedauer: {time} Min",
  "common.readingTimeMinutes.short": "{time} Min",
  "common.recipient": "Empfänger",
  "common.refresh": "Refresh",
  "common.releasedAt": "freigegeben am {date}",
  "common.releasedAtBy": "freigegeben am {date} von {name}",
  "common.releaseRequired.plural": "{count} Freigaben benötigt",
  "common.releaseRequired.singular": "Eine Freigabe benötigt",
  "common.remainingItems": "+{count} weitere",
  "common.remainingItemsUnknown": "Mehr laden",
  "common.retry": "Erneut versuchen",
  "common.romanian": "Românesc",
  "common.russian": "Русский",
  "common.save": "Speichern",
  "common.sendCaps": "SENDEN",
  "common.serbian": "Српски",
  "common.settings": "Einstellungen",
  "common.showAll": "Alle anzeigen",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "Titel",
  "common.taxNumber": "Steuernummer",
  "common.termsOfUse": "Nutzungsbedingungen",
  "common.ticket": "Rückfrage",
  "common.timePeriod": "Zeitraum",
  "common.timePeriod.olderThan1Month": "Älter als ein Monat",
  "common.timePeriod.olderThan1Week": "Älter als eine Woche",
  "common.timePeriod.olderThan1Year": "Älter als ein Jahr",
  "common.timePeriod.olderThan6Months": "Älter als 6 Monate",
  "common.to": "Bis",
  "common.tpaFirstName": "TPA",
  "common.tpaLastName": "Mitarbeiter",
  "common.unknown": "unbekannt",
  "common.upcomingTermsOfUse": "Zukünftige Nutzungsbedingungen",
  "common.upload": "Hochladen",
  "common.uploadFiles": "Dateien hochladen",
  "common.user": "Benutzer",
  "common.vatNumber": "UID",
  "common.yes": "Ja",
  "commuterAllowance.IndividuellePendlerpauschale": "Individuelle Pendlerpauschale",
  "commuterAllowance.KeinePendlerpauschale": "Keine Pendlerpauschale",
  "commuterAllowance.MehrAls20kmKeinBus": "Mehr als 20 km, Öffis nicht zumutbar",
  "commuterAllowance.MehrAls40kmBus": "Mehr als 40 km, Öffis zumutbar",
  "commuterAllowance.MehrAls40kmKeinBus": "Mehr als 40 km, Öffis nicht zumutbar",
  "commuterAllowance.MehrAls60kmBus": "Mehr als 60 km, Öffis zumutbar",
  "commuterAllowance.MehrAls60kmKeinBus": "Mehr als 60 km, Öffis nicht zumutbar",
  "commuterAllowance.Mindestens20kmBus": "Mehr als 20 km, Öffis zumutbar",
  "commuterAllowance.Mindestens2kmKeinBus": "Mehr als 2 km, Öffis nicht zumutbar",
  "companiesUserReleaseInfoActionsBar.message": "Sie haben {count} neue Benutzervorschläge freizugeben",
  "companiesUserReleaseInfoActionsBar.submit": "Anzeigen und freigeben",
  "companiesUserRelease.permissions.navbar.back": "Benutzer freigeben und einladen / Rolle zuweisen",
  "companiesUserRelease.title": "Benutzer freigeben und einladen",
  "companyImages.cover.error": "Coverbild konnte nicht geladen werden",
  "companyImages.cover.title": "Coverbild",
  "companyImages.delete.message": "Wollen Sie das Bild wirklich löschen?",
  "companyImages.delete.title": "Bild löschen",
  "companyImages.logo.error": "Unternehmenslogo konnte nicht geladen werden",
  "companyImages.logo.title": "Unternehmenslogo",
  "companyImages.title": "Coverbild und Logo Ihres Unternehmens (optional)",
  "companyList.remainingCompanies": "+{remainingCompanies} Weitere",
  "company.optionalSideBarMenuItems.maxLength.name": "Name darf nur 15 Zeichen beinhalten",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "Name für optionalen Menüpunkt bereits vorhanden",
  "company.optionalSideBarMenuItems.name.label": "Name",
  "company.optionalSideBarMenuItems.required.name": "Name muss angegeben werden",
  "company.optionalSideBarMenuItems.required.url": "URL muss angegeben werden",
  "company.optionalSideBarMenuItems.title": "Optionale Menüpunkte des Unternehmens",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "Menüpunkt entfernen",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "Link für optionalen Menüpunkt ist bereits vorhanden",
  "company.optionalSideBarMenuItems.url.label": "URL",
  "company.optionalSideBarMenuItems.urlNotValid": "Bitte geben sie eine gültige URL an (Beispiel: https://www.tpa-connect.app )",
  "companyOverview.emptyState.message": "Bitte wenden Sie sich an Ihren Berater",
  "companyOverview.emptyState.title": "Kein aktives Unternehmen",
  "companyOverview.emptyState.tpa.message": "Sie haben aktuell keine aktiven Kunden",
  "companyOverview.emptyState.tpa.title": "Keine aktiven Kunden",
  "companyOverview.unlockCountry.tooltip": "Neue Länder freischalten<br /><br /> Schalten Sie internationale Projekte aus <b>{country}</b> frei, um das Projekte Feature dieses Unternehmens zu nutzen und alle Rückfragen einzusehen.",
  "companyStatus.accountingSetupDone": "0/2 erledigt",
  "companyStatus.active": "aktiv",
  "companyStatus.hrSetupDone": "1/2 erledigt",
  "companyStatus.inactive": "inaktiv",
  "companyStatus.userSetupDone": "2/2 erledigt",
  "config.accounting.accountingByCustomer": "Übermittlung der Buchhaltungsdaten erfolgt via TPA Connect durch Kunden",
  "config.accounting.delete.subsidiaries.last.message": "Buchungskreis {name} kann nicht gelöscht werden. Mindestens ein Buchungskreis muss existieren.",
  "config.accounting.delete.subsidiaries.message": "Wollen Sie den Buchungskreis {name} wirklich löschen?",
  "config.accounting.delete.subsidiaries.title": "Buchungskreis löschen",
  "config.accounting.description": "Bestimmen Sie die Art und den Umfang des Bereichs Buchhaltung.",
  "config.accounting.description.customer": "Diesen Bereich hat ihr/e TPA BeraterIn für Sie konfiguriert. Bei Änderungswüschen können Sie Ihre/n TPA BeraterIn jederzeit kontaktieren.",
  "config.accounting.due.accounting.reports.select.title": "Tage nach Übermittlung der Buchhaltung",
  "config.accounting.dueDates.title": "Belegübermittlung der Buchhaltung",
  "config.accounting.due.reportRelease.select.subtitle": "Zum Beispiel 4 Tage nach der Übermittlung der Reports durch TPA. Also am 12. des Folgemonats (wenn die Übermittlung der Reports durch TPA am 8. des Folgemonats stattgefunden hat).",
  "config.accounting.due.reportRelease.select.title": "Tage nach Übermittlung der Reports",
  "config.accounting.due.reportRelease.select.value": "{day} Tage nach Übermittlung der Reports",
  "config.accounting.due.reportRelease.title": "Freigabe der Reports durch Kunden",
  "config.accounting.due.reports.select.subtitle": "Zum Beispiel 3 Tage nach der Übermittlung der Buchhaltung. Also am 8. des Folgemonats (wenn die Übermittlung der Buchhaltung am 5. des Folgemonats stattgefunden hat).",
  "config.accounting.due.reports.select.value": "{day} Tage nach Übermittlung der Buchhaltung",
  "config.accounting.due.reports.title": "Übermittlung der Reports durch TPA",
  "config.accounting.due.transfer.select.subtitle": "Zum Beispiel am 5. des Folgemonats.",
  "config.accounting.due.transfer.select.title": "Übermittlung bis",
  "config.accounting.due.transfer.select.value": "{day} Tage nach Ende der Periode",
  "config.accounting.due.transfer.title": "Übermittlung der Buchhaltung durch Kunden",
  "config.accounting.globalReports.description": "Kunde hat unternehmensweite Reports",
  "config.accounting.globalReports.title": "Unternehmensweite Reports",
  "config.accounting.hasAccounting": "Kunde hat Buchhaltung",
  "config.accounting.hasBankAccountTransactionInvoices": "Belege den Banktransaktionen zuordnen",
  "config.accounting.hasCockpitKpi": "Auswertungen im Cockpit",
  "config.accounting.hasKpi": "Zahlen & Fakten",
  "config.accounting.hasResults": "Auswertungen",
  "config.accounting.incaseoflaw.description": "Kunde hat Feature 'In Case of Law'",
  "config.accounting.incaseoflaw.label": "In Case of Law",
  "config.accounting.incaseoflaw.title": "In Case of Law",
  "config.accounting.month.description": "Umfasst eine Dauer von einem Monat",
  "config.accounting.month.title": "Monatliche Buchhaltung",
  "config.accounting.quarter.description": "Umfasst eine Dauer von 3 Monaten",
  "config.accounting.quarter.title": "Buchhaltung pro Quartal",
  "config.accounting.recordTypes": "Belege der Buchhaltung",
  "config.accounting.recordTypes.ocrDisabled": "Automatische Belegerkennung (OCR) deaktiviert",
  "config.accounting.recordTypes.ocrEnabled": "Automatische Belegerkennung (OCR) aktiviert",
  "config.accounting.requireTwoReportReleases": "Freigabe der Reports von zwei Usern erforderlich (Vieraugenprinzip)",
  "config.accounting.subsidiaries.add": "Buchungskreis hinzufügen",
  "config.accounting.subsidiaries.title": "Buchungskreise festlegen",
  "config.accounting.subsidiaries.title.customer": "Buchungskreise",
  "config.accounting.subsidiary.maxReached": "Maximale Anzahl an Buchungskreisen erreicht",
  "config.accounting.subsidiary.name": "Name des Buchungskreises",
  "config.accounting.week.description": "Umfasst eine Dauer von 5 Werktagen und ist in Kalenderwochen gegliedert",
  "config.accounting.week.title": "Wöchentliche Buchhaltung",
  "config.bulk.button.configure": "Konfigurieren",
  "config.bulk.data.description": "Sie können hier ein Coverbild und das Logo der Unternehmen hochladen.",
  "config.bulk.data.title": "Stammdaten",
  "config.bulk.finish.description": "Die Unternehmen werden konfiguriert. Dies kann einige Minuten dauern, bitte das Browserfenster nicht schließen.",
  "config.bulk.finish.start": "Starten",
  "config.bulk.finish.title": "Konfiguration abschließen",
  "config.companies": "Unternehmenskonfiguration",
  "config.companies.buttonConfig": "Unternehmen konfigurieren",
  "config.companies.count": "{count} Unternehmen",
  "config.companies.description": "In diesem Schritt bestimmen Sie Prozesse, die für das Unternehmen spezifisch sind. Dazu zählen der visuelle Auftritt in TPA Connect, Art, Umfang zu Buchhaltung und Personalverrechnung als auch Fristen.",
  "config.companyData.cockpit": "Cockpit",
  "config.companyData.description": "Sie können hier ein Coverbild und das Logo des Unternehmens hochladen und die Buchungskriterien festlegen.",
  "config.companyData.title": "Stammdaten",
  "config.companyOverview.inactive": "Unternehmensübersicht / Inaktiv",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "Ja, löschen",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "Wollen Sie diesen Menüpunkt wirklich löschen?",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "Menüpunkt löschen",
  "config.customMenuPoints.noMenuPointsAvailable": "Keine Menüpunkte vorhanden",
  "config.employees.assignRole": "Rolle zuweisen",
  "config.employees.emptyState.message": "Es gibt aktuell keine aktiven DienstnehmerInnen",
  "config.employees.emptyState.title": "Keine aktiven DienstnehmerInnen",
  "config.employees.table.missingEmail": "E-Mail Adresse fehlt",
  "config.employees.table.missingEmail.tooltip": "E-Mail Adresse muss von einem TPA-Berater in BMD eingetragen werden",
  "config.features.alreadyToggledToday.disabled": "Das Add-On wurde heute bereits deaktiviert",
  "config.features.alreadyToggledToday.enabled": "Das Add-On wurde heute bereits aktiviert",
  "config.features.confirm.message.disabled": "Wenn Sie das Add-On aktivieren, ist das kostenpflichtige Add-On ab sofort verfügbar und wird Ihnen ab heute in Rechnung gestellt. Sie können das Add-On morgen wieder deaktivieren.",
  "config.features.confirm.message.enabled": "Das kostenpflichtige Add-On wird mit Ende des Tages deaktiviert. Der heutige Tag wird Ihnen noch in Rechnung gestellt. Sie können das Add-On ab morgen wieder aktivieren.",
  "config.features.confirm.title.disabled": "Add-On aktivieren",
  "config.features.confirm.title.enabled": "Add-On deaktivieren",
  "config.finishConfiguration": "Konfiguration abschließen",
  "config.finishConfiguration.cockpit": "Zum Cockpit",
  "config.finishConfiguration.continue": "Weiteres Unternehmen anlegen",
  "config.finishConfiguration.emailNotification": "Benutzer per E-Mail benachrichtigen",
  "config.finishConfiguration.message": "Das Unternehmen {company} wurde erfolgreich konfiguriert. Es kann nun für den Austausch zwischen TPA und Klient verwendet werden.",
  "config.finishSetup": "SETUP BEENDEN",
  "config.hr.chat.description": "Lohnmitarbeiter dürfen TPA Berater kontaktieren",
  "config.hr.chat.title": "Chat",
  "config.hr.delete.subsidiaries.last.message": "Abrechnungskreis {name} kann nicht gelöscht werden. Mindestens ein Abrechnungskreis muss existieren.",
  "config.hr.delete.subsidiaries.message": "Wollen Sie den Abrechnungskreis {name} wirklich löschen?",
  "config.hr.delete.subsidiaries.title": "Abrechnungskreis löschen",
  "config.hr.description": "Bestimmen Sie die Art und den Umfang des Bereichs Personalverrechnung.",
  "config.hr.dueDates.reminder": "Erinnerung an Fristen",
  "config.hr.dueDates.title": "Fristen der Personalverrechnung",
  "config.hr.due.relativeDueDates": "Unterlagenanlieferung bis inkl. 15. des Folgemonats erlauben",
  "config.hr.due.reports.select.subtitle": "Zum Beispiel 3 Tage nach der Übermittlung der Personalverrechnung. Also am 8. des Folgemonats (wenn die Übermittlung der Personalverrechnung am 5. des Folgemonats stattgefunden hat).",
  "config.hr.due.reports.select.title": "Tage nach Übermittlung der Personalverrechnung",
  "config.hr.due.reports.select.value": "{day} Tage nach Übermittlung der Personalverrechnung",
  "config.hr.due.select.value": "{day}. des Monats",
  "config.hr.due.transfer.select.subtitle": "Zum Beispiel am 5. des Monats.",
  "config.hr.due.transfer.select.value.relativeDueDates": "{day} Tage nach Ende des Monats",
  "config.hr.due.transfer.title": "Übermittlung der Personalverrechnung durch Kunden",
  "config.hr.hasHr": "Kunde hat Personalverrechnung",
  "config.hr.hasResults": "Auswertungen",
  "config.hr.recordTypes": "Dokumente der Personalverrechnung",
  "config.hr.releaseSteps.oneStep.description": "Alle monatlichen Auswertungen werden von der TPA in einem Schritt an den Kunden übermittelt. Der Kunde muss bis zu einer definierten Frist alle notwendigen Dokumente freigeben.",
  "config.hr.releaseSteps.oneStep.title": "in einem Schritt",
  "config.hr.releaseSteps.title": "Schritte der Freigaben",
  "config.hr.releaseSteps.twoSteps.description": "Die Übermittlung und die Freigabe der monatlichen Auswertung erfolgt in zwei Schritten. Der Kunde kann Abrechnungen freigeben, bevor er alle weiteren monatlichen Auswertungen freigibt.",
  "config.hr.releaseSteps.twoSteps.title": "in zwei Schritten",
  "config.hr.requireTwoReportReleases": "Freigabe der Reports von zwei Usern erforderlich (Vieraugenprinzip)",
  "config.hr.sendPayroll.description": "Verdienstnachweise elektronisch übermitteln",
  "config.hr.sendPayroll.title": "Elektronischer Verdienstnachweis",
  "config.hr.sendPayroll.tooltip": "Mit Ausstellen eines Lohn-/Gehaltszettels erhält jeder Mitarbeiter vollautomatisch einen Zugang zur TPA Connect App mit welchem die eigenen Verdienstnachweise als auch der elektronische Personalakt eingesehen werden können.",
  "config.hr.subsidiaries.add": "Abrechnungskreis hinzufügen",
  "config.hr.subsidiaries.info": "Nach Auswahl des ersten Abrechnungskreises können nur mehr weitere vom selben Typ ausgewählt werden (z.B. Kostenstamm). Um einen neuen Typ (z.B. FIBU-Kreis) auszuwählen, müssen alle bis auf einen Abrechnungskreis gelöscht werden.",
  "config.hr.subsidiaries.title": "Abrechnungskreise festlegen",
  "config.hr.subsidiaries.title.customer": "Abrechnungskreise",
  "config.hr.subsidiary.maxReached": "Maximale Anzahl an Abrechnungskreisen erreicht",
  "config.hr.subsidiary.name": "Name des Abrechnungskreises",
  "config.hr.upload.description": "Lohnmitarbeiter dürfen Dokumente in ihren Personalakt hochladen",
  "config.hr.upload.title": "Dokumente in Personalakt hochladen",
  "config.hr.warning.noBranch": "Personalverrechnung kann nicht aktiviert werden. Bitte legen Sie eine Lohnfirma im NTCS an",
  "config.moduleTypeDetails.asp.helpInstructions": "Anleitung ASP Kunden",
  "config.moduleTypeDetails.asp.helpInstructions.open": "Zur Anleitung",
  "config.moduleTypeDetails.label.database": "Datenbank",
  "config.moduleTypeDetails.label.dataSource": "Datenquelle",
  "config.moduleTypeDetails.label.url": "URL",
  "config.noDueDate": "keine Frist",
  "config.optionalMenuPoints.success.title": "Menüpunkte erfolgreich gespeichert",
  "config.overview": "Konfigurationsübersicht",
  "config.permission.change": "geändert auf \"{permission}\"",
  "config.permissions.accounting.globalReports": "Unternehmensweite Reports der Buchhaltung",
  "config.permissions.deleted": "(gelöscht)",
  "config.permissions.description": "Wählen Sie die Bereiche auf die der User zugreifen darf. Sie können für jeden Buchungskreis unterschiediche Einstellungen treffen.",
  "config.permissions.employees": "DienstnehmerInnen",
  "config.permissions.existingUser.navbar.back": "Konfigurationsübersicht / Benutzerverwaltung / Rolle zuweisen",
  "config.permissions.existingUser.navbar.backToUser": "Konfigurationsübersicht / Benutzerverwaltung / Rolle zuweisen / {employeeName}",
  "config.permissions.global": "Unternehmensweit",
  "config.permissions.internationalProjects.info": "Projekte Berechtigungen können nicht entzogen werden: Mitglieder von internationalen Projekten erhalten diese automatisch.",
  "config.permissions.navbar.back": "Konfigurationsübersicht / Benutzerverwaltung / AnsprechpartnerInnen wählen / Rolle zuweisen",
  "config.permissions.navbar.backToUser": "Konfigurationsübersicht / Benutzerverwaltung / AnsprechpartnerInnen wählen / Rolle zuweisen / {employeeName}",
  "config.permissions.noPermissionSelected.message": "Wählen Sie mindestens eine Berechtigung aus.",
  "config.permissions.noPermissionSelected.title": "Keine Berechtigung ausgewählt",
  "config.permissions.title": "Berechtigungen anpassen",
  "config.roles.change": "Rolle von {oldRole} auf {newRole} geändert",
  "config.roles.customizePermissions": "Berechtigungen anpassen",
  "config.roles.description": "Wählen Sie eine Rolle mit einem bereits vordefinierten Set an Berechtigungen. Sie können die Berechtigungen ggf. in einem weiteren Schritt anpassen.",
  "config.roles.multipleCompaniesDialog.message": "Der Benutzer {name} ist in den nachfolgenden Unternehmen vorhanden. Wollen Sie die Berechtigungsänderungen in allen Unternehmen durchführen?",
  "config.roles.multipleCompaniesDialog.title": "Änderungen in mehreren Unternehmen übernehmen",
  "config.roles.multipleCompaniesDialog.warning": "Unternehmen konnten nicht geladen werden. Die Änderungen werden nur auf das aktuelle Unternehmen angewendet.",
  "config.roles.navbar.back": "Konfigurationsübersicht / Benutzerverwaltung / AnsprechpartnerInnen wählen",
  "config.roles.navbar.heading": "{employeeCount} Benutzer gewählt",
  "config.roles.option.copy.description": "Geben Sie den Namen des Benutzers an, um dessen Rolle und die Berechtigungen zu übernehmen. Sie können die Berechtigungen ggf. im nächsten Schritt anpassen.",
  "config.roles.option.copy.label": "Rolle von anderem Benutzer übernehmen",
  "config.roles.option.copy.placeholder": "Name des Benutzers",
  "config.roles.releaseInformation": "Um die Benutzereinstellungen von <b>{userName}</b> bearbeiten zu können, muss ein <b>Superadmin</b> die Änderungen freigeben/ablehnen.",
  "config.roles.releaseInformation.info.message": "Wenn jemand (abgesehen vom Superadmin) Änderungen an Benutzereinstellungen vornimmt, müssen diese Änderungen von einem Superadmin freigegeben oder abgelehnt werden. Bis dahin sind die Einstellungen des Benutzers gesperrt und nicht bearbeitbar.",
  "config.roles.releaseInformation.info.title": "Warum können die Benutzereinstellungen nicht bearbeitet werden?",
  "config.roles.releaseInformation.superAdmin": "{name} hat Änderungen bei <b>{userName}</b> vorgenommen. Bitte geben Sie die Änderungen des Benutzer frei um fortzufahren.",
  "config.roles.sidebar.title": "Gewählte Benutzer",
  "config.roles.submit": "Benutzer vorschlagen",
  "config.roles.submit.existingUser": "Änderungen vorschlagen",
  "config.roles.submit.superAdmin": "Benutzer einladen",
  "config.roles.title": "Rolle zuweisen",
  "config.roles.viewPermissions": "Berechtigungen einsehen",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "OK, speichern",
  "config.terms.allSigneesAcceptedWarningDialog.message": "Achtung: Da bereits alle hinzugefügten Personen unterzeichnet haben, wird das Unternehmen nach dem Speichern aktiviert.",
  "config.terms.allSigneesAcceptedWarningDialog.title": "Nutzungsbedingungen speichern",
  "config.terms.alreadySigned": "Die Nutzungsbedingungen ihres Unternehmens wurden bereits unterzeichnet.",
  "config.terms.button.revokeActivation": "Freischaltung widerrufen",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "Ja, Dokument ändern",
  "config.terms.changeDocumentVersionDialog.message": "Wenn Sie fortfahren, müssen alle bereits hinzugefügten E-Mail Adressen die Nutzungsbedingungen erneut akzeptieren.",
  "config.terms.changeDocumentVersionDialog.title": "Dokument ändern",
  "config.terms.deleteEmailDialog.confirmLabel": "Ja, E-Mail Adresse löschen",
  "config.terms.deleteEmailDialog.message": "Wollen Sie die E-Mail Adresse wirklich löschen?",
  "config.terms.deleteEmailDialog.title": "E-Mail Adresse löschen",
  "config.terms.documentVersion": "Dokumentenversion",
  "config.terms.emailAddressesInfo": "Fügen Sie die E-Mail Adressen der gewünschten Personen hinzu, die per E-Mail aufgefordert werden sollen, die jeweiligen Nutzungsbedingungen zu unterzeichnen. Fügen Sie nur die E-Mail-Adresse von Zeichnungsberechtigten hinzu! Nutzungsbedingungen werden erst bei Abschluss der Unternehmens- und Benutzerkonfiguration an die unten stehenden E-Mail-Adressen versendet.",
  "config.terms.emailAddressesTitle": "E-Mail Adressen",
  "config.terms.emailAlreadyUsed": "Diese E-Mail Adresse wurde bereits hinzugefügt",
  "config.terms.invalidEmail": "Ungültige E-Mail Adresse",
  "config.terms.noDocumentsAvailable": "Aktuell sind noch keine Dokumente vorhanden",
  "config.terms.noSigneesAvailable": "Es sind noch keine UnterzeichnerInnen vorhanden",
  "config.termsOfUse.navbar.back": "Konfigurationsübersicht / Stammdaten",
  "config.terms.removeEmail": "E-Mail Adresse löschen",
  "config.terms.resendEmail.header": "E-Mail versendet",
  "config.terms.resendEmail.message": "E-Mail zum Akzeptieren der Nutzungsbedingungen wurde erneut versendet",
  "config.terms.resendMail": "E-Mail erneut versenden",
  "config.terms.revokeActivationDialog.confirmLabel": "ja, Freischaltung widerrufen",
  "config.terms.revokeActivationDialog.message": "Sind Sie sicher, dass die Freischaltung des Unternehmens widerrufen werden soll? Alle eingetragenen Personen müssen die Nutzungsbedingungen erneut unterzeichnen, um das Unternehmen erneut freizuschalten.",
  "config.terms.revokeActivationDialog.title": "Freischaltung widerrufen",
  "config.terms.signedVersion": "unterzeichnete Version:",
  "config.terms.skip.button": "Überspringen",
  "config.terms.skip.dialog.message": "Kundenseitige Benutzer können sich erst anmelden wenn die Nutzungsbedingungen akzeptiert wurden. Bis dahin steht das Unternehmen nur für TPA-interne Zwecke in der Connect App zur Verfügung.",
  "config.terms.skip.dialog.title": "Information",
  "config.terms.success.header": "Erfolgreich gespeichert",
  "config.terms.termsOfUseInfo": "Wählen Sie die entsprechende Dokumentenversion aus",
  "config.terms.termsOfUseTitle": "Nutzungsbedingungen",
  "config.users": "Benutzerverwaltung",
  "config.users.active.title": "Aktive Benutzer",
  "config.users.assignRole": "Rolle zuweisen",
  "config.users.buttonConfig": "Benutzer verwalten",
  "config.users.changeUsername": "Neue E-Mail",
  "config.users.changeUsername.tooltip": "Neue E-Mail Adresse des Users lautet {newUsername}. Durch Klick auf den Button wird der/die UserIn aufgefordert, die neue E-Mail Adresse zu bestätigen.",
  "config.users.description": "Weisen Sie einzelnen Prozessen Benutzer bzw. Benutzergruppen zu. Sie bestimmen, wer auf welche Bereiche in welchem Ausmaß Zugriff hat.",
  "config.users.edit": "Ändern",
  "config.users.emptyState.message": "Es gibt aktuell keine aktiven User<br/>Um einen Superadmin anzulegen, verwenden Sie bitte das BMD NTCS.",
  "config.users.emptyState.title": "Keine aktiven User",
  "config.users.inviteAgain": "Erneut einladen",
  "config.users.navBar.back": "Konfigurationsübersicht / Benutzerverwaltung",
  "config.users.new": "Neu",
  "config.users.newUsers": "Neuen Benutzer anlegen",
  "config.users.newUsers.description": "Wählen Sie hier die AnsprechpartnerInnen, die Sie als Benutzer von TPA Connect anlegen möchten. Im nächsten Schritt können Sie den ausgewählten Personen eine Rolle zuweisen und ggf. die vordefinierten Berechtigungen anpassen.",
  "config.users.newUsers.title": "AnsprechpartnerInnen wählen",
  "config.users.reject": "Ablehnen",
  "config.users.release": "Freigeben",
  "config.users.releaseAndInvite": "Freigeben & einladen",
  "config.users.table.invalidEmail": "E-Mail Adresse ungültig",
  "config.users.table.invalidEmail.tooltip": "E-Mail Adresse muss von einem TPA-Berater in BMD korrigiert werden",
  "config.users.waiting.title": "Warten auf Freigabe",
  "cookies.acceptAll": "Alle Cookies zulassen",
  "cookies.acceptFunctional": "Nur notwendige Cookies verwenden",
  "cookies.cookieList": "Cookie-Erklärung",
  "cookies.text": "Wir verwenden Cookies, um unseren Service zu optimieren. Mehr Details dazu finden Sie in der {link}.",
  "country.al": "Albanien",
  "country.at": "Österreich",
  "country.bg": "Bulgarien",
  "country.cz": "Tschechien",
  "country.hr": "Kroatien",
  "country.hu": "Ungarn",
  "country.me": "Montenegro",
  "country.pl": "Polen",
  "country.ro": "Rumänien",
  "country.rs": "Serbien",
  "country.si": "Slowenien",
  "country.sk": "Slowakei",
  "deleteDialog.message.plural": "Wollen Sie die Dokumente <b>{documents}</b> wirklich löschen?<br/><br/>Diese Aktion kann nicht rückgängig gemacht werden.",
  "deleteDialog.message.singular": "Wollen Sie das Dokument <b>{documents}</b> wirklich löschen?<br/><br/>Diese Aktion kann nicht rückgängig gemacht werden.",
  "deleteDialog.title": "Dokumente löschen?",
  "deleteUser.message": "Sind Sie sicher, dass Sie den Benutzer / die Benutzerin <b>{name}</b> löschen möchten?",
  "deleteUser.multipleCompanies.confirmLabel": "Den Benutzer in allen Unternehmen löschen",
  "deleteUser.multipleCompanies.message": "Der Benutzer ist den folgenden Unternehmen vorhanden:",
  "deleteUser.title": "BenutzerIn löschen",
  "deleteUser.warning.superadminskipped": "Achtung! Unternehmen, in denen Benutzer:innen die Rolle Superadmin haben, werden beim Löschen übersprungen.",
  "dialog.archive.checkbox_pdf": "PDF lokal speichern",
  "dialog.archive.subject": "Betreff",
  "dialog.archive.success.text": "Sie können nun im {bmd} auf die archivierten Nachrichten zugreifen.",
  "dialog.archive.success.title": "Nachrichten erfolgreich archiviert!",
  "dialog.archive.text": "Wählen Sie ein Unternehmen, zu welchem Sie die gewählten Nachrichten im BMD hinterlegen wollen.",
  "dialog.archive.title_multiple": "{count} Nachrichten archivieren",
  "dialog.archive.title_single": "1 Nachricht archivieren",
  "dialog.assignTicket.header": "Rückfrage zuweisen",
  "dialog.assignTicket.message": "Wählen Sie eine Person, der Sie die Rückfrage zuweisen möchten.",
  "dialog.button.understood": "Verstanden",
  "dialog.closeTicket.success.header": "Rückfrage erledigt",
  "dialog.companyConfig.success.header": "Unternehmenskonfiguration erfolgreich gespeichert.",
  "dialog.companyConfig.success.message": "Die Bereiche für {name} sind nun bereit.",
  "dialog.eldaTransfer.andNext": " und weiter",
  "dialog.eldaTransfer.downloadLater": "Später Herunterladen",
  "dialog.eldaTransfer.success.header": "Anmeldung erfolgreich übermittelt",
  "dialog.loginError.multiFactorCodeWrong.title": "Der eingegebene Code ist leider falsch",
  "dialog.loginError.subtitle": "Bitte versuchen Sie es erneut.",
  "dialog.loginError.title": "Unbekannte E-Mail Adresse oder falsches Passwort",
  "dialog.noPhoneNumberWarning.message": "Mindestens eine der gewählten AnsprechpartnerInnen hat keine gesetzte Telefonnummer. Ohne Telefonnummer steht die Zweifaktor-Authentifizierung nicht zur Verfügung und eine Einladung ist möglicherweise unsicher.",
  "dialog.noPhoneNumberWarning.title": "Keine Telefonnummer gesetzt",
  "dialog.passwordExpired.message": "Ihr Passwort ist abgelaufen und muss geändert werden. Sie erhalten in Kürze eine E-Mail zur Passwortänderung. Sollten Sie Fragen dazu haben Kontaktieren Sie bitte TPA unter {telephoneNumber}. Vielen Dank!",
  "dialog.passwordExpired.title": "Passwort abgelaufen",
  "dialog.password_reset.success.header": "Passwort erfolgreich geändert",
  "dialog.registration.success.button": "Jetzt einloggen",
  "dialog.registration.success.header": "Sie haben sich erfolgreich registriert!",
  "dialog.registration.success.message": "Loggen Sie sich mit Microsoft ein um TPA Connect zu benutzen.",
  "dialog.transferReports.success.message": "Ihr Kunde wird nun die Dateien sichten und sich ggf. mit Rückfragen bei Ihnen melden.",
  "dialog.transfer.success.header": "Dateien erfolgreich übermittelt",
  "dialog.userConfig.success.header": "Benutzereinstellungen gespeichert",
  "dialog.user_not_found.button": "Verstanden",
  "dialog.user_not_found.subtitle": "Setzen sich sich mit Ihrem Systemadmin in Verbindung oder melden Sie sich mit Ihrem TPA-Connect-Konto an.",
  "dialog.user_not_found.title": "kein Benutzerkonto mit dieser E-Mail Adresse bekannt",
  "documentUpload.error.amount": "<b>{failedUploads}</b> von {uploads} Dateien konnten nicht hochgeladen werden:",
  "documentUpload.error.button.text.continue": "Ohne diese Dateien fortfahren",
  "documentUpload.error.button.text.retry": "Nochmals versuchen",
  "documentUpload.error.message": "Fehler beim Dateiupload",
  "documentUpload.error.rejection.amount": "<b>{failedUploads}</b> von {uploads} Dateien können nicht hochgeladen werden:",
  "documentUpload.error.tooBigImages.amount": "<b>{failedUploads}</b> Bilder sind größer als max. {maxImageDimension} Pixel. Sie werden automatisch verkleinert. Fortfahren?",
  "documentUpload.error.tooBigImages.amount.singular": "Das Bild ist größer als max. {maxImageDimension} Pixel. Es wird automatisch verkleinert. Fortfahren?",
  "documentUpload.error.tooBigImages.button.text.continue": "Verkleinern und fortfahren",
  "documentUpload.error.tooBigImages.pdf": "Bilder im PDF sind größer als max. {maxImageDimension} Pixel. Bitte verkleinern Sie die Bilder.",
  "documentUpload.error.tooManyFiles.plural": "Maximal <b>{count}</b> Dateien können hochgeladen werden:",
  "documentUpload.error.tooManyFiles.singular": "Maximal <b>eine</b> Datei kann hochgeladen werden:",
  "documentUpload.max.size": "Maximale Dokumentgröße {maxUploadSizeMb} Mb",
  "documentUpload.tooBigImages.message": "Bilder zu groß",
  "documentUpload.type.file.formats": "Unterstützte Formate",
  "dropzone.button.label.search": "suchen Sie danach.",
  "dropzone.image.text": "Ziehen Sie das Bild einfach in diesen Bereich oder",
  "dropzone.info.redo": "erneut hochladen",
  "dropzone.info.text": "Ziehen Sie die Dateien einfach in diesen Bereich oder",
  "dueDate.answerAt": "zu beantworten bis {date}",
  "dueDate.closedAt": "abgeschlossen am {date}",
  "dueDate.sentAt": "gesendet am {date}",
  "dueDate.sentAtWithTime": "gesendet am {date} um {time}",
  "dueDate.transferredAt": "übermittelt am {date}",
  "dueDate.until": "bis {date}",
  "employmentInsuranceType.apprentice": "Lehrling",
  "employmentInsuranceType.fullTime": "vollversichert",
  "employmentInsuranceType.independentContractor": "freier DN",
  "employmentInsuranceType.marginalIndependentContractor": "geringfügiger freier DN",
  "employmentInsuranceType.marginally": "geringfügig",
  "employmentRelationship.employee": "Angestellte:r",
  "employmentRelationship.worker": "Arbeiter:in",
  "error.accounting.postSubsidiary": "Buchungskreis konnte nicht angelegt werden",
  "error.accounting.subsidiaryDoesNotExist": "Buchungskreis nicht gefunden",
  "error.activate": "Fehler beim Aktivieren",
  "error.addBankAccountTransactionInvoices": "Transaktion und Belege konnten nicht verknüpft werden",
  "error.addBankConnection": "Bankverbindung konnte nicht hinzugefügt werden",
  "error.addCompanyToUserFavorites": "Unternehmen konnte nicht zu Ihren Favoriten hinzugefügt werden",
  "error.addFolderBadRequest": "Ordner konnte nicht erstellt werden. Bachten Sie, dass Sie keine unerlaubten Sonderzeichen benutzt haben: \" * : < > ? / \\ |",
  "error.archiveDisabledForCompany": "Archivierung bei diesem Unternehmen nicht möglich",
  "error.archiveFailed": "Fehler beim Archivieren",
  "error.archiveProjectFailed": "Projekt konnte nicht archiviert werden",
  "error.avatarUploadFailed": "Das Profilbild konnte nicht hochgeladen werden. Stellen Sie sicher, dass das Format JPEG oder PNG & die Dateigröße max. {maxSize}MB beträgt",
  "error.avatarUploadOnlyOneImage": "Sie können nur ein Bild hochladen",
  "error.changeUsernameEmail": "E-Mail zur Änderung des Benutzernamens konnte nicht versendet werden",
  "error.changeUsername.newUsernameConflict": "Neue E-Mail stimmt nicht mit hinterlegter überein.",
  "error.changeUsername.newUsernameDuplicate": "E-Mail bereits in Verwendung.",
  "error.changeUsername.noNewUsername": "Keine neue E-Mail Adresse wurde vergeben.",
  "error.changeUsername.tokenExpired": "Dieser Link ist abgelaufen.",
  "error.changeUsername.tokenNotFound": "Link ungültig, wurde die neue E-Mail Adresse bereits bestätigt?",
  "error.chatForbidden": "Face to Face ist bei dieser Firma für Sie nicht aktiviert.",
  "error.chatNoCompany": "Nachricht ohne Firmenkontext.",
  "error.chatNoRecipient": "Nachricht hat keinen Empfänger.",
  "error.chatSearch": "Suche fehlgeschlagen. Bitte versuchen Sie es erneut.",
  "error.completeSign": "Elektronische Signatur konnte nicht abgeschlossen werden",
  "error.config.accounting.duplicate": "Sie haben mehrere Buchungskreise mit dem selben Namen angegeben",
  "error.config.accounting.noSubsidiary": "Bitte legen Sie mindestens einen Buchungskreis fest um fortzufahren.",
  "error.config.activateCompanyFailed": "Dieser Kunde wurde technisch noch nicht auf die Nutzung von TPA Connect vorbereitet. Bitte wenden Sie sich an den EDV-Support um einen entsprechenden Benutzer anlegen zu lassen.",
  "error.config.corruptSubsidiary": "Buchungskreis ohne ID",
  "error.config.hr.duplicate": "Sie haben mehrere Abrechnungskreise mit dem selben Namen angegeben",
  "error.config.hr.noSubsidiary": "Bitte legen Sie mindestens einen Abrechnungskreis fest um fortzufahren.",
  "error.copy.alreadyExists.file": "Fehler beim Kopieren: Eine Datei mit dieser Bezeichnung existiert bereits",
  "error.copy.alreadyExists.folder": "Fehler beim Kopieren: Ein Ordner mit dieser Bezeichnung existiert bereits",
  "error.copy.alreadyExists.unknown": "Fehler beim Kopieren: Ein Ordner oder eine Datei mit dieser Bezeichnung existiert bereits",
  "error.create": "Fehler beim Anlegen",
  "error.create.alreadyExists.project": "Ein Projekt mit dieser Bezeichnung existiert bereits",
  "error.createEmployee": "Mitarbeiter konnte nicht angelegt werden",
  "error.delete": "Fehler beim Löschen",
  "error.deleteBankAccountTransactionInvoices": "Verknüpfung konnte nicht gelöscht werden",
  "error.deleteBankConnection": "Bankverbindung konnte nicht gelöscht werden",
  "error.delete.fileLocked": "Die Datei wird momentan bearbeitet und kann daher nicht gelöscht werden.",
  "error.deleteUser": "BenutzerIn konnte nicht gelöscht werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
  "error.deregisterEmployee": "Mitarbeiter konnte nicht abgemeldet werden",
  "error.documentForbidden": "Sie haben keinen Zugriff auf das Dokument",
  "error.documentInProgress": "Dokument wird gerade an TPA übermittelt und kann nicht heruntergeladen werden. Probieren Sie es in Kürze erneut.",
  "error.documentNotFound": "Dokument nicht gefunden",
  "error.documentsInProgress": "Dokumente werden gerade an TPA übermittelt und können nicht heruntergeladen werden. Probieren Sie es in Kürze erneut.",
  "error.download": "Fehler beim Download",
  "error.downloadTermsOfUseDocument": "Beim Herunterladen der Nutzungsbedingungen ist ein Fehler aufgetreten",
  "error.download.transferInProgress": "Dokument wird gerade übertragen. Bitte versuchen Sie es etwas später nochmals.",
  "error.dueDate.minDate": "Das Datum für die Frist darf nicht in der Vergangenheit liegen",
  "error.duplicateName": "Name bereits vergeben",
  "error.duplicateUsername": "Die neu zugewiesene E-Mail Adresse ist bereits in Verwendung",
  "error.edit": "Fehler beim Bearbeiten",
  "error.employeeWithoutEmail": "Mitarbeiter:in hat keine gültige E-Mail Adresse",
  "error.folderIsEmpty": "Der Ordner ist leer und kann daher nicht heruntergeladen werden",
  "error.foldersAreEmpty": "Alle gewählten Ordner sind leer und können daher nicht heruntergeladen werden",
  "error.forbidden": "Keine Berechtigung",
  "error.general": "Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  "error.getSign": "Beim Laden der erforderlichen Informationen für die elektronische Signatur ist ein Fehler aufgetreten",
  "error.hr.noSubsidiarySelected": "Kein Abrechnungskreis ausgewählt",
  "error.hr.postSubsidiary": "Abrechnungskreis konnte nicht angelegt werden",
  "error.hr.subsidiaryDoesNotExist": "Abrechnungskreis nicht gefunden",
  "error.imageCrop": "Fehler bei der Bildverarbeitung",
  "error.imageTooBig": "Bild zu groß",
  "error.invalidCharacters": "Eingabe enthält ungültige Zeichen",
  "error.invalidCompanyId": "Ungültige Firmen ID",
  "error.invalid_credentials": "Falsche Benutzerkennung oder Passwort",
  "error.invalidDateFormat": "Ungültiges Datumsformat",
  "error.invalidEmployee": "Daten von DienstnehmerIn unvollständig oder fehlerhaft",
  "error.invalidPeriodId": "Ungültige Periode für diese Firma",
  "error.invalidRolePermissions": "Rollen-Berechtigungen sind ungültig",
  "error.invalidSsn": "Ungültige Sozialversicherungsnummer",
  "error.invalidTicket": "Ungültiges Ticket",
  "error.invalidWebhookParams": "Ungültige Webhook Parameter.",
  "error.invitationNoCompany": "Einladung ohne Unternehmens ID",
  "error.linkExpired": "Dieser Link ist abgelaufen. Bitte fordern Sie einen neuen an.",
  "error.linkInvalid": "Dieser Link ist ungültig.",
  "error.loadAccount": "Konto konnte nicht geladen werden",
  "error.loadAccountListing": "Saldenliste konnte nicht geladen werden",
  "error.loadAccounts": "Konten konnten nicht geladen werden",
  "error.loadAccountTransactions": "Buchungen konnten nicht geladen werden",
  "error.loadArchiveDocuments": "Nicht alle Archivdokumente konnten geladen werden",
  "error.loadBadges": "Fehler beim Laden von Benachrichtigungen",
  "error.loadBalanceSheet": "Bilanz konnte nicht geladen werden",
  "error.loadBankAccountTransactions": "Transaktionen konnten nicht geladen werden",
  "error.loadBankConnections": "Bankverbindungen konnten nicht geladen werden",
  "error.loadBranches": "Filialen konnten nicht geladen werden",
  "error.loadCashAccounting": "Einnahmen-Ausgaben-Rechnung konnte nicht geladen werden",
  "error.loadCompany": "Unternehmen konnte nicht geladen werden",
  "error.loadCompanyFeatures": "Add-Ons konnten nicht geladen werden",
  "error.loadContacts": "Kontakte konnten nicht geladen werden",
  "error.loadCostCenters": "Kostenstellen konnten nicht geladen werden",
  "error.loadCountries": "Länderdaten konnten nicht geladen werden",
  "error.loadCustomerListing": "Debitorenliste konnte nicht geladen werden",
  "error.loadDestinations": "Belegsstandorte konnten nicht geladen werden. Bitte probieren Sie es später erneut",
  "error.loadDocument": "Dokument konnte nicht geladen werden",
  "error.loadDocuments": "Dokumente konnten nicht geladen werden",
  "error.loadDraft": "Freigabe konnte nicht geladen werden",
  "error.loadEmployee": "DienstnehmerIn konnte nicht geladen werden",
  "error.loadEmployeeDocuments": "Dateien konnten nicht geladen werden",
  "error.loadEmployees": "DienstnehmerInnen konnten nicht geladen werden",
  "error.loadEvents": "Events konnten nicht geladen werden, versuchen Sie es später erneut",
  "error.loadFeatureFlags": "Feature Flags konnten nicht geladen werden",
  "error.loadFeedCountries": "Länder konnten nicht geladen werden.",
  "error.loadFinancialAccountancies": "Buchhaltungen konnten nicht geladen werden",
  "error.loadFormData": "Formulardaten konnten nicht geladen werden",
  "error.loadFunders": "Kostenträger konnten nicht geladen werden",
  "error.loadGeneralDocumentsTimeout": "Die Berichte sind vorübergehend nicht verfügbar. Bitte probieren Sie es später erneut.",
  "error.loading": "Fehler beim Laden",
  "error.loadInsuranceCarrierBalance": "Fehler beim Laden des Krankenkassen Saldos",
  "error.loadInsuranceCarriers": "Fehler beim Laden der Krankenkassen",
  "error.loadInvitationResults": "Fehler beim Laden freigegebener User-Änderungen",
  "error.loadKpiConfigurations": "Zahlen und Fakten Konfiguration konnte nicht geladen werden",
  "error.loadKpis": "Zahlen und Fakten konnten nicht geladen werden, versuchen Sie es später erneut",
  "error.loadMessages": "Nachrichten konnten nicht geladen werden",
  "error.loadModuleTypeDetails": "Fehler beim Laden der Modul Details",
  "error.loadNews": "Nachrichten konnten nicht geladen werden, versuchen Sie es später erneut",
  "error.loadNews.noSource": "Der Nachrichten-Feed ist derzeit nicht erreichbar.",
  "error.loadNotifications": "Benachrichtigungseinstellungen konnten nicht geladen werden",
  "error.loadOptionalMenuItems": "Optionale Menüpunkte konnten nicht geladen werden",
  "error.loadPayrollAccount": "Lohnkonto konnte nicht geladen werden",
  "error.loadPayrollAccountDetails": "Lohnkonto details konnten nicht geladen werden",
  "error.loadPendingMicrosoftActions": "Einladungen & Refresh-Informationen konnten nicht geladen werden",
  "error.loadPeriod": "Fehler beim Laden der Periode",
  "error.loadPeriodData": "Fehler beim Laden der Periodendaten",
  "error.loadPermissions": "Berechtigungen konnten nicht geladen werden",
  "error.loadPersonnelFile": "Personalakte konnte nicht geladen werden",
  "error.loadPersonnelFile.notStaff": "User ist kein Lohnmitarbeiter",
  "error.loadPowerBIReportEmbedToken": "Fehler beim Laden des Power BI Berichts",
  "error.loadPowerBIReports": "Fehler beim Laden von Power BI Berichten",
  "error.loadPreRegistration": "Vorerfassung konnte nicht geladen werden.",
  "error.loadProfile": "Profil konnte nicht geladen werden",
  "error.loadProfitAndLoss": "G&V konnte nicht geladen werden",
  "error.loadProjects": "Projekte konnten nicht geladen werden",
  "error.loadProjectsCompanies": "Unternehmen für Projekte konnten nicht geladen werden",
  "error.loadPromotions": "Artikel konnten nicht geladen werden, versuchen Sie es später erneut",
  "error.loadPublications": "Publikationen konnten nicht geladen werden, versuchen Sie es später erneut",
  "error.loadRecord": "Beleg konnte nicht geladen werden",
  "error.loadRecords": "Belege konnten nicht geladen werden",
  "error.loadRecordTypes": "Fehler beim Laden der Buchungstypen",
  "error.loadReports": "Reports konnten nicht geladen werden",
  "error.loadResponsibleUsers": "Berater konnten nicht geladen werden",
  "error.loadRolePermissions": "Fehler beim Laden der Rollen-Berechtigungen",
  "error.loadSubsidiaries": "Fehler beim Laden der Buchungskreise",
  "error.loadSubsidiary": "Fehler beim Laden des Buchungskreises",
  "error.loadTeam": "Teammitglieder konnten nicht geladen werden",
  "error.loadTermsofUse": "Beim Laden der Nutzungsbedingungen ist ein Fehler aufgetreten",
  "error.loadTicketDetails": "Rückfrage konnte nicht geladen werden",
  "error.loadTickets": "Rückfragen konnten nicht geladen werden",
  "error.loadUserCompanySettings": "Fehler beim Laden der User Einstellungen",
  "error.loadUsers": "Benutzer konnten nicht geladen werden",
  "error.loadVendorListing": "Kreditorenliste konnte nicht geladen werden",
  "error.loadWaitingUsers": "Fehler beim Laden freizugebender User",
  "error.loginAccountSetup": "Zum ersten Mal hier? Wir bereiten Ihr Benutzerkonto vor. Versuchen Sie es bitte in einigen Minuten erneut.",
  "error.logout": "Fehler beim Abmelden",
  "error.markAsDone": "Sie können nur Rückfragen als erledigt markieren, die Sie erstellt haben",
  "error.markBankAccountTransactionAsPrivate": "Transaktion konnte nicht als Privat markiert werden",
  "error.maxCharacters": "Maximal {count} Zeichen erlaubt",
  "error.mfaActivation.codeNotMatched": "Eingegebener Code ist falsch",
  "error.mfaCodeAlreadySent": "Ein Code wurde bereits verschickt, bitte warten Sie mindestens 20 Sekunden bis Sie den nächsten anfordern.",
  "error.mfaCodeWrong": "Der eingegebene Code ist leider falsch. Bitte probieren Sie es erneut.",
  "error.mfaSettingChange": "2FA kann nicht aktiviert oder deaktiviert werden, da bereits ein Code für die Aktivierung angefordert wurde. Bitte warten Sie mindestens 20 Sekunden bevor Sie es erneut probieren.",
  "error.mfaSettingChange.codeAlreadySent": "Ein neuer Code wurde bereits angefordert. Bitte warten Sie mindestens 20 Sekunden bis Sie es erneut versuchen.",
  "error.mfaSettingChange.codeNotMatched": "Eingegebener Code ist falsch",
  "error.microsoftLogin": "Ein Problem bei der Kommunikation mit Microsoft ist aufgetreten. Bitte versuchen Sie sich nochmals einzuloggen.",
  "error.missingDocument": "Dokument fehlt",
  "error.missingEmail": "Fehlende E-Mail Adresse",
  "error.missingEmployee": "Bitte DienstnehmerIn auswählen",
  "error.missingParams": "Fehlende Parameter",
  "error.move": "Fehler beim Verschieben",
  "error.move.alreadyExists.unknown": "Fehler beim Verschieben: Ein Ordner oder eine Datei mit dieser Bezeichnung existiert bereits",
  "error.msTokenExpired": "Ihr MS Token ist abgelaufen. Bitte erneuern Sie dieses.",
  "error.multipleArchives": "mehrere Mandantenarchive für dieses Unternehmen vorhanden",
  "error.noArchive": "Kein Mandantenarchiv für dieses Unternehmen vorhanden",
  "error.noCompanySelected": "Kein Unternehmen ausgewählt",
  "error.noDocumentSelected": "Bitte wählen Sie ein Dokument für die Nutzungsbedingungen aus",
  "error.noDotAtEnd": "Letztes Zeichen darf kein Punkt sein",
  "error.noFinancialAccountancies": "Unternehmen hat keine FIBUs",
  "error.noMsAccountAvailable": "User kann nicht eingeladen werden. Es ist erwartet, dass für TPA Mitarbeiterinnen bereits ein Microsoft-Account existiert.",
  "error.noRecordTypes": "Keine Belegkategorien gefunden",
  "error.noSignees": "Bitte fügen Sie E-Mail Adressen hinzu",
  "error.noSubsidiaries.accounting": "Keine Buchungskreise gefunden",
  "error.noSubsidiaries.hr": "Keine Abrechnungskreise gefunden",
  "error.noSubsidiaryWithEmployeeRead": "Kein Abrechnungskreis mit Mitarbeiter-Leserechten.",
  "error.notAdvisor": "Keine Beraterberechtigungen",
  "error.notAllowed": "Aktion darf nicht durchgeführt werden",
  "error.notificationCenter.load": "Benachrichtigungen konnten nicht geladen werden",
  "error.notificationCenter.markAllAsRead": "Benachrichtigungen konnten nicht als gelesen markiert werden",
  "error.notificationCenter.markAsRead": "Benachrichtigung konnte nicht als gelesen markiert werden",
  "error.oauthApi": "Es gab ein Problem bei der Authentifizierung. Bitte versuchen Sie es erneut.",
  "error.oauthCompany": "Kein ausgewähltes Unternehmen. Bitte wählen Sie ein Unternehmen aus und versuchen Sie es erneut.",
  "error.oauthUrl": "Es gab ein Problem beim Aufruf von Zahlen und Fakten. Bitte versuchen Sie es erneut.",
  "error.overlappingSignature": "Überlappende Signatur. Bitte wählen Sie eine neue Position",
  "error.passwordReset.tokenExpired": "Dieser Password Reset Link ist abgelaufen",
  "error.passwordReset.tokenNotFound": "Ungültiger Passwort Reset Link",
  "error.payTaxAccount": "Fehler beim Bezahlen der Steuerschuld",
  "error.payUnpaidAccount": "Fehler beim Bezahlen einer TPA Rechnung",
  "error.periodClosed": "Periode bereits abgeschlossen",
  "error.permission.corruptRoles": "Ungültige Rollen",
  "error.permission.onlyOneRoleAllowed": "Nur eine Rolle darf gesetzt werden",
  "error.postTicket": "Rückfrage konnte nicht erstellt werden.",
  "error.projectForbidden": "Sie haben keinen Zugriff auf Projekte",
  "error.projectIsEmpty": "Das Projekt ist leer und kann daher nicht heruntergeladen werden",
  "error.projectPermissions": "Projekt-Berechtigungen konnten nicht geladen werden",
  "error.projects.cantMove": "Die Dateien konnten nicht verschoben werden. Bitte versuchen Sie es später erneut.",
  "error.projects.cantPaste": "Die Dateien konnten nicht eingefügt werden. Bitte versuchen Sie es später erneut.",
  "error.projects.openBookmarkUrlFailed": "Die URL konnte nicht geöffnet werden",
  "error.qes.missingSignaturePositions": "Bitte fügen Sie die erforderlichen Signaturpositionen hinzu",
  "error.recordForbidden": "Sie haben keinen Zugriff auf den Beleg",
  "error.recordWithNotAllowedCompany": "Keine Berechtigung für Firma, der das Dokument zugewiesen ist.",
  "error.refreshBankConnection": "Bankverbindung konnte nicht erneuert werden",
  "error.releaseEmployeeDocument": "Fehler bei der Freigabe",
  "error.releaseReport": "Fehler bei der Freigabe",
  "error.removeBankAccountTransactionPrivateMark": "Konnte Markierung nicht aufheben",
  "error.removeCompanyFromUserFavorites": "Unternehmen konnte nicht von Ihren Favoriten entfernt werden",
  "error.rename.alreadyExists.file": "Fehler beim Umbenennen: Eine Datei mit dieser Bezeichnung existiert bereits",
  "error.rename.alreadyExists.folder": "Fehler beim Umbenennen: Ein Ordner mit dieser Bezeichnung existiert bereits",
  "error.requiredField": "Eingabe erforderlich",
  "error.resendTermsOfUseEmail": "Beim erneuten Senden der E-Mail ist ein Fehler aufgetreten",
  "error.save": "Fehler beim Speichern",
  "error.saveKpiConfigurations": "Zahlen und Fakten Konfiguration konnte nicht gespeichert werden",
  "error.saveOptionalMenuItems": "Optionale Menüpunkte konnte nicht gespeichert werden. Bitte versuchen Sie es später erneut",
  "error.saveTermsOfUse": "Beim Speichern der Nutzungsbedingungen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut",
  "error.send": "Fehler beim Senden.",
  "error.setDocumentOrSignees": "Beim Speichern des Dokuments oder der zu unterzeichnenden Personen ist ein Fehler aufgetreten",
  "error.settings": "Fehler beim Ändern der Einstellungen",
  "error.signFileTooLarge": "Die Datei ist zu groß und kann nicht elektronisch signiert werden.",
  "error.signReadOnlyDocument": "Das Dokument ist schreibgeschützt und kann daher nicht signiert werden",
  "error.signTermsOfUse": "Beim Akzeptieren der Nutzungsbedingungen ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmal",
  "error.subsidiaryWithoutRecordTypes": "{subsidiary} hat keine aktiven Belegssymbole",
  "error.termsOfUseDuplicateFile": "Selbes File mit einem anderen Namen existiert bereits",
  "error.termsOfUseFolderNotFound": "Es wurde noch kein Ordner mit Nutzungsbedingungen angelegt",
  "error.timeout": "Dieser Vorgang dauert momentan etwas länger. Bitte versuchen Sie es später erneut.",
  "error.toggleCompanyFeature": "Fehler beim Aktivieren/Deaktivieren des Add-Ons",
  "error.tokenRefresh": "Authentifizierungsfehler",
  "error.tooManyCharacters": "Maximal {maxCharacters} Zeichen pro Nachricht erlaubt.",
  "error.transfer": "Fehler beim Transfer",
  "error.transferEmployee": "Mitarbeiter konnte nicht übermittelt werden",
  "error.transferEmployeeDirect": "Die Übermittlung an die Krankenkasse ist fehlgeschlagen. Bitte kontaktieren Sie Ihren Berater.",
  "error.unknown": "Unbekannter Fehler ({statusCode})",
  "error.unsupportedDocumentType": "Dieser Dokumenttyp wird nicht unterstützt",
  "error.updateBankAccountAccountNumber": "Das Konto konnte nicht gespeichert werden.",
  "error.upgradeToMicrosoft": "Upgrade konnte nicht durchgeführt werden. Bitte überprüfen Sie ihr Passwort.",
  "error.upload": "Fehler beim Upload",
  "error.userDeactivated": "Dieser Microsoft User wurde deaktiviert.",
  "error.userNotRegistered": "Benutzer ist nicht registriert",
  "error.userUnknown": "Unbekannter Benutzer",
  "externalLinkDialog.content": "Falls es nicht automatisch geöffnet wurde <a href=\"{url}\" target=\"_blank\" rel=\"noopener noreferrer\">klicken Sie hier.</a>",
  "externalLinkDialog.title": "Neues Fenster oder Registerkarte wird geöffnet",
  "failedUserDialog.amount": "<b>{failedUserCount}</b> von {userCount} Benutzern konnten nicht angelegt/geändert werden:",
  "failedUserDialog.button.text.continue": "Trotzdem fortfahren",
  "failedUserDialog.button.text.retry": "Erneut versuchen",
  "failedUserDialog.error.emailAlreadyExists": "E-Mail Adresse bereits in Verwendung",
  "failedUserDialog.error.pendingPermissionChanges": "Wartet bereits auf Freigabe",
  "failedUserDialog.message": "Fehler beim Anlegen/Ändern",
  "filterDialog.apply": "Filter anwenden",
  "filterDialog.category.advanced": "Erweiterte Filter",
  "filterDialog.category.assigned": "Zugeordneter Bereich",
  "filterDialog.header": "Filteroptionen",
  "filterDialog.required": "Bitte einen Wert auswählen",
  "filterDialog.reset": "FILTER ZURÜCKSETZEN",
  "finishProjectDialog.text": "Sind Sie sicher, dass Sie das Projekt <b>{projectName}</b> abschließen möchten?",
  "finishProjectDialog.title": "Projekt abschließen",
  "footer.copyright": "All rights reserved",
  "footer.disclaimer": "Disclaimer",
  "footer.generalBusinessTerms": "AGB",
  "footer.legalInformation": "Impressum",
  "footer.privacy": "Datenschutz",
  "helpcenter.ideas": "Ideen oder Wünsche",
  "helpcenter.problems": "Probleme melden",
  "helpcenter.problems.email": "E-Mail Adresse",
  "helpcenter.problems.send": "Problem melden",
  "helpcenter.problems.upload.failed": "Das Problem konnte nicht übermittelt werden",
  "helpcenter.problems.upload.success": "Das Problem wurde erfolgreich übermittelt",
  "helpcenter.problems.whathappened": "Was ist stattdessen passiert?",
  "helpcenter.problems.whatshouldhappen": "Was hätte passieren sollen?",
  "helpcenter.problems.whatwasdone": "Was wurde versucht zu tun?",
  "helpCenter.title": "Hilfezentrum",
  "helpcenter.videos": "Hilfevideos",
  "helpcenter.videos.tpaonly": "Hilfevideos TPA-Mitarbeiter:innen",
  "hr.casualEmployment": "Fallweise beschäftigt",
  "hr.disclaimer.additionalData": "Für die direkte Übermittlung benötigen wir noch ein paar zusätzliche Daten. Bitte füllen Sie diese noch aus.",
  "hr.disclaimer.employmentDate": "Datum der Beschäftigung",
  "hr.disclaimer.subtitle": "Wollen Sie die Daten an TPA oder direkt an die Krankenkasse übermitteln?",
  "hr.disclaimer.title": "Übermittlung",
  "hr.disclaimer.transfer.direct": "Die Datenübermittlung soll direkt an die österreichische Gesundheitskasse erfolgen",
  "hr.disclaimer.transfer.direct.subtitle": "Wir nehmen zur Kenntnis, dass TPA die Daten erst in den nächsten Tagen prüft, frühestens wenn das Büro geöffnet ist.",
  "hr.disclaimer.transfer.tpa": "Die Datenübermittlung erfolgt an die TPA",
  "hr.disclaimer.transfer.tpa.subtitle": "Wir nehmen zur Kenntnis, dass die Mitarbeiteranmeldung bei der österreichischen Gesundheitskasse erst erfolgt, wenn TPA die Daten verarbeitet hat.",
  "hr.documents.backLabel": "Personal/Dateien",
  "hr.documents.emptyState.header": " erfassen",
  "hr.documents.emptyState.message": "Beginnen Sie jetzt mit dem Hochladen Ihrer Dateien.",
  "hr.documents.list.backLabel": "Personal/ Dokumente",
  "hr.documents.nonWorkingTimes": "Nichtleistungszeiten",
  "hr.documents.nonWorkingTimesUpload": "Dateiupload Nichtleistungszeiten",
  "hr.documents.upload.navHeader": "{documentType}",
  "hr.documents.upload.navLabel": "Personal/Dokumente",
  "hr.employee.employment": ". Dienstverhältnis",
  "hr.employee.employmentRelationship": "Beschäftigungsverhältnis",
  "hr.employee.jobDescription": "Berufsbezeichnung",
  "hr.employee.payslip.upload.date": "Zeitraum",
  "hr.employee.payslip.upload.title": "Lohnzettel hochladen",
  "hr.employee.payslip.upload.type": "Lohnzettel Typ",
  "hr.employee.payslip.upload.type.monthly": "Monatlicher Lohnzettel",
  "hr.employee.payslip.upload.type.yearly": "Jahreslohnzettel",
  "hr.employee.reasonForLeaving": "Austrittsgrund",
  "hr.employee.workplaceState": "Bundesland der Arbeitsstätte",
  "hr.finished.analysis": "Monatliche Auswertungen",
  "hr.finished.billing": "Monatliche Abrechnungen",
  "hr.finished.globalAnalysis": "Unternehmensweite Auswertung",
  "hr.finished.globalBilling": "Unternehmensweite Abrechnung",
  "hr.insuranceCarrier": "Versicherungsträger",
  "hr.overview.continuousActions": "Fortlaufende Funktionen",
  "hr.recordTypes.names.benefitsInKindCar": "Sachbezüge PKW",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "Vertragsanpassungen & Sonderzahlungen",
  "hr.recordTypes.names.mealDeductions": "Essensabzüge",
  "hr.recordTypes.names.nonWorkingTimes": "Nichtleistungszeiten",
  "hr.recordTypes.names.overtimeAndBonusPayments": "Überstunden- und Prämienauszahlungen",
  "hr.recordTypes.names.supplementaryDocuments": "Ergänzende Unterlagen",
  "hr.recordTypes.names.timeRecords": "Zeitaufzeichnungen",
  "hr.recordTypes.names.travelExpenseReport": "Reisekostenabrechnung",
  "hr.tickets.preRegistration.NavBar": "Rückfrage zu {preRegistration}",
  "hr.tickets.preRegistration.NavBarLabel": "Personal / DienstnehmerInnen / Rückfragen",
  "hr.tickets.preRegistration.toOverview": "ZUR PERSONALAKTE",
  "imageCrop.description": "Ziehen, um das Bild zu verschieben",
  "imageCrop.title": "Bild positionieren",
  "imageCrop.zoom": "Zoom",
  "language.german": "Deutsch",
  "maritalStatus.divorced": "geschieden",
  "maritalStatus.livingInPartnership": "in Partnerschaft lebend",
  "maritalStatus.married": "verheiratet/eingetragene Partnerschaft",
  "maritalStatus.separated": "getrennt lebend",
  "maritalStatus.unknown": "unbekannt",
  "maritalStatus.unmarried": "ledig",
  "maritalStatus.widowed": "verwitwet",
  "menu.answer": "Beantworten",
  "menu.assign": "Zuweisen",
  "menu.cancelRelease": "Freigabe zurückziehen",
  "menu.delete": "Löschen",
  "menu.details": "Details",
  "menu.download": "Herunterladen",
  "menu.draft": "Freigabe anfordern",
  "menu.edit": "Bearbeiten",
  "menu.editRelease": "Freigabe neu anfragen",
  "menu.finish": "Abschließen",
  "menu.markAsDone": "Als erledigt markieren",
  "menu.move": "Verschieben",
  "menu.openInBrowser": "In Browser öffnen",
  "menu.openInSharepoint": "In Sharepoint öffnen",
  "menu.rename": "Umbenennen",
  "menu.requestRelease": "Freigabe anfragen",
  "menu.showReleases": "Freigaben anzeigen",
  "menu.view": "Anzeigen",
  "mfa.activation.dialog.code.required": "Bitte geben Sie den Code ein",
  "mfa.activation.dialog.description": "Bitte geben Sie den Code ein, den wir an Ihre Telefonnummer gesendet haben, um die Zweifaktor-Authentifizierung zu aktivieren.",
  "mfa.activation.dialog.title": "2FA aktivieren",
  "mfa.activation.success.dialog.title": "2FA wurde erfolgreich aktiviert",
  "mfa.changeSetting.dialog.code.field.label": "Code",
  "mfa.changeSetting.dialog.resendCode": "Code erneut senden",
  "mfa.codeResend.success.dialog.title": "Code wurde erneut versendet",
  "mfa.deactivation.dialog.description": "Bitte geben Sie den Code ein, den wir an Ihre Telefonnummer gesendet haben, um die Zweifaktor-Authentifizierung zu deaktivieren.",
  "mfa.deactivation.dialog.title": "2FA deaktivieren",
  "mfa.deactivation.success.dialog.title": "2FA wurde erfolgreich deaktiviert",
  "mfa.validation.code.length": "Der Code hat 6 Stellen",
  "mfa.validation.code.required": "Code muss eingegeben werden",
  "mfa.validation.code.type": "Es sind nur Zahlen erlaubt",
  "microsoft_oauth.button.login.title": "Mit Microsoft anmelden",
  "microsoft_oauth.button.send_code.title": "Code abschicken",
  "microsoft_oauth.button.upgrade.title": "Upgrade durchführen",
  "missingEmailDialog.description": "Die E-Mail Adresse von <b>{name}</b> ist nicht hinterlegt. Bitte geben Sie eine gültige E-Mail Adresse ein, um die Einladung verschicken zu können.",
  "missingEmailDialog.title": "E-Mail Adresse fehlt",
  "missingEmail.email.error": "Bitte geben Sie eine korrekte E-Mail Adresse ein",
  "missingEmail.email.label": "E-Mail Adresse eingeben",
  "module.finished.globalReports": "Unternehmensweite Reports",
  "modules.delete.report.message": "Wollen Sie den Report {name} wirklich löschen?",
  "modules.delete.report.title": "Report löschen",
  "navbar.week": "KW {week} {year}",
  "newUsername.checkbox.error": "Bitte bestätigen Sie Ihre neue E-Mail Adresse",
  "notificationCenter.emptyState": "Keine Benachrichtigungen",
  "notificationCenter.filter.accounting": "Buchhaltung",
  "notificationCenter.filter.appNotification:maintenance": "Wartungsnachrichten",
  "notificationCenter.filter.appNotification:newFeature": "Neue Features",
  "notificationCenter.filter.appNotification:update": "Aktualisierungen",
  "notificationCenter.filter.companySpecific": "Unternehmensspezifisch",
  "notificationCenter.filter.employeeDocument": "DienstnehmerInnen Dokumente",
  "notificationCenter.filter.file": "Dokumente",
  "notificationCenter.filter.general": "Allgemein",
  "notificationCenter.filter.generalDocument": "Allgemeine Dateien",
  "notificationCenter.filter.hr": "Personalverrechnung",
  "notificationCenter.filter.invoice": "Belege",
  "notificationCenter.filter.login": "Login",
  "notificationCenter.filter.module": "Modul",
  "notificationCenter.filter.notificationType": "Benachrichtigungstyp",
  "notificationCenter.filter.other": "Sonstige",
  "notificationCenter.filter.payslip": "Lohnzettel",
  "notificationCenter.filter.project": "Projekte",
  "notificationCenter.filter.report": "Reports",
  "notificationCenter.filter.ticket": "Rückfrage",
  "notificationCenter.filter.unread": "Nur ungelesene",
  "notificationCenter.markAllAsRead": "Alle als gelesen markieren",
  "notificationCenter.title": "Benachrichtigungszentrum",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "Sie haben <b>{count} neue Dokumente</b> in Ihrem Personalakt die Ihre Freigabe erfordern.",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "Sie haben <b>ein neues Dokument</b> in Ihrem Personalakt das Ihre Freigabe erfordert.",
  "openEmployeeDocumentReleasesDialog.submit": "Anzeigen und freigeben",
  "openEmployeeDocumentReleasesDialog.title": "Dokumente freigeben",
  "pdfView.error.caption": "Das Dokument Konnte nicht geladen werden, bitte versuchen Sie es erneut",
  "pendingUserCreationDialog.message": "Das Anlegen von Benutzern in Sharepoint kann bis zu 30 Minuten dauern. Bitte um etwas Geduld, bis die Berechtigungen angelegt wurden.",
  "pendingUserCreationDialog.title": "Benutzer werden angelegt",
  "permission.accounting.banking": "Bankverbindungen",
  "permission.accounting.global.reports": "Unternehmensweite Reports der Buchhaltung",
  "permission.accounting.global.results": "Unternehmensweite Auswertungen der Buchhaltung",
  "permission.accounting.incaseoflaw": "In Case Of Law",
  "permission.accounting.records": "Bekanntgabe der Buchhaltung",
  "permission.accounting.reports": "Reports der Buchhaltung",
  "permission.expenses.cockpit": "Auswertungen im Cockpit",
  "permission.expenses.kpi": "Verwendung des Zahlen und Fakten Moduls",
  "permission.hr.employeePayrollAccount": "Dienstnehmerlohnkonto einsehen",
  "permission.hr.employerPayrollAccount": "Dienstgeberlohnkonto",
  "permission.hr.employerPayrollAccount.details": "Lohnart-Details einsehen",
  "permission.hr.global.reports": "Unternehmensweite Reports der Personalverrechnung",
  "permission.hr.global.results": "Unternehmensweite Auswertungen der Personalverrechnung",
  "permission.hr.personnel": "DienstnehmerInnen",
  "permission.hr.records": "Bekanntgabe der Personalverrechnung",
  "permission.hr.reports": "Reports der Personalverrechnung",
  "permission.option.canAccess": "kann zugreifen",
  "permission.option.canEdit": "kann bearbeiten und löschen",
  "permission.option.canEdit.all": "kann alles bearbeiten/freigeben",
  "permission.option.canEdit.none": "kann nichts bearbeiten/freigeben",
  "permission.option.canEdit.some": "kann teilweise bearbeiten/freigeben",
  "permission.option.canPay": "kann bezahlen",
  "permission.option.canRead": "kann einsehen",
  "permission.option.canRead.all": "kann alles einsehen",
  "permission.option.canRead.none": "kann nichts einsehen",
  "permission.option.canRead.some": "kann teilweise einsehen",
  "permission.option.canRelease": "kann freigeben",
  "permission.option.canUpdate": "kann bearbeiten",
  "permission.option.canUpload": "kann hochladen",
  "permission.option.mixed": "gemischt",
  "permission.option.none": "keine Berechtigung",
  "permission.projects": "Verwendung des Projektmoduls",
  "permission.settings.company": "Unternehmenseinstellungen einsehen",
  "permission.settings.features": "Änderung von Add-Ons",
  "permission.settings.images": "Änderung von Coverbild & Logo",
  "permission.settings.user": "Änderungen von Benutzerrechten vorschlagen",
  "permission.taxAccount.payment": "Steuerschuld bezahlen",
  "permission.unpaidAccount.payment": "Offene Rechnungen bezahlen",
  "project.invitation": "Projekteinladung",
  "projectItemPermission.owner": "Vollzugriff",
  "projectItemPermission.owner.description": "Alle Rechte zum Bearbeiten von Ordnern & Dateien. Bearbeiten und Abschließen des Projekts sowie Verwaltung von Mitgliedern und Berechtigungen.",
  "projectItemPermission.read": "Lesen",
  "projectItemPermission.read.description": "Anzeigen von Ordnern und Dateien und Herunterladen von Dateien",
  "projectItemPermission.readNoDownload": "Nur lesen",
  "projectItemPermission.write": "Bearbeiten",
  "projectItemPermission.write.description": "Anzeigen, Erstellen und Umbenennen von Ordnern. Anzeigen, Hochladen, Bearbeiten und Löschen von Dateien. ",
  "projects.activate": "WIEDERHERSTELLEN",
  "projects.active.emptystate.buttonlabel": "Projekt erstellen",
  "projects.active.emptystate.message": "Für dieses Unternehmen ist noch kein Projekt vorhanden",
  "projects.addCompanies": "Unternehmen hinzufügen",
  "projects.addCompanies.tooltip": "Das Projekt in mehreren Unternehmen erstellen",
  "projects.addFolder": "Ordner erstellen",
  "projects.addMembers": "Mitglieder hinzufügen",
  "projects.addMembers.bulk.success.title": "Mitglieder erfolgreich hinzugefügt",
  "projects.bulkDeleteDialog.message": "Sind Sie sicher, dass Sie die gewählten Dateien und Ordner löschen möchten?",
  "projects.bulkDeleteDialog.title": "Dateien und Ordner löschen",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "Zurückziehen",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "Sind Sie sicher, dass Sie die offene Freigabe zu <b>{fileName}</b> zurückziehen möchten?",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "Bestehende Freigabe zurückziehen?",
  "projects.closed.emptystate.message": "Für dieses Unternehmen ist noch kein abgeschlossenes Projekt vorhanden",
  "projects.companies.emptyState.title": "Keine Unternehmen vorhanden",
  "projects.companies.submit": "Hinzufügen",
  "projects.copySuccessDialog.message": "Die ausgewählten Dateien wurden in Ihre Zwischenablage kopiert",
  "projects.copySuccessDialog.title": "In Zwischenablage kopiert",
  "projects.create.bulk.backToProjects": "Zurück zu Projekte",
  "projects.create.bulk.description": "Die Projekte werden erstellt. Dies kann einige Minuten dauern, bitte das Browserfenster nicht schließen.",
  "projects.create.bulk.start": "Starten",
  "projects.deleted.emptystate.message": "Für dieses Unternehmen ist noch kein gelöschtes Projekt vorhanden",
  "projects.deleteDialog.message.file": "Sind Sie sicher, dass Sie die Datei <b>{name}</b> löschen möchten?",
  "projects.deleteDialog.message.folder": "Sind Sie sicher, dass Sie den Ordner <b>{name}</b> löschen möchten?",
  "projects.deleteDialog.message.project": "Sind Sie sicher, dass Sie das Projekt <b>{name}</b> löschen möchten?",
  "projects.deleteDialog.title.file": "Datei löschen",
  "projects.deleteDialog.title.folder": "Ordner löschen",
  "projects.deleteDialog.title.project": "Projekt löschen",
  "projects.emptystate.title": "Keine Projekte vorhanden",
  "projects.externalId": "Referenznummer:",
  "projects.finish.successDialog.title": "Projekt erfolgreich abgeschlossen",
  "projects.folder.emptystate.message": "Beginnen Sie jetzt mit dem Befüllen des Ordners.",
  "projects.folder.emptystate.title": "Inhalte erstellen & hochladen",
  "projects.importUsers.add": "Hinzufügen",
  "projects.importUsers.replace": "Ersetzen",
  "projects.importUsers.switch": "Mitglieder von einem anderen Projekt importieren",
  "projects.international": "Internationales Projekt",
  "projects.international.activate": "Um ein Projekt in ein internationales umzuwandeln, müssen Sie diese Funktionalität freischalten.",
  "projects.international.confirmButton": "Projekt umwandeln",
  "projects.international.disabled.refresh": "Ihr internationale MS Login ist abgelaufen, bitte erneuern Sie dieses. Danach können Sie \"{project}\" in ein internationales Projekt umwandeln.",
  "projects.international.disabled.unlock": "Um ein Projekt in ein internationales umzuwandeln, müssen Sie diese Funktionalität in der Projektübersicht freischalten.",
  "projects.international.info.message": "Die Umwandlung eines Projekts in ein internationales Projekt ermöglicht es TPA-Mitarbeiter:innen anderer Länder zum Projekt einzuladen, um grenzüberschreitend mit der Kundin bzw. dem Kunden arbeiten zu können. Hierfür werden sämtliche Dateien und Dokumente, die als Teil des Projekts auf Microsoft SharePoint gespeichert sind, zwischen unterschiedlichen Microsoft-Tenants übertragen und im Zuge dessen sämtliche Benutzerberechtigungen für die Mitarbeit am Projekt neuaufgebaut. Dabei können etwaige feingranularen Berechtigungen verloren gehen. Es wird daher empfohlen die Zugriffsmöglichkeiten nach der Umwandlung in ein internationales Projekt zu überprüfen. Dieser Schritt kann nicht widerrufen werden.",
  "projects.international.info.subtitle": "Bitte beachten Sie folgendes:",
  "projects.international.invite.message": "Sie wurden zu neuen internationalen Projekten hinzugefügt. Schalten Sie folgende Länder einmalig frei, um Projekte dieser Länder einsehen zu können.",
  "projects.international.invite.title": "Neue Länder freischalten",
  "projects.international.migrating": "Internationales Projekt (Umwandlung in Arbeit)",
  "projects.international.migrating.message": "Das Projekt wird gerade in ein internationales Projekt umgewandelt. Eine Bearbeitung ist erst nach Abschluss der Umwandlung wieder möglich.",
  "projects.international.plural": "Internationale Projekte",
  "projects.international.switch": "Dieses Projekt in ein internationales Projekt umwandeln",
  "projects.international.undoWarning.message": "Sind Sie sicher, dass Sie dieses Projekt in ein internationales Projekt umwandeln möchten? Sämtliche Projektdaten werden auf den Sharepoint von TPA AT kopiert. Diese Aktion kann nach dem Erstellen des Projekts nicht mehr rückgängig gemacht werden.",
  "projects.international.undoWarning.message.at": "Sind Sie sicher, dass Sie dieses Projekt in ein internationales Projekt umwandeln möchten? Diese Aktion kann nach dem Erstellen des Projekts nicht mehr rückgängig gemacht werden.",
  "projects.international.undoWarning.title": "Diese Aktion kann nicht rückgängig gemacht werden",
  "projects.invite.international": "Sie wurden zu internationalen Projekten hinzugefügt. Schalten Sie diese jetzt frei.",
  "projects.invite.message": "Sie wurden zu Projekten aus {numCountries} Ländern hinzugefügt. Schalten Sie diese jetzt frei.",
  "projects.invite.message.singular": "Sie wurden zu Projekten aus {country} hinzugefügt. Schalten Sie diese jetzt frei.",
  "projects.members.assignPermission": "Berechtigung vergeben",
  "projects.membersIncluded.multi": "Einschließlich {names} und {name}.",
  "projects.membersIncluded.single": "Einschließlich {name}.",
  "projects.moveConfirmationDialog.message": "Verschobene Dateien werden im Ursprungsordner gelöscht!",
  "projects.moveConfirmationDialog.title": "Dateien Verschieben?",
  "projects.moveSuccessDialog.message": "Die Dateien wurden erfolgreich verschoben",
  "projects.moveSuccessDialog.title": "Dateien verschoben",
  "projects.navbar.activeProjects": "Aktiv",
  "projects.navbar.deletedProjects": "Gelöscht",
  "projects.navbar.fileUpload": "Dateiupload",
  "projects.navbar.finishedProjects": "Abgeschlossen",
  "projects.navbar.heading": "Projekte",
  "projects.navbar.notConfiguredProjects": "Nicht konfiguriert",
  "projects.navbar.overview": "Übersicht{path}",
  "projects.navbar.project.fileTree": "Ordnerstruktur",
  "projects.navbar.project.heading": "Projekt: {projectName}",
  "projects.navbar.project.releases": "Freigaben",
  "projects.navbar.releases.heading": "Freigaben zu {fileName}",
  "projects.navbar.ticket.heading": "Rückfrage zu {fileName}",
  "projects.new": "Neues Projekt",
  "projects.new.createNewProject": "Projekt erstellen",
  "projects.new.externalId": "Referenznummer",
  "projects.new.name": "Projektname",
  "projects.new.permissionContextMenu.remove": "Entfernen",
  "projects.new.permissions.text": "Fügen Sie neue Mitglieder hinzu und vergeben Sie Benutzerrechte.",
  "projects.new.title": "Projekt benennen",
  "projects.new.userSearch.placeholder": "Mitglied einladen...",
  "projects.notConfigured.emptystate.message": "Für dieses Unternehmen ist noch kein Projekt zum Konfigurieren vorhanden",
  "projects.openReleases.plural": "Offene Freigaben",
  "projects.openReleases.singular": "Offene Freigabe",
  "projects.pasteProgressDialog.title": "Dateien werden eingefügt",
  "projects.pasteSuccessDialog.message": "Die Dateien wurden erfolgreich eingefügt",
  "projects.pasteSuccessDialog.title": "Dateien eingefügt",
  "projects.refreshDialog.message": "Microsoft Logins für folgende Länder sind abgelaufen. Bitte erneuern Sie diese.",
  "projects.refreshDialog.title": "Microsoft Logins abgelaufen",
  "projects.refresh.message": "Microsoft Logins für {numCountries} Länder sind abgelaufen. Bitte erneuern Sie diese.",
  "projects.refresh.message.singular": "Ihr Microsoft Login für {country} ist abgelaufen. Bitte erneuern Sie dieses.",
  "projects.releaseConfirmationDialog.message": "Bitte beachten Sie folgende Anmerkung von {name}:",
  "projects.releaseConfirmationDialog.title": "Dokument freigeben",
  "projects.releases.emptystate.message": "Sie haben aktuell keine Freigaben.",
  "projects.releases.emptystate.title": "Keine Freigaben vorhanden",
  "projects.releases.reject": "Ablehnen",
  "projects.releases.status.canceled": "zurückgezogen",
  "projects.releases.status.canceledFrom": "zurückgezogen von {name} am {date}",
  "projects.releases.status.canceled.tooltip": "zurückgezogen am {performedAt} von {name}",
  "projects.releases.status.open": "warten auf Freigabe",
  "projects.releases.status.openFrom": "warten auf Freigabe von {name}",
  "projects.releases.status.open.tooltip": "warten auf Freigabe (Frist: {dueDate}) von {name}",
  "projects.releases.status.rejected": "abgelehnt",
  "projects.releases.status.rejectedFrom": "abgelehnt von {name} am {date}",
  "projects.releases.status.rejected.tooltip": "abgelehnt am {performedAt} von {name}",
  "projects.releases.status.released": "freigegeben",
  "projects.releases.status.releasedFrom": "freigegeben von {name} am {date}",
  "projects.releases.status.released.tooltip": "freigegeben am {performedAt} von {name}",
  "projects.releaseSuccessDialog.message": "<b>{name}</b> wird nun die Dateien sichten und sich ggf. mit Rückfragen bei Ihnen melden.",
  "projects.releaseSuccessDialog.multi.message": "Die zugeteilten Personen werden nun die Dateien sichten und sich ggf. mit Rückfragen bei Ihnen melden.",
  "projects.releaseSuccessDialog.title": "Freigabe erfolgreich angefragt",
  "projects.requestReleaseConfirmationDialog.confirm": "Zurückziehen und neu erstellen",
  "projects.requestReleaseConfirmationDialog.message": "Es besteht bereits eine offene Freigabe zu <b>{fileName}</b>. Um eine Neue Freigabe zu erstellen, muss die bestehende Freigabe zurückgezogen werden.",
  "projects.requestReleaseConfirmationDialog.multi.message": "Es bestehen bereits offene Freigaben in Ihrer Auswahl. Um neue Freigaben zu erstellen, müssen die bestehenden Freigaben zurückgezogen werden.",
  "projects.requestReleaseConfirmationDialog.multi.title": "Bestehende Freigaben zurückziehen?",
  "projects.requestReleaseConfirmationDialog.title": "Bestehende Freigabe zurückziehen?",
  "projects.restoreDialog.message.project": "Sind Sie sicher, dass Sie das Projekt <b>{name}</b> aktivieren möchten?",
  "projects.restoreDialog.title.project": "Projekt aktivieren",
  "projects.statusClosed": "Abgeschlossen am",
  "projects.statusClosed.personalized": "Abgeschlossen von {name} am",
  "projects.statusDeleted": "Gelöscht am",
  "projects.statusDeleted.personalized": "Gelöscht von {name} am",
  "projects.statusInProgress": "Zuletzt geändert am",
  "projects.statusInProgress.personalized": "Zuletzt geändert von {name} am",
  "projects.tickets.plural": "Rückfragen",
  "projects.tickets.singular": "Rückfrage",
  "records.details.upload.header": "Erfassung {recordType}",
  "recordType.statusClosed": "Übermittelt am",
  "recordType.statusInProgress": "Bearbeitet am",
  "recordType.statusOpen": "Unbearbeitet",
  "renameDialog.button.text.rename": "Umbenennen",
  "renameDialog.label.document": "Bezeichnung",
  "renameDialog.label.file": "Dateibezeichnung",
  "renameDialog.label.folder": "Ordnerbezeichnung",
  "renameDialog.title.document": "Dokument umbenennen",
  "renameDialog.title.file": "Datei umbenennen",
  "renameDialog.title.folder": "Ordner umbenennen",
  "renameProjectItemDialog.button.text.rename": "Umbenennen",
  "renameProjectItemDialog.itemName.file": "Dateibezeichnung",
  "renameProjectItemDialog.itemName.folder": "Ordnerbezeichnung",
  "renameProjectItemDialog.title.file": "Datei umbenennen",
  "renameProjectItemDialog.title.folder": "Ordner umbenennen",
  "renameRecordDialog.button.text.rename": "Umbenennen",
  "renameRecordDialog.name": "Bezeichnung",
  "renameRecordDialog.title": "Dokument umbenennen",
  "requestReleaseProjectItemDialog.button.text.request": "Anfragen",
  "requestReleaseProjectItemDialog.info": "Wann brauche ich eine qualifizierte Signatur?",
  "requestReleaseProjectItemDialog.label.comment": "Kommentar",
  "requestReleaseProjectItemDialog.label.dueDate": "Frist",
  "requestReleaseProjectItemDialog.label.requiresQes": "Freigabe per elektronischer Signatur (nur PDF-Dateien)",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "Namen eingeben...",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "Alle Personen müssen freigeben",
  "requestReleaseProjectItemDialog.qes.collective.title": "Kollektive Freigabe",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "Nur eine Person muss freigeben",
  "requestReleaseProjectItemDialog.qes.single.title": "Einzelfreigabe",
  "requestReleaseProjectItemDialog.searchForUsers.error": "Bitte wählen Sie einen Namen aus der Liste",
  "requestReleaseProjectItemDialog.text": "Wählen Sie die Personen aus, welchen Sie die Freigabe zu den aufgelisteten Dokumenten zuweisen möchten und geben Sie eine Frist an.",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "Weitere Person hinzufügen",
  "requestReleaseProjectItemDialog.title.create": "Freigabe anfragen",
  "requestReleaseProjectItemDialog.title.edit": "Freigabe bearbeiten",
  "results.accountSheet.backLabel.accountSheet": "Kontenblatt {accountNr} \"{name}\"",
  "results.accountSheet.backLabel.results.accountListing": "Saldenliste",
  "results.accountSheet.backLabel.results.balanceSheet": "Bilanz",
  "results.accountSheet.backLabel.results.cashAccounting": "Einnahmen-Ausgaben-Rechnung",
  "results.accountSheet.backLabel.results.customerListing": "Debitorenliste",
  "results.accountSheet.backLabel.results.profitAndLoss": "G&V",
  "results.accountSheet.backLabel.results.vendorListing": "Kreditorenliste",
  "results.accountSheet.columns.amount": "Betrag",
  "results.accountSheet.columns.documentDate": "Belegdatum",
  "results.accountSheet.columns.documentNumber": "Belegnr.",
  "results.accountSheet.columns.documents": "Belege",
  "results.accountSheet.columns.offsettingAccounts": "Gegenkonten",
  "results.accountSheet.columns.openItemAmount": "Offener Betrag",
  "results.accountSheet.columns.postingSymbol": "BS",
  "results.accountSheet.columns.postingText": "Buchungstext",
  "results.accountSheet.details.costCenter": "Kostenstelle",
  "results.accountSheet.details.discount": "Skonto",
  "results.accountSheet.details.externalDocumentNumber": "Externe Dokumentennr.",
  "results.accountSheet.details.foreignCurrency": "Fremdwährung / Kurs",
  "results.accountSheet.details.period": "Periode",
  "results.accountSheet.details.serialNumber": "Journalnr.",
  "results.accountSheet.details.tax": "Steuer",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "Ausfuhrlieferungen",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "Bauleistungen mit VSt",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "Bauleistungen ohne VSt",
  "results.accountSheet.details.taxCode.EUSt": "EUSt",
  "results.accountSheet.details.taxCode.igEMitVSt": "igE mit VSt",
  "results.accountSheet.details.taxCode.igEOhneVst": "igE ohne VSt",
  "results.accountSheet.details.taxCode.igLieferung": "ig Lieferung",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "Kleinunternehmer",
  "results.accountSheet.details.taxCode.RCMitVSt": "RC mit VSt",
  "results.accountSheet.details.taxCode.RCOhneVSt": "RC ohne VSt",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "sonstige Leistungen EU (ZM-pflichtig)",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "teilabzugsfähige VSt",
  "results.accountSheet.details.taxCode.Ust": "USt",
  "results.accountSheet.details.taxCode.VSt": "VSt",
  "results.accountSheet.details.taxPercent": "Steuer-Prozent",
  "results.accountSheet.details.vatPeriod": "UVA-Periode",
  "results.accountSheet.documents.downloadAll": "Alle Dateien herunterladen",
  "results.accountSheet.filters.amount": "Betrag",
  "results.accountSheet.filters.documentDate": "Belegdatum",
  "results.accountSheet.filters.postingSymbol": "Buchungssymbol",
  "results.accountSheet.noResults.message": "Für das ausgewählte Konto konnten keine Posten gefunden werden",
  "results.accountSheet.noResults.open.message": "Für das ausgewählte Konto konnten keine offenen Posten gefunden werden",
  "results.accountSheet.noResults.open.title": "Keine offenen Posten gefunden",
  "results.accountSheet.noResults.title": "Keine Posten gefunden",
  "results.accountSheet.offsettingAccounts.more": "{count} weitere",
  "results.accountSheet.paid": "bezahlt",
  "results.accountSheet.search.count": "Buchungen: <b>{count}</b>",
  "results.accountSheet.search.placeholder": "nach Buchungstext suchen...",
  "results.accountSheet.sum.title": "Σ Summe",
  "results.accountSheet.tabs.all": "Alle Posten",
  "results.accountSheet.tabs.open": "Nur offene Posten",
  "results.accountSheet.title": "Kontenblatt für Konto {accountNr} \"{name}\"",
  "results.columns.changeAbsolute": "Abw.",
  "results.columns.changePercentage": "Abw. (%)",
  "results.columns.credit": "Haben",
  "results.columns.debit": "Soll",
  "results.common.accounts": "Konten",
  "results.common.quickExpand.expandAccounts": "Alle (inkl. Konten)",
  "results.common.quickExpand.expandNthDepth": "Ebene {depth}",
  "results.common.quickExpand.expandRoot": "Hauptgliederungsebene",
  "results.common.quickExpand.expandRows": "Zeilen ausklappen:",
  "results.disclaimer": "Wichtiger Hinweis",
  "results.disclaimerMessage": "Die dargestellten Auswertungen beruhen für Zeiträume, für die die Jahresabschlussarbeiten noch nicht abgeschlossen sind, auf Daten aus der laufenden Saldenliste auf dem Buchungsstand zum jeweils dargestellten Stichtag. Die Auswertungen wurden aus den uns übergebenen Belegen ohne materielle Prüfung derselben erstellt und bietet dem Auftraggeber zeitnah zusätzliche Informationen über die laufende Geschäftsentwicklung. Insbesondere Abgrenzungen, Rückstellungsveränderungen, Bestandsveränderungen, Forderungsbewertungen und Abschreibungen wurden in Wirtschaftsjahren, für die die Jahresabschlussarbeiten noch nicht abgeschlossen sind, nur insoweit berücksichtigt, als dies mit dem Auftraggeber vereinbart ist. Dadurch kommt es zu Abweichungen zwischen den dargestellten Auswertungen und dem endgültigen Jahresabschluss.\n\nEine Haftung für die Richtigkeit und Vollständigkeit der Auswertungen gegenüber dem Auftraggeber und gegenüber Dritten wird daher nicht übernommen. Im Übrigen gelten für unsere Aufträge und Verantwortlichkeit, auch im Verhältnis zu Dritten, die Allgemeinen Auftragsbedingungen für Wirtschaftstreuhandberufe (AAB).",
  "results.navbar.buttons.accountListing": "Saldenliste",
  "results.navbar.buttons.balanceSheet": "Bilanz",
  "results.navbar.buttons.cashAccounting": "Einnahmen-Ausgaben-Rechnung",
  "results.navbar.buttons.customerListing": "Debitorenliste",
  "results.navbar.buttons.payrollAccount": "Lohnkonto",
  "results.navbar.buttons.profitAndLoss": "G&V",
  "results.navbar.buttons.vendorListing": "Kreditorenliste",
  "results.noFinancialAccountancies.message": "Derzeit sind keine Auswertungen verfügbar.",
  "results.noFinancialAccountancies.title": "Keine Auswertungen",
  "results.payrollAccountSheet.dataTable.month": "Monat",
  "results.payrollAccountSheet.dataTable.number": "Nr.",
  "results.payrollAccountSheet.dataTable.payrollType": "Lohnart",
  "results.payrollAccountSheet.dataTable.total": "Gesamt",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "Gesamt Vorjahr",
  "results.payrollAccountSheet.noPermission.message": "Sie haben keine Berechtigung das Lohnkonto einzusehen.",
  "results.payrollAccountSheet.noPermission.title": "Keine Berechtigung",
  "results.payrollAccountSheet.noResults.details": "Dieses Lohnkonto enthält keine Details",
  "results.payrollAccountSheet.noResultsYear.message": "Keine Daten für das ausgewählte Jahr gefunden.",
  "results.payrollAccountSheet.noResultsYear.title": "Keine Daten gefunden",
  "results.settings.accountsVisibility.hideAccounts": "Konten ausblenden",
  "results.settings.accountsVisibility.showAccounts": "Konten einblenden",
  "results.settings.orderBy.accountNameAsc": "Kontoname aufsteigend",
  "results.settings.orderBy.accountNameDesc": "Kontoname absteigend",
  "results.settings.orderBy.accountNrAsc": "Kontonummer aufsteigend",
  "results.settings.orderBy.accountNrDesc": "Kontonummer absteigend",
  "results.settings.orderBy.amountAsc": "Betrag aufsteigend",
  "results.settings.orderBy.amountDesc": "Betrag absteigend",
  "results.settings.orderBy.title": "Sortieren nach: {orderBy}",
  "results.settings.previousYearRanges.fullYear": "Gesamtes Vorjahr anzeigen",
  "results.settings.previousYearRanges.partialYear": "Vorjahr auch nur bis {previousYearRangeUntil} berücksichtigen",
  "results.settings.variant.compactView": "Kompaktansicht",
  "results.settings.variant.currentAndPastYear": "Aktuelles und Vorjahr",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "Aktuelles und Vorjahr mit absoluter Veränderung",
  "results.settings.variant.currentAndPastYearPercentageChange": "Aktuelles und Vorjahr mit prozentualer Veränderung",
  "results.settings.variant.currentYear": "Aktuelles Jahr",
  "results.settings.variant.detailView": "Detailansicht",
  "results.title": "Auswertungen",
  "role.accountingAssistent": "Buchhaltungsassistenz",
  "role.accountingAssistent.description": "Zugriff auf Buchhaltung ohne Freigaberechte",
  "role.accountingManagement": "Finance-Management",
  "role.accountingManagement.description": "Zugriff auf Buchhaltung inklusive Freigaberechte",
  "role.admin": "Admin / Geschäftsführung",
  "role.admin.description": "Alle Zugriffsrechte auf alle Bereiche",
  "role.assistent": "Assistenz",
  "role.assistent.description": "Zugriff auf Buchhaltung und Personalverrechnung ohne Freigaberrechte<br />Kann Änderung der Benutzer vorschlagen",
  "role.custom": "Benutzerdefiniert",
  "role.custom.description": "Benutzerdefinierte Zugriffsrechte",
  "role.custom.jobTitle": "Mitarbeiter",
  "role.custom.jobTitle.female": "Mitarbeiterin",
  "role.hrAssistent": "Personalverrechnungsassistenz",
  "role.hrAssistent.description": "Zugriff auf Personalverrechnung ohne Freigaberechte",
  "role.hrManagement": "HR-Management",
  "role.hrManagement.description": "Zugriff auf Personalverrechnung inklusive Freigaberechte",
  "role.internationalProjects": "Projektmitarbeiter",
  "role.internationalProjects.female": "Projektmitarbeiterin",
  "role.legalAdvisor": "Rechtsberater:in",
  "role.noRole": "keine Rolle",
  "role.noRole.description": "keine Zugriffsrechte auf TPA Connect",
  "role.staff": "Dienstnehmer",
  "role.staff.description": "Erhält elektronische Verdienstnachweise",
  "role.staff.female": "Dienstnehmerin",
  "role.superAdmin": "Superadmin",
  "role.superAdmin.description": "Zugriff auf alle Bereiche inkl. User Management",
  "role.tpaAccounting": "Buchhaltung",
  "role.tpaAccounting.description": "Berater-Zugriff auf Buchhaltung",
  "role.tpaAdvisor": "Berater",
  "role.tpaAdvisor.description": "Berater-Zugriff auf alle Bereiche",
  "role.tpaAdvisor.female": "Beraterin",
  "role.tpaAnnualAccounts": "Jahresabschluss",
  "role.tpaAnnualAccounts.description": "Zuständig für Jahresabschluss",
  "role.tpaAuditor": "Audit",
  "role.tpaBusinessConsultant": "Unternehmensberatung",
  "role.tpaBusinessConsultant.description": "Zuständig für Unternehmensberatung",
  "role.tpaChat": "Ansprechpartner",
  "role.tpaChatSecret": "Ansprechpartner",
  "role.tpaHr": "Personalverrechnung",
  "role.tpaHr.description": "Berater-Zugriff auf Personalverrechnung",
  "role.tpaOther": "Projektmitarbeiter",
  "role.tpaOther.female": "Projektmitarbeiterin",
  "role.tpaPartner": "Partner",
  "role.tpaPartner.description": "Berater-Zugriff auf alle Bereiche",
  "role.tpaPartner.female": "Partnerin",
  "role.tpaPayrollAccountant": "Lohnverrechnung",
  "role.tpaReporting": "Reporting",
  "role.tpaReporting.description": "Zuständig für Reporting",
  "role.tpaSpecialAdvisor": "Sonderberatung",
  "role.tpaSpecialAdvisor.description": "Zuständig für Sonderberatung",
  "role.tpaTaxAccountant": "Steuerberatung",
  "role.tpaTaxAccountant.description": "Zuständig für Steuerberatung",
  "screen.accounting.navbar.bankConnections": "Bankverbindungen",
  "screen.accounting.navbar.heading": "Buchhaltung",
  "screen.accounting.navbar.overview": "Übersicht",
  "screen.accounting.navbar.records": "Belege",
  "screen.accounting.overview.bankConnection": "{salutation}, <br /> <br /> eine oder mehrere Ihrer Bankverbindungen laufen innerhalb dieser Buchungsperiode ab. Bitte erneuern Sie diese.<br /> <br /> Mit freundlichen Grüßen<br /> {signature}",
  "screen.accounting.overview.bankConnection.expired": "{salutation}, <br /> <br /> eine oder mehrere Ihrer Bankverbindungen sind abgelaufen. Bitte erneuern Sie diese.<br /> <br /> Mit freundlichen Grüßen<br /> {signature}",
  "screen.accounting.overview.bankConnection.expired.title": "Bankverbindungen abgelaufen",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "Bankverbindungen erneuern",
  "screen.accounting.overview.bankConnection.title": "Bankverbindungen laufen ab",
  "screen.accounting.overview.card.analysis": "Freigabe Reports für ",
  "screen.accounting.overview.card.globalAnalysis": "Freigabe unternehmensweiter Reports",
  "screen.accounting.overview.card.overview": "Bekanntgabe Buchhaltung für ",
  "screen.accounting.overview.finished.download": "Alle Dateien herunterladen",
  "screen.accounting.overview.finished.message": "{salutation}, <br /> <br /> Danke für die Kontrolle und Freigabe der Buchhaltung. Nachstehend finden Sie Ihre Reports.<br /> <br />Mit freundlichen Grüßen<br />{signature}",
  "screen.accounting.overview.reports": "{salutation}, <br /> <br /> Anbei übermitteln wir Ihnen folgende Unterlagen für {period}. Die Dokumente wurden aufgrund Ihrer Informationen und übermittelten Unterlagen erstellt. Sollten Sie in den Unterlagen irgendwelche Unrichtigkeiten oder Unvollständigkeiten erkennen, ersuchen wir um Mitteilung, damit wir entsprechende Berichtigungen vornehmen können. <br /> <br /> Für Rückfragen stehen wir Ihnen gerne zur Verfügung.<br /> <br />Mit freundlichen Grüßen<br />{signature}",
  "screen.accounting.overview.ticketsWelcome.inProgress": "{salutation}, <br /> <br />Danke für die Übermittlung der Belege. Im Zuge der Verbuchung haben sich einige Rückfragen ergeben:",
  "screen.accounting.overview.welcome.closed": "{salutation}, <br /> <br /> Vielen Dank für die Übermittlung aller relevanten Belege. Wir werden diese gemäß den vereinbarten Terminvorgaben verarbeiten und melden uns gegebenenfalls mit Rückfragen bei Ihnen.",
  "screen.accounting.overview.welcome.inProgress": "{salutation}, <br /> <br /> Wir ersuchen um Upload Ihrer Buchungsunterlagen für {period}{dueDate}. Übermitteln Sie uns dazu bitte alle erforderlichen Unterlagen. <br /> <br />Mit freundlichen Grüßen<br />{signature}",
  "screen.accounting.records.banner.selectedAll": "Alle {count} Dateien in “{recordType}” sind ausgewählt.",
  "screen.accounting.records.banner.selectedAll.transferFailed": "Alle {count} fehlgeschlagenen Dateien in “{recordType}” sind ausgewählt.",
  "screen.accounting.records.banner.selectedCount": "Alle {count} Dateien auf dieser Seite sind ausgewählt",
  "screen.accounting.records.banner.totalCount": "Alle {count} Dateien in “{recordType}” auswählen",
  "screen.accounting.records.banner.totalCount.transferFailed": "Alle {count} fehlgeschlagenen Dateien in “{recordType}” auswählen",
  "screen.accounting.records.banner.unselect": "Auswahl aufheben",
  "screen.accounting.records.documents": "Dateien:",
  "screen.accounting.records.empty": "Datei ist leer",
  "screen.accounting.records.filter.transferFailed": "Transfer fehlgeschlagen",
  "screen.accounting.records.format.error": "Format überprüfen",
  "screen.accounting.records.move.dialog.button.text": "Verschieben",
  "screen.accounting.records.move.multiple.dialog.title": "Belege verschieben",
  "screen.accounting.records.move.single.dialog.title": "Beleg verschieben",
  "screen.accounting.records.navbar.tab.list": "Belege ({count})",
  "screen.accounting.records.navbar.tab.tickets": "Rückfragen ({count})",
  "screen.accounting.records.person.responsible": "Zuständigkeit",
  "screen.accounting.records.serverUpload.error": "Datei konnte nicht auf den Server hochgeladen werden",
  "screen.accounting.records.serverUpload.typeError": "Format nicht akzeptiert",
  "screen.accounting.records.sizeAndFormat.error": "Dateigröße und Format überprüfen",
  "screen.accounting.records.size.error": "Dateigröße überprüfen",
  "screen.accounting.records.tickets": "| Rückfragen: {numTickets}",
  "screen.accounting.records.transfer.button.text": "An TPA übermitteln",
  "screen.accounting.records.transfer.dialog.text": "Möchten Sie <b>alle Dateien der Buchhaltung</b> für den Zeitraum {periodStart} - {periodEnd} an TPA zur Verbuchung übergeben?",
  "screen.accounting.records.transfer.dialog.textRecordType": "Möchten Sie <b>alle Dateien von \"{name}\"</b> für den Zeitraum {periodStart} - {periodEnd} an TPA zur Verbuchung übergeben?",
  "screen.accounting.records.transfer.dialog.title": "Buchhaltung übermitteln",
  "screen.accounting.records.transfer.retryDialog.button.text": "Erneut versuchen.",
  "screen.accounting.records.transfer.retryDialog.file": "Fehler bei der Übermittlung von {failedFile}",
  "screen.accounting.records.transfer.retryDialog.text": "Oups. Hier ging etwas schief. Bitte erneut versuchen.",
  "screen.accounting.records.transfer.retryDialog.title": "Übermittlung fehlgeschlagen",
  "screen.accounting.records.transfer.successDialog.text": "Ihr TPA Berater wird nun die Daten sichten und sich ggf. mit Rückfragen bei Ihnen melden.",
  "screen.accounting.records.transfer.successDialog.title": "Daten erfolgreich übermittelt",
  "screen.accounting.records.types.header": "Buchhaltung",
  "screen.accounting.records.unknown.error": "Unbekannter Fehler",
  "screen.accounting.records.upload.button": "Belege hochladen",
  "screen.accounting.records.upload.navbar.back": "Buchhaltung / Belege",
  "screen.accounting.recordTypes.title": "Art der Belege wählen",
  "screen.advisor.tabbar.active": "Aktiv",
  "screen.advisor.tabbar.notConfigured": "Nicht konfiguriert",
  "screen.advisor.upload.approve.info": "Wann brauche ich eine qualifizierte Signatur?",
  "screen.advisor.uploads.upload.approve": "Kundenfreigabe",
  "screen.advisor.uploads.upload.approve.option.click": "Freigabe per Klick",
  "screen.advisor.uploads.upload.approve.option.none": "Keine Kundenfreigabe",
  "screen.advisor.uploads.upload.approve.option.qes": "Freigabe per elektronischer Signatur",
  "screen.advisor.uploads.upload.buttonText": "Übermitteln",
  "screen.advisor.uploads.upload.category": "Kategorie",
  "screen.advisor.uploads.upload.category.validation": "Bitte wählen Sie eine Kategorie aus",
  "screen.advisor.uploads.upload.comment": "Übermittlungstext",
  "screen.advisor.uploads.upload.comment.downloadError": "Fehler beim Laden des Kommentars",
  "screen.advisor.uploads.upload.dueDate": "Fällig am",
  "screen.advisor.uploads.upload.dueDate.validation": "Bitte geben Sie einen Fälligkeitstermin an",
  "screen.advisor.uploads.upload.global": "Unternehmensweit",
  "screen.advisor.uploads.upload.module": "Bereich",
  "screen.advisor.uploads.upload.module.validation": "Bitte wählen Sie einen Bereich aus",
  "screen.advisor.uploads.upload.navBack": "Uploads",
  "screen.advisor.uploads.upload.navHeader": "Dateien hochladen: {category}",
  "screen.advisor.uploads.upload.notifyCustomer": "Kunden benachrichtigen",
  "screen.advisor.uploads.upload.subsidiary.validation": "Bitte wählen Sie einen Buchungskreis aus",
  "screen.advisor.uploads.upload.visibleToEmployee": "Freigabe für MA",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "Wenn wir einen Benutzer mit der E-Mail Adresse {email} gefunden haben, werden Sie in Kürze eine E-Mail mit einem Link zum Zurücksetzen Ihres Passwortes erhalten.",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "Keine Mail erhalten? Schauen Sie auch in Ihrem Spamordner nach oder kontaktieren Sie ihren Systemadmin.",
  "screen.auth.forgot_password_confirm.title": "Sie erhalten in Kürze eine E-Mail",
  "screen.auth.forgot_password_email.form.button": "Link zum Zurücksetzen senden",
  "screen.auth.forgot_password_email.form.label.email": "Benutzerkennung / E-Mail Adresse",
  "screen.auth.forgot_password_email.subtitle": "Geben Sie Ihre E-Mail Adresse an und wir senden Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passwortes.",
  "screen.auth.forgot_password_email.title": "Passwort zurücksetzen",
  "screen.auth.forgot_password_email.validation_error": "Bitte geben Sie Ihre Email Adresse ein",
  "screen.auth.forgot_password_reset.error.password_not_set": "Bitte geben Sie ein Passwort an",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "Passwörter stimmen nicht überein",
  "screen.auth.forgot_password_reset.error.password_weak": "Ihr Passwort ist noch zu schwach.",
  "screen.auth.forgot_password_reset.form.button": "Passwort ändern",
  "screen.auth.forgot_password_reset.form.button.mfa": "Code abschicken",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "Code erneut senden",
  "screen.auth.forgot_password_reset.form.mfa": "Code",
  "screen.auth.forgot_password_reset.form.password": "Passwort",
  "screen.auth.forgot_password_reset.form.repeat_password": "Passwort wiederholen",
  "screen.auth.forgot_password_reset.subtitle": "Setzen Sie ein neuen Passwort für ihr TPA-Connect-Konto.",
  "screen.auth.forgot_password_reset.subtitle.mfa": "Bitte geben Sie den Code ein, den wir Ihnen per SMS an Ihre Telefonnummer gesendet haben.",
  "screen.auth.forgot_password_reset.title": "Passwort ändern",
  "screen.auth.forgot_password_reset.title.mfa": "Passwortänderung mit Zweifaktorauthentifizierung bestätigen",
  "screen.auth.registration.form.button": "Registrieren",
  "screen.auth.registration.form.button.mfa": "Code abschicken",
  "screen.auth.registration.form.button.mfa.resendCode": "Code erneut senden",
  "screen.auth.registration.form.label.mfa": "Code",
  "screen.auth.registration.form.label.password": "Passwort",
  "screen.auth.registration.form.label.repeat_password": "Passwort wiederholen",
  "screen.auth.registration.form.section_1": "Verwenden Sie mindestens 8 Zeichen.",
  "screen.auth.registration.form.section_2": "Verwenden Sie Groß- und Kleinschreibung, Zahlen und Sonderzeichen, um Ihr Passwort stärker zu machen.",
  "screen.auth.registration.form.section_3": "Denken Sie sich ein Merksatz aus, um sich ihr Passwort leichter merken zu können.",
  "screen.auth.registration.mfa.subtitle": "Bitte geben Sie den Code ein, den wir Ihnen per SMS an Ihre Telefonnummer gesendet haben.",
  "screen.auth.registration.subtitle": "Setzen Sie ein Passwort für ihr TPA-Connect-Konto.",
  "screen.auth.registration.title": "Passwort setzen & registrieren",
  "screen.auth.welcome.subtitle": "Verknüpfen Sie Ihr TPA-Connect-Konto mit ihrem Microsoft-Konto, um von alle Features im vollen Umfang nutzen zu können. Sie können ihr Konto auch zu einem späteren Zeitpunkt verknüpfen.",
  "screen.auth.welcome.subtitle.msOnly": "Verknüpfen Sie Ihr TPA-Connect-Konto mit ihrem Microsoft-Konto, um von alle Features im vollen Umfang nutzen zu können.",
  "screen.auth.welcome.title": "Willkommen bei TPA Connect!",
  "screen.changeUsername.checkbox": "Hiermit bestätige ich meine neue E-Mail Adresse",
  "screen.changeUsername.message": "Das Rechnerherz der TPA Connect App hat bemerkt, dass sich Ihre Email-Adresse auf <b>{newUsername}</b> geändert hat. Damit wir uns auch ganz sicher sein können, dass diese Änderung korrekt ist, muss das unbedingt mit einem einfachen Klick bestätigt werden.",
  "screen.changeUsername.title": "Neue E-Mail Adresse",
  "screen.cockpit.allEvents": "Events: Alle Events",
  "screen.cockpit.allNews": "Neuigkeiten: Alle Artikel",
  "screen.cockpit.dueDates.export": "Fristen exportieren",
  "screen.cockpit.dueDates.navbar.heading": "Offene Fristen",
  "screen.cockpit.dueDates.projects.text": "Freigabe für {documentName}",
  "screen.cockpit.dueDates.records.accounting.text": "Bekanntgabe Buchhaltung für {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.records.hr.text": "Bekanntgabe Personalverrechnungsdaten für {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.reports.global.text": "Reportfreigabe für \"unternehmensweite Reports\" - {period}",
  "screen.cockpit.dueDates.reports.subsidiary.text": "Reportfreigabe für {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "Bitte verwenden Sie BMD NTCS um Reports bereitzustellen",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "Reportupload",
  "screen.cockpit.dueDates.reportUpload.global.text": "Reportupload für \"unternehmensweite Reports\" - {period}",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "Reportupload für {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.tickets.global.text": "Frist zur Rückfrage zum Dokument {documentName}",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "Frist zur Rückfrage zum Dokument {documentName} für {subsidiaryName}",
  "screen.cockpit.emptyState.message": "Kommen Sie später wieder vorbei",
  "screen.cockpit.emptyState.title": "Momentan gibt es keine Neuigkeiten",
  "screen.cockpit.eventDetail": "Event Detail",
  "screen.cockpit.eventDetails.heading": "Details & Anmeldung",
  "screen.cockpit.eventDetails.lecturer.email": "E: {email}",
  "screen.cockpit.eventDetails.lecturers": "Vortragende",
  "screen.cockpit.eventDetails.lecturer.telephone": "T: {telephone}",
  "screen.cockpit.eventDetails.register": "Jetzt anmelden",
  "screen.cockpit.event.moreInformation": "INFOS & ANMELDUNG",
  "screen.cockpit.kpis.add": "Kennzahl hinzufügen",
  "screen.cockpit.kpis.chart.legend": "in 1000 €",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "Krankenkasse",
  "screen.cockpit.kpis.configurationDialog.metric.label": "KPI",
  "screen.cockpit.kpis.configurationDialog.months.label": "Monate",
  "screen.cockpit.kpis.configurationDialog.title": "KPI Widget konfigurieren",
  "screen.cockpit.kpis.ebit.chart.name": "EBIT Verlauf",
  "screen.cockpit.kpis.ebit.kpi.name": "EBIT bisher",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "Zeigt den EBIT-Wert aller bereits vollständig gebuchten Monate seit Beginn des Geschäftsjahres.",
  "screen.cockpit.kpis.empty": "Ihre Unternehmenskennzahl \"{name}\" ist derzeit nicht verfügbar.",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "Saldo Krankenkasse",
  "screen.cockpit.kpis.noneConfigured": "Kennzahl hinzufügen",
  "screen.cockpit.kpis.revenue.chart.name": "Umsatzverlauf",
  "screen.cockpit.kpis.revenue.kpi.name": "Umsatz bisher",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "Zeigt den Umsatz aller bereits vollständig gebuchten Monate seit Beginn des Geschäftsjahres.",
  "screen.cockpit.kpis.save": "Änderungen übernehmen",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "Rückstand",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "Guthaben",
  "screen.cockpit.kpis.taxAccount.kpi.label": "Stand per {date}",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "[amount] zuletzt bezahlt am {date}",
  "screen.cockpit.kpis.taxAccount.kpi.name": "Saldo Steuerkonto",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "Bereits bezahlt am {date}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "{invoicesCount} Rechnungen",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "Stand per {date}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "Offene Rechnungen bei TPA",
  "screen.cockpit.navbar.heading": "Cockpit",
  "screen.cockpit.overview.kpis.title": "Zahlen und Fakten",
  "screen.cockpit.overview.newestPublications": "Neueste Publikationen",
  "screen.cockpit.overview.notifications.dueDates": "Offene Fristen",
  "screen.cockpit.overview.notifications.messages": "Nachrichten",
  "screen.cockpit.overview.notifications.tickets": "Rückfragen",
  "screen.cockpit.overview.notifications.title": "Benachrichtigungen",
  "screen.cockpit.overview.promotions.title": "Aktuelles",
  "screen.cockpit.overview.relevantNews.title": "Relevante Neuigkeiten",
  "screen.cockpit.overview.relevantPublications.description": "Sie können entweder eine gedruckte TPA Publikation anfordern, der Versand an Sie erfolgt dann innerhalb weniger Tage. Oder: Sie laden eine elektronische Version (PDF) der gewünschten TPA Publikation herunter.",
  "screen.cockpit.overview.relevantPublications.title": "Relevante Publikationen",
  "screen.cockpit.promotion": "Aktuelles: Artikel",
  "screen.cockpit.promotion.additionalNews": "Neuigkeiten",
  "screen.cockpit.relevantNews": "Neuigkeiten: Artikel",
  "screen.cockpit.relevantNews.additionalNews": "Weitere Neuigkeiten",
  "screen.cockpit.tickets.navbar.heading": "Offene Rückfragen",
  "screen.hr.additionaldata.addBonus": "weitere Zulage hinzufügen",
  "screen.hr.additionaldata.additionaldata": "Ergänzende Informationen",
  "screen.hr.additionaldata.additionalInformation": "Weiterführende Informationen",
  "screen.hr.additionaldata.amount": "Betrag in {currency} pro Monat",
  "screen.hr.additionaldata.amount_error": "Bitte geben Sie einen Betrag an",
  "screen.hr.additionaldata.bankDetails": "Bankverbindung",
  "screen.hr.additionaldata.bic": "BIC",
  "screen.hr.additionaldata.bic.validation": "Ungültiger BIC",
  "screen.hr.additionaldata.commuterAllowance": "Pendlerpauschale",
  "screen.hr.additionaldata.costCenter": "Kostenstelle",
  "screen.hr.additionaldata.costCenterNote": "Anmerkung zu Kostenstelle",
  "screen.hr.additionaldata.description": "Folgende Angaben können ggf. auch mit Dienstantritt vervollständigt werden.",
  "screen.hr.additionaldata.designator": "Zulage",
  "screen.hr.additionaldata.designator_error": "Bitte geben Sie eine Zulage an",
  "screen.hr.additionaldata.emailAddress": "E-Mail Adresse",
  "screen.hr.additionaldata.email_error": "Bitte geben Sie eine korrekte E-Mail Adresse ein",
  "screen.hr.additionaldata.familyBonusPlusAmount": "Fabo Plus Betrag in EUR",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "Bitte geben Sie mindestens 0 ein",
  "screen.hr.additionaldata.funder": "Kostenträger",
  "screen.hr.additionaldata.gross": "Brutto",
  "screen.hr.additionaldata.hours_error": "Bitte geben Sie eine Zahl zwischen {minHours} und {maxHours} ein",
  "screen.hr.additionaldata.iban": "IBAN",
  "screen.hr.additionaldata.iban_error": "Ungültiger IBAN",
  "screen.hr.additionaldata.jobDescription": "Berufsbezeichnung",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "Kilometer für Pendlereuro",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "Bitte geben Sie mindestens 0 ein",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "Kilometer dürfen nur in ganzen Zahlen angegeben werden",
  "screen.hr.additionaldata.maritalStatus": "Familienstand",
  "screen.hr.additionaldata.monthlySalary": "Gehalt in {currency} pro Monat",
  "screen.hr.additionaldata.monthlySalary_error": "Bitte geben Sie mindestens 0 ein",
  "screen.hr.additionaldata.net": "Netto",
  "screen.hr.additionaldata.nextPage": "Weiter zu Dokumente",
  "screen.hr.additionaldata.number_error": "Bitte geben Sie eine Zahl ein",
  "screen.hr.additionaldata.per": "pro",
  "screen.hr.additionaldata.personaldata": "Persönliche Angaben",
  "screen.hr.additionaldata.religiousConfession": "Religionsbekenntnis",
  "screen.hr.additionaldata.salarayAndPosition": "Entgelt und Position",
  "screen.hr.additionaldata.saveChanges": "Änderungen speichern",
  "screen.hr.additionaldata.soleEarnerStatus": "Alleinverdiener",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "Titel und akademische Grade",
  "screen.hr.additionaldata.titlePrefix": "Vorangestellt",
  "screen.hr.additionaldata.titleSuffix": "Nachstehend",
  "screen.hr.additionaldata.wageAgreementClassification": "Einstufung Kollektivvertrag",
  "screen.hr.additionaldata.weeklyWorkDays": "Anzahl der Arbeitstage pro Woche",
  "screen.hr.additionaldata.weeklyWorkDays_error": "Bitte wählen Sie einen gültigen Wert aus",
  "screen.hr.additionaldata.workingHours": "Stundenzahl",
  "screen.hr.additionaldata.workingHoursInterval": "Zeitraum",
  "screen.hr.additionaldata.workingTimes": "Arbeitszeiten",
  "screen.hr.cancelRegistration.dataSaved": "Wenn Sie abbrechen, bleiben die eingegebenen Daten gespeichert. Die Anmeldung kann zu einem späteren Zeitpunkt fortgesetzt werden.",
  "screen.hr.cancelRegistration.header": "Anmeldung abbrechen",
  "screen.hr.cancelRegistration.question1": "Sind Sie sich sicher, dass Sie den Anmeldevorgang abbrechen wollen? Sie können mit den bisher erfassten Daten ",
  "screen.hr.cancelRegistration.question2": " anlegen und zur Anmeldung an Ihren TPA Steuerberater übermitteln.",
  "screen.hr.deregistration.data.navback": "Personal / DienstnehmerInnen / Angemeldet / {firstname} {lastname}",
  "screen.hr.deregistration.documents.navback": "Personal / DienstnehmerInnen / Angemeldet / {firstname} {lastname} / Abmeldungsdaten",
  "screen.hr.deregistration.navheader": "{firstname} {lastname} abmelden",
  "screen.hr.deregistration.validation.deregistrationDate": "Bitte geben sie ein Austrittsdatum an",
  "screen.hr.deregistration.validation.deregistrationDateMin": "Das Austrittsdatum muss größer sein als das Eintrittsdatum",
  "screen.hr.deregistration.validation.deregistrationReason": "Bitte geben sie einen Austrittsgrund an",
  "screen.hr.deregistration.validation.remainingHolidays": "Bitte geben sie den Resturlaub an",
  "screen.hr.deregistration.validation.remainingOvertime": "Bitte geben sie die offenen Überstunden an",
  "screen.hr.documents.citizenship": "Staatsbürgerschafts&shy;nachweis",
  "screen.hr.documents.contract": "Dienst&shy;vertrag",
  "screen.hr.documents.description": "Laden Sie weiterführende Dokumente hoch, wie zum Beispiel: Dienstvertrag Personalausweis, Staatsbürgerschaftsnachweis, Meldezettel oder Sonstiges",
  "screen.hr.documents.emptystate.buttonlabel": "Dokumente hochladen",
  "screen.hr.documents.emptystate.message": "Für diese/n DienstehmerIn sind keine Dokumente vorhanden",
  "screen.hr.documents.emptystate.title": "Keine Dokumente vorhanden",
  "screen.hr.documents.enterManually": "Manuelle Dateneingabe",
  "screen.hr.documents.idcard": "Personal&shy;ausweis",
  "screen.hr.documents.logTime": "Zeiten erfassen",
  "screen.hr.documents.needsRelease.notReleased": "warten auf Freigabe",
  "screen.hr.documents.needsRelease.released": "freigegeben",
  "screen.hr.documents.needsRelease.released.tooltip": "freigegeben am {releasedAt} von {name}",
  "screen.hr.documents.nextButton": "Zur Zusammenfassung",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "Beginnen Sie jetzt mit dem Hochladen Ihrer Dateien, oder geben Sie die Nichtleistungszeiten manuell bekannt.",
  "screen.hr.documents.others": "Sonstiges",
  "screen.hr.documents.registrationslip": "Melde&shy;zettel",
  "screen.hr.documents.uploadDocuments": "Dokumente hochladen",
  "screen.hr.edit.flow.navback": "Personal / DienstnehmerInnen / TODO: Status / {firstName} {lastName}",
  "screen.hr.edit.flow.navheader": "{firstName} {lastName} bearbeiten",
  "screen.hr.employee.current.all": "Alle",
  "screen.hr.employee.current.anyDateOfJoining": "Beliebiges Eintrittsdatum",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "Beliebiges Ein-/Austrittsdatum",
  "screen.hr.employee.current.cancellation": "Abmeldungen",
  "screen.hr.employee.current.changeUsername": "Neue E-Mail",
  "screen.hr.employee.current.changeUsername.successDialog.message": "<b>{userName}</b> erhält eine Aufforderung die neu bekanntgegebene E-Mail Adresse zu bestätigen.",
  "screen.hr.employee.current.changeUsername.successDialog.title": "E-Mail für die Änderung der E-Mail Adresse wurde erfolgreich verschickt.",
  "screen.hr.employee.current.changeUsername.tooltip": "Neue E-Mail Adresse des/der MitarbeiterIn lautet {newUsername}. Durch Klick auf den Button wird der/die MitarbeiterIn aufgefordert, die neue E-Mail Adresse zu bestätigen.",
  "screen.hr.employee.current.dataChange": "Stammdatenänderungen",
  "screen.hr.employee.current.notTransferred": "Nicht übermittelt",
  "screen.hr.employee.current.registration": "Anmeldungen",
  "screen.hr.employee.deregistration.data.date": "Austrittsdatum",
  "screen.hr.employee.deregistration.data.header": "Abmeldungsdaten",
  "screen.hr.employee.deregistration.data.reason": "Austrittsgrund",
  "screen.hr.employee.deregistration.data.remainingHolidays": "Resturlaub in Tagen",
  "screen.hr.employee.deregistration.data.remainingOvertime": "Offene Überstunden",
  "screen.hr.employee.deregistration.data.subtitle": "Sofern diese vorhanden sind, laden Sie die Austrittsunterlagen für Endabrechnung hinauf.",
  "screen.hr.employee.deregistration.documents.submitButton": "Abmeldung übermitteln",
  "screen.hr.employee.deregistration.stepper.data": "Abmeldungsdaten",
  "screen.hr.employee.deregistration.stepper.documents": "Austrittsunterlagen",
  "screen.hr.employee.details.addFiles": "Dokumente hinzufügen",
  "screen.hr.employee.details.currentEmployment": "Aktuelles Dienstverhältnis",
  "screen.hr.employee.details.deregisterEmployee": "DienstnehmerIn abmelden",
  "screen.hr.employee.details.employments": "Dienstverhältnisse",
  "screen.hr.employee.details.fileProvidedBy": "bereitgestellt von {name}",
  "screen.hr.employee.details.fileReleased": "für Mitarbeiter:in einsehbar",
  "screen.hr.employee.details.linkedFiles": "Verknüpfte Dateien",
  "screen.hr.employee.details.navBack": "Personal / {list}",
  "screen.hr.employee.details.pastEmployments": "Vorherige Dienstverhältnisse",
  "screen.hr.employee.details.payrollAccount.navBack": "Personal / Aktuelle DN / {fullName}",
  "screen.hr.employee.details.personnelFile": "Personalakte",
  "screen.hr.employee.details.personnelNumber": "Personalnummer: ",
  "screen.hr.employee.details.records": "Verdienstnachweise",
  "screen.hr.employee.details.reregisterEmployee": "DienstnehmerIn erneut anmelden",
  "screen.hr.employee.details.showAll": "Alle anzeigen",
  "screen.hr.employee.details.showEmployeePayrollAccount": "Lohnkonto anzeigen",
  "screen.hr.employee.details.status": "Status",
  "screen.hr.employee.details.weeklyWorkDays": "{weeklyWorkDays} Tage pro Woche",
  "screen.hr.employee.details.workingHours": "{workingHours} Stunden pro {workingHoursInterval} ",
  "screen.hr.employee.documents.all": "Alle",
  "screen.hr.employee.documents.annualPayslips": "Jahreslohnzettel",
  "screen.hr.employee.documents.anyDate": "Beliebiger Zeitraum",
  "screen.hr.employee.documents.backTargetDescription": "Personal / DienstnehmerInnen / {status} / {firstName} {lastName}",
  "screen.hr.employee.documents.certificateOfCitizenship": "Staatsbürgerschaftsnachweis",
  "screen.hr.employee.documents.certificatesOfRegistration": "Meldebestätigungen",
  "screen.hr.employee.documents.contracts": "Dienstvertrag",
  "screen.hr.employee.documents.deregisterDocument": "Abmeldeunterlagen",
  "screen.hr.employee.documents.employeeDocuments": "Dateien von {firstName} {lastName}",
  "screen.hr.employee.documents.employmentPermit": "Arbeitsgenehmigung",
  "screen.hr.employee.documents.generalDocuments": "Sonstiges",
  "screen.hr.employee.documents.identityCard": "Personalausweis",
  "screen.hr.employee.documents.payslips": "Verdienstnachweise",
  "screen.hr.employee.emptystate.buttonlabel": "DienstnehmerInnen erfassen",
  "screen.hr.employee.emptystate.message": "Beginnen Sie jetzt mit dem Erfassen neuer DienstnehmerInnen",
  "screen.hr.employee.emptystate.title": "DienstnehmerInnen anmelden",
  "screen.hr.employee.former.anyDateOfLeaving": "Beliebiges Austrittsdatum",
  "screen.hr.employee.registration.stepper.additional": "Ergänzende Informationen",
  "screen.hr.employee.registration.stepper.documents": "Dokumente hochladen",
  "screen.hr.employee.registration.stepper.personal": "Personaldaten",
  "screen.hr.employee.registration.stepper.status": "Schritt {activeStep} von {totalSteps}",
  "screen.hr.employee.registration.stepper.summary": "Zusammenfassung",
  "screen.hr.employees.current.registerEmployee": "DienstnehmerIn anmelden",
  "screen.hr.employees.current.uploadPayslip": "Lohnzettel hochladen",
  "screen.hr.employees.documents.recordType.title": "Im {date} in Personalakte hochgeladene Dateien",
  "screen.hr.employees.documents.title": "Dateien in Personalakte",
  "screen.hr.navbar.documents": "Dateien",
  "screen.hr.navbar.employees": "DienstnehmerInnen",
  "screen.hr.navbar.heading": "Personal",
  "screen.hr.navbar.overview": "Übersicht",
  "screen.hr.navbar.tabbar.current": "Aktuelle DN",
  "screen.hr.navbar.tabbar.documents": "Dateien",
  "screen.hr.navbar.tabbar.employees": "DienstnehmerInnen",
  "screen.hr.navbar.tabbar.former": "Ausgeschiedene DN",
  "screen.hr.navbar.tabbar.inprogress": "In Bearbeitung",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "Rückfragen ({preRegistrationTicketCount})",
  "screen.hr.overview.card.analysis": "Freigabe monatlicher Auswertungen für ",
  "screen.hr.overview.card.billing": "Freigabe monatlicher Abrechnungen für ",
  "screen.hr.overview.card.globalAnalysis": "Freigabe unternehmensweiter Auswertungen",
  "screen.hr.overview.card.globalBilling": "Freigabe unternehmensweiter Abrechnungen",
  "screen.hr.overview.card.overview": "Bekanntgabe monatlicher Personaldaten für ",
  "screen.hr.overview.card.registration": "DienstnehmerInnen An-/Abmeldung",
  "screen.hr.overview.finished.message": "{salutation}, <br /> <br /> Danke für die Kontrolle und Freigabe der Personalverrechnung. Nachstehend finden Sie Ihre Reports.<br /> <br />Mit freundlichen Grüßen<br />{signature}",
  "screen.hr.overview.registration": "{salutation}, <br /> <br /> geben Sie bitte Änderungen der DienstnehmerInnen bekannt, damit wir die An- und Abmeldung durchführen können.<br /> <br /> Mit freundlichen Grüßen<br /> {signature}",
  "screen.hr.overview.registration.button": "DienstnehmerInnen an/abmelden",
  "screen.hr.overview.ticketsWelcome.inProgress": "{salutation}, <br /> <br />Danke für die Übermittlung der Personaldaten. Im Zuge der Verarbeitung haben sich einige Rückfragen ergeben:",
  "screen.hr.overview.welcome.closed": "{salutation}, <br /> <br /> Vielen Dank für die Übermittlung aller relevanten Personaldaten. Wir werden diese gemäß den vereinbarten Terminvorgaben verarbeiten und melden uns gegebenenfalls mit Rückfragen bei Ihnen.",
  "screen.hr.overview.welcome.inProgress": "{salutation}, <br /> <br /> Ich ersuche Sie, mir alle notwendigen Personaldaten für die Gehaltsabrechnung {period}{dueDate} zukommen zu lassen. <br><br>Mit freundlichen Grüßen<br>{signature}",
  "screen.hr.personaldata.address": "Straße",
  "screen.hr.personaldata.addressAddition": "Adresszusatz",
  "screen.hr.personaldata.addressHead": "Adresse",
  "screen.hr.personaldata.area.validation": "Bitte geben Sie einen Ort an",
  "screen.hr.personaldata.birthdate.validation": "Bitte geben Sie ein Geburtsdatum an",
  "screen.hr.personaldata.citizenship": "Staatsbürgerschaft",
  "screen.hr.personaldata.citizenship.validation": "Bitte geben Sie eine Staatsbürgerschaft an",
  "screen.hr.personaldata.city": "Ort",
  "screen.hr.personaldata.country": "Land",
  "screen.hr.personaldata.country.validation": "Bitte wählen Sie ein Land aus",
  "screen.hr.personaldata.dateOfBirth": "Geburtsdatum",
  "screen.hr.personaldata.dateOfJoining": "Eintrittsdatum",
  "screen.hr.personaldata.description": "Folgende Angaben sind für die Anmeldung vor Dienstantritt verpflichtend bekanntzugeben.",
  "screen.hr.personaldata.diverse": "Divers",
  "screen.hr.personaldata.employmentInsuranceType": "Versicherungsgruppe",
  "screen.hr.personaldata.employmentRelationship": "Beschäftigungsverhältnis",
  "screen.hr.personaldata.employmenttypeheader": "Art der Beschäftigung",
  "screen.hr.personaldata.employmenttypeinformationbody": "Alle monatlichen Brutto-Einkommen oberhalb der Geringfügigkeitsgrenze gelten als verpflichtend vollversichert.",
  "screen.hr.personaldata.employmenttypeinformationhead": "Vollversichert/Geringfügig:",
  "screen.hr.personaldata.employmenttype.validation": "Bitte wählen Sie ein Beschäftigungsverhältnis aus",
  "screen.hr.personaldata.entrydate.invalid": "Ungültiges Eintrittsdatum",
  "screen.hr.personaldata.entrydate.validation": "Bitte geben Sie ein Eintrittsdatum an",
  "screen.hr.personaldata.female": "Weiblich",
  "screen.hr.personaldata.firstname": "Vorname",
  "screen.hr.personaldata.firstname.validation": "Bitte geben Sie einen Vornamen an",
  "screen.hr.personaldata.fullTime": "Vollversichert",
  "screen.hr.personaldata.houseNumber": "Hausnummer",
  "screen.hr.personaldata.houseNumber.validation": "Bitte geben Sie eine Hausnummer an",
  "screen.hr.personaldata.insurancedata": "Versicherungsdaten",
  "screen.hr.personaldata.insurancedatainformationbody": "Wenn keine SV Nummer vorhanden ist, bitte das Feld Sozialversicherungsnummer freilassen.",
  "screen.hr.personaldata.insurancedatainformationhead": "Hinweis:",
  "screen.hr.personaldata.insurancetype.validation": "Bitte wählen Sie eine Versicherung an",
  "screen.hr.personaldata.lastname": "Nachname",
  "screen.hr.personaldata.lastname.validation": "Bitte geben Sie einen Nachnamen an",
  "screen.hr.personaldata.male": "Männlich",
  "screen.hr.personaldata.marginally": "Geringfügig",
  "screen.hr.personaldata.navback": "Personal / DienstnehmerInnen",
  "screen.hr.personaldata.navheader": "DienstnehmerInnen anmelden",
  "screen.hr.personaldata.nextpage": "Weiter zu ergänzenden Informationen",
  "screen.hr.personaldata.notSpecified": "keine Angabe",
  "screen.hr.personaldata.personaldata": "Personaldaten",
  "screen.hr.personaldata.personalinformation": "Persönliche Angaben",
  "screen.hr.personaldata.previouslyEmployed": "War diese Person innerhalb der letzten 12 Monate schon einmal bei Ihnen beschäftigt?",
  "screen.hr.personaldata.socialsecuritynumber.validation": "Bitte geben Sie eine Sozialversicherungsnummer an",
  "screen.hr.personaldata.ssn": "Sozialversicherungsnummer",
  "screen.hr.personaldata.state.validation": "Bitte geben Sie ein Bundesland an",
  "screen.hr.personaldata.streetnr.validation": "Bitte geben Sie eine Adresse an",
  "screen.hr.personaldata.workplaceState": "Bundesland der Arbeitsstätte",
  "screen.hr.personaldata.zipCode": "PLZ",
  "screen.hr.personaldata.zipcode.length.validation": "Die PLZ muss {count} Zeichen lang sein",
  "screen.hr.personaldata.zipcode.validation": "Bitte geben Sie eine PLZ an",
  "screen.hr.records.move.multiple.dialog.title": "Dateien verschieben",
  "screen.hr.records.move.single.dialog.title": "Datei verschieben",
  "screen.hr.records.navbar.tab.list": "Dateien ({count})",
  "screen.hr.records.navbar.tab.tickets": "Rückfragen ({count})",
  "screen.hr.records.transfer.dialog.text": "Möchten Sie <b>alle Dateien und Informationen der Personalverrechnung</b> für den Zeitraum {periodStart} - {periodEnd} an TPA zur Verbuchung übergeben?",
  "screen.hr.records.transfer.dialog.textRecordType": "Möchten Sie <b>alle Dateien und Informationen von \"{name}\"</b> für den Zeitraum {periodStart} - {periodEnd} an TPA zur Verbuchung übergeben?",
  "screen.hr.records.transfer.dialog.title": "Dateien übermitteln",
  "screen.hr.records.types.header": "Personal",
  "screen.hr.records.upload.button": "Dateien hochladen",
  "screen.hr.records.upload.navbar.back": "Personal / Dateien",
  "screen.hr.recordTypes.title": "Art der Dokumente wählen",
  "screen.hr.registration.flow.navback": "Schritt zurück",
  "screen.hr.registration.flow.navheader": "{firstName} {lastName} anmelden",
  "screen.hr.summary.additionalinformation": "Ergänzende Angaben",
  "screen.hr.summary.bonuses": "Zulagen",
  "screen.hr.summary.changeData": "Nachtragen",
  "screen.hr.summary.completeAdditionalData": "Sie haben alle ergänzenden Angaben angegeben.",
  "screen.hr.summary.completePersonalData": "Sie haben alle verpflichtenden Angaben zur Anmeldung bei der Gebietskrankenkasse eingetragen.",
  "screen.hr.summary.day": "Tag",
  "screen.hr.summary.daysPerWeek": "Tage pro Woche",
  "screen.hr.summary.description": "Überprüfen Sie, ob die angegebenen Informationen korrekt sind, um sie im nächsten Schritt an die TPA zu übermitteln.",
  "screen.hr.summary.dialogQuestion1": "Wollen Sie ",
  "screen.hr.summary.dialogQuestion2": " anlegen und zur Anmeldung an Ihren TPA Steuerberater übermitteln?",
  "screen.hr.summary.documents": "Dateien",
  "screen.hr.summary.gender": "Geschlecht",
  "screen.hr.summary.grossSalary": "Bruttogehalt in {currency}",
  "screen.hr.summary.hours": "Stunden",
  "screen.hr.summary.incompleteAdditionalData": "Sie haben noch nicht alle ergänzenden Angaben eingetragen. Beachten Sie dass einige der ergänzenden Daten womöglich bis zur ersten Lohnauszahlung erforderlich sind. Diese können Sie jederzeit nachtragen.",
  "screen.hr.summary.month": "Monat",
  "screen.hr.summary.name": "{firstName} {lastName}",
  "screen.hr.summary.netSalary": "Nettogehalt in {currency}",
  "screen.hr.summary.noPreregistration": "Es wurden keine Stammdaten geändert. Eine Datenübermittlung ist nicht notwendig.",
  "screen.hr.summary.openDialog": "Anmeldung übermitteln",
  "screen.hr.summary.summary": "Zusammenfassung",
  "screen.hr.summary.transferDataChange": "Stammdatenänderung übermitteln",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": "{name} wurde angelegt und zur Anmeldung an Ihren TPA Steuerberater übermittelt.",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "{name} wurde angelegt. Unten können Sie das ELDA Protokoll einsehen und herunterladen. Alternativ kann das Protokoll im Personalakt der/des Dienstnehmerin/Dienstnehmers heruntergeladen werden.",
  "screen.hr.summary.week": "Woche",
  "screen.login.button.forgot_password": "PASSWORT VERGESSEN?",
  "screen.login.button.resendCode": "Code erneut senden",
  "screen.login.form.divider": "oder",
  "screen.login.form.multiFactorAuth.label": "Code",
  "screen.login.form.multiFactorAuth.validation_error": "Bitte geben Sie den Code ein.",
  "screen.login.form.password.label": "Passwort",
  "screen.login.form.password.validation_error": "Bitte geben Sie ein Passwort ein",
  "screen.login.form.submit": "Anmelden",
  "screen.login.form.title": "Anmelden",
  "screen.login.form.username.label": "Benutzerkennung / E-Mail Adresse",
  "screen.login.form.username.validation_error": "Bitte geben Sie Ihre Benutzerkennung ein",
  "screen.login.multiFactorAuth": "Bitte geben Sie den Code ein, welcher von uns per SMS an Ihre Telefonnummer versendet wurde.",
  "screen.login.welcome": "Willkommen! Bitte geben Sie Ihre E-Mail-Adresse ein.",
  "screen.module.overview.report.empty.message": "Es wurden noch keine Reports hochgeladen",
  "screen.module.overview.report.empty.title": "Keine Reports vorhanden",
  "screen.module.overview.report.upload": "Report hochladen",
  "screen.moduleReportUpload.comment": "Übermittlungstext",
  "screen.moduleReportUpload.title": "Reports hochladen",
  "screen.not_found.button.text": "vom Cockpit aus starten",
  "screen.not_found.header": "404 Error",
  "screen.not_found.message": "Wir sind richtig gut mit Zahlen, aber die wollten wir Ihnen eigentlich ersparen.",
  "screen.overview.salutation.female": "Sehr geehrte Frau {lastName}",
  "screen.overview.salutation.general": "Sehr geehrte Damen und Herren",
  "screen.overview.salutation.male": "Sehr geehrter Herr {lastName}",
  "screen.overview.salutation.notSpecified": "Guten Tag {firstName} {lastName}",
  "screen.records.upload.header": "Dateiupload {recordType}",
  "screen.records.upload.message": "Beginnen Sie jetzt mit dem Hochladen der ersten Dateien.",
  "screen.records.upload.title": "{recordType} hochladen",
  "screen.server_error.button.text": "Zum Cockpit",
  "screen.server_error.header": "505 Error",
  "screen.server_error.message": "Wir sind richtig gut mit Zahlen, aber die wollten wir Ihnen eigentlich ersparen.",
  "screen.termsNotAccepted.button": "Zurück zur Unternehmensübersicht",
  "screen.termsNotAccepted.buttonAcceptNow": "Jetzt akzeptieren",
  "screen.termsNotAccepted.header": "Akzeptieren der Nutzungsbedingungen ausständig",
  "screen.termsNotAccepted.message": "Die Nutzungsbedingungen Ihres Unternehmens wurden noch nicht von allen Zuständigen unterzeichnet. Bei etwaigen Fragen kontaktieren Sie bitte ihren TPA Berater.",
  "screen.termsOfUse.accept": "Unterzeichnen",
  "screen.termsOfUse.alreadySignedInfo": "Nutzungsbedingungen wurden entweder bereits akzeptiert oder ein Akzeptieren ist nicht mehr notwendig",
  "screen.termsOfUse.backToLogin": "Zurück zum Login",
  "screen.termsOfUse.checkbox": "Hiermit akzeptiere ich die Nutzungsbedingungen",
  "screen.termsOfUse.success.sign": "Nutzungsbedingungen erfolgreich akzeptiert",
  "screen.termsOfUse.title": "Nutzungsbedingungen akzeptieren",
  "screen.termsOfUseWithCompanyNames.title": "Nutzungsbedingungen für [count] Unternehmen akzeptieren",
  "screen.termsOfUseWithCompanyName.title": "Nutzungsbedingungen für {companyName} akzeptieren",
  "screen.tickets.navbar.tabbar.closed": "Erledigt",
  "screen.tickets.navbar.tabbar.open": "Offen",
  "screen.tickets.navbar.tabbar.reported": "Von mir gestellt",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "Dieser Microsoft-User ist bereits mit E-Mail-Adresse {existingUsername} registriert",
  "screen.upgradeToMicrosoft.feature.projects.text": "Bitte upgraden Sie Ihren Account, um Zugriff auf das Feature \"Projekte\" zu erhalten.",
  "screen.upgradeToMicrosoft.form.code.description": "Bitte geben Sie den Code ein, den wir Ihnen per SMS zugesendet haben, um mit dem Microsoft Upgrade fortzufahren.",
  "screen.upgradeToMicrosoft.form.code.label": "Code",
  "screen.upgradeToMicrosoft.form.password.label": "Passwort",
  "screen.upgradeToMicrosoft.form.password.validation_error": "Bitte geben Sie Ihr Passwort ein",
  "screen.upgradeToMicrosoft.needCompany": "Die Verknüpfung mit einem MS Konto kann nur durchgeführt werden, wenn ein Unternehmen ausgewählt ist.",
  "screen.upgradeToMicrosoft.resendButton.label": "Code erneut senden",
  "screen.upgradeToMicrosoft.title": "Microsoft-Konto verknüpfen",
  "search.caption.numCompanies": "Unternehmen: <b>{count}</b>",
  "search.caption.numDespositedPersons": "Hinterlegte Personen: <b>{count}</b>",
  "search.caption.numDocuments": "Dateien: <b>{count}</b>",
  "search.caption.numPersons": "Personen: <b>{count}</b>",
  "search.caption.numProjects": "Projekte: <b>{count}</b>",
  "search.caption.numResults": "Ergebnisse: <b>{count}</b>",
  "search.caption.numSelected": "Ausgewählte Einträge: <b>{count}</b>",
  "search.caption.numTickets": "Rückfragen: <b>{count}</b>",
  "search.caption.numUploads": "Uploads: <b>{count}</b>",
  "search.caption.numUsers": "Benutzer: <b>{count}</b>",
  "search.placeholder.companies": "Unternehmen suchen ...",
  "search.placeholder.projects": "nach Projekt suchen ...",
  "search.placeholder.search": "Suche ...",
  "search.placeholder.searchForActiveUsers": "aktive User nach Namen suchen...",
  "search.placeholder.searchForEmployees": "nach Namen suchen...",
  "search.placeholder.searchForEmployeesPersonalNumber": "nach Namen oder Personalnummern suchen...",
  "search.placeholder.searchForFileName": "nach Dateibezeichnung suchen...",
  "search.placeholder.searchForFiles": "nach Dateinamen suchen...",
  "search.placeholder.searchForUsers": "nach Namen suchen...",
  "search.placeholder.searchForUsersFileName": "nach Namen oder Dateibezeichnung suchen...",
  "selectQesPosition.message": "Wählen Sie eine Position für die Signatur aus und klicken Sie dann {button}.",
  "selectQesPosition.message.collective": "Wählen Sie eine Position für die Signatur für jede:n Unterzeichner:in aus und klicken Sie dann auf {button}. ",
  "selectQesPosition.title": "Signaturposition wählen",
  "settings.navbar.company": "Unternehmen",
  "settings.navbar.companyData": "Stammdaten",
  "settings.navbar.features": "Add-Ons",
  "settings.navbar.general": "Allgemein",
  "settings.navbar.optionalSideBarItems": "Optionale Menüpunkte",
  "settings.navbar.userManagement": "Benutzerverwaltung",
  "settings.notifications.activateAll": "ALLE AKTIVIEREN",
  "settings.notifications.assignments": "Zuweisungen",
  "settings.notifications.deactivateAll": "ALLE DEAKTIVIEREN",
  "settings.notifications.deadlineExceeded": "Fristüberschreitung",
  "settings.notifications.deadlineReminder": "Fristerinnerung",
  "settings.notifications.generalDocumentUpload": "Upload allgemeiner Dateien",
  "settings.notifications.label": "E-Mail-Benachrichtigungen wenn:",
  "settings.notifications.messages": "Nachrichten",
  "settings.notifications.modules.accounting": "Buchhaltung",
  "settings.notifications.modules.hr": "Personal",
  "settings.notifications.modules.other": "Andere Bereiche",
  "settings.notifications.project.items.uploaded": "Projekt Uploads",
  "settings.notifications.recordProvision": "Uploads des Kunden",
  "settings.notifications.release": "Freigaben",
  "settings.notifications.remindAfterHours.label": "Erinnerung nach {hours}h",
  "settings.notifications.reportProvision": "Reportbereitstellung",
  "settings.notifications.tickets": "Rückfragen",
  "settings.notifications.title": "Benachrichtigungen",
  "settings.personalData.email": "E-Mail Adresse",
  "settings.personalData.language": "Sprache",
  "settings.personalData.location": "Standort",
  "settings.personalData.microsoftAccount": "Microsoft-Konto",
  "settings.personalData.microsoftUpgrade.info": "Microsoft-Konto:<br /><br />Sie können Ihr TPA-Connect-Konto mit Ihrem Microsoft-Konto verknüpfen. Das ermöglicht Ihnen die Nutzung von allen Features von TPA Connect.<br /><br />Sobald Sie Ihr Konto mit Microsoft verknüpft haben, müssen Sie sich ab dem Zeitpunkt, statt über ihre E-Mail Adresse, mit ihrem Microsoft-Konto einloggen.",
  "settings.personalData.microsoftUpgrade.label": "UPGRADE",
  "settings.personalData.phoneNumber": "Telefonnummer",
  "settings.personalData.role": "Rolle",
  "settings.personalData.title": "Persönliche Daten",
  "settings.personalData.username": "Benutzername",
  "settings.userManagement.permissions.existingUser.navbar.back": "Benutzerverwaltung / Rolle zuweisen",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "Benutzerverwaltung / Rolle zuweisen / {employeeName}",
  "settings.userManagement.permissions.navbar.back": "Benutzerverwaltung / AnsprechpartnerInnen wählen / Rolle zuweisen",
  "settings.userManagement.permissions.navbar.backToUser": "Benutzerverwaltung / AnsprechpartnerInnen wählen / Rolle zuweisen / {employeeName}",
  "settings.userManagement.roles.navbar.back": "Benutzerverwaltung / AnsprechpartnerInnen wählen",
  "sidebar.advisor.employees": "Personalliste",
  "sidebar.advisor.inbox": "Postfach",
  "sidebar.advisor.tickets": "Rückfragen",
  "sidebar.advisor.uploads": "Uploads",
  "sidebar.customerView": "Kundenansicht",
  "sidebar.faceToFace.button": "Kontaktieren",
  "sidebar.faceToFace.title": "Face to Face mit",
  "sidebar.list.accounting": "Buchhaltung",
  "sidebar.list.analysis": "Analysis",
  "sidebar.list.cockpit": "Cockpit",
  "sidebar.list.documents": "Berichte",
  "sidebar.list.faceToFace": "Face to Face",
  "sidebar.list.hr": "Personal",
  "sidebar.list.kpi": "Zahlen & Fakten",
  "sidebar.list.personnelFile": "Personalakte",
  "sidebar.list.projects": "Projekte",
  "sidebar.list.results": "Auswertungen",
  "sidebar.list.settings": "Einstellungen",
  "sidebar.list.support": "Support",
  "sidebar.list.tickets": "Rückfragen",
  "signDialog.noQesEnabled.message": "Ich bestätige hiermit die Freigabe des Dokuments.",
  "signDialog.noQesRequired.button.click": "Freigeben",
  "signDialog.noQesRequired.button.qes": "Elektronische Signatur",
  "signDialog.noQesRequired.message": "Für die Freigabe können Sie zwischen Freigabe per Klick oder per elektronischer Signatur wählen.",
  "signDialog.qesblocked.message": "Unterzeichnung mittels elektronischer Signatur ist aktuell nicht möglich, da zum jetzigen Zeitpunkt bereits ein Signaturverfahren von einer anderen Person gestartet wurde. Bitte versuchen Sie es in etwa 15 Minuten erneut.",
  "signDialog.qesblocked.title": "Freigabe derzeit nicht möglich",
  "signDialog.qesRequired.confirm": "OK, elektronisch signieren",
  "signDialog.qesRequired.message": "Für die Freigabe wurde eine elektronische Signatur angefordert. Sie werden nun zur elektronischen Signatur weitergeleitet.",
  "signDialog.title": "Freigabe",
  "soleEarnerStatus.Alleinerzieher": "Alleinerzieher",
  "soleEarnerStatus.Alleinverdiener": "Alleinverdiener",
  "soleEarnerStatus.Nein": "Nein",
  "staff.documents.navbar.heading": "Meine Dateien",
  "staff.personnelFile.navbar.heading": "Hallo {name}!",
  "stepper.optional": "optional",
  "stepper.progress": "Fortschritt",
  "support.admins.title": "AdministratorInnen",
  "support.filter.option.activeChats": "Nur aktive Chats",
  "support.filter.option.allChats": "Alle Chats",
  "support.filter.option.inactiveChats": "Nur inaktive Chats",
  "support.filter.setting.showInactiveColleagues": "Inaktive Mitarbeiter anzeigen",
  "support.inactiveEmployee": "Ehemaliger Mitarbeiter",
  "support.partners.title": "PartnerInnen",
  "support.privateChat.dialog.markAsPrivate.confirm": "Als vertraulich markieren",
  "support.privateChat.dialog.markAsPrivate.message": "Wenn ein Chat als vertraulich markiert wurde, kann <b>keine weitere Person</b> den Chat einsehen und Nachrichten in Ihrer Abwesenheit beantworten.<br/><br/>Ihr Kunde wird <b>nicht</b> über diese Einstellung <b>verständigt</b>.",
  "support.privateChat.dialog.markAsPrivate.title": "Chat als vertraulich markieren?",
  "support.privateChat.dialog.markAsPublic.confirm": "Vertraulichkeit aufheben",
  "support.privateChat.dialog.markAsPublic.message": "Wenn ein Chat nicht mehr als vertraulich markiert wird, können <b>weitere Personen</b> den Chat einsehen und Nachrichten in Ihrer Abwesenheit beantworten.<br/><br/>Ihr Kunde wird <b>nicht</b> über diese Einstellung <b>verständigt</b>.",
  "support.privateChat.dialog.markAsPublic.title": "Chat nicht mehr als vertraulich markieren?",
  "support.privateChat.info": "Chat ist als <b>vertraulich</b> markiert und kann nicht von anderen eingesehen werden.",
  "support.privateChat.info.markAsPublic": "AUFHEBEN",
  "support.privateChat.info.mobile": "Chat ist <b>vertraulich</b>.",
  "support.privateChat.placeholder": "Nachricht eingeben ...",
  "support.responsible.title": "Teammitglieder",
  "support.search.navbar.title": "Suchergebnisse",
  "support.substitute.author": "{substituteName} (in Vertretung für {authorName})",
  "support.substitute.back": "ZURÜCK ZU MEINEM CHAT",
  "support.substitute.back.mobile": "MEIN CHAT",
  "support.substitute.warning": "<b>Achtung:</b> Sie befinden sich im Chat von <b>{name}</b>",
  "support.substitute.warning.mobile": "Chat von <b>{name}</b>",
  "support.yourContacts": "Ihre AnsprechpartnerInnen",
  "table.button.release": "FREIGEBEN",
  "table.employees.dateOfJoining": "Eintrittsdatum",
  "table.employees.dateOfJoiningExiting": "Ein-/Austrittsdatum",
  "table.employees.dateOfLeaving": "Austrittsdatum",
  "table.employees.dateOfTransmitting": "Übermittelt am",
  "table.employees.documents.date": "Datum",
  "table.employees.documents.document": "Datei",
  "table.employees.documents.documentName": "Bezeichnung",
  "table.employees.documents.downloadDocuments": "Dateien herunterladen",
  "table.employees.documents.uploadedBy": "Hochgeladen von",
  "table.employees.employee": "DienstnehmerIn",
  "table.employees.jobDescription": "Berufsbezeichnung",
  "table.employees.missingDetails": "Fehlende Angaben",
  "table.employees.personalId": "Personalnummer",
  "table.employees.reasonForLeaving": "Austrittsgrund",
  "table.employees.status": "Status",
  "table.employees.status.active": "Aktiv",
  "table.employees.status.inactive": "Inaktiv",
  "table.employees.status.inDataChange": "Stammdatenänderung",
  "table.employees.status.inDataChangeNotTransferred": "Änderung (Entwurf)",
  "table.employees.status.inDeregistration": "In Abmeldung",
  "table.employees.status.inDeregistrationNotTransferred": "Abmeldung (Entwurf)",
  "table.employees.status.inRegistration": "In Anmeldung",
  "table.employees.status.inRegistrationNotTransferred": "Anmeldung (Entwurf)",
  "table.label.changes": "Änderungen",
  "table.label.changeUsername": "E-Mail für Änderung von Benutzernamen versenden",
  "table.label.comment": "Kommentar",
  "table.label.companyName": "Unternehmensname",
  "table.label.contactPerson": "AnsprechpartnerIn",
  "table.label.contactPersonId": "Personennummer",
  "table.label.costCenter": "Kostenstelle",
  "table.label.createdAt": "Uploaddatum",
  "table.label.createdAt.variant": "Erstellt am",
  "table.label.createdBy": "Erstellt von",
  "table.label.date": "Datum",
  "table.label.dateOfJoining": "Eintrittsdatum",
  "table.label.dateOfJoiningExiting": "Ein-/Austrittsdatum",
  "table.label.dateOfLeaving": "Austrittsdatum",
  "table.label.dateOfTransmitting": "Übermittelt am",
  "table.label.deleteUser": "BenutzerIn löschen",
  "table.label.document": "Datei",
  "table.label.documentName": "Bezeichnung",
  "table.label.documentType": "Datei",
  "table.label.downloadDocuments": "Dateien herunterladen",
  "table.label.dueDate": "Frist",
  "table.label.dueDateForDraft": "Frist für Freigabe",
  "table.label.email": "E-Mail Adresse",
  "table.label.employee": "DienstnehmerIn",
  "table.label.file": "Datei",
  "table.label.fileName": "Bezeichnung",
  "table.label.folder": "Ordner",
  "table.label.holiday": "Urlaub",
  "table.label.info": "Info",
  "table.label.jobDescription": "Berufsbezeichnung",
  "table.label.lastAuthenticatedAt": "Letzter Login",
  "table.label.lastAuthenticatedAt.registered.tooltip": "Benutzer registriert",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "Benutzer nicht registriert",
  "table.label.lastCreated": "Zuletzt angelegt",
  "table.label.members": "Mitglieder",
  "table.label.message": "Nachricht",
  "table.label.messages": "Nachrichten",
  "table.label.missingDetails": "Fehlende Angaben",
  "table.label.modulePermissions": "Berechtigungen auf Bereichsebene",
  "table.label.modules": "Bereiche",
  "table.label.needsRelease": "Freigabe erforderlich",
  "table.label.openReleases": "Offene Freigaben",
  "table.label.otherAbsence": "Sonstige Abwesenheit",
  "table.label.personalId": "Personalnummer",
  "table.label.personalNumber": "Personalnummer",
  "table.label.phoneNumber": "Telefonnummer",
  "table.label.reasonForLeaving": "Austrittsgrund",
  "table.label.recordedBy": "Erfasst von",
  "table.label.release": "Freigaben",
  "table.label.released": "Übermittelt",
  "table.label.releasedAt": "Übermittelt am",
  "table.label.releaseRequestedBy": "Freigabe gestellt von",
  "table.label.role": "Rolle",
  "table.label.sentBy": "Gesendet von",
  "table.label.sickLeave": "Krankenstand",
  "table.label.status": "Status",
  "table.label.teamMessages": "Teamnachrichten",
  "table.label.ticket": "Rückfrage",
  "table.label.ticketBy": "Frage gestellt von",
  "table.label.ticketDescription": "Bezeichnung",
  "table.label.ticketDueDate": "Frist",
  "table.label.tickets": "Rückfragen",
  "table.label.transferStatus": "Transfer",
  "table.label.transmittedAt": "Übermittelt am",
  "table.label.type": "Art",
  "table.label.updatedAt": "Zuletzt bearbeitet",
  "table.label.updatedAt.variant": "Zuletzt geändert",
  "table.label.uploadedBy": "Hochgeladen von",
  "table.label.username": "Benutzername",
  "table.noResults.message": "Ihre Suche hat keine Ergebnisse geliefert.",
  "table.noResults.title": "Keine Suchergebnisse",
  "taxAccount.pay.button": "Steuerschuld bezahlen",
  "taxAccount.transactions.table.filters.date": "Datum",
  "taxAccount.transactions.table.filters.tax": "Steuer",
  "taxAccount.transactions.table.label.amount": "Betrag",
  "taxAccount.transactions.table.label.date": "Datum",
  "taxAccount.transactions.table.label.due": "Fälligkeit",
  "taxAccount.transactions.table.label.taxPeriod": "Periode",
  "taxAccount.transactions.table.label.text": "Bezeichnung",
  "taxAccount.transactions.table.search.count": "Transaktionen: <b>{count}</b>",
  "taxAccount.transactions.table.search.placeholder": "nach Transaktionen suchen",
  "taxAccount.transactions.title": "Saldo Steuerkonto: [value]",
  "termsOfUse.checkbox.error": "Bitte akzeptieren Sie die Nutzungsbedingungen",
  "termsOfUse.upcoming.navbar.button": "Akzeptieren",
  "termsOfUse.upcoming.navbar.message": "Die Nutzungsbedingungen der TPA Connect App ändern sich ab {date}.",
  "ticketList.emptystate.title": "Keine Rückfragen vorhanden",
  "unpaidAccounts.invoices.pay": "Bezahlen",
  "unpaidAccounts.invoices.table.filters.date": "Belegdatum",
  "unpaidAccounts.invoices.table.label.documentDate": "Belegdatum",
  "unpaidAccounts.invoices.table.label.documentNumber": "Belegnummer",
  "unpaidAccounts.invoices.table.label.documentText": "Belegtext",
  "unpaidAccounts.invoices.table.label.netAmount": "Nettobetrag",
  "unpaidAccounts.invoices.table.label.opAmount": "Offener Betrag",
  "unpaidAccounts.invoices.table.label.receiptDate": "Belegdatum",
  "unpaidAccounts.invoices.table.label.receiptNumber": "Belegnummer",
  "unpaidAccounts.invoices.table.label.receiptText": "Belegtext",
  "unpaidAccounts.invoices.table.search.count": "Rechnungen: <b>{count}</b>",
  "unpaidAccounts.invoices.table.search.placeholder": "nach Rechnungen suchen",
  "unpaidAccounts.invoices.title": "Offene Rechnungen bei TPA: [value]",
  "upload.category.analysis": "Report: Monatliche Auswertung",
  "upload.category.annualReports": "Jahresabschluss",
  "upload.category.articles": "Ausarbeitungen",
  "upload.category.billing": "Report: Monatliche Abrechnung",
  "upload.category.certificateOfCitizenship": "Staatsbürgerschaftsnachweis",
  "upload.category.certificateOfRegistration": "Meldebestätigung",
  "upload.category.contractOfEmployment": "Vertrag",
  "upload.category.contracts": "Verträge",
  "upload.category.correspondence": "Korrespondenz",
  "upload.category.dataChangeConfirmation": "Stammdatenänderung",
  "upload.category.deregisterDocument": "Abmeldeunterlagen",
  "upload.category.deregistrationConfirmation": "DienstnehmerInnen Abmeldung",
  "upload.category.employmentPermit": "Arbeitsgenehmigung",
  "upload.category.identityCard": "Personalausweis",
  "upload.category.information": "Information",
  "upload.category.notices": "Bescheide",
  "upload.category.other": "Sonstiges",
  "upload.category.payslip": "Lohnzettel",
  "upload.category.registrationConfirmation": "DienstnehmerInnen Anmeldung",
  "upload.category.report": "Reports",
  "upload.category.taxOffice": "Finanzamt",
  "upload.category.taxReturn": "Steuererklärungen",
  "userManagement.successDialog.changeUsername.message": "<b>{userName}</b> erhält eine Aufforderung die neu bekanntgegebene E-Mail Adresse zu bestätigen.",
  "userManagement.successDialog.deleteUser.single.message": "<b>{userName}</b> wurde erfolgreich gelöscht.",
  "userManagement.successDialog.deleteUser.title": "BenutzerIn wurde erfolgreich gelöscht.",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "Einstellungen für <b>{count} Benutzer</b> wurden übernommen.",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "Einstellungen für <b>{userName}</b> wurden übernommen.",
  "userManagement.successDialog.existingUser.superAdmin.title": "Benutzer&shy;ein&shy;stel&shy;lun&shy;gen erfolg&shy;reich ge&shy;speichert",
  "userManagement.successDialog.existingUser.title": "Benutzer&shy;ein&shy;stel&shy;lun&shy;gen erfolg&shy;reich vor&shy;geschlagen",
  "userManagement.successDialog.message": "Ihr Vorschlag muss nun von einem <b>Superadmin</b> freigegeben werden",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "<b>{count} Benutzer</b> erhalten eine Einladung per E-Mail.",
  "userManagement.successDialog.newUser.superAdmin.single.message": "<b>{userName}</b> erhält eine Einladung per E-Mail.",
  "userManagement.successDialog.newUser.superAdmin.title": "Benutzer erfolgreich eingeladen",
  "userManagement.successDialog.newUser.title": "Benutzer erfolgreich vorgeschlagen",
  "userManagement.successDialog.rejectUser.title": "Benutzer erfolgreich abgelehnt",
  "userManagement.successDialog.releaseExistingUser.title": "Benutzer erfolgreich freigegeben",
  "userManagement.successDialog.releaseNewUser.title": "Benutzer erfolgreich freigegeben und eingeladen",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "E-Mail für die Änderung des Benutzernamens wurde erfolgreich verschickt.",
  "userProfile.createBmdTodos": "Aufgaben in BMD für Rückfragen erstellen",
  "userProfile.deleteAccount.message": "Möchten Sie Ihr Konto wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!",
  "userProfile.deleteAccount.title": "Konto löschen",
  "userProfile.multiFactorAuth": "Zwei-Faktor-Authentifizierung mit Telefonnummer aktivieren",
  "userProfile.navbar.heading": "Profil",
  "userProfile.upgrade.navbar.heading": "Upgrade",
  "userProfile.userInterests.title": "Interessen",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "Bitte geben Sie die neuen Benutzer frei, um sie einzuladen.",
  "userReleaseInfoAdminDialog.releaseInfo.single": "Bitte geben Sie den neuen Benutzer frei, um ihn einzuladen.",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "{userName} hat <b>{count} neue Benutzer</b> vorgeschlagen.",
  "userReleaseInfoAdminDialog.releaseMessage.single": "{userName} hat <b>{count} neuen Benutzer</b> vorgeschlagen.",
  "userReleaseInfoAdminDialog.submit": "Anzeigen und freigeben",
  "userReleaseInfoAdminDialog.title": "Benutzer freigeben und einladen",
  "userReleaseInfoDialog.cancel": "Zur Benutzerverwaltung",
  "userReleaseInfoDialog.from": "von",
  "userReleaseInfoDialog.message": "Ihre vorgeschlagenen Änderungen der folgenden Benutzer wurden freigegeben/abgelehnt:",
  "userReleaseInfoDialog.rejected": "abgelehnt",
  "userReleaseInfoDialog.released": "freigegeben",
  "userReleaseInfoDialog.title": "Änderung der Benutzer",
  "viewer.unsupported.message": "{name} kann derzeit nicht angezeigt werden.",
  "webhook.employee.noSearch": "Sucheingabe erforderlich",
  "webhook.employee.placeholder": "Name der DienstnehmerIn",
  "webhook.noCompanies": "Keine Firmenberechtigungen",
  "webhook.tickets.button": "Rückfrage erstellen",
  "webhook.tickets.info": "Ordnen Sie das Dokument einem Bereich zu und wählen Sie die Kategorie aus",
  "webhook.tickets.navbar": "Rückfrage zu {document}",
  "webhook.tickets.taskTitle": "Aufgabe zu {name}",
  "webhook.tickets.title": "Rückfrage erstellen",
  "webhook.uploads.navbar": "Dateien übermitteln",
  "welcomeCard.accounting.addMoreRecords.button": "Weitere Belege erfassen",
  "welcomeCard.accounting.addRecords.button": "Belege erfassen",
  "welcomeCard.hr.addMoreRecords.button": "Weitere Dateien übermitteln",
  "welcomeCard.hr.addRecords.button": "Dateien übermitteln",
  "texterify_timestamp": "2024-11-18T09:59:56Z"
} as const;

export { german };
