import * as React from "react";
import { t } from "../../i18n/util";
import { API, ITableParams } from "../../network/API";
import { GetCompaniesResponseItem } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useCompanies = (options?: {
    search?: string;
    skipLoadForEmptySearch?: boolean; // Only load if search !== "" (needed for webhooks)
    limit?: number;
}) => {
    const [companies, setCompanies] = React.useState<GetCompaniesResponseItem[]>([]);
    const [initialized, setInitialized] = React.useState(false);
    const [total, setTotal] = React.useState(0);

    const skip = options?.skipLoadForEmptySearch === true;
    const limit = options?.limit;
    const search = options?.search;

    const load = React.useCallback(async () => {
        if (skip && search === "") {
            return;
        }

        try {
            generalStore.isLoading = true;
            const params: ITableParams = {
                search,
                limit,
            };

            const response = await API.getCompanies(params);

            if (response) {
                setCompanies(response.companies ?? []);
                setTotal(response.total);
                setInitialized(true);
            }
        } catch (err) {
            generalStore.setError(t("error.loadCompany"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [skip, limit, search]);

    const loadNext = React.useCallback(async () => {
        try {
            generalStore.isLoading = true;
            if (companies && companies.length < total) {
                const response = await API.getCompanies({
                    limit: limit,
                    offset: companies.length,
                    search: search,
                });
                if (response.companies) {
                    const newCompanies = companies.concat(response.companies);
                    setCompanies(newCompanies);
                }
            }
        } catch (err) {
            generalStore.setError(t("error.loadCompany"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companies, limit, search, total]);

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        initialized,
        total,
        companies,
        loadNext,
        remainingCount: total - companies.length,
    };
};
