import { Button } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { CustomDialog } from "./CustomDialog";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";

const Container = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => (
    <div style={{ padding: "0 42px", ...style }}>{children}</div>
);

export const InfoDialog = (props: {
    title: string;
    subtitle?: string;
    message: React.ReactNode;
    open: boolean;

    confirmLabel?: string;
    onConfirm?: () => void;

    // Adds a close X icon
    onClose?: () => void;
}) => {
    const isMobile = React.useContext(MobileContext);

    return (
        <CustomDialog open={props.open} onClose={props.onClose} showCloseIcon={!!props.onClose}>
            <div style={{ paddingTop: props.onClose ? 0 : 42, display: "flex", flexDirection: "column" }}>
                <Container style={{ paddingBottom: 16, borderBottom: `1px solid ${customColors.greyLight}` }}>
                    <h1>{props.title}</h1>
                    {props.subtitle && <p style={{ marginTop: 24 }}>{props.subtitle}</p>}
                </Container>
                <Container style={{ overflow: "auto", maxHeight: isMobile ? undefined : "40vh" }}>
                    <p style={{ marginTop: 24, marginBottom: 24 }}>{props.message}</p>
                </Container>
                <Container
                    style={{ paddingTop: 24, paddingBottom: 42, borderTop: `1px solid ${customColors.greyLight}` }}
                >
                    <ResponsiveButtonContainer>
                        {props.onConfirm && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={props.onConfirm}
                                data-id="button_confirm"
                            >
                                {props.confirmLabel ?? t("common.ok")}
                            </Button>
                        )}
                    </ResponsiveButtonContainer>
                </Container>
            </div>
        </CustomDialog>
    );
};
