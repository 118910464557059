import * as React from "react";
import { TransferSuccessDialog } from "../ui/TransferSuccessDialog";
import { useTimeoutDialog } from "./useTimeoutDialog";

export const useTransferSuccessDialog = (message: string, onClose: () => void) => {
    const timeoutDialog = useTimeoutDialog(onClose);

    const component = (
        <TransferSuccessDialog
            open={timeoutDialog.isDialogOpen}
            message={message}
            onClose={timeoutDialog.closeDialog}
        />
    );

    return {
        ...timeoutDialog,
        component,
    };
};
