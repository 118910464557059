import { Tab, Tabs } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { pushRoute } from "../app/router/history";
import { NavBarBack } from "../ui/NavBarBack";
import { ProjectsRoutes } from "./router/ProjectsRoutes";

export const ProjectsNavBar = observer(function ProjectsNavBar({ showTabs }: { showTabs?: boolean }) {
    const { pathname } = useLocation();
    const [page, setPage] = React.useState<string | null>("active");

    React.useEffect(() => {
        if (pathname === ProjectsRoutes.ACTIVE.ROOT) {
            setPage("active");
        } else if (pathname === ProjectsRoutes.FINISHED) {
            setPage("finished");
        } else if (pathname === ProjectsRoutes.TO_CONFIGURE.ROOT) {
            setPage("configure");
        } else if (pathname === ProjectsRoutes.DELETED) {
            setPage("deleted");
        }
    }, [pathname]);

    const handlePageChange = (_: React.ChangeEvent<unknown>, page: string | null) => {
        setPage(page);
    };

    return (
        <NavBarBack
            title={t("projects.navbar.heading")}
            tabs={
                showTabs ? (
                    <Tabs
                        value={page}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handlePageChange}
                        variant="scrollable"
                        scrollButtons="off"
                    >
                        <Tab
                            label={t("projects.navbar.activeProjects")}
                            value="active"
                            onClick={() => {
                                pushRoute(ProjectsRoutes.ACTIVE.ROOT);
                            }}
                            data-id="projects_tab_activeProjects"
                        />
                        {authStore.canCreateProjects && (
                            <Tab
                                label={t("projects.navbar.notConfiguredProjects")}
                                value="configure"
                                onClick={() => {
                                    pushRoute(ProjectsRoutes.TO_CONFIGURE.ROOT);
                                }}
                                data-id="projects_tab_notConfiguredProjects"
                            />
                        )}
                        {authStore.isTpa && [
                            <Tab
                                key="tab_finished"
                                label={t("projects.navbar.finishedProjects")}
                                value="finished"
                                onClick={() => {
                                    pushRoute(ProjectsRoutes.FINISHED);
                                }}
                                data-id="projects_tab_finishedProjects"
                            />,
                            <Tab
                                key="tab_deleted"
                                label={t("projects.navbar.deletedProjects")}
                                value="deleted"
                                onClick={() => {
                                    pushRoute(ProjectsRoutes.DELETED);
                                }}
                                data-id="projects_tab_deletedProjects"
                            />,
                        ]}
                    </Tabs>
                ) : undefined
            }
        />
    );
});
