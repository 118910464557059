import React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Message, GetChatMessagesResponse } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { useInterval } from "./useInterval";

export const useChatMessages = ({
    companyId,
    userId,
    limit = 50,
    offset = 0,
    onLoad, // Gets called whenever new messages are loaded
    pollingIntervalMs,
    colleagueId,
    messageId,
}: {
    companyId?: string;
    userId?: string;
    limit?: number;
    offset?: number;
    onLoad?: (userId: string) => void;
    pollingIntervalMs?: number;
    colleagueId?: string;
    messageId?: string;
}) => {
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [total, setTotal] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [start, setStart] = React.useState(offset);
    // ref not state, because it should not trigger a rerender
    const messageIdRef = React.useRef<string | undefined>(messageId);

    const load = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        if (!userId) {
            // TPAPORTAL-1267: If selected user id was cleared -> clear messages
            setMessages([]);
            return;
        }

        try {
            generalStore.isLoading = true;
            let response: GetChatMessagesResponse;
            if (messageIdRef.current) {
                response = await API.getPreviousChatMessages({
                    companyId,
                    userId,
                    colleagueId,
                    params: { limit },
                    messageId: messageIdRef.current,
                });
                messageIdRef.current = undefined;
            } else {
                response = await API.getChatMessages({
                    companyId,
                    userId,
                    colleagueId,
                    params: { limit, offset },
                });
            }

            setStart(response.offset);

            if (response.messages) {
                onLoad?.(userId);
                setMessages(response.messages);
                setTotal(response.total);
                setCount(response.messages.length);
            }
        } catch (error) {
            generalStore.setError(t("error.loadMessages"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [colleagueId, companyId, limit, offset, onLoad, userId]);

    const loadAdditional = React.useCallback(
        async (older: boolean) => {
            if (!companyId || !userId) {
                return;
            }

            try {
                generalStore.isLoading = true;

                if (messages && count < total) {
                    const params = { limit, offset: 0 };
                    const end = start + count;

                    if ((older && end >= total) || (!older && start <= 0)) {
                        // We have reached the end or the start -> get out
                        return;
                    }

                    let newStart = start;
                    if (older) {
                        params.offset = end;
                    } else {
                        newStart -= limit;
                        if (newStart < 0) {
                            // Don't go past the start, only load the remaining
                            newStart = 0;
                            params.limit = start;
                        }
                        params.offset = newStart;
                    }

                    const response = await API.getChatMessages({ companyId, userId, colleagueId, params });
                    if (response.messages) {
                        // prepend or append new messages (depending on loading direction)
                        const newMessages = older
                            ? messages.concat(response.messages)
                            : response.messages.concat(messages);

                        // We potentially got more unread messages
                        if (newMessages.length > messages.length) {
                            onLoad?.(userId);
                        }

                        setMessages(newMessages);
                        setCount(newMessages.length);
                        setTotal(response.total);

                        if (!older) {
                            // We are loading newer messages -> start moves forward
                            setStart(newStart);
                        }
                    }
                }
            } catch (error) {
                generalStore.setError(t("error.loadMessages"), error);
            } finally {
                generalStore.isLoading = false;
            }
        },
        [colleagueId, companyId, count, limit, messages, onLoad, start, total, userId],
    );

    // Used for polling
    const loadUnread = React.useCallback(
        async (onNewMessages?: (newMessageCount: number) => void) => {
            if (!companyId || !userId) {
                return;
            }

            if (start > 0) {
                // We have not loaded the beginning of the chat thread -> don't poll for new messages
                return;
            }

            if (!messages || messages.length === 0) {
                // We haven't loaded any messages yet -> do a simple load
                await load();
                return;
            }

            try {
                generalStore.isLoading = true;
                const response = await API.getChatMessages({ companyId, userId, colleagueId, params: { limit } });
                if (response.messages) {
                    const startIndex = response.messages.findIndex(message => message.id === messages[0].id);
                    // startIndex === 0 means -> no new messages
                    if (startIndex > 0) {
                        // Prepend new messages to current ones
                        const newMessages = response.messages.slice(0, startIndex).concat(messages);
                        setMessages(newMessages);
                        setCount(newMessages.length);
                        setTotal(response.total);
                    }

                    onNewMessages?.(Math.max(0, startIndex));
                }
            } catch (error) {
                generalStore.setError(t("error.loadMessages"), error);
            } finally {
                generalStore.isLoading = false;
            }
        },
        [colleagueId, companyId, limit, load, messages, start, userId],
    );

    React.useEffect(() => {
        load();
    }, [load]);

    useInterval(loadUnread, pollingIntervalMs);

    return {
        messages,
        reload: load,
        loadAdditional,
        poll: loadUnread,
    };
};
