import * as React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { generalStore } from "../../../stores/GeneralStore";
import { debug } from "../../../util/debug";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useCrossTabState } from "../../hooks/useCrossTabState";
import { useHash } from "../../hooks/useHash";

export type MSCloseRefreshState = "waiting" | "refresh";

// MS OAuth token refresh redirects here
export const AuthMSCloseSite = () => {
    const {
        code,
        state,
        error,
        error_description,
    }: {
        code?: string;
        state?: string;
        session_state?: string;
        error?: string;
        error_description?: string;
    } = useHash();

    if (!!error || !!error_description) {
        generalStore.setError(`${t("error.invalid_credentials")}: ${error ?? ""} (${error_description ?? ""})`);
    }

    const [refreshProjects, setRefreshProjects] = useCrossTabState<MSCloseRefreshState>(
        "refreshProjectOverview",
        "waiting",
    );
    const [refreshPendingMicrosoftActionsBar, setRefreshPendingMicrosoftActionsBar] =
        useCrossTabState<MSCloseRefreshState>("refreshPendingMicrosoftActionsBar", "waiting");

    const [refreshProjectsUnlockBar, setRefreshProjectsUnlockBar] = useCrossTabState<MSCloseRefreshState>(
        "refreshProjectsUnlockBar",
        "waiting",
    );

    React.useEffect(() => {
        const login = async () => {
            if (code && state) {
                try {
                    generalStore.isLoading = true;
                    await API.postMSLogin(code, state);
                } catch (error) {
                    const apiError = getApiError(error);
                    if (apiError?.response.type === "USER_DEACTIVATED") {
                        generalStore.setError(t("error.userDeactivated"));
                    } else if (apiError?.response.type === "UNKNOWN_USER") {
                        generalStore.setError(t("error.userUnknown"));
                    } else if (apiError?.response.type === "MS_AUTH_FAILED") {
                        generalStore.setError(t("error.microsoftLogin"));
                    } else if (
                        apiError?.response.type === "COMPANIES_SYNC_TIMEOUT" ||
                        apiError?.statusCode === HttpStatusCode.GatewayTimeout_504
                    ) {
                        generalStore.setError(t("error.loginAccountSetup"));
                    } else {
                        generalStore.setError(t("error.general"), error);
                    }
                } finally {
                    generalStore.isLoading = false;
                }
            }

            setRefreshProjects("refresh");
            setRefreshPendingMicrosoftActionsBar("refresh");
            setRefreshProjectsUnlockBar("refresh");

            window.close();
        };

        if (code && state) {
            login();
        } else {
            debug.error("### invalid redirect from MS OAuth");
            pushRoute(Routes.ROOT);
        }
    }, [code, setRefreshPendingMicrosoftActionsBar, setRefreshProjects, setRefreshProjectsUnlockBar, state]);

    return null;
};
