import MomentUtils from "@date-io/moment";
import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { observer } from "mobx-react";
// Import locales for moment
import { createInstance, MatomoProvider, useMatomo } from "@jonkoops/matomo-tracker-react";
import * as React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RawIntlProvider } from "react-intl";
import { history } from "./components/app/router/history";
import { AppContainerSite } from "./components/app/sites/AppContainerSite";
import { useMobileMediaQuery } from "./components/hooks/useMobileMediaQuery";
import { MobileContext } from "./components/util/MobileContext";
import { theme } from "./components/util/Theme";
import { API_BASE_URL, BASE_NAME, DEPLOYMENT_ENV, IS_DEMO, IS_PROD, IS_STAGING, PUBLIC_URL } from "./config";
import { GLOBAL_FEATURES } from "./features";
import { i18n } from "./i18n/util";
import { authStore } from "./stores/AuthStore";
import { generalStore } from "./stores/GeneralStore";
import { debug } from "./util/debug";

debug.group("Build info");
debug.log("base url", API_BASE_URL);
debug.log("base name", BASE_NAME);
debug.log("public url", PUBLIC_URL);
debug.log("deployment env", DEPLOYMENT_ENV);
if (IS_DEMO) {
    debug.log("THIS IS A DEMO BUILD");
}
debug.groupEnd();

// IMPORTANT: Disable ad blockers to test this (uBlock origin blocks getting the matomo.js tracking library)
let matomoSiteId = IS_PROD ? 6 : 5;
if (IS_DEMO) {
    matomoSiteId = 7;
}

const matomoInstance = GLOBAL_FEATURES.matomo
    ? createInstance(
          IS_PROD || IS_STAGING
              ? {
                    urlBase: "https://matomo.its-tpa.com/",
                    siteId: matomoSiteId,
                    linkTracking: false,
                }
              : {
                    // url = urlBase
                    // credentials in lastpass - shared development

                    // testing URL ist valid 21 days starting from 2021-09-17, create a new one if you wand to test again
                    // using https://matomo.org/start-free-analytics-trial/

                    // docs: https://developer.matomo.org/ & https://matomo.org/docs
                    urlBase: "https://testings2.matomo.cloud/",
                    siteId: 1,
                    linkTracking: false,
                },
      )
    : undefined;

function doNotScrollState(state: object): boolean {
    return "doNotScroll" in state && state.doNotScroll === true;
}

history.listen(location => {
    if (location.state && doNotScrollState(location.state)) {
        return; // the new page has its own scroll handling, e.g. scroll to a table row
    }

    window.scrollTo(0, 0);
});

const Wrapper = (props: { children: React.ReactNode }) =>
    matomoInstance ? (
        <MatomoProvider value={matomoInstance}>{props.children}</MatomoProvider>
    ) : (
        <React.Fragment>{props.children}</React.Fragment>
    );

const App: React.FunctionComponent = observer(function App() {
    const isMobile = useMobileMediaQuery();
    const { trackPageView } = useMatomo();

    const cookiesSaved = generalStore.cookiesSaved;
    const cookiesRehydrated = generalStore.isRehydrated;
    const authenticated = authStore.isAuthenticated && authStore.isRehydrated;
    React.useEffect(() => {
        if (cookiesRehydrated) {
            if (!cookiesSaved && authenticated) {
                authStore.logout();
            }
        }
    }, [authenticated, cookiesRehydrated, cookiesSaved]);

    if (GLOBAL_FEATURES.matomo && generalStore.cookieSettings.marketing) {
        trackPageView({ href: history.location.pathname });
    }

    return (
        <MobileContext.Provider value={isMobile}>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DndProvider backend={HTML5Backend}>
                        <Wrapper>
                            <CssBaseline />
                            <RawIntlProvider value={i18n.intl} key={authStore.locale}>
                                <AppContainerSite />
                            </RawIntlProvider>
                        </Wrapper>
                    </DndProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </MobileContext.Provider>
    );
});

export default App;
