import { datePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";
import * as React from "react";
import { Status, statusColors } from "../../stores/Status";
import { Icon, IIconNames } from "../util/Icon";
import { customColors, BOX_SHADOW_LIGHT } from "../util/Theme";
import { Deadline, IDueDate } from "./Deadline";
import { StatusBar } from "./StatusBar";

export const LinkCard = (props: {
    status: Status;
    name: IIconNames;
    title: string;
    ticketCount?: number;
    onClick: () => void;
    style?: React.CSSProperties;
    date?: IDueDate;
}) => {
    return (
        <div
            role="button"
            onClick={props.onClick}
            style={{
                display: "flex",
                backgroundColor: customColors.white,
                alignItems: "center",
                borderRadius: 4,
                boxShadow: BOX_SHADOW_LIGHT,
                cursor: "pointer",
                ...props.style,
            }}
        >
            <StatusBar minHeight={56} marginBottom={0} status={props.status} />
            <div
                style={{
                    color: statusColors[props.status].color,
                    backgroundColor: statusColors[props.status].background,
                    padding: "16px 16px",
                }}
            >
                <Icon name={props.name} style={{ display: "block" }} />
            </div>
            <p className="body2" style={{ flexGrow: 1, padding: "16px 16px", color: customColors.body1Dark }}>
                {props.title} {props.ticketCount ? `(${props.ticketCount})` : ""}
            </p>
            {props.date && (
                <Deadline date={props.date.date} overdue={props.date.overdue} labelId={props.date.labelId} />
            )}
            <div style={{ padding: 16 }}>
                <Icon name="chevronRight" style={{ display: "block", color: customColors.greyDarkIcons }} />
            </div>
        </div>
    );
};
