import * as React from "react";
import { BulkDeleteButton, BulkDownloadButton, BulkMoveButton } from "./BulkActionButton";

export const BulkActionButtonsFiles = (props: {
    onMove?: () => void;
    onDelete?: () => void;
    onDownload?: () => void;
}) => {
    return (
        <>
            <BulkDownloadButton onClick={props.onDownload} />
            <BulkDeleteButton onClick={props.onDelete} />
            <BulkMoveButton onClick={props.onMove} />
        </>
    );
};
