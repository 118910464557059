import compact from "lodash/compact";
import { observer } from "mobx-react";
import { GLOBAL_FEATURES } from "../../features";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { Routes } from "../app/router/Routes";
import { ModuleNavBar } from "../ui/ModuleNavBar";
import { accountingStore } from "./AccountingStore";
import { AccountingRoutes } from "./router/AccountingRoutes";

interface Props {
    showPeriods?: boolean;
    showSubsidiaries?: boolean;
}

export const AccountingNavBar = observer(function AccountingNavBar({ showPeriods, showSubsidiaries }: Props) {
    const subsidiaryId = accountingStore.selectedSubsidiaryId;

    if (showPeriods !== false) {
        showPeriods = accountingStore.canSeeOverviewSite() || accountingStore.canReadAnySubsidiaryRecords(subsidiaryId);
    }

    return (
        <ModuleNavBar
            title={t("screen.accounting.navbar.heading")}
            moduleStore={accountingStore}
            showPeriods={showPeriods}
            showSubsidiaries={showSubsidiaries}
            buttons={compact([
                accountingStore.canSeeOverviewSite() && {
                    title: t("screen.accounting.navbar.overview"),
                    value: "overview",
                    route: Routes.ACCOUNTING,
                },
                accountingStore.canReadAnySubsidiaryRecords(subsidiaryId) && {
                    title: t("screen.accounting.navbar.records"),
                    value: "records",
                    route: AccountingRoutes.RECORDS.ROOT,
                },
                GLOBAL_FEATURES.bankConnections &&
                    authStore.canReadBanking() && {
                        title: t("screen.accounting.navbar.bankConnections"),
                        value: "bank-connections",
                        route: AccountingRoutes.BANKING.CONNECTIONS.ROOT,
                    },
            ])}
        />
    );
});
