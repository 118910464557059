import React, { useCallback } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { NewsFeedEntry } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { generalStore } from "../../stores/GeneralStore";
import { newsStore } from "../../stores/NewsStore";

export const useNews = ({
    companyId,
    country,
    offset,
    limit,
    setGeneralStoreIsLoading,
}: {
    companyId?: string;
    country?: string;
    offset?: number;
    limit?: number;
    setGeneralStoreIsLoading?: boolean;
}) => {
    const [news, setNews] = React.useState<NewsFeedEntry[]>([]);
    const [total, setTotal] = React.useState(0);

    const reloadNews = useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            if (setGeneralStoreIsLoading) {
                generalStore.isLoading = true;
            }

            const news = await API.getNews(companyId, {
                offset,
                limit,
            });

            // TPAPORTAL-2015: also store in news store
            newsStore.news = news.entries ?? [];

            setNews(news.entries ?? []);
            setTotal(news.total);
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.Conflict_409) {
                generalStore.setError(t("error.loadNews.noSource"));
            } else {
                generalStore.setError(t("error.loadNews"), error);
            }
        } finally {
            if (setGeneralStoreIsLoading) {
                generalStore.isLoading = false;
            }
        }
    }, [companyId, limit, offset, setGeneralStoreIsLoading]);

    React.useEffect(() => {
        reloadNews();
    }, [reloadNews, country]);

    return { news, total };
};
