import { Card, CardContent, withStyles } from "@material-ui/core";
import * as React from "react";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import styled from "styled-components";
import { NewsFeedEntry } from "../../network/APITypes";
import { CockpitNewsInformation } from "./CockpitNewsInformation";

const ResponsiveHTMLEllipsis = responsiveHOC<React.ComponentProps<typeof HTMLEllipsis>>(0)(HTMLEllipsis);

const StyledCard = withStyles({
    root: {
        backgroundColor: "unset",
        cursor: "pointer",
    },
})(Card);

const StyledCardContent = withStyles({
    root: {
        marginTop: 16,
        padding: 0,

        "&:last-child": {
            padding: 0,
        },
    },
})(CardContent);

const DescriptionContainer = styled.div`
    margin-top: 16px;

    & * {
        font-size: unset;
        margin: unset;
        padding: unset;
        color: unset;
    }
`;

export interface IProps {
    news: NewsFeedEntry;
    onClick: (news: NewsFeedEntry) => void;
    style?: React.CSSProperties;
}

export const CockpitNewsCard = ({ onClick, news, style }: IProps) => {
    const handleClick = () => {
        onClick(news);
    };

    return (
        <StyledCard style={style} onClick={handleClick}>
            <div
                style={{
                    height: 146,
                    width: "100%",
                    background: news.imageUrl ? `url(${news.imageUrl}) center / cover no-repeat` : undefined,
                    borderRadius: 4,
                }}
            />
            <CockpitNewsInformation details={news} style={{ marginTop: 16 }} />
            <StyledCardContent>
                <h4>{news.title}</h4>
                <DescriptionContainer className="body2">
                    <ResponsiveHTMLEllipsis unsafeHTML={news.description} maxLine={3} basedOn="words" />
                </DescriptionContainer>
            </StyledCardContent>
        </StyledCard>
    );
};
