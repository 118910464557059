import { FormikHelpers } from "formik";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import * as React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { DetailedEmployeePreRegistration } from "../../../network/APITypes";
import { getApiError, getValidationError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { IEmployee } from "../../../types/models";
import { pushRoute, withParamsAndQuery } from "../../app/router/history";
import { useEmployee } from "../../hooks/useEmployee";
import { useQueryParams } from "../../hooks/useQueryParams";
import { customColors } from "../../util/Theme";
import { HrPersonalDataForm } from "../HrPersonalDataForm";
import { HrRegistrationSite } from "../HrRegistrationSite";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

export const HrEmployeesRegistrationPersonalDataSite = () => {
    useHideSideBar();

    const query = useQueryParams<{
        employeeId: string;
        isEdit: boolean;
        isPreRegistration: boolean;
        isReRegistration: boolean;
    }>();
    const { employeeId, isEdit, isPreRegistration, isReRegistration } = query;
    const { employee } = useEmployee(employeeId, !!isPreRegistration);

    const [isLoading, setIsLoading] = React.useState(false);

    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = hrStore.selectedSubsidiaryId;

    const nextPage = (employeeId: string, isPreRegistration: boolean) => {
        pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_ADDITIONAL_DATA, {
            params: { employeeId },
            query: { isEdit, isPreRegistration, isReRegistration },
        });
    };

    const handleSubmit = async (employeeData: IEmployee, formikHelpers: FormikHelpers<IEmployee>) => {
        const employeeChanged = !isEqual(employee, employeeData);
        if (!employeeChanged && isEdit) {
            nextPage(employeeId ?? "", !!isPreRegistration);
            return;
        }

        const exists = employeeData.id ? true : false;
        setIsLoading(true);

        const body = cloneDeep(employeeData) as Partial<IEmployee>;
        delete body.dateOfLeaving;
        delete body.reasonForLeaving;

        try {
            let preRegistration: DetailedEmployeePreRegistration;
            if (isReRegistration) {
                // Start reregistration and convert employee to a preRegistration
                preRegistration = await API.reregisterEmployee(companyId, subsidiaryId, employeeData);
            } else if (exists) {
                if (isEdit && !isPreRegistration) {
                    // We start a new data change
                    preRegistration = await API.postEmployeeDataChange(companyId, subsidiaryId, employeeData);
                } else {
                    // Normal patch case
                    preRegistration = await API.patchPreRegistration(companyId, subsidiaryId, body);
                }
            } else {
                // Employee has no id -> create new one
                preRegistration = await API.postEmployee(companyId, subsidiaryId, employeeData);
            }

            if (preRegistration) {
                nextPage(preRegistration.id, true);
            }
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.BadRequest_400) {
                const validationError = getValidationError(apiError);
                const failedSSN = validationError?.validationErrors?.find(validation => validation.key === "ssn");
                if (failedSSN) {
                    // Show failed SSN check as validation error
                    formikHelpers.setFieldError("ssn", t("error.invalidSsn"));
                } else {
                    generalStore.setError(t("error.createEmployee"), error);
                }
            } else {
                generalStore.setError(t("error.createEmployee"), error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    if (!employee) {
        // Wait until employee is loaded
        return null;
    }

    let backTarget: string = HrRoutes.EMPLOYEES.ROOT;
    if ((isEdit && employee.status !== "inDataChangeNotTransferred") || isReRegistration) {
        backTarget = withParamsAndQuery(
            HrRoutes.EMPLOYEES.DETAILS,
            { employeeId: employeeId ?? "" },
            { isPreRegistration },
        );
    } else if (isPreRegistration) {
        backTarget = HrRoutes.EMPLOYEES.IN_PROGRESS;
    }

    return (
        <HrRegistrationSite
            employee={employee}
            backTarget={backTarget}
            backLabel={t("screen.hr.personaldata.navback")}
            query={query}
        >
            <h1 style={{ marginBottom: 16 }}>{t("screen.hr.personaldata.personaldata")}</h1>
            <p className="body2" style={{ marginBottom: 28, color: customColors.body1Dark }}>
                {t("screen.hr.personaldata.description")}
            </p>
            <HrPersonalDataForm
                employee={employee}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                isReRegistration={isReRegistration}
                buttonText={t("screen.hr.personaldata.nextpage")}
            />
        </HrRegistrationSite>
    );
};
