import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";
import { t } from "../../i18n/util";
import {
    Project,
    ProjectConfiguration,
    ProjectItemPermission,
    ProjectItemPermissionRoleEnum,
    ProjectStatusEnum,
} from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { formatDate } from "../../util/date";
import { getFullName } from "../../util/user";
import { ProjectPermissionAvatars } from "../projects/ProjectPermissionAvatars";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors, theme } from "../util/Theme";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { TableRowButton, TpaBadge } from "./Primitives";

const projectStatusColors = {
    toConfigure: customColors.orangeOpen,
    active: customColors.yellowInProgress,
    closed: customColors.blueFinished,
    deleted: customColors.placeholder,
    migrating: customColors.yellowInProgressDisabled,
};

const ProjectStatusBar = ({ status }: { status: ProjectStatusEnum | "toConfigure"; style?: React.CSSProperties }) => (
    <div
        style={{
            width: 6,
            flexShrink: 0,
            background: projectStatusColors[status],
            borderRadius: "4px 0px 0px 4px",
        }}
    />
);

export const ProjectToConfigureRow = observer(function ProjectToConfigureRow({
    project,
    onClick,
    "data-id": dataId,
}: {
    project: ProjectConfiguration;
    onClick?: (event: React.MouseEvent) => void;
    "data-id": string;
}) {
    const isMobile = React.useContext(MobileContext);

    return (
        <div style={{ width: "100%", marginBottom: 8 }}>
            <div
                style={{
                    display: "flex",
                    backgroundColor: "white",
                    minHeight: 130,
                    lineHeight: "22px",
                    borderRadius: 4,
                    cursor: "pointer",
                }}
                data-id={dataId}
                onClick={onClick}
            >
                <ProjectStatusBar status="toConfigure" />
                <div
                    style={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: isMobile ? "column" : undefined,
                        alignItems: "flex-start",
                        padding: 24,
                        paddingBottom: 16,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", height: "100%" }}>
                            <h3>{project.name}</h3>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    flexGrow: 1,
                                    marginTop: 8,
                                }}
                            >
                                {authStore.canCreateProjects && project.projectNumber && (
                                    <p>
                                        {t("projects.externalId")} {project.projectNumber}
                                    </p>
                                )}
                                {/* // TODO
                                    <p style={{ color: customColors.body2Dark }}>
                                        {project.fileCount !== undefined && (
                                            <>
                                                {project.fileCount} {t("common.files")}
                                            </>
                                        )}
                                    </p> */}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                        <TableRowButton color="primary">{t("button.configure")}</TableRowButton>
                    </div>
                </div>
            </div>
        </div>
    );
});

const getStatusTitle = (project: Project) => {
    const name = getFullName(project.updatedBy);

    if (project.status === "active" || project.status === "migrating") {
        return name ? t("projects.statusInProgress.personalized", { name }) : t("projects.statusInProgress");
    } else if (project.status === "closed") {
        return name ? t("projects.statusClosed.personalized", { name }) : t("projects.statusClosed");
    } else if (project.status === "deleted") {
        return name ? t("projects.statusDeleted.personalized", { name }) : t("projects.statusDeleted");
    } else {
        return "";
    }
};

export const ProjectRow = observer(function ProjectRow({
    project,
    permissionUsers,
    role,
    onClick,
    onClickMenu,
    onClickRestore,
    "data-id": dataId,
}: {
    project: Project;
    permissionUsers?: ProjectItemPermission[];
    role?: ProjectItemPermissionRoleEnum;
    onClick?: (event: React.MouseEvent) => void;
    onClickMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickRestore: (projectId: string) => void;
    "data-id": string;
}) {
    const isMobile = React.useContext(MobileContext);
    const [migrationInfoOpen, setMigrationInfoOpen] = React.useState(false);

    const statusComponent = (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <span className="body2" style={{ marginRight: 8 }}>
                {getStatusTitle(project)}
            </span>
            <span
                className="body1"
                style={{
                    fontWeight: "bold",
                    color: theme.palette.text.secondary,
                }}
            >
                {formatDate(project.updatedAt)}
            </span>
        </div>
    );

    const permissionsComponent = (
        <ProjectPermissionAvatars permissionUsers={permissionUsers} variant="async" align="right" />
    );

    const isActive = project.status === "active";
    const isMigrating = project.status === "migrating";

    const textStyle = {
        color: isActive ? theme.palette.text.primary : customColors.placeholder,
    };

    const hasOpenDrafts = (project.openDrafts ?? 0) > 0;
    const hasTickets = (project.ticketCount ?? 0) > 0;
    const isInternational = project.international;

    const separator = (
        <div
            style={{
                height: 16,
                width: 1,
                backgroundColor: customColors.greyTextfields,
                margin: "0 8px",
            }}
        />
    );

    const badgesComponent = (
        <div style={{ display: "flex", alignItems: "center", marginTop: 4 }}>
            {(isInternational || isMigrating) && ( // project during migration is not guaranteed to be already international, so we have to check for isInternational and isMigrating
                <>
                    <Icon name="internationalProject" style={{ color: customColors.body2Dark, marginRight: 4 }} />
                    <div className="body2">
                        {t(isMigrating ? "projects.international.migrating" : "projects.international")}
                    </div>
                    {isMigrating && <CircularProgress size={22} color="primary" style={{ marginLeft: 8 }} />}
                </>
            )}
            {hasOpenDrafts && (
                <>
                    {isInternational && separator}
                    <div className="body2" style={{ display: "flex", alignItems: "center" }}>
                        <TpaBadge>{project.openDrafts}</TpaBadge>
                        <span style={{ marginLeft: 8 }}>
                            {project.openDrafts === 1
                                ? t("projects.openReleases.singular")
                                : t("projects.openReleases.plural")}
                        </span>
                    </div>
                </>
            )}

            {hasTickets && (
                <>
                    {(hasOpenDrafts || isInternational) && separator}
                    <div className="body2" style={{ display: "flex", alignItems: "center" }}>
                        <TpaBadge>{project.ticketCount}</TpaBadge>
                        <span style={{ marginLeft: 8 }}>
                            {project.ticketCount === 1 ? t("projects.tickets.singular") : t("projects.tickets.plural")}
                        </span>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <>
            <div style={{ width: "100%", marginBottom: 8 }}>
                <div
                    style={{
                        display: "flex",
                        backgroundColor: "white",
                        minHeight: 118,
                        lineHeight: "22px",
                        borderRadius: 4,
                        cursor: isActive || isMigrating ? "pointer" : undefined,
                    }}
                    data-id={dataId}
                    onClick={
                        isActive
                            ? onClick
                            : () => {
                                  setMigrationInfoOpen(isMigrating);
                              }
                    }
                >
                    <ProjectStatusBar status={project.status} />
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            flexDirection: isMobile ? "column" : undefined,
                            alignItems: "flex-start",
                            padding: 24,
                            paddingBottom: 16,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", height: "100%" }}>
                                <h3 style={textStyle}>{project.name}</h3>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        flexGrow: 1,
                                        marginTop: 8,
                                    }}
                                >
                                    <p style={textStyle}>
                                        {authStore.canCreateProjects && project.projectNumber && (
                                            <>
                                                {t("projects.externalId")} {project.projectNumber}
                                            </>
                                        )}
                                    </p>
                                    {project.fileCount !== undefined && project.fileCount !== null && (
                                        <p style={{ color: customColors.body2Dark }}>
                                            {project.fileCount} {t("common.files")}
                                        </p>
                                    )}
                                    {badgesComponent}
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                <div style={{ display: "flex" }}>
                                    {!isMobile && (
                                        <div style={{ display: "flex", alignItems: "center" }}>{statusComponent}</div>
                                    )}
                                    {project.status !== "deleted" && (
                                        <IconButton
                                            data-id="context_menu"
                                            style={{ padding: 0, marginLeft: 16 }}
                                            onClick={event => {
                                                event.stopPropagation();
                                                onClickMenu(event);
                                            }}
                                            disabled={isMigrating}
                                        >
                                            <Icon name="more" style={{ display: "block" }} />
                                        </IconButton>
                                    )}
                                    {project.status === "deleted" && !project.cleanUpPerformedAt && (
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                onClickRestore(project.id);
                                            }}
                                            disabled={!authStore.canCreateProjects || role !== "owner"}
                                        >
                                            {t("projects.activate")}
                                        </Button>
                                    )}
                                </div>
                                {!isMobile && (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                            alignItems: "flex-end",
                                            flexGrow: 1,
                                            marginTop: 8,
                                        }}
                                    >
                                        {permissionsComponent}
                                    </div>
                                )}
                            </div>
                        </div>
                        {isMobile && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 8,
                                    justifyContent: "flex-end",
                                    width: "100%",
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                    {statusComponent}
                                    {permissionsComponent}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmationDialog
                title={t("projects.international")}
                message={t("projects.international.migrating.message")}
                open={migrationInfoOpen}
                onConfirm={() => {
                    setMigrationInfoOpen(false);
                }}
                confirmLabel={t("dialog.button.understood")}
            />
        </>
    );
});
