import { TableBody } from "@material-ui/core";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Employee } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { formatDate, formatDateTime, getFormattedISODateOnlyRange } from "../../../util/date";
import { missingDataToString } from "../../../util/helpers";
import { getFullName } from "../../../util/user";
import { pushRoute } from "../../app/router/history";
import { useCurrentEmployees } from "../../hooks/useCurrentEmployees";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { useTableFilters } from "../../hooks/useTableFilters";
import { EmptyState } from "../../ui/EmptyState";
import {
    OptionalTooltip,
    TableLabel,
    TableRowButton,
    TpaTable,
    TpaTableCell,
    TpaTableContainer,
    TpaTableRow,
} from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { DATE_FILTER_TYPE, ITableFilters } from "../../ui/filter/types";
import { Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

export const HrEmployeesCurrentSite = observer(function HrEmployeesCurrentSite() {
    const { employees, setOnlyMissingDetails, setStartDate, setEndDate, loadEmployees, initialized, tableStore } =
        useCurrentEmployees();

    const onRegisterEmployee = () => {
        pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA);
    };

    const canUploadPayslip =
        companiesStore.selectedCompany?.localPaySlipUploadEnabled &&
        (authStore.isAdvisor || authStore.isResponsibleForModule("hr"));

    const headerFields: ITableHeaderConfig[] = compact([
        { column: "lastAuthenticatedAt", sort: false },
        { column: "name", label: "table.label.employee" },
        {
            column: "jobDescription",
            label: "table.label.jobDescription",
        },
        // { column: "missingDetails", label: "table.label.missingDetails" },
        { column: "dateOfJoining", label: "table.label.dateOfJoining" },
        { column: "personnelNumber", label: "table.label.personalId" },
        { column: "lastChangedAt", label: "table.label.updatedAt", sort: false },
        authStore.isAdvisor && employees.some(e => e.usernameChanged)
            ? { column: "changeUsername", label: "table.label.changeUsername", sort: false }
            : undefined,
    ]);

    const filters = useTableFilters({
        filters: [
            {
                category: "general",
                entries: [
                    // { name: "missingDetails", label: t("table.label.missingDetails") },
                    {
                        name: "anyDateOfJoining",
                        label: t("screen.hr.employee.current.anyDateOfJoining"),
                        type: DATE_FILTER_TYPE,
                        onChange: (date: Date) => {
                            const { startDate, endDate } = getFormattedISODateOnlyRange(date, "month");
                            setStartDate(startDate);
                            setEndDate(endDate);
                        },
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            tableStore.resetOffset();
            setOnlyMissingDetails(!!selected.missingDetails);

            if (!selected.anyDateOfJoining) {
                setStartDate("");
                setEndDate("");
            }
        },
    });

    const handleUploadPayslip = () => {
        pushRoute(HrRoutes.EMPLOYEES.UPLOAD_PAYSLIP, {
            query: { companyId: companiesStore.selectedCompanyId, subsidiaryId: hrStore.selectedSubsidiaryId },
        });
    };

    const sendChangeUsernameChangeEmailSuccessDialog = useSuccessDialog({
        title: t("screen.hr.employee.current.changeUsername.successDialog.title"),
    });

    const handleClickSendUsernameChangeEmail = async (employee: Employee) => {
        if (!companiesStore.selectedCompanyId || !employee.userId) {
            return;
        }

        sendChangeUsernameChangeEmailSuccessDialog.setMessage(
            <FormattedHTMLMessage
                id="screen.hr.employee.current.changeUsername.successDialog.message"
                values={{ userName: getFullName(employee) }}
            />,
        );

        try {
            generalStore.isLoading = true;
            await API.putChangeUsernameEmail(companiesStore.selectedCompanyId, employee.userId);
            await loadEmployees();
            sendChangeUsernameChangeEmailSuccessDialog.openDialog();
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.response.type === "NEW_USERNAME_DUPLICATED" &&
                apiError.statusCode === HttpStatusCode.Conflict_409
            ) {
                generalStore.setError(t("error.duplicateUsername"), error);
            } else {
                generalStore.setError(t("error.changeUsernameEmail"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    };

    const tableBody = (
        <TableBody>
            {employees.map((employee, index) => {
                return (
                    <TpaTableRow
                        key={employee.id}
                        onClick={() => {
                            pushRoute(HrRoutes.EMPLOYEES.DETAILS.replace(":employeeId", employee.id));
                        }}
                        role="button"
                        style={{ cursor: "pointer" }}
                        data-id={`employee_${index}`}
                    >
                        {headerFields.map(hf => {
                            let label, content;
                            if (hf.column === "name") {
                                label = getFullName(employee);
                            } else if (hf.column === "jobDescription") {
                                label = employee.jobDescription;
                            } else if (hf.column === "dateOfJoining") {
                                label = formatDate(employee.dateOfJoining);
                            } else if (hf.column === "missingDetails") {
                                label = missingDataToString(employee.missingDetails);
                            } else if (hf.column === "lastChangedAt") {
                                label = formatDate(employee.lastChangedAt);
                            } else if (hf.column === "personnelNumber") {
                                label = employee.personnelNumber;
                            } else if (hf.column === "changeUsername") {
                                if (employee.usernameChanged) {
                                    const { newUsername } = employee;
                                    content = (
                                        <OptionalTooltip
                                            title={
                                                newUsername
                                                    ? t("screen.hr.employee.current.changeUsername.tooltip", {
                                                          newUsername,
                                                      })
                                                    : ""
                                            }
                                        >
                                            <TableRowButton
                                                color="primary"
                                                onClick={ev => {
                                                    ev.stopPropagation();
                                                    handleClickSendUsernameChangeEmail(employee);
                                                }}
                                                style={{ textTransform: "uppercase" }}
                                            >
                                                {t("screen.hr.employee.current.changeUsername")}
                                            </TableRowButton>
                                        </OptionalTooltip>
                                    );
                                }
                            } else if (hf.column === "lastAuthenticatedAt") {
                                return (
                                    <TpaTableCell
                                        key={hf.column}
                                        style={{ padding: 16, width: 0, lineHeight: 0, paddingRight: 0 }}
                                    >
                                        <OptionalTooltip
                                            title={
                                                employee.lastAuthenticatedAt
                                                    ? t("table.label.lastAuthenticatedAt.registered.tooltip") +
                                                      "\n" +
                                                      t("table.label.lastAuthenticatedAt") +
                                                      ": " +
                                                      formatDateTime(employee.lastAuthenticatedAt)
                                                    : t("table.label.lastAuthenticatedAt.unregistered.tooltip")
                                            }
                                        >
                                            <Icon
                                                name="user"
                                                style={{
                                                    color: employee.lastAuthenticatedAt
                                                        ? customColors.primaryColor
                                                        : customColors.redUrgent,
                                                    marginRight: "4px",
                                                }}
                                            />
                                        </OptionalTooltip>
                                    </TpaTableCell>
                                );
                            }

                            return (
                                <TpaTableCell key={hf.column} style={{ padding: 16 }}>
                                    {content ?? (label && <TableLabel>{label}</TableLabel>)}
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    if (!initialized) {
        return null;
    }

    const canEdit = authStore.canEditEmployees(hrStore.selectedSubsidiaryId);

    const actions = {
        registerEmployee: companiesStore.selectedCompany?.preRegistrationEnabled
            ? {
                  label: t("screen.hr.employees.current.registerEmployee"),
                  onClick: onRegisterEmployee,
              }
            : undefined,
        uploadPayslip: canUploadPayslip
            ? {
                  label: t("screen.hr.employees.current.uploadPayslip"),
                  onClick: handleUploadPayslip,
              }
            : undefined,
    };
    return (
        <>
            {tableStore.getIsEmptyState(generalStore.isLoading, !isEmpty(filters.activeFilters)) ? (
                <EmptyState
                    title={t("screen.hr.employee.emptystate.title")}
                    message={t("screen.hr.employee.emptystate.message")}
                    buttonLabel={actions.registerEmployee?.label}
                    onAddEntry={actions.registerEmployee?.onClick}
                    data-id="empty_state"
                    disabled={!canEdit}
                />
            ) : (
                <>
                    <SiteContent>
                        <TableSearchBarWithAction
                            label="search.caption.numPersons"
                            placeholder="search.placeholder.searchForEmployeesPersonalNumber"
                            search={tableStore.search}
                            totalCount={tableStore.totalCount}
                            onChangeSearch={tableStore.handleSearchChange}
                            disabled={!canEdit}
                            buttonLabel={actions.registerEmployee?.label}
                            onAction={actions.registerEmployee?.onClick}
                            buttonLabelSecondary={actions.uploadPayslip?.label}
                            onActionSecondary={actions.uploadPayslip?.onClick}
                            tableFilters={filters}
                        />
                        <TpaTableContainer>
                            <TpaTable>
                                <TableHeader headerFields={headerFields} tableStore={tableStore} />
                                {tableBody}
                            </TpaTable>
                        </TpaTableContainer>
                        <tableStore.Pagination />
                    </SiteContent>
                    {tableStore.getIsNoResultState(generalStore.isLoading, !isEmpty(filters.activeFilters)) && (
                        <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                    )}
                </>
            )}
            {sendChangeUsernameChangeEmailSuccessDialog.dialog}
        </>
    );
});
