export const Routes = {
    ROOT: "/",
    COCKPIT: "/cockpit",
    HR: "/hr",
    PROJECTS: "/projects",
    DOCS: "/docs",
    SETTINGS: "/settings",
    RESULTS: "/results",
    ANALYSIS: "/analysis",
    SUPPORT: "/support",
    TICKETS: "/tickets",
    KPIS: "/kpis",
    ACCOUNTING: "/accounting",
    TEST: "/test",
    ERROR: "/error",
    ADVISOR: "/advisor",
    COMPANY_OVERVIEW: "/companies",
    COMPANIES_USERS_RELEASE: "/companies/users/release",
    COMPANIES_USERS_RELEASE_ROLES: "/companies/:companyId/users/release/roles",
    COMPANIES_USERS_RELEASE_PERMISSIONS: "/companies/:companyId/users/release/permissions",
    WEBHOOKS: "/webhooks",
    USER_PROFILE: "/profile",
    MS_UPGRADE: "/upgrade",
    AUTH: "/auth",
    OAUTH: "/oauth/auth",
    LEGAL_NOTICE: "/legal-notice",
    PRIVACY_POLICY: "/privacy-policy",
    STAFF: "/staff",
    EXTERNAL: "/external/:name",
    TERMS_OF_USE: "/terms-of-use",
    TERMS_NOT_ACCEPTED: "/terms-not-accepted",
    NOT_FOUND: "/404",
    LOADING: "/loading",

    prefixed:
        <P extends string>(prefix: P) =>
        <T extends string>(route: T): `${P}${T}` =>
            `${prefix}${route}`,
} as const;
