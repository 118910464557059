import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    useMediaQuery,
    withStyles,
} from "@material-ui/core";
import flatMap from "lodash/flatMap";
import fromPairs from "lodash/fromPairs";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import { observer } from "mobx-react";
import * as React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { Redirect, useRouteMatch } from "react-router";
import { GLOBAL_FEATURES } from "../../../features";
import { IMessageIDS, t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    GetCompaniesForPersonResponseItem,
    PermissionsRequest,
    Role,
    RoleRequest,
    UserPermissions,
} from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { companiesStore } from "../../../stores/CompaniesStore";
import { IConfigUser, configStore } from "../../../stores/ConfigStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { debug } from "../../../util/debug";
import { customerRoleMapping } from "../../../util/permissionConfig";
import {
    areValidRolePermissions,
    filterRealRoles,
    getApplyRoleButtonText,
    getSuccessDialogMessage,
    getSuccessDialogTitle,
    hasAnyAction,
    permissionsToMap,
} from "../../../util/permissionHelpers";
import { getFullName } from "../../../util/user";
import { AdvisorRoutes } from "../../advisor/router/AdvisorRoutes";
import { Routes } from "../../app/router/Routes";
import { pushRoute, withParams, withParamsAndQuery, withQuery } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { useNoPermissionSelectedDialog } from "../../hooks/useNoPermissionSelectedDialog";
import { usePermissions } from "../../hooks/usePermissions";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { SettingsRoutes } from "../../settings/router/SettingsRoutes";
import { AutocompleteUsers } from "../../ui/AutocompleteUsers";
import { CenteredContent } from "../../ui/CenteredContent";
import { CustomDialog, CustomDialogContent } from "../../ui/CustomDialog";
import { NavBarBack } from "../../ui/NavBarBack";
import { OverviewContainer, TpaWhiteButton } from "../../ui/Primitives";
import { ResponsiveButtonContainer } from "../../ui/ResponsiveButtonContainer";
import { SiteContent } from "../../ui/SiteContent";
import { Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { DIALOG_WIDTH, customColors } from "../../util/Theme";
import { FailedUserDialog, IFailedUser } from "../FailedUserDialog";
import { PermissionChangeInformation } from "../PermissionChangeInformation";

interface IRoleOption {
    role: RoleRequest | ""; // "" if it is copy from pseudo-role
    male: IMessageIDS;
    female?: IMessageIDS;
    description: IMessageIDS;
}

// TODO: Add project-user role once it's added in the BE
// Attention: make sure ALL RoleRequest union members are present here. Alas, typescript does not allow union -> tuple conversion https://github.com/Microsoft/TypeScript/issues/13298
const roles: RoleRequest[] = [
    "none",
    "admin",
    "accounting-management",
    "hr-management",
    "assistent",
    "accounting-assistent",
    "hr-assistent",
    "custom",
];

const roleOptions: IRoleOption[] = roles.map(role => ({
    role,
    ...customerRoleMapping[role],
}));

const StyledFormControlLabel = withStyles({
    label: {
        width: "100%",
    },
})(FormControlLabel);

const StyledRadio = withStyles({
    root: {
        color: "#B4B8B8",
    },
})(Radio);

interface IPermissionRadioProps {
    option: IRoleOption;
    searchFieldComponent?: React.ReactNode;
    disabled?: boolean;
    style?: React.CSSProperties;
}

const PermissionRadio = ({
    option: { role, male, description },
    searchFieldComponent,
    disabled,
    style,
}: IPermissionRadioProps) => (
    <StyledFormControlLabel
        value={role}
        control={<StyledRadio data-id={role} color="primary" disabled={disabled} />}
        label={
            <div style={{ paddingTop: 10 }}>
                <p style={{ color: disabled ? customColors.greyTextfields : undefined }}>
                    <FormattedHTMLMessage id={male} />
                </p>
                <p className="caption" style={{ color: disabled ? customColors.greyTextfields : undefined }}>
                    <FormattedHTMLMessage id={description} />
                </p>
                {searchFieldComponent}
                <Divider style={{ marginTop: 12, marginBottom: 4 }} />
            </div>
        }
        style={{ marginTop: 0, alignItems: "flex-start", ...style }}
    />
);

// TABLE ACTIONS
// SETTINGS BERATER SUPERADMIN FREIGEBEN
// Benutzer erfolgreich eingeladen
// {userName} erhält eine Einladung per E-Mail.

// SETTINGS BERATER EINLADEN
// Benutzer erfolgreich vorgeschlagen
// Ihr Vorschlag muss nun von einem <b>Super Admin</b> freigegeben werden

// SETTINGS ADMIN FREIGEBEN & EINLADEN
// Benutzer erfolgreich freigegeben und eingeladen
// {count} Benutzer erhalten eine Einladung per E-Mail.
// {userName} erhält eine Einladung per E-Mail.

// USERMANAGEMENT DONE
// CONFIG BERATER EXISTING USER
// Benutzereinstellungen erfolgreich vorgeschlagen
// Ihr Vorschlag muss nun von einem <b>Super Admin</b> freigegeben werden

// CONFIG BERATER NEW USER
// Benutzer erfolgreich vorgeschlagen
// Ihr Vorschlag muss nun von einem <b>Super Admin</b> freigegeben werden

// SETTINGS ADMIN NEW USER
// Benutzer erfolgreich eingeladen
// {count} Benutzer erhalten eine Einladung per E-Mail.
// {userName} erhält eine Einladung per E-Mail.

const isRoleRequestType = (role?: string): role is RoleRequest => {
    return includes(roles, role);
};

export const UserRolesSite = observer(function UserRolesSite() {
    useHideSideBar();

    const advisorConfigUserRolesMatch = useRouteMatch<{ companyId: string }>(AdvisorRoutes.CONFIG.USER_ROLES);
    const companiesUsersReleaseRolesMatch = useRouteMatch<{ companyId: string }>(Routes.COMPANIES_USERS_RELEASE_ROLES);
    let companyId = advisorConfigUserRolesMatch?.params.companyId ?? companiesUsersReleaseRolesMatch?.params.companyId;
    if (!companyId && companiesStore.selectedCompanyId) {
        companyId = companiesStore.selectedCompanyId;
    }
    const company = useCompany({ companyId, preloaded: companiesStore.selectedCompany }).company;
    const permissions = usePermissions(company?.id).parsed;

    const isMobile = React.useContext(MobileContext);
    const showSelectedEmployeesBar = useMediaQuery(`(min-width: 1334px)`, { noSsr: true });

    const [failedUsers, setFailedUsers] = React.useState<IFailedUser[]>([]);
    const [selectedRole, setSelectedRole] = React.useState<IRoleOption["role"] | null>(null);
    const [userForCopy, setUserForCopy] = React.useState<UserPermissions | null>(null);
    const noPermissionSelectedDialog = useNoPermissionSelectedDialog();

    let availableRoleOptions: IRoleOption[] = [...roleOptions];
    if (!company?.hasAccounting) {
        availableRoleOptions = availableRoleOptions.filter(
            option => option.role !== "accounting-management" && option.role !== "accounting-assistent",
        );
    }
    if (!company?.hasHR) {
        availableRoleOptions = availableRoleOptions.filter(
            option => option.role !== "hr-management" && option.role !== "hr-assistent",
        );
    }
    if (!company?.hasAccounting && !company?.hasHR) {
        availableRoleOptions = availableRoleOptions.filter(option => option.role !== "assistent");
    }

    const handleMoveToNextSite = () => {
        configStore.wipe();
        if (companyId && advisorConfigUserRolesMatch) {
            pushRoute(withParams(AdvisorRoutes.CONFIG.USERS, { companyId }));
        } else if (companyId && companiesUsersReleaseRolesMatch) {
            pushRoute(Routes.COMPANIES_USERS_RELEASE);
        } else {
            pushRoute(SettingsRoutes.USER_MANAGEMENT.ROOT);
        }
    };

    const selectedAreUsers = configStore.selectedEmployees.every(employee => employee.type === "user");
    const isReadOnlyView = configStore.selectedEmployees.some(employee => employee.needsRelease);

    const successDialog = useSuccessDialog({
        title: getSuccessDialogTitle(!!permissions?.isSuperAdmin, selectedAreUsers),
        message: getSuccessDialogMessage(!!permissions?.isSuperAdmin, selectedAreUsers, configStore.selectedEmployees),
        onClose: handleMoveToNextSite,
    });

    const multipleCompaniesDialog = useMultipleCompaniesDialog();

    React.useEffect(() => {
        // Initialize selected role -> checks correct radio button
        const initializeRole = () => {
            const setRoleFromMultiple = (roles: Role[]) => {
                // staff and international-projects are not valid FE roles -> filter them
                const uniqRoles = filterRealRoles(uniq(roles));

                if (uniqRoles.length === 0) {
                    // User has no role set after creation
                    setSelectedRole("none");
                } else if (uniqRoles.length === 1 && isRoleRequestType(uniqRoles[0])) {
                    setSelectedRole(uniqRoles[0]);
                } else {
                    setSelectedRole("custom");
                }
            };

            if (!configStore.permissions && !configStore.defaultPermissions) {
                if (selectedAreUsers) {
                    const allRoles = flatMap(configStore.selectedEmployees, employee => employee.roles ?? []);
                    setRoleFromMultiple(allRoles);
                } else {
                    setSelectedRole("admin");
                }
            } else if (configStore.permissions && configStore.defaultPermissions) {
                const allPermissionsAreEqual = configStore.selectedEmployees.every(employee => {
                    // Check if current permissions are the same as the default permissions -> if yes, we match a role
                    return isEqual(
                        permissionsToMap(configStore.permissions?.[employee.id]),
                        permissionsToMap(configStore.defaultPermissions?.[employee.id]),
                    );
                });

                if (allPermissionsAreEqual) {
                    const allRoleArrays = Object.keys(configStore.permissions || {}).map(
                        employeeId => configStore.permissions?.[employeeId].roles ?? [],
                    );
                    const allRoles = flatMap(allRoleArrays, rolesArray => rolesArray);
                    setRoleFromMultiple(allRoles);
                } else {
                    setSelectedRole("custom");
                }
            }
        };

        initializeRole();
    }, [selectedAreUsers]);

    if (advisorConfigUserRolesMatch && !companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }
    if (companiesUsersReleaseRolesMatch && !companyId) {
        // No company in route -> get out
        return <Redirect to={Routes.COMPANIES_USERS_RELEASE} />;
    }

    if (configStore.selectedEmployees.length === 0) {
        if (advisorConfigUserRolesMatch && companyId) {
            pushRoute(withParams(AdvisorRoutes.CONFIG.USERS, { companyId }));
        } else if (companiesUsersReleaseRolesMatch && companyId) {
            pushRoute(Routes.COMPANIES_USERS_RELEASE);
        } else {
            pushRoute(SettingsRoutes.USER_MANAGEMENT.ROOT);
        }
        return null;
    }

    if (!company || !permissions) {
        // Company/Permissions not loaded yet -> wait
        return null;
    }

    if (selectedRole === null) {
        return null;
    }

    const openMultipleCompaniesDialog = async (
        employee: IConfigUser,
        companies: GetCompaniesForPersonResponseItem[],
    ) => {
        return new Promise<boolean>(resolve => {
            multipleCompaniesDialog.open({ employee, companies, onSubmit: resolve });
        });
    };

    const handleChangeRole = (_: unknown, value: string) => {
        setSelectedRole(value as IRoleOption["role"]);
    };

    // Initialize permissions from a selected role (instead of selected user's permission set)
    const initFromRole = async (role: RoleRequest) => {
        if (!companyId) {
            return;
        }

        try {
            // Get permission preset for a role from backend
            const permissions = await API.getRolePermissions(companyId, role);

            // Verify that backend permissions can be mapped onto UI options in permissionOptionsMap
            if (!areValidRolePermissions(permissions, role)) {
                generalStore.setError(t("error.invalidRolePermissions"));
            }

            const initialPermissionsState = fromPairs(
                configStore.selectedEmployees.map(employee => [employee.id, permissions]),
            );

            configStore.permissions = initialPermissionsState;
            configStore.defaultPermissions = initialPermissionsState;

            // Reset the state for UserPermissionsSite
            configStore.permissionsState = null;
        } catch (error) {
            generalStore.setError(t("error.loadRolePermissions"), error);
        }
    };

    const setPreselectedState = async () => {
        if (!companyId) {
            return;
        }

        if (selectedRole) {
            if (selectedAreUsers && selectedRole === "custom") {
                try {
                    for (let i = 0; i < configStore.selectedEmployees.length; i++) {
                        const employee = configStore.selectedEmployees[i];

                        if (!configStore.permissions?.[employee.id]) {
                            // We use it for request so cast it to needed type
                            const permissions = (await API.getUserPermissions(
                                companyId,
                                employee.id,
                            )) as PermissionsRequest;

                            // Append to permissions and default permissions array
                            configStore.permissions = { ...configStore.permissions, [employee.id]: permissions };
                            configStore.defaultPermissions = {
                                ...configStore.defaultPermissions,
                                [employee.id]: permissions,
                            };
                        }
                    }
                } catch (error) {
                    generalStore.setError(t("error.loadPermissions"), error);
                }
            } else if (selectedRole !== "custom" || !configStore.permissions) {
                await initFromRole(selectedRole as RoleRequest);
            }
        } else if (userForCopy) {
            let userWithPermissions;
            try {
                userWithPermissions = await API.getUserPermissions(companyId, userForCopy.id);
            } catch (error) {
                generalStore.setError(t("error.loadPermissions"), error);
                return;
            }

            const copyFrom = userWithPermissions.changeRequest ?? userWithPermissions;

            // Copy from other user
            const roles = copyFrom.roles.filter(role => role !== "staff");
            const role = roles.length > 0 ? roles[0] : "custom";
            if (role !== "custom") {
                await initFromRole(role as RoleRequest);
            } else {
                for (let i = 0; i < configStore.selectedEmployees.length; i++) {
                    const employee = configStore.selectedEmployees[i];

                    const initial: PermissionsRequest = {
                        global: copyFrom.global,
                        roles: copyFrom.roles as RoleRequest[],
                        subsidiaries: copyFrom.subsidiaries,
                    };

                    if (!configStore.permissions?.[employee.id]) {
                        // Append to permissions and default permissions array
                        configStore.permissions = { ...configStore.permissions, [employee.id]: initial };
                        configStore.defaultPermissions = {
                            ...configStore.defaultPermissions,
                            [employee.id]: initial,
                        };
                    }
                }
            }
        }
    };

    const handleClickCustomizePermissions = async () => {
        await setPreselectedState();

        if (advisorConfigUserRolesMatch && companyId) {
            pushRoute(
                withParamsAndQuery(
                    AdvisorRoutes.CONFIG.USER_PERMISSIONS,
                    { companyId },
                    { employeeId: configStore.selectedEmployees[0].id },
                ),
            );
        } else if (companiesUsersReleaseRolesMatch && companyId) {
            pushRoute(
                withParamsAndQuery(
                    Routes.COMPANIES_USERS_RELEASE_PERMISSIONS,
                    { companyId },
                    { employeeId: configStore.selectedEmployees[0].id },
                ),
            );
        } else {
            pushRoute(
                withQuery(SettingsRoutes.USER_MANAGEMENT.USER_PERMISSIONS, {
                    employeeId: configStore.selectedEmployees[0].id,
                }),
            );
        }
    };

    const handleClickApply = async () => {
        await setPreselectedState();

        const selectedAreUsers = configStore.selectedEmployees.every(employee => employee.type === "user");

        const failedUserItems: IFailedUser[] = [];

        const employees =
            failedUsers.length > 0
                ? failedUsers.map(failedEmployee => failedEmployee.employee)
                : configStore.selectedEmployees;

        const anyPermissionSelected = configStore.selectedEmployees.every(employee => {
            const permissions = configStore.permissions?.[employee.id];
            const actionsSelected = permissions ? hasAnyAction(permissions) : false;

            return actionsSelected;
        });

        if (anyPermissionSelected || selectedAreUsers) {
            generalStore.isLoading = true;

            for (let i = 0; i < employees.length; i++) {
                const employee = employees[i];
                const isUser = employee.type === "user";

                const userPermissions = configStore.permissions?.[employee.id];
                if (!userPermissions) {
                    continue;
                }

                let companies: GetCompaniesForPersonResponseItem[] = [
                    {
                        id: company.id,
                        name: company.name,
                        personID: isUser ? undefined : employee.id,
                        userID: isUser ? employee.id : undefined,
                    },
                ];

                if (roleCanBeSetInMultipleCompanies(selectedRole)) {
                    try {
                        const response = await API.getCompaniesForPerson(
                            company.id,
                            isUser ? { userID: employee.id } : { personID: employee.id },
                        );
                        if (response.companies.length > 1) {
                            generalStore.isLoading = false;
                            const applyInAllCompanies = await openMultipleCompaniesDialog(employee, response.companies);
                            generalStore.isLoading = true;
                            if (applyInAllCompanies) {
                                companies = response.companies;
                            }
                        }
                    } catch (error) {
                        debug.error("### Error getting companies", error);
                        generalStore.addNotification({
                            severity: "warning",
                            message: t("config.roles.multipleCompaniesDialog.warning"),
                        });
                    }
                }

                const userDefaultPermissions = configStore.defaultPermissions?.[employee.id];
                const permissionsEqualRole = isEqual(userPermissions, userDefaultPermissions);
                const permissionsRequest: PermissionsRequest =
                    permissionsEqualRole && userDefaultPermissions && userDefaultPermissions.roles[0] !== "custom"
                        ? {
                              roles: [userDefaultPermissions.roles[0]],
                          }
                        : userPermissions;

                for (const { id, userID, personID } of companies) {
                    try {
                        if (userID) {
                            await API.putUserPermissions(id, userID, permissionsRequest);
                        } else if (personID) {
                            await API.postUser(id, {
                                personId: personID,
                                email: employee.email,
                                permissions: permissionsRequest,
                            });
                        }
                    } catch (error) {
                        const apiError = getApiError(error);
                        debug.error(userID ? "### Error applying permissions" : "### Error creating user", error);
                        failedUserItems.push({ employee, statusCode: apiError?.statusCode ?? 500 });
                    }
                }
            }

            generalStore.isLoading = false;

            if (failedUserItems.length > 0) {
                setFailedUsers(failedUserItems);
            } else {
                successDialog.openDialog();
            }
        } else {
            noPermissionSelectedDialog.open();
        }
    };

    const selectedEmployeesList = configStore.selectedEmployees.map(employee => (
        <React.Fragment key={employee.id}>
            <div style={{ display: "flex", alignItems: "center", height: 56 }}>
                <div>
                    <Icon name="user" style={{ display: "block", color: customColors.greyDarkIcons }} />
                </div>
                <span style={{ marginLeft: 16 }}>
                    {employee.firstName} {employee.lastName}
                </span>
            </div>
            <Divider />
        </React.Fragment>
    ));

    const navBarTitle =
        configStore.selectedEmployees.length === 1
            ? getFullName(configStore.selectedEmployees[0])
            : t("config.roles.navbar.heading", { employeeCount: configStore.selectedEmployees.length });

    let navBar;
    if (advisorConfigUserRolesMatch && companyId) {
        const backLabel = selectedAreUsers ? t("config.users.navBar.back") : t("config.roles.navbar.back");
        const backTarget = selectedAreUsers ? AdvisorRoutes.CONFIG.USERS : AdvisorRoutes.CONFIG.USERS_EMPLOYEES;
        navBar = (
            <NavBarBack
                title={navBarTitle}
                backLabel={backLabel}
                backTarget={withParams(backTarget, { companyId })}
                companyName={company.name}
            />
        );
    } else if (companiesUsersReleaseRolesMatch && companyId) {
        navBar = (
            <NavBarBack
                title={navBarTitle}
                backLabel={t("companiesUserRelease.title")}
                backTarget={Routes.COMPANIES_USERS_RELEASE}
                companyName={company.name}
            />
        );
    } else {
        const backLabel = selectedAreUsers ? t("config.users") : t("settings.userManagement.roles.navbar.back");
        const backTarget = selectedAreUsers
            ? SettingsRoutes.USER_MANAGEMENT.ROOT
            : SettingsRoutes.USER_MANAGEMENT.USERS_EMPLOYEES;
        navBar = <NavBarBack title={navBarTitle} backLabel={backLabel} backTarget={backTarget} />;
    }

    return (
        <>
            {navBar}
            <CenteredContent>
                <SiteContent>
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            justifyContent: "space-between",
                            paddingTop: isMobile ? 16 : 32,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                maxWidth: isMobile ? undefined : DIALOG_WIDTH,
                                marginRight: 16,
                            }}
                        >
                            <h1>{t("config.roles.title")}</h1>
                            <p style={{ marginTop: 16, fontSize: 12 }}>{t("config.roles.description")}</p>
                            {isReadOnlyView && (
                                <PermissionChangeInformation
                                    configUser={configStore.selectedEmployees[0]}
                                    companyId={company.id}
                                    permissions={permissions}
                                    style={{
                                        margin: "24px 0",
                                    }}
                                />
                            )}
                            {!isReadOnlyView && (
                                <FormControl component="fieldset" style={{ marginTop: 16 }}>
                                    <RadioGroup value={selectedRole} onChange={handleChangeRole}>
                                        {availableRoleOptions.map(option => (
                                            <PermissionRadio
                                                key={option.role}
                                                option={option}
                                                disabled={!selectedAreUsers && option.role === "none"}
                                            />
                                        ))}
                                        {GLOBAL_FEATURES.copyRoleFromUser && (
                                            <PermissionRadio
                                                option={{
                                                    role: "",
                                                    male: "config.roles.option.copy.label",
                                                    description: "config.roles.option.copy.description",
                                                }}
                                                searchFieldComponent={
                                                    <AutocompleteUsers
                                                        companyId={companyId}
                                                        style={{ marginTop: 16, width: "100%" }}
                                                        disabled={selectedRole !== ""}
                                                        disableSuperAdmins
                                                        disableRoleNone
                                                        disabledUserIds={configStore.selectedEmployees.map(
                                                            employee => employee.id,
                                                        )}
                                                        onSelectUser={user => {
                                                            setUserForCopy(user);
                                                        }}
                                                    />
                                                }
                                            />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            )}
                            {configStore.selectedEmployees.length > 1 && !showSelectedEmployeesBar && (
                                <OverviewContainer fullWidth>
                                    <h4>{t("config.roles.sidebar.title")}</h4>
                                    <div style={{ marginTop: 12 }}>{selectedEmployeesList}</div>
                                </OverviewContainer>
                            )}
                            <ResponsiveButtonContainer style={{ marginTop: 40 }}>
                                <Button
                                    data-id="button_customize_permissions"
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickCustomizePermissions}
                                    disabled={!selectedRole && !userForCopy}
                                >
                                    {isReadOnlyView
                                        ? t("config.roles.viewPermissions")
                                        : t("config.roles.customizePermissions")}
                                </Button>
                                {!isReadOnlyView && (
                                    <Button
                                        data-id="button_apply"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClickApply}
                                        disabled={!selectedRole && !userForCopy}
                                    >
                                        {getApplyRoleButtonText(
                                            !!advisorConfigUserRolesMatch,
                                            permissions.isSuperAdmin,
                                            selectedAreUsers,
                                        )}
                                    </Button>
                                )}
                            </ResponsiveButtonContainer>
                        </div>
                    </div>
                </SiteContent>
            </CenteredContent>
            {configStore.selectedEmployees.length > 1 && showSelectedEmployeesBar && (
                <div
                    style={{
                        position: "absolute",
                        right: 0,
                        height: `calc(100vh - var(--navBarHeight)`,
                        top: `calc(var(--navBarHeight))`,
                        padding: "56px 32px 0 32px",
                        width: 520,
                        backgroundColor: customColors.white,
                        overflowY: "auto",
                    }}
                >
                    <h4>{t("config.roles.sidebar.title")}</h4>
                    <div style={{ marginTop: 12 }}>{selectedEmployeesList}</div>
                </div>
            )}
            <FailedUserDialog
                failedUsers={failedUsers}
                totalUsers={configStore.selectedEmployees.length}
                open={failedUsers.length > 0}
                onRetry={handleClickApply}
                onSubmit={handleMoveToNextSite}
            />
            {noPermissionSelectedDialog.component}
            {successDialog.dialog}
            {multipleCompaniesDialog.dialog}
        </>
    );
});

interface MultipleCompaniesDialogData {
    employee: IConfigUser;
    companies: GetCompaniesForPersonResponseItem[];
    onSubmit: (applyInAllCompanies: boolean) => void;
}

const useMultipleCompaniesDialog = () => {
    const [data, setData] = React.useState<MultipleCompaniesDialogData>();

    const handleSubmit = (applyInAllCompanies: boolean) => {
        data?.onSubmit(applyInAllCompanies);
        setData(undefined);
    };

    const dialog = (
        <CustomDialog open={!!data}>
            <CustomDialogContent>
                <h1>{t("config.roles.multipleCompaniesDialog.title")}</h1>
                <p style={{ marginTop: 24, marginBottom: 24 }}>
                    {t("config.roles.multipleCompaniesDialog.message", { name: getFullName(data?.employee) })}
                </p>
                <ul style={{ marginBottom: 24, paddingLeft: 16 }}>
                    {data?.companies.map(company => {
                        return <li key={company.id}>{company.name}</li>;
                    })}
                </ul>
                <ResponsiveButtonContainer>
                    <TpaWhiteButton
                        onClick={() => {
                            handleSubmit(false);
                        }}
                    >
                        {t("common.no")}
                    </TpaWhiteButton>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleSubmit(true);
                        }}
                    >
                        {t("common.yes")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );

    return {
        open: setData,
        dialog,
    };
};

function roleCanBeSetInMultipleCompanies(role: IRoleOption["role"]) {
    // at the moment only "none" and "admin" roles can be set in multiple companies
    return role === "none" || role === "admin";
}
