import { withStyles } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { customColors } from "../util/Theme";

export const StyledPagination = withStyles({
    root: {
        marginTop: 24,
    },
    ul: {
        justifyContent: "center",
    },
})(Pagination);

export const StyledPaginationItem = withStyles({
    root: {
        width: 32,
        height: 32,
        // numbers are light grey
        color: customColors.greyTextfields,
    },
    selected: {
        // selected page is black bold with white circle
        color: "black !important",
        backgroundColor: "white !important",
        fontWeight: "bold",
    },
    sizeSmall: {
        fontSize: 14,
    },
    icon: {
        // right and left arrow are bold, bigger with white circle
        color: "black",
        fontSize: "22px !important",
        backgroundColor: "white",
        boxShadow: "0 0 0 6px white",
        borderRadius: "100%",
    },
    disabled: {
        // disable arrow is invisible
        opacity: "0 !important",
    },
})(PaginationItem);

interface IProps {
    totalCount: number;
    rowsPerPage: number;
    page: number;
    onChangePage: (page: number) => void;
    "data-id"?: string;
}

export const TablePagination = ({ totalCount: count, rowsPerPage, page, onChangePage, "data-id": dataId }: IProps) => {
    const pageCount = Math.ceil(count / rowsPerPage);
    const handleChangePage = (_: unknown, page: number) => {
        onChangePage(page);
    };

    return pageCount > 1 ? (
        <StyledPagination
            color="primary"
            count={pageCount}
            page={page}
            onChange={handleChangePage}
            size="small"
            data-id={dataId}
            renderItem={item => <StyledPaginationItem {...item} data-id={`page_${item.page}`} />}
        />
    ) : null;
};
