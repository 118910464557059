import { BalanceSheetQuery } from "../../network/API";
import { AccountingViews, Variant } from "./types";

export function formatAccountNumber(nr: number) {
    return `${nr}`.padStart(4, "0");
}

export const variantToQuery: Record<Variant, BalanceSheetQuery> = {
    currentYear: {
        previousYears: 0,
    },
    currentAndPastYear: {
        previousYears: 1,
    },
    currentAndPastYearAbsoluteChange: {
        previousYears: 1,
    },
    currentAndPastYearPercentageChange: {
        previousYears: 1,
    },
};

export const supportsOrderBy: Record<AccountingViews, boolean> = {
    accountListing: true,
    customerListing: true,
    vendorListing: true,
    balanceSheet: false,
    cashAccounting: false,
    profitAndLoss: false,
};
