import { Divider, withStyles } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import * as React from "react";
import { IIconNames, Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";
import { TpaBadge } from "../Primitives";

export interface ISideBarItemConfig {
    iconName: IIconNames;
    title: string;
    target: string;
    divider?: boolean;
    enabled?: boolean;
    badgeCount?: number;
    isOptionalMenuItem?: boolean;
}

const StyledListItem = withStyles({
    selected: {
        backgroundColor: `${customColors.primaryShade} !important`,
        color: `${customColors.primaryColor} !important`,
    },
    gutters: {
        paddingLeft: 12,
    },
})(ListItem);

export const SideBarItem = (props: {
    iconName: IIconNames;
    title: string;
    selected: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    divider?: boolean;
    badgeCount?: number;
    "data-id": string;
}) => {
    return (
        <>
            <StyledListItem
                button
                selected={props.selected}
                onClick={props.onClick}
                style={{ borderLeft: props.selected ? `2px solid ${customColors.primaryColor}` : undefined }}
                data-id={props["data-id"]}
            >
                <Icon name={props.iconName} />
                <span
                    style={{
                        marginLeft: 16,
                        color: props.selected ? customColors.primaryColor : undefined,
                        fontWeight: props.selected ? "bold" : undefined,
                    }}
                >
                    {props.title}
                </span>
                {!!props.badgeCount && <TpaBadge style={{ marginLeft: 8 }}>{props.badgeCount}</TpaBadge>}
            </StyledListItem>
            {props.divider === true && (
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Divider style={{ color: customColors.greyLight, width: "100%", margin: "16px 0px" }} />
                </div>
            )}
        </>
    );
};
