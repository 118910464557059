import { Button } from "@material-ui/core";
import { ModuleStore } from "../../stores/ModuleStore";
import { Status } from "../../stores/Status";
import { pushRoute } from "../app/router/history";

export const ModuleAddRecordsButton = ({ moduleStore, status }: { moduleStore: ModuleStore; status: Status }) => {
    const handleAddRecords = () => {
        pushRoute(moduleStore.routes.RECORDS.ROOT);
    };

    return (
        <div style={{ marginTop: 16, alignSelf: "flex-end" }}>
            <Button
                disabled={
                    !moduleStore.canEditAnySubsidiaryRecords(moduleStore.selectedSubsidiaryId) ||
                    moduleStore.selectedPeriod?.finished
                }
                variant="contained"
                color="primary"
                onClick={handleAddRecords}
                data-id="welcomecard_addRecords_button"
            >
                {moduleStore.t(
                    status !== "closed"
                        ? "welcomeCard.accounting.addRecords.button"
                        : "welcomeCard.accounting.addMoreRecords.button",
                )}
            </Button>
        </div>
    );
};
