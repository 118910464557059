import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { ICredentials, authStore } from "../../../stores/AuthStore";
import { coordinator } from "../../../stores/Coordinator";
import { pushRoute } from "../../app/router/history";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { useHash } from "../../hooks/useHash";
import { AuthForgotPasswordConfirmSite } from "../sites/AuthForgotPasswordConfirm";
import { AuthForgotPasswordEmailSite } from "../sites/AuthForgotPasswordEmailSite";
import { AuthForgotPasswordResetSite } from "../sites/AuthForgotPasswordReset";
import { AuthMSSite } from "../sites/AuthMSSite";
import { AuthRegistrationSite } from "../sites/AuthRegistrationSite";
import { AuthWelcomeSite } from "../sites/AuthWelcomeSite";
import { AuthRoutes } from "./AuthRoutes";

const LocalCredentials = () => {
    const credentials = useHash<ICredentials>();

    React.useEffect(() => {
        const login = async () => {
            authStore.credentials = credentials as ICredentials;
            try {
                await coordinator.refreshAfterLogin();
            } catch (error) {
                pushRoute("/");
            }
        };

        login();
    }, [credentials]);

    return null;
};

export const AuthRouter = () => {
    return (
        <Switch>
            <Route exact path={AuthRoutes.WELCOME}>
                <AuthWelcomeSite />
            </Route>
            <Route exact path={AuthRoutes.MS}>
                <AuthMSSite />
            </Route>
            <Route exact path={AuthRoutes.LOCAL_CREDENTIALS}>
                <LocalCredentials />
            </Route>
            <Route exact path={AuthRoutes.REGISTRATION}>
                <AuthRegistrationSite />
            </Route>
            <Route exact path={AuthRoutes.FORGOT_PASSWORD.EMAIL}>
                <AuthForgotPasswordEmailSite />
            </Route>
            <Route exact path={AuthRoutes.FORGOT_PASSWORD.CONFIRM}>
                <AuthForgotPasswordConfirmSite />
            </Route>
            <Route exact path={AuthRoutes.FORGOT_PASSWORD.RESET}>
                <AuthForgotPasswordResetSite />
            </Route>
            <Route component={NotFoundSite} />
        </Switch>
    );
};
