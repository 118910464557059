import { Mutex } from "async-mutex";

export class CachedApiCall<T> {
    // Ten minutes
    static DEFAULT_CACHE_DURATION_MS = 1000 * 60 * 10;

    static allCachedCalls: CachedApiCall<unknown>[] = [];

    // Call this when credentials change, otherwise after logout/login the new user would
    // get the old values
    static resetAll() {
        for (const cachedCall of CachedApiCall.allCachedCalls) {
            cachedCall.reset();
        }
    }

    cacheDurationMs: number;
    response?: T;
    time?: Date;
    cacheKey?: string;

    mutex = new Mutex();

    constructor(cacheDurationMs?: number) {
        this.cacheDurationMs = cacheDurationMs ?? CachedApiCall.DEFAULT_CACHE_DURATION_MS;
        CachedApiCall.allCachedCalls.push(this);
    }

    // Call this when cache prerequisites change (e.g. company change for calls depending on company)
    reset() {
        this.response = undefined;
        this.time = undefined;
        this.cacheKey = undefined;
    }

    // Return cached if available
    private getResponse() {
        const dt = this.time ? new Date().getTime() - this.time.getTime() : 0;
        if (dt > this.cacheDurationMs) {
            this.response = undefined;
        }

        return this.response;
    }

    private storeResponse(result: T, cacheKey?: string) {
        this.response = result;
        this.cacheKey = cacheKey;
        this.time = new Date();
    }

    async call(apiCall: () => Promise<T>, cacheKey?: string) {
        return await this.mutex.runExclusive(async () => {
            if (cacheKey !== this.cacheKey) {
                this.reset();
            } else if (this.getResponse()) {
                return this.getResponse();
            }

            const result = await apiCall();
            this.storeResponse(result, cacheKey);
            return result;
        });
    }
}
