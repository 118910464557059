import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { IRecordType, Module } from "../../types/models";

export const useRecordTypes = ({
    companyId,
    module,
    periodId,
    subsidiaryId,
}: {
    companyId?: string;
    module?: Module | "";
    periodId?: string;
    subsidiaryId?: string;
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [recordTypes, setRecordTypes] = React.useState<IRecordType[]>([]);

    React.useEffect(() => {
        const load = async () => {
            if (!companyId || !module || !periodId || !subsidiaryId) {
                return;
            }

            try {
                setIsLoading(true);
                const response = await API.getRecordTypes({
                    companyId,
                    module,
                    periodId,
                    subsidiaryId,
                });

                setRecordTypes(response);
            } catch (error) {
                generalStore.setError(t("error.loadRecordTypes"), error);
            } finally {
                setIsLoading(false);
            }
        };

        load();
    }, [companyId, module, periodId, subsidiaryId]);

    return {
        isLoading,
        recordTypes,
        reset: () => {
            setRecordTypes([]);
        },
    };
};
