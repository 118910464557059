import React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetTermsOfUseResponse } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { generalStore } from "../../stores/GeneralStore";

export const useTermsOfUseDocuments = (companyId?: string) => {
    const [documents, setDocuments] = React.useState<GetTermsOfUseResponse | undefined>();

    const load = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            const response = await API.getTermsOfUseDocuments(companyId);
            setDocuments(response);
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.statusCode === HttpStatusCode.NotFound_404 &&
                apiError.response.type === "TERMS_OF_USE_FOLDER_NOT_FOUND"
            ) {
                generalStore.setError(t("error.termsOfUseFolderNotFound"), error);
            } else {
                generalStore.setError(t("error.loadTermsofUse"), error);
            }
        }
    }, [companyId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        documents,
        reload: load,
    };
};
