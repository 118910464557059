import * as React from "react";
import { GLOBAL_FEATURES } from "../../features";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Draft, EmployeeDocument } from "../../network/APITypes";
import { CachedApiCall } from "../../network/CachedApiCall";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { generalStore } from "../../stores/GeneralStore";
import { Module } from "../../types/models";
import { sanitizeHtml } from "../../util/helpers";
import { getFullName } from "../../util/user";
import { AccountingRoutes } from "../accounting/router/AccountingRoutes";
import { pushRoute, withParams } from "../app/router/history";
import { DocsRoutes } from "../docs/router/DocsRoutes";
import { HrRoutes } from "../hr/router/HrRoutes";
import { ProjectsRoutes } from "../projects/router/ProjectsRoutes";
import { ConfirmationDialog } from "../ui/ConfirmationDialog";
import { useQueryParams } from "./useQueryParams";

// <any> because we don't care about the return value. We just want to cache the call
const cachedComplete = new CachedApiCall<unknown>(1000);

export type SignContext = "generalDocument" | "report" | "draft" | "employeeDocument";
export interface SignReportConfig {
    companyId?: string;
    periodId?: string;
    subsidiaryId?: string;
    module: Module;
    global?: boolean;
}

export interface SignReport {
    id: string;
    requiresQes?: boolean;
    document: { id: string };
}

export interface SignGeneralDocument {
    documentId: string;
    generalDocumentId: string;
    requiresQes?: boolean;
}

interface SignProps {
    companyId?: string;
    projectId?: string;
    onReleaseReport?: (report: SignReport, config: SignReportConfig) => void;
    onReleaseDraft?: (draft: Draft, action: "reject" | "release" | "cancel") => void;
    onReleaseEmployeeDocument?: (employeeDocument: EmployeeDocument) => void;
    onReleaseGeneralDocument?: (generalDocument: SignGeneralDocument) => void;
    onReload?: (itemId?: string) => Promise<void>;
    isDocList?: boolean;
}

interface SignDocumentParams {
    documentId: string;
    signComplete: boolean;

    reportId: string;
    reportModule: Module;
    reportGlobal: boolean;
    reportPeriodId: string;
    reportSubsidiaryId: string;

    projectId: string;
    draftId: string;
    draftProjectItemId: string;

    employeeDocumentId: string;

    generalDocumentId: string;

    signContext: SignContext;

    companyId: string;
}

export const useDocumentSign = ({
    companyId,
    projectId,
    onReleaseReport,
    onReleaseDraft,
    onReleaseEmployeeDocument,
    onReleaseGeneralDocument,
    onReload,
    isDocList,
}: SignProps) => {
    const [showQesDialog, setShowQesDialog] = React.useState(false);
    const [showQesBlockedDialog, setShowQesBlockedDialog] = React.useState(false);
    const [isQesRequired, setIsQesRequired] = React.useState(false);
    const [report, setReport] = React.useState<SignReport>();
    const [draft, setDraft] = React.useState<Draft>();
    const [generalDocument, setGeneralDocument] = React.useState<SignGeneralDocument>();
    const [employeeDocument, setEmployeeDocument] = React.useState<EmployeeDocument>();
    const [reportConfig, setReportConfig] = React.useState<SignReportConfig>();
    const [signContext, setSignContext] = React.useState<SignContext>();
    const { signDocumentParameters }: { signDocumentParameters?: string } = useQueryParams();
    const [qesAvailable, setQesAvailable] = React.useState<boolean>(true);

    let parameters: Partial<SignDocumentParams> | undefined = undefined;

    // TPAPORTAL-2758 - external QES provider currently doesn´t support query params concatenated with "&", so they need to be send uri encoded
    if (signDocumentParameters) {
        parameters = decodeSignDocumentParams(signDocumentParameters);
    }

    const handleCloseQesBlockedDialog = () => {
        setShowQesBlockedDialog(false);
    };

    const handleCloseQesDialog = () => {
        setShowQesDialog(false);
    };

    const handleRelease = () => {
        if (signContext === "report" && report && reportConfig && onReleaseReport) {
            onReleaseReport(report, reportConfig);
        } else if (signContext === "draft" && draft && onReleaseDraft) {
            onReleaseDraft(draft, "release");
        } else if (signContext === "employeeDocument" && employeeDocument && onReleaseEmployeeDocument) {
            onReleaseEmployeeDocument(employeeDocument);
        } else if (signContext === "generalDocument" && generalDocument && onReleaseGeneralDocument) {
            onReleaseGeneralDocument(generalDocument);
        }

        handleCloseQesDialog();
    };

    const handleRedirectToSigningPortal = async () => {
        await startSigning();
    };

    // Initiates QES signing process. Receives a redirect URL from the backend and opens it in the same window.
    const startSigning = React.useCallback(async () => {
        let signRedirectUrl;

        try {
            generalStore.isLoading = true;

            if (signContext === "report" && reportConfig?.companyId && reportConfig.periodId && report?.id) {
                const redirectUrl = buildRedirectUrl({
                    signContext: "report",
                    documentId: report.document.id,
                    companyId: reportConfig.companyId,
                    reportModule: reportConfig.module,
                    reportPeriodId: reportConfig.periodId,
                    reportId: report.id,
                    reportGlobal: reportConfig.global,
                    reportSubsidiaryId: reportConfig.subsidiaryId,
                    signComplete: true,
                });

                const response = await API.signReport(
                    redirectUrl,
                    reportConfig.companyId,
                    reportConfig.module,
                    reportConfig.periodId,
                    report.id,
                    reportConfig.subsidiaryId,
                );

                signRedirectUrl = response.redirectURL;
            } else if (signContext === "draft" && companyId && projectId && draft) {
                const redirectUrl = buildRedirectUrl({
                    signContext: "draft",
                    documentId: draft.document.id,
                    companyId,
                    projectId: draft.projectID,
                    draftProjectItemId: draft.projectItemID,
                    draftId: draft.id,
                    signComplete: true,
                });

                const response = await API.signDraft(
                    redirectUrl,
                    companyId,
                    draft.projectID,
                    draft.projectItemID,
                    draft.id,
                );

                signRedirectUrl = response.redirectURL;
            } else if (signContext === "employeeDocument" && companyId && employeeDocument) {
                const redirectUrl = buildRedirectUrl({
                    signContext: "employeeDocument",
                    companyId,
                    employeeDocumentId: employeeDocument.id,
                    signComplete: true,
                });

                const response = await API.signEmployeeDocument(redirectUrl, companyId, employeeDocument.id);

                signRedirectUrl = response.redirectURL;
            } else if (signContext === "generalDocument" && companyId && generalDocument) {
                const redirectUrl = buildRedirectUrl({
                    signContext: "generalDocument",
                    companyId,
                    generalDocumentId: generalDocument.generalDocumentId,
                    signComplete: true,
                });

                const response = await API.signGeneralDocument(
                    redirectUrl,
                    companyId,
                    generalDocument.generalDocumentId,
                );

                signRedirectUrl = response.redirectURL;
            }

            if (signRedirectUrl) {
                window.open(signRedirectUrl, "_self");
            }
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.statusCode === HttpStatusCode.Locked_423 &&
                apiError.response.type === "DOCUMENT_LOCKED_FOR_QES"
            ) {
                setShowQesDialog(false);
                setShowQesBlockedDialog(true);
            } else if (apiError?.response.type === "DOCUMENT_READ_ONLY") {
                generalStore.setError(t("error.signReadOnlyDocument"), error);
            } else if (
                apiError?.statusCode === HttpStatusCode.PayloadTooLarge_413 &&
                apiError.response.type === "FILE_TOO_LARGE"
            ) {
                generalStore.setError(t("error.signFileTooLarge"), error);
            } else {
                generalStore.setError(t("error.getSign"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, draft, employeeDocument, generalDocument, projectId, report, reportConfig, signContext]);

    // CONNECT-460: How signing works: Depending on the type of document to be signed we start
    // the signing process above using startSigning().
    //
    // There we provide queryParams that MUST contain all information so we can later,
    // when signing completes and redirects to TPA connect, complete the signing process.
    //
    // In the past part of the params were passed as function parameters to the useDocumentSign() hook.
    // Later parts were refactored to React state variables. This does not work, because after signing completes
    // we are redirected back to TPA connect and the state variables are reset. So the cleanest
    // way to fix this is by passing all necessary information via query params.
    //
    // Why are we using a CachedApiCall here? Because e.g. report lists are present multiple times on a page.
    // Thus when we are redirected back, multiple complete calls would be triggered by each hook. Thus we
    // cache the call and only execute it only once.
    const completeSign = React.useCallback(async () => {
        if (!parameters) {
            return;
        }

        try {
            generalStore.isLoading = true;

            const cacheKey = JSON.stringify(parameters);

            if (
                parameters.signContext === "report" &&
                parameters.documentId &&
                parameters.companyId &&
                parameters.reportModule &&
                parameters.reportPeriodId &&
                parameters.reportId &&
                (parameters.reportGlobal || parameters.reportSubsidiaryId)
            ) {
                const {
                    documentId,
                    companyId,
                    reportModule,
                    reportPeriodId,
                    reportId,
                    reportGlobal,
                    reportSubsidiaryId,
                } = parameters;

                await cachedComplete.call(
                    () =>
                        API.completeSignReport(
                            documentId,
                            companyId,
                            reportModule,
                            reportPeriodId,
                            reportId,
                            reportGlobal ? undefined : reportSubsidiaryId,
                        ),
                    cacheKey,
                );

                if (onReload) {
                    await onReload(parameters.reportId);
                }
            } else if (
                parameters.signContext === "draft" &&
                parameters.documentId &&
                parameters.companyId &&
                parameters.projectId &&
                parameters.draftProjectItemId &&
                parameters.draftId
            ) {
                const { documentId, companyId, projectId, draftProjectItemId, draftId } = parameters;
                await cachedComplete.call(
                    () => API.completeSignDraft(documentId, companyId, projectId, draftProjectItemId, draftId),
                    cacheKey,
                );

                if (onReload) {
                    await onReload(parameters.draftProjectItemId);
                }
            } else if (
                parameters.signContext === "employeeDocument" &&
                parameters.companyId &&
                parameters.employeeDocumentId
            ) {
                const { companyId, employeeDocumentId } = parameters;
                await cachedComplete.call(
                    () => API.completeSignEmployeeDocument(companyId, employeeDocumentId),
                    cacheKey,
                );
                if (onReload) {
                    await onReload(parameters.employeeDocumentId);
                }
            } else if (
                parameters.signContext === "generalDocument" &&
                parameters.companyId &&
                parameters.generalDocumentId
            ) {
                const { companyId, generalDocumentId } = parameters;
                await cachedComplete.call(
                    () => API.completeSignGeneralDocument(companyId, generalDocumentId),
                    cacheKey,
                );
                if (onReload) {
                    await onReload(parameters.generalDocumentId);
                }
            }
        } catch (err) {
            generalStore.setError(t("error.completeSign"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [parameters, onReload]);

    React.useEffect(() => {
        if (parameters?.signComplete && parameters.signContext) {
            completeSign();
            if (isDocList || parameters.signContext === "generalDocument") {
                pushRoute(DocsRoutes.LIST);
            } else if (parameters.signContext === "report" && parameters.reportModule === "accounting") {
                pushRoute(AccountingRoutes.ROOT);
            } else if (parameters.signContext === "report" && parameters.reportModule === "hr") {
                pushRoute(HrRoutes.ROOT);
            } else if (parameters.signContext === "draft" && projectId && parameters.draftProjectItemId) {
                pushRoute(
                    withParams(ProjectsRoutes.RELEASES_PROJECT_ITEM, {
                        projectId,
                        projectItemId: parameters.draftProjectItemId,
                    }),
                );
            }
        }
    }, [completeSign, projectId, signContext, parameters, isDocList]);

    const buttonConfig =
        GLOBAL_FEATURES.qes && qesAvailable
            ? {
                  // QES
                  confirmLabel: isQesRequired
                      ? t("signDialog.qesRequired.confirm")
                      : t("signDialog.noQesRequired.button.qes"),
                  onConfirm: handleRedirectToSigningPortal,

                  cancelLabel: t("common.cancel"),
                  onCancel: isQesRequired ? handleCloseQesDialog : undefined,
                  onClose: isQesRequired ? undefined : handleCloseQesDialog,

                  // Release via click
                  secondaryActionLabel: isQesRequired ? undefined : t("signDialog.noQesRequired.button.click"),
                  onSecondaryAction: isQesRequired ? undefined : handleRelease,
              }
            : {
                  // Only allow release via click button when QES is disabled
                  confirmLabel: t("signDialog.noQesRequired.button.click"),
                  onConfirm: handleRelease,

                  cancelLabel: t("common.cancel"),
                  onCancel: handleCloseQesDialog,
                  onClose: handleCloseQesDialog,
              };

    const qesDialog = (
        <ConfirmationDialog
            open={showQesDialog}
            title={t("signDialog.title")}
            message={
                <div>
                    <p>
                        {isQesRequired
                            ? t("signDialog.qesRequired.message")
                            : t(
                                  GLOBAL_FEATURES.qes && qesAvailable
                                      ? "signDialog.noQesRequired.message"
                                      : "signDialog.noQesEnabled.message",
                              )}
                    </p>
                    {signContext === "draft" && draft?.comment && (
                        <>
                            <br />
                            <p>
                                {t("projects.releaseConfirmationDialog.message", {
                                    name: getFullName(draft.requestor),
                                })}
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(draft.comment) }} />
                        </>
                    )}
                </div>
            }
            {...buttonConfig}
        />
    );

    const qesBlockedDialog = (
        <ConfirmationDialog
            open={showQesBlockedDialog}
            title={t("signDialog.qesblocked.title")}
            message={t("signDialog.qesblocked.message")}
            confirmLabel={t("common.ok")}
            onConfirm={() => {
                handleCloseQesBlockedDialog();
            }}
            onClose={handleCloseQesBlockedDialog}
        />
    );

    return {
        openQesDialog({
            signContext,
            itemToRelease,
            reportConfig,
            mimeType,
        }: {
            signContext: SignContext;
            itemToRelease: SignReport | Draft | EmployeeDocument | SignGeneralDocument;
            reportConfig?: SignReportConfig;
            mimeType: string;
        }) {
            // CONNECT-494: Disable for non PDFs
            setQesAvailable(mimeType === "application/pdf");

            setSignContext(signContext);
            const required = (GLOBAL_FEATURES.qes && itemToRelease.requiresQes) ?? false;
            setReportConfig(reportConfig);
            setIsQesRequired(required);

            if (signContext === "report") {
                const report = itemToRelease as SignReport;
                setReport(report);
            } else if (signContext === "draft") {
                const draft = itemToRelease as Draft;
                setDraft(draft);
            } else if (signContext === "employeeDocument") {
                const employeeDocument = itemToRelease as EmployeeDocument;
                setEmployeeDocument(employeeDocument);
            } else if (signContext === "generalDocument") {
                const generalDocument = itemToRelease as SignGeneralDocument;
                setGeneralDocument(generalDocument);
            }

            setShowQesDialog(true);
        },
        qesDialog,
        qesBlockedDialog,
    };
};

const encodeSignDocumentParams = (params: Partial<SignDocumentParams>): string => {
    return encodeURIComponent(JSON.stringify(params));
};
const decodeSignDocumentParams = (params: string): Partial<SignDocumentParams> => {
    return JSON.parse(decodeURIComponent(params)) as Partial<SignDocumentParams>;
};

const buildRedirectUrl = (params: Partial<SignDocumentParams>): string => {
    const signDocumentParameters = encodeSignDocumentParams(params);
    const url = new URL(document.URL);
    url.searchParams.set("signDocumentParameters", signDocumentParameters);
    return url.toString();
};
