import { Routes } from "../../app/router/Routes";

const prefix = Routes.prefixed(Routes.ACCOUNTING);

export const AccountingRoutes = {
    ROOT: prefix("/"),
    RECORDS: {
        ROOT: prefix("/records"),
        LIST: prefix("/records/:recordTypeId/list"),
        TICKETS: prefix("/records/:recordTypeId/tickets"),
        TICKET_DETAILS: prefix("/records/:recordTypeId/tickets/:ticketId"),
        TYPES: prefix("/records/types"),
        UPLOAD: prefix("/records/:recordTypeId/upload"),
        DETAILS_UPLOAD: prefix("/records/:recordTypeId/details/:recordId/upload"),
        DETAILS_EDIT: prefix("/records/:recordTypeId/details/:recordId/edit"),
    },
    BANKING: {
        CONNECTIONS: {
            ROOT: prefix("/bank-connections"),
            ACCOUNTS: {
                TRANSACTIONS: prefix("/bank-connections/:bankConnectionId/accounts/:bankAccountId/transactions"),
                ASSIGN_INVOICES_TO_TRANSACTIONS: prefix("/assign-invoices-to-transactions"),
            },
        },
    },
    REPORT_TICKET: prefix("/reports/:reportId/ticket"),
    REPORT_UPLOAD: prefix("/reports/upload"),
};
