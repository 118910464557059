import { TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { t } from "../../../i18n/util";
import { companiesStore } from "../../../stores/CompaniesStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { formatISODateOnly } from "../../../util/date";
import { employeeStatusToString } from "../../../util/helpers";
import { getFullName } from "../../../util/user";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { useExternalPreRegistration } from "../../hooks/useExternalPreRegistration";
import { useQueryParams } from "../../hooks/useQueryParams";
import { CenteredContent } from "../../ui/CenteredContent";
import { DocumentLine } from "../../ui/DocumentLine/DocumentLine";
import { NavBarBack } from "../../ui/NavBarBack";
import { CenteredForm } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";
import { ConfirmWebhook } from "../ConfirmWebhook";
import { WebhookRoutes } from "../router/WebhookRoutes";

export const WebhookPreRegistrationTicketsSite = observer(function WebhookPreRegistrationTicketsSite() {
    useHideSideBar();

    const query: { employeeRegistration?: string[]; dueDate?: string; taskTitle?: string } = useQueryParams({
        arrayKeys: ["employeeRegistration"],
    });

    const [selectedDueDate, setSelectedDueDate] = React.useState<Date | null>(
        query.dueDate ? new Date(query.dueDate) : null,
    );

    const preRegistrations = useExternalPreRegistration(query.employeeRegistration);
    const preRegistration = preRegistrations[0];
    const employeeName = getFullName(preRegistration?.preRegistration);
    const company = useCompany({ companyId: preRegistration?.companyId });
    const currentDate = moment().startOf("day");

    const [taskTitle, setTaskTitle] = React.useState(query.taskTitle);

    // employee name is undefined in the first render, hence the useEffect
    React.useEffect(() => {
        if (!query.taskTitle) {
            setTaskTitle(t("webhook.tickets.taskTitle", { name: employeeName }));
        }
    }, [employeeName, setTaskTitle, query.taskTitle]);

    if (!preRegistration) {
        return (
            <NavBarBack
                cancelTarget={companiesStore.selectedCompanyId ? Routes.COCKPIT : Routes.COMPANY_OVERVIEW}
                title={t("common.ticket")}
            />
        );
    }

    const onSubmit = () => {
        pushRoute(WebhookRoutes.PRE_REGISTRATION_TICKETS_DETAILS, {
            query: {
                ...query,
                dueDate: selectedDueDate ? formatISODateOnly(selectedDueDate) : undefined,
                taskTitle: taskTitle,
            },
            state: { company: company.company, preRegistrations },
        });
    };

    const handleChangeDate = (date: MaterialUiPickersDate) => {
        setSelectedDueDate(date ? date.toDate() : null);
    };

    const handleChangeTaskTitle = (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTaskTitle(value.target.value);
    };

    let disableConfirm = false;
    if (selectedDueDate) {
        const selectedMoment = moment(selectedDueDate);
        disableConfirm = !selectedMoment.isValid() || selectedMoment.isBefore(currentDate);
    }

    return (
        <>
            <NavBarBack
                cancelTarget={companiesStore.selectedCompanyId ? Routes.COCKPIT : Routes.COMPANY_OVERVIEW}
                title={t("webhook.tickets.navbar", { document: employeeName })}
            />

            <CenteredContent>
                <SiteContent>
                    <CenteredForm>
                        <h1>{t("webhook.tickets.title")}</h1>
                        <CenteredForm>
                            <DocumentLine
                                fileName={
                                    employeeName +
                                    ` (${employeeStatusToString(preRegistration?.preRegistration?.status) ?? ""})`
                                }
                                iconName="user"
                                style={{ marginBottom: 32, padding: 0, fontWeight: "bold" }}
                            />
                            <TextField
                                value={company.company?.name ?? " "}
                                label={t("common.company")}
                                variant="outlined"
                                style={{ marginBottom: 18 }}
                                disabled
                            />
                            <TextField
                                value={preRegistration.subsidiary.name}
                                label={t("common.hr.subsidiary")}
                                variant="outlined"
                                style={{ marginBottom: 18 }}
                                disabled
                            />
                            <TextField
                                value={taskTitle}
                                label={t("common.taskTitle")}
                                variant="outlined"
                                style={{ marginBottom: 18 }}
                                onChange={handleChangeTaskTitle}
                            />
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                format="DD.MM.YYYY"
                                margin="none"
                                label={t("common.dueDate")}
                                value={selectedDueDate}
                                onChange={handleChangeDate}
                                disablePast
                                invalidDateMessage={t("error.invalidDateFormat")}
                                minDateMessage={t("error.dueDate.minDate")}
                                openTo="year"
                                keyboardIcon={<Icon name="calendar" style={{ color: customColors.primaryColor }} />}
                            />
                            <ConfirmWebhook
                                onSubmit={onSubmit}
                                buttonLabel={t("webhook.tickets.button")}
                                disabled={disableConfirm}
                            />
                        </CenteredForm>
                    </CenteredForm>
                </SiteContent>
            </CenteredContent>
        </>
    );
});
