import * as React from "react";
import { t } from "../../i18n/util";
import { API, ITableParams } from "../../network/API";
import { UserPermissions } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useUsers = ({
    companyId,
    search,
    orderBy,
    orderDir,
    limit = 10,
    showLoading = true,
}: {
    companyId?: string;
    search?: string;
    orderBy?: string;
    orderDir?: "asc" | "desc";
    limit?: number;
    showLoading?: boolean;
} = {}) => {
    const [users, setUsers] = React.useState<UserPermissions[]>([]);
    const [total, setTotal] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const load = React.useCallback(async () => {
        if (!companyId) {
            return;
        }
        try {
            if (showLoading) {
                generalStore.isLoading = true;
            }
            setIsLoading(true);
            const params: ITableParams = {
                search,
                limit,
                orderBy,
                orderDir,
            };

            const response = await API.getUsers(companyId, params);

            if (response.users) {
                setUsers(response.users.map(u => u.permissions) ?? []);
                setTotal(response.total);
            }
        } catch (error) {
            generalStore.setError(t("error.loadUsers"), error);
        } finally {
            if (showLoading) {
                generalStore.isLoading = false;
            }
            setIsLoading(false);
        }
    }, [companyId, limit, orderBy, orderDir, search, showLoading]);

    const loadNext = React.useCallback(async () => {
        if (!companyId) {
            return;
        }
        try {
            if (showLoading) {
                generalStore.isLoading = true;
            }
            setIsLoading(true);
            if (users && users.length < total) {
                const response = await API.getUsers(companyId, {
                    limit,
                    search,
                    orderBy,
                    orderDir,
                    offset: users.length,
                });
                if (response.users) {
                    const newUsers = users.concat(response.users.map(u => u.permissions));
                    setUsers(newUsers);
                }
            }
        } catch (error) {
            generalStore.setError(t("error.loadUsers"), error);
        } finally {
            if (showLoading) {
                generalStore.isLoading = false;
            }
            setIsLoading(false);
        }
    }, [companyId, limit, orderBy, orderDir, search, showLoading, total, users]);

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        total,
        users,
        loadNext,
        remainingCount: total - users.length,
        isLoading,
    };
};
