import { Button, Checkbox, TableBody } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, useLocation, useParams } from "react-router";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Person } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { configStore } from "../../stores/ConfigStore";
import { generalStore } from "../../stores/GeneralStore";
import { useHideSideBar } from "../../stores/SideBarStore";
import { useTableStore } from "../../stores/TableStore";
import { getFullName } from "../../util/user";
import { AdvisorRoutes } from "../advisor/router/AdvisorRoutes";
import { pushRoute, withParams } from "../app/router/history";
import { usePhoneNumberMissingDialog } from "../hooks/usePhoneNumberMissingDialog";
import { SettingsRoutes } from "../settings/router/SettingsRoutes";
import { EmptyState } from "../ui/EmptyState";
import { InfoButton, TableLabel, TpaTable, TpaTableCell, TpaTableContainer, TpaTableRow } from "../ui/Primitives";
import { ITableHeaderConfig, TableHeader } from "../ui/TableHeader";
import { TableSearchBarWithAction } from "../ui/TableSearchBar";
import { customColors } from "../util/Theme";

function canSelect(person: Person) {
    return !!person.email;
}

export const EmployeesTable = observer(function EmployeesTable() {
    useHideSideBar();

    const tableStore = useTableStore<Person & { name?: unknown }>("EmployeesTable", {
        orderBy: "name",
        orderDir: "asc",
    });
    tableStore.canSelect = canSelect;

    const location = useLocation();
    const isConfigRoute = location.pathname.startsWith(AdvisorRoutes.CONFIG.ROOT);
    const params = useParams<{ companyId?: string }>();
    const companyId = params.companyId ?? companiesStore.selectedCompanyId;
    const [initialized, setInitialized] = React.useState(false);

    const { tableParams } = tableStore;
    const loadPeople = React.useCallback(
        async (noCache?: boolean) => {
            try {
                generalStore.isLoading = true;
                const response = await API.getCompanyPeople(companyId, { options: tableParams }, noCache);
                tableStore.totalCount = response.total;
                tableStore.items = response.people ?? [];
            } catch (err) {
                generalStore.setError(t("error.loadEmployees"), err);
            } finally {
                generalStore.isLoading = false;
                setInitialized(true);
            }
        },
        [companyId, tableParams, tableStore],
    );

    React.useEffect(() => {
        loadPeople();
    }, [loadPeople]);

    const handleNavigateToAssignRole = () => {
        if (companyId) {
            if (isConfigRoute) {
                pushRoute(withParams(AdvisorRoutes.CONFIG.USER_ROLES, { companyId }));
            } else {
                pushRoute(SettingsRoutes.USER_MANAGEMENT.USER_ROLES);
            }
        }
    };

    const phoneNumberMissingDialog = usePhoneNumberMissingDialog({
        onConfirm: handleNavigateToAssignRole,
    });

    if (!companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    if (!initialized) {
        return null;
    }

    const checkPhoneNumberMissing = (people: Person[]) => {
        return people.some(person => !person.phoneNumber);
    };

    const handleClickAssignRole = () => {
        const selectedPeople = tableStore.getAllSelectedItems();

        configStore.selectedEmployees = selectedPeople.map(e => ({ ...e, type: "person" }));
        if (checkPhoneNumberMissing(selectedPeople)) {
            phoneNumberMissingDialog.open();
        } else {
            handleNavigateToAssignRole();
        }
    };

    const headerFields: ITableHeaderConfig[] = [
        { column: "name", label: "table.label.contactPerson" },
        { column: "personnelNumber", label: "table.label.contactPersonId" },
        { column: "email", label: "table.label.email" },
        { column: "phoneNumber", label: "table.label.phoneNumber" },
    ];

    const tableBody = (
        <TableBody>
            {tableStore.items.map((person, index) => {
                return (
                    <TpaTableRow key={person.id}>
                        <TpaTableCell padding="checkbox">
                            <Checkbox
                                data-id={`employee_checkbox_${index}`}
                                onChange={(event, checked) => {
                                    tableStore.toggleSelection(person);
                                }}
                                color="primary"
                                checked={tableStore.isSelected(person)}
                                disabled={!canSelect(person)}
                            />
                        </TpaTableCell>
                        {headerFields.map(({ column }) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = person[column as keyof Person];

                            if (column === "name") {
                                label = getFullName(person);
                            } else if (column === "email") {
                                return (
                                    <TpaTableCell key={column}>
                                        <div
                                            style={{
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {person.email ?? (
                                                <>
                                                    {t("config.employees.table.missingEmail")}
                                                    <InfoButton
                                                        title={t("config.employees.table.missingEmail.tooltip")}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </TpaTableCell>
                                );
                            }

                            return (
                                <TpaTableCell key={column}>
                                    <TableLabel
                                        style={{
                                            color:
                                                !person.email && (column === "name" || column === "personnelNumber")
                                                    ? customColors.placeholder
                                                    : undefined,
                                        }}
                                    >
                                        {label}
                                    </TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    // No records and NOT caused by a search term
    const isEmpty = tableStore.getIsEmptyState(generalStore.isLoading);

    return (
        <>
            {isEmpty && (
                <EmptyState
                    title={t("config.employees.emptyState.title")}
                    message={t("config.employees.emptyState.message")}
                    buttonLabel={t("common.refresh")}
                    onAddEntry={() => loadPeople(true)}
                />
            )}
            {!isEmpty && (
                <>
                    <div style={{ textAlign: "right", marginBottom: 8 }}>
                        <Button color="primary" onClick={() => loadPeople(true)} endIcon={<RefreshIcon />}>
                            {t("common.refresh")}
                        </Button>
                    </div>
                    <TableSearchBarWithAction
                        label="search.caption.numDespositedPersons"
                        placeholder="search.placeholder.searchForUsers"
                        labelSelected="search.caption.numSelected"
                        select={tableStore}
                        search={tableStore.search}
                        totalCount={tableStore.totalCount}
                        onChangeSearch={tableStore.handleSearchChange}
                        buttonLabel={t("config.employees.assignRole")}
                        onAction={tableStore.selectedItems.size > 0 ? handleClickAssignRole : undefined}
                    />
                    <TpaTableContainer>
                        <TpaTable>
                            <TableHeader
                                allowMultiSelectAlways
                                headerFields={headerFields}
                                tableStore={tableStore}
                                select={tableStore}
                            />
                            {tableBody}
                        </TpaTable>
                    </TpaTableContainer>
                    <tableStore.Pagination />
                    {tableStore.getIsNoResultState(generalStore.isLoading) && (
                        <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                    )}
                </>
            )}
            {phoneNumberMissingDialog.dialog}
        </>
    );
});
