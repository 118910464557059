import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { ICompany } from "../../types/models";

export function useCompany({
    companyId,
    preloaded,
    includeReportsExist,
    onError,
}: {
    companyId?: string;
    preloaded?: ICompany;
    includeReportsExist?: boolean;
    onError?: (err: unknown) => boolean;
}): {
    company: ICompany | null;
    reload: (noCache?: boolean) => void;
} {
    const [company, setCompany] = React.useState<ICompany | null>(preloaded ?? null);

    const loadCompany = React.useCallback(
        async (noCache?: boolean) => {
            // Return if no id, or loaded already matches. noCache = true forces reload
            if (!companyId) {
                return;
            }

            try {
                generalStore.isLoading = true;
                const response = await API.getCompany(companyId, noCache, includeReportsExist);
                setCompany(response);
            } catch (err) {
                if (onError?.(err)) {
                    return; // handled by the caller
                }
                generalStore.setError(t("error.loadCompany"), err);
            } finally {
                generalStore.isLoading = false;
            }
        },
        [companyId, includeReportsExist, onError],
    );

    const initialId = preloaded?.id;
    React.useEffect(() => {
        if (!initialId || initialId !== companyId) {
            loadCompany();
        }
    }, [companyId, initialId, loadCompany]);

    return { company, reload: loadCompany };
}
