import { observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";
import { BMD_DESTINATION_ID, MAX_UPLOAD_SIZE } from "../../../config";
import { t } from "../../../i18n/util";
import { GetRecordTypesResponse, PostRecordResponse } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { Module } from "../../../types/models";
import { pushRoute, withParams } from "../../app/router/history";
import { DocumentUpload, IUpload, OnSuccess, UploadFile } from "../../ui/DocumentUpload";
import { FullScreenDocumentUploadContainer } from "../../ui/FullScreenDocumentUploadContainer";
import { NavBarBack } from "../../ui/NavBarBack";

export const ModuleRecordsUploadSite = observer(function ModuleRecordsUploadSite({ module }: { module: Module }) {
    useHideSideBar();

    const store = getModuleStore(module);

    const { recordTypeId } = useParams<{ recordTypeId: string }>();
    const recordType = store.getRecordTypeForId(recordTypeId);

    React.useEffect(() => {
        if (!recordType || !recordTypeId) {
            pushRoute(store.routes.ROOT);
        }
    }, [recordType, recordTypeId, store.routes.ROOT]);

    const onHandleCancelClick = () => {
        pushRoute(withParams(store.routes.RECORDS.LIST, { recordTypeId: recordTypeId ?? "" }));
    };

    const uploadFile = async (file: File) => {
        return await store.uploadRecord(file, recordTypeId ?? "");
    };

    const startDetailsFlow = async (uploads: IUpload<PostRecordResponse>[]) => {
        store.uploadedRecords = uploads.map(upload => upload.response);
        const recordId = store.uploadedRecords[0]?.recordId;

        try {
            // Reload period to activate transfer button
            await store.reloadCurrentPeriod();
        } catch (err) {
            generalStore.setError(t("error.loadPeriod"), err);
        }
        pushRoute(withParams(store.routes.RECORDS.DETAILS_UPLOAD, { recordTypeId: recordTypeId ?? "", recordId }));
    };

    if (!recordTypeId || !recordType) {
        return null;
    }

    return (
        <>
            <NavBarBack
                title={t("screen.records.upload.header", { recordType: store.getRecordTypeName(recordType) })}
                moduleInfo={module}
                onCancel={onHandleCancelClick}
            />
            <FullScreenDocumentUploadContainer>
                <ModuleRecordsUpload
                    module={module}
                    recordType={recordType}
                    uploadFile={uploadFile}
                    onSuccess={startDetailsFlow}
                    multipleFiles
                />
            </FullScreenDocumentUploadContainer>
        </>
    );
});

interface ModuleRecordsUploadProps {
    module: Module;
    recordType: GetRecordTypesResponse;
    uploadFile: UploadFile<PostRecordResponse>;
    onSuccess: OnSuccess<PostRecordResponse>;
    multipleFiles?: boolean;
}

export const ModuleRecordsUpload = observer(function ModuleRecordsUpload({
    module,
    recordType,
    uploadFile,
    onSuccess,
    multipleFiles,
}: ModuleRecordsUploadProps) {
    return (
        <DocumentUpload<PostRecordResponse>
            maxFileSizeBytes={MAX_UPLOAD_SIZE}
            uploadFile={uploadFile}
            onSuccess={onSuccess}
            multipleFiles={multipleFiles}
            data-id="document_upload_records"
            filterBigImages={
                module === "accounting" &&
                // CONNECT-385: only BMD records are affected by these limitations
                // NOTE: we are deliberately ignoring the fact that the destination could change. Explanation:
                //       The customer has a record type with a destination other than BMD, so no limitations
                //       were applied to the uploads. Later the destination for that record type changes to BMD,
                //       so newly uploaded documents are checked. However the previously uploaded documents
                //       are not checked again if they match the new destination limitations.
                //       The reasons for that are simple:
                //       * it's an edge case that will rarely happen (and cannot solve easily...)
                //       * the destination is actually TPA-managed (cannot be changed by the customer)
                //         hence *WE* should be aware of this issue
                //       see https://github.com/its-tpa/tpa-connect-frontend/pull/144#discussion_r1234838322
                recordType.destination?.id === BMD_DESTINATION_ID
            }
        />
    );
});
