import { action, computed, observable } from "mobx";
import { persist } from "mobx-persist";
import moment from "moment";
import { goToRootLocation } from "../components/app/router/history";
import { APIResult } from "../components/hooks/useAPI";
import { IDueDate } from "../components/ui/Deadline";
import { IGNORE_PERIOD_FINISHED } from "../config";
import { IMessageIDS, t } from "../i18n/util";
import { API } from "../network/API";
import {
    CostCenter,
    GetRecordTypesResponse,
    PermissionsRecordTypes,
    PostRecordResponse,
    RecordType,
} from "../network/APITypes";
import { getApiError } from "../network/NetworkStapler";
import { HttpStatusCode } from "../network/httpStatusCode";
import { IBasicPeriod, ICompany, IPeriod, IRecordType, ISubsidiary, Module } from "../types/models";
import { getModuleRoutes } from "../types/moduleRoutes";
import { formatDate, getInitialPeriodRange } from "../util/date";
import { debug } from "../util/debug";
import { toError } from "../util/error";
import { getPeriodActionsDatesForSubsidiary } from "../util/periods";
import {
    containsActions,
    getSubsidiaryPermissions,
    hasAnyRecordTypeWithPermission,
    recordTypeHasActions,
} from "../util/permissionHelpers";
import { parsePermissions } from "../util/permissionParser";
import { IDeepLinkInfo } from "../util/url";
import { authStore } from "./AuthStore";
import { companiesStore } from "./CompaniesStore";
import { generalStore } from "./GeneralStore";
import { Status } from "./Status";

// Returns nice human readable string for a booking period (e.g. "Q1 2020")
export function periodToString(period?: IBasicPeriod | null, includingDate?: boolean) {
    let ret = "";
    if (!period) {
        return ret;
    }

    const startMoment = moment(period.periodStart);
    if (period.periodType === "month") {
        ret = startMoment.format("MMMM YYYY");
    } else if (period.periodType === "quarter") {
        ret = `Q${startMoment.month() / 3 + 1} ${startMoment.format("YYYY")}`;
    } else if (period.periodType === "week") {
        ret = t("navbar.week", { week: startMoment.week(), year: startMoment.format("YYYY") });
    }

    if (includingDate === true) {
        ret += ` (${formatDate(period.periodStart, "l")} - ${formatDate(period.periodEnd, "l")})`;
    }

    return ret;
}

// Returns the id of the period we are currently in, for an array of periods
export function getCurrentPeriodId(module: Module, periods?: IPeriod[], company?: ICompany | null) {
    if (!periods) {
        debug.error("### getCurrentPeriodId() no periods provided");
        return;
    }

    const now = moment();
    const key = periods.findIndex(period => {
        // If provided only return periods with current bookingPeriodType
        // Do this only for "accounting", "hr" books monthly
        if (module === "accounting" && company && period.periodType !== company.bookingPeriodType) {
            return false;
        }

        return now.isBetween(period.periodStart, moment(period.periodEnd).endOf("day"));
    });

    if (key >= 0) {
        return periods[key].id;
    } else {
        debug.error("### getCurrentPeriodId() no period found");
    }
}

export function getRecordTypeStatus(recordType: IRecordType, transferDate?: string): { status: Status; date?: Date } {
    let status: Status = "open";
    let date;
    if (
        (recordType.recordCount === 0 && !!transferDate) ||
        (recordType.releasedAt &&
            recordType.updatedAt &&
            isRecordTypeReleased(recordType.releasedAt, recordType.updatedAt))
    ) {
        status = "closed";
        if (recordType.releasedAt) {
            date = new Date(recordType.releasedAt);
        } else if (recordType.recordCount === 0 && !!transferDate) {
            date = new Date(transferDate);
        }
    } else if (recordType.updatedAt) {
        status = "inProgress";
        date = new Date(recordType.updatedAt);
    }

    return {
        status,
        date,
    };
}

/**
 * A record type is considered as "released" if there was no update since the last release.
 * It compares the `releasedAt` and `updatedAt` timestamps.
 *
 * CONNECT-361: Unfortunately this check is not as simple as `releasedAt` === `updatedAt`, because
 * the `updatedAt` is set by the DB library automatically, which can happen a few milliseconds
 * after we set `releasedAt` in our code. Therefore we have to allow a tiny gap between these
 * two dates to still be considered as "released". For the time being we allow a gap of 100ms.
 */
function isRecordTypeReleased(releasedAt: moment.MomentInput, updatedAt: moment.MomentInput): boolean {
    const r = moment(releasedAt);
    const u = moment(updatedAt);
    return r.add(100, "millisecond") >= u;
}

const emptyPermissions = parsePermissions();

export abstract class ModuleStore {
    module: Module;

    @observable isRehydrated = false;

    @observable isInitialized = false;

    @observable isSyncing = false;

    @observable subsidiaries: ISubsidiary[] = [];
    @persist @observable selectedSubsidiaryId?: string;

    @observable periods: IPeriod[] = [];
    @persist @observable selectedPeriodId?: string;

    @observable recordTypes: IRecordType[] = [];

    @observable costCenters: APIResult<{
        costCenters: CostCenter[];
        byId: Record<string, CostCenter[]>;
        byName: Record<string, CostCenter[]>;
    }> = {
        state: "initial",
    };

    @observable canSelectPreviousPeriod = true;
    @observable canSelectNextPeriod = true;

    // Used for booking period "pagination"
    periodRange = getInitialPeriodRange();

    // Helper data for record upload
    @observable uploadedRecords: PostRecordResponse[] = [];

    constructor(module: Module) {
        this.module = module;
    }

    get routes() {
        return getModuleRoutes(this.module);
    }

    get t() {
        const moduleT: typeof t = (...parameters) => {
            const [id, values] = parameters;
            const isAccounting = this.module === "accounting";
            const newId: IMessageIDS = isAccounting ? id : (id.replace("accounting", "hr") as IMessageIDS);
            return t(newId, values);
        };
        return moduleT;
    }

    get permissions() {
        return companiesStore.selectedCompanyStore?.permissions ?? emptyPermissions;
    }

    getTextId(id: IMessageIDS) {
        const isAccounting = this.module === "accounting";
        return isAccounting ? id : (id.replace("accounting", "hr") as IMessageIDS);
    }

    wipe() {
        this.isInitialized = false;

        this.subsidiaries = [];
        this.selectedSubsidiaryId = undefined;

        this.recordTypes = [];
        this.costCenters = { state: "initial" };

        this.periods = [];
        this.selectedPeriodId = undefined;
        this.periodRange = getInitialPeriodRange();
        this.canSelectPreviousPeriod = true;
        this.canSelectNextPeriod = true;

        this.uploadedRecords = [];
    }

    async init() {
        const companyId = companiesStore.selectedCompanyId;
        // debug.log("### Init ModuleStore", this.module, companyId);
        if (!this.isInitialized && companyId && companiesStore.canAccessCompany) {
            debug.log("### Init ModuleStore", this.module);
            await this.loadPeriods(companyId);
            await this.refreshAfterPeriodChange();
            this.isInitialized = true;
        }
    }

    loadSubsidiaries = async (companyId: string) => {
        if (!this.selectedPeriodId) {
            debug.error("### No period selected, cannot load subsidiaries");
            return;
        }

        this.subsidiaries = await API.getSubsidiaries({
            companyId,
            module: this.module,
            periodId: this.selectedPeriodId,
            includeKnoedels: true,
        });

        if (!this.subsidiaries || this.subsidiaries.length === 0) {
            if (this.canReadAnyRecords()) {
                throw new Error(this.t("error.noSubsidiaries.accounting"));
            } else {
                this.selectedSubsidiaryId = undefined;
            }
        }

        // Set initial selection
        if (this.subsidiaries.length > 0 && !this.subsidiaries.find(s => s.id === this.selectedSubsidiaryId)) {
            this.selectedSubsidiaryId = this.subsidiaries[0].id;
        }
    };

    selectedSubsidiary() {
        return this.subsidiaries.find(s => s.id === this.selectedSubsidiaryId);
    }

    @computed get selectedPeriodIndex() {
        return this.periods.findIndex(p => p.id === this.selectedPeriodId);
    }

    @computed get selectedPeriod() {
        const period = this.periods.find(p => p.id === this.selectedPeriodId);
        if (IGNORE_PERIOD_FINISHED && period) {
            period.finished = false;
        }

        return period;
    }

    // Defaults to all periods, optionally filtered periods can be passed e.g.: to search for an index in periods of the same booking type
    getPeriodIndexById(periodId: string, periods = this.periods) {
        return periods.findIndex(p => p.id === periodId);
    }

    async loadPeriodsIfUnavailable() {
        if (!this.periods || this.periods.length === 0) {
            const companyId = companiesStore.selectedCompanyId;
            if (!companyId) {
                // No company selected -> get out
                return false;
            }

            await this.loadPeriods(companyId);
        }
    }

    @computed get selectedPeriodActionDates() {
        if (!this.selectedSubsidiaryId || !this.selectedPeriod) {
            debug.error("### No period/subsidiary selected");
            return undefined;
        }

        return getPeriodActionsDatesForSubsidiary(
            this.selectedPeriod.periodActionsDates,
            this.selectedSubsidiaryId,
            false,
        );
    }

    calculateSubsidiaryStatus(): { status: Status; releasedAt?: Date; dueDate?: IDueDate } | undefined {
        if (
            !this.selectedSubsidiaryId ||
            !this.selectedPeriodId ||
            !this.recordTypes ||
            this.recordTypes.length === 0
        ) {
            return;
        }

        // debug.log("");
        // debug.log("### calculateSubsidiaryStatus() begin");

        // Get period actions for current subsidiary
        const periodActionsDates = this.selectedPeriodActionDates;

        // Did the user transfer at least once to TPA?
        const wasTransferredOnce = periodActionsDates?.dateRecordsTransferredAt !== undefined;

        let allNonEmptyRecordTypesReleased = true;
        let allRecordTypesOpen = true;

        // Search for latest release date as overall release date for this booking period
        this.recordTypes.forEach(recordType => {
            if (recordType.recordCount > 0) {
                // At least one record type contains an upload
                allRecordTypesOpen = false;

                // debug.log(
                //     `### recordType ${recordType.name} releasedAt=${recordType.releasedAt}, updatedAt=${recordType.updatedAt}`,
                // );

                if (recordType.releasedAt) {
                    if (recordType.updatedAt && !isRecordTypeReleased(recordType.releasedAt, recordType.updatedAt)) {
                        // There was an upload after the release
                        allNonEmptyRecordTypesReleased = false;
                    }
                } else {
                    // This record type is not empty and was not released yet
                    allNonEmptyRecordTypesReleased = false;
                }
            }
        });

        // debug.log(
        //     `### calculateSubsidiaryStatus(): transferredOnce=${wasTransferredOnce}, allRecordTypesOpen=${allRecordTypesOpen}, allNonEmptyRecordTypesReleased=${allNonEmptyRecordTypesReleased}`,
        // );

        // First calculate status
        let status: Status = "inProgress";
        if (allRecordTypesOpen) {
            if (!wasTransferredOnce) {
                status = "open";
            } else {
                status = "closed";
            }
        } else if (allNonEmptyRecordTypesReleased) {
            status = "closed";
        }

        // Now calculate dueDate based on status
        let dueDate: IDueDate | undefined = undefined;
        const releasedAt = periodActionsDates?.dateRecordsTransferredAt
            ? new Date(periodActionsDates.dateRecordsTransferredAt)
            : undefined;
        if (this.selectedPeriod?.dueDate) {
            const due = new Date(this.selectedPeriod.dueDate);
            if (status === "open" || status === "inProgress") {
                const overdue = new Date() > due;
                dueDate = { labelId: "dueDate.until", date: due, overdue };
            } else if (releasedAt) {
                const overdue = releasedAt > due;
                dueDate = { labelId: "dueDate.transferredAt", date: releasedAt, overdue };
            }
        }

        const ret = {
            status,
            releasedAt,
            dueDate,
        };

        // debug.log("### calculateSubsidiaryStatus() end", ret);
        // debug.log("");

        return ret;
    }

    async selectSubsidiaryById(subsidiaryId: string, goToRoot = false) {
        // No change -> get out
        if (subsidiaryId === this.selectedSubsidiaryId) {
            return;
        }

        if (goToRoot) {
            goToRootLocation();
        }

        this.selectedSubsidiaryId = subsidiaryId;
        await this.refreshAfterSubsidiaryChange();
    }

    hasSubsidiaryWithId(subsidiaryId?: string) {
        return !!subsidiaryId && this.subsidiaries && this.subsidiaries.findIndex(s => s.id === subsidiaryId) >= 0;
    }

    // Don't call directly. Use coordinator for syncing flag!
    @action selectPreviousPeriod = async () => {
        try {
            generalStore.isLoading = true;
            await this.selectPeriodByIndex(this.selectedPeriodIndex - 1);
        } catch (error) {
            generalStore.setError(t("error.loadPeriod"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    // Don't call directly. Use coordinator for syncing flag!
    @action selectNextPeriod = async () => {
        try {
            generalStore.isLoading = true;
            await this.selectPeriodByIndex(this.selectedPeriodIndex + 1);
        } catch (error) {
            generalStore.setError(t("error.loadPeriod"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    @action selectPeriodByIndex = async (index: number) => {
        if (index === this.selectedPeriodIndex) {
            return;
        }

        const previousPeriodIndex = this.selectedPeriodIndex;

        if (!companiesStore.selectedCompanyId) {
            debug.error("### No company selected, cannot load periods");
            return;
        }

        let selection = index;

        if (this.periods.length > 0) {
            // Go backwards in time
            if (index < 1 && this.canSelectPreviousPeriod) {
                const oldStart = moment(this.periodRange.start).subtract(1, "day");
                const newStart = moment(this.periodRange.start).subtract(1, "year");
                const newPeriods = await API.getPeriods(companiesStore.selectedCompanyId, this.module, {
                    start: newStart,
                    end: oldStart,
                });
                if (newPeriods.length > 0) {
                    selection = newPeriods.length;
                    this.periods = newPeriods.concat(this.periods);
                    this.periodRange.start = newStart;
                } else {
                    this.canSelectPreviousPeriod = false;
                }
            }

            // Go forward in time
            if (index >= this.periods.length - 1 && this.canSelectNextPeriod) {
                const oldEnd = moment(this.periodRange.end).add(1, "day");
                const newEnd = moment(this.periodRange.end).add(1, "year");
                const newPeriods = await API.getPeriods(companiesStore.selectedCompanyId, this.module, {
                    start: oldEnd,
                    end: newEnd,
                });

                if (newPeriods.length > 0) {
                    selection = this.periods.length - 1;
                    this.periods = this.periods.concat(newPeriods);
                    this.periodRange.end = newEnd;
                } else {
                    this.canSelectNextPeriod = false;
                }
            }

            if (selection < this.selectedPeriodIndex) {
                this.canSelectNextPeriod = true;
            }

            if (selection > this.selectedPeriodIndex) {
                this.canSelectPreviousPeriod = true;
            }

            // Fix TPAPORTAL-1219: Mark ModuleStore as syncing, as long as period
            // and subsidiary data don't match up. After this.refreshAfterPeriodChange()
            // is done, everything is ok again.
            this.isSyncing = true;
            this.selectedPeriodId = this.periods[selection].id;

            try {
                await this.refreshAfterPeriodChange();
            } catch (error) {
                if (error instanceof Error) {
                    generalStore.setError(error.message, error);
                }

                // Subsidiary empty in this period -> go back
                if (this.subsidiaries.length === 0) {
                    this.selectPeriodByIndex(previousPeriodIndex);
                }
                throw error;
            } finally {
                this.isSyncing = false;
            }
        }
    };

    // Select a period and optionally also provide subsidiary that is to be set as initial selection
    // Returns true if selection was successful
    @action selectPeriodById = async (periodId: string, subsidiaryId?: string) => {
        await this.loadPeriodsIfUnavailable();

        const periodChanged = this.selectedPeriodId !== periodId;
        const subsidiaryChanged = subsidiaryId && this.selectedSubsidiaryId !== subsidiaryId;
        if (!periodChanged) {
            if (subsidiaryChanged) {
                if (!this.subsidiaries || this.subsidiaries.length === 0) {
                    // Subsidiaries not yet loaded -> happens e.g. when we are coming from
                    // a webhook
                    this.selectedSubsidiaryId = subsidiaryId;
                } else {
                    if (this.hasSubsidiaryWithId(subsidiaryId)) {
                        this.selectedSubsidiaryId = subsidiaryId;
                        await this.refreshAfterSubsidiaryChange();
                    } else {
                        debug.error(`### subsidiary ${subsidiaryId} does not exist in current period ${periodId}`);
                        return false;
                    }
                }
            }

            return true;
        }

        // Switch to correct subsidiary first so it is set correctly for refreshAfterPeriodChange()
        if (subsidiaryId) {
            this.selectedSubsidiaryId = subsidiaryId;
        }

        // First, try to find period withing preloaded periods
        const index = this.periods.findIndex(p => p.id === periodId);
        if (index) {
            await this.selectPeriodByIndex(index);
        } else {
            // Period was not found in preloaded periods, so expand periods to include it
            this.selectedPeriodId = periodId;
            await this.expandPeriodArrayToContainSelection(companiesStore.selectedCompanyId);

            try {
                await this.refreshAfterPeriodChange();
            } catch (err) {
                generalStore.setError(t("error.loadPeriodData"));
            }
        }

        if (subsidiaryId && !this.hasSubsidiaryWithId(subsidiaryId)) {
            debug.error(`### subsidiary ${subsidiaryId} does not exist in new period ${periodId}`);
            return false;
        }

        return true;
    };

    // Select the current period, optionally also provide selected subsidiary
    // Returns true if selection was successful
    @action selectCurrentPeriod = async (subsidiaryId?: string) => {
        // Just to be sure, load initial periods if not already loaded
        if (!this.periods || this.periods.length === 0) {
            const companyId = companiesStore.selectedCompanyId;
            if (!companyId) {
                // No company selected -> get out
                return false;
            }

            await this.loadPeriods(companyId);
        }

        const currentPeriodId = getCurrentPeriodId(this.module, this.periods, companiesStore.selectedCompany);
        if (currentPeriodId) {
            return await this.selectPeriodById(currentPeriodId, subsidiaryId);
        }

        return false;
    };

    private expandPeriodArrayToContainSelection = async (companyId?: string) => {
        if (!this.selectedPeriodId || !companyId) {
            return;
        }

        const currentPeriodId = getCurrentPeriodId(this.module, this.periods, companiesStore.selectedCompany);

        // See if we can find the period in the loaded array
        if (!this.periods.find(b => b.id === this.selectedPeriodId)) {
            // Not found -> load period and shift our periods range to include it
            try {
                const period = await API.getPeriod(companyId, this.module, this.selectedPeriodId);

                // Extend our range to include the period
                const range = { start: moment(period.periodStart), end: moment(period.periodEnd) };
                if (range.start.isBefore(this.periodRange.start)) {
                    this.periodRange.start = range.start.startOf("year");
                } else if (range.end.isBefore(this.periodRange.end)) {
                    this.periodRange.end = range.end.endOf("year");
                }

                // Reload periods with new range
                this.periods = await API.getPeriods(companyId, this.module, this.periodRange);
                if (!this.periods.find(b => b.id === this.selectedPeriodId)) {
                    // Still not found? Fallback -> select current period
                    this.selectedPeriodId = currentPeriodId;

                    // Also throw because that should be impossible
                    throw new Error("selected period not in range");
                }
            } catch (err) {
                // only reason would be period/companyId mismatch
                generalStore.setError(t("error.invalidPeriodId"));
                // fallback -> select current period
                this.selectedPeriodId = currentPeriodId;
            }
        }
    };

    private setInitialPeriodAfterLoad = async (companyId: string) => {
        if (!this.selectedPeriodId) {
            this.selectedPeriodId = getCurrentPeriodId(this.module, this.periods, companiesStore.selectedCompany);
            return;
        }

        // We already have a selection -> check if it's contained in loaded range
        await this.expandPeriodArrayToContainSelection(companyId);
    };

    // Don't make this public. I wan to keep control when a full period reload is done.
    private loadPeriods = async (companyId: string) => {
        this.periodRange = getInitialPeriodRange();
        this.periods = await API.getPeriods(companyId, this.module, this.periodRange);

        // Set initial selection, if changed
        if (this.periods.length > 0) {
            this.setInitialPeriodAfterLoad(companyId);
        }

        this.canSelectNextPeriod = this.selectedPeriodIndex !== this.periods.length - 1;
        this.canSelectPreviousPeriod = this.selectedPeriodIndex !== 0;
    };

    reloadCurrentPeriod = async () => {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId || !this.selectedPeriod) {
            return;
        }

        const period = await API.getPeriod(companyId, this.module, this.selectedPeriod.id);
        const index = this.selectedPeriodIndex;
        this.periods[index] = period;
    };

    loadRecordTypes = async (companyId?: string) => {
        if (!companyId) {
            throw new Error("Cannot load record types, no company provided");
        }

        if (!this.selectedPeriodId) {
            throw new Error("Cannot load record types, no period selected");
        }

        if (!this.selectedSubsidiaryId) {
            if (this.subsidiaries.length > 0 && this.canReadAnyRecords()) {
                throw new Error("Cannot load record types, no subsidiary selected");
            }
            return;
        }

        this.recordTypes = await API.getRecordTypes({
            companyId,
            module: this.module,
            periodId: this.selectedPeriodId,
            subsidiaryId: this.selectedSubsidiaryId,
        });

        // Show error if we are allowed to see records
        if (this.recordTypes.length === 0 && this.canReadAnySubsidiaryRecords(this.selectedSubsidiaryId)) {
            generalStore.setError(t("error.noRecordTypes"));
        }
    };

    getRecordTypeForId = (recordTypeId?: string) => {
        return this.recordTypes.find(b => b.id === recordTypeId);
    };

    abstract getRecordTypeName(recordType: RecordType | GetRecordTypesResponse | PermissionsRecordTypes): string;

    @computed
    get hasRecordTickets() {
        return !!this.recordTypes.find(rt => !!rt.ticketCount);
    }

    refreshAfterPeriodChange = async () => {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId) {
            throw new Error(`### "${this.module}" refreshAfterPeriodChange(), no company selected`);
        }

        if (!companiesStore.selectedCompanyStore?.hasModule(this.module)) {
            return;
        }

        await this.loadSubsidiaries(companyId);
        await this.refreshAfterSubsidiaryChange();
    };

    refreshAfterSubsidiaryChange = async () => {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId) {
            throw new Error(`### "${this.module}" refreshAfterSubsidiaryChange(), no company selected`);
        }

        if (!companiesStore.selectedCompanyStore?.hasModule(this.module)) {
            return;
        }

        await this.loadRecordTypes(companyId);
        await this.loadCostCenters(companyId);
        await this.loadFunders(companyId);
    };

    loadFunders = async (companyId: string) => {
        // overload in child if necessary
    };

    loadCostCenters = async (companyId: string) => {
        if (!this.selectedSubsidiaryId) {
            // For accounting cost centers are needed for records
            // For hr cost centers are needed for employees
            // TPAPORTAL-1911: Commented out code below. But keep it for reference, because code was added
            // as fix for TPAPORTAL-851
            // if (
            //     (this.module === "accounting" && authStore.canReadAnyRecords(this.module)) ||
            //     (this.module === "hr" && authStore.canReadAnyEmployees)
            // ) {
            //     generalStore.setError(t("error.loadCostCenters"));
            // }
            return;
        }

        if (this.module === "hr" && !authStore.canReadEmployees(this.selectedSubsidiaryId)) {
            return;
        }

        try {
            this.costCenters = { state: "loading" };
            let { costCenters } = await API.getCostCenters(companyId, this.module, this.selectedSubsidiaryId);

            // deduplicate by id and name
            costCenters = costCenters.filter((costCenter, index, self) => {
                return index === self.findIndex(c => c.id === costCenter.id && c.name === costCenter.name);
            });

            // sort by name and then by id
            costCenters.sort((a, b) => {
                if (a.name === b.name) {
                    return a.id.localeCompare(b.id);
                }
                return a.name.localeCompare(b.name);
            });

            // create quick lookup maps by id and name
            const byName: Record<string, CostCenter[]> = {};
            const byId: Record<string, CostCenter[]> = {};

            costCenters.forEach(costCenter => {
                const { id, name } = costCenter;
                byName[id] ??= [];
                byName[id].push(costCenter);
                byName[name] ??= [];
                byName[name].push(costCenter);
            });

            this.costCenters = { state: "success", data: { costCenters, byId, byName } };
        } catch (err) {
            this.costCenters = { state: "error", error: toError(err) };
            generalStore.setError(t("error.loadCostCenters"), err);
        }
    };
    getCostCenter(id: string | null | undefined, useNameFallback?: boolean) {
        if (id == null || this.costCenters.state !== "success") {
            return undefined;
        }
        let costCenter: CostCenter[] | undefined = this.costCenters.data.byId[id];
        if (!costCenter && useNameFallback === true) {
            costCenter = this.costCenters.data.byName[id];
        }
        return costCenter?.[0];
    }
    getCostCenterLabel(costCenter?: CostCenter): string {
        if (!costCenter || this.costCenters.state !== "success") {
            return "";
        }

        let label = costCenter.name;
        if (costCenter.id && this.costCenters.data.byName[costCenter.name]?.length > 1) {
            label += ` (${costCenter.id})`;
        }
        return label;
    }

    async refreshAfterCompanyChange() {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId) {
            throw new Error(`### "${this.module}" refreshAfterCompanyChange(), no company selected`);
        }

        if (!companiesStore.canAccessCompany) {
            return;
        }

        if (!companiesStore.selectedCompanyStore?.hasModule(this.module)) {
            return;
        }

        // Company has changed -> invalidate previous selections
        this.selectedPeriodId = undefined;
        this.selectedSubsidiaryId = undefined;

        await this.loadPeriods(companyId);
        await this.refreshAfterPeriodChange();
    }

    // records

    canReleaseAllSubsidiaryRecords() {
        if (!this.selectedPeriod || !this.selectedSubsidiaryId) {
            return false;
        }
        const subsidiaryPermissions = getSubsidiaryPermissions(this.permissions.raw, this.selectedSubsidiaryId);
        if (!subsidiaryPermissions?.recordTypes) {
            return false;
        }
        return ModuleStore.canReleaseAllSubsidiaryRecords(
            this.selectedPeriod.periodEnd,
            subsidiaryPermissions.recordTypes,
            this.recordTypes,
        );
    }

    static canReleaseAllSubsidiaryRecords(
        rawPeriodEnd: string,
        permissionsRecordTypes: PermissionsRecordTypes[],
        recordTypes: IRecordType[],
    ) {
        const periodEnd = moment(rawPeriodEnd).endOf("day");

        // find all permissions record types that are not deleted in the current period
        const activePermissionsRecordTypes = permissionsRecordTypes.filter(rt => {
            return !rt.deletedAt || periodEnd.isBefore(rt.deletedAt);
        });

        const allRecordTypesExist =
            recordTypes.length === activePermissionsRecordTypes.length &&
            recordTypes.every(rt => activePermissionsRecordTypes.find(r => r.id === rt.id));
        if (!allRecordTypesExist) {
            return false;
        }

        return activePermissionsRecordTypes.every(rt => containsActions(rt.permission.actions, ["release"]));
    }

    transferRecords = async () => {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId) {
            throw new Error("transferRecords is missing companyId");
        }

        const subsidiaryId = this.selectedSubsidiaryId;
        if (!subsidiaryId) {
            throw new Error("transferRecords is missing subsidiaryId");
        }

        const periodId = this.selectedPeriodId;
        if (!periodId) {
            throw new Error("transferRecords is missing periodId");
        }

        return await API.transferRecords({ companyId, module: this.module, periodId, subsidiaryId });
    };

    canReleaseAllSubsidiaryRecordTypeRecords(recordTypeId: string) {
        const subsidiaryPermissions = getSubsidiaryPermissions(this.permissions.raw, this.selectedSubsidiaryId);
        // we can ignore the "deletedAt" check like it's done in canReleaseAllSubsidiaryRecords
        // because the user can only open active record types (= !deletedAt) anyway.
        return !!subsidiaryPermissions && recordTypeHasActions(subsidiaryPermissions, recordTypeId, ["release"]);
    }

    transferRecordTypeRecords = async (recordTypeID: string) => {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId) {
            throw new Error("transferRecordTypeRecords is missing companyId");
        }

        const subsidiaryId = this.selectedSubsidiaryId;
        if (!subsidiaryId) {
            throw new Error("transferRecordTypeRecords is missing subsidiaryId");
        }

        const periodId = this.selectedPeriodId;
        if (!periodId) {
            throw new Error("transferRecordTypeRecords is missing periodId");
        }

        return await API.transferRecordTypeRecords({
            companyId,
            module: this.module,
            periodId,
            subsidiaryId,
            recordTypeID,
        });
    };

    uploadRecord = async (recordFile: File, recordTypeId: string): Promise<PostRecordResponse> => {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId) {
            throw new Error("uploadRecord is missing company");
        }

        const subsidiaryId = this.selectedSubsidiaryId;
        if (!subsidiaryId) {
            throw new Error("uploadRecord is missing subsidiary");
        }

        const periodId = this.selectedPeriodId;
        if (!periodId) {
            throw new Error("uploadRecord is missing period");
        }

        return API.postRecords({
            file: recordFile,
            companyId,
            module: this.module,
            subsidiaryId,
            periodId,
            recordTypeId,
        });
    };

    // reports

    canReadGlobalReports() {
        return this.permissions.hasGlobalActions(`${this.module}:company:reports`, ["read"]);
    }

    canReadGlobalReportTickets() {
        return this.permissions.hasGlobalActions(`${this.module}:company:reports`, ["ticketRead"]);
    }

    canCreateGlobalReportTickets() {
        return this.permissions.hasGlobalActions(`${this.module}:company:reports`, ["ticketCreate"]);
    }

    canReleaseGlobalReports() {
        return this.permissions.hasGlobalActions(`${this.module}:company:reports`, ["release"]);
    }

    canDeleteGlobalReports() {
        return this.permissions.hasGlobalActions(`${this.module}:company:reports`, ["delete"]);
    }

    canReadReports(subsidiaryId?: string) {
        return this.permissions.hasSubsidiaryActions(subsidiaryId, `${this.module}:reports`, ["read"]);
    }

    canReadReportTickets(subsidiaryId?: string) {
        return this.permissions.hasSubsidiaryActions(subsidiaryId, `${this.module}:reports`, ["ticketRead"]);
    }

    canCreateReportTickets(subsidiaryId?: string) {
        return this.permissions.hasSubsidiaryActions(subsidiaryId, `${this.module}:reports`, ["ticketCreate"]);
    }

    canReleaseReports(subsidiaryId?: string) {
        return this.permissions.hasSubsidiaryActions(subsidiaryId, `${this.module}:reports`, ["release"]);
    }

    canDeleteReports(subsidiaryId?: string) {
        return this.permissions.hasSubsidiaryActions(subsidiaryId, `${this.module}:reports`, ["delete"]);
    }

    getReportPermissions(subsidiaryId?: string) {
        const canReadGlobal = this.canReadGlobalReports();
        const canReadLocal = this.canReadReports(subsidiaryId);

        const canReleaseGlobal = this.canReleaseGlobalReports();
        const canReleaseLocal = this.canReleaseReports(subsidiaryId);

        const canDeleteGlobal = this.canDeleteGlobalReports();
        const canDeleteLocal = this.canDeleteReports(subsidiaryId);

        const canReadGlobalTickets = this.canReadGlobalReportTickets();
        const canReadLocalTickets = this.canReadReportTickets(subsidiaryId);

        const canCreateGlobalTickets = this.canCreateGlobalReportTickets();
        const canCreateLocalTickets = this.canCreateReportTickets(subsidiaryId);

        return {
            canReadGlobal,
            canReadLocal,
            canReleaseGlobal,
            canReleaseLocal,
            canDeleteGlobal,
            canDeleteLocal,
            canReadGlobalTickets,
            canReadLocalTickets,
            canCreateGlobalTickets,
            canCreateLocalTickets,
        };
    }

    canSeeOverviewSite() {
        const subsidiaryId = this.selectedSubsidiaryId;
        const reportPermissions = this.getReportPermissions(subsidiaryId);
        return (
            // You see overview site if you can read reports or edit records
            this.canEditAnySubsidiaryRecords(subsidiaryId) ||
            reportPermissions.canReadGlobal ||
            reportPermissions.canReadLocal ||
            // Or if you can see employees
            (this.module === "hr" && authStore.canReadAnyEmployees)
        );
    }

    canReadAnyRecords() {
        // First check if module is in any way included
        if (!companiesStore.selectedCompanyStore?.hasModule(this.module)) {
            return false;
        }

        // If accounting -> check if user accounting is enabled
        if (this.module === "accounting" && companiesStore.selectedCompanyStore.company.accountingByCustomer !== true) {
            return false;
        }

        // Now check if user has any read permission
        return this.permissions.modulesWithReadAccess.includes(this.module);
    }

    canEditAnyRecords() {
        if (!this.canReadAnyRecords()) {
            return false;
        }

        // Now check if user has any edit permission
        return this.permissions.modulesWithEditAccess.includes(this.module);
    }

    canReadAnySubsidiaryRecords(subsidiaryId?: string) {
        if (!this.canReadAnyRecords()) {
            return false;
        }

        const subsidiaryPermissions = getSubsidiaryPermissions(this.permissions.raw, subsidiaryId);
        return !!subsidiaryPermissions && hasAnyRecordTypeWithPermission(subsidiaryPermissions, "canRead");
    }

    canReadRecords(subsidiaryId: string | undefined, recordTypeId: string) {
        if (!this.canReadAnyRecords()) {
            return false;
        }

        return this.permissions.hasRecordTypePermission(
            subsidiaryId,
            recordTypeId,
            `${this.module}:records`,
            "canRead",
        );
    }

    canEditAnySubsidiaryRecords(subsidiaryId?: string) {
        if (!this.canEditAnyRecords()) {
            return false;
        }

        const subsidiaryPermissions = getSubsidiaryPermissions(this.permissions.raw, subsidiaryId);
        return !!subsidiaryPermissions && hasAnyRecordTypeWithPermission(subsidiaryPermissions, "canEdit");
    }

    canEditRecords(subsidiaryId: string | undefined, recordTypeId?: string) {
        if (!this.canEditAnyRecords()) {
            return false;
        }

        return this.permissions.hasRecordTypePermission(
            subsidiaryId,
            recordTypeId,
            `${this.module}:records`,
            "canEdit",
        );
    }

    releaseReport = async ({
        companyId = companiesStore.selectedCompanyId,
        reportId,
        isGlobal = false,
        periodId = this.selectedPeriodId,
        subsidiaryId = this.selectedSubsidiaryId,
    }: {
        companyId?: string;
        reportId: string;
        isGlobal: boolean;
        periodId?: string;
        subsidiaryId?: string;
    }) => {
        if (!companyId || !periodId || (!isGlobal && !subsidiaryId)) {
            return;
        }

        try {
            generalStore.isLoading = true;
            await API.releaseReport(companyId, this.module, periodId, reportId, isGlobal ? undefined : subsidiaryId);

            // Reload badges to reflect due dates
            if (companyId && companyId === companiesStore.selectedCompanyStore?.id) {
                await companiesStore.selectedCompanyStore.startPollingBadges();
            }
        } catch (err) {
            generalStore.setError(t("error.releaseReport"), err);
        } finally {
            generalStore.isLoading = false;
        }
    };

    deleteReport = async (reportId: string, isGlobal = false) => {
        const subsidiaryId = this.selectedSubsidiaryId;
        const periodId = this.selectedPeriodId;

        if (!companiesStore.selectedCompanyId || !subsidiaryId || !periodId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            await API.deleteReport(
                companiesStore.selectedCompanyId,
                this.module,
                periodId,
                reportId,
                isGlobal ? undefined : subsidiaryId,
            );
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.Conflict_409) {
                generalStore.setError(t("error.periodClosed"));
            } else {
                generalStore.setError(t("error.delete"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    };

    parseDeepLink = (deepLink: IDeepLinkInfo) => {
        debug.log("### deepLink parsing module:", this.module);
        debug.log("### deepLink target module:", deepLink.module);
        if (deepLink.module === this.module) {
            // Even if it's undefined assign it, because if e.g. the companyId is set,
            // then the persisted period and subsidiary will be invalid anyway
            this.selectedPeriodId = deepLink.query.periodId;
            this.selectedSubsidiaryId = deepLink.query.subsidiaryId;

            debug.log("### deepLink period:", deepLink.query.periodId);
            debug.log("### deepLink subsidiary:", deepLink.query.subsidiaryId);
        } else {
            this.selectedPeriodId = undefined;
            this.selectedSubsidiaryId = undefined;

            debug.log("### deepLink period reset");
            debug.log("### deepLink subsidiary reset");
        }
    };
}
