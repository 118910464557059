import { Snackbar as MuiSnackbar, Slide, SlideProps } from "@material-ui/core";
import { observer } from "mobx-react";
import { SNACKBAR_DURATION } from "../../config";
import { t } from "../../i18n/util";
import { isApiError } from "../../network/NetworkStapler";
import { Notification, generalStore } from "../../stores/GeneralStore";

const SlideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="up" />;
};

export const Snackbar = observer(function Snackbar() {
    const handleClose = (_: unknown, reason: "timeout" | "clickaway") => {
        if (reason !== "clickaway") {
            generalStore.popNotification();
        }
    };

    const notification = generalStore.notification;

    const message = createMessage(notification);

    return (
        <MuiSnackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={!!notification}
            key={notification?.message}
            onClose={handleClose}
            autoHideDuration={SNACKBAR_DURATION}
            message={message}
            TransitionComponent={SlideTransition}
            data-severity={notification?.severity}
        />
    );
});

function createMessage(notification: Notification | undefined) {
    if (!notification) {
        return "";
    }

    let prefix = "";
    let postfix = "";

    if (notification.severity === "error" && notification.message.startsWith(t("error.general"))) {
        if (notification.error && "statusCode" in notification.error && notification.error.statusCode) {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            prefix = `${notification.error.statusCode.toString()}: `;
        }

        if (notification.error?.message && !isApiError(notification.error)) {
            postfix = ` (${notification.error.message})`;
        }
    }

    return `${prefix}${notification.message}${postfix}`;
}
