import moment from "moment";
import * as React from "react";
import { t } from "../../i18n/util";
import { formatDate } from "../../util/helpers";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { InfoButton, TableRowButton } from "./Primitives";

export const OverviewLine = (props: {
    title: string;
    infoText?: React.ReactNode;
    label?: string | null;
    date?: string | Date | moment.Moment | null;
    number?: number | "" | null;
    component?: React.ReactNode;
    bonuses?: { designator: string; amount?: number | string | null }[];
    buttonLabel?: string;
    onButtonClick?: () => void;
}) => {
    const isMobile = React.useContext(MobileContext);
    let value: React.ReactNode = undefined;

    if (props.label) {
        value = props.label;
    } else if (props.date) {
        value = formatDate(props.date);
    } else if (props.number) {
        value = props.number;
    } else if (props.component) {
        value = props.component;
    } else if (props.bonuses && props.bonuses.length > 0) {
        if (props.bonuses[0].designator) {
            value = props.bonuses.map((bonus, index) => {
                return (
                    <p key={index}>
                        {bonus.designator}: {bonus.amount}
                    </p>
                );
            });
        } else {
            value = t("common.notSpecified");
        }
    } else {
        value = t("common.notSpecified");
    }

    if (value === t("common.notSpecified")) {
        return null;
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "flex-start" : "center",
                padding: "10px 0",
                borderBottom: `1px solid ${customColors.greyLight}`,
            }}
        >
            <p className="caption" style={isMobile ? undefined : { minWidth: "30%", marginRight: 24 }}>
                {props.title}
                {props.infoText && (
                    <InfoButton
                        color="primary"
                        style={{
                            display: "inline-block",
                            padding: 0,
                            marginLeft: 4,
                        }}
                        title={props.infoText}
                    />
                )}
            </p>

            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div className="body1">{value}</div>
                {props.onButtonClick && props.buttonLabel && (
                    <div>
                        <TableRowButton color="primary" onClick={props.onButtonClick}>
                            {props.buttonLabel}
                        </TableRowButton>
                    </div>
                )}
            </div>
        </div>
    );
};
