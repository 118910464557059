import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetReasonsForLeavingResponse } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useReasonsForLeaving = () => {
    const [reasons, setReasons] = React.useState<GetReasonsForLeavingResponse | null>(null);

    React.useEffect(() => {
        const load = async () => {
            try {
                const response = await API.getReasonsForLeaving();
                setReasons(response);
            } catch (err) {
                generalStore.setError(t("error.loadCompany"), err);
            }
        };

        load();
    }, []);

    return {
        reasons,
        findById(reasonId: string) {
            const ret = reasons?.find(reason => reason.id === reasonId);
            // console.log("#### reason for leaving", reasonId, ret, reasons);
            return ret;
        },
        strings: reasons?.map(reason => reason.reason) ?? [],
        idToString(reasonId?: string) {
            if (!reasonId) {
                return "";
            }

            const found = reasons?.find(reason => {
                return reason.id === reasonId;
            });

            // Fallback for TPAPORTAL-1286 -> return reasonId because BMD returns reason directly instead of id
            return found?.reason ?? reasonId;
        },
        stringToId(reasonString: string) {
            const found = reasons?.find(reason => {
                return reason.reason === reasonString;
            });
            return found?.id;
        },
    };
};
