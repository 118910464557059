import { Button } from "@material-ui/core";
import * as React from "react";
import { useCrossTabState } from "../../hooks/useCrossTabState";
import { MSCloseRefreshState } from "./AuthMSCloseSite";

export const AuthMSRefreshOfflineSite = () => {
    const [refreshState, setRefreshState] = useCrossTabState<MSCloseRefreshState>("refreshProjectOverview", "waiting");

    return (
        <div style={{ padding: 64 }}>
            <h1 style={{ marginBottom: 16 }}>Refresh offline test site</h1>

            <Button
                color="primary"
                variant="outlined"
                fullWidth
                onClick={() => {
                    setRefreshState("refresh");
                    window.close();
                }}
            >
                Close window
            </Button>
        </div>
    );
};
