import compact from "lodash/compact";
import * as React from "react";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { ModuleNavBar } from "../ui/ModuleNavBar";
import { SettingsRoutes } from "./router/SettingsRoutes";

export const SettingsNavBar = (props: { tabs?: React.ReactNode }) => {
    const companyStore = companiesStore.selectedCompanyStore;

    return (
        <ModuleNavBar
            title={t("common.settings")}
            buttons={compact([
                { title: t("settings.navbar.general"), value: "general", route: SettingsRoutes.GENERAL.ROOT },
                (authStore.canReadCompanySettings || authStore.canEditCompanyImages) && {
                    title: t("settings.navbar.company"),
                    value: "company",
                    route: SettingsRoutes.COMPANY.ROOT,
                },
                authStore.canReadUserSettings && {
                    title: t("settings.navbar.userManagement"),
                    value: "userManagement",
                    route: SettingsRoutes.USER_MANAGEMENT.ROOT,
                    badgeCount:
                        authStore.isSuperAdmin && companyStore
                            ? companiesStore.getWaitingUsers(companyStore.id).length
                            : undefined,
                },
            ])}
            tabs={props.tabs}
        />
    );
};
