import { Menu, MenuProps, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { generalStore } from "../../stores/GeneralStore";
import { ModuleStore, periodToString } from "../../stores/ModuleStore";
import { formatDate } from "../../util/helpers";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { BOX_SHADOW_LIGHT, customColors } from "../util/Theme";

interface IProps {
    moduleStore: ModuleStore;
    readOnly?: boolean;
    style?: React.CSSProperties;
}

const StyledMenuDate = withStyles({
    paper: {
        boxShadow: BOX_SHADOW_LIGHT,
        marginTop: 4,
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
));

export const NavBarChoosePeriod = observer(function NavBarChoosePeriod({ moduleStore, readOnly, style }: IProps) {
    const [dateEl, setDateEl] = React.useState<null | HTMLElement>(null);
    const isMobile = React.useContext(MobileContext);

    const handleDateOpen = (event: React.MouseEvent<HTMLElement>) => {
        setDateEl(event.currentTarget);
    };

    const handleDateClose = () => {
        setDateEl(null);
    };

    const iconColor = !isMobile && readOnly ? customColors.greyButton : customColors.primaryColor;

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: readOnly && !isMobile ? undefined : "pointer",
                    ...style,
                }}
                onClick={readOnly && !isMobile ? undefined : handleDateOpen}
            >
                <div>
                    <Icon name="calendar" style={{ display: "block", color: iconColor }} />
                </div>
                {!isMobile && (
                    <span
                        style={{
                            color: customColors.body2Dark,
                            marginLeft: 8,
                            fontSize: 16,
                            whiteSpace: "nowrap",
                        }}
                    >
                        {periodToString(moduleStore.selectedPeriod)}
                    </span>
                )}
            </div>
            <StyledMenuDate
                data-id="choose_booking_period_picker"
                id="customized-menu"
                anchorEl={dateEl}
                keepMounted
                open={!!dateEl}
                onClose={handleDateClose}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        outline: "none",
                    }}
                >
                    <Icon
                        data-id="previous_period_icon"
                        name="chevronLeft"
                        style={{
                            margin: "16px 0px 16px 20px",
                            color:
                                moduleStore.canSelectPreviousPeriod && !readOnly
                                    ? customColors.greyDarkIcons
                                    : customColors.greyLight,

                            cursor: readOnly ? undefined : "pointer",
                        }}
                        onClick={
                            moduleStore.canSelectPreviousPeriod && !readOnly && !generalStore.isLoading
                                ? () => moduleStore.selectPreviousPeriod()
                                : undefined
                        }
                    />
                    <span
                        style={{
                            fontFamily: "Archivo",
                            fontWeight: "bold",
                            textAlign: "center",
                            userSelect: "none",
                            minWidth: 160,
                            maxWidth: 160,
                        }}
                    >
                        {periodToString(moduleStore.selectedPeriod)}
                    </span>
                    <Icon
                        name="chevronRight"
                        data-id="next_period_icon"
                        style={{
                            margin: "16px 20px 16px 0px",
                            color:
                                moduleStore.canSelectNextPeriod && !readOnly
                                    ? customColors.greyDarkIcons
                                    : customColors.greyLight,
                            cursor: readOnly ? undefined : "pointer",
                        }}
                        onClick={
                            moduleStore.canSelectNextPeriod && !readOnly && !generalStore.isLoading
                                ? () => moduleStore.selectNextPeriod()
                                : undefined
                        }
                    />
                </div>
                <span
                    className="body2"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        color: customColors.primaryColor,
                        paddingBottom: 24,
                        userSelect: "none",
                    }}
                >
                    {`${formatDate(moduleStore.selectedPeriod?.periodStart)} - 
                    ${formatDate(moduleStore.selectedPeriod?.periodEnd)}`}
                </span>
            </StyledMenuDate>
        </>
    );
});
