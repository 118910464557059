import * as React from "react";
import { AUTO_REDIRECT_TIMEOUT } from "../../config";

export const useTimeoutDialog = (onClose?: () => void) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const [timeoutId, setTimeoutId] = React.useState(0);

    const openDialog = () => {
        setDialogOpen(true);
        setTimeoutId(setTimeout(closeDialog, AUTO_REDIRECT_TIMEOUT));
    };

    // Has to be useCallback, because used in useSuccessDialog() <Component/>
    const closeDialog = React.useCallback(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(0);
        }
        setDialogOpen(false);
        if (onClose) {
            onClose();
        }
    }, [onClose, timeoutId]);
    return { isDialogOpen, openDialog, closeDialog };
};
