import { Redirect, Route, Switch } from "react-router-dom";
import { GLOBAL_FEATURES } from "../../../features";
import { authStore } from "../../../stores/AuthStore";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { ModuleRouter } from "../../shared/router/ModuleRouter";
import { accountingStore } from "../AccountingStore";
import { AssignInvoicesToBankAccountTransactionsSite } from "../sites/AssignInvoicesToBankAccountTransactionsSite";
import { BankAccountTransactionsSite } from "../sites/BankAccountTransactionsSite";
import { BankConnectionSite } from "../sites/BankConnectionSite";
import { AccountingRoutes } from "./AccountingRoutes";

export const AccountingRouter = () => {
    return (
        <Switch>
            {getBankingRoutes()}
            <Route path={AccountingRoutes.ROOT}>
                <ModuleRouter module="accounting" />
            </Route>
            <Route component={NotFoundSite} />
        </Switch>
    );
};

const getBankingRoutes = () => {
    if (!GLOBAL_FEATURES.bankConnections) {
        return null;
    }

    const canReadBanking = authStore.canReadBanking();
    if (!canReadBanking) {
        return null;
    }

    const routes = AccountingRoutes.BANKING;

    const onlyBanking = !accountingStore.canSeeOverviewSite() && canReadBanking;
    return [
        onlyBanking ? (
            <Redirect key={AccountingRoutes.ROOT} exact from={AccountingRoutes.ROOT} to={routes.CONNECTIONS.ROOT} />
        ) : null,
        <Route key={routes.CONNECTIONS.ROOT} exact path={routes.CONNECTIONS.ROOT}>
            <BankConnectionSite />
        </Route>,
        <Route key={routes.CONNECTIONS.ACCOUNTS.TRANSACTIONS} exact path={routes.CONNECTIONS.ACCOUNTS.TRANSACTIONS}>
            <BankAccountTransactionsSite />
        </Route>,
        <Route
            key={routes.CONNECTIONS.ACCOUNTS.ASSIGN_INVOICES_TO_TRANSACTIONS}
            exact
            path={routes.CONNECTIONS.ACCOUNTS.ASSIGN_INVOICES_TO_TRANSACTIONS}
        >
            <AssignInvoicesToBankAccountTransactionsSite />
        </Route>,
    ];
};
