import { Collapse } from "@material-ui/core";
import * as React from "react";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";

export interface ExpansionLineProps {
    title: string;
    label: string;
    children: React.ReactNode;
}

export const ExpansionLine = ({ title, label, children }: ExpansionLineProps) => {
    const isMobile = React.useContext(MobileContext);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "flex-start" : "center",
                    padding: "10px 0",
                    borderBottom: `1px solid ${customColors.greyLight}`,
                    cursor: "pointer",
                }}
                onClick={handleExpandClick}
                aria-expanded={expanded}
            >
                <b className="caption" style={isMobile ? undefined : { minWidth: "30%", marginRight: 24 }}>
                    {title}
                </b>

                <div className="body1">{label}</div>
                <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                    {expanded ? <Icon name="chevronUp" /> : <Icon name="chevronDown" />}
                </div>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </>
    );
};
