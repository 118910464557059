import { TextField, TextFieldProps } from "@material-ui/core";
import { FieldInputProps, FormikProps, getIn } from "formik";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { numberToLocaleString } from "../../util/helpers";

type IProps = TextFieldProps & {
    field: FieldInputProps<string>;
    hideOptional?: boolean;
    /** Trim the value on blur (default: true) */
    trimOnBlur?: boolean;
    form: FormikProps<unknown>;
    "data-id": string;
};

export const FieldError = styled.span`
    color: #f00;
    display: block;
    min-height: 18px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
`;

export const CustomInputField = ({
    style,
    label,
    type,
    autoComplete,
    required,
    field,
    hideOptional,
    trimOnBlur,
    disabled,
    onChange,
    onBlur,
    multiline,
    minRows,
    form,
    autoFocus,
    "aria-label": ariaLabel,
    "data-id": dataId,
}: IProps) => {
    const fieldError = getIn(form.errors, field.name) as React.ReactNode;
    const showError = (getIn(form.touched, field.name) as boolean) && !!fieldError;

    return (
        <div style={style}>
            <TextField
                inputProps={{ "data-id": dataId }}
                disabled={disabled}
                label={label}
                multiline={multiline}
                value={typeof field.value === "number" ? numberToLocaleString(field.value) : field.value}
                name={field.name}
                onChange={e => {
                    field.onChange(e);
                    if (onChange) {
                        onChange(e);
                    }
                }}
                onBlur={e => {
                    if (trimOnBlur !== false && typeof field.value !== "number") {
                        form.setFieldValue(field.name, field.value.trim());
                    }
                    field.onBlur(e);
                    if (onBlur) {
                        onBlur(e);
                    }
                }}
                fullWidth
                type={type}
                autoComplete={autoComplete}
                error={showError}
                variant="outlined"
                aria-label={ariaLabel}
                minRows={minRows}
                autoFocus={autoFocus}
                helperText={!required && !hideOptional && t("common.optional")}
            />
            <FieldError>{showError && fieldError}</FieldError>
        </div>
    );
};
