import { Button, MenuItem, TextField } from "@material-ui/core";
import * as React from "react";
import { Redirect, useParams } from "react-router-dom";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { GetTermsOfUseSigneesResponse, PutTermsOfUseSigneesPayload, TermsOfUseSignee } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { mailValidation } from "../../../util/helpers";
import { pushRoute, withParams } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { useSignees } from "../../hooks/useSignees";
import { useTermsOfUseDocuments } from "../../hooks/useTermsOfUseDocuments";
import { FieldError } from "../../ui/CustomInputField";
import { NavBarBack } from "../../ui/NavBarBack";
import { PageWithStepper } from "../../ui/PageWithStepper";
import { SigneeList } from "../../ui/SigneeList";
import { Icon } from "../../util/Icon";
import { AdvisorConfigCompanyStepper } from "../AdvisorConfigCompanyStepper";
import { AdvisorRoutes } from "../router/AdvisorRoutes";

const AlreadySigned = ({
    onClickRevokeTermsOfUse,
    style,
}: {
    onClickRevokeTermsOfUse: () => void;
    style?: React.CSSProperties;
}) => {
    return (
        <div style={{ display: "flex", alignContent: "center", ...style }}>
            <Icon name="checkmark" />
            <p>{t("config.terms.alreadySigned")}</p>
            {/* <Button variant="contained" color="primary" onClick={onClickRevokeTermsOfUse}> // TODO button to revoke terms of use consent - needs to be clarified if even needed
                {t("config.terms.button.revokeActivation")}
            </Button> */}
        </div>
    );
};

const TermsOfUseSubmitButton = ({
    onSubmit,
    isSettings,
    disabled,
    style,
}: {
    onSubmit: () => void;
    isSettings?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
}) => (
    <Button color="primary" variant="contained" onClick={onSubmit} style={style} disabled={disabled}>
        {isSettings ? t("common.save") : t("button.nextStep")}
    </Button>
);
const TermsOfUseSkipButton = ({ onSubmit, disabled }: { onSubmit: () => void; disabled?: boolean }) => {
    const dialog = useConfirmationDialog({
        message: t("config.terms.skip.dialog.message"),
        confirmLabel: t("common.ok"),
        onConfirm: onSubmit,
        title: t("config.terms.skip.dialog.title"),
    });

    return (
        <>
            <Button color="primary" variant="text" onClick={() => dialog.open()} disabled={disabled}>
                {t("config.terms.skip.button")}
            </Button>
            {dialog.dialog}
        </>
    );
};

export const AdvisorConfigTermsOfUseContentWithCurrentSignees = ({
    variant,
    companyId,
    companyTermsId,
    isSettings,
    termsAccepted,
    onSubmit,
    onSkip,
    onAlreadyAcceptedSubmit,
}: {
    variant: "current" | "upcoming";
    companyId: string;
    companyTermsId?: string;
    isSettings?: boolean;
    termsAccepted?: boolean;
    onSubmit: (documentVersion: string, signees: TermsOfUseSignee[]) => void;
    onSkip: () => void;
    onAlreadyAcceptedSubmit: () => void;
}) => {
    const [signees, setSignees] = React.useState<TermsOfUseSignee[]>([]);
    const [documentVersion, setDocumentVersion] = React.useState("");

    // load the current signees and terms of use document
    const { signees: previousSignees, initialized } = useSignees(companyId, companyTermsId);

    React.useEffect(() => {
        setDocumentVersion(previousSignees?.fileName ?? "");
        setSignees(previousSignees?.signees ?? []);
    }, [previousSignees]);

    if (!initialized) {
        return null;
    }

    const handleSubmit = () => {
        onSubmit(documentVersion, signees);
    };

    return (
        <AdvisorConfigTermsOfUseContent
            variant={variant}
            companyId={companyId}
            isSettings={isSettings}
            termsAccepted={termsAccepted}
            previousSignees={previousSignees}
            signees={signees}
            onSigneesChange={setSignees}
            documentVersion={documentVersion}
            onDocumentVersionChange={setDocumentVersion}
            onSubmit={handleSubmit}
            onSkip={onSkip}
            onAlreadyAcceptedSubmit={onAlreadyAcceptedSubmit}
        />
    );
};

export const AdvisorConfigTermsOfUseContent = (props: {
    variant: "current" | "upcoming";
    companyId: string;
    isSettings?: boolean;
    termsAccepted?: boolean;
    previousSignees?: GetTermsOfUseSigneesResponse;
    signees: TermsOfUseSignee[];
    onSigneesChange: (signees: TermsOfUseSignee[]) => void;
    documentVersion: string;
    onDocumentVersionChange: (documentVersion: string) => void;
    onSubmit: () => void;
    onSkip: () => void;
    onAlreadyAcceptedSubmit: () => void;
}) => {
    const {
        variant,
        companyId,
        isSettings,
        termsAccepted,
        previousSignees,
        signees,
        onSigneesChange,
        documentVersion,
        onDocumentVersionChange,
        onSubmit,
        onSkip,
    } = props;

    // only disable the submit button if we are viewing the current terms and they are already accepted
    const disabled = variant === "current" && !!termsAccepted;

    const [email, setEmail] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState<string>();
    const [documentError, setDocumentError] = React.useState<string>();
    const { documents } = useTermsOfUseDocuments(companyId);
    const [targetDocumentVersion, setTargetDocumentVersion] = React.useState("");

    const handleSetDocumentVersion = () => {
        if (targetDocumentVersion) {
            onDocumentVersionChange(targetDocumentVersion);
        }
    };

    const changeDocumentVersionDialog = useConfirmationDialog({
        message: t("config.terms.changeDocumentVersionDialog.message"),
        confirmLabel: t("config.terms.changeDocumentVersionDialog.confirmLabel"),
        onConfirm: handleSetDocumentVersion,
        title: t("config.terms.changeDocumentVersionDialog.title"),
    });

    const allSigneesAcceptedWarningDialog = useConfirmationDialog({
        message: t("config.terms.allSigneesAcceptedWarningDialog.message"),
        confirmLabel: t("config.terms.allSigneesAcceptedWarningDialog.confirmLabel"),
        onConfirm: onSubmit,
        title: t("config.terms.allSigneesAcceptedWarningDialog.title"),
    });

    const handleAddEmail = () => {
        const hasValidEmail = mailValidation.isValidSync({ username: email });
        const signeesEmail = signees.map(signee => signee.email);

        if (signeesEmail.includes(email)) {
            setEmailError(t("config.terms.emailAlreadyUsed"));
        } else {
            if (hasValidEmail) {
                const newSignees = [...signees, { id: "", email: email }];
                setEmail("");
                setEmailError("");
                onSigneesChange(newSignees);
            } else {
                if (email) {
                    setEmailError(t("config.terms.invalidEmail"));
                } else {
                    setEmailError(t("error.missingEmail"));
                }
            }
        }
    };

    const handleChangeDocumentVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (documentVersion) {
            setTargetDocumentVersion(event.target.value);
            changeDocumentVersionDialog.open();
        } else {
            onDocumentVersionChange(event.target.value);
        }
    };

    const handleResendEmail = async (id: string) => {
        try {
            generalStore.isLoading = true;

            await API.putTermsOfUseResendEmail(companyId, id);
        } catch (error) {
            generalStore.setError(t("error.resendTermsOfUseEmail"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const handleDeleteSignee = (email: string) => {
        const newSignees = signees.filter(signee => signee.email !== email);

        onSigneesChange(newSignees ?? []);
    };

    const handleSubmit = () => {
        const everySigneeAccepted = signees.every(signee => !!signee.signedAt);

        if (!documentVersion && signees.length === 0) {
            setDocumentError(t("error.noDocumentSelected"));
            setEmailError(t("error.noSignees"));
        } else if (!documentVersion) {
            setEmail("");
            setDocumentError(t("error.noDocumentSelected"));
        } else if (signees.length === 0) {
            setDocumentError("");
            setEmailError(t("error.noSignees"));
        } else if (everySigneeAccepted && signees.length > 0) {
            allSigneesAcceptedWarningDialog.open();
        } else {
            setEmailError("");
            setDocumentError("");
            onSubmit();
        }
    };

    return (
        <>
            <h3 style={{ hyphens: "auto", wordBreak: "break-all" }}>
                {variant === "current" ? t("config.terms.termsOfUseTitle") : t("common.upcomingTermsOfUse")}
            </h3>
            {termsAccepted && <AdvisorConfigTermsOfUseContentAccepted {...props} />}
            {!disabled && <p style={{ marginTop: 24 }}>{t("config.terms.termsOfUseInfo")}</p>}
            <div style={{ display: "flex", flexDirection: "column", gap: 24, marginTop: 32 }}>
                {!documents && <h4>{t("config.terms.noDocumentsAvailable")}</h4>}
                {documents && (
                    <>
                        <TextField
                            variant="outlined"
                            select
                            label={t("config.terms.documentVersion")}
                            value={documentVersion}
                            onChange={handleChangeDocumentVersion}
                            disabled={disabled}
                        >
                            {documents.map((option, i) => (
                                <MenuItem key={i} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        {!disabled && <FieldError>{documentError}</FieldError>}
                        <h3
                            style={{
                                hyphens: "auto",
                                wordBreak: "break-all",
                                marginTop: !isSettings ? 16 : undefined,
                            }}
                        >
                            {t("config.terms.emailAddressesTitle")}
                        </h3>
                        {!disabled && (
                            <>
                                <p>{t("config.terms.emailAddressesInfo")}</p>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label={t("common.emailAddress")}
                                        type="email"
                                        value={email}
                                        onChange={e => {
                                            setEmail(e.target.value);
                                        }}
                                        onKeyUp={event => {
                                            if (event.key === "Enter") {
                                                handleAddEmail();
                                            }
                                        }}
                                    />
                                    <Button
                                        color="primary"
                                        onClick={handleAddEmail}
                                        style={{ marginLeft: 16, alignContent: "right" }}
                                    >
                                        {t("common.add")}
                                    </Button>
                                </div>
                                <FieldError>{emailError}</FieldError>
                            </>
                        )}
                        <SigneeList
                            signees={signees}
                            fileName={previousSignees?.fileName}
                            onResendEmail={handleResendEmail}
                            onDelete={handleDeleteSignee}
                            disabled={disabled}
                            disableResendMail={!isSettings}
                        />
                    </>
                )}
                {!disabled && (
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 8, gap: 16 }}>
                        {isSettings ? null : (
                            <TermsOfUseSkipButton onSubmit={onSkip} disabled={!documents && !documentVersion} />
                        )}
                        <TermsOfUseSubmitButton
                            onSubmit={handleSubmit}
                            isSettings={isSettings}
                            disabled={!documents && !documentVersion && isSettings}
                        />
                    </div>
                )}
            </div>
            {changeDocumentVersionDialog.dialog}
            {allSigneesAcceptedWarningDialog.dialog}
        </>
    );
};

const AdvisorConfigTermsOfUseContentAccepted = ({
    isSettings,
    onAlreadyAcceptedSubmit,
}: {
    isSettings?: boolean;
    onAlreadyAcceptedSubmit: () => void;
}) => {
    const handleRevokeActivation = () => {
        // TODO - clarify if terms of use consent rejection is needed TPAPORTAL-2596
    };

    const revokeActivationDialog = useConfirmationDialog({
        message: t("config.terms.revokeActivationDialog.message"),
        confirmLabel: t("config.terms.revokeActivationDialog.confirmLabel"),
        onConfirm: handleRevokeActivation,
        title: t("config.terms.revokeActivationDialog.title"),
    });

    const handleOpenRevokeTermsOfUseDialog = () => {
        revokeActivationDialog.open();
    };

    return (
        <>
            <AlreadySigned onClickRevokeTermsOfUse={handleOpenRevokeTermsOfUseDialog} style={{ marginTop: 32 }} />
            {!isSettings && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <TermsOfUseSubmitButton onSubmit={onAlreadyAcceptedSubmit} style={{ marginTop: 32 }} />
                </div>
            )}
            {revokeActivationDialog.dialog}
        </>
    );
};

export const setDocumentVersionAndSignees = async (
    companyId: string,
    companyTermsId: string | undefined,
    body: PutTermsOfUseSigneesPayload,
) => {
    try {
        generalStore.isLoading = true;

        await API.putTermsOfUseSignees(companyId, body, { companyTermsId });

        return true;
    } catch (error) {
        const apiError = getApiError(error);
        if (
            apiError?.statusCode === HttpStatusCode.Conflict_409 &&
            apiError.response.type === "TERMS_OF_USE_DUPLICATE"
        ) {
            generalStore.setError(t("error.termsOfUseDuplicateFile"), error);
        } else {
            generalStore.setError(t("error.setDocumentOrSignees"), error);
        }

        return false;
    } finally {
        generalStore.isLoading = false;
    }
};

export const AdvisorConfigTermsOfUseSite = () => {
    useHideSideBar();

    const { companyId } = useParams<{ companyId?: string }>();
    const { company } = useCompany({ companyId });

    const hasNextStep = !!company?.accountingEnabled || !!company?.hrEnabled;

    let nextStepRoute: string | undefined = undefined;
    if (hasNextStep) {
        nextStepRoute = company.accountingEnabled ? AdvisorRoutes.CONFIG.ACCOUNTING : AdvisorRoutes.CONFIG.HR;
    }

    if (!companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    const handleSubmit = async (documentVersion: string, signees: TermsOfUseSignee[]) => {
        const success = await setDocumentVersionAndSignees(companyId, company?.companyTerms?.id, {
            fileName: documentVersion,
            signees: signees.map(s => s.email),
            sendEmail: false, // do not send emails to the signees, will be done when activating the company
        });

        if (!success) {
            return;
        }

        try {
            await companiesStore.reloadCompany();
            pushRoute(withParams(nextStepRoute ?? AdvisorRoutes.CONFIG.OVERVIEW, { companyId }));
        } catch (error) {
            generalStore.setError(t("error.loadCompany"), error);
        }
    };

    const handleSkip = () => {
        pushRoute(withParams(nextStepRoute ?? AdvisorRoutes.CONFIG.OVERVIEW, { companyId }));
    };
    const handleAlreadyAcceptedSubmit = () => {
        pushRoute(withParams(nextStepRoute ?? AdvisorRoutes.CONFIG.OVERVIEW, { companyId }));
    };

    return (
        <>
            <NavBarBack
                title={t("config.terms.termsOfUseTitle")}
                backLabel={t("config.termsOfUse.navbar.back")}
                backTarget={withParams(AdvisorRoutes.CONFIG.COMPANY_DATA, { companyId })}
                cancelTarget={withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId })}
                companyName={company?.name ?? ""}
            />
            {company && (
                <PageWithStepper stepper={<AdvisorConfigCompanyStepper company={company} />}>
                    <AdvisorConfigTermsOfUseContentWithCurrentSignees
                        variant="current"
                        companyId={companyId}
                        companyTermsId={company.companyTerms?.id}
                        termsAccepted={!!company.companyTerms?.acceptedAt}
                        onSubmit={handleSubmit}
                        onSkip={handleSkip}
                        onAlreadyAcceptedSubmit={handleAlreadyAcceptedSubmit}
                    />
                </PageWithStepper>
            )}
        </>
    );
};
