import { Button, Checkbox, Collapse, Divider, FormControlLabel } from "@material-ui/core";
import compact from "lodash/compact";
import fromPairs from "lodash/fromPairs";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, useRouteMatch } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Permission, PermissionGroupEnum, PermissionsRecordTypes, PermissionsRequest } from "../../../network/APITypes";
import { getApiError, getValidationError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { companiesStore } from "../../../stores/CompaniesStore";
import { IPermissionState, IPermissionsState, IUserPermissionsState, configStore } from "../../../stores/ConfigStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { ICompany, Module } from "../../../types/models";
import { debug } from "../../../util/debug";
import {
    getDefaultActionsForPermissionGroup,
    getDefaultPermissionShortForPermissionGroup,
    getFilteredPermissionTree,
    getGlobalPermissionGroups,
    permissionOptionsMap,
    permissionShortsMap,
} from "../../../util/permissionConfig";
import {
    actionsToPermissionShort,
    getApplyRoleButtonText,
    getSuccessDialogMessage,
    getSuccessDialogTitle,
    hasAnyAction,
} from "../../../util/permissionHelpers";
import { getFullName } from "../../../util/user";
import { AdvisorRoutes } from "../../advisor/router/AdvisorRoutes";
import { Routes } from "../../app/router/Routes";
import { pushRoute, withParams, withParamsAndQuery, withQuery } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { useNoPermissionSelectedDialog } from "../../hooks/useNoPermissionSelectedDialog";
import { usePermissions } from "../../hooks/usePermissions";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { SettingsRoutes } from "../../settings/router/SettingsRoutes";
import { CenteredContent } from "../../ui/CenteredContent";
import { ConfigAccordion, ConfigAccordionDetails, ConfigAccordionSummary } from "../../ui/ConfigAccordion";
import { ContextMenu, useContextMenu } from "../../ui/ContextMenu";
import { NavBarBack } from "../../ui/NavBarBack";
import { ResponsiveButtonContainer } from "../../ui/ResponsiveButtonContainer";
import { SiteContent } from "../../ui/SiteContent";
import { User } from "../../ui/User";
import { Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { DIALOG_WIDTH, customColors } from "../../util/Theme";
import { FailedUserDialog, IFailedUser } from "../FailedUserDialog";
import { PermissionChangeInformation } from "../PermissionChangeInformation";

const SET_INITIAL_STATE_FROM_DEFAULTS = "SET_INITIAL_STATE_FROM_DEFAULTS";
const SET_GLOBAL_PERMISSION = "SET_GLOBAL_PERMISSION";
const SET_SUBSIDIARY_PERMISSION = "SET_SUBSIDIARY_PERMISSION";
const SET_RECORD_TYPE_PERMISSION = "SET_RECORD_TYPE_PERMISSION";

interface IDefaultPermissions {
    userId: string;
    permissions: PermissionsRequest;
}

type IAction =
    | {
          type: "SET_INITIAL_STATE_FROM_DEFAULTS";
          payload: IDefaultPermissions[];
      }
    | {
          type: "SET_GLOBAL_PERMISSION";
          payload: {
              userId: string;
              permission: IPermissionState;
          };
      }
    | {
          type: "SET_SUBSIDIARY_PERMISSION";
          payload: {
              userId: string;
              subsidiaryId: string;
              permission: IPermissionState;
          };
      }
    | {
          type: "SET_RECORD_TYPE_PERMISSION";
          payload: {
              userId: string;
              subsidiaryId: string;
              recordTypeId: string;
              permission: IPermissionState;
          };
      };

const actions = {
    setInitialStateFromDefaults(defaultPermissions: IDefaultPermissions[]): IAction {
        return {
            type: SET_INITIAL_STATE_FROM_DEFAULTS,
            payload: defaultPermissions,
        };
    },
    setGlobalPermission(userId: string, permission: IPermissionState): IAction {
        return {
            type: SET_GLOBAL_PERMISSION,
            payload: {
                userId,
                permission,
            },
        };
    },
    setSubsidiaryPermission(userId: string, subsidiaryId: string, permission: IPermissionState): IAction {
        return {
            type: SET_SUBSIDIARY_PERMISSION,
            payload: {
                userId,
                subsidiaryId,
                permission,
            },
        };
    },
    setRecordTypePermission(
        userId: string,
        subsidiaryId: string,
        recordTypeId: string,
        permission: IPermissionState,
    ): IAction {
        return {
            type: SET_RECORD_TYPE_PERMISSION,
            payload: {
                userId,
                subsidiaryId,
                recordTypeId,
                permission,
            },
        };
    },
};

const reducer = (state: IPermissionsState | null, action: IAction) => {
    switch (action.type) {
        case SET_INITIAL_STATE_FROM_DEFAULTS: {
            const defaultPermissions = action.payload;

            const permissionsToState = (permissions: PermissionsRequest) => ({
                ...permissions,
                global: permissions.global?.map(permission => ({
                    ...permission,
                    isSelected: permission.actions.length > 0,
                })),
                subsidiaries: permissions.subsidiaries?.map(subsidiary => ({
                    ...subsidiary,
                    permissions: subsidiary.permissions?.map(permission => ({
                        ...permission,
                        isSelected: permission.actions.length > 0,
                    })),
                    recordTypes: subsidiary.recordTypes?.map(recordType => ({
                        ...recordType,
                        permission: {
                            ...recordType.permission,
                            isSelected: recordType.permission.actions.length > 0,
                        },
                    })),
                })),
            });

            return fromPairs(
                defaultPermissions.map(({ userId, permissions }) => [userId, permissionsToState(permissions)]),
            );
        }
        case SET_GLOBAL_PERMISSION: {
            if (state) {
                const { userId, permission } = action.payload;
                const userPermissions = state[userId];
                const hasPermission = !!userPermissions.global?.find(
                    permissionItem => permissionItem.group === permission.group,
                );

                return {
                    ...state,
                    [userId]: {
                        ...userPermissions,
                        global: hasPermission
                            ? userPermissions.global?.map(permissionItem =>
                                  permissionItem.group === permission.group ? permission : permissionItem,
                              )
                            : userPermissions.global?.concat(permission),
                    },
                };
            }

            return state;
        }
        case SET_SUBSIDIARY_PERMISSION: {
            if (state) {
                const { userId, subsidiaryId, permission } = action.payload;
                const userPermissions = state[userId];
                const subsidiaryItem = userPermissions.subsidiaries?.find(subsidiary => subsidiary.id === subsidiaryId);
                const hasPermission = !!subsidiaryItem?.permissions?.find(
                    permissionItem => permissionItem.group === permission.group,
                );

                return {
                    ...state,
                    [userId]: {
                        ...userPermissions,
                        subsidiaries: userPermissions.subsidiaries?.map(subsidiary =>
                            subsidiary.id === subsidiaryId
                                ? {
                                      ...subsidiary,
                                      ...(!permission.group.endsWith(":records")
                                          ? {
                                                permissions: hasPermission
                                                    ? subsidiary.permissions?.map(permissionItem =>
                                                          permissionItem.group === permission.group
                                                              ? permission
                                                              : permissionItem,
                                                      )
                                                    : subsidiary.permissions?.concat(permission),
                                            }
                                          : {}),
                                      recordTypes: permission.group.endsWith(":records")
                                          ? subsidiary.recordTypes?.map(recordType => {
                                                let recordTypeActions = recordType.permission.actions;
                                                if (!recordTypeActions || recordTypeActions.length === 0) {
                                                    // TPAPORTAL-1464: If no actions selected for record type, but record type itself is selected -> use default actions
                                                    recordTypeActions = getDefaultActionsForPermissionGroup(
                                                        recordType.permission.group,
                                                    );
                                                }

                                                return {
                                                    ...recordType,
                                                    permission: {
                                                        ...permission,
                                                        actions:
                                                            permission.actions.length === 0
                                                                ? recordTypeActions
                                                                : permission.actions, // actions array not empty means drop down change
                                                        isSelected:
                                                            permission.actions.length === 0 // actions array is empty means selection change
                                                                ? permission.isSelected
                                                                : recordType.permission.isSelected,
                                                    },
                                                };
                                            })
                                          : subsidiary.recordTypes,
                                  }
                                : subsidiary,
                        ),
                    },
                };
            }

            return state;
        }
        case SET_RECORD_TYPE_PERMISSION: {
            if (state) {
                const { userId, subsidiaryId, recordTypeId, permission } = action.payload;
                const userPermissions = state[userId];

                return {
                    ...state,
                    [userId]: {
                        ...userPermissions,
                        subsidiaries: userPermissions.subsidiaries?.map(subsidiary =>
                            subsidiary.id === subsidiaryId
                                ? {
                                      ...subsidiary,
                                      recordTypes: subsidiary.recordTypes?.map(recordType =>
                                          recordType.id === recordTypeId
                                              ? {
                                                    ...recordType,
                                                    permission,
                                                }
                                              : recordType,
                                      ),
                                  }
                                : subsidiary,
                        ),
                    },
                };
            }

            return state;
        }
        default:
            return state;
    }
};

const PermissionSection = ({
    title,
    defaultIsOpen,
    showDivider = true,
    style,
    children,
}: {
    title: string;
    defaultIsOpen?: boolean;
    showDivider?: boolean;
    style?: React.CSSProperties;
    children: React.ReactNode;
}) => {
    const [isOpen, setIsOpen] = React.useState(!!defaultIsOpen);

    const handleClickToggleSection = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div
                onClick={handleClickToggleSection}
                role="button"
                style={{
                    display: "flex",
                    alignItems: "center",
                    height: 72,
                    paddingRight: 16,
                    justifyContent: "space-between",
                    cursor: "pointer",
                    ...style,
                }}
            >
                <h3>{title}</h3>
                <div>
                    <Icon
                        name={isOpen ? "chevronUp" : "chevronDown"}
                        style={{ display: "block", color: customColors.greyDarkIcons }}
                    />
                </div>
            </div>
            <Collapse in={isOpen}>
                <div
                    style={{
                        marginBottom: showDivider ? 24 : undefined,
                    }}
                >
                    {children}
                </div>
            </Collapse>
            {showDivider && !isOpen && <Divider />}
        </>
    );
};

const PermissionLine = ({
    permission,
    checked,
    indeterminate,
    label,
    isRecordTypeGroup,
    permissionLabel,
    onChange,
    "data-id": dataId,
    children,
    disabled,
    level,
    style,
    deletedAt,
}: {
    permission: IPermissionState;
    checked?: boolean;
    indeterminate?: boolean;
    label?: string;
    isRecordTypeGroup?: boolean;
    permissionLabel?: string;
    onChange: (permission: IPermissionState) => void;
    "data-id": string;
    children?: React.ReactNode;
    disabled?: boolean;
    level?: number;
    style?: React.CSSProperties;
    deletedAt?: Date;
}) => {
    const contextMenu = useContextMenu<Permission>();
    const indentation = isNumber(level) ? 15 + level * 32 : undefined;
    const permissionOption = permissionOptionsMap[permission.group];

    const defaultPermissionShort = getDefaultPermissionShortForPermissionGroup(permission.group) ?? "canRead";
    const defaultActions = getDefaultActionsForPermissionGroup(permission.group);

    const permissionShort = actionsToPermissionShort(permission.actions) ?? defaultPermissionShort;

    if (!permissionOption) {
        return null;
    }

    const handleSelect = (_: unknown, checked: boolean) => {
        onChange(
            isRecordTypeGroup
                ? {
                      ...permission,
                      isSelected: checked,
                      actions: [],
                  }
                : {
                      ...permission,
                      isSelected: checked,
                      actions: permission.actions.length > 0 ? permission.actions : defaultActions,
                  },
        );
    };

    return (
        <>
            <div
                style={{
                    minHeight: 56,
                    width: "100%",
                    paddingRight: 15,
                    paddingLeft: indentation ?? 15,
                    border: `1px solid ${customColors.greyLight}`,
                    borderBottom: "none",
                    borderTop: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    ...style,
                }}
            >
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                indeterminate={indeterminate}
                                color="primary"
                                onChange={handleSelect}
                                disabled={disabled}
                                data-id={dataId}
                            />
                        }
                        label={
                            <div style={{ marginLeft: 2, color: customColors.body1Dark }}>
                                {label ?? t(permissionOption.label)} {deletedAt && t("config.permissions.deleted")}
                            </div>
                        }
                    />
                </div>
                {permissionOption.options.length > 1 && (
                    <div
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            if (!disabled) {
                                contextMenu.open(event, permission);
                            }
                        }}
                        style={{ display: "flex", alignItems: "center", cursor: disabled ? undefined : "pointer" }}
                    >
                        <div style={{ marginRight: 8 }}>
                            {permissionLabel ?? t(permissionShortsMap[permissionShort].label)}
                        </div>
                        <Icon
                            name={contextMenu.isOpen ? "dropUp" : "dropDown"}
                            style={{ color: disabled ? customColors.greyTextfields : customColors.primaryColor }}
                        />
                    </div>
                )}
            </div>
            <div style={{ paddingLeft: indentation }}>
                <Divider />
            </div>
            {children}
            <ContextMenu
                config={contextMenu}
                data-id="permission_context_menu"
                items={permissionOption.options.map(option => ({
                    "data-id": option,
                    title: t(permissionShortsMap[option].label),
                    onClick: () => {
                        onChange({
                            ...permission,
                            actions: permissionShortsMap[option].actions,
                        });
                        contextMenu.close();
                    },
                }))}
            />
        </>
    );
};

const PermissionLineGroup = ({
    permission,
    permissions,
    onChange,
    "data-id": dataId,
    children,
    disabled,
    level,
}: {
    permission: IPermissionState;
    permissions: IPermissionState[];
    onChange: (permission: IPermissionState) => void;
    "data-id": string;
    children: React.ReactNode;
    disabled?: boolean;
    level?: number;
}) => {
    const allPermissionsSelected = permissions.every(permission => permission.isSelected);
    const somePermissionsSelected = permissions.some(permission => permission.isSelected) && !allPermissionsSelected;

    const uniquePermissionShorts = uniq(
        compact(
            permissions.map(
                permissionItem =>
                    actionsToPermissionShort(permissionItem.actions) ??
                    permissionOptionsMap[permissionItem.group]?.options[0],
            ),
        ),
    );
    const allPermissionsEqual = uniquePermissionShorts.length === 1;

    return (
        <PermissionLine
            permission={permission}
            isRecordTypeGroup
            checked={allPermissionsSelected}
            indeterminate={somePermissionsSelected}
            permissionLabel={
                allPermissionsEqual
                    ? t(permissionShortsMap[uniquePermissionShorts[0]].label)
                    : t("permission.option.mixed")
            }
            onChange={onChange}
            data-id={dataId}
            disabled={disabled}
            level={level}
        >
            {children}
        </PermissionLine>
    );
};

interface IPermissionCardProps {
    scope?: string;
    module?: Module;
    permissions: IPermissionState[];
    recordTypes?: (PermissionsRecordTypes & { permission: IPermissionState })[];
    onChangePermission: (permission: IPermissionState) => void;
    onChangeRecordTypePermission?: (recordTypeId: string) => (permission: IPermissionState) => void;
    disabled?: boolean;
    style?: React.CSSProperties;
}

const PermissionCard = ({
    scope,
    module,
    permissions,
    recordTypes,
    onChangePermission,
    onChangeRecordTypePermission,
    disabled,
    style,
}: IPermissionCardProps) => {
    const [expanded, setExpanded] = React.useState(false);
    const [expandedTimeoutId, setExpandedTimeoutId] = React.useState<number>(0);

    const moduleStore = module ? getModuleStore(module) : null;

    const handleSelectAll = (_: unknown, checked: boolean) => {
        permissions.forEach(permission => {
            onChangePermission({
                ...permission,
                isSelected: checked,
                actions:
                    permission.actions.length > 0
                        ? permission.actions
                        : getDefaultActionsForPermissionGroup(permission.group),
            });
        });

        if (recordTypes && onChangeRecordTypePermission) {
            recordTypes.forEach(recordType => {
                onChangeRecordTypePermission(recordType.id)({
                    ...recordType.permission,
                    isSelected: checked,
                    actions:
                        recordType.permission.actions.length > 0
                            ? recordType.permission.actions
                            : getDefaultActionsForPermissionGroup(recordType.permission.group),
                });
            });
        }
    };

    const handleChangePermission = (selectedPermission: IPermissionState) => {
        permissions.forEach(permission => {
            const dependencies = permissionOptionsMap[permission.group]?.dependsOn ?? [];

            dependencies.forEach(dependency => {
                if (dependency === selectedPermission.group && !selectedPermission.isSelected) {
                    onChangePermission({ ...permission, isSelected: false });
                }
            });
        });
        onChangePermission(selectedPermission);
    };

    const permissionsWithoutRecordsGroup = permissions.filter(permission => !permission.group.endsWith(":records"));
    const allRecordTypesSelected = (recordTypes ?? []).every(recordType => recordType.permission.isSelected);
    const someRecordTypesSelected = (recordTypes ?? []).some(recordType => recordType.permission.isSelected);

    const allPermissionsSelected =
        permissionsWithoutRecordsGroup.every(permission => permission.isSelected) && allRecordTypesSelected;

    const somePermissionsSelected =
        (permissionsWithoutRecordsGroup.some(permission => permission.isSelected) || someRecordTypesSelected) &&
        !allPermissionsSelected;

    const isDependsOnSelected = (dependsOn: PermissionGroupEnum[] | undefined): boolean => {
        if (!dependsOn) {
            return true;
        }

        return dependsOn.every(dependency => {
            const permission = permissions.find(permission => permission.group === dependency);
            return permission ? permission.isSelected : false;
        });
    };

    return (
        <ConfigAccordion
            style={style}
            onChange={(event, expanded) => {
                clearTimeout(expandedTimeoutId);
                if (expanded) {
                    setExpanded(true);
                } else {
                    // Keep rendering during close animation
                    const timeoutId = setTimeout(() => {
                        setExpanded(false);
                    }, 1000);
                    setExpandedTimeoutId(timeoutId);
                }
            }}
        >
            <ConfigAccordionSummary expandIcon={<Icon name="chevronDown" />}>
                <FormControlLabel
                    onClick={event => {
                        event.stopPropagation();
                    }}
                    onFocus={event => {
                        event.stopPropagation();
                    }}
                    control={
                        <Checkbox
                            color="primary"
                            indeterminate={somePermissionsSelected}
                            checked={allPermissionsSelected}
                            onChange={handleSelectAll}
                            style={
                                somePermissionsSelected && !disabled ? { color: customColors.primaryColor } : undefined
                            }
                            disabled={disabled}
                            data-id="select-all-checkbox"
                        />
                    }
                    label={
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: 2,
                                color: customColors.body1Dark,
                            }}
                        >
                            <Icon
                                name={scope === t("config.permissions.global") ? "globe" : "subsidiary"}
                                style={{ display: "block" }}
                            />
                            <div style={{ marginLeft: 16 }}>{scope}</div>
                        </div>
                    }
                />
            </ConfigAccordionSummary>
            <ConfigAccordionDetails style={{ flexDirection: "column" }}>
                {expanded &&
                    permissions.map((permission, index) =>
                        permission.group.endsWith(":records") && onChangeRecordTypePermission ? (
                            <PermissionLineGroup
                                key={permission.group}
                                permission={permission}
                                permissions={recordTypes?.map(recordType => recordType.permission) ?? []}
                                onChange={onChangePermission}
                                data-id="select-all-checkbox-group"
                                disabled={disabled}
                                level={0}
                            >
                                {recordTypes?.map((recordType, index) => (
                                    <PermissionLine
                                        key={recordType.id}
                                        permission={recordType.permission}
                                        label={moduleStore?.getRecordTypeName(recordType) ?? recordType.name}
                                        data-id={`permission_checkbox_${index}`}
                                        checked={recordType.permission.isSelected}
                                        onChange={onChangeRecordTypePermission(recordType.id)}
                                        disabled={disabled}
                                        deletedAt={recordType.deletedAt}
                                        level={1}
                                    />
                                ))}
                            </PermissionLineGroup>
                        ) : (
                            <PermissionLine
                                key={permission.group}
                                permission={permission}
                                data-id={`permission_checkbox_${index}`}
                                checked={permission.isSelected}
                                onChange={handleChangePermission}
                                disabled={
                                    !!disabled ||
                                    !isDependsOnSelected(permissionOptionsMap[permission.group]?.dependsOn)
                                }
                            />
                        ),
                    )}
            </ConfigAccordionDetails>
        </ConfigAccordion>
    );
};

// Removes isSelected flag which is only used for UI and is not sent to backend
const sanitizePermissions = ({
    company,
    permissions,
    filterNotConfigurablePermissions = false,
}: {
    company: ICompany;
    permissions: IUserPermissionsState;

    // TPAPORTAL-2077: Only set this to true when checking if any FE permissions are selected
    // Reason: BE sets additional permissions which otherwise would get removed in persistPermissions()
    // when navigating between pages. This would then trigger the role checker to change the detected role to "custom"
    filterNotConfigurablePermissions?: boolean;
}): PermissionsRequest => {
    const globalPermissionGroups = getGlobalPermissionGroups(company);

    return {
        ...permissions,
        global: permissions.global
            ?.filter(
                permission =>
                    permission.isSelected &&
                    // omit global permissions that are not configurable in the UI
                    (!filterNotConfigurablePermissions || globalPermissionGroups.includes(permission.group)),
            )
            .map(permission => omit(permission, "isSelected")),
        subsidiaries: permissions.subsidiaries?.map(subsidiary => ({
            ...subsidiary,
            permissions: subsidiary.permissions
                ?.filter(permission => permission.isSelected)
                .map(permission => omit(permission, "isSelected")),
            recordTypes: subsidiary.recordTypes?.map(recordType => ({
                ...recordType,
                permission: {
                    ...omit(recordType.permission, "isSelected"),
                    actions: recordType.permission.isSelected ? recordType.permission.actions : [],
                },
            })),
        })),
    };
};

// function for permissions, that should not make it into the role deduction
// use at equality check
// CURRENTLY NOT USABLE since we only send the role to the backend when the permissions are equal
// const dropRoleAgnosticPermissions = (permissions?: PermissionsRequest) =>
//     permissions
//         ? {
//               ...permissions,
//               global: permissions.global?.filter(permission => permission.group !== "projects"),
//           }
//         : permissions;

export const UserPermissionsSite = observer(function UserPermissionsSite() {
    useHideSideBar();

    const advisorConfigUserPermissionsMatch = useRouteMatch<{ companyId: string }>(
        AdvisorRoutes.CONFIG.USER_PERMISSIONS,
    );
    const companiesUsersReleasePermissionsMatch = useRouteMatch<{ companyId: string }>(
        Routes.COMPANIES_USERS_RELEASE_PERMISSIONS,
    );
    let companyId =
        advisorConfigUserPermissionsMatch?.params.companyId ?? companiesUsersReleasePermissionsMatch?.params.companyId;
    if (!companyId && companiesStore.selectedCompanyId) {
        companyId = companiesStore.selectedCompanyId;
    }
    const company = useCompany({ companyId, preloaded: companiesStore.selectedCompany }).company;
    const userPermissions = usePermissions(company?.id).parsed;

    const { employeeId } = useQueryParams<{ employeeId: string }>();
    const [permissions, dispatch] = React.useReducer(reducer, configStore.permissionsState);
    const isMobile = React.useContext(MobileContext);
    const [failedUsers, setFailedUsers] = React.useState<IFailedUser[]>([]);
    const noPermissionSelectedDialog = useNoPermissionSelectedDialog();

    const handleMoveToNextSite = () => {
        configStore.wipe();
        if (advisorConfigUserPermissionsMatch && companyId) {
            pushRoute(withParams(AdvisorRoutes.CONFIG.USERS, { companyId }));
        } else if (companiesUsersReleasePermissionsMatch && companyId) {
            pushRoute(Routes.COMPANIES_USERS_RELEASE);
        } else {
            pushRoute(SettingsRoutes.USER_MANAGEMENT.ROOT);
        }
    };

    const selectedAreUsers = configStore.selectedEmployees.every(employee => employee.type === "user");
    const isReadOnlyView = configStore.selectedEmployees.some(employee => employee.needsRelease);

    const successDialog = useSuccessDialog({
        title: getSuccessDialogTitle(!!userPermissions?.isSuperAdmin, selectedAreUsers),
        message: getSuccessDialogMessage(
            !!userPermissions?.isSuperAdmin,
            selectedAreUsers,
            configStore.selectedEmployees,
        ),
        onClose: handleMoveToNextSite,
    });

    React.useEffect(() => {
        const loadRolePermissions = () => {
            if (!company) {
                return;
            }

            if (!configStore.permissionsState && configStore.permissions) {
                dispatch(
                    actions.setInitialStateFromDefaults(
                        configStore.selectedEmployees.map(employee => ({
                            userId: employee.id,
                            permissions: configStore.permissions?.[employee.id] ?? { roles: ["custom"] },
                        })),
                    ),
                );
            }
        };

        loadRolePermissions();
    }, [company, selectedAreUsers]);

    if (advisorConfigUserPermissionsMatch && !companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }
    if (companiesUsersReleasePermissionsMatch && !companyId) {
        // No company in route -> get out
        return <Redirect to={Routes.COMPANIES_USERS_RELEASE} />;
    }

    if (configStore.selectedEmployees.length === 0 || typeof employeeId !== "string") {
        if (advisorConfigUserPermissionsMatch && companyId) {
            pushRoute(withParams(AdvisorRoutes.CONFIG.USERS, { companyId }));
        } else if (companiesUsersReleasePermissionsMatch && companyId) {
            pushRoute(Routes.COMPANIES_USERS_RELEASE);
        } else {
            pushRoute(SettingsRoutes.USER_MANAGEMENT.ROOT);
        }

        return null;
    }

    if (!company || !userPermissions) {
        // Company/Permissions not loaded yet -> wait
        return null;
    }

    if (!permissions) {
        return null;
    }

    const filteredPermissionTree = getFilteredPermissionTree(company);

    const selectedEmployeesCount = configStore.selectedEmployees.length;
    const currentEmployeeIndex = configStore.getCurrentEmployeeIndex(employeeId);
    const hasMoreEmployees = selectedEmployeesCount > 1 && currentEmployeeIndex < selectedEmployeesCount - 1;
    const selectedEmployee = configStore.selectedEmployees[currentEmployeeIndex];
    const employeeName = getFullName(selectedEmployee);

    const previousEmployee =
        currentEmployeeIndex > 0 && selectedEmployeesCount > 1
            ? configStore.selectedEmployees[currentEmployeeIndex - 1]
            : null;
    const previousEmployeeName = getFullName(previousEmployee);

    const nextEmployee =
        currentEmployeeIndex < selectedEmployeesCount - 1
            ? configStore.selectedEmployees[currentEmployeeIndex + 1]
            : null;

    const handleChangeGlobalPermission = (permission: IPermissionState) => {
        dispatch(actions.setGlobalPermission(selectedEmployee.id, permission));
    };

    const handleChangeSubsidiaryPermission = (subsidiaryId: string) => (permission: IPermissionState) => {
        dispatch(actions.setSubsidiaryPermission(selectedEmployee.id, subsidiaryId, permission));
    };

    const handleChangeRecordTypePermission =
        (subsidiaryId: string) => (recordTypeId: string) => (permission: IPermissionState) => {
            dispatch(actions.setRecordTypePermission(selectedEmployee.id, subsidiaryId, recordTypeId, permission));
        };

    const handleClickApply = async () => {
        if (!selectedAreUsers) {
            // When creating a new user -> at least one permission must be selected
            const userPermissions = permissions[selectedEmployee.id];
            const sanitizedPermissions = sanitizePermissions({
                company,
                permissions: userPermissions,
                // Have to filter BE only role permissions, otherwise there would still be actions left
                filterNotConfigurablePermissions: true,
            });

            // No permission selected -> Display error dialog
            if (!hasAnyAction(sanitizedPermissions)) {
                noPermissionSelectedDialog.open();
                return;
            }
        }

        if (hasMoreEmployees && nextEmployee) {
            if (advisorConfigUserPermissionsMatch && companyId) {
                pushRoute(
                    withParamsAndQuery(
                        AdvisorRoutes.CONFIG.USER_PERMISSIONS,
                        { companyId },
                        { employeeId: nextEmployee.id },
                    ),
                );
            } else if (companiesUsersReleasePermissionsMatch && companyId) {
                pushRoute(
                    withParamsAndQuery(
                        Routes.COMPANIES_USERS_RELEASE_PERMISSIONS,
                        { companyId },
                        { employeeId: nextEmployee.id },
                    ),
                );
            } else {
                pushRoute(withQuery(SettingsRoutes.USER_MANAGEMENT.USER_PERMISSIONS, { employeeId: nextEmployee.id }));
            }
        } else {
            const failedUserItems: IFailedUser[] = [];
            const employees =
                failedUsers.length > 0
                    ? failedUsers.map(failedEmployee => failedEmployee.employee)
                    : configStore.selectedEmployees;

            try {
                generalStore.isLoading = true;

                for (let i = 0; i < employees.length; i++) {
                    const employee = employees[i];
                    const putUser = employee.type === "user";

                    try {
                        const userPermissions = permissions[employee.id];
                        const userDefaultPermissions = configStore.defaultPermissions?.[employee.id];
                        const sanitizedPermissions = sanitizePermissions({ company, permissions: userPermissions });
                        const permissionsEqualRole = isEqual(sanitizedPermissions, userDefaultPermissions);

                        const permissionsRequest =
                            permissionsEqualRole &&
                            userDefaultPermissions &&
                            userDefaultPermissions.roles[0] !== "custom"
                                ? {
                                      roles: userDefaultPermissions.roles,
                                  }
                                : sanitizedPermissions;

                        if (putUser) {
                            await API.putUserPermissions(company.id, employee.id, permissionsRequest);
                        } else {
                            await API.postUser(company.id, {
                                email: employee.email,
                                personId: employee.id,
                                permissions: permissionsRequest,
                            });
                        }
                    } catch (error) {
                        const apiError = getApiError(error);
                        debug.error(putUser ? "### Error applying permissions" : "### Error creating user", error);
                        failedUserItems.push({
                            employee,
                            statusCode: apiError?.statusCode ?? HttpStatusCode.InternalServerError_500,
                            response: getValidationError(apiError),
                        });
                    }
                }
            } finally {
                generalStore.isLoading = false;
            }

            if (failedUserItems.length > 0) {
                generalStore.setError(t("error.general"));
                setFailedUsers(failedUserItems);
            } else {
                successDialog.openDialog();
            }
        }
    };

    const persistPermissions = () => {
        configStore.permissionsState = permissions;
        configStore.permissions = mapValues(permissions, userPermissions => {
            const sanitizedPermissions = sanitizePermissions({ company, permissions: userPermissions });

            return sanitizedPermissions;
        });
    };

    const getNavigationProps = (): Partial<React.ComponentProps<typeof NavBarBack>> | undefined => {
        if (advisorConfigUserPermissionsMatch) {
            if (companyId) {
                if (previousEmployee) {
                    return {
                        backLabel: t(
                            selectedAreUsers
                                ? "config.permissions.existingUser.navbar.backToUser"
                                : "config.permissions.navbar.backToUser",
                            {
                                employeeName: previousEmployeeName,
                            },
                        ),
                        backTarget: withParamsAndQuery(
                            AdvisorRoutes.CONFIG.USER_PERMISSIONS,
                            { companyId },
                            { employeeId: previousEmployee.id },
                        ),
                        companyName: company.name,
                    };
                } else {
                    return {
                        backLabel: selectedAreUsers
                            ? t("config.permissions.existingUser.navbar.back")
                            : t("config.permissions.navbar.back"),
                        onBack: () => {
                            persistPermissions();
                            if (companyId) {
                                pushRoute(AdvisorRoutes.CONFIG.USER_ROLES, { params: { companyId } });
                            }
                        },
                    };
                }
            }
        } else if (companiesUsersReleasePermissionsMatch) {
            if (companyId) {
                // this screen only supports one employee
                return {
                    backLabel: t("companiesUserRelease.permissions.navbar.back"),
                    onBack: () => {
                        persistPermissions();
                        if (companyId) {
                            pushRoute(Routes.COMPANIES_USERS_RELEASE_ROLES, { params: { companyId } });
                        }
                    },
                    companyName: company.name,
                };
            }
        } else {
            if (previousEmployee) {
                return {
                    backLabel: t(
                        selectedAreUsers
                            ? "settings.userManagement.permissions.existingUser.navbar.backToUser"
                            : "settings.userManagement.permissions.navbar.backToUser",
                        {
                            employeeName: previousEmployeeName,
                        },
                    ),
                    backTarget: withQuery(SettingsRoutes.USER_MANAGEMENT.USER_PERMISSIONS, {
                        employeeId: previousEmployee.id,
                    }),
                };
            } else {
                return {
                    backLabel: selectedAreUsers
                        ? t("settings.userManagement.permissions.existingUser.navbar.back")
                        : t("settings.userManagement.permissions.navbar.back"),
                    onBack: () => {
                        persistPermissions();
                        pushRoute(SettingsRoutes.USER_MANAGEMENT.USER_ROLES);
                    },
                };
            }
        }
    };

    return (
        <>
            <NavBarBack title={employeeName} {...getNavigationProps()} />
            <CenteredContent>
                <SiteContent>
                    <div
                        style={{
                            display: "flex",
                            flexGrow: 1,
                            justifyContent: "center",
                            paddingTop: isMobile ? 16 : 32,
                        }}
                    >
                        <div
                            style={{
                                maxWidth: isMobile ? undefined : DIALOG_WIDTH,
                                marginRight: 16,
                            }}
                        >
                            {selectedEmployeesCount > 1 && (
                                <h4 style={{ color: customColors.body2Dark, marginBottom: 24 }}>
                                    {currentEmployeeIndex + 1}/{selectedEmployeesCount}{" "}
                                    {t("config.permissions.employees")}
                                </h4>
                            )}
                            <h1>{t("config.permissions.title")}</h1>
                            <p style={{ marginTop: 16, fontSize: 12 }}>{t("config.permissions.description")}</p>
                            {selectedEmployeesCount > 1 && (
                                <div style={{ display: "flex", alignItems: "center", marginTop: 24, marginBottom: 16 }}>
                                    <User
                                        firstName={selectedEmployee.firstName}
                                        lastName={selectedEmployee.lastName}
                                        imageUrl={selectedEmployee.profile_picture_url}
                                        showAvatarOnly
                                    />
                                    <h4 style={{ color: customColors.body2Dark, marginLeft: 16 }}>{employeeName}</h4>
                                </div>
                            )}
                            {isReadOnlyView && (
                                <PermissionChangeInformation
                                    configUser={selectedEmployee}
                                    companyId={company.id}
                                    permissions={userPermissions}
                                    style={{
                                        margin: "24px 0",
                                    }}
                                />
                            )}
                            {filteredPermissionTree.map((section, index) => (
                                <PermissionSection
                                    key={section.title}
                                    title={t(section.title)}
                                    defaultIsOpen={index === 0}
                                    showDivider
                                >
                                    {section.groups.map(group => {
                                        if (group.type === "global") {
                                            // TPAPORTAL-2490: User with "international-projects": Permission is bound to role
                                            // and therefore can't be disabled
                                            const isProjectsGroup = group.order.includes("projects");
                                            const userHasInternationalProjects =
                                                selectedEmployee.roles?.includes("international-projects");
                                            const disableProject = isProjectsGroup && userHasInternationalProjects;

                                            return (
                                                <React.Fragment key={`${index}_${group.type}`}>
                                                    <PermissionCard
                                                        scope={t("config.permissions.global")}
                                                        permissions={group.order.map(group => {
                                                            const defaultPermission = {
                                                                isSelected: false,
                                                                group,
                                                                actions: [],
                                                            };

                                                            return (
                                                                permissions[selectedEmployee.id].global?.find(
                                                                    permission => permission.group === group,
                                                                ) ?? defaultPermission
                                                            );
                                                        })}
                                                        onChangePermission={handleChangeGlobalPermission}
                                                        disabled={isReadOnlyView || disableProject}
                                                    />
                                                    {disableProject && (
                                                        <p
                                                            style={{
                                                                marginTop: 16,
                                                                paddingLeft: 8,
                                                                color: customColors.disabled,
                                                            }}
                                                        >
                                                            {t("config.permissions.internationalProjects.info")}
                                                        </p>
                                                    )}
                                                </React.Fragment>
                                            );
                                        } else if (group.type === "subsidiary") {
                                            return permissions[selectedEmployee.id].subsidiaries
                                                ?.filter(subsidiary => subsidiary.module === section.module)
                                                .map(subsidiary => (
                                                    <PermissionCard
                                                        key={`${index}_${group.type}_${subsidiary.id}`}
                                                        scope={subsidiary.name}
                                                        module={subsidiary.module}
                                                        recordTypes={subsidiary.recordTypes}
                                                        permissions={group.order.map(group => {
                                                            const defaultPermission = {
                                                                isSelected: false,
                                                                group,
                                                                actions: [],
                                                            };

                                                            return (
                                                                subsidiary.permissions?.find(
                                                                    permission => permission.group === group,
                                                                ) ?? defaultPermission
                                                            );
                                                        })}
                                                        onChangePermission={handleChangeSubsidiaryPermission(
                                                            subsidiary.id,
                                                        )}
                                                        onChangeRecordTypePermission={handleChangeRecordTypePermission(
                                                            subsidiary.id,
                                                        )}
                                                        disabled={isReadOnlyView}
                                                        style={{ marginTop: 24 }}
                                                    />
                                                ));
                                        }

                                        return null;
                                    })}
                                </PermissionSection>
                            ))}
                            {!isReadOnlyView && (
                                <ResponsiveButtonContainer style={{ marginTop: 40 }}>
                                    <Button
                                        data-id="button_apply"
                                        color="primary"
                                        variant="contained"
                                        onClick={handleClickApply}
                                    >
                                        {getApplyRoleButtonText(
                                            !!advisorConfigUserPermissionsMatch,
                                            userPermissions.isSuperAdmin,
                                            selectedAreUsers,
                                        )}
                                    </Button>
                                </ResponsiveButtonContainer>
                            )}
                        </div>
                    </div>
                </SiteContent>
            </CenteredContent>
            <FailedUserDialog
                failedUsers={failedUsers}
                totalUsers={configStore.selectedEmployees.length}
                open={failedUsers.length > 0}
                onRetry={handleClickApply}
                onSubmit={handleMoveToNextSite}
            />
            {noPermissionSelectedDialog.component}
            {successDialog.dialog}
        </>
    );
});
