import * as React from "react";
import { CenteredContent } from "../../ui/CenteredContent";
import { generalStore } from "../../../stores/GeneralStore";
import { AdvisorUploadsNavBar } from "../AdvisorUploadsNavBar";

export const AdvisorUploadsDocumentsSite = () => {
    return (
        <>
            <AdvisorUploadsNavBar />
            <CenteredContent>
                <p>AdvisorUploadsDocumentsSite</p>
            </CenteredContent>
        </>
    );
};
