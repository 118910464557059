import { Button, Checkbox, FormControlLabel, IconButton, Link, RadioGroup, Tooltip } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import compact from "lodash/compact";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import * as Yup from "yup";
import { MAX_CHAT_CHARACTERS } from "../../config";
import { GLOBAL_FEATURES } from "../../features";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { ProjectItemSignaturePosition, ProjectItemSignatures, ProjectUser } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { hasFileExtension } from "../../util/files";
import { formatISODateOnly } from "../../util/helpers";
import { ISignatureDocument, usePdfSignaturePositionDraftDialog } from "../hooks/usePdfSignaturePositionDraftDialog";
import { CustomDatePicker } from "../ui/CustomDatePicker";
import { CustomDialog } from "../ui/CustomDialog";
import { CustomInputField } from "../ui/CustomInputField";
import { TpaRadio, TpaWhiteButton } from "../ui/Primitives";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";
import { FileIcon } from "../util/FileIcon";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { ResponsibleUserField } from "./ResponsibleUserField";

interface RequestReleaseProjectItemValues {
    dueDate: Date | null;
    comment?: string;
}

export interface ProjectItemValues {
    id: string;
    name: string;
}

interface IProps {
    open: boolean;
    companyId: string;
    projectId: string;
    projectItems: ProjectItemValues[];
    onClose: () => void;
    onSubmit: (
        responsibleUsers: ProjectUser[],
        dueDate: string,
        requiresQes?: boolean,
        collectiveSignature?: boolean,
        comment?: string,
        qesPositioning?: ProjectItemSignatures[],
    ) => void | Promise<void>;
}

const minReleaseDate = moment().startOf("day").toDate();

const AddResponsibleInputFieldButton = (props: {
    style?: React.CSSProperties;
    title: string;
    disabled?: boolean;
    onClick: () => void;
}) => {
    const color = props.disabled === true ? customColors.greyButton : customColors.primaryColor;
    return (
        <div
            style={{
                ...props.style,
                color,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                width: "fit-content",
            }}
            role="button"
            onClick={props.disabled ? undefined : props.onClick}
        >
            <Icon name="add" />
            <p style={{ color, marginLeft: 8 }}>{props.title}</p>
        </div>
    );
};

export const ISignatureDocumentToProjectItemSignatures = (document: ISignatureDocument): ProjectItemSignatures => {
    return {
        positions: document.signatures.map<ProjectItemSignaturePosition>(signature => ({
            position: {
                x: signature.signaturePosition.x,
                y: signature.signaturePosition.y,
                page: signature.signaturePosition.page,
            },
            responsibleID: signature.user?.id,
        })),
        projectItemID: document.documentId,
    };
};

export const RequestReleaseProjectItemDialog = observer(function RequestReleaseProjectItemDialog({
    open,
    projectId,
    companyId,
    projectItems,
    onClose,
    onSubmit,
}: IProps) {
    const [selectedUsers, setSelectedUsers] = React.useState<(ProjectUser | null)[]>([null]);
    const [users, setUsers] = React.useState<ProjectUser[]>([]);
    const [initialResponsibleUserCount, setInitialResponsibleUserCount] = React.useState(0);
    const [isUserTouched, setIsUserTouched] = React.useState(false);
    const [requiresQes, setRequiresQes] = React.useState(false);
    const [collectiveRelease, setCollectiveRelease] = React.useState(false);
    const [formValues, setFormValues] = React.useState<RequestReleaseProjectItemValues>();

    const handleSubmitQes = async (documents: ISignatureDocument[]) => {
        if (!formValues?.dueDate) {
            return;
        }

        const qesPositioning = documents.map(document => ISignatureDocumentToProjectItemSignatures(document));
        await onSubmit(
            compact(selectedUsers),
            formatISODateOnly(formValues.dueDate),
            requiresQes,
            collectiveRelease,
            formValues.comment,
            qesPositioning,
        );
        onClose();
    };

    const pdfSignatureDialog = usePdfSignaturePositionDraftDialog({
        projectItems,
        companyId,
        projectId,
        collectiveRelease,
        onSubmit: handleSubmitQes,
    });

    const isEveryItemPdf = projectItems.every(item => hasFileExtension(item.name, "pdf"));

    React.useEffect(() => {
        const loadUsers = async () => {
            try {
                const projectItemIds = projectItems.map(item => item.id);

                const response = await API.getProjectItemDraftPermittedCustomers(companyId, projectId, projectItemIds);
                setUsers(response.users ?? []);
                setInitialResponsibleUserCount(response.users?.length ?? 0);
            } catch (error) {
                generalStore.setError(t("error.general"), error);
            }
        };

        loadUsers();
    }, [companyId, projectId, projectItems]);

    const handleAddSelectedUser = (user: ProjectUser | null, index: number) => {
        const prevSelectedUsers = [...selectedUsers];

        prevSelectedUsers[index] = user;
        setSelectedUsers(prevSelectedUsers);
    };

    const handleDeleteUser = (user: ProjectUser | null, index: number) => {
        const prevSelectedUsers = [...selectedUsers];
        prevSelectedUsers.splice(index, 1);
        setSelectedUsers(prevSelectedUsers);
    };

    const handleBlurUserSelect = () => {
        setIsUserTouched(true);
    };

    const handleCheckRequiresQes = () => {
        setRequiresQes(!requiresQes);
    };

    const handleSubmit = async (values: RequestReleaseProjectItemValues) => {
        const isEveryRowSet = selectedUsers.every(selectedUser => selectedUser !== null);

        if (values.dueDate && selectedUsers.length > 0 && isEveryRowSet) {
            if (requiresQes) {
                setFormValues(values);
                pdfSignatureDialog.open(compact(selectedUsers));
            } else {
                // No QES -> submit
                await onSubmit(
                    compact(selectedUsers),
                    formatISODateOnly(values.dueDate),
                    requiresQes,
                    collectiveRelease,
                    values.comment,
                );
            }
        }
    };

    return (
        <>
            <CustomDialog open={open} onClose={onClose}>
                <div style={{ padding: "42px 42px 0 42px" }}>
                    <h1>{t("requestReleaseProjectItemDialog.title.create")}</h1>
                    <p style={{ marginTop: 24, wordBreak: "break-word" }}>
                        {t("requestReleaseProjectItemDialog.text")}
                    </p>
                    <Formik
                        initialValues={{
                            dueDate: null,
                            comment: undefined,
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={Yup.object().shape({
                            dueDate: Yup.date()
                                .typeError(t("common.dateValidation"))
                                .min(minReleaseDate, t("error.dueDate.minDate"))
                                .required(t("error.requiredField")),
                            comment: Yup.string().max(
                                MAX_CHAT_CHARACTERS,
                                t("error.tooManyCharacters", { maxCharacters: MAX_CHAT_CHARACTERS }),
                            ),
                        })}
                        validateOnBlur
                        validateOnChange
                    >
                        {({ isSubmitting }) => (
                            <>
                                {projectItems.map((projectItem, index) => (
                                    <div
                                        key={projectItem.id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: index === 0 ? 16 : 0,
                                            marginBottom: 16,
                                        }}
                                    >
                                        <FileIcon name={projectItem.name} style={{ marginRight: 16 }} />
                                        <span className="body2">{projectItem.name}</span>
                                    </div>
                                ))}
                                <Form>
                                    {selectedUsers.map((selectedUser, index) => {
                                        return (
                                            <ResponsibleUserField
                                                key={selectedUser?.id ?? index}
                                                users={users.filter(
                                                    u => !compact(selectedUsers).find(s => u.id === s.id),
                                                )}
                                                selectedUser={selectedUser}
                                                userRowCount={selectedUsers.length}
                                                isTouched={isUserTouched}
                                                onAddUser={user => {
                                                    handleAddSelectedUser(user, index);
                                                }}
                                                onDeleteUser={user => {
                                                    handleDeleteUser(user, index);
                                                }}
                                                style={{ marginTop: 8 }}
                                            />
                                        );
                                    })}
                                    <AddResponsibleInputFieldButton
                                        title={t("requestReleaseProjectItemDialog.title.addResponsibleUser")}
                                        onClick={() => {
                                            setSelectedUsers(prevSelectedUsers => [...prevSelectedUsers, null]);
                                        }}
                                        disabled={
                                            selectedUsers.length === initialResponsibleUserCount ||
                                            selectedUsers[selectedUsers.length - 1] === null
                                        }
                                    />
                                    <Field
                                        component={CustomDatePicker}
                                        disablePast
                                        name="dueDate"
                                        label={t("requestReleaseProjectItemDialog.label.dueDate")}
                                        data-id="release_project_item_dialog_dueDate"
                                        required
                                        style={{ marginTop: 16 }}
                                    />
                                    <Field
                                        component={CustomInputField}
                                        name="comment"
                                        label={t("requestReleaseProjectItemDialog.label.comment")}
                                        data-id="release_project_item_dialog_comment"
                                        required
                                        multiline
                                        minRows={5}
                                        style={{ marginTop: 16 }}
                                    />
                                    {GLOBAL_FEATURES.qes && (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={handleCheckRequiresQes}
                                                        checked={requiresQes}
                                                        disabled={!isEveryItemPdf}
                                                    />
                                                }
                                                label={t("requestReleaseProjectItemDialog.label.requiresQes")}
                                            />
                                            <Tooltip
                                                title={
                                                    <Link
                                                        href="https://tpagroupat.sharepoint.com/sites/intranet-work/sitepages/QES.aspx"
                                                        target="_blank"
                                                        style={{
                                                            fontSize: 12,
                                                            textDecorationLine: "underline",
                                                        }}
                                                    >
                                                        {t("requestReleaseProjectItemDialog.info")}
                                                    </Link>
                                                }
                                                interactive
                                                placement="top"
                                            >
                                                <IconButton>
                                                    <Icon name="info"></Icon>
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                    {selectedUsers.length > 1 && (
                                        <RadioGroup
                                            name="transferType"
                                            value={collectiveRelease ? "collective" : "single"}
                                            onChange={event => {
                                                setCollectiveRelease(event.target.value === "collective");
                                            }}
                                            style={{ marginTop: 16 }}
                                        >
                                            <TpaRadio
                                                value="single"
                                                title={t("requestReleaseProjectItemDialog.qes.single.title")}
                                                subtitle={t("requestReleaseProjectItemDialog.qes.single.subtitle")}
                                            />
                                            <TpaRadio
                                                value="collective"
                                                title={t("requestReleaseProjectItemDialog.qes.collective.title")}
                                                subtitle={t("requestReleaseProjectItemDialog.qes.collective.subtitle")}
                                                style={{ marginTop: 8 }}
                                            />
                                        </RadioGroup>
                                    )}
                                    <ResponsiveButtonContainer style={{ paddingBottom: 42, marginTop: 16 }}>
                                        <TpaWhiteButton onClick={onClose} data-id="button_cancel">
                                            {t("common.cancel")}
                                        </TpaWhiteButton>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            onClick={handleBlurUserSelect}
                                            disabled={isSubmitting}
                                            data-id="button_rename"
                                        >
                                            {t("requestReleaseProjectItemDialog.button.text.request")}
                                        </Button>
                                    </ResponsiveButtonContainer>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </CustomDialog>
            {pdfSignatureDialog.component}
        </>
    );
});
