import { Button, Divider } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import flatMap from "lodash/flatMap";
import omit from "lodash/omit";
import * as React from "react";
import { t } from "../../../i18n/util";
import { formatMonth } from "../../../util/helpers";
import { Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";
import { CustomDialog, CustomDialogContent } from "../CustomDialog";
import { BackButton, NavBarTitle } from "../NavBarContainer";
import { ResponsiveButtonContainer } from "../ResponsiveButtonContainer";
import { DateRangeFilter, NumberRangeFilter, formatDateRange, formatNumberRange } from "./TableFilter";
import { callOnChange, canSubmit, getRequiredText } from "./filterHelper";
import {
    BOOLEAN_FILTER_TYPE,
    DATE_FILTER_TYPE,
    DATE_RANGE_FILTER_TYPE,
    DROPDOWN_FILTER_TYPE,
    DateRange,
    IFilterItem,
    ITableFilterCategory,
    ITableFilterOption,
    ITableFilters,
    NUMBER_RANGE_FILTER_TYPE,
    NumberRange,
    TableFilterValue,
} from "./types";

interface IProps {
    open: boolean;
    onClose: () => void;
    filters: ITableFilterCategory[];
    activeFilters: ITableFilters;
    onSubmit: (filters: ITableFilters) => void;
}

export const FilterItem = ({
    name,
    label,
    value,
    type,
    disabled,
    required,
    requiredText,
    options,
    onChange,
    onClick,
}: {
    name: string;
    label: React.ReactNode;
    value: TableFilterValue;
    type?: IFilterItem["type"];
    disabled?: boolean;
    required?: IFilterItem["required"];
    requiredText?: IFilterItem["requiredText"];
    options?: ITableFilterOption[];
    onChange: (name: string, value: TableFilterValue) => void;
    onClick?: () => void;
}) => {
    const [showDatePicker, setShowDatePicker] = React.useState(false);

    const handlers: Record<NonNullable<IFilterItem["type"]>, () => void> = {
        [BOOLEAN_FILTER_TYPE]: () => {
            onChange(name, !value);
        },
        [DATE_FILTER_TYPE]: () => {
            if (value) {
                onChange(name, null);
            } else {
                setShowDatePicker(!showDatePicker);
            }
        },
        [DATE_RANGE_FILTER_TYPE]: () => {
            if (onClick) {
                onClick();
            }
        },
        [DROPDOWN_FILTER_TYPE]: () => {
            if (value) {
                onChange(name, null);
            } else if (onClick) {
                onClick();
            }
        },
        [NUMBER_RANGE_FILTER_TYPE]: () => {
            if (onClick) {
                onClick();
            }
        },
    };

    let renderedValue: React.ReactNode = null;
    switch (type) {
        case undefined:
        case BOOLEAN_FILTER_TYPE:
            renderedValue = value && <Icon name="checkmark" style={{ display: "block" }} />;
            break;
        case DATE_FILTER_TYPE:
            renderedValue = value && formatMonth(value as Date);
            break;
        case DATE_RANGE_FILTER_TYPE:
            renderedValue = value && formatDateRange(value as DateRange);
            break;
        case DROPDOWN_FILTER_TYPE:
            renderedValue = value && options?.find(option => option.value === value)?.label;
            break;
        case NUMBER_RANGE_FILTER_TYPE:
            renderedValue = value && formatNumberRange(value as NumberRange);
            break;
    }

    const renderRequired = () => {
        const text = getRequiredText({ required, requiredText, value });
        if (!text) {
            return null;
        }
        return <div style={{ color: customColors.error }}>{text}</div>;
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 16,
                    padding: 8,
                    minHeight: 56,
                    opacity: disabled ? 0.5 : undefined,
                    cursor: disabled ? undefined : "pointer",
                }}
                onClick={disabled ? undefined : handlers[type ?? BOOLEAN_FILTER_TYPE]}
                role="button"
            >
                <span>{label}</span>
                <div>
                    {renderedValue}
                    {renderRequired()}
                </div>
            </div>
            {type === DATE_FILTER_TYPE && (
                <DatePicker
                    TextFieldComponent={() => null}
                    openTo="year"
                    open={showDatePicker}
                    views={["year", "month"]}
                    value={value as Date | null}
                    onClose={() => {
                        setShowDatePicker(false);
                    }}
                    onChange={date => {
                        if (date) {
                            onChange(name, date?.toDate());
                        }
                    }}
                    data-id="date_picker"
                    cancelLabel={t("common.cancel")}
                />
            )}
            <Divider />
        </>
    );
};

export const TableFilterDialog = ({ open, onClose, filters, activeFilters, onSubmit }: IProps) => {
    const [filterItems, setFilterItems] = React.useState<ITableFilters>({});
    const [selectedFilter, setSelectedFilter] = React.useState<IFilterItem | null>(null);

    React.useEffect(() => {
        setFilterItems(activeFilters);
    }, [activeFilters]);

    const handleChangeFilter = (name: string, value: TableFilterValue) => {
        if (value) {
            setFilterItems({
                ...filterItems,
                [name]: {
                    value,
                },
            });
        } else {
            setFilterItems(omit(filterItems, name));
        }
    };

    const handleClose = () => {
        setFilterItems(activeFilters);
        onClose();
    };

    const handleSubmit = () => {
        const filterDefinitions = flatMap(filters, category => category.entries);

        // check if all required filter values have been selected
        if (!canSubmit(filterDefinitions, filterItems)) {
            // an error is displayed in the FilterItem component already
            return;
        }

        // call the onChange function for all filters
        callOnChange(filterDefinitions, filterItems);

        onSubmit(filterItems);
        onClose();
    };

    const handleOptionsClick = (filter: IFilterItem) => () => {
        setSelectedFilter(filter);
    };

    const handleResetFilters = () => {
        const filterDefinitions = flatMap(filters, category => category.entries);
        const filterItems: ITableFilters = {};
        setFilterItems(filterItems);

        // check if all required filter values have been selected
        if (!canSubmit(filterDefinitions, filterItems)) {
            // an error is displayed in the FilterItem component already
            return;
        }

        onSubmit(filterItems);
        onClose();
    };

    return (
        <CustomDialog open={open} onClose={selectedFilter ? undefined : handleClose}>
            {!selectedFilter && (
                <CustomDialogContent>
                    <h1>{t("filterDialog.header")}</h1>
                    {filters.map(category => (
                        <React.Fragment key={category.category}>
                            {category.label && <h3 style={{ marginTop: 40, marginBottom: 16 }}>{category.label}</h3>}
                            {category.entries.map(entry => {
                                const filter = filterItems[entry.name];
                                const disabled =
                                    typeof entry.disabled === "function" ? entry.disabled(filterItems) : entry.disabled;

                                return (
                                    <FilterItem
                                        key={entry.name}
                                        name={entry.name}
                                        label={entry.label}
                                        type={entry.type}
                                        disabled={disabled}
                                        required={entry.required}
                                        requiredText={entry.requiredText}
                                        value={filter?.value ?? null}
                                        options={entry.type === DROPDOWN_FILTER_TYPE ? entry.options : undefined}
                                        onChange={handleChangeFilter}
                                        onClick={handleOptionsClick(entry)}
                                    />
                                );
                            })}
                        </React.Fragment>
                    ))}
                    <ResponsiveButtonContainer style={{ marginTop: 24 }}>
                        <Button onClick={handleSubmit} variant="contained" color="primary">
                            {t("filterDialog.apply")}
                        </Button>
                        <Button onClick={handleResetFilters} color="primary">
                            {t("filterDialog.reset")}
                        </Button>
                    </ResponsiveButtonContainer>
                </CustomDialogContent>
            )}
            {selectedFilter && (
                <>
                    <CustomDialogContent style={{ paddingBottom: 24 }}>
                        <BackButton
                            backLabel={t("filterDialog.header")}
                            onBack={() => {
                                handleChangeFilter(selectedFilter.name, null);
                                setSelectedFilter(null);
                            }}
                        />
                        <NavBarTitle style={{ marginTop: 8 }}>{selectedFilter.label}</NavBarTitle>
                    </CustomDialogContent>
                    <Divider />
                    <CustomDialogContent style={{ paddingTop: 0 }}>
                        {selectedFilter.type === DATE_RANGE_FILTER_TYPE && (
                            <DateRangeFilter
                                dateRange={filterItems[selectedFilter.name]?.value as DateRange | null}
                                filter={selectedFilter}
                                onChange={dateRange => {
                                    handleChangeFilter(selectedFilter.name, dateRange);
                                }}
                                style={{ paddingTop: 16, display: "flex" }}
                            />
                        )}
                        {selectedFilter.type === DROPDOWN_FILTER_TYPE &&
                            selectedFilter.options.map(option => (
                                <FilterItem
                                    key={option.value}
                                    name={selectedFilter.name}
                                    label={option.label}
                                    value={filterItems[selectedFilter.name]?.value === option.value}
                                    onChange={() => {
                                        handleChangeFilter(selectedFilter.name, option.value);
                                    }}
                                />
                            ))}
                        {selectedFilter.type === NUMBER_RANGE_FILTER_TYPE && (
                            <NumberRangeFilter
                                numberRange={filterItems[selectedFilter.name]?.value as NumberRange | null}
                                filter={selectedFilter}
                                onChange={numberRange => {
                                    handleChangeFilter(selectedFilter.name, numberRange);
                                }}
                                style={{ paddingTop: 16, display: "flex" }}
                            />
                        )}
                        <ResponsiveButtonContainer style={{ marginTop: 24 }}>
                            <Button
                                onClick={() => {
                                    setSelectedFilter(null);
                                }}
                                variant="contained"
                                color="primary"
                            >
                                {t("common.apply")}
                            </Button>
                        </ResponsiveButtonContainer>
                    </CustomDialogContent>
                </>
            )}
        </CustomDialog>
    );
};
