import { observable } from "mobx";
import { accountingStore } from "../components/accounting/AccountingStore";
import { AdvisorRoutes } from "../components/advisor/router/AdvisorRoutes";
import { Routes } from "../components/app/router/Routes";
import { pushRoute } from "../components/app/router/history";
import { hrStore } from "../components/hr/HrStore";
import { DEBUG } from "../config";
import { Module } from "../types/models";
import { addStoreToWindow, debug } from "../util/debug";
import { authStore } from "./AuthStore";
import { companiesStore } from "./CompaniesStore";
import { configStore } from "./ConfigStore";
import { generalStore } from "./GeneralStore";
import { newsStore } from "./NewsStore";
import { clearTableStore } from "./TableStore";
import { viewerStore } from "./ViewerStore";
import { getModuleStore } from "./moduleStores";

export function getInitialRoute() {
    debug.log(
        "### getInitialRoute -> num companies",
        companiesStore.numCompanies,
        authStore.isTpa ? "TPA user" : "normal user",
    );

    if (authStore.isTpa) {
        // TPA frontend
        return AdvisorRoutes.COMPANIES.ACTIVE;
    } else {
        // If one company -> go to personnel file or cockpit.
        // 0 or more -> go to overview
        if (companiesStore.numCompanies === 1) {
            if (authStore.isStaffOnly) {
                // We are staff only -> go to personnel file
                return Routes.STAFF;
            } else {
                // We are normal user -> go to cockpit
                return Routes.COCKPIT;
            }
        }

        // Customer frontend
        return Routes.COMPANY_OVERVIEW;
    }
}

class Coordinator {
    @observable isInitialized = false;
    _initStarted = false;

    // isSyncing = true -> e.g. subsidiary and periods could be out of sync
    // because we are in the middle of a change after selecting a new subsidiary
    // Usually you don't want to render any company/subsidiary date during sync
    @observable _isSyncing = 0;
    get isSyncing() {
        return this._isSyncing > 0;
    }

    get isRehydrated() {
        return (
            authStore.isRehydrated &&
            companiesStore.isRehydrated &&
            accountingStore.isRehydrated &&
            hrStore.isRehydrated &&
            generalStore.isRehydrated
        );
    }

    constructor() {
        authStore.coordinator = this;
    }

    beginLoad(message?: string) {
        debug.log("### coordinator begin load", message);
        generalStore.isLoading = true;
        this._isSyncing++;
    }

    endLoad(message?: string) {
        debug.log("### coordinator end load", message);
        generalStore.isLoading = false;
        this._isSyncing--;
        if (this._isSyncing < 0) {
            if (DEBUG) {
                throw new Error("Sync count negative -> reason: mismatching beginLoad/endLoad in coordinator");
            } else {
                this._isSyncing = 0;
            }
        }
    }

    initApp = async () => {
        if (this.isInitialized || this._initStarted) {
            return;
        }

        // Do this to avoid concurrent inits by Login and AppContainerSite after login
        this._initStarted = true;

        try {
            this.beginLoad("initApp");
            await authStore.loadUserInfo();
            await generalStore.init();
            await companiesStore.init();
            this.isInitialized = true;
            debug.log("### coordinator initialized", this.isInitialized);
        } finally {
            this.endLoad("initApp");
            this._initStarted = false;
        }
    };

    initModule = async (module: Module) => {
        const moduleStore = getModuleStore(module);
        if (!moduleStore.isInitialized) {
            try {
                this.beginLoad("initModule " + module);
                await moduleStore.init();
            } finally {
                this.endLoad("initModule " + module);
            }
        }
    };

    // Select a company and then redirect to a location (or the root location of the current module)
    async selectCompanyById(id: string) {
        // No change -> get out
        if (id === companiesStore.selectedCompanyId) {
            return;
        }

        try {
            this.beginLoad("selectCompany");
            newsStore.wipe();
            clearTableStore();
            await companiesStore.setSelectedCompanyId(id);
            await accountingStore.refreshAfterCompanyChange();
            await hrStore.refreshAfterCompanyChange();
        } finally {
            this.endLoad("selectCompany");
        }
    }

    async reloadCompany() {
        try {
            this.beginLoad("reloadCompany");
            newsStore.wipe();
            await companiesStore.reloadCompany();
            await accountingStore.refreshAfterCompanyChange();
            await hrStore.refreshAfterCompanyChange();
        } finally {
            this.endLoad("reloadCompany");
        }
    }

    refreshAfterLogin = async () => {
        await coordinator.initApp();
        if (authStore.redirectAfterLogin) {
            debug.log("[ROUTER] restore redirectAfterLogin", authStore.redirectAfterLogin);
            // Redirect to requested target
            pushRoute(authStore.redirectAfterLogin);
            authStore.redirectAfterLogin = undefined;
        } else {
            // Default route after login
            const initialRoute = getInitialRoute();
            debug.log("[ROUTER] going to initial route", initialRoute);
            pushRoute(initialRoute);
        }
    };

    wipe() {
        this.isInitialized = false;
        this._initStarted = false;

        companiesStore.selectedCompanyStore?.stopPollingBadges();

        // Wipe all local data
        accountingStore.wipe();
        hrStore.wipe();
        companiesStore.wipe();
        newsStore.wipe();
        configStore.wipe();
        generalStore.wipe();
        viewerStore.wipe();
        clearTableStore();
    }

    async logout() {
        await authStore.logout();
        // Has to be last to clear redirectAfterLogin which gets autoset by PrivateRoute otherwise
        authStore.wipe(null);
    }
}

export const coordinator = new Coordinator();

addStoreToWindow("coordinator", coordinator);
