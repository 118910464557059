import styled from "styled-components";

const LabelAndIconWrapper = styled.span`
    display: inline-block;
    white-space: nowrap;
    text-decoration: inherit;
`;

/**
 * This component displays a label and an icon next to.
 *
 * The main difference to just doing `{label} {icon}` is that this will "join" the last word of the label with the icon.
 * Hence the last word and icon will always be on the same line. Otherwise the icon might be on the next line which might look really weird.
 *
 * So imagine a container with a fixed width, using `{label} {icon}` would result in:
 *
 *     +------------------+
 *     | This is a text   |
 *     | <icon>           |
 *     +------------------+
 *
 * This component however will display:
 *
 *     +------------------+
 *     | This is a        |
 *     | text <icon>      |
 *     +------------------+
 *
 * Inspiration: https://codepen.io/snookca/pen/JBdBVZ
 */
export const LabelAndIcon = ({ label, icon }: { label: string; icon: React.ReactElement }) => {
    const lastIndex = label.lastIndexOf(" ");
    if (lastIndex === -1) {
        return (
            <LabelAndIconWrapper>
                {icon}
                {label}
            </LabelAndIconWrapper>
        );
    }

    return (
        <>
            {label.substring(0, lastIndex + 1)}
            <LabelAndIconWrapper>
                {label.substring(lastIndex + 1)}
                {icon}
            </LabelAndIconWrapper>
        </>
    );
};
