import * as React from "react";
import { t } from "../../../i18n/util";
import { configStore } from "../../../stores/ConfigStore";
import { EmployeesTable } from "../../shared/EmployeesTable";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { MobileContext } from "../../util/MobileContext";
import { SettingsRoutes } from "../router/SettingsRoutes";

export const SettingsUserManagementNewUsersSite = () => {
    const isMobile = React.useContext(MobileContext);

    React.useEffect(() => {
        configStore.wipe();
    }, []);

    return (
        <>
            <NavBarBack
                title={t("config.users.newUsers")}
                backLabel={t("settings.navbar.userManagement")}
                backTarget={SettingsRoutes.USER_MANAGEMENT.ROOT}
            />
            <CenteredContent>
                <SiteContent>
                    <h1 style={{ marginTop: isMobile ? 16 : 32 }}>{t("config.users.newUsers.title")}</h1>
                    <p style={{ fontSize: 12, marginTop: 16, marginBottom: 8 }}>
                        {t("config.users.newUsers.description")}
                    </p>
                    <EmployeesTable />
                </SiteContent>
            </CenteredContent>
        </>
    );
};
