import { Button, MenuItem, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { t } from "../../../i18n/util";
import {
    KPIConfiguration,
    KPIConfigurationInsuranceCarrierBalance,
    KPIConfigurationMetric,
    KPIConfigurationWithMonths,
} from "../../../types/kpis";
import { useAwaitableDialog } from "../../hooks/useAwaitableDialog";
import { useInsuranceCarriers } from "../../hooks/useInsuranceCarriers";
import { CustomDialog, CustomDialogContent, CustomDialogTitle } from "../../ui/CustomDialog";
import { TpaWhiteButton } from "../../ui/Primitives";
import { ResponsiveButtonContainer } from "../../ui/ResponsiveButtonContainer";
import { useKPIContext } from "./KPIContext";
import { getDefaultConfig, metricLabels } from "./utils";

interface AdditionalProps {
    metrics: KPIConfigurationMetric[];
    initialConfig?: KPIConfiguration;
}

export const useKPIConfigurationDialog = () => {
    const kpiConfigurationDialog = useAwaitableDialog<KPIConfiguration, AdditionalProps>({ metrics: [] });

    return {
        open: (additionalProps: AdditionalProps) => {
            return kpiConfigurationDialog.open(additionalProps);
        },
        dialog: (
            <KPIConfigurationDialog
                {...kpiConfigurationDialog.additionalProps}
                {...kpiConfigurationDialog.dialogProps}
            />
        ),
    };
};

const KPIConfigurationDialog = ({
    open,
    onSubmit,
    onClose,
    metrics,
    initialConfig,
}: {
    open: boolean;
    onSubmit: (kpi: KPIConfiguration) => void;
    onClose: () => void;
    metrics: KPIConfigurationMetric[];
    initialConfig?: KPIConfiguration;
}) => {
    const [config, setConfig] = useState<KPIConfiguration>();

    useEffect(() => {
        setConfig(initialConfig);
    }, [initialConfig]);

    const handleChange = (config: KPIConfiguration) => {
        setConfig(config);
    };

    const handleSave = () => {
        if (!config) {
            return;
        }

        setConfig(undefined);

        onSubmit(config);
    };

    const handleClose = () => {
        setConfig(undefined);

        onClose();
    };

    return (
        <CustomDialog open={open} onClose={handleClose}>
            <CustomDialogContent>
                <CustomDialogTitle>{t("screen.cockpit.kpis.configurationDialog.title")}</CustomDialogTitle>
                <TextField
                    value={config?.metric ?? ""}
                    label={t("screen.cockpit.kpis.configurationDialog.metric.label")}
                    variant="outlined"
                    fullWidth
                    select
                    disabled={!!initialConfig}
                    onChange={event => {
                        setConfig(getDefaultConfig(event.target.value as KPIConfigurationMetric));
                    }}
                >
                    {metrics
                        .map(metric => ({ metric, label: t(metricLabels[metric]) }))
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map(({ metric, label }) => (
                            <MenuItem key={metric} value={metric}>
                                {label}
                            </MenuItem>
                        ))}
                </TextField>
                {config?.metric === KPIConfigurationMetric.insuranceCarrierBalance && (
                    <KPIConfigurationDialogInsuranceCarrierBalance config={config} onChange={handleChange} />
                )}
                {config && hasMonths(config) && (
                    <KPIConfigurationDialogMonths config={config} onChange={handleChange} />
                )}
                <ResponsiveButtonContainer style={{ marginTop: 32 }}>
                    <TpaWhiteButton onClick={handleClose}>{t("common.cancel")}</TpaWhiteButton>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {t("common.save")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
};

const insuranceCarrierValue = (ic: { carrierNumber: string; accountNumber: string }) => {
    return !ic.carrierNumber ? "" : `${ic.carrierNumber}/${ic.accountNumber}`;
};

const KPIConfigurationDialogInsuranceCarrierBalance = ({
    config,
    onChange,
}: {
    config: KPIConfigurationInsuranceCarrierBalance;
    onChange: (config: KPIConfiguration) => void;
}) => {
    const companyId = useKPIContext().kpiStore.companyId;
    const insuranceCarriers = useInsuranceCarriers(companyId);

    return (
        <>
            <TextField
                value={!insuranceCarriers ? "" : insuranceCarrierValue(config.settings)}
                label={t("screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label")}
                variant="outlined"
                fullWidth
                select
                onChange={event => {
                    const [carrierNumber, accountNumber] = event.target.value.split("/");
                    onChange({ ...config, settings: { ...config.settings, carrierNumber, accountNumber } });
                }}
                style={{ marginTop: 16 }}
            >
                {insuranceCarriers
                    ?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(ic => {
                        const value = insuranceCarrierValue(ic);
                        return (
                            <MenuItem key={value} value={value}>
                                {ic.name} ({value})
                            </MenuItem>
                        );
                    }) ?? []}
            </TextField>
        </>
    );
};

const hasMonths = (config: KPIConfiguration): config is KPIConfigurationWithMonths => {
    return (
        config.metric === KPIConfigurationMetric.ebitValue ||
        config.metric === KPIConfigurationMetric.revenueValue ||
        config.metric === KPIConfigurationMetric.ebitBarChart ||
        config.metric === KPIConfigurationMetric.revenueBarChart
    );
};

const KPIConfigurationDialogMonths = ({
    config,
    onChange,
}: {
    config: KPIConfigurationWithMonths;
    onChange: (config: KPIConfigurationWithMonths) => void;
}) => {
    return (
        <TextField
            value={config.settings.months}
            label={t("screen.cockpit.kpis.configurationDialog.months.label")}
            variant="outlined"
            fullWidth
            type="number"
            onChange={event => {
                const value = parseInt(event.target.value);
                if (isNaN(value)) {
                    return;
                }
                onChange({ ...config, settings: { months: value } });
            }}
            InputProps={{ inputProps: { min: 1 } }}
            style={{ marginTop: 16 }}
        />
    );
};
