import { observer } from "mobx-react";
import { t } from "../../i18n/util";
import { ProjectItem, ProjectItemPermission } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { getFileIconName } from "../../util/helpers";
import { CustomDialog } from "../ui/CustomDialog";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { PermissionUsersList } from "./PermissionUsersList";

export const ProjectItemPermissionsDialog = observer(function ProjectItemPermissionsDialog({
    open,
    onClose,
    permissionUsers,
    projectItem,
}: {
    open: boolean;
    permissionUsers: ProjectItemPermission[];
    projectItem: ProjectItem;
    onClose: () => void;
}) {
    return (
        <CustomDialog open={open} onClose={onClose} showCloseIcon>
            <div style={{ padding: "0 42px" }}>
                <h1>
                    {permissionUsers.length} {t("common.members")}
                </h1>
                <div style={{ display: "flex", alignItems: "center", marginTop: 24 }}>
                    <div>
                        <Icon
                            name={projectItem.isFolder ? "folder" : getFileIconName(projectItem.name)}
                            style={{ display: "block" }}
                        />
                    </div>
                    <div style={{ marginLeft: 16 }}>{projectItem.name}</div>
                </div>
            </div>
            <PermissionUsersList permissionUsers={permissionUsers} />
            {authStore.isTpa && (
                <div style={{ padding: "0 42px 16px 42px" }}>
                    <p style={{ display: "flex", alignItems: "center" }}>
                        <Icon name="info" style={{ color: customColors.primaryColor }} />
                        <span className="body2" style={{ marginLeft: 4 }}>
                            {t("addFolderDialog.info")}
                        </span>
                    </p>
                </div>
            )}
        </CustomDialog>
    );
});
