import { Button } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import { MAX_IMAGE_UPLOAD_SIZE_MB } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Company, PatchCompanyPayload } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { ICompany } from "../../../types/models";
import { debug } from "../../../util/debug";
import { pushRoute, withParams } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { ImageUploadSmall } from "../../ui/ImageUploadSmall";
import { NavBarBack } from "../../ui/NavBarBack";
import { OverviewLine } from "../../ui/OverviewLine";
import { PageWithStepper } from "../../ui/PageWithStepper";
import { AdvisorConfigCompanyStepper } from "../AdvisorConfigCompanyStepper";
import { AdvisorRoutes } from "../router/AdvisorRoutes";
import { ConfigSwitch } from "./AdvisorConfigAccountingSite";

export const ToggleCockpitKpiActive = ({
    company,
    canEdit,
    style,
    onIsCheckedChanged,
}: {
    company: Company;
    canEdit: boolean;
    style?: React.CSSProperties;
    onIsCheckedChanged?: () => void;
}) => {
    const [isChecked, setIsChecked] = React.useState(false);
    React.useEffect(() => {
        if (company.hasCockpitKPI === true) {
            setIsChecked(true);
        }
    }, [company.hasCockpitKPI]);

    return (
        <div>
            <h4 style={style}>{t("config.companyData.cockpit")}</h4>
            <ConfigSwitch
                data-id="switch_toggle_cockpit_kpi"
                checked={isChecked}
                onCheck={async checked => {
                    const patchCompanyPayload: PatchCompanyPayload = {
                        hasCockpitKPI: checked,
                    };

                    try {
                        setIsChecked(checked);
                        generalStore.isLoading = true;
                        await API.patchCompany(company.id, patchCompanyPayload);
                    } catch (err) {
                        generalStore.setError(t("error.edit"), err);
                        setIsChecked(!checked);
                        return;
                    } finally {
                        generalStore.isLoading = false;
                    }

                    onIsCheckedChanged?.();
                }}
                label={t("config.accounting.hasCockpitKpi")}
                canEdit={canEdit}
                style={{ marginTop: 16 }}
            />
        </div>
    );
};

export const CompanyData = (props: { company: ICompany; style?: React.CSSProperties }) => {
    const company = props.company;

    return (
        <div style={props.style}>
            <h4 style={{ marginBottom: 16 }}>{t("common.generalData")}</h4>
            <OverviewLine title={t("table.label.companyName")} label={company.name} />
            <OverviewLine title={t("common.clientNumber")} label={company.customerNumber} />
            <OverviewLine title={t("common.taxNumber")} label={company.taxNumber} />
            <OverviewLine title={t("common.vatNumber")} label={company.vatNumber} />
            {/* <OverviewLine title={t("common.companyNumber")} label={company.companyNumber} /> */}
            <OverviewLine title={t("common.companyRegisterNumber")} label={company.companyRegistrationNumber} />
            <OverviewLine title={t("common.bankContact")} label={company.iban} />
            <OverviewLine title={t("common.bic")} label={company.bic} />
        </div>
    );
};

export const CompanyImages = (props: {
    company: ICompany;
    style?: React.CSSProperties;
    onImagesChanged?: () => void;
    disabled?: boolean;
}) => {
    return (
        <CompanyImagesLocal
            coverImageUrl={props.company.coverImage?.imageUrl}
            onCoverImageChange={async file => {
                const res = await API.putCoverImage(props.company.id, file);
                props.onImagesChanged?.();
                debug.log("### cover upload", res);
            }}
            onCoverImageDelete={async () => {
                try {
                    await API.deleteCoverImage(props.company.id);
                    props.onImagesChanged?.();
                } catch (error) {
                    generalStore.setError(t("error.delete"), error);
                }
            }}
            logoImageUrl={props.company.logoImage?.imageUrl}
            onLogoImageChange={async file => {
                const res = await API.putLogoImage(props.company.id, file);
                props.onImagesChanged?.();
                debug.log("### logo upload", res);
            }}
            onLogoImageDelete={async () => {
                try {
                    await API.deleteLogoImage(props.company.id);
                    props.onImagesChanged?.();
                } catch (error) {
                    generalStore.setError(t("error.delete"), error);
                }
            }}
            style={props.style}
            disabled={props.disabled}
        />
    );
};

export const CompanyImagesLocal = (props: {
    coverImageUrl: string | undefined;
    onCoverImageChange: (image: File) => void;
    onCoverImageDelete: () => void;
    logoImageUrl: string | undefined;
    onLogoImageChange: (image: File) => void;
    onLogoImageDelete: () => void;
    style?: React.CSSProperties;
    disabled?: boolean;
}) => {
    return (
        <div style={props.style}>
            <h4 style={{ marginBottom: 24 }}>{t("companyImages.title")}</h4>
            <ImageUploadSmall
                title={"companyImages.cover.title"}
                uploadFile={props.onCoverImageChange}
                deleteFile={props.onCoverImageDelete}
                imageUrl={props.coverImageUrl}
                disabled={props.disabled}
                fallbackErrorMessage={t("companyImages.cover.error")}
                maxUploadSizeMb={MAX_IMAGE_UPLOAD_SIZE_MB}
            />
            <ImageUploadSmall
                title={"companyImages.logo.title"}
                cropCircle
                uploadFile={props.onLogoImageChange}
                deleteFile={props.onLogoImageDelete}
                imageUrl={props.logoImageUrl}
                disabled={props.disabled}
                fallbackErrorMessage={t("companyImages.logo.error")}
                maxUploadSizeMb={MAX_IMAGE_UPLOAD_SIZE_MB}
            />
        </div>
    );
};

export const AdvisorConfigCompanyDataSite = () => {
    useHideSideBar();

    const { companyId } = useParams<{ companyId: string }>();
    const { company, reload } = useCompany({ companyId });

    if (!companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    return (
        <>
            <NavBarBack
                title={t("config.companies")}
                backLabel={t("config.overview")}
                backTarget={withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId })}
                companyName={company?.name}
            />
            {company && (
                <PageWithStepper stepper={<AdvisorConfigCompanyStepper company={company} />}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 8,
                        }}
                    >
                        <h1 style={{ hyphens: "auto", wordBreak: "break-all" }}>{t("config.companyData.title")}</h1>
                        <Button
                            color="primary"
                            onClick={() => {
                                reload(true);
                            }}
                            endIcon={<RefreshIcon />}
                            style={{ marginLeft: 16 }}
                        >
                            {t("common.refresh")}
                        </Button>
                    </div>

                    <p style={{ marginTop: 16, fontSize: 12 }}>{t("config.companyData.description")}</p>
                    <CompanyData style={{ marginTop: 24 }} company={company} />
                    <ToggleCockpitKpiActive
                        company={company}
                        style={{ marginTop: 40 }}
                        canEdit={true}
                        onIsCheckedChanged={reload}
                    />
                    <CompanyImages style={{ marginTop: 40 }} company={company} onImagesChanged={reload} />
                    <div style={{ marginTop: 32, textAlign: "right" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                pushRoute(withParams(AdvisorRoutes.CONFIG.TERMS_OF_USE, { companyId }));
                            }}
                        >
                            {t("button.nextStep")}
                        </Button>
                    </div>
                </PageWithStepper>
            )}
        </>
    );
};
