import { Button } from "@material-ui/core";
import React from "react";
import { t } from "../../../i18n/util";
import { ProjectItemPermissionRoleEnum, ProjectUser } from "../../../network/APITypes";
import { CustomDialog } from "../../ui/CustomDialog";
import { TpaWhiteButton } from "../../ui/Primitives";
import { ResponsiveButtonContainer } from "../../ui/ResponsiveButtonContainer";
import { customColors } from "../../util/Theme";
import { ProjectUserPermissionLine } from "../ProjectUserPermissionLine";

const Container = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => (
    <div style={{ padding: "0 42px", ...style }}>{children}</div>
);

type NewProjectUser = ProjectUser & { role: ProjectItemPermissionRoleEnum };

const AddProjectUsersDialog = ({
    open,
    users,
    onCancel,
    onConfirm,
    onUserPermissionChange,
}: {
    open: boolean;
    users: NewProjectUser[];
    onCancel?: () => void;
    onConfirm?: () => void;
    onUserPermissionChange: (userId: string, permission: ProjectItemPermissionRoleEnum) => void;
}) => {
    return (
        <CustomDialog open={open}>
            <div style={{ paddingTop: 42, display: "flex", flexDirection: "column" }}>
                <Container style={{ paddingBottom: 16, borderBottom: `1px solid ${customColors.greyLight}` }}>
                    <h1>{t("projects.members.assignPermission")}</h1>
                    <h4 style={{ marginTop: 24 }}>{`${users.length} Personen`}</h4>
                </Container>
                <Container>
                    {users.map(user => {
                        return (
                            <ProjectUserPermissionLine
                                key={user.id}
                                firstName={user.firstName}
                                lastName={user.lastName}
                                profileImageUrl={user.profile_picture_url}
                                isTpa={!!user.isTpaEmployee}
                                permission={user.role}
                                needsRelease={user.needsRelease}
                                userId={user.id}
                                onChangePermission={(value: ProjectItemPermissionRoleEnum) => {
                                    onUserPermissionChange(user.id, value);
                                }}
                            />
                        );
                    })}
                </Container>
                <Container
                    style={{ paddingTop: 24, paddingBottom: 42, borderTop: `1px solid ${customColors.greyLight}` }}
                >
                    <ResponsiveButtonContainer>
                        {onCancel && (
                            <TpaWhiteButton onClick={onCancel} data-id="button_delete_subsidiary_cancel">
                                {t("common.cancel")}
                            </TpaWhiteButton>
                        )}
                        {onConfirm && (
                            <Button variant="contained" color="primary" onClick={onConfirm} data-id="button_confirm">
                                {t("common.ok")}
                            </Button>
                        )}
                    </ResponsiveButtonContainer>
                </Container>
            </div>
        </CustomDialog>
    );
};

export function useAddProjectUsersDialog(onSubmit: (usersToAdd: NewProjectUser[]) => unknown) {
    const [usersToAdd, setUsersToAdd] = React.useState<NewProjectUser[]>([]);
    const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false);

    const handlePermissionChange = (userId: string, value: ProjectItemPermissionRoleEnum) => {
        const user = usersToAdd.find(u => u.id === userId);
        if (!user) {
            return;
        }

        user.role = value;
        setUsersToAdd([...usersToAdd]);
    };

    const handleAddUsers = () => {
        if (usersToAdd.length === 0) {
            return;
        }

        setAddUserDialogOpen(false);
        onSubmit(usersToAdd);
    };

    const component = (
        <AddProjectUsersDialog
            open={addUserDialogOpen}
            users={usersToAdd}
            onCancel={() => {
                setAddUserDialogOpen(false);
            }}
            onConfirm={handleAddUsers}
            onUserPermissionChange={handlePermissionChange}
        />
    );

    return {
        open(users: ProjectUser[]) {
            const newUsers = users.map<NewProjectUser>(user => ({
                ...user,
                role: "read",
            }));
            setUsersToAdd(newUsers);
            setAddUserDialogOpen(true);
        },
        component,
    };
}
