import { Redirect, Route, Switch } from "react-router-dom";
import { authStore } from "../../../stores/AuthStore";
import { Routes } from "../../app/router/Routes";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { UserPermissionsSite } from "../../shared/sites/UserPermissionsSite";
import { UserRolesSite } from "../../shared/sites/UserRolesSite";
import { SettingsCompanyNavBar } from "../SettingsCompanyNavBar";
import { SettingsCompanyAccountingSite } from "../sites/SettingsCompanyAccountingSite";
import { SettingsCompanyDataSite } from "../sites/SettingsCompanyDataSite";
import { SettingsCompanyFeaturesSite } from "../sites/SettingsCompanyFeaturesSite";
import { SettingsCompanyHrSite } from "../sites/SettingsCompanyHrSite";
import { SettingsCompanyKPIsSite } from "../sites/SettingsCompanyKPIsSite";
import { SettingsCompanyOptionalMenuItemsSite } from "../sites/SettingsCompanyOptionalMenuItemsSite";
import { SettingsCompanyTermsSite } from "../sites/SettingsCompanyTermsSite";
import { SettingsGeneralSite } from "../sites/SettingsGeneralSite";
import { SettingsUserManagementNewUsersSite } from "../sites/SettingsUserManagementNewUsersSite";
import { SettingsUserManagementSite } from "../sites/SettingsUserManagementSite";
import { SettingsRoutes } from "./SettingsRoutes";

const CompanySettings = () => {
    // TPAPORTAL-1912: Redirect to the general settings page if the user has no permissions
    if (!authStore.canReadCompanySettings && !authStore.canEditCompanyImages) {
        return <Redirect to={SettingsRoutes.GENERAL.ROOT} />;
    }

    return (
        <>
            <SettingsCompanyNavBar />
            <Route exact path={SettingsRoutes.COMPANY.ROOT}>
                <Redirect to={SettingsRoutes.COMPANY.DATA} />
            </Route>

            <Route exact path={SettingsRoutes.COMPANY.DATA}>
                <SettingsCompanyDataSite />
            </Route>
            <Route exact path={SettingsRoutes.COMPANY.ACCOUNTING}>
                {!authStore.canReadCompanySettings ? (
                    <Redirect to={SettingsRoutes.COMPANY.DATA} />
                ) : (
                    <SettingsCompanyAccountingSite />
                )}
            </Route>
            <Route exact path={SettingsRoutes.COMPANY.HR}>
                {!authStore.canReadCompanySettings ? (
                    <Redirect to={SettingsRoutes.COMPANY.DATA} />
                ) : (
                    <SettingsCompanyHrSite />
                )}
            </Route>
            <Route exact path={SettingsRoutes.COMPANY.KPIS}>
                {!authStore.canReadCompanySettings ? (
                    <Redirect to={SettingsRoutes.COMPANY.DATA} />
                ) : (
                    <SettingsCompanyKPIsSite />
                )}
            </Route>
            <Route path={SettingsRoutes.COMPANY.TERMS}>
                <SettingsCompanyTermsSite variant="current" />
            </Route>
            <Route path={SettingsRoutes.COMPANY.UPCOMING_TERMS}>
                <SettingsCompanyTermsSite variant="upcoming" />
            </Route>
            <Route exact path={SettingsRoutes.COMPANY.OPTIONAL_SIDEBAR_ITEMS}>
                <SettingsCompanyOptionalMenuItemsSite />
            </Route>
            <Route exact path={SettingsRoutes.COMPANY.FEATURES}>
                <SettingsCompanyFeaturesSite />
            </Route>
        </>
    );
};

export const SettingsRouter = () => (
    <Switch>
        <Route exact path={Routes.SETTINGS}>
            <Redirect to={SettingsRoutes.GENERAL.ROOT} />
        </Route>
        <Route exact path={SettingsRoutes.GENERAL.ROOT}>
            <SettingsGeneralSite />
        </Route>
        <Route path={SettingsRoutes.COMPANY.ROOT}>
            <CompanySettings />
        </Route>
        <Route exact path={SettingsRoutes.USER_MANAGEMENT.ROOT} component={SettingsUserManagementSite} />
        <Route path={SettingsRoutes.USER_MANAGEMENT.USERS_EMPLOYEES} component={SettingsUserManagementNewUsersSite} />
        <Route path={SettingsRoutes.USER_MANAGEMENT.USER_ROLES}>
            <UserRolesSite />
        </Route>
        <Route path={SettingsRoutes.USER_MANAGEMENT.USER_PERMISSIONS}>
            <UserPermissionsSite />
        </Route>
        <Route component={NotFoundSite} />
    </Switch>
);
