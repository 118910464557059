import React from "react";
import { t } from "../../i18n/util";
import { Status } from "../../stores/Status";
import { formatDate } from "../../util/helpers";
import { MobileContext } from "../util/MobileContext";
import { customColors, theme } from "../util/Theme";
import { StatusBar } from "./StatusBar";

export const FolderRow = (props: {
    title: string;
    personResponsible?: string;
    documentCount: number;
    status: Status;
    ticketCount?: number;
    date?: Date;
    overdue?: boolean;
    statusTitle: string;
    onClick?: (event: React.MouseEvent) => void;
    "data-id": string;
}) => {
    const isMobile = React.useContext(MobileContext);

    const personResponsibleComponent = props.personResponsible && (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <span className="body2" style={{ marginRight: 8 }}>
                {t("screen.accounting.records.person.responsible")}
            </span>
            <span style={{ fontWeight: "bold" }}>{props.personResponsible}</span>
            {!isMobile && (
                <div
                    style={{
                        background: customColors.greyTextfields,
                        width: 1,
                        height: 24,
                        margin: "0px 16px",
                    }}
                />
            )}
        </div>
    );

    const statusComponent = (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <span className="body2" style={{ marginRight: 8 }}>
                {props.statusTitle}
            </span>
            {props.date && (
                <span
                    className="body1"
                    style={{
                        fontWeight: "bold",
                        color: props.overdue ? "#B3364C" : theme.palette.text.secondary,
                    }}
                >
                    {formatDate(props.date)}
                </span>
            )}
        </div>
    );

    return (
        <div style={{ width: "100%", marginBottom: 8 }}>
            <div
                style={{
                    display: "flex",
                    backgroundColor: "white",
                    minHeight: 100,
                    lineHeight: "22px",
                    borderRadius: 4,
                    cursor: "pointer",
                }}
                data-id={props["data-id"]}
                onClick={props.onClick}
            >
                <StatusBar marginBottom={0} status={props.status} />
                <div style={{ display: "flex", flexGrow: 1, alignItems: "center", padding: 24 }}>
                    <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
                        <h3>{props.title}</h3>
                        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
                            <p style={{ marginRight: 4 }}>
                                {t("screen.accounting.records.documents")} {props.documentCount}
                            </p>
                            {!!props.ticketCount && (
                                <p>{t("screen.accounting.records.tickets", { numTickets: props.ticketCount })}</p>
                            )}
                        </div>
                        {isMobile && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 8,
                                    justifyContent: "flex-end",
                                }}
                            >
                                <div>
                                    {personResponsibleComponent}
                                    {statusComponent}
                                </div>
                            </div>
                        )}
                    </div>
                    {!isMobile && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {personResponsibleComponent}
                            {statusComponent}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
