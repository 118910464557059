import {
    CommuterAllowance,
    Company,
    DetailedEmployee,
    DocumentDetails,
    EmployeeBonus,
    EmployeeDocument,
    ExternalDocument,
    GeneralDocumentType,
    GetEmployeeDocumentsResponse,
    GetPeriodsResponse,
    GetPeriodsResponsePeriodTypeEnum,
    GetRecordTypesResponse,
    GetSubsidiariesResponse,
    GetUserInfoResponse,
    Permissions,
    ProjectItemPermission,
    Record,
    RecordType,
    Role,
    SoleEarnerStatus,
    TPAEmployeePublicUserInfo,
    UserNotifications,
} from "../network/APITypes";

export type Module = "accounting" | "hr";

export type DocumentModule = Module | "generalDocuments";

// If an app model needs to extend an APIModel then use interface extends instead. For now we can simply alias the types

export type ICompany = Company;

export type ISubsidiary = GetSubsidiariesResponse & {
    // Preloaded recordTypes for this subsidiary
    recordTypes?: RecordType[];
};

export interface IBasicPeriod {
    periodEnd: string;
    periodStart: string;
    periodType: GetPeriodsResponsePeriodTypeEnum;
}

export type IPeriod = GetPeriodsResponse;

export type IUserInfo = GetUserInfoResponse;

// This is a combo of TPAEmployeePublicUserInfo and ChatUserInfo
export type IPublicUserInfo = TPAEmployeePublicUserInfo & { role?: Role };
export type IChatUserInfo = IPublicUserInfo & { isConfidential?: boolean };

export type IRecordType = GetRecordTypesResponse;

export type IDocument = DocumentDetails;

export type IRecord = Record;

export type IEmployee = Omit<
    DetailedEmployee,
    | "email"
    | "dateOfBirth"
    | "dateOfJoining"
    | "dateOfLeaving"
    | "bonuses"
    | "monthlySalary"
    | "workingHours"
    | "isGrossSalary"
    | "weeklyWorkDays"
    | "workingHoursInterval"
    | "remainingOvertime"
    | "remainingVacationDays"
    | "reasonForLeaving"
    | "titlePrefix"
    | "titleSuffix"
    | "religiousConfession"
    | "country"
    | "citizenship"
    | "previouslyEmployed"
    | "commuterAllowance"
    | "kilometersForCommuterEuro"
    | "soleEarnerStatus"
    | "familyBonusPlusAmount"
> & {
    email?: string | null;
    dateOfBirth: string | null;
    dateOfJoining: string | null;
    costCenterNote?: string;
    bonuses: IEmployeeBonus[];
    monthlySalary?: number | "" | null;
    workingHours?: number | "" | null;
    isGrossSalary?: boolean | "false" | "true";
    weeklyWorkDays?: number | "" | null;
    workingHoursInterval?: string;
    dateOfLeaving?: string | null;
    remainingOvertime: number | "" | null;
    remainingVacationDays: number | "" | null;
    reasonForLeaving?: string | null;
    titlePrefix: string | null;
    titleSuffix: string | null;
    religiousConfession: string | null;
    country: string | null;
    citizenship: string | null;
    previouslyEmployed?: boolean;
    employee_id?: string;
    commuterAllowance?: CommuterAllowance | null;
    kilometersForCommuterEuro?: number | "" | null;
    soleEarnerStatus?: SoleEarnerStatus | null;
    familyBonusPlusAmount?: number | "" | null;
};

export interface IEmployeeRouteParams {
    employeeId: string;
}

export interface IUser {
    id: string;
}

export interface IDeregistration {
    employee: IEmployee;
    touched: boolean;
}

export interface INonWorkingTime {
    title: string;
    fromDate: string;
    toDate: string;
    unit: string;
    length: number;
}

export interface INonWorkingTimes {
    holidays: INonWorkingTime[];
    sickLeave: INonWorkingTime[];
    others?: INonWorkingTime[];
}

export type IEmployeeBonus = Omit<EmployeeBonus, "amount"> & {
    amount?: number | string | null;
};

export type IEmployeeDocument = EmployeeDocument;

export type IGetEmployeeDocumentsResponse = GetEmployeeDocumentsResponse & {
    limit: number;
    offset: number;
    total: number;
};

// This is either a ProjectItemPermission or a ProjectPermissionRequest
export type IProjectUser = Omit<ProjectItemPermission, "id" | "name"> & {
    id?: string;
    name?: string;
};

export type PeriodType = GetPeriodsResponsePeriodTypeEnum;

// Identifies a BMD archive document
export type IArchiveDocument = ExternalDocument;

export type IUserNotifications = UserNotifications;

export type IPermissions = Permissions;

export const generalDocumentTypes: GeneralDocumentType[] = [
    "articles",
    "notices",
    "taxOffice",
    "information",
    "annualReports",
    "correspondence",
    "taxReturn",
    "contracts",
    "other",
];

// Represents either an azure token refresh or invitation
export interface IRefreshOrInvitation {
    companyId?: string;
    countryCode: string;
    isInternational?: boolean;
}

export type ApprovalOption = "none" | "click" | "qes";

export type InvalidLogin = "credentials" | "code";

export type AuthStep = "email" | "password" | "multiFactorAuth";

export interface IPassword {
    password: string;
    repeatPassword: string;
}

export type AuthAction = "registration" | "passwordReset";

export type NotificationState = "read" | "unread" | "all";
