import { Decimal } from "decimal.js";
import React from "react";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";
import { isNever } from "../../util/ts";
import { Icon } from "../util/Icon";
import { ResultValue } from "./hooks/useTransformedResult";

const ValueRoot = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
`;
const CurrencyValueRoot = styled(ValueRoot)`
    margin-left: 24px;
`;

const RatioValueRoot = styled(ValueRoot)`
    font-size: 11px;
    color: inherit;
    font-weight: bold;
    font-style: normal;
`;

interface ValueProps {
    value: ResultValue | null;
}

export const Value = ({ value }: ValueProps) => {
    if (!value) {
        return null;
    }

    switch (value.type) {
        case "currency":
            return (
                <CurrencyValueRoot>
                    <Currency value={value.value} />
                </CurrencyValueRoot>
            );
        case "currency-pill":
            return (
                <ValueRoot>
                    <CurrencyPill value={value.value} flipSign={value.flipSign} />
                </ValueRoot>
            );
        case "percentage-pill":
            return (
                <ValueRoot>
                    <PercentagePill value={value.value} flipSign={value.flipSign} />
                </ValueRoot>
            );
        case "ratio":
            return (
                <RatioValueRoot className="ratio-cell">
                    <Ratio value={value.value} />
                </RatioValueRoot>
            );

        default:
            isNever(value);
            return null;
    }
};

const CurrencyRoot = styled.span`
    white-space: nowrap;
`;

interface CurrencyProps {
    value: number | Decimal;
    currency?: string;
    currencyPosition?: "start" | "end";
    className?: string;
    style?: React.CSSProperties;
}
export const Currency = ({ value, currency, currencyPosition, className, style }: CurrencyProps) => {
    const v = typeof value === "number" ? value : value.toNumber();
    return (
        <CurrencyRoot className={className} style={style}>
            {currency && currencyPosition === "start" ? `${currency} ` : null}
            <FormattedNumber value={v} minimumFractionDigits={2} maximumFractionDigits={2} />
            {currency && currencyPosition === "end" ? ` ${currency}` : null}
        </CurrencyRoot>
    );
};
export const BoldCurrency = styled(Currency)`
    font-weight: bold;
`;

interface RatioProps {
    value?: number;
}
const Ratio = ({ value }: RatioProps) => {
    return value == null ? null : (
        <FormattedNumber value={value} minimumFractionDigits={1} maximumFractionDigits={1} style="percent" />
    );
};

interface PercentageProps {
    value: number | Decimal;
}
const Percentage = ({ value }: PercentageProps) => {
    const v = typeof value === "number" ? value : value.toNumber();
    return (
        <FormattedNumber
            value={v / 100} // 0.01 == 1% | 1 == 100%
            style="percent"
            minimumFractionDigits={1}
            maximumFractionDigits={1}
        />
    );
};

interface CurrencyPillProps {
    value: number | Decimal;
    flipSign?: boolean;
}
export const CurrencyPill = ({ value, flipSign }: CurrencyPillProps) => {
    let variant: PillProps["variant"] = "grey";
    let icon: React.ReactNode = null;
    const v = new Decimal(value);
    if (v.lessThan(0)) {
        variant = flipSign === true ? "green" : "red";
        icon = <Icon size={14} name="arrowGrowth" style={{ transform: "scaleY(-1)" }} />;
    } else if (v.greaterThan(0)) {
        variant = flipSign === true ? "red" : "green";
        icon = <Icon size={14} name="arrowGrowth" />;
    }
    return (
        <Pill variant={variant}>
            <Currency value={v} />
            {icon}
        </Pill>
    );
};

interface PercentagePillProps {
    value: number | Decimal;
    flipSign?: boolean;
}
export const PercentagePill = ({ value, flipSign }: PercentagePillProps) => {
    let variant: PillProps["variant"] = "grey";
    let icon: React.ReactNode = null;
    let prefix: React.ReactNode = null;
    let v = new Decimal(value);
    if (v.lessThan(0)) {
        variant = flipSign === true ? "green" : "red";
        icon = <Icon size={14} name="arrowGrowth" style={{ transform: "scaleY(-1)" }} />;
        if (v.lessThan(-9999.9)) {
            v = new Decimal(-9999.9);
            prefix = <>&gt; </>;
        }
    } else if (v.greaterThan(0)) {
        variant = flipSign === true ? "red" : "green";
        icon = <Icon size={14} name="arrowGrowth" />;
        if (v.greaterThan(9999.9)) {
            v = new Decimal(9999.9);
            prefix = <>&gt; </>;
        }
    }
    return (
        <Pill variant={variant}>
            {prefix}
            <Percentage value={v} />
            {icon}
        </Pill>
    );
};

const PillRoot = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 2px 6px;
    border-radius: 100px;
    border: 1px solid transparent;
    font-size: 10.5px;
    font-style: normal;
    font-weight: bold;
    line-height: 1.43; // same as body defined by Mui CssBaseline

    &[data-variant="red"] {
        border-color: #f9ecec;
        background: #fff8f8;
        color: #d05054;
    }
    &[data-variant="green"] {
        border-color: #e6f1e6;
        background: #f3fcf2;
        color: #47a85a;
    }
    &[data-variant="blue"] {
        border-color: #cfd9f4;
        background: #e6edfa;
        color: #5060ec;
    }
    &[data-variant="cyan"] {
        border-color: #d0f3ef;
        background: #edffff;
        color: #00a5a5;
    }
    &[data-variant="magenta"] {
        border-color: #ffdbff;
        background: #fff0ff;
        color: #e26fe2;
    }
    &[data-variant="yellow"] {
        border-color: #fdf28e;
        background: #ffffdd;
        color: #bb9b38;
    }
    &[data-variant="orange"] {
        border-color: #ffcb97;
        background: #ffead4;
        color: #ff8000;
    }
    &[data-variant="purple"] {
        border-color: #ebc7e8;
        background: #f6e6f3;
        color: #bb3ea1;
    }
    &[data-variant="brown"] {
        border-color: #edd6b2;
        background: #ffe7bc;
        color: #954723;
    }
    &[data-variant="grey"] {
        border-color: #e9e9e9;
        background: #f4f4f4;
        color: #6f6f6f;
    }
`;

export type PillVariant =
    | "red"
    | "green"
    | "blue"
    | "cyan"
    | "magenta"
    | "yellow"
    | "orange"
    | "purple"
    | "brown"
    | "grey";
interface PillProps {
    variant: PillVariant;
    style?: React.CSSProperties;
    children: React.ReactNode;
}
export const Pill = React.forwardRef(function Pill(
    { variant, children, ...props }: PillProps,
    ref: React.Ref<HTMLDivElement>,
) {
    return (
        <PillRoot data-variant={variant} ref={ref} {...props}>
            {children}
        </PillRoot>
    );
});
