import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { debug } from "../../../util/debug";
import { Routes } from "../../app/router/Routes";
import { TicketsRouter } from "../router/TicketsRouter";

export const TicketsContainerSite = observer(function TicketsContainerSite() {
    // Wait until roles are loaded
    if (!authStore.userInfo) {
        return null;
    }

    // Wait until companies are loaded
    if (!companiesStore.initialized) {
        return null;
    }

    // Not an advisor -> go back to cockpit
    const isTpa = authStore.isTpa;
    if (!isTpa) {
        debug.log("### not tpa employee -> tickets not allowed");
        return <Redirect to={Routes.COCKPIT} />;
    }

    return <TicketsRouter />;
});
