import { observer } from "mobx-react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { authStore } from "../../../stores/AuthStore";
import { coordinator, getInitialRoute } from "../../../stores/Coordinator";

export const NoAuthOnlyRoute = observer(function NoAuthOnlyRoute({ component: Component, ...props }: RouteProps) {
    if (!coordinator.isRehydrated) {
        return null;
    }

    if (authStore.isAuthenticated) {
        if (!authStore.userInfo) {
            // Need userInfo for permissions (e.g. if advisor or not)
            return null;
        }

        if (!coordinator.isInitialized) {
            // Not everything is loaded -> cannot determine initial route yet
            return null;
        }
    }

    return (
        <Route
            {...props}
            render={props =>
                !authStore.isAuthenticated ? (
                    Component && <Component {...props} />
                ) : (
                    <>
                        {!authStore.redirectAfterLogin && (
                            <Redirect
                                to={{
                                    pathname: getInitialRoute(),
                                    state: { from: props.location },
                                }}
                            />
                        )}
                    </>
                )
            }
        />
    );
});
