import Divider from "@material-ui/core/Divider";
import { observer } from "mobx-react";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { AdvisorRoutes } from "../../advisor/router/AdvisorRoutes";
import { Routes } from "../../app/router/Routes";
import { SideBarItems } from "./SideBarItems";

export const AdvisorDivider = ({ title }: { title: string }) => (
    <div
        style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 30,
            flexShrink: 0,
        }}
    >
        <Divider style={{ position: "absolute", width: "100%" }} />
        <div className="caption" style={{ position: "absolute", backgroundColor: "white", padding: 4 }}>
            {title}
        </div>
    </div>
);

export const AdvisorSideBarItems = observer(function AdvisorSideBarItems(props: { onClickItem?: () => void }) {
    const badgeCounts = companiesStore.selectedCompanyStore?.badgeCounts;
    return (
        <SideBarItems
            items={[
                {
                    iconName: "rueckfrage",
                    title: t("sidebar.list.tickets"),
                    target: Routes.TICKETS,
                    badgeCount: badgeCounts?.tickets,
                    enabled: GLOBAL_FEATURES.tickets && authStore.canSeeTpaTicketsSidebar,
                },
                {
                    iconName: "upload",
                    title: t("sidebar.advisor.uploads"),
                    target: AdvisorRoutes.UPLOADS.ROOT,
                    enabled: GLOBAL_FEATURES.upload,
                },
                {
                    iconName: "mail",
                    title: t("sidebar.list.faceToFace"),
                    target: Routes.SUPPORT,
                    badgeCount: badgeCounts?.unreadChatMessages,
                    enabled: GLOBAL_FEATURES.chat,
                },
                {
                    iconName: "projects",
                    title: t("sidebar.list.projects"),
                    target: Routes.PROJECTS,
                    enabled: GLOBAL_FEATURES.projects && authStore.canReadProjects,
                    badgeCount: (badgeCounts?.openProjectDrafts ?? 0) + (badgeCounts?.projectDraftTickets ?? 0),
                },
                {
                    iconName: "settings",
                    title: t("sidebar.list.settings"),
                    target: Routes.SETTINGS,
                },
            ]}
            onClickItem={props.onClickItem}
        />
    );
});
