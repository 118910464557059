import { IconButton, ListItem, ListItemText, Tooltip, withStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import * as React from "react";
import { t } from "../../i18n/util";
import { useConfirmationDialog } from "../hooks/useConfirmationDialog";
import { Icon } from "../util/Icon";

interface MenuItem {
    name: string;
    url: string;
}

interface OptionalMenuPointProps {
    item: { name: string; url: string };
    onDeleteItem: (item: MenuItem) => void;
    onSortUp: () => void;
    onSortDown: () => void;
    isFirst: boolean;
    isLast: boolean;
}

export const OptionalMenuItem = ({
    item,
    onDeleteItem,
    onSortUp,
    onSortDown,
    isFirst,
    isLast,
}: OptionalMenuPointProps) => {
    const [itemToDelete, setItemToDelete] = React.useState<MenuItem>();
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const [isHovered, setIsHovered] = React.useState(false);
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (ref.current && isHovered) {
            setIsOverflowing(ref.current.offsetWidth < ref.current.scrollWidth);
        }
    }, [isHovered]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const deleteItemDialog = useConfirmationDialog({
        message: t("config.customMenuPoints.deleteCustomMenuPoint.message"),
        confirmLabel: t("config.customMenuPoints.deleteCustomMenuPoint.confirmLabel"),
        onConfirm: () => {
            if (itemToDelete) {
                onDeleteItem(itemToDelete);
            }
        },
        title: t("config.customMenuPoints.deleteCustomMenuPoint.title"),
    });

    // removed hover state on sort button since it appears glitchy when sorting up/down
    const SortButton = withStyles({
        root: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
    })(IconButton);

    return (
        <ListItem key={item.name} divider disableGutters style={{ justifyContent: "space-between" }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                <ListItemText primary={item.name} />
                <Tooltip title={isOverflowing ? item.url : ""}>
                    <p
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            fontWeight: "bold",
                        }}
                        ref={ref}
                    >
                        {item.url}
                    </p>
                </Tooltip>
            </div>
            <div
                style={{
                    display: "flex",
                }}
            >
                <SortButton
                    aria-label="sortDown"
                    disabled={isLast}
                    onClick={() => {
                        onSortDown();
                    }}
                    disableRipple
                >
                    <KeyboardArrowDownIcon />
                </SortButton>
                <SortButton
                    aria-label="sortUp"
                    disabled={isFirst}
                    onClick={() => {
                        onSortUp();
                    }}
                    disableRipple
                >
                    <KeyboardArrowUpIcon />
                </SortButton>
                <Tooltip title={t("company.optionalSideBarMenuItems.tooltip.removeItem")} placement="top">
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                            setItemToDelete(item);
                            deleteItemDialog.open();
                        }}
                    >
                        <Icon name="delete" />
                    </IconButton>
                </Tooltip>
            </div>
            {deleteItemDialog.dialog}
        </ListItem>
    );
};
