import { Redirect } from "react-router";
import { authStore } from "../../../stores/AuthStore";
import { Routes } from "../../app/router/Routes";
import { AdvisorRouter } from "../router/AdvisorRouter";

export const AdvisorContainerSite = () => {
    if (!authStore.userInfo) {
        return null;
    }

    if (!authStore.isTpa) {
        return <Redirect to={Routes.ROOT} />;
    }

    return <AdvisorRouter />;
};
