import { MenuItem, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { PaySlipType } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { useCompanySubsidiaries } from "../../hooks/useSubsidiaries";
import { useTransferSuccessDialog } from "../../hooks/useTransferSuccessDialog";
import { IAcceptedFile, useDocumentUploadSmall } from "../../ui/DocumentUploadSmall";
import { ConfirmWebhook } from "../../webhooks/ConfirmWebhook";
import { HrRoutes } from "../router/HrRoutes";

export type FileWithMetaData = IAcceptedFile<{ employeeId?: string; visibleToEmployee?: boolean }>;

export const usePayslipUploadConfig = (options: { companyId?: string; subsidiaryId?: string }) => {
    const [notifyCustomer, setNotifyCustomer] = React.useState(true);
    const [currentCompanyId, setCurrentCompanyId] = React.useState<string>();
    const [subsidiaryId, setSubsidiaryId] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [payslipType, setPayslipType] = React.useState<PaySlipType>("month");
    const [payslipDate, setPayslipDate] = React.useState<moment.Moment>(moment());
    const [showEmployeeMissingError, setShowEmployeeMissingError] = React.useState(false);

    const { subsidiaries, reset: resetSubsidiaries } = useCompanySubsidiaries({
        companyId: options.companyId,
        module: "hr",
    });

    // Reset subsidiaries on company change (should not happen)
    React.useEffect(() => {
        if (currentCompanyId !== options.companyId) {
            resetSubsidiaries();
            setCurrentCompanyId(options.companyId);
        }
    }, [currentCompanyId, options.companyId, resetSubsidiaries]);

    // Preselect subsidiary
    React.useEffect(() => {
        // Current subsidiary doesn't exist in new array -> set current to option or first subsidiary
        if (subsidiaries.length > 0 && (!subsidiaryId || !subsidiaries.find(s => s.id === subsidiaryId))) {
            if (options.subsidiaryId && subsidiaries.find(s => s.id === options.subsidiaryId)) {
                setSubsidiaryId(options.subsidiaryId);
            } else {
                setSubsidiaryId(subsidiaries[0].id);
            }
        }
    }, [options.subsidiaryId, subsidiaries, subsidiaryId]);

    const payslipTypeToLabel: Record<PaySlipType, string> = {
        month: t("hr.employee.payslip.upload.type.monthly"),
        year: t("hr.employee.payslip.upload.type.yearly"),
    };

    const uploadFile = async (file: FileWithMetaData) => {
        if (!options.companyId || !options.subsidiaryId || !file.metaData?.employeeId) {
            generalStore.setError(t("error.upload"));
            return;
        }
        setIsLoading(true);
        try {
            return await API.uploadEmployeePayslip({
                companyId: options.companyId,
                subsidiaryId: options.subsidiaryId,
                employeeId: file.metaData.employeeId,
                file: file.file,
                year: payslipDate.year(),
                month: payslipDate.month() + 1,
                payslipType: payslipType,
                sendEmail: notifyCustomer,
            });
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.response.type === "EMPLOYEE_EMAIL_MISSING") {
                generalStore.setError(t("error.employeeWithoutEmail"), error);
            } else {
                generalStore.setError(t("error.upload"), error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const documentUploadSmallConfig = useDocumentUploadSmall(uploadFile, true);

    const successDialog = useTransferSuccessDialog(t("dialog.transferReports.success.message"), () => {
        pushRoute(HrRoutes.EMPLOYEES.CURRENT);
    });

    const handleSubmit = async () => {
        if (!documentUploadSmallConfig.acceptedFiles.every(file => file.metaData?.employeeId)) {
            setShowEmployeeMissingError(true);
            return;
        }
        const success = await documentUploadSmallConfig.uploadFiles(documentUploadSmallConfig.acceptedFiles);
        if (success) {
            successDialog.openDialog();
        }
    };

    const payslipTypes: PaySlipType[] = ["month", "year"];
    const configComponent = (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {subsidiaries.length > 0 && (
                <TextField
                    value={subsidiaryId}
                    name="subsidiary"
                    data-id="upload_subsidiary"
                    label={t("common.hr.subsidiary")}
                    variant="outlined"
                    select
                    style={{ marginBottom: 18 }}
                    onChange={event => {
                        setSubsidiaryId(event.target.value);
                    }}
                >
                    {subsidiaries.map(subsidiary => (
                        <MenuItem key={subsidiary.id} value={subsidiary.id}>
                            {subsidiary.name}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            <TextField
                value={payslipType}
                name="payslipType"
                data-id="pay_slip_type"
                label={t("hr.employee.payslip.upload.type")}
                variant="outlined"
                select
                style={{ marginBottom: 18 }}
                onChange={event => {
                    setPayslipType(event.target.value as PaySlipType);
                }}
            >
                {payslipTypes.map(type => (
                    <MenuItem key={type} value={type}>
                        {payslipTypeToLabel[type]}
                    </MenuItem>
                ))}
            </TextField>
            <KeyboardDatePicker
                label={t("hr.employee.payslip.upload.date")}
                value={payslipDate}
                onChange={e => {
                    setPayslipDate(moment(e?.toDate()));
                }}
                disableToolbar
                openTo="year"
                inputVariant="outlined"
                views={payslipType === "month" ? ["year", "month"] : ["year"]}
                PopoverProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: 240,
                    },
                }}
                minDate={moment().subtract(2, "years")}
                maxDate={moment().add(2, "years")}
            />
        </div>
    );

    const confirmComponent = (
        <>
            <ConfirmWebhook
                onSubmit={handleSubmit}
                notifyCustomer={notifyCustomer}
                setNotifyCustomer={setNotifyCustomer}
                disabled={isLoading || !subsidiaryId || documentUploadSmallConfig.acceptedFiles.length === 0}
                buttonLabel={t("common.uploadFiles")}
            />
            {successDialog.component}
        </>
    );

    return {
        configComponent,
        confirmComponent,
        documentUploadSmallConfig,
        subsidiaryId,
        showEmployeeMissingError,
    };
};
