import { Button } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { generalStore } from "../../stores/GeneralStore";
import { IRefreshOrInvitation } from "../../types/models";
import { getCountryName } from "../../util/helpers";
import { MSCloseRefreshState } from "../auth/sites/AuthMSCloseSite";
import { useCrossTabState } from "../hooks/useCrossTabState";
import { useFeedCountries } from "../hooks/useFeedCountries";
import { MicrosoftUpgradeDialog, useMicrosoftUpgradeDialog } from "../hooks/useMicrosoftUpgradeDialog";
import { usePendingMicrosoftActions } from "../hooks/usePendingMicrosoftActions";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { CustomDialog, CustomDialogContent } from "./CustomDialog";
import { NavBarInfo, NavBarInfoButton, NavBarInfoMessage } from "./NavBarInfo";
import { TpaWhiteButton } from "./Primitives";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";

const CountriesDialog = ({
    isRefresh,
    open,
    onCancel,
    invitations,
    upgradeDialog,
}: {
    isRefresh: boolean;
    open: boolean;
    onCancel: () => void;
    invitations: IRefreshOrInvitation[];
    upgradeDialog: MicrosoftUpgradeDialog;
}) => {
    const feedCountries = useFeedCountries();

    const handleAction = async (invitation: IRefreshOrInvitation) => {
        if (isRefresh) {
            await authStore.refreshMSToken(invitation.countryCode);
        } else {
            if (!invitation.companyId) {
                generalStore.setError(t("error.invitationNoCompany"));
                return;
            }

            if (!authStore.userInfo?.has_microsoft_login) {
                upgradeDialog.openDialog(invitation);
            } else {
                await authStore.acceptMSInvite({
                    companyId: invitation.companyId,
                    international: invitation.isInternational,
                });
            }
        }
    };

    return (
        <>
            <CustomDialog open={open}>
                <CustomDialogContent>
                    <h1>{t(isRefresh ? "projects.refreshDialog.title" : "projects.international.invite.title")}</h1>
                    <p style={{ marginTop: 24 }}>
                        {t(isRefresh ? "projects.refreshDialog.message" : "projects.international.invite.message")}
                    </p>
                    <div style={{ marginTop: 24 }}>
                        {invitations.map(invitation => {
                            const countryCode = invitation.countryCode;
                            const country = feedCountries.getCountryByCode(countryCode);
                            return (
                                <div
                                    key={countryCode}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        borderBottom: `1px solid ${customColors.greyLight}`,
                                        height: 56,
                                    }}
                                >
                                    <img
                                        src={country?.iconUrl}
                                        style={{ width: 24, height: 24 }}
                                        alt={`flag of ${getCountryName(countryCode)}`}
                                    />
                                    <span style={{ marginLeft: 16, flex: 1 }}>{getCountryName(countryCode)}</span>
                                    <Button
                                        color="primary"
                                        style={{ textTransform: "uppercase" }}
                                        onClick={() => handleAction(invitation)}
                                    >
                                        {t(isRefresh ? "button.refresh" : "button.unlock")}
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                    <ResponsiveButtonContainer style={{ marginTop: 24 }}>
                        <TpaWhiteButton onClick={onCancel}>{t("common.cancel")}</TpaWhiteButton>
                    </ResponsiveButtonContainer>
                </CustomDialogContent>
            </CustomDialog>
        </>
    );
};

export const PendingMicrosoftActionsBar = () => {
    const { isLoading, pendingActions, reload } = usePendingMicrosoftActions();
    const [countriesDialogOpen, setCountriesDialogOpen] = React.useState(false);
    const upgradeDialog = useMicrosoftUpgradeDialog();

    const [refresh, setRefresh] = useCrossTabState<MSCloseRefreshState>("refreshPendingMicrosoftActionsBar", "waiting");

    // Reload when token was refreshed
    React.useEffect(() => {
        if (refresh === "refresh") {
            reload();
            setRefresh("waiting");
        }
    }, [refresh, reload, setRefresh]);

    // No token refresh needed and no invitations pending -> get out
    if (isLoading || (pendingActions.staleCountries.length === 0 && pendingActions.pendingInvitations.length === 0)) {
        return null;
    }

    let isRefresh = false;
    let isInternationalInvite = false;
    let message = "";
    let invitations: IRefreshOrInvitation[] = [];
    if (pendingActions.staleCountries.length > 0) {
        // Refresh are shown first
        invitations = pendingActions.staleCountries.map(countryCode => ({ countryCode }));

        isRefresh = true;

        message =
            pendingActions.staleCountries.length === 1
                ? t("projects.refresh.message.singular", {
                      country: getCountryName(pendingActions.staleCountries[0]),
                  })
                : t("projects.refresh.message", { numCountries: pendingActions.staleCountries.length });
    } else if (pendingActions.pendingInvitations.length > 0) {
        invitations = pendingActions.pendingInvitations;

        const internationalInvitePending = invitations.some(
            invitation => invitation.isInternational && !!invitation.companyId,
        );

        if (internationalInvitePending) {
            // Then international invites
            message = t("projects.invite.international");
            isInternationalInvite = true;
        } else {
            // Then local invites
            message =
                pendingActions.pendingInvitations.length === 1
                    ? t("projects.invite.message.singular", {
                          country: getCountryName(pendingActions.pendingInvitations[0].countryCode),
                      })
                    : t("projects.invite.message", { numCountries: pendingActions.pendingInvitations.length });
        }
    }

    const buttonMessage = t(isRefresh ? "button.refresh" : "button.unlock");

    const handleRefresh = async () => {
        // Attention: Do NOT rewrite this to if (isInternationalInvite) { ... } else { ... } because order is important!
        // Refreshes come first, then international invites, then local invites
        if (isRefresh) {
            setCountriesDialogOpen(true);
        } else if (isInternationalInvite) {
            const invitation = invitations.find(invitation => invitation.isInternational);
            if (invitation) {
                if (!authStore.userInfo?.has_microsoft_login) {
                    upgradeDialog.openDialog(invitation);
                } else {
                    await authStore.acceptMSInvite({
                        companyId: invitation.companyId,
                        international: true,
                    });
                }
            }
        } else {
            setCountriesDialogOpen(true);
        }
    };

    return (
        <>
            <NavBarInfo>
                <div style={{ marginRight: 16 }}>
                    <Icon name="internationalProject" style={{ display: "block", color: "white" }} />
                </div>
                <NavBarInfoMessage>{message}</NavBarInfoMessage>
                <NavBarInfoButton onClick={handleRefresh}>{buttonMessage}</NavBarInfoButton>
            </NavBarInfo>
            <CountriesDialog
                isRefresh={isRefresh}
                open={countriesDialogOpen}
                onCancel={() => {
                    setCountriesDialogOpen(false);
                }}
                invitations={invitations}
                upgradeDialog={upgradeDialog}
            />
            {upgradeDialog.component}
        </>
    );
};
