import { LocationDescriptor } from "history";
import { observer } from "mobx-react";
import * as React from "react";
import Headroom from "react-headroom";
import { NAVBAR_HEIGHT } from "../../config";
import { SIDEBAR_WIDTH_OPEN, sideBarStore } from "../../stores/SideBarStore";
import { debug } from "../../util/debug";
import { Icon } from "../util/Icon";
import { CONTENT_PADDING_HORIZONTAL, customColors, theme } from "../util/Theme";
import { CenteredContent } from "./CenteredContent";
import { NavBarInfo } from "./NavBarInfo";
import { RouterLink, VCenter } from "./Primitives";
import { MobileMenuButton } from "./mobileMenu/MobileMenuButton";

interface IProps {
    children: React.ReactNode;
    backLabel?: string;
    backTarget?: string | LocationDescriptor;
    hideBorder?: boolean;
    navBarInfo?: React.ReactNode;
    ignoreSideBar?: boolean;
    onBack?: () => void;
}

interface IMobileProps {
    tabs?: React.ReactNode;
}

export const BackButton = (props: { backLabel?: string; backTarget?: IProps["backTarget"]; onBack?: () => void }) => {
    const content = (
        <>
            <Icon name="arrowLeft" />
            <p className="caption" style={{ marginLeft: 16, color: theme.palette.text.secondary }}>
                {props.backLabel}
            </p>
        </>
    );

    if (props.backTarget && props.onBack) {
        debug.error("#### Both backTarget and onBack are provided this shouldn't happen. onBack will be executed!");
    }

    return (
        <div style={{ height: 24 }}>
            {props.onBack && (
                <div
                    style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    onClick={props.onBack}
                    role="button"
                >
                    {content}
                </div>
            )}
            {props.backTarget && !props.onBack && (
                <RouterLink
                    style={{ display: "flex", alignItems: "center" }}
                    to={props.backTarget}
                    data-id="back_button"
                >
                    {content}
                </RouterLink>
            )}
        </div>
    );
};

export const NavBarTitle = (props: { children: React.ReactNode; style?: React.CSSProperties }) => (
    <h2
        style={{
            flexGrow: 1,
            marginRight: 16,
            wordBreak: "break-word",
            hyphens: "auto",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            ...props.style,
        }}
    >
        {props.children}
    </h2>
);

const NavBarFrame = (props: {
    children: React.ReactNode;
    style?: React.CSSProperties;
    hideBorder?: boolean;
    navBarInfo?: React.ReactNode;
}) => (
    <>
        <div
            className="mui-fixed"
            style={{
                width: "100%",
                background: customColors.white,
                borderBottom: props.hideBorder ? `none` : `1px solid ${customColors.greyLight}`,
                position: "fixed",
                display: "flex",
                height: NAVBAR_HEIGHT,
                alignItems: "center",
                zIndex: 100,
                ...props.style,
            }}
        >
            {props.children}
        </div>
        <div style={{ height: NAVBAR_HEIGHT, flexShrink: 0 }} />
        {props.navBarInfo && <NavBarInfo fixed>{props.navBarInfo}</NavBarInfo>}
    </>
);

// Navbar where child elements only take the centered content area of the browser window
export const NavBarContainer = (props: IProps) => {
    return (
        <NavBarFrame hideBorder={props.hideBorder} navBarInfo={props.navBarInfo}>
            <CenteredContent ignoreSideBar={props.ignoreSideBar}>
                <div style={{ padding: `0 ${CONTENT_PADDING_HORIZONTAL}px` }}>
                    <div style={{ display: "flex" }}>
                        <BackButton backLabel={props.backLabel} backTarget={props.backTarget} onBack={props.onBack} />
                        <div style={{ flexGrow: 1 }} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            minHeight: 44, // == height of the "cancel" button - avoids jumpy layout
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            </CenteredContent>
        </NavBarFrame>
    );
};

// Navbar where child elements only take the centered content area of the browser window
export const NavBarCenteredLogoOnly = (props: IProps) => {
    return (
        <NavBarFrame hideBorder={props.hideBorder} navBarInfo={props.navBarInfo}>
            <VCenter style={{ alignItems: "center" }}>{props.children}</VCenter>
        </NavBarFrame>
    );
};

// Navbar where child elements take full width of browser window
export const FullWidthNavBarContainer = (props: IProps) => {
    const [leftWidth, setLeftWidth] = React.useState(SIDEBAR_WIDTH_OPEN);
    const resize = (width: number) => {
        setLeftWidth(width);
    };

    return (
        <NavBarFrame hideBorder={props.hideBorder} navBarInfo={props.navBarInfo}>
            {/* CenteredContent is only used for measuring */}
            <CenteredContent getLeftWidth={resize} />

            <div style={{ position: "absolute", left: leftWidth, width: `calc(100vw - ${leftWidth}px` }}>
                <div style={{ padding: `0 ${CONTENT_PADDING_HORIZONTAL}px` }}>
                    <BackButton backLabel={props.backLabel} backTarget={props.backTarget} onBack={props.onBack} />
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            minHeight: 44, // == height of the "cancel" button - avoids jumpy layout
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </NavBarFrame>
    );
};

export const MobileNavBarContainer = observer(function MobileNavBarContainer(props: IProps & IMobileProps) {
    const hasBackButton = !!props.backLabel || !!props.backTarget || !!props.onBack;

    return (
        <Headroom style={{ zIndex: 2 }}>
            <div style={{ backgroundColor: customColors.white, borderBottom: `1px solid ${customColors.greyLight}` }}>
                <div style={{ padding: 16, paddingTop: 11 }}>
                    {!sideBarStore.sideBarHidden && (
                        <div style={{ marginBottom: hasBackButton ? 8 : undefined }}>
                            <MobileMenuButton data-id="mobile_menu_button" />
                        </div>
                    )}
                    <div style={{ display: "inline-flex", marginBottom: 8 }}>
                        {hasBackButton && (
                            <BackButton
                                backLabel={props.backLabel}
                                backTarget={props.backTarget}
                                onBack={props.onBack}
                            />
                        )}
                    </div>
                    {props.children}
                </div>
                {props.navBarInfo && <NavBarInfo>{props.navBarInfo}</NavBarInfo>}
                {props.tabs && <div style={{ paddingLeft: 16, paddingRight: 16 }}>{props.tabs}</div>}
            </div>
        </Headroom>
    );
});
