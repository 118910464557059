import { Button, Divider, IconButton } from "@material-ui/core";
import { useState } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Account as AccountType, BankAccount } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { formatDateTime } from "../../util/helpers";
import { pushRoute } from "../app/router/history";
import { CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle } from "../ui/CustomDialog";
import { FormattedCurrency, TpaWhiteButton } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { Account } from "./Account";
import { accountingStore } from "./AccountingStore";
import { AccountingRoutes } from "./router/AccountingRoutes";

export const BankConnectionAccountListItem = ({ bankAccount }: { bankAccount: BankAccount }) => {
    const handleClick = () => {
        pushRoute(AccountingRoutes.BANKING.CONNECTIONS.ACCOUNTS.TRANSACTIONS, {
            params: {
                bankConnectionId: bankAccount.bankConnectionId,
                bankAccountId: bankAccount.accountId,
            },
        });
    };

    const accountNumberComponents = useAccount(bankAccount);

    return (
        <div>
            <Divider />
            <div style={{ display: "flex", cursor: "pointer" }} onClick={handleClick}>
                <div style={{ padding: 8 }}>
                    <b>{bankAccount.name}</b>
                    <p>
                        <b>{t("accounting.bankConnections.accountHolder")}</b> {bankAccount.accountHolderName}
                    </p>
                    <p>
                        <b>{t("accounting.bankConnections.iban")}</b> {bankAccount.iban}
                        {bankAccount.accountType && (
                            <>
                                {" | "}
                                <b>{t("accounting.bankConnections.accountType")}</b>{" "}
                                {t(`accounting.bankConnections.accountType.${bankAccount.accountType}`)}
                            </>
                        )}
                        {bankAccount.balance && (
                            <>
                                {" | "}
                                <b>{t("accounting.bankConnections.balance")}</b>{" "}
                                <FormattedCurrency value={bankAccount.balance} currency={bankAccount.currency} />
                            </>
                        )}
                        {bankAccount.lastSuccessfulUpdate && (
                            <span>
                                {" | "}
                                {t("accounting.bankConnections.lastSuccessfulUpdate", {
                                    date: formatDateTime(bankAccount.lastSuccessfulUpdate),
                                })}
                            </span>
                        )}
                    </p>
                    <p>{accountNumberComponents}</p>
                </div>
            </div>
        </div>
    );
};

function useAccount(bankAccount: BankAccount) {
    const companyId = companiesStore.selectedCompanyId;

    const [account, setAccount] = useState<AccountType | null>(null);

    const [open, setOpen] = useState(false);
    const handleOpen = (ev: React.MouseEvent) => {
        ev.stopPropagation();
        setAccount(bankAccount.account ?? null);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setAccount(null);
    };
    const handleSubmit = async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            await API.putBankAccountAccountNumber(companyId, bankAccount.bankConnectionId, bankAccount.accountId, {
                accountNumber: account?.number.toString() ?? "",
            });
            bankAccount.account = account ?? undefined;
            handleClose();
        } catch (error) {
            generalStore.setError(t("error.updateBankAccountAccountNumber"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const canEditAccountNumber = authStore.canEditBankAccountAccountNumber();
    if (!canEditAccountNumber) {
        return null;
    }

    return (
        <>
            <b>{t("accounting.bankAccount.account.label")}: </b>
            {bankAccount.account
                ? `${bankAccount.account.name} (${bankAccount.account.number})`
                : t("common.notSpecified")}
            <IconButton size="small" onClick={handleOpen} style={{ marginLeft: 4, padding: 2 }}>
                <Icon name="pen" size={18} />
            </IconButton>
            <CustomDialog open={open} onClose={handleClose}>
                <CustomDialogContent>
                    <CustomDialogTitle>{t("accounting.bankAccount.account.title")}</CustomDialogTitle>
                    <Account
                        value={account}
                        onChange={setAccount}
                        companyId={companyId}
                        subsidiaryId={accountingStore.selectedSubsidiaryId}
                        placeholder="accounting.bankAccount.account.placeholder"
                    />
                    <CustomDialogActions>
                        <TpaWhiteButton onClick={handleClose}>{t("common.cancel")}</TpaWhiteButton>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            {t("common.apply")}
                        </Button>
                    </CustomDialogActions>
                </CustomDialogContent>
            </CustomDialog>
        </>
    );
}
