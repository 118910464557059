import { observer } from "mobx-react";
import * as React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../../i18n/util";
import { Message } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { fixUrlsInMessage } from "../../util/helpers";
import { useArchiveMessages } from "../hooks/useArchiveMessages";
import { FileRejections } from "../ui/FailedUploadDialog";
import { TableRowButton } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { ChatButtons } from "./ChatButtons";
import { useChatDropzone } from "./ChatDropzone";
import { ChatInput } from "./ChatInput";
import { ChatInteractionContainer } from "./ChatInteractionContainer";
import { ChatMessage } from "./ChatMessage";

const CHAT_CONTAINER_PADDING = 32;

export interface IChatContext {
    companyId?: string;
    userId: string;
    colleagueId?: string;
}

export interface ITicketContext {
    companyId: string;
    ticketId: string;
}

const PrivacyNotice = ({ onTogglePrivate }: { onTogglePrivate?: () => void }) => {
    const isMobile = React.useContext(MobileContext);
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: customColors.primaryShade,
                padding: "8px 16px",
                marginLeft: isMobile ? 0 : -CHAT_CONTAINER_PADDING,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Icon name="locked" style={{ marginRight: 10, color: customColors.primaryColor }} />
                <p className="body2">
                    <FormattedHTMLMessage
                        id={isMobile ? "support.privateChat.info.mobile" : "support.privateChat.info"}
                    />
                </p>
            </div>
            {onTogglePrivate && (
                <div>
                    <TableRowButton
                        style={{ marginTop: 4 }}
                        color="primary"
                        onClick={onTogglePrivate}
                        data-id="mark_as_public"
                    >
                        {t("support.privateChat.info.markAsPublic")}
                    </TableRowButton>
                </div>
            )}
        </div>
    );
};

const ChatContainer = observer(function ChatContainer({
    children,
    style,
    ...props
}: {
    children: React.ReactNode;
    style?: React.CSSProperties;
}) {
    const isMobile = React.useContext(MobileContext);
    return (
        <div
            style={{
                background: "white",
                height: isMobile ? "60vh" : `calc(100vh - var(--navBarHeight))`,
                display: "flex",
                flexDirection: "column",
                paddingLeft: CHAT_CONTAINER_PADDING,
                ...style,
            }}
            {...props}
        >
            {children}
        </div>
    );
});

const ChatMessageContainer = ({
    loadOlder,
    loadNewer,
    latestMessageId,
    children,
    style,
}: {
    loadOlder?: () => Promise<void>;
    loadNewer?: () => Promise<void>;
    latestMessageId: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
}) => {
    const bottomRef = React.useRef<HTMLDivElement | null>(null);
    const topRef = React.useRef<HTMLDivElement | null>(null);
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const latestMessageIdRef = React.useRef<string>(latestMessageId);
    const [isLoading, setIsLoading] = React.useState(false);

    const isMobile = React.useContext(MobileContext);

    const loadNewerMessages = React.useCallback(async () => {
        if (loadNewer) {
            setIsLoading(true);

            // Remember total height before loading newer messages
            const heightBefore = containerRef.current?.scrollHeight ?? 0;

            await loadNewer();

            // Now take the delta after loading the newer messages
            const heightAfter = containerRef.current?.scrollHeight ?? heightBefore;
            const delta = heightAfter - heightBefore;

            // Scroll last seen message back into view
            if (containerRef.current && delta > 0) {
                containerRef.current.scrollTop = -delta;
            }

            setIsLoading(false);
        }
    }, [loadNewer]);

    const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
        // How many pixel until we trigger a new load?
        const LOAD_THRESHOLD_PIXEL = 50;

        const target = event.currentTarget;
        const rect = target.getBoundingClientRect();

        const topDistance = target.scrollHeight + target.scrollTop - rect.height;

        if (topDistance < LOAD_THRESHOLD_PIXEL && !isLoading && loadOlder) {
            setIsLoading(true);
            await loadOlder();
            setIsLoading(false);
        }

        const bottomDistance = Math.abs(target.scrollTop);
        if (bottomDistance < LOAD_THRESHOLD_PIXEL && !isLoading && loadNewer) {
            await loadNewerMessages();
        }
    };

    const scrollToBottom = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView();
        }
    };

    // If latest message changes or we switch between mobile/desktop -> scroll to bottom
    React.useEffect(() => {
        scrollToBottom();
    }, [latestMessageId, isMobile]);

    // When we have received a latestMessageId this means that we have rendered the messages
    // Trigger a scroll by one pixel to load newer messages if available
    React.useEffect(() => {
        const loadNewerIfAvailable = async () => {
            if (!latestMessageIdRef.current && latestMessageId && containerRef.current) {
                latestMessageIdRef.current = latestMessageId;
                await loadNewerMessages();
            }
        };

        loadNewerIfAvailable();
    }, [latestMessageId, loadNewerMessages]);

    return (
        // direction is column-reverse because we display the messages bottom up
        // that's why bottomRef comes first, then messages, then topRef
        <div
            style={{ overflow: "auto", display: "flex", flexDirection: "column-reverse", flexGrow: 1, ...style }}
            onScroll={handleScroll}
            ref={containerRef}
        >
            <div ref={bottomRef} />
            {children}
            <div ref={topRef} style={{ flexGrow: 1 }} />
        </div>
    );
};

// This is a reusable chat component, so all functionality regarding e.g. tickets should be
// done elsewhere. E.g. onCloseTicket is passed as prop.
// This is done, in preparation to reuse Chat for face2face support chats. So messages are
// also passed as props and not directly read from a ticket id.
export const Chat = observer(function Chat({
    onRelease,
    onAssignTicket,
    onTogglePrivate,
    isPrivateChat,
    onCloseTicket,
    onSend,
    disableUpload,
    messages,
    loadOlder,
    loadNewer,
    disableInput,
    mobileFullScreen,
    initialInputMessage,
    onChangeInputMessage,
    onArchiveSelection,
    isActiveChat = true,
    onMessageDownload,
}: {
    // used for releasing reports
    onRelease?: () => void;

    // used for tickets
    onAssignTicket?: () => void;
    onCloseTicket?: () => void;

    // Private chats
    onTogglePrivate?: () => void;
    isPrivateChat?: boolean;

    // general chat handling
    onSend?: (message?: string, file?: File) => Promise<boolean>;

    disableUpload?: boolean;
    messages?: Message[];
    loadOlder?: () => Promise<void>;
    loadNewer?: () => Promise<void>;
    disableInput?: boolean;
    mobileFullScreen?: boolean;

    initialInputMessage?: string;
    onChangeInputMessage?: (message: string) => void;

    onArchiveSelection?: (
        selectedMessageIds: string[],
        companyId: string,
        subject: string,
        downloadPdf: boolean,
    ) => void;
    isActiveChat?: boolean;

    onMessageDownload?: (message: Message) => void;
}) {
    const isMobile = React.useContext(MobileContext);

    const [inputMessage, setInputMessage] = React.useState(initialInputMessage ?? "");
    const changeInputMessage = (message: string) => {
        setInputMessage(message);
        onChangeInputMessage?.(message);
    };

    const {
        dialog: archiveMessagesDialog,
        selectionBar: archiveMessagesSelectionBar,
        selectedMessageIds,
        selectionMode,
        onArchive,
        toggleMessageSelection,
    } = useArchiveMessages(onArchiveSelection, companiesStore.selectedCompanyId);

    const [latestMessageId, setLatestMessageId] = React.useState("");

    const firstMessageId = messages?.[0]?.id;
    React.useEffect(() => {
        if (firstMessageId && latestMessageId !== firstMessageId) {
            setLatestMessageId(firstMessageId);
        }
    }, [latestMessageId, firstMessageId]);

    // Currently not needed anymore, but maybe later if we have UI depending on input height
    const [inputHeight, setInputHeight] = React.useState(0);

    const [isSending, setIsSending] = React.useState(false);

    const handleSend = async () => {
        if (isSending || !onSend) {
            return;
        }

        dropzone.clearFileRejections();

        // Don't allow empty messages
        if (!inputMessage.trim()) {
            changeInputMessage("");
            return;
        }

        setIsSending(true);
        const sendOk = await onSend(fixUrlsInMessage(inputMessage));
        setIsSending(false);

        if (sendOk) {
            changeInputMessage("");
        }
    };

    const dropzone = useChatDropzone(isSending || !!disableUpload || selectionMode, async (acceptedFiles: File[]) => {
        if (isSending || !onSend) {
            return;
        }

        setIsSending(true);
        generalStore.isLoading = true;
        let message = inputMessage;
        if (!inputMessage.trim()) {
            message = "";
        }

        // No for each -> we want sequential send
        for (let i = 0; i < acceptedFiles.length; ++i) {
            const file = acceptedFiles[i];

            await onSend(message, file);
            // Message is attached only to first upload
            message = "";
        }

        generalStore.isLoading = false;
        setIsSending(false);
        changeInputMessage("");
    });

    const disabled = isSending || disableInput;

    return (
        <React.Fragment>
            <dropzone.Container>
                <ChatContainer style={mobileFullScreen ? { height: "100%" } : undefined} {...dropzone.rootProps}>
                    <ChatMessageContainer
                        latestMessageId={latestMessageId}
                        loadOlder={loadOlder}
                        loadNewer={loadNewer}
                        style={{ paddingBottom: mobileFullScreen ? inputHeight : undefined }}
                    >
                        {messages?.map((message, index) => (
                            <ChatMessage
                                key={index}
                                message={message}
                                onSelect={selectionMode ? toggleMessageSelection : undefined}
                                isSelected={!!selectedMessageIds.find(messageId => messageId === message.id)}
                                onDownload={onMessageDownload}
                            />
                        ))}
                    </ChatMessageContainer>

                    {!selectionMode ? (
                        <ChatInteractionContainer onHeightChange={setInputHeight} fixed={mobileFullScreen}>
                            <ChatButtons
                                onUpload={
                                    !isActiveChat || disableUpload
                                        ? undefined
                                        : () => {
                                              // We always want the button to show which is why
                                              // not dropzone.open is passed directly
                                              dropzone.open();
                                          }
                                }
                                onArchive={onArchiveSelection ? onArchive : undefined}
                                onRelease={isActiveChat ? onRelease : undefined}
                                onCloseTicket={isActiveChat ? onCloseTicket : undefined}
                                onAssign={isActiveChat ? onAssignTicket : undefined}
                                onTogglePrivate={isActiveChat && !isPrivateChat ? onTogglePrivate : undefined}
                                disabled={disabled}
                            />
                            <FileRejections fileRejections={dropzone.fileRejections} />
                            <ChatInput
                                user={{
                                    firstName: authStore.userInfo?.given_name ?? "",
                                    lastName: authStore.userInfo?.family_name ?? "",
                                    imageUrl: authStore.userInfo?.profile_picture_url,
                                    isTpaEmployee: authStore.userInfo?.is_tpa_employee,
                                }}
                                message={inputMessage}
                                onChange={changeInputMessage}
                                onSend={handleSend}
                                disableSend={!isActiveChat || disabled}
                                disableInput={!isActiveChat || disableInput}
                                style={isMobile ? { padding: 8 } : undefined}
                            />
                            {isPrivateChat && mobileFullScreen && <PrivacyNotice onTogglePrivate={onTogglePrivate} />}
                        </ChatInteractionContainer>
                    ) : (
                        archiveMessagesSelectionBar
                    )}
                    {dropzone.input}
                    {isPrivateChat && !mobileFullScreen && <PrivacyNotice onTogglePrivate={onTogglePrivate} />}
                </ChatContainer>
            </dropzone.Container>
            {archiveMessagesDialog}
        </React.Fragment>
    );
});
