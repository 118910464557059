import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { RESIZE_DEBOUNCE_MS } from "../../config";

// Define general type for useWindowSize hook, which includes width and height
interface ISize {
    width?: number;
    height?: number;
}

// Hook
export function useWindowSize(noDebounce?: boolean): ISize {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<ISize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = debounce(
            () => {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            },
            noDebounce ? 0 : RESIZE_DEBOUNCE_MS,
        );

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}
