import { Checkbox, IconButton, TableBody } from "@material-ui/core";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import { observer } from "mobx-react";
import * as React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { useParams } from "react-router";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    DraftRequest,
    ProjectItem,
    ProjectItemPermission,
    ProjectItemSignatures,
    ProjectItemSliceUserRoleEnum,
    ProjectUser,
} from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { getFileExtension, hasFileExtension } from "../../../util/files";
import { escapeHtmlAndConvertLineBreaks, formatDate, getFileIconName } from "../../../util/helpers";
import { getFullName } from "../../../util/user";
import { UseParams, pushRoute, withParams } from "../../app/router/history";
import { ClipboardHook } from "../../hooks/useCopyToClipBoard";
import { useProjectPermissions } from "../../hooks/useProjectPermissions";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { BulkActionButton, BulkDeleteButton, BulkDownloadButton, BulkDraftButton } from "../../ui/BulkActionButton";
import { CenteredContent } from "../../ui/CenteredContent";
import { ConfirmationDialog } from "../../ui/ConfirmationDialog";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../../ui/ContextMenu";
import { EmptyState } from "../../ui/EmptyState";
import { TableLabel, TpaBadge, TpaTable, TpaTableCell, TpaTableContainer, TpaTableRow } from "../../ui/Primitives";
import { RenameDialog } from "../../ui/RenameDialog";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { AddFolderDialog } from "../AddFolderDialog";
import { ProjectItemPermissionsDialog } from "../ProjectItemPermissionsDialog";
import { ProjectPermissionAvatars } from "../ProjectPermissionAvatars";
import { RequestReleaseProjectItemDialog } from "../RequestReleaseProjectItemDialog";
import { ProjectsRoutes } from "../router/ProjectsRoutes";

export const ProjectsContentSite = observer(function ProjectsContentSite({
    path,
    parentPermissionUsers,
    onChangeFolder,
    reloadProject,
    clipboard,
}: {
    path: string[];
    parentPermissionUsers?: ProjectItemPermission[];
    onChangeFolder: (path: string[], parentId?: string) => void;
    reloadProject: () => Promise<void>;
    clipboard: ClipboardHook;
}) {
    //                                                         typeof ProjectsRoutes["ACTIVE"]["PROJECT"] - alternative route where this component is used
    const { projectId, projectFolderId } = useParams<UseParams<(typeof ProjectsRoutes)["ACTIVE"]["PROJECT_FOLDER"]>>();
    const isMobile = React.useContext(MobileContext);
    const [showAddFolderDialog, setShowAddFolderDialog] = React.useState(false);
    const [showBulkDeleteDialog, setShowBulkDeleteDialog] = React.useState(false);
    const [showRequestReleaseConfirmationDialog, setShowRequestReleaseConfirmationDialog] = React.useState(false);
    const [showRequestReleaseDialog, setShowRequestReleaseDialog] = React.useState(false);
    const [showMoveConfirmDialog, setShowMoveConfirmDialog] = React.useState(false);
    const [itemToDisplay, setItemToDisplay] = React.useState<ProjectItem | null>(null);
    const [itemToDelete, setItemToDelete] = React.useState<ProjectItem | null>(null);
    const [itemToRename, setItemToRename] = React.useState<ProjectItem | null>(null);
    const [releaseItems, setReleaseItems] = React.useState<ProjectItem[] | null>(null);
    const [releaseItemsWithDraft, setReleaseItemsWithDraft] = React.useState<ProjectItem[] | null>(null);
    const [currentUserRole, setCurrentUserRole] = React.useState<ProjectItemSliceUserRoleEnum | undefined>();
    const contextMenu = useContextMenu<ProjectItem>();

    const { clipboardContent, copyToClipboard, clearClipboard, moveFiles, pasteFiles, progressDialog, canMove } =
        clipboard;
    const canPaste = currentUserRole && currentUserRole !== "read" && currentUserRole !== "read-no-download";

    const copySuccessDialog = useSuccessDialog({
        title: t("projects.copySuccessDialog.title"),
        message: t("projects.copySuccessDialog.message"),
    });

    const pasteSuccessDialog = useSuccessDialog({
        title: t("projects.pasteSuccessDialog.title"),
        message: t("projects.pasteSuccessDialog.message"),
        onClose: async () => {
            // Has to be done here, otherwise screen with success dialog flickers
            await loadProjectItems();
        },
    });

    const moveSuccessDialog = useSuccessDialog({
        title: t("projects.moveSuccessDialog.title"),
        message: t("projects.moveSuccessDialog.message"),
        onClose: async () => {
            // Has to be done here, otherwise screen with success dialog flickers
            await loadProjectItems();
        },
    });

    const tableStore = useTableStore<ProjectItem>("ProjectsContentSite", {
        orderBy: "isFolder",
        orderDir: "asc",
        limit: 0,
    });
    const items = tableStore.items;
    const selectedItemIds = tableStore.getAllSelectedItems().map(item => item.id);

    const releaseSuccessDialog = useSuccessDialog({
        title: t("projects.releaseSuccessDialog.title"),
        onClose: async () => {
            // Has to be done here, otherwise screen with success dialog flickers
            await loadProjectItems();
        },
    });

    const companyId = companiesStore.selectedCompanyId;

    const itemPermissions = useProjectPermissions({
        companyId,
        projectItems: {
            projectId,
            items,
        },
    });

    React.useEffect(() => {
        if (isMobile) {
            tableStore.selectedItems.clear();
        }
    }, [tableStore, isMobile]);

    const { tableParams } = tableStore;
    const loadProjectItems = React.useCallback(async () => {
        if (!companyId || !projectId) {
            return;
        }

        try {
            generalStore.isLoading = true;

            const response = await API.getProjectItems(companyId, projectId, projectFolderId, tableParams);
            setCurrentUserRole(response.userRole);

            tableStore.totalCount = response.total;

            if (response.items) {
                tableStore.items = response.items;
                onChangeFolder(response.path ?? [], response.parentId);
            }
        } catch (error) {
            // TODO more specific error
            generalStore.setError(t("error.general"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, onChangeFolder, projectFolderId, projectId, tableParams, tableStore]);

    React.useEffect(() => {
        loadProjectItems();
    }, [loadProjectItems]);

    if (!companyId) {
        // No company selected -> get out
        return null;
    }

    if (!projectId) {
        // No project selected -> get out
        return null;
    }

    // Check if folder or project is empty
    const isItemEmpty = async (item: ProjectItem) => {
        let empty = false;
        if (item.isFolder) {
            const projectItemsResponse = await API.getProjectItems(
                companyId,
                projectId,
                item.id === projectId ? undefined : item.id,
            );

            empty = !!projectItemsResponse.items && projectItemsResponse.items.length === 0;
        }
        return empty;
    };

    const downloadItems = async (itemIds: string[]) => {
        if (itemIds.length === 0) {
            return;
        }

        try {
            generalStore.isLoading = true;

            if (itemIds.length === 1) {
                const itemId = itemIds[0];
                const item = items.find(item => item.id === itemId);

                if (item) {
                    const empty = await isItemEmpty(item);
                    if (!empty) {
                        // Not empty -> download
                        if (item.id === projectId) {
                            await API.putDownloadProject(companyId, projectId);
                        } else {
                            await API.putDownloadProject(companyId, projectId, [itemId]);
                        }
                    } else {
                        // Empty -> display error
                        if (item.id === projectId) {
                            generalStore.setError(t("error.projectIsEmpty"));
                        } else {
                            generalStore.setError(t("error.folderIsEmpty"));
                        }
                    }
                }
            } else if (itemIds.length > 1) {
                const itemsToDownload = compact(itemIds.map(itemId => items.find(item => item.id === itemId)));

                const allItemsAreFolders = itemsToDownload.every(item => item.isFolder);

                const denyDownload = allItemsAreFolders
                    ? (await Promise.all(itemsToDownload.map(isItemEmpty))).every(isEmpty => isEmpty)
                    : false;

                if (!denyDownload) {
                    await API.putDownloadProject(companyId, projectId, itemIds);
                    tableStore.selectedItems.clear();
                } else {
                    generalStore.setError(t("error.foldersAreEmpty"));
                }
            }
        } catch (err) {
            generalStore.setError(t("error.download"), err);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const getBookmarkUrl = async (itemId: string): Promise<string | undefined> => {
        try {
            generalStore.isLoading = true;
            const item = items.find(item => item.id === itemId);

            if (item) {
                return await API.getBookmarkUrl(companyId, projectId, itemId);
            }
        } catch (err) {
            generalStore.setError(t("error.projects.openBookmarkUrlFailed"), err);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const deleteItems = async (itemIds: string[]) => {
        try {
            generalStore.isLoading = true;

            await Promise.all(itemIds.map(itemId => API.deleteProjectItem(companyId, projectId, itemId)));
            await loadProjectItems();
            tableStore.selectedItems.clear();
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.response.type === "PROJECT_ITEM_LOCKED") {
                generalStore.setError(t("error.delete.fileLocked"), error);
            } else {
                generalStore.setError(t("error.delete"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    };

    const handleUploadFile = () => {
        if (projectFolderId) {
            pushRoute(withParams(ProjectsRoutes.UPLOAD_FILES_TO_PROJECT_FOLDER, { projectId, projectFolderId }));
        } else {
            pushRoute(withParams(ProjectsRoutes.UPLOAD_FILES_TO_PROJECT, { projectId }));
        }
    };

    const handleCopyFiles = (deleteAllowed: boolean) => {
        copySuccessDialog.openDialog();
        copyToClipboard(tableStore.getAllSelectedItems(), projectId, deleteAllowed);
        tableStore.selectedItems.clear();
    };

    const handleRequestDraft = () => {
        const itemsToRequestRelease = tableStore.getAllSelectedItems();
        setReleaseItems(itemsToRequestRelease);

        const itemsWithOpenDraft = itemsToRequestRelease.filter(
            itemToRequestRelease => itemToRequestRelease.openDrafts && itemToRequestRelease.openDrafts > 0,
        );

        setReleaseItemsWithDraft(itemsWithOpenDraft);

        if (itemsWithOpenDraft.length > 0) {
            setShowRequestReleaseConfirmationDialog(true);
        } else {
            setShowRequestReleaseDialog(true);
        }

        tableStore.selectedItems.clear();
    };

    const handlePasteFiles = async () => {
        const result = await pasteFiles(companyId, projectId, projectFolderId);
        clearClipboard();
        if (result === "without-dialog") {
            pasteSuccessDialog.openDialog(); // the copy was fast enough to not show the progress dialog
        } else {
            await loadProjectItems(); // the copy dialog was visible anyway, so we can reload the items immediately
        }
    };

    const handleMoveFiles = () => {
        setShowMoveConfirmDialog(true);
    };

    const handleMoveConfirm = async () => {
        handleCloseMoveConfirmDialog();
        const result = await moveFiles(companyId, projectId, projectFolderId);
        clearClipboard();
        if (result === "without-dialog") {
            moveSuccessDialog.openDialog(); // the move was fast enough to not show the progress dialog
        } else {
            await loadProjectItems(); // the move dialog was visible anyway, so we can reload the items immediately
        }
    };

    const handleAddFolder = () => {
        setShowAddFolderDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setItemToDelete(null);
    };

    const handleClickBulkDownload = async () => {
        await downloadItems(tableStore.getAllSelectedItems().map(item => item.id));
    };

    const handleClickBulkDelete = () => {
        setShowBulkDeleteDialog(true);
    };

    const handleCloseBulkDeleteDialog = () => {
        setShowBulkDeleteDialog(false);
    };

    const handleSubmitBulkDeleteDialog = async () => {
        await deleteItems(tableStore.getAllSelectedItems().map(item => item.id));
        setShowBulkDeleteDialog(false);
    };

    const handleCloseRequestReleaseConfirmationDialog = () => {
        setReleaseItems(null);
        setShowRequestReleaseConfirmationDialog(false);
    };

    const handleSubmitRequestReleaseConfirmationDialog = () => {
        setShowRequestReleaseConfirmationDialog(false);
        setShowRequestReleaseDialog(true);
    };

    const handleSubmitDeleteDialog = async () => {
        if (itemToDelete) {
            await deleteItems([itemToDelete.id]);
            setItemToDelete(null);
        }
    };

    const handleCloseRenameDialog = () => {
        setItemToRename(null);
    };

    const handleCloseRequestReleaseDialog = () => {
        setReleaseItems(null);
        setShowRequestReleaseDialog(false);
    };

    const handleCloseMoveConfirmDialog = () => {
        setShowMoveConfirmDialog(false);
    };

    const handleSubmitRenameDialog = async (name: string) => {
        if (itemToRename) {
            const isFolder = itemToRename.isFolder;
            try {
                generalStore.isLoading = true;

                await API.patchProjectItem(companyId, projectId, itemToRename.id, {
                    name: isFolder ? name : `${name}.${getFileExtension(itemToRename.name)}`,
                });
                setItemToRename(null);
                await loadProjectItems();
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.statusCode === HttpStatusCode.Conflict_409) {
                    if (isFolder) {
                        generalStore.setError(t("error.rename.alreadyExists.folder"), error);
                    } else {
                        generalStore.setError(t("error.rename.alreadyExists.file"), error);
                    }
                } else {
                    generalStore.setError(t("error.general"), error);
                }
            } finally {
                generalStore.isLoading = false;
            }
        }
    };

    const handleSubmitRequestReleaseDialog = async (
        responsibleUsers: ProjectUser[],
        dueDate: string,
        requiresQes?: boolean,
        collectiveRelease?: boolean,
        comment?: string,
        qesPositioning?: ProjectItemSignatures[],
    ) => {
        const responsibleIDs = responsibleUsers.map(responsibleUser => responsibleUser.id);

        if (releaseItems && releaseItems.length > 0) {
            try {
                generalStore.isLoading = true;

                const releaseItemsNoDraft = releaseItems.filter(
                    itemToReleaseRequest => itemToReleaseRequest.openDrafts === 0,
                );

                const draftRequest: DraftRequest = {
                    comment: escapeHtmlAndConvertLineBreaks(comment),
                    dueDate,
                    projectItems: [],
                    requiresQes,
                    responsibleIDs,
                    collectiveRelease,
                    qesPositioning,
                };

                if (releaseItemsNoDraft.length > 0) {
                    draftRequest.projectItems = releaseItemsNoDraft.map(item => item.id);
                    await API.postProjectItemDraft(companyId, projectId, draftRequest);
                }

                if (releaseItemsWithDraft && releaseItemsWithDraft.length > 0) {
                    draftRequest.draftIDs = compact(flatten(releaseItemsWithDraft.map(item => item.openDraftIDs)));
                    draftRequest.projectItems = releaseItemsWithDraft.map(item => item.id);
                    await API.putProjectItemDraft(companyId, projectId, draftRequest);
                }

                await reloadProject();
                setShowRequestReleaseDialog(false);
                setReleaseItems(null);
                setReleaseItemsWithDraft(null);

                releaseSuccessDialog.setMessage(
                    responsibleUsers.length > 1 ? (
                        t("projects.releaseSuccessDialog.multi.message")
                    ) : (
                        <FormattedHTMLMessage
                            id="projects.releaseSuccessDialog.message"
                            values={{ name: getFullName(responsibleUsers[0]) }}
                        />
                    ),
                );
                releaseSuccessDialog.openDialog();
            } catch (error) {
                generalStore.setError(t("error.general"), error);
            } finally {
                generalStore.isLoading = false;
            }
        }
    };

    const handleSubmitAddFolderDialog = async (name: string) => {
        try {
            generalStore.isLoading = true;
            setShowAddFolderDialog(false);
            const projectFolder = await API.postProjectFolder(companyId, projectId, projectFolderId, { name });
            pushRoute(
                withParams(ProjectsRoutes.ACTIVE.PROJECT_FOLDER, { projectId, projectFolderId: projectFolder.id }),
            );
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.BadRequest_400) {
                generalStore.setError(t("error.addFolderBadRequest"), error);
            } else {
                generalStore.setError(t("error.general"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    };

    const handleCloseAddFolderDialog = () => {
        setShowAddFolderDialog(false);
    };

    const handleShowItemPermissionsDialog = (item: ProjectItem) => () => {
        setItemToDisplay(item);
    };

    const handleCloseItemPermissionsDialog = () => {
        setItemToDisplay(null);
    };

    const handleClickCell = (item: ProjectItem) => () => {
        // Optimistic update to path
        onChangeFolder([...path, item.name], item.id);
        pushRoute(withParams(ProjectsRoutes.ACTIVE.PROJECT_FOLDER, { projectId, projectFolderId: item.id }));
    };

    const handleOpenBookmarkUrl = (bookmarkUrl?: string) => {
        // need to use _self as target since _blank is blocked sporadically by safari
        window.open(bookmarkUrl, "_self");
    };

    const headerFields: ITableHeaderConfig[] = compact(
        isMobile
            ? [
                  { column: "isFolder", label: "table.label.type" },
                  { column: "name", label: "table.label.documentName", style: { width: "24%", minWidth: 150 } },
                  { column: "updatedAt", label: "table.label.updatedAt.variant" },
                  { column: "members", label: "table.label.members", sort: false },
                  { column: "openReleases", label: "table.label.openReleases", sort: false },
                  { column: "openTickets", label: "table.label.tickets", sort: false },
                  { column: "createdAt", label: "table.label.createdAt.variant" },
                  { column: "createdBy", label: "table.label.createdBy" },
                  { column: "contextMenu" },
              ]
            : [
                  { column: "isFolder", label: "table.label.type" },
                  { column: "name", label: "table.label.documentName", style: { width: "24%", minWidth: 150 } },
                  { column: "updatedAt", label: "table.label.updatedAt.variant" },
                  { column: "members", label: "table.label.members", sort: false },
                  { column: "openReleases", label: "table.label.openReleases", sort: false },
                  { column: "openTickets", label: "table.label.tickets", sort: false },
                  { column: "createdAt", label: "table.label.createdAt.variant" },
                  { column: "createdBy", label: "table.label.createdBy" },
                  { column: "contextMenu" },
              ],
    );

    const tableBody = (
        <TableBody>
            {items.map((item, index) => {
                const isFolder = item.isFolder;
                const isUrlFile = hasFileExtension(item.name, "url");

                return (
                    <TpaTableRow key={item.id}>
                        {!isMobile && (
                            <TpaTableCell padding="checkbox">
                                <Checkbox
                                    data-id={`item_checkbox_${index}`}
                                    onChange={(event, checked) => {
                                        tableStore.toggleSelection(item);
                                    }}
                                    color="primary"
                                    checked={tableStore.isSelected(item)}
                                />
                            </TpaTableCell>
                        )}
                        {headerFields.map(({ column }, index) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = item[column as keyof ProjectItem];
                            let style: React.CSSProperties = {};

                            if (column === "members") {
                                const permissionUsers = itemPermissions[item.id];

                                return (
                                    <TpaTableCell
                                        key={column}
                                        style={{ cursor: "pointer" }}
                                        onClick={async () => {
                                            if (isFolder) {
                                                handleClickCell(item)();
                                            } else if (isUrlFile) {
                                                const bookmarkUrl = await getBookmarkUrl(item.id);

                                                handleOpenBookmarkUrl(bookmarkUrl);
                                            } else {
                                                window.open(item.webUrl, "_blank");
                                            }
                                        }}
                                    >
                                        <ProjectPermissionAvatars
                                            variant="async"
                                            permissionUsers={permissionUsers}
                                            showTooltip
                                            onClickShowAll={handleShowItemPermissionsDialog(item)}
                                        />
                                    </TpaTableCell>
                                );
                            } else if (column === "updatedAt") {
                                style = { maxWidth: "unset" };
                                label = formatDate(item.updatedAt);
                            } else if (column === "createdAt") {
                                style = { maxWidth: "unset" };
                                label = formatDate(item.createdAt);
                            } else if (column === "createdBy") {
                                style = { maxWidth: "unset" };
                                label = item.createdBy;
                            } else if (column === "openReleases") {
                                style = { textAlign: "center" };
                                label = item.openDrafts ? <TpaBadge>{item.openDrafts}</TpaBadge> : "";
                            } else if (column === "openTickets") {
                                style = { textAlign: "center" };
                                label = item.ticketCount ? <TpaBadge>{item.ticketCount}</TpaBadge> : "";
                            } else if (column === "name") {
                                label = item.name;
                            } else if (column === "isFolder") {
                                label = <Icon name={isFolder ? "folder" : getFileIconName(item.name)} />;
                            } else if (column === "contextMenu") {
                                return (
                                    <TpaTableCell key={column} style={{ textAlign: "right", width: 1 }}>
                                        <IconButton
                                            data-id={`context_menu_${index}`}
                                            style={{ padding: 0 }}
                                            onClick={event => {
                                                contextMenu.open(event, item);
                                            }}
                                        >
                                            <Icon name="more" />
                                        </IconButton>
                                    </TpaTableCell>
                                );
                            }

                            return (
                                <TpaTableCell
                                    key={column}
                                    // https://stackoverflow.com/questions/9789723/css-text-overflow-in-a-table-cell
                                    style={{
                                        cursor: "pointer",
                                        maxWidth: 0,
                                        ...style,
                                    }}
                                    onClick={async () => {
                                        if (isFolder) {
                                            handleClickCell(item)();
                                        } else if (isUrlFile) {
                                            const bookmarkUrl = await getBookmarkUrl(item.id);

                                            handleOpenBookmarkUrl(bookmarkUrl);
                                        } else {
                                            window.open(item.webUrl, "_blank");
                                        }
                                    }}
                                >
                                    <TableLabel style={{ maxWidth: "100%" }}>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    const getItemPermissionsForCurrentUser = (itemId: string) => {
        // Permission handling
        const permissions = itemPermissions[itemId];
        const currentUserPermissions = permissions?.find(permission => permission.userId === authStore.userId);
        const currentUserRole = currentUserPermissions?.role;
        const canRead = !!currentUserRole;
        const canDownload = canRead && currentUserRole !== "read-no-download";
        const canRequestDraft = currentUserRole === "write" || currentUserRole === "owner";
        const canDelete = currentUserRole === "write" || currentUserRole === "owner";
        const canRename = currentUserRole === "write" || currentUserRole === "owner";

        return {
            canRead,
            canDownload,
            canRequestDraft,
            canDelete,
            canRename,
        };
    };

    let contextMenuItems: ContextMenuItem[] = [];
    if (contextMenu.contextElement) {
        const item = contextMenu.contextElement;

        // Permission handling
        const { canRead, canDownload, canDelete, canRename } = getItemPermissionsForCurrentUser(item.id);
        const isUrlFile = hasFileExtension(item.name, "url");

        contextMenuItems = [
            ...(!item.isFolder
                ? ([
                      {
                          title: t("menu.openInBrowser"),
                          icon: "open",
                          onClick: async () => {
                              contextMenu.close();
                              if (isUrlFile) {
                                  const bookmarkUrl = await getBookmarkUrl(item.id);

                                  handleOpenBookmarkUrl(bookmarkUrl);
                              } else {
                                  window.open(item.webUrl, "_blank");
                              }
                          },
                          disabled: selectedItemIds.length > 0 || !canRead,
                          "data-id": "context_menu_viewOrEdit",
                      },
                  ] satisfies ContextMenuItem[])
                : []),
            {
                title: t("menu.download"),
                icon: "download",
                onClick: async () => {
                    contextMenu.close();
                    await downloadItems([item.id]);
                },
                disabled: selectedItemIds.length > 0 || !canDownload,
                "data-id": "context_menu_download",
            },
            {
                title: t("menu.delete"),
                icon: "archive",
                onClick: () => {
                    contextMenu.close();
                    setItemToDelete(item);
                },
                disabled: selectedItemIds.length > 0 || !canDelete,
                "data-id": "context_menu_delete",
            },
            {
                title: t("menu.rename"),
                icon: "rename",
                onClick: () => {
                    contextMenu.close();
                    setItemToRename(item);
                },
                disabled: selectedItemIds.length > 0 || !canRename,
                "data-id": "context_menu_rename",
            },
            ...(!item.isFolder && !isUrlFile
                ? ([
                      {
                          title: t("menu.showReleases"),
                          icon: "hours",
                          onClick: () => {
                              contextMenu.close();
                              onChangeFolder([...path, item.name], projectFolderId ?? projectId);
                              pushRoute(
                                  withParams(ProjectsRoutes.RELEASES_PROJECT_ITEM, {
                                      projectId,
                                      projectItemId: item.id,
                                  }),
                              );
                          },
                          disabled: selectedItemIds.length > 0,
                          "data-id": "context_menu_showReleases",
                      },
                      ...(authStore.isTpa
                          ? ([
                                {
                                    title: t("menu.requestRelease"),
                                    icon: "checkmark",
                                    onClick: () => {
                                        setReleaseItems([item]);

                                        if (item.openDrafts) {
                                            setReleaseItemsWithDraft([item]);
                                            setShowRequestReleaseConfirmationDialog(true);
                                        } else {
                                            setShowRequestReleaseDialog(true);
                                        }

                                        contextMenu.close();
                                    },
                                    disabled: selectedItemIds.length > 0,
                                    "data-id": "context_menu_requestRelease",
                                },
                            ] satisfies ContextMenuItem[])
                          : []),
                  ] satisfies ContextMenuItem[])
                : []),
        ] satisfies ContextMenuItem[];
    }

    let actionButtons: React.ReactNode;
    if (selectedItemIds.length > 0) {
        let downloadAllowed = true;
        let deleteAllowed = true;
        let draftAllowed = true;

        for (let i = 0; i < selectedItemIds.length; ++i) {
            // Get permissions for single item
            const { canDownload, canDelete, canRequestDraft } = getItemPermissionsForCurrentUser(selectedItemIds[i]);

            const item = items.find(item => item.id === selectedItemIds[i]);
            const isFolder = item?.isFolder;
            const isUrlFile = item?.name && hasFileExtension(item.name, "url");

            // If one of the selected items does not allow download/delete/draft -> disable button
            downloadAllowed = downloadAllowed && canDownload;
            deleteAllowed = deleteAllowed && canDelete;
            draftAllowed = draftAllowed && canRequestDraft && !isFolder && !isUrlFile;

            if (!downloadAllowed && !deleteAllowed && !draftAllowed) {
                // If all are disabled -> early out
                break;
            }
        }

        actionButtons = (
            <>
                <BulkDownloadButton onClick={downloadAllowed ? handleClickBulkDownload : undefined} />
                {authStore.isTpa && <BulkDraftButton onClick={handleRequestDraft} disabled={!draftAllowed} />}
                {GLOBAL_FEATURES.projectCopyPaste && (
                    <BulkActionButton
                        onClick={() => {
                            handleCopyFiles(deleteAllowed);
                        }}
                        iconName="copy"
                        style={{ margin: 0 }}
                        disabled={!deleteAllowed}
                    >
                        {t("button.copy")}
                    </BulkActionButton>
                )}
                <BulkDeleteButton onClick={deleteAllowed ? handleClickBulkDelete : undefined} />
            </>
        );
    } else {
        actionButtons = (
            <>
                <BulkActionButton
                    onClick={handleUploadFile}
                    iconName="upload"
                    style={{ margin: 0 }}
                    disabled={!currentUserRole || currentUserRole === "read" || currentUserRole === "read-no-download"}
                >
                    {t("button.upload")}
                </BulkActionButton>

                {GLOBAL_FEATURES.projectCopyPaste && clipboardContent ? (
                    <>
                        <BulkActionButton
                            onClick={handlePasteFiles}
                            iconName="paste"
                            style={{ marginLeft: 16 }}
                            disabled={!canPaste}
                        >
                            {t("button.paste")}
                        </BulkActionButton>
                        <BulkActionButton
                            onClick={handleMoveFiles}
                            iconName="move"
                            style={{ marginLeft: 16 }}
                            disabled={!canPaste || !canMove}
                        >
                            {t("button.move")}
                        </BulkActionButton>
                    </>
                ) : undefined}

                <BulkActionButton
                    onClick={handleAddFolder}
                    iconName="addFolder"
                    style={{ marginLeft: 16 }}
                    disabled={!currentUserRole || currentUserRole === "read" || currentUserRole === "read-no-download"}
                >
                    {t("projects.addFolder")}
                </BulkActionButton>
            </>
        );
    }

    // No items and NOT caused by a search term
    const isEmpty = items.length === 0 && !tableStore.search;

    return (
        <>
            <CenteredContent>
                {isEmpty && !generalStore.isLoading && (
                    <>
                        <EmptyState
                            title={t("projects.folder.emptystate.title")}
                            message={
                                <>
                                    <span style={{ display: "block", marginBottom: 24 }}>
                                        {t("projects.folder.emptystate.message")}
                                    </span>
                                    {actionButtons}
                                </>
                            }
                        />
                    </>
                )}
                {!isEmpty && (
                    <SiteContent>
                        <div style={{ textAlign: "right", marginBottom: 16 }}>{actionButtons}</div>
                        <TpaTableContainer>
                            <TpaTable>
                                <TableHeader headerFields={headerFields} tableStore={tableStore} select={tableStore} />
                                {tableBody}
                            </TpaTable>
                        </TpaTableContainer>
                        <ContextMenu
                            data-id="context_menu"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            config={contextMenu}
                            items={contextMenuItems}
                        />
                    </SiteContent>
                )}
            </CenteredContent>
            <AddFolderDialog
                open={showAddFolderDialog}
                permissionUsers={parentPermissionUsers}
                onSubmit={handleSubmitAddFolderDialog}
                onClose={handleCloseAddFolderDialog}
            />
            <ConfirmationDialog
                title={t("projects.bulkDeleteDialog.title")}
                message={t("projects.bulkDeleteDialog.message")}
                confirmLabel={t("common.delete")}
                onConfirm={handleSubmitBulkDeleteDialog}
                onCancel={handleCloseBulkDeleteDialog}
                open={showBulkDeleteDialog}
            />
            {itemToDisplay && itemPermissions[itemToDisplay.id] && (
                <ProjectItemPermissionsDialog
                    open={!!itemToDisplay}
                    projectItem={itemToDisplay}
                    permissionUsers={itemPermissions[itemToDisplay.id]}
                    onClose={handleCloseItemPermissionsDialog}
                />
            )}
            {itemToDelete && (
                <ConfirmationDialog
                    title={
                        itemToDelete.isFolder
                            ? t("projects.deleteDialog.title.folder")
                            : t("projects.deleteDialog.title.file")
                    }
                    message={
                        <FormattedHTMLMessage
                            id={
                                itemToDelete.isFolder
                                    ? "projects.deleteDialog.message.folder"
                                    : "projects.deleteDialog.message.file"
                            }
                            values={{ name: itemToDelete.name }}
                        />
                    }
                    confirmLabel={t("common.delete")}
                    onConfirm={handleSubmitDeleteDialog}
                    onCancel={handleCloseDeleteDialog}
                    open={!!itemToDelete}
                />
            )}
            {itemToRename && (
                <RenameDialog
                    open={!!itemToRename}
                    currentName={itemToRename.name}
                    variant={itemToRename.isFolder ? "folder" : "file"}
                    onClose={handleCloseRenameDialog}
                    onSubmit={handleSubmitRenameDialog}
                />
            )}
            {releaseItems && showRequestReleaseConfirmationDialog && (
                <ConfirmationDialog
                    title={
                        releaseItems.length > 1
                            ? t("projects.requestReleaseConfirmationDialog.multi.title")
                            : t("projects.requestReleaseConfirmationDialog.title")
                    }
                    message={
                        releaseItems.length > 1 ? (
                            t("projects.requestReleaseConfirmationDialog.multi.message")
                        ) : (
                            <FormattedHTMLMessage
                                id="projects.requestReleaseConfirmationDialog.message"
                                values={{ fileName: releaseItems[0].name }}
                            />
                        )
                    }
                    confirmLabel={t("projects.requestReleaseConfirmationDialog.confirm")}
                    onConfirm={handleSubmitRequestReleaseConfirmationDialog}
                    onCancel={handleCloseRequestReleaseConfirmationDialog}
                    open
                />
            )}
            {releaseItems && showRequestReleaseDialog && (
                <RequestReleaseProjectItemDialog
                    open={releaseItems.length > 0}
                    companyId={companyId}
                    projectId={projectId}
                    projectItems={releaseItems}
                    onClose={handleCloseRequestReleaseDialog}
                    onSubmit={handleSubmitRequestReleaseDialog}
                />
            )}
            <ConfirmationDialog
                title={t("projects.moveConfirmationDialog.title")}
                message={t("projects.moveConfirmationDialog.message")}
                confirmLabel={t("common.ok")}
                onConfirm={handleMoveConfirm}
                onCancel={handleCloseMoveConfirmDialog}
                open={showMoveConfirmDialog}
            />

            {copySuccessDialog.dialog}
            {pasteSuccessDialog.dialog}
            {moveSuccessDialog.dialog}
            {releaseSuccessDialog.dialog}
            {progressDialog}
        </>
    );
});
