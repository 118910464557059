import * as React from "react";
import { SuccessDialog } from "../ui/SuccessDialog";
import { useTimeoutDialog } from "./useTimeoutDialog";

export const useSuccessDialog = (params: {
    title?: React.ReactNode;
    message?: React.ReactNode;
    onClose?: () => void;
}) => {
    const timeoutDialog = useTimeoutDialog(params.onClose);
    const [title, setTitle] = React.useState<React.ReactNode>();
    const [message, setMessage] = React.useState<React.ReactNode>();

    const dialog = (
        <SuccessDialog
            title={title ?? params.title ?? ""}
            message={message ?? params.message ?? ""}
            open={timeoutDialog.isDialogOpen}
            onClose={timeoutDialog.closeDialog}
        />
    );

    return {
        ...timeoutDialog,
        setTitle,
        setMessage,
        dialog,
    };
};
