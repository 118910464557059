import * as React from "react";
import { t } from "../../i18n/util";
import { FallbackImage } from "../ui/FallbackImage";
import { MobileContext } from "../util/MobileContext";
import { BOX_SHADOW_LIGHT, customColors } from "../util/Theme";

export interface IProps {
    companyName?: string;
    backgroundImageUrl?: string;
    logoUrl?: string;
}

export const CockpitHeader = ({ companyName, backgroundImageUrl, logoUrl }: IProps) => {
    const isMobile = React.useContext(MobileContext);
    const hasCompany = !!companyName || !!backgroundImageUrl || !!logoUrl;

    return (
        <div
            key={backgroundImageUrl} // fix backgroundimage cover not working properly when url changes eg. after switching companies
            style={{
                height: 226,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                background: hasCompany
                    ? `linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33)), url(${backgroundImageUrl ?? ""}) no-repeat`
                    : customColors.primaryColorDark,
                backgroundColor: "white",
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
            }}
        >
            <h2 style={{ color: customColors.white, padding: "0 16px" }}>
                {hasCompany ? companyName : t("common.cockpit")}
            </h2>
            {logoUrl && (
                <FallbackImage
                    src={logoUrl}
                    style={{
                        position: "absolute",
                        left: isMobile ? 16 : 48,
                        bottom: -50,
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                        boxShadow: BOX_SHADOW_LIGHT,
                        backgroundColor: "white",
                    }}
                    errorMessage={t("companyImages.logo.error")}
                />
            )}
        </div>
    );
};
