import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { RecordType } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { generalStore } from "../../stores/GeneralStore";
import { Module } from "../../types/models";

export const useCompanyRecordTypes = (companyId: string | undefined, module: Module): RecordType[] => {
    const [recordTypes, setRecordTypes] = React.useState<RecordType[]>([]);

    React.useEffect(() => {
        const load = async () => {
            if (!companyId) {
                return;
            }

            try {
                generalStore.isLoading = true;
                const response = await API.getCompanyRecordTypes({ companyId, module });
                setRecordTypes(response);
            } catch (error) {
                const apiError = getApiError(error);

                // No FIBUs -> no record types -> no accounting
                if (apiError?.response.type !== "MISSING_FINANCIAL_ACCOUNTANCIES") {
                    generalStore.setError(t("error.loadRecordTypes"), error);
                }
            } finally {
                generalStore.isLoading = false;
            }
        };

        load();
    }, [companyId, module]);

    return recordTypes;
};
