import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { IPeriod, Module } from "../../types/models";

export const usePeriods = (companyId: string | undefined, module: Module | "" | undefined) => {
    const [periods, setPeriods] = React.useState<IPeriod[]>([]);

    React.useEffect(() => {
        const load = async () => {
            if (!companyId || !module) {
                return;
            }

            try {
                const newPeriods = await API.getPeriods(companyId, module);
                setPeriods(newPeriods);
            } catch (err) {
                generalStore.setError(t("error.loadPeriod"), err);
            }
        };

        load();
    }, [companyId, module]);

    return {
        periods,
        reset: () => {
            setPeriods([]);
        },
    };
};
