import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import { FieldInputProps, FormikState, getIn } from "formik";
import { t } from "../../i18n/util";

interface IOption {
    value: string | number;
    label: string | number;
}

type IProps = TextFieldProps & {
    errorMessage?: string;
    field: FieldInputProps<string>;
    isTouched?: boolean;
    options: IOption[];
    hideOptional?: boolean;
    form: FormikState<unknown>;
    "data-id": string;
};

export const CustomSelect = ({
    options,
    style,
    label,
    type,
    required,
    field,
    form,
    hideOptional,
    onChange,
    disabled,
    "aria-label": ariaLabel,
    "data-id": dataId,
}: IProps) => {
    const fieldError = getIn(form.errors, field.name) as React.ReactNode;
    const showError = (getIn(form.touched, field.name) as boolean) && !!fieldError;

    return (
        <div style={style}>
            <TextField
                inputProps={{ "data-id": dataId }}
                label={label}
                value={field.value ?? ""} // avoid "undefined" because it throws a warning about "uncontrolled component"
                name={field.name}
                onChange={event => {
                    field.onChange(event);
                    if (onChange) {
                        onChange(event);
                    }
                }}
                onBlur={field.onBlur}
                fullWidth
                type={type}
                error={showError}
                variant="outlined"
                aria-label={ariaLabel}
                helperText={!required && !hideOptional ? t("common.optional") : undefined}
                select
                disabled={disabled}
            >
                {options.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <span
                style={{
                    color: "#f00",
                    display: "block",
                    minHeight: 18,
                    width: "100%",
                    marginTop: 4,
                    marginBottom: 2,
                    fontSize: 12,
                }}
            >
                {showError && fieldError}
            </span>
        </div>
    );
};
