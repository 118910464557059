import moment from "moment";

export function getInitialPeriodRange() {
    const start = moment().startOf("year").subtract(1, "year");
    const end = moment().endOf("year").add(1, "year");

    return { start, end };
}
export function formatDate(date?: Date | moment.Moment | string | null, formatString = "L") {
    if (!date) {
        return "";
    }

    return moment(date).format(formatString);
}

export function formatTime(date?: Date | moment.Moment | string | null) {
    if (!date) {
        return "";
    }

    let formatString = "HH:mm";
    if (moment.locale() === "en") {
        formatString = "hh:mm A";
    }
    return moment(date).format(formatString);
}

export function formatDateTime(date?: Date | moment.Moment | string | null) {
    return formatDate(date) + ", " + formatTime(date);
}

export function formatMonth(date?: Date | moment.Moment | string) {
    if (!date) {
        return "";
    }

    return moment(date).format("MMMM YYYY");
}

export function formatISODateOnly(date?: Date | moment.Moment | null) {
    if (!date) {
        return "";
    }

    return moment(date).format("YYYY-MM-DD");
}

export function getFormattedISODateRange(date: Date | moment.Moment, range: moment.unitOfTime.StartOf) {
    const startDate = moment(date).startOf(range).toISOString();
    const endDate = moment(date).endOf(range).toISOString();

    return { startDate, endDate };
}

export function getFormattedISODateOnlyRange(date: Date | moment.Moment, range: moment.unitOfTime.StartOf) {
    const startDate = moment(date).startOf(range).format("YYYY-MM-DD");
    const endDate = moment(date).endOf(range).format("YYYY-MM-DD");

    return { startDate, endDate };
}

// Get max of an array of dates
export function getMaxDate(dates: (Date | null | undefined)[]): Date | undefined {
    let maxDate: Date | undefined = undefined;
    dates.forEach(date => {
        if (date && (!maxDate || date > maxDate)) {
            maxDate = date;
        }
    });

    return maxDate;
}

export function getMinMaxDate(dates: (Date | null | undefined)[]): {
    min: Date | undefined;
    max: Date | undefined;
} {
    let min: Date | undefined = undefined;
    let max: Date | undefined = undefined;
    dates.forEach(date => {
        if (!date) {
            return;
        }
        if (!min || date < min) {
            min = date;
        }
        if (!max || date > max) {
            max = date;
        }
    });
    return { min, max };
}
