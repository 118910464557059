import * as React from "react";
import { CenteredContent } from "../../ui/CenteredContent";
import { SupportRouter } from "../router/SupportRouter";
import { companiesStore } from "../../../stores/CompaniesStore";

export const SupportContainerSite = () => {
    if (!companiesStore.selectedCompanyId) {
        return null;
    }

    return <SupportRouter />;
};
