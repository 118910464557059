import React from "react";
import { FormattedHTMLMessage } from "react-intl";

export const SearchBarLabel = (props: { count: number; label: string }) => {
    return (
        <p className="caption" style={{ marginTop: 8 }}>
            <FormattedHTMLMessage
                id={props.label}
                values={{
                    count: props.count,
                }}
            />
        </p>
    );
};
