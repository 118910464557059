import { useEffect, useState } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { InsuranceCarrier } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useInsuranceCarriers = (companyId: string | undefined) => {
    const [carriers, setCarriers] = useState<InsuranceCarrier[]>();

    useEffect(() => {
        const load = async () => {
            if (!companyId) {
                return;
            }
            try {
                const response = await API.getInsuranceCarriers(companyId);
                setCarriers(response.insuranceCarriers);
            } catch (err) {
                generalStore.setError(t("error.loadInsuranceCarriers"), err);
            }
        };

        load();
    }, [companyId]);

    return carriers;
};
