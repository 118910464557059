import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import { forwardRef } from "react";
import { customColors } from "../util/Theme";

const WIDTH = 28;
const HEIGHT = 16;

// Taken directly from here: https://material-ui.com/components/switches/
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: WIDTH,
            height: HEIGHT,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: `translateX(${Math.ceil(WIDTH / 2.65)}px)`,
                color: customColors.white,
                "& + $track": {
                    backgroundColor: customColors.primaryColor,
                    opacity: 1,
                    border: "none",
                },
                "&$disabled + $track": {
                    opacity: 0.12,
                },
            },
            "&$focusVisible $thumb": {
                color: customColors.primaryColor,
                border: `${WIDTH / 7}px solid #fff`,
            },
        },
        thumb: {
            width: HEIGHT - 2,
            height: HEIGHT - 2,
        },
        track: {
            borderRadius: HEIGHT / 2,
            // border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: customColors.greyTextfields,
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"]),
        },
        checked: {},
        disabled: {},
        focusVisible: {},
    }),
);

export const IOSSwitch = forwardRef<HTMLButtonElement, SwitchProps>(function IOSSwitch(props, ref) {
    const classes = useStyles();
    return (
        <Switch
            ref={ref}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
                disabled: classes.disabled,
            }}
            {...props}
        />
    );
});
