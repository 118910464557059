import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Ticket } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { Module } from "../../types/models";

export const useRecordTicket = ({
    companyId,
    module,
    periodId,
    subsidiaryId,
    recordTypeId,
    recordId,
    showError,
}: {
    companyId?: string;
    module?: Module;
    periodId?: string;
    subsidiaryId?: string;
    recordTypeId?: string;
    recordId?: string;
    showError?: boolean;
}) => {
    const [ticket, setTicket] = React.useState<Ticket | null>(null);

    React.useEffect(() => {
        const load = async () => {
            if (!companyId || !module || !periodId || !subsidiaryId || !recordTypeId || !recordId) {
                return;
            }

            try {
                const response = await API.getRecordTicket({
                    companyId,
                    module,
                    periodId,
                    subsidiaryId,
                    recordTypeId,
                    recordId,
                });
                setTicket(response);
            } catch (err) {
                if (showError !== false) {
                    generalStore.setError(t("error.loadTicketDetails"), err);
                }
            }
        };

        load();
    }, [companyId, module, periodId, recordId, recordTypeId, showError, subsidiaryId]);

    return ticket;
};
