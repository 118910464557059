import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { companiesStore } from "../../../stores/CompaniesStore";
import { Routes } from "../../app/router/Routes";
import { KPIsCurrentCompany } from "../../cockpit/kpis/KPIs";
import { CenteredContent } from "../../ui/CenteredContent";
import { SiteContent } from "../../ui/SiteContent";

export const SettingsCompanyKPIsSite = observer(function SettingsCompanyKPIsSite() {
    if (!companiesStore.selectedCompanyStore) {
        return null; // not loaded yet, keep waiting
    }

    const kpiStore = companiesStore.selectedCompanyStore.kpiStore;
    if (!kpiStore.canRead) {
        return <Redirect to={Routes.ROOT} />;
    }

    return (
        <CenteredContent>
            <SiteContent style={{ paddingTop: 40 }}>
                <KPIsCurrentCompany />
            </SiteContent>
        </CenteredContent>
    );
});
