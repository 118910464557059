import * as React from "react";
import { customColors } from "../util/Theme";

export const TextDivider = (props: { text: string; style?: React.CSSProperties }) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                ...props.style,
            }}
        >
            <p className="caption" style={{ color: customColors.greyButton }}>
                {props.text}
            </p>
            <div
                style={{
                    borderBottom: `1px solid ${customColors.greyLight}`,
                    flexGrow: 1,
                    marginLeft: 10,
                }}
            />
        </div>
    );
};
