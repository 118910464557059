import compact from "lodash/compact";
import difference from "lodash/difference";
import fromPairs from "lodash/fromPairs";
import intersection from "lodash/intersection";
import uniq from "lodash/uniq";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../i18n/util";
import {
    Gender,
    Permission,
    PermissionActionsEnum,
    PermissionGroupEnum,
    Permissions,
    PermissionsRequest,
    PermissionsSubsidiaries,
    Role,
    RoleRequest,
    UserPermissions,
} from "../network/APITypes";
import { IConfigUser } from "../stores/ConfigStore";
import { IPermissions, Module } from "../types/models";
import { containSameEntries } from "./array";
import { debug } from "./debug";
import {
    PermissionCardinality,
    PermissionModule,
    PermissionShort,
    getMostPermissiveActionsForPermissionGroup,
    permissionOptionsMap,
    permissionShortsMap,
    permissionTree,
    roleMapping,
} from "./permissionConfig";
import { getRecordTypeName } from "./recordTypes";
import { getFullName } from "./user";

export const actionsToPermissionShort = (actions: PermissionActionsEnum[]): PermissionShort | undefined => {
    const permissionShorts = Object.keys(permissionShortsMap) as (keyof typeof permissionShortsMap)[];

    const permissionShort = permissionShorts.find(key => {
        const entry = permissionShortsMap[key];
        return (
            entry.actions.length === actions.length && intersection(actions, entry.actions).length === actions.length
        );
    });

    return permissionShort;
};

export const actionsToShortString = (actions?: PermissionActionsEnum[]): string => {
    if (!actions || actions.length === 0) {
        return t("permission.option.none");
    }

    // Go from most permissive to least permissive action (so if a user canEdit and canRead
    // you will get the canEdit string)
    if (containsActions(actions, permissionShortsMap.canUpload.actions)) {
        return t(permissionShortsMap.canUpload.label);
    } else if (containsActions(actions, permissionShortsMap.canEdit.actions)) {
        return t(permissionShortsMap.canEdit.label);
    } else if (containsActions(actions, permissionShortsMap.canUpdate.actions)) {
        return t(permissionShortsMap.canUpdate.label);
    } else if (containsActions(actions, permissionShortsMap.canRelease.actions)) {
        return t(permissionShortsMap.canRelease.label);
    } else if (containsActions(actions, permissionShortsMap.canRead.actions)) {
        return t(permissionShortsMap.canRead.label);
    }

    // Fallback: simply return action list
    return actions.join(", ");
};

const getPermissionGroupString = (group: PermissionGroupEnum) => {
    const entry = permissionOptionsMap[group];
    if (entry) {
        return t(entry.label);
    }
    return undefined;
};

function getGlobalPermissionGroupsForModule(module: PermissionModule) {
    const section = permissionTree.find(entry => entry.module === module);
    if (section) {
        const global = section.groups.find(group => group.type === "global");
        if (global) {
            return global.order;
        }
    }
    return undefined;
}

function getSubsidiaryPermissionGroupsForModule(module: PermissionModule) {
    const section = permissionTree.find(entry => entry.module === module);
    if (section) {
        const subsidiary = section.groups.find(group => group.type === "subsidiary");
        if (subsidiary) {
            // record permission group is not on the subsidiary, but on the record type
            return subsidiary.order.filter(group => !group.includes("records"));
        }
    }
    return undefined;
}

export function getSubsidiaryPermissions(permissions: Permissions | undefined, subsidiaryId?: string) {
    return permissions?.subsidiaries?.find(subsidiary => subsidiary.id === subsidiaryId);
}

export function getPermissionModuleString(module: PermissionModule) {
    switch (module) {
        case "accounting":
            return t("common.accounting");
        case "hr":
            return t("common.hr");
        case "settings":
            return t("common.settings");
        case "generalDocuments":
            return t("common.generalDocuments");
        case "projects":
            return t("common.projects");
    }
}

// staff and international-projects are special roles not configurable in FE -> filter them for
// role selection.
export function filterRealRoles(roles: Role[]): Role[] {
    return roles.filter(r => r !== "international-projects" && r !== "staff");
}

export function getRoleString({ role, gender, asJobTitle }: { role?: Role; gender?: Gender; asJobTitle?: boolean }) {
    if (!role) {
        return t("common.unknown");
    }

    const mapping = roleMapping[role];
    if (!mapping) {
        debug.error("### roleMapping not defined", role);
        return t("common.unknown");
    }

    if (asJobTitle && "jobTitle" in mapping && mapping.jobTitle) {
        if (gender === "female" && "jobTitleFemale" in mapping && mapping.jobTitleFemale) {
            return t(mapping.jobTitleFemale);
        } else {
            return t(mapping.jobTitle);
        }
    }

    if (gender === "female" && "female" in mapping && mapping.female) {
        return t(mapping.female);
    } else {
        return t(mapping.male);
    }
}

export function getRolesString({
    roles,
    gender,
    fallback,
    asJobTitle,
}: {
    roles?: Role[];
    gender?: Gender;
    fallback?: string;
    asJobTitle?: boolean;
}) {
    if (!roles) {
        return fallback ?? t("common.unknown");
    }

    // Is only role "none"?
    const onlyNone = roles.length === 1 && roles[0] === "none";

    // Filter "none" but only if we have at least one other role
    let filteredRoles = onlyNone ? roles : roles.filter(r => r !== "none");

    // TPAPORTAL-2414: "chat" and "chat secret" should not be displayed. To make sure
    // something is displayed only do this if the user still has more than one role
    if (filteredRoles.length > 1) {
        filteredRoles = filteredRoles.filter(r => r !== "tpa-chat" && r !== "tpa-chat-secret");
    }

    if (filteredRoles.length > 1) {
        filteredRoles = filteredRoles.filter(r => r !== "international-projects");
    }

    // If array -> join roles with comma
    return filteredRoles.map(r => getRoleString({ role: r, gender, asJobTitle })).join(", ");
}

export function hasRole({ roles, requested }: { roles?: Role | Role[]; requested: Role }) {
    if (!roles) {
        return false;
    }

    if (Array.isArray(roles)) {
        let found = false;
        for (let i = 0; i < roles.length && !found; ++i) {
            const role = roles[i];
            found = role === requested;
        }
        return found;
    } else {
        return roles === requested;
    }
}

// Get an array of modules for which permissions exist
export const getPermissionModules = (permissions?: Permissions): PermissionModule[] => {
    if (!permissions) {
        return [];
    }

    let globalModules: PermissionModule[] = [];
    if (permissions.global) {
        globalModules = compact(
            permissions.global.map(permission => permissionOptionsMap[permission.group]?.permissionModule),
        );
    }
    const localModules: Module[] = [];
    if (permissions.subsidiaries) {
        permissions.subsidiaries.forEach(subsidiary => {
            if (subsidiary.module && hasAnySubsidiaryPermission(subsidiary)) {
                localModules.push(subsidiary.module);
            }
        });
    }

    return uniq(globalModules.concat(localModules));
};

export const getModulesWithRecordTypePermission = (
    permissions: Permissions | undefined,
    permissionShort: PermissionShort,
): PermissionModule[] => {
    if (!permissions) {
        return [];
    }

    const localModules: Module[] = [];
    if (permissions.subsidiaries) {
        permissions.subsidiaries.forEach(subsidiary => {
            if (subsidiary.module && hasAnyRecordTypeWithPermission(subsidiary, permissionShort)) {
                localModules.push(subsidiary.module);
            }
        });
    }

    return uniq(localModules);
};

// Get an array of global modules for which permissions exist
export const getGlobalModules = (user: Permissions): PermissionModule[] => {
    let globalModules: PermissionModule[] = [];
    if (user.global) {
        globalModules = compact(
            user.global.map(permission => permissionOptionsMap[permission.group]?.permissionModule),
        );
    }

    const modules = uniq(globalModules);
    // if (!GLOBAL_FEATURES.kpis) {
    //     modules = modules.filter(m => m !== "expenses");
    // }

    return modules;
};

export const getPermissionSubsidiaries = (user: Permissions, module: Module): PermissionsSubsidiaries[] => {
    let subsidiaries: PermissionsSubsidiaries[] = [];
    if (user.subsidiaries) {
        subsidiaries = user.subsidiaries.filter(subsidiary => subsidiary.module === module);
    }

    return subsidiaries;
};

// Check if user has all permissions for included groups in subsidiary
// Does NOT return false if not all groups are present, only completeness WITHIN the given groups
const hasAllSubsidiaryPermissionsInGroups = (subsidiary: PermissionsSubsidiaries) => {
    if (subsidiary.permissions) {
        const complete = arePermissionsComplete(subsidiary.permissions);
        if (!complete) {
            debug.log("### subsidiary incomplete\n");
            return false;
        }
    }

    if (subsidiary.recordTypes) {
        subsidiary.recordTypes.forEach(recordType => {
            const complete = arePermissionsComplete([recordType.permission]);
            if (!complete) {
                debug.log("### recordType incomplete\n");
                return false;
            }
        });
    }

    return true;
};

export function combineCardinality(lhs: PermissionCardinality, rhs: PermissionCardinality) {
    if (lhs === "all" && rhs === "all") {
        return "all";
    }

    if (lhs === "none" && rhs === "none") {
        return "none";
    }

    return "some";
}

export const canReadSubsidiary = (module: Module, subsidiary: PermissionsSubsidiaries): PermissionCardinality => {
    return hasSubsidiaryPermissions(module, subsidiary, ["read"]);
};

export const canEditSubsidiary = (module: Module, subsidiary: PermissionsSubsidiaries): PermissionCardinality => {
    return hasSubsidiaryPermissions(module, subsidiary);
};

// Returns true it <permissions> are present in every subsidiary and recordType permissions in the hierarchy
// If no <permissions> are provided then check the most permissive permissions
const hasSubsidiaryPermissions = (
    module: Module,
    subsidiary: PermissionsSubsidiaries,
    permissions?: PermissionActionsEnum[],
): PermissionCardinality => {
    // First check if we can read all record types, if some -> return some
    const allowedRecordTypes = hasRecordTypePermissions(subsidiary, permissions);
    if (allowedRecordTypes === "some") {
        return "some";
    }

    const groups = getSubsidiaryPermissionGroupsForModule(module);
    // No group permissions defined -> only record permissions count
    if (!groups) {
        return allowedRecordTypes;
    }

    // No subsidiary permissions -> only record permissions count
    if (!subsidiary.permissions || subsidiary.permissions.length === 0) {
        // Groups are non empty, but subsidiary has no permissions -> subsidiary permissions = "none",
        // otherwise both are empty -> "all"
        return combineCardinality(allowedRecordTypes, groups.length > 0 ? "none" : "all");
    }

    // Now check if we can read the subsidiary permissions that are NOT within a recordType
    let numAllowed = 0;
    subsidiary.permissions.forEach(permission => {
        const actions = permission.actions;
        const neededActions = permissions ?? getMostPermissiveActionsForPermissionGroup(permission.group);
        if (containsActions(actions, neededActions)) {
            numAllowed++;
        }
    });

    // Calculate cardinality for subsidiary permissions
    let allowedSubsidiary: PermissionCardinality = "some";
    if (numAllowed === 0) {
        allowedSubsidiary = "none";
    } else if (numAllowed === groups.length) {
        allowedSubsidiary = "all";
    }

    // ... now combine them for final return value
    const ret = combineCardinality(allowedRecordTypes, allowedSubsidiary);
    return ret;
};

export const canReadRecordTypes = (subsidiary: PermissionsSubsidiaries): PermissionCardinality => {
    return hasRecordTypePermissions(subsidiary, ["read"]);
};

export const canEditRecordTypes = (subsidiary: PermissionsSubsidiaries): PermissionCardinality => {
    return hasRecordTypePermissions(subsidiary);
};

// Returns true it <permissions> are present in every recordType permissions in the hierarchy
// If no <permissions> are provided then check the most permissive permissions
const hasRecordTypePermissions = (
    subsidiary: PermissionsSubsidiaries,
    permissions?: PermissionActionsEnum[],
): PermissionCardinality => {
    if (!subsidiary.recordTypes?.length) {
        return "none";
    }

    let numAllowed = 0;
    subsidiary.recordTypes.forEach(recordType => {
        // Check for each record type if read action is present
        const actions = recordType.permission.actions;
        const neededActions = permissions ?? getMostPermissiveActionsForPermissionGroup(recordType.permission.group);
        if (containsActions(actions, neededActions)) {
            numAllowed++;
        }
    });

    // debug.log("### num readable", readable);

    if (numAllowed === 0) {
        return "none";
    } else if (numAllowed === subsidiary.recordTypes?.length) {
        return "all";
    }

    return "some";
};

export function getGlobalModulePermissions(module: PermissionModule, user: UserPermissions): Permission[] {
    if (!user.global) {
        return [];
    }

    return user.global.filter(permission => permissionOptionsMap[permission.group]?.permissionModule === module);
}

// Returns true if the user has all permissions for a module
export function hasAllGlobalPermissions(module: PermissionModule, user: UserPermissions) {
    const availableGroups = getGlobalPermissionGroupsForModule(module)?.length ?? 0;
    if (availableGroups === 0) {
        debug.error(`### Module ${module} has no available groups`);
        return false;
    }

    const modulePermissions = getGlobalModulePermissions(module, user);

    // Check if we have all groups
    if (modulePermissions.length < availableGroups) {
        return false;
    }

    // Now check if user has all permissions within the groups
    let hasAll = modulePermissions.length > 0;
    for (let i = 0; i < modulePermissions.length; ++i) {
        const permission = modulePermissions[i];
        const neededActions = getMostPermissiveActionsForPermissionGroup(permission.group);
        if (!containsActions(permission.actions, neededActions)) {
            debug.log(
                `### modulePermissions mismatch for ${permission.group}. User has [${permission.actions.toString()}], expected [${neededActions.toString()}]`,
            );
            hasAll = false;
            break;
        }
    }

    return hasAll;
}

// Returns true if <actions> contains <actionSubset>
export function containsActions(actions: PermissionActionsEnum[], actionSubset: PermissionActionsEnum[]) {
    return difference(actionSubset, actions).length === 0;
}

export function permissionShortContainsActions(
    permissionShort: PermissionShort | undefined,
    actionSubset: PermissionActionsEnum[],
) {
    if (!permissionShort) {
        return false;
    }

    const actions = permissionShortsMap[permissionShort].actions;
    return containsActions(actions, actionSubset);
}

// Check if given permissions are complete within the contained groups of a given permission array
const arePermissionsComplete = (permissions: Permission[], username?: string) => {
    let complete = true;
    for (let i = 0; i < permissions.length; ++i) {
        const p = permissions[i];
        const neededActions = getMostPermissiveActionsForPermissionGroup(p.group);
        if (!neededActions) {
            debug.error("### missing permission actions for", p.group);
        } else {
            if (!containsActions(p.actions, neededActions)) {
                debug.log(
                    `### incomplete ${p.group}: "${username ?? "<unknown>"}" has [${p.actions.toString()}], most permissive is [${neededActions.toString()}]`,
                );
                complete = false;
                break;
            }
        }
    }

    return complete;
};

// Check if user has all permissions for included groups
export const hasAllPermissionsInGroups = (user: UserPermissions) => {
    const username = getFullName(user);

    if (user.global) {
        const complete = arePermissionsComplete(user.global, username);
        if (!complete) {
            // debug.log("### global incomplete\n");
            return false;
        }
    }

    if (user.subsidiaries) {
        user.subsidiaries.forEach(subsidiary => {
            const subsidiaryComplete = hasAllSubsidiaryPermissionsInGroups(subsidiary);
            if (!subsidiaryComplete) {
                return false;
            }
        });
    }

    return true;
};

export const isSuperAdmin = (user: UserPermissions) => {
    return user.roles?.includes("super-admin");
};

export const getNumPermissions = (permissions?: IPermissions) => {
    if (!permissions) {
        return 0;
    }

    const numGlobal = permissions.global?.length ?? 0;
    // debug.log("### global", numGlobal);
    let allSubsidiaries = 0;
    permissions.subsidiaries?.forEach(subsidiary => {
        const numSubsidiary = subsidiary.permissions?.length ?? 0;
        const numRecordType = subsidiary.recordTypes?.length ?? 0;

        // debug.log("### subsidiary", subsidiary.name, numSubsidiary, numRecordType);
        allSubsidiaries += numSubsidiary + numRecordType;
    });

    return numGlobal + allSubsidiaries;
};

// Check if user has any permission in this subsidiary
const hasAnySubsidiaryPermission = (subsidiary: PermissionsSubsidiaries) => {
    let hasAnyPermission = (subsidiary.permissions?.length ?? 0) > 0;
    if (!hasAnyPermission && subsidiary.recordTypes) {
        for (let j = 0; j < subsidiary.recordTypes.length && !hasAnyPermission; ++j) {
            hasAnyPermission = subsidiary.recordTypes[j].permission.actions.length > 0;
        }
    }

    return hasAnyPermission;
};

/**
 * Check if any subsidiary has specific group actions.
 * E.g. `hasAnySubsidiaryWithGroupActions(permissions, "hr:reports", ["read"])` for checking
 * if user can read any reports of a subsidiary
 */
export const hasAnySubsidiaryWithGroupActions = (
    permissions: IPermissions | undefined,
    group: PermissionGroupEnum,
    actions: PermissionActionsEnum[],
) => {
    if (!permissions?.subsidiaries || permissions.subsidiaries.length === 0) {
        return false;
    }

    let hasAny = false;
    for (let i = 0; i < permissions.subsidiaries.length && !hasAny; ++i) {
        const subsidiary = permissions.subsidiaries[i];
        if (subsidiary.permissions && subsidiary.permissions.length > 0) {
            for (let j = 0; j < subsidiary.permissions.length && !hasAny; ++j) {
                const permission = subsidiary.permissions[j];
                hasAny = permission.group === group && containsActions(permission.actions, actions);
            }
        }
    }

    return hasAny;
};

/**
 * Check if any subsidiary has a specific group permission.
 * E.g. `hasAnySubsidiaryWithGroupPermission(permissions, "hr:reports", "canRead")` for checking
 * if user can read any reports of a subsidiary
 */
export const hasAnySubsidiaryWithGroupPermission = (
    permissions: IPermissions | undefined,
    group: PermissionGroupEnum,
    permissionShort: PermissionShort,
) => {
    return hasAnySubsidiaryWithGroupActions(permissions, group, permissionShortToActions(permissionShort));
};

const permissionShortToActions = (permissionShort: PermissionShort) => permissionShortsMap[permissionShort].actions;

export const hasAnyRecordTypeWithActions = (subsidiary: PermissionsSubsidiaries, actions: PermissionActionsEnum[]) => {
    let found = false;
    if (subsidiary.recordTypes) {
        for (let j = 0; j < subsidiary.recordTypes.length && !found; ++j) {
            found = containsActions(subsidiary.recordTypes[j].permission.actions, actions);
        }
    }

    return found;
};

export const recordTypeHasActions = (
    subsidiary: PermissionsSubsidiaries,
    recordTypeId: string,
    actions: PermissionActionsEnum[],
) => {
    const recordType = subsidiary.recordTypes?.find(rt => rt.id === recordTypeId);
    if (!recordType) {
        return false;
    }
    return containsActions(recordType.permission.actions, actions);
};

export const hasAnyRecordTypeWithPermission = (subsidiary: PermissionsSubsidiaries, permissionShort: PermissionShort) =>
    hasAnyRecordTypeWithActions(subsidiary, permissionShortToActions(permissionShort));

export const hasAllPermissions = (user: UserPermissions): PermissionCardinality => {
    if (isSuperAdmin(user)) {
        return "all";
    } else {
        let hasAnyPermission = false;
        hasAnyPermission = (user.global?.length ?? 0) > 0;

        if (user.subsidiaries) {
            for (let i = 0; i < user.subsidiaries.length && !hasAnyPermission; ++i) {
                hasAnyPermission = hasAnySubsidiaryPermission(user.subsidiaries[i]);
            }
        }

        if (!hasAnyPermission) {
            return "none";
        }
    }

    return "some";
};

export const getApplyRoleButtonText = (isConfigRoute: boolean, isSuperAdmin: boolean, selectedAreUsers: boolean) => {
    if (isConfigRoute) {
        return t("common.apply");
    }

    if (isSuperAdmin) {
        if (selectedAreUsers) {
            return t("common.apply");
        } else {
            return t("config.roles.submit.superAdmin");
        }
    } else {
        if (selectedAreUsers) {
            return t("config.roles.submit.existingUser");
        } else {
            return t("config.roles.submit");
        }
    }
};

export const getSuccessDialogTitle = (isSuperAdmin: boolean, selectedAreUsers: boolean) => {
    if (isSuperAdmin) {
        if (selectedAreUsers) {
            return <FormattedHTMLMessage id="userManagement.successDialog.existingUser.superAdmin.title" />;
        } else {
            return <FormattedHTMLMessage id="userManagement.successDialog.newUser.superAdmin.title" />;
        }
    } else {
        if (selectedAreUsers) {
            return <FormattedHTMLMessage id="userManagement.successDialog.existingUser.title" />;
        } else {
            return <FormattedHTMLMessage id="userManagement.successDialog.newUser.title" />;
        }
    }
};

export const getSuccessDialogMessage = (
    isSuperAdmin: boolean,
    selectedAreUsers: boolean,
    selectedEmployees: IConfigUser[],
) => {
    if (selectedEmployees.length > 0) {
        const count = selectedEmployees.length;
        const userName = getFullName(selectedEmployees[0]);

        if (isSuperAdmin) {
            if (selectedAreUsers) {
                if (count === 1) {
                    return (
                        <FormattedHTMLMessage
                            id="userManagement.successDialog.existingUser.superAdmin.single.message"
                            values={{ userName }}
                        />
                    );
                } else {
                    return (
                        <FormattedHTMLMessage
                            id="userManagement.successDialog.existingUser.superAdmin.multi.message"
                            values={{ count }}
                        />
                    );
                }
            } else {
                if (count === 1) {
                    return (
                        <FormattedHTMLMessage
                            id="userManagement.successDialog.newUser.superAdmin.single.message"
                            values={{ userName }}
                        />
                    );
                } else {
                    return (
                        <FormattedHTMLMessage
                            id="userManagement.successDialog.newUser.superAdmin.multi.message"
                            values={{ count }}
                        />
                    );
                }
            }
        } else {
            return <FormattedHTMLMessage id="userManagement.successDialog.message" />;
        }
    }

    return <FormattedHTMLMessage id="userManagement.successDialog.message" />;
};

const toPermissionsObject = (permissionItems?: Permission[]) =>
    permissionItems
        ? fromPairs(
              permissionItems.map(permissionItem => {
                  const short = actionsToPermissionShort(permissionItem.actions);
                  // Keep this: I need it for debugging TPAPORTAL-1017 and similar issues
                  //   if (!short && permissionItem.actions.length > 0) {
                  //       debug.log(
                  //           "### Could not match actions to permissionShort. This combination is not configurable using the Permissions UI.",
                  //           permissionItem.group,
                  //           permissionItem.actions,
                  //       );
                  //   }

                  return [permissionItem.group, { short, actions: permissionItem.actions }];
              }),
          )
        : {};

export const permissionsToMap = (permissions?: Permissions) => {
    if (!permissions) {
        return null;
    }

    return {
        global: {
            permissions: toPermissionsObject(permissions.global),
        },
        roles: permissions.roles ?? [],
        subsidiaries: permissions.subsidiaries
            ? fromPairs(
                  permissions.subsidiaries.map(subsidiary => [
                      subsidiary.id,
                      {
                          permissions: toPermissionsObject(subsidiary.permissions),
                          recordTypes: subsidiary.recordTypes
                              ? fromPairs(
                                    subsidiary.recordTypes.map(recordType => [
                                        recordType.id,
                                        {
                                            permissions: toPermissionsObject([recordType.permission]),
                                        },
                                    ]),
                                )
                              : {},
                      },
                  ]),
              )
            : {},
    };
};

export type IPermissionMap = ReturnType<typeof permissionsToMap>;

export function permissionsHasAnyTPARole(permissions?: IPermissions) {
    return permissions?.roles.some(r => r.startsWith("tpa-")) ?? false;
}

/** Advisor (`tpa-advisor`), accountants (`tpa-accounting`) and HR (`tpa-hr`) are managing TPA roles */
export function permissionsHasManagingTPARole(permissions?: IPermissions) {
    return permissions?.roles.some(r => r === "tpa-advisor" || r === "tpa-accounting" || r === "tpa-hr") ?? false;
}

export function permissionsIsAdvisor(permissions?: IPermissions) {
    return permissions?.roles?.includes("tpa-advisor") ?? false;
}

export function permissionsCanChat(permissions?: IPermissions) {
    return permissions?.roles?.includes("tpa-chat") ?? false;
}

export function permissionsCanChatSecret(permissions?: IPermissions) {
    return permissions?.roles?.includes("tpa-chat-secret") ?? false;
}

export function permissionsIsSuperAdmin(permissions?: IPermissions) {
    return permissions?.roles?.includes("super-admin") ?? false;
}

export function permissionsIsStaff(permissions?: IPermissions) {
    return permissions?.roles?.includes("staff") ?? false;
}

export const injectProjectPermissionChanges = (user: Partial<UserPermissions>) => {
    if (user.projectPermissions && user.projectPermissions.length > 0) {
        if (!user.changes) {
            user.changes = {
                roles: user.roles ?? [],
                global: [],
            };
        }

        if (!user.changes?.global) {
            user.changes.global = [];
        }

        const containsProject = user.changes.global.find(p => p.group === "projects");
        if (!containsProject) {
            user.changes.global.push({
                group: "projects",
                actions: ["read", "ticketRead", "ticketCreate"],
            });
        }
    }
};

export const getPermissionChanges = (user: Pick<UserPermissions, "roles" | "changes" | "projectPermissions">) => {
    // Convert project invitations to visible permission changes
    injectProjectPermissionChanges(user);

    const hasRoleChange = user.changes?.roles && user.changes?.roles.length > 0;

    const permissionChangedText = (actions: PermissionActionsEnum[]) =>
        t("config.permission.change", { permission: actionsToShortString(actions) });

    const changes: { name?: string; change: string }[] = [];

    if (hasRoleChange) {
        changes.push({
            change: t("config.roles.change", {
                oldRole: getRoleString({ role: user.roles[0] }),
                newRole: getRoleString({ role: user.changes?.roles[0] }),
            }),
        });
    }

    const getChangeText = (p: Permission) => {
        return `${getPermissionGroupString(p.group) ?? p.group}: ${permissionChangedText(p.actions)}`;
    };

    if (user.changes?.global) {
        user.changes.global.forEach(permission =>
            changes.push({
                change: getChangeText(permission),
            }),
        );
    }

    // List projects the user has been invited to
    if (user.projectPermissions && user.projectPermissions.length > 0) {
        user.projectPermissions.forEach(project => {
            changes.push({
                name: t("project.invitation"),
                change: project.name ?? "-",
            });
        });
    }

    if (user.changes?.subsidiaries) {
        user.changes.subsidiaries.forEach(subsidiary => {
            let first = true;

            if (subsidiary.permissions) {
                subsidiary.permissions.forEach(permission => {
                    changes.push({
                        name: first ? subsidiary.name : undefined,
                        change: getChangeText(permission),
                    });
                    first = false;
                });
            }

            if (subsidiary.recordTypes) {
                subsidiary.recordTypes.forEach(recordType => {
                    const recordTypeName =
                        (subsidiary.module && getRecordTypeName(subsidiary.module, recordType)) ?? recordType.name;
                    changes.push({
                        name: first ? subsidiary.name : undefined,
                        change: `${recordTypeName ?? ""}: ${permissionChangedText(recordType.permission.actions)}`,
                    });
                    first = false;
                });
            }
        });
    }

    return changes;
};

function isGroupOption(permission: Permission) {
    if (permission.actions.length === 0) {
        return true;
    }

    const entry = permissionOptionsMap[permission.group];
    if (!entry) {
        return false;
    }
    for (let i = 0; i < entry.options.length; ++i) {
        if (containSameEntries(permissionShortsMap[entry.options[i]].actions, permission.actions)) {
            return true;
        }
    }

    return false;
}

// What this function does: we walk the whole permission tree for a predefined
// role that we received from the backend and check for each permission if
// it matches with one of the permission options displayed in the UI.
// If not then we have a problem because this means a role has as preset defined on the BE
// which we cannot map to an option in the UI
//
// E.g. recordType has mapped "kann einsehen" = ["read"] and "kann hochladen" = ["read", "create", "update", "delete", "release", "ticketRead"]
// Now if we receive a role with ["read", "create", "update", "delete", "ticketRead"] then we cannot map it to
// a valid option in the UI.
export function areValidRolePermissions(rolePermissions: PermissionsRequest, role: RoleRequest) {
    const checkPermissionArray = (permissionArray?: Permission[]) => {
        if (permissionArray) {
            for (let i = 0; i < permissionArray.length; ++i) {
                const permission = permissionArray[i];
                if (!isGroupOption(permission)) {
                    console.error(
                        `### Role "${role}": Permission "${permission.group}" = [${permission.actions.toString()}] is not a valid frontend group option`,
                    );
                    return false;
                }
            }
        }

        return true;
    };

    if (!checkPermissionArray(rolePermissions.global)) {
        return false;
    }

    if (rolePermissions.subsidiaries) {
        for (let i = 0; i < rolePermissions.subsidiaries.length; ++i) {
            const subsidiaryPermissions = rolePermissions.subsidiaries[i];
            if (!checkPermissionArray(subsidiaryPermissions.permissions)) {
                return false;
            }

            if (subsidiaryPermissions.recordTypes) {
                for (let j = 0; j < subsidiaryPermissions.recordTypes.length; ++j) {
                    const permission = subsidiaryPermissions.recordTypes[j].permission;
                    if (!isGroupOption(permission)) {
                        console.error(
                            `### Role "${role}": Permission "${permission.group}" = [${permission.actions.toString()}] is not a valid frontend group option`,
                        );
                        return false;
                    }
                }
            }
        }
    }

    return true;
}

// Return true if any action in the permission is in any of the actions arrays
export const hasAnyAction = (permissions: PermissionsRequest): boolean => {
    if (permissions.global) {
        for (let i = 0; i < permissions.global.length; ++i) {
            if (permissions.global[i].actions.length > 0) {
                return true;
            }
        }
    }

    if (permissions.subsidiaries) {
        for (let i = 0; i < permissions.subsidiaries.length; ++i) {
            const subsidiaryPermissions = permissions.subsidiaries[i];
            if (subsidiaryPermissions.permissions) {
                for (let j = 0; j < subsidiaryPermissions.permissions.length; ++j) {
                    if (subsidiaryPermissions.permissions[j].actions.length > 0) {
                        return true;
                    }
                }
            }

            if (subsidiaryPermissions.recordTypes) {
                for (let j = 0; j < subsidiaryPermissions.recordTypes.length; ++j) {
                    const permission = subsidiaryPermissions.recordTypes[j].permission;
                    if (permission.actions.length > 0) {
                        return true;
                    }
                }
            }
        }
    }

    return false;
};
