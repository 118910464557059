import React from "react";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { OverviewContainer } from "./Primitives";

interface OverviewSectionProps {
    title: string;
    onEdit?: () => void;
    children: React.ReactNode;
    button?: React.ReactNode;
    editDisabled?: boolean;
    noBackground?: boolean;
}

export const OverviewSection = ({
    title,
    onEdit,
    children,
    button,
    editDisabled,
    noBackground,
}: OverviewSectionProps) => {
    const isMobile = React.useContext(MobileContext);
    return (
        <OverviewContainer fullWidth={isMobile} noBackground={noBackground}>
            <div
                style={{
                    display: "flex",
                    marginBottom: 16,
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                }}
            >
                <h3>{title}</h3>
                <div style={{ flex: 1 }}> </div>
                {button}
                {onEdit && (
                    <div style={{ marginLeft: 16 }}>
                        <Icon
                            name="pen"
                            style={
                                editDisabled
                                    ? { color: customColors.greyButton, display: "block" }
                                    : { color: customColors.primaryColor, cursor: "pointer", display: "block" }
                            }
                            onClick={editDisabled ? undefined : onEdit}
                            role="button"
                            data-id={"edit_" + title}
                        />
                    </div>
                )}
            </div>
            {children}
        </OverviewContainer>
    );
};
