import take from "lodash/take";
import { observer } from "mobx-react";
import * as React from "react";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useResponsibleUsers } from "../../hooks/useResponsibleUsers";
import { Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";
import { TpaBadge } from "../Primitives";
import { User } from "../User";

export const FaceToFaceWidget = observer(function FaceToFaceWidget(props: {
    onClick?: () => void;
    style?: React.CSSProperties;
}) {
    const companyId =
        companiesStore.canAccessCompany && companiesStore.selectedCompanyId
            ? companiesStore.selectedCompanyId
            : undefined;
    const { users } = useResponsibleUsers({ companyId });
    const partners = users.partners;

    // Only show partners in the widget
    const responsibleUsersSuggestion = take(partners, 2);
    const handleClickContact = () => {
        props.onClick?.();
        if (GLOBAL_FEATURES.chat) {
            pushRoute(Routes.SUPPORT);
        } else {
            generalStore.openSupportDialog(users);
        }
    };

    const handleClickContactUser = (id: string) => () => {
        props.onClick?.();
        if (GLOBAL_FEATURES.chat) {
            pushRoute(Routes.SUPPORT, { query: { selectedContactId: id } });
        } else {
            generalStore.openSupportDialog(users, id);
        }
    };

    const badgeCount = companiesStore.selectedCompanyStore?.badgeCounts.unreadChatMessages ?? 0;

    return (
        <div style={{ padding: "0 16px", ...props.style }}>
            <div style={{ border: `1px solid ${customColors.greyLight}`, borderRadius: 4 }}>
                <div
                    role="button"
                    style={{
                        fontWeight: 700,
                        borderBottom: `1px solid ${customColors.greyLight}`,
                        padding: "12px 0 16px 0",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onClick={handleClickContact}
                    data-id="faceToFace_title"
                >
                    {t("sidebar.faceToFace.title")}
                    {badgeCount > 0 && <TpaBadge style={{ marginLeft: 8 }}>{badgeCount}</TpaBadge>}
                </div>
                <div style={{ padding: "0 12px" }}>
                    {responsibleUsersSuggestion.map((user, index) => (
                        <div
                            role="button"
                            onClick={handleClickContactUser(user.id)}
                            style={{ cursor: "pointer", marginTop: 16 }}
                            key={user.id}
                        >
                            <User
                                firstName={user.firstName}
                                lastName={user.lastName}
                                imageUrl={user.profile_picture_url}
                                isTpaStaff={user.isTpaEmployee}
                                data-id={`faceToFace_user_${index}`}
                                smallName
                            />
                        </div>
                    ))}
                </div>
                <div
                    role="button"
                    className="caption"
                    style={{
                        color: customColors.headingsDark,
                        height: 32,
                        backgroundColor: customColors.primaryShade,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        marginTop: 16,
                    }}
                    onClick={handleClickContact}
                    data-id="faceToFace_contact_button"
                >
                    <Icon name="send" style={{ color: customColors.primaryColor, marginRight: 8 }} />
                    {t("sidebar.faceToFace.button")}
                </div>
            </div>
        </div>
    );
});
