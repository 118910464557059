import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { pushRoute } from "../app/router/history";
import { ModuleMainResponsible } from "../shared/ModuleMainResponsible";
import { ExpansionCard } from "../ui/ExpansionCard";
import { AccountingRoutes } from "./router/AccountingRoutes";

interface IProps {
    expanded: boolean;
    onExpand: (isExpanded: boolean) => void;
    expired: boolean;
    "data-id": string;
}

export const AccountingBankConnectionExpirationCard = observer(function AccountingBankConnectionExpirationCard({
    expanded,
    onExpand,
    expired,
    "data-id": dataId,
}: IProps) {
    const handleNavigateToBankConnections = () => {
        pushRoute(AccountingRoutes.BANKING.CONNECTIONS.ROOT);
    };

    return (
        <ExpansionCard
            data-id={dataId}
            expanded={expanded}
            onExpand={onExpand}
            title={
                expired
                    ? t("screen.accounting.overview.bankConnection.expired.title")
                    : t("screen.accounting.overview.bankConnection.title")
            }
            status={expired ? "overdue" : "open"}
            iconName="accounting"
        >
            <ModuleMainResponsible module={"accounting"} />
            <p style={{ marginTop: 16 }}>
                <FormattedHTMLMessage
                    id={
                        expired
                            ? "screen.accounting.overview.bankConnection.expired"
                            : "screen.accounting.overview.bankConnection"
                    }
                    values={{
                        salutation: authStore.greeting,
                        signature: companiesStore.mainResponsibleSignatureName("accounting"),
                    }}
                />
            </p>
            <div style={{ paddingBottom: 16, alignSelf: "flex-end" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNavigateToBankConnections}
                    data-id="register_deregister_button"
                >
                    {t("screen.accounting.overview.bankConnections.refreshBankConnections")}
                </Button>
            </div>
        </ExpansionCard>
    );
});
