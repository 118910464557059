import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { newsStore } from "../../../stores/NewsStore";
import { CockpitNavBar } from "../CockpitNavBar";
import { CockpitNewsContent } from "../CockpitNewsContent";
import { CockpitRoutes } from "../router/CockpitRoutes";

export const CockpitNewsArticleSite = observer(function CockpitNewsArticleSite() {
    const location = useLocation();
    const { newsId, eventId } = useParams<{ newsId?: string; eventId?: string }>();

    const isPromotion = newsStore.promotions.find(promotion => promotion.id === newsId);
    const isEvent = newsStore.events.find(event => event.id === eventId);

    const comingFromAllNewsSite = location.pathname.startsWith(CockpitRoutes.NEWS_LIST);
    const comingFromAllEventsSite = location.pathname.startsWith(CockpitRoutes.EVENTS_LIST);

    const navBarProps = {
        title: t("screen.cockpit.relevantNews"),
        backTarget: comingFromAllNewsSite ? CockpitRoutes.NEWS_LIST : CockpitRoutes.ROOT,
        backLabel: comingFromAllNewsSite ? t("screen.cockpit.allNews") : t("common.cockpit"),
        ...(isPromotion
            ? {
                  title: t("screen.cockpit.promotion"),
              }
            : {}),
        ...(isEvent
            ? {
                  title: t("screen.cockpit.eventDetail"),
                  backTarget: comingFromAllEventsSite ? CockpitRoutes.EVENTS_LIST : CockpitRoutes.ROOT,
                  backLabel: comingFromAllEventsSite ? t("screen.cockpit.allEvents") : t("common.cockpit"),
              }
            : {}),
    };

    return (
        <>
            <CockpitNavBar {...navBarProps} />
            <CockpitNewsContent
                rightColumnTitle={
                    isPromotion
                        ? t("screen.cockpit.promotion.additionalNews")
                        : t("screen.cockpit.relevantNews.additionalNews")
                }
            />
        </>
    );
});
