import { useProfitAndLoss } from "./hooks/useProfitAndLoss";
import { ResultsTable } from "./ResultsTable";
import { ResultsSiteViewProps } from "./types";

interface ResultsProfitAndLossProps extends ResultsSiteViewProps {}

export const ResultsProfitAndLoss = ({ companyId, settings, financialAccountancy }: ResultsProfitAndLossProps) => {
    const state = useProfitAndLoss(companyId, settings);
    return state && financialAccountancy ? (
        <ResultsTable
            type="profitAndLoss"
            columns={state.columns}
            rows={state.rows}
            stylesheet={state.stylesheet}
            financialAccountancy={financialAccountancy}
        />
    ) : null;
};
