import * as queryString from "query-string";
import { history } from "../components/app/router/history";
import { BASE_NAME } from "../config";

/** Get basePath for location */
export function getBasePath(pathname: string) {
    // window.location.pathname contains BASE_NAME, history.location.pathname does not!
    // So just in case we try to strip the base name from the pathname and remove any erroneous double slashes

    const withoutBaseName = pathname.replace(BASE_NAME, "").replace(new RegExp("//", "g"), "/");

    const components = withoutBaseName.split("/");
    if (components.length < 2) {
        return "/";
    }

    return "/" + components[1];
}

export function getDeepLinkInfo() {
    const module = getBasePath(history.location.pathname).slice(1);
    const query = queryString.parse(window.location.search) as {
        companyId?: string;
        subsidiaryId?: string;
        periodId?: string;
    };
    const isDeepLink =
        typeof query.companyId === "string" ||
        typeof query.subsidiaryId === "string" ||
        typeof query.periodId === "string";

    return {
        module,
        query,
        isDeepLink,
    };
}

export type IDeepLinkInfo = ReturnType<typeof getDeepLinkInfo>;
