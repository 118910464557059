import * as React from "react";
import { customColors } from "../util/Theme";

export const CustomDivider = () => {
    return (
        <div
            style={{
                alignSelf: "flex-end",
                borderBottom: `1px solid ${customColors.greyLight}`,
                width: "100%",
            }}
        />
    );
};
