import * as React from "react";
import { Button } from "@material-ui/core";
import { Icon, IIconNames } from "../util/Icon";
import styled from "styled-components";
import { customColors } from "../util/Theme";
import { t } from "../../i18n/util";
import { User } from "../ui/User";
import { EventsFeedEntry } from "../../network/APITypes";
import { formatDate } from "../../util/helpers";

interface IProps {
    event: EventsFeedEntry;
}

const EventDetailsContainer = styled.div`
    padding: 24px;
    background-color: ${customColors.white};
    border-radius: 4px;
    box-shadow: 0px 3px 6px rgba(54, 75, 141, 0.1);
`;

const EventDetail = ({
    icon,
    onClick,
    children,
}: {
    icon: IIconNames;
    onClick?: () => void;
    children: React.ReactNode;
}) => (
    <div
        role={onClick ? "button" : undefined}
        onClick={onClick}
        style={{ display: "flex", alignItems: "center", marginTop: 16, cursor: onClick ? "pointer" : undefined }}
    >
        <div>
            <Icon name={icon} style={{ display: "block", color: customColors.greyDarkIcons }} />
        </div>
        <p style={{ color: customColors.body2Dark, marginLeft: 8 }}>{children}</p>
    </div>
);

export const CockpitEventDetailsCard = ({ event }: IProps) => {
    const handleClickLocation = () => {
        if (event.location) {
            window.open(
                `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.location)}`,
                "_blank",
            );
        }
    };

    const handleClickRegister = () => {
        if (event.optionalLink) {
            window.open(event.optionalLink, "_blank");
        }
    };

    const lecturers = event.lecturers?.map(lecturer => {
        const nameParts = lecturer.name.split(" ");

        return (
            <div key={lecturer.id} style={{ display: "flex", alignItems: "flex-start", marginTop: 16 }}>
                <User
                    firstName={nameParts[0] ?? ""}
                    lastName={nameParts[1] ?? ""}
                    showAvatarOnly
                    imageUrl={lecturer.profilePicture}
                />
                <div style={{ marginLeft: 16 }}>
                    <p style={{ fontWeight: 700 }}>{lecturer.name}</p>
                    <p className="caption">
                        {lecturer.position}
                        {lecturer.email && (
                            <>
                                <br />
                                {t("screen.cockpit.eventDetails.lecturer.email", {
                                    email: lecturer.email,
                                })}
                            </>
                        )}
                        {lecturer.telephone && (
                            <>
                                <br />
                                {t("screen.cockpit.eventDetails.lecturer.telephone", {
                                    telephone: lecturer.telephone,
                                })}
                            </>
                        )}
                    </p>
                </div>
            </div>
        );
    });

    return (
        <EventDetailsContainer>
            <h4>{t("screen.cockpit.eventDetails.heading")}</h4>
            <EventDetail icon="hours">{formatDate(event.eventDate)}</EventDetail>
            {event.location && (
                <EventDetail onClick={handleClickLocation} icon="pin">
                    {event.location}
                </EventDetail>
            )}
            {lecturers && lecturers.length > 0 && (
                <>
                    <p style={{ fontWeight: 700, marginTop: 24 }}>{t("screen.cockpit.eventDetails.lecturers")}</p>
                    {lecturers}
                </>
            )}
            {event.optionalLink && (
                <Button
                    onClick={handleClickRegister}
                    color="primary"
                    variant="contained"
                    fullWidth
                    style={{ marginTop: 24 }}
                >
                    {t("screen.cockpit.eventDetails.register")}
                </Button>
            )}
        </EventDetailsContainer>
    );
};
