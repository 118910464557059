import { useCallback, useState } from "react";
import { POPUP_WINDOW_FEATURES } from "../../../config";
import { API } from "../../../network/API";
import { BankAccount, BankConnection, PublicHttpError } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { MISSING_REQUEST_DATA, useAPI } from "../../hooks/useAPI";
import { useExternalWindow } from "../../hooks/useExternalWindow";
import { useBankConnectionsPolling } from "./useBankConnectionsPolling";

interface Options {
    periodId?: string;
    startDate?: string;
    endDate?: string;
    includeInvoices?: boolean;
    shouldMatchInvoicesAndTransactions?: boolean;
    skipIfAssignedToInvoice?: boolean;
    updateTransactions?: boolean;
}

export function useBankAccountTransactions(
    companyId: string | undefined,
    bankConnectionId: number | undefined,
    bankAccountId: number | undefined,
    options: Options = {},
) {
    const {
        periodId,
        startDate,
        endDate,
        includeInvoices,
        shouldMatchInvoicesAndTransactions,
        skipIfAssignedToInvoice,
        updateTransactions,
    } = options;

    const [forceReload, setForceReload] = useState(0);
    const { startPolling } = useBankConnectionsPolling(() => {
        setForceReload(forceReload + 1);
    });
    const externalWindow = useExternalWindow();
    const openExternalWindow = externalWindow.open;

    const loader = useCallback(async () => {
        void forceReload; // used to trigger a reload when the bank connections change

        if (!companyId || !bankConnectionId || isNaN(bankConnectionId) || !bankAccountId || isNaN(bankAccountId)) {
            return MISSING_REQUEST_DATA;
        }

        if (!periodId && (!startDate || !endDate)) {
            return MISSING_REQUEST_DATA;
        }

        try {
            const response = await API.getBankAccountTransactions(companyId, bankConnectionId, bankAccountId, {
                periodID: periodId,
                startDate,
                endDate,
                includeInvoices,
                shouldMatchInvoicesAndTransactions: shouldMatchInvoicesAndTransactions,
                skipIfAssignedToInvoice,
                updateTransactions,
            });
            return response.transactions;
        } catch (error) {
            const apiError = getApiError<PublicHttpError>(error);
            if (apiError?.response.type === "UPDATE_BANK_ACCOUNT_TRANSACTIONS_REQUIRES_USER_INTERACTION") {
                const url: unknown = apiError.response.additionalData?.url;
                if (typeof url === "string" && url) {
                    startPolling((updateStartedAt, connections) =>
                        bankConnectionUpdated(updateStartedAt, bankConnectionId, connections),
                    );
                    openExternalWindow(url, "TPA", POPUP_WINDOW_FEATURES);

                    return MISSING_REQUEST_DATA; // stop loading. Once the user has updated the transactions, the `forceReload` will trigger another load
                }
            }

            throw error;
        }
    }, [
        bankAccountId,
        bankConnectionId,
        companyId,
        endDate,
        forceReload,
        includeInvoices,
        shouldMatchInvoicesAndTransactions,
        periodId,
        skipIfAssignedToInvoice,
        startDate,
        startPolling,
        updateTransactions,
        openExternalWindow,
    ]);
    const result = useAPI(loader, { generalStoreErrorMessage: "error.loadBankAccountTransactions" });
    return { result, externalWindow };
}

function bankConnectionUpdated(
    updateStartedAt: Date,
    bankConnectionId: number,
    connections: BankConnection[],
): boolean {
    const connection = connections.find(c => c.bankConnectionId === bankConnectionId);
    if (!connection) {
        return false;
    }

    return (
        connection.updateStatus === "READY" &&
        !!connection.lastUpdatedAt &&
        new Date(connection.lastUpdatedAt) > updateStartedAt &&
        bankConnectionAccountsUpdated(updateStartedAt, connection.accounts ?? [])
    );
}

function bankConnectionAccountsUpdated(updateStartedAt: Date, accounts: BankAccount[]): boolean {
    return accounts.every(acc => {
        return (
            (acc.status === "UPDATED" || acc.status === "UPDATED_FIXED" || acc.status === "DOWNLOAD_FAILED") &&
            !!acc.lastSuccessfulUpdate &&
            new Date(acc.lastSuccessfulUpdate) > updateStartedAt
        );
    });
}
