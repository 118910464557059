import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Report } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { Module } from "../../types/models";
import { pushRoute } from "../app/router/history";
import { useTransferSuccessDialog } from "../hooks/useTransferSuccessDialog";
import { DocumentCategory } from "../hooks/useWebhookConfiguration";
import { ModuleConfiguration, useWebhookModuleConfiguration } from "../hooks/useWebhookModuleConfiguration";
import { DocumentLine } from "../ui/DocumentLine/DocumentLine";
import { IUpload } from "../ui/DocumentUpload";
import { DocumentUploadSmall, IAcceptedFile, useDocumentUploadSmall } from "../ui/DocumentUploadSmall";
import { AdvisorRoutes } from "./router/AdvisorRoutes";

export const AdvisorReportUpload = observer(function AdvisorReportUpload({
    module,
    category,
}: {
    module: Module;
    category: DocumentCategory;
}) {
    const companyId = companiesStore.selectedCompanyId;
    if (!companyId) {
        return <Redirect to={AdvisorRoutes.COMPANIES.ACTIVE} />;
    }

    return <AdvisorReportUploadComponent module={module} category={category} companyId={companyId} />;
});

const AdvisorReportUploadComponent = observer(function AdvisorReportUploadComponent({
    module,
    category,
    companyId,
}: {
    module: Module;
    category: DocumentCategory;
    companyId: string;
}) {
    const onSubmit = async () => {
        const success = await documentUploadConfig.uploadFiles(documentUploadConfig.acceptedFiles);
        if (success) {
            successDialog.openDialog();
        }
    };

    const configuration = useWebhookModuleConfiguration({
        companyId,
        company: companiesStore.selectedCompany,
        module,
    });

    const successDialog = useTransferSuccessDialog(t("dialog.transferReports.success.message"), () => {
        pushRoute(AdvisorRoutes.UPLOADS.ROOT);
    });

    const uploadFile = async (file: IAcceptedFile<{ needsApproval: boolean }>) => {
        if (!configuration.periodId || !companyId) {
            generalStore.setError(t("error.upload"));
            return;
        }

        try {
            return await API.postReport({
                companyId,
                module,
                periodId: configuration.periodId,
                subsidiaryId: configuration.globalReport ? undefined : configuration.subsidiaryId,
                file: file.file,
                needsRelease: file.metaData?.needsApproval === true,
                reportType: category === "billing" ? "billing" : undefined,
                sendEmail: configuration.notifyCustomer,
            });
        } catch (error) {
            generalStore.setError(t("error.upload"), error);
        }
    };

    const documentUploadConfig = useDocumentUploadSmall(uploadFile, true);

    const renderSuccess = (upload: IUpload<Report>) => {
        return <DocumentLine fileName={upload.file.name} key={upload.file.name} />;
    };

    const renderAccepted = (file: IAcceptedFile, onDelete: () => void) => {
        return (
            <DocumentLine
                fileName={file.file.name}
                showReleaseOptions
                onChangeReleaseOption={option => {
                    file.metaData = { option };
                }}
                onDelete={onDelete}
                key={file.file.name}
            />
        );
    };

    if (!configuration.initialized || !companyId) {
        return null;
    }

    return (
        <>
            <ModuleConfiguration {...configuration} onSubmit={onSubmit}>
                <DocumentUploadSmall
                    renderSuccess={renderSuccess}
                    renderAccepted={renderAccepted}
                    config={documentUploadConfig}
                />
            </ModuleConfiguration>
            {successDialog.component}
        </>
    );
});
