import { TextField } from "@material-ui/core";
import { useEffect } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { apiClient } from "../../network/APIClient";
import { generalStore } from "../../stores/GeneralStore";
import { getTextCharset } from "../../util/mime";
import { useQueryParams } from "../hooks/useQueryParams";

export const Comment = ({
    value,
    onChange,
    placeholder,
    shrinkLabel,
}: {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    shrinkLabel?: boolean;
}) => {
    const query: { comment?: string; commentArchive?: string; commentDocumentId?: string } = useQueryParams();

    useEffect(() => {
        if (query.comment) {
            onChange(query.comment);
        }
    }, [onChange, query.comment]);

    useEffect(() => {
        let cancelled = false;

        const load = async (archiveId: string, documentId: string) => {
            try {
                const download = await API.downloadExternalDocuments(archiveId, [documentId]);
                if (cancelled) {
                    return;
                }
                const response = await apiClient.apiClient.performFetch(download.downloadUrl, {});
                if (cancelled) {
                    return;
                }
                let text;
                const mimeType = response.headers.get("content-type");
                const charset = mimeType ? getTextCharset(mimeType) : null;
                if (charset && charset !== "utf-8") {
                    // comment files in NTCS might have a different encoding, e.g. iso-8859-1.
                    // convert them to utf-8 before displaying them in our utf-8 page.
                    const buffer = await response.arrayBuffer();
                    if (cancelled) {
                        return;
                    }
                    text = new TextDecoder(charset).decode(buffer);
                } else {
                    text = await response.text();
                }

                onChange(text);
            } catch (error) {
                if (cancelled) {
                    return;
                }
                generalStore.setError(t("screen.advisor.uploads.upload.comment.downloadError"), error);
            }
        };

        if (query.commentArchive && query.commentDocumentId) {
            load(query.commentArchive, query.commentDocumentId);
        }

        return () => {
            cancelled = true;
        };
    }, [onChange, query.commentArchive, query.commentDocumentId]);

    return (
        <TextField
            value={value}
            onChange={event => {
                onChange(event.target.value);
            }}
            variant="outlined"
            label={t("screen.advisor.uploads.upload.comment")}
            placeholder={placeholder}
            fullWidth
            multiline
            style={{ marginTop: 40 }}
            inputProps={{ style: { minHeight: 50 /* 3 lines */ } }}
            InputLabelProps={shrinkLabel ? { shrink: true } : undefined}
        />
    );
};
