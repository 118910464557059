import { t } from "../../../i18n/util";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { CenteredPanel } from "./Helper";

export const ErrorRenderer = ({ error }: { error: unknown }) => {
    let message: string;
    const apiError = getApiError(error);
    if (
        apiError?.statusCode === HttpStatusCode.Locked_423 &&
        apiError.response.type === "DOCUMENT_LOCKED_TRANSFER_IN_PROGRESS"
    ) {
        message = t("error.download.transferInProgress");
    } else if (error instanceof Error) {
        message = error.message || error.toString();
    } else {
        message = t("error.download");
    }

    return (
        <CenteredPanel panelStyle={{ textAlign: "center" }}>
            <h3 style={{ marginBottom: 8 }}>{t("error.loading")}</h3>
            {message ? <p>{message}</p> : null}
        </CenteredPanel>
    );
};
