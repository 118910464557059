import { Dialog, DialogProps } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";

interface IProps {
    showCloseIcon?: boolean;
    mobileFullScreen?: boolean; // default is true
    onClose?: () => void;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

const CloseIcon = ({ onClick }: { onClick?: () => void }) => (
    <Icon name="close" style={{ color: customColors.primaryColor, cursor: "pointer" }} onClick={onClick} />
);

export const CustomDialogContent = styled.div`
    padding: 42px;
`;

export const CustomDialogTitle = styled.h1`
    margin-bottom: 24px;
`;

export const CustomDialogActions = styled(ResponsiveButtonContainer)`
    margin-top: 24px;
`;

export const CustomDialog = ({
    showCloseIcon,
    mobileFullScreen,
    onClose,
    children,
    maxWidth,
    ...props
}: DialogProps & IProps) => {
    const isMobile = React.useContext(MobileContext);

    return (
        <Dialog
            maxWidth={maxWidth ?? "md"}
            fullWidth
            fullScreen={isMobile && mobileFullScreen !== false}
            onClose={onClose}
            {...props}
            onClick={e => {
                // keep the clicks inside the dialog, avoid "onClick" calls in parent components (e.g. a list or table)
                e.stopPropagation();
            }}
        >
            {isMobile && onClose && showCloseIcon && (
                <CustomDialogContent style={{ paddingBottom: 0 }}>
                    <CloseIcon onClick={onClose} />
                </CustomDialogContent>
            )}
            {!isMobile && onClose && showCloseIcon && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 24,
                        paddingBottom: 0,
                    }}
                >
                    <CloseIcon onClick={onClose} />
                </div>
            )}
            {children}
        </Dialog>
    );
};
