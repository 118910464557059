import { Button, Radio } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { t } from "../../../../i18n/util";
import { BankAccountTransaction } from "../../../../network/APITypes";
import { generalStore } from "../../../../stores/GeneralStore";
import { TableStore, useInMemoryTableStore } from "../../../../stores/TableStore";
import { formatDate, formatISODateOnly, toCurrency } from "../../../../util/helpers";
import { useTableFilters } from "../../../hooks/useTableFilters";
import { BoldCurrency } from "../../../results/ResultsValue";
import { EmptyState } from "../../../ui/EmptyState";
import { ResponsiveButtonContainer } from "../../../ui/ResponsiveButtonContainer";
import { TableSearchBarWithAction } from "../../../ui/TableSearchBar";
import { DateRange, NumberRange } from "../../../ui/filter/types";
import { BOX_SHADOW_LIGHT } from "../../../util/Theme";
import { useBankAccountTransactions } from "../../hooks/useBankAccountTransactions";
import { getRecipientParts } from "../BankAccountTransactionsSite";
import { AssignInvoicesToBankAccountTransactionsState, BankConnectionAndAccount, Dependencies } from "./types";

const BankAccountTransactionsRoot = styled.div`
    flex: 1;
    overflow: auto;
    margin-bottom: 16px;
    .mobile & {
        max-height: 100vh; // limit the height of the table to avoid excessive scrolling to reach the "Apply" button
    }
`;

export const useBankAccountTransactionsTable = () => {
    const tableStoreRef = useRef<TableStore<BankAccountTransaction>>();
    if (!tableStoreRef.current) {
        tableStoreRef.current = new TableStore<BankAccountTransaction>(
            { orderBy: "bankBookingDate", orderDir: "asc" },
            "BankAccountTransactionsTable",
        );
    }
    const tableStore = tableStoreRef.current;

    const [transaction, setTransaction] = useState<BankAccountTransaction>();

    const { state } = useLocation<AssignInvoicesToBankAccountTransactionsState | undefined>();
    const initialBankAccountTransactionId = state?.bankAccountTransactionId;

    useEffect(() => {
        const disposer = autorun(() => {
            const transactions = tableStore.items;
            setTransaction(transaction => {
                if (transaction) {
                    const newTransaction = transactions.find(t => t.id === transaction.id);
                    if (newTransaction) {
                        return newTransaction;
                    }
                }
                if (initialBankAccountTransactionId) {
                    const newTransaction = transactions.find(t => t.id === initialBankAccountTransactionId);
                    if (newTransaction) {
                        return newTransaction;
                    }
                }
                if (transactions.length > 0) {
                    return transactions[0];
                }
                return undefined;
            });
        });
        return () => {
            disposer();
        };
    }, [initialBankAccountTransactionId, tableStore]);

    const removeTransaction = useCallback(
        (id: number) => {
            const index = tableStore.items.findIndex(t => t.id === id);
            if (index >= 0) {
                tableStore.items.splice(index, 1);
                const nextIndex = index < tableStore.items.length ? index : index - 1;
                setTransaction(tableStore.items[nextIndex]);
            }
            tableStore.totalCount--;
        },
        [tableStore],
    );

    return {
        tableStore,
        transaction,
        setTransaction,
        removeTransaction,
    };
};

const searchFn = (transaction: BankAccountTransaction, search: string) => {
    const recipientParts = getRecipientParts(transaction);
    return (
        !!transaction.purpose?.toLocaleLowerCase().includes(search) ||
        !!recipientParts?.name?.toLocaleLowerCase().includes(search) ||
        !!recipientParts?.iban?.toLocaleLowerCase().includes(search) ||
        !!recipientParts?.bic?.toLocaleLowerCase().includes(search) ||
        false
    );
};

export const BankAccountTransactionsTable = observer(function BankAccountTransactionsTable({
    bankConnectionAndAccount,
    tableStore,
    tableFilters,
    transaction,
    setTransaction,
    accountComponent,
    deps,
    onClickAssign,
}: {
    bankConnectionAndAccount: BankConnectionAndAccount | undefined;
    tableStore: TableStore<BankAccountTransaction>;
    tableFilters: ReturnType<typeof useTableFilters>;
    transaction: BankAccountTransaction | undefined;
    setTransaction: (transaction: BankAccountTransaction) => void;
    accountComponent: React.ReactNode;
    deps: Dependencies;
    onClickAssign: () => void;
}) {
    const { companyId } = deps;

    const dateRange = tableFilters.activeFilters.dateRange?.value as DateRange | undefined;

    const { result, externalWindow } = useBankAccountTransactions(
        companyId,
        bankConnectionAndAccount?.bankConnection.bankConnectionId,
        bankConnectionAndAccount?.bankAccount.accountId,
        {
            startDate: dateRange?.from ? formatISODateOnly(dateRange.from) : undefined,
            endDate: dateRange?.to ? formatISODateOnly(dateRange.to) : undefined,
            skipIfAssignedToInvoice: true,
            updateTransactions: true,
            shouldMatchInvoicesAndTransactions: false, // don't do it here because the user is going to assign invoices manually
        },
    );

    const filterFn = useCallback(
        (transaction: BankAccountTransaction) => {
            const amount = tableFilters.activeFilters.amount?.value as NumberRange | undefined;
            if ((amount?.from && transaction.amount < amount.from) || (amount?.to && transaction.amount > amount.to)) {
                return false;
            }
            return true;
        },
        [tableFilters.activeFilters.amount],
    );

    useInMemoryTableStore({
        tableStore,
        items: result,
        searchFn,
        filterFn,
    });

    const listRef = useRef<HTMLDivElement>(null);

    const selectedTransactionId = transaction?.id;

    useLayoutEffect(() => {
        if (selectedTransactionId) {
            listRef.current
                ?.querySelector(`[data-id="${selectedTransactionId.toString()}"]`)
                ?.scrollIntoView({ block: "nearest" });
        }
    }, [selectedTransactionId]);

    let content;

    if (tableStore.getIsEmptyState(generalStore.isLoading, !isEmpty(tableFilters.activeFilters))) {
        content = <EmptyState title={t("accounting.bankAccount.transactions.table.emptyState.title")} />;
    } else if (tableStore.getIsNoResultState(generalStore.isLoading, !isEmpty(tableFilters.activeFilters))) {
        content = <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />;
    } else {
        content = (
            <>
                <BankAccountTransactionsRoot ref={listRef}>
                    {tableStore.items.map(transaction => {
                        return (
                            <BankTransaction
                                key={transaction.id}
                                transaction={transaction}
                                selected={transaction.id === selectedTransactionId}
                                onSelect={setTransaction}
                            />
                        );
                    })}
                </BankAccountTransactionsRoot>
                {/* always display, maybe with : none */}
                {accountComponent}
                <ResponsiveButtonContainer style={{ marginTop: 16 }}>
                    <Button color="primary" variant="contained" onClick={onClickAssign}>
                        {t("common.apply")}
                    </Button>
                </ResponsiveButtonContainer>
                {externalWindow.components}
            </>
        );
    }

    return (
        <>
            <TableSearchBarWithAction
                label="accounting.bankAccount.transactions.table.search.count"
                placeholder="accounting.bankAccount.transactions.table.search.placeholder"
                search={tableStore.search}
                totalCount={tableStore.items.length}
                onChangeSearch={tableStore.handleSearchChange}
                tableFilters={tableFilters}
                showTableFiltersAsButton
            />
            {content}
        </>
    );
});

const BankTransactionRoot = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    margin: 4px 8px 8px 4px;
    padding: 16px 16px 16px 0px;
    border-radius: 4px;
    box-shadow: ${BOX_SHADOW_LIGHT};
    cursor: pointer;
`;

const BankTransaction = ({
    transaction,
    selected,
    onSelect,
}: {
    transaction: BankAccountTransaction;
    selected: boolean;
    onSelect: (transaction: BankAccountTransaction) => void;
}) => {
    return (
        <BankTransactionRoot
            onClick={() => {
                onSelect(transaction);
            }}
            data-id={transaction.id}
        >
            <Radio checked={selected} color="primary" style={{ marginTop: -12 /* align with text next to it */ }} />
            <div style={{ flex: 1 }}>
                <div>{transaction.counterpartName}</div>
                <p className="caption" style={{ marginTop: 4 }}>
                    {formatDate(transaction.bankBookingDate)} • {transaction.purpose}
                </p>
            </div>
            <BoldCurrency
                value={transaction.amount}
                currency={transaction.currency ? toCurrency(transaction.currency) : undefined}
                currencyPosition="start"
            />
        </BankTransactionRoot>
    );
};
