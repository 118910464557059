import {
    Button,
    CircularProgress,
    Divider,
    Drawer,
    Fab,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    SwipeableDrawer,
} from "@material-ui/core";
import debounce from "lodash/debounce";
import { observer } from "mobx-react";
import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { NOTIFICATION_CENTER_SCROLL_LOAD_THRESHOLD, NOTIFICATION_CENTER_WIDTH } from "../../config";
import { t } from "../../i18n/util";
import { AppNotification, NotificationModule, NotificationType } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { isAppNotification } from "../../util/appNotifications";
import { formatDate } from "../../util/helpers";
import { useNotifications } from "../hooks/useNotifications";
import { useTableFilters } from "../hooks/useTableFilters";
import { EmptyState } from "../ui/EmptyState";
import { TpaBadge } from "../ui/Primitives";
import { BOOLEAN_FILTER_TYPE, DROPDOWN_FILTER_TYPE, ITableFilterCategory } from "../ui/filter/types";
import { IIconNames, Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { ZINDEX_DRAWER_OFFSET, customColors, theme } from "../util/Theme";

const NotificationCenterElementsWrapper = styled.div`
    position: fixed;
    right: 10px;
    z-index: ${theme.zIndex.speedDial};
`;

export const NotificationCenterElements = observer(function NotificationCenterElements() {
    const [open, setOpen] = useState(false);

    const notificationCount =
        companiesStore.selectedCompanyStore?.badgeCounts.unreadNotifications ?? companiesStore.totalUnreadNotifications;

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const isMobile = React.useContext(MobileContext);

    if (authStore.isStaffOnly) {
        return null;
    }

    return (
        <>
            <div style={{ position: "relative" }}>
                <Fab color="primary" onClick={handleOpen}>
                    <Icon name="cockpit" />
                </Fab>
                {notificationCount > 0 && (
                    <div
                        style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            display: "inline-block",
                        }}
                    >
                        <TpaBadge>{notificationCount}</TpaBadge>
                    </div>
                )}
            </div>

            {isMobile ? (
                <SwipeableDrawer
                    anchor="right"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    style={{ zIndex: ZINDEX_DRAWER_OFFSET }} // zIndex 51 to stay above mui drawer and footer
                    PaperProps={{ style: { width: "100%" } }}
                >
                    <NotificationCenter onClose={handleClose} isMobile />
                </SwipeableDrawer>
            ) : (
                <Drawer anchor="right" open={open} onClose={handleClose} style={{ zIndex: ZINDEX_DRAWER_OFFSET }}>
                    <NotificationCenter onClose={handleClose} />
                </Drawer>
            )}
        </>
    );
});

interface FilterOption<T> {
    value: T;
    label: string;
}

const NotificationCenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100%;
`;

const NotificationCenter = observer(function NotificationCenter({
    onClose,
    isMobile,
}: {
    onClose: () => void;
    isMobile?: boolean;
}) {
    const [module, setModule] = useState<NotificationModule>();
    const [type, setType] = useState<NotificationType>();

    const moduleFilterOptions: FilterOption<NotificationModule>[] = [
        {
            value: "accounting",
            label: t("notificationCenter.filter.accounting"),
        },
        {
            value: "hr",
            label: t("notificationCenter.filter.hr"),
        },
        {
            value: "project",
            label: t("notificationCenter.filter.project"),
        },
        {
            value: "general",
            label: t("notificationCenter.filter.general"),
        },
    ];

    const typeFilterOptions: FilterOption<NotificationType>[] = [
        {
            value: "login",
            label: t("notificationCenter.filter.login"),
        },
        {
            value: "report",
            label: t("notificationCenter.filter.report"),
        },
        {
            value: "generalDocument",
            label: t("notificationCenter.filter.generalDocument"),
        },
        {
            value: "employeeDocument",
            label: t("notificationCenter.filter.employeeDocument"),
        },
        {
            value: "invoice",
            label: t("notificationCenter.filter.invoice"),
        },
        {
            value: "payslip",
            label: t("notificationCenter.filter.payslip"),
        },
        {
            value: "project",
            label: t("notificationCenter.filter.project"),
        },
        {
            value: "ticket",
            label: t("notificationCenter.filter.ticket"),
        },
        {
            value: "appNotification:newFeature",
            label: t("notificationCenter.filter.appNotification:newFeature"),
        },
        {
            value: "appNotification:maintenance",
            label: t("notificationCenter.filter.appNotification:maintenance"),
        },
        {
            value: "appNotification:update",
            label: t("notificationCenter.filter.appNotification:update"),
        },
        {
            value: "other",
            label: t("notificationCenter.filter.other"),
        },
    ];

    const filterItems: ITableFilterCategory[] = [
        {
            category: "types",
            entries: [
                {
                    name: "companySpecific",
                    label: t("notificationCenter.filter.companySpecific"),
                    type: BOOLEAN_FILTER_TYPE,
                    disabled: !companiesStore.selectedCompanyId,
                },
                {
                    name: "unread",
                    label: t("notificationCenter.filter.unread"),
                    type: BOOLEAN_FILTER_TYPE,
                },
                {
                    name: "module",
                    label: t("notificationCenter.filter.module"),
                    type: DROPDOWN_FILTER_TYPE,
                    onChange: (module: string) => {
                        setModule(module as NotificationModule);
                    },
                    options: moduleFilterOptions,
                },

                {
                    name: "notificationType",
                    label: t("notificationCenter.filter.notificationType"),
                    type: DROPDOWN_FILTER_TYPE,
                    onChange: (type: string) => {
                        setType(type as NotificationType);
                    },
                    options: typeFilterOptions,
                },
            ],
        },
    ];

    const filters = useTableFilters({
        filters: filterItems,
        onChangeFilters: selected => {
            if (!selected.notificationType) {
                setType(undefined);
            }
            if (!selected.module) {
                setModule(undefined);
            }
        },
        initialFilters: companiesStore.selectedCompanyId
            ? {
                  companySpecific: { value: true },
              }
            : undefined,
    });

    const isCompanySpecific = filters.isActive("companySpecific");

    const { loadAdditionalNotifications, ...notifications } = useNotifications({
        read: !filters.isActive("unread"),
        companyId: isCompanySpecific ? companiesStore.selectedCompanyId : undefined,
        module: module,
        type: type,
    });

    const scrollTarget = useRef<HTMLDivElement | null>(null);

    const debounceHandleScroll = useMemo(
        () =>
            debounce(e => {
                if (!scrollTarget.current) {
                    return;
                }

                const target = scrollTarget.current;
                const remainingScroll = target.scrollHeight - (target.offsetHeight + target.scrollTop);

                if (remainingScroll < NOTIFICATION_CENTER_SCROLL_LOAD_THRESHOLD) {
                    loadAdditionalNotifications();
                }
            }, 100),
        [loadAdditionalNotifications],
    );

    const handleNotificationClick = (ev: React.MouseEvent<HTMLAnchorElement>, notification: AppNotification) => {
        if (isAppNotification(notification)) {
            ev.preventDefault();
            ev.stopPropagation();

            generalStore.appNotifications = [notification];
            return;
        }

        notifications.markNotificationAsRead(notification.id);
    };

    return (
        <NotificationCenterWrapper style={{ width: isMobile ? "100%" : NOTIFICATION_CENTER_WIDTH }}>
            <div style={{ padding: "24px 16px", display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <h2>{t("notificationCenter.title")}</h2>
                    {isMobile && (
                        <div style={{ alignSelf: "flex-end" }}>
                            <Icon
                                name="close"
                                style={{ color: customColors.primaryColor, cursor: "pointer", display: "block" }}
                                onClick={onClose}
                            />
                        </div>
                    )}
                </div>
                {filters.Buttons}
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 8 }}
                    onClick={notifications.markAllNotificationsAsRead}
                >
                    {t("notificationCenter.markAllAsRead")}
                </Button>
            </div>
            <Divider style={{ marginTop: 8 }} />

            <List onScroll={debounceHandleScroll} ref={scrollTarget} component={"div"} style={{ overflowY: "auto" }}>
                {notifications.notifications?.map(notification => (
                    <Notification
                        key={notification.id}
                        notification={notification}
                        onClick={handleNotificationClick}
                        isCompanySpecific={isCompanySpecific}
                    />
                ))}
                {notifications.hasMore && (
                    <ListItem style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress />
                    </ListItem>
                )}
            </List>
            {notifications.notifications?.length === 0 && (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <EmptyState title={t("notificationCenter.emptyState")} />
                </div>
            )}
        </NotificationCenterWrapper>
    );
});

const icons: Record<NotificationModule, IIconNames> = {
    accounting: "accounting",
    hr: "personnel",
    project: "projects",
    general: "global",
};

const Notification = ({
    notification,
    onClick,
    isCompanySpecific,
}: {
    notification: AppNotification;
    onClick: (ev: React.MouseEvent<HTMLAnchorElement>, notification: AppNotification) => void;
    isCompanySpecific: boolean;
}) => {
    const { message, createdAt, module, isRead, deeplink, companyName } = notification;

    const icon = icons[module ?? "general"];

    let secondary: React.ReactNode = formatDate(createdAt);
    if (isCompanySpecific) {
        secondary = (
            <>
                {secondary}
                <br />
                {companyName}
            </>
        );
    }

    return (
        <>
            <ListItem
                button
                component="a"
                href={deeplink ?? "#"}
                target={deeplink ? "_blank" : undefined}
                rel={deeplink ? "noopener noreferrer" : undefined}
                onClick={ev => {
                    onClick(ev, notification);
                }}
            >
                <ListItemAvatar style={{ display: "flex", alignItems: "center" }}>
                    <Icon name={icon} />
                </ListItemAvatar>
                <ListItemText
                    primary={message}
                    secondary={secondary}
                    primaryTypographyProps={{
                        style: { fontWeight: isRead ? undefined : "bold", overflowWrap: "break-word" },
                    }}
                    secondaryTypographyProps={{
                        style: { fontWeight: isRead ? undefined : "bold", fontSize: 12 },
                    }}
                />
                <TpaBadge style={{ marginLeft: 16, visibility: isRead ? "hidden" : undefined }} />
            </ListItem>
            <Divider />
        </>
    );
};
