import styled from "styled-components";
import { TELEPHONE_NUMBER } from "../../../config";
import { t } from "../../../i18n/util";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { goBack } from "../router/history";
import { Routes } from "../router/Routes";

const P = styled.p`
    margin-top: 16px;
`;

const H1 = styled.h1`
    font-size: 18px;
`;

const H2 = styled.h2`
    margin-top: 32px;
    font-size: 16px;
`;

const H3 = styled.h3`
    margin-top: 16px;
    font-size: 14px;
`;

const UL = styled.ul`
    padding-left: 32px;
    margin-top: 16px;
`;

// TODO: For localization later easiest will be -> one page per language instead of putting the strings into texterify
export const PrivacyPolicySite = () => {
    useHideSideBar();

    return (
        <>
            <NavBarBack
                title={t("footer.privacy")}
                backLabel={t("common.back")}
                onBack={() => {
                    goBack(Routes.ROOT);
                }}
            />
            <CenteredContent>
                <SiteContent style={{ paddingBottom: 64 }}>
                    <H1>DATENSCHUTZERKLÄRUNG</H1>
                    <P>
                        Der Schutz Ihrer personenbezogenen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre
                        Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSG, DSGVO, TKG 2003).
                        In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der
                        Datenverarbeitung im Rahmen unserer Plattform.
                    </P>
                    <P>
                        Bitte beachten Sie dabei, dass sich diese Datenschutzerklärung lediglich auf Datenverarbeitungen
                        im Zusammenhang mit dem Betrieb und der Bedienung der Plattform bezieht, hinsichtlich derer wir
                        Verantwortlicher im Sinne der DSGVO sind. Hinsichtlich der Daten in Dokumenten, Chats, oder
                        sonstigen Inhalten, die mittels der Plattform hochgeladen, geteilt oder auf sonstige Weise
                        genutzt werden, sind wir Auftragsverarbeiter des jeweiligen Kunden und ist dieser
                        Verantwortlicher in Hinblick auf solche Daten.
                    </P>
                    <P>
                        <b>Verantwortlicher:</b>
                    </P>
                    <P>
                        <b>TPA Connect GmbH</b>
                        <br />
                        Wiedner Gürtel 13
                        <br />
                        1100 Wien
                        <br />
                        <br /> T: {TELEPHONE_NUMBER}
                        <br /> M:{" "}
                        <a href="mailto:service@tpa-connect.app" target="_blank" rel="noopener noreferrer">
                            service@tpa-connect.app
                        </a>
                    </P>
                    <H2>I. WELCHE DATEN WERDEN VON USERN UNSERER PLATTFORM ZU WELCHEN ZWECKEN ERHOBEN?</H2>
                    <H3>1. User-Account Verwaltung</H3>
                    <P>
                        Um die vereinbarungsgemäße Nutzung und Verwaltung Ihres Accounts sowie die entsprechende
                        Zuordnung über unserer Plattform zu ermöglich, erheben wir folgende Daten
                    </P>
                    <UL>
                        <li>Vorname</li>
                        <li>Nachname</li>
                        <li>Zugehörigkeit zum Unternehmen</li>
                        <li>E-Mail-Adresse bzw. Benutzername</li>
                        <li>Account-Passwort</li>
                    </UL>
                    <H3>2. Logfiles</H3>
                    <P>
                        Wenn Sie auf unsere Website zugreifen, erheben und speichern wir folgende Zugriffsdaten
                        (Logfiles):
                    </P>
                    <UL>
                        <li> IP Adresse und IP Standort </li>
                        <li> Referrer URL (die zuvor und anschließend besuchte Internetseite) </li>
                        <li> Anzahl, Dauer und Zeit der Aufrufe </li>
                        <li> Suchmaschinen und Schlüsselwörter, mit denen Sie unsere Website gesucht haben </li>
                        <li> Browsertyp, Gerätetyp, Internet Service Provider und Betriebssystem </li>
                    </UL>
                    <P>
                        Die erhobenen Logfiles (Zugriffsdaten) werden zum Zweck des Betriebs, der Sicherheit, der
                        Erstellung von Nutzungsstatistiken und somit der Optimierung unserer Website verwendet
                        (überwiegend berechtigte Interessen).
                    </P>
                    <H2>II. ÜBERMITTLUNG PERSONENBEZOGENER DATEN</H2>
                    <P>
                        Zu den oben genannten Zwecken, werden wir die erhobenen Daten fallweise an von uns eingesetzte
                        Auftragsverarbeiter wie insbesondere Anbieter von Marketingtools, IT-Dienstleister, Anbieter von
                        sonstigen Tools und Softwarelösungen, IT-Wartungsdienste und weitere Anbieter ähnlicher Dienste
                        übermitteln. Alle unsere Auftragsverarbeiter verarbeiten Ihre Daten nur in unserem Auftrag und
                        auf Basis unserer Weisungen für unsere oben dargestellten Zwecke.
                    </P>
                    <H2>III. SPEICHERDAUER</H2>
                    <P>
                        Wir speichern Ihre personenbezogenen Daten nur so lange, wie wir sie für die Erfüllung der oben
                        dargestellten Zwecke und unserer vertraglichen bzw gesetzlichen Verpflichtungen benötigen. Wenn
                        wir Ihre personenbezogenen Daten nicht länger benötigen, löschen wir diese von unseren Systemen
                        und Aufzeichnungen oder anonymisieren sie, damit Sie nicht mehr identifiziert werden können.
                    </P>
                    <H2>IV. IHRE RECHTE</H2>
                    <P>
                        Sie haben ein Recht auf Auskunft über die von uns über Sie verarbeiteten personenbezogenen
                        Daten. Zusätzlich haben Sie das Recht auf Berichtigung unrichtiger Daten und Löschung Ihrer
                        Daten (&quot;Recht auf Vergessenwerden&quot;). Sie können außerdem ihre Einwilligung zur
                        Verarbeitung personenbezogener Daten mit Wirkung für die Zukunft widerrufen, wenn die
                        Verarbeitung auf Ihrer Zustimmung basiert. Ihnen kann weiters ein Recht auf Einschränkung der
                        Verarbeitung Ihrer Daten sowie ein Recht auf Herausgabe der von Ihnen bereitgestellten Daten in
                        einem strukturierten, gängigen und maschinenlesbaren Format zustehen
                        (&quot;Datenübertragbarkeit&quot;).
                    </P>{" "}
                    <P style={{ border: "1px solid black", padding: 4, maxWidth: 600 }}>
                        <b>
                            Sie haben das Recht der Datenverarbeitung jederzeit zu widersprechen, wenn sich aus Ihrer
                            besonderen Situation Gründe dafür ergeben.
                        </b>
                    </P>
                    <P>
                        Sie haben außerdem die Möglichkeit, sich mit einer Beschwerde an eine
                        Datenschutzaufsichtsbehörde zu wenden. Die für uns zuständige Datenschutzaufsichtsbehörde ist
                        die Österreichische Datenschutzbehörde.
                    </P>
                    <P>
                        Sollten Sie Fragen bezüglich Ihrer personenbezogenen Daten haben, kontaktieren Sie uns bitte
                        unter{" "}
                        <a href="mailto:service@tpa-connect.app" target="_blank" rel="noopener noreferrer">
                            service@tpa-connect.app
                        </a>
                        .
                    </P>
                    <H2>V. LINKS ZU ANDEREN WEBSITEN</H2>
                    <P>
                        Sobald Sie einem Link auf eine andere Website folgen und damit unsere Plattform verlassen, haben
                        wir keine weitere Kontrolle oder Einfluss auf die dort angeführten Inhalte oder die Verarbeitung
                        Ihrer personenbezogenen Daten. Wir können auch keine Garantie oder Haftung (weder mittelbar noch
                        unmittelbar) für die Richtigkeit, Vollständigkeit, Nutzbarkeit, Verlässlichkeit oder
                        Zeitlosigkeit der auf solchen Seiten angebotenen Informationen übernehmen.
                    </P>
                    <P>
                        Soweit rechtlich zulässig übernehmen wir keinerlei Haftung für den Inhalt verlinkter Websiten,
                        davon ohne Beschränkung eingeschlossen sind die Links auf einer verlinkten Website sowie
                        Änderungen oder Updates einer verlinkten Website.
                    </P>
                    <P>
                        Beachten Sie bitte auch, dass die Nutzungsbedingungen und Datenschutzbestimmungen einer
                        verlinkten Website von den Nutzungsbedingungen und Datenschutzstandards unserer Plattform
                        abweichen können. Aus diesem Grund sollten Sie die Datenschutzbestimmungen und
                        Nutzungsbedingungen der verlinkten Website lesen und einhalten.
                    </P>
                </SiteContent>
            </CenteredContent>
        </>
    );
};
