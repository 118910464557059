import { Routes } from "../../app/router/Routes";

const prefix = (route: string) => Routes.AUTH + route;

export const AuthRoutes = {
    ROOT: prefix("/"),
    WELCOME: prefix("/welcome"), // registration: welcome screen
    MS: prefix("/ms"), // OAuth2 redirect url for MS login
    MS_CLOSE: prefix("/ms-close"), // OAuth2 redirect url for MS token refresh
    MS_REFRESH_OFFLINE: prefix("/ms-refresh-offline"), // OAuth2 redirect url for MS token refresh offline testing
    LOCAL_CREDENTIALS: prefix("/local-credentials"), // handover url for local testing
    REGISTRATION: prefix("/registration"), // registration: set password
    FORGOT_PASSWORD: {
        EMAIL: prefix("/forgot-password/email"), // forgot password flow: send email
        CONFIRM: prefix("/forgot-password/confirm"), // forgot password flow: confirm
        RESET: prefix("/forgot-password/reset"), // forgot password flow: set new password
    },
    CONFIRM_USERNAME_CHANGE: prefix("/confirm-username-change"), // deep link when email has changed params: ?token=UID&newUsername=NEW_USERNAME
};
