import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetCompanyDeadlinesResponse } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";

export const useDueDates = () => {
    const [dueDates, setDueDates] = React.useState<GetCompanyDeadlinesResponse>({
        projects: [],
        recordsAccounting: [],
        recordsPersonnel: [],
        reportsAccounting: [],
        reportsPersonnel: [],
        ticketsAccounting: [],
        ticketsPersonnel: [],
    });

    const companyId = companiesStore.selectedCompanyId;

    React.useEffect(() => {
        const loadDueDates = async () => {
            if (!companyId) {
                return;
            }

            try {
                generalStore.isLoading = true;
                const dueDatesResponse = await API.getDueDates(companyId);
                setDueDates(dueDatesResponse);
            } catch (error) {
                generalStore.setError(t("error.loadDocument"), error);
            } finally {
                generalStore.isLoading = false;
            }
        };

        loadDueDates();
    }, [companyId]);

    return {
        dueDates,
    };
};
