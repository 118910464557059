import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { pushRoute } from "../app/router/history";
import { ExpansionCard } from "../ui/ExpansionCard";
import { User } from "../ui/User";
import { HrRoutes } from "./router/HrRoutes";

interface IProps {
    expanded: boolean;
    title: string;
    onExpand: (isExpanded: boolean) => void;
    "data-id": string;
}

export const HrRegistrationCard = observer(function HrRegistrationCard({
    title,
    expanded,
    onExpand,
    "data-id": dataId,
}: IProps) {
    const handleRegisterEmployee = () => {
        pushRoute(HrRoutes.EMPLOYEES.ROOT);
    };

    return (
        <ExpansionCard
            data-id={dataId}
            expanded={expanded}
            onExpand={onExpand}
            title={title}
            status="closed"
            iconName="hrLogInOut"
        >
            <User
                isTpaStaff={authStore.userInfo?.is_tpa_employee}
                firstName={companiesStore.mainResponsible.hr.firstName}
                lastName={companiesStore.mainResponsible.hr.lastName}
                imageUrl={companiesStore.mainResponsible.hr.profile_picture_url}
                role={t("common.hr")}
                style={{ marginBottom: 16 }}
            />
            <p>
                <FormattedHTMLMessage
                    id="screen.hr.overview.registration"
                    values={{
                        salutation: authStore.greeting,
                        signature: companiesStore.mainResponsibleSignatureName("hr"),
                    }}
                />
            </p>
            <div style={{ paddingBottom: 16, alignSelf: "flex-end" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRegisterEmployee}
                    data-id="register_deregister_button"
                >
                    {t("screen.hr.overview.registration.button")}
                </Button>
            </div>
        </ExpansionCard>
    );
});
