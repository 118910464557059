import * as React from "react";
import { Redirect, useHistory, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { Module } from "../../../types/models";
import { getFilenameFromPath } from "../../../util/helpers";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useTicketDetails } from "../../hooks/useTicketDetails";
import { TicketSiteContent } from "../../shared/TicketSiteContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { TicketsRoutes } from "../router/TicketsRoutes";

export const TicketsDetailsSite = ({ module }: { module?: Module }) => {
    useHideSideBar();
    const { ticketId, recordTypeId } = useParams<{ ticketId: string; recordTypeId: string }>();
    const companyId = companiesStore.selectedCompanyId;
    const history = useHistory();
    const [ticketName, setTicketName] = React.useState("");
    const ticket = useTicketDetails({ companyId, ticketId });

    if (!companyId || !ticketId) {
        generalStore.setError(t("error.missingParams"));
        return <Redirect to={TicketsRoutes.OPEN} />;
    }

    const handleCloseTicket = () => {
        if (module && recordTypeId) {
            // User UI
            const moduleStore = getModuleStore(module);
            pushRoute(moduleStore.routes.RECORDS.TICKETS, { params: { recordTypeId } });
        } else if (authStore.isTpa) {
            // tpa user
            pushRoute(TicketsRoutes.OPEN);
        } else {
            // If all fails go to cockpit
            pushRoute(Routes.COCKPIT);
        }
    };

    const handleBack = () => {
        if (generalStore.lastLocation) {
            // Best case we can go back (e.g. to webhook, record ticket list, advisor ticket list)
            // If not then it was a deep link -> handle back for those cases below
            history.goBack();
        } else {
            handleCloseTicket();
        }
    };

    return (
        <>
            <NavBarBack
                backLabel={t("common.back")}
                onBack={handleBack}
                title={t("webhook.tickets.navbar", { document: getFilenameFromPath(ticketName) })}
                showCancel={false}
            />
            {ticket.details && (
                <TicketSiteContent
                    companyId={companiesStore.selectedCompanyId}
                    onCloseTicket={handleCloseTicket}
                    onTicketLoaded={name => {
                        setTicketName(name);
                    }}
                    ticketId={ticketId}
                    ticketDetails={ticket.details}
                />
            )}
        </>
    );
};
