import { Checkbox, IconButton, TableRow } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { CompanyUser, PermissionsSubsidiaries, UserPermissions } from "../../network/APITypes";
import { Module } from "../../types/models";
import { mailValidation } from "../../util/helpers";
import {
    canEditSubsidiary,
    canReadSubsidiary,
    getGlobalModules,
    getPermissionModuleString,
    getPermissionModules,
    getPermissionSubsidiaries,
    getRoleString,
    getRolesString,
    hasAllGlobalPermissions,
    hasAllPermissions,
} from "../../util/permissionHelpers";
import { getFullName } from "../../util/user";
import {
    InfoButton,
    OptionalTooltip,
    TableLabel,
    TableLabelSmall,
    TableRowButton,
    TpaExpandedTableRowContainer,
    TpaTableCell,
} from "../ui/Primitives";
import { ITableHeaderConfig } from "../ui/TableHeader";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

export type User = CompanyUser & UserPermissions;

const PermissionRowBorder = ({ children }: { children: React.ReactNode }) => (
    <TableRow style={{ height: 32, borderBottom: `1px solid ${customColors.greyLight}` }}>{children}</TableRow>
);

export const UserRow = ({
    index,
    user,
    select,
    headerFields,
    isSuperAdmin,
    isAdvisor,
    onEdit,
    onInvite,
    onDelete,
    onSendUsernameChangeEmail,
    canUpdate,
    usernameChanged,
    newUsername,
}: {
    index: number;
    user: User;
    select: { toggleSelection: (user: User) => void; isSelected: (user: User) => boolean };
    headerFields: ITableHeaderConfig[];
    isSuperAdmin: boolean; // true if current logged in user is super admin
    isAdvisor: boolean; // true if current logged in user is tpa-advisor
    onEdit: (user: User) => void;
    onInvite: (user: User) => void;
    onDelete: (user: User) => void;
    onSendUsernameChangeEmail: (user: User) => void;
    canUpdate: boolean;
    usernameChanged?: boolean;
    newUsername?: string;
}) => {
    const [expanded, setExpanded] = React.useState(false);

    // true if user for this row is super admin
    const rowUserIsSuperAdmin = user.roles?.includes("super-admin");

    const canInvite = (isSuperAdmin || isAdvisor) && !user.confirmedAt;

    // Returns button that should be displayed on this row
    const getUserAction = () => {
        if (rowUserIsSuperAdmin) {
            if (canInvite) {
                return "invite";
            }
        } else {
            if (user.needsRelease) {
                return "waiting";
            } else if (canInvite) {
                if (user.roles?.length > 0 && !user.roles?.includes("none")) {
                    // TPAPORTAL-2025: role "none" can not be invited
                    return "invite";
                } else {
                    return "edit";
                }
            } else if (
                (!isSuperAdmin && !user.needsRelease) ||
                (isSuperAdmin && !user.needsRelease && user.confirmedAt)
            ) {
                return "edit";
            }
        }
    };

    const userAction = getUserAction();

    const modules = getPermissionModules(user);
    const globalModules = getGlobalModules(user);

    const subsidiarySections = {
        accounting: {
            title: t("common.accounting"),
            subsidiaries: getPermissionSubsidiaries(user, "accounting"),
        },
        hr: {
            title: t("common.hr.short"),
            subsidiaries: getPermissionSubsidiaries(user, "hr"),
        },
    };

    // How many columns should the last column span when expanded. This allows the last
    // column displaying the permission details to span the rest of the table.
    // Increase this when adding columns to the user table and 4 is not enough.
    const LAST_COLSPAN = 4;

    return (
        <TpaExpandedTableRowContainer>
            <TableRow style={{ height: 56 }}>
                {canUpdate && (
                    <TpaTableCell padding="checkbox">
                        {!rowUserIsSuperAdmin && (
                            <Checkbox
                                data-id={`user_checkbox_${index}`}
                                onChange={(event, checked) => {
                                    select.toggleSelection(user);
                                }}
                                color="primary"
                                disabled={user.needsRelease}
                                checked={select.isSelected(user)}
                            />
                        )}
                    </TpaTableCell>
                )}
                {headerFields.map(({ column }) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let label: any = user[column as keyof UserPermissions];
                    let icon;
                    let tooltipLabel: string | undefined = undefined;

                    const rowProps = canUpdate
                        ? {
                              onClick:
                                  column !== "expand" && userAction && !rowUserIsSuperAdmin
                                      ? () => {
                                            onEdit(user);
                                        }
                                      : undefined,
                              style:
                                  column !== "expand" && userAction && !rowUserIsSuperAdmin
                                      ? {
                                            cursor: "pointer",
                                            color: userAction === "waiting" ? customColors.placeholder : undefined,
                                        }
                                      : undefined,
                          }
                        : {};

                    if (column === "edit") {
                        const getContent = () => {
                            if (userAction === "waiting") {
                                return (
                                    <span style={{ whiteSpace: "nowrap", marginRight: 16 }}>
                                        {t("config.users.waiting.title")}
                                    </span>
                                );
                            } else if (userAction === "invite") {
                                const hasValidEmail = mailValidation.isValidSync(user);

                                if (hasValidEmail) {
                                    return (
                                        <TableRowButton
                                            color="primary"
                                            onClick={event => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                onInvite(user);
                                            }}
                                            style={{ whiteSpace: "nowrap", textTransform: "uppercase" }}
                                            disabled={!canUpdate}
                                        >
                                            {!user.invitedAt && t("common.invite")}
                                            {user.invitedAt && t("config.users.inviteAgain")}
                                        </TableRowButton>
                                    );
                                } else {
                                    return (
                                        <div
                                            style={{
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {t("config.users.table.invalidEmail")}
                                            <InfoButton title={t("config.users.table.invalidEmail.tooltip")} />
                                        </div>
                                    );
                                }
                            } else if (userAction === "edit") {
                                return (
                                    <TableRowButton
                                        color="primary"
                                        onClick={e => {
                                            e.stopPropagation();
                                            onEdit(user);
                                        }}
                                        style={{ textTransform: "uppercase" }}
                                        disabled={!canUpdate}
                                    >
                                        {t("config.users.edit")}
                                    </TableRowButton>
                                );
                            }
                        };

                        return (
                            <TpaTableCell
                                key={column}
                                onClick={rowProps.onClick}
                                style={{
                                    textAlign: "right",
                                    width: 1 /* width 1 limits to content */,
                                    ...rowProps.style,
                                    color: undefined,
                                }}
                            >
                                {getContent()}
                            </TpaTableCell>
                        );
                    } else if (column === "lastName") {
                        const name = getFullName(user);
                        label = name;
                        tooltipLabel = `${name} (${user.username})`;
                    } else if (column === "changeUsername") {
                        return (
                            <TpaTableCell key={column}>
                                {usernameChanged && (
                                    <OptionalTooltip
                                        title={
                                            newUsername ? t("config.users.changeUsername.tooltip", { newUsername }) : ""
                                        }
                                    >
                                        <TableRowButton
                                            color="primary"
                                            onClick={() => {
                                                onSendUsernameChangeEmail(user);
                                            }}
                                            style={{ textTransform: "uppercase" }}
                                        >
                                            {t("config.users.changeUsername")}
                                        </TableRowButton>
                                    </OptionalTooltip>
                                )}
                            </TpaTableCell>
                        );
                    } else if (column === "role") {
                        label =
                            user.roles.length === 0
                                ? getRoleString({ role: "none" })
                                : getRolesString({ roles: user.roles });

                        if (rowUserIsSuperAdmin) {
                            icon = (
                                <Icon
                                    name="locked"
                                    style={{ color: customColors.greyDarkIcons, marginLeft: 8 }}
                                    size={18}
                                />
                            );
                        }
                    } else if (column === "moduleCount") {
                        // debug.log("### user modules", user.firstName, user.lastName, modules);
                        label = modules.length;
                    } else if (column === "permissions") {
                        const allPermissions = hasAllPermissions(user);
                        if (allPermissions === "all") {
                            label = t("common.all").toLowerCase();
                        } else if (allPermissions === "some") {
                            label = t("common.partial");
                        } else {
                            label = t("common.none");
                        }
                    } else if (column === "deleteUser") {
                        return (
                            <TpaTableCell
                                key={column}
                                style={{ width: "1%" /* https://stackoverflow.com/a/27583573/677910 */ }}
                            >
                                <IconButton
                                    onClick={() => {
                                        onDelete(user);
                                    }}
                                    disabled={rowUserIsSuperAdmin}
                                >
                                    {<Icon name="delete" />}
                                </IconButton>
                            </TpaTableCell>
                        );
                    } else if (column === "expand") {
                        return (
                            <TpaTableCell
                                key={column}
                                style={{ width: "1%" /* https://stackoverflow.com/a/27583573/677910 */ }}
                            >
                                <IconButton
                                    onClick={() => {
                                        setExpanded(!expanded);
                                    }}
                                >
                                    <Icon name={expanded ? "chevronUp" : "chevronDown"} />
                                </IconButton>
                            </TpaTableCell>
                        );
                    }

                    return (
                        <TpaTableCell key={column} {...rowProps}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <TableLabel tooltip={tooltipLabel}>{label}</TableLabel>
                                {icon}
                            </div>
                        </TpaTableCell>
                    );
                })}
            </TableRow>
            {expanded && ( // Render expanded permissions
                <>
                    {globalModules.map(module => (
                        <PermissionRowBorder key={module}>
                            {canUpdate && <TpaTableCell />} {/* checkbox */}
                            <TpaTableCell />
                            <TpaTableCell />
                            <TpaTableCell>
                                <TableLabelSmall>{getPermissionModuleString(module)}</TableLabelSmall>
                            </TpaTableCell>
                            <TpaTableCell colSpan={LAST_COLSPAN}>
                                <TableLabelSmall style={{ maxWidth: "none" }}>
                                    {hasAllGlobalPermissions(module, user) ? t("common.all") : t("common.partial")}
                                </TableLabelSmall>
                            </TpaTableCell>
                        </PermissionRowBorder>
                    ))}
                    {(Object.keys(subsidiarySections) as Module[]).map(module => {
                        const section = subsidiarySections[module];
                        const title = section.title;

                        return (
                            <React.Fragment key={module}>
                                {section.subsidiaries.map((subsidiary: PermissionsSubsidiaries) => {
                                    const canEdit = canEditSubsidiary(module, subsidiary);
                                    let permissionString = t(`permission.option.canEdit.${canEdit}`);
                                    if (canEdit !== "all") {
                                        const canRead = canReadSubsidiary(module, subsidiary);
                                        permissionString =
                                            t(`permission.option.canRead.${canRead}`) + ", " + permissionString;
                                    }

                                    return (
                                        <PermissionRowBorder key={subsidiary.id}>
                                            {canUpdate && <TpaTableCell />} {/* checkbox */}
                                            <TpaTableCell />
                                            <TpaTableCell>
                                                <TableLabelSmall>{subsidiary.name}</TableLabelSmall>
                                            </TpaTableCell>
                                            <TpaTableCell>
                                                <TableLabelSmall>{title}</TableLabelSmall>
                                            </TpaTableCell>
                                            <TpaTableCell colSpan={LAST_COLSPAN}>
                                                <TableLabelSmall style={{ maxWidth: "none" }}>
                                                    {permissionString}
                                                </TableLabelSmall>
                                            </TpaTableCell>
                                        </PermissionRowBorder>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </TpaExpandedTableRowContainer>
    );
};
