import { Link, useMediaQuery } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { LINK_LEGAL, LINK_PRIVACY } from "../../config";
import { IMessageIDS, t } from "../../i18n/util";
import { Routes } from "../app/router/Routes";
import { pushRoute } from "../app/router/history";
import { useGlobalQuickActionsOffset } from "../hooks/useGlobalQuickActionsOffset";
import { useResize } from "../hooks/useResize";
import { customColors } from "../util/Theme";

const BaseContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    color: ${customColors.white};
    background-color: ${customColors.primaryColor};
`;

// z index 1250 to stay below mui stuff but above drawer: https://material-ui.com/customization/z-index/
const DesktopContainer = styled(BaseContainer)`
    position: fixed;
    bottom: 0;
    height: 42px;
    z-index: 1250;
    justify-content: space-between;
    padding: 0 40px;
`;

const MobileContainer = styled(BaseContainer)`
    position: fixed;
    bottom: 0;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
`;

const MobileLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
`;

// href = external link, to = internal link
const links: { label: IMessageIDS; href?: string; to?: string }[] = [
    {
        label: "footer.legalInformation",
        href: LINK_LEGAL,
        to: Routes.LEGAL_NOTICE,
    },
    // {
    //     label: "footer.generalBusinessTerms",
    //     href: "",
    // },
    {
        label: "footer.privacy",
        href: LINK_PRIVACY,
        to: Routes.PRIVACY_POLICY,
    },
    // {
    //     label: "footer.disclaimer",
    //     href: "https://www.tpa-group.at/de/disclaimer/ ",
    // },
    // TODO: @mwild add link to cookie policy
];

export const Footer = () => {
    const isMobile = useMediaQuery(`(max-width: 580px)`, { noSsr: true });

    const Container = isMobile ? MobileContainer : DesktopContainer;
    const LinkContainer = isMobile ? MobileLinkContainer : "div";

    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const size = useResize(containerRef);
    useGlobalQuickActionsOffset(size.height ?? 0);

    return (
        // Using redundant div around <Container> to fix iOS Layout issue TPAPORTAL-1339
        <div>
            <Container ref={containerRef}>
                <div>&copy; {t("footer.copyright")}</div>
                <LinkContainer>
                    {links.map((link, index) => (
                        <React.Fragment key={link.label}>
                            <Link
                                href={link.href}
                                onClick={() => {
                                    if (!link.href && link.to) {
                                        pushRoute(link.to);
                                    }
                                }}
                                style={{ cursor: "pointer" }}
                                target="_blank"
                                color="inherit"
                            >
                                {t(link.label)}
                            </Link>
                            {!isMobile && index < links.length - 1 && <span style={{ margin: "0 8px" }}>|</span>}
                        </React.Fragment>
                    ))}
                </LinkContainer>
            </Container>
            <div style={{ height: size.height }} />
        </div>
    );
};
