import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { getMfaValidation } from "../../util/helpers";
import { useCompanies } from "../hooks/useCompanies";
import { useMultiFactorAuth } from "../hooks/useMultiFactorAuth";
import { CenteredContent } from "../ui/CenteredContent";
import { CustomInputField } from "../ui/CustomInputField";
import { MicrosoftSignInButton } from "../ui/MicrosoftSignInButton";
import { VCenter } from "../ui/Primitives";
import { SiteContent } from "../ui/SiteContent";
import { customColors } from "../util/Theme";

export const MicrosoftUpgradeForm = ({
    companyId,
    text,
    isMfaStep,
    onSubmitPassword,
    onSubmitMfaCode,
}: {
    companyId?: string;
    text?: string;
    isMfaStep?: boolean;
    onSubmitPassword: (username?: string, password?: string) => void;
    onSubmitMfaCode: (code: string) => void;
}) => {
    return (
        <>
            <h1 style={{ color: customColors.body1Dark, textAlign: "center" }}>
                {t("screen.upgradeToMicrosoft.title")}
            </h1>
            {!isMfaStep && (
                <>
                    {text && <p style={{ textAlign: "center", marginTop: 16 }}>{text}</p>}
                    <Formik
                        initialValues={{
                            password: "",
                        }}
                        onSubmit={model => {
                            onSubmitPassword(authStore.userInfo?.email, model.password);
                        }}
                        validationSchema={Yup.object().shape({
                            password: Yup.string().required(
                                t("screen.upgradeToMicrosoft.form.password.validation_error"),
                            ),
                        })}
                        validateOnBlur
                        validateOnChange
                    >
                        {() => {
                            return (
                                <Form style={{ marginTop: 32 }}>
                                    {companyId && (
                                        <Field
                                            data-id="login_password"
                                            component={CustomInputField}
                                            label={t("screen.upgradeToMicrosoft.form.password.label")}
                                            name="password"
                                            type="password"
                                            required
                                            autoComplete="current-password"
                                        />
                                    )}
                                    {!companyId && (
                                        <p style={{ marginBottom: 32 }}>{t("screen.upgradeToMicrosoft.needCompany")}</p>
                                    )}
                                    <MicrosoftSignInButton variant="upgrade" type="submit" disabled={!companyId} />
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
            {isMfaStep && (
                <>
                    <p style={{ textAlign: "center", marginTop: 16 }}>
                        {t("screen.upgradeToMicrosoft.form.code.description")}
                    </p>
                    <Formik
                        initialValues={{
                            code: "",
                        }}
                        onSubmit={model => {
                            onSubmitMfaCode(model.code);
                        }}
                        validationSchema={getMfaValidation()}
                        validateOnBlur
                        validateOnChange
                    >
                        {() => {
                            return (
                                <Form style={{ marginTop: 32 }}>
                                    <Field
                                        data-id="mfa_code"
                                        component={CustomInputField}
                                        label={t("screen.upgradeToMicrosoft.form.code.label")}
                                        name="code"
                                        required
                                    />
                                    <MicrosoftSignInButton variant="send_code" type="submit" />
                                    <Button
                                        style={{ marginTop: 24 }}
                                        onClick={() => {
                                            onSubmitPassword(authStore.userInfo?.email);
                                        }}
                                        color="primary"
                                        data-id="resend_code"
                                    >
                                        {t("screen.upgradeToMicrosoft.resendButton.label")}
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                </>
            )}
        </>
    );
};

export const MicrosoftUpgradeSite = observer(function MicrosoftUpgradeSite({ text }: { text?: string }) {
    const selectedCompanyId = companiesStore.selectedCompanyId;

    const hasCompany = !!selectedCompanyId;
    // If no company selected -> take first company of user's list
    const companies = useCompanies({ search: "", skipLoadForEmptySearch: hasCompany, limit: 1 });

    const companyId = selectedCompanyId ?? (companies.companies.length > 0 ? companies.companies[0]?.id : undefined);

    const { submitPassword, submitMfaCode, currentAuthStep, mfaResendSuccessDialog } = useMultiFactorAuth(
        () => authStore.upgradeAndLoginWithMicrosoft(companyId ?? ""),
        true,
    );

    return (
        <CenteredContent>
            <VCenter style={{ alignItems: "center" }}>
                <SiteContent style={{ maxWidth: 375, marginTop: `calc(var(--navBarHeight) * -1)` }}>
                    <MicrosoftUpgradeForm
                        companyId={companyId}
                        text={text}
                        onSubmitPassword={submitPassword}
                        onSubmitMfaCode={submitMfaCode}
                        isMfaStep={currentAuthStep === "multiFactorAuth"}
                    />
                </SiteContent>
            </VCenter>
            {mfaResendSuccessDialog.dialog}
        </CenteredContent>
    );
});
