import * as React from "react";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

// Page indicator (e.g. for thumbnail preview)
export const PageIndicator = (props: {
    current: number;
    total: number;
    onNext: () => void;
    onPrevious: () => void;
}) => (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
        <div
            style={{
                borderRadius: 200,
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 4,
            }}
        >
            <Icon
                name="chevronLeft"
                role="button"
                style={{
                    color: props.current === 1 ? customColors.greyLight : customColors.greyDarkIcons,
                    marginRight: 8,
                    cursor: "pointer",
                }}
                onClick={props.onPrevious}
            />
            <p className="caption">{`${props.current}/${props.total}`}</p>
            <Icon
                name="chevronRight"
                role="button"
                style={{
                    color: props.current < props.total ? customColors.greyDarkIcons : customColors.greyLight,
                    marginLeft: 8,
                    cursor: "pointer",
                }}
                onClick={props.onNext}
            />
        </div>
    </div>
);
