import { Menu, MenuItem } from "@material-ui/core";
import * as React from "react";
import { useLocation } from "react-router";
import { pushRoute } from "../app/router/history";
import { TpaBadge, TpaToggleButton, TpaToggleButtonGroup } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";

export interface IToggleButtonRoute {
    title: string;
    value: string;
    route: string;
    /** Alternative route to match against the current path, useful if `route` does not fully match the current path, e.g. because of query params */
    matchRoute?: string;
    badgeCount?: number;
}

interface Props {
    buttons: IToggleButtonRoute[];
    maxVisible?: number;
}

export const NavBarToggleButtons = ({ buttons, maxVisible = Infinity }: Props) => {
    const isMobile = React.useContext(MobileContext);
    const toggleButtonStyle = isMobile ? { width: "100%" } : undefined;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
    const [page, setPage] = React.useState<string | null>(buttons[0]?.value ?? null);
    const handlePageChange = (event: React.MouseEvent<HTMLElement>, page: string | null) => {
        if (page === "dropdown") {
            setAnchorEl(event.currentTarget);
            return;
        }

        if (page) {
            setPage(page);
        }
    };

    const { pathname } = useLocation();
    React.useEffect(() => {
        let currentButton: IToggleButtonRoute | undefined = buttons[0];
        let matchingScore = 0;
        // Match route with highest score (= prefix length)
        buttons.forEach(button => {
            const matchRoute = button.matchRoute ?? button.route;
            if (pathname.startsWith(matchRoute)) {
                if (matchRoute.length > matchingScore) {
                    matchingScore = matchRoute.length;
                    currentButton = button;
                }
            }
        });
        if (currentButton) {
            setPage(currentButton.value);
        }
    }, [pathname, buttons]);

    const handleCloseMenu = () => {
        setAnchorEl(undefined);
    };

    let visibleButtons = buttons;
    let dropdownToggle, menu;
    if (buttons.length > maxVisible) {
        visibleButtons = buttons.slice(0, maxVisible);
        dropdownToggle = (
            <TpaToggleButton value="dropdown" style={{ padding: 0, width: 40 }}>
                <Icon name={anchorEl ? "dropUp" : "dropDown"} />
            </TpaToggleButton>
        );

        const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>, button: IToggleButtonRoute) => {
            pushRoute(button.route);
            handlePageChange(event, button.value);
            setAnchorEl(undefined);
        };

        const menuButtons = buttons.slice(maxVisible);
        menu = (
            <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
            >
                {menuButtons.map(button => {
                    const { value, title, badgeCount } = button;
                    return (
                        <MenuItem
                            key={value}
                            value={value}
                            selected={value === page}
                            onClick={ev => {
                                handleClickMenuItem(ev, button);
                            }}
                        >
                            {title}
                            {!!badgeCount && <TpaBadge style={{ marginLeft: 8 }}>{badgeCount}</TpaBadge>}
                        </MenuItem>
                    );
                })}
            </Menu>
        );
    }

    if (visibleButtons.length === 0) {
        return null;
    }

    return (
        <>
            <TpaToggleButtonGroup value={page} exclusive onChange={handlePageChange} style={toggleButtonStyle}>
                {visibleButtons.map(button => (
                    <TpaToggleButton
                        key={button.value}
                        value={button.value}
                        onClick={() => {
                            pushRoute(button.route);
                        }}
                        style={toggleButtonStyle}
                        data-id={"tpa_toggle_button_" + button.value}
                    >
                        {button.title}
                        {!!button.badgeCount && <TpaBadge style={{ marginLeft: 8 }}>{button.badgeCount}</TpaBadge>}
                    </TpaToggleButton>
                ))}
                {dropdownToggle}
            </TpaToggleButtonGroup>
            {menu}
        </>
    );
};
