import { Button } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { CustomDialog, CustomDialogContent } from "../ui/CustomDialog";
import { ErrorImage } from "../util/Images";
import { customColors } from "../util/Theme";

export const ErrorDialog = ({
    open,
    onClose,
    onSubmit,
    title,
    message,
    buttonLabel,
    children,
}: {
    open: boolean;
    onClose: () => void;
    onSubmit?: () => void | Promise<void>;
    title?: string;
    message: string;
    buttonLabel?: string;
    children?: React.ReactNode;
}) => {
    const [submitting, setSubmitting] = React.useState(false);

    const handleSubmit = async () => {
        if (submitting || !onSubmit) {
            return;
        }

        setSubmitting(true);
        await onSubmit();
        setSubmitting(false);
    };

    return (
        <CustomDialog onClose={onClose} mobileFullScreen={false} open={open} showCloseIcon={!!onSubmit}>
            <CustomDialogContent style={{ textAlign: "center" }}>
                <ErrorImage />
                <h1 style={{ marginTop: 24 }}>{title ?? t("common.errorOccurred")}</h1>
                <div style={{ marginTop: 24 }}>{message}</div>
                {children}
            </CustomDialogContent>
            <Button
                variant="contained"
                fullWidth
                style={{ marginTop: 8, borderRadius: 0, backgroundColor: customColors.error, color: "white" }}
                onClick={onSubmit ? handleSubmit : onClose}
                disabled={submitting}
            >
                {buttonLabel ?? t("screen.accounting.records.transfer.retryDialog.button.text")}
            </Button>
        </CustomDialog>
    );
};
