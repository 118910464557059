import { Decimal } from "decimal.js";
import { t } from "../../i18n/util";
import { TaxAccountPayment } from "../../network/APITypes";
import { formatDate, replaceTextWithElements } from "../../util/helpers";
import { FormattedCurrency, InfoButton } from "../ui/Primitives";

interface Props {
    lastCompletedPayment: TaxAccountPayment;
}

export const LastCompletedTaxAccountPayment = ({ lastCompletedPayment }: Props) => {
    const amount = new Decimal(lastCompletedPayment.amount);
    const title = replaceTextWithElements(
        t("screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment", {
            date: formatDate(lastCompletedPayment.date),
        }),
        { "[amount]": <FormattedCurrency key="amount" value={amount.toNumber()} /> },
    );

    return <InfoButton title={title} color="primary" style={{ padding: 0, marginLeft: 4 }} />;
};
