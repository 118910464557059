import { observer } from "mobx-react";
import { useCallback } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { companiesStore } from "../../stores/CompaniesStore";
import { CenteredContent } from "../ui/CenteredContent";
import { EmptyState } from "../ui/EmptyState";
import { SiteContent } from "../ui/SiteContent";
import { mapPayrollItem } from "./payrollUtils";
import { ResultsPayrollAccount } from "./ResultsPayrollAccount";
import { ResultsNavBar } from "./sites/ResultsNavBar";

export const ResultsEmployerPayrollAccount = observer(function ResultsEmployerPayrollAccount() {
    const companyId = companiesStore.selectedCompanyId;

    if (companyId === undefined) {
        return <EmptyState title={t("results.payrollAccountSheet.noResultsYear.title")} />;
    }

    return <ResultsEmployerPayrollAccountSheet companyId={companyId} />;
});

interface ResultsEmployerPayrollAccountAccountSheetProps {
    companyId: string;
}

const ResultsEmployerPayrollAccountSheet = (props: ResultsEmployerPayrollAccountAccountSheetProps) => {
    const { companyId } = props;

    const loader = useCallback(
        async (companyId: string, year: number, options: { filter: string; previousYears: number }) => {
            const response = await API.getEmployerPayrollAccount({
                companyId,
                year,
                query: { filter: options.filter, previousYears: options.previousYears },
            });

            return (response.employerPayrollAccount.payrollItems ?? []).map(mapPayrollItem);
        },
        [],
    );

    return (
        <>
            <ResultsNavBar title={t("results.navbar.buttons.payrollAccount")} />
            <CenteredContent>
                <SiteContent>
                    <ResultsPayrollAccount type={"employer"} loader={loader} companyId={companyId} />
                </SiteContent>
            </CenteredContent>
        </>
    );
};
