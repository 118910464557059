import compact from "lodash/compact";
import { observer } from "mobx-react";
import { TABBAR_HEIGHT } from "../../../config";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { AdvisorRoutes } from "../../advisor/router/AdvisorRoutes";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { ContextMenu, useContextMenu } from "../../ui/ContextMenu";
import { Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";

export const UserHandlingDialog = observer(function UserHandlingDialog() {
    const contextMenu = useContextMenu();

    const handleClickCompanyOverview = () => {
        contextMenu.close();

        const target = authStore.isTpa ? AdvisorRoutes.COMPANIES.ACTIVE : Routes.COMPANY_OVERVIEW;
        pushRoute(target);
    };

    const handleClickProfile = () => {
        contextMenu.close();

        pushRoute(Routes.USER_PROFILE);
    };

    const handleClickLogout = async () => {
        contextMenu.close();

        try {
            await coordinator.logout();
        } catch (err) {
            generalStore.setError(t("error.logout"), err);
        }
    };

    const firstName = authStore.userInfo?.given_name ?? "";
    const lastName = authStore.userInfo?.family_name ?? "";
    const email = authStore.userInfo?.email ?? "";
    const addCompanyOverview = !companiesStore.selectedCompanyId && companiesStore.numCompanies > 0;

    return (
        <>
            <div
                onClick={contextMenu.open}
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    padding: "0px 16px",
                    height: TABBAR_HEIGHT,
                }}
            >
                <span
                    style={{
                        maxWidth: "80%",
                        overflowWrap: "break-word",
                        whiteSpace: "normal",
                        fontSize: 10,
                        cursor: "pointer",
                    }}
                >
                    {firstName ? `${firstName} ${lastName}` : email}
                </span>
                <div />
                {contextMenu.isOpen ? (
                    <Icon name="dropUp" style={{ color: customColors.primaryColor }} />
                ) : (
                    <Icon name="dropDown" style={{ color: customColors.primaryColor }} />
                )}
            </div>

            <ContextMenu
                config={contextMenu}
                data-id="user_handling_context_menu"
                items={compact([
                    addCompanyOverview && {
                        "data-id": "company_overview",
                        title: t("common.companyOverview"),
                        icon: "suitcase",
                        onClick: handleClickCompanyOverview,
                    },
                    {
                        "data-id": "profile",
                        title: t("common.profile"),
                        icon: "profile",
                        onClick: handleClickProfile,
                    },
                    {
                        "data-id": "logout",
                        title: t("common.logout"),
                        icon: "logout",
                        onClick: handleClickLogout,
                    },
                ])}
            />
        </>
    );
});
