import { IconButtonProps, InputBaseComponentProps } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FieldInputProps, FormikProps, FormikValues, getIn } from "formik";
import * as React from "react";
import { t } from "../../i18n/util";
import { Icon } from "../util/Icon";

type IProps<Values extends FormikValues> = KeyboardDatePickerProps & {
    disableFuture?: boolean;
    disablePast?: boolean;
    disabled?: boolean;
    hideOptional?: boolean;
    iconStyle?: React.CSSProperties;
    minDate?: Date;
    maxDate?: Date;
    label?: string;
    field: FieldInputProps<string>;
    form: FormikProps<Values>;
    "data-id": string;
};

export const CustomDatePicker = <Values extends FormikValues>({
    style,
    iconStyle,
    label,
    type,
    required,
    field,
    form,
    "aria-label": ariaLabel,
    "data-id": dataId,
    onChange,
    minDate,
    maxDate,
    hideOptional,
    disableFuture,
    disablePast,
    disabled,
}: IProps<Values>) => {
    const fieldError = getIn(form.errors, field.name) as React.ReactNode;
    const showError = (getIn(form.touched, field.name) as boolean) && !!fieldError;

    const handleChange = (date: MaterialUiPickersDate, value?: string | null) => {
        form.setFieldValue(field.name, date, true);

        if (onChange) {
            onChange(date, value);
        }
    };

    return (
        <div style={style}>
            <KeyboardDatePicker
                disableFuture={disableFuture}
                disablePast={disablePast}
                disabled={disabled}
                data-id={dataId}
                maxDate={maxDate ? maxDate : undefined}
                minDate={minDate ? minDate : undefined}
                PopoverProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: 240,
                    },
                }}
                inputProps={{ "data-id": dataId + "_text_field" } as InputBaseComponentProps}
                autoOk
                openTo="year"
                disableToolbar
                value={field.value}
                name={field.name}
                label={label ? label : t("common.dateformat")}
                onChange={handleChange}
                onBlur={field.onBlur}
                format={t("common.dateformat")}
                fullWidth
                type={type}
                error={showError}
                variant="inline"
                inputVariant="outlined"
                aria-label={ariaLabel}
                helperText={!required && !hideOptional && t("common.optional")}
                keyboardIcon={<Icon name="calendar" style={iconStyle} />}
                KeyboardButtonProps={{ "data-id": dataId + "_icon" } as IconButtonProps}
            />
            <span
                style={{
                    color: "#f00",
                    display: "block",
                    minHeight: 18,
                    width: "100%",
                    marginTop: 4,
                    marginBottom: 4,
                    fontSize: 12,
                }}
            >
                {showError && fieldError}
            </span>
        </div>
    );
};
