import { Button, Divider, Link } from "@material-ui/core";
import sortBy from "lodash/sortBy";
import take from "lodash/take";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import styled from "styled-components";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { EventsFeedEntry, NewsFeedEntry, PublicationCategory, PublicationDocument } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { newestPublicationsCategory, newsStore } from "../../../stores/NewsStore";
import { clearTableStore } from "../../../stores/TableStore";
import { formatDate } from "../../../util/date";
import { pushRoute, toQueryString, withParams } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import type { TermsOfUseSiteState } from "../../app/sites/TermsOfUseSite";
import { useNews } from "../../hooks/useNews";
import { CenteredContent } from "../../ui/CenteredContent";
import { EmptyState } from "../../ui/EmptyState";
import { MobileNavBarContainer, NavBarTitle } from "../../ui/NavBarContainer";
import { NavBarInfo, NavBarInfoLinkButton, NavBarInfoMessage } from "../../ui/NavBarInfo";
import { TableLabel } from "../../ui/Primitives";
import { SwipeableViews } from "../../ui/SwipeableViews";
import { IIconNames, Icon } from "../../util/Icon";
import { DataUploadImage } from "../../util/Images";
import { MobileContext } from "../../util/MobileContext";
import { DIALOG_WIDTH, customColors } from "../../util/Theme";
import { CockpitCountries } from "../CockpitCountries";
import { CockpitEventCard, EVENT_CARD_WIDTH } from "../CockpitEventCard";
import { CockpitHeader } from "../CockpitHeader";
import { CockpitNewsCard } from "../CockpitNewsCard";
import { CockpitNotificationButton } from "../CockpitNotificationButton";
import { CockpitPromotionCard, PROMOTION_CARD_WIDTH } from "../CockpitPromotionCard";
import { KPIsCurrentCompany } from "../kpis/KPIs";
import { CockpitRoutes } from "../router/CockpitRoutes";

const NEWS_CARD_WIDTH = 272;
const NOTIFICATION_BUTTON_WIDTH = 272;
const MAX_EVENTS = 6;

const Section = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NewsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-gap: 16px;
`;

const PromotionNavigationButton = ({
    icon,
    disabled,
    onClick,
}: {
    icon: IIconNames;
    disabled?: boolean;
    onClick: () => void;
}) => {
    return (
        <Icon
            name={icon}
            onClick={disabled ? undefined : onClick}
            style={{
                cursor: disabled ? undefined : "pointer",
                color: disabled ? customColors.greyLight : customColors.greyDarkIcons,
            }}
        />
    );
};

const NewsContainer = ({
    swipeable,
    currentSwipeIndex,
    onChangeSwipeIndex,
    elementWidth,
    children,
    style,
}: {
    swipeable?: boolean;
    currentSwipeIndex?: number;
    onChangeSwipeIndex?: (index: number) => void;
    elementWidth: number;
    children: React.ReactNode;
    style?: React.CSSProperties;
}) =>
    swipeable ? (
        <SwipeableViews
            currentSwipeIndex={currentSwipeIndex}
            onChangeSwipeIndex={onChangeSwipeIndex}
            elementWidth={elementWidth}
            style={style}
        >
            {children}
        </SwipeableViews>
    ) : (
        <NewsGrid style={style}>{children}</NewsGrid>
    );

const Promotions = ({
    promotions,
    onClickPromotion,
    onDismissPromotion,
}: {
    promotions: NewsFeedEntry[];
    onClickPromotion: (promotion: NewsFeedEntry) => void;
    onDismissPromotion: (promotion: NewsFeedEntry) => void;
}) => {
    const [currentPromotionIndex, setCurrentPromotionIndex] = React.useState(0);

    const previousPromotionDisabled = currentPromotionIndex === 0;
    const nextPromotionDisabled = currentPromotionIndex === promotions.length - 1;

    const handleClickPreviousPromotion = () => {
        if (!previousPromotionDisabled) {
            setCurrentPromotionIndex(currentPromotionIndex - 1);
        }
    };

    const handleClickNextPromotion = () => {
        if (!nextPromotionDisabled) {
            setCurrentPromotionIndex(currentPromotionIndex + 1);
        }
    };

    const handleChangeSwipeIndex = (index: number) => {
        setCurrentPromotionIndex(index);
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 48, marginBottom: 24 }}>
                <h2 style={{ flex: 1 }}>{t("screen.cockpit.overview.promotions.title")}</h2>
                <PromotionNavigationButton
                    icon="chevronLeft"
                    onClick={handleClickPreviousPromotion}
                    disabled={previousPromotionDisabled}
                />
                <PromotionNavigationButton
                    icon="chevronRight"
                    onClick={handleClickNextPromotion}
                    disabled={nextPromotionDisabled}
                />
            </div>
            <SwipeableViews
                currentSwipeIndex={currentPromotionIndex}
                onChangeSwipeIndex={handleChangeSwipeIndex}
                elementWidth={PROMOTION_CARD_WIDTH}
            >
                {promotions.map(promotion => (
                    <CockpitPromotionCard
                        key={promotion.id}
                        promotion={promotion}
                        onClick={onClickPromotion}
                        onClose={onDismissPromotion}
                    />
                ))}
            </SwipeableViews>
        </>
    );
};

export const CockpitOverviewSite = observer(function CockpitOverviewSite() {
    const companyId = companiesStore.selectedCompanyId;
    const news = useNews({
        companyId,
        country: companiesStore.userCompanySettings?.feedCountryCode,
        limit: 10,
        setGeneralStoreIsLoading: false, // don't block the UI
    });
    const promotions = newsStore.promotions;
    const events = newsStore.events;
    const isMobile = React.useContext(MobileContext);
    const [selectedPublicationCategory, setSelectedPublicationCategory] = React.useState(newestPublicationsCategory());
    const publications = newsStore.getPublicationsForCategory(selectedPublicationCategory.id);

    const relevantNews = take(news.news, 3);
    const promotionsToDisplay = take(promotions, 15);

    if (!companyId) {
        return <Redirect to={Routes.COMPANY_OVERVIEW} />;
    }

    const handleDismissPromotion = async (promotion: NewsFeedEntry) => {
        try {
            await API.deletePromotion(companyId, promotion.id);
            newsStore.promotions = newsStore.promotions.filter(promotionItem => promotionItem.id !== promotion.id);
        } catch (error) {
            generalStore.setError(t("error.delete"), error);
        }
    };

    const handleClickNewsItem = (newsItem: NewsFeedEntry) => {
        pushRoute(withParams(CockpitRoutes.NEWS_ARTICLE, { newsId: newsItem.id }));
    };

    const handleClickShowAllNews = () => {
        clearTableStore();
        pushRoute(CockpitRoutes.NEWS_LIST);
    };

    const handleClickShowAllEvents = () => {
        clearTableStore();
        pushRoute(CockpitRoutes.EVENTS_LIST);
    };

    const handleClickPublicationCategory = (category: PublicationCategory) => () => {
        setSelectedPublicationCategory(category);
    };

    const handleClickDownloadPublication = (publicationId: string, document: PublicationDocument) => async () => {
        if (!companiesStore.selectedCompanyId) {
            generalStore.setError(t("error.noCompanySelected"));
            return;
        }

        try {
            window.open(document.url, "_blank");
            await API.getPublication({
                companyID: companiesStore.selectedCompanyId,
                publicationID: publicationId,
                lang: document.lang,
            });
        } catch (err) {
            generalStore.setError(t("error.download"), err);
        }
    };

    const handleClickEvent = (event: EventsFeedEntry) => {
        pushRoute(withParams(CockpitRoutes.EVENT_ARTICLE, { eventId: event.id }));
    };

    const handleClickTicketNotifications = () => {
        pushRoute(CockpitRoutes.TICKET_OVERVIEW);
    };

    const handleClickMessageNotifications = () => {
        pushRoute(Routes.SUPPORT);
    };

    const handleClickDueDateNotifications = () => {
        pushRoute(CockpitRoutes.DUE_DATES);
    };

    const badgeCounts = companiesStore.selectedCompanyStore?.badgeCounts ?? {};

    const hasNotificationSection =
        (badgeCounts.tickets ?? 0) > 0 || (badgeCounts.deadlines ?? 0) > 0 || (badgeCounts.unreadChatMessages ?? 0) > 0;

    const isEmpty =
        news.total === 0 &&
        promotions.length === 0 &&
        publications.length === 0 &&
        events.length === 0 &&
        !hasNotificationSection;

    return (
        <div>
            {isMobile && (
                <MobileNavBarContainer>
                    <NavBarTitle>{t("screen.cockpit.navbar.heading")}</NavBarTitle>
                </MobileNavBarContainer>
            )}
            <CenteredContent>
                <CockpitHeader
                    companyName={companiesStore.selectedCompany?.name}
                    backgroundImageUrl={companiesStore.selectedCompany?.coverImage?.imageUrl}
                    logoUrl={companiesStore.selectedCompany?.logoImage?.imageUrl}
                />
                <UpcomingTermsInfo />
                <div style={{ padding: isMobile ? "0 16px 40px 16px" : "0 48px 48px 48px" }}>
                    <CockpitCountries style={{ marginTop: 16 }} />
                    {promotionsToDisplay.length > 0 && (
                        <Promotions
                            promotions={promotionsToDisplay}
                            onClickPromotion={handleClickNewsItem}
                            onDismissPromotion={handleDismissPromotion}
                        />
                    )}
                    {GLOBAL_FEATURES.kpis && !!companiesStore.selectedCompanyStore?.kpiStore.canRead && (
                        <KPIsCurrentCompany style={{ marginTop: 48 }} />
                    )}
                    {GLOBAL_FEATURES.tickets && hasNotificationSection && (
                        <>
                            <Section style={{ marginTop: 48, marginBottom: 24 }}>
                                <h2>{t("screen.cockpit.overview.notifications.title")}</h2>
                            </Section>
                            <NewsGrid
                                style={{
                                    gridTemplateColumns: isMobile
                                        ? `repeat(auto-fit, ${NOTIFICATION_BUTTON_WIDTH}px)`
                                        : undefined,
                                }}
                            >
                                {!!badgeCounts.tickets && (
                                    <CockpitNotificationButton
                                        onClick={handleClickTicketNotifications}
                                        data-id="notification_button_tickets"
                                        icon="rueckfrage"
                                        text={t("screen.cockpit.overview.notifications.tickets")}
                                        count={badgeCounts.tickets ?? 0}
                                    />
                                )}
                                {GLOBAL_FEATURES.chat && !!badgeCounts.unreadChatMessages && (
                                    <CockpitNotificationButton
                                        onClick={handleClickMessageNotifications}
                                        data-id="notification_button_messages"
                                        icon="mail"
                                        text={t("screen.cockpit.overview.notifications.messages")}
                                        count={badgeCounts.unreadChatMessages ?? 0}
                                    />
                                )}
                                {GLOBAL_FEATURES.dueDates && !!badgeCounts.deadlines && (
                                    <CockpitNotificationButton
                                        onClick={handleClickDueDateNotifications}
                                        data-id="notification_button_dueDates"
                                        icon="time"
                                        text={t("screen.cockpit.overview.notifications.dueDates")}
                                        count={badgeCounts.deadlines}
                                    />
                                )}
                            </NewsGrid>
                        </>
                    )}
                    {news.total > 0 && (
                        <>
                            <Section style={{ marginTop: 48, marginBottom: 24 }}>
                                <h2>{t("screen.cockpit.overview.relevantNews.title")}</h2>
                                {news.total > 3 && (
                                    <Link
                                        component="button"
                                        color="inherit"
                                        underline="always"
                                        onClick={handleClickShowAllNews}
                                        style={{ fontSize: 10, whiteSpace: "nowrap", marginLeft: 16, fontWeight: 500 }}
                                    >
                                        {t("common.showAll")}
                                    </Link>
                                )}
                            </Section>
                            <NewsContainer swipeable={isMobile} elementWidth={NEWS_CARD_WIDTH}>
                                {relevantNews.map(newsItem => (
                                    <CockpitNewsCard key={newsItem.id} news={newsItem} onClick={handleClickNewsItem} />
                                ))}
                            </NewsContainer>
                        </>
                    )}
                    {GLOBAL_FEATURES.publications && (
                        <div style={{ marginTop: 48 }}>
                            <h2>{t("screen.cockpit.overview.relevantPublications.title")}</h2>
                            {/* Phase 1: kein Postversand laut User Story
                            <p className="body2" style={{ marginTop: 24, maxWidth: 656 }}>
                                {t("screen.cockpit.overview.relevantPublications.description")}
                            </p> */}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: isMobile ? "column" : "row",
                                    marginTop: isMobile ? 24 : 40,
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    {newsStore.publicationCategories.map(category => (
                                        <Button
                                            key={category.id}
                                            color={
                                                selectedPublicationCategory.id === category.id ? "primary" : undefined
                                            }
                                            size="small"
                                            onClick={handleClickPublicationCategory(category)}
                                            style={{
                                                justifyContent: "flex-start",
                                                marginTop: 8,
                                                fontSize: 14,
                                                lineHeight: "16px",
                                                letterSpacing: "0.1em",
                                                color:
                                                    selectedPublicationCategory.id === category.id
                                                        ? undefined
                                                        : customColors.greyTextfields,
                                            }}
                                        >
                                            {category.name.toUpperCase()}
                                        </Button>
                                    ))}
                                </div>
                                <div
                                    style={{
                                        flexGrow: 1,
                                        maxWidth: isMobile ? undefined : DIALOG_WIDTH,
                                        marginLeft: isMobile ? undefined : 16,
                                        marginTop: isMobile ? 24 : undefined,
                                        maxHeight: 300,
                                        overflow: "auto",
                                    }}
                                >
                                    {publications.map(publication => (
                                        <React.Fragment key={publication.id}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    height: 40,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    <div>
                                                        <Icon
                                                            size={16}
                                                            name="document"
                                                            style={{
                                                                display: "block",
                                                                color: customColors.primaryColor,
                                                            }}
                                                        />
                                                    </div>
                                                    {/* TODO rename TableLabel to something more generic */}
                                                    <TableLabel
                                                        style={{
                                                            marginLeft: 16,
                                                            maxWidth: "unset",
                                                            userSelect: "none",
                                                        }}
                                                    >
                                                        {publication.title}
                                                    </TableLabel>
                                                </div>
                                                {publication.documents && publication.documents.length > 0 && (
                                                    <div
                                                        style={{
                                                            marginLeft: 16,
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        {sortBy(publication.documents, "lang")
                                                            .map<React.ReactNode>(file => (
                                                                <Button
                                                                    key={file.lang}
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{
                                                                        minWidth: 0,
                                                                        fontSize: 14,
                                                                        lineHeight: "16px",
                                                                    }}
                                                                    onClick={handleClickDownloadPublication(
                                                                        publication.id,
                                                                        file,
                                                                    )}
                                                                >
                                                                    {file.lang.toUpperCase()}
                                                                </Button>
                                                            ))
                                                            .reduce((previous, current) => [previous, " | ", current])}
                                                    </div>
                                                )}
                                            </div>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {GLOBAL_FEATURES.events && events.length > 0 && (
                        <>
                            <Section style={{ marginTop: 48, marginBottom: 24 }}>
                                <h2>{t("common.events")}</h2>
                                {events.length > MAX_EVENTS && (
                                    <Link
                                        component="button"
                                        color="inherit"
                                        underline="always"
                                        onClick={handleClickShowAllEvents}
                                        style={{ fontSize: 10, whiteSpace: "nowrap", marginLeft: 16, fontWeight: 500 }}
                                    >
                                        {t("common.showAll")}
                                    </Link>
                                )}
                            </Section>
                            <NewsContainer swipeable={isMobile} elementWidth={EVENT_CARD_WIDTH}>
                                {take(events, MAX_EVENTS).map(event => (
                                    <CockpitEventCard key={event.id} event={event} onClick={handleClickEvent} />
                                ))}
                            </NewsContainer>
                        </>
                    )}
                    {isEmpty && (
                        <div style={{ marginTop: 124 }}>
                            <EmptyState
                                title={t("screen.cockpit.emptyState.title")}
                                message={t("screen.cockpit.emptyState.message")}
                                image={<DataUploadImage />}
                            />
                        </div>
                    )}
                </div>
            </CenteredContent>
            {/* TODO: Implement when tpa wants this
                {GLOBAL_FEATURES.cockpitFilebar && isFileBarVisible && (
                <FileBar
                    sections={sections}
                    title={t("common.files")}
                    buttonText={t("common.showAll")}
                    canEdit
                    noNavBar
                />
            )} */}
        </div>
    );
});

const UpcomingTermsInfo = observer(() => {
    const upcomingCompanyTerms = companiesStore.selectedCompany?.upcomingCompanyTerms;
    if (!upcomingCompanyTerms || upcomingCompanyTerms.acceptedAt) {
        return null;
    }

    return (
        <NavBarInfo centered={false}>
            <NavBarInfoMessage>
                {t("termsOfUse.upcoming.navbar.message", {
                    date: formatDate(upcomingCompanyTerms.requiredFrom),
                })}
            </NavBarInfoMessage>
            {!!upcomingCompanyTerms.token && (
                <NavBarInfoLinkButton
                    to={{
                        pathname: Routes.TERMS_OF_USE,
                        search: toQueryString({
                            token: upcomingCompanyTerms.token,
                        }),
                        state: {
                            backLabel: t("sidebar.list.cockpit"),
                            backTarget: Routes.COCKPIT,
                        } satisfies TermsOfUseSiteState,
                    }}
                >
                    {t("termsOfUse.upcoming.navbar.button")}
                </NavBarInfoLinkButton>
            )}
        </NavBarInfo>
    );
});
