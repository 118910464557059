import * as React from "react";
import { DropEvent, FileRejection } from "react-dropzone";
import { ACCEPTED_IMAGE_FORMATS, MAX_IMAGE_UPLOAD_SIZE_MB } from "../../config";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { filterEmptyFiles, filterInvalidFiles } from "../../util/files";
import { isIos } from "../../util/helpers";
import { DropZoneAvatar } from "./DropZoneAvatar";
import { ImageCropDialog } from "./ImageCropDialog";

export const AvatarUpload = (props: {
    uploadFile: (file: File) => Promise<void>;
    "data-id"?: string;
    disabled?: boolean;
    fallbackErrorMessage?: string;
    style?: React.CSSProperties;
}) => {
    const [image, setImage] = React.useState<File | null>(null);
    const [isUploading, setIsUploading] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState("");

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        if (isIos()) {
            const filtered = filterInvalidFiles(acceptedFiles, fileRejections, ACCEPTED_IMAGE_FORMATS);
            acceptedFiles = filtered.accepted;
            fileRejections = filtered.rejected;
        }
        const { accepted, rejected } = filterEmptyFiles(acceptedFiles, fileRejections);

        if (!props.disabled) {
            if (accepted.length === 1 && rejected.length === 0) {
                // Can only add single file
                setImage(accepted[0]);
            } else if (rejected.length === 1) {
                generalStore.setError(t("error.avatarUploadFailed", { maxSize: MAX_IMAGE_UPLOAD_SIZE_MB }));
            } else if (rejected.length > 1) {
                generalStore.setError(t("error.avatarUploadOnlyOneImage"));
            } else {
                generalStore.setError(t("common.errorOccurred"));
            }
        }
    };

    const onSave = async (blob: Blob) => {
        if (!image) {
            return;
        }

        const file = new File([blob], image.name, { type: image.type });
        try {
            setIsUploading(true);
            await props.uploadFile(file);
            setImage(null);
            const url = URL.createObjectURL(blob);
            setImageUrl(url);
        } catch (err) {
            generalStore.setError(t("error.upload"), err);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <>
            <DropZoneAvatar
                isUploading={isUploading}
                onDrop={onDrop}
                data-id={props["data-id"]}
                fileTypes={ACCEPTED_IMAGE_FORMATS}
                disabled={props.disabled}
                imageUrl={imageUrl}
            />
            <ImageCropDialog
                image={image}
                onSave={onSave}
                onCancel={() => {
                    setImage(null);
                }}
                disableSave={isUploading}
                cropCircle
            />
        </>
    );
};
