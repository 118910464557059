import Decimal from "decimal.js";
import {
    EmployeeDetail as ApiEmployeeDetail,
    PayrollItem as ApiPayrollItem,
    PayrollTypeValue as ApiPayrollTypeValue,
    PreviousYearDetail as ApiPreviousYearDetail,
    PayrollType,
} from "../../network/APITypes";

export type PayrollItem = Omit<ApiPayrollItem, "employees" | "months" | "previousYears" | "total"> & {
    payrollType?: PayrollType;
    employees?: EmployeeDetail[];
    months?: PayrollTypeValue[];
    previousYears?: PreviousYearDetail[];
    total?: PayrollTypeValue;
};

export type PreviousYearDetail = Omit<ApiPreviousYearDetail, "employees" | "months" | "total"> & {
    employees?: EmployeeDetail[];
    months?: PayrollTypeValue[];
    total?: PayrollTypeValue;
};

export type PayrollTypeValue = Omit<
    ApiPayrollTypeValue,
    "amount" | "deviationFromLastMonth" | "deviationFromLastYear"
> & {
    amount: Decimal;
    deviationFromLastMonth: Decimal;
    deviationFromLastYear: Decimal;
    deviationFromLastYearPercentage: Decimal;
};

export type EmployeeDetail = Omit<ApiEmployeeDetail, "months" | "total" | "lastYearTotalAmount"> & {
    months?: PayrollTypeValue[];
    total?: PayrollTypeValue;
    lastYearTotalAmount: Decimal;
};

const calculatePercentageChange = (totalAmount: Decimal, previousAmount: Decimal) => {
    if (previousAmount.isZero()) return new Decimal(0); // Prevent division by zero

    const change = totalAmount.sub(previousAmount);

    if (change.isZero()) return new Decimal(0);

    const percentage = change.times(100).dividedBy(previousAmount);
    return percentage;
};

export const mapPayrollItem = (item: ApiPayrollItem): PayrollItem => {
    return {
        ...item,
        payrollType: item.payrollType,
        employees: item.employees?.map(mapEmployeeDetail),
        months: item.months?.map(mapPayrollTypeValue),
        previousYears: item.previousYears?.map(mapPreviousYearDetail),
        total: item.total ? mapPayrollTypeValue(item.total) : undefined,
    };
};

const mapEmployeeDetail = (employee: ApiEmployeeDetail): EmployeeDetail => ({
    ...employee,
    months: employee.months?.map(mapPayrollTypeValue),
    total: employee.total ? mapPayrollTypeValue(employee.total) : undefined,
    lastYearTotalAmount: new Decimal(employee.lastYearTotalAmount ?? 0),
});

const mapPayrollTypeValue = (month: ApiPayrollTypeValue): PayrollTypeValue => ({
    amount: new Decimal(month.amount ?? 0),
    deviationFromLastMonth: new Decimal(month.deviationFromLastMonth ?? 0),
    deviationFromLastYear: new Decimal(month.deviationFromLastYear ?? 0),
    deviationFromLastYearPercentage: calculatePercentageChange(
        new Decimal(month.amount ?? 0),
        new Decimal(month.amount ?? 0).sub(new Decimal(month.deviationFromLastYear ?? 0)),
    ),
});

const mapPreviousYearDetail = (previousYear: ApiPreviousYearDetail): PreviousYearDetail => ({
    employees: previousYear.employees?.map(mapEmployeeDetail),
    months: previousYear.months?.map(mapPayrollTypeValue),
    total: previousYear.total ? mapPayrollTypeValue(previousYear.total) : undefined,
});
