import { Tab, Tabs } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router";
import { t } from "../../i18n/util";
import { pushRoute } from "../app/router/history";
import { NavBarBack } from "../ui/NavBarBack";
import { TicketsRoutes } from "./router/TicketsRoutes";

type TicketsPage = "open" | "reported" | "closed";

export const TicketsNavBar = observer(function TicketsNavBar() {
    const { pathname } = useLocation();
    const [page, setPage] = React.useState<TicketsPage | null>("open");

    React.useEffect(() => {
        if (pathname === TicketsRoutes.OPEN) {
            setPage("open");
        } else if (pathname === TicketsRoutes.AUTHORED) {
            setPage("reported");
        } else if (pathname === TicketsRoutes.CLOSED) {
            setPage("closed");
        }
    }, [pathname]);

    const handlePageChange = (_: React.ChangeEvent<unknown>, page: TicketsPage | null) => {
        setPage(page);
    };

    return (
        <NavBarBack
            title={t("sidebar.advisor.tickets")}
            tabs={
                <Tabs
                    value={page}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handlePageChange}
                    variant="scrollable"
                    scrollButtons="off"
                >
                    <Tab
                        label={t("screen.tickets.navbar.tabbar.open")}
                        value="open"
                        onClick={() => {
                            pushRoute(TicketsRoutes.OPEN);
                        }}
                        data-id="tickets_tab_bar_open"
                    />
                    <Tab
                        label={t("screen.tickets.navbar.tabbar.reported")}
                        value="reported"
                        onClick={() => {
                            pushRoute(TicketsRoutes.AUTHORED);
                        }}
                        data-id="tickets_tab_bar_reported"
                    />
                    <Tab
                        label={t("screen.tickets.navbar.tabbar.closed")}
                        value="closed"
                        onClick={() => {
                            pushRoute(TicketsRoutes.CLOSED);
                        }}
                        data-id="tickets_tab_bar_closed"
                    />
                </Tabs>
            }
        />
    );
});
