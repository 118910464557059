import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Redirect } from "react-router";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { coordinator } from "../../../stores/Coordinator";
import { getMfaValidation, passwordRegex } from "../../../util/helpers";
import { Routes } from "../../app/router/Routes";
import { useMultiFactorAuth } from "../../hooks/useMultiFactorAuth";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { CustomInputField } from "../../ui/CustomInputField";
import { AuthGeneralContainerSite } from "./AuthGeneralContainerSite";

export const AuthForgotPasswordResetSite = () => {
    const successDialog = useSuccessDialog({
        title: t("dialog.password_reset.success.header"),
        onClose: async () => {
            await coordinator.refreshAfterLogin();
        },
    });

    const { token } = useQueryParams<{ token: string }>();

    const handleAuthActionComplete = () => {
        successDialog.openDialog();
    };

    const { currentAuthStep, mfaResendSuccessDialog, onSubmitAuthAction, onSubmitAuthActionCompleteMfa } =
        useMultiFactorAuth(handleAuthActionComplete);

    if (!token) {
        return <Redirect to={Routes.ROOT} />;
    }

    return (
        <AuthGeneralContainerSite>
            {currentAuthStep !== "multiFactorAuth" && (
                <>
                    <h1 style={{ marginBottom: 16 }}>{t("screen.auth.forgot_password_reset.title")}</h1>
                    <p style={{ marginBottom: 32 }}>{t("screen.auth.forgot_password_reset.subtitle")}</p>

                    <Formik
                        initialValues={{
                            password: "",
                            repeatPassword: "",
                        }}
                        onSubmit={model => onSubmitAuthAction("passwordReset", token, model)}
                        validationSchema={Yup.object().shape({
                            password: Yup.string()
                                .matches(passwordRegex, t("screen.auth.forgot_password_reset.error.password_weak"))
                                .required(t("screen.auth.forgot_password_reset.error.password_not_set")),
                            repeatPassword: Yup.string()
                                .oneOf(
                                    [Yup.ref("password")],
                                    t("screen.auth.forgot_password_reset.error.passwords_do_not_match"),
                                )
                                .required(t("screen.auth.forgot_password_reset.error.password_not_set")),
                        })}
                    >
                        <Form style={{ width: "100%", textAlign: "left" }}>
                            <Field
                                component={CustomInputField}
                                label={t("screen.auth.forgot_password_reset.form.password")}
                                name="password"
                                type="password"
                                required
                                style={{ marginBottom: 16 }}
                            />
                            <Field
                                component={CustomInputField}
                                label={t("screen.auth.forgot_password_reset.form.repeat_password")}
                                name="repeatPassword"
                                type="password"
                                required
                                style={{ marginBottom: 8 }}
                            />

                            <div style={{ fontSize: 12, marginBottom: 24 }}>
                                <p>{t("screen.auth.registration.form.section_1")}</p>
                                <p style={{ marginTop: 8, marginBottom: 8 }}>
                                    {t("screen.auth.registration.form.section_2")}
                                </p>
                                <p>{t("screen.auth.registration.form.section_3")}</p>
                            </div>

                            <Button variant="contained" color="primary" type="submit" fullWidth>
                                {t("screen.auth.forgot_password_reset.form.button")}
                            </Button>
                        </Form>
                    </Formik>
                </>
            )}
            {currentAuthStep === "multiFactorAuth" && (
                <>
                    <h1 style={{ marginBottom: 16 }}>{t("screen.auth.forgot_password_reset.title.mfa")}</h1>
                    <p style={{ marginBottom: 32 }}>{t("screen.auth.forgot_password_reset.subtitle.mfa")}</p>

                    <Formik
                        initialValues={{
                            code: "",
                        }}
                        onSubmit={model => onSubmitAuthActionCompleteMfa("passwordReset", model, token)}
                        validationSchema={getMfaValidation()}
                    >
                        <Form style={{ width: "100%", textAlign: "left" }}>
                            <Field
                                component={CustomInputField}
                                label={t("screen.auth.forgot_password_reset.form.mfa")}
                                name="code"
                                required
                                style={{ marginBottom: 16 }}
                            />
                            <Button
                                style={{ marginBottom: 24 }}
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                            >
                                {t("screen.auth.forgot_password_reset.form.button.mfa")}
                            </Button>
                            <Button
                                style={{ marginTop: 24 }}
                                onClick={() => onSubmitAuthAction("passwordReset", token)}
                                color="primary"
                                data-id="resend_code"
                            >
                                {t("screen.auth.forgot_password_reset.form.button.mfa.resend_code").toUpperCase()}
                            </Button>
                        </Form>
                    </Formik>
                </>
            )}
            {mfaResendSuccessDialog.dialog}
            {successDialog.dialog}
        </AuthGeneralContainerSite>
    );
};
