import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { IRecord, Module } from "../../types/models";

export const useRecord = (
    module: Module,
    companyId?: string,
    subsidiaryId?: string,
    periodId?: string,
    recordTypeId?: string,
    recordId?: string,
): IRecord | null => {
    const [record, setRecord] = React.useState<IRecord | null>(null);

    React.useEffect(() => {
        const load = async () => {
            try {
                generalStore.isLoading = true;
                const response = await API.getRecord({
                    companyId,
                    module,
                    periodId,
                    subsidiaryId,
                    recordTypeId,
                    recordId,
                });

                if (response) {
                    setRecord(response);
                }
            } catch (err) {
                generalStore.setError(t("error.loadRecord"), err);
            } finally {
                generalStore.isLoading = false;
            }
        };

        load();
    }, [companyId, module, periodId, recordId, recordTypeId, subsidiaryId]);

    return record;
};
