import * as React from "react";
import { CenteredContent } from "../ui/CenteredContent";
import { SiteContent } from "../ui/SiteContent";
import { MobileContext } from "../util/MobileContext";
import { DIALOG_WIDTH } from "../util/Theme";

export const PageWithStepper = (props: {
    stepper: React.ReactNode;
    children: React.ReactNode;
    fullWidth?: boolean;
}) => {
    const isMobile = React.useContext(MobileContext);
    return (
        <CenteredContent>
            <SiteContent>
                {isMobile && props.stepper}
                <div
                    style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "space-between",
                        paddingTop: isMobile ? 16 : 32,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            maxWidth: props.fullWidth ? undefined : DIALOG_WIDTH,
                            marginRight: 32,
                        }}
                    >
                        {props.children}
                    </div>
                    {!isMobile && props.stepper}
                </div>
            </SiteContent>
        </CenteredContent>
    );
};
