import { observable } from "mobx";
import { NAVBAR_HEIGHT } from "../config";
import { addStoreToWindow } from "../util/debug";

class StylesStore {
    @observable _navBarHeight = NAVBAR_HEIGHT;
    get navBarHeight() {
        return this._navBarHeight;
    }
    set navBarHeight(height: number) {
        this._navBarHeight = height;
        this.updateStyles();
    }

    private stylesheet: HTMLStyleElement;

    constructor() {
        this.stylesheet = document.createElement("style");
        document.head.appendChild(this.stylesheet);
        this.updateStyles();
    }

    private updateStyles() {
        this.stylesheet.innerHTML = `
            :root {
                --navBarHeight: ${this._navBarHeight}px;
            }
        `;
    }
}

export const stylesStore = new StylesStore();

addStoreToWindow("stylesStore", stylesStore);
