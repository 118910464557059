import { Tab, Tabs } from "@material-ui/core";
import * as React from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { GLOBAL_FEATURES } from "../../features";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";
import { getModuleRoutes } from "../../types/moduleRoutes";
import { pushRoute, withParams } from "../app/router/history";
import { NavBarBack } from "../ui/NavBarBack";

export const ModuleRecordsNavBar = ({ module }: { module: Module }) => {
    const { recordTypeId } = useParams<{ recordTypeId: string }>();
    const { pathname } = useLocation();
    const [page, setPage] = React.useState<string | null>("tickets");
    const isAccounting = module === "accounting";
    const store = getModuleStore(module);
    const moduleRoutes = getModuleRoutes(module);
    const recordType = store.getRecordTypeForId(recordTypeId);

    React.useEffect(() => {
        if (!recordTypeId) {
            return;
        }

        if (pathname === withParams(moduleRoutes.RECORDS.TICKETS, { recordTypeId })) {
            setPage("tickets");
        } else if (pathname === withParams(moduleRoutes.RECORDS.LIST, { recordTypeId })) {
            setPage("records");
        }
    }, [moduleRoutes.RECORDS.LIST, moduleRoutes.RECORDS.TICKETS, pathname, recordTypeId]);

    if (!recordTypeId || !recordType) {
        return <Redirect to={moduleRoutes.ROOT} />;
    }

    const handlePageChange = (_: React.ChangeEvent<unknown>, page: string | null) => {
        setPage(page);
    };

    const ticketCount = recordType.ticketCount ?? 0;

    return (
        <NavBarBack
            title={recordType ? store.getRecordTypeName(recordType) : ""}
            backLabel={store.t("screen.accounting.records.upload.navbar.back")}
            backTarget={store.routes.RECORDS.ROOT}
            moduleInfo={module}
            showCancel={false}
            tabs={
                ticketCount > 0 && GLOBAL_FEATURES.tickets ? (
                    <Tabs
                        value={page}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handlePageChange}
                        variant="scrollable"
                        scrollButtons="off"
                    >
                        <Tab
                            label={store.t("screen.accounting.records.navbar.tab.tickets", {
                                count: ticketCount,
                            })}
                            value="tickets"
                            onClick={() => {
                                pushRoute(withParams(moduleRoutes.RECORDS.TICKETS, { recordTypeId }));
                            }}
                            data-id={`${isAccounting ? "accounting" : "hr"}_tab_tickets`}
                        />
                        <Tab
                            label={store.t("screen.accounting.records.navbar.tab.list", {
                                count: recordType.recordCount,
                            })}
                            value="records"
                            onClick={() => {
                                pushRoute(withParams(moduleRoutes.RECORDS.LIST, { recordTypeId }));
                            }}
                            data-id={`${isAccounting ? "accounting" : "hr"}_tab_records`}
                        />
                    </Tabs>
                ) : undefined
            }
        />
    );
};
