import * as React from "react";
import { Divider } from "@material-ui/core";
import { User } from "../ui/User";
import { useResize } from "../hooks/useResize";
import { ProjectItemPermission, ProjectItemPermissionRoleEnum } from "../../network/APITypes";
import { IMessageIDS, t } from "../../i18n/util";

interface IProps {
    permissionUsers: ProjectItemPermission[];
}

const messageIdByRole: { [role in ProjectItemPermissionRoleEnum]: IMessageIDS } = {
    owner: "projectItemPermission.owner",
    write: "projectItemPermission.write",
    read: "projectItemPermission.read",
    "read-no-download": "projectItemPermission.readNoDownload",
};

export const PermissionUsersList = ({ permissionUsers }: IProps) => {
    const [hasVerticalScrollbar, setHasVerticalScrollbar] = React.useState(false);
    const listRef = React.useRef<HTMLDivElement>(null);
    const dimensions = useResize(listRef);

    React.useEffect(() => {
        if (listRef.current) {
            setHasVerticalScrollbar(listRef.current.scrollHeight > listRef.current.clientHeight);
        }
    }, [dimensions]);

    return (
        <>
            {hasVerticalScrollbar && <Divider style={{ marginTop: 16 }} />}
            <div ref={listRef} style={{ padding: "16px 42px", overflow: "auto" }}>
                {permissionUsers.map(user => {
                    const nameParts = user.name.split(" ");

                    return (
                        <div
                            key={user.id}
                            style={{
                                height: 64,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <User
                                    key={user.id}
                                    showAvatarOnly
                                    isTpaStaff={user.isTpaEmployee}
                                    size={40}
                                    firstName={nameParts[0] ?? ""}
                                    lastName={nameParts[1] ?? ""}
                                    imageUrl={user.profile_picture_url}
                                />
                                <p style={{ marginLeft: 12 }}>{user.name}</p>
                            </div>
                            <div style={{ marginLeft: 16 }}>{t(messageIdByRole[user.role])}</div>
                        </div>
                    );
                })}
            </div>
            {hasVerticalScrollbar && <Divider style={{ marginBottom: 16 }} />}
        </>
    );
};
