import compact from "lodash/compact";
import { t } from "../../i18n/util";
import { Company } from "../../network/APITypes";
import { withParams } from "../app/router/history";
import { Stepper } from "../ui/Stepper";
import { AdvisorRoutes } from "./router/AdvisorRoutes";

export const AdvisorConfigCompanyStepper = (props: { company: Company }) => {
    const steps = compact([
        {
            label: t("config.companyData.title"),
            url: withParams(AdvisorRoutes.CONFIG.COMPANY_DATA, { companyId: props.company.id }),
        },
        {
            label: t("config.terms.termsOfUseTitle"),
            url: withParams(AdvisorRoutes.CONFIG.TERMS_OF_USE, { companyId: props.company.id }),
        },
        props.company.accountingEnabled && {
            label: t("common.accounting"),
            url: withParams(AdvisorRoutes.CONFIG.ACCOUNTING, { companyId: props.company.id }),
        },
        props.company.hrEnabled && {
            label: t("common.hr"),
            url: withParams(AdvisorRoutes.CONFIG.HR, { companyId: props.company.id }),
        },
        props.company.accountingEnabled &&
            props.company.hasCockpitKPI && {
                label: t("common.kpi"),
                url: withParams(AdvisorRoutes.CONFIG.KPIS, { companyId: props.company.id }),
            },
    ]);

    return <Stepper steps={steps} />;
};
