import * as React from "react";
import { MobileContext } from "../util/MobileContext";

// Container that can be split with a percentage into left and right, or with fixed left width
// Mobile view is currently up/down fixed
export const SplitContainer = ({
    style,
    leftWidth,
    splitPercentage = 50,
    children,
    leftChildren,
}: {
    style?: React.CSSProperties;
    leftWidth?: number;
    splitPercentage?: number;
    leftChildren: React.ReactNode;
    children?: React.ReactNode;
}) => {
    const isMobile = React.useContext(MobileContext);

    const leftStyle = leftWidth ? { width: leftWidth } : { flex: splitPercentage };
    const rightStyle = leftWidth ? { flex: 1 } : { flex: 100 - splitPercentage };
    return (
        <div
            style={{
                display: "flex",
                flexGrow: 1,
                width: "100%",
                flexDirection: isMobile ? "column" : "row",
                ...style,
            }}
        >
            <div style={{ maxWidth: isMobile ? "100vw" : undefined, ...leftStyle }}>{leftChildren}</div>
            <div style={{ width: isMobile ? "100%" : undefined, minWidth: 0, ...rightStyle }}>{children}</div>
        </div>
    );
};
