import { StyledPagination, StyledPaginationItem } from "./TablePagination";

export const DocumentPagination = ({
    pageCount,
    page,
    onChangePage,
}: {
    pageCount: number;
    page: number;
    onChangePage: (page: number) => void;
}) => {
    const handleChangePage = (_: unknown, page: number) => {
        // mui pagination is 1 base index
        onChangePage(page - 1);
    };

    return pageCount > 1 ? (
        <StyledPagination
            color="primary"
            count={pageCount}
            page={page + 1} // // mui pagination is 1 base index
            onChange={handleChangePage}
            size="small"
            renderItem={item => <StyledPaginationItem {...item} />}
        />
    ) : null;
};
