import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { ExternalDocument, GetDocumentsResponseErrors } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { generalStore } from "../../stores/GeneralStore";
import { getFilenameFromPath } from "../../util/helpers";
import { ErrorDialog } from "../ui/ErrorDialog";
import { DialogErrorLine } from "../ui/FailedUploadDialog";

export function useExternalDocuments({
    archive,
    documentIds,
    databoxIds,
    initialState,
    generateThumbnails,
}: {
    archive?: string;
    documentIds?: string[];
    databoxIds?: string[];
    initialState?: ExternalDocument[];
    generateThumbnails?: boolean;
}) {
    const initialStateRef = React.useRef(initialState);
    const [documents, setDocuments] = React.useState<ExternalDocument[]>(initialState ?? []);
    const [errors, setErrors] = React.useState<GetDocumentsResponseErrors[]>([]);

    useDeepCompareEffect(() => {
        // Load document infos from BMD
        const loadDocuments = async () => {
            // We have preloaded matching archive documents
            const initialDocuments = initialStateRef.current;
            if (initialDocuments && initialDocuments.length > 0) {
                const ids = initialDocuments.map(d => d.documentId);
                const equalIds = isEqual(sortBy(ids), sortBy(documentIds));
                if (archive === initialDocuments[0].archiveId && equalIds) {
                    return;
                }
            }

            const hasArchive = archive && documentIds && documentIds.length > 0;
            const hasDatabox = databoxIds && databoxIds.length > 0;
            if (!hasArchive && !hasDatabox) {
                return;
            }

            try {
                const documents = await API.getArchiveDocument(archive, documentIds, databoxIds, generateThumbnails);

                // Extract filename from absolute path names
                documents.documents?.forEach(d => {
                    d.name = getFilenameFromPath(d.name);
                });

                if (documents.documents) {
                    setDocuments(documents.documents);
                }

                if (documents.errors) {
                    setErrors(documents.errors);
                }
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.statusCode === HttpStatusCode.Forbidden_403) {
                    generalStore.setError(t("error.notAdvisor"), error);
                } else {
                    generalStore.setError(t("error.loadDocument"), error);
                }
            }
        };

        loadDocuments();
    }, [archive, databoxIds, documentIds, generateThumbnails]);

    const hasErrors = errors && errors.length > 0;
    const errorDialog = (
        <ErrorDialog
            open={hasErrors}
            message={t("error.loadArchiveDocuments")}
            onClose={() => {
                setErrors([]);
            }}
            buttonLabel={t("dialog.button.understood")}
        >
            <div style={{ paddingTop: 16 }}>
                {errors.map(error => {
                    let title = t("error.loading");
                    if (error.error.type === "DOCUMENT_FORBIDDEN") {
                        title = t("error.documentForbidden");
                    } else if (error.error.type === "RECORD_FORBIDDEN") {
                        title = t("error.recordForbidden");
                    } else if (error.error.type === "DOCUMENT_NOT_FOUND") {
                        title = t("error.documentNotFound");
                    }

                    return (
                        <DialogErrorLine
                            key={error.documentId}
                            fileName={error.name ?? error.documentId}
                            infoTitle={title}
                        />
                    );
                })}
            </div>
        </ErrorDialog>
    );

    return { documents, setDocuments, errorDialog };
}
