import React from "react";
import styled from "styled-components";

const Root = styled.div`
    display: flex;
    height: 100%;
`;

const Content = styled.div`
    margin: auto;
    border: 16px solid transparent;
`;

export const Centered = ({ style, children }: { style?: React.CSSProperties; children: React.ReactNode }) => {
    return (
        <Root style={style}>
            <Content>{children}</Content>
        </Root>
    );
};

const Panel = styled.div`
    background: white;
    padding: 16px;
    tab-size: 4;
    white-space: pre-wrap;
`;

export const CenteredPanel = ({
    style,
    panelStyle,
    children,
}: {
    style?: React.CSSProperties;
    panelStyle?: React.CSSProperties;
    children: React.ReactNode;
}) => {
    return (
        <Centered style={style}>
            <Panel style={panelStyle}>{children}</Panel>
        </Centered>
    );
};
