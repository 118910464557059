import * as React from "react";
import { Redirect, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { configStore } from "../../../stores/ConfigStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { withParams } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { EmployeesTable } from "../../shared/EmployeesTable";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { MobileContext } from "../../util/MobileContext";
import { AdvisorRoutes } from "../router/AdvisorRoutes";

export const AdvisorConfigNewUsersSite = () => {
    useHideSideBar();

    const { companyId } = useParams<{ companyId?: string }>();
    const { company } = useCompany({ companyId });
    const isMobile = React.useContext(MobileContext);

    React.useEffect(() => {
        configStore.wipe();
    }, []);

    if (!companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    return (
        <>
            <NavBarBack
                title={t("config.users.newUsers")}
                backLabel={t("config.users.navBar.back")}
                backTarget={withParams(AdvisorRoutes.CONFIG.USERS, { companyId })}
                companyName={company?.name ?? ""}
            />
            {company && (
                <CenteredContent>
                    <SiteContent>
                        <h1 style={{ marginTop: isMobile ? 16 : 32 }}>{t("config.users.newUsers.title")}</h1>
                        <p style={{ fontSize: 12, marginTop: 16, marginBottom: 8 }}>
                            {t("config.users.newUsers.description")}
                        </p>
                        <EmployeesTable />
                    </SiteContent>
                </CenteredContent>
            )}
        </>
    );
};
