import React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetTermsOfUseSigneesResponse } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useSignees = (companyId?: string, companyTermsId?: string) => {
    const [signees, setSignees] = React.useState<GetTermsOfUseSigneesResponse>();
    const [initialized, setInitialized] = React.useState(false);

    const load = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        if (!companyTermsId) {
            setSignees({ signees: [] });
            setInitialized(true);
            return;
        }

        try {
            const response = await API.getTermsOfUseSignees(companyId, { companyTermsId });
            setSignees(response);
        } catch (error) {
            generalStore.setError(t("error.loadTermsofUse"), error);
        } finally {
            setInitialized(true);
        }
    }, [companyId, companyTermsId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        signees,
        initialized,
        reload: load,
    };
};
