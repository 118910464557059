import omit from "lodash/omit";
import * as React from "react";
import { TableFilterButtons } from "../ui/filter/TableFilter";
import { TableFilterDialog } from "../ui/filter/TableFilterDialog";
import { TableFilterDrawer } from "../ui/filter/TableFilterDrawer";
import { ITableFilterCategory, ITableFilters } from "../ui/filter/types";
import { MobileContext } from "../util/MobileContext";

export const useTableFilters = ({
    filters,
    onChangeFilters,
    showAllButton,
    initialFilters,
    showAsDrawer,
}: {
    filters: ITableFilterCategory[];
    onChangeFilters?: (selected: ITableFilters) => void;
    showAllButton?: boolean; // false if you don't want to show an "all" button that removes all filters
    initialFilters?: ITableFilters;
    /** Display the filter inside a drawer on the right side instead of inside a dialog. */
    showAsDrawer?: boolean;
}) => {
    const [activeFilters, setActiveFilters] = React.useState<ITableFilters>(initialFilters ?? {});
    const [open, setOpen] = React.useState(false);
    const handleChangeFilters = (newFilters: ITableFilters) => {
        setActiveFilters(newFilters);
        onChangeFilters?.(newFilters);
    };

    // Cannot use function form because of remount issues
    const Buttons = (
        <TableFilterButtons
            filters={filters}
            onChange={handleChangeFilters}
            activeFilters={activeFilters}
            showAllButton={showAllButton}
        />
    );

    const isMobile = React.useContext(MobileContext);

    let Components;
    if (showAsDrawer && !isMobile) {
        Components = (
            <TableFilterDrawer
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                filters={filters}
                activeFilters={activeFilters}
                onSubmit={handleChangeFilters}
            />
        );
    } else {
        Components = (
            <TableFilterDialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                filters={filters}
                activeFilters={activeFilters}
                onSubmit={handleChangeFilters}
            />
        );
    }

    const changeFilter = React.useCallback((name: string, value: boolean | Date | string | null) => {
        if (value) {
            setActiveFilters(activeFilters => ({
                ...activeFilters,
                [name]: {
                    value,
                },
            }));
        } else {
            setActiveFilters(activeFilters => omit(activeFilters, name));
        }
    }, []);

    return {
        activeFilters,
        isActive: (name: string) => {
            return !!activeFilters[name];
        },
        changeFilter,
        open: () => {
            setOpen(true);
        },
        Buttons,
        Components,
    };
};

export type ITableFiltersConfig = ReturnType<typeof useTableFilters>;
