import debounce from "lodash/debounce";
import * as React from "react";
import { COMPANY_LIMIT, SEARCH_DEBOUNCE_MS } from "../../config";
import { t } from "../../i18n/util";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../ui/ContextMenu";
import { useCompanies } from "./useCompanies";

export const useCompanyContextMenu = ({
    getSelectedCompany,
    disableSelected,
    onClickCompanyItem,
    onClickRemainingCompanyItems,
}: {
    getSelectedCompany?: (companyId: string) => boolean;
    disableSelected?: boolean;
    onClickCompanyItem: (companyId: string, companyName: string) => void;
    onClickRemainingCompanyItems?: () => void;
}) => {
    const contextMenu = useContextMenu();
    const [filter, setFilter] = React.useState("");
    const [search, setSearch] = React.useState("");
    const [numCompanies, setNumCompanies] = React.useState<number | undefined>(undefined);
    const {
        initialized,
        companies,
        total,
        remainingCount: remainingCompanies,
        loadNext,
    } = useCompanies({
        search: filter,
        limit: COMPANY_LIMIT,
    });

    React.useEffect(() => {
        if (initialized && !filter && numCompanies === undefined) {
            setNumCompanies(total);
        }
    }, [filter, initialized, numCompanies, total]);

    const items: ContextMenuItem[] = companies.map((company, index) => ({
        title: company.name,
        onClick: () => {
            onClickCompanyItem(company.id, company.name);
        },
        selected: getSelectedCompany ? getSelectedCompany(company.id) : false,
        disabled: disableSelected && getSelectedCompany ? getSelectedCompany(company.id) : false,
        "data-id": company.name,
    }));

    if (remainingCompanies > 0) {
        items.push({
            title: t("companyList.remainingCompanies", { remainingCompanies }),
            onClick: onClickRemainingCompanyItems ?? loadNext,
            selected: false,
            "data-id": "further_companies",
        });
    }

    const applyFilter = React.useMemo(
        () =>
            debounce((value: string) => {
                setFilter(value);
            }, SEARCH_DEBOUNCE_MS),
        [],
    );

    const handleChange = (value: string) => {
        setSearch(value);
        applyFilter(value);
    };

    const hasMoreThanOneCompany = (numCompanies ?? 0) > 1;

    const contextMenuProps: React.ComponentProps<typeof ContextMenu> = {
        config: contextMenu,
        "data-id": "choose_company_context_menu",
        items: items,
        maxHeight: 270,
        maxWidth: 275,
        searchField: hasMoreThanOneCompany
            ? {
                  "data-id": "context_menu_companies_search",
                  placeholder: t("search.placeholder.companies"),
                  value: search,
                  onChange: value => {
                      handleChange(value);
                  },
              }
            : undefined,
    };

    return {
        initialized,
        contextMenu,
        hasMoreThanOneCompany,
        contextMenuProps,
    };
};
