import React from "react";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { IRefreshOrInvitation } from "../../types/models";
import { MicrosoftUpgradeForm } from "../shared/MicrosoftUpgradeSite";
import { CustomDialog, CustomDialogContent } from "../ui/CustomDialog";
import { useMultiFactorAuth } from "./useMultiFactorAuth";

// Dialog version of microsoft upgrade site
const UpgradeDialog = ({
    companyId,
    open,
    onSubmitPassword,
    onSubmitMfaCode,
    isMfaStep,
    onClose,
}: {
    companyId?: string;
    open: boolean;
    onSubmitPassword: (username?: string, password?: string) => void;
    onSubmitMfaCode: (code: string) => void;
    isMfaStep?: boolean;
    onClose: () => void;
}) => {
    return (
        <CustomDialog open={open && !!companyId} onClose={onClose}>
            <CustomDialogContent>
                <MicrosoftUpgradeForm
                    companyId={companyId}
                    text={t("screen.upgradeToMicrosoft.feature.projects.text")}
                    onSubmitPassword={onSubmitPassword}
                    onSubmitMfaCode={onSubmitMfaCode}
                    isMfaStep={isMfaStep}
                />
            </CustomDialogContent>
        </CustomDialog>
    );
};

// Dialog used for users who habe no MS account yet but are invited to projects
export const useMicrosoftUpgradeDialog = () => {
    const [upgradeInvitation, setUpgradeInvitation] = React.useState<IRefreshOrInvitation>();
    const [upgradeDialogOpen, setUpgradeDialogOpen] = React.useState(false);
    const { submitPassword, submitMfaCode, currentAuthStep, mfaResendSuccessDialog } = useMultiFactorAuth(
        () =>
            authStore.upgradeAndLoginWithMicrosoft(
                upgradeInvitation?.companyId ?? "",
                undefined,
                upgradeInvitation?.isInternational,
            ),
        true,
    );

    const component = (
        <>
            <UpgradeDialog
                open={upgradeDialogOpen}
                onSubmitPassword={submitPassword}
                onSubmitMfaCode={submitMfaCode}
                isMfaStep={currentAuthStep === "multiFactorAuth"}
                companyId={upgradeInvitation?.companyId}
                onClose={() => {
                    setUpgradeDialogOpen(false);
                }}
            />
            {mfaResendSuccessDialog.dialog}
        </>
    );

    return {
        openDialog(invitation: IRefreshOrInvitation) {
            setUpgradeInvitation(invitation);
            setUpgradeDialogOpen(true);
        },

        component,
    };
};

export type MicrosoftUpgradeDialog = ReturnType<typeof useMicrosoftUpgradeDialog>;
