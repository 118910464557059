import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { ROWS_PER_PAGE } from "../../../config";
import { t } from "../../../i18n/util";
import { EventsFeedEntry } from "../../../network/APITypes";
import { newsStore } from "../../../stores/NewsStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { useTableStore } from "../../../stores/TableStore";
import { pushRoute, withParams } from "../../app/router/history";
import { CenteredContent } from "../../ui/CenteredContent";
import { MobileContext } from "../../util/MobileContext";
import { CockpitEventCard } from "../CockpitEventCard";
import { CockpitNavBar } from "../CockpitNavBar";
import { CockpitRoutes } from "../router/CockpitRoutes";

const EventsContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ isMobile }: { isMobile: boolean }) => (isMobile ? "1fr" : "1fr 1fr 1fr")};
    grid-gap: ${({ isMobile }: { isMobile: boolean }) => (isMobile ? "24px 0px" : "24px 16px")};
`;

export const CockpitEventsSite = observer(function CockpitEventsSite() {
    const events = newsStore.events;
    useHideSideBar();
    const tableStore = useTableStore("EventsSite", { orderBy: "publishedAt", orderDir: "desc", limit: ROWS_PER_PAGE });
    const isMobile = React.useContext(MobileContext);

    React.useEffect(() => {
        tableStore.totalCount = events.length;
    }, [events, tableStore]);

    const handleClickEvent = (event: EventsFeedEntry) => {
        pushRoute(withParams(CockpitRoutes.EVENTS_LIST_ARTICLE, { eventId: event.id }));
    };

    return (
        <>
            <CockpitNavBar title={t("screen.cockpit.allEvents")} backTarget={CockpitRoutes.ROOT} />
            <CenteredContent>
                <div style={{ padding: isMobile ? "48px 16px" : "48px 24px" }}>
                    <EventsContainer isMobile={isMobile}>
                        {events
                            .slice(ROWS_PER_PAGE * (tableStore.page - 1), ROWS_PER_PAGE * tableStore.page)
                            .map(event => (
                                <CockpitEventCard key={event.id} event={event} onClick={handleClickEvent} />
                            ))}
                    </EventsContainer>
                </div>
                <tableStore.Pagination />
            </CenteredContent>
        </>
    );
});
