import * as React from "react";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { MobileContext } from "../../util/MobileContext";

export const AuthGeneralContainerSite = (props: {
    children: React.ReactNode;
    cancelTarget?: string;
    onCancel?: () => void;
    showNavBar?: boolean;
}) => {
    const isMobile = React.useContext(MobileContext);

    // Hide the burger menu
    useHideSideBar();

    return (
        <>
            {props.showNavBar !== false && (
                <NavBarBack showLogo ignoreSideBar cancelTarget={props.cancelTarget ?? "/"} onCancel={props.onCancel} />
            )}
            <CenteredContent ignoreSideBar>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        flexGrow: 1,
                    }}
                >
                    <div
                        style={{
                            marginTop: isMobile ? 0 : "calc(var(--navBarHeight) * -1)",
                            textAlign: "center",
                            maxWidth: 375,
                            padding: 32,
                            boxSizing: "content-box",
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            </CenteredContent>
        </>
    );
};
