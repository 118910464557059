import * as React from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import styled from "styled-components";
import { ACCEPTED_FILE_FORMATS, MAX_UPLOAD_SIZE } from "../../config";
import { IMessageIDS, t } from "../../i18n/util";
import { getSupportedFormatsString, isIos } from "../../util/helpers";
import { DataUploadImage } from "../util/Images";
import { customColors } from "../util/Theme";
import { FallbackImage } from "./FallbackImage";
import { Progress } from "./Progress";
import { SymmetricBorderBox } from "./SymmetricBorderBox";

const HEIGHT = 147;

const UploadContainer = styled.div<{ highlight: boolean }>`
    background-color: ${props => (props.highlight ? "#F6F8F9" : "white")};
    width: 100%;
    min-height: ${HEIGHT}px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    flex-direction: column;
`;

export const DropZoneSmall = (props: {
    currentUploadFile?: number;
    totalFileCount?: number;
    isUploading: boolean;
    onDrop<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent): void;
    "data-id"?: string;
    fileTypes?: string;
    infoText?: IMessageIDS;
    imageUrl?: string;
    imageStyle?: "cover" | "circle";
    disabled?: boolean;
    fallbackErrorMessage?: string;
    maxUploadSize?: number;
}) => {
    const [highlight, setHighlight] = React.useState(false);

    const acceptedFileTypes = props.fileTypes ?? ACCEPTED_FILE_FORMATS;
    const acceptedFileTypesLabel = getSupportedFormatsString(acceptedFileTypes);

    const uploadingView = (
        <UploadContainer highlight={highlight}>
            <DataUploadImage style={{ display: "flex", maxHeight: 68, justifySelf: "flex-start", marginBottom: 18 }} />
            <div style={{ display: "flex", alignItems: "center", width: "80%" }}>
                <Progress current={props.currentUploadFile ?? 0} total={props.totalFileCount ?? 0} />
            </div>
        </UploadContainer>
    );

    const uploadView = (
        <UploadContainer highlight={highlight} style={{ padding: 16 }}>
            <div
                style={{
                    fontSize: 14,
                    lineHeight: "22px",
                    color: props.disabled ? customColors.greyTextfields : customColors.body1Dark,
                }}
            >
                {t(props.infoText ?? "dropzone.info.text")}
                <br />
                <div
                    style={{
                        color: props.disabled ? customColors.greyTextfields : customColors.primaryColor,
                        fontWeight: "bold",
                        display: "inline",
                    }}
                >
                    {t("dropzone.button.label.search")}
                </div>
            </div>
            <p className="caption" style={{ color: customColors.greyTextfields, paddingTop: 8 }}>
                {acceptedFileTypesLabel}
            </p>
        </UploadContainer>
    );

    const defaultView = props.isUploading ? uploadingView : uploadView;

    const imageStyle: React.CSSProperties = {
        height: HEIGHT,
        objectPosition: "center",
        objectFit: "cover",
    };

    const imageViewCover = props.imageUrl && (
        <UploadContainer highlight={highlight}>
            <FallbackImage
                src={props.imageUrl}
                style={{ width: "100%", ...imageStyle }}
                fallbackComponent={defaultView}
                errorMessage={props.fallbackErrorMessage}
            />
        </UploadContainer>
    );

    const imageViewCircle = props.imageUrl && (
        <UploadContainer style={{ backgroundColor: "#bbb" }} highlight={highlight}>
            <FallbackImage
                src={props.imageUrl}
                style={{
                    ...imageStyle,
                    width: HEIGHT * 0.9,
                    height: HEIGHT * 0.9,
                    backgroundColor: "#fff",
                    borderRadius: "100%",
                }}
                fallbackComponent={defaultView}
                errorMessage={props.fallbackErrorMessage}
            />
        </UploadContainer>
    );

    const onDragOver = () => {
        setHighlight(true);
    };

    const onDragLeave = () => {
        setHighlight(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: isIos() ? undefined : acceptedFileTypes, // ios blocks msg, eml, fib
        noKeyboard: true,
        maxSize: props.maxUploadSize ?? MAX_UPLOAD_SIZE,
        disabled: !!props.disabled || props.isUploading,
        onDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
            setHighlight(false);
            props.onDrop(acceptedFiles, fileRejections, event);
        },
        onDragOver: onDragOver,
        onDragLeave: onDragLeave,
    });

    let borderColor = props.isUploading ? customColors.secondaryColor : customColors.primaryColor;
    if (props.disabled) {
        borderColor = customColors.greyTextfields;
    }

    return (
        <SymmetricBorderBox color={borderColor} style={{ borderRadius: 4 }}>
            <div
                style={{
                    boxSizing: "border-box",
                    cursor: props.disabled ? "auto" : "pointer",
                }}
                {...getRootProps()}
                role="button"
                data-id={(props["data-id"] ?? "") + "_dropzone"}
            >
                {props.imageUrl && (props.imageStyle === "circle" ? imageViewCircle : imageViewCover)}
                {!props.imageUrl && defaultView}
                <input {...getInputProps()} />
            </div>
        </SymmetricBorderBox>
    );
};
