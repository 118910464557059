import * as React from "react";
import { Redirect, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { pushRoute, withParamsAndQuery } from "../../app/router/history";
import { useEmployee } from "../../hooks/useEmployee";
import { useEmployeeDocuments } from "../../hooks/useEmployeeDocuments";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useRerenderKey } from "../../hooks/useRerenderKey";
import { StaffRoutes } from "../../staff/router/StaffRoutes";
import { DocumentsSummary } from "../../ui/DocumentsSummary";
import { HrDocumentsForm } from "../HrDocumentsForm";
import { HrRegistrationSite } from "../HrRegistrationSite";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

interface QueryParams {
    isEdit?: boolean;
    isPreRegistration?: boolean;
    isReRegistration?: boolean;
    isDocumentUpload?: boolean;
    subsidiaryId?: string;
}

export const HrEmployeesRegistrationDocumentsSite = ({ isStaffUpload }: { isStaffUpload?: boolean }) => {
    useHideSideBar();

    const query: QueryParams = useQueryParams();
    const { isEdit, isPreRegistration = false, isReRegistration, isDocumentUpload } = query;
    const subsidiaryId = query.subsidiaryId ?? hrStore.selectedSubsidiaryId;
    const { employeeId } = useParams<{ employeeId: string }>();
    const { employee } = useEmployee(employeeId, isPreRegistration, subsidiaryId);
    const documents = useEmployeeDocuments({}, isPreRegistration, employee ? employee.id : "", undefined, subsidiaryId);

    const companyId = companiesStore.selectedCompanyId;

    const { rerender, rerenderKey } = useRerenderKey();

    const uploadedDocumentsCountRef = React.useRef(0);

    const transferDocuments = React.useCallback(async () => {
        if (isDocumentUpload && companyId && subsidiaryId && employeeId) {
            try {
                await API.putEmployeeDocuments(companyId, "hr", subsidiaryId, employeeId);
            } catch (error) {
                generalStore.setError(t("error.transfer"), error);
            }
        }
    }, [companyId, employeeId, isDocumentUpload, subsidiaryId]);

    // When user leaves site -> transfer documents
    React.useEffect(() => {
        return () => {
            if (uploadedDocumentsCountRef.current === 0) {
                return;
            }
            transferDocuments();
        };
    }, [transferDocuments]);

    const onSubmit = () => {
        if (employeeId) {
            // clear the uploaded count to avoid duplicate transfers (see useEffect above)
            uploadedDocumentsCountRef.current = 0;
            transferDocuments();

            if (isStaffUpload) {
                pushRoute(StaffRoutes.ROOT);
                return;
            }
            if (isDocumentUpload) {
                // Go back to details
                pushRoute(HrRoutes.EMPLOYEES.DETAILS, { params: { employeeId }, query: { isPreRegistration } });
            } else {
                // Continue to summary
                pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_SUMMARY, {
                    params: { employeeId },
                    query: { isEdit, isPreRegistration, isReRegistration },
                });
            }
        }
    };

    if (!employeeId) {
        return <Redirect to={HrRoutes.EMPLOYEES.ROOT} />;
    }

    if (!employee) {
        return null;
    }

    let backTarget = withParamsAndQuery(HrRoutes.EMPLOYEES.REGISTRATION_ADDITIONAL_DATA, { employeeId }, query);
    if (isStaffUpload) {
        backTarget = StaffRoutes.ROOT;
    }
    if (isDocumentUpload && !isStaffUpload) {
        backTarget = withParamsAndQuery(HrRoutes.EMPLOYEES.DETAILS, { employeeId }, { isPreRegistration });
    }

    const documentsSummary =
        !documents?.documents || documents.documents.length === 0 ? null : (
            <DocumentsSummary
                employeeId={employeeId}
                isPreRegistration={isPreRegistration}
                key={rerenderKey}
                documents={documents.documents}
            />
        );

    return (
        <>
            <HrRegistrationSite
                employee={employee}
                cancelDialog={!isDocumentUpload}
                showCancel={!isDocumentUpload}
                backTarget={backTarget}
                rightBarContent={documentsSummary}
                query={query}
            >
                <h1 style={{ marginBottom: 16 }}>{t("screen.hr.documents.uploadDocuments")}</h1>
                <p>{t("screen.hr.documents.description")}</p>
                <HrDocumentsForm
                    onSubmit={onSubmit}
                    buttonText={t(
                        isDocumentUpload
                            ? "screen.accounting.records.transfer.button.text"
                            : "screen.hr.documents.nextButton",
                    )}
                    employeeId={employee.id}
                    onUploaded={count => {
                        uploadedDocumentsCountRef.current += count;
                        rerender();
                    }}
                    onDeleted={() => {
                        uploadedDocumentsCountRef.current -= 1;
                        rerender();
                    }}
                    subsidiaryId={subsidiaryId}
                    isPreRegistration={isPreRegistration}
                    isStaffUpload={isStaffUpload}
                />
            </HrRegistrationSite>
        </>
    );
};
