import { CircularProgress, Link, Tooltip } from "@material-ui/core";
import drop from "lodash/drop";
import last from "lodash/last";
import take from "lodash/take";
import { t } from "../../i18n/util";
import { ProjectItemPermission } from "../../network/APITypes";
import { Avatar, User } from "../ui/User";
import { customColors } from "../util/Theme";

interface IProps {
    permissionUsers?: ProjectItemPermission[];
    variant?: "async" | "sync";
    align?: "left" | "right";
    showTooltip?: boolean;
    onClickShowAll?: () => void;
}

const alignToFlex = {
    left: "flex-start",
    right: "flex-end",
};

export const ProjectPermissionAvatars = ({
    permissionUsers,
    variant = "sync",
    align = "left",
    showTooltip,
    onClickShowAll,
}: IProps) => {
    const MAX_AVATAR_COUNT = 6;
    const AVATAR_SPACING = 4;
    const AVATAR_SIZE = 28;
    const displayCount = (permissionUsers?.length ?? 0) <= MAX_AVATAR_COUNT ? MAX_AVATAR_COUNT : MAX_AVATAR_COUNT - 1;
    const hiddenPermissionUsers = drop(permissionUsers, displayCount);

    const disableTooltip = !showTooltip || !permissionUsers;

    if (!permissionUsers && variant === "sync") {
        return null;
    }

    return (
        <Tooltip
            interactive
            style={{
                padding: 16,
                backgroundColor: customColors.white,
            }}
            disableFocusListener={disableTooltip}
            disableHoverListener={disableTooltip}
            disableTouchListener={disableTooltip}
            title={
                <div
                    style={{ width: 230, textAlign: "center" }}
                    onClick={event => {
                        // needed, otherwise clicking anywhere on the tooltip enters the folder
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    <p>
                        {permissionUsers?.length} {t("common.members")}
                    </p>
                    <p className="caption">
                        {hiddenPermissionUsers.length === 1 &&
                            t("projects.membersIncluded.single", {
                                name: hiddenPermissionUsers[0].name,
                            })}
                        {hiddenPermissionUsers.length > 1 &&
                            t("projects.membersIncluded.multi", {
                                names: take(hiddenPermissionUsers, hiddenPermissionUsers.length - 1)
                                    .map(({ name }) => name)
                                    .join(", "),
                                name: last(hiddenPermissionUsers)?.name,
                            })}
                    </p>
                    <Link
                        className="body1"
                        component="button"
                        underline="always"
                        color="inherit"
                        onClick={onClickShowAll}
                        style={{
                            fontSize: 10,
                            fontWeight: 500,
                            color: customColors.body1Dark,
                            marginTop: 8,
                        }}
                    >
                        {t("common.showAll")}
                    </Link>
                </div>
            }
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: alignToFlex[align],
                    width: AVATAR_SIZE * MAX_AVATAR_COUNT + (MAX_AVATAR_COUNT - 1) * AVATAR_SPACING, // reserve space to avoid jumping components
                }}
            >
                {!permissionUsers && variant === "async" && <CircularProgress size={28} color="primary" />}
                {permissionUsers && (
                    <>
                        {take(permissionUsers, displayCount).map((user, index) => {
                            const nameParts = user.name.split(" ");

                            const isWaitingForRelease = user.needsRelease;

                            return (
                                <User
                                    key={user.id ?? user.userId}
                                    showAvatarOnly
                                    isTpaStaff={user.isTpaEmployee}
                                    size={AVATAR_SIZE}
                                    firstName={nameParts[0] ?? ""}
                                    lastName={nameParts[1] ?? ""}
                                    imageUrl={user.profile_picture_url}
                                    style={{
                                        marginLeft: index > 0 ? AVATAR_SPACING : undefined,
                                        opacity: isWaitingForRelease ? 0.2 : 1,
                                    }}
                                />
                            );
                        })}
                        {permissionUsers.length > MAX_AVATAR_COUNT && (
                            <Avatar
                                alt={`+${permissionUsers.length - displayCount}`}
                                size={AVATAR_SIZE}
                                style={{
                                    marginLeft: AVATAR_SPACING,
                                    backgroundColor: customColors.greyLight,
                                    color: customColors.body2Dark,
                                }}
                            >{`+${permissionUsers.length - displayCount}`}</Avatar>
                        )}
                    </>
                )}
            </div>
        </Tooltip>
    );
};
