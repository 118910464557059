import { observer } from "mobx-react";
import { useEffect } from "react";
import { GLOBAL_FEATURES } from "../../../features";
import { companiesStore } from "../../../stores/CompaniesStore";
import { Routes } from "../../app/router/Routes";
import { useOptionalSideBarMenuItems } from "../../hooks/useOptionalSideMenuItems";
import { SideBarItems } from "./SideBarItems";

export const OptionalSideBarItems = observer(function OptionalSideBarItems(props: { onClickItem?: () => void }) {
    const { optionalSideBarMenuItems, reload } = useOptionalSideBarMenuItems(companiesStore.selectedCompanyId);

    const { forceReloadOptionalSideBarItems } = companiesStore;
    useEffect(() => {
        const reloadAfterChange = async () => {
            if (forceReloadOptionalSideBarItems) {
                await reload();
                companiesStore.forceReloadOptionalSideBarItems = false;
            }
        };
        reloadAfterChange();
    }, [forceReloadOptionalSideBarItems, reload]);

    const items = optionalSideBarMenuItems?.map(item => {
        return {
            iconName: "globe" as const,
            title: item.name,
            target: Routes.EXTERNAL,
            enabled: GLOBAL_FEATURES.optionalSidebarItems,
            isOptionalMenuItem: true,
        };
    });

    if (!items) {
        return null;
    }

    return <SideBarItems items={items} onClickItem={props.onClickItem} />;
});
