import { Button, Checkbox, DialogActions, FormControlLabel, TextField } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Message } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { AutocompleteCompanies, ICompanyListItem } from "../shared/AutocompleteCompanies";
import { CustomDialog } from "../ui/CustomDialog";
import { FieldError } from "../ui/CustomInputField";
import { TpaWhiteButton } from "../ui/Primitives";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";

const MessageSelectionBar = styled.div`
    padding: 8px 0 8px 40px;
    margin-left: -32px;
    background-color: ${customColors.white};
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid ${customColors.greyLight};
`;

const DialogContainer = styled.div`
    padding: 42px;
    background-color: ${customColors.greyBackground};
`;

export const useArchiveMessages = (
    onArchiveSelection?: (
        selectedMessageIds: string[],
        companyId: string,
        subject: string,
        downloadPDF: boolean,
    ) => void | Promise<void>,
    initialCompanyId?: string,
) => {
    const [selectedMessageIds, setSelectedMessageIds] = React.useState<string[]>([]);
    const [selectionMode, setSelectionMode] = React.useState(false);
    const [companyId, setCompanyId] = React.useState(initialCompanyId);
    const [subject, setSubject] = React.useState("");
    const [downloadPdf, setDownloadPdf] = React.useState(false);
    const [isArchiveDialogOpen, setIsArchiveDialogOpen] = React.useState(false);
    const isMobile = React.useContext(MobileContext);
    const [archiveDisabled, setArchiveDisabled] = React.useState(false);

    React.useEffect(() => {
        const loadCompany = async () => {
            if (!companyId) {
                return;
            }

            try {
                const companyDetails = await API.getCompany(companyId);
                if (!companyDetails.archiveEnabled) {
                    setArchiveDisabled(true);
                } else {
                    setArchiveDisabled(false);
                }
            } catch (err) {
                generalStore.setError(t("error.loadCompany"), err);
            }
        };

        loadCompany();
    }, [companyId]);

    const onArchive = () => {
        setSelectionMode(true);
        setSelectedMessageIds([]);
    };

    const onCancelArchive = () => {
        setSelectionMode(false);
        setSelectedMessageIds([]);
    };

    const toggleDownloadPdf = () => {
        setDownloadPdf(!downloadPdf);
    };

    const onChangeCompany = (company: ICompanyListItem | null) => {
        setCompanyId(company?.id ?? "");
    };

    const handleOpenArchiveDialog = () => {
        setIsArchiveDialogOpen(true);
    };

    const handleCloseArchiveDialog = () => {
        setIsArchiveDialogOpen(false);
    };

    const handleConfirmArchiveDialog = async () => {
        if (!companyId || !onArchiveSelection || archiveDisabled) {
            return;
        }

        setIsArchiveDialogOpen(false);
        await onArchiveSelection(selectedMessageIds, companyId, subject, downloadPdf);
        setCompanyId(initialCompanyId);
        setSubject("");
        setDownloadPdf(false);
        setSelectionMode(false);
        setSelectedMessageIds([]);
    };

    const handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSubject(event.target.value);
    };

    const toggleMessageSelection = (message: Message) => {
        if (selectedMessageIds.filter(messageId => messageId === message.id).length === 0) {
            setSelectedMessageIds([...selectedMessageIds, message.id]);
        } else {
            setSelectedMessageIds(selectedMessageIds.filter(messageId => messageId !== message.id));
        }
    };

    const dialog = (
        <CustomDialog open={isArchiveDialogOpen}>
            <DialogContainer>
                <h1 style={{ marginBottom: 24 }}>
                    {selectedMessageIds.length === 1
                        ? t("dialog.archive.title_single")
                        : t("dialog.archive.title_multiple", { count: selectedMessageIds.length })}
                </h1>
                <p style={{ marginBottom: 32 }}>{t("dialog.archive.text")}</p>
                <AutocompleteCompanies
                    onChangeCompany={onChangeCompany}
                    style={{ marginBottom: 12 }}
                    companyId={initialCompanyId}
                />
                {archiveDisabled && (
                    <div style={{ marginBottom: 16 }}>
                        <FieldError>{t("error.archiveDisabledForCompany")}</FieldError>
                    </div>
                )}
                <TextField
                    style={{ width: "100%", marginBottom: 24 }}
                    label={t("dialog.archive.subject")}
                    variant="outlined"
                    value={subject}
                    onChange={handleChangeSubject}
                />
                <FormControlLabel
                    control={<Checkbox checked={downloadPdf} onClick={toggleDownloadPdf} color="primary" />}
                    label={t("dialog.archive.checkbox_pdf")}
                />
                <DialogActions style={{ padding: "48px 0 0 0" }}>
                    <TpaWhiteButton style={{ marginRight: 16 }} onClick={handleCloseArchiveDialog}>
                        {t("common.cancel")}
                    </TpaWhiteButton>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!companyId || !subject || archiveDisabled}
                        onClick={handleConfirmArchiveDialog}
                    >
                        {t("button.archive")}
                    </Button>
                </DialogActions>
            </DialogContainer>
        </CustomDialog>
    );

    const selectionBar = (
        <MessageSelectionBar style={isMobile ? { position: "fixed", bottom: 0, zIndex: 100 } : undefined}>
            <TpaWhiteButton style={{ margin: 8 }} onClick={onCancelArchive}>
                {t("common.cancel")}
            </TpaWhiteButton>
            <Button
                onClick={handleOpenArchiveDialog}
                disabled={selectedMessageIds.length === 0}
                color="primary"
                variant="contained"
                style={{ margin: 8 }}
            >
                {selectedMessageIds.length === 1
                    ? t("button.submitArchiveSingle", {
                          count: selectedMessageIds.length,
                      })
                    : t("button.submitArchiveMultiple", {
                          count: selectedMessageIds.length > 0 ? selectedMessageIds.length : undefined,
                      })}
            </Button>
        </MessageSelectionBar>
    );

    return {
        dialog,
        selectionBar,
        selectedMessageIds,
        selectionMode,
        onArchive,
        toggleMessageSelection,
    };
};
