import { Button, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React from "react";
import { FileRejection } from "react-dropzone";
import { MAX_UPLOAD_SIZE_MB, MB_SIZE } from "../../config";
import { t } from "../../i18n/util";
import { fileRejectionToErrorMessage, getSupportedFileFormatsString } from "../../util/helpers";
import { ic_document_upload_error } from "../util/Images";
import { customColors } from "../util/Theme";
import { CustomDialog, CustomDialogContent } from "./CustomDialog";
import { InfoButton, TpaWhiteButton } from "./Primitives";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";

const Transition = React.forwardRef(function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogErrorLine = (props: {
    errorMessage?: string;
    fileName: string;
    infoTitle?: string;
    infoBody?: string;
}) => (
    <div
        style={{
            backgroundColor: customColors.errorBackground,
            borderRadius: 4,
            height: 44,
            marginTop: 4,
            marginBottom: 4,
            display: "flex",
            alignItems: "center",
            padding: "12px 8px 12px 16px",
            justifyContent: "space-between",
            flexGrow: 1,
        }}
    >
        <div style={{ display: "flex", maxWidth: "50%" }}>
            <img src={ic_document_upload_error} alt="" />
            <p
                className="body2"
                style={{
                    color: customColors.error,
                    marginLeft: 16,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
            >
                {props.fileName}
            </p>
        </div>
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
            }}
        >
            <p className="caption" style={{ color: customColors.error }}>
                {props.errorMessage}
            </p>
            {props.infoTitle && (
                <InfoButton title={props.infoTitle} body={props.infoBody} style={{ color: customColors.error }} />
            )}
        </div>
    </div>
);

export const FileRejections = ({
    fileRejections,
    rejectionInfoText,
    maxFileSizeBytes,
}: {
    fileRejections?: FileRejection[];
    rejectionInfoText?: string;
    maxFileSizeBytes?: number;
}) => {
    if (!fileRejections) {
        return null;
    }
    return (
        <>
            {fileRejections.map((failedFile, index) => (
                <DialogErrorLine
                    errorMessage={fileRejectionToErrorMessage(failedFile)}
                    fileName={failedFile.file.name}
                    infoTitle={rejectionInfoText ?? getSupportedFileFormatsString()}
                    infoBody={t("documentUpload.max.size", {
                        maxUploadSizeMb: maxFileSizeBytes ? maxFileSizeBytes / MB_SIZE : MAX_UPLOAD_SIZE_MB,
                    })}
                    key={index}
                />
            ))}
        </>
    );
};

// List all failed uploads
export const FailedDialog = (props: {
    open: boolean;
    title?: React.ReactNode;
    message: React.ReactNode;
    submitText?: React.ReactNode;
    onClose?: () => void;
    onSubmit?: () => void;
    totalFiles: number;
    onRetry?: () => void;
    children: React.ReactNode;
}) => {
    return (
        <CustomDialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.onClose}>
            <CustomDialogContent style={{ paddingBottom: 16 }}>
                <h1 style={{ marginBottom: 16 }}>{props.title ?? t("documentUpload.error.message")}</h1>
                {props.message}
            </CustomDialogContent>
            <CustomDialogContent
                style={{
                    borderTop: `1px solid ${customColors.greyLight}`,
                    borderBottom: `1px solid ${customColors.greyLight}`,
                    paddingTop: 16,
                    paddingBottom: 16,
                    flex: 1,
                    overflow: "auto",
                }}
            >
                {props.children}
            </CustomDialogContent>
            <CustomDialogContent style={{ paddingTop: 16 }}>
                <ResponsiveButtonContainer>
                    {props.onSubmit && (
                        <TpaWhiteButton onClick={props.onSubmit} data-id="continue_without">
                            {props.submitText ?? t("documentUpload.error.button.text.continue")}
                        </TpaWhiteButton>
                    )}
                    {props.onRetry && (
                        <Button onClick={props.onRetry} variant="contained" color="primary" data-id="retry_upload">
                            {t("documentUpload.error.button.text.retry")}
                        </Button>
                    )}
                    {props.onClose && (
                        <Button onClick={props.onClose} variant="contained" color="primary" data-id="cancel_upload">
                            {t("common.cancel")}
                        </Button>
                    )}
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
};
