import { action, observable } from "mobx";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { FinancialAccountancy } from "../network/APITypes";
import { ICompany } from "../types/models";
import { generalStore } from "./GeneralStore";

export class ResultsStore {
    readonly company: ICompany;

    @observable.ref financialAccountancies: FinancialAccountancy[] | undefined = undefined;

    constructor(company: ICompany) {
        this.company = company;
    }

    get companyId() {
        return this.company.id;
    }

    // actions

    @action async loadFinancialAccountancies() {
        if (generalStore.findLoading("ResultsStore.loadFinancialAccountancies") || this.financialAccountancies) {
            return; // already loaded or loading
        }

        try {
            generalStore.beginLoading("ResultsStore.loadFinancialAccountancies");

            const result = await API.getFinancialAccountancies({ companyId: this.companyId });
            this.financialAccountancies = result.financialAccountancies.filter(
                fa => !!fa.profitAssessmentType && new Date(fa.startsAt) < new Date(fa.resultsAvailableUntil),
            );
        } catch (error) {
            generalStore.setError(t("error.loadFinancialAccountancies"), error);
        } finally {
            generalStore.endLoading("ResultsStore.loadFinancialAccountancies");
        }
    }
}
