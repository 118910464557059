export interface DateRange {
    from: Date | null;
    to: Date | null;
}

export interface NumberRange {
    from: number | null;
    to: number | null;
}

export type TableFilterValue = boolean | Date | DateRange | NumberRange | string | null;

export type ITableFilters = Record<string, { value: TableFilterValue }>;

export interface ITableFilterOption {
    value: string;
    label: React.ReactNode;
}

export const BOOLEAN_FILTER_TYPE = "boolean";
export const DATE_FILTER_TYPE = "date";
export const DATE_RANGE_FILTER_TYPE = "date-range";
export const DROPDOWN_FILTER_TYPE = "dropdown";
export const NUMBER_RANGE_FILTER_TYPE = "number-range";

export interface IBooleanFilterItem {
    type?: typeof BOOLEAN_FILTER_TYPE;
    name: string;
    label: string;
    disabled?: boolean | ((selected: ITableFilters) => boolean);
    /** If a function is used, return `true` if the value is not complete, hence is still required */
    required?: boolean | ((value: boolean | undefined) => boolean);
    requiredText?: (value: boolean | undefined) => React.ReactNode | undefined;
}

export interface IDateFilterItem {
    type: typeof DATE_FILTER_TYPE;
    name: string;
    label: string;
    disabled?: boolean | ((selected: ITableFilters) => boolean);
    /** If a function is used, return `true` if the value is not complete, hence is still required */
    required?: boolean | ((value: Date | undefined) => boolean);
    requiredText?: (value: Date | undefined) => React.ReactNode | undefined;
    onChange?: (date: Date) => void;
}

export interface IDateRangeFilterItem {
    type: typeof DATE_RANGE_FILTER_TYPE;
    name: string;
    label: string;
    disabled?: boolean | ((selected: ITableFilters) => boolean);
    /** If a function is used, return `true` if the value is not complete, hence is still required */
    required?: boolean | ((value: DateRange | undefined) => boolean);
    requiredText?: (value: DateRange | undefined) => React.ReactNode | undefined;
    onChange?: (dateRange: DateRange) => void;
    minDate?: Date;
    maxDate?: Date;
}

export interface IDropdownFilterItem {
    type: typeof DROPDOWN_FILTER_TYPE;
    name: string;
    label: string;
    disabled?: boolean | ((selected: ITableFilters) => boolean);
    /** If a function is used, return `true` if the value is not complete, hence is still required */
    required?: boolean | ((value: string | undefined) => boolean);
    requiredText?: (value: string | undefined) => React.ReactNode | undefined;
    toggle?: boolean; // If false -> this is not a toggle button. Default = true
    options: ITableFilterOption[];
    listSettings?: React.ReactNode[];
    onChange?: (value: string) => void;
}

export interface INumberRangeFilterItem {
    type: typeof NUMBER_RANGE_FILTER_TYPE;
    name: string;
    label: string;
    disabled?: boolean | ((selected: ITableFilters) => boolean);
    /** If a function is used, return `true` if the value is not complete, hence is still required */
    required?: boolean | ((value: NumberRange | undefined) => boolean);
    requiredText?: (value: NumberRange | undefined) => React.ReactNode | undefined;
    onChange?: (numberRange: NumberRange) => void;
}

export type IFilterItem =
    | IBooleanFilterItem
    | IDateFilterItem
    | IDateRangeFilterItem
    | IDropdownFilterItem
    | INumberRangeFilterItem;

export interface ITableFilterCategory {
    category: string;
    label?: string;
    entries: IFilterItem[];
}
