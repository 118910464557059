import { Button, Slider } from "@material-ui/core";
import * as React from "react";
import AvatarEditor from "react-avatar-editor";
import { MB_SIZE } from "../../config";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { canvasToBlob, sleep } from "../../util/helpers";
import { customColors } from "../util/Theme";
import { CustomDialog } from "./CustomDialog";
import { TpaWhiteButton } from "./Primitives";
import { BORDER_WIDTH, SymmetricBorderBox } from "./SymmetricBorderBox";

export const ImageCropDialog = (props: {
    image: File | null;
    maxSizeBytes?: number;
    onSave: (blob: Blob) => void;
    onCancel: () => void;
    disableSave?: boolean;
    cropCircle?: boolean;
}) => {
    const [scale, setScale] = React.useState(1);
    const [editor, setEditor] = React.useState<AvatarEditor>();
    const [isConverting, setIsConverting] = React.useState(false);

    const aspect = props.cropCircle ? 1 : 1000 / 250;
    const width = props.cropCircle ? 240 : 480;
    const height = width / aspect;

    const image = props.image;
    React.useEffect(() => {
        setScale(1);
    }, [image]);

    const MIN_SCALE = 0.5;
    const MAX_SCALE = 3;

    // Value is
    const valueToScale = (value: number) => ((MAX_SCALE - MIN_SCALE) * value) / 100 + MIN_SCALE;
    const scaleToValue = (scale: number) => ((scale - MIN_SCALE) * 100) / (MAX_SCALE - MIN_SCALE);

    return (
        <CustomDialog open={!!props.image}>
            <div style={{ padding: 40, textAlign: "center" }}>
                <h1>{t("imageCrop.title")}</h1>
                <p style={{ marginTop: 24 }}>{t("imageCrop.description")}</p>
                {props.image && (
                    <>
                        {props.cropCircle && (
                            <div style={{ textAlign: "center", marginTop: 16 }}>
                                <AvatarEditor
                                    ref={editor => {
                                        setEditor(editor ?? undefined);
                                    }}
                                    width={width - 2 * BORDER_WIDTH}
                                    height={height - 2 * BORDER_WIDTH}
                                    image={props.image}
                                    scale={scale}
                                    border={0}
                                    style={
                                        props.cropCircle
                                            ? {
                                                  borderRadius: "100%",
                                                  border: `2px dashed ${customColors.primaryColor}`,
                                              }
                                            : undefined
                                    }
                                />
                            </div>
                        )}
                        {!props.cropCircle && (
                            <SymmetricBorderBox
                                color={customColors.primaryColor}
                                style={{ borderRadius: 4, marginTop: 16, width, height }}
                            >
                                <AvatarEditor
                                    ref={editor => {
                                        setEditor(editor ?? undefined);
                                    }}
                                    width={width - 2 * BORDER_WIDTH}
                                    height={height - 2 * BORDER_WIDTH}
                                    image={props.image}
                                    scale={scale}
                                    border={0}
                                />
                            </SymmetricBorderBox>
                        )}
                        <div style={{ display: "flex", alignItems: "center", marginTop: 24 }}>
                            <div>{t("imageCrop.zoom")}</div>
                            <Slider
                                style={{ margin: "0 32px" }}
                                value={Math.round(scaleToValue(scale))}
                                onChange={(event, value) => {
                                    setScale(valueToScale(value as number));
                                }}
                            />
                            <div>{Math.round(scale * 100)}%</div>
                        </div>
                    </>
                )}
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 56 }}>
                    <TpaWhiteButton onClick={props.onCancel}>{t("common.cancel")}</TpaWhiteButton>
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 16 }}
                        disabled={!!props.disableSave || isConverting}
                        onClick={async () => {
                            if (editor) {
                                generalStore.isLoading = true;
                                try {
                                    setIsConverting(true);

                                    // JPEG conversion is blocking -> sleep for 800 ms to rerender disabled button and loading indicator
                                    if (props.image?.type === "image/jpeg" && (props.image.size ?? 0) > 3 * MB_SIZE) {
                                        await sleep(800);
                                    }

                                    const canvas = editor.getImage();
                                    if (canvas) {
                                        const blob = canvasToBlob(canvas, props.image?.type, props.maxSizeBytes);

                                        if (blob) {
                                            props.onSave(blob);
                                            return;
                                        } else {
                                            generalStore.setError(t("error.imageCrop"));
                                        }
                                    }
                                } finally {
                                    generalStore.isLoading = false;
                                    setIsConverting(false);
                                }
                            }
                        }}
                    >
                        {t("common.save")}
                    </Button>
                </div>
            </div>
        </CustomDialog>
    );
};
