import { IMessageIDS, t } from "../../i18n/util";
import { formatDate } from "../../util/date";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

export interface IDueDate {
    date: Date;
    overdue?: boolean;
    labelId: IMessageIDS;
}

export const Deadline = (props: IDueDate) => {
    const color = props.overdue ? customColors.redUrgent : "black";
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div>
                <Icon name="time" style={{ color: color, display: "block" }} />
            </div>
            <span
                style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: color,
                    margin: "0px 12px",
                }}
            >
                {t(props.labelId, { date: formatDate(props.date) })}
            </span>
        </div>
    );
};
