import * as queryString from "query-string";
import { PublicHttpError, PublicHttpValidationError } from "./APITypes";
import { HttpStatusCode } from "./httpStatusCode";

// Join two urls but do NOT normalize url afterwards to keep any https:// double slashes afterwards
// for our CORS proxy (url-join for sid polling chokes on this)
export function urljoin(base?: string, url?: string) {
    // trim trailing /
    let left = base ?? "";
    if (left.length > 0 && left.endsWith("/")) {
        left = left.slice(0, -1);
    }

    // trim leading /
    let right = url ?? "";
    if (right.length > 0 && right.startsWith("/")) {
        right = right.slice(1);
    }

    const separator = right.length > 0 && right.startsWith("?") ? "" : "/";
    return left + separator + right;
}

/**
 * defines a request target
 */
export interface IAPITarget {
    /**
     * relative url to resource (without base url)
     */
    url: string;
    /**
     * http method
     */
    method?: "GET" | "POST" | "DELETE" | "PUT" | "PATCH";
    /**
     * body of request
     */
    body?: object | FormData | string;
    /**
     * query parameters
     */
    queryParameters?: object;
    /**
     * header of request
     */
    headers?: Record<string, string>;
}

export interface IDownloadAPITarget extends IAPITarget {
    fileName: string;
}

/**
 * defines a request target with a typed result
 */
export interface ITypedAPITarget<T> extends IAPITarget {
    /**
     * validate / transform response json data to typed data
     */
    parse?: (data: unknown) => T;
}
export interface IAPIClientOptions {
    /**
     * base url of remote service
     */
    baseUrl: string;
    /**
     * used to modify or add header before a request is executed (like add authorization headers)
     */
    injectHeaders?: (target: IAPITarget) => Record<string, string>;
    /**
     * throws status codes < 200 || > 399 as APIClientError
     */
    throwOnErrorStatusCodes?: boolean;
}

/**
 * executes a requests, defined by APITarget
 */
export class APIClient {
    options: IAPIClientOptions;

    constructor(options: IAPIClientOptions) {
        this.options = options;
        this.options.throwOnErrorStatusCodes = options.throwOnErrorStatusCodes ?? true;
    }

    async performFetch(requestUrl: string, options: RequestInit): Promise<Response> {
        const response = await fetch(requestUrl, options);

        const statusOk = response.status >= HttpStatusCode.Ok_200 && response.status < HttpStatusCode.BadRequest_400;
        if (!this.options.throwOnErrorStatusCodes || statusOk) {
            // Request was ok
            return response;
        } else {
            // Request not ok -> extract json error and throw
            let json;
            try {
                json = (await response.json()) as Promise<PublicHttpError | PublicHttpValidationError>;
            } catch (error) {
                // JSON parsing failed -> throw with response
                throw new APIClientStatusCodeError(response, response.status);
            }

            // Throw json error
            throw new APIClientStatusCodeError(json, response.status);
        }
    }

    /**
     * executes a request for the given target,
     * transforms target definition to fetch parameters
     * throws `APIClientError` if `throwOnErrorStatusCodes` is true and status code of response is < 200 || > 399
     * @param target request target
     */
    async request(target: IAPITarget): Promise<Response> {
        const headers = this.options.injectHeaders?.(target) ?? target.headers;

        let requestUrl = urljoin(this.options.baseUrl, target.url);

        if (target.queryParameters) {
            const query = queryString.stringify(target.queryParameters);
            if (query) {
                requestUrl = urljoin(requestUrl, `?${query}`);
            }
        }

        let body;
        if (target.body instanceof FormData) {
            body = target.body;
        } else if (typeof target.body === "string") {
            body = target.body;
        } else {
            body = JSON.stringify(target.body);
        }

        const options: RequestInit = {
            method: target.method ?? "GET",
            body,
            headers,
        };

        return this.performFetch(requestUrl, options);
    }

    /**
     * executes a request for the given target and transforms the result to JSON
     * throws `APIClientError` if `throwOnErrorStatusCodes` is true and status code of response is < 200 || > 399
     * @param target
     */
    async requestJSON<T>(target: IAPITarget): Promise<T> {
        const response = await this.request(target);
        return response.json() as Promise<T>;
    }

    /**
     * executes a request for the given target and asks target to transform JSON result into type
     * throws `APIClientError` if `throwOnErrorStatusCodes` is true and status code of response is < 200 || > 399
     * @param target
     */
    async requestType<T>(target: ITypedAPITarget<T>): Promise<T> {
        const json = await this.requestJSON<T>(target);
        return target.parse ? target.parse(json) : json;
    }
}

type AnythingWithOptionalType = Record<string, unknown> & { type?: string };

/**
 * represents a error caused by invalid statuscode
 */
export class APIClientStatusCodeError<R = AnythingWithOptionalType> extends Error {
    response: PublicHttpError | PublicHttpValidationError | R;
    statusCode: number;

    constructor(response: unknown, statusCode: number) {
        super(`Request failed with status code ${statusCode}`);

        this.statusCode = statusCode;
        this.response = response as never;
    }
}

export const isApiError = <R = AnythingWithOptionalType>(error: unknown): error is APIClientStatusCodeError<R> => {
    return error instanceof APIClientStatusCodeError;
};

export const getApiError = <R = AnythingWithOptionalType>(error: unknown) => {
    return isApiError<R>(error) ? error : null;
};

export const isValidationError = (res: APIClientStatusCodeError["response"]): res is PublicHttpValidationError => {
    return typeof res === "object" && "validationErrors" in res;
};

export const getValidationError = (error: unknown): PublicHttpValidationError | null => {
    if (!isApiError(error)) {
        return null;
    }
    if (!isValidationError(error.response)) {
        return null;
    }
    return error.response;
};
