import * as React from "react";
import { ErrorDialog } from "../ui/ErrorDialog";
import { t } from "../../i18n/util";

export const useErrorDialog = (params: {
    title?: string;
    message: string;
    buttonLabel?: string;
    onClose?: () => void;
}) => {
    const [open, setOpen] = React.useState(false);

    const dialog = (
        <ErrorDialog
            title={params.title}
            message={params.message}
            buttonLabel={params.buttonLabel ?? t("dialog.button.understood")}
            open={open}
            onClose={() => {
                setOpen(false);
            }}
        />
    );

    return {
        open() {
            setOpen(true);
        },
        dialog,
    };
};
