import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { observer } from "mobx-react";
import * as React from "react";
import { GLOBAL_FEATURES } from "../../../features";
import { generalStore } from "../../../stores/GeneralStore";
import { debug } from "../../../util/debug";
import { history } from "./history";

export const RoutingManager = observer(function RoutingManager(props: { children: React.ReactNode }) {
    const { trackPageView } = useMatomo();

    React.useEffect(() => {
        const initial = history.location;
        debug.log(
            `%c[ROUTER] Initial url "${initial.pathname}${initial.search}${initial.hash}"`,
            "background: #eee; color: #666;",
        );

        history.listen((location, action) => {
            const last = generalStore.lastLocation;
            if (location !== last) {
                const previous = last ?? initial;
                debug.log(
                    `%c[ROUTER] Switching url from "${previous.pathname}${previous.search}${previous.hash}" to "${location.pathname}${location.search}${location.hash}" by action ${action}.`,
                    "background: #eee; color: #666;",
                    "from state:",
                    previous.state,
                    "\nto state:  ",
                    location.state,
                );
                if (GLOBAL_FEATURES.matomo && generalStore.cookieSettings.marketing) {
                    trackPageView({ href: `${location.pathname}${location.search}` });
                }
                generalStore.lastLocation = location;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{props.children}</>;
});
