import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import { observer } from "mobx-react";
import { NAVBAR_HEIGHT } from "../../../config";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { SIDEBAR_WIDTH_OPEN } from "../../../stores/SideBarStore";
import { Routes } from "../../app/router/Routes";
import { TpaConnectPortrait } from "../../util/Images";
import { AdvisorDivider, AdvisorSideBarItems } from "./AdvisorSideBarItems";
import { ChooseCompanyDialog } from "./ChooseCompanyDialog";
import { CustomerSideBarItems } from "./CustomerSideBarItems";
import { FaceToFaceWidget } from "./FaceToFaceWidget";
import { OptionalSideBarItems } from "./OptionalSideBarItems";
import { SideBarItems } from "./SideBarItems";
import { UserHandlingDialog } from "./UserHandlingDialog";

export const SideBar = observer(function SideBar() {
    if (!authStore.userInfo) {
        return null;
    }

    // We are in an iframe -> don't display sidebar
    if (window.frameElement) {
        return null;
    }

    // Cases where we cannot show the sidebar yet:
    // - No company selected -> we need the company to know if it has (accounting, hr, etc. enabled)
    // - Permissions not loaded -> we don't know if the logged in user is allowed to see every feature
    // - Terms not accepted yet
    const logoutOnly = !companiesStore.selectedCompanyStore?.permissions.raw || !companiesStore.canAccessCompany;

    return (
        <Drawer
            variant="permanent"
            style={{
                width: SIDEBAR_WIDTH_OPEN,
                whiteSpace: "nowrap",
            }}
            PaperProps={{ style: { width: SIDEBAR_WIDTH_OPEN } }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexBasis: NAVBAR_HEIGHT - 1,
                    flexShrink: 0,
                }}
            >
                <TpaConnectPortrait style={{ width: 126 }} />
            </div>
            <div>
                <UserHandlingDialog />
            </div>
            <Divider />
            {!logoutOnly && (
                <>
                    <div>
                        <ChooseCompanyDialog />
                    </div>
                    <Divider style={{ marginBottom: 16 }} />
                    {authStore.isStaff && (
                        <SideBarItems
                            items={[
                                {
                                    iconName: "personnelFile",
                                    title: t("sidebar.list.personnelFile"),
                                    target: Routes.STAFF,
                                    enabled: GLOBAL_FEATURES.staff,
                                    badgeCount:
                                        companiesStore.selectedCompanyStore?.badgeCounts.openEmployeeDocumentReleases,
                                },
                            ]}
                        />
                    )}
                    {/* CONNECT-550: do not give full "advisor" access to staff only TPA employees */}
                    {authStore.isTpa && !authStore.isStaffOnly && (
                        <>
                            <AdvisorSideBarItems />
                            <AdvisorDivider title={t("sidebar.customerView")} />
                        </>
                    )}
                    {!authStore.isStaffOnly && <CustomerSideBarItems />}
                    <OptionalSideBarItems />
                    {(!!authStore.canSeeFaceToFaceWidget ||
                        (companiesStore.selectedCompany?.staffCanChat && authStore.isStaffOnly)) && (
                        <>
                            <Divider style={{ marginBottom: 16 }} />
                            <FaceToFaceWidget />
                        </>
                    )}
                </>
            )}
        </Drawer>
    );
});
