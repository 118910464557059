import * as React from "react";
import { POLL_PENDING_MS_ACTIONS_MS } from "../../config";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetPendingMicrosoftActionsResponse } from "../../network/APITypes";
import { CachedApiCall } from "../../network/CachedApiCall";
import { generalStore } from "../../stores/GeneralStore";

const cachedActions = new CachedApiCall<GetPendingMicrosoftActionsResponse>(1000);

export const usePendingMicrosoftActions = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [pendingActions, setPendingActions] = React.useState<GetPendingMicrosoftActionsResponse>({
        activeCountries: [],
        pendingInvitations: [],
        staleCountries: [],
    });

    const [pollRefresh, setPollRefresh] = React.useState(false);

    const load = React.useCallback(async () => {
        try {
            const res = await cachedActions.call(API.getPendingMicrosoftActions);
            if (res) {
                setPendingActions(res);

                // We currently have open invitation or refresh requests -> keep polling pending MS actions since
                // MS API sometimes has timing issues resulting in stale state being displayed
                const userActionRequired = res.pendingInvitations.length > 0 || res.staleCountries.length > 0;
                setPollRefresh(userActionRequired);
            }
        } catch (err) {
            generalStore.setError(t("error.loadPendingMicrosoftActions"), err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    React.useEffect(() => {
        load();
    }, [load]);

    // Poll pending actions if necessary
    React.useEffect(() => {
        let pollingId = 0;
        if (pollRefresh) {
            pollingId = setInterval(load, POLL_PENDING_MS_ACTIONS_MS);
        }

        return () => {
            clearInterval(pollingId);
        };
    }, [load, pollRefresh]);

    return {
        isLoading,
        reload: load,
        pendingActions,
        isCountryActive: (country?: string) => {
            return !!country && pendingActions.activeCountries.includes(country.toUpperCase());
        },
        isCountryStale: (country?: string) => {
            return !!country && pendingActions.staleCountries.includes(country.toUpperCase());
        },
        internationalAvailable: pendingActions.activeCountries.includes("AT"),
    };
};
