import { Button } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { User } from "../../network/APITypes";
import { CustomDialog } from "./CustomDialog";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";

export const ChooseUserDialog = ({
    open,
    title,
    message,
    buttonLabel,
    onClose,
    onSubmit,
}: {
    open: boolean;
    title: string;
    message: React.ReactNode;
    buttonLabel: string;
    onClose: () => void;
    onSubmit: (user: User) => void;
}) => {
    const handleSubmit = (user: User) => {
        onSubmit(user);
    };

    const handleSetUser = (user: User | null) => {
        console.log("### selected user", user);
    };

    // TODO: Need extra endpoint for loading users
    // TODO: Add second input field for adding users by email

    return (
        <CustomDialog open={open} onClose={onClose}>
            <div
                style={{
                    padding: 42,
                }}
            >
                <h1>{title}</h1>
                <p style={{ marginTop: 24 }}>{message}</p>
                TODO: Selector goes here
                <ResponsiveButtonContainer style={{ marginTop: 60 }}>
                    <Button color="primary" variant="outlined" onClick={onClose}>
                        {t("common.cancel")}
                    </Button>
                    <Button color="primary" variant="contained">
                        {buttonLabel}
                    </Button>
                </ResponsiveButtonContainer>
            </div>
        </CustomDialog>
    );
};
