import { Checkbox, TableBody } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    GetProjectUsersResponse,
    GetProjectsCompaniesResponseItem,
    ProjectUser,
    ProjectUserType,
} from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { IProjectUser } from "../../../types/models";
import { getCountryName } from "../../../util/helpers";
import { getFullName } from "../../../util/user";
import { pushRoute, withParams, withQuery } from "../../app/router/history";
import { useCompanies } from "../../hooks/useCompanies";
import { useFeedCountries } from "../../hooks/useFeedCountries";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useTableFilters } from "../../hooks/useTableFilters";
import { CenteredContent } from "../../ui/CenteredContent";
import { EmptyState } from "../../ui/EmptyState";
import { NavBarBack } from "../../ui/NavBarBack";
import { TableLabel, TpaTable, TpaTableCell, TpaTableContainer, TpaTableRow } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { DROPDOWN_FILTER_TYPE, ITableFilters } from "../../ui/filter/types";
import { customColors } from "../../util/Theme";
import { useAddProjectUsersDialog } from "../hooks/useAddProjectUsersDialog";
import { ProjectsRoutes } from "../router/ProjectsRoutes";
import { ProjectConfig } from "./types";

export const ProjectsDataProjectUsersSite = observer(function ProjectsDataProjectUsersSite({
    isInternational,
    project,
    projectId,
    externalId,
    projectUsers,
    setProjectUsers,
    projectCompanies,
}: {
    isInternational: boolean;
    project: ProjectConfig;
    projectId?: string;
    externalId?: string;
    projectUsers: IProjectUser[];
    setProjectUsers: (users: IProjectUser[]) => void;
    projectCompanies: GetProjectsCompaniesResponseItem[];
}) {
    const isNewRoute = !externalId && !projectId;

    const queryParams = useQueryParams<Record<string, unknown>>();

    const tableStore = useTableStore<ProjectUser & { name?: unknown }>("ProjectUsers", {
        orderBy: "name",
        orderDir: "asc",
    });

    const canSelect = useCallback(
        (user: ProjectUser) => {
            return !!user.username && !projectUsers.find(u => u.userId === user.id);
        },
        [projectUsers],
    );
    tableStore.canSelect = canSelect;

    const headerFields: ITableHeaderConfig[] = [
        { column: "name", label: "table.label.contactPerson" },
        { column: "username", label: "table.label.email" },
        { column: "status" },
    ];

    const companyId = companiesStore.selectedCompanyId;

    const feedCountries = useFeedCountries();

    // Restrict filter to 20 companies since some test users have thousands of companies
    const companies = useCompanies({ limit: 20 });

    const [filterCompanyId, setFilterCompanyId] = React.useState<string>();
    const [filterUserType, setFilterUserType] = React.useState<ProjectUserType>();
    const [filterCountryCode, setFilterCountryCode] = React.useState<string>();

    const { tableParams } = tableStore;
    React.useEffect(() => {
        const loadUsers = async () => {
            if (!companyId) {
                return;
            }

            try {
                generalStore.isLoading = true;
                let response: GetProjectUsersResponse;
                if (isNewRoute) {
                    // the new page allows the user to create a project for multiple companies
                    // therefore we have to load the intersected users for all companies
                    response = await API.getProjectUsersIntersection(
                        {
                            international: isInternational,
                            // if the project is international, load all users from all companies
                            companyIDs: isInternational ? [] : projectCompanies.map(c => c.id).concat(companyId),
                        },
                        tableParams,
                    );
                } else if (isInternational) {
                    response = await API.getInternationalUsers({
                        companyId,
                        ...tableParams,
                        filterCompanyId,
                        userType: filterUserType,
                        countryCode: filterCountryCode,
                    });
                } else {
                    response = await API.getUsersForProjects({ companyId, ...tableParams });
                }

                tableStore.items = response.users ?? [];
                tableStore.totalCount = response.total;
            } catch (error) {
                generalStore.setError(t("error.loadUsers"), error);
            } finally {
                generalStore.isLoading = false;
            }
        };

        loadUsers();
    }, [
        companyId,
        filterCompanyId,
        filterCountryCode,
        filterUserType,
        isInternational,
        isNewRoute,
        projectCompanies,
        tableParams,
        tableStore,
    ]);

    const tableFilters = useTableFilters({
        filters: [
            {
                category: "general",
                entries: [
                    {
                        name: "filterCompanyId",
                        label: t("common.company"),
                        type: DROPDOWN_FILTER_TYPE,
                        options: companies.companies.map(c => ({ value: c.id, label: c.name })),
                        onChange: (companyId: string) => {
                            setFilterCompanyId(companyId);
                        },
                    },
                    {
                        name: "userType",
                        label: t("common.people"),
                        type: DROPDOWN_FILTER_TYPE,
                        options: [
                            { value: "tpa", label: t("common.tpaFirstName") },
                            { value: "customer", label: "Kunden" },
                        ],
                        onChange: (userType: string) => {
                            setFilterUserType(userType as ProjectUserType);
                        },
                    },
                    {
                        name: "countryCode",
                        label: t("common.country"),
                        type: DROPDOWN_FILTER_TYPE,
                        options: feedCountries.countries.map(country => ({
                            value: country.countryCode,
                            label: getCountryName(country.countryCode),
                        })),
                        onChange: (countryCode: string) => {
                            setFilterCountryCode(countryCode);
                        },
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            tableStore.resetOffset();

            // Deselect
            if (!selected.filterCompanyId) {
                setFilterCompanyId(undefined);
            }
            if (!selected.userType) {
                setFilterUserType(undefined);
            }
            if (!selected.countryCode) {
                setFilterCountryCode(undefined);
            }
        },
    });

    const tableBody = (
        <TableBody>
            {tableStore.items.map((user, index) => {
                const disabled = !canSelect(user);
                const color = disabled ? customColors.disabled : undefined;

                return (
                    <TpaTableRow key={user.id}>
                        <TpaTableCell padding="checkbox">
                            <Checkbox
                                data-id={`user_checkbox_${index}`}
                                onChange={(event, checked) => {
                                    tableStore.toggleSelection(user);
                                }}
                                color="primary"
                                checked={tableStore.isSelected(user)}
                                disabled={disabled}
                            />
                        </TpaTableCell>
                        {headerFields.map(({ column }, index) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            let label: any = user[column as keyof ProjectUser];

                            if (column === "name") {
                                label = getFullName(user);
                            } else if (column === "username") {
                                return (
                                    <TpaTableCell key={column}>
                                        <div style={{ whiteSpace: "nowrap", color }}>{user.username}</div>
                                    </TpaTableCell>
                                );
                            } else if (column === "status") {
                                const isWaitingForRelease = user.needsRelease;
                                if (isWaitingForRelease) {
                                    label = t("config.users.waiting.title");
                                }
                            }

                            return (
                                <TpaTableCell key={column}>
                                    <TableLabel style={{ color }}>{label}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    const hasFilter = !!filterCompanyId || !!filterUserType || !!filterCountryCode;

    // No users and NOT caused by a search term
    const isEmpty = tableStore.getIsEmptyState(generalStore.isLoading, hasFilter);

    const backLabel = projectId
        ? t("projects.navbar.project.heading", { projectName: project?.name })
        : t("projects.new");

    let backTarget: string = ProjectsRoutes.NEW_PROJECT;
    if (projectId) {
        backTarget = withParams(ProjectsRoutes.EDIT_PROJECT, { projectId });
    } else if (externalId) {
        backTarget = withParams(ProjectsRoutes.TO_CONFIGURE.PROJECT, { externalId });
    }
    backTarget = withQuery(backTarget, queryParams);

    const addProjectUsersDialog = useAddProjectUsersDialog(usersToAdd => {
        const newUsers = [
            ...projectUsers,
            ...usersToAdd.map(user => ({
                id: project?.users?.find(u => u.userId === user.id)?.id,
                userId: user.id,
                name: getFullName(user),
                role: user.role,
                username: user.username,
                profile_picture_url: user.profile_picture_url,
                isTpaEmployee: user.isTpaEmployee,
            })),
        ];

        setProjectUsers(newUsers);
        pushRoute(backTarget);
    });

    const handleClickInviteUser = () => {
        addProjectUsersDialog.open(tableStore.getAllSelectedItems());
    };

    return (
        <>
            <NavBarBack
                title={t("projects.addMembers")}
                companyName={companiesStore.selectedCompany?.name}
                backLabel={backLabel}
                backTarget={backTarget}
            />
            <CenteredContent>
                {isEmpty && (
                    <EmptyState
                        title={t("config.users.emptyState.title")}
                        message={<FormattedHTMLMessage id="config.users.emptyState.message" />}
                    />
                )}
                {!isEmpty && (
                    <SiteContent>
                        <TableSearchBarWithAction
                            label="search.caption.numDespositedPersons"
                            placeholder="search.placeholder.searchForUsers"
                            labelSelected="search.caption.numSelected"
                            select={tableStore}
                            search={tableStore.search}
                            totalCount={tableStore.totalCount}
                            onChangeSearch={tableStore.handleSearchChange}
                            buttonLabel={t("projects.members.assignPermission")}
                            tableFilters={!isNewRoute && isInternational ? tableFilters : undefined}
                            onAction={tableStore.selectedItems.size > 0 ? handleClickInviteUser : undefined}
                        />
                        <TpaTableContainer>
                            <TpaTable>
                                <TableHeader
                                    allowMultiSelectAlways
                                    headerFields={headerFields}
                                    tableStore={tableStore}
                                    select={tableStore}
                                />
                                {tableBody}
                            </TpaTable>
                        </TpaTableContainer>
                        <tableStore.Pagination />
                    </SiteContent>
                )}
                {tableStore.getIsNoResultState(generalStore.isLoading, hasFilter) && (
                    <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                )}
            </CenteredContent>
            {addProjectUsersDialog.component}
        </>
    );
});
