import { MenuItem, TextField } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { EmployeeDocumentType } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { IArchiveDocument, ISubsidiary } from "../../types/models";
import { pushRoute } from "../app/router/history";
import { Routes } from "../app/router/Routes";
import { DocumentCategory, isSupportedHrWebhookDocumentType } from "../hooks/useWebhookConfiguration";
import { DocumentLineEmployees } from "../ui/DocumentLine/DocumentLineEmployees";
import { UploadSmallPaper } from "../ui/DocumentUploadSmall";
import { ConfirmWebhook } from "./ConfirmWebhook";
import { DEFAULT_STAFF_CAN_READ, WEBHOOK_CURRENT_EMPLOYEES_ACTIVE_DAYS } from "../../config";

export type IUploadEmployeeDocument = IArchiveDocument & { selectedEmployeeId?: string; staffCanRead: boolean };

export const UploadEmployeeDocumentList = (props: {
    companyId: string;
    subsidiaryId?: string;
    category: DocumentCategory | "";
    documents: IUploadEmployeeDocument[];
    setDocuments: (documents: IUploadEmployeeDocument[]) => void;
    onSubmit?: () => void;
    redirectToActiveEmployees: (active: boolean) => void;
}) => {
    const [notify, setNotify] = React.useState(true);
    const [submitted, setSubmitted] = React.useState(false);
    const [subsidiaries, setSubsidiaries] = React.useState<ISubsidiary[]>([]);
    const [subsidiaryId, setSubsidiaryId] = React.useState(props.subsidiaryId ?? "");

    React.useEffect(() => {
        const loadSubsidiaries = async () => {
            try {
                const subsidiaries = await API.getSubsidiaries({ companyId: props.companyId, module: "hr" });
                setSubsidiaries(subsidiaries);

                if (
                    subsidiaries.length > 0 &&
                    (!subsidiaryId || subsidiaries.findIndex(s => s.id === subsidiaryId) === -1)
                ) {
                    // Current subsidiary doesn't exist in new array -> set current to first subsidiary
                    setSubsidiaryId(subsidiaries[0].id);
                }
            } catch (err) {
                generalStore.setError(t("error.loadSubsidiary"), err);
            }
        };

        loadSubsidiaries();
    }, [props.companyId, subsidiaryId]);

    const handleSetEmployeeIdChanged = (
        document: IUploadEmployeeDocument,
        employeeId: string,
        isCurrentlyEmployed?: boolean,
    ) => {
        document.selectedEmployeeId = employeeId;

        // If one employee is active -> redirect to active list, but don't go back to
        // inactive if the next is inactive. Active has precedence.
        if (isCurrentlyEmployed) {
            props.redirectToActiveEmployees(!!isCurrentlyEmployed);
        }
    };

    const handleSubmit = async () => {
        setSubmitted(true);

        // Check if all employee ids are filled in
        for (let i = 0; i < props.documents.length; ++i) {
            const document = props.documents[i];
            if (!document.selectedEmployeeId && !document.employee?.id) {
                generalStore.setError(`${document.name}: ${t("error.missingEmployee")}`);
                return;
            }
        }

        try {
            if (!props.companyId) {
                generalStore.setError(t("error.noCompanySelected"));
                return;
            }

            if (!subsidiaryId) {
                generalStore.setError(t("error.hr.noSubsidiarySelected"));
                return;
            }

            if (!isSupportedHrWebhookDocumentType(props.category)) {
                // This shouldn't happen
                generalStore.setError(t("error.unsupportedDocumentType"));
                return;
            }

            generalStore.isLoading = true;

            await API.postEmployeeDocumentsWebhook({
                companyId: props.companyId,
                subsidiaryId,
                archiveDocuments: props.documents.map(document => {
                    const employeeId = document.selectedEmployeeId ?? document.employee?.id;
                    return {
                        archiveId: document.archiveId ?? "", // Save because from archive
                        documentId: document.documentId ?? "", // Save because from archive
                        name: document.name,
                        employeeId: employeeId ?? "", // Save because of check above
                        type: props.category as EmployeeDocumentType,
                        staffCanRead: document.staffCanRead ?? DEFAULT_STAFF_CAN_READ,
                    };
                }),
                sendEmail: notify,
            });

            props.onSubmit?.();
        } catch (err) {
            generalStore.setError(t("error.upload"), err);
        }

        generalStore.isLoading = false;
    };

    return (
        <>
            {subsidiaries.length > 0 && (
                <TextField
                    value={subsidiaryId}
                    name="subsidiary"
                    data-id="upload_subsidiary"
                    label={t("common.hr.subsidiary")}
                    variant="outlined"
                    select
                    style={{ marginBottom: 18 }}
                    onChange={event => {
                        setSubsidiaryId(event.target.value);
                    }}
                >
                    {subsidiaries.map(subsidiary => (
                        <MenuItem key={subsidiary.id} value={subsidiary.id}>
                            {subsidiary.name}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            {subsidiaryId && (
                <UploadSmallPaper>
                    {props.documents.map((document, index) => (
                        <DocumentLineEmployees
                            companyId={props.companyId}
                            subsidiaryId={subsidiaryId}
                            style={{ marginTop: index === 0 ? 0 : 32 }}
                            fileName={document.name}
                            onSetEmployeeId={(employeeId, isCurrentlyEmployed) => {
                                handleSetEmployeeIdChanged(document, employeeId, isCurrentlyEmployed);
                            }}
                            onDelete={() => {
                                if (props.documents.length === 1) {
                                    // Last doc deleted -> go to cockpit
                                    pushRoute(Routes.COCKPIT);
                                } else {
                                    const newDocs = [...props.documents];
                                    newDocs.splice(index, 1);
                                    props.setDocuments(newDocs);
                                }
                            }}
                            onChangeVisibleToEmployee={(visible: boolean) => {
                                const newDocs = [...props.documents];
                                newDocs[index] = {
                                    ...document,
                                    staffCanRead: visible,
                                };

                                props.setDocuments(newDocs);
                            }}
                            showEmptyErrors={submitted}
                            preloadedEmployee={document.employee}
                            key={document.documentId ?? index}
                            activeDays={WEBHOOK_CURRENT_EMPLOYEES_ACTIVE_DAYS}
                        />
                    ))}
                </UploadSmallPaper>
            )}
            <ConfirmWebhook notifyCustomer={notify} setNotifyCustomer={setNotify} onSubmit={handleSubmit} />
        </>
    );
};
