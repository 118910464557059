import { useCallback, useState } from "react";
import { POLLING_BANK_CONNECTIONS_MS } from "../../../config";
import { BankConnection } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { useInterval } from "../../hooks/useInterval";

type Compare = (updateStartedAt: Date, connections: BankConnection[]) => boolean;

export function useBankConnectionsPolling(onChange: (connections: BankConnection[]) => void) {
    const [pollData, setPollData] = useState<{ updateStartedAt: Date; compare: Compare }>();

    const reloadBankConnections = async () => {
        if (pollData) {
            await companiesStore.loadBankConnections();
            const connections = companiesStore.bankConnections.connections;
            if (connections && pollData.compare(pollData.updateStartedAt, connections)) {
                setPollData(undefined);
                onChange(connections);
            }
        }
    };

    useInterval(reloadBankConnections, pollData ? POLLING_BANK_CONNECTIONS_MS : 0);

    const startPolling = useCallback((compare: Compare) => {
        setPollData({ updateStartedAt: new Date(), compare });
    }, []);

    return {
        startPolling,
    };
}
