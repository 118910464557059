import React from "react";
import { API } from "../../network/API";
import { TicketDetails } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { t } from "../../i18n/util";

export const useTicketDetails = ({
    companyId,
    ticketId,
    ticketDetails, // Optionally you can pass preloaded ticket details
}: { companyId?: string; ticketId?: string; ticketDetails?: TicketDetails } = {}) => {
    const [details, setDetails] = React.useState<TicketDetails | undefined>(ticketDetails);

    const load = React.useCallback(async () => {
        if (!companyId || !ticketId) {
            return;
        }

        try {
            const response = await API.getTicketDetails(companyId, ticketId);
            setDetails(response);
        } catch (error) {
            generalStore.setError(t("error.loadTicketDetails"), error);
        }
    }, [companyId, ticketId]);

    React.useEffect(() => {
        // Load if not already preloaded
        if (!ticketDetails) {
            load();
        }
    }, [load, ticketDetails]);

    return {
        details,
        reload: load,
    };
};
