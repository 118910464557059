import { observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import styled from "styled-components";
import { ROWS_PER_PAGE } from "../../../config";
import { t } from "../../../i18n/util";
import { NewsFeedEntry } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { useTableStore } from "../../../stores/TableStore";
import { pushRoute, withParams } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { useNews } from "../../hooks/useNews";
import { CenteredContent } from "../../ui/CenteredContent";
import { MobileContext } from "../../util/MobileContext";
import { CockpitNavBar } from "../CockpitNavBar";
import { CockpitNewsCard } from "../CockpitNewsCard";
import { CockpitRoutes } from "../router/CockpitRoutes";

const NewsContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ isMobile }: { isMobile: boolean }) => (isMobile ? "auto" : "auto auto auto")};
    grid-gap: ${({ isMobile }: { isMobile: boolean }) => (isMobile ? "24px 0px" : "24px 16px")};
`;

export const CockpitNewsSite = observer(function CockpitNewsSite() {
    useHideSideBar();
    const tableStore = useTableStore("NewsSite", { orderBy: "publishedAt", orderDir: "desc", limit: ROWS_PER_PAGE });
    const isMobile = React.useContext(MobileContext);

    const news = useNews({
        companyId: companiesStore.selectedCompanyId,
        offset: tableStore.offset,
        limit: 9,
        setGeneralStoreIsLoading: true, // we want to show the loading spinner here
    });

    React.useEffect(() => {
        tableStore.totalCount = news.total;
    }, [news.total, tableStore]);

    const handleClickNewsItem = (newsItem: NewsFeedEntry) => {
        pushRoute(withParams(CockpitRoutes.NEWS_LIST_ARTICLE, { newsId: newsItem.id }));
    };

    if (!companiesStore.selectedCompanyId) {
        return <Redirect to={Routes.COMPANY_OVERVIEW} />;
    }

    return (
        <>
            <CockpitNavBar title={t("screen.cockpit.allNews")} backTarget={CockpitRoutes.ROOT} />
            <CenteredContent>
                <div style={{ padding: isMobile ? "48px 16px" : "48px 24px" }}>
                    <NewsContainer isMobile={isMobile}>
                        {news.news.map(newsItem => (
                            <CockpitNewsCard key={newsItem.id} news={newsItem} onClick={handleClickNewsItem} />
                        ))}
                    </NewsContainer>
                </div>
                <tableStore.Pagination />
            </CenteredContent>
        </>
    );
});
