import { Checkbox, Chip, TableBody } from "@material-ui/core";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    GetCompaniesProjectsResponseItem,
    PostProjectUsersIntersectionPayload,
    ProjectItemPermission,
    ProjectUser,
} from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { useTableStore } from "../../../stores/TableStore";
import { getFullName } from "../../../util/user";
import { pushRoute } from "../../app/router/history";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { Autocomplete, Loader } from "../../ui/Autocomplete";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { TableLabel, TpaTable, TpaTableCell, TpaTableContainer, TpaTableRow } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { customColors } from "../../util/Theme";
import { useAddProjectUsersDialog } from "../hooks/useAddProjectUsersDialog";
import { ProjectsRoutes } from "../router/ProjectsRoutes";

const SelectedProjectsContainer = styled.ul`
    margin-top: 16px;
    margin-bottom: 16px;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
`;

export const ProjectsBulkAddUsersSite = observer(function ProjectsBulkAddUsersSite() {
    useHideSideBar();

    const [projects, setProjects] = useState<GetCompaniesProjectsResponseItem[]>([]);

    const handleProjectAdd = async (project: GetCompaniesProjectsResponseItem) => {
        generalStore.isLoading = true;
        try {
            const { users } = await API.getProjectItemPermissions(project.company.id, project.project.id);
            project = { ...project, project: { ...project.project, users } };
        } catch (error) {
            return;
        } finally {
            generalStore.isLoading = false;
        }

        setProjects(projects.concat(project));
    };
    const handleProjectRemove = (project: GetCompaniesProjectsResponseItem) => {
        setProjects(projects.filter(p => p !== project));
    };

    return (
        <>
            <NavBarBack
                title={t("projects.addMembers")}
                backLabel={t("sidebar.list.projects")}
                backTarget={ProjectsRoutes.ROOT}
            />
            <CenteredContent>
                <SiteContent>
                    <ProjectsPicker projects={projects} onAdd={handleProjectAdd} onRemove={handleProjectRemove} />
                    <ProjectUsersTable projects={projects} />
                </SiteContent>
            </CenteredContent>
        </>
    );
});

interface ProjectsPickerProps {
    projects: GetCompaniesProjectsResponseItem[];
    onAdd: (project: GetCompaniesProjectsResponseItem) => void;
    onRemove: (project: GetCompaniesProjectsResponseItem) => void;
}

const ProjectsPicker = ({ projects, onAdd, onRemove }: ProjectsPickerProps) => {
    const handleProjectChange = (project: GetCompaniesProjectsResponseItem | null) => {
        if (!project) {
            return;
        }
        onAdd(project);
    };

    return (
        <>
            <AutocompleteProjectWhereUserIsOwner
                onChange={handleProjectChange}
                filterIds={projects.map(project => project.project.id)}
            />

            <SelectedProjectsContainer>
                {projects.map(project => {
                    return (
                        <li key={project.project.id}>
                            <Chip
                                label={`${project.project.name} (${project.company.name.trim()})`}
                                onDelete={() => {
                                    onRemove(project);
                                }}
                            />
                        </li>
                    );
                })}
            </SelectedProjectsContainer>
        </>
    );
};

interface AutocompleteProjectWhereUserIsOwnerProps {
    onChange: (project: GetCompaniesProjectsResponseItem | null) => void;
    filterIds?: string[];
}

const AutocompleteProjectWhereUserIsOwner = ({ onChange, filterIds }: AutocompleteProjectWhereUserIsOwnerProps) => {
    const loader: Loader<GetCompaniesProjectsResponseItem> = useCallback(async ({ cursor, search }) => {
        try {
            const response = await API.getCompaniesProjectsOwner({
                limit: 9,
                cursor,
                search,
            });
            return {
                items: response.projects,
                cursor: response.cursor,
            };
        } catch (error) {
            generalStore.setError(t("error.loadProjects"), error);
            return null;
        }
    }, []);

    return (
        <Autocomplete<GetCompaniesProjectsResponseItem>
            value={null}
            onChange={onChange}
            loader={loader}
            getOptionLabel={option => `${option.project.name} (${option.company.name.trim()})`}
            isSelected={(option, value) => option.project.id === value.project.id}
            isDisabled={option => (filterIds ? filterIds.includes(option.project.id) : false)}
            placeholder={t("search.placeholder.projects")}
        />
    );
};

interface ProjectUsersTableProps {
    projects: GetCompaniesProjectsResponseItem[];
}

type ProjectUserWithName = ProjectUser & { name?: string };

const ProjectUsersTable = observer(function ProjectUsersTable({ projects }: ProjectUsersTableProps) {
    const tableStore = useTableStore<ProjectUserWithName>("ProjectsBulkAddUsersTable", {
        orderBy: "name",
        orderDir: "asc",
        limit: 9,
    });

    const canSelect = useCallback((user: ProjectUser) => {
        return !!user.username;
    }, []);
    tableStore.canSelect = canSelect;

    const body = useMemo<PostProjectUsersIntersectionPayload>(() => {
        let international = false;
        const companyIDs = new Set<string>();
        projects.forEach(p => {
            if (p.project.international) {
                international = true;
            }
            companyIDs.add(p.company.id);
        });
        return {
            companyIDs: Array.from(companyIDs),
            international,
        };
    }, [projects]);

    const { tableParams } = tableStore;
    useEffect(() => {
        const loadUsers = async () => {
            generalStore.isLoading = true;

            try {
                const response = await API.getProjectUsersIntersection(body, tableParams);

                tableStore.items = response.users ?? [];
                tableStore.totalCount = response.total;
            } catch (error) {
                generalStore.setError(t("error.loadUsers"), error);
            }

            generalStore.isLoading = false;
        };

        loadUsers();
    }, [body, tableParams, tableStore]);

    const successDialog = useSuccessDialog({
        title: t("projects.addMembers.bulk.success.title"),
        onClose: () => {
            pushRoute(ProjectsRoutes.ACTIVE.ROOT);
        },
    });

    const addProjectUsersDialog = useAddProjectUsersDialog(async usersToAdd => {
        const mappedUsersToAdd = usersToAdd.map<ProjectItemPermission>(user => ({
            userId: user.id,
            name: getFullName(user),
            role: user.role,
            username: user.username,
            profile_picture_url: user.profile_picture_url,
            isTpaEmployee: user.isTpaEmployee,
        }));

        generalStore.isLoading = true;
        for (const project of projects) {
            const existingUsers = [...(project.project.users ?? [])];
            mappedUsersToAdd.forEach(user => {
                const existingProjectUserId = existingUsers.find(u => u.userId === user.id)?.id;
                if (existingProjectUserId) {
                    user = { ...user, id: existingProjectUserId };
                }
                existingUsers.push(user);
            });

            try {
                await API.putProject(project.company.id, project.project.id, {
                    ...project.project,
                    users: existingUsers,
                });
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.statusCode === HttpStatusCode.Conflict_409) {
                    if (apiError.response.type === "NO_REFRESH_TOKEN_FOUND") {
                        generalStore.setError(t("error.msTokenExpired"), error);
                    } else if (apiError.response.type === "AZURE_CANT_INVITE_DOMAIN_EMAIL") {
                        generalStore.setError(t("error.noMsAccountAvailable"), error);
                    } else {
                        generalStore.setError(t("error.general"), error);
                    }
                } else {
                    generalStore.setError(t("error.general"), error);
                }
            }
        }
        generalStore.isLoading = false;

        successDialog.openDialog();
    });

    const handleClickInviteUser = () => {
        addProjectUsersDialog.open(tableStore.getAllSelectedItems());
    };

    const headerFields: ITableHeaderConfig<ProjectUserWithName>[] = [
        { column: "name", label: "table.label.contactPerson" },
        { column: "username", label: "table.label.email" },
        { column: "needsRelease" },
    ];

    const tableBody = (
        <TableBody>
            {tableStore.items.map((user, index) => {
                const disabled = !canSelect(user);
                const color = disabled ? customColors.disabled : undefined;

                return (
                    <TpaTableRow key={user.id}>
                        <TpaTableCell padding="checkbox">
                            <Checkbox
                                data-id={`user_checkbox_${index}`}
                                onChange={(event, checked) => {
                                    tableStore.toggleSelection(user);
                                }}
                                color="primary"
                                checked={tableStore.isSelected(user)}
                                disabled={disabled}
                            />
                        </TpaTableCell>
                        {headerFields.map(({ column }) => {
                            let label;

                            if (column === "name") {
                                label = getFullName(user);
                            } else if (column === "username") {
                                return (
                                    <TpaTableCell key={column}>
                                        <div style={{ whiteSpace: "nowrap", color }}>{user.username}</div>
                                    </TpaTableCell>
                                );
                            } else if (column === "needsRelease") {
                                const isWaitingForRelease = user.needsRelease;
                                if (isWaitingForRelease) {
                                    label = t("config.users.waiting.title");
                                }
                            }

                            return (
                                <TpaTableCell key={column}>
                                    <TableLabel style={{ color }}>{label ?? ""}</TableLabel>
                                </TpaTableCell>
                            );
                        })}
                    </TpaTableRow>
                );
            })}
        </TableBody>
    );

    return (
        <>
            <TableSearchBarWithAction
                label="search.caption.numDespositedPersons"
                placeholder="search.placeholder.searchForUsers"
                labelSelected="search.caption.numSelected"
                select={tableStore}
                search={tableStore.search}
                totalCount={tableStore.totalCount}
                onChangeSearch={tableStore.handleSearchChange}
                buttonLabel={t("projects.members.assignPermission")}
                onAction={tableStore.selectedItems.size > 0 ? handleClickInviteUser : undefined}
            />
            <TpaTableContainer>
                <TpaTable>
                    <TableHeader
                        allowMultiSelectAlways
                        headerFields={headerFields}
                        tableStore={tableStore}
                        select={tableStore}
                    />
                    {tableBody}
                </TpaTable>
            </TpaTableContainer>
            <tableStore.Pagination />
            {addProjectUsersDialog.component}
            {successDialog.dialog}
        </>
    );
});
