import { useCustomerListing } from "./hooks/useCustomerListing";
import { ResultsTable } from "./ResultsTable";
import { ResultsSiteViewProps } from "./types";

interface ResultsCustomerListingProps extends ResultsSiteViewProps {}

export const ResultsCustomerListing = ({ companyId, settings, financialAccountancy }: ResultsCustomerListingProps) => {
    const state = useCustomerListing(companyId, settings);
    return state && financialAccountancy ? (
        <ResultsTable
            type="customerListing"
            columns={state.columns}
            rows={state.rows}
            stylesheet={state.stylesheet}
            financialAccountancy={financialAccountancy}
        />
    ) : null;
};
