import { IconButton, Tooltip } from "@material-ui/core";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import React from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { ViewerFile } from "../../stores/ViewerStore";
import { MultiLineEllipsis } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { rendererForFile } from "./renderer/renderer";

const HeaderRoot = styled.header`
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 24px;
    background: ${customColors.white};
    border-bottom: 1px solid ${customColors.greyLight};
    gap: 32px;
`;
const HeaderActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
    white-space: nowrap;
`;
const HeaderActionsLeft = styled(HeaderActions)``;
const HeaderActionsRight = styled(HeaderActions)`
    justify-content: flex-end;
`;

const MobileHeaderRoot = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8px;
    background: ${customColors.white};
    border-bottom: 1px solid ${customColors.greyLight};
`;
const MobileFileNameBar = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
const MobileActionsBar = styled.div`
    display: flex;
    gap: 24px;
`;

export type ZoomDirection = "out" | "in";

interface HeaderProps {
    files: ViewerFile[];
    file: ViewerFile | null;
    onClose?: () => void;
    onSelect?: (file: ViewerFile) => void;
    onZoom: (dir: ZoomDirection) => void;
    layout?: "mobile";
}

export const Header = ({ files, file, onClose, onSelect, onZoom, layout }: HeaderProps) => {
    let downloadButton;
    if (file?.download) {
        const download = file.download;
        downloadButton = (
            <Tooltip title={t("common.download")}>
                <IconButton onClick={() => download()}>
                    <Icon name="download" />
                </IconButton>
            </Tooltip>
        );
    }

    const zoomButtons = useZoomButtons({ file, onZoom });
    const prevNextButtons = usePrevNextButtons({ files, file, onSelect });
    const closeButton = onClose ? (
        <IconButton onClick={onClose}>
            <Icon name="close" />
        </IconButton>
    ) : null;
    const fileName = (
        <FileName>
            <MultiLineEllipsis text={file ? file.name : ""} maxLine={2} />
        </FileName>
    );

    const isMobile = React.useContext(MobileContext) || layout === "mobile";

    if (isMobile) {
        return (
            <MobileHeaderRoot>
                <MobileFileNameBar>
                    {/* hidden close button to center file name */}
                    {closeButton && <div style={{ visibility: "hidden" }}>{closeButton}</div>}
                    {fileName}
                    {closeButton}
                </MobileFileNameBar>
                <MobileActionsBar>
                    {downloadButton}
                    {zoomButtons}
                    {prevNextButtons}
                </MobileActionsBar>
            </MobileHeaderRoot>
        );
    }

    return (
        <HeaderRoot>
            <HeaderActionsLeft>
                {downloadButton}
                {zoomButtons}
            </HeaderActionsLeft>
            {fileName}
            <HeaderActionsRight>
                {prevNextButtons}
                {closeButton}
            </HeaderActionsRight>
        </HeaderRoot>
    );
};

const FileName = styled.h1`
    flex: 1;
    min-height: 48px; // height of close button and enough for 2 lines of text
    font-size: 18px;
    line-height: 1em;
    text-align: center;
    word-break: break-word;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        flex: 1; // fix sizing of LineEllipsis component
    }
`;

function useZoomButtons({ file, onZoom }: { file: ViewerFile | null; onZoom: HeaderProps["onZoom"] }) {
    const canZoom = file ? rendererForFile(file).canZoom : false;
    if (!canZoom) {
        return null;
    }
    return (
        <div>
            <IconButton
                onClick={() => {
                    onZoom("out");
                }}
            >
                <ZoomOutIcon />
            </IconButton>
            <IconButton
                onClick={() => {
                    onZoom("in");
                }}
            >
                <ZoomInIcon />
            </IconButton>
        </div>
    );
}

function usePrevNextButtons({
    files,
    file,
    onSelect,
}: {
    files: ViewerFile[];
    file: ViewerFile | null;
    onSelect?: (file: ViewerFile) => void;
}) {
    if (!onSelect) {
        return null;
    }

    let prevFile: ViewerFile | undefined = undefined;
    let nextFile: ViewerFile | undefined = undefined;
    if (files.length > 1 && file) {
        const idx = files.findIndex(f => f.id === file.id);
        if (idx > 0) {
            prevFile = files[idx - 1];
        }
        if (idx < files.length - 1) {
            nextFile = files[idx + 1];
        }
    }

    const handleSelect = (file: ViewerFile | undefined) => {
        if (!file) {
            return undefined;
        }
        return () => {
            onSelect(file);
        };
    };

    return (
        <div>
            <IconButton onClick={handleSelect(prevFile)} disabled={!prevFile}>
                <Icon name="chevronLeft" />
            </IconButton>
            <IconButton onClick={handleSelect(nextFile)} disabled={!nextFile}>
                <Icon name="chevronRight" />
            </IconButton>
        </div>
    );
}
