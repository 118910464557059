import * as React from "react";
import { t } from "../../i18n/util";
import { API, IGetEmployeesParams } from "../../network/API";
import { Employee } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { useTableStore } from "../../stores/TableStore";
import { hrStore } from "../hr/HrStore";

export const useCurrentEmployees = () => {
    const [employees, setEmployees] = React.useState<Employee[]>([]);
    const tableStore = useTableStore("CurrentEmployees", {
        orderBy: "name",
        orderDir: "asc",
    });
    const [onlyMissingDetails, setOnlyMissingDetails] = React.useState(false);
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [initialized, setInitialized] = React.useState(false);

    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = hrStore.selectedSubsidiaryId;

    const { tableParams } = tableStore;
    const loadEmployees = React.useCallback(async () => {
        try {
            generalStore.isLoading = true;

            const options: IGetEmployeesParams["options"] = { ...tableParams };

            if (onlyMissingDetails) {
                options.onlyMissingDetails = onlyMissingDetails;
            }

            if (startDate) {
                options.startDate = startDate;
            }

            if (endDate) {
                options.endDate = endDate;
            }

            const employees = await API.getCurrentEmployees(companyId, subsidiaryId, {
                options: options,
            });

            if (employees) {
                setEmployees(employees.employees ?? []);
                tableStore.totalCount = employees.total;
            }
        } catch (err) {
            generalStore.setError(t("error.loadEmployees"), err);
        } finally {
            generalStore.isLoading = false;
            setInitialized(true);
        }
    }, [companyId, endDate, onlyMissingDetails, startDate, subsidiaryId, tableParams, tableStore]);

    React.useEffect(() => {
        loadEmployees();
    }, [loadEmployees]);

    return {
        employees,
        tableStore,

        setEndDate,

        setOnlyMissingDetails,

        setStartDate,

        loadEmployees,
        initialized,
    };
};
