import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../../app/router/Routes";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { DocsRoutes } from "./DocsRoutes";
import { DocsListSite } from "../sites/DocsListSite";

export const DocsRouter = () => (
    <Switch>
        <Route exact path={DocsRoutes.ROOT}>
            <Redirect to={DocsRoutes.LIST} />
        </Route>
        <Route exact path={DocsRoutes.LIST}>
            <DocsListSite />
        </Route>
        <Route component={NotFoundSite} />
    </Switch>
);
