import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import { useContext, useEffect, useRef, useState } from "react";
import { BANKING_REFRESHING_CONNECTION_DATE, POPUP_WINDOW_FEATURES } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { BankConnection } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useExternalWindow } from "../../hooks/useExternalWindow";
import { CenteredContent } from "../../ui/CenteredContent";
import { EmptyState } from "../../ui/EmptyState";
import { SiteContent } from "../../ui/SiteContent";
import { MobileContext } from "../../util/MobileContext";
import { AccountingNavBar } from "../AccountingNavBar";
import { accountingStore } from "../AccountingStore";
import { BankConnectionListItem } from "../BankConnectionListItem";
import { useBankConnectionsPolling } from "../hooks/useBankConnectionsPolling";

export const BankConnectionSite = observer(function BankConnectionSite() {
    const isMobile = useContext(MobileContext);
    const [bankConnections, setBankConnections] = useState<BankConnection[]>();
    const externalWindow = useExternalWindow();

    const bankConnectionsHaveChanged = (currentBankConnections: BankConnection[]) => {
        if (currentBankConnections.length !== bankConnections?.length) {
            return true;
        }
        for (let i = 0; i < currentBankConnections.length; i++) {
            const currentBankConnection = currentBankConnections[i];
            const bankConnection = bankConnections[i];
            if (
                currentBankConnection.expiresAt !== bankConnection.expiresAt &&
                currentBankConnection.expiresAt?.toString() !== BANKING_REFRESHING_CONNECTION_DATE
            ) {
                return true;
            }
        }
        return false;
    };

    const { startPolling } = useBankConnectionsPolling(bankConnections => {
        setBankConnections(bankConnections);
    });
    const startPollingBankConnections = () => {
        startPolling((_, connections) => bankConnectionsHaveChanged(connections));
    };

    const { expired, expireDuringPeriod, upToDate, refreshPending } = companiesStore.getFilteredBankConnections(
        accountingStore.bankConnectionPeriodEnd,
    );

    const firstLoad = useRef<boolean>(true);
    useEffect(() => {
        if (firstLoad.current) {
            setBankConnections(companiesStore.bankConnections.connections);
            firstLoad.current = false;
        }
    }, []);

    const handleAddBankConnection = async () => {
        try {
            generalStore.isLoading = true;
            if (companiesStore.selectedCompanyId) {
                const response = await API.postBankConnections(companiesStore.selectedCompanyId);
                if (response.url) {
                    externalWindow.open(response.url, "TPA", POPUP_WINDOW_FEATURES);
                    startPollingBankConnections();
                }
            }
        } catch (e) {
            generalStore.setError(t("error.addBankConnection"), e);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const empty =
        expireDuringPeriod.length === 0 && upToDate.length === 0 && expired.length === 0 && refreshPending.length === 0;

    return (
        <>
            <AccountingNavBar
                // hide the period and subsidiary selectors because the bank connections and accounts are company wide
                showPeriods={false}
                showSubsidiaries={false}
            />
            {(!generalStore.isLoading || !firstLoad.current) && (
                <>
                    {empty ? (
                        <EmptyState
                            title={t("accounting.bankConnections.emptyState.title")}
                            message={t("accounting.bankConnections.emptyState.description")}
                            buttonLabel={t("accounting.bankConnections.addBankConnection")}
                            onAddEntry={handleAddBankConnection}
                            disabled={!authStore.canCreateBanking()}
                        />
                    ) : (
                        <CenteredContent>
                            <SiteContent>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: isMobile ? "start" : "center",
                                        flexDirection: isMobile ? "column" : "row",
                                    }}
                                >
                                    <h3>{t("common.bankConnections")}</h3>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!authStore.canCreateBanking()}
                                        onClick={handleAddBankConnection}
                                        style={{ marginTop: isMobile ? 16 : undefined }}
                                    >
                                        {t("accounting.bankConnections.addBankConnection")}
                                    </Button>
                                </div>
                                {expireDuringPeriod.length > 0 && (
                                    <>
                                        <h4
                                            style={{
                                                marginTop: 24,
                                                marginBottom: 16,
                                            }}
                                        >
                                            {t("accounting.bankConnections.pending")}
                                        </h4>
                                        {expireDuringPeriod.map(bankConnection => (
                                            <BankConnectionListItem
                                                key={bankConnection.bankConnectionId}
                                                bankConnection={bankConnection}
                                                expiresDuringPeriod
                                                onRefresh={() => {
                                                    startPollingBankConnections();
                                                }}
                                            />
                                        ))}
                                    </>
                                )}
                                {expired.length > 0 && (
                                    <>
                                        <h4
                                            style={{
                                                marginTop: 24,
                                                marginBottom: 16,
                                            }}
                                        >
                                            {t("accounting.bankConnections.expired")}
                                        </h4>
                                        {expired.map(bankConnection => (
                                            <BankConnectionListItem
                                                key={bankConnection.bankConnectionId}
                                                bankConnection={bankConnection}
                                                onRefresh={() => {
                                                    startPollingBankConnections();
                                                }}
                                                expired
                                            />
                                        ))}
                                    </>
                                )}
                                {refreshPending.length > 0 && (
                                    <>
                                        <h4
                                            style={{
                                                marginTop: 24,
                                                marginBottom: 16,
                                            }}
                                        >
                                            {t("accounting.bankConnections.refreshPending")}
                                        </h4>
                                        {refreshPending.map(bankConnection => (
                                            <BankConnectionListItem
                                                key={bankConnection.bankConnectionId}
                                                bankConnection={bankConnection}
                                                onRefresh={() => {
                                                    startPollingBankConnections();
                                                }}
                                                refreshPending
                                            />
                                        ))}
                                    </>
                                )}
                                {upToDate.length > 0 && (
                                    <>
                                        <h4
                                            style={{
                                                marginTop: 24,
                                                marginBottom: 16,
                                            }}
                                        >
                                            {t("accounting.bankConnections.upToDate")}
                                        </h4>
                                        {upToDate.map(bankConnection => (
                                            <BankConnectionListItem
                                                key={bankConnection.bankConnectionId}
                                                bankConnection={bankConnection}
                                                onRefresh={() => {
                                                    startPollingBankConnections();
                                                }}
                                            />
                                        ))}
                                    </>
                                )}
                            </SiteContent>
                        </CenteredContent>
                    )}
                </>
            )}
            {externalWindow.components}
        </>
    );
});
