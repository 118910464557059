import { Tooltip } from "@material-ui/core";
import { observer } from "mobx-react";
import moment from "moment";
import { useCallback } from "react";
import { Redirect } from "react-router";
import styled from "styled-components";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { CompanyFeature } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { APIResultSuccess, useAPI } from "../../hooks/useAPI";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { CenteredContent } from "../../ui/CenteredContent";
import { IOSSwitch } from "../../ui/IOSSwitch";
import { InfoButton } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { SettingsRoutes } from "../router/SettingsRoutes";

export const SettingsCompanyFeaturesSite = observer(function SettingsCompanyFeaturesSite() {
    const companyStore = companiesStore.selectedCompanyStore;
    if (!companyStore) {
        return null;
    }

    if (!companyStore.canReadFeatures) {
        return <Redirect to={SettingsRoutes.ROOT} />;
    }

    return <SettingsCompanyFeatures companyId={companyStore.id} />;
});

const SettingsCompanyFeatures = observer(function SettingsCompanyFeatures({ companyId }: { companyId: string }) {
    const loader = useCallback(async () => {
        const response = await API.getCompanyFeatures(companyId);
        return response.features;
    }, [companyId]);

    const result = useAPI(loader, {
        generalStoreErrorMessage: "error.loadCompanyFeatures",
    });

    return (
        <CenteredContent>
            <SiteContent style={{ paddingTop: 40 }}>
                {result.state === "success" && <Features companyId={companyId} result={result} />}
            </SiteContent>
        </CenteredContent>
    );
});

interface FeaturesProps {
    companyId: string;
    result: APIResultSuccess<CompanyFeature[]>;
}

const Features = observer(function Features({ companyId, result }: FeaturesProps) {
    const { data: features, update } = result;

    const confirmationDialog = useConfirmationDialog({
        title: "",
    });

    const handleToggle = async (feature: CompanyFeature) => {
        await confirmationDialog.open({
            title: feature.enabled
                ? t("config.features.confirm.title.enabled")
                : t("config.features.confirm.title.disabled"),
            message: feature.enabled
                ? t("config.features.confirm.message.enabled")
                : t("config.features.confirm.message.disabled"),
        });

        try {
            const result = await API.toggleCompanyFeature(companyId, feature.featureId);
            const companyFeature = result.feature;
            update?.(features => {
                return features.map(feature => {
                    if (feature.featureId === companyFeature.featureId) {
                        return companyFeature;
                    }
                    return feature;
                });
            });
        } catch (error) {
            generalStore.setError(t("error.toggleCompanyFeature"), error);
        }
    };

    return (
        <>
            {features.map(feature => (
                <Feature key={feature.featureId} feature={feature} onToggle={handleToggle} />
            ))}
            {confirmationDialog.dialog}
        </>
    );
});

const FeatureRoot = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: auto auto;
    gap: 0px 16px;
    grid-template-areas:
        "switch name"
        ". price-description";
    margin-bottom: 24px;

    .switch {
        grid-area: switch;
    }
    .name {
        grid-area: name;
        display: flex;
        align-items: center;
    }
    .price-description {
        grid-area: price-description;
    }
`;

interface FeatureProps {
    feature: CompanyFeature;
    onToggle: (feature: CompanyFeature) => void;
}

const Feature = observer(function Feature({ feature, onToggle }: FeatureProps) {
    const disabled = feature.updatedAt ? moment(feature.updatedAt).isSame(Date.now(), "day") : false;
    let disabledTitle = "";
    if (disabled) {
        disabledTitle = feature.enabled
            ? t("config.features.alreadyToggledToday.enabled")
            : t("config.features.alreadyToggledToday.disabled");
    }

    return (
        <FeatureRoot>
            <Tooltip title={disabledTitle}>
                <div className="switch">
                    <IOSSwitch
                        checked={feature.enabled}
                        onChange={() => {
                            onToggle(feature);
                        }}
                        disabled={disabled}
                    />
                </div>
            </Tooltip>
            <h4 className="name">
                {feature.name}
                <InfoButton title={feature.description} color="primary" style={{ padding: 0, marginLeft: 4 }} />
            </h4>
        </FeatureRoot>
    );
});
