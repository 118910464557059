const english = {
  "accounting.bankAccount.account.label": "Account",
  "accounting.bankAccount.account.placeholder": "Account",
  "accounting.bankAccount.account.title": "Select account",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "Document categories could not be loaded",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "Document category",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "Please select a start and end date.",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "Bank transactions",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "Document symbol",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "Receipts",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "No evidence found",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "Next document",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "Previous document",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "Please assign the receipt to a bank transaction.",
  "accounting.bankAccount.transactions.assignInvoices.title": "Assignment of vouchers",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "Assign open vouchers",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "Export for bank statement posting",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "Export for booking import in NTCS",
  "accounting.bankAccount.transactions.table.emptyState.title": "No transactions found",
  "accounting.bankAccount.transactions.table.label.amount": "Amount",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "Assign document",
  "accounting.bankAccount.transactions.table.label.date": "Booking date",
  "accounting.bankAccount.transactions.table.label.invoice": "Receipt",
  "accounting.bankAccount.transactions.table.label.isPrivate": "Private",
  "accounting.bankAccount.transactions.table.label.recipient": "Receiver",
  "accounting.bankAccount.transactions.table.label.text": "Booking text",
  "accounting.bankAccount.transactions.table.label.valueDate": "Value date",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "Remove document link",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "Mark as private transaction",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "Remove private marking",
  "accounting.bankAccount.transactions.table.search.count": "Transactions: <b>{count}</b>",
  "accounting.bankAccount.transactions.table.search.placeholder": "Search for transactions",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "Receipt is uploaded",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "Select company code and document symbol",
  "accounting.bankAccount.transactions.title": "Transactions",
  "accounting.bankConnection.delete.message": "Do you really want to delete the bank account {name}?",
  "accounting.bankConnection.delete.title": "Delete bank account",
  "accounting.bankConnections.accountHolder": "Owner:",
  "accounting.bankConnections.accountType": "Account type:",
  "accounting.bankConnections.accountType.Bausparen": "Building society savings",
  "accounting.bankConnections.accountType.Checking": "Current account",
  "accounting.bankConnections.accountType.CreditCard": "Credit card",
  "accounting.bankConnections.accountType.Loan": "Loan account",
  "accounting.bankConnections.accountType.Membership": "Cooperative shares",
  "accounting.bankConnections.accountType.Savings": "Savings account",
  "accounting.bankConnections.accountType.Security": "Securities account",
  "accounting.bankConnections.addBankConnection": "Add bank details",
  "accounting.bankConnections.balance": "Account balance:",
  "accounting.bankConnections.emptyState.description": "Add a new bank account.",
  "accounting.bankConnections.emptyState.title": "No bank details",
  "accounting.bankConnections.expired": "Expired",
  "accounting.bankConnections.expiredAt": "Expired on {date}",
  "accounting.bankConnections.expiresAt": "Expires on {date}",
  "accounting.bankConnections.iban": "IBAN:",
  "accounting.bankConnections.lastSuccessfulUpdate": "per {date}",
  "accounting.bankConnections.pending": "Outstanding",
  "accounting.bankConnections.refreshBankConnection": "Renew bank account",
  "accounting.bankConnections.refreshPending": "Ongoing renewals",
  "accounting.bankConnections.refreshPending.info": "The renewal of the bank details runs in a separate window. If the window was closed before the update was completed, you must click on \"Renew bank details\" again",
  "accounting.bankConnections.refreshPending.label": "Renewal process underway",
  "accounting.bankConnections.upToDate": "Acknowledged",
  "accounting.details.edit.header": "Edit document",
  "accounting.details.updatedAt": "last amended",
  "accounting.details.upload.files": "Files",
  "accounting.details.upload.skip": "Skip all",
  "accounting.finished.reports": "Reports",
  "accounting.table.label.totalGrossAmount": "Invoice amount",
  "addFolderDialog.button.text.create": "Create",
  "addFolderDialog.folderName": "Folder name",
  "addFolderDialog.info": "Go to Sharepoint to manage members.",
  "addFolderDialog.title": "Create folder",
  "advisor.uploads.uploadReports.title": "Transmission of reports",
  "analysis.powerBI.reports.table.label.description": "",
  "analysis.powerBI.reports.table.label.name": "",
  "analysis.powerBI.reports.table.search.count": "",
  "analysis.powerBI.reports.table.search.placeholder": "",
  "analysis.powerBI.reports.title": "",
  "analysis.powerBI.report.title": "",
  "appNotificationDialog.button.accept": "Understood",
  "appNotificationDialog.title.maintenance": "Maintenance work",
  "appNotificationDialog.title.newFeature": "New feature",
  "appNotificationDialog.title.update": "Aktualisierung / Update",
  "branchType.allocationGroup": "Payroll area",
  "branchType.costCenter": "Cost master",
  "branchType.department": "Department",
  "branchType.financialAccountancyCircle": "FIBU Circle",
  "branchType.other": "Other",
  "branchType.wageCompany": "Payroll company",
  "branchType.workplace": "Workplace",
  "button.activateMfa": "Activate now",
  "button.answer": "RESPOND",
  "button.archive": "Archive",
  "button.assign": "Assign",
  "button.back_to_login": "Back to the login",
  "button.closeTicket": "Mark as completed",
  "button.configure": "CONFIGURING",
  "button.continueInBackground": "Continue in the background",
  "button.continue_without_microsoft": "Continue without a Microsoft account",
  "button.copy": "Copy/Move",
  "button.deactivateMfa": "Deactivate now",
  "button.enter": "OPEN UP",
  "button.markAsPrivate": "Mark as confidential",
  "button.moreInfo": "MORE INFORMATION",
  "button.move": "Move here",
  "button.nextStep": "next step",
  "button.paste": "Paste here",
  "button.refresh": "Renew",
  "button.release": "Approve",
  "button.submitArchiveMultiple": "{count} Archive messages",
  "button.submitArchiveSingle": "{count} Archive message",
  "button.toOverview": "To the overview",
  "button.transfer": "Transfer",
  "button.unlock": "Unlock",
  "button.upload": "Upload file",
  "chatInput.deleteLink": "Remove",
  "chatInput.editLink": "Edit",
  "chatInput.enterLink": "Link",
  "chatInput.openLink": "Open URL",
  "chatInput.saveLink": "Save",
  "common.abacus": "Abacus",
  "common.accounting": "Accounting",
  "common.accounting.period": "Booking period",
  "common.accounting.recordType": "Document symbol",
  "common.accounting.subsidiary": "Accounting area",
  "common.add": "Add",
  "common.albanian": "Shqiptare",
  "common.all": "All",
  "common.amount": "Number",
  "common.anyDateRange": "Any period",
  "common.apply": "Apply all changes",
  "common.archive": "Archive",
  "common.back": "Back",
  "common.bankConnection": "Bank details",
  "common.bankConnections": "Bank details",
  "common.bankContact": "Bank details",
  "common.bic": "BIC",
  "common.bmd": "BMD",
  "common.bulgarian": "Български",
  "common.cancel": "Cancel",
  "common.cancelCaps": "CANCEL",
  "common.chat": "Chat",
  "common.clientNumber": "Customer number",
  "common.cockpit": "Cockpit",
  "common.companies": "Company",
  "common.company": "Company",
  "common.companyNumber": "Company number",
  "common.companyOverview": "Company overview",
  "common.companyRegisterNumber": "Company register number",
  "common.continue": "Continue",
  "common.country": "Country",
  "common.croatian": "Hrvatski",
  "common.czech": "Česky",
  "common.dateformat": "DD.MM.YYYY",
  "common.dateValidation": "Please enter a valid date",
  "common.defaultSignature": "Your TPA",
  "common.delete": "Delete",
  "common.done": "Completed",
  "common.download": "Download",
  "common.dueDate": "Deadline",
  "common.dueUntil": " by {dueDate} at the latest",
  "common.emailAddress": "E-mail address",
  "common.english": "English",
  "common.errorOccurred": "An error has occurred",
  "common.events": "Events",
  "common.files": "Files",
  "common.finished": "Completed",
  "common.folder": "Folder",
  "common.from": "From",
  "common.fullName": "{firstName} {lastName}",
  "common.generalData": "General data",
  "common.generalDocuments": "General files",
  "common.german": "Deutsch",
  "common.global": "Company-wide",
  "common.hr": "Payroll",
  "common.hr.period": "Accounting period",
  "common.hrPreRegistrations": "Personnel - preliminary entries",
  "common.hr.short": "Payroll",
  "common.hr.subsidiary": "Payroll area",
  "common.hungarian": "Magyar",
  "common.invite": "Invite",
  "common.kpi": "Facts & Figures",
  "common.kpi.long": "Facts and figures",
  "common.later": "Later",
  "common.logout": "Logout",
  "common.me": "Me",
  "common.members": "Members",
  "common.moreInformation": "learn more",
  "common.mr": "Mr {name}",
  "common.mrs": "Mrs {name}",
  "common.name": "Name",
  "common.no": "No",
  "common.none": "none",
  "common.noSearchResults": "No results found",
  "common.notAvailable": "N/A",
  "common.notSpecified": "n.d.",
  "common.numberValidation": "Please enter a valid number",
  "common.ok": "Okay",
  "common.optional": "(optional)",
  "common.orderBy": "Sort by",
  "common.partial": "partly",
  "common.people": "People",
  "common.polish": "Polski",
  "common.profile": "Profile",
  "common.project": "Project",
  "common.projects": "Projects",
  "common.readingTimeMinutes": "Reading time: {time} Min",
  "common.readingTimeMinutes.short": "{time} Min",
  "common.recipient": "Receiver",
  "common.refresh": "Refresh",
  "common.releasedAt": "released on {date}",
  "common.releasedAtBy": "approved on {date} by {name}",
  "common.releaseRequired.plural": "{count} Releases required",
  "common.releaseRequired.singular": "A release is required",
  "common.remainingItems": "+{count} more",
  "common.remainingItemsUnknown": "Load more",
  "common.retry": "Try again",
  "common.romanian": "Românesc",
  "common.russian": "Русский",
  "common.save": "Save",
  "common.sendCaps": "SEND",
  "common.serbian": "Српски",
  "common.settings": "Settings",
  "common.showAll": "Show all",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "Title",
  "common.taxNumber": "Tax number",
  "common.termsOfUse": "Terms of use",
  "common.ticket": "Inquiry",
  "common.timePeriod": "Period",
  "common.timePeriod.olderThan1Month": "Older than one month",
  "common.timePeriod.olderThan1Week": "Older than one week",
  "common.timePeriod.olderThan1Year": "Older than one year",
  "common.timePeriod.olderThan6Months": "Older than 6 months",
  "common.to": "To",
  "common.tpaFirstName": "TPA",
  "common.tpaLastName": "Employees",
  "common.unknown": "Unknown",
  "common.upcomingTermsOfUse": "Future terms of use",
  "common.upload": "Upload",
  "common.uploadFiles": "Upload files",
  "common.user": "Users",
  "common.vatNumber": "VAT number",
  "common.yes": "Yes",
  "commuterAllowance.IndividuellePendlerpauschale": "Individual commuter allowance",
  "commuterAllowance.KeinePendlerpauschale": "No commuter allowance",
  "commuterAllowance.MehrAls20kmKeinBus": "More than 20 km, no public transit",
  "commuterAllowance.MehrAls40kmBus": "More than 40 km, reasonable public tranist",
  "commuterAllowance.MehrAls40kmKeinBus": "More than 40 km, no public transit",
  "commuterAllowance.MehrAls60kmBus": "More than 60 km, reasonable public transit",
  "commuterAllowance.MehrAls60kmKeinBus": "More than 60 km, no public transit",
  "commuterAllowance.Mindestens20kmBus": "More than 20 km, reasonable public transit",
  "commuterAllowance.Mindestens2kmKeinBus": "More than 2 km, no public transit",
  "companiesUserReleaseInfoActionsBar.message": "You have {count} new user changes to approve",
  "companiesUserReleaseInfoActionsBar.submit": "View and share",
  "companiesUserRelease.permissions.navbar.back": "Share and invite users / assign role",
  "companiesUserRelease.title": "Share and invite users",
  "companyImages.cover.error": "Cover image could not be loaded",
  "companyImages.cover.title": "Cover image",
  "companyImages.delete.message": "Are you sure you want to delete this image?",
  "companyImages.delete.title": "Delete image",
  "companyImages.logo.error": "Company logo could not be loaded",
  "companyImages.logo.title": "Company logo",
  "companyImages.title": "Cover image and logo of your company (optional)",
  "companyList.remainingCompanies": "+{remainingCompanies} More",
  "company.optionalSideBarMenuItems.maxLength.name": "Name may only contain 15 characters",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "Optional menu item already available",
  "company.optionalSideBarMenuItems.name.label": "Name",
  "company.optionalSideBarMenuItems.required.name": "Name must be specified",
  "company.optionalSideBarMenuItems.required.url": "URL must be specified",
  "company.optionalSideBarMenuItems.title": "Optional menu items of the company",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "Remove menu item",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "Link for optional menu item is already present",
  "company.optionalSideBarMenuItems.url.label": "URL",
  "company.optionalSideBarMenuItems.urlNotValid": "Please enter a valid URL (example: https://www.tpa-connect.app )",
  "companyOverview.emptyState.message": "Please contact your advisor",
  "companyOverview.emptyState.title": "No active company",
  "companyOverview.emptyState.tpa.message": "You currently have no active customers",
  "companyOverview.emptyState.tpa.title": "No active customers",
  "companyOverview.unlockCountry.tooltip": "Unlock new countries<br /><br /> Unlock international projects from <b>{country}</b> to use this company's projects feature and view all queries.",
  "companyStatus.accountingSetupDone": "0/2 completed",
  "companyStatus.active": "active",
  "companyStatus.hrSetupDone": "1/2 completed",
  "companyStatus.inactive": "inactive",
  "companyStatus.userSetupDone": "2/2 completed",
  "config.accounting.accountingByCustomer": "Transmission of accounting data via TPA Connect by customers",
  "config.accounting.delete.subsidiaries.last.message": "Accounting area {name} cannot be deleted. At least one company code must exist.",
  "config.accounting.delete.subsidiaries.message": "Do you really want to delete accounting area {name}?",
  "config.accounting.delete.subsidiaries.title": "Delete accounting area",
  "config.accounting.description": "Define the nature and scope of the accounting area.",
  "config.accounting.description.customer": "Your TPA advisor has configured this area for you. If you wish to make any changes, please contact your TPA advisor at any time.",
  "config.accounting.due.accounting.reports.select.title": "Days after transmission of the accounts",
  "config.accounting.dueDates.title": "Document transmission of the accounting",
  "config.accounting.due.reportRelease.select.subtitle": "For example, 4 days after the upload of the reports by TPA. This means on the 12th of the following month (if the upload of the reports by TPA took place on the 8th of the following month).",
  "config.accounting.due.reportRelease.select.title": "Days after upload of the reports",
  "config.accounting.due.reportRelease.select.value": "{day} days after upload of the reports",
  "config.accounting.due.reportRelease.title": "Approval of reports by customers",
  "config.accounting.due.reports.select.subtitle": "For example, 3 days after the transmission of accounting. That is, on the 8th of the following month (if the transmission of accounting took place on the 5th of the following month).",
  "config.accounting.due.reports.select.value": "{day} Days after transmission of the accounts",
  "config.accounting.due.reports.title": "Transmission of reports by TPA",
  "config.accounting.due.transfer.select.subtitle": "For example, on the 5th of the following month.",
  "config.accounting.due.transfer.select.title": "Transmission until",
  "config.accounting.due.transfer.select.value": "{day} Days after end of period",
  "config.accounting.due.transfer.title": "Transmission of accounting by customers",
  "config.accounting.globalReports.description": "Customer has company-wide reports",
  "config.accounting.globalReports.title": "Company-wide reports",
  "config.accounting.hasAccounting": "Customer has accounting",
  "config.accounting.hasBankAccountTransactionInvoices": "Assign documents to bank transactions",
  "config.accounting.hasCockpitKpi": "Results in the Cockpit",
  "config.accounting.hasKpi": "Facts & Figures",
  "config.accounting.hasResults": "Results",
  "config.accounting.incaseoflaw.description": "Customer has feature 'In Case of Law'",
  "config.accounting.incaseoflaw.label": "In Case of Law",
  "config.accounting.incaseoflaw.title": "In Case of Law",
  "config.accounting.month.description": "Includes a duration of one month",
  "config.accounting.month.title": "Monthly accounting",
  "config.accounting.quarter.description": "Includes a duration of 3 months",
  "config.accounting.quarter.title": "Accounting per quarter",
  "config.accounting.recordTypes": "accounting documents",
  "config.accounting.recordTypes.ocrDisabled": "Automatic document recognition (OCR) deactivated",
  "config.accounting.recordTypes.ocrEnabled": "Automatic document recognition (OCR) activated",
  "config.accounting.requireTwoReportReleases": "Approval of reports from two users required (four-eye principle)",
  "config.accounting.subsidiaries.add": "Add accounting area",
  "config.accounting.subsidiaries.title": "Define accounting areas",
  "config.accounting.subsidiaries.title.customer": "Accounting areas",
  "config.accounting.subsidiary.maxReached": "Maximum number of accounting areas reached",
  "config.accounting.subsidiary.name": "Accounting area name",
  "config.accounting.week.description": "Comprises a duration of 5 working days and is divided into calendar weeks",
  "config.accounting.week.title": "Weekly accounting",
  "config.bulk.button.configure": "Configure",
  "config.bulk.data.description": "You can upload a cover image and the logo of the companies here.",
  "config.bulk.data.title": "Master data",
  "config.bulk.finish.description": "The companies are being configured. This may take a few minutes, please do not close the browser window.",
  "config.bulk.finish.start": "Start",
  "config.bulk.finish.title": "Finalize configuration",
  "config.companies": "Company Configuration",
  "config.companies.buttonConfig": "Configure company",
  "config.companies.count": "{count} companies",
  "config.companies.description": "In this step you define processes that are specific to the company. These include the visual appearance in TPA Connect, the type and scope of accounting and payroll as well as deadlines.",
  "config.companyData.cockpit": "Cockpit",
  "config.companyData.description": "Here you can upload a cover image and the logo of the company and define the booking criteria.",
  "config.companyData.title": "Master data",
  "config.companyOverview.inactive": "Company overview / Inactive",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "Yes, delete",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "Do you really want to delete this menu item?",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "Delete menu item",
  "config.customMenuPoints.noMenuPointsAvailable": "No menu items available",
  "config.employees.assignRole": "Assign role",
  "config.employees.emptyState.message": "There are currently no active employees",
  "config.employees.emptyState.title": "No active employees",
  "config.employees.table.missingEmail": "E-mail address missing",
  "config.employees.table.missingEmail.tooltip": "E-mail address must be entered by a TPA consultant in BMD",
  "config.features.alreadyToggledToday.disabled": "The add-on has already been deactivated today",
  "config.features.alreadyToggledToday.enabled": "The add-on has already been activated today",
  "config.features.confirm.message.disabled": "If you activate the add-on, the paid add-on will be available immediately and you will be billed from today. You can deactivate the add-on again tomorrow.",
  "config.features.confirm.message.enabled": "The chargeable add-on will be deactivated at the end of the day. You will still be charged for today. You can reactivate the add-on from tomorrow.",
  "config.features.confirm.title.disabled": "Activate add-on",
  "config.features.confirm.title.enabled": "Deactivate add-on",
  "config.finishConfiguration": "Finalize configuration",
  "config.finishConfiguration.cockpit": "To the cockpit",
  "config.finishConfiguration.continue": "Create another company",
  "config.finishConfiguration.emailNotification": "Notify users by e-mail",
  "config.finishConfiguration.message": "The company {company} has been configured successfully. It can now be used for the exchange between TPA and client.",
  "config.finishSetup": "EXIT SETUP",
  "config.hr.chat.description": "Payroll employees may contact TPA consultants",
  "config.hr.chat.title": "Chat",
  "config.hr.delete.subsidiaries.last.message": "Payroll area {name} cannot be deleted. At least one payroll area must exist.",
  "config.hr.delete.subsidiaries.message": "Do you really want to delete the payroll area {name}?",
  "config.hr.delete.subsidiaries.title": "Delete payroll area",
  "config.hr.description": "Define the nature and scope of the payroll area.",
  "config.hr.dueDates.reminder": "Reminder of deadlines",
  "config.hr.dueDates.title": "Deadlines for payroll accounting",
  "config.hr.due.relativeDueDates": "Allow delivery of documents up to and including the 15th of the following month",
  "config.hr.due.reports.select.subtitle": "For example, 3 days after the transmission of the payroll. This means on the 8th of the following month (if the transfer of payroll accounting took place on the 5th of the following month).",
  "config.hr.due.reports.select.title": "Days after transfer of payroll",
  "config.hr.due.reports.select.value": "{day} Days after transmission of the payroll accounting",
  "config.hr.due.select.value": "{day}. of the month",
  "config.hr.due.transfer.select.subtitle": "For example, on the 5th of the month.",
  "config.hr.due.transfer.select.value.relativeDueDates": "{day} Days after end of month",
  "config.hr.due.transfer.title": "Transmission of payroll accounting by customers",
  "config.hr.hasHr": "Customer has payroll accounting",
  "config.hr.hasResults": "Evaluations",
  "config.hr.recordTypes": "Payroll documents",
  "config.hr.releaseSteps.oneStep.description": "All monthly evaluations are sent from TPA to the customer in one step. The customer must release all necessary documents by a defined deadline.",
  "config.hr.releaseSteps.oneStep.title": "in one step",
  "config.hr.releaseSteps.title": "Steps of the approval",
  "config.hr.releaseSteps.twoSteps.description": "The upload and approval of the monthly evaluation takes place in two steps. The customer can release settlements before approving all further monthly evaluations.",
  "config.hr.releaseSteps.twoSteps.title": "in two stages",
  "config.hr.requireTwoReportReleases": "Approval of reports from two users required (four-eye principle)",
  "config.hr.sendPayroll.description": "Submitting earnings records electronically",
  "config.hr.sendPayroll.title": "Electronic earnings record",
  "config.hr.sendPayroll.tooltip": "By issuing a payslip, each employee automatically receives access to the TPA Connect app, which can be used to view their own electronic payslips as well as their personnel file.",
  "config.hr.subsidiaries.add": "Add payroll area",
  "config.hr.subsidiaries.info": "After selecting the first payroll area, you can only select others of the same type (e.g. cost master). To select a new type (e.g. FIBU area), all but one payroll area must be deleted.",
  "config.hr.subsidiaries.title": "Define payroll areas",
  "config.hr.subsidiaries.title.customer": "Payroll areas",
  "config.hr.subsidiary.maxReached": "Maximum number of payroll areas reached",
  "config.hr.subsidiary.name": "Name of the payroll area",
  "config.hr.upload.description": "Payroll employees may upload documents to their personnel file",
  "config.hr.upload.title": "Upload documents to personnel file",
  "config.hr.warning.noBranch": "Payroll cannot be activated. Please create a payroll company in the NTCS",
  "config.moduleTypeDetails.asp.helpInstructions": "Instructions ASP customers",
  "config.moduleTypeDetails.asp.helpInstructions.open": "To the instructions",
  "config.moduleTypeDetails.label.database": "Database",
  "config.moduleTypeDetails.label.dataSource": "Data source",
  "config.moduleTypeDetails.label.url": "URL",
  "config.noDueDate": "no deadline",
  "config.optionalMenuPoints.success.title": "Menu items successfully saved",
  "config.overview": "Configuration overview",
  "config.permission.change": "changed to \"{permission}\"",
  "config.permissions.accounting.globalReports": "Company-wide accounting reports",
  "config.permissions.deleted": "(deleted)",
  "config.permissions.description": "Select the areas the user is allowed to access. You can configure different settings for each accounting area.",
  "config.permissions.employees": "Employees",
  "config.permissions.existingUser.navbar.back": "Configuration overview / User administration / Assign role",
  "config.permissions.existingUser.navbar.backToUser": "Configuration overview / User administration / Assign role / {employeeName}",
  "config.permissions.global": "Company-wide",
  "config.permissions.internationalProjects.info": "Project permissions cannot be disabled: Users with international projects have them enabled automatically.",
  "config.permissions.navbar.back": "Configuration overview / User administration / Select contact person / Assign role",
  "config.permissions.navbar.backToUser": "Configuration overview / User administration / Select contact person / Assign role / {employeeName}",
  "config.permissions.noPermissionSelected.message": "Choose at least one permission.",
  "config.permissions.noPermissionSelected.title": "No permissions selected",
  "config.permissions.title": "Customize permissions",
  "config.roles.change": "Role changed from {oldRole} to {newRole}",
  "config.roles.customizePermissions": "Customize permissions",
  "config.roles.description": "Choose a role with a predefined set of authorizations. If necessary, you can adjust the authorizations in a further step.",
  "config.roles.multipleCompaniesDialog.message": "The user {name} exists in the following companies. Do you want to make the permission changes in all companies?",
  "config.roles.multipleCompaniesDialog.title": "Apply changes in multiple companies",
  "config.roles.multipleCompaniesDialog.warning": "Company could not be loaded. The changes are applied to the current company only.",
  "config.roles.navbar.back": "Configuration overview / User administration / Select contact person",
  "config.roles.navbar.heading": "{employeeCount} User selected",
  "config.roles.option.copy.description": "Enter the name of the user to adopt their role and permissions. You can adjust the permissions in the next step if necessary.",
  "config.roles.option.copy.label": "Take the role from another user",
  "config.roles.option.copy.placeholder": "Name of the user",
  "config.roles.releaseInformation": "In order to edit the user settings of <b>{userName}</b>, a <b>Super Admin</b> must approve/reject the changes.",
  "config.roles.releaseInformation.info.message": "If anyone (other than the super admin) makes changes to user settings, those changes must be approved or denied by a super admin. Until then, the user's settings are locked and cannot be edited.",
  "config.roles.releaseInformation.info.title": "Why can't the user settings be edited?",
  "config.roles.releaseInformation.superAdmin": "{name} has made changes to <b>{userName}</b>. Please approve the user's changes to continue.",
  "config.roles.sidebar.title": "Selected users",
  "config.roles.submit": "Suggest user",
  "config.roles.submit.existingUser": "propose changes",
  "config.roles.submit.superAdmin": "Invite users",
  "config.roles.title": "Assign role",
  "config.roles.viewPermissions": "View permissions ",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "OK, save",
  "config.terms.allSigneesAcceptedWarningDialog.message": "Attention: Since all added persons have already signed, the company will be activated after saving.",
  "config.terms.allSigneesAcceptedWarningDialog.title": "Save terms of use",
  "config.terms.alreadySigned": "The terms of use of their company have already been signed.",
  "config.terms.button.revokeActivation": "Revoke activation",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "Yes, change document",
  "config.terms.changeDocumentVersionDialog.message": "If you continue, all email addresses already added will have to accept the Terms of Use again.",
  "config.terms.changeDocumentVersionDialog.title": "Change document",
  "config.terms.deleteEmailDialog.confirmLabel": "Yes, delete email address",
  "config.terms.deleteEmailDialog.message": "Do you really want to delete the email address?",
  "config.terms.deleteEmailDialog.title": "Delete email address",
  "config.terms.documentVersion": "Document version",
  "config.terms.emailAddressesInfo": "Add the e-mail addresses of the desired persons who are to be asked by e-mail to sign the respective terms of use. Only add the e-mail address of authorized signatories! Terms of use are only sent to the e-mail addresses below when the company and user configuration is completed.",
  "config.terms.emailAddressesTitle": "E-mail addresses",
  "config.terms.emailAlreadyUsed": "This email address has already been added",
  "config.terms.invalidEmail": "Invalid email address",
  "config.terms.noDocumentsAvailable": "Currently there are no documents available",
  "config.terms.noSigneesAvailable": "There are no signatories yet",
  "config.termsOfUse.navbar.back": "Configuration overview / master data",
  "config.terms.removeEmail": "Delete email address",
  "config.terms.resendEmail.header": "Email sent",
  "config.terms.resendEmail.message": "Email to accept the terms of use was sent again",
  "config.terms.resendMail": "Resend email",
  "config.terms.revokeActivationDialog.confirmLabel": "yes, activation revoked",
  "config.terms.revokeActivationDialog.message": "Are you sure that the activation of the company should be revoked? All registered persons have to sign the terms of use again to unlock the company again.",
  "config.terms.revokeActivationDialog.title": "Revoke activation",
  "config.terms.signedVersion": "signed version:",
  "config.terms.skip.button": "Skip",
  "config.terms.skip.dialog.message": "Customer-side users can only log in once the terms of use have been accepted. Until then, the company is only available for TPA internal purposes in the Connect app.",
  "config.terms.skip.dialog.title": "Information",
  "config.terms.success.header": "Successfully saved",
  "config.terms.termsOfUseInfo": "Select the appropriate document version",
  "config.terms.termsOfUseTitle": "Terms of use",
  "config.users": "User management",
  "config.users.active.title": "Active users",
  "config.users.assignRole": "Assign role",
  "config.users.buttonConfig": "Manage users",
  "config.users.changeUsername": "New email",
  "config.users.changeUsername.tooltip": "New e-mail address of the user is {newUsername}. By clicking the button the user will be asked to confirm the new email address.",
  "config.users.description": "Assign users or user groups to individual processes. You define who has access to which areas and to what extent.",
  "config.users.edit": "Change",
  "config.users.emptyState.message": "There are currently no active users<br/>To create a Super Admin, please use the BMD NTCS.",
  "config.users.emptyState.title": "No active users",
  "config.users.inviteAgain": "Invite again",
  "config.users.navBar.back": "Configuration overview / user administration",
  "config.users.new": "New",
  "config.users.newUsers": "Create new user",
  "config.users.newUsers.description": "Here, select the contact persons you want to create as TPA Connect users. In the next step, you can assign a role to the selected persons and, if necessary, adjust the predefined permissions.",
  "config.users.newUsers.title": "Select contact person",
  "config.users.reject": "Decline",
  "config.users.release": "Approve",
  "config.users.releaseAndInvite": "Approve & Invite",
  "config.users.table.invalidEmail": "E-mail address invalid",
  "config.users.table.invalidEmail.tooltip": "E-mail address must be corrected by a TPA consultant in BMD.",
  "config.users.waiting.title": "Waiting for approval",
  "cookies.acceptAll": "Accept all cookies",
  "cookies.acceptFunctional": "Only use required cookies",
  "cookies.cookieList": "cookie description",
  "cookies.text": "We use cookies to optimize our service. You can find more details in our {link}.",
  "country.al": "Albania",
  "country.at": "Austria",
  "country.bg": "Bulgaria",
  "country.cz": "Czechia",
  "country.hr": "Croatia",
  "country.hu": "Hungary",
  "country.me": "Montenegro",
  "country.pl": "Poland",
  "country.ro": "Romania",
  "country.rs": "Serbia",
  "country.si": "Slovenia",
  "country.sk": "Slovakia",
  "deleteDialog.message.plural": "Do you really want to delete the documents <b>{documents}</b>?<br/><br/>This action cannot be undone.",
  "deleteDialog.message.singular": "Do you really want to delete the document <b>{documents}</b>?<br/><br/>This action cannot be undone.",
  "deleteDialog.title": "Delete documents?",
  "deleteUser.message": "Are you sure you want to delete the user <b>{name}</b>?",
  "deleteUser.multipleCompanies.confirmLabel": "Delete the user in all companies",
  "deleteUser.multipleCompanies.message": "The user is present to the following companies:",
  "deleteUser.title": "Delete user",
  "deleteUser.warning.superadminskipped": "Attention! Companies in which users have the Superadmin role are skipped when deleting.",
  "dialog.archive.checkbox_pdf": "Save PDF locally",
  "dialog.archive.subject": "Subject",
  "dialog.archive.success.text": "You can now access the archived messages in the {bmd}.",
  "dialog.archive.success.title": "Messages successfully archived!",
  "dialog.archive.text": "Select a company for which you want to store the selected messages in the BMD.",
  "dialog.archive.title_multiple": "{count} Archive messages",
  "dialog.archive.title_single": "1 Archive message",
  "dialog.assignTicket.header": "Assign inquiry",
  "dialog.assignTicket.message": "Select a person to whom you want to assign the inquiry.",
  "dialog.button.understood": "Roger",
  "dialog.closeTicket.success.header": "Inquiry completed",
  "dialog.companyConfig.success.header": "Company configuration successfully saved.",
  "dialog.companyConfig.success.message": "The scopes for {name} are now ready.",
  "dialog.eldaTransfer.andNext": "and continue",
  "dialog.eldaTransfer.downloadLater": "Download later",
  "dialog.eldaTransfer.success.header": "Application successfully submitted",
  "dialog.loginError.multiFactorCodeWrong.title": "The entered code is wrong",
  "dialog.loginError.subtitle": "Please try again.",
  "dialog.loginError.title": "Unknown e-mail address or wrong password",
  "dialog.noPhoneNumberWarning.message": "At least one of the selected contact persons does not have a set phone number. Without a phone number, two-factor authentication is not available and an invitation may be insecure.",
  "dialog.noPhoneNumberWarning.title": "No phone number set",
  "dialog.passwordExpired.message": "Your password has expired and needs to be changed. You will shortly receive an e-mail to change your password. If you have any questions, please contact TPA at {telephoneNumber}. Thank you very much!",
  "dialog.passwordExpired.title": "Password expired",
  "dialog.password_reset.success.header": "Password successfully changed",
  "dialog.registration.success.button": "Log in now",
  "dialog.registration.success.header": "You have successfully registered!",
  "dialog.registration.success.message": "Log in with Microsoft to use TPA Connect.",
  "dialog.transferReports.success.message": "Your customer will now view the files and contact you in case of any questions.",
  "dialog.transfer.success.header": "Files successfully transmitted",
  "dialog.userConfig.success.header": "User settings saved",
  "dialog.user_not_found.button": "Roger",
  "dialog.user_not_found.subtitle": "Contact your System Admin or log in with your TPA Connect account.",
  "dialog.user_not_found.title": "no user account with this e-mail address known",
  "documentUpload.error.amount": "<b>{failedUploads}</b> of {uploads} files could not be uploaded:",
  "documentUpload.error.button.text.continue": "Continue without these files",
  "documentUpload.error.button.text.retry": "Try again",
  "documentUpload.error.message": "Error during file upload",
  "documentUpload.error.rejection.amount": "<b>{failedUploads}</b> of {uploads} files cannot be uploaded:",
  "documentUpload.error.tooBigImages.amount": "<b>{failedUploads}</b> Images are larger than max {maxImageDimension} pixels. They will be automatically resized. Continue?",
  "documentUpload.error.tooBigImages.amount.singular": "The image is larger than max {maxImageDimension} pixels. It will be automatically resized. Continue?",
  "documentUpload.error.tooBigImages.button.text.continue": "Zoom out and continue",
  "documentUpload.error.tooBigImages.pdf": "Images in the PDF are larger than max {maxImageDimension} pixels. Please reduce the size of the images.",
  "documentUpload.error.tooManyFiles.plural": "A maximum of <b>{count}</b> files can be uploaded:",
  "documentUpload.error.tooManyFiles.singular": "A maximum of <b>one</b> file can be uploaded:",
  "documentUpload.max.size": "Maximum document size {maxUploadSizeMb} Mb",
  "documentUpload.tooBigImages.message": "Pictures too big",
  "documentUpload.type.file.formats": "Supported formats",
  "dropzone.button.label.search": "search for it.",
  "dropzone.image.text": "Simply drag the image into this area or",
  "dropzone.info.redo": "re-upload",
  "dropzone.info.text": "Simply drag and drop the files into this area or",
  "dueDate.answerAt": "to be answered by {date}",
  "dueDate.closedAt": "completed on {date}",
  "dueDate.sentAt": "sent on {date}",
  "dueDate.sentAtWithTime": "sent on {date} at {time}",
  "dueDate.transferredAt": "transmitted on {date}",
  "dueDate.until": "by {date}",
  "employmentInsuranceType.apprentice": "Apprentice",
  "employmentInsuranceType.fullTime": "fully insured",
  "employmentInsuranceType.independentContractor": "independent contractor",
  "employmentInsuranceType.marginalIndependentContractor": "marginally independent contractor",
  "employmentInsuranceType.marginally": "marginally",
  "employmentRelationship.employee": "Employee",
  "employmentRelationship.worker": "Workers",
  "error.accounting.postSubsidiary": "Accounting area could not be created",
  "error.accounting.subsidiaryDoesNotExist": "Accounting area not found",
  "error.activate": "Activation error",
  "error.addBankAccountTransactionInvoices": "Transaction and documents could not be linked",
  "error.addBankConnection": "Bank details could not be added",
  "error.addCompanyToUserFavorites": "Company could not be added to your favorites",
  "error.addFolderBadRequest": "Folder could not be created. Make sure that you have not used any unauthorized special characters: \" * : < > ? / \\ |",
  "error.archiveDisabledForCompany": "Archiving in the context of this customer is not possible",
  "error.archiveFailed": "error in archiving",
  "error.archiveProjectFailed": "Could not archive project",
  "error.avatarUploadFailed": "The profile picture could not be uploaded. Make sure the format is JPEG or PNG & the file size is {maxSize}MB max.",
  "error.avatarUploadOnlyOneImage": "You can only upload one image",
  "error.changeUsernameEmail": "Email to change the username could not be sent",
  "error.changeUsername.newUsernameConflict": "New email does not match the one registered.",
  "error.changeUsername.newUsernameDuplicate": "Email already in use.",
  "error.changeUsername.noNewUsername": "No new email registered.",
  "error.changeUsername.tokenExpired": "This link has expired.",
  "error.changeUsername.tokenNotFound": "Invalid link. Did you already confirm your new email address?",
  "error.chatForbidden": "Face to Face is not enabled for you at this company.",
  "error.chatNoCompany": "Message without company context.",
  "error.chatNoRecipient": "Message has no recipient.",
  "error.chatSearch": "Search failed. Please try again.",
  "error.completeSign": "Electronic signature could not be completed",
  "error.config.accounting.duplicate": "You have specified several accounting areas with the same name",
  "error.config.accounting.noSubsidiary": "Please define at least one accounting area to continue.",
  "error.config.activateCompanyFailed": "This customer has not yet been technically prepared for the use of TPA Connect. Please contact IT support to have an appropriate user created.",
  "error.config.corruptSubsidiary": "Accounting area without ID",
  "error.config.hr.duplicate": "You have specified several payroll areas with the same name",
  "error.config.hr.noSubsidiary": "Please define at least one payroll area to continue.",
  "error.copy.alreadyExists.file": "Error when copying: A file with this name already exists",
  "error.copy.alreadyExists.folder": "Error when copying: A folder with this name already exists",
  "error.copy.alreadyExists.unknown": "Error when copying: A folder or file with this name already exists",
  "error.create": "Error when creating",
  "error.create.alreadyExists.project": "A project with this name already exists",
  "error.createEmployee": "Employee could not be created",
  "error.delete": "Errors during deletion",
  "error.deleteBankAccountTransactionInvoices": "Link could not be deleted",
  "error.deleteBankConnection": "Bank account could not be deleted",
  "error.delete.fileLocked": "The file is currently being edited and therefore cannot be deleted.",
  "error.deleteUser": "User could not be deleted. Please try again at a later time.",
  "error.deregisterEmployee": "Employee could not be logged off",
  "error.documentForbidden": "You do not have access to the document",
  "error.documentInProgress": "Document is being transferred to TPA and cannot be downloaded. Try again shortly.",
  "error.documentNotFound": "Document not found",
  "error.documentsInProgress": "Documents are being transferred to TPA and cannot be downloaded. Try again shortly.",
  "error.download": "Error during download",
  "error.downloadTermsOfUseDocument": "An error occurred while downloading the terms of use",
  "error.download.transferInProgress": "Document is being transferred. Please try again a little later.",
  "error.dueDate.minDate": "The date for the deadline must not be in the past",
  "error.duplicateName": "Name already assigned",
  "error.duplicateUsername": "The newly assigned e-mail address is already in use",
  "error.edit": "editing errors",
  "error.employeeWithoutEmail": "Employee does not have a valid e-mail address",
  "error.folderIsEmpty": "The folder is empty and can therefore not be downloaded",
  "error.foldersAreEmpty": "All selected folders are empty and therefore cannot be downloaded",
  "error.forbidden": "No permission",
  "error.general": "Sorry, an error has occurred. Please try again later.",
  "error.getSign": "An error occurred while loading the required information for the electronic signature",
  "error.hr.noSubsidiarySelected": "No payroll area selected",
  "error.hr.postSubsidiary": "Payroll area could not be created",
  "error.hr.subsidiaryDoesNotExist": "Payroll area not found",
  "error.imageCrop": "Errors in image processing",
  "error.imageTooBig": "Image too large",
  "error.invalidCharacters": "Input contains invalid characters",
  "error.invalidCompanyId": "Invalid company ID",
  "error.invalid_credentials": "Incorrect user ID or password",
  "error.invalidDateFormat": "Invalid date format",
  "error.invalidEmployee": "Data of employee incomplete or incorrect",
  "error.invalidPeriodId": "Invalid period for this company",
  "error.invalidRolePermissions": "Role permissions are invalid",
  "error.invalidSsn": "Invalid social security number",
  "error.invalidTicket": "Invalid ticket",
  "error.invalidWebhookParams": "Invalid webhook parameters.",
  "error.invitationNoCompany": "Invitation without company ID",
  "error.linkExpired": "This link has expired. Please request a new one.",
  "error.linkInvalid": "This link is invalid.",
  "error.loadAccount": "Account could not be loaded",
  "error.loadAccountListing": "Balance list could not be loaded",
  "error.loadAccounts": "Accounts could not be loaded",
  "error.loadAccountTransactions": "Bookings could not be loaded",
  "error.loadArchiveDocuments": "Not all archive documents could be loaded",
  "error.loadBadges": "Error loading notifications",
  "error.loadBalanceSheet": "Balance could not be loaded",
  "error.loadBankAccountTransactions": "Transactions could not be loaded",
  "error.loadBankConnections": "Bank connections could not be loaded",
  "error.loadBranches": "Branches could not be loaded",
  "error.loadCashAccounting": "Cash income statement could not be loaded",
  "error.loadCompany": "Company could not be loaded",
  "error.loadCompanyFeatures": "Add-ons could not be loaded",
  "error.loadContacts": "Contacts could not be loaded",
  "error.loadCostCenters": "Cost centers could not be loaded",
  "error.loadCountries": "Country data could not be loaded",
  "error.loadCustomerListing": "Debtor list could not be loaded",
  "error.loadDestinations": "Belegsstandorte could not be loaded. Please try again later",
  "error.loadDocument": "Document could not be loaded",
  "error.loadDocuments": "Documents could not be loaded",
  "error.loadDraft": "Approval could not be loaded",
  "error.loadEmployee": "Employee could not be loaded",
  "error.loadEmployeeDocuments": "Files could not be loaded",
  "error.loadEmployees": "Employees could not be invited",
  "error.loadEvents": "Events could not be loaded, try again later",
  "error.loadFeatureFlags": "Feature flags could not be loaded",
  "error.loadFeedCountries": "Error loading countries",
  "error.loadFinancialAccountancies": "",
  "error.loadFormData": "Form data could not be loaded",
  "error.loadFunders": "Cost objects could not be loaded",
  "error.loadGeneralDocumentsTimeout": "The reports are temporarily unavailable. Please try again later.",
  "error.loading": "error in loading",
  "error.loadInsuranceCarrierBalance": "Error when loading the health insurance balance",
  "error.loadInsuranceCarriers": "Health insurance loading error",
  "error.loadInvitationResults": "Error loading released user changes",
  "error.loadKpiConfigurations": "Facts and figures Configuration could not be loaded",
  "error.loadKpis": "Facts and figures could not be loaded, try again later",
  "error.loadMessages": "Messages could not be loaded",
  "error.loadModuleTypeDetails": "Error loading the module details",
  "error.loadNews": "Messages could not be loaded, try again later",
  "error.loadNews.noSource": "The news feed is currently unavailable.",
  "error.loadNotifications": "Notification settings could not be loaded",
  "error.loadOptionalMenuItems": "Optional menu items could not be loaded",
  "error.loadPayrollAccount": "Payroll account could not be loaded",
  "error.loadPayrollAccountDetails": "Payroll account details could not be loaded",
  "error.loadPendingMicrosoftActions": "Invitations & refresh information could not be loaded",
  "error.loadPeriod": "Error when loading the period",
  "error.loadPeriodData": "Error when loading the period data",
  "error.loadPermissions": "Permissions could not be loaded",
  "error.loadPersonnelFile": "Personnel file could not be loaded",
  "error.loadPersonnelFile.notStaff": "User is not a wage earner",
  "error.loadPowerBIReportEmbedToken": "",
  "error.loadPowerBIReports": "",
  "error.loadPreRegistration": "Pre-recording could not be loaded.",
  "error.loadProfile": "Profile could not be loaded",
  "error.loadProfitAndLoss": "P&L could not be loaded",
  "error.loadProjects": "Projects could not be loaded",
  "error.loadProjectsCompanies": "Companies for projects could not be loaded",
  "error.loadPromotions": "Articles could not be loaded, try again later",
  "error.loadPublications": "Publications could not be loaded, try again later",
  "error.loadRecord": "Document could not be loaded",
  "error.loadRecords": "Documents could not be loaded",
  "error.loadRecordTypes": "Error when loading the posting categories",
  "error.loadReports": "Reports could not be loaded",
  "error.loadResponsibleUsers": "Consultants could not be loaded",
  "error.loadRolePermissions": "Error loading role permissions",
  "error.loadSubsidiaries": "Error when loading accounting areas",
  "error.loadSubsidiary": "Error when loading the accounting area",
  "error.loadTeam": "Team members could not be loaded",
  "error.loadTermsofUse": "An error occurred while loading the terms of use",
  "error.loadTicketDetails": "Inquiry could not be loaded",
  "error.loadTickets": "Inquiries could not be loaded",
  "error.loadUserCompanySettings": "Error loading user settings",
  "error.loadUsers": "Users could not be loaded",
  "error.loadVendorListing": "The vendor list could not be loaded",
  "error.loadWaitingUsers": "Error loading users to be released",
  "error.loginAccountSetup": "Here for the first time? We are preparing your user account. Please try again in a few minutes.",
  "error.logout": "Logoff error",
  "error.markAsDone": "You can only mark inquiries as completed that you have created",
  "error.markBankAccountTransactionAsPrivate": "Transaction could not be marked as private",
  "error.maxCharacters": "Maximum {count} characters allowed",
  "error.mfaActivation.codeNotMatched": "Entered code is wrong",
  "error.mfaCodeAlreadySent": "A code has already been sent, please wait at least 20 seconds before requesting the next one.",
  "error.mfaCodeWrong": "Entered code is wrong. Please try again.",
  "error.mfaSettingChange": "2FA cannot be activated/deactivated because a code has already been requested for activation. Please wait at least 20 seconds before trying again.",
  "error.mfaSettingChange.codeAlreadySent": "A new code has already been requested. Please wait at least 20 seconds before trying again.",
  "error.mfaSettingChange.codeNotMatched": "Code is wrong",
  "error.microsoftLogin": "A problem occurred while communicating with Microsoft. Please try to log in again.",
  "error.missingDocument": "Document missing",
  "error.missingEmail": "Missing e-mail address",
  "error.missingEmployee": "Please select employee",
  "error.missingParams": "Missing parameters",
  "error.move": "error in moving",
  "error.move.alreadyExists.unknown": "Error when moving: A folder or file with this name already exists",
  "error.msTokenExpired": "Your MS Token has expired. Please renew it.",
  "error.multipleArchives": "several client archives available for this company",
  "error.noArchive": "No client archive available for this company",
  "error.noCompanySelected": "No company selected",
  "error.noDocumentSelected": "Please select a document for the terms of use",
  "error.noDotAtEnd": "Last character must not be a period",
  "error.noFinancialAccountancies": "Company has no FIBUs",
  "error.noMsAccountAvailable": "User cannot be invited. It is expected that a Microsoft account already exists for TPA employees.",
  "error.noRecordTypes": "No document categories found",
  "error.noSignees": "Please add email addresses",
  "error.noSubsidiaries.accounting": "No accounting areas found",
  "error.noSubsidiaries.hr": "No payroll areas found",
  "error.noSubsidiaryWithEmployeeRead": "No payroll area with employee read rights.",
  "error.notAdvisor": "No consulting permissions ",
  "error.notAllowed": "Action must not be carried out",
  "error.notificationCenter.load": "Notifications could not be loaded",
  "error.notificationCenter.markAllAsRead": "Notifications could not be marked as read",
  "error.notificationCenter.markAsRead": "Notification could not be marked as read",
  "error.oauthApi": "There was a problem with the authentication. Please try again.",
  "error.oauthCompany": "No selected company. Please select a company and try again.",
  "error.oauthUrl": "There was a problem calling facts and figures. Please try again.",
  "error.overlappingSignature": "Overlapping signature. Please select a new position",
  "error.passwordReset.tokenExpired": "This password reset link has expired",
  "error.passwordReset.tokenNotFound": "Invalid password reset link",
  "error.payTaxAccount": "Errors when paying the tax debt",
  "error.payUnpaidAccount": "Error when paying a TPA invoice",
  "error.periodClosed": "Period already closed",
  "error.permission.corruptRoles": "Invalid roles",
  "error.permission.onlyOneRoleAllowed": "Only one roll may be set",
  "error.postTicket": "Inquiry could not be created.",
  "error.projectForbidden": "You do not have access to projects",
  "error.projectIsEmpty": "The project is empty and can therefore not be downloaded",
  "error.projectPermissions": "Project permissions could not be loaded",
  "error.projects.cantMove": "The files could not be moved. Please try again later.",
  "error.projects.cantPaste": "The files could not be inserted. Please try again later.",
  "error.projects.openBookmarkUrlFailed": "The URL could not be opened",
  "error.qes.missingSignaturePositions": "Please add the required signature positions",
  "error.recordForbidden": "You do not have access to the document",
  "error.recordWithNotAllowedCompany": "No permission for company to which the document is assigned.",
  "error.refreshBankConnection": "Bank account could not be renewed",
  "error.releaseEmployeeDocument": "Error during release",
  "error.releaseReport": "Error during approval",
  "error.removeBankAccountTransactionPrivateMark": "Could not deselect",
  "error.removeCompanyFromUserFavorites": "Company could not be removed from your favorites",
  "error.rename.alreadyExists.file": "Renaming error: A file with this name already exists",
  "error.rename.alreadyExists.folder": "Rename error: A folder with this name already exists",
  "error.requiredField": "Input required",
  "error.resendTermsOfUseEmail": "An error occurred when resending the e-mail",
  "error.save": "save error",
  "error.saveKpiConfigurations": "Facts and figures Configuration could not be saved",
  "error.saveOptionalMenuItems": "Optional menu items could not be loaded. Please try again later",
  "error.saveTermsOfUse": "An error occurred while saving the terms of use. Please try again later",
  "error.send": "Error on transmission.",
  "error.setDocumentOrSignees": "An error occurred while saving the document or the persons to be signed",
  "error.settings": "Error when changing the settings",
  "error.signFileTooLarge": "The file is too large and cannot be signed electronically.",
  "error.signReadOnlyDocument": "The document is read-only and therefore cannot be signed",
  "error.signTermsOfUse": "An error occurred while accepting the terms of use. Please try again later",
  "error.subsidiaryWithoutRecordTypes": "{subsidiary} has no active document symbols",
  "error.termsOfUseDuplicateFile": "The same file with a different name already exists",
  "error.termsOfUseFolderNotFound": "No folder with terms of use has been created yet",
  "error.timeout": "This process is currently taking a little longer. Please try again later.",
  "error.toggleCompanyFeature": "Error when activating/deactivating the add-on",
  "error.tokenRefresh": "Authentication error",
  "error.tooManyCharacters": "Maximum {maxCharacters} characters allowed per message.",
  "error.transfer": "transfer error",
  "error.transferEmployee": "Employee could not be transferred",
  "error.transferEmployeeDirect": "The transmission to the health insurance company failed. Please contact your advisor.",
  "error.unknown": "Unknown error ({statusCode})",
  "error.unsupportedDocumentType": "This document type is not supported",
  "error.updateBankAccountAccountNumber": "The account could not be saved.",
  "error.upgradeToMicrosoft": "Upgrade could not be performed. Please check your password.",
  "error.upload": "Upload error",
  "error.userDeactivated": "This Microsoft user has been deactivated.",
  "error.userNotRegistered": "User is not registered",
  "error.userUnknown": "Unknown user",
  "externalLinkDialog.content": "",
  "externalLinkDialog.title": "",
  "failedUserDialog.amount": "<b>{failedUserCount}</b> of {userCount} users could not be created/modified:",
  "failedUserDialog.button.text.continue": "Continue anyway",
  "failedUserDialog.button.text.retry": "Try again",
  "failedUserDialog.error.emailAlreadyExists": "E-mail address already in use",
  "failedUserDialog.error.pendingPermissionChanges": "Already waiting for approval",
  "failedUserDialog.message": "Error when creating/changing",
  "filterDialog.apply": "Apply filters",
  "filterDialog.category.advanced": "Advanced filters",
  "filterDialog.category.assigned": "Assigned area",
  "filterDialog.header": "Filter options",
  "filterDialog.required": "Please select a value",
  "filterDialog.reset": "RESET FILTER",
  "finishProjectDialog.text": "Are you sure you want to complete the <b>{projectName}</b> project?",
  "finishProjectDialog.title": "Complete project",
  "footer.copyright": "All rights reserved",
  "footer.disclaimer": "Disclaimer",
  "footer.generalBusinessTerms": "AGB",
  "footer.legalInformation": "Imprint",
  "footer.privacy": "Data protection",
  "helpcenter.ideas": "Ideas or wishes",
  "helpcenter.problems": "Report problems",
  "helpcenter.problems.email": "E-mail address",
  "helpcenter.problems.send": "Report a problem",
  "helpcenter.problems.upload.failed": "The problem could not be transmitted",
  "helpcenter.problems.upload.success": "The problem was successfully submitted",
  "helpcenter.problems.whathappened": "What happened instead?",
  "helpcenter.problems.whatshouldhappen": "What should have happened?",
  "helpcenter.problems.whatwasdone": "What was tried to be done?",
  "helpCenter.title": "Help center",
  "helpcenter.videos": "Help videos",
  "helpcenter.videos.tpaonly": "Help videos TPA employees",
  "hr.casualEmployment": "Casual employment",
  "hr.disclaimer.additionalData": "For direct transmission we need some additional data. Please still fill them out.",
  "hr.disclaimer.employmentDate": "Date of employment",
  "hr.disclaimer.subtitle": "Do you want to send the data to TPA or directly to the health insurance company?",
  "hr.disclaimer.title": "Transmission",
  "hr.disclaimer.transfer.direct": "The data transfer is to be made directly to the Austrian health insurance fund",
  "hr.disclaimer.transfer.direct.subtitle": "We note that TPA will not review the data until the next few days, at the earliest when the office is open.",
  "hr.disclaimer.transfer.tpa": "The data transmission is made to the TPA",
  "hr.disclaimer.transfer.tpa.subtitle": "We note that employee registration with the Austrian Health Insurance Fund will not take place until TPA has processed the data.",
  "hr.documents.backLabel": "Personnel/Files",
  "hr.documents.emptyState.header": " enter",
  "hr.documents.emptyState.message": "Start uploading your files now.",
  "hr.documents.list.backLabel": "Personnel/ Documents",
  "hr.documents.nonWorkingTimes": "Non-Performance Times",
  "hr.documents.nonWorkingTimesUpload": "File Upload Non-Performance Times",
  "hr.documents.upload.navHeader": "{documentType}",
  "hr.documents.upload.navLabel": "Personnel/Documents",
  "hr.employee.employment": ". Employment",
  "hr.employee.employmentRelationship": "Employment",
  "hr.employee.jobDescription": "Job title",
  "hr.employee.payslip.upload.date": "Period",
  "hr.employee.payslip.upload.title": "Upload payslip",
  "hr.employee.payslip.upload.type": "Payslip type",
  "hr.employee.payslip.upload.type.monthly": "Monthly payslip",
  "hr.employee.payslip.upload.type.yearly": "Annual payslip",
  "hr.employee.reasonForLeaving": "Reason for leaving",
  "hr.employee.workplaceState": "State of the place of work",
  "hr.finished.analysis": "Monthly evaluations",
  "hr.finished.billing": "Monthly settlements",
  "hr.finished.globalAnalysis": "Company-wide evaluation",
  "hr.finished.globalBilling": "Company-wide accounting",
  "hr.insuranceCarrier": "Insurance carrier",
  "hr.overview.continuousActions": "Continuous functions",
  "hr.recordTypes.names.benefitsInKindCar": "benefits in kind car",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "contract adjustments and special payments",
  "hr.recordTypes.names.mealDeductions": "meal deductions",
  "hr.recordTypes.names.nonWorkingTimes": "non working times",
  "hr.recordTypes.names.overtimeAndBonusPayments": "overtime and bonus payments",
  "hr.recordTypes.names.supplementaryDocuments": "supplementary documents",
  "hr.recordTypes.names.timeRecords": "time records",
  "hr.recordTypes.names.travelExpenseReport": "travel expense report",
  "hr.tickets.preRegistration.NavBar": "Inquiry concerning {preRegistration}",
  "hr.tickets.preRegistration.NavBarLabel": "Staff / Employees / Inquiries",
  "hr.tickets.preRegistration.toOverview": "TO THE PERSONNEL FILE",
  "imageCrop.description": "Drag to move the image",
  "imageCrop.title": "Position image",
  "imageCrop.zoom": "Zoom",
  "language.german": "German",
  "maritalStatus.divorced": "Divorced",
  "maritalStatus.livingInPartnership": "cohabiting union",
  "maritalStatus.married": "married/registered partnership",
  "maritalStatus.separated": "separated",
  "maritalStatus.unknown": "Unknown",
  "maritalStatus.unmarried": "single",
  "maritalStatus.widowed": "widowed",
  "menu.answer": "Answer",
  "menu.assign": "Assign",
  "menu.cancelRelease": "withdraw approval",
  "menu.delete": "Delete",
  "menu.details": "Details",
  "menu.download": "Download",
  "menu.draft": "Request approval",
  "menu.edit": "Edit",
  "menu.editRelease": "Request new approval",
  "menu.finish": "Complete",
  "menu.markAsDone": "Mark as completed",
  "menu.move": "Move",
  "menu.openInBrowser": "Open in browser",
  "menu.openInSharepoint": "Open in Sharepoint",
  "menu.rename": "Rename",
  "menu.requestRelease": "Request approval",
  "menu.showReleases": "Display approvals",
  "menu.view": "View",
  "mfa.activation.dialog.code.required": "Please enter the code",
  "mfa.activation.dialog.description": "Please enter the code we sent to your phone number to activate two-factor authentication.",
  "mfa.activation.dialog.title": "Activate 2FA",
  "mfa.activation.success.dialog.title": "2FA was successfully activated",
  "mfa.changeSetting.dialog.code.field.label": "Code",
  "mfa.changeSetting.dialog.resendCode": "Resend code",
  "mfa.codeResend.success.dialog.title": "Code has been sent again",
  "mfa.deactivation.dialog.description": "Please enter the code we sent to your phone number to disable two-factor authentication.",
  "mfa.deactivation.dialog.title": "Deactivate 2FA",
  "mfa.deactivation.success.dialog.title": "2FA was successfully disabled",
  "mfa.validation.code.length": "The code has 6 digits",
  "mfa.validation.code.required": "Code must be entered",
  "mfa.validation.code.type": "Only numbers are allowed",
  "microsoft_oauth.button.login.title": "Log in with Microsoft",
  "microsoft_oauth.button.send_code.title": "Send code",
  "microsoft_oauth.button.upgrade.title": "Perform upgrade",
  "missingEmailDialog.description": "The email address of <b>{name}</b> is not on file. Please enter a valid email address to be able to send the invitation.",
  "missingEmailDialog.title": "E-mail address missing",
  "missingEmail.email.error": "Please enter a correct e-mail address",
  "missingEmail.email.label": "Enter email address",
  "module.finished.globalReports": "Company-wide reports",
  "modules.delete.report.message": "Do you really want to delete the report {name}?",
  "modules.delete.report.title": "Delete report",
  "navbar.week": "KW {week} {year}",
  "newUsername.checkbox.error": "Please confirm your new email address",
  "notificationCenter.emptyState": "No notifications available",
  "notificationCenter.filter.accounting": "Accounting",
  "notificationCenter.filter.appNotification:maintenance": "Maintenance messages",
  "notificationCenter.filter.appNotification:newFeature": "New features",
  "notificationCenter.filter.appNotification:update": "Updates",
  "notificationCenter.filter.companySpecific": "Company specific",
  "notificationCenter.filter.employeeDocument": "Employee documents",
  "notificationCenter.filter.file": "Documents",
  "notificationCenter.filter.general": "General",
  "notificationCenter.filter.generalDocument": "General documents",
  "notificationCenter.filter.hr": "Staff",
  "notificationCenter.filter.invoice": "Documents",
  "notificationCenter.filter.login": "Login",
  "notificationCenter.filter.module": "Module",
  "notificationCenter.filter.notificationType": "Notification type",
  "notificationCenter.filter.other": "Other",
  "notificationCenter.filter.payslip": "Payslip",
  "notificationCenter.filter.project": "Projects",
  "notificationCenter.filter.report": "Reports",
  "notificationCenter.filter.ticket": "Inquiry",
  "notificationCenter.filter.unread": "Unread",
  "notificationCenter.markAllAsRead": "Mark all as read",
  "notificationCenter.title": "Notification center",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "You have <b>{count} new documents</b> in your personnel file that require your approval.",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "You have <b>a new document</b> in your personnel file that requires your approval.",
  "openEmployeeDocumentReleasesDialog.submit": "View and share",
  "openEmployeeDocumentReleasesDialog.title": "Share documents",
  "pdfView.error.caption": "The document could not be loaded, please try again",
  "pendingUserCreationDialog.message": "Adding users to sharepoint can take up to 30 minutes. Please wait until the update has been synced.",
  "pendingUserCreationDialog.title": "User creation in progress",
  "permission.accounting.banking": "Bank details",
  "permission.accounting.global.reports": "Company-wide accounting reports",
  "permission.accounting.global.results": "Company-wide accounting results",
  "permission.accounting.incaseoflaw": "In Case Of Law",
  "permission.accounting.records": "Announcement of the accounts",
  "permission.accounting.reports": "Accounting reports",
  "permission.expenses.cockpit": "Results in the cockpit",
  "permission.expenses.kpi": "Use of the Facts and Figures Module",
  "permission.hr.employeePayrollAccount": "View employee payroll account",
  "permission.hr.employerPayrollAccount": "Employer payroll account",
  "permission.hr.employerPayrollAccount.details": "View wage type details",
  "permission.hr.global.reports": "Company-wide reports for payroll accounting",
  "permission.hr.global.results": "Company-wide evaluations of payroll accounting",
  "permission.hr.personnel": "Employees",
  "permission.hr.records": "Announcement of the payroll",
  "permission.hr.reports": "Reports for payroll accounting",
  "permission.option.canAccess": "can access",
  "permission.option.canEdit": "can edit and delete",
  "permission.option.canEdit.all": "can edit/approve everything",
  "permission.option.canEdit.none": "cannot edit/approve anything",
  "permission.option.canEdit.some": "can partially edit/approve",
  "permission.option.canPay": "can pay",
  "permission.option.canRead": "can see",
  "permission.option.canRead.all": "can see everything",
  "permission.option.canRead.none": "cannot see anything",
  "permission.option.canRead.some": "may partially see",
  "permission.option.canRelease": "can approve",
  "permission.option.canUpdate": "can edit",
  "permission.option.canUpload": "can upload",
  "permission.option.mixed": "mixed",
  "permission.option.none": "no permission",
  "permission.projects": "Use of the projects module",
  "permission.settings.company": "View company settings",
  "permission.settings.features": "Modification of add-ons",
  "permission.settings.images": "Change cover image & logo",
  "permission.settings.user": "Suggest changes to user rights",
  "permission.taxAccount.payment": "Pay tax debt",
  "permission.unpaidAccount.payment": "Pay outstanding invoices",
  "project.invitation": "Project invitation",
  "projectItemPermission.owner": "Full access",
  "projectItemPermission.owner.description": "All rights to edit folders & files. Edit and complete the project and manage members and permissions.",
  "projectItemPermission.read": "Read",
  "projectItemPermission.read.description": "Viewing folders and files and downloading files",
  "projectItemPermission.readNoDownload": "Read only",
  "projectItemPermission.write": "Edit",
  "projectItemPermission.write.description": "View, create, and rename folders. View, upload, edit, and delete files.",
  "projects.activate": "REACTIVATE",
  "projects.active.emptystate.buttonlabel": "Create project",
  "projects.active.emptystate.message": "There is no project for this company yet",
  "projects.addCompanies": "Add company",
  "projects.addCompanies.tooltip": "Create the project in several companies",
  "projects.addFolder": "Create folder",
  "projects.addMembers": "Add members",
  "projects.addMembers.bulk.success.title": "Members successfully added",
  "projects.bulkDeleteDialog.message": "Are you sure you want to delete the selected files and folders?",
  "projects.bulkDeleteDialog.title": "Delete files and folders",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "Withdraw",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "Are you sure you want to withdraw the open approval request to <b>{fileName}</b>?",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "Withdraw existing approval?",
  "projects.closed.emptystate.message": "There is no completed project for this company yet",
  "projects.companies.emptyState.title": "No companies available",
  "projects.companies.submit": "Add",
  "projects.copySuccessDialog.message": "The selected files were copied to your clipboard",
  "projects.copySuccessDialog.title": "Copied to clipboard",
  "projects.create.bulk.backToProjects": "Back to projects",
  "projects.create.bulk.description": "The projects are created. This may take a few minutes, please do not close the browser window.",
  "projects.create.bulk.start": "Launch",
  "projects.deleted.emptystate.message": "There is no deleted project for this company yet",
  "projects.deleteDialog.message.file": "Are you sure you want to delete the file <b>{name}</b>?",
  "projects.deleteDialog.message.folder": "Are you sure you want to delete the <b>{name}</b> folder?",
  "projects.deleteDialog.message.project": "Are you sure you want to delete the <b>{name}</b> project?",
  "projects.deleteDialog.title.file": "Delete file",
  "projects.deleteDialog.title.folder": "Delete folder",
  "projects.deleteDialog.title.project": "Delete project",
  "projects.emptystate.title": "No projects available",
  "projects.externalId": "Reference Number:",
  "projects.finish.successDialog.title": "Project successfully completed",
  "projects.folder.emptystate.message": "Now start filling the folder.",
  "projects.folder.emptystate.title": "Create & upload content",
  "projects.importUsers.add": "Add",
  "projects.importUsers.replace": "Replace",
  "projects.importUsers.switch": "Import members from another project",
  "projects.international": "International project",
  "projects.international.activate": "To convert a project into an international one, you need to enable this functionality.",
  "projects.international.confirmButton": "Convert project",
  "projects.international.disabled.refresh": "Your international MS login has expired, please renew it. After that you can change \"{project}\" to an international project.",
  "projects.international.disabled.unlock": "To convert a project into an international one, you have to enable this functionality in the project overview.",
  "projects.international.info.message": "Converting a project into an international project enables TPA employees from other countries to be invited to the project in order to work with the customer across borders. For this purpose, all files and documents stored on Microsoft SharePoint as part of the project are transferred between different Microsoft tenants and, in the course of this, all user authorizations for working on the project are rebuilt. Any fine-grained permissions may be lost in the process. It is therefore recommended to check the access rights after the conversion to an international project. This step cannot be revoked.",
  "projects.international.info.subtitle": "Please note the following:",
  "projects.international.invite.message": "You have been added to new international projects. Unlock the following countries once to view projects from these countries.",
  "projects.international.invite.title": "Unlock new countries",
  "projects.international.migrating": "International project (conversion in progress)",
  "projects.international.migrating.message": "The project is currently being converted to an international project. Editing will not be possible again until the conversion is complete.",
  "projects.international.plural": "International projects",
  "projects.international.switch": "Transform this project into an international project",
  "projects.international.undoWarning.message": "Are you sure you want to convert this project into an international project? All project data will be copied to the TPA AT sharepoint. This action cannot be undone after the project has been created.",
  "projects.international.undoWarning.message.at": "Are you sure you want to convert this project into an international project? This action cannot be undone after the project has been created.",
  "projects.international.undoWarning.title": "This action cannot be undone",
  "projects.invite.international": "You have been added to international projects. Unlock them now.",
  "projects.invite.message": "You have been added to projects from {numCountries} countries. Unlock them now.",
  "projects.invite.message.singular": "You have been added to projects from {country}. Unlock them now.",
  "projects.members.assignPermission": "Assign authorization",
  "projects.membersIncluded.multi": "Including {names} and {name}.",
  "projects.membersIncluded.single": "Including {name}.",
  "projects.moveConfirmationDialog.message": "Moved files are deleted in the original folder!",
  "projects.moveConfirmationDialog.title": "Move files?",
  "projects.moveSuccessDialog.message": "The files were moved successfully",
  "projects.moveSuccessDialog.title": "Files moved",
  "projects.navbar.activeProjects": "Active",
  "projects.navbar.deletedProjects": "Deleted",
  "projects.navbar.fileUpload": "File upload",
  "projects.navbar.finishedProjects": "Completed",
  "projects.navbar.heading": "Projects",
  "projects.navbar.notConfiguredProjects": "Not configured",
  "projects.navbar.overview": "Overview{path}",
  "projects.navbar.project.fileTree": "Folder structure",
  "projects.navbar.project.heading": "Project: {projectName}",
  "projects.navbar.project.releases": "Approvals",
  "projects.navbar.releases.heading": "Approve requests for {fileName}",
  "projects.navbar.ticket.heading": "Consultation on {fileName}",
  "projects.new": "New project",
  "projects.new.createNewProject": "Create project",
  "projects.new.externalId": "Reference number",
  "projects.new.name": "Project name",
  "projects.new.permissionContextMenu.remove": "Remove",
  "projects.new.permissions.text": "Add new members and assign user rights.",
  "projects.new.title": "Name project",
  "projects.new.userSearch.placeholder": "Invite member...",
  "projects.notConfigured.emptystate.message": "There is no project to configure for this company yet",
  "projects.openReleases.plural": "Open approvals",
  "projects.openReleases.singular": "Open approval",
  "projects.pasteProgressDialog.title": "Files are inserted",
  "projects.pasteSuccessDialog.message": "The files were successfully inserted",
  "projects.pasteSuccessDialog.title": "Files inserted",
  "projects.refreshDialog.message": "Microsoft logins for the following countries have expired. Please renew them.",
  "projects.refreshDialog.title": "Microsoft logins expired",
  "projects.refresh.message": "Microsoft logins for {numCountries} countries have expired. Please renew them.",
  "projects.refresh.message.singular": "Your Microsoft login for {country} has expired. Please renew it.",
  "projects.releaseConfirmationDialog.message": "Please note the following comment from {name}:",
  "projects.releaseConfirmationDialog.title": "Share document",
  "projects.releases.emptystate.message": "You currently have no approvals.",
  "projects.releases.emptystate.title": "No approval available",
  "projects.releases.reject": "Decline",
  "projects.releases.status.canceled": "withdrawn",
  "projects.releases.status.canceledFrom": "withdrawn from {name} on {date}",
  "projects.releases.status.canceled.tooltip": "withdrawn on {performedAt} by {name}",
  "projects.releases.status.open": "waiting for approval",
  "projects.releases.status.openFrom": "waiting for approval of {name}",
  "projects.releases.status.open.tooltip": "waiting for approval (deadline: {dueDate}) from {name}",
  "projects.releases.status.rejected": "rejected",
  "projects.releases.status.rejectedFrom": "rejected by {name} on {date}",
  "projects.releases.status.rejected.tooltip": "rejected on {performedAt} by {name}",
  "projects.releases.status.released": "shared",
  "projects.releases.status.releasedFrom": "approved by {name} on {date}",
  "projects.releases.status.released.tooltip": "approved on {performedAt} by {name}",
  "projects.releaseSuccessDialog.message": "<b>{name}</b> will now look at the files and get back to you in case of any questions.",
  "projects.releaseSuccessDialog.multi.message": "The assigned individuals will now review the files and contact you with any queries.",
  "projects.releaseSuccessDialog.title": "Approval successfully requested",
  "projects.requestReleaseConfirmationDialog.confirm": "Withdraw and recreate",
  "projects.requestReleaseConfirmationDialog.message": "There is already an open approval request for <b>{fileName}</b>. To create a new approval request, the existing release request must be revoked.",
  "projects.requestReleaseConfirmationDialog.multi.message": "There are already open shares in your selection. To create new shares, the existing shares must be withdrawn.",
  "projects.requestReleaseConfirmationDialog.multi.title": "Withdraw existing releases?",
  "projects.requestReleaseConfirmationDialog.title": "Withdraw existing approval?",
  "projects.restoreDialog.message.project": "Are you sure you want to activate the <b>{name}</b> project?",
  "projects.restoreDialog.title.project": "Activate project",
  "projects.statusClosed": "Completed on",
  "projects.statusClosed.personalized": "Completed by {name} on",
  "projects.statusDeleted": "Deleted on",
  "projects.statusDeleted.personalized": "Deleted by {name} on",
  "projects.statusInProgress": "Last modified on",
  "projects.statusInProgress.personalized": "Last modified by {name} on",
  "projects.tickets.plural": "Inquiries",
  "projects.tickets.singular": "Consultation",
  "records.details.upload.header": "Record {recordType}",
  "recordType.statusClosed": "Transmitted on",
  "recordType.statusInProgress": "Edited on",
  "recordType.statusOpen": "Unedited",
  "renameDialog.button.text.rename": "Rename",
  "renameDialog.label.document": "Name",
  "renameDialog.label.file": "File name",
  "renameDialog.label.folder": "Folder name",
  "renameDialog.title.document": "Rename document",
  "renameDialog.title.file": "Rename file",
  "renameDialog.title.folder": "Rename folder",
  "renameProjectItemDialog.button.text.rename": "Rename",
  "renameProjectItemDialog.itemName.file": "File name",
  "renameProjectItemDialog.itemName.folder": "Folder name",
  "renameProjectItemDialog.title.file": "Rename file",
  "renameProjectItemDialog.title.folder": "Rename folder",
  "renameRecordDialog.button.text.rename": "Rename",
  "renameRecordDialog.name": "Name",
  "renameRecordDialog.title": "Rename document",
  "requestReleaseProjectItemDialog.button.text.request": "Request",
  "requestReleaseProjectItemDialog.info": "When do I need a qualified signature?",
  "requestReleaseProjectItemDialog.label.comment": "Comment",
  "requestReleaseProjectItemDialog.label.dueDate": "Deadline",
  "requestReleaseProjectItemDialog.label.requiresQes": "Approval by electronic signature (PDF files only)",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "Enter name...",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "All persons must approve",
  "requestReleaseProjectItemDialog.qes.collective.title": "Collective approval",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "One person must approve",
  "requestReleaseProjectItemDialog.qes.single.title": "Individual approval",
  "requestReleaseProjectItemDialog.searchForUsers.error": "Please select a name from the list",
  "requestReleaseProjectItemDialog.text": "Select the people to which you want to assign the release to the listed documents and specify a deadline.",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "Add another person",
  "requestReleaseProjectItemDialog.title.create": "Request approval",
  "requestReleaseProjectItemDialog.title.edit": "Edit approval",
  "results.accountSheet.backLabel.accountSheet": "Account sheet {accountNr} \"{name}\"",
  "results.accountSheet.backLabel.results.accountListing": "Balance list",
  "results.accountSheet.backLabel.results.balanceSheet": "Balance sheet",
  "results.accountSheet.backLabel.results.cashAccounting": "Cash income statement",
  "results.accountSheet.backLabel.results.customerListing": "Accounts receivable list",
  "results.accountSheet.backLabel.results.profitAndLoss": "P&L",
  "results.accountSheet.backLabel.results.vendorListing": "Accounts payable list",
  "results.accountSheet.columns.amount": "Amount",
  "results.accountSheet.columns.documentDate": "Document date",
  "results.accountSheet.columns.documentNumber": "Document no.",
  "results.accountSheet.columns.documents": "Documents",
  "results.accountSheet.columns.offsettingAccounts": "Offsetting accounts",
  "results.accountSheet.columns.openItemAmount": "Open amount",
  "results.accountSheet.columns.postingSymbol": "Doc Type",
  "results.accountSheet.columns.postingText": "Posting text",
  "results.accountSheet.details.costCenter": "Cost center",
  "results.accountSheet.details.discount": "Cash discount",
  "results.accountSheet.details.externalDocumentNumber": "External document no.",
  "results.accountSheet.details.foreignCurrency": "FX / rate",
  "results.accountSheet.details.period": "Period",
  "results.accountSheet.details.serialNumber": "Journal no.",
  "results.accountSheet.details.tax": "Tax",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "Export deliveries",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "construction work with input tax",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "construction work without input tax",
  "results.accountSheet.details.taxCode.EUSt": "import VAT",
  "results.accountSheet.details.taxCode.igEMitVSt": "ic purchase with input tax",
  "results.accountSheet.details.taxCode.igEOhneVst": "ic purchase without input tax",
  "results.accountSheet.details.taxCode.igLieferung": "ig delivery",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "Small business",
  "results.accountSheet.details.taxCode.RCMitVSt": "RC with input tax",
  "results.accountSheet.details.taxCode.RCOhneVSt": "RC without input tax",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "Other services EU (subject to ZM)",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "partially deductible VAT",
  "results.accountSheet.details.taxCode.Ust": "VAT",
  "results.accountSheet.details.taxCode.VSt": "input tax",
  "results.accountSheet.details.taxPercent": "Tax percent",
  "results.accountSheet.details.vatPeriod": "VAT return period",
  "results.accountSheet.documents.downloadAll": "Download all files",
  "results.accountSheet.filters.amount": "Amount",
  "results.accountSheet.filters.documentDate": "Document date",
  "results.accountSheet.filters.postingSymbol": "Booking symbol",
  "results.accountSheet.noResults.message": "No items could be found for the selected account",
  "results.accountSheet.noResults.open.message": "No open items could be found for the selected account",
  "results.accountSheet.noResults.open.title": "No open items found",
  "results.accountSheet.noResults.title": "No items found",
  "results.accountSheet.offsettingAccounts.more": "{count} more",
  "results.accountSheet.paid": "paid",
  "results.accountSheet.search.count": "Bookings: <b>{count}</b>",
  "results.accountSheet.search.placeholder": "search for posting text...",
  "results.accountSheet.sum.title": "Σ Total",
  "results.accountSheet.tabs.all": "All items",
  "results.accountSheet.tabs.open": "Open items only",
  "results.accountSheet.title": "Account sheet for account {accountNr} \"{name}\"",
  "results.columns.changeAbsolute": "diff",
  "results.columns.changePercentage": "diff (%)",
  "results.columns.credit": "Have",
  "results.columns.debit": "Target",
  "results.common.accounts": "Accounts",
  "results.common.quickExpand.expandAccounts": "All (incl. accounts)",
  "results.common.quickExpand.expandNthDepth": "Level {depth}",
  "results.common.quickExpand.expandRoot": "Main breakdown level",
  "results.common.quickExpand.expandRows": "Expand lines:",
  "results.disclaimer": "Important note",
  "results.disclaimerMessage": "This monthly accounting report provides evaluations based on the current balance list, which was created from the documents provided to us without any material examination of them, and provides the client with timely additional information about ongoing business development. In particular, accruals, changes in provisions, changes in inventories, valuations of receivables and write-offs were not taken into account in the present evaluations. This also results in discrepancies between the monthly evaluations and the final annual financial statements. \n\nNo liability is therefore assumed for the accuracy and completeness of the evaluations towards the client or third parties. Furthermore, the General Terms and Conditions of Contract for Public Accounting Professions (AAB) apply to our orders and responsibilities, including in relation to third parties.",
  "results.navbar.buttons.accountListing": "Balance list",
  "results.navbar.buttons.balanceSheet": "Balance",
  "results.navbar.buttons.cashAccounting": "Cash income statement",
  "results.navbar.buttons.customerListing": "Accounts receivable list",
  "results.navbar.buttons.payrollAccount": "Payroll account",
  "results.navbar.buttons.profitAndLoss": "P&L",
  "results.navbar.buttons.vendorListing": "Accounts payable list",
  "results.noFinancialAccountancies.message": "No result reports are currently available.",
  "results.noFinancialAccountancies.title": "No result reports",
  "results.payrollAccountSheet.dataTable.month": "month",
  "results.payrollAccountSheet.dataTable.number": "No.",
  "results.payrollAccountSheet.dataTable.payrollType": "Wage type",
  "results.payrollAccountSheet.dataTable.total": "Total",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "Total previous year",
  "results.payrollAccountSheet.noPermission.message": "You are not authorized to view the payroll account.",
  "results.payrollAccountSheet.noPermission.title": "No authorization",
  "results.payrollAccountSheet.noResults.details": "This payroll account does not contain any details",
  "results.payrollAccountSheet.noResultsYear.message": "No data found for the selected year.",
  "results.payrollAccountSheet.noResultsYear.title": "No data found",
  "results.settings.accountsVisibility.hideAccounts": "Hide accounts",
  "results.settings.accountsVisibility.showAccounts": "Show accounts",
  "results.settings.orderBy.accountNameAsc": "Account name ascending",
  "results.settings.orderBy.accountNameDesc": "Account name descending",
  "results.settings.orderBy.accountNrAsc": "Account number ascending",
  "results.settings.orderBy.accountNrDesc": "Account number descending",
  "results.settings.orderBy.amountAsc": "Amount ascending",
  "results.settings.orderBy.amountDesc": "Amount descending",
  "results.settings.orderBy.title": "Sort by: {orderBy}",
  "results.settings.previousYearRanges.fullYear": "Show total previous year",
  "results.settings.previousYearRanges.partialYear": "Consider previous year also only up to {previousYearRangeUntil}.",
  "results.settings.variant.compactView": "Compact view",
  "results.settings.variant.currentAndPastYear": "Current and previous year",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "Current and previous year with absolute change",
  "results.settings.variant.currentAndPastYearPercentageChange": "Current and previous year with percentage change",
  "results.settings.variant.currentYear": "Current year",
  "results.settings.variant.detailView": "Detailed view",
  "results.title": "Result reports",
  "role.accountingAssistent": "Accounting Assistant",
  "role.accountingAssistent.description": "Access to accounting without approval rights",
  "role.accountingManagement": "Finance Management",
  "role.accountingManagement.description": "Access to accounting including approval rights",
  "role.admin": "Admin / Management",
  "role.admin.description": "All access rights to all areas",
  "role.assistent": "Assistance",
  "role.assistent.description": "Access to accounting and payroll without approval rights<br />Can suggest modification of the users.",
  "role.custom": "Custom",
  "role.custom.description": "User-defined access rights",
  "role.custom.jobTitle": "employee",
  "role.custom.jobTitle.female": "employee",
  "role.hrAssistent": "Payroll Assistant",
  "role.hrAssistent.description": "Access to payroll accounting without approval rights",
  "role.hrManagement": "HR Management",
  "role.hrManagement.description": "Access to payroll accounting including approval rights",
  "role.internationalProjects": "Project member",
  "role.internationalProjects.female": "Project member",
  "role.legalAdvisor": "Legal Advisor",
  "role.noRole": "No role",
  "role.noRole.description": "No access rights to TPA Connect",
  "role.staff": "Employee",
  "role.staff.description": "Receives electronic earnings statements",
  "role.staff.female": "Employee",
  "role.superAdmin": "Super Admin",
  "role.superAdmin.description": "Access to all areas incl. user management",
  "role.tpaAccounting": "Accounting",
  "role.tpaAccounting.description": "Consultant access to accounting",
  "role.tpaAdvisor": "Advisor",
  "role.tpaAdvisor.description": "Consultant access to all areas",
  "role.tpaAdvisor.female": "Advisor",
  "role.tpaAnnualAccounts": "Annual accounts",
  "role.tpaAnnualAccounts.description": "Responsible for annual financial statements",
  "role.tpaAuditor": "audit",
  "role.tpaBusinessConsultant": "Management consultancy",
  "role.tpaBusinessConsultant.description": "Responsible for management consulting",
  "role.tpaChat": "contact person",
  "role.tpaChatSecret": "contact person",
  "role.tpaHr": "Payroll",
  "role.tpaHr.description": "Consultant access to payroll accounting",
  "role.tpaOther": "Project staff",
  "role.tpaOther.female": "Project employee",
  "role.tpaPartner": "Partner",
  "role.tpaPartner.description": "Consultant access to all areas",
  "role.tpaPartner.female": "Partner",
  "role.tpaPayrollAccountant": "payroll accountant",
  "role.tpaReporting": "Reporting",
  "role.tpaReporting.description": "Responsible for reporting",
  "role.tpaSpecialAdvisor": "Special Consultation",
  "role.tpaSpecialAdvisor.description": "Responsible for special consulting",
  "role.tpaTaxAccountant": "Tax consultancy",
  "role.tpaTaxAccountant.description": "Responsible for tax consulting",
  "screen.accounting.navbar.bankConnections": "Bank details",
  "screen.accounting.navbar.heading": "Accounting",
  "screen.accounting.navbar.overview": "Overview",
  "screen.accounting.navbar.records": "Documents",
  "screen.accounting.overview.bankConnection": "{salutation}, <br /> <br /> one or more of your bank accounts will expire within this posting period. Please renew them.<br /> <br /> Sincerely<br /> {signature}",
  "screen.accounting.overview.bankConnection.expired": "{salutation}, <br /> <br /> one or more of your bank details have expired. Please renew them.<br /> <br /> Sincerely<br /> {signature}",
  "screen.accounting.overview.bankConnection.expired.title": "Bank details expired",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "Renew bank accounts",
  "screen.accounting.overview.bankConnection.title": "Bank accounts expire",
  "screen.accounting.overview.card.analysis": "Approve reports for ",
  "screen.accounting.overview.card.globalAnalysis": "Approval of company-wide reports",
  "screen.accounting.overview.card.overview": "Announcement accounting for",
  "screen.accounting.overview.finished.download": "Download all files",
  "screen.accounting.overview.finished.message": "{salutation}, <br /> <br />Thank you for checking and approving the accounts. Please find below your reports.<br /> <br /> Sincerely, <br />{signature}",
  "screen.accounting.overview.reports": "{salutation}, <br /> <br /> Enclosed please find the following documents for {period}. The documents have been prepared based on the information and records you have provided. If you find any inaccuracies or incompleteness in the documents, please let us know so that we can make appropriate corrections. <br /> <br /> In case of questions, please do not hesitate to contact us.<br /> <br /> Sincerely, <br />{signature}",
  "screen.accounting.overview.ticketsWelcome.inProgress": "{salutation}, <br /> <br />Thank you for submitting the receipts. In the course of the accounting, some questions have arisen:",
  "screen.accounting.overview.welcome.closed": "{salutation}, <br /> <br /> Thank you for submitting all relevant supporting documents. We will process these in accordance with the agreed deadlines and get back to you in case of any questions.",
  "screen.accounting.overview.welcome.inProgress": "{salutation}, <br /> <br /> We request that you upload your booking documents for {period}{dueDate}. For this purpose, please submit all the required documents. <br /> <br />Sincerely, <br />{signature}",
  "screen.accounting.records.banner.selectedAll": "All {count} files in \"{recordType}\" are selected.",
  "screen.accounting.records.banner.selectedAll.transferFailed": "All {count} failed files in \"{recordType}\" are selected.",
  "screen.accounting.records.banner.selectedCount": "All {count} files on this page are selected",
  "screen.accounting.records.banner.totalCount": "Select all {count} files in \"{recordType}\".",
  "screen.accounting.records.banner.totalCount.transferFailed": "Select all {count} failed files in \"{recordType}\".",
  "screen.accounting.records.banner.unselect": "cancel selection",
  "screen.accounting.records.documents": "Files:",
  "screen.accounting.records.empty": "file is empty",
  "screen.accounting.records.filter.transferFailed": "Transfer failed",
  "screen.accounting.records.format.error": "Check format",
  "screen.accounting.records.move.dialog.button.text": "Move",
  "screen.accounting.records.move.multiple.dialog.title": "Move documents",
  "screen.accounting.records.move.single.dialog.title": "Move document",
  "screen.accounting.records.navbar.tab.list": "Receipts ({count})",
  "screen.accounting.records.navbar.tab.tickets": "Inquiries ({count})",
  "screen.accounting.records.person.responsible": "Responsibility",
  "screen.accounting.records.serverUpload.error": "File could not be uploaded to the server",
  "screen.accounting.records.serverUpload.typeError": "Format not accepted",
  "screen.accounting.records.sizeAndFormat.error": "Check file size and format",
  "screen.accounting.records.size.error": "Check file size",
  "screen.accounting.records.tickets": "| Inquiries: {numTickets}",
  "screen.accounting.records.transfer.button.text": "Transmit to TPA",
  "screen.accounting.records.transfer.dialog.text": "Do you want to transfer <b>all accounting</b> files for the period {periodStart} - {periodEnd} to TPA for posting?",
  "screen.accounting.records.transfer.dialog.textRecordType": "Do you want to pass <b>all files from \"{name}\"</b> for the period {periodStart} - {periodEnd} to TPA for posting?",
  "screen.accounting.records.transfer.dialog.title": "submit accounting",
  "screen.accounting.records.transfer.retryDialog.button.text": "Try again.",
  "screen.accounting.records.transfer.retryDialog.file": "Error submitting {failedFile}",
  "screen.accounting.records.transfer.retryDialog.text": "Oops. Something went wrong here. Please try again.",
  "screen.accounting.records.transfer.retryDialog.title": "Transmission failed",
  "screen.accounting.records.transfer.successDialog.text": "Your TPA adviser will now review the data and contact you in case of any questions.",
  "screen.accounting.records.transfer.successDialog.title": "Data successfully transmitted",
  "screen.accounting.records.types.header": "Accounting",
  "screen.accounting.records.unknown.error": "Unknown error",
  "screen.accounting.records.upload.button": "Upload receipts",
  "screen.accounting.records.upload.navbar.back": "Accounting / Vouchers",
  "screen.accounting.recordTypes.title": "Choose type of documents",
  "screen.advisor.tabbar.active": "Active",
  "screen.advisor.tabbar.notConfigured": "Not configured",
  "screen.advisor.upload.approve.info": "When do I need a qualified signature?",
  "screen.advisor.uploads.upload.approve": "Customer approval",
  "screen.advisor.uploads.upload.approve.option.click": "Release per click",
  "screen.advisor.uploads.upload.approve.option.none": "No customer release",
  "screen.advisor.uploads.upload.approve.option.qes": "Release via electronic signature",
  "screen.advisor.uploads.upload.buttonText": "Transmit",
  "screen.advisor.uploads.upload.category": "Category",
  "screen.advisor.uploads.upload.category.validation": "Please select a category",
  "screen.advisor.uploads.upload.comment": "Transmission text",
  "screen.advisor.uploads.upload.comment.downloadError": "Error loading the comment",
  "screen.advisor.uploads.upload.dueDate": "Due on",
  "screen.advisor.uploads.upload.dueDate.validation": "Please enter a due date",
  "screen.advisor.uploads.upload.global": "Company-wide",
  "screen.advisor.uploads.upload.module": "Area",
  "screen.advisor.uploads.upload.module.validation": "Please select an area",
  "screen.advisor.uploads.upload.navBack": "Uploads",
  "screen.advisor.uploads.upload.navHeader": "Upload files: {category}",
  "screen.advisor.uploads.upload.notifyCustomer": "notify customer",
  "screen.advisor.uploads.upload.subsidiary.validation": "Please select an accounting area",
  "screen.advisor.uploads.upload.visibleToEmployee": "Approval for employee",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "If we have found a user with the email address {email}, you will shortly receive an email with a link to reset your password.",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "Didn't receive any mail? Check your spam folder or contact your system admin.",
  "screen.auth.forgot_password_confirm.title": "You will receive an e-mail shortly",
  "screen.auth.forgot_password_email.form.button": "Send link to reset",
  "screen.auth.forgot_password_email.form.label.email": "User ID / E-mail address",
  "screen.auth.forgot_password_email.subtitle": "Enter your email address and we will send you an email with a link to reset your password.",
  "screen.auth.forgot_password_email.title": "reset password",
  "screen.auth.forgot_password_email.validation_error": "Please enter your email address",
  "screen.auth.forgot_password_reset.error.password_not_set": "Please enter a password",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "Passwords do not match",
  "screen.auth.forgot_password_reset.error.password_weak": "Your password is still too weak.",
  "screen.auth.forgot_password_reset.form.button": "Change password",
  "screen.auth.forgot_password_reset.form.button.mfa": "Send code",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "Resend code",
  "screen.auth.forgot_password_reset.form.mfa": "Code",
  "screen.auth.forgot_password_reset.form.password": "Password",
  "screen.auth.forgot_password_reset.form.repeat_password": "Repeat password",
  "screen.auth.forgot_password_reset.subtitle": "Set a new password for your TPA Connect account.",
  "screen.auth.forgot_password_reset.subtitle.mfa": "Please enter the code we sent you by SMS to your phone number.",
  "screen.auth.forgot_password_reset.title": "Change password",
  "screen.auth.forgot_password_reset.title.mfa": "Confirm password change with two-factor authentication",
  "screen.auth.registration.form.button": "Register",
  "screen.auth.registration.form.button.mfa": "Send code",
  "screen.auth.registration.form.button.mfa.resendCode": "Resend code",
  "screen.auth.registration.form.label.mfa": "Code",
  "screen.auth.registration.form.label.password": "Password",
  "screen.auth.registration.form.label.repeat_password": "Repeat password",
  "screen.auth.registration.form.section_1": "Use at least 8 characters.",
  "screen.auth.registration.form.section_2": "Use upper and lower case, numbers and special characters to make your password stronger.",
  "screen.auth.registration.form.section_3": "Think of a mnemonic to help you remember your password.",
  "screen.auth.registration.mfa.subtitle": "Please enter the code we sent you by SMS to your phone number.",
  "screen.auth.registration.subtitle": "Set a password for your TPA Connect account.",
  "screen.auth.registration.title": "Set & register password",
  "screen.auth.welcome.subtitle": "Link your TPA Connect account to your Microsoft account to take full advantage of all the features. You can also link your account at a later date.",
  "screen.auth.welcome.subtitle.msOnly": "Link your TPA Connect account to your Microsoft account to take full advantage of all the features.",
  "screen.auth.welcome.title": "Welcome to TPA Connect!",
  "screen.changeUsername.checkbox": "I hereby confirm my new e-mail address",
  "screen.changeUsername.message": "The computing heart of the TPA Connect app has noticed that your email address has changed to <b>{newUsername}</b>. In order for us to be completely sure that this change is correct, it is imperative that you confirm this with a single click.",
  "screen.changeUsername.title": "New email address",
  "screen.cockpit.allEvents": "Events: All Events",
  "screen.cockpit.allNews": "News: All articles",
  "screen.cockpit.dueDates.export": "Export deadlines",
  "screen.cockpit.dueDates.navbar.heading": "Open deadlines",
  "screen.cockpit.dueDates.projects.text": "Approval for {documentName}",
  "screen.cockpit.dueDates.records.accounting.text": "Announcement Accounting for {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.records.hr.text": "Announcement of payroll data for {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.reports.global.text": "Report approval for \"company-wide reports\" - {period}",
  "screen.cockpit.dueDates.reports.subsidiary.text": "Report approval for {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "Please use BMD NTCS to provide reports",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "Reportupload",
  "screen.cockpit.dueDates.reportUpload.global.text": "Report upload for \"company-wide reports\" - {period}",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "Reportupload for {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.tickets.global.text": "Deadline for inquiry concerning document {documentName}",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "Deadline for inquiry concerning document {documentName} for {subsidiaryName}",
  "screen.cockpit.emptyState.message": "Come back later",
  "screen.cockpit.emptyState.title": "At the moment there is no news",
  "screen.cockpit.eventDetail": "Event Detail",
  "screen.cockpit.eventDetails.heading": "Details & Registration",
  "screen.cockpit.eventDetails.lecturer.email": "E: {email}",
  "screen.cockpit.eventDetails.lecturers": "Presenter",
  "screen.cockpit.eventDetails.lecturer.telephone": "T: {telephone}",
  "screen.cockpit.eventDetails.register": "Register now",
  "screen.cockpit.event.moreInformation": "INFO & REGISTRATION",
  "screen.cockpit.kpis.add": "Add key figure",
  "screen.cockpit.kpis.chart.legend": "in 1000 €",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "Health insurance",
  "screen.cockpit.kpis.configurationDialog.metric.label": "KPI",
  "screen.cockpit.kpis.configurationDialog.months.label": "months",
  "screen.cockpit.kpis.configurationDialog.title": "Configure KPI widget",
  "screen.cockpit.kpis.ebit.chart.name": "EBIT trend",
  "screen.cockpit.kpis.ebit.kpi.name": "EBIT to date",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "Shows the EBIT value of all months already fully booked since the beginning of the fiscal year.",
  "screen.cockpit.kpis.empty": "Your company code \"{name}\" is currently not available.",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "Health insurance balance",
  "screen.cockpit.kpis.noneConfigured": "Add key figure",
  "screen.cockpit.kpis.revenue.chart.name": "Sales trend",
  "screen.cockpit.kpis.revenue.kpi.name": "Turnover to date",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "Shows the turnover of all moths already fully booked since the beginning of the fiscal year.",
  "screen.cockpit.kpis.save": "Apply changes",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "Arrears",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "Credit",
  "screen.cockpit.kpis.taxAccount.kpi.label": "Status as of {date}",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "[amount] last paid on {date}",
  "screen.cockpit.kpis.taxAccount.kpi.name": "Balance tax account",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "Already paid on {date}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "{invoicesCount} Invoices",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "Status as of {date}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "Outstanding invoices at TPA",
  "screen.cockpit.navbar.heading": "Cockpit",
  "screen.cockpit.overview.kpis.title": "Facts and figures",
  "screen.cockpit.overview.newestPublications": "Recent publications",
  "screen.cockpit.overview.notifications.dueDates": "Open deadlines",
  "screen.cockpit.overview.notifications.messages": "Messages",
  "screen.cockpit.overview.notifications.tickets": "Inquiries",
  "screen.cockpit.overview.notifications.title": "Notifications",
  "screen.cockpit.overview.promotions.title": "News",
  "screen.cockpit.overview.relevantNews.title": "Relevant news",
  "screen.cockpit.overview.relevantPublications.description": "You can either request a printed TPA publication, which will then be sent to you within a few days. Or: You can download an electronic version (PDF) of the desired TPA publication.",
  "screen.cockpit.overview.relevantPublications.title": "Relevant publications",
  "screen.cockpit.promotion": "News: Article",
  "screen.cockpit.promotion.additionalNews": "News",
  "screen.cockpit.relevantNews": "News: Article",
  "screen.cockpit.relevantNews.additionalNews": "More news",
  "screen.cockpit.tickets.navbar.heading": "Open inquiries",
  "screen.hr.additionaldata.addBonus": "add another bonus",
  "screen.hr.additionaldata.additionaldata": "Supplementary information",
  "screen.hr.additionaldata.additionalInformation": "Further information",
  "screen.hr.additionaldata.amount": "Amount in {currency} per month",
  "screen.hr.additionaldata.amount_error": "Please enter an amount",
  "screen.hr.additionaldata.bankDetails": "Bank details",
  "screen.hr.additionaldata.bic": "BIC",
  "screen.hr.additionaldata.bic.validation": "Invalid BIC",
  "screen.hr.additionaldata.commuterAllowance": "Commuter allowance",
  "screen.hr.additionaldata.costCenter": "Cost center",
  "screen.hr.additionaldata.costCenterNote": "Note on cost center",
  "screen.hr.additionaldata.description": "If necessary, the following information may also be completed when you take up your duties.",
  "screen.hr.additionaldata.designator": "Allowance",
  "screen.hr.additionaldata.designator_error": "Please specify an allowance",
  "screen.hr.additionaldata.emailAddress": "E-mail address",
  "screen.hr.additionaldata.email_error": "Please enter a correct e-mail address",
  "screen.hr.additionaldata.familyBonusPlusAmount": "Fabo Plus Amount in EUR",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "Please enter at least 0",
  "screen.hr.additionaldata.funder": "Cost unit",
  "screen.hr.additionaldata.gross": "Gross",
  "screen.hr.additionaldata.hours_error": "Please enter a number between {minHours} and {maxHours}.",
  "screen.hr.additionaldata.iban": "IBAN",
  "screen.hr.additionaldata.iban_error": "Invalid IBAN",
  "screen.hr.additionaldata.jobDescription": "Job title",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "Kilometers for commuter euro",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "Please enter at least 0",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "Kilometers may only be specified in whole numbers",
  "screen.hr.additionaldata.maritalStatus": "Marital status",
  "screen.hr.additionaldata.monthlySalary": "Salary in {currency} per month",
  "screen.hr.additionaldata.monthlySalary_error": "Please enter at least 0",
  "screen.hr.additionaldata.net": "Net",
  "screen.hr.additionaldata.nextPage": "Continue to Documents",
  "screen.hr.additionaldata.number_error": "Please enter a number",
  "screen.hr.additionaldata.per": "per",
  "screen.hr.additionaldata.personaldata": "Personal data",
  "screen.hr.additionaldata.religiousConfession": "Religion",
  "screen.hr.additionaldata.salarayAndPosition": "Remuneration and position",
  "screen.hr.additionaldata.saveChanges": "Save changes",
  "screen.hr.additionaldata.soleEarnerStatus": "Sole earner",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "Titles and academic degrees",
  "screen.hr.additionaldata.titlePrefix": "Title preceding name",
  "screen.hr.additionaldata.titleSuffix": "Title following name",
  "screen.hr.additionaldata.wageAgreementClassification": "Classification Collective agreement",
  "screen.hr.additionaldata.weeklyWorkDays": "Number of working days per week",
  "screen.hr.additionaldata.weeklyWorkDays_error": "Please select a valid value",
  "screen.hr.additionaldata.workingHours": "Number of hours",
  "screen.hr.additionaldata.workingHoursInterval": "Period",
  "screen.hr.additionaldata.workingTimes": "Working hours",
  "screen.hr.cancelRegistration.dataSaved": "If you cancel, the data entered will remain saved. The registration can be continued at a later time.",
  "screen.hr.cancelRegistration.header": "Cancel registration",
  "screen.hr.cancelRegistration.question1": "Are you sure you want to cancel the registration process? You can use the data you have entered so far to",
  "screen.hr.cancelRegistration.question2": " and submit it to your TPA tax advisor for registration.",
  "screen.hr.deregistration.data.navback": "Personnel / Employees / Registered / {firstname} {lastname}",
  "screen.hr.deregistration.documents.navback": "Personnel / Employees / Registered / {firstname} {lastname} / Deregistration data",
  "screen.hr.deregistration.navheader": "{firstname} {lastname} log out",
  "screen.hr.deregistration.validation.deregistrationDate": "Please enter an exit date",
  "screen.hr.deregistration.validation.deregistrationDateMin": "The leaving date must be greater than the entry date",
  "screen.hr.deregistration.validation.deregistrationReason": "Please enter a reason for leaving",
  "screen.hr.deregistration.validation.remainingHolidays": "Please enter the remaining leave",
  "screen.hr.deregistration.validation.remainingOvertime": "Please enter the open overtime",
  "screen.hr.documents.citizenship": "Proof of Citizenship",
  "screen.hr.documents.contract": "Employment Contract",
  "screen.hr.documents.description": "Upload additional documents, such as: employment contract, identity card, proof of citizenship, registration certificate or other documents",
  "screen.hr.documents.emptystate.buttonlabel": "Upload documents",
  "screen.hr.documents.emptystate.message": "No documents are available for this employee",
  "screen.hr.documents.emptystate.title": "No documents available",
  "screen.hr.documents.enterManually": "Manual data entry",
  "screen.hr.documents.idcard": "Personal&shy;identity card",
  "screen.hr.documents.logTime": "Enter times",
  "screen.hr.documents.needsRelease.notReleased": "waiting for release",
  "screen.hr.documents.needsRelease.released": "released",
  "screen.hr.documents.needsRelease.released.tooltip": "released on {releasedAt} by {name}",
  "screen.hr.documents.nextButton": "To the summary",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "Start uploading your files now, or manually post the non-performance times.",
  "screen.hr.documents.others": "Other",
  "screen.hr.documents.registrationslip": "Proof of Residency",
  "screen.hr.documents.uploadDocuments": "Upload documents",
  "screen.hr.edit.flow.navback": "Personnel / Employees / TODO: Status / {firstName} {lastName}",
  "screen.hr.edit.flow.navheader": "{firstName} {lastName} edit",
  "screen.hr.employee.current.all": "All",
  "screen.hr.employee.current.anyDateOfJoining": "Any entry date",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "Any entry/exit date",
  "screen.hr.employee.current.cancellation": " Deregistrations",
  "screen.hr.employee.current.changeUsername": "New e-mail",
  "screen.hr.employee.current.changeUsername.successDialog.message": "<b>{userName}</b> receives a request to confirm the newly provided e-mail address.",
  "screen.hr.employee.current.changeUsername.successDialog.title": "E-mail for changing the e-mail address has been sent successfully.",
  "screen.hr.employee.current.changeUsername.tooltip": "The employee's new e-mail address is {newUsername}. By clicking on the button, the employee is asked to confirm the new e-mail address.",
  "screen.hr.employee.current.dataChange": "Master data changes",
  "screen.hr.employee.current.notTransferred": "Not transmitted",
  "screen.hr.employee.current.registration": "Registrations",
  "screen.hr.employee.deregistration.data.date": "Exit date",
  "screen.hr.employee.deregistration.data.header": "Logoff data",
  "screen.hr.employee.deregistration.data.reason": "Reason for leaving",
  "screen.hr.employee.deregistration.data.remainingHolidays": "Remaining leave in days",
  "screen.hr.employee.deregistration.data.remainingOvertime": "Open overtime",
  "screen.hr.employee.deregistration.data.subtitle": "If these are available, upload the leaving documents for final settlement.",
  "screen.hr.employee.deregistration.documents.submitButton": "Submit logoff",
  "screen.hr.employee.deregistration.stepper.data": "Logoff data",
  "screen.hr.employee.deregistration.stepper.documents": "Exit documents",
  "screen.hr.employee.details.addFiles": "Add documents",
  "screen.hr.employee.details.currentEmployment": "Current employment relationship",
  "screen.hr.employee.details.deregisterEmployee": "Deregister employee",
  "screen.hr.employee.details.employments": "Employment contracts",
  "screen.hr.employee.details.fileProvidedBy": "provided by {name}",
  "screen.hr.employee.details.fileReleased": "visible for employee",
  "screen.hr.employee.details.linkedFiles": "Linked files",
  "screen.hr.employee.details.navBack": "Staff / {list}",
  "screen.hr.employee.details.pastEmployments": "Previous employment",
  "screen.hr.employee.details.payrollAccount.navBack": "Personnel / Current DN / {fullName}",
  "screen.hr.employee.details.personnelFile": "Personnel file",
  "screen.hr.employee.details.personnelNumber": "Personnel Number:",
  "screen.hr.employee.details.records": "Payslips",
  "screen.hr.employee.details.reregisterEmployee": "Re-register employee",
  "screen.hr.employee.details.showAll": "Show all",
  "screen.hr.employee.details.showEmployeePayrollAccount": "Display payroll account",
  "screen.hr.employee.details.status": "Status",
  "screen.hr.employee.details.weeklyWorkDays": "{weeklyWorkDays} days per week",
  "screen.hr.employee.details.workingHours": "{workingHours} hours per {workingHoursInterval};",
  "screen.hr.employee.documents.all": "All",
  "screen.hr.employee.documents.annualPayslips": "Annual payslip",
  "screen.hr.employee.documents.anyDate": "Any period",
  "screen.hr.employee.documents.backTargetDescription": "Personnel / Employees / {status} / {firstName} {lastName}",
  "screen.hr.employee.documents.certificateOfCitizenship": "Proof of Citizenship",
  "screen.hr.employee.documents.certificatesOfRegistration": "Confirmations of registration",
  "screen.hr.employee.documents.contracts": "Employment Contract",
  "screen.hr.employee.documents.deregisterDocument": "Deregistration documents",
  "screen.hr.employee.documents.employeeDocuments": "Files from {firstName} {lastName}",
  "screen.hr.employee.documents.employmentPermit": "Work permit",
  "screen.hr.employee.documents.generalDocuments": "Other",
  "screen.hr.employee.documents.identityCard": "Identity card",
  "screen.hr.employee.documents.payslips": "Payslips",
  "screen.hr.employee.emptystate.buttonlabel": "Record employees",
  "screen.hr.employee.emptystate.message": "Start now with the registration of new employees",
  "screen.hr.employee.emptystate.title": "Registering employees",
  "screen.hr.employee.former.anyDateOfLeaving": "Any leaving date",
  "screen.hr.employee.registration.stepper.additional": "Supplementary information",
  "screen.hr.employee.registration.stepper.documents": "Upload documents",
  "screen.hr.employee.registration.stepper.personal": "Personnel data",
  "screen.hr.employee.registration.stepper.status": "Step {activeStep} of {totalSteps}",
  "screen.hr.employee.registration.stepper.summary": "Summary",
  "screen.hr.employees.current.registerEmployee": "Register employee",
  "screen.hr.employees.current.uploadPayslip": "Upload payslip",
  "screen.hr.employees.documents.recordType.title": "files uploaded in {date}",
  "screen.hr.employees.documents.title": "Files in personnel file",
  "screen.hr.navbar.documents": "Files",
  "screen.hr.navbar.employees": "Employees",
  "screen.hr.navbar.heading": "Staff",
  "screen.hr.navbar.overview": "Overview",
  "screen.hr.navbar.tabbar.current": "Current Employees",
  "screen.hr.navbar.tabbar.documents": "Files",
  "screen.hr.navbar.tabbar.employees": "Employees",
  "screen.hr.navbar.tabbar.former": "Retired Employees",
  "screen.hr.navbar.tabbar.inprogress": "In process",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "Inquiries ({preRegistrationTicketCount})",
  "screen.hr.overview.card.analysis": "Approval of monthly evaluations for ",
  "screen.hr.overview.card.billing": "Approval of monthly statements for ",
  "screen.hr.overview.card.globalAnalysis": "Approval of company-wide evaluations",
  "screen.hr.overview.card.globalBilling": "Approval of company-wide settlements",
  "screen.hr.overview.card.overview": "Announcement of monthly personnel data for",
  "screen.hr.overview.card.registration": "Employee registration/deregistration",
  "screen.hr.overview.finished.message": "{salutation}, <br /> <br />Thank you for checking and approving the payroll. Please find your reports below.<br /> <br /> Sincerely, <br />{signature}",
  "screen.hr.overview.registration": "{salutation}, <br /> <br /> please provide notice of any changes in service members so that we may complete the registration and deregistration process.<br /> <br /> Sincerely,<br /> {signature}",
  "screen.hr.overview.registration.button": "Registering/deregistering employees",
  "screen.hr.overview.ticketsWelcome.inProgress": "{salutation}, <br /> <br />Thank you for submitting the personnel data. In the course of processing, some questions have arisen:",
  "screen.hr.overview.welcome.closed": "{salutation}, <br /> <br /> Thank you for submitting all relevant personnel data. We will process these in accordance with the agreed deadlines and get back to you in case of any questions.",
  "screen.hr.overview.welcome.inProgress": "{salutation}, <br /> <br /> I request you to furnish me with all necessary personnel data for payroll {period}{dueDate}. <br><br>Sincerely,<br>{signature}",
  "screen.hr.personaldata.address": "Street",
  "screen.hr.personaldata.addressAddition": "Address suffix",
  "screen.hr.personaldata.addressHead": "Address",
  "screen.hr.personaldata.area.validation": "Please enter a location",
  "screen.hr.personaldata.birthdate.validation": "Please enter a date of birth",
  "screen.hr.personaldata.citizenship": "Citizenship",
  "screen.hr.personaldata.citizenship.validation": "Please indicate a nationality",
  "screen.hr.personaldata.city": "Location",
  "screen.hr.personaldata.country": "Country",
  "screen.hr.personaldata.country.validation": "Please select a country",
  "screen.hr.personaldata.dateOfBirth": "Date of birth",
  "screen.hr.personaldata.dateOfJoining": "Entry date",
  "screen.hr.personaldata.description": "The following information is mandatory for registration prior to entering the service.",
  "screen.hr.personaldata.diverse": "Divers",
  "screen.hr.personaldata.employmentInsuranceType": "Insurance Group",
  "screen.hr.personaldata.employmentRelationship": "Employment",
  "screen.hr.personaldata.employmenttypeheader": "type of employment",
  "screen.hr.personaldata.employmenttypeinformationbody": "All gross monthly incomes above the marginal wages threshold are considered to be covered by compulsory full insurance.",
  "screen.hr.personaldata.employmenttypeinformationhead": "Fully Insured/Marginally Employed:",
  "screen.hr.personaldata.employmenttype.validation": "Please select an employment relationship",
  "screen.hr.personaldata.entrydate.invalid": "Invalid entry date",
  "screen.hr.personaldata.entrydate.validation": "Please enter an entry date",
  "screen.hr.personaldata.female": "Female",
  "screen.hr.personaldata.firstname": "First name",
  "screen.hr.personaldata.firstname.validation": "Please enter a first name",
  "screen.hr.personaldata.fullTime": "Fully insured",
  "screen.hr.personaldata.houseNumber": "House number",
  "screen.hr.personaldata.houseNumber.validation": "Please enter a house number",
  "screen.hr.personaldata.insurancedata": "Insurance data",
  "screen.hr.personaldata.insurancedatainformationbody": "If there is no SI number, please leave the social security number field blank.",
  "screen.hr.personaldata.insurancedatainformationhead": "Notice:",
  "screen.hr.personaldata.insurancetype.validation": "Please select an insurance",
  "screen.hr.personaldata.lastname": "Last name",
  "screen.hr.personaldata.lastname.validation": "Please enter a last name",
  "screen.hr.personaldata.male": "Male",
  "screen.hr.personaldata.marginally": "Marginally Employed",
  "screen.hr.personaldata.navback": "Personnel / Employees",
  "screen.hr.personaldata.navheader": "Registering employees",
  "screen.hr.personaldata.nextpage": "Further to additional information",
  "screen.hr.personaldata.notSpecified": "not stated",
  "screen.hr.personaldata.personaldata": "Personnel data",
  "screen.hr.personaldata.personalinformation": "Personal data",
  "screen.hr.personaldata.previouslyEmployed": "Has this person ever been employed by you within the last 12 months?",
  "screen.hr.personaldata.socialsecuritynumber.validation": "Please provide a social security number",
  "screen.hr.personaldata.ssn": "Social security number",
  "screen.hr.personaldata.state.validation": "Please enter a state",
  "screen.hr.personaldata.streetnr.validation": "Please enter an address",
  "screen.hr.personaldata.workplaceState": "State of the place of work",
  "screen.hr.personaldata.zipCode": "POSTCODE",
  "screen.hr.personaldata.zipcode.length.validation": "",
  "screen.hr.personaldata.zipcode.validation": "Please enter a postcode",
  "screen.hr.records.move.multiple.dialog.title": "Move files",
  "screen.hr.records.move.single.dialog.title": "Move file",
  "screen.hr.records.navbar.tab.list": "Files ({count})",
  "screen.hr.records.navbar.tab.tickets": "Inquiries ({count})",
  "screen.hr.records.transfer.dialog.text": "Do you want to transfer <b>all payroll files and information</b> for the period {periodStart} - {periodEnd} to TPA for posting?",
  "screen.hr.records.transfer.dialog.textRecordType": "Do you want to pass <b>all files and information from \"{name}\"</b> for the period {periodStart} - {periodEnd} to TPA for posting?",
  "screen.hr.records.transfer.dialog.title": "Submit files",
  "screen.hr.records.types.header": "Staff",
  "screen.hr.records.upload.button": "Upload files",
  "screen.hr.records.upload.navbar.back": "Personnel / Files",
  "screen.hr.recordTypes.title": "Select type of documents",
  "screen.hr.registration.flow.navback": "step back",
  "screen.hr.registration.flow.navheader": "{firstName} {lastName} log in",
  "screen.hr.summary.additionalinformation": "Supplementary information",
  "screen.hr.summary.bonuses": "Allowances",
  "screen.hr.summary.changeData": "Add to",
  "screen.hr.summary.completeAdditionalData": "You have provided all the supplementary information.",
  "screen.hr.summary.completePersonalData": "You have entered all the mandatory data for registration with the regional health insurance fund.",
  "screen.hr.summary.day": "Day",
  "screen.hr.summary.daysPerWeek": "Days per week",
  "screen.hr.summary.description": "Verify that the information provided is correct to submit to the TPA in the next step.",
  "screen.hr.summary.dialogQuestion1": "Do you want",
  "screen.hr.summary.dialogQuestion2": " and send it to your TPA tax advisor for registration?",
  "screen.hr.summary.documents": "Files",
  "screen.hr.summary.gender": "Gender",
  "screen.hr.summary.grossSalary": "Gross salary in {currency}",
  "screen.hr.summary.hours": "Hours",
  "screen.hr.summary.incompleteAdditionalData": "You have not yet entered all the additional data. Please note that some of the additional data may be required until the first wage payment. You can add these at any time.",
  "screen.hr.summary.month": "Month",
  "screen.hr.summary.name": "{firstName} {lastName}",
  "screen.hr.summary.netSalary": "Net salary in {currency}",
  "screen.hr.summary.noPreregistration": "No master data has been changed. A data transfer is not necessary.",
  "screen.hr.summary.openDialog": "Submit application",
  "screen.hr.summary.summary": "Summary",
  "screen.hr.summary.transferDataChange": "Transmit master data change",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": "{name} has been created and sent to your TPA tax advisor for registration.",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "{name} has been created. You can view and download the ELDA protocol below. Alternatively, the protocol can be downloaded from the employee's personnel file.",
  "screen.hr.summary.week": "Week",
  "screen.login.button.forgot_password": "FORGOTTEN PASSWORD?",
  "screen.login.button.resendCode": "Resend code",
  "screen.login.form.divider": "or",
  "screen.login.form.multiFactorAuth.label": "code",
  "screen.login.form.multiFactorAuth.validation_error": "Please enter the code.",
  "screen.login.form.password.label": "Password",
  "screen.login.form.password.validation_error": "Please enter a password",
  "screen.login.form.submit": "Log in",
  "screen.login.form.title": "Log in",
  "screen.login.form.username.label": "User ID / E-mail address",
  "screen.login.form.username.validation_error": "Please enter your user ID",
  "screen.login.multiFactorAuth": "Please enter the code, which was sent by us via SMS to your phone number.",
  "screen.login.welcome": "Welcome! Please enter your e-mail address.",
  "screen.module.overview.report.empty.message": "No reports have been uploaded yet",
  "screen.module.overview.report.empty.title": "No reports available",
  "screen.module.overview.report.upload": "Upload report",
  "screen.moduleReportUpload.comment": "Message",
  "screen.moduleReportUpload.title": "Upload report",
  "screen.not_found.button.text": "Launch from the cockpit",
  "screen.not_found.header": "404 Error",
  "screen.not_found.message": "We're really good with numbers, but we really wanted to spare you that one.",
  "screen.overview.salutation.female": "Dear Ms {lastName}",
  "screen.overview.salutation.general": "Dear Sir or Madam",
  "screen.overview.salutation.male": "Dear Mr {lastName}",
  "screen.overview.salutation.notSpecified": "Good afternoon {firstName} {lastName}",
  "screen.records.upload.header": "File Upload {recordType}",
  "screen.records.upload.message": "Start uploading the first files now.",
  "screen.records.upload.title": "{recordType} upload",
  "screen.server_error.button.text": "To the cockpit",
  "screen.server_error.header": "505 Error",
  "screen.server_error.message": "We're really good with numbers, but we really wanted to spare you that one.",
  "screen.termsNotAccepted.button": "Back to the company overview",
  "screen.termsNotAccepted.buttonAcceptNow": "Accept now",
  "screen.termsNotAccepted.header": "Acceptance of the terms of use pending",
  "screen.termsNotAccepted.message": "Your company's terms of use have not yet been accepted by all signees. If you have any questions, please contact your TPA consultant.",
  "screen.termsOfUse.accept": "Sign",
  "screen.termsOfUse.alreadySignedInfo": "Terms of use have either already been accepted or acceptance is no longer necessary",
  "screen.termsOfUse.backToLogin": "Back to the login",
  "screen.termsOfUse.checkbox": "I hereby accept the terms of use",
  "screen.termsOfUse.success.sign": "Terms of use successfully accepted",
  "screen.termsOfUse.title": "Accept terms of use",
  "screen.termsOfUseWithCompanyNames.title": "Accept terms of use for [count] company",
  "screen.termsOfUseWithCompanyName.title": "Accept terms of use for {companyName}",
  "screen.tickets.navbar.tabbar.closed": "Completed",
  "screen.tickets.navbar.tabbar.open": "Open",
  "screen.tickets.navbar.tabbar.reported": "Asked by me",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "This Microsoft user is already registered with the e-mail address {existingUsername}",
  "screen.upgradeToMicrosoft.feature.projects.text": "Please upgrade your account to get access to the \"Projects\" feature.",
  "screen.upgradeToMicrosoft.form.code.description": "Please enter the code we sent you via SMS to proceed with the Microsoft upgrade.",
  "screen.upgradeToMicrosoft.form.code.label": "Code",
  "screen.upgradeToMicrosoft.form.password.label": "Password",
  "screen.upgradeToMicrosoft.form.password.validation_error": "Please enter your password",
  "screen.upgradeToMicrosoft.needCompany": "Linking to an MS account can only be performed when a company is selected.",
  "screen.upgradeToMicrosoft.resendButton.label": "Resend code",
  "screen.upgradeToMicrosoft.title": "Link Microsoft account",
  "search.caption.numCompanies": "Company: <b>{count}</b>",
  "search.caption.numDespositedPersons": "Deposited persons: <b>{count}</b>",
  "search.caption.numDocuments": "Files: <b>{count}</b>",
  "search.caption.numPersons": "People: <b>{count}</b>",
  "search.caption.numProjects": "Projects: <b>{count}</b>",
  "search.caption.numResults": "Results: <b>{count}</b>",
  "search.caption.numSelected": "Selected Entries: <b>{count}</b>",
  "search.caption.numTickets": "Inquiries: <b>{count}</b>",
  "search.caption.numUploads": "Uploads: <b>{count}</b>",
  "search.caption.numUsers": "User: <b>{count}</b>",
  "search.placeholder.companies": "Find business ...",
  "search.placeholder.projects": "search for project ...",
  "search.placeholder.search": "Search ...",
  "search.placeholder.searchForActiveUsers": "search active users by name...",
  "search.placeholder.searchForEmployees": "search by name...",
  "search.placeholder.searchForEmployeesPersonalNumber": "search by name or personnel number...",
  "search.placeholder.searchForFileName": "search by file name...",
  "search.placeholder.searchForFiles": "search by filename...",
  "search.placeholder.searchForUsers": "search by name...",
  "search.placeholder.searchForUsersFileName": "search by name or file name...",
  "selectQesPosition.message": "Select a position for the signature, and then click {button}.",
  "selectQesPosition.message.collective": "Select a position for the signature for each signee, then click {button}. ",
  "selectQesPosition.title": "Select signature position",
  "settings.navbar.company": "Company",
  "settings.navbar.companyData": "Master data",
  "settings.navbar.features": "Add-ons",
  "settings.navbar.general": "General",
  "settings.navbar.optionalSideBarItems": "Optional menu items",
  "settings.navbar.userManagement": "User management",
  "settings.notifications.activateAll": "ACTIVATE ALL",
  "settings.notifications.assignments": "Allocations",
  "settings.notifications.deactivateAll": "DEACTIVATE ALL",
  "settings.notifications.deadlineExceeded": "Deadline exceeded",
  "settings.notifications.deadlineReminder": "Deadline reminder",
  "settings.notifications.generalDocumentUpload": "Upload general files",
  "settings.notifications.label": "Email notifications when:",
  "settings.notifications.messages": "Messages",
  "settings.notifications.modules.accounting": "Accounting",
  "settings.notifications.modules.hr": "Staff",
  "settings.notifications.modules.other": "Other areas",
  "settings.notifications.project.items.uploaded": "Project uploads",
  "settings.notifications.recordProvision": "Uploads of the customer",
  "settings.notifications.release": "Approvals",
  "settings.notifications.remindAfterHours.label": "Memory after {hours}h",
  "settings.notifications.reportProvision": "Report Provision",
  "settings.notifications.tickets": "Inquiries",
  "settings.notifications.title": "Notifications",
  "settings.personalData.email": "E-mail address",
  "settings.personalData.language": "Language",
  "settings.personalData.location": "Location",
  "settings.personalData.microsoftAccount": "Microsoft account",
  "settings.personalData.microsoftUpgrade.info": "Microsoft account:<br /><br />You can link your TPA Connect account to your Microsoft account. This allows you to use all the features of TPA Connect.<br /><br />Once you have linked your account with Microsoft, you will need to log in with your Microsoft account from that point on, instead of using your email address.",
  "settings.personalData.microsoftUpgrade.label": "UPGRADE",
  "settings.personalData.phoneNumber": "Phone number",
  "settings.personalData.role": "Role",
  "settings.personalData.title": "Personal data",
  "settings.personalData.username": "Username",
  "settings.userManagement.permissions.existingUser.navbar.back": "User administration / Assign role",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "User management / Assign role / {employeeName}",
  "settings.userManagement.permissions.navbar.back": "User administration / Select contact person / Assign role",
  "settings.userManagement.permissions.navbar.backToUser": "User administration / Select contact person / Assign role / {employeeName}",
  "settings.userManagement.roles.navbar.back": "User administration / Select contact person",
  "sidebar.advisor.employees": "Staff list",
  "sidebar.advisor.inbox": "P.O. Box",
  "sidebar.advisor.tickets": "Inquiries",
  "sidebar.advisor.uploads": "Uploads",
  "sidebar.customerView": "Customer view",
  "sidebar.faceToFace.button": "Contact",
  "sidebar.faceToFace.title": "Face to Face with",
  "sidebar.list.accounting": "Accounting",
  "sidebar.list.analysis": "",
  "sidebar.list.cockpit": "Cockpit",
  "sidebar.list.documents": "Reports",
  "sidebar.list.faceToFace": "Face to Face",
  "sidebar.list.hr": "Staff",
  "sidebar.list.kpi": "Facts & Figures",
  "sidebar.list.personnelFile": "Personnel file",
  "sidebar.list.projects": "Projects",
  "sidebar.list.results": "Results",
  "sidebar.list.settings": "Settings",
  "sidebar.list.support": "Support",
  "sidebar.list.tickets": "Inquiries",
  "signDialog.noQesEnabled.message": "I hereby approve the document.",
  "signDialog.noQesRequired.button.click": "Approve",
  "signDialog.noQesRequired.button.qes": "Electronic signature",
  "signDialog.noQesRequired.message": "For approval, you can choose between approval by click or by electronic signature.",
  "signDialog.qesblocked.message": "Signing by means of an electronic signature is currently not possible because a signature procedure has already been started by another person at this point in time. Please try again in about 15 minutes.",
  "signDialog.qesblocked.title": "Release currently not possible",
  "signDialog.qesRequired.confirm": "OK, sign electronically",
  "signDialog.qesRequired.message": "An electronic signature has been requested for the release. You will now be redirected to the electronic signature.",
  "signDialog.title": "Release",
  "soleEarnerStatus.Alleinerzieher": "Single parent",
  "soleEarnerStatus.Alleinverdiener": "Sole earner",
  "soleEarnerStatus.Nein": "No",
  "staff.documents.navbar.heading": "My files",
  "staff.personnelFile.navbar.heading": "Hello {name}!",
  "stepper.optional": "optional",
  "stepper.progress": "Progress",
  "support.admins.title": "Administrators",
  "support.filter.option.activeChats": "Only active chats",
  "support.filter.option.allChats": "All chats",
  "support.filter.option.inactiveChats": "Only inactive chats",
  "support.filter.setting.showInactiveColleagues": "Display inactive employees",
  "support.inactiveEmployee": "Former employee",
  "support.partners.title": "Partners",
  "support.privateChat.dialog.markAsPrivate.confirm": "Mark as confidential",
  "support.privateChat.dialog.markAsPrivate.message": "When a chat is marked confidential, <b>no other person</b> can view the chat and reply to messages in your absence.<br/><br/>Your customer will <b>not</b> be notified</b> of this setting.",
  "support.privateChat.dialog.markAsPrivate.title": "Mark chat as confidential?",
  "support.privateChat.dialog.markAsPublic.confirm": "abrogate confidentiality",
  "support.privateChat.dialog.markAsPublic.message": "When a chat is no longer marked as confidential, <b>other people</b> can view the chat and reply to messages in your absence.<br/><br/>Your customer is <b>not</b> informed</b> about this setting.",
  "support.privateChat.dialog.markAsPublic.title": "Stop marking chat as confidential?",
  "support.privateChat.info": "Chat is marked as <b>confidential</b> and cannot be viewed by others.",
  "support.privateChat.info.markAsPublic": "UPDATE",
  "support.privateChat.info.mobile": "Chat is <b>confidential</b>.",
  "support.privateChat.placeholder": "Enter message ...",
  "support.responsible.title": "Team members",
  "support.search.navbar.title": "Search results",
  "support.substitute.author": "{substituteName} (substitute for {authorName})",
  "support.substitute.back": "BACK TO MY CHAT",
  "support.substitute.back.mobile": "MY CHAT",
  "support.substitute.warning": "<b>Caution:</b> You are in the chat of <b>{name}</b>.",
  "support.substitute.warning.mobile": "Chat by <b>{name}</b>",
  "support.yourContacts": "Your contact persons",
  "table.button.release": "RELEASE",
  "table.employees.dateOfJoining": "Entry date",
  "table.employees.dateOfJoiningExiting": "Entry/exit date",
  "table.employees.dateOfLeaving": "Exit date",
  "table.employees.dateOfTransmitting": "Transmitted on",
  "table.employees.documents.date": "Date",
  "table.employees.documents.document": "File",
  "table.employees.documents.documentName": "Name",
  "table.employees.documents.downloadDocuments": "Download files",
  "table.employees.documents.uploadedBy": "Uploaded by",
  "table.employees.employee": "Employee",
  "table.employees.jobDescription": "Job title",
  "table.employees.missingDetails": "Missing data",
  "table.employees.personalId": "Personnel number",
  "table.employees.reasonForLeaving": "Reason for leaving",
  "table.employees.status": "Status",
  "table.employees.status.active": "Active",
  "table.employees.status.inactive": "Inactive",
  "table.employees.status.inDataChange": "Master data change",
  "table.employees.status.inDataChangeNotTransferred": "Amendment (draft)",
  "table.employees.status.inDeregistration": "In logout",
  "table.employees.status.inDeregistrationNotTransferred": "Deregistration (draft)",
  "table.employees.status.inRegistration": "In registration",
  "table.employees.status.inRegistrationNotTransferred": "Application (draft)",
  "table.label.changes": "Changes",
  "table.label.changeUsername": "Send email for user name change",
  "table.label.comment": "Comment",
  "table.label.companyName": "Company name",
  "table.label.contactPerson": "Contact",
  "table.label.contactPersonId": "Personal number",
  "table.label.costCenter": "Cost center",
  "table.label.createdAt": "Upload date",
  "table.label.createdAt.variant": "Created on",
  "table.label.createdBy": "Created by",
  "table.label.date": "Date",
  "table.label.dateOfJoining": "Entry date",
  "table.label.dateOfJoiningExiting": "Entry/exit date",
  "table.label.dateOfLeaving": "Exit date",
  "table.label.dateOfTransmitting": "Transmitted on",
  "table.label.deleteUser": "Delete user",
  "table.label.document": "File",
  "table.label.documentName": "Name",
  "table.label.documentType": "File",
  "table.label.downloadDocuments": "Download files",
  "table.label.dueDate": "Deadline",
  "table.label.dueDateForDraft": "Deadline for release",
  "table.label.email": "E-mail address",
  "table.label.employee": "Employee",
  "table.label.file": "File",
  "table.label.fileName": "Name",
  "table.label.folder": "Folder",
  "table.label.holiday": "Holiday",
  "table.label.info": "Info",
  "table.label.jobDescription": "Job title",
  "table.label.lastAuthenticatedAt": "Last login",
  "table.label.lastAuthenticatedAt.registered.tooltip": "User registered",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "User not registered",
  "table.label.lastCreated": "Last created",
  "table.label.members": "Members",
  "table.label.message": "Message",
  "table.label.messages": "News",
  "table.label.missingDetails": "Missing data",
  "table.label.modulePermissions": "Permissions at area level",
  "table.label.modules": "Areas",
  "table.label.needsRelease": "Release required",
  "table.label.openReleases": "Open approvals",
  "table.label.otherAbsence": "Other absence",
  "table.label.personalId": "Personnel number",
  "table.label.personalNumber": "Personnel number",
  "table.label.phoneNumber": "Phone number",
  "table.label.reasonForLeaving": "Reason for leaving",
  "table.label.recordedBy": "Captured from",
  "table.label.release": "Releases",
  "table.label.released": "Transfered",
  "table.label.releasedAt": "Transfered on",
  "table.label.releaseRequestedBy": "Approval provided by",
  "table.label.role": "Role",
  "table.label.sentBy": "Sent from",
  "table.label.sickLeave": "Sick leave",
  "table.label.status": "Status",
  "table.label.teamMessages": "Team News",
  "table.label.ticket": "Inquiry",
  "table.label.ticketBy": "Question asked by",
  "table.label.ticketDescription": "Name",
  "table.label.ticketDueDate": "Deadline",
  "table.label.tickets": "Inquiries",
  "table.label.transferStatus": "Transfer",
  "table.label.transmittedAt": "Transmitted on",
  "table.label.type": "Art",
  "table.label.updatedAt": "Last edited",
  "table.label.updatedAt.variant": "Last modified",
  "table.label.uploadedBy": "Uploaded by",
  "table.label.username": "Username",
  "table.noResults.message": "Your search did not return any results.",
  "table.noResults.title": "No search results",
  "taxAccount.pay.button": "Pay tax debt",
  "taxAccount.transactions.table.filters.date": "Date",
  "taxAccount.transactions.table.filters.tax": "Tax",
  "taxAccount.transactions.table.label.amount": "Amount",
  "taxAccount.transactions.table.label.date": "Date",
  "taxAccount.transactions.table.label.due": "Due date",
  "taxAccount.transactions.table.label.taxPeriod": "Period",
  "taxAccount.transactions.table.label.text": "Name",
  "taxAccount.transactions.table.search.count": "Transactions: <b>{count}</b>",
  "taxAccount.transactions.table.search.placeholder": "Search for transactions",
  "taxAccount.transactions.title": "Balance tax account: [value]",
  "termsOfUse.checkbox.error": "Please accept the terms of use",
  "termsOfUse.upcoming.navbar.button": "Accept",
  "termsOfUse.upcoming.navbar.message": "The terms of use of the TPA Connect app will change from {date}.",
  "ticketList.emptystate.title": "No inquiries available",
  "unpaidAccounts.invoices.pay": "Pay",
  "unpaidAccounts.invoices.table.filters.date": "Document date",
  "unpaidAccounts.invoices.table.label.documentDate": "Document date",
  "unpaidAccounts.invoices.table.label.documentNumber": "Document number",
  "unpaidAccounts.invoices.table.label.documentText": "Document text",
  "unpaidAccounts.invoices.table.label.netAmount": "Net amount",
  "unpaidAccounts.invoices.table.label.opAmount": "Open amount",
  "unpaidAccounts.invoices.table.label.receiptDate": "Document date",
  "unpaidAccounts.invoices.table.label.receiptNumber": "Document number",
  "unpaidAccounts.invoices.table.label.receiptText": "Document text",
  "unpaidAccounts.invoices.table.search.count": "Invoices: <b>{count}</b>",
  "unpaidAccounts.invoices.table.search.placeholder": "Search for invoices",
  "unpaidAccounts.invoices.title": "Outstanding invoices at TPA: [value]",
  "upload.category.analysis": "Report: Monthly evaluation",
  "upload.category.annualReports": "Annual accounts",
  "upload.category.articles": "Elaborations",
  "upload.category.billing": "Report: Monthly billing",
  "upload.category.certificateOfCitizenship": "Proof of Citizenship",
  "upload.category.certificateOfRegistration": "Confirmation of registration",
  "upload.category.contractOfEmployment": "Contract",
  "upload.category.contracts": "Contracts",
  "upload.category.correspondence": "Correspondence",
  "upload.category.dataChangeConfirmation": "Master data change",
  "upload.category.deregisterDocument": "Deregistration documents",
  "upload.category.deregistrationConfirmation": "Employee deregistration",
  "upload.category.employmentPermit": "Work permit",
  "upload.category.identityCard": "Identity card",
  "upload.category.information": "Information",
  "upload.category.notices": "Notices",
  "upload.category.other": "Other",
  "upload.category.payslip": "Payslip",
  "upload.category.registrationConfirmation": "Employee registration",
  "upload.category.report": "Reports",
  "upload.category.taxOffice": "Tax office",
  "upload.category.taxReturn": "Tax returns",
  "userManagement.successDialog.changeUsername.message": "<b>{userName}</b> will receive a request to confirm the newly announced email address.",
  "userManagement.successDialog.deleteUser.single.message": "<b>{userName}</b> was successfully deleted.",
  "userManagement.successDialog.deleteUser.title": "User has been deleted successfully.",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "Settings for <b>{count} users</b> have been applied.",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "Settings for <b>{userName}</b> have been applied.",
  "userManagement.successDialog.existingUser.superAdmin.title": "User settings successfully saved",
  "userManagement.successDialog.existingUser.title": "User settings successfully proposed",
  "userManagement.successDialog.message": "Your proposal must now be approved by a <b>Super Admin</b>.",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "<b>{count} users</b> will receive an invitation via email.",
  "userManagement.successDialog.newUser.superAdmin.single.message": "<b>{userName}</b> will receive an invitation via email.",
  "userManagement.successDialog.newUser.superAdmin.title": "User successfully invited",
  "userManagement.successDialog.newUser.title": "User successfully proposed",
  "userManagement.successDialog.rejectUser.title": "User successfully rejected",
  "userManagement.successDialog.releaseExistingUser.title": "User successfully approved",
  "userManagement.successDialog.releaseNewUser.title": "User successfully approved and invited",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "Email for username change was sent successfully.",
  "userProfile.createBmdTodos": "Create tasks in BMD for inquiries in TPA Connect",
  "userProfile.deleteAccount.message": "Are you sure you want to delete your account? This action cannot be undone!",
  "userProfile.deleteAccount.title": "Delete account",
  "userProfile.multiFactorAuth": "Enable two-factor authentication with phone number",
  "userProfile.navbar.heading": "Profile",
  "userProfile.upgrade.navbar.heading": "Upgrade",
  "userProfile.userInterests.title": "Interests",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "Please approve the new users to invite them.",
  "userReleaseInfoAdminDialog.releaseInfo.single": "Please approve the new user to invite them.",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "{userName} has submitted <b>{count} new users</b>.",
  "userReleaseInfoAdminDialog.releaseMessage.single": "{userName} has submitted <b>{count} new user</b>.",
  "userReleaseInfoAdminDialog.submit": "View and approve",
  "userReleaseInfoAdminDialog.title": "Approve and invite users",
  "userReleaseInfoDialog.cancel": "To the user administration",
  "userReleaseInfoDialog.from": "from",
  "userReleaseInfoDialog.message": "Your proposed changes to the following users have been approved/rejected:",
  "userReleaseInfoDialog.rejected": "rejected",
  "userReleaseInfoDialog.released": "approved",
  "userReleaseInfoDialog.title": "Change of users",
  "viewer.unsupported.message": "{name} cannot be displayed at the moment.",
  "webhook.employee.noSearch": "Search input required",
  "webhook.employee.placeholder": "Name of the employee",
  "webhook.noCompanies": "No company permissions ",
  "webhook.tickets.button": "Create inquiry",
  "webhook.tickets.info": "Assign the document to an area and select the category",
  "webhook.tickets.navbar": "Inquiry concerning {document}",
  "webhook.tickets.taskTitle": "Task for {name}",
  "webhook.tickets.title": "Create inquiry",
  "webhook.uploads.navbar": "Submit files",
  "welcomeCard.accounting.addMoreRecords.button": "Enter additional documents",
  "welcomeCard.accounting.addRecords.button": "enter documents",
  "welcomeCard.hr.addMoreRecords.button": "Transmit additional files",
  "welcomeCard.hr.addRecords.button": "Submit files",
  "texterify_timestamp": "2024-11-18T09:59:55Z"
} as const;

export { english };
