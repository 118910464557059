import * as React from "react";
import { t } from "../../i18n/util";
import { IDocument } from "../../types/models";
import { formatDate } from "../../util/helpers";
import { FileIcon } from "../util/FileIcon";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { SplitContainer } from "./SplitContainer";
import { Thumbnails } from "./Thumbnails";

export const DocumentDetails = (props: {
    document: Partial<IDocument>;
    current?: number;
    updatedAt?: Date;
    total?: number;
    onDelete?: () => void;
    onEdit?: () => void;
    onDownload?: () => void;
    children: React.ReactNode;
}) => {
    const isMobile = React.useContext(MobileContext);

    return (
        <SplitContainer
            splitPercentage={40}
            style={{ alignItems: "center" }}
            leftChildren={document && <Thumbnails document={props.document} />}
        >
            <div
                style={{
                    marginLeft: isMobile ? undefined : 32,
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    maxWidth: isMobile ? undefined : 500,
                    wordBreak: "break-word",
                }}
            >
                {props.current && props.total && props.total > 1 && (
                    <h4 style={{ color: customColors.body2Dark }}>
                        {`${props.current}/${props.total} ${t("accounting.details.upload.files")}`}
                    </h4>
                )}

                {props.document && (
                    <>
                        <h1 style={{ marginTop: 24 }}>{props.document.name ?? "DOCUMENT_NAME"}</h1>
                        <div style={{ display: "flex", alignItems: "center", marginTop: 28, marginBottom: 16 }}>
                            <FileIcon name={props.document.name} style={{ marginRight: 16 }} />
                            <p style={{ flexGrow: 1, fontWeight: "bold" }}>{props.document.name ?? "DOCUMENT_NAME"}</p>
                            <div>
                                <Icon
                                    name="pen"
                                    data-id="edit_icon"
                                    style={{ cursor: "pointer", display: "block" }}
                                    onClick={props.onEdit}
                                />
                            </div>
                            <div style={{ marginLeft: 16 }}>
                                <Icon
                                    name="delete"
                                    data-id="delete_icon"
                                    style={{ cursor: "pointer", display: "block" }}
                                    onClick={props.onDelete}
                                />
                            </div>
                            <div style={{ marginLeft: 16 }}>
                                <Icon
                                    name="download"
                                    data-id="download_icon"
                                    style={{
                                        cursor: props.onDownload ? "pointer" : undefined,
                                        display: "block",
                                        color: !props.onDownload ? customColors.greyTextfields : undefined,
                                    }}
                                    onClick={props.onDownload}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
                            {props.updatedAt && (
                                <p>
                                    {t("accounting.details.updatedAt")} {formatDate(props.updatedAt)}
                                </p>
                            )}
                        </div>
                    </>
                )}

                {props.children}
            </div>
        </SplitContainer>
    );
};
