import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { ISubsidiary, Module } from "../../types/models";

// Helper hook, don't use directly, use one of the variants below
const useSubsidiaries = (p: {
    companyId?: string;
    module?: Module | null | "";
    periodId?: string;
    periodNeeded?: boolean;
    withRecordTypes?: boolean;
    allSubsidiaries?: boolean; // if all subsidiaries then also load those from the past
}) => {
    const [subsidiaries, setSubsidiaries] = React.useState<ISubsidiary[]>([]);

    React.useEffect(() => {
        const load = async () => {
            // We need a periodId, but don't have it yet
            const missingPeriod = p.periodNeeded && !p.periodId;
            if (!p.companyId || !p.module || missingPeriod) {
                return;
            }

            const companyId = p.companyId;

            try {
                generalStore.isLoading = true;
                const response: ISubsidiary[] = await API.getSubsidiaries({
                    companyId,
                    module: p.module,
                    periodId: p.periodId,
                    all: p.allSubsidiaries,
                });

                if (p.withRecordTypes && response && response.length > 0) {
                    await Promise.all(
                        response.map(async subsidiary => {
                            if (p.module) {
                                subsidiary.recordTypes = await API.getSubsidiaryRecordTypes({
                                    companyId,
                                    module: p.module,
                                    subsidiaryId: subsidiary.id,
                                });
                            }
                        }),
                    );
                }

                setSubsidiaries(response);
            } catch (err) {
                generalStore.setError(t("error.loadSubsidiaries"), err);
            } finally {
                generalStore.isLoading = false;
            }
        };

        load();
    }, [p.allSubsidiaries, p.companyId, p.module, p.periodId, p.periodNeeded, p.withRecordTypes]);

    return {
        subsidiaries,
        reset: () => {
            setSubsidiaries([]);
        },
    };
};

// Get subsidiaries on company level independent of period (e.g. during company configuration)
export const useCompanySubsidiaries = (p: {
    companyId?: string;
    module?: Module | null | "";
    withRecordTypes?: boolean;
    allSubsidiaries?: boolean; // if allSubsidiaries then also load those from the past
}) => {
    return useSubsidiaries(p);
};

// Get subsidiaries for a period (e.g. for displaying accounting subsidiaries for a specific period)
export const usePeriodSubsidiaries = (p: {
    companyId?: string;
    module?: Module | null | "";
    periodId?: string;
    withRecordTypes?: boolean;
}) => {
    return useSubsidiaries({ ...p, periodNeeded: true });
};
