import { useCallback } from "react";
import { API } from "../../../network/API";
import { useAPI } from "../../hooks/useAPI";

interface UseAccountProps {
    companyId: string;
    financialAccountancyId: string;
    accountNr: number;
}

export function useAccount({ companyId, financialAccountancyId, accountNr }: UseAccountProps) {
    const loader = useCallback(async () => {
        const response = await API.getAccount({
            companyId,
            financialAccountancyId,
            accountNr,
        });
        return response.account;
    }, [accountNr, companyId, financialAccountancyId]);

    return useAPI(loader, {
        generalStoreErrorMessage: "error.loadAccount",
    });
}
