import * as React from "react";
import readingTime from "reading-time";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { EventsFeedEntry, NewsFeedEntry } from "../../network/APITypes";
import { formatDate } from "../../util/helpers";
import { useResize } from "../hooks/useResize";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";

const PLACEMENT_FIX = 2;
const CATEGORY_MARGIN = 16;

const CategoryTag = styled.div`
    display: inline-block;
    border: 1px solid #797979;
    border-radius: 4px;
    padding: 5px 6px 3px 6px;
    white-space: nowrap;
`;

const NewsInformationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
`;

const ReadingTimeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

interface NewsInformationProps {
    details: NewsFeedEntry | EventsFeedEntry;
    style?: React.CSSProperties;
    hidePublishDate?: boolean;
    hideReadingTime?: boolean;
}

export const CockpitNewsInformation = ({ details, hidePublishDate, hideReadingTime, style }: NewsInformationProps) => {
    const isMobile = React.useContext(MobileContext);
    const [hasTags, setHasTags] = React.useState(false);

    const tagContainerRef = React.useRef<HTMLDivElement | null>(null);
    const newsContainerRef = React.useRef<HTMLDivElement | null>(null);

    const tagContainerDimensions = useResize(tagContainerRef);

    React.useLayoutEffect(() => {
        if (tagContainerRef.current) {
            const tags = tagContainerRef.current.children;

            let tagCount = 0;

            if (tags) {
                (Array.from(tags) as HTMLDivElement[]).forEach(tag => {
                    const tagDimensions = tag.getBoundingClientRect();

                    if (tagContainerDimensions.right < tagDimensions.right + CATEGORY_MARGIN) {
                        tag.style.visibility = "hidden";
                    } else {
                        tag.style.visibility = "";
                        tagCount += 1;
                    }
                });
            }

            setHasTags(tagCount > 0);
        }
    }, [tagContainerDimensions]);

    return (
        <NewsInformationContainer ref={newsContainerRef} className="caption" style={style}>
            <div
                ref={tagContainerRef}
                style={{
                    overflow: "hidden",
                    flexGrow: 1,
                    whiteSpace: "nowrap",
                }}
            >
                {"categories" in details &&
                    details.categories?.map((category, index) => (
                        <CategoryTag
                            key={category}
                            style={{
                                marginLeft: index === 0 ? undefined : 8,
                            }}
                        >
                            {category}
                        </CategoryTag>
                    ))}
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: hasTags ? "flex-end" : "flex-start",
                }}
            >
                {!hidePublishDate && (
                    <div style={{ textAlign: "center", marginTop: PLACEMENT_FIX }}>
                        {formatDate(details.publishedAt)}
                    </div>
                )}
                {!hideReadingTime && (
                    <ReadingTimeContainer style={{ marginLeft: 16 }}>
                        <div>
                            <Icon name="hours" style={{ display: "block", marginRight: 8 }} />
                        </div>
                        <span style={{ marginTop: PLACEMENT_FIX, whiteSpace: "nowrap" }}>
                            {t(isMobile ? "common.readingTimeMinutes.short" : "common.readingTimeMinutes", {
                                time: Math.ceil(readingTime(details.content).minutes),
                            })}
                        </span>
                    </ReadingTimeContainer>
                )}
            </div>
        </NewsInformationContainer>
    );
};
