import { customColors } from "../components/util/Theme";

export type Status = "open" | "inProgress" | "closed" | "overdue";

export const statusColors = {
    open: {
        color: customColors.orangeOpen,
        background: customColors.orangeOpenBackground,
    },
    inProgress: {
        color: customColors.yellowInProgress,
        background: customColors.yellowBackgroundInProgress,
    },
    closed: {
        color: customColors.blueFinished,
        background: customColors.blueFinishedBackground,
    },
    overdue: {
        color: customColors.redUrgent,
        background: customColors.redUrgentBackground,
    },
};
