import { Tab, Tabs } from "@material-ui/core";
import Decimal from "decimal.js";
import { LocationDescriptor } from "history";
import compact from "lodash/compact";
import { observer } from "mobx-react";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { Account } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useInMemoryTableStore, useTableStore } from "../../../stores/TableStore";
import { UseParams } from "../../app/router/history";
import { CenteredContent } from "../../ui/CenteredContent";
import { EmptyState } from "../../ui/EmptyState";
import { GridTable, GridTableColumn, GridTableRef, createExpandColumn } from "../../ui/GridTable";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { customColors } from "../../util/Theme";
import { ResultsNavigationState } from "../ResultsTable";
import { Currency } from "../ResultsValue";
import { useAccount } from "../hooks/useAccount";
import { EnhancedAccountTransaction, useAccountTransactions } from "../hooks/useAccountTransactions";
import { useResultsAccountSheetTableFilters } from "../hooks/useResultsAccountSheetTableFilters";
import {
    ResultsAccountSheetNavigationState,
    useResultsAccountSheetTableRenderCell,
} from "../hooks/useResultsAccountSheetTableRenderCell";
import { useResultsAccountSheetTableRenderExpanded } from "../hooks/useResultsAccountSheetTableRenderExpanded";
import { ResultsRoutes } from "../router/ResultsRoutes";
import { formatAccountNumber } from "../utils";

export const ResultsAccountSheetSite = observer(function ResultsAccountSheetSite() {
    const companyId = companiesStore.selectedCompanyId;

    const params = useParams<UseParams<typeof ResultsRoutes.ACCOUNT_SHEET>>();

    const { financialAccountancyId } = params;
    const accountNr = params.accountNr && parseInt(params.accountNr, 10);

    if (!companyId || !financialAccountancyId || !accountNr || isNaN(accountNr)) {
        return null;
    }

    return (
        <ResultsAccountSheet
            companyId={companyId}
            financialAccountancyId={financialAccountancyId}
            accountNr={accountNr}
        />
    );
});

interface ResultsAccountSheetProps {
    companyId: string;
    financialAccountancyId: string;
    accountNr: number;
}

const ResultsAccountSheet = observer(function ResultsAccountSheet(props: ResultsAccountSheetProps) {
    const { companyId, financialAccountancyId, accountNr } = props;

    const location = useLocation<ResultsNavigationState | ResultsAccountSheetNavigationState | undefined>();
    const { state } = location;

    const [selectedTab, setSelectedTab] = useState<"all" | "open">(
        state && "initialTab" in state ? (state.initialTab ?? "all") : "all",
    );

    const handleTabChange = (_: unknown, tab: "all" | "open") => {
        setSelectedTab(tab);
    };

    const createTabs = (showOpenTab: boolean) => {
        return (
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="off"
            >
                <Tab label={t("results.accountSheet.tabs.all")} value="all" data-id="account-sheet-all" />
                {showOpenTab ? (
                    <Tab label={t("results.accountSheet.tabs.open")} value="open" data-id="account-sheet-open" />
                ) : null}
            </Tabs>
        );
    };

    let backLabel = t("results.title");
    let backTarget: LocationDescriptor = ResultsRoutes.ROOT;
    let documentId: number | undefined = undefined;

    switch (state?.type) {
        case "results":
            backLabel = state.resultsType
                ? t(`results.accountSheet.backLabel.results.${state.resultsType}`)
                : backLabel;
            backTarget = state.location ?? backTarget;
            break;
        case "accountSheet":
            backLabel = state.account.number
                ? t("results.accountSheet.backLabel.accountSheet", {
                      accountNr: formatAccountNumber(state.account.number),
                      name: state.account.name,
                  })
                : backLabel;
            backTarget = state.location ?? backTarget;
            documentId = state.documentId;
            break;
        default:
            break;
    }

    const result = useAccount({ companyId, financialAccountancyId, accountNr });
    if (result.state !== "success") {
        const tabs = createTabs(true);
        return (
            <NavBarBack backLabel={backLabel} backTarget={backTarget} tabs={tabs} showCancel={false} title="&nbsp;" />
        );
    }

    const account = result.data;

    let showOpenTab = true;
    if (!account.hasOpenItems) {
        showOpenTab = false;
        if (selectedTab === "open") {
            setSelectedTab("all"); // the open tab does not exist in this case, switch to "all"
        }
    }

    const tabs = createTabs(showOpenTab);

    return (
        <>
            <NavBarBack
                backLabel={backLabel}
                backTarget={backTarget}
                title={t("results.accountSheet.title", {
                    accountNr: formatAccountNumber(account.number),
                    name: account.name,
                })}
                tabs={tabs}
                showCancel={false}
            />
            <CenteredContent>
                <SiteContent>
                    <ResultsAccountSheetTable
                        companyId={companyId}
                        financialAccountancyId={financialAccountancyId}
                        account={account}
                        selectedTab={selectedTab}
                        documentId={documentId}
                    />
                </SiteContent>
            </CenteredContent>
        </>
    );
});

const headerStyle: React.CSSProperties = { top: `var(--navBarHeight)` };
function createFields(account: Account): GridTableColumn<EnhancedAccountTransaction>[] {
    return compact([
        {
            column: "postingSymbol",
            label: "results.accountSheet.columns.postingSymbol",
            headerStyle,
            gridTemplateColumn: "min-content",
        },
        {
            column: "documentNumber",
            label: "results.accountSheet.columns.documentNumber",
            headerStyle,
            gridTemplateColumn: "min-content",
            align: "right",
        },
        {
            column: "postingText",
            label: "results.accountSheet.columns.postingText",
            headerStyle,
            gridTemplateColumn: "1fr",
        },
        {
            column: "documentDate",
            label: "results.accountSheet.columns.documentDate",
            headerStyle,
            gridTemplateColumn: "min-content",
        },
        {
            column: "amount",
            label: "results.accountSheet.columns.amount",
            align: "right",
            headerStyle,
            gridTemplateColumn: "min-content",
        },
        !account.hasOpenItems
            ? null // for this type of account the openItemAmount === amount - always! No need to display it then.
            : {
                  column: "openItemAmount",
                  label: "results.accountSheet.columns.openItemAmount",
                  align: "right",
                  headerStyle,
                  gridTemplateColumn: "min-content",
              },
        {
            column: "documents",
            label: "results.accountSheet.columns.documents",
            align: "right",
            headerStyle,
            gridTemplateColumn: "min-content",
        },
        {
            column: "offsettingAccounts",
            label: "results.accountSheet.columns.offsettingAccounts",
            align: "right",
            headerStyle,
            gridTemplateColumn: "min-content",
            sort: false,
        },
        createExpandColumn<EnhancedAccountTransaction>({ headerStyle }),
    ]);
}

const emptyAccountTransactions: EnhancedAccountTransaction[] = [];

interface ResultsAccountSheetTableProps {
    companyId: string;
    financialAccountancyId: string;
    account: Account;
    selectedTab: "all" | "open";
    documentId?: number;
}

const ResultsAccountSheetTable = observer(function AccountSheetTable({
    companyId,
    financialAccountancyId,
    account,
    selectedTab,
    documentId,
}: ResultsAccountSheetTableProps) {
    const accountNr = account.number;
    const tableStore = useTableStore<EnhancedAccountTransaction>("AccountSheet", {
        orderBy: "documentDate",
        orderDir: "asc",
    });

    const isOpenOnly = selectedTab === "open";

    const fields = useMemo(() => createFields(account), [account]);

    let accountTransactions = emptyAccountTransactions;

    const { result, loadedResult } = useAccountTransactions({
        companyId,
        financialAccountancyId,
        accountNr,
        tableStore,
        isOpenOnly,
    });
    if (result.state === "success") {
        accountTransactions = result.data;
    }

    const { filterFn, tableFilters } = useResultsAccountSheetTableFilters(accountTransactions);
    const renderCell = useResultsAccountSheetTableRenderCell(companyId, financialAccountancyId, account);
    const renderExpanded = useResultsAccountSheetTableRenderExpanded();

    useInMemoryTableStore({
        tableStore,
        items: accountTransactions,
        filterFn,
    });

    const gridTableRef = useRef<GridTableRef | null>(null);

    // clicking on an offsetting account will set the documentId *before* the new account transactions are loaded.
    // this will unnecessary scroll and highlight the currently clicked account transaction. moving it into a ref
    // removes it from the dependency array below and the effect only reacts on newly loaded account transactions.
    const documentIdRef = useRef(documentId);
    documentIdRef.current = documentId;

    useLayoutEffect(() => {
        const documentId = documentIdRef.current;
        if (!documentId) {
            return;
        }
        // do not use `result` here because it changes whenever the user user searches or changes a filter.
        // this would unnecessarily scroll on those changes too.
        if (loadedResult.state !== "success" || !gridTableRef.current) {
            return;
        }

        // find the account transaction that matches the documentId
        const accountTransactions = loadedResult.data.filter(at => {
            return at.documentId === documentId;
        });

        if (accountTransactions.length === 0) {
            return;
        }

        gridTableRef.current.focus(accountTransactions[0].id);
    }, [loadedResult]);

    const isEmpty = tableStore.getIsNoResultState(
        generalStore.isLoading,
        tableStore.items.length !== accountTransactions.length || isOpenOnly,
    );

    return (
        <>
            <TableSearchBarWithAction
                label="results.accountSheet.search.count"
                placeholder="results.accountSheet.search.placeholder"
                search={tableStore.search}
                totalCount={tableStore.items.length}
                onChangeSearch={tableStore.handleSearchChange}
                tableFilters={tableFilters}
            />
            <GridTable
                columns={fields}
                tableStore={tableStore}
                renderCell={renderCell}
                renderExpanded={renderExpanded}
                gridTableRef={gridTableRef}
                renderFooterCell={(items, column) => {
                    if (column.column === "amount") {
                        return (
                            <Currency
                                style={{ fontWeight: "bold", color: customColors.primaryColor }}
                                value={items.reduce((sum, transaction) => sum.add(transaction.amount), new Decimal(0))}
                            />
                        );
                    }
                    if (column.column === "openItemAmount") {
                        return (
                            <Currency
                                style={{ fontWeight: "bold", color: customColors.primaryColor }}
                                value={items.reduce(
                                    (sum, transaction) => sum.add(transaction.openItemAmount ?? 0),
                                    new Decimal(0),
                                )}
                            />
                        );
                    }
                    if (column.column === "postingSymbol") {
                        return (
                            <span style={{ fontWeight: "bold", color: customColors.primaryColor }}>
                                {t("results.accountSheet.sum.title")}
                            </span>
                        );
                    }
                    return null;
                }}
            />
            {isEmpty &&
                (isOpenOnly ? (
                    <EmptyState
                        title={t("results.accountSheet.noResults.open.title")}
                        message={t("results.accountSheet.noResults.open.message")}
                    />
                ) : (
                    <EmptyState
                        title={t("results.accountSheet.noResults.title")}
                        message={t("results.accountSheet.noResults.message")}
                    />
                ))}
        </>
    );
});
