import { Button } from "@material-ui/core";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { Icon } from "../../util/Icon";
import { AuthGeneralContainerSite } from "./AuthGeneralContainerSite";

export const AuthForgotPasswordConfirmSite = () => {
    const handleBack = () => {
        pushRoute(Routes.ROOT);
    };

    return (
        <AuthGeneralContainerSite>
            <Icon style={{ width: 200, height: 130 }} name="success" />

            <h1 style={{ marginBottom: 16 }}>{t("screen.auth.forgot_password_confirm.title")}</h1>
            <p style={{ marginBottom: 10 }}>
                {t("screen.auth.forgot_password_confirm.subtitle.section_1", { email: authStore.forgotPasswordEmail })}
            </p>
            <p style={{ marginBottom: 32 }}>{t("screen.auth.forgot_password_confirm.subtitle.section_2")}</p>

            <Button variant="contained" color="primary" onClick={handleBack}>
                {t("button.back_to_login")}
            </Button>
        </AuthGeneralContainerSite>
    );
};
