import compact from "lodash/compact";
import * as React from "react";
import { getJobTitle } from "../../i18n/util";
import { companiesStore } from "../../stores/CompaniesStore";
import { Module } from "../../types/models";
import { User } from "../ui/User";

export const ModuleMainResponsible = ({ module, style }: { module: Module; style?: React.CSSProperties }) => {
    const mainResponsible = companiesStore.mainResponsible[module];
    // const moduleStore = getModuleStore(module);

    const jobTitle = compact([
        // TPAPORTAL-2414: use job title only
        // getRoleString({
        //     roles: mainResponsible.roles,
        //     fallback: moduleStore.t(module === "accounting" ? "common.accounting" : "common.hr"),
        //     gender: mainResponsible.gender,
        // }),
        getJobTitle(mainResponsible.tpaJobTitles, mainResponsible.gender),
    ]).join(", ");

    return (
        <User
            isTpaStaff={mainResponsible.isTpaEmployee}
            firstName={mainResponsible.firstName}
            lastName={mainResponsible.lastName}
            imageUrl={mainResponsible.profile_picture_url}
            role={jobTitle}
            style={style}
        />
    );
};
