import compact from "lodash/compact";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { useSuccessDialog } from "../hooks/useSuccessDialog";

export const useCloseTicket = (companyId?: string, onClose?: () => void) => {
    const successDialog = useSuccessDialog({
        title: t("dialog.closeTicket.success.header"),
        onClose,
    });

    const close = async (ticketIds?: (string | undefined)[]) => {
        if (!companyId || !ticketIds) {
            return;
        }

        const cleaned = compact(ticketIds);
        if (cleaned.length === 0) {
            return;
        }

        try {
            await API.putCloseTickets(companyId, cleaned);

            // Update badge count
            if (companyId === companiesStore.selectedCompanyStore?.id) {
                await companiesStore.selectedCompanyStore.startPollingBadges();
            }
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        }

        successDialog.openDialog();
    };

    return {
        successDialog,
        close,
    };
};
