import { observer } from "mobx-react";
import { sideBarStore } from "../../../stores/SideBarStore";
import { Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";

export const MobileMenuButton = observer(function MobileMenuButton() {
    const handleToggleMobileMenu = () => {
        sideBarStore.mobileMenuOpen = !sideBarStore.mobileMenuOpen;
    };

    return (
        <Icon
            name="menue"
            style={{ display: "block", color: customColors.primaryColor, cursor: "pointer" }}
            onClick={handleToggleMobileMenu}
        />
    );
});
