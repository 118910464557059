import { LocationDescriptor, LocationState } from "history";
import * as queryString from "query-string";
import { generalStore } from "../../../stores/GeneralStore";
import { Flatten } from "../../../util/ts";
import { browserHistory as history } from "./browserHistory";

export { history };

/**
 * Converts an object to a query string including the leading "?".
 */
export function toQueryString(params: object) {
    return "?" + queryString.stringify(params);
}

export function withQuery(route: string, params: object) {
    return route + toQueryString(params);
}

/**
 * Turns a url with params into a dict with the params as keys and the provided `Value` as value.
 *
 * @example
 * type Foo = ToParams<"prefix/:foo/:bar/suffix", string>;
 * //   Foo = { foo: string; bar: string }
 */
type ToParams<T extends string, Value = never> = T extends `${string}:${infer Param}/${infer Rest}`
    ? Flatten<Record<Param, Value> & ToParams<Rest, Value>>
    : T extends `${string}:${infer Param}`
      ? Flatten<Record<Param, Value>>
      : Record<never, string>;

/**
 * Turns a url with params into a dict with the params as keys.
 *
 * @example
 * type Foo = WithParams<"prefix/:foo/:bar/suffix">;
 * //   Foo = { foo: string | number | boolean; bar: string | number | boolean }
 */
export type WithParams<T extends string> = ToParams<T, string | number | boolean>;

/**
 * Turns a url with params into a dict with the params as keys and string as values.
 * Can be used with the `useParams` hook from `react-router`
 *
 * Note: the value deliberately is `string | undefined` to defend against broken URLs.
 *
 * @example
 * type Foo = UseParams<"prefix/:foo/:bar/suffix">;
 * //   Foo = { foo: string | undefined; bar: string | undefined }
 */
export type UseParams<T extends string> = ToParams<T, string | undefined>;

export function withParams<T extends string>(route: T, params: WithParams<T>): string {
    const keys = Object.keys(params) as (keyof typeof params)[];

    const routeParams = route.split("/").filter(component => component.startsWith(":"));
    // Look for params that are not defined in the route params
    const notFound = keys.filter(key => !routeParams.includes(`:${key.toString()}`));
    if (notFound.length > 0) {
        throw new Error(`Invalid URL parameters "${notFound.toString()}" for route "${route}". Maybe a typo?`);
    }

    return keys.reduce<string>((url, key) => {
        const param = `:${key.toString()}`;
        const value = params[key];
        return url.replace(param, value?.toString() ?? "");
    }, route);
}

// Not unified as optional parameter to e.g. withParams to allow for better searchability
export function withParamsAndQuery<T extends string>(route: T, params: WithParams<T>, query: object) {
    return withQuery(withParams(route, params), query);
}

// If user is e.g. in accounting then changing company will make the current subpage
// useless -> go back to the modules root location
export function goToRootLocation() {
    const rootLocation = history.location.pathname.split("/")[1];
    if (rootLocation === "accounting" || rootLocation === "hr" || rootLocation === "settings") {
        pushRoute(`/${rootLocation}`);
    }
}

interface IRouteOptions<T extends string> {
    params?: WithParams<T>;
    query?: object;
    state?: LocationState;
}

export function pushRoute<T extends string>(route: T, options?: IRouteOptions<T>, replace?: boolean) {
    let url: string = route;
    if (options?.params) {
        url = withParams(url as T, options.params);
    }
    if (options?.query) {
        url = withQuery(url, options.query);
    }

    // debug.log("### pushRoute", url);
    if (replace !== true) {
        history.push(url, options?.state);
    } else {
        history.replace(url, options?.state);
    }
}

export function replaceRoute<T extends string>(route: T, options?: IRouteOptions<T>) {
    pushRoute(route, options, true);
}

export function pushLocation(location: LocationDescriptor) {
    if (typeof location === "string") {
        history.push(location);
    } else {
        history.push(location);
    }
}

// Safe goBack function that goes back if there is a back route and uses
// fallback otherwise
export function goBack<T extends string>(fallbackRoute: T, options?: IRouteOptions<T>) {
    if (generalStore.lastLocation) {
        history.goBack();
    } else {
        pushRoute(fallbackRoute, options);
    }
}
