import { FormikHelpers } from "formik";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { getApiError, getValidationError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { IEmployee } from "../../../types/models";
import { getMinMaxWorkingHours } from "../../../util/helpers";
import { pushRoute, withParamsAndQuery, withQuery } from "../../app/router/history";
import { useEmployee } from "../../hooks/useEmployee";
import { useQueryParams } from "../../hooks/useQueryParams";
import { customColors } from "../../util/Theme";
import { HrAdditionalDataForm } from "../HrAdditionalDataForm";
import { HrRegistrationSite } from "../HrRegistrationSite";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

export const HrEmployeesRegistrationAdditionalDataSite = observer(function HrEmployeesRegistrationAdditionalDataSite() {
    useHideSideBar();

    const query = useQueryParams<{ isEdit: boolean; isPreRegistration: boolean }>();
    const params = useParams<{ employeeId: string }>();
    const { isEdit, isPreRegistration } = query;
    const { employeeId } = params;
    const { employee } = useEmployee(employeeId, !!isPreRegistration);

    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = hrStore.selectedSubsidiaryId;

    const [isLoading, setIsLoading] = React.useState(false);

    const nextPage = () => {
        if (isEdit) {
            // During edit -> hide document upload from flow
            pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_SUMMARY, { params, query });
        } else {
            pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_DOCUMENTS, { params, query });
        }
    };

    const onSubmit = async (employeeData: IEmployee, formikHelpers: FormikHelpers<IEmployee>) => {
        const employeeChanged = !isEqual(employee, employeeData);

        if (!employeeChanged) {
            nextPage();
            return;
        }

        // throw away empty bonus fields
        // debug.log(employee);
        setIsLoading(true);
        employeeData.bonuses = employeeData.bonuses.filter(bonuses => !!bonuses.designator);
        if (employeeData.bonuses.length === 0) {
            employeeData.bonuses = [{ designator: "", amount: "" }];
        }

        const body = cloneDeep(employeeData);

        try {
            if (body.status !== "inDeregistrationNotTransferred" && body.status !== "inDeregistration") {
                delete body.dateOfLeaving;
            }

            if (isPreRegistration) {
                await API.patchPreRegistration(companyId, subsidiaryId, body);
            } else if (isEdit) {
                const ret = await API.postEmployeeDataChange(companyId, subsidiaryId, body);

                // Switch from real employee to preRegistration
                params.employeeId = ret.id;
                query.isPreRegistration = true;
            } else {
                // We should never get here
                generalStore.setError("Inconsistent employee state");
            }

            nextPage();
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.BadRequest_400) {
                const validationError = getValidationError(apiError);
                validationError?.validationErrors?.forEach(validation => {
                    switch (validation.key) {
                        case "iban":
                            formikHelpers.setFieldError("iban", t("screen.hr.additionaldata.iban_error"));
                            break;
                        case "bic":
                            formikHelpers.setFieldError("bic", t("screen.hr.additionaldata.bic.validation"));
                            break;
                        case "email":
                            formikHelpers.setFieldError("email", t("screen.hr.additionaldata.email_error"));
                            break;
                        case "monthlySalary":
                            formikHelpers.setFieldError(
                                "monthlySalary",
                                t("screen.hr.additionaldata.monthlySalary_error"),
                            );
                            break;
                        case "kilometersForCommuterEuro":
                            formikHelpers.setFieldError(
                                "kilometersForCommuterEuro",
                                t("screen.hr.additionaldata.kilometersForCommuterEuro_error"),
                            );
                            break;
                        case "familyBonusPlusAmount":
                            formikHelpers.setFieldError(
                                "familyBonusPlusAmount",
                                t("screen.hr.additionaldata.familyBonusPlusAmount_error"),
                            );
                            break;
                        case "workingHours":
                            formikHelpers.setFieldError(
                                "workingHours",
                                t(
                                    "screen.hr.additionaldata.hours_error",
                                    getMinMaxWorkingHours(body.workingHoursInterval ?? "monthly"),
                                ),
                            );
                            break;
                        case "weeklyWorkDays":
                            formikHelpers.setFieldError(
                                "weeklyWorkDays",
                                t("screen.hr.additionaldata.weeklyWorkDays_error"),
                            );
                            break;
                    }
                });
            }
            generalStore.setError(t("error.edit"), error);
        } finally {
            setIsLoading(false);
        }
    };

    if (!employeeId) {
        return <Redirect to={HrRoutes.EMPLOYEES.ROOT} />;
    }

    if (!employee) {
        // Wait until employee is loaded
        return null;
    }

    const initialValues = toJS(employee);

    let cancelTarget = undefined;
    if (isEdit && employee.status !== "inDataChangeNotTransferred") {
        cancelTarget = withParamsAndQuery(HrRoutes.EMPLOYEES.DETAILS, { employeeId }, { isPreRegistration });
    } else if (isPreRegistration) {
        cancelTarget = HrRoutes.EMPLOYEES.IN_PROGRESS;
    }

    return (
        <HrRegistrationSite
            employee={employee}
            cancelDialog={!isEdit}
            backTarget={withQuery(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA, {
                ...query,
                ...params,
            })}
            cancelTarget={cancelTarget}
            query={query}
        >
            <h1 style={{ marginBottom: 16 }}>{t("screen.hr.additionaldata.additionaldata")}</h1>
            <p className="body2" style={{ marginBottom: 28, color: customColors.body1Dark }}>
                {t("screen.hr.additionaldata.description")}
            </p>

            <HrAdditionalDataForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                isLoading={isLoading}
                buttonText={t(isEdit ? "screen.hr.documents.nextButton" : "screen.hr.additionaldata.nextPage")}
            />
        </HrRegistrationSite>
    );
});
