import * as React from "react";
import { ConfirmationDialog } from "../ui/ConfirmationDialog";
import { customColors } from "../util/Theme";
import { t } from "../../i18n/util";
import { InfoDialog } from "../ui/InfoDialog";

const ConfirmInternationalProjectDialog = (props: {
    open: boolean;
    countryCode: string;
    onMoreInfo: () => void;
    onCancel: () => void;
    onConfirm: () => void;
}) => {
    return (
        <ConfirmationDialog
            open={props.open}
            title={t("projects.international.undoWarning.title")}
            message={
                <>
                    <p>
                        {t(
                            props.countryCode.toUpperCase() === "AT"
                                ? "projects.international.undoWarning.message.at"
                                : "projects.international.undoWarning.message",
                        )}
                    </p>
                    <div
                        role="button"
                        style={{
                            marginTop: 16,
                            fontWeight: "bold",
                            color: customColors.primaryColor,
                            cursor: "pointer",
                        }}
                        onClick={props.onMoreInfo}
                    >
                        {t("button.moreInfo")}
                    </div>
                </>
            }
            confirmLabel={t("projects.international.confirmButton")}
            onCancel={props.onCancel}
            onConfirm={props.onConfirm}
        />
    );
};

export const useConfirmInternationalDialog = (countryCode: string) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [isInternational, setIsInternational] = React.useState(false);
    const [internationalInfoOpen, setInternationalInfoOpen] = React.useState(false);

    const onChange = (checked: boolean) => {
        if (checked) {
            setDialogOpen(true);
        } else {
            setIsInternational(false);
        }
    };

    const confirm = () => {
        setIsInternational(true);
        setDialogOpen(false);
    };

    const dialog = (
        <ConfirmInternationalProjectDialog
            open={dialogOpen}
            countryCode={countryCode}
            onCancel={() => {
                setDialogOpen(false);
            }}
            onConfirm={confirm}
            onMoreInfo={() => {
                setInternationalInfoOpen(true);
            }}
        />
    );

    const infoDialog = (
        <InfoDialog
            open={internationalInfoOpen}
            title={t("projects.international.plural")}
            subtitle={t("projects.international.info.subtitle")}
            message={t("projects.international.info.message")}
            confirmLabel={t("dialog.button.understood")}
            onConfirm={() => {
                setInternationalInfoOpen(false);
            }}
        />
    );

    return {
        onChange,
        openInfoDialog: () => {
            setInternationalInfoOpen(true);
        },
        isInternational,
        setIsInternational,
        dialog,
        infoDialog,
    };
};

export type ConfirmInternationalDialog = ReturnType<typeof useConfirmInternationalDialog>;
