import { useCallback } from "react";
import { IMessageIDS, t } from "../../i18n/util";
import { API } from "../../network/API";
import { Account as AccountType } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { InMemoryAutocomplete } from "../ui/Autocomplete";

export const Account = ({
    value,
    onChange,
    companyId,
    subsidiaryId,
    placeholder,
    errorMessage,
}: {
    value: AccountType | null;
    onChange: (account: AccountType | null) => void;
    companyId: string | undefined;
    subsidiaryId: string | undefined;
    placeholder: IMessageIDS;
    errorMessage?: IMessageIDS;
}) => {
    const loader = useCallback(async () => {
        if (!companyId || !subsidiaryId) {
            return null;
        }
        try {
            const { accounts } = await API.getAccounts(companyId, "accounting", subsidiaryId);
            return { items: accounts, total: accounts.length };
        } catch (error) {
            generalStore.setError(t(errorMessage ?? "error.loadAccounts"), error);
            return null;
        }
    }, [companyId, errorMessage, subsidiaryId]);

    const searchFn = useCallback((account: AccountType, search: string) => {
        return account.name.toLocaleLowerCase().includes(search) || account.number.toString().includes(search) || false;
    }, []);

    return (
        <InMemoryAutocomplete
            value={value}
            onChange={onChange}
            loader={loader}
            searchFn={searchFn}
            getOptionLabel={option => `${option.name} (${option.number})`}
            isSelected={(option, value) => option.number === value.number}
            placeholder={t(placeholder)}
            disableClearable={false}
        />
    );
};
