import { Button, Link } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { LINK_COOKIES } from "../../config";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";

const Wrapper = styled.div`
    background-color: ${customColors.white};
    min-height: 120px;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0px;
    width: 100%;
`;

const Text = styled.div`
    font-size: 16px;
`;

const Background = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1400;
`;

export const CookieBanner = observer(function CookieBanner() {
    const handleClickSaveCookies = (all: boolean) => () => {
        generalStore.cookieSettings = { marketing: all };
        generalStore.cookiesSaved = true;
    };

    const isMobile = React.useContext(MobileContext);

    if (!generalStore.isRehydrated) {
        return null;
    }

    return (
        <Background>
            <Wrapper style={{ flexDirection: isMobile ? "column" : "row" }}>
                <Text style={{ marginRight: isMobile ? 0 : 32, marginBottom: isMobile ? 16 : 0 }}>
                    <FormattedMessage
                        id="cookies.text"
                        values={{
                            link: (
                                <Link
                                    component="button"
                                    onClick={() => window.open(LINK_COOKIES, "_blank")}
                                    style={{ fontSize: 16 }}
                                >
                                    {t("cookies.cookieList")}
                                </Link>
                            ),
                        }}
                    />
                </Text>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickSaveCookies(true)}
                        style={{ marginBottom: 16, width: "100%" }}
                    >
                        {t("cookies.acceptAll")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleClickSaveCookies(false)}
                        style={{ width: "100%", color: customColors.white }}
                    >
                        {t("cookies.acceptFunctional")}
                    </Button>
                </div>
            </Wrapper>
        </Background>
    );
});
