import { useCallback, useEffect, useState } from "react";
import { IMessageIDS, t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { toError } from "../../util/error";

export type APIResult<T> = APIResultInitial | APIResultLoading<T> | APIResultSuccess<T> | APIResultError;
export interface APIResultInitial {
    state: "initial";
}
export interface APIResultLoading<T> {
    state: "loading";
    prevData?: T;
}
export interface APIResultSuccess<T> {
    state: "success";
    data: T;
    reload?: () => void;
    update?: (updater: (data: T) => T) => void;
}
export interface APIResultError {
    state: "error";
    error?: Error;
}

interface Options {
    setGeneralStoreIsLoading?: false;
    generalStoreErrorMessage?: IMessageIDS;
}

/** Can be returned from the `loader` function if some data is missing for the request. */
export const MISSING_REQUEST_DATA = Symbol("MISSING_REQUEST_DATA");

/**
 * A simple hook to fetch data.
 *
 * Usually used in combination with the functions defined on `API`.
 *
 * It returns an `APIResult` that determines the current state of the request.
 */
export function useAPI<T>(loader: () => Promise<T | typeof MISSING_REQUEST_DATA>, options?: Options): APIResult<T> {
    const [result, setResult] = useState<APIResult<T>>({ state: "loading" });

    const setGeneralStoreIsLoading = options?.setGeneralStoreIsLoading !== false;
    const generalStoreErrorMessage = options?.generalStoreErrorMessage;

    const [forceReload, setForceReload] = useState(0);

    const reload = useCallback(() => {
        setResult(result => {
            return { state: "loading", prevData: result.state === "success" ? result.data : undefined };
        });
        setForceReload(n => n + 1);
    }, []);
    const update = useCallback((updater: (data: T) => T) => {
        setResult(result => {
            if (result.state !== "success") {
                return result;
            }
            return { ...result, data: updater(result.data) };
        });
    }, []);

    useEffect(() => {
        void forceReload;

        let cancelled = false;

        const load = async () => {
            try {
                if (setGeneralStoreIsLoading) {
                    generalStore.isLoading = true;
                }

                const data = await loader();

                if (cancelled) {
                    return;
                }

                if (data === MISSING_REQUEST_DATA) {
                    return;
                }

                setResult({ state: "success", data, reload, update });
            } catch (err) {
                if (cancelled) {
                    return;
                }
                if (generalStoreErrorMessage) {
                    generalStore.setError(t(generalStoreErrorMessage), err);
                }
                setResult({ state: "error", error: toError(err) });
            } finally {
                if (setGeneralStoreIsLoading) {
                    generalStore.isLoading = false;
                }
            }
        };

        load();

        return () => {
            cancelled = true;
        };
    }, [forceReload, generalStoreErrorMessage, loader, reload, setGeneralStoreIsLoading, update]);

    return result;
}
