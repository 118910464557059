import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { DetailedEmployee } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { generalStore } from "../../stores/GeneralStore";

export const usePersonnelFile = (companyId?: string) => {
    const [employee, setEmployee] = React.useState<DetailedEmployee>();

    React.useEffect(() => {
        const load = async () => {
            try {
                if (!companyId) {
                    setEmployee(undefined);
                    return;
                }

                const res = await API.getPersonnelFile(companyId);
                setEmployee(res);
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.response.type === "USER_NOT_STAFF_CONFLICT") {
                    generalStore.setError(t("error.loadPersonnelFile.notStaff"));
                } else {
                    generalStore.setError(t("error.loadPersonnelFile"));
                }
            }
        };

        load();
    }, [companyId]);

    return employee;
};
