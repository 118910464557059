import { observer } from "mobx-react";
import { t } from "../../../i18n/util";
import { companiesStore } from "../../../stores/CompaniesStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { Module } from "../../../types/models";
import { useWebhookConfiguration } from "../../hooks/useWebhookConfiguration";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { CenteredForm } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { AdvisorReportUpload } from "../AdvisorReportUpload";
import { AdvisorRoutes } from "../router/AdvisorRoutes";

export const AdvisorUploadsUploadSite = observer(function AdvisorUploadsUploadSite() {
    useHideSideBar();

    const { module, category, categoryString, uploadConfiguration } = useWebhookConfiguration({
        chooseCompany: false,
        companyId: companiesStore.selectedCompanyId,
    });

    return (
        <>
            <NavBarBack
                backTarget={AdvisorRoutes.UPLOADS.ROOT}
                title={t("screen.advisor.uploads.upload.navHeader", {
                    category: categoryString,
                })}
                backLabel={t("screen.advisor.uploads.upload.navBack")}
            />
            <CenteredContent>
                <SiteContent>
                    <CenteredForm>
                        {uploadConfiguration}
                        {module !== "" && (
                            <>
                                {(category === "report" || category === "billing" || category === "analysis") && (
                                    <AdvisorReportUpload module={module as Module} category={category} />
                                )}
                                {/* {category ==="registrationConfirmation" && <AdvisorRegisterEmployee />} */}
                            </>
                        )}
                    </CenteredForm>
                </SiteContent>
            </CenteredContent>
        </>
    );
});
