import React from "react";
import { t } from "../../i18n/util";
import { API, ITableParams } from "../../network/API";
import { CompanyDocumentScope, Ticket, TicketRelation } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { Module } from "../../types/models";

export const useTickets = ({
    companyId,
    relation,
    tableParams,
    subsidiaryId,
    startDate,
    endDate,
    scope,
    module,
}: {
    relation?: TicketRelation;
    companyId?: string;
    tableParams: ITableParams;
    subsidiaryId?: string;
    startDate?: string;
    endDate?: string;
    scope?: CompanyDocumentScope;
    module?: Module | "projects" | "all";
}) => {
    const [tickets, setTickets] = React.useState<Ticket[]>([]);
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [total, setTotal] = React.useState(0);

    const reloadTickets = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.beginLoading("tickets");

            const ticketsResponse = await API.getTickets(companyId, {
                ...tableParams,
                module,
                scope,
                subsidiaryId,
                startDate,
                endDate,
                relation,
            });

            setTickets(ticketsResponse.tickets ?? []);
            setTotal(ticketsResponse.total);
        } catch (err) {
            generalStore.setError(t("error.loadTickets"), err);
        } finally {
            generalStore.endLoading("tickets");
            setIsInitialized(true);
        }
    }, [companyId, endDate, module, relation, scope, startDate, subsidiaryId, tableParams]);

    React.useEffect(() => {
        reloadTickets();
    }, [reloadTickets]);

    return { tickets, reload: reloadTickets, isInitialized, total };
};
