import { Routes } from "../../app/router/Routes";

const prefix = Routes.prefixed(Routes.RESULTS);

export const ResultsRoutes = {
    ROOT: prefix("/"),
    ACCOUNT_LISTING: prefix("/account-listing"),
    CUSTOMER_LISTING: prefix("/customer-listing"),
    VENDOR_LISTING: prefix("/vendor-listing"),
    BALANCE_SHEET: prefix("/balance-sheet"),
    CASH_ACCOUNTING: prefix("/cash-accounting"),
    PROFIT_AND_LOSS: prefix("/profit-and-loss"),
    ACCOUNT_SHEET: prefix("/:financialAccountancyId/accounts/:accountNr/account-sheet"),
    EMPLOYER_PAYROLL_ACCOUNT: prefix("/payroll-account/:year"),
    EMPLOYEE_PAYROLL_ACCOUNT: prefix("/payroll-account/:employeeId/:year"),
};
