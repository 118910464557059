import { GLOBAL_FEATURES } from "../features";
import { ICompany, Module } from "../types/models";
import { isNever } from "./ts";

export function isModuleActive(company: ICompany, module: Module) {
    if (module === "accounting") {
        return !!GLOBAL_FEATURES.accounting && !!company.accountingEnabled && !!company.hasAccounting;
    } else if (module === "hr") {
        return !!GLOBAL_FEATURES.hr && !!company.hrEnabled && !!company.hasHR;
    } else {
        isNever(module);
    }

    return false;
}
