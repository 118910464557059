import { t } from "../../i18n/util";
import { getModuleStore } from "../../stores/moduleStores";
import { IRecordType, Module } from "../../types/models";
import { pushRoute, withParams } from "../app/router/history";
import { EmptyState } from "../ui/EmptyState";

export const ModuleRecordsEmptyState = (props: { module: Module; subsidiaryId?: string; recordType: IRecordType }) => {
    const store = getModuleStore(props.module);

    const handleRecordUploadClick = () => {
        pushRoute(withParams(store.routes.RECORDS.UPLOAD, { recordTypeId: props.recordType.id }));
    };
    return (
        <EmptyState
            title={t("screen.records.upload.title", { recordType: store.getRecordTypeName(props.recordType) })}
            message={t("screen.records.upload.message")}
            buttonLabel={store.t("screen.accounting.records.upload.button")}
            onAddEntry={handleRecordUploadClick}
            disabled={!store.canEditRecords(props.subsidiaryId, props.recordType.id) || store.selectedPeriod?.finished}
        />
    );
};
