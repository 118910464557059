import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { ExternalEmployeePreRegistration } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { ICompany } from "../../../types/models";
import { employeeStatusToString, handlePostTicketError } from "../../../util/helpers";
import { getFullName } from "../../../util/user";
import { Routes } from "../../app/router/Routes";
import { pushRoute, withQuery } from "../../app/router/history";
import { useCompany } from "../../hooks/useCompany";
import { useExternalPreRegistration } from "../../hooks/useExternalPreRegistration";
import { useQueryParams } from "../../hooks/useQueryParams";
import { TicketSiteContent } from "../../shared/TicketSiteContent";
import { TicketsRoutes } from "../../tickets/router/TicketsRoutes";
import { NavBarBack } from "../../ui/NavBarBack";
import { WebhookRoutes } from "../router/WebhookRoutes";

export const WebhookPreRegistrationTicketsDetailsSite = observer(function WebhookPreRegistrationTicketsDetailsSite() {
    useHideSideBar();

    const [ticketId, setTicketId] = React.useState("");

    const location = useLocation<{ preRegistrations?: ExternalEmployeePreRegistration[]; company?: ICompany }>();
    const query: { employeeRegistration?: string[]; dueDate?: string; taskTitle?: string } = useQueryParams({
        arrayKeys: ["employeeRegistration"],
    });
    const preRegistrations = useExternalPreRegistration(query.employeeRegistration, location.state?.preRegistrations);
    const preRegistration = preRegistrations[0];
    const companyId = preRegistration?.companyId;
    const company = useCompany({ companyId, preloaded: location.state?.company });
    const employeeName = getFullName(preRegistration?.preRegistration);

    React.useEffect(() => {
        if (preRegistration?.preRegistration.ticket) {
            setTicketId(preRegistration?.preRegistration.ticket.id);
        }
    }, [preRegistration]);

    const handleCreateTicket = async (message: string): Promise<string | undefined> => {
        let ret = undefined;

        if (preRegistration && !ticketId) {
            try {
                generalStore.isLoading = true;
                const ticket = await API.postTicket(companyId, {
                    message: message ?? "",
                    preRegistration: {
                        preRegistrationId: preRegistration.preRegistration.id,
                        subsidiaryId: preRegistration.subsidiary.id,
                    },
                    dueDate: query.dueDate,
                    taskTitle: query.taskTitle,
                });

                setTicketId(ticket.id);
                ret = ticket.id;

                // Update badge count
                if (companyId === companiesStore.selectedCompanyStore?.id) {
                    await companiesStore.selectedCompanyStore.startPollingBadges();
                }
            } catch (error) {
                handlePostTicketError(error);
            } finally {
                generalStore.isLoading = false;
            }
        }

        return ret;
    };

    if (!preRegistration) {
        return (
            <NavBarBack
                cancelTarget={companiesStore.selectedCompanyId ? Routes.COCKPIT : Routes.COMPANY_OVERVIEW}
                title={t("common.ticket")}
            />
        );
    }

    return (
        <>
            <NavBarBack
                backLabel={t("webhook.tickets.title")}
                backTarget={withQuery(WebhookRoutes.TICKETS, query)}
                cancelLabel={t("button.toOverview").toUpperCase()}
                onCancel={() => {
                    if (companyId) {
                        pushRoute(TicketsRoutes.OPEN);
                        coordinator.selectCompanyById(companyId);
                    } else {
                        pushRoute(Routes.COMPANY_OVERVIEW);
                    }
                }}
                title={t("webhook.tickets.navbar", {
                    document:
                        employeeName + ` (${employeeStatusToString(preRegistration?.preRegistration?.status) ?? ""})`,
                })}
                companyName={company.company?.name}
            />
            <TicketSiteContent
                companyId={companyId}
                subsidiaryId={preRegistration.subsidiary.id}
                preRegistration={preRegistration.preRegistration}
                onCreateTicket={handleCreateTicket}
                ticketId={ticketId}
            />
        </>
    );
});
