import * as React from "react";
import { authStore } from "../../../stores/AuthStore";
import { DocsRouter } from "../router/DocsRouter";

export const DocsContainerSite = () => {
    if (!authStore.userInfo) {
        return null;
    }

    return <DocsRouter />;
};
