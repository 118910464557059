import { FormControl, IconButton, Link, MenuItem, Tooltip } from "@material-ui/core";
import compact from "lodash/compact";
import React from "react";
import styled from "styled-components";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { ApprovalOption } from "../../../types/models";
import { hasFileExtension } from "../../../util/files";
import { getFileIconName, getFilenameFromPath } from "../../../util/helpers";
import { IIconNames, Icon } from "../../util/Icon";
import { customColors } from "../../util/Theme";
import { IOSSwitch } from "../IOSSwitch";
import { StyledSelect } from "../Primitives";

interface ApprovalOptions {
    label: string;
    value: ApprovalOption;
}

const approvalOptions: ApprovalOptions[] = compact([
    { label: t("screen.advisor.uploads.upload.approve.option.none"), value: "none" },
    { label: t("screen.advisor.uploads.upload.approve.option.click"), value: "click" },
    GLOBAL_FEATURES.qes && { label: t("screen.advisor.uploads.upload.approve.option.qes"), value: "qes" },
]);

const Switch = (props: { defaultChecked?: boolean; label?: string; onCheck: (checked: boolean) => void }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", marginRight: 16 }}>
            <p className="caption">{props.label}</p>
            <IOSSwitch
                data-id="approve"
                defaultChecked={props.defaultChecked}
                onChange={(_, checked) => {
                    props.onCheck(checked);
                }}
            />
        </div>
    );
};

const DocumentTitleText = styled.p`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.clickable:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const DocumentTitle = (props: {
    fileName: string;
    subtitle?: string;
    iconName?: IIconNames;
    style?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    staffCanRead?: boolean;
    onClick?: () => void;
}) => (
    <div style={{ display: "flex", alignItems: "center", ...props.style }}>
        <Icon name={props.iconName ?? getFileIconName(props.fileName)} />
        <div
            style={{
                marginLeft: 16,
                maxWidth: "90%",
            }}
        >
            <DocumentTitleText
                className={"body2" + (props.onClick ? " clickable" : "")}
                style={props.textStyle}
                onClick={props.onClick}
            >
                {props.fileName}
            </DocumentTitleText>
            <p className="caption">{props.subtitle}</p>
            {props.staffCanRead && <p className="caption">{t("screen.hr.employee.details.fileReleased")}</p>}
        </div>
    </div>
);

export const DocumentLine = (props: {
    fileName: string;
    iconName?: IIconNames;
    style?: React.CSSProperties;
    onDelete?: () => void;
    defaultChecked?: boolean;
    defaultApprovalOption?: ApprovalOption;
    showReleaseOptions?: boolean;
    onCheck?: (checked: boolean) => void;
    onChangeReleaseOption?: (option: ApprovalOption) => void;
    onClickReleaseOption?: () => void;
    switchLabel?: string;
}) => {
    return (
        <div
            style={{
                borderRadius: 4,
                height: 52,
                marginBottom: 4,
                marginTop: 4,
                display: "flex",
                alignItems: "center",
                padding: "12px 8px",
                justifyContent: "space-between",
                flexGrow: 1,
                ...props.style,
            }}
        >
            <DocumentTitle
                fileName={props.fileName}
                iconName={props.iconName}
                style={{
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                    overflow: "hidden",
                    paddingRight: 24,
                }}
            />

            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                {props.onCheck && props.switchLabel && (
                    <Switch defaultChecked={props.defaultChecked} label={props.switchLabel} onCheck={props.onCheck} />
                )}

                {props.showReleaseOptions && props.onChangeReleaseOption && (
                    <>
                        <FormControl>
                            <StyledSelect
                                defaultValue={props.defaultApprovalOption}
                                onChange={e => {
                                    if (props.onChangeReleaseOption) {
                                        props.onChangeReleaseOption(e.target.value as ApprovalOption);
                                    }
                                }}
                                onClick={e => {
                                    if (props.onClickReleaseOption) {
                                        props.onClickReleaseOption();
                                    }
                                }}
                                MenuProps={{
                                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                    transformOrigin: { vertical: "top", horizontal: "left" },
                                    getContentAnchorEl: null,
                                }}
                                disableUnderline
                            >
                                {approvalOptions.map(approval => (
                                    <MenuItem
                                        disabled={approval.value === "qes" && !hasFileExtension(props.fileName, "pdf")}
                                        key={approval.value}
                                        value={approval.value}
                                    >
                                        {approval.label}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                        <Tooltip
                            title={
                                <Link
                                    href="https://tpagroupat.sharepoint.com/sites/intranet-work/sitepages/QES.aspx"
                                    target="_blank"
                                    style={{
                                        fontSize: 12,
                                        textDecorationLine: "underline",
                                    }}
                                >
                                    {t("screen.advisor.upload.approve.info")}
                                </Link>
                            }
                            interactive
                        >
                            <IconButton>
                                <Icon name="info"></Icon>
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                {props.onDelete && (
                    <Link
                        onClick={props.onDelete}
                        style={{
                            fontWeight: 500,
                            fontSize: 12,
                            textDecorationLine: "underline",
                            cursor: "pointer",
                            minWidth: 47,
                            color: customColors.body1Dark,
                        }}
                    >
                        {t("common.delete")}
                    </Link>
                )}
            </div>
        </div>
    );
};

export const DocumentDownloadLine = (props: {
    style?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    fileName?: string;
    subtitle?: string;
    documentId?: string;
    disabled?: boolean;
    staffCanRead?: boolean;
    onDownload?: (documentIds: string[]) => void | Promise<void>;
    downloadOnClick?: boolean;
}) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const name = getFilenameFromPath(props.fileName ?? "");

    let handleDownload;
    if (!props.disabled && props.documentId && props.onDownload && !isLoading) {
        const { documentId, onDownload } = props;
        handleDownload = async () => {
            setIsLoading(true);
            await onDownload([documentId]);
            setIsLoading(false);
        };
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ...props.style,
            }}
        >
            {/* See this https://makandracards.com/makandra/66994-css-flex-and-min-width why we need minWidth here */}
            <DocumentTitle
                fileName={name}
                subtitle={props.subtitle}
                textStyle={props.textStyle}
                style={{ minWidth: 0, marginRight: 8 }}
                staffCanRead={props.staffCanRead}
                onClick={props.downloadOnClick ? handleDownload : undefined}
            />
            {props.onDownload && (
                <Icon
                    name="download"
                    data-id={`document_summary_download_${name}`}
                    style={{
                        cursor: "pointer",
                        display: "block",
                        color: isLoading ? customColors.greyButton : undefined,
                    }}
                    onClick={handleDownload}
                />
            )}
        </div>
    );
};
