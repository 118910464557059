import * as React from "react";
import { SHARE_POINT_CALL_DELAY_MS } from "../../config";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Project, ProjectItem, ProjectItemPermission } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

// Hook for loading project or project item permissions for a list of projects or projectItems.
// Only one of projects or projectItems should be provided.
export const useProjectPermissions = ({
    companyId,
    projects,
    projectItems,
}: {
    companyId?: string;
    projects?: Project[];
    projectItems?: {
        projectId?: string;
        items: ProjectItem[];
    };
}) => {
    const [permissions, setPermissions] = React.useState<Record<string, ProjectItemPermission[]>>({});

    const permissionCalls = React.useRef<number[]>([]);

    const projectItemsProjectId = projectItems?.projectId;
    const projectItemsEntries = projectItems?.items;

    React.useEffect(() => {
        const loadPermissions = () => {
            if (!companyId) {
                return;
            }
            const items = projects ?? projectItemsEntries;
            if (!items) {
                return;
            }

            const isProjects = !!projects;

            const timers: number[] = [];
            items.forEach((item: Project | ProjectItem, index: number) => {
                // TPAPORTAL-2322: Because of sharepoint rate limit -> only one call/second
                const timer = setTimeout(async () => {
                    // Return empty array to hide loading spinner if load failed
                    let permissions: ProjectItemPermission[] = [];
                    const projectId = isProjects ? item.id : projectItemsProjectId;
                    if (!projectId) {
                        return;
                    }

                    try {
                        const response = await API.getProjectItemPermissions(
                            companyId,
                            projectId,
                            // We are loading items -> provide item id
                            !isProjects ? item.id : undefined,
                        );
                        permissions = response.users;
                    } catch (error) {
                        generalStore.setError(t("error.projectPermissions"), error);
                    }

                    // Append loaded permissions to existing ones
                    setPermissions(prev => {
                        const newPermissions = { ...prev };
                        newPermissions[item.id] = permissions;
                        return newPermissions;
                    });
                }, SHARE_POINT_CALL_DELAY_MS * index);
                timers.push(timer);
            });

            permissionCalls.current = timers;
        };

        loadPermissions();

        return () => {
            permissionCalls.current.forEach(timer => {
                clearTimeout(timer);
            });
        };
    }, [companyId, projectItemsEntries, projectItemsProjectId, projects]);

    return permissions;
};
