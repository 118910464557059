import React from "react";
import { t } from "../../i18n/util";
import { API, ITableParams } from "../../network/API";
import { useDeepCompareCallback } from "use-deep-compare";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";
import { generalStore } from "../../stores/GeneralStore";
import { Ticket } from "../../network/APITypes";

export const useRecordTypeTickets = ({
    companyId,
    module,
    recordTypeId,
    tableParams,
}: {
    companyId?: string;
    module?: Module;
    recordTypeId?: string;
    tableParams: ITableParams;
}) => {
    const [tickets, setTickets] = React.useState<Ticket[]>([]);
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [total, setTotal] = React.useState(0);

    const reloadRecordTypeTickets = useDeepCompareCallback(async () => {
        if (!companyId || !module || !recordTypeId) {
            return;
        }

        const moduleStore = getModuleStore(module);

        if (!moduleStore.selectedPeriodId || !moduleStore.selectedSubsidiaryId) {
            return;
        }

        try {
            generalStore.beginLoading("tickets");

            const ticketsResponse = await API.getRecordTypeTickets({
                ...tableParams,
                companyId,
                module,
                periodId: moduleStore.selectedPeriodId,
                subsidiaryId: moduleStore.selectedSubsidiaryId,
                recordTypeId,
            });

            setTickets(ticketsResponse.tickets ?? []);
            setTotal(ticketsResponse.total);
        } catch (err) {
            generalStore.setError(t("error.loadTickets"), err);
        } finally {
            generalStore.endLoading("tickets");
            setIsInitialized(true);
        }
    }, [tableParams, companyId, module, recordTypeId]);

    React.useEffect(() => {
        reloadRecordTypeTickets();
    }, [reloadRecordTypeTickets]);

    return { tickets, reload: reloadRecordTypeTickets, isInitialized, total };
};
