import styled from "styled-components";
import { FAX_NUMBER, TELEPHONE_NUMBER } from "../../../config";
import { t } from "../../../i18n/util";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { goBack } from "../router/history";
import { Routes } from "../router/Routes";

const P = styled.p`
    margin-top: 16px;
`;

const H1 = styled.h1`
    font-size: 18px;
`;

// TODO: For localization later easiest will be -> one page per language instead of putting the strings into texterify
export const LegalNoticeSite = () => {
    useHideSideBar();

    return (
        <>
            <NavBarBack
                title={t("footer.legalInformation")}
                backLabel={t("common.back")}
                onBack={() => {
                    goBack(Routes.ROOT);
                }}
            />
            <CenteredContent>
                <SiteContent style={{ paddingBottom: 64 }}>
                    <H1>
                        <u>Impressum TPA Connect GmbH</u>
                    </H1>
                    <P>
                        Impressums- und Offenlegungspflicht gemäß §§ 24, 25 MedienG
                        <br />
                        Informationspflichten gemäß § 5 E-Commerce Gesetz (ECG)
                        <br />
                        Offenlegungspflicht gemäß § 14 UGB
                    </P>
                    <P>
                        <b>TPA Connect GmbH</b>
                        <br />
                        (Medieninhaber und Herausgeber)
                    </P>
                    <P>
                        Wiedner Gürtel 13, Turm 24, 1100 Wien
                        <br />
                        Tel.: {TELEPHONE_NUMBER}
                        <br />
                        Fax: {FAX_NUMBER}
                        <br />
                        E-Mail:{" "}
                        <a href="mailto:service@tpa-connect.app" target="_blank" rel="noopener noreferrer">
                            service@tpa-connect.app
                        </a>
                    </P>
                    <P>
                        Firmenbuchnummer: 560546h
                        <br />
                        Firmenbuchgericht: Handelsgericht Wien
                        <br />
                        UID: ATU77085627
                    </P>
                    <P>
                        Geschäftsführer: Mag. Veronika Seitweger, Mag. (FH) Andreas Pichler und Mag. Jürgen Baumgartner
                    </P>
                    <P>
                        Kammerzugehörigkeit: Mitglied der Fachgruppe Unternehmensberatung und Informationstechnologie
                        der Wirtschaftskammer Österreich
                    </P>
                    <P>
                        Unternehmensgegenstand: Dienstleistungen in der automatischen Datenverarbeitung und
                        Informationstechnik
                    </P>
                    <P>
                        Beteiligungsverhältnisse: TPA Group Administration und Steuerberatung GmbH (100%),
                        Beteiligungsverhältnis an der TPA Group Administration und Steuerberatung GmbH: TPA
                        Steuerberatung GmbH (100%) Beteiligungsverhältnisse an der TPA Steuerberatung GmbH: Mag. Klaus
                        Bauer-Mitterlehner (Anteil: 3,846%), Mag. Helmut Beer (Anteil: 3,846%), Mag. Leopold Brunner
                        (Anteil: 3,846%), Mag. Iris Burgstaller (Anteil: 3,846%), Mag. Anja Cupal (Anteil: 3,846%), Mag.
                        Karin Fuhrmann (Anteil: 3,846%), Mag. Thomas Haneder (Anteil: 3,846%), Mag. Christoph Harrer
                        (Anteil: 3,846 %), Mag. Sebastian Haupt (Anteil: 3,846%), Mag. Gerald Kerbl (Anteil: 3,846%),
                        Mag. Leopold Kühmayer (Anteil: 3,846%), Mag. Manfred Kunisch (Anteil: 3,846%), Dr. Gunther Lang
                        (Anteil: 3,846%), Mag. Robert Lovrecki (Anteil: 3,846%), Mag. Birgit Perkounig (Anteil: 3,846%),
                        Mag. Dieter Pock (Anteil: 3,846%), Mag. Roland Reisch (Anteil: 3,846 %), Mag. Erich Resch
                        (Anteil: 3,846%), Mag. Klaus Scheder (Anteil: 3,846%), Mag. Karl Schwarz (Anteil: 3,846%), Mag.
                        Monika Seywald (Anteil: 3,846%), Mag. Gottfried Sulz (Anteil: 3,846%), Mag. Veronika Seitweger
                        (Anteil: 3,846%), Mag. Yasmin Wagner (Anteil: 3,846%), Mag. Gerald Weiß (Anteil: 3,846%), Mag.
                        Ingrid Winkelbauer (Anteil: 3,846%)
                    </P>
                    <P>
                        Grundlegende Richtung des periodischen Medienwerkes: Bereitstellung einer Plattform für Zwecke
                        der Kommunikation sowie für den Austausch von Dokumenten
                    </P>
                    <P>Aufsichtsbehörde: Magistratisches Bezirksamt des X. Bezirkes in Wien</P>
                    <P>
                        Anwendbare Rechtsvorschriften und Zugang: Gewerbeordnung (
                        <a href="https://www.ris.bka.gv.at" target="_blank" rel="noopener noreferrer">
                            www.ris.bka.gv.at
                        </a>
                        )
                    </P>
                </SiteContent>
            </CenteredContent>
        </>
    );
};
