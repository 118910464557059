import { observer } from "mobx-react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { debug } from "../../../util/debug";
import { getBasePath } from "../../../util/url";
import { useQueryParams } from "../../hooks/useQueryParams";
import { Routes } from "./Routes";
import { history } from "./history";

export const PrivateRoute = observer(function PrivateRoute({ component: Component, ...props }: RouteProps) {
    const { companyId } = useQueryParams<{ companyId: string }>();

    if (!coordinator.isRehydrated) {
        return null;
    }

    // Have to move this here so that mobx observing works, if using authStore.isAuthenticated
    // directly in render function below user is not redirected to login (I assume because Route is not an observer)
    const isAuthenticated = authStore.isAuthenticated;

    if (!isAuthenticated) {
        const basePath = getBasePath(history.location.pathname);
        debug.log("[ROUTER] check if base path can be restored", basePath);

        let canRestorePath = true;
        if (basePath === Routes.ACCOUNTING || basePath === Routes.HR) {
            if (!companyId) {
                canRestorePath = !!companiesStore.selectedCompanyId;
            }
        }

        if (!canRestorePath) {
            debug.log("[ROUTER] cannot restore path");
        }

        if (canRestorePath) {
            // Remember what route the user wanted to visit, redirect after login
            authStore.redirectAfterLogin = history.location.pathname + history.location.search + history.location.hash;
            debug.log("[ROUTER] Store redirect after login ", authStore.redirectAfterLogin);
        }
    }

    if (isAuthenticated && !coordinator.isInitialized) {
        return null;
    }

    return (
        <Route
            {...props}
            render={props =>
                isAuthenticated ? (
                    Component && <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.ROOT,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
});
