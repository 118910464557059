import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route, Router, Switch, useLocation } from "react-router-dom";
import { LOADING_INDICATOR_DELAY_MS } from "../../../config";
import { GLOBAL_FEATURES } from "../../../features";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { AccountingContainerSite } from "../../accounting/sites/AccountingContainerSite";
import { AdvisorContainerSite } from "../../advisor/sites/AdvisorContainerSite";
import { AnalysisContainerSite } from "../../analysis/sites/AnalysisContainerSite";
import { AuthRoutes } from "../../auth/router/AuthRoutes";
import { AuthConfirmUsernameChangeSite } from "../../auth/sites/AuthConfirmUsernameChange";
import { AuthContainerSite } from "../../auth/sites/AuthContainerSite";
import { AuthLoginSite } from "../../auth/sites/AuthLoginSite";
import { AuthMSCloseSite } from "../../auth/sites/AuthMSCloseSite";
import { AuthMSRefreshOfflineSite } from "../../auth/sites/AuthMSRefreshOfflineSite";
import { AuthOAuthSite } from "../../auth/sites/AuthOAuthSite";
import { CockpitContainerSite } from "../../cockpit/sites/CockpitContainerSite";
import { DocsContainerSite } from "../../docs/sites/DocsContainerSite";
import { HrContainerSite } from "../../hr/sites/HrContainerSite";
import { KpisContainerSite } from "../../kpis/sites/KpisContainerSite";
import { ProjectsContainerSite } from "../../projects/sites/ProjectsContainerSite";
import { ResultsContainerSite } from "../../results/sites/ResultsContainerSite";
import { SettingsContainerSite } from "../../settings/sites/SettingsContainerSite";
import { Footer } from "../../shared/Footer";
import { UserPermissionsSite } from "../../shared/sites/UserPermissionsSite";
import { UserRolesSite } from "../../shared/sites/UserRolesSite";
import { StaffContainerSite } from "../../staff/sites/StaffContainerSite";
import { SupportContainerSite } from "../../support/sites/SupportContainerSite";
import { TicketsContainerSite } from "../../tickets/sites/TicketsContainerSite";
import { CookieBanner } from "../../ui/CookieBanner";
import { LoadingOverlay } from "../../ui/LoadingOverlay";
import { Snackbar } from "../../ui/Snackbar";
import { WebhookContainerSite } from "../../webhooks/sites/WebhookContainerSite";
import { CompaniesUserReleaseSite } from "../sites/CompaniesUserReleaseSite";
import { CompanyOverviewSite } from "../sites/CompanyOverviewSite";
import { LegalNoticeSite } from "../sites/LegalNoticeSite";
import { LoadingSite } from "../sites/LoadingSite";
import { NotFoundSite } from "../sites/NotFoundSite";
import { OptionalMenuItemSite } from "../sites/OptionalMenuItemSite";
import { PrivacyPolicySite } from "../sites/PrivacyPolicySite";
import { TermsNotAcceptedSite } from "../sites/TermsNotAcceptedSite";
import { TermsOfUseSite } from "../sites/TermsOfUseSite";
import { UserProfileSite } from "../sites/UserProfileSite";
import { UserUpgradeSite } from "../sites/UserUpgradeSite";
import { NoAuthOnlyRoute } from "./NoAuthOnlyRoute";
import { PrivateRoute } from "./PrivateRoute";
import { Routes } from "./Routes";
import { RoutingManager } from "./RoutingManager";
import { history } from "./history";

const ConditionalFooter = () => {
    const location = useLocation();

    return location.pathname === Routes.USER_PROFILE ||
        (!authStore.isAuthenticated && location.pathname === Routes.ROOT) ? (
        <Footer />
    ) : null;
};

const RedirectIfNoCompanySelected = observer(function RedirectIfNoCompanySelected({
    children,
}: {
    children: React.ReactNode;
}) {
    const location = useLocation();

    if (!coordinator.isRehydrated || (authStore.isAuthenticated && !coordinator.isInitialized)) {
        // We are not yet ready -> wait for initialization
        return null;
    }

    if (!companiesStore.selectedCompanyId) {
        // We are ready, but no company selected -> go to company overview
        return <Redirect to={Routes.ROOT} />;
    }

    if (!companiesStore.canAccessCompany) {
        // Block all routes for customers when terms are not accepted
        return <Redirect to={Routes.TERMS_NOT_ACCEPTED} />;
    }

    if (!authStore.isStaff && location.pathname.startsWith(Routes.STAFF)) {
        // We are at a staff route but don't have the role -> go to cockpit
        return <Redirect to={Routes.COCKPIT} />;
    }

    if (
        authStore.isStaffOnly &&
        !location.pathname.startsWith(Routes.STAFF) &&
        !location.pathname.startsWith(Routes.SUPPORT)
    ) {
        // We are staff only for this company -> only allow staff routes
        return <Redirect to={Routes.STAFF} />;
    }

    return <>{children}</>;
});

export const AppRouter = observer(function AppRouter() {
    return (
        <Router history={history}>
            <RoutingManager>
                <Switch>
                    {/* Do this before the AuthContainerSite route below, because ms-close is used by refresh where the user is logged in */}
                    <Route exact path={AuthRoutes.MS_CLOSE}>
                        <AuthMSCloseSite />
                    </Route>
                    <Route exact path={AuthRoutes.MS_REFRESH_OFFLINE}>
                        <AuthMSRefreshOfflineSite />
                    </Route>
                    <Route exact path={Routes.TERMS_OF_USE}>
                        <TermsOfUseSite />
                    </Route>
                    <Route exact path={AuthRoutes.CONFIRM_USERNAME_CHANGE}>
                        <AuthConfirmUsernameChangeSite />
                    </Route>
                    <Route exact path={Routes.NOT_FOUND}>
                        <NotFoundSite />
                    </Route>
                    <Route exact path={Routes.LOADING}>
                        <LoadingSite />
                    </Route>
                    <NoAuthOnlyRoute exact path={Routes.ROOT} component={AuthLoginSite} />
                    <NoAuthOnlyRoute path={Routes.AUTH} component={AuthContainerSite} />
                    <Route path={Routes.LEGAL_NOTICE} component={LegalNoticeSite} />
                    <Route path={Routes.PRIVACY_POLICY} component={PrivacyPolicySite} />
                    <PrivateRoute exact path={Routes.OAUTH} component={AuthOAuthSite} />
                    <PrivateRoute exact path={Routes.COMPANY_OVERVIEW} component={CompanyOverviewSite} />
                    <PrivateRoute exact path={Routes.COMPANIES_USERS_RELEASE} component={CompaniesUserReleaseSite} />
                    <PrivateRoute exact path={Routes.COMPANIES_USERS_RELEASE_ROLES} component={UserRolesSite} />
                    <PrivateRoute
                        exact
                        path={Routes.COMPANIES_USERS_RELEASE_PERMISSIONS}
                        component={UserPermissionsSite}
                    />
                    <PrivateRoute path={Routes.TERMS_NOT_ACCEPTED} component={TermsNotAcceptedSite} />
                    <PrivateRoute path={Routes.ADVISOR} component={AdvisorContainerSite} />
                    <PrivateRoute path={Routes.USER_PROFILE} component={UserProfileSite} />
                    <PrivateRoute path={Routes.MS_UPGRADE} component={UserUpgradeSite} />
                    {GLOBAL_FEATURES.webhooks && (
                        <PrivateRoute path={Routes.WEBHOOKS} component={WebhookContainerSite} />
                    )}
                    <RedirectIfNoCompanySelected>
                        <Switch>
                            {GLOBAL_FEATURES.cockpit && (
                                <PrivateRoute path={Routes.COCKPIT} component={CockpitContainerSite} />
                            )}
                            {GLOBAL_FEATURES.accounting && (
                                <PrivateRoute path={Routes.ACCOUNTING} component={AccountingContainerSite} />
                            )}
                            {GLOBAL_FEATURES.hr && <PrivateRoute path={Routes.HR} component={HrContainerSite} />}
                            {GLOBAL_FEATURES.kpis && <PrivateRoute path={Routes.KPIS} component={KpisContainerSite} />}
                            {GLOBAL_FEATURES.projects && (
                                <PrivateRoute path={Routes.PROJECTS} component={ProjectsContainerSite} />
                            )}
                            {GLOBAL_FEATURES.docs && <PrivateRoute path={Routes.DOCS} component={DocsContainerSite} />}
                            {GLOBAL_FEATURES.settings && (
                                <PrivateRoute path={Routes.SETTINGS} component={SettingsContainerSite} />
                            )}
                            {GLOBAL_FEATURES.results && (
                                <PrivateRoute path={Routes.RESULTS} component={ResultsContainerSite} />
                            )}
                            {GLOBAL_FEATURES.analysis && (
                                <PrivateRoute path={Routes.ANALYSIS} component={AnalysisContainerSite} />
                            )}
                            {GLOBAL_FEATURES.chat && (
                                <PrivateRoute path={Routes.SUPPORT} component={SupportContainerSite} />
                            )}
                            {GLOBAL_FEATURES.tickets && (
                                <PrivateRoute path={Routes.TICKETS} component={TicketsContainerSite} />
                            )}
                            {GLOBAL_FEATURES.staff && (
                                <PrivateRoute path={Routes.STAFF} component={StaffContainerSite} />
                            )}
                            <PrivateRoute path={Routes.EXTERNAL} component={OptionalMenuItemSite} />
                            <Route component={NotFoundSite} />
                        </Switch>
                    </RedirectIfNoCompanySelected>

                    {/* Don't put any routes after here because React router doesn't see anything after <RedirectIfNoCompanySelected/> */}
                </Switch>
            </RoutingManager>
            <GeneralStoreLoadingOverlay />
            {GLOBAL_FEATURES.matomo && !generalStore.cookiesSaved && <CookieBanner />}
            <Snackbar />
            <ConditionalFooter />
        </Router>
    );
});

const GeneralStoreLoadingOverlay = observer(function GeneralStoreLoadingOverlay() {
    if (!generalStore.isLoading) {
        return null;
    }

    return <LoadingOverlay delayMs={LOADING_INDICATOR_DELAY_MS} />;
});
