import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { pushRoute } from "../../app/router/history";
import { CustomInputField } from "../../ui/CustomInputField";
import { AuthRoutes } from "../router/AuthRoutes";
import { AuthGeneralContainerSite } from "./AuthGeneralContainerSite";

interface IPasswordResetEmailData {
    email: string;
}

export const AuthForgotPasswordEmailSite = () => {
    const handleSubmit = async (model: IPasswordResetEmailData) => {
        try {
            await API.postForgotPassword(model.email);
            authStore.forgotPasswordEmail = model.email;
            pushRoute(AuthRoutes.FORGOT_PASSWORD.CONFIRM);
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        }
    };

    return (
        <>
            <AuthGeneralContainerSite>
                <h1 style={{ marginBottom: 16 }}>{t("screen.auth.forgot_password_email.title")}</h1>

                <p style={{ marginBottom: 32 }}>{t("screen.auth.forgot_password_email.subtitle")}</p>

                <Formik
                    initialValues={{
                        email: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email(t("screen.auth.forgot_password_email.validation_error"))
                            .required(t("screen.auth.forgot_password_email.validation_error")),
                    })}
                >
                    {props => {
                        return (
                            <Form style={{ width: "100%", textAlign: "left" }} noValidate>
                                <Field
                                    component={CustomInputField}
                                    label={t("screen.auth.forgot_password_email.form.label.email")}
                                    name="email"
                                    required
                                />

                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    {t("screen.auth.forgot_password_email.form.button")}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </AuthGeneralContainerSite>
        </>
    );
};
