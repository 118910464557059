import { observable } from "mobx";
import { create } from "mobx-persist";
import { GetRecordTypesResponse, PermissionsRecordTypes, RecordType } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { ModuleStore, getCurrentPeriodId } from "../../stores/ModuleStore";
import { addStoreToWindow } from "../../util/debug";
import { getRecordTypeName } from "../../util/recordTypes";
import { getDeepLinkInfo } from "../../util/url";

// Accounting specific data goes here
// General data goes into other stores e.g. CompaniesStore
export class AccountingStore extends ModuleStore {
    constructor() {
        super("accounting");
    }

    @observable bankConnectionPeriodEnd?: string;

    async refreshAfterCompanyChange() {
        await super.refreshAfterCompanyChange();
        this.getBankConnectionBookingPeriodEnd();
    }

    async init() {
        await super.init();
        this.getBankConnectionBookingPeriodEnd();
    }

    getBankConnectionBookingPeriodEnd = () => {
        const filteredPeriods = this.periods.filter(
            period => period.periodType === companiesStore.selectedCompany?.bookingPeriodType,
        );
        const currentPeriodId = getCurrentPeriodId("accounting", filteredPeriods, companiesStore.selectedCompany);
        if (currentPeriodId) {
            const currentPeriodIndex = this.getPeriodIndexById(currentPeriodId, filteredPeriods);
            // Check that the current period is not final entry in array
            if (currentPeriodIndex < filteredPeriods.length - 1) {
                this.bankConnectionPeriodEnd = filteredPeriods[currentPeriodIndex + 1].periodEnd;
            }
            this.bankConnectionPeriodEnd = filteredPeriods[currentPeriodIndex].periodEnd;
        }
    };

    getRecordTypeName(recordType: RecordType | GetRecordTypesResponse | PermissionsRecordTypes): string {
        return getRecordTypeName("accounting", recordType);
    }

    canAccessSection() {
        const companyStore = companiesStore.selectedCompanyStore;
        return !!companyStore?.hasModule("accounting") || !!companyStore?.canReadBanking();
    }
}

export const accountingStore = new AccountingStore();

if (import.meta.env.NODE_ENV !== "test") {
    (async () => {
        const hydrate = create({
            storage: (await import("localforage")).default,
        });

        hydrate("accounting", accountingStore)
            .then(() => {
                // Override with query param if provided for this module
                const deepLink = getDeepLinkInfo();
                if (deepLink.isDeepLink) {
                    accountingStore.parseDeepLink(deepLink);
                }

                accountingStore.isRehydrated = true;
            })
            .catch((error: unknown) => {
                console.error(error);
            });
    })();
}

addStoreToWindow("accountingStore", accountingStore);
