import * as queryString from "query-string";
import * as Config from "../config";
import { t } from "../i18n/util";
import { debug } from "../util/debug";
import { Download } from "./APITypes";
import * as NetworkStapler from "./NetworkStapler";
import { IAPITarget, urljoin } from "./NetworkStapler";
import { HttpStatusCode } from "./httpStatusCode";

interface AuthStore {
    credentials?: { access_token: string } | null;
    locale: string;
    handleUnauthorized: () => Promise<boolean>;
}

interface GeneralStore {
    setError: (message: string, error: unknown) => void;
}

class APIClient {
    apiClient: NetworkStapler.APIClient;

    // the stores inject themselves to avoid circular dependencies
    authStore: AuthStore | undefined = undefined;
    generalStore: GeneralStore | undefined = undefined;

    constructor() {
        this.apiClient = new NetworkStapler.APIClient({
            baseUrl: Config.API_BASE_URL,
            injectHeaders: this.injectHeaders,
            throwOnErrorStatusCodes: true,
        });
    }

    injectHeaders = (target: NetworkStapler.IAPITarget) => {
        const ret: Record<string, string> = {
            ...target.headers,
            "Content-Type": target.headers?.["Content-Type"] ?? "application/json",
            Accept: "application/json",
            "Accept-Language": this.authStore?.locale ?? "",
        };

        if (this.authStore?.credentials?.access_token) {
            ret.Authorization = `Bearer ${this.authStore.credentials.access_token}`;
        }

        if (
            !!window?.location?.search?.includes("enable-additional-logging=true") ||
            window?.sessionStorage?.getItem("enable-additional-logging") === "true"
        ) {
            ret["Enable-Additional-Logging"] = "true";
        }

        return ret;
    };

    async request(target: NetworkStapler.IAPITarget): Promise<Response> {
        try {
            return await this.apiClient.request(target);
        } catch (err) {
            const retry = await this.checkStatusCodeError(err);
            if (retry) {
                return await this.apiClient.request(target);
            }
            throw err;
        }
    }

    async requestType<T>(target: NetworkStapler.ITypedAPITarget<T>, isTokenRefresh = false): Promise<T> {
        try {
            return await this.apiClient.requestType(target);
        } catch (err) {
            const retry = await this.checkStatusCodeError(err, isTokenRefresh);
            if (retry) {
                return await this.apiClient.requestType(target);
            }
            throw err;
        }
    }

    async uploadFormData(target: IAPITarget): Promise<Response> {
        const headers = this.injectHeaders(target);

        // FormData with fetch must not have Content-Type set. This gets set automatically by
        // fetch()
        delete headers["Content-Type"];

        let requestUrl = urljoin(this.apiClient.options.baseUrl, target.url);

        if (target.queryParameters) {
            const query = queryString.stringify(target.queryParameters);
            if (query) {
                requestUrl = urljoin(requestUrl, `?${query}`);
            }
        }

        let body;
        if (target.body instanceof FormData) {
            body = target.body;
        } else if (typeof target.body === "string") {
            body = target.body;
        } else {
            body = JSON.stringify(target.body);
        }

        const options: RequestInit = {
            method: target.method ?? "GET",
            body,
            headers,
        };

        return this.apiClient.performFetch(requestUrl, options);
    }

    async uploadFormDataJSON<T>(target: NetworkStapler.ITypedAPITarget<T>): Promise<T> {
        let response: Response;

        try {
            response = await this.uploadFormData(target);
        } catch (err) {
            const retry = await this.checkStatusCodeError(err);
            if (retry) {
                response = await this.uploadFormData(target);
            } else {
                throw err;
            }
        }

        return response.json() as Promise<T>;
    }

    async browserDownload(target: NetworkStapler.ITypedAPITarget<Download>) {
        const link = await apiClient.requestType(target);
        window.open(link.downloadUrl, "_self");
    }

    async checkStatusCodeError(error: unknown, isTokenRefresh = false): Promise<boolean> {
        let retry = false;
        if (NetworkStapler.isApiError(error)) {
            if (error.statusCode) {
                switch (error.statusCode) {
                    case HttpStatusCode.Unauthorized_401:
                        if (!isTokenRefresh) {
                            retry = (await this.authStore?.handleUnauthorized()) ?? false;
                        }
                        break;
                    case HttpStatusCode.Forbidden_403:
                        this.generalStore?.setError(t("error.notAllowed"), error);
                        break;
                    default:
                        break;
                }
            }
        } else {
            debug.error("### APIClient.checkStatusCodeError() network error:", error);
        }

        return retry;
    }
}

export const apiClient = new APIClient();
