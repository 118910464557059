import * as React from "react";
import { TEST_MS_TOKEN_REFRESH_OFFLINE } from "../../config";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { getCountryName } from "../../util/helpers";
import { MSCloseRefreshState } from "../auth/sites/AuthMSCloseSite";
import { useCrossTabState } from "../hooks/useCrossTabState";
import { usePendingMicrosoftActions } from "../hooks/usePendingMicrosoftActions";
import { NavBarInfo, NavBarInfoButton, NavBarInfoMessage } from "../ui/NavBarInfo";
import { Icon } from "../util/Icon";

export const ProjectsUnlockInternationalBar = () => {
    const { internationalAvailable, pendingActions, isLoading, reload } = usePendingMicrosoftActions();

    const [refresh, setRefresh] = useCrossTabState<MSCloseRefreshState>("refreshProjectsUnlockBar", "waiting");

    // Reload when token was refreshed
    React.useEffect(() => {
        if (refresh === "refresh") {
            reload();
            setRefresh("waiting");
        }
    }, [refresh, reload, setRefresh]);

    const companyId = companiesStore.selectedCompanyId;

    if (!companyId || companyId !== companiesStore.selectedCompany?.id) {
        return null;
    }

    // If we have stale countries -> the company has to be loaded, so we can get the country code for the refresh
    if (pendingActions.staleCountries.length > 0) {
        return null;
    }

    const internationalInvitePending = pendingActions.pendingInvitations.some(
        invitation => invitation.isInternational && invitation.companyId === companyId,
    );

    const localInvite = pendingActions.pendingInvitations.find(
        invitation => !invitation.isInternational && invitation.companyId === companyId,
    );

    const companyCountryCode = companiesStore.selectedCompany.countryCode;
    const refreshPending =
        TEST_MS_TOKEN_REFRESH_OFFLINE ||
        pendingActions.staleCountries.find(country => country === companyCountryCode) !== undefined;

    // Only way to detect international refresh is via "AT" tenant
    const internationalRefreshPending =
        companyCountryCode !== "AT" && pendingActions.staleCountries.find(country => country === "AT") !== undefined;

    // Only advisors are allowed to promote a project to international (clarified with Leo: all tpa are allowed to edit projects not only advisors)
    const internationalUnlockAvailable = !internationalAvailable && authStore.isTpa;

    // No refresh, unlock or invite pending -> get out
    if (
        isLoading ||
        (!internationalInvitePending &&
            !localInvite &&
            !internationalUnlockAvailable &&
            !refreshPending &&
            !internationalRefreshPending)
    ) {
        return null;
    }

    let message = "";
    let isInternational = true;
    if (refreshPending && companyCountryCode) {
        // Refresh first
        message = t("projects.refresh.message.singular", {
            country: getCountryName(companyCountryCode),
        });
    } else if (internationalRefreshPending) {
        // Refresh first
        message = t("projects.refresh.message.singular", {
            country: getCountryName("AT"),
        });
    } else if (internationalInvitePending) {
        // International invites second
        message = t("projects.invite.international");
    } else if (localInvite) {
        // Local invites third
        message = t("projects.invite.message.singular", {
            country: getCountryName(localInvite.countryCode),
        });

        isInternational = false;
    } else if (!internationalAvailable) {
        // International unlock last
        message = t("projects.international.activate");
    }

    const buttonMessage = t("button.unlock");

    const handleUnlock = async () => {
        if (refreshPending && companyCountryCode) {
            await authStore.refreshMSToken(companyCountryCode);
        } else if (internationalRefreshPending) {
            await authStore.refreshMSToken("AT");
        } else {
            // international invite and unlock use same call
            await authStore.acceptMSInvite({ companyId, international: isInternational });
        }
    };

    return (
        <NavBarInfo>
            <div style={{ marginRight: 16 }}>
                <Icon name="internationalProject" style={{ display: "block", color: "white" }} />
            </div>
            <NavBarInfoMessage>{message}</NavBarInfoMessage>
            <NavBarInfoButton onClick={handleUnlock}>{buttonMessage}</NavBarInfoButton>
        </NavBarInfo>
    );
};
