import { Button } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";

export const ContinueWithoutMicrosoftButton = (props: { onClick: () => void; style?: React.CSSProperties }) => {
    return (
        <Button color="primary" variant="outlined" fullWidth onClick={props.onClick} style={props.style}>
            {t("button.continue_without_microsoft")}
        </Button>
    );
};
