import { Button, useMediaQuery } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import loginBackground from "../../../assets/images/login_background.jpg";
import { IMessageIDS, t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { PostAuthInitResponse } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { InvalidLogin } from "../../../types/models";
import { getMfaValidation } from "../../../util/helpers";
import { pushRoute } from "../../app/router/history";
import { useMultiFactorAuth } from "../../hooks/useMultiFactorAuth";
import { useQueryParams } from "../../hooks/useQueryParams";
import { CustomInputField } from "../../ui/CustomInputField";
import { ErrorDialog } from "../../ui/ErrorDialog";
import { MicrosoftSignInButton } from "../../ui/MicrosoftSignInButton";
import { SuccessDialog } from "../../ui/SuccessDialog";
import { Icon } from "../../util/Icon";
import { TpaConnectLandscape } from "../../util/Images";
import { customColors } from "../../util/Theme";
import { AuthRoutes } from "../router/AuthRoutes";

const InnerContainer = styled.div`
    display: flex;
    min-height: 100%;
`;

const Container = ({ children }: { children: React.ReactNode }) => (
    // Fix for TPAPORTAL-1428: iOS < 14.4 does not like flex box at root. Height
    // calculation will go wrong and cut off contained elements.
    // Solution: Wrap flex box with normal div
    <div style={{ flexGrow: 1 }}>
        <InnerContainer>{children}</InnerContainer>
    </div>
);

const LEFT_CONTAINER_MAX_WIDTH = 620;

const LeftContainer = styled.div`
    background-color: ${customColors.white};
    min-width: 320px;
    max-width: ${LEFT_CONTAINER_MAX_WIDTH}px;
    flex-shrink: 0;
    width: 100%;
`;

const FormContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
    min-height: 500px;
    flex-direction: column;
`;

const LobbyImage = styled.div`
    flex-grow: 1;
    background-image: url(${loginBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const SUBTITLE_HEIGHT = 32;
const FORGOT_PASSWORD_HEIGHT = 76;

const errorDialogTitles: { [key in InvalidLogin]: IMessageIDS } = {
    credentials: "dialog.loginError.title",
    code: "dialog.loginError.multiFactorCodeWrong.title",
};

const SecondaryActionButton = (props: { title: string; onClick: () => void }) => {
    const isMobile = useMediaQuery(`(max-width: ${LEFT_CONTAINER_MAX_WIDTH}px)`, { noSsr: true });

    return (
        <div
            style={
                isMobile
                    ? { position: "relative", height: FORGOT_PASSWORD_HEIGHT }
                    : { position: "absolute", bottom: 64 }
            }
        >
            <Button
                style={isMobile ? { position: "absolute", bottom: 0 } : undefined}
                onClick={props.onClick}
                color="primary"
                data-id="resend_code"
            >
                {props.title.toUpperCase()}
            </Button>
        </div>
    );
};

const EmailForm = (props: { username?: string; onSubmit: (model: { username: string }) => void }) => {
    const isMobile = useMediaQuery(`(max-width: ${LEFT_CONTAINER_MAX_WIDTH}px)`, { noSsr: true });
    return (
        <Formik
            initialValues={{
                username: props.username ?? "",
            }}
            onSubmit={props.onSubmit}
            validationSchema={Yup.object().shape({
                username: Yup.string()
                    .required(t("screen.login.form.username.validation_error"))
                    .trim()
                    .email(t("missingEmail.email.error")),
            })}
            validateOnBlur
            validateOnChange
        >
            {p => {
                return (
                    <Form noValidate>
                        <p style={{ height: SUBTITLE_HEIGHT, marginTop: 12 }}>{t("screen.login.welcome")}</p>
                        <Field
                            data-id="login_username"
                            component={CustomInputField}
                            label={t("screen.login.form.username.label")}
                            name="username"
                            type="email"
                            required
                            autoComplete="username"
                            style={{ marginTop: 32 }}
                            autoFocus={!!props.username}
                        />
                        <Button
                            data-id="login_submit"
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            style={{ flexShrink: 0, marginTop: 8 }}
                        >
                            {t("common.continue")}
                        </Button>
                        {/* placeholder so form doesn't jump up */}
                        {isMobile && <div style={{ height: FORGOT_PASSWORD_HEIGHT }} />}
                    </Form>
                );
            }}
        </Formik>
    );
};

const SelectedUser = ({
    email,
    style,
    onClick,
}: {
    email: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}) => {
    return (
        <div
            style={{ display: "flex", alignItems: "center", cursor: onClick ? "pointer" : undefined, ...style }}
            onClick={onClick}
        >
            <Icon name={"chevronLeft"} style={{ marginRight: 8, color: customColors.primaryColor }} />
            <p>{email}</p>
        </div>
    );
};

const PasswordForm = (props: {
    username: string;
    onBack: () => void;
    onSubmit: (model: { password: string }) => void;
    onForgotPassword: () => void;
    showMsUpgrade?: boolean;
    onMsUpgrade: () => void;
}) => {
    return (
        <Formik
            initialValues={{
                password: "",
            }}
            onSubmit={props.onSubmit}
            validationSchema={Yup.object().shape({
                password: Yup.string().required(t("screen.login.form.password.validation_error")).trim(),
            })}
            validateOnBlur
            validateOnChange
        >
            {p => {
                return (
                    <Form noValidate>
                        <SelectedUser
                            email={props.username}
                            onClick={props.onBack}
                            style={{ marginTop: 12, height: SUBTITLE_HEIGHT }}
                        />
                        <Field
                            data-id="login_password"
                            component={CustomInputField}
                            label={t("screen.login.form.password.label")}
                            name="password"
                            type="password"
                            required
                            autoComplete="current-password"
                            style={{ marginTop: 32 }}
                            autoFocus
                        />
                        <Button
                            data-id="login_submit"
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            style={{ flexShrink: 0, marginTop: 8 }}
                        >
                            {t("screen.login.form.submit")}
                        </Button>
                        {props.showMsUpgrade && (
                            <MicrosoftSignInButton
                                variant="upgrade"
                                onClick={props.onMsUpgrade}
                                style={{ marginTop: 32 }}
                            />
                        )}
                        <SecondaryActionButton
                            title={t("screen.login.button.forgot_password")}
                            onClick={props.onForgotPassword}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

const MultiFactorAuthForm = (props: {
    username: string;
    onBack: () => void;
    onSubmit: (model: { code: string }) => void;
    onResendCode: () => void;
}) => {
    return (
        <Formik
            initialValues={{
                code: "",
            }}
            onSubmit={props.onSubmit}
            validationSchema={getMfaValidation()}
            validateOnBlur
            validateOnChange
        >
            {p => {
                return (
                    <Form noValidate>
                        <SelectedUser
                            email={props.username}
                            onClick={props.onBack}
                            style={{ marginTop: 12, height: SUBTITLE_HEIGHT }}
                        />
                        <p style={{ height: SUBTITLE_HEIGHT, marginTop: 12 }}>{t("screen.login.multiFactorAuth")}</p>
                        <Field
                            data-id="2FA_code"
                            component={CustomInputField}
                            label={t("screen.login.form.multiFactorAuth.label")}
                            name="code"
                            required
                            style={{ marginTop: 32 }}
                            autoFocus
                        />
                        <Button
                            data-id="2FA_code_submit"
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                            style={{ flexShrink: 0, marginTop: 8 }}
                        >
                            {t("screen.login.form.submit")}
                        </Button>
                        <SecondaryActionButton
                            title={t("screen.login.button.resendCode")}
                            onClick={props.onResendCode}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export const AuthLoginSite = observer(function AuthLoginSite() {
    const isMobile = useMediaQuery(`(max-width: ${LEFT_CONTAINER_MAX_WIDTH}px)`, { noSsr: true });

    const { msRegistrationSuccess, azureConfirmationCode } = useQueryParams<{
        msRegistrationSuccess: boolean;
        azureConfirmationCode: string;
    }>();
    const [showMsRegistrationSuccess, setShowMsRegistrationSuccess] = React.useState<boolean>(
        msRegistrationSuccess ?? false,
    );

    const [username, setUsername] = React.useState<string>("");

    const [postAuthInitResponse, setPostAuthInitResponse] = React.useState<PostAuthInitResponse>();

    const {
        submitPassword,
        submitMfaCode,
        invalidLogin,
        setInvalidLogin,
        currentAuthStep,
        setCurrentAuthStep,
        resetLogin,
        mfaResendSuccessDialog,
        passwordExpiredDialog,
    } = useMultiFactorAuth(coordinator.refreshAfterLogin);

    const handleEmailSubmit = async (model: { username: string }) => {
        setUsername(model.username);
        try {
            const res = await API.postAuthInit({ username: model.username, azureConfirmationCode });
            if (res.authType === "azure" && !!res.redirectUrl) {
                window.open(res.redirectUrl, "_self");
            } else {
                setCurrentAuthStep("password");
            }
            setPostAuthInitResponse(res);
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        }
    };

    const handleMsUpgrade = () => {
        if (postAuthInitResponse?.redirectUrl) {
            try {
                window.open(postAuthInitResponse.redirectUrl, "_self");
            } catch (error) {
                generalStore.setError(t("error.general"), error);
            }
        } else {
            generalStore.setError(t("error.general"));
        }
    };

    const handleForgotPassword = () => {
        pushRoute(AuthRoutes.FORGOT_PASSWORD.EMAIL);
    };

    // Need this to trigger rerender (otherwise there is no prop change)
    const showError = authStore.showUnknownMSLoginDialog;

    if (!authStore.isRehydrated) {
        return null;
    }

    return (
        <Container>
            <LeftContainer>
                <FormContainer>
                    <TpaConnectLandscape
                        style={{
                            position: "absolute",
                            left: isMobile ? 16 : 24,
                            top: isMobile ? 32 : 64,
                            width: 320,
                        }}
                    />
                    <div
                        style={{
                            maxWidth: 375 + 2 * 16,
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            padding: 16,
                            flexGrow: 1,
                        }}
                    >
                        <h1 className="display2">{t("screen.login.form.title")}</h1>
                        {currentAuthStep === "email" && <EmailForm username={username} onSubmit={handleEmailSubmit} />}
                        {currentAuthStep === "password" && (
                            <PasswordForm
                                username={username}
                                onBack={resetLogin}
                                onSubmit={model => submitPassword(username, model.password)}
                                onForgotPassword={handleForgotPassword}
                                showMsUpgrade={postAuthInitResponse?.azureUpgrade}
                                onMsUpgrade={handleMsUpgrade}
                            />
                        )}
                        {currentAuthStep === "multiFactorAuth" && (
                            <MultiFactorAuthForm
                                username={username}
                                onBack={resetLogin}
                                onSubmit={model => submitMfaCode(model.code)}
                                onResendCode={() => submitPassword(username)}
                            />
                        )}
                    </div>
                </FormContainer>
            </LeftContainer>
            <LobbyImage style={{ position: "relative", overflow: "hidden" }} />
            <ErrorDialog
                open={showError}
                onClose={() => {
                    authStore.showUnknownMSLoginDialog = false;
                }}
                title={t("dialog.user_not_found.title")}
                message={t("dialog.user_not_found.subtitle")}
                buttonLabel={t("dialog.user_not_found.button")}
            />
            {invalidLogin && (
                <ErrorDialog
                    open={!!invalidLogin}
                    onClose={() => {
                        setInvalidLogin(undefined);
                    }}
                    title={t(errorDialogTitles[invalidLogin])}
                    message={t("dialog.loginError.subtitle")}
                    buttonLabel={t("dialog.button.understood")}
                />
            )}
            <SuccessDialog
                open={showMsRegistrationSuccess}
                title={t("dialog.registration.success.header")}
                message={t("dialog.registration.success.message")}
                onClose={() => {
                    setShowMsRegistrationSuccess(false);
                }}
                buttonLabel={t("dialog.registration.success.button")}
            />
            {mfaResendSuccessDialog.dialog}
            {passwordExpiredDialog.dialog}
        </Container>
    );
});
