import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { BranchNameEntry } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { generalStore } from "../../stores/GeneralStore";
import { Module } from "../../types/models";

export function getBranchTypeString(branch: BranchNameEntry) {
    if (!branch.type) {
        return "";
    }

    return t(`branchType.${branch.type}`);
}

export const useCompanyBranches = (companyId: string | undefined, module: Module, advisor: boolean) => {
    const [branches, setBranches] = React.useState<BranchNameEntry[]>([]);
    const [fixed, setFixed] = React.useState(false);

    React.useEffect(() => {
        const load = async () => {
            if (!companyId || !advisor) {
                return;
            }

            try {
                generalStore.isLoading = true;
                const response = await API.getBranchNames(companyId, module);
                const wageCompanies = response.filter(branch => branch.type === "wageCompany");
                if (wageCompanies.length > 1) {
                    setBranches(wageCompanies);
                    setFixed(true);
                } else {
                    setBranches(response);
                }
            } catch (error) {
                const apiError = getApiError(error);

                // No FIBUs -> no record types -> no accounting
                if (apiError?.response.type === "MISSING_FINANCIAL_ACCOUNTANCIES") {
                    generalStore.setError(t("error.noFinancialAccountancies"), error);
                } else {
                    generalStore.setError(t("error.loadBranches"), error);
                }
            } finally {
                generalStore.isLoading = false;
            }
        };

        load();
    }, [advisor, companyId, module]);

    return {
        fixed,
        branches,
    };
};
