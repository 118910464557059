import { Accordion, AccordionSummary, withStyles } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { getJobTitle, t } from "../../i18n/util";
import { Status, statusColors } from "../../stores/Status";
import { IPublicUserInfo } from "../../types/models";
import { getRolesString } from "../../util/permissionHelpers";
import { User } from "../ui/User";
import { Icon, IIconNames } from "../util/Icon";
import { BOX_SHADOW_LIGHT, customColors } from "../util/Theme";
import { TpaBadge } from "./Primitives";

const ContactLine = ({
    iconName,
    label,
    borderBottom,
    style,
}: {
    iconName: IIconNames;
    label: string;
    borderBottom?: boolean;
    style?: React.CSSProperties;
}) => (
    <div
        style={{
            display: "flex",
            alignItems: "center",
            padding: 12,
            paddingLeft: 0,
            borderBottom: borderBottom ? `1px solid ${customColors.greyLight}` : undefined,
            ...style,
        }}
    >
        <Icon name={iconName} size={16} />
        <p className="caption" style={{ marginLeft: 16 }}>
            {label}
        </p>
    </div>
);

const StyledAccordion = withStyles({
    root: {
        "&:before": {
            height: 0,
        },
        "&.Mui-expanded": {
            margin: 0,
        },
    },
})(Accordion);

const StyledAccordionSummary = withStyles({
    root: {
        padding: 0,
        minHeight: 0,
        "&.Mui-expanded": {
            minHeight: 0,
        },
    },
    content: {
        margin: 0,
        "&.Mui-expanded": {
            margin: 0,
        },
    },
})(AccordionSummary);

const Arrow = styled.div`
    width: 17px;
    height: 17px;
    background: white;
    position: absolute;
    top: 24px;
    right: -9px;
    transform: rotate(45deg);
`;

// Show user information with status and optional details. Used only for Chat contact list.
export const UserCard = ({
    onClick,
    user,
    active,
    status,
    showDetail,
    highlightSelection,
}: {
    onClick: () => void;
    user: IPublicUserInfo;
    active?: boolean;
    status?: Status;
    showDetail?: boolean;
    highlightSelection?: boolean;
}) => {
    const borderColor = status ? statusColors[status].color : "white";
    const badgeCount = user.unreadChatMessages;

    let jobTitle;
    if (user.isTpaEmployee) {
        jobTitle = getJobTitle(user.tpaJobTitles, user.gender);
    } else {
        const roles = user.role ? [user.role] : user.roles;
        jobTitle = getRolesString({ roles, gender: user.gender, asJobTitle: true });
    }

    return (
        <StyledAccordion
            expanded={showDetail}
            onClick={onClick}
            style={{
                marginTop: 8,
                background: "white",
                padding: 16,
                borderRadius: 4,
                borderLeft: `6px solid ${borderColor}`,
                filter: highlightSelection && showDetail ? `drop-shadow(${BOX_SHADOW_LIGHT})` : undefined,
            }}
        >
            <StyledAccordionSummary>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                    <User
                        firstName={user.firstName}
                        lastName={user.lastName}
                        imageUrl={user.profile_picture_url}
                        isTpaStaff={user.isTpaEmployee}
                        data-id={`faceToFace_user_${user.id}`}
                        role={active === false ? t("support.inactiveEmployee") : jobTitle}
                    />
                    {!!badgeCount && <TpaBadge>{badgeCount}</TpaBadge>}
                </div>
            </StyledAccordionSummary>
            <div style={{ marginTop: 16 }}>
                {user.phoneNumber && (
                    <ContactLine
                        iconName="phone"
                        label={user.phoneNumber || "-"}
                        borderBottom={!!user.email || !!user.username || !!user.workLocation}
                    />
                )}
                {(!!user.email || !!user.username) && (
                    <ContactLine
                        iconName="mail"
                        label={user.email ?? (user.username || "-")}
                        borderBottom={!!user.workLocation}
                    />
                )}
                {user.workLocation && (
                    <ContactLine iconName="location" label={user.workLocation || "-"} style={{ paddingBottom: 0 }} />
                )}
            </div>
            {highlightSelection && showDetail && <Arrow />}
        </StyledAccordion>
    );
};
