import { observer } from "mobx-react";
import { t } from "../../i18n/util";
import { generalStore } from "../../stores/GeneralStore";
import { getRecordTypeStatus } from "../../stores/ModuleStore";
import { getModuleStore } from "../../stores/moduleStores";
import { IRecordType, Module } from "../../types/models";
import { useRefreshRecordTypes } from "../hooks/useRefreshRecordTypes";
import { FolderRow } from "../ui/FolderRow";

export const RecordTypesList = observer(function RecordTypesList(props: {
    module: Module;
    onRecordTypeSelected: (recordType: IRecordType) => void;
}) {
    useRefreshRecordTypes(props.module);
    const moduleStore = getModuleStore(props.module);

    if (!moduleStore.recordTypes || moduleStore.recordTypes.length === 0) {
        generalStore.setError(t("error.noRecordTypes"));
        return null;
    }

    if (generalStore.isLoading) {
        return null;
    }

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
            }}
        >
            <div style={{ alignSelf: "center", width: "100%" }}>
                {moduleStore.recordTypes.map((recordType, index) => {
                    const handleRowClick = () => {
                        props.onRecordTypeSelected(recordType);
                    };

                    const transferredAt = moduleStore.selectedPeriodActionDates?.dateRecordsTransferredAt;
                    const status = getRecordTypeStatus(recordType, transferredAt);

                    const getStatusTitle = () => {
                        if (status.status === "inProgress") {
                            return t("recordType.statusInProgress");
                        } else if (status.status === "closed") {
                            return t("recordType.statusClosed");
                        } else {
                            return t("recordType.statusOpen");
                        }
                    };

                    return (
                        <FolderRow
                            key={index}
                            title={moduleStore.getRecordTypeName(recordType)}
                            // personResponsible={record.personResponsible}
                            documentCount={recordType.recordCount}
                            ticketCount={recordType.ticketCount}
                            statusTitle={getStatusTitle()}
                            status={status.status}
                            date={status.date}
                            onClick={handleRowClick}
                            data-id={`recordtypes_${index}`}
                        />
                    );
                })}
            </div>
        </div>
    );
});
