import { observer } from "mobx-react";
import { FormattedHTMLMessage } from "react-intl";
import { CARD_SEND_DATE } from "../../config";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { periodToString } from "../../stores/ModuleStore";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";
import { formatDate } from "../../util/helpers";
import { ExpansionCard } from "../ui/ExpansionCard";
import { ModuleAddRecordsButton } from "./ModuleAddRecordButton";
import { ModuleMainResponsible } from "./ModuleMainResponsible";

interface IProps {
    module: Module;
    expanded: boolean;
    title: string;
    onExpand: (isExpanded: boolean) => void;
    "data-id": string;
}

export const ModuleWelcomeCard = observer(function ModuleWelcomeCard({
    module,
    title,
    expanded,
    onExpand,
    "data-id": dataId,
}: IProps) {
    const moduleStore = getModuleStore(module);

    const status = moduleStore.calculateSubsidiaryStatus();

    if (!moduleStore.selectedPeriod || !status) {
        return null;
    }

    let sentDate = new Date(moduleStore.selectedPeriod.periodStart);
    if (status.status === "closed" && status.releasedAt) {
        sentDate = status.releasedAt;
    }

    const period = periodToString(moduleStore.selectedPeriod);

    return (
        <ExpansionCard
            data-id={dataId}
            expanded={expanded}
            onExpand={onExpand}
            title={title}
            status={status.status}
            iconName="upload"
            date={status.dueDate}
        >
            <ModuleMainResponsible module={module} />
            <p style={{ marginTop: 16 }}>
                {(status.status === "open" || status.status === "inProgress") && (
                    <FormattedHTMLMessage
                        id={moduleStore.getTextId("screen.accounting.overview.welcome.inProgress")}
                        values={{
                            salutation: authStore.greeting,
                            period,
                            dueDate: moduleStore.selectedPeriod.dueDate
                                ? t("common.dueUntil", { dueDate: formatDate(moduleStore.selectedPeriod.dueDate) })
                                : "",
                            signature: companiesStore.mainResponsibleSignatureName(module),
                        }}
                    />
                )}
                {status.status === "closed" && (
                    <FormattedHTMLMessage
                        id={moduleStore.getTextId("screen.accounting.overview.welcome.closed")}
                        values={{ salutation: authStore.greeting }}
                    />
                )}
            </p>
            {CARD_SEND_DATE && (
                <p className="caption" style={{ marginTop: 8 }}>
                    {t("dueDate.sentAt", { date: formatDate(sentDate) })}
                </p>
            )}

            <ModuleAddRecordsButton status={status.status} moduleStore={moduleStore} />
        </ExpansionCard>
    );
});
