import { Routes } from "../../app/router/Routes";

const prefix = (route: string) => Routes.TICKETS + route;

export const TicketsRoutes = {
    ROOT: prefix("/"),
    OPEN: prefix("/open"),
    AUTHORED: prefix("/reported"),
    CLOSED: prefix("/closed"),
    DETAILS: prefix("/:ticketId/details"),
};
