import styled from "styled-components";
import { t } from "../../i18n/util";
import { useConfirmationDialog } from "../hooks/useConfirmationDialog";
import { NavBarInfo, NavBarInfoMessage } from "../ui/NavBarInfo";
import { TpaIconInfoButton } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

const InfoIcon = styled(Icon)`
    .i {
        fill: ${customColors.primaryColorLight}; // same as NavBarInfo background
    }
`;

export const Disclaimer = () => {
    const dialog = useConfirmationDialog({
        title: t("results.disclaimer"),
        message: <p style={{ whiteSpace: "pre-line" }}>{t("results.disclaimerMessage")}</p>,
        confirmLabel: t("common.ok"),
        onCancel: false,
    });

    return (
        <>
            <NavBarInfo onClick={() => dialog.open()}>
                <NavBarInfoMessage>
                    {t("results.disclaimer")}
                    <TpaIconInfoButton disableRipple color="inherit" size="small">
                        <InfoIcon name="info" />
                    </TpaIconInfoButton>
                </NavBarInfoMessage>
            </NavBarInfo>
            {dialog.dialog}
        </>
    );
};
