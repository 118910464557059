import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../../i18n/util";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { newsStore } from "../../../stores/NewsStore";
import { CockpitRouter } from "../router/CockpitRouter";

export const CockpitContainerSite = observer(function CockpitContainerSite() {
    // Has to be company and not selected id because otherwise we may look at the wrong definition for permission checks
    const companyId = companiesStore.selectedCompany?.id;
    const country = companiesStore.userCompanySettings?.feedCountryCode;

    React.useEffect(() => {
        const loadPromotions = async () => {
            try {
                await newsStore.loadPromotions();
            } catch (error) {
                generalStore.setError(t("error.loadPromotions"), error);
            }
        };

        const loadEvents = async () => {
            try {
                await newsStore.loadEvents();
            } catch (error) {
                generalStore.setError(t("error.loadEvents"), error);
            }
        };

        const loadPublications = async () => {
            try {
                await newsStore.loadPublications();
            } catch (error) {
                generalStore.setError(t("error.loadPublications"), error);
            }
        };

        loadPromotions();
        loadEvents();
        loadPublications();
    }, [companyId, country]);

    return <CockpitRouter />;
});
