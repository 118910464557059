import { Redirect } from "react-router";
import { t } from "../../../i18n/util";
import { companiesStore } from "../../../stores/CompaniesStore";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { RouterLinkButton, VCenter } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { Routes } from "../router/Routes";
import { toQueryString } from "../router/history";
import type { TermsOfUseSiteState } from "./TermsOfUseSite";

export const TermsNotAcceptedSite = () => {
    if (companiesStore.canAccessCompany) {
        return <Redirect to={Routes.COCKPIT} />;
    }

    // to avoid flashing during company change
    if (companiesStore.selectedCompanyId !== companiesStore.selectedCompany?.id) {
        return null;
    }

    return (
        <>
            <NavBarBack centeredLogoOnly></NavBarBack>
            <CenteredContent>
                <VCenter style={{ alignItems: "center" }}>
                    <SiteContent>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <h1 style={{ margin: 24 }}>{t("screen.termsNotAccepted.header")}</h1>
                            <p>{t("screen.termsNotAccepted.message")}</p>
                            {!!companiesStore.selectedCompany?.companyTerms?.token && (
                                <RouterLinkButton
                                    variant="contained"
                                    color="primary"
                                    to={{
                                        pathname: Routes.TERMS_OF_USE,
                                        search: toQueryString({
                                            token: companiesStore.selectedCompany.companyTerms.token,
                                        }),
                                        state: {
                                            backLabel: t("common.companyOverview"),
                                            backTarget: Routes.COMPANY_OVERVIEW,
                                        } satisfies TermsOfUseSiteState,
                                    }}
                                    style={{ marginTop: 24 }}
                                >
                                    {t("screen.termsNotAccepted.buttonAcceptNow")}
                                </RouterLinkButton>
                            )}
                            {companiesStore.numCompanies > 1 && (
                                <RouterLinkButton
                                    variant="contained"
                                    color="primary"
                                    to={Routes.COMPANY_OVERVIEW}
                                    style={{ marginTop: 24 }}
                                >
                                    {t("screen.termsNotAccepted.button")}
                                </RouterLinkButton>
                            )}
                        </div>
                    </SiteContent>
                </VCenter>
            </CenteredContent>
        </>
    );
};
