import { useEffect } from "react";
import { generalStore } from "../../stores/GeneralStore";

export const useGlobalQuickActionsOffset = (offset: number) => {
    useEffect(() => {
        generalStore.globalQuickActionsBottomOffset += offset;
        return () => {
            generalStore.globalQuickActionsBottomOffset -= offset;
        };
    }, [offset]);
};
