import { Button, Divider } from "@material-ui/core";
import flatMap from "lodash/flatMap";
import omit from "lodash/omit";
import * as React from "react";
import { t } from "../../../i18n/util";
import { CustomDrawer, CustomDrawerContent } from "../CustomDrawer";
import { BackButton, NavBarTitle } from "../NavBarContainer";
import { DateRangeFilter, NumberRangeFilter } from "./TableFilter";
import { FilterItem } from "./TableFilterDialog";
import { callOnChange, canSubmit } from "./filterHelper";
import {
    DATE_RANGE_FILTER_TYPE,
    DROPDOWN_FILTER_TYPE,
    DateRange,
    IFilterItem,
    ITableFilterCategory,
    ITableFilters,
    NUMBER_RANGE_FILTER_TYPE,
    NumberRange,
    TableFilterValue,
} from "./types";

interface IProps {
    open: boolean;
    onClose: () => void;
    filters: ITableFilterCategory[];
    activeFilters: ITableFilters;
    onSubmit: (filters: ITableFilters) => void;
}

export const TableFilterDrawer = ({ open, onClose, filters, activeFilters, onSubmit }: IProps) => {
    const [filterItems, setFilterItems] = React.useState<ITableFilters>({});
    const [selectedFilter, setSelectedFilter] = React.useState<IFilterItem | null>(null);

    React.useEffect(() => {
        setFilterItems(activeFilters);
    }, [activeFilters]);

    const handleChangeFilter = (name: string, value: TableFilterValue) => {
        if (value) {
            setFilterItems({
                ...filterItems,
                [name]: {
                    value,
                },
            });
        } else {
            setFilterItems(omit(filterItems, name));
        }
    };

    const handleClose = () => {
        setFilterItems(activeFilters);
        onClose();
    };

    const handleSubmit = () => {
        const filterDefinitions = flatMap(filters, category => category.entries);

        // check if all required filter values have been selected
        if (!canSubmit(filterDefinitions, filterItems)) {
            // an error is displayed in the FilterItem component already
            return;
        }

        // call the onChange function for all filters
        callOnChange(filterDefinitions, filterItems);

        onSubmit(filterItems);
        onClose();
    };

    const handleOptionsClick = (filter: IFilterItem) => () => {
        setSelectedFilter(filter);
    };

    const handleResetFilters = () => {
        const filterDefinitions = flatMap(filters, category => category.entries);
        const filterItems: ITableFilters = {};
        setFilterItems(filterItems);

        // check if all required filter values have been selected
        if (!canSubmit(filterDefinitions, filterItems)) {
            // an error is displayed in the FilterItem component already
            return;
        }

        onSubmit(filterItems);
        onClose();
    };

    return (
        <CustomDrawer
            open={open}
            onClose={selectedFilter ? undefined : handleClose}
            anchor="right"
            showCloseIcon
            PaperProps={{ style: { width: 400 } }}
        >
            {!selectedFilter && (
                <CustomDrawerContent style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <h1>{t("filterDialog.header")}</h1>
                    {filters.map(category => (
                        <React.Fragment key={category.category}>
                            {category.label && <h3 style={{ marginTop: 40, marginBottom: 16 }}>{category.label}</h3>}
                            {category.entries.map(entry => {
                                const filter = filterItems[entry.name];
                                const disabled =
                                    typeof entry.disabled === "function" ? entry.disabled(filterItems) : entry.disabled;

                                return (
                                    <FilterItem
                                        key={entry.name}
                                        name={entry.name}
                                        label={entry.label}
                                        type={entry.type}
                                        disabled={disabled}
                                        required={entry.required}
                                        requiredText={entry.requiredText}
                                        value={filter?.value ?? null}
                                        options={entry.type === DROPDOWN_FILTER_TYPE ? entry.options : undefined}
                                        onChange={handleChangeFilter}
                                        onClick={handleOptionsClick(entry)}
                                    />
                                );
                            })}
                        </React.Fragment>
                    ))}
                    <div style={{ flex: 1 }} />
                    <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
                        {t("filterDialog.apply")}
                    </Button>
                    <Button onClick={handleResetFilters} color="primary" fullWidth style={{ marginTop: 16 }}>
                        {t("filterDialog.reset")}
                    </Button>
                </CustomDrawerContent>
            )}
            {selectedFilter && (
                <>
                    <CustomDrawerContent style={{ paddingBottom: 24 }}>
                        <BackButton
                            backLabel={t("filterDialog.header")}
                            onBack={() => {
                                handleChangeFilter(selectedFilter.name, null);
                                setSelectedFilter(null);
                            }}
                        />
                        <NavBarTitle style={{ marginTop: 8 }}>{selectedFilter.label}</NavBarTitle>
                    </CustomDrawerContent>
                    <Divider />
                    <CustomDrawerContent style={{ display: "flex", flexDirection: "column", flex: 1, paddingTop: 0 }}>
                        {selectedFilter.type === DATE_RANGE_FILTER_TYPE && (
                            <DateRangeFilter
                                dateRange={filterItems[selectedFilter.name]?.value as DateRange | null}
                                filter={selectedFilter}
                                onChange={dateRange => {
                                    handleChangeFilter(selectedFilter.name, dateRange);
                                }}
                                style={{ paddingTop: 16, display: "flex" }}
                            />
                        )}
                        {selectedFilter.type === DROPDOWN_FILTER_TYPE &&
                            selectedFilter.options.map(option => (
                                <FilterItem
                                    key={option.value}
                                    name={selectedFilter.name}
                                    label={option.label}
                                    value={filterItems[selectedFilter.name]?.value === option.value}
                                    onChange={() => {
                                        handleChangeFilter(selectedFilter.name, option.value);
                                    }}
                                />
                            ))}
                        {selectedFilter.type === NUMBER_RANGE_FILTER_TYPE && (
                            <NumberRangeFilter
                                numberRange={filterItems[selectedFilter.name]?.value as NumberRange | null}
                                filter={selectedFilter}
                                onChange={numberRange => {
                                    handleChangeFilter(selectedFilter.name, numberRange);
                                }}
                                style={{ paddingTop: 16, display: "flex" }}
                            />
                        )}
                        <div style={{ flex: 1 }} />
                        <Button
                            onClick={() => {
                                setSelectedFilter(null);
                            }}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            {t("common.apply")}
                        </Button>
                    </CustomDrawerContent>
                </>
            )}
        </CustomDrawer>
    );
};
