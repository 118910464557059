import { Routes } from "../../app/router/Routes";

const prefix = Routes.prefixed(Routes.ADVISOR);

export const AdvisorRoutes = {
    ROOT: prefix("/"),
    UPLOADS: {
        ROOT: prefix("/uploads"),
        UPLOAD: prefix("/uploads/upload"),
        DOCUMENTS: prefix("/uploads/documents"),
    },
    COMPANIES: {
        ROOT: prefix("/companies"),
        ACTIVE: prefix("/companies/active"),
        INACTIVE: prefix("/companies/inactive"),
    },
    CONFIG: {
        ROOT: prefix("/config"),
        OVERVIEW: prefix("/config/company/:companyId/overview"),
        COMPANY_DATA: prefix("/config/company/:companyId/data"),
        TERMS_OF_USE: prefix("/config/company/:companyId/terms-of-use"),
        ACCOUNTING: prefix("/config/company/:companyId/accounting"),
        HR: prefix("/config/company/:companyId/hr"),
        KPIS: prefix("/config/company/:companyId/kpis"),
        USERS: prefix("/config/company/:companyId/users"),
        USERS_EMPLOYEES: prefix("/config/company/:companyId/users/employees"),
        USER_ROLES: prefix("/config/company/:companyId/users/roles"), // userIds as query params or state (can be multiple)
        USER_PERMISSIONS: prefix("/config/company/:companyId/users/permissions"), // userIds as query params or state (can be multiple)
        BULK: {
            DATA: prefix("/config/company/bulk/data"),
            TERMS_OF_USE: prefix("/config/company/bulk/terms-of-use"),
            FINISH: prefix("/config/company/bulk/finish"),
        },
    },
};
