import { observer } from "mobx-react";
import React from "react";
import { useHideSideBar } from "../../stores/SideBarStore";
import { useWebhookConfiguration } from "../hooks/useWebhookConfiguration";
import { CenteredContent } from "../ui/CenteredContent";
import { DocumentLine } from "../ui/DocumentLine/DocumentLine";
import { IUpload } from "../ui/DocumentUpload";
import { DocumentUploadSmall, IAcceptedFile, UseDocumentUploadSmallConfig } from "../ui/DocumentUploadSmall";
import { LoadingOverlay } from "../ui/LoadingOverlay";
import { NavBarBack } from "../ui/NavBarBack";
import { CenteredForm } from "../ui/Primitives";
import { SiteContent } from "../ui/SiteContent";

export const DocumentUploadSite = observer(function DocumentUploadSite<T, FileWithMetaData extends IAcceptedFile>({
    title,
    cancelTarget,
    uploadConfig,
    documentUploadSmallConfig,
    isLoading,
    renderAccepted,
    children,
}: {
    title: string;
    cancelTarget: string;
    uploadConfig: ReturnType<typeof useWebhookConfiguration>;
    documentUploadSmallConfig: UseDocumentUploadSmallConfig<T, FileWithMetaData>;
    isLoading?: boolean;
    renderAccepted?: (accepted: FileWithMetaData, onDelete: () => void) => React.ReactNode;
    children?: (documentUploadSmallElement: React.ReactNode) => React.ReactNode;
}) {
    useHideSideBar();

    const renderSuccess = (upload: IUpload<T>) => {
        return <DocumentLine fileName={upload.file.name} key={upload.file.name} />;
    };

    if (isLoading) {
        return <LoadingOverlay />;
    }

    const documentUploadSmallElement = (
        <DocumentUploadSmall
            renderSuccess={renderSuccess}
            renderAccepted={renderAccepted}
            config={documentUploadSmallConfig}
        />
    );

    return (
        <>
            <NavBarBack cancelTarget={cancelTarget} title={title} />
            <CenteredContent>
                <SiteContent>
                    <CenteredForm>
                        {uploadConfig.uploadConfiguration}
                        {uploadConfig.isBaseDataSelected &&
                            (typeof children === "function"
                                ? children(documentUploadSmallElement)
                                : documentUploadSmallElement)}
                    </CenteredForm>
                </SiteContent>
            </CenteredContent>
        </>
    );
});
