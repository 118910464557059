import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { RecordDestination } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useDocumentAccountingDestinations = (companyId?: string) => {
    const [destinations, setDestinations] = React.useState<RecordDestination[]>([]);

    const load = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const response = await API.getAccountingDocumentDestinations(companyId);
            setDestinations(response.documentDestinations);
        } catch (error) {
            generalStore.setError(t("error.loadDestinations"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return destinations;
};
