const croatian = {
  "accounting.bankAccount.account.label": "",
  "accounting.bankAccount.account.placeholder": "",
  "accounting.bankAccount.account.title": "",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "",
  "accounting.bankAccount.transactions.assignInvoices.title": "",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "",
  "accounting.bankAccount.transactions.table.emptyState.title": "",
  "accounting.bankAccount.transactions.table.label.amount": "",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "",
  "accounting.bankAccount.transactions.table.label.date": "",
  "accounting.bankAccount.transactions.table.label.invoice": "",
  "accounting.bankAccount.transactions.table.label.isPrivate": "",
  "accounting.bankAccount.transactions.table.label.recipient": "",
  "accounting.bankAccount.transactions.table.label.text": "",
  "accounting.bankAccount.transactions.table.label.valueDate": "",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "",
  "accounting.bankAccount.transactions.table.search.count": "",
  "accounting.bankAccount.transactions.table.search.placeholder": "",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "",
  "accounting.bankAccount.transactions.title": "",
  "accounting.bankConnection.delete.message": "",
  "accounting.bankConnection.delete.title": "",
  "accounting.bankConnections.accountHolder": "",
  "accounting.bankConnections.accountType": "",
  "accounting.bankConnections.accountType.Bausparen": "",
  "accounting.bankConnections.accountType.Checking": "",
  "accounting.bankConnections.accountType.CreditCard": "",
  "accounting.bankConnections.accountType.Loan": "",
  "accounting.bankConnections.accountType.Membership": "",
  "accounting.bankConnections.accountType.Savings": "",
  "accounting.bankConnections.accountType.Security": "",
  "accounting.bankConnections.addBankConnection": "",
  "accounting.bankConnections.balance": "",
  "accounting.bankConnections.emptyState.description": "",
  "accounting.bankConnections.emptyState.title": "",
  "accounting.bankConnections.expired": "",
  "accounting.bankConnections.expiredAt": "",
  "accounting.bankConnections.expiresAt": "",
  "accounting.bankConnections.iban": "",
  "accounting.bankConnections.lastSuccessfulUpdate": "",
  "accounting.bankConnections.pending": "",
  "accounting.bankConnections.refreshBankConnection": "",
  "accounting.bankConnections.refreshPending": "",
  "accounting.bankConnections.refreshPending.info": "",
  "accounting.bankConnections.refreshPending.label": "",
  "accounting.bankConnections.upToDate": "",
  "accounting.details.edit.header": "",
  "accounting.details.updatedAt": "",
  "accounting.details.upload.files": "",
  "accounting.details.upload.skip": "",
  "accounting.finished.reports": "",
  "accounting.table.label.totalGrossAmount": "",
  "addFolderDialog.button.text.create": "",
  "addFolderDialog.folderName": "",
  "addFolderDialog.info": "",
  "addFolderDialog.title": "",
  "advisor.uploads.uploadReports.title": "",
  "analysis.powerBI.reports.table.label.description": "",
  "analysis.powerBI.reports.table.label.name": "",
  "analysis.powerBI.reports.table.search.count": "",
  "analysis.powerBI.reports.table.search.placeholder": "",
  "analysis.powerBI.reports.title": "",
  "analysis.powerBI.report.title": "",
  "appNotificationDialog.button.accept": "",
  "appNotificationDialog.title.maintenance": "",
  "appNotificationDialog.title.newFeature": "",
  "appNotificationDialog.title.update": "",
  "branchType.allocationGroup": "",
  "branchType.costCenter": "",
  "branchType.department": "",
  "branchType.financialAccountancyCircle": "",
  "branchType.other": "",
  "branchType.wageCompany": "",
  "branchType.workplace": "",
  "button.activateMfa": "",
  "button.answer": "",
  "button.archive": "",
  "button.assign": "",
  "button.back_to_login": "",
  "button.closeTicket": "",
  "button.configure": "",
  "button.continueInBackground": "",
  "button.continue_without_microsoft": "",
  "button.copy": "",
  "button.deactivateMfa": "",
  "button.enter": "",
  "button.markAsPrivate": "",
  "button.moreInfo": "",
  "button.move": "",
  "button.nextStep": "",
  "button.paste": "",
  "button.refresh": "",
  "button.release": "",
  "button.submitArchiveMultiple": "",
  "button.submitArchiveSingle": "",
  "button.toOverview": "",
  "button.transfer": "",
  "button.unlock": "",
  "button.upload": "",
  "chatInput.deleteLink": "",
  "chatInput.editLink": "",
  "chatInput.enterLink": "",
  "chatInput.openLink": "",
  "chatInput.saveLink": "",
  "common.abacus": "",
  "common.accounting": "",
  "common.accounting.period": "",
  "common.accounting.recordType": "",
  "common.accounting.subsidiary": "",
  "common.add": "",
  "common.albanian": "Shqiptare",
  "common.all": "",
  "common.amount": "",
  "common.anyDateRange": "",
  "common.apply": "",
  "common.archive": "",
  "common.back": "",
  "common.bankConnection": "",
  "common.bankConnections": "",
  "common.bankContact": "",
  "common.bic": "",
  "common.bmd": "",
  "common.bulgarian": "Български",
  "common.cancel": "",
  "common.cancelCaps": "",
  "common.chat": "",
  "common.clientNumber": "",
  "common.cockpit": "",
  "common.companies": "",
  "common.company": "",
  "common.companyNumber": "",
  "common.companyOverview": "",
  "common.companyRegisterNumber": "",
  "common.continue": "",
  "common.country": "",
  "common.croatian": "Hrvatski",
  "common.czech": "Česky",
  "common.dateformat": "",
  "common.dateValidation": "",
  "common.defaultSignature": "",
  "common.delete": "",
  "common.done": "",
  "common.download": "",
  "common.dueDate": "",
  "common.dueUntil": "",
  "common.emailAddress": "",
  "common.english": "English",
  "common.errorOccurred": "",
  "common.events": "",
  "common.files": "",
  "common.finished": "",
  "common.folder": "",
  "common.from": "",
  "common.fullName": "",
  "common.generalData": "",
  "common.generalDocuments": "",
  "common.german": "Deutsch",
  "common.global": "",
  "common.hr": "",
  "common.hr.period": "",
  "common.hrPreRegistrations": "",
  "common.hr.short": "",
  "common.hr.subsidiary": "",
  "common.hungarian": "Magyar",
  "common.invite": "",
  "common.kpi": "",
  "common.kpi.long": "",
  "common.later": "",
  "common.logout": "",
  "common.me": "",
  "common.members": "",
  "common.moreInformation": "",
  "common.mr": "",
  "common.mrs": "",
  "common.name": "",
  "common.no": "",
  "common.none": "",
  "common.noSearchResults": "",
  "common.notAvailable": "",
  "common.notSpecified": "",
  "common.numberValidation": "",
  "common.ok": "",
  "common.optional": "",
  "common.orderBy": "",
  "common.partial": "",
  "common.people": "",
  "common.polish": "Polski",
  "common.profile": "",
  "common.project": "",
  "common.projects": "",
  "common.readingTimeMinutes": "",
  "common.readingTimeMinutes.short": "",
  "common.recipient": "",
  "common.refresh": "",
  "common.releasedAt": "",
  "common.releasedAtBy": "",
  "common.releaseRequired.plural": "",
  "common.releaseRequired.singular": "",
  "common.remainingItems": "",
  "common.remainingItemsUnknown": "",
  "common.retry": "",
  "common.romanian": "Românesc",
  "common.russian": "Русский",
  "common.save": "",
  "common.sendCaps": "",
  "common.serbian": "Српски",
  "common.settings": "",
  "common.showAll": "",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "",
  "common.taxNumber": "",
  "common.termsOfUse": "",
  "common.ticket": "",
  "common.timePeriod": "",
  "common.timePeriod.olderThan1Month": "",
  "common.timePeriod.olderThan1Week": "",
  "common.timePeriod.olderThan1Year": "",
  "common.timePeriod.olderThan6Months": "",
  "common.to": "",
  "common.tpaFirstName": "",
  "common.tpaLastName": "",
  "common.unknown": "",
  "common.upcomingTermsOfUse": "",
  "common.upload": "",
  "common.uploadFiles": "",
  "common.user": "",
  "common.vatNumber": "",
  "common.yes": "",
  "commuterAllowance.IndividuellePendlerpauschale": "",
  "commuterAllowance.KeinePendlerpauschale": "",
  "commuterAllowance.MehrAls20kmKeinBus": "",
  "commuterAllowance.MehrAls40kmBus": "",
  "commuterAllowance.MehrAls40kmKeinBus": "",
  "commuterAllowance.MehrAls60kmBus": "",
  "commuterAllowance.MehrAls60kmKeinBus": "",
  "commuterAllowance.Mindestens20kmBus": "",
  "commuterAllowance.Mindestens2kmKeinBus": "",
  "companiesUserReleaseInfoActionsBar.message": "",
  "companiesUserReleaseInfoActionsBar.submit": "",
  "companiesUserRelease.permissions.navbar.back": "",
  "companiesUserRelease.title": "",
  "companyImages.cover.error": "",
  "companyImages.cover.title": "",
  "companyImages.delete.message": "",
  "companyImages.delete.title": "",
  "companyImages.logo.error": "",
  "companyImages.logo.title": "",
  "companyImages.title": "",
  "companyList.remainingCompanies": "",
  "company.optionalSideBarMenuItems.maxLength.name": "",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "",
  "company.optionalSideBarMenuItems.name.label": "",
  "company.optionalSideBarMenuItems.required.name": "",
  "company.optionalSideBarMenuItems.required.url": "",
  "company.optionalSideBarMenuItems.title": "",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "",
  "company.optionalSideBarMenuItems.url.label": "",
  "company.optionalSideBarMenuItems.urlNotValid": "",
  "companyOverview.emptyState.message": "",
  "companyOverview.emptyState.title": "",
  "companyOverview.emptyState.tpa.message": "",
  "companyOverview.emptyState.tpa.title": "",
  "companyOverview.unlockCountry.tooltip": "",
  "companyStatus.accountingSetupDone": "",
  "companyStatus.active": "",
  "companyStatus.hrSetupDone": "",
  "companyStatus.inactive": "",
  "companyStatus.userSetupDone": "",
  "config.accounting.accountingByCustomer": "",
  "config.accounting.delete.subsidiaries.last.message": "",
  "config.accounting.delete.subsidiaries.message": "",
  "config.accounting.delete.subsidiaries.title": "",
  "config.accounting.description": "",
  "config.accounting.description.customer": "",
  "config.accounting.due.accounting.reports.select.title": "",
  "config.accounting.dueDates.title": "",
  "config.accounting.due.reportRelease.select.subtitle": "",
  "config.accounting.due.reportRelease.select.title": "",
  "config.accounting.due.reportRelease.select.value": "",
  "config.accounting.due.reportRelease.title": "",
  "config.accounting.due.reports.select.subtitle": "",
  "config.accounting.due.reports.select.value": "",
  "config.accounting.due.reports.title": "",
  "config.accounting.due.transfer.select.subtitle": "",
  "config.accounting.due.transfer.select.title": "",
  "config.accounting.due.transfer.select.value": "",
  "config.accounting.due.transfer.title": "",
  "config.accounting.globalReports.description": "",
  "config.accounting.globalReports.title": "",
  "config.accounting.hasAccounting": "",
  "config.accounting.hasBankAccountTransactionInvoices": "",
  "config.accounting.hasCockpitKpi": "",
  "config.accounting.hasKpi": "",
  "config.accounting.hasResults": "",
  "config.accounting.incaseoflaw.description": "",
  "config.accounting.incaseoflaw.label": "",
  "config.accounting.incaseoflaw.title": "",
  "config.accounting.month.description": "",
  "config.accounting.month.title": "",
  "config.accounting.quarter.description": "",
  "config.accounting.quarter.title": "",
  "config.accounting.recordTypes": "",
  "config.accounting.recordTypes.ocrDisabled": "",
  "config.accounting.recordTypes.ocrEnabled": "",
  "config.accounting.requireTwoReportReleases": "",
  "config.accounting.subsidiaries.add": "",
  "config.accounting.subsidiaries.title": "",
  "config.accounting.subsidiaries.title.customer": "",
  "config.accounting.subsidiary.maxReached": "",
  "config.accounting.subsidiary.name": "",
  "config.accounting.week.description": "",
  "config.accounting.week.title": "",
  "config.bulk.button.configure": "",
  "config.bulk.data.description": "",
  "config.bulk.data.title": "",
  "config.bulk.finish.description": "",
  "config.bulk.finish.start": "",
  "config.bulk.finish.title": "",
  "config.companies": "",
  "config.companies.buttonConfig": "",
  "config.companies.count": "",
  "config.companies.description": "",
  "config.companyData.cockpit": "",
  "config.companyData.description": "",
  "config.companyData.title": "",
  "config.companyOverview.inactive": "",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "",
  "config.customMenuPoints.noMenuPointsAvailable": "",
  "config.employees.assignRole": "",
  "config.employees.emptyState.message": "",
  "config.employees.emptyState.title": "",
  "config.employees.table.missingEmail": "",
  "config.employees.table.missingEmail.tooltip": "",
  "config.features.alreadyToggledToday.disabled": "",
  "config.features.alreadyToggledToday.enabled": "",
  "config.features.confirm.message.disabled": "",
  "config.features.confirm.message.enabled": "",
  "config.features.confirm.title.disabled": "",
  "config.features.confirm.title.enabled": "",
  "config.finishConfiguration": "",
  "config.finishConfiguration.cockpit": "",
  "config.finishConfiguration.continue": "",
  "config.finishConfiguration.emailNotification": "",
  "config.finishConfiguration.message": "",
  "config.finishSetup": "",
  "config.hr.chat.description": "",
  "config.hr.chat.title": "",
  "config.hr.delete.subsidiaries.last.message": "",
  "config.hr.delete.subsidiaries.message": "",
  "config.hr.delete.subsidiaries.title": "",
  "config.hr.description": "",
  "config.hr.dueDates.reminder": "",
  "config.hr.dueDates.title": "",
  "config.hr.due.relativeDueDates": "",
  "config.hr.due.reports.select.subtitle": "",
  "config.hr.due.reports.select.title": "",
  "config.hr.due.reports.select.value": "",
  "config.hr.due.select.value": "",
  "config.hr.due.transfer.select.subtitle": "",
  "config.hr.due.transfer.select.value.relativeDueDates": "",
  "config.hr.due.transfer.title": "",
  "config.hr.hasHr": "",
  "config.hr.hasResults": "",
  "config.hr.recordTypes": "",
  "config.hr.releaseSteps.oneStep.description": "",
  "config.hr.releaseSteps.oneStep.title": "",
  "config.hr.releaseSteps.title": "",
  "config.hr.releaseSteps.twoSteps.description": "",
  "config.hr.releaseSteps.twoSteps.title": "",
  "config.hr.requireTwoReportReleases": "",
  "config.hr.sendPayroll.description": "",
  "config.hr.sendPayroll.title": "",
  "config.hr.sendPayroll.tooltip": "",
  "config.hr.subsidiaries.add": "",
  "config.hr.subsidiaries.info": "",
  "config.hr.subsidiaries.title": "",
  "config.hr.subsidiaries.title.customer": "",
  "config.hr.subsidiary.maxReached": "",
  "config.hr.subsidiary.name": "",
  "config.hr.upload.description": "",
  "config.hr.upload.title": "",
  "config.hr.warning.noBranch": "",
  "config.moduleTypeDetails.asp.helpInstructions": "",
  "config.moduleTypeDetails.asp.helpInstructions.open": "",
  "config.moduleTypeDetails.label.database": "",
  "config.moduleTypeDetails.label.dataSource": "",
  "config.moduleTypeDetails.label.url": "",
  "config.noDueDate": "",
  "config.optionalMenuPoints.success.title": "",
  "config.overview": "",
  "config.permission.change": "",
  "config.permissions.accounting.globalReports": "",
  "config.permissions.deleted": "",
  "config.permissions.description": "",
  "config.permissions.employees": "",
  "config.permissions.existingUser.navbar.back": "",
  "config.permissions.existingUser.navbar.backToUser": "",
  "config.permissions.global": "",
  "config.permissions.internationalProjects.info": "",
  "config.permissions.navbar.back": "",
  "config.permissions.navbar.backToUser": "",
  "config.permissions.noPermissionSelected.message": "",
  "config.permissions.noPermissionSelected.title": "",
  "config.permissions.title": "",
  "config.roles.change": "",
  "config.roles.customizePermissions": "",
  "config.roles.description": "",
  "config.roles.multipleCompaniesDialog.message": "",
  "config.roles.multipleCompaniesDialog.title": "",
  "config.roles.multipleCompaniesDialog.warning": "",
  "config.roles.navbar.back": "",
  "config.roles.navbar.heading": "",
  "config.roles.option.copy.description": "",
  "config.roles.option.copy.label": "",
  "config.roles.option.copy.placeholder": "",
  "config.roles.releaseInformation": "",
  "config.roles.releaseInformation.info.message": "",
  "config.roles.releaseInformation.info.title": "",
  "config.roles.releaseInformation.superAdmin": "",
  "config.roles.sidebar.title": "",
  "config.roles.submit": "",
  "config.roles.submit.existingUser": "",
  "config.roles.submit.superAdmin": "",
  "config.roles.title": "",
  "config.roles.viewPermissions": "",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "",
  "config.terms.allSigneesAcceptedWarningDialog.message": "",
  "config.terms.allSigneesAcceptedWarningDialog.title": "",
  "config.terms.alreadySigned": "",
  "config.terms.button.revokeActivation": "",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "",
  "config.terms.changeDocumentVersionDialog.message": "",
  "config.terms.changeDocumentVersionDialog.title": "",
  "config.terms.deleteEmailDialog.confirmLabel": "",
  "config.terms.deleteEmailDialog.message": "",
  "config.terms.deleteEmailDialog.title": "",
  "config.terms.documentVersion": "",
  "config.terms.emailAddressesInfo": "",
  "config.terms.emailAddressesTitle": "",
  "config.terms.emailAlreadyUsed": "",
  "config.terms.invalidEmail": "",
  "config.terms.noDocumentsAvailable": "",
  "config.terms.noSigneesAvailable": "",
  "config.termsOfUse.navbar.back": "",
  "config.terms.removeEmail": "",
  "config.terms.resendEmail.header": "",
  "config.terms.resendEmail.message": "",
  "config.terms.resendMail": "",
  "config.terms.revokeActivationDialog.confirmLabel": "",
  "config.terms.revokeActivationDialog.message": "",
  "config.terms.revokeActivationDialog.title": "",
  "config.terms.signedVersion": "",
  "config.terms.skip.button": "",
  "config.terms.skip.dialog.message": "",
  "config.terms.skip.dialog.title": "",
  "config.terms.success.header": "",
  "config.terms.termsOfUseInfo": "",
  "config.terms.termsOfUseTitle": "",
  "config.users": "",
  "config.users.active.title": "",
  "config.users.assignRole": "",
  "config.users.buttonConfig": "",
  "config.users.changeUsername": "",
  "config.users.changeUsername.tooltip": "",
  "config.users.description": "",
  "config.users.edit": "",
  "config.users.emptyState.message": "",
  "config.users.emptyState.title": "",
  "config.users.inviteAgain": "",
  "config.users.navBar.back": "",
  "config.users.new": "",
  "config.users.newUsers": "",
  "config.users.newUsers.description": "",
  "config.users.newUsers.title": "",
  "config.users.reject": "",
  "config.users.release": "",
  "config.users.releaseAndInvite": "",
  "config.users.table.invalidEmail": "",
  "config.users.table.invalidEmail.tooltip": "",
  "config.users.waiting.title": "",
  "cookies.acceptAll": "",
  "cookies.acceptFunctional": "",
  "cookies.cookieList": "",
  "cookies.text": "",
  "country.al": "",
  "country.at": "",
  "country.bg": "",
  "country.cz": "",
  "country.hr": "",
  "country.hu": "",
  "country.me": "",
  "country.pl": "",
  "country.ro": "",
  "country.rs": "",
  "country.si": "",
  "country.sk": "",
  "deleteDialog.message.plural": "",
  "deleteDialog.message.singular": "",
  "deleteDialog.title": "",
  "deleteUser.message": "",
  "deleteUser.multipleCompanies.confirmLabel": "",
  "deleteUser.multipleCompanies.message": "",
  "deleteUser.title": "",
  "deleteUser.warning.superadminskipped": "",
  "dialog.archive.checkbox_pdf": "",
  "dialog.archive.subject": "",
  "dialog.archive.success.text": "",
  "dialog.archive.success.title": "",
  "dialog.archive.text": "",
  "dialog.archive.title_multiple": "",
  "dialog.archive.title_single": "",
  "dialog.assignTicket.header": "",
  "dialog.assignTicket.message": "",
  "dialog.button.understood": "",
  "dialog.closeTicket.success.header": "",
  "dialog.companyConfig.success.header": "",
  "dialog.companyConfig.success.message": "",
  "dialog.eldaTransfer.andNext": "",
  "dialog.eldaTransfer.downloadLater": "",
  "dialog.eldaTransfer.success.header": "",
  "dialog.loginError.multiFactorCodeWrong.title": "",
  "dialog.loginError.subtitle": "",
  "dialog.loginError.title": "",
  "dialog.noPhoneNumberWarning.message": "",
  "dialog.noPhoneNumberWarning.title": "",
  "dialog.passwordExpired.message": "",
  "dialog.passwordExpired.title": "",
  "dialog.password_reset.success.header": "",
  "dialog.registration.success.button": "",
  "dialog.registration.success.header": "",
  "dialog.registration.success.message": "",
  "dialog.transferReports.success.message": "",
  "dialog.transfer.success.header": "",
  "dialog.userConfig.success.header": "",
  "dialog.user_not_found.button": "",
  "dialog.user_not_found.subtitle": "",
  "dialog.user_not_found.title": "",
  "documentUpload.error.amount": "",
  "documentUpload.error.button.text.continue": "",
  "documentUpload.error.button.text.retry": "",
  "documentUpload.error.message": "",
  "documentUpload.error.rejection.amount": "",
  "documentUpload.error.tooBigImages.amount": "",
  "documentUpload.error.tooBigImages.amount.singular": "",
  "documentUpload.error.tooBigImages.button.text.continue": "",
  "documentUpload.error.tooBigImages.pdf": "",
  "documentUpload.error.tooManyFiles.plural": "",
  "documentUpload.error.tooManyFiles.singular": "",
  "documentUpload.max.size": "",
  "documentUpload.tooBigImages.message": "",
  "documentUpload.type.file.formats": "",
  "dropzone.button.label.search": "",
  "dropzone.image.text": "",
  "dropzone.info.redo": "",
  "dropzone.info.text": "",
  "dueDate.answerAt": "",
  "dueDate.closedAt": "",
  "dueDate.sentAt": "",
  "dueDate.sentAtWithTime": "",
  "dueDate.transferredAt": "",
  "dueDate.until": "",
  "employmentInsuranceType.apprentice": "",
  "employmentInsuranceType.fullTime": "",
  "employmentInsuranceType.independentContractor": "",
  "employmentInsuranceType.marginalIndependentContractor": "",
  "employmentInsuranceType.marginally": "",
  "employmentRelationship.employee": "",
  "employmentRelationship.worker": "",
  "error.accounting.postSubsidiary": "",
  "error.accounting.subsidiaryDoesNotExist": "",
  "error.activate": "",
  "error.addBankAccountTransactionInvoices": "",
  "error.addBankConnection": "",
  "error.addCompanyToUserFavorites": "",
  "error.addFolderBadRequest": "",
  "error.archiveDisabledForCompany": "",
  "error.archiveFailed": "",
  "error.archiveProjectFailed": "",
  "error.avatarUploadFailed": "",
  "error.avatarUploadOnlyOneImage": "",
  "error.changeUsernameEmail": "",
  "error.changeUsername.newUsernameConflict": "",
  "error.changeUsername.newUsernameDuplicate": "",
  "error.changeUsername.noNewUsername": "",
  "error.changeUsername.tokenExpired": "",
  "error.changeUsername.tokenNotFound": "",
  "error.chatForbidden": "",
  "error.chatNoCompany": "",
  "error.chatNoRecipient": "",
  "error.chatSearch": "",
  "error.completeSign": "",
  "error.config.accounting.duplicate": "",
  "error.config.accounting.noSubsidiary": "",
  "error.config.activateCompanyFailed": "",
  "error.config.corruptSubsidiary": "",
  "error.config.hr.duplicate": "",
  "error.config.hr.noSubsidiary": "",
  "error.copy.alreadyExists.file": "",
  "error.copy.alreadyExists.folder": "",
  "error.copy.alreadyExists.unknown": "",
  "error.create": "",
  "error.create.alreadyExists.project": "",
  "error.createEmployee": "",
  "error.delete": "",
  "error.deleteBankAccountTransactionInvoices": "",
  "error.deleteBankConnection": "",
  "error.delete.fileLocked": "",
  "error.deleteUser": "",
  "error.deregisterEmployee": "",
  "error.documentForbidden": "",
  "error.documentInProgress": "",
  "error.documentNotFound": "",
  "error.documentsInProgress": "",
  "error.download": "",
  "error.downloadTermsOfUseDocument": "",
  "error.download.transferInProgress": "",
  "error.dueDate.minDate": "",
  "error.duplicateName": "",
  "error.duplicateUsername": "",
  "error.edit": "",
  "error.employeeWithoutEmail": "",
  "error.folderIsEmpty": "",
  "error.foldersAreEmpty": "",
  "error.forbidden": "",
  "error.general": "",
  "error.getSign": "",
  "error.hr.noSubsidiarySelected": "",
  "error.hr.postSubsidiary": "",
  "error.hr.subsidiaryDoesNotExist": "",
  "error.imageCrop": "",
  "error.imageTooBig": "",
  "error.invalidCharacters": "",
  "error.invalidCompanyId": "",
  "error.invalid_credentials": "",
  "error.invalidDateFormat": "",
  "error.invalidEmployee": "",
  "error.invalidPeriodId": "",
  "error.invalidRolePermissions": "",
  "error.invalidSsn": "",
  "error.invalidTicket": "",
  "error.invalidWebhookParams": "",
  "error.invitationNoCompany": "",
  "error.linkExpired": "",
  "error.linkInvalid": "",
  "error.loadAccount": "",
  "error.loadAccountListing": "",
  "error.loadAccounts": "",
  "error.loadAccountTransactions": "",
  "error.loadArchiveDocuments": "",
  "error.loadBadges": "",
  "error.loadBalanceSheet": "",
  "error.loadBankAccountTransactions": "",
  "error.loadBankConnections": "",
  "error.loadBranches": "",
  "error.loadCashAccounting": "",
  "error.loadCompany": "",
  "error.loadCompanyFeatures": "",
  "error.loadContacts": "",
  "error.loadCostCenters": "",
  "error.loadCountries": "",
  "error.loadCustomerListing": "",
  "error.loadDestinations": "",
  "error.loadDocument": "",
  "error.loadDocuments": "",
  "error.loadDraft": "",
  "error.loadEmployee": "",
  "error.loadEmployeeDocuments": "",
  "error.loadEmployees": "",
  "error.loadEvents": "",
  "error.loadFeatureFlags": "",
  "error.loadFeedCountries": "",
  "error.loadFinancialAccountancies": "",
  "error.loadFormData": "",
  "error.loadFunders": "",
  "error.loadGeneralDocumentsTimeout": "",
  "error.loading": "",
  "error.loadInsuranceCarrierBalance": "",
  "error.loadInsuranceCarriers": "",
  "error.loadInvitationResults": "",
  "error.loadKpiConfigurations": "",
  "error.loadKpis": "",
  "error.loadMessages": "",
  "error.loadModuleTypeDetails": "",
  "error.loadNews": "",
  "error.loadNews.noSource": "",
  "error.loadNotifications": "",
  "error.loadOptionalMenuItems": "",
  "error.loadPayrollAccount": "",
  "error.loadPayrollAccountDetails": "",
  "error.loadPendingMicrosoftActions": "",
  "error.loadPeriod": "",
  "error.loadPeriodData": "",
  "error.loadPermissions": "",
  "error.loadPersonnelFile": "",
  "error.loadPersonnelFile.notStaff": "",
  "error.loadPowerBIReportEmbedToken": "",
  "error.loadPowerBIReports": "",
  "error.loadPreRegistration": "",
  "error.loadProfile": "",
  "error.loadProfitAndLoss": "",
  "error.loadProjects": "",
  "error.loadProjectsCompanies": "",
  "error.loadPromotions": "",
  "error.loadPublications": "",
  "error.loadRecord": "",
  "error.loadRecords": "",
  "error.loadRecordTypes": "",
  "error.loadReports": "",
  "error.loadResponsibleUsers": "",
  "error.loadRolePermissions": "",
  "error.loadSubsidiaries": "",
  "error.loadSubsidiary": "",
  "error.loadTeam": "",
  "error.loadTermsofUse": "",
  "error.loadTicketDetails": "",
  "error.loadTickets": "",
  "error.loadUserCompanySettings": "",
  "error.loadUsers": "",
  "error.loadVendorListing": "",
  "error.loadWaitingUsers": "",
  "error.loginAccountSetup": "",
  "error.logout": "",
  "error.markAsDone": "",
  "error.markBankAccountTransactionAsPrivate": "",
  "error.maxCharacters": "",
  "error.mfaActivation.codeNotMatched": "",
  "error.mfaCodeAlreadySent": "",
  "error.mfaCodeWrong": "",
  "error.mfaSettingChange": "",
  "error.mfaSettingChange.codeAlreadySent": "",
  "error.mfaSettingChange.codeNotMatched": "",
  "error.microsoftLogin": "",
  "error.missingDocument": "",
  "error.missingEmail": "",
  "error.missingEmployee": "",
  "error.missingParams": "",
  "error.move": "",
  "error.move.alreadyExists.unknown": "",
  "error.msTokenExpired": "",
  "error.multipleArchives": "",
  "error.noArchive": "",
  "error.noCompanySelected": "",
  "error.noDocumentSelected": "",
  "error.noDotAtEnd": "",
  "error.noFinancialAccountancies": "",
  "error.noMsAccountAvailable": "",
  "error.noRecordTypes": "",
  "error.noSignees": "",
  "error.noSubsidiaries.accounting": "",
  "error.noSubsidiaries.hr": "",
  "error.noSubsidiaryWithEmployeeRead": "",
  "error.notAdvisor": "",
  "error.notAllowed": "",
  "error.notificationCenter.load": "",
  "error.notificationCenter.markAllAsRead": "",
  "error.notificationCenter.markAsRead": "",
  "error.oauthApi": "",
  "error.oauthCompany": "",
  "error.oauthUrl": "",
  "error.overlappingSignature": "",
  "error.passwordReset.tokenExpired": "",
  "error.passwordReset.tokenNotFound": "",
  "error.payTaxAccount": "",
  "error.payUnpaidAccount": "",
  "error.periodClosed": "",
  "error.permission.corruptRoles": "",
  "error.permission.onlyOneRoleAllowed": "",
  "error.postTicket": "",
  "error.projectForbidden": "",
  "error.projectIsEmpty": "",
  "error.projectPermissions": "",
  "error.projects.cantMove": "",
  "error.projects.cantPaste": "",
  "error.projects.openBookmarkUrlFailed": "",
  "error.qes.missingSignaturePositions": "",
  "error.recordForbidden": "",
  "error.recordWithNotAllowedCompany": "",
  "error.refreshBankConnection": "",
  "error.releaseEmployeeDocument": "",
  "error.releaseReport": "",
  "error.removeBankAccountTransactionPrivateMark": "",
  "error.removeCompanyFromUserFavorites": "",
  "error.rename.alreadyExists.file": "",
  "error.rename.alreadyExists.folder": "",
  "error.requiredField": "",
  "error.resendTermsOfUseEmail": "",
  "error.save": "",
  "error.saveKpiConfigurations": "",
  "error.saveOptionalMenuItems": "",
  "error.saveTermsOfUse": "",
  "error.send": "",
  "error.setDocumentOrSignees": "",
  "error.settings": "",
  "error.signFileTooLarge": "",
  "error.signReadOnlyDocument": "",
  "error.signTermsOfUse": "",
  "error.subsidiaryWithoutRecordTypes": "",
  "error.termsOfUseDuplicateFile": "",
  "error.termsOfUseFolderNotFound": "",
  "error.timeout": "",
  "error.toggleCompanyFeature": "",
  "error.tokenRefresh": "",
  "error.tooManyCharacters": "",
  "error.transfer": "",
  "error.transferEmployee": "",
  "error.transferEmployeeDirect": "",
  "error.unknown": "",
  "error.unsupportedDocumentType": "",
  "error.updateBankAccountAccountNumber": "",
  "error.upgradeToMicrosoft": "",
  "error.upload": "",
  "error.userDeactivated": "",
  "error.userNotRegistered": "",
  "error.userUnknown": "",
  "externalLinkDialog.content": "",
  "externalLinkDialog.title": "",
  "failedUserDialog.amount": "",
  "failedUserDialog.button.text.continue": "",
  "failedUserDialog.button.text.retry": "",
  "failedUserDialog.error.emailAlreadyExists": "",
  "failedUserDialog.error.pendingPermissionChanges": "",
  "failedUserDialog.message": "",
  "filterDialog.apply": "",
  "filterDialog.category.advanced": "",
  "filterDialog.category.assigned": "",
  "filterDialog.header": "",
  "filterDialog.required": "",
  "filterDialog.reset": "",
  "finishProjectDialog.text": "",
  "finishProjectDialog.title": "",
  "footer.copyright": "",
  "footer.disclaimer": "",
  "footer.generalBusinessTerms": "",
  "footer.legalInformation": "",
  "footer.privacy": "",
  "helpcenter.ideas": "",
  "helpcenter.problems": "",
  "helpcenter.problems.email": "",
  "helpcenter.problems.send": "",
  "helpcenter.problems.upload.failed": "",
  "helpcenter.problems.upload.success": "",
  "helpcenter.problems.whathappened": "",
  "helpcenter.problems.whatshouldhappen": "",
  "helpcenter.problems.whatwasdone": "",
  "helpCenter.title": "",
  "helpcenter.videos": "",
  "helpcenter.videos.tpaonly": "",
  "hr.casualEmployment": "",
  "hr.disclaimer.additionalData": "",
  "hr.disclaimer.employmentDate": "",
  "hr.disclaimer.subtitle": "",
  "hr.disclaimer.title": "",
  "hr.disclaimer.transfer.direct": "",
  "hr.disclaimer.transfer.direct.subtitle": "",
  "hr.disclaimer.transfer.tpa": "",
  "hr.disclaimer.transfer.tpa.subtitle": "",
  "hr.documents.backLabel": "",
  "hr.documents.emptyState.header": "",
  "hr.documents.emptyState.message": "",
  "hr.documents.list.backLabel": "",
  "hr.documents.nonWorkingTimes": "",
  "hr.documents.nonWorkingTimesUpload": "",
  "hr.documents.upload.navHeader": "",
  "hr.documents.upload.navLabel": "",
  "hr.employee.employment": "",
  "hr.employee.employmentRelationship": "",
  "hr.employee.jobDescription": "",
  "hr.employee.payslip.upload.date": "",
  "hr.employee.payslip.upload.title": "",
  "hr.employee.payslip.upload.type": "",
  "hr.employee.payslip.upload.type.monthly": "",
  "hr.employee.payslip.upload.type.yearly": "",
  "hr.employee.reasonForLeaving": "",
  "hr.employee.workplaceState": "",
  "hr.finished.analysis": "",
  "hr.finished.billing": "",
  "hr.finished.globalAnalysis": "",
  "hr.finished.globalBilling": "",
  "hr.insuranceCarrier": "",
  "hr.overview.continuousActions": "",
  "hr.recordTypes.names.benefitsInKindCar": "",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "",
  "hr.recordTypes.names.mealDeductions": "",
  "hr.recordTypes.names.nonWorkingTimes": "",
  "hr.recordTypes.names.overtimeAndBonusPayments": "",
  "hr.recordTypes.names.supplementaryDocuments": "",
  "hr.recordTypes.names.timeRecords": "",
  "hr.recordTypes.names.travelExpenseReport": "",
  "hr.tickets.preRegistration.NavBar": "",
  "hr.tickets.preRegistration.NavBarLabel": "",
  "hr.tickets.preRegistration.toOverview": "",
  "imageCrop.description": "",
  "imageCrop.title": "",
  "imageCrop.zoom": "",
  "language.german": "",
  "maritalStatus.divorced": "",
  "maritalStatus.livingInPartnership": "",
  "maritalStatus.married": "",
  "maritalStatus.separated": "",
  "maritalStatus.unknown": "",
  "maritalStatus.unmarried": "",
  "maritalStatus.widowed": "",
  "menu.answer": "",
  "menu.assign": "",
  "menu.cancelRelease": "",
  "menu.delete": "",
  "menu.details": "",
  "menu.download": "",
  "menu.draft": "",
  "menu.edit": "",
  "menu.editRelease": "",
  "menu.finish": "",
  "menu.markAsDone": "",
  "menu.move": "",
  "menu.openInBrowser": "",
  "menu.openInSharepoint": "",
  "menu.rename": "",
  "menu.requestRelease": "",
  "menu.showReleases": "",
  "menu.view": "",
  "mfa.activation.dialog.code.required": "",
  "mfa.activation.dialog.description": "",
  "mfa.activation.dialog.title": "",
  "mfa.activation.success.dialog.title": "",
  "mfa.changeSetting.dialog.code.field.label": "",
  "mfa.changeSetting.dialog.resendCode": "",
  "mfa.codeResend.success.dialog.title": "",
  "mfa.deactivation.dialog.description": "",
  "mfa.deactivation.dialog.title": "",
  "mfa.deactivation.success.dialog.title": "",
  "mfa.validation.code.length": "",
  "mfa.validation.code.required": "",
  "mfa.validation.code.type": "",
  "microsoft_oauth.button.login.title": "",
  "microsoft_oauth.button.send_code.title": "",
  "microsoft_oauth.button.upgrade.title": "",
  "missingEmailDialog.description": "",
  "missingEmailDialog.title": "",
  "missingEmail.email.error": "",
  "missingEmail.email.label": "",
  "module.finished.globalReports": "",
  "modules.delete.report.message": "",
  "modules.delete.report.title": "",
  "navbar.week": "",
  "newUsername.checkbox.error": "",
  "notificationCenter.emptyState": "",
  "notificationCenter.filter.accounting": "",
  "notificationCenter.filter.appNotification:maintenance": "",
  "notificationCenter.filter.appNotification:newFeature": "",
  "notificationCenter.filter.appNotification:update": "",
  "notificationCenter.filter.companySpecific": "",
  "notificationCenter.filter.employeeDocument": "",
  "notificationCenter.filter.file": "",
  "notificationCenter.filter.general": "",
  "notificationCenter.filter.generalDocument": "",
  "notificationCenter.filter.hr": "",
  "notificationCenter.filter.invoice": "",
  "notificationCenter.filter.login": "",
  "notificationCenter.filter.module": "",
  "notificationCenter.filter.notificationType": "",
  "notificationCenter.filter.other": "",
  "notificationCenter.filter.payslip": "",
  "notificationCenter.filter.project": "",
  "notificationCenter.filter.report": "",
  "notificationCenter.filter.ticket": "",
  "notificationCenter.filter.unread": "",
  "notificationCenter.markAllAsRead": "",
  "notificationCenter.title": "",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "",
  "openEmployeeDocumentReleasesDialog.submit": "",
  "openEmployeeDocumentReleasesDialog.title": "",
  "pdfView.error.caption": "",
  "pendingUserCreationDialog.message": "",
  "pendingUserCreationDialog.title": "",
  "permission.accounting.banking": "",
  "permission.accounting.global.reports": "",
  "permission.accounting.global.results": "",
  "permission.accounting.incaseoflaw": "",
  "permission.accounting.records": "",
  "permission.accounting.reports": "",
  "permission.expenses.cockpit": "",
  "permission.expenses.kpi": "",
  "permission.hr.employeePayrollAccount": "",
  "permission.hr.employerPayrollAccount": "",
  "permission.hr.employerPayrollAccount.details": "",
  "permission.hr.global.reports": "",
  "permission.hr.global.results": "",
  "permission.hr.personnel": "",
  "permission.hr.records": "",
  "permission.hr.reports": "",
  "permission.option.canAccess": "",
  "permission.option.canEdit": "",
  "permission.option.canEdit.all": "",
  "permission.option.canEdit.none": "",
  "permission.option.canEdit.some": "",
  "permission.option.canPay": "",
  "permission.option.canRead": "",
  "permission.option.canRead.all": "",
  "permission.option.canRead.none": "",
  "permission.option.canRead.some": "",
  "permission.option.canRelease": "",
  "permission.option.canUpdate": "",
  "permission.option.canUpload": "",
  "permission.option.mixed": "",
  "permission.option.none": "",
  "permission.projects": "",
  "permission.settings.company": "",
  "permission.settings.features": "",
  "permission.settings.images": "",
  "permission.settings.user": "",
  "permission.taxAccount.payment": "",
  "permission.unpaidAccount.payment": "",
  "project.invitation": "",
  "projectItemPermission.owner": "",
  "projectItemPermission.owner.description": "",
  "projectItemPermission.read": "",
  "projectItemPermission.read.description": "",
  "projectItemPermission.readNoDownload": "",
  "projectItemPermission.write": "",
  "projectItemPermission.write.description": "",
  "projects.activate": "",
  "projects.active.emptystate.buttonlabel": "",
  "projects.active.emptystate.message": "",
  "projects.addCompanies": "",
  "projects.addCompanies.tooltip": "",
  "projects.addFolder": "",
  "projects.addMembers": "",
  "projects.addMembers.bulk.success.title": "",
  "projects.bulkDeleteDialog.message": "",
  "projects.bulkDeleteDialog.title": "",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "",
  "projects.closed.emptystate.message": "",
  "projects.companies.emptyState.title": "",
  "projects.companies.submit": "",
  "projects.copySuccessDialog.message": "",
  "projects.copySuccessDialog.title": "",
  "projects.create.bulk.backToProjects": "",
  "projects.create.bulk.description": "",
  "projects.create.bulk.start": "",
  "projects.deleted.emptystate.message": "",
  "projects.deleteDialog.message.file": "",
  "projects.deleteDialog.message.folder": "",
  "projects.deleteDialog.message.project": "",
  "projects.deleteDialog.title.file": "",
  "projects.deleteDialog.title.folder": "",
  "projects.deleteDialog.title.project": "",
  "projects.emptystate.title": "",
  "projects.externalId": "",
  "projects.finish.successDialog.title": "",
  "projects.folder.emptystate.message": "",
  "projects.folder.emptystate.title": "",
  "projects.importUsers.add": "",
  "projects.importUsers.replace": "",
  "projects.importUsers.switch": "",
  "projects.international": "",
  "projects.international.activate": "",
  "projects.international.confirmButton": "",
  "projects.international.disabled.refresh": "",
  "projects.international.disabled.unlock": "",
  "projects.international.info.message": "",
  "projects.international.info.subtitle": "",
  "projects.international.invite.message": "",
  "projects.international.invite.title": "",
  "projects.international.migrating": "",
  "projects.international.migrating.message": "",
  "projects.international.plural": "",
  "projects.international.switch": "",
  "projects.international.undoWarning.message": "",
  "projects.international.undoWarning.message.at": "",
  "projects.international.undoWarning.title": "",
  "projects.invite.international": "",
  "projects.invite.message": "",
  "projects.invite.message.singular": "",
  "projects.members.assignPermission": "",
  "projects.membersIncluded.multi": "",
  "projects.membersIncluded.single": "",
  "projects.moveConfirmationDialog.message": "",
  "projects.moveConfirmationDialog.title": "",
  "projects.moveSuccessDialog.message": "",
  "projects.moveSuccessDialog.title": "",
  "projects.navbar.activeProjects": "",
  "projects.navbar.deletedProjects": "",
  "projects.navbar.fileUpload": "",
  "projects.navbar.finishedProjects": "",
  "projects.navbar.heading": "",
  "projects.navbar.notConfiguredProjects": "",
  "projects.navbar.overview": "",
  "projects.navbar.project.fileTree": "",
  "projects.navbar.project.heading": "",
  "projects.navbar.project.releases": "",
  "projects.navbar.releases.heading": "",
  "projects.navbar.ticket.heading": "",
  "projects.new": "",
  "projects.new.createNewProject": "",
  "projects.new.externalId": "",
  "projects.new.name": "",
  "projects.new.permissionContextMenu.remove": "",
  "projects.new.permissions.text": "",
  "projects.new.title": "",
  "projects.new.userSearch.placeholder": "",
  "projects.notConfigured.emptystate.message": "",
  "projects.openReleases.plural": "",
  "projects.openReleases.singular": "",
  "projects.pasteProgressDialog.title": "",
  "projects.pasteSuccessDialog.message": "",
  "projects.pasteSuccessDialog.title": "",
  "projects.refreshDialog.message": "",
  "projects.refreshDialog.title": "",
  "projects.refresh.message": "",
  "projects.refresh.message.singular": "",
  "projects.releaseConfirmationDialog.message": "",
  "projects.releaseConfirmationDialog.title": "",
  "projects.releases.emptystate.message": "",
  "projects.releases.emptystate.title": "",
  "projects.releases.reject": "",
  "projects.releases.status.canceled": "",
  "projects.releases.status.canceledFrom": "",
  "projects.releases.status.canceled.tooltip": "",
  "projects.releases.status.open": "",
  "projects.releases.status.openFrom": "",
  "projects.releases.status.open.tooltip": "",
  "projects.releases.status.rejected": "",
  "projects.releases.status.rejectedFrom": "",
  "projects.releases.status.rejected.tooltip": "",
  "projects.releases.status.released": "",
  "projects.releases.status.releasedFrom": "",
  "projects.releases.status.released.tooltip": "",
  "projects.releaseSuccessDialog.message": "",
  "projects.releaseSuccessDialog.multi.message": "",
  "projects.releaseSuccessDialog.title": "",
  "projects.requestReleaseConfirmationDialog.confirm": "",
  "projects.requestReleaseConfirmationDialog.message": "",
  "projects.requestReleaseConfirmationDialog.multi.message": "",
  "projects.requestReleaseConfirmationDialog.multi.title": "",
  "projects.requestReleaseConfirmationDialog.title": "",
  "projects.restoreDialog.message.project": "",
  "projects.restoreDialog.title.project": "",
  "projects.statusClosed": "",
  "projects.statusClosed.personalized": "",
  "projects.statusDeleted": "",
  "projects.statusDeleted.personalized": "",
  "projects.statusInProgress": "",
  "projects.statusInProgress.personalized": "",
  "projects.tickets.plural": "",
  "projects.tickets.singular": "",
  "records.details.upload.header": "",
  "recordType.statusClosed": "",
  "recordType.statusInProgress": "",
  "recordType.statusOpen": "",
  "renameDialog.button.text.rename": "",
  "renameDialog.label.document": "",
  "renameDialog.label.file": "",
  "renameDialog.label.folder": "",
  "renameDialog.title.document": "",
  "renameDialog.title.file": "",
  "renameDialog.title.folder": "",
  "renameProjectItemDialog.button.text.rename": "",
  "renameProjectItemDialog.itemName.file": "",
  "renameProjectItemDialog.itemName.folder": "",
  "renameProjectItemDialog.title.file": "",
  "renameProjectItemDialog.title.folder": "",
  "renameRecordDialog.button.text.rename": "",
  "renameRecordDialog.name": "",
  "renameRecordDialog.title": "",
  "requestReleaseProjectItemDialog.button.text.request": "",
  "requestReleaseProjectItemDialog.info": "",
  "requestReleaseProjectItemDialog.label.comment": "",
  "requestReleaseProjectItemDialog.label.dueDate": "",
  "requestReleaseProjectItemDialog.label.requiresQes": "",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "",
  "requestReleaseProjectItemDialog.qes.collective.title": "",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "",
  "requestReleaseProjectItemDialog.qes.single.title": "",
  "requestReleaseProjectItemDialog.searchForUsers.error": "",
  "requestReleaseProjectItemDialog.text": "",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "",
  "requestReleaseProjectItemDialog.title.create": "",
  "requestReleaseProjectItemDialog.title.edit": "",
  "results.accountSheet.backLabel.accountSheet": "",
  "results.accountSheet.backLabel.results.accountListing": "",
  "results.accountSheet.backLabel.results.balanceSheet": "",
  "results.accountSheet.backLabel.results.cashAccounting": "",
  "results.accountSheet.backLabel.results.customerListing": "",
  "results.accountSheet.backLabel.results.profitAndLoss": "",
  "results.accountSheet.backLabel.results.vendorListing": "",
  "results.accountSheet.columns.amount": "",
  "results.accountSheet.columns.documentDate": "",
  "results.accountSheet.columns.documentNumber": "",
  "results.accountSheet.columns.documents": "",
  "results.accountSheet.columns.offsettingAccounts": "",
  "results.accountSheet.columns.openItemAmount": "",
  "results.accountSheet.columns.postingSymbol": "",
  "results.accountSheet.columns.postingText": "",
  "results.accountSheet.details.costCenter": "",
  "results.accountSheet.details.discount": "",
  "results.accountSheet.details.externalDocumentNumber": "",
  "results.accountSheet.details.foreignCurrency": "",
  "results.accountSheet.details.period": "",
  "results.accountSheet.details.serialNumber": "",
  "results.accountSheet.details.tax": "",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "",
  "results.accountSheet.details.taxCode.EUSt": "",
  "results.accountSheet.details.taxCode.igEMitVSt": "",
  "results.accountSheet.details.taxCode.igEOhneVst": "",
  "results.accountSheet.details.taxCode.igLieferung": "",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "",
  "results.accountSheet.details.taxCode.RCMitVSt": "",
  "results.accountSheet.details.taxCode.RCOhneVSt": "",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "",
  "results.accountSheet.details.taxCode.Ust": "",
  "results.accountSheet.details.taxCode.VSt": "",
  "results.accountSheet.details.taxPercent": "",
  "results.accountSheet.details.vatPeriod": "",
  "results.accountSheet.documents.downloadAll": "",
  "results.accountSheet.filters.amount": "",
  "results.accountSheet.filters.documentDate": "",
  "results.accountSheet.filters.postingSymbol": "",
  "results.accountSheet.noResults.message": "",
  "results.accountSheet.noResults.open.message": "",
  "results.accountSheet.noResults.open.title": "",
  "results.accountSheet.noResults.title": "",
  "results.accountSheet.offsettingAccounts.more": "",
  "results.accountSheet.paid": "",
  "results.accountSheet.search.count": "",
  "results.accountSheet.search.placeholder": "",
  "results.accountSheet.sum.title": "",
  "results.accountSheet.tabs.all": "",
  "results.accountSheet.tabs.open": "",
  "results.accountSheet.title": "",
  "results.columns.changeAbsolute": "",
  "results.columns.changePercentage": "",
  "results.columns.credit": "",
  "results.columns.debit": "",
  "results.common.accounts": "",
  "results.common.quickExpand.expandAccounts": "",
  "results.common.quickExpand.expandNthDepth": "",
  "results.common.quickExpand.expandRoot": "",
  "results.common.quickExpand.expandRows": "",
  "results.disclaimer": "",
  "results.disclaimerMessage": "",
  "results.navbar.buttons.accountListing": "",
  "results.navbar.buttons.balanceSheet": "",
  "results.navbar.buttons.cashAccounting": "",
  "results.navbar.buttons.customerListing": "",
  "results.navbar.buttons.payrollAccount": "",
  "results.navbar.buttons.profitAndLoss": "",
  "results.navbar.buttons.vendorListing": "",
  "results.noFinancialAccountancies.message": "",
  "results.noFinancialAccountancies.title": "",
  "results.payrollAccountSheet.dataTable.month": "",
  "results.payrollAccountSheet.dataTable.number": "",
  "results.payrollAccountSheet.dataTable.payrollType": "",
  "results.payrollAccountSheet.dataTable.total": "",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "",
  "results.payrollAccountSheet.noPermission.message": "",
  "results.payrollAccountSheet.noPermission.title": "",
  "results.payrollAccountSheet.noResults.details": "",
  "results.payrollAccountSheet.noResultsYear.message": "",
  "results.payrollAccountSheet.noResultsYear.title": "",
  "results.settings.accountsVisibility.hideAccounts": "",
  "results.settings.accountsVisibility.showAccounts": "",
  "results.settings.orderBy.accountNameAsc": "",
  "results.settings.orderBy.accountNameDesc": "",
  "results.settings.orderBy.accountNrAsc": "",
  "results.settings.orderBy.accountNrDesc": "",
  "results.settings.orderBy.amountAsc": "",
  "results.settings.orderBy.amountDesc": "",
  "results.settings.orderBy.title": "",
  "results.settings.previousYearRanges.fullYear": "",
  "results.settings.previousYearRanges.partialYear": "",
  "results.settings.variant.compactView": "",
  "results.settings.variant.currentAndPastYear": "",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "",
  "results.settings.variant.currentAndPastYearPercentageChange": "",
  "results.settings.variant.currentYear": "",
  "results.settings.variant.detailView": "",
  "results.title": "",
  "role.accountingAssistent": "",
  "role.accountingAssistent.description": "",
  "role.accountingManagement": "",
  "role.accountingManagement.description": "",
  "role.admin": "",
  "role.admin.description": "",
  "role.assistent": "",
  "role.assistent.description": "",
  "role.custom": "",
  "role.custom.description": "",
  "role.custom.jobTitle": "",
  "role.custom.jobTitle.female": "",
  "role.hrAssistent": "",
  "role.hrAssistent.description": "",
  "role.hrManagement": "",
  "role.hrManagement.description": "",
  "role.internationalProjects": "",
  "role.internationalProjects.female": "",
  "role.legalAdvisor": "",
  "role.noRole": "",
  "role.noRole.description": "",
  "role.staff": "",
  "role.staff.description": "",
  "role.staff.female": "",
  "role.superAdmin": "",
  "role.superAdmin.description": "",
  "role.tpaAccounting": "",
  "role.tpaAccounting.description": "",
  "role.tpaAdvisor": "",
  "role.tpaAdvisor.description": "",
  "role.tpaAdvisor.female": "",
  "role.tpaAnnualAccounts": "",
  "role.tpaAnnualAccounts.description": "",
  "role.tpaAuditor": "",
  "role.tpaBusinessConsultant": "",
  "role.tpaBusinessConsultant.description": "",
  "role.tpaChat": "",
  "role.tpaChatSecret": "",
  "role.tpaHr": "",
  "role.tpaHr.description": "",
  "role.tpaOther": "",
  "role.tpaOther.female": "",
  "role.tpaPartner": "",
  "role.tpaPartner.description": "",
  "role.tpaPartner.female": "",
  "role.tpaPayrollAccountant": "",
  "role.tpaReporting": "",
  "role.tpaReporting.description": "",
  "role.tpaSpecialAdvisor": "",
  "role.tpaSpecialAdvisor.description": "",
  "role.tpaTaxAccountant": "",
  "role.tpaTaxAccountant.description": "",
  "screen.accounting.navbar.bankConnections": "",
  "screen.accounting.navbar.heading": "",
  "screen.accounting.navbar.overview": "",
  "screen.accounting.navbar.records": "",
  "screen.accounting.overview.bankConnection": "",
  "screen.accounting.overview.bankConnection.expired": "",
  "screen.accounting.overview.bankConnection.expired.title": "",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "",
  "screen.accounting.overview.bankConnection.title": "",
  "screen.accounting.overview.card.analysis": "",
  "screen.accounting.overview.card.globalAnalysis": "",
  "screen.accounting.overview.card.overview": "",
  "screen.accounting.overview.finished.download": "",
  "screen.accounting.overview.finished.message": "",
  "screen.accounting.overview.reports": "",
  "screen.accounting.overview.ticketsWelcome.inProgress": "",
  "screen.accounting.overview.welcome.closed": "",
  "screen.accounting.overview.welcome.inProgress": "",
  "screen.accounting.records.banner.selectedAll": "",
  "screen.accounting.records.banner.selectedAll.transferFailed": "",
  "screen.accounting.records.banner.selectedCount": "",
  "screen.accounting.records.banner.totalCount": "",
  "screen.accounting.records.banner.totalCount.transferFailed": "",
  "screen.accounting.records.banner.unselect": "",
  "screen.accounting.records.documents": "",
  "screen.accounting.records.empty": "",
  "screen.accounting.records.filter.transferFailed": "",
  "screen.accounting.records.format.error": "",
  "screen.accounting.records.move.dialog.button.text": "",
  "screen.accounting.records.move.multiple.dialog.title": "",
  "screen.accounting.records.move.single.dialog.title": "",
  "screen.accounting.records.navbar.tab.list": "",
  "screen.accounting.records.navbar.tab.tickets": "",
  "screen.accounting.records.person.responsible": "",
  "screen.accounting.records.serverUpload.error": "",
  "screen.accounting.records.serverUpload.typeError": "",
  "screen.accounting.records.sizeAndFormat.error": "",
  "screen.accounting.records.size.error": "",
  "screen.accounting.records.tickets": "",
  "screen.accounting.records.transfer.button.text": "",
  "screen.accounting.records.transfer.dialog.text": "",
  "screen.accounting.records.transfer.dialog.textRecordType": "",
  "screen.accounting.records.transfer.dialog.title": "",
  "screen.accounting.records.transfer.retryDialog.button.text": "",
  "screen.accounting.records.transfer.retryDialog.file": "",
  "screen.accounting.records.transfer.retryDialog.text": "",
  "screen.accounting.records.transfer.retryDialog.title": "",
  "screen.accounting.records.transfer.successDialog.text": "",
  "screen.accounting.records.transfer.successDialog.title": "",
  "screen.accounting.records.types.header": "",
  "screen.accounting.records.unknown.error": "",
  "screen.accounting.records.upload.button": "",
  "screen.accounting.records.upload.navbar.back": "",
  "screen.accounting.recordTypes.title": "",
  "screen.advisor.tabbar.active": "",
  "screen.advisor.tabbar.notConfigured": "",
  "screen.advisor.upload.approve.info": "",
  "screen.advisor.uploads.upload.approve": "",
  "screen.advisor.uploads.upload.approve.option.click": "",
  "screen.advisor.uploads.upload.approve.option.none": "",
  "screen.advisor.uploads.upload.approve.option.qes": "",
  "screen.advisor.uploads.upload.buttonText": "",
  "screen.advisor.uploads.upload.category": "",
  "screen.advisor.uploads.upload.category.validation": "",
  "screen.advisor.uploads.upload.comment": "",
  "screen.advisor.uploads.upload.comment.downloadError": "",
  "screen.advisor.uploads.upload.dueDate": "",
  "screen.advisor.uploads.upload.dueDate.validation": "",
  "screen.advisor.uploads.upload.global": "",
  "screen.advisor.uploads.upload.module": "",
  "screen.advisor.uploads.upload.module.validation": "",
  "screen.advisor.uploads.upload.navBack": "",
  "screen.advisor.uploads.upload.navHeader": "",
  "screen.advisor.uploads.upload.notifyCustomer": "",
  "screen.advisor.uploads.upload.subsidiary.validation": "",
  "screen.advisor.uploads.upload.visibleToEmployee": "",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "",
  "screen.auth.forgot_password_confirm.title": "",
  "screen.auth.forgot_password_email.form.button": "",
  "screen.auth.forgot_password_email.form.label.email": "",
  "screen.auth.forgot_password_email.subtitle": "",
  "screen.auth.forgot_password_email.title": "",
  "screen.auth.forgot_password_email.validation_error": "",
  "screen.auth.forgot_password_reset.error.password_not_set": "",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "",
  "screen.auth.forgot_password_reset.error.password_weak": "",
  "screen.auth.forgot_password_reset.form.button": "",
  "screen.auth.forgot_password_reset.form.button.mfa": "",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "",
  "screen.auth.forgot_password_reset.form.mfa": "",
  "screen.auth.forgot_password_reset.form.password": "",
  "screen.auth.forgot_password_reset.form.repeat_password": "",
  "screen.auth.forgot_password_reset.subtitle": "",
  "screen.auth.forgot_password_reset.subtitle.mfa": "",
  "screen.auth.forgot_password_reset.title": "",
  "screen.auth.forgot_password_reset.title.mfa": "",
  "screen.auth.registration.form.button": "",
  "screen.auth.registration.form.button.mfa": "",
  "screen.auth.registration.form.button.mfa.resendCode": "",
  "screen.auth.registration.form.label.mfa": "",
  "screen.auth.registration.form.label.password": "",
  "screen.auth.registration.form.label.repeat_password": "",
  "screen.auth.registration.form.section_1": "",
  "screen.auth.registration.form.section_2": "",
  "screen.auth.registration.form.section_3": "",
  "screen.auth.registration.mfa.subtitle": "",
  "screen.auth.registration.subtitle": "",
  "screen.auth.registration.title": "",
  "screen.auth.welcome.subtitle": "",
  "screen.auth.welcome.subtitle.msOnly": "",
  "screen.auth.welcome.title": "",
  "screen.changeUsername.checkbox": "",
  "screen.changeUsername.message": "",
  "screen.changeUsername.title": "",
  "screen.cockpit.allEvents": "",
  "screen.cockpit.allNews": "",
  "screen.cockpit.dueDates.export": "",
  "screen.cockpit.dueDates.navbar.heading": "",
  "screen.cockpit.dueDates.projects.text": "",
  "screen.cockpit.dueDates.records.accounting.text": "",
  "screen.cockpit.dueDates.records.hr.text": "",
  "screen.cockpit.dueDates.reports.global.text": "",
  "screen.cockpit.dueDates.reports.subsidiary.text": "",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "",
  "screen.cockpit.dueDates.reportUpload.global.text": "",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "",
  "screen.cockpit.dueDates.tickets.global.text": "",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "",
  "screen.cockpit.emptyState.message": "",
  "screen.cockpit.emptyState.title": "",
  "screen.cockpit.eventDetail": "",
  "screen.cockpit.eventDetails.heading": "",
  "screen.cockpit.eventDetails.lecturer.email": "",
  "screen.cockpit.eventDetails.lecturers": "",
  "screen.cockpit.eventDetails.lecturer.telephone": "",
  "screen.cockpit.eventDetails.register": "",
  "screen.cockpit.event.moreInformation": "",
  "screen.cockpit.kpis.add": "",
  "screen.cockpit.kpis.chart.legend": "",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "",
  "screen.cockpit.kpis.configurationDialog.metric.label": "",
  "screen.cockpit.kpis.configurationDialog.months.label": "",
  "screen.cockpit.kpis.configurationDialog.title": "",
  "screen.cockpit.kpis.ebit.chart.name": "",
  "screen.cockpit.kpis.ebit.kpi.name": "",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "",
  "screen.cockpit.kpis.empty": "",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "",
  "screen.cockpit.kpis.noneConfigured": "",
  "screen.cockpit.kpis.revenue.chart.name": "",
  "screen.cockpit.kpis.revenue.kpi.name": "",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "",
  "screen.cockpit.kpis.save": "",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "",
  "screen.cockpit.kpis.taxAccount.kpi.label": "",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "",
  "screen.cockpit.kpis.taxAccount.kpi.name": "",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "",
  "screen.cockpit.navbar.heading": "",
  "screen.cockpit.overview.kpis.title": "",
  "screen.cockpit.overview.newestPublications": "",
  "screen.cockpit.overview.notifications.dueDates": "",
  "screen.cockpit.overview.notifications.messages": "",
  "screen.cockpit.overview.notifications.tickets": "",
  "screen.cockpit.overview.notifications.title": "",
  "screen.cockpit.overview.promotions.title": "",
  "screen.cockpit.overview.relevantNews.title": "",
  "screen.cockpit.overview.relevantPublications.description": "",
  "screen.cockpit.overview.relevantPublications.title": "",
  "screen.cockpit.promotion": "",
  "screen.cockpit.promotion.additionalNews": "",
  "screen.cockpit.relevantNews": "",
  "screen.cockpit.relevantNews.additionalNews": "",
  "screen.cockpit.tickets.navbar.heading": "",
  "screen.hr.additionaldata.addBonus": "",
  "screen.hr.additionaldata.additionaldata": "",
  "screen.hr.additionaldata.additionalInformation": "",
  "screen.hr.additionaldata.amount": "",
  "screen.hr.additionaldata.amount_error": "",
  "screen.hr.additionaldata.bankDetails": "",
  "screen.hr.additionaldata.bic": "",
  "screen.hr.additionaldata.bic.validation": "",
  "screen.hr.additionaldata.commuterAllowance": "",
  "screen.hr.additionaldata.costCenter": "",
  "screen.hr.additionaldata.costCenterNote": "",
  "screen.hr.additionaldata.description": "",
  "screen.hr.additionaldata.designator": "",
  "screen.hr.additionaldata.designator_error": "",
  "screen.hr.additionaldata.emailAddress": "",
  "screen.hr.additionaldata.email_error": "",
  "screen.hr.additionaldata.familyBonusPlusAmount": "",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "",
  "screen.hr.additionaldata.funder": "",
  "screen.hr.additionaldata.gross": "",
  "screen.hr.additionaldata.hours_error": "",
  "screen.hr.additionaldata.iban": "",
  "screen.hr.additionaldata.iban_error": "",
  "screen.hr.additionaldata.jobDescription": "",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "",
  "screen.hr.additionaldata.maritalStatus": "",
  "screen.hr.additionaldata.monthlySalary": "",
  "screen.hr.additionaldata.monthlySalary_error": "",
  "screen.hr.additionaldata.net": "",
  "screen.hr.additionaldata.nextPage": "",
  "screen.hr.additionaldata.number_error": "",
  "screen.hr.additionaldata.per": "",
  "screen.hr.additionaldata.personaldata": "",
  "screen.hr.additionaldata.religiousConfession": "",
  "screen.hr.additionaldata.salarayAndPosition": "",
  "screen.hr.additionaldata.saveChanges": "",
  "screen.hr.additionaldata.soleEarnerStatus": "",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "",
  "screen.hr.additionaldata.titlePrefix": "",
  "screen.hr.additionaldata.titleSuffix": "",
  "screen.hr.additionaldata.wageAgreementClassification": "",
  "screen.hr.additionaldata.weeklyWorkDays": "",
  "screen.hr.additionaldata.weeklyWorkDays_error": "",
  "screen.hr.additionaldata.workingHours": "",
  "screen.hr.additionaldata.workingHoursInterval": "",
  "screen.hr.additionaldata.workingTimes": "",
  "screen.hr.cancelRegistration.dataSaved": "",
  "screen.hr.cancelRegistration.header": "",
  "screen.hr.cancelRegistration.question1": "",
  "screen.hr.cancelRegistration.question2": "",
  "screen.hr.deregistration.data.navback": "",
  "screen.hr.deregistration.documents.navback": "",
  "screen.hr.deregistration.navheader": "",
  "screen.hr.deregistration.validation.deregistrationDate": "",
  "screen.hr.deregistration.validation.deregistrationDateMin": "",
  "screen.hr.deregistration.validation.deregistrationReason": "",
  "screen.hr.deregistration.validation.remainingHolidays": "",
  "screen.hr.deregistration.validation.remainingOvertime": "",
  "screen.hr.documents.citizenship": "",
  "screen.hr.documents.contract": "",
  "screen.hr.documents.description": "",
  "screen.hr.documents.emptystate.buttonlabel": "",
  "screen.hr.documents.emptystate.message": "",
  "screen.hr.documents.emptystate.title": "",
  "screen.hr.documents.enterManually": "",
  "screen.hr.documents.idcard": "",
  "screen.hr.documents.logTime": "",
  "screen.hr.documents.needsRelease.notReleased": "",
  "screen.hr.documents.needsRelease.released": "",
  "screen.hr.documents.needsRelease.released.tooltip": "",
  "screen.hr.documents.nextButton": "",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "",
  "screen.hr.documents.others": "",
  "screen.hr.documents.registrationslip": "",
  "screen.hr.documents.uploadDocuments": "",
  "screen.hr.edit.flow.navback": "",
  "screen.hr.edit.flow.navheader": "",
  "screen.hr.employee.current.all": "",
  "screen.hr.employee.current.anyDateOfJoining": "",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "",
  "screen.hr.employee.current.cancellation": "",
  "screen.hr.employee.current.changeUsername": "",
  "screen.hr.employee.current.changeUsername.successDialog.message": "",
  "screen.hr.employee.current.changeUsername.successDialog.title": "",
  "screen.hr.employee.current.changeUsername.tooltip": "",
  "screen.hr.employee.current.dataChange": "",
  "screen.hr.employee.current.notTransferred": "",
  "screen.hr.employee.current.registration": "",
  "screen.hr.employee.deregistration.data.date": "",
  "screen.hr.employee.deregistration.data.header": "",
  "screen.hr.employee.deregistration.data.reason": "",
  "screen.hr.employee.deregistration.data.remainingHolidays": "",
  "screen.hr.employee.deregistration.data.remainingOvertime": "",
  "screen.hr.employee.deregistration.data.subtitle": "",
  "screen.hr.employee.deregistration.documents.submitButton": "",
  "screen.hr.employee.deregistration.stepper.data": "",
  "screen.hr.employee.deregistration.stepper.documents": "",
  "screen.hr.employee.details.addFiles": "",
  "screen.hr.employee.details.currentEmployment": "",
  "screen.hr.employee.details.deregisterEmployee": "",
  "screen.hr.employee.details.employments": "",
  "screen.hr.employee.details.fileProvidedBy": "",
  "screen.hr.employee.details.fileReleased": "",
  "screen.hr.employee.details.linkedFiles": "",
  "screen.hr.employee.details.navBack": "",
  "screen.hr.employee.details.pastEmployments": "",
  "screen.hr.employee.details.payrollAccount.navBack": "",
  "screen.hr.employee.details.personnelFile": "",
  "screen.hr.employee.details.personnelNumber": "",
  "screen.hr.employee.details.records": "",
  "screen.hr.employee.details.reregisterEmployee": "",
  "screen.hr.employee.details.showAll": "",
  "screen.hr.employee.details.showEmployeePayrollAccount": "",
  "screen.hr.employee.details.status": "",
  "screen.hr.employee.details.weeklyWorkDays": "",
  "screen.hr.employee.details.workingHours": "",
  "screen.hr.employee.documents.all": "",
  "screen.hr.employee.documents.annualPayslips": "",
  "screen.hr.employee.documents.anyDate": "",
  "screen.hr.employee.documents.backTargetDescription": "",
  "screen.hr.employee.documents.certificateOfCitizenship": "",
  "screen.hr.employee.documents.certificatesOfRegistration": "",
  "screen.hr.employee.documents.contracts": "",
  "screen.hr.employee.documents.deregisterDocument": "",
  "screen.hr.employee.documents.employeeDocuments": "",
  "screen.hr.employee.documents.employmentPermit": "",
  "screen.hr.employee.documents.generalDocuments": "",
  "screen.hr.employee.documents.identityCard": "",
  "screen.hr.employee.documents.payslips": "",
  "screen.hr.employee.emptystate.buttonlabel": "",
  "screen.hr.employee.emptystate.message": "",
  "screen.hr.employee.emptystate.title": "",
  "screen.hr.employee.former.anyDateOfLeaving": "",
  "screen.hr.employee.registration.stepper.additional": "",
  "screen.hr.employee.registration.stepper.documents": "",
  "screen.hr.employee.registration.stepper.personal": "",
  "screen.hr.employee.registration.stepper.status": "",
  "screen.hr.employee.registration.stepper.summary": "",
  "screen.hr.employees.current.registerEmployee": "",
  "screen.hr.employees.current.uploadPayslip": "",
  "screen.hr.employees.documents.recordType.title": "",
  "screen.hr.employees.documents.title": "",
  "screen.hr.navbar.documents": "",
  "screen.hr.navbar.employees": "",
  "screen.hr.navbar.heading": "",
  "screen.hr.navbar.overview": "",
  "screen.hr.navbar.tabbar.current": "",
  "screen.hr.navbar.tabbar.documents": "",
  "screen.hr.navbar.tabbar.employees": "",
  "screen.hr.navbar.tabbar.former": "",
  "screen.hr.navbar.tabbar.inprogress": "",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "",
  "screen.hr.overview.card.analysis": "",
  "screen.hr.overview.card.billing": "",
  "screen.hr.overview.card.globalAnalysis": "",
  "screen.hr.overview.card.globalBilling": "",
  "screen.hr.overview.card.overview": "",
  "screen.hr.overview.card.registration": "",
  "screen.hr.overview.finished.message": "",
  "screen.hr.overview.registration": "",
  "screen.hr.overview.registration.button": "",
  "screen.hr.overview.ticketsWelcome.inProgress": "",
  "screen.hr.overview.welcome.closed": "",
  "screen.hr.overview.welcome.inProgress": "",
  "screen.hr.personaldata.address": "",
  "screen.hr.personaldata.addressAddition": "",
  "screen.hr.personaldata.addressHead": "",
  "screen.hr.personaldata.area.validation": "",
  "screen.hr.personaldata.birthdate.validation": "",
  "screen.hr.personaldata.citizenship": "",
  "screen.hr.personaldata.citizenship.validation": "",
  "screen.hr.personaldata.city": "",
  "screen.hr.personaldata.country": "",
  "screen.hr.personaldata.country.validation": "",
  "screen.hr.personaldata.dateOfBirth": "",
  "screen.hr.personaldata.dateOfJoining": "",
  "screen.hr.personaldata.description": "",
  "screen.hr.personaldata.diverse": "",
  "screen.hr.personaldata.employmentInsuranceType": "",
  "screen.hr.personaldata.employmentRelationship": "",
  "screen.hr.personaldata.employmenttypeheader": "",
  "screen.hr.personaldata.employmenttypeinformationbody": "",
  "screen.hr.personaldata.employmenttypeinformationhead": "",
  "screen.hr.personaldata.employmenttype.validation": "",
  "screen.hr.personaldata.entrydate.invalid": "",
  "screen.hr.personaldata.entrydate.validation": "",
  "screen.hr.personaldata.female": "",
  "screen.hr.personaldata.firstname": "",
  "screen.hr.personaldata.firstname.validation": "",
  "screen.hr.personaldata.fullTime": "",
  "screen.hr.personaldata.houseNumber": "",
  "screen.hr.personaldata.houseNumber.validation": "",
  "screen.hr.personaldata.insurancedata": "",
  "screen.hr.personaldata.insurancedatainformationbody": "",
  "screen.hr.personaldata.insurancedatainformationhead": "",
  "screen.hr.personaldata.insurancetype.validation": "",
  "screen.hr.personaldata.lastname": "",
  "screen.hr.personaldata.lastname.validation": "",
  "screen.hr.personaldata.male": "",
  "screen.hr.personaldata.marginally": "",
  "screen.hr.personaldata.navback": "",
  "screen.hr.personaldata.navheader": "",
  "screen.hr.personaldata.nextpage": "",
  "screen.hr.personaldata.notSpecified": "",
  "screen.hr.personaldata.personaldata": "",
  "screen.hr.personaldata.personalinformation": "",
  "screen.hr.personaldata.previouslyEmployed": "",
  "screen.hr.personaldata.socialsecuritynumber.validation": "",
  "screen.hr.personaldata.ssn": "",
  "screen.hr.personaldata.state.validation": "",
  "screen.hr.personaldata.streetnr.validation": "",
  "screen.hr.personaldata.workplaceState": "",
  "screen.hr.personaldata.zipCode": "",
  "screen.hr.personaldata.zipcode.length.validation": "",
  "screen.hr.personaldata.zipcode.validation": "",
  "screen.hr.records.move.multiple.dialog.title": "",
  "screen.hr.records.move.single.dialog.title": "",
  "screen.hr.records.navbar.tab.list": "",
  "screen.hr.records.navbar.tab.tickets": "",
  "screen.hr.records.transfer.dialog.text": "",
  "screen.hr.records.transfer.dialog.textRecordType": "",
  "screen.hr.records.transfer.dialog.title": "",
  "screen.hr.records.types.header": "",
  "screen.hr.records.upload.button": "",
  "screen.hr.records.upload.navbar.back": "",
  "screen.hr.recordTypes.title": "",
  "screen.hr.registration.flow.navback": "",
  "screen.hr.registration.flow.navheader": "",
  "screen.hr.summary.additionalinformation": "",
  "screen.hr.summary.bonuses": "",
  "screen.hr.summary.changeData": "",
  "screen.hr.summary.completeAdditionalData": "",
  "screen.hr.summary.completePersonalData": "",
  "screen.hr.summary.day": "",
  "screen.hr.summary.daysPerWeek": "",
  "screen.hr.summary.description": "",
  "screen.hr.summary.dialogQuestion1": "",
  "screen.hr.summary.dialogQuestion2": "",
  "screen.hr.summary.documents": "",
  "screen.hr.summary.gender": "",
  "screen.hr.summary.grossSalary": "",
  "screen.hr.summary.hours": "",
  "screen.hr.summary.incompleteAdditionalData": "",
  "screen.hr.summary.month": "",
  "screen.hr.summary.name": "",
  "screen.hr.summary.netSalary": "",
  "screen.hr.summary.noPreregistration": "",
  "screen.hr.summary.openDialog": "",
  "screen.hr.summary.summary": "",
  "screen.hr.summary.transferDataChange": "",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": "",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "",
  "screen.hr.summary.week": "",
  "screen.login.button.forgot_password": "",
  "screen.login.button.resendCode": "",
  "screen.login.form.divider": "",
  "screen.login.form.multiFactorAuth.label": "",
  "screen.login.form.multiFactorAuth.validation_error": "",
  "screen.login.form.password.label": "",
  "screen.login.form.password.validation_error": "",
  "screen.login.form.submit": "",
  "screen.login.form.title": "",
  "screen.login.form.username.label": "",
  "screen.login.form.username.validation_error": "",
  "screen.login.multiFactorAuth": "",
  "screen.login.welcome": "",
  "screen.module.overview.report.empty.message": "",
  "screen.module.overview.report.empty.title": "",
  "screen.module.overview.report.upload": "",
  "screen.moduleReportUpload.comment": "",
  "screen.moduleReportUpload.title": "",
  "screen.not_found.button.text": "",
  "screen.not_found.header": "",
  "screen.not_found.message": "",
  "screen.overview.salutation.female": "",
  "screen.overview.salutation.general": "",
  "screen.overview.salutation.male": "",
  "screen.overview.salutation.notSpecified": "",
  "screen.records.upload.header": "",
  "screen.records.upload.message": "",
  "screen.records.upload.title": "",
  "screen.server_error.button.text": "",
  "screen.server_error.header": "",
  "screen.server_error.message": "",
  "screen.termsNotAccepted.button": "",
  "screen.termsNotAccepted.buttonAcceptNow": "",
  "screen.termsNotAccepted.header": "",
  "screen.termsNotAccepted.message": "",
  "screen.termsOfUse.accept": "",
  "screen.termsOfUse.alreadySignedInfo": "",
  "screen.termsOfUse.backToLogin": "",
  "screen.termsOfUse.checkbox": "",
  "screen.termsOfUse.success.sign": "",
  "screen.termsOfUse.title": "",
  "screen.termsOfUseWithCompanyNames.title": "",
  "screen.termsOfUseWithCompanyName.title": "",
  "screen.tickets.navbar.tabbar.closed": "",
  "screen.tickets.navbar.tabbar.open": "",
  "screen.tickets.navbar.tabbar.reported": "",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "",
  "screen.upgradeToMicrosoft.feature.projects.text": "",
  "screen.upgradeToMicrosoft.form.code.description": "",
  "screen.upgradeToMicrosoft.form.code.label": "",
  "screen.upgradeToMicrosoft.form.password.label": "",
  "screen.upgradeToMicrosoft.form.password.validation_error": "",
  "screen.upgradeToMicrosoft.needCompany": "",
  "screen.upgradeToMicrosoft.resendButton.label": "",
  "screen.upgradeToMicrosoft.title": "",
  "search.caption.numCompanies": "",
  "search.caption.numDespositedPersons": "",
  "search.caption.numDocuments": "",
  "search.caption.numPersons": "",
  "search.caption.numProjects": "",
  "search.caption.numResults": "",
  "search.caption.numSelected": "",
  "search.caption.numTickets": "",
  "search.caption.numUploads": "",
  "search.caption.numUsers": "",
  "search.placeholder.companies": "",
  "search.placeholder.projects": "",
  "search.placeholder.search": "",
  "search.placeholder.searchForActiveUsers": "",
  "search.placeholder.searchForEmployees": "",
  "search.placeholder.searchForEmployeesPersonalNumber": "",
  "search.placeholder.searchForFileName": "",
  "search.placeholder.searchForFiles": "",
  "search.placeholder.searchForUsers": "",
  "search.placeholder.searchForUsersFileName": "",
  "selectQesPosition.message": "",
  "selectQesPosition.message.collective": "",
  "selectQesPosition.title": "",
  "settings.navbar.company": "",
  "settings.navbar.companyData": "",
  "settings.navbar.features": "",
  "settings.navbar.general": "",
  "settings.navbar.optionalSideBarItems": "",
  "settings.navbar.userManagement": "",
  "settings.notifications.activateAll": "",
  "settings.notifications.assignments": "",
  "settings.notifications.deactivateAll": "",
  "settings.notifications.deadlineExceeded": "",
  "settings.notifications.deadlineReminder": "",
  "settings.notifications.generalDocumentUpload": "",
  "settings.notifications.label": "",
  "settings.notifications.messages": "",
  "settings.notifications.modules.accounting": "",
  "settings.notifications.modules.hr": "",
  "settings.notifications.modules.other": "",
  "settings.notifications.project.items.uploaded": "",
  "settings.notifications.recordProvision": "",
  "settings.notifications.release": "",
  "settings.notifications.remindAfterHours.label": "",
  "settings.notifications.reportProvision": "",
  "settings.notifications.tickets": "",
  "settings.notifications.title": "",
  "settings.personalData.email": "",
  "settings.personalData.language": "",
  "settings.personalData.location": "",
  "settings.personalData.microsoftAccount": "",
  "settings.personalData.microsoftUpgrade.info": "",
  "settings.personalData.microsoftUpgrade.label": "",
  "settings.personalData.phoneNumber": "",
  "settings.personalData.role": "",
  "settings.personalData.title": "",
  "settings.personalData.username": "",
  "settings.userManagement.permissions.existingUser.navbar.back": "",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "",
  "settings.userManagement.permissions.navbar.back": "",
  "settings.userManagement.permissions.navbar.backToUser": "",
  "settings.userManagement.roles.navbar.back": "",
  "sidebar.advisor.employees": "",
  "sidebar.advisor.inbox": "",
  "sidebar.advisor.tickets": "",
  "sidebar.advisor.uploads": "",
  "sidebar.customerView": "",
  "sidebar.faceToFace.button": "",
  "sidebar.faceToFace.title": "",
  "sidebar.list.accounting": "",
  "sidebar.list.analysis": "",
  "sidebar.list.cockpit": "",
  "sidebar.list.documents": "",
  "sidebar.list.faceToFace": "",
  "sidebar.list.hr": "",
  "sidebar.list.kpi": "",
  "sidebar.list.personnelFile": "",
  "sidebar.list.projects": "",
  "sidebar.list.results": "",
  "sidebar.list.settings": "",
  "sidebar.list.support": "",
  "sidebar.list.tickets": "",
  "signDialog.noQesEnabled.message": "",
  "signDialog.noQesRequired.button.click": "",
  "signDialog.noQesRequired.button.qes": "",
  "signDialog.noQesRequired.message": "",
  "signDialog.qesblocked.message": "",
  "signDialog.qesblocked.title": "",
  "signDialog.qesRequired.confirm": "",
  "signDialog.qesRequired.message": "",
  "signDialog.title": "",
  "soleEarnerStatus.Alleinerzieher": "",
  "soleEarnerStatus.Alleinverdiener": "",
  "soleEarnerStatus.Nein": "",
  "staff.documents.navbar.heading": "",
  "staff.personnelFile.navbar.heading": "",
  "stepper.optional": "",
  "stepper.progress": "",
  "support.admins.title": "",
  "support.filter.option.activeChats": "",
  "support.filter.option.allChats": "",
  "support.filter.option.inactiveChats": "",
  "support.filter.setting.showInactiveColleagues": "",
  "support.inactiveEmployee": "",
  "support.partners.title": "",
  "support.privateChat.dialog.markAsPrivate.confirm": "",
  "support.privateChat.dialog.markAsPrivate.message": "",
  "support.privateChat.dialog.markAsPrivate.title": "",
  "support.privateChat.dialog.markAsPublic.confirm": "",
  "support.privateChat.dialog.markAsPublic.message": "",
  "support.privateChat.dialog.markAsPublic.title": "",
  "support.privateChat.info": "",
  "support.privateChat.info.markAsPublic": "",
  "support.privateChat.info.mobile": "",
  "support.privateChat.placeholder": "",
  "support.responsible.title": "",
  "support.search.navbar.title": "",
  "support.substitute.author": "",
  "support.substitute.back": "",
  "support.substitute.back.mobile": "",
  "support.substitute.warning": "",
  "support.substitute.warning.mobile": "",
  "support.yourContacts": "",
  "table.button.release": "",
  "table.employees.dateOfJoining": "",
  "table.employees.dateOfJoiningExiting": "",
  "table.employees.dateOfLeaving": "",
  "table.employees.dateOfTransmitting": "",
  "table.employees.documents.date": "",
  "table.employees.documents.document": "",
  "table.employees.documents.documentName": "",
  "table.employees.documents.downloadDocuments": "",
  "table.employees.documents.uploadedBy": "",
  "table.employees.employee": "",
  "table.employees.jobDescription": "",
  "table.employees.missingDetails": "",
  "table.employees.personalId": "",
  "table.employees.reasonForLeaving": "",
  "table.employees.status": "",
  "table.employees.status.active": "",
  "table.employees.status.inactive": "",
  "table.employees.status.inDataChange": "",
  "table.employees.status.inDataChangeNotTransferred": "",
  "table.employees.status.inDeregistration": "",
  "table.employees.status.inDeregistrationNotTransferred": "",
  "table.employees.status.inRegistration": "",
  "table.employees.status.inRegistrationNotTransferred": "",
  "table.label.changes": "",
  "table.label.changeUsername": "",
  "table.label.comment": "",
  "table.label.companyName": "",
  "table.label.contactPerson": "",
  "table.label.contactPersonId": "",
  "table.label.costCenter": "",
  "table.label.createdAt": "",
  "table.label.createdAt.variant": "",
  "table.label.createdBy": "",
  "table.label.date": "",
  "table.label.dateOfJoining": "",
  "table.label.dateOfJoiningExiting": "",
  "table.label.dateOfLeaving": "",
  "table.label.dateOfTransmitting": "",
  "table.label.deleteUser": "",
  "table.label.document": "",
  "table.label.documentName": "",
  "table.label.documentType": "",
  "table.label.downloadDocuments": "",
  "table.label.dueDate": "",
  "table.label.dueDateForDraft": "",
  "table.label.email": "",
  "table.label.employee": "",
  "table.label.file": "",
  "table.label.fileName": "",
  "table.label.folder": "",
  "table.label.holiday": "",
  "table.label.info": "",
  "table.label.jobDescription": "",
  "table.label.lastAuthenticatedAt": "",
  "table.label.lastAuthenticatedAt.registered.tooltip": "",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "",
  "table.label.lastCreated": "",
  "table.label.members": "",
  "table.label.message": "",
  "table.label.messages": "",
  "table.label.missingDetails": "",
  "table.label.modulePermissions": "",
  "table.label.modules": "",
  "table.label.needsRelease": "",
  "table.label.openReleases": "",
  "table.label.otherAbsence": "",
  "table.label.personalId": "",
  "table.label.personalNumber": "",
  "table.label.phoneNumber": "",
  "table.label.reasonForLeaving": "",
  "table.label.recordedBy": "",
  "table.label.release": "",
  "table.label.released": "",
  "table.label.releasedAt": "",
  "table.label.releaseRequestedBy": "",
  "table.label.role": "",
  "table.label.sentBy": "",
  "table.label.sickLeave": "",
  "table.label.status": "",
  "table.label.teamMessages": "",
  "table.label.ticket": "",
  "table.label.ticketBy": "",
  "table.label.ticketDescription": "",
  "table.label.ticketDueDate": "",
  "table.label.tickets": "",
  "table.label.transferStatus": "",
  "table.label.transmittedAt": "",
  "table.label.type": "",
  "table.label.updatedAt": "",
  "table.label.updatedAt.variant": "",
  "table.label.uploadedBy": "",
  "table.label.username": "",
  "table.noResults.message": "",
  "table.noResults.title": "",
  "taxAccount.pay.button": "",
  "taxAccount.transactions.table.filters.date": "",
  "taxAccount.transactions.table.filters.tax": "",
  "taxAccount.transactions.table.label.amount": "",
  "taxAccount.transactions.table.label.date": "",
  "taxAccount.transactions.table.label.due": "",
  "taxAccount.transactions.table.label.taxPeriod": "",
  "taxAccount.transactions.table.label.text": "",
  "taxAccount.transactions.table.search.count": "",
  "taxAccount.transactions.table.search.placeholder": "",
  "taxAccount.transactions.title": "",
  "termsOfUse.checkbox.error": "",
  "termsOfUse.upcoming.navbar.button": "",
  "termsOfUse.upcoming.navbar.message": "",
  "ticketList.emptystate.title": "",
  "unpaidAccounts.invoices.pay": "",
  "unpaidAccounts.invoices.table.filters.date": "",
  "unpaidAccounts.invoices.table.label.documentDate": "",
  "unpaidAccounts.invoices.table.label.documentNumber": "",
  "unpaidAccounts.invoices.table.label.documentText": "",
  "unpaidAccounts.invoices.table.label.netAmount": "",
  "unpaidAccounts.invoices.table.label.opAmount": "",
  "unpaidAccounts.invoices.table.label.receiptDate": "",
  "unpaidAccounts.invoices.table.label.receiptNumber": "",
  "unpaidAccounts.invoices.table.label.receiptText": "",
  "unpaidAccounts.invoices.table.search.count": "",
  "unpaidAccounts.invoices.table.search.placeholder": "",
  "unpaidAccounts.invoices.title": "",
  "upload.category.analysis": "",
  "upload.category.annualReports": "",
  "upload.category.articles": "",
  "upload.category.billing": "",
  "upload.category.certificateOfCitizenship": "",
  "upload.category.certificateOfRegistration": "",
  "upload.category.contractOfEmployment": "",
  "upload.category.contracts": "",
  "upload.category.correspondence": "",
  "upload.category.dataChangeConfirmation": "",
  "upload.category.deregisterDocument": "",
  "upload.category.deregistrationConfirmation": "",
  "upload.category.employmentPermit": "",
  "upload.category.identityCard": "",
  "upload.category.information": "",
  "upload.category.notices": "",
  "upload.category.other": "",
  "upload.category.payslip": "",
  "upload.category.registrationConfirmation": "",
  "upload.category.report": "",
  "upload.category.taxOffice": "",
  "upload.category.taxReturn": "",
  "userManagement.successDialog.changeUsername.message": "",
  "userManagement.successDialog.deleteUser.single.message": "",
  "userManagement.successDialog.deleteUser.title": "",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "",
  "userManagement.successDialog.existingUser.superAdmin.title": "",
  "userManagement.successDialog.existingUser.title": "",
  "userManagement.successDialog.message": "",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "",
  "userManagement.successDialog.newUser.superAdmin.single.message": "",
  "userManagement.successDialog.newUser.superAdmin.title": "",
  "userManagement.successDialog.newUser.title": "",
  "userManagement.successDialog.rejectUser.title": "",
  "userManagement.successDialog.releaseExistingUser.title": "",
  "userManagement.successDialog.releaseNewUser.title": "",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "",
  "userProfile.createBmdTodos": "",
  "userProfile.deleteAccount.message": "",
  "userProfile.deleteAccount.title": "",
  "userProfile.multiFactorAuth": "",
  "userProfile.navbar.heading": "",
  "userProfile.upgrade.navbar.heading": "",
  "userProfile.userInterests.title": "",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "",
  "userReleaseInfoAdminDialog.releaseInfo.single": "",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "",
  "userReleaseInfoAdminDialog.releaseMessage.single": "",
  "userReleaseInfoAdminDialog.submit": "",
  "userReleaseInfoAdminDialog.title": "",
  "userReleaseInfoDialog.cancel": "",
  "userReleaseInfoDialog.from": "",
  "userReleaseInfoDialog.message": "",
  "userReleaseInfoDialog.rejected": "",
  "userReleaseInfoDialog.released": "",
  "userReleaseInfoDialog.title": "",
  "viewer.unsupported.message": "",
  "webhook.employee.noSearch": "",
  "webhook.employee.placeholder": "",
  "webhook.noCompanies": "",
  "webhook.tickets.button": "",
  "webhook.tickets.info": "",
  "webhook.tickets.navbar": "",
  "webhook.tickets.taskTitle": "",
  "webhook.tickets.title": "",
  "webhook.uploads.navbar": "",
  "welcomeCard.accounting.addMoreRecords.button": "",
  "welcomeCard.accounting.addRecords.button": "",
  "welcomeCard.hr.addMoreRecords.button": "",
  "welcomeCard.hr.addRecords.button": "",
  "texterify_timestamp": "2024-11-18T09:59:51Z"
} as const;

export { croatian };
