import * as React from "react";
import { MobileContext } from "../util/MobileContext";
import { CONTENT_PADDING_HORIZONTAL, CONTENT_PADDING_HORIZONTAL_MOBILE } from "../util/Theme";

export const SiteContent = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => {
    const isMobile = React.useContext(MobileContext);

    return (
        <div
            style={{
                paddingTop: 24,
                paddingBottom: 16,
                paddingLeft: isMobile ? CONTENT_PADDING_HORIZONTAL_MOBILE : CONTENT_PADDING_HORIZONTAL,
                paddingRight: isMobile ? CONTENT_PADDING_HORIZONTAL_MOBILE : CONTENT_PADDING_HORIZONTAL,
                ...style,
            }}
        >
            {children}
        </div>
    );
};
