import * as React from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { formatDate, getMaxDate } from "../../util/date";
import { ReportLike, hasUserReleasedReport, isReportReleased } from "../../util/reports";
import { getFullName } from "../../util/user";
import { MobileContext } from "../util/MobileContext";
import { InfoButton, TableRowButton } from "./Primitives";

export const ReportReleaseDetails = ({
    report,
    onRelease,
}: {
    report: ReportLike;
    onRelease: undefined | (() => void);
}) => {
    const isMobile = React.useContext(MobileContext);

    if (!report.needsRelease) {
        return null;
    }

    const isReleasedByUser = hasUserReleasedReport(report.releasers, authStore.userId ?? "");
    const releaseInformation = getReportReleasedInformation(report, isMobile);

    if (releaseInformation) {
        return releaseInformation;
    }

    if (!isReleasedByUser && onRelease) {
        return (
            <TableRowButton color="primary" onClick={onRelease}>
                {t("table.button.release")}
            </TableRowButton>
        );
    }

    if (!report.releasers) {
        return null;
    }

    const openReleasers = report.releasers.filter(s => !s.releasedAt).length;
    if (openReleasers === 1) {
        return <p className="body2">{t("common.releaseRequired.singular")}</p>;
    }
    return <p className="body2">{t("common.releaseRequired.plural", { count: openReleasers })}</p>;
};

const ReleaseInformation = styled.p.attrs({ className: "body2" })`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export function getReportReleasedInformation(report: ReportLike, isMobile: boolean) {
    if (!report.needsRelease) {
        return null;
    }

    const isReleased = isReportReleased(report);
    if (!isReleased) {
        return null;
    }

    const releasedReleasers = (report.releasers ?? []).filter(s => s.releasedAt);
    if (releasedReleasers.length === 1) {
        // display date and user immediately if only 1 releaser needs to release
        const releaser = releasedReleasers[0];
        return (
            <ReleaseInformation style={{ marginTop: isMobile ? 8 : undefined }}>
                {t("common.releasedAtBy", { date: formatDate(releaser.releasedAt), name: getFullName(releaser.user) })}
            </ReleaseInformation>
        );
    }

    const releasersText = releasedReleasers.reduce<React.ReactNode[]>((texts, s) => {
        if (!s.releasedAt || !s.user) {
            return texts;
        }

        if (texts.length > 0) {
            texts.push(<br key={texts.length} />);
        }
        texts.push(
            t("common.releasedAtBy", {
                date: formatDate(s.releasedAt),
                name: getFullName(s.user),
            }),
        );

        return texts;
    }, []);

    const releasedAt = getMaxDate(releasedReleasers.map(s => s.releasedAt));

    return (
        <ReleaseInformation style={{ marginTop: isMobile ? 8 : undefined }}>
            {t("common.releasedAt", { date: formatDate(releasedAt) })}
            <InfoButton title={releasersText} color="primary" style={{ padding: 0, marginLeft: 4 }} />
        </ReleaseInformation>
    );
}
