import { Button } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { User } from "../../network/APITypes";
import { CustomDialog } from "./CustomDialog";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";
import { ChooseUserDialog } from "./ChooseUserDialog";

export const AssignTicketDialog = ({
    open,
    onClose,
}: {
    // TODO: ticket
    open: boolean;
    onClose: () => void;
}) => {
    const handleSubmit = (user: User) => {
        // TODO: API call assigning ticket goes here
        onClose();
    };

    return (
        <ChooseUserDialog
            title={t("dialog.assignTicket.header")}
            message={t("dialog.assignTicket.message")}
            buttonLabel={t("button.assign")}
            open={open}
            onClose={onClose}
            onSubmit={handleSubmit}
        />
    );
};
