// THIS IS A PLACEHOLDER COMPONENT WITH NO FUNCTIONALITY
// Do not add any hooks, effects or logic here. This component is used as a placeholder for loading state.
// -----------------------------------------------------------------
// Explanation as of why we use this component:
// If companyId is changed in companyStore, the current component will refresh and try to reload data with the newly selected company.
// If this component is not available for the newly selected company, it will cause an error, as it is trying to load data for a component that is disabled. (The new company has no access on this component.)
// e.g. first we load BankAccounts for a company in /account-sheet. If we now switch the company to one that has no /account-sheet, it will still try to load BankAccounts for the new company, causing an error.
// This is why we first push to loading route, which opens empty LoadingSite component. Then we set the new company, causing the current component(LoadingSite) to refresh. This will not load any data, as LoadingSite is empty with no hooks or effects.
// After that we push to Cockpit Route, which will load data for Cockpit component with newly selected company.
// -----------------------------------------------------------------

export const LoadingSite = () => {
    return <></>;
};
