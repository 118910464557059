import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { sideBarStore } from "../../../stores/SideBarStore";
import { hrStore } from "../../hr/HrStore";
import { SettingsRoutes } from "../../settings/router/SettingsRoutes";
import { HelpCenterElements } from "../../shared/HelpCenter";
import { NotificationCenterElements } from "../../shared/NotificationCenter";
import { StaffRoutes } from "../../staff/router/StaffRoutes";
import { AppNotificationsDialog } from "../../ui/AppNotificationsDialog";
import { OpenEmployeeDocumentReleasesDialog } from "../../ui/OpenEmployeeDocumentReleasesDialog";
import { SupportDialog } from "../../ui/SupportDialog";
import { UserReleaseInfoAdminDialog } from "../../ui/UserReleaseInfoAdminDialog";
import { UserReleaseInfoDialog } from "../../ui/UserReleaseInfoDialog";
import { MobileMenu } from "../../ui/mobileMenu/MobileMenu";
import { SideBar } from "../../ui/sidebar/SideBar";
import { MobileContext } from "../../util/MobileContext";
import { FullscreenViewer } from "../../viewer/FullscreenViewer";
import { AppRouter } from "../router/AppRouter";
import { pushRoute } from "../router/history";

export const AppContainerSite = observer(function AppContainerSite() {
    const isReadyToStart = authStore.isAuthenticated && coordinator.isRehydrated;

    const isMobile = React.useContext(MobileContext);

    React.useEffect(() => {
        const init = async () => {
            if (isReadyToStart) {
                try {
                    await coordinator.initApp();
                } catch (error) {
                    generalStore.setError(t("error.general"), error);
                }
            }
        };

        init();
    }, [isReadyToStart]);

    const handleMarkReleaseInfoAsRead = async (ids: string[]) => {
        try {
            if (companiesStore.selectedCompanyId) {
                await API.putInvitationResultsRead(companiesStore.selectedCompanyId, { markAsRead: ids });
                generalStore.showUserReleaseInfoDialog = false;
            }
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        }
    };

    return (
        <>
            {!isMobile && !sideBarStore.sideBarHidden && authStore.isAuthenticated && <SideBar />}
            {isMobile && !sideBarStore.sideBarHidden && authStore.isAuthenticated && <MobileMenu />}
            <AppRouter />
            <SupportDialog />
            <FullscreenViewer />
            {authStore.isAuthenticated && authStore.isSuperAdmin && !!companiesStore.selectedCompanyId && (
                <UserReleaseInfoAdminDialog
                    open={generalStore.showUserReleaseInfoAdminDialog}
                    onSubmit={() => {
                        generalStore.showUserReleaseInfoAdminDialog = false;
                        pushRoute(SettingsRoutes.USER_MANAGEMENT.ROOT);
                    }}
                    onCancel={() => {
                        generalStore.showUserReleaseInfoAdminDialog = false;
                    }}
                    waitingUsers={companiesStore.getWaitingUsers(companiesStore.selectedCompanyId)}
                />
            )}
            {authStore.isAuthenticated && !authStore.isSuperAdmin && (
                <UserReleaseInfoDialog
                    open={generalStore.showUserReleaseInfoDialog}
                    onSubmit={handleMarkReleaseInfoAsRead}
                    onCancel={ids => {
                        handleMarkReleaseInfoAsRead(ids);
                        pushRoute(SettingsRoutes.USER_MANAGEMENT.ROOT);
                    }}
                    invitationResults={companiesStore.invitationResults}
                />
            )}
            {authStore.isAuthenticated &&
                authStore.isStaff &&
                !!companiesStore.selectedCompanyStore &&
                !!companiesStore.selectedCompanyStore.badgeCounts.openEmployeeDocumentReleases && (
                    <OpenEmployeeDocumentReleasesDialog
                        open={generalStore.showOpenEmployeeDocumentReleasesDialog}
                        count={companiesStore.selectedCompanyStore.badgeCounts.openEmployeeDocumentReleases}
                        companyId={companiesStore.selectedCompanyStore.id}
                        onSubmit={employee => {
                            generalStore.showOpenEmployeeDocumentReleasesDialog = false;

                            hrStore.selectedSubsidiaryId = employee.subsidiaryId;
                            pushRoute(StaffRoutes.DOCUMENTS, { params: { employeeId: employee.id } });
                        }}
                        onCancel={() => {
                            generalStore.showOpenEmployeeDocumentReleasesDialog = false;
                        }}
                    />
                )}
            {authStore.isAuthenticated && <AppNotificationsDialog />}
            <GlobalQuickActions />
        </>
    );
});

const GlobalQuickActions = observer(function GlobalQuickActions() {
    return (
        <div
            className="mui-fixed"
            style={{
                position: "fixed",
                right: 10,
                bottom: 16,
                display: "flex",
                flexDirection: "column-reverse",
                gap: 16,
                zIndex: 1,
                marginBottom: generalStore.globalQuickActionsBottomOffset,
            }}
        >
            {authStore.isAuthenticated && <NotificationCenterElements />}
            <HelpCenterElements />
        </div>
    );
});
