import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Routes } from "../../app/router/Routes";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { SupportSite } from "../sites/SupportSite";
import { SupportRoutes } from "./SupportRoutes";
import { SupportSearchSite } from "../sites/SupportSearchSite";

export const SupportRouter = () => (
    <Switch>
        <Route exact path={Routes.SUPPORT} component={SupportSite} />
        <Route path={SupportRoutes.SEARCH} component={SupportSearchSite} />
        <Route component={NotFoundSite} />
    </Switch>
);
