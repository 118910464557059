import { Button, TableBody, TableRow } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { IConfigUser } from "../../stores/ConfigStore";
import { generalStore } from "../../stores/GeneralStore";
import { getPermissionChanges } from "../../util/permissionHelpers";
import { IParsedPermissions } from "../../util/permissionParser";
import { getFullName } from "../../util/user";
import { pushRoute } from "../app/router/history";
import { useSuccessDialog } from "../hooks/useSuccessDialog";
import { SettingsRoutes } from "../settings/router/SettingsRoutes";
import { InfoButton, TableLabelSmall, TpaTable, TpaTableCell } from "../ui/Primitives";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";
import { BOX_SHADOW_LIGHT, customColors } from "../util/Theme";

interface IProps {
    configUser: IConfigUser;
    companyId: string;
    permissions: IParsedPermissions;
    style?: React.CSSProperties;
}

export const PermissionChangeInformation = observer(function PermissionChangeInformation({
    configUser,
    companyId,
    permissions,
    style,
}: IProps) {
    const successDialog = useSuccessDialog({
        onClose: () => {
            pushRoute(SettingsRoutes.USER_MANAGEMENT.ROOT);
        },
    });

    const handleClickRelease = async () => {
        try {
            await API.releaseUserPermissions(companyId, configUser.id);

            if (!configUser.confirmedAt) {
                successDialog.setTitle(t("userManagement.successDialog.releaseNewUser.title"));
                successDialog.setMessage(
                    <FormattedHTMLMessage
                        id="userManagement.successDialog.newUser.superAdmin.single.message"
                        values={{ userName: getFullName(configUser) }}
                    />,
                );
            } else {
                successDialog.setTitle(t("userManagement.successDialog.releaseExistingUser.title"));
            }

            successDialog.openDialog();
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        }
    };

    const handleClickReject = async () => {
        try {
            await API.rejectUserPermissions(companyId, configUser.id);

            successDialog.setTitle(t("userManagement.successDialog.rejectUser.title"));
            successDialog.openDialog();
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        }
    };

    const infoButton = (
        <div style={{ marginLeft: 16 }}>
            <InfoButton
                title={t("config.roles.releaseInformation.info.title")}
                body={t("config.roles.releaseInformation.info.message")}
                color="primary"
                style={{ padding: 0 }}
            />
        </div>
    );

    const currentUserNoRole = !configUser.roles || configUser.roles.length === 0 || configUser.roles.includes("none");
    const changedUserNoRole = configUser.changes?.roles.length === 0 || configUser.changes?.roles.includes("none");

    return (
        <>
            <div
                style={{
                    backgroundColor: customColors.white,
                    borderRadius: 4,
                    boxShadow: BOX_SHADOW_LIGHT,
                    borderLeft: `6px solid ${customColors.orangeOpen}`,
                    padding: 24,
                    ...style,
                }}
            >
                <div>
                    {!permissions.isSuperAdmin && (
                        <div style={{ display: "flex" }}>
                            <FormattedHTMLMessage
                                id="config.roles.releaseInformation"
                                values={{
                                    userName: getFullName(configUser),
                                }}
                            />
                            {infoButton}
                        </div>
                    )}
                    {permissions.isSuperAdmin && (
                        <div>
                            <div style={{ display: "flex" }}>
                                <FormattedHTMLMessage
                                    id="config.roles.releaseInformation.superAdmin"
                                    values={{
                                        name: getFullName(configUser.changedBy),
                                        userName: getFullName(configUser),
                                    }}
                                />
                                {infoButton}
                            </div>
                            <TpaTable style={{ marginTop: 24 }}>
                                <TableBody>
                                    {getPermissionChanges({
                                        changes: configUser.changes,
                                        roles: configUser.roles ?? [],
                                    }).map((permissionChange, index) => (
                                        <TableRow key={index}>
                                            <TpaTableCell style={{ padding: 4, verticalAlign: "top" }}>
                                                <TableLabelSmall>{permissionChange.name}</TableLabelSmall>
                                            </TpaTableCell>
                                            <TpaTableCell style={{ padding: 4 }}>
                                                <TableLabelSmall style={{ maxWidth: "none", whiteSpace: "normal" }}>
                                                    {permissionChange.change}
                                                </TableLabelSmall>
                                            </TpaTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </TpaTable>
                            <ResponsiveButtonContainer style={{ marginTop: 40 }}>
                                <Button
                                    data-id="button_reject"
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleClickReject}
                                >
                                    {t("config.users.reject")}
                                </Button>
                                <Button
                                    data-id="button_release"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClickRelease}
                                    // TPAPORTAL-2378: We disable if new user has no role, so no role before and no role afterwards
                                    disabled={currentUserNoRole && changedUserNoRole}
                                >
                                    {configUser.confirmedAt
                                        ? t("config.users.release")
                                        : t("config.users.releaseAndInvite")}
                                </Button>
                            </ResponsiveButtonContainer>
                        </div>
                    )}
                </div>
            </div>
            {successDialog.dialog}
        </>
    );
});
