import { Box, LinearProgress, LinearProgressProps, withStyles } from "@material-ui/core";
import * as React from "react";
import { customColors } from "../util/Theme";

const StyledLinearProgress = withStyles(theme => ({
    colorSecondary: {
        backgroundColor: customColors.greyLight,
    },
}))(LinearProgress);

const LinearProgressWithLabel = (
    props: LinearProgressProps & { value: number; numfile: number; totalnumfiles: number },
) => {
    return (
        <>
            <Box display="flex" width="100%" justifyContent="space-between" marginBottom="8px">
                <p className="caption" style={{ color: customColors.body2Dark }}>{`${Math.round(props.value)}%`}</p>

                <p className="caption" style={{ color: customColors.body2Dark }}>
                    {props.numfile}/{props.totalnumfiles}
                </p>
            </Box>
            <Box width="100%">
                <StyledLinearProgress style={{ borderRadius: 4 }} color="secondary" variant="determinate" {...props} />
            </Box>
        </>
    );
};

export const Progress = (props: { current: number; total: number; style?: React.CSSProperties }) => {
    return (
        <div style={{ width: "100%", ...props.style }}>
            <LinearProgressWithLabel
                value={props.total === 0 ? 0 : (props.current * 100) / props.total}
                numfile={props.current}
                totalnumfiles={props.total}
            />
        </div>
    );
};
