import { Button } from "@material-ui/core";
import { LocationDescriptor } from "history";
import * as React from "react";
import { NAVBAR_HEIGHT, NAVBAR_INFO_HEIGHT, TABBAR_HEIGHT } from "../../config";
import { t } from "../../i18n/util";
import { stylesStore } from "../../stores/StylesStore";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";
import { pushLocation } from "../app/router/history";
import { Icon } from "../util/Icon";
import { ImageLogo } from "../util/Images";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { NavBarChoosePeriod } from "./NavBarChoosePeriod";
import { NavBarChooseSubsidiary } from "./NavBarChooseSubsidiary";
import {
    FullWidthNavBarContainer,
    MobileNavBarContainer,
    NavBarCenteredLogoOnly,
    NavBarContainer,
    NavBarTitle,
} from "./NavBarContainer";
import { TabBarContainer } from "./TabBar";

const CompanyName = ({ name }: { name: string }) => (
    <>
        <div style={{ marginRight: 16 }}>
            <Icon name="suitcase" style={{ display: "block", color: "white" }} />
        </div>
        <p style={{ fontWeight: "bold", color: "white" }}>{name}</p>
    </>
);

// NavBar back are centered and not full width
export const NavBarBack = (props: {
    title?: React.ReactNode;
    showLogo?: boolean;
    centeredLogoOnly?: boolean;

    // Back button config
    backLabel?: string;
    backTarget?: LocationDescriptor;
    onBack?: () => void;

    // Cancel button config
    cancelTarget?: string;
    onCancel?: () => void;
    cancelComponent?: React.ReactNode;
    showCancel?: boolean;
    cancelLabel?: string;

    // e.g specific components e.g. zoom buttons on sign terms site
    controlComponent?: React.ReactNode;

    // Display module info
    moduleInfo?: Module;
    moduleInfoReadOnly?: boolean;
    showPeriods?: boolean;
    periodsComponent?: React.ReactNode;
    showSubsidiaries?: boolean;
    subsidiariesComponent?: React.ReactNode;

    // Display company info
    companyName?: string;

    // Additional navbar info
    navBarInfo?: React.ReactNode;

    // Add tabs
    tabs?: React.ReactNode;
    ignoreSideBar?: boolean;

    useFullWidth?: boolean;
}) => {
    const isMobile = React.useContext(MobileContext);
    const navBarInfo = props.companyName ? <CompanyName name={props.companyName} /> : props.navBarInfo;
    const hasNavBarInfo = !!navBarInfo;

    React.useEffect(() => {
        const previousHeight = stylesStore.navBarHeight;

        const tabHeight = props.tabs ? TABBAR_HEIGHT : 0;
        const navbarInfoHeight = hasNavBarInfo ? NAVBAR_INFO_HEIGHT : 0;
        stylesStore.navBarHeight = NAVBAR_HEIGHT + tabHeight + navbarInfoHeight;

        return () => {
            // Restore previous height
            stylesStore.navBarHeight = previousHeight;
        };
    }, [hasNavBarInfo, props.tabs]);

    const handleClickCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        } else if (props.cancelTarget) {
            pushLocation(props.cancelTarget);
        } else if (props.backTarget) {
            pushLocation(props.backTarget);
        }
    };

    const moduleStore = props.moduleInfo && getModuleStore(props.moduleInfo);

    const title = <NavBarTitle>{props.title}</NavBarTitle>;
    const logo = (
        <div style={{ flexGrow: 1 }}>
            <ImageLogo style={{ width: isMobile ? 47 : undefined }} />
        </div>
    );

    let titleOrLogo;
    if (props.title) {
        titleOrLogo = title;
    } else if (props.showLogo) {
        titleOrLogo = logo;
    } else {
        titleOrLogo = <div style={{ flexGrow: 1 }} />;
    }

    const showCancel = props.showCancel !== false && (!!props.backTarget || !!props.onCancel || !!props.cancelTarget);

    let disable: boolean;

    if (props.moduleInfoReadOnly !== false) {
        disable = true;
    } else {
        disable = props.moduleInfoReadOnly;
    }

    // TPAPORTAL-2024: Really weird hack necessary for useMediaQuery to work. With normal
    // NavBarContainer useMediaQuery does not trigger in HrEmployeeOverviewFileBar.
    const Component = props.useFullWidth ? FullWidthNavBarContainer : NavBarContainer;

    return isMobile ? (
        <MobileNavBarContainer
            backLabel={props.backLabel}
            backTarget={props.backTarget}
            navBarInfo={navBarInfo}
            tabs={props.tabs}
            onBack={props.onBack}
        >
            {!props.centeredLogoOnly && (
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {titleOrLogo}
                    {moduleStore && (
                        <div style={{ display: "flex" }}>
                            {props.showPeriods !== false &&
                                (props.periodsComponent ?? (
                                    <NavBarChoosePeriod
                                        readOnly={disable}
                                        moduleStore={moduleStore}
                                        style={{ marginRight: 16 }}
                                    />
                                ))}
                            {props.showSubsidiaries !== false &&
                                (props.subsidiariesComponent ?? (
                                    <NavBarChooseSubsidiary
                                        readOnly={disable}
                                        moduleStore={moduleStore}
                                        style={{ marginRight: 16 }}
                                    />
                                ))}
                        </div>
                    )}
                    {showCancel && !props.cancelComponent && (
                        <div>
                            <Icon
                                name="close"
                                style={{ color: customColors.primaryColor, cursor: "pointer", display: "block" }}
                                onClick={handleClickCancel}
                                data-id="cancel"
                            />
                        </div>
                    )}
                    {showCancel && props.cancelComponent}
                    {!showCancel && props.controlComponent}
                </div>
            )}
            {props.centeredLogoOnly && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ImageLogo style={{ width: isMobile ? 47 : undefined }} />
                </div>
            )}
        </MobileNavBarContainer>
    ) : (
        <>
            {props.centeredLogoOnly && (
                <NavBarCenteredLogoOnly>
                    <ImageLogo style={{ width: isMobile ? 47 : undefined }} />
                </NavBarCenteredLogoOnly>
            )}
            {!props.centeredLogoOnly && (
                <Component
                    backLabel={props.backLabel}
                    backTarget={props.backTarget}
                    navBarInfo={navBarInfo}
                    ignoreSideBar={props.ignoreSideBar}
                    onBack={props.onBack}
                    hideBorder={!!props.tabs}
                >
                    {!props.centeredLogoOnly && (
                        <>
                            {titleOrLogo}
                            {moduleStore && (
                                <>
                                    {props.showPeriods !== false &&
                                        (props.periodsComponent ?? (
                                            <NavBarChoosePeriod
                                                readOnly={disable}
                                                moduleStore={moduleStore}
                                                style={{ marginRight: 32 }}
                                            />
                                        ))}
                                    {props.showSubsidiaries !== false &&
                                        (props.subsidiariesComponent ?? (
                                            <NavBarChooseSubsidiary
                                                readOnly={disable}
                                                moduleStore={moduleStore}
                                                style={{ marginRight: 16 }}
                                            />
                                        ))}
                                </>
                            )}
                            {showCancel && !props.cancelComponent && (
                                <Button color="primary" onClick={handleClickCancel} data-id="cancel">
                                    {props.cancelLabel ?? t("common.cancelCaps")}
                                </Button>
                            )}
                            {showCancel && props.cancelComponent}
                            {!showCancel && props.controlComponent}
                        </>
                    )}
                </Component>
            )}
            {props.tabs && <TabBarContainer>{props.tabs}</TabBarContainer>}
        </>
    );
};
