import { Redirect } from "react-router";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { getApiError } from "../../../network/NetworkStapler";
import { authStore } from "../../../stores/AuthStore";
import { generalStore } from "../../../stores/GeneralStore";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useInviteTokenValid } from "../../hooks/useInviteTokenValid";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ContinueWithoutMicrosoftButton } from "../../ui/ContinueWithoutMicrosoftButton";
import { MicrosoftSignInButton } from "../../ui/MicrosoftSignInButton";
import { AuthRoutes } from "../router/AuthRoutes";
import { AuthGeneralContainerSite } from "./AuthGeneralContainerSite";

export const AuthWelcomeSite = () => {
    // For CEE invites need the company ID to assign an initial tenant
    const {
        token,
        companyId,
        "ms-only": msOnly,
    } = useQueryParams<{ token: string; companyId: string; "ms-only": string }>();

    if (!token) {
        return <Redirect to={Routes.ROOT} />;
    }

    return <AuthWelcome token={token} companyId={companyId} msOnly={msOnly} />;
};

const AuthWelcome = ({ token, companyId, msOnly }: { token: string; companyId?: string; msOnly?: string }) => {
    const tokenCheckComplete = useInviteTokenValid(token);

    return (
        <>
            <AuthGeneralContainerSite>
                <h1 style={{ marginBottom: 16 }}>{t("screen.auth.welcome.title")}</h1>
                <p style={{ marginBottom: 32 }}>
                    {t(msOnly ? "screen.auth.welcome.subtitle.msOnly" : "screen.auth.welcome.subtitle")}
                </p>
                {tokenCheckComplete && (
                    <>
                        {!companyId && <p style={{ marginBottom: 32 }}>{t("screen.upgradeToMicrosoft.needCompany")}</p>}
                        <MicrosoftSignInButton
                            style={{ marginBottom: 24 }}
                            onClick={async () => {
                                try {
                                    await authStore.upgradeAndLoginWithMicrosoft(companyId ?? "", token);
                                } catch (error) {
                                    const apiError = getApiError(error);
                                    if (apiError?.response.type === "TOKEN_NOT_FOUND") {
                                        generalStore.setError(t("error.linkInvalid"), error);
                                        return;
                                    } else if (apiError?.response.type === "TOKEN_EXPIRED") {
                                        generalStore.setError(t("error.linkExpired"), error);
                                        return;
                                    } else if (
                                        apiError?.response.type === "AZURE_INVITED_NON_EXTERNAL_USER_ALREADY_EXISTS"
                                    ) {
                                        const data = apiError.response.additionalData;
                                        const schema = Yup.object().shape({
                                            existingUsername: Yup.string().required(),
                                        });
                                        if (schema.isValidSync(data)) {
                                            generalStore.setError(
                                                t("screen.upgradeToMicrosoft.error.azureUserAlreadyExists", {
                                                    existingUsername: data.existingUsername,
                                                }),
                                                error,
                                            );
                                            return;
                                        }
                                        // fall through for default error handling below
                                    }

                                    generalStore.setError(t("error.general"), error);
                                }
                            }}
                            disabled={!companyId}
                        />
                        {!msOnly && (
                            <ContinueWithoutMicrosoftButton
                                onClick={() => {
                                    pushRoute(AuthRoutes.REGISTRATION, { query: { token } });
                                }}
                            />
                        )}
                    </>
                )}
            </AuthGeneralContainerSite>
        </>
    );
};
