function isGlobalEnabled(key: string, defaultValue: boolean): boolean {
    const v = localStorage.getItem("features.global." + key);
    return v === null ? defaultValue : v === "true";
}

export const GLOBAL_FEATURES = {
    cockpit: isGlobalEnabled("cockpit", true),
    accounting: isGlobalEnabled("accounting", true),
    hr: isGlobalEnabled("hr", true),
    kpis: isGlobalEnabled("kpis", true),
    projects: isGlobalEnabled("projects", true),
    tickets: isGlobalEnabled("tickets", true),
    dueDates: isGlobalEnabled("dueDates", true),
    docs: isGlobalEnabled("docs", true),
    settings: isGlobalEnabled("settings", true),
    results: isGlobalEnabled("results", true),
    analysis: isGlobalEnabled("analysis", false),
    support: isGlobalEnabled("support", true),
    chat: isGlobalEnabled("chat", isGlobalEnabled("support", true)),
    webhooks: isGlobalEnabled("webhooks", true),
    publications: isGlobalEnabled("publications", true),
    events: isGlobalEnabled("events", true),
    cockpitFilebar: isGlobalEnabled("cockpitFilebar", false),
    copyRoleFromUser: isGlobalEnabled("copyRoleFromUser", true),
    upload: isGlobalEnabled("upload", false),
    additionalNotificationSettings: isGlobalEnabled("additionalNotificationSettings", false),
    singleSubsidiarySelection: isGlobalEnabled("singleSubsidiarySelection", false),
    staff: isGlobalEnabled("staff", true),
    bmdTodosForTickets: isGlobalEnabled("bmdTodosForTickets", true),
    matomo: isGlobalEnabled("matomo", true),
    projectCopyPaste: isGlobalEnabled("projectCopyPaste", true),
    optionalSidebarItems: isGlobalEnabled("optionalSidebarItems", true),
    qes: isGlobalEnabled("qes", true),
    bankConnections: isGlobalEnabled("bankConnections", true),
    payrollAccounts: isGlobalEnabled("payrollAccounts", true),
    helpCenterProblems: isGlobalEnabled("helpCenterProblems", true),
    deleteUserProfile: isGlobalEnabled("deleteUserProfile", true),
};
