import { autorun, computed, observable, transaction } from "mobx";
import { useCallback, useEffect, useRef } from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { APIResult } from "../components/hooks/useAPI";
import { SearchField } from "../components/ui/SearchField";
import { TablePagination } from "../components/ui/TablePagination";
import { ROWS_PER_PAGE, SEARCH_DEBOUNCE_MS } from "../config";
import { ITableParams } from "../network/API";
import { addStoreToWindow } from "../util/debug";
import { Comparer, sort } from "../util/sort";

export type OrderDirection = "asc" | "desc";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnObject = Record<string, any>;
export type ItemId = string | number;
export type ItemWithId = AnObject & { id: ItemId; uniqueId?: ItemId }; // sometimes `id` is already assigned but not "unique", so `uniqueId` can be used instead for the unique id

export function getId(item: ItemWithId): ItemId {
    return "uniqueId" in item && item.uniqueId != null ? item.uniqueId : item.id;
}

export interface Options<Item extends ItemWithId = ItemWithId> {
    orderBy: keyof Item;
    orderDir: OrderDirection;
    limit?: number;
    search?: string;
}

export class TableStore<Item extends ItemWithId = ItemWithId> {
    name: string | null = null;
    @observable private _totalCount = 0;

    // Offset is intentionally read only -> fixes TPAPORTAL-1077 which leads to an infinite loop
    // Changes should be done via set page()
    @observable _offset = 0;
    @computed get offset() {
        return this._offset;
    }
    _setOffset(offset: number) {
        const previousOffset = this._offset;
        this._offset = offset;
        if (previousOffset !== this._offset) {
            this._updateTableParams();
        }
    }
    resetOffset() {
        this._setOffset(0);
    }

    @observable private _items: Item[] = [];

    // NOTE: we need to keep the item instances in here to allow selection across multiple pages
    @observable selectedItems = new Map<ItemId, Item>();
    @observable canSelect?: (item: Item) => boolean;
    @observable onChangeSelection?: (items: Item[]) => void;

    // // the "collapsing" state is used to keep the existing DOM nodes during the collapse animation
    @observable expandedItems = new Map<ItemId, true | "collapsing" | undefined>();
    @observable canExpand?: (item: Item) => boolean;

    @observable _search = "";
    get search() {
        return this._search;
    }
    set search(search: string) {
        const previousSearch = this._search;
        this._search = search;
        if (previousSearch !== this._search) {
            this._previousSearch = previousSearch;
            this._updateTableParams();
        }
    }

    @observable _orderBy: keyof Item = "id";
    get orderBy() {
        return this._orderBy;
    }
    set orderBy(orderBy: keyof Item) {
        const previousOrderBy = this._orderBy;
        this._orderBy = orderBy;
        if (previousOrderBy !== this._orderBy) {
            this._updateTableParams();
        }
    }

    @observable _orderDir: OrderDirection = "asc";
    get orderDir() {
        return this._orderDir;
    }
    set orderDir(orderDir: OrderDirection) {
        const previousOrderDir = this._orderDir;
        this._orderDir = orderDir;
        if (previousOrderDir !== this._orderDir) {
            this._updateTableParams();
        }
    }

    @observable _rowsPerPage: number = ROWS_PER_PAGE;
    get rowsPerPage() {
        return this._rowsPerPage;
    }
    set rowsPerPage(rowsPerPage: number) {
        const previousRowsPerPage = this._rowsPerPage;
        this._rowsPerPage = rowsPerPage;
        if (previousRowsPerPage !== this._rowsPerPage) {
            this._updateTableParams();
        }
    }

    @observable tableParams: ITableParams<Item> = {
        offset: this.offset,
        limit: this.rowsPerPage,
        orderBy: this.orderBy,
        orderDir: this.orderDir,
    };
    _updateTableParams() {
        const tableParams: ITableParams<Item> = {
            offset: this.offset,
            limit: this.rowsPerPage,
            orderBy: this.orderBy,
            orderDir: this.orderDir,
        };

        if (this.search) {
            tableParams.search = this.search;
        }

        this.tableParams = tableParams;
    }

    // Use this, if for a table you need to track when the initial load has finished
    @observable private _initialized = false;

    // used to prevent flashing empty screen when there was a search and the search is set to "" directly after
    @observable private _previousSearch = "";

    constructor(options: Options<Item>, name: string) {
        this.name = name;
        this.orderBy = options.orderBy;
        this.orderDir = options.orderDir;
        this.search = options.search ?? "";
        this.rowsPerPage = options.limit ?? ROWS_PER_PAGE;
    }

    onChangeSort = (column: keyof Item) => {
        if (column === this.orderBy) {
            this.orderDir = this.orderDir === "asc" ? "desc" : "asc";
        } else {
            this.orderBy = column;
            this.orderDir = "asc";
        }
    };

    @computed get page() {
        return Math.floor(this.offset / this.rowsPerPage) + 1;
    }

    /** Starts at 1 */
    set page(pageNumber: number) {
        const newOffset = (pageNumber - 1) * this.rowsPerPage;
        this._setOffset(newOffset);
    }

    @computed get items() {
        return this._items;
    }

    set items(items: Item[]) {
        this._items = items;

        // update potentially outdated selected items, happens if you change the order or search for items
        items.forEach(item => {
            const id = getId(item);
            if (this.selectedItems.has(id)) {
                this.selectedItems.set(id, item);
            }
        });
    }

    getAllSelectedItems() {
        return Array.from(this.selectedItems.values());
    }

    @computed get selectableItems() {
        return this.canSelect ? this.items.filter(this.canSelect) : this.items;
    }

    setItemSelected(item: Item, selected: boolean) {
        if (selected) {
            this.selectedItems.set(getId(item), item);
        } else {
            this.selectedItems.delete(getId(item));
        }
        this.onChangeSelection?.(this.getAllSelectedItems());
    }

    toggleSelection(item: Item) {
        const id = getId(item);
        const selected = this.selectedItems.has(id);
        this.setItemSelected(item, !selected);
    }

    toggleSelectAll(selectAll: boolean) {
        if (selectAll) {
            this.selectableItems.forEach(item => this.selectedItems.set(getId(item), item));
        } else {
            this.selectableItems.forEach(item => this.selectedItems.delete(getId(item)));
        }

        this.onChangeSelection?.(this.getAllSelectedItems());
    }

    isSelected = (item: Item): boolean => {
        return this.selectedItems.has(getId(item));
    };

    @computed get allSelected() {
        return this.selectableItems.length > 0 && this.selectableItems.every(this.isSelected);
    }
    @computed get someSelected() {
        return !this.allSelected && this.selectableItems.length > 0 && this.selectableItems.some(this.isSelected);
    }

    isExpanded = (item: Item): boolean => {
        return this.expandedItems.get(getId(item)) === true;
    };

    @computed get expandableItems() {
        return this.canExpand ? this.items.filter(this.canExpand) : this.items;
    }

    @computed get allExpanded() {
        return this.expandableItems.length > 0 && this.expandableItems.every(this.isExpanded);
    }
    @computed get someExpanded() {
        return !this.allExpanded && this.expandableItems.length > 0 && this.expandableItems.some(this.isExpanded);
    }

    @computed get totalCount() {
        return this._totalCount;
    }

    set totalCount(total: number) {
        this._totalCount = total;

        // We got our first total count -> table is initialized
        this._initialized = true;
    }

    getIsEmptyState(isLoadingSomething = false, hasFilter = false) {
        return (
            this.totalCount === 0 &&
            !this._previousSearch &&
            !this.search &&
            !hasFilter &&
            !isLoadingSomething &&
            this._initialized
        );
    }

    getIsNoResultState(isLoadingSomething = false, hasFilter = false) {
        return this.totalCount === 0 && (!!this.search || hasFilter) && !isLoadingSomething && this._initialized;
    }

    handleSearchChange = (search: string) => {
        transaction(() => {
            this.resetOffset();
            this.search = search;
        });
    };

    Pagination = () => {
        return (
            <TablePagination
                totalCount={this.totalCount}
                rowsPerPage={this.rowsPerPage}
                page={this.page}
                onChangePage={pageNumber => (this.page = pageNumber)}
                data-id="table_pagination"
            />
        );
    };

    SearchField = (props: { placeholder: string }) => {
        return (
            <SearchField
                value={this.search}
                data-id="table_search"
                onChange={(search: string) => {
                    this.handleSearchChange(search);
                }}
                placeholder={props.placeholder}
                style={{ flexGrow: 1 }}
                debounceMs={SEARCH_DEBOUNCE_MS}
            />
        );
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let tableStore: TableStore<any> | null = null;

export function useTableStore<Item extends ItemWithId = ItemWithId>(
    tableName: string,
    options: Options<Item>,
): TableStore<Item> {
    if (!tableStore || tableStore.name !== tableName) {
        tableStore = new TableStore<Item>(options, tableName);
    }

    // clear the selection/expanded state when leaving the table to start fresh if the user opens the same table again
    const t = tableStore;
    useEffect(() => {
        return () => {
            t.selectedItems.clear();
            t.expandedItems.clear();
        };
    }, [t]);

    addStoreToWindow(tableName, tableStore);

    return tableStore as TableStore<Item>;
}

export function clearTableStore() {
    tableStore = null;
}

/**
 * Manages filtering (filters and searching) and sorting of a {@link TableStore} with all items already loaded (in memory).
 */
export const useInMemoryTableStore = <Item extends ItemWithId>({
    tableStore,
    items,
    filterFn,
    searchFn,
    sortComparer,
}: {
    tableStore: TableStore<Item>;
    items: undefined | Item[] | APIResult<Item[]>;
    filterFn?: (item: Item) => boolean;
    searchFn?: (item: Item, search: string) => boolean;
    sortComparer?: Comparer<Item>;
}) => {
    useEffect(() => {
        const disposer = autorun(() => {
            if (!items) {
                return;
            }

            let actualItems: Item[] | undefined = undefined;
            if (Array.isArray(items)) {
                actualItems = items;
            } else if (items.state === "success") {
                actualItems = items.data;
            }
            if (!actualItems) {
                return;
            }

            const { search, orderBy, orderDir } = tableStore;

            const filteredItems = !filterFn ? actualItems : actualItems.filter(filterFn);

            const lowerSearch = search.toLowerCase();
            const searchedItems =
                !lowerSearch || !searchFn ? filteredItems : filteredItems.filter(item => searchFn(item, lowerSearch));

            const sortedItems = sort(searchedItems, orderBy, orderDir, sortComparer);

            tableStore.items = sortedItems;
            tableStore.totalCount = sortedItems.length;
        });
        return () => {
            disposer();
        };
    }, [filterFn, items, searchFn, sortComparer, tableStore]);
};

/**
 * A helper for loading the table store items.
 *
 * It will automatically reset the offset (=page) and clear the selection when the dependencies change.
 *
 * It does not do any error handling, do this in the `loader`.
 *
 * `deps` and `loader` are expected to be "stable", e.g. not change on every render (use `useMemo` and `useCallback`).
 */
export const useTableStoreLoader = <Item extends ItemWithId, Deps extends AnObject>(
    tableStore: TableStore<Item>,
    loader: (deps: Deps, tableParams: ITableParams<Item>) => Promise<{ items: Item[]; totalCount: number } | null>,
    deps: Deps,
) => {
    const previousLoadDeps = useRef<Deps>();
    const previousLoadTableParams = useRef<ITableParams<Item>>();

    const load = useCallback(
        async (deps: Deps, tableParams: ITableParams<Item>) => {
            previousLoadDeps.current = deps;
            previousLoadTableParams.current = tableParams;

            const result = await loader(deps, tableParams);
            if (result) {
                tableStore.items = result.items;
                tableStore.totalCount = result.totalCount;
            }
        },
        [loader, tableStore],
    );

    useEffect(() => {
        const prev = previousLoadDeps.current;
        if (!prev || prev !== deps) {
            previousLoadDeps.current = deps;

            tableStore.selectedItems.clear();

            if (tableStore.page > 1) {
                // start from page 1 again - this will cause a change in `tableParams` which triggers the useEffect below
                tableStore.resetOffset();
                return;
            }
        }

        if (previousLoadTableParams.current) {
            load(deps, previousLoadTableParams.current);
        }
    }, [deps, load, tableStore]);

    useDeepCompareEffect(() => {
        if (previousLoadDeps.current) {
            load(previousLoadDeps.current, tableStore.tableParams);
        }
    }, [load, tableStore.tableParams]);

    const reload = useCallback(() => {
        if (previousLoadDeps.current && previousLoadTableParams.current) {
            load(previousLoadDeps.current, previousLoadTableParams.current);
        }
    }, [load]);

    return {
        /** Loads the current page again */
        reload,
    };
};
