import { Tab, Tabs } from "@material-ui/core";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { t } from "../../i18n/util";
import { pushRoute } from "../app/router/history";
import { CompaniesUserReleaseInfoAdminActionsBar } from "../ui/CompaniesUserReleaseInfoAdminActionsBar";
import { FullWidthNavBarContainer, MobileNavBarContainer, NavBarTitle } from "../ui/NavBarContainer";
import { PendingMicrosoftActionsBar } from "../ui/PendingMicrosoftActionsBar";
import { TabBarContainer } from "../ui/TabBar";
import { MobileContext } from "../util/MobileContext";
import { AdvisorRoutes } from "./router/AdvisorRoutes";

export const AdvisorCompanyOverviewNavBar = () => {
    const isMobile = React.useContext(MobileContext);

    const { pathname } = useLocation();
    const [page, setPage] = React.useState<string | null>("active");
    React.useEffect(() => {
        if (pathname === AdvisorRoutes.COMPANIES.ACTIVE) {
            setPage("active");
        } else if (pathname === AdvisorRoutes.COMPANIES.INACTIVE) {
            setPage("not-configured");
        }
    }, [pathname]);
    const handlePageChange = (_: React.ChangeEvent<unknown>, page: string | null) => {
        setPage(page);
    };

    const showActiveCompanies = () => {
        pushRoute(AdvisorRoutes.COMPANIES.ACTIVE);
    };

    const showNonConfiguredCompanies = () => {
        pushRoute(AdvisorRoutes.COMPANIES.INACTIVE);
    };

    const title = <NavBarTitle>{t("common.companyOverview")}</NavBarTitle>;

    const tabs = (
        <Tabs
            value={page}
            indicatorColor="primary"
            textColor="primary"
            onChange={handlePageChange}
            variant="scrollable"
            scrollButtons="off"
        >
            <Tab
                label={t("screen.advisor.tabbar.active")}
                value="active"
                onClick={showActiveCompanies}
                data-id="company_tab_bar_active"
            />
            <Tab
                label={t("screen.advisor.tabbar.notConfigured")}
                value="not-configured"
                onClick={showNonConfiguredCompanies}
                data-id="company_tab_bar_not_configured"
            />
        </Tabs>
    );

    return (
        <>
            {isMobile && (
                <MobileNavBarContainer tabs={tabs}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 16 }}>{title}</div>
                </MobileNavBarContainer>
            )}
            {!isMobile && (
                <>
                    <FullWidthNavBarContainer hideBorder>{title}</FullWidthNavBarContainer>
                    <TabBarContainer>{tabs}</TabBarContainer>
                </>
            )}
            <PendingMicrosoftActionsBar />
            <CompaniesUserReleaseInfoAdminActionsBar />
        </>
    );
};
