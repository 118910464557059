import { Button } from "@material-ui/core";
import flatMap from "lodash/flatMap";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import { FormattedHTMLMessage } from "react-intl";
import { t } from "../../i18n/util";
import { UserPermissions } from "../../network/APITypes";
import { getFullName } from "../../util/user";
import { CustomDialog, CustomDialogContent } from "./CustomDialog";
import { TpaWhiteButton } from "./Primitives";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";

export const UserReleaseInfoAdminDialog = (props: {
    open: boolean;
    waitingUsers: UserPermissions[];
    onSubmit: () => void;
    onCancel: () => void;
}) => {
    const groupedWaitingUsers = groupBy(props.waitingUsers, waitingUser => waitingUser.changedBy?.id);
    const superAdmins = uniqBy(
        flatMap(props.waitingUsers, waitingUser => (waitingUser.changedBy ? [waitingUser.changedBy] : [])),
        superAdmin => superAdmin.id,
    );

    return (
        <CustomDialog open={props.open}>
            <CustomDialogContent style={{ paddingBottom: 0 }}>
                <h1>{t("userReleaseInfoAdminDialog.title")}</h1>
            </CustomDialogContent>
            <div
                style={{
                    padding: "0 42px",
                    marginTop: 24,
                    minHeight: props.waitingUsers.length >= 2 ? undefined : props.waitingUsers.length * 20,
                    overflow: "auto",
                }}
            >
                {superAdmins.map(superAdmin => {
                    const count = groupedWaitingUsers[superAdmin.id]?.length;
                    return (
                        <div key={superAdmin.id}>
                            <FormattedHTMLMessage
                                id={
                                    count === 1
                                        ? "userReleaseInfoAdminDialog.releaseMessage.single"
                                        : "userReleaseInfoAdminDialog.releaseMessage.multi"
                                }
                                values={{
                                    userName: getFullName(superAdmin),
                                    count: groupedWaitingUsers[superAdmin.id]?.length,
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <CustomDialogContent style={{ paddingTop: 0 }}>
                <p style={{ marginTop: 16 }}>
                    {props.waitingUsers.length === 1
                        ? t("userReleaseInfoAdminDialog.releaseInfo.single")
                        : t("userReleaseInfoAdminDialog.releaseInfo.multi")}
                </p>
                <ResponsiveButtonContainer style={{ marginTop: 42 }}>
                    <TpaWhiteButton onClick={props.onCancel} data-id="button_cancel">
                        {t("common.later")}
                    </TpaWhiteButton>
                    <Button variant="contained" color="primary" onClick={props.onSubmit} data-id="button_confirm">
                        {t("userReleaseInfoAdminDialog.submit")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
};
