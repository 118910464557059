import { useEffect, useRef, useState } from "react";
import { API } from "../../network/API";
import { GetLROResponse, PublicHttpError } from "../../network/APITypes";
import { allCompleted, allDone } from "../../util/lro";

/**
 * Represents a Long Running Operation (LRO).
 */
interface LRO extends GetLROResponse {
    id: string;
}

export function useLROs(ids: string[] | undefined) {
    const [lros, setLROs] = useState<LRO[]>();
    const completedOnFirstLoad = useRef(false);

    useEffect(() => {
        if (!ids) {
            setLROs(undefined);
            completedOnFirstLoad.current = false;
            return;
        }

        let lros = ids.map<LRO>(id => ({ id, percentageComplete: 0, status: "inProgress", type: "unknown" }));

        let cancelled = false;

        let firstLoad = true;
        let timeoutId: number | undefined;

        async function load() {
            if (cancelled) {
                return;
            }

            const newLROs = await Promise.all(lros.map(fetchItem));
            if (cancelled) {
                return;
            }

            if (allCompleted(newLROs) && firstLoad) {
                completedOnFirstLoad.current = true;
                setLROs(newLROs);
                return;
            }

            firstLoad = false;
            completedOnFirstLoad.current = false;

            lros = newLROs;
            setLROs(newLROs);

            if (!allDone(newLROs)) {
                timeoutId = setTimeout(load, 1000); // start polling
            }
        }

        load();

        return () => {
            cancelled = true;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [ids]);

    return {
        lros,
        completedOnFirstLoad: completedOnFirstLoad.current,
    };
}

async function fetchItem(lro: LRO): Promise<LRO> {
    try {
        const response = await API.getLRO(lro.id);
        return { id: lro.id, ...response };
    } catch (error) {
        return { id: lro.id, percentageComplete: 0, status: "failed", error: toError(error), type: "unknown" };
    }
}

function toError(error: unknown): PublicHttpError {
    if (error instanceof Error) {
        return { type: "unknown", status: 500, title: error.message };
    }
    if (typeof error === "string") {
        return { type: "unknown", status: 500, title: error };
    }
    return { type: "unknown", status: 500, title: JSON.stringify(error) };
}
