import * as config from "../config";

const nilLog: (typeof console)["log"] = () => {
    // pass
};

export const debug = {
    log: config.DEBUG ? console.log.bind(console) : nilLog,
    error: config.DEBUG ? console.error.bind(console) : nilLog,
    group: config.DEBUG ? console.group.bind(console) : nilLog,
    groupEnd: config.DEBUG ? console.groupEnd.bind(console) : nilLog,
};

interface WindowWithStores extends Window {
    stores: Record<string, unknown>;
}

declare const window: WindowWithStores;

export function addStoreToWindow(name: string, store: unknown) {
    if (import.meta.env.DEV) {
        window.stores = { ...window.stores, [name]: store };
    }
}
