import { Checkbox, CheckboxProps, FormControlLabel } from "@material-ui/core";
import { FieldInputProps } from "formik";

type IProps = CheckboxProps & {
    field: FieldInputProps<boolean>;
    label: string;
    "data-id": string;
    onClick: () => void;
};

export const CustomCheckbox = ({ onClick, field, label, "data-id": dataId }: IProps) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    inputProps={{ "data-id": dataId } as never}
                    checked={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    onClick={onClick}
                    onBlur={field.onBlur}
                    color="primary"
                />
            }
            label={label}
        />
    );
};
