import { Routes } from "../../app/router/Routes";

const prefix = Routes.prefixed(Routes.SETTINGS);

export const SettingsRoutes = {
    ROOT: "/",
    GENERAL: {
        ROOT: prefix("/general"),
    },
    COMPANY: {
        ROOT: prefix("/company"),
        DATA: prefix("/company/data"),
        ACCOUNTING: prefix("/company/accounting"),
        HR: prefix("/company/hr"),
        KPIS: prefix("/company/kpis"),
        TERMS: prefix("/company/terms-of-use"),
        UPCOMING_TERMS: prefix("/company/upcoming-terms-of-use"),
        OPTIONAL_SIDEBAR_ITEMS: prefix("/company/optional-sidebar-items"),
        FEATURES: prefix("/company/features"),
    },
    USER_MANAGEMENT: {
        ROOT: prefix("/users"),
        USERS_EMPLOYEES: prefix("/users/employees"),
        USER_ROLES: prefix("/users/roles"),
        USER_PERMISSIONS: prefix("/users/permissions"),
    },
};
