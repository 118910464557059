import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetTitlesResponse } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useTitles = () => {
    const [titles, setTitles] = React.useState<GetTitlesResponse | null>(null);

    React.useEffect(() => {
        const load = async () => {
            try {
                const response = await API.getTitles();
                setTitles(response);
            } catch (err) {
                generalStore.setError(t("error.loadCompany"), err);
            }
        };

        load();
    }, []);

    return titles;
};
