import { Divider } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../i18n/util";
import { FullWidthNavBarContainer, MobileNavBarContainer, NavBarTitle } from "../ui/NavBarContainer";
import { NavBarToggleButtons } from "../ui/NavBarToggleButtons";
import { MobileContext } from "../util/MobileContext";
import { AdvisorRoutes } from "./router/AdvisorRoutes";

export const AdvisorUploadsNavBar = observer(function AdvisorUploadsNavBar() {
    const isMobile = React.useContext(MobileContext);

    const title = <NavBarTitle>{t("sidebar.advisor.uploads")}</NavBarTitle>;

    const buttons = [
        { title: t("screen.accounting.navbar.overview"), value: "overview", route: AdvisorRoutes.UPLOADS.ROOT },
        { title: t("common.files"), value: "documents", route: AdvisorRoutes.UPLOADS.DOCUMENTS },
    ];

    return isMobile ? (
        <>
            <MobileNavBarContainer>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 16 }}>{title}</div>
                <NavBarToggleButtons buttons={buttons} />
            </MobileNavBarContainer>
            <Divider />
        </>
    ) : (
        <FullWidthNavBarContainer>
            {title}
            <NavBarToggleButtons buttons={buttons} />
        </FullWidthNavBarContainer>
    );
});
