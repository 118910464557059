import { t } from "../../i18n/util";
import { DetailedEmployeePreRegistration } from "../../network/APITypes";
import { IEmployee } from "../../types/models";
import { genderToString } from "../../util/helpers";
import { useCountries } from "../hooks/useCountries";
import { OverviewLine } from "../ui/OverviewLine";
import { OverviewSection } from "../ui/OverviewSection";

export const HrEmployeePersonalDataOverview = (props: {
    employee: IEmployee | DetailedEmployeePreRegistration;
    showInsuranceType?: boolean;
    onEdit?: () => void;
    noBackground?: boolean;
}) => {
    const canEdit = !!props.onEdit;
    const countries = useCountries();

    const address = [props.employee.address, props.employee.houseNumber, props.employee.addressAddition]
        .join(" ")
        .trim();

    return (
        <OverviewSection
            title={t("screen.hr.personaldata.personaldata")}
            onEdit={props.onEdit}
            editDisabled={!canEdit}
            noBackground={props.noBackground}
        >
            <OverviewLine
                title={t("common.name")}
                label={`${props.employee.firstName}${props.employee.lastName && props.employee.firstName && " "}${
                    props.employee.lastName
                }`}
            />
            <OverviewLine title={t("screen.hr.summary.gender")} label={genderToString(props.employee.gender)} />
            <OverviewLine
                title={t("screen.hr.personaldata.addressHead")}
                component={
                    <div>
                        <p>{address}</p>
                        <p>
                            {props.employee.zipCode} {props.employee.city}
                        </p>
                        <p>{props.employee.country ? countries.isoToString(props.employee.country) : undefined}</p>
                    </div>
                }
            />

            <OverviewLine title={t("screen.hr.personaldata.dateOfBirth")} date={props.employee.dateOfBirth} />
            <OverviewLine title={t("screen.hr.personaldata.ssn")} label={props.employee.ssn} />
            <OverviewLine
                title={t("screen.hr.personaldata.citizenship")}
                label={props.employee.citizenship ? countries.isoToString(props.employee.citizenship) : undefined}
            />
            <OverviewLine
                title={t("screen.hr.personaldata.employmentRelationship")}
                label={
                    props.employee.employmentRelationship
                        ? t(`employmentRelationship.${props.employee.employmentRelationship}`)
                        : undefined
                }
            />
            {props.showInsuranceType !== false && (
                <OverviewLine
                    title={t("screen.hr.personaldata.employmentInsuranceType")}
                    label={
                        props.employee.employmentInsuranceType
                            ? t(`employmentInsuranceType.${props.employee.employmentInsuranceType}`)
                            : undefined
                    }
                />
            )}
            <OverviewLine title={t("screen.hr.personaldata.workplaceState")} label={props.employee.workplaceState} />
        </OverviewSection>
    );
};
