import { Button, FormControlLabel, IconButton } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    GetProjectsCompaniesResponseItem,
    PostProjectPayload,
    Project,
    ProjectItemPermission,
    ProjectItemPermissionRoleEnum,
    ProjectPermissionRequest,
    PublicHttpError,
} from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { IProjectUser } from "../../../types/models";
import { pushRoute, withParams } from "../../app/router/history";
import { ConfirmInternationalDialog } from "../../hooks/useConfirmInternationalProject";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { useErrorDialog } from "../../hooks/useErrorDialog";
import { usePendingMicrosoftActions } from "../../hooks/usePendingMicrosoftActions";
import { CenteredContent } from "../../ui/CenteredContent";
import { CustomInputField } from "../../ui/CustomInputField";
import { IOSSwitch } from "../../ui/IOSSwitch";
import { NavBarBack } from "../../ui/NavBarBack";
import { InfoButton, TableLabel, TpaIconInfoButton } from "../../ui/Primitives";
import { ResponsiveButtonContainer } from "../../ui/ResponsiveButtonContainer";
import { SiteContent } from "../../ui/SiteContent";
import { Icon } from "../../util/Icon";
import { DIALOG_WIDTH, customColors } from "../../util/Theme";
import { AutocompleteProject, AutocompleteProjectOption } from "../AutocompleteProject";
import { ProjectUserPermissionLine } from "../ProjectUserPermissionLine";
import { ProjectsRoutes } from "../router/ProjectsRoutes";
import { ProjectConfig } from "./types";

export const ProjectUsers = ({
    isNewProject,
    projectUsers,
    onChangePermission,
    onRemove,
}: {
    isNewProject: boolean;
    projectUsers: IProjectUser[];
    onChangePermission: (user: ProjectPermissionRequest) => (permission: ProjectItemPermissionRoleEnum) => void;
    onRemove: (user: ProjectPermissionRequest) => () => void;
}) => {
    const onlyOnePortalOwnerRemaining =
        projectUsers.filter(projectUser => !!projectUser.userId && projectUser.role === "owner").length === 1;

    return (
        <>
            {projectUsers.map(user => {
                const nameParts = user.name?.split(" ") ?? [];

                const isProjectCreator = isNewProject && user.userId === authStore.userId;
                const isLastOwner =
                    !isNewProject && user.role === "owner" && !!user.userId && onlyOnePortalOwnerRemaining;

                return (
                    <ProjectUserPermissionLine
                        key={`${user.id ?? ""}_${user.userId ?? ""}`}
                        firstName={nameParts[0] ?? ""}
                        lastName={nameParts[1] ?? ""}
                        needsRelease={user.needsRelease}
                        profileImageUrl={user.profile_picture_url}
                        isTpa={!!user.isTpaEmployee}
                        permissionDisabled={isProjectCreator || isLastOwner}
                        permission={user.role}
                        userId={user.userId}
                        onChangePermission={onChangePermission(user)}
                        onRemove={onRemove(user)}
                    />
                );
            })}
        </>
    );
};

const ProjectCompanies = ({
    projectCompanies,
    onRemove,
}: {
    projectCompanies: GetProjectsCompaniesResponseItem[];
    onRemove: (user: GetProjectsCompaniesResponseItem) => void;
}) => {
    return (
        <>
            {projectCompanies.map(company => {
                return (
                    <div
                        key={company.id}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 16,
                        }}
                    >
                        <TableLabel style={{ maxWidth: undefined }}>{company.name}</TableLabel>
                        <IconButton
                            onClick={() => {
                                onRemove(company);
                            }}
                        >
                            <Icon name="delete" />
                        </IconButton>
                    </div>
                );
            })}
        </>
    );
};

export const ProjectsDataProjectDetailsSite = observer(function ProjectsDataProjectDetailsSite({
    project,
    projectId,
    externalId,
    projectUsers,
    setProjectUsers,
    projectCompanies,
    setProjectCompanies,
    confirmInternational,
    resetProjectUsers,
}: {
    project: ProjectConfig;
    projectId?: string;
    externalId?: string;
    projectUsers: IProjectUser[];
    setProjectUsers: (users: IProjectUser[]) => void;
    projectCompanies: GetProjectsCompaniesResponseItem[];
    setProjectCompanies: (users: GetProjectsCompaniesResponseItem[]) => void;
    confirmInternational: ConfirmInternationalDialog;
    resetProjectUsers: () => void;
}) {
    const companyId = companiesStore.selectedCompanyId;
    const isConfigRoute = !!externalId;
    const isNewRoute = !externalId && !projectId;

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const nameConflictDialog = useErrorDialog({
        title: t("error.create"),
        message: t("error.create.alreadyExists.project"),
    });

    const exitAfterSubmit = () => {
        pushRoute(ProjectsRoutes.ACTIVE.ROOT);
    };

    const showUsersPendingDialog = useConfirmationDialog({
        title: t("pendingUserCreationDialog.title"),
        message: t("pendingUserCreationDialog.message"),
        onConfirm: exitAfterSubmit,
    });

    const { internationalAvailable, pendingActions } = usePendingMicrosoftActions();
    // Only way to detect international refresh is via "AT" tenant
    const companyCountryCode = companiesStore.selectedCompany?.countryCode;

    const internationalRefreshPending =
        companyCountryCode !== "AT" && pendingActions.staleCountries.find(country => country === "AT") !== undefined;

    const internationalUnlockAvailable = !internationalAvailable && authStore.isTpa;

    const disableInternational = internationalRefreshPending || internationalUnlockAvailable;
    let disableInternationalMessage = "Unknown reason";
    if (disableInternational) {
        if (internationalRefreshPending) {
            disableInternationalMessage = t("projects.international.disabled.refresh", { project: project?.name });
        } else if (internationalUnlockAvailable) {
            disableInternationalMessage = t("projects.international.disabled.unlock");
        }
    }

    const handleTokenRefresh = async () => {
        await authStore.refreshMSToken("AT");
    };

    const handleChangeProjectUserPermission =
        (user: ProjectPermissionRequest) => (permission: ProjectItemPermissionRoleEnum) => {
            setProjectUsers(
                projectUsers.map(projectUser =>
                    (projectUser.userId && user.userId && projectUser.userId === user.userId) ||
                    (projectUser.id && user.id && projectUser.id === user.id)
                        ? { ...projectUser, role: permission }
                        : projectUser,
                ),
            );
        };

    const handleClickUserRemove = (user: ProjectPermissionRequest) => () => {
        // disable delete for current user
        const disableDelete = authStore.userId === user.userId;
        if (disableDelete) {
            return;
        }

        setProjectUsers(
            projectUsers.filter(projectUser => {
                if (projectUser.userId && user.userId) {
                    return projectUser.userId !== user.userId;
                } else if (projectUser.id && user.id) {
                    return projectUser.id !== user.id;
                }

                return true;
            }),
        );
    };

    const handleClickCompanyRemove = (company: GetProjectsCompaniesResponseItem) => {
        setProjectCompanies(
            projectCompanies.filter(c => {
                return c.id !== company.id;
            }),
        );
    };

    const handleCancel = () => {
        if (isConfigRoute) {
            pushRoute(ProjectsRoutes.TO_CONFIGURE.ROOT);
        } else {
            pushRoute(ProjectsRoutes.ACTIVE.ROOT);
        }
    };

    const handleSubmit = async (values: { name: string }) => {
        if (!companyId || isSubmitting) {
            return;
        }

        try {
            setIsSubmitting(true);
            generalStore.isLoading = true;
            let response: Response;
            if (projectId) {
                // some subtypes are different for users, so needs to be casted
                response = await API.putProject(companyId, projectId, {
                    ...project, // First spread original data
                    ...values, // Then spread user input
                    international: confirmInternational.isInternational,
                    users: projectUsers,
                } as Partial<Project>);
            } else if (externalId || projectCompanies.length === 0) {
                const body: PostProjectPayload = {
                    ...project, // First spread original data
                    ...values, // Then spread user input
                    international: confirmInternational.isInternational,
                    users: projectUsers,
                };
                response = await API.postProject(companyId, body);

                for (const company of projectCompanies) {
                    await API.postProject(company.id, body);
                }
            } else {
                // update the project with the current values (= name)
                if (project) {
                    Object.assign(project, values);
                }
                // redirect to another page
                pushRoute(ProjectsRoutes.NEW_PROJECT_FINISH);
                return;
            }

            // TPAPORTAL-2373
            // Sharepoint sometimes cannot add users immediately because the azure user is unknown
            // during sharepoint invite. Waiting for a few minutes and retry fixes that.
            // Inform user about this
            let pendingDialog = false;
            if (response.status === HttpStatusCode.Accepted_202) {
                const json = (await response.json()) as PublicHttpError | undefined;
                if (json?.type === "AZURE_SHAREPOINT_SYNC_TIMEOUT") {
                    showUsersPendingDialog.open();
                    pendingDialog = true;
                }
            }

            // Not in finally to avoid state change on unmounted component after route change
            setIsSubmitting(false);

            if (!pendingDialog) {
                exitAfterSubmit();
            }
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.Conflict_409) {
                if (apiError.response.type === "NO_REFRESH_TOKEN_FOUND") {
                    generalStore.setError(t("error.msTokenExpired"), error);
                } else if (apiError.response.type === "PROJECT_NAME_EXISTS_CONFLICT") {
                    nameConflictDialog.open();
                } else if (apiError.response.type === "AZURE_CANT_INVITE_DOMAIN_EMAIL") {
                    generalStore.setError(t("error.noMsAccountAvailable"), error);
                } else {
                    generalStore.setError(t("error.general"), error);
                }
            } else {
                generalStore.setError(t("error.general"), error);
            }

            setIsSubmitting(false);
        } finally {
            generalStore.isLoading = false;
        }
    };

    if (!companyId) {
        return null;
    }

    // Necessary to make initial values of formik work
    if ((projectId || externalId) && !project) {
        return null;
    }

    let addMembersLink: string = ProjectsRoutes.NEW_PROJECT_USERS;
    if (projectId) {
        addMembersLink = withParams(ProjectsRoutes.EDIT_PROJECT_USERS, { projectId });
    } else if (externalId) {
        addMembersLink = withParams(ProjectsRoutes.TO_CONFIGURE.PROJECT_USERS, { externalId });
    }

    const handleInternationalChange = (isInternational: boolean) => {
        confirmInternational.onChange(isInternational);
        if (!isInternational) {
            resetProjectUsers();
        }
    };

    return (
        <>
            <NavBarBack
                title={
                    projectId ? t("projects.navbar.project.heading", { projectName: project?.name }) : t("projects.new")
                }
                companyName={companiesStore.selectedCompany?.name}
                onCancel={handleCancel}
            />
            <CenteredContent>
                <SiteContent style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "100%", maxWidth: DIALOG_WIDTH, paddingTop: 32 }}>
                        <h4>{t("projects.new.title")}</h4>
                        <Formik
                            initialValues={{
                                name: project?.name ?? "",
                                projectNumber: project?.projectNumber ?? "",
                            }}
                            onSubmit={handleSubmit}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required(t("error.requiredField")),
                            })}
                            validateOnBlur
                            validateOnChange
                        >
                            <Form id="formData" style={{ marginTop: 32 }}>
                                <Field
                                    data-id="name"
                                    component={CustomInputField}
                                    label={t("projects.new.name")}
                                    name="name"
                                    required
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                        // TPAPORTAL-2333: Save changes directly back to new project
                                        if (project) {
                                            project.name = e.target.value;
                                        }
                                    }}
                                />
                                <Field
                                    data-id="projectNumber"
                                    component={CustomInputField}
                                    label={t("projects.new.externalId")}
                                    name="projectNumber"
                                    disabled={true}
                                    hideOptional
                                />
                                <div style={{ display: "flex" }}>
                                    <h4 style={{ marginTop: 16 }}>{t("projects.international")}</h4>
                                    <TpaIconInfoButton
                                        disableRipple
                                        style={{ color: customColors.primaryColor }}
                                        onClick={confirmInternational.openInfoDialog}
                                    >
                                        <Icon name="info" />
                                    </TpaIconInfoButton>
                                </div>

                                <FormControlLabel
                                    control={
                                        <IOSSwitch
                                            checked={confirmInternational.isInternational}
                                            onChange={(_, checked) => {
                                                handleInternationalChange(checked);
                                            }}
                                        />
                                    }
                                    label={t("projects.international.switch")}
                                    style={{ marginTop: 16 }}
                                    disabled={
                                        project?.international === true /* cannot be undone */ || disableInternational
                                    }
                                />
                                {disableInternational && (
                                    <div style={{ display: "flex" }}>
                                        {disableInternationalMessage}
                                        {internationalRefreshPending && (
                                            <Button
                                                color="primary"
                                                style={{ textTransform: "uppercase", flexShrink: 0, height: 44 }}
                                                onClick={handleTokenRefresh}
                                            >
                                                {t("button.refresh")}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </Form>
                        </Formik>

                        <div style={{ marginTop: 32, display: "flex", justifyContent: "space-between" }}>
                            <h4>{t("common.members")}</h4>
                            <Link
                                to={addMembersLink}
                                className="body1"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: customColors.primaryColor,
                                    textDecoration: "none",
                                }}
                            >
                                <Icon name="add" />
                                <span style={{ marginLeft: 8 }}>{t("projects.addMembers")}</span>
                            </Link>
                        </div>
                        {/* {legacyAddProjectUsers} */}

                        <div>
                            <ProjectUsers
                                isNewProject={!projectId}
                                onChangePermission={handleChangeProjectUserPermission}
                                onRemove={handleClickUserRemove}
                                projectUsers={projectUsers}
                            />
                        </div>

                        <ImportUsers
                            projectId={projectId}
                            projectUsers={projectUsers}
                            setProjectUsers={setProjectUsers}
                            international={confirmInternational.isInternational}
                        />

                        {isNewRoute && (
                            <>
                                <div style={{ marginTop: 32, display: "flex", alignItems: "center" }}>
                                    <h4>{t("common.companies")}</h4>
                                    <InfoButton title={t("projects.addCompanies.tooltip")} color="primary" />
                                    <div style={{ flex: 1 }} />
                                    <Link
                                        to={ProjectsRoutes.NEW_PROJECT_COMPANIES}
                                        className="body1"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            color: customColors.primaryColor,
                                            textDecoration: "none",
                                        }}
                                    >
                                        <Icon name="add" />
                                        <span style={{ marginLeft: 8 }}>{t("projects.addCompanies")}</span>
                                    </Link>
                                </div>
                                <div>
                                    <ProjectCompanies
                                        projectCompanies={projectCompanies}
                                        onRemove={handleClickCompanyRemove}
                                    />
                                </div>
                            </>
                        )}

                        <ResponsiveButtonContainer style={{ marginTop: 40 }}>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                form="formData"
                                disabled={
                                    isSubmitting || (!!projectId && !projectUsers.some(user => user.role === "owner"))
                                }
                            >
                                {projectId ? t("common.save") : t("projects.new.createNewProject")}
                            </Button>
                        </ResponsiveButtonContainer>
                    </div>
                </SiteContent>
            </CenteredContent>
            {confirmInternational.infoDialog}
            {confirmInternational.dialog}
            {nameConflictDialog.dialog}
            {showUsersPendingDialog.dialog}
        </>
    );
});
interface Props {
    projectId?: string;
    projectUsers: IProjectUser[];
    setProjectUsers: (users: IProjectUser[]) => void;
    international: boolean;
}

const ImportUsers = ({ projectId, projectUsers, setProjectUsers, international }: Props) => {
    const [checked, setChecked] = React.useState(false);
    const [selected, setSelected] = React.useState<AutocompleteProjectOption | null>(null);
    const [users, setUsers] = React.useState<ProjectItemPermission[] | null>(null);

    const handleCheckedChange = (_: unknown, checked: boolean) => {
        setChecked(checked);
        if (!checked) {
            setSelected(null);
            setUsers(null);
        }
    };

    const handleProjectChange = async (option: AutocompleteProjectOption | null) => {
        setSelected(option);
        setUsers(null);

        if (option) {
            const { users } = await API.getProjectItemPermissions(option.company.id, option.project.id);
            setUsers(users);
        }
    };

    const handleAdd = () => {
        const newProjectUsers = [...projectUsers];

        users?.forEach(user => {
            const index = newProjectUsers.findIndex(u => u.userId === user.userId);
            if (index >= 0) {
                // change the role
                newProjectUsers[index] = { ...newProjectUsers[index], role: user.role };
            } else {
                // the ID is unique, hence should not be taken over
                const { id, ...rest } = user;
                newProjectUsers.push(rest);
            }
        });

        setProjectUsers(newProjectUsers);
    };
    const handleReplace = () => {
        if (users) {
            // the ID is unique, hence should not be taken over
            setProjectUsers(users.map(({ id, ...rest }) => rest));
        }
    };

    return (
        <div style={{ marginTop: 16 }}>
            <FormControlLabel
                control={<IOSSwitch checked={checked} onChange={handleCheckedChange} />}
                label={t("projects.importUsers.switch")}
            />
            {checked ? (
                <div style={{ marginTop: 16 }}>
                    <AutocompleteProject
                        value={selected}
                        onChange={handleProjectChange}
                        includeInternational={international}
                        filterIds={projectId ? [projectId] : []}
                    />
                    {users?.map(user => {
                        const [firstName, lastName] = user.name.split(" ");
                        return (
                            <ProjectUserPermissionLine
                                key={`${user.id ?? ""}_${user.userId ?? ""}`}
                                firstName={firstName ?? ""}
                                lastName={lastName ?? ""}
                                isTpa={user.isTpaEmployee ?? false}
                                permission={user.role}
                                permissionDisabled={true}
                                profileImageUrl={user.profile_picture_url}
                                userId={user.userId}
                            />
                        );
                    })}
                    <ResponsiveButtonContainer style={{ marginTop: 16 }}>
                        <Button color="primary" variant="contained" onClick={handleAdd} disabled={!users}>
                            {t("projects.importUsers.add")}
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleReplace} disabled={!users}>
                            {t("projects.importUsers.replace")}
                        </Button>
                    </ResponsiveButtonContainer>
                </div>
            ) : null}
        </div>
    );
};
