import { Location } from "history";
import { observer } from "mobx-react";
import * as React from "react";
import { history, pushRoute, withParams } from "../../app/router/history";
import { ISideBarItemConfig, SideBarItem } from "./SideBarItem";

export const SideBarItems = observer(function SideBarItems(props: {
    items: ISideBarItemConfig[];
    onClickItem?: () => void;
}) {
    const entries = props.items.filter(item => item.enabled !== false);

    // Get entries index from browser location
    const getIndexFromLocation = React.useCallback(
        (location: Location) => {
            return entries.findIndex(entry => {
                if (entry.isOptionalMenuItem) {
                    return location.pathname.endsWith(entry.title);
                } else {
                    return location.pathname.startsWith(entry.target);
                }
            });
        },
        [entries],
    );

    // Subscribe to history changes
    React.useEffect(() => {
        // If getIndexFromLocation changed it means we have new entries -> update selection
        setSelectedIndex(getIndexFromLocation(history.location));

        const unregister = history.listen((location, action) => {
            // Set current selection
            const index = getIndexFromLocation(location);
            setSelectedIndex(index);
        });

        return unregister;
    }, [getIndexFromLocation]);

    // Index into entries
    const [selectedIndex, setSelectedIndex] = React.useState(getIndexFromLocation(history.location));

    const selectEntry = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
        const entry = entries[index];
        if (entry.target) {
            if (entry.isOptionalMenuItem) {
                pushRoute(withParams(entry.target, { name: entry.title }));
            } else {
                pushRoute(entry.target);
            }
        }

        if (props.onClickItem) {
            props.onClickItem();
        }
    };

    return (
        <div style={{ width: "100%" }}>
            {entries.map((entry, index) => (
                <SideBarItem
                    data-id={"sidebar_" + entry.title}
                    key={entry.title}
                    selected={selectedIndex === index}
                    iconName={entry.iconName}
                    title={entry.title}
                    onClick={event => {
                        selectEntry(event, index);
                    }}
                    divider={entry.divider}
                    badgeCount={entry.badgeCount}
                />
            ))}
        </div>
    );
});
