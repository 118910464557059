import { Button, ButtonProps, withStyles } from "@material-ui/core";
import * as React from "react";
import { Icon, IIconNames } from "../util/Icon";
import { customColors } from "../util/Theme";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { MobileContext } from "../util/MobileContext";

const StyledButton = withStyles({
    root: {
        fontWeight: "normal",
        fontSize: 10,
        padding: "4px 8px",
        backgroundColor: customColors.primaryShade,
        marginLeft: 8,
        marginBottom: 8,
    },
})(Button);

const ChatButton = ({
    iconName,
    onClick,
    children,
    disabled,
    ...props
}: ButtonProps & {
    iconName: IIconNames;
    onClick?: () => void;
    children?: React.ReactNode;
}) => (
    <StyledButton
        onClick={onClick}
        startIcon={<Icon style={{ color: customColors.primaryColor, opacity: disabled ? 0.5 : 1 }} name={iconName} />}
        {...props}
        disabled={disabled}
    >
        {children}
    </StyledButton>
);

const ChatButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
`;

export const ChatButtons = (props: {
    onUpload?: () => void;
    onRelease?: () => void;
    onAssign?: () => void;
    onCloseTicket?: () => void;
    onArchive?: () => void;
    onTogglePrivate?: () => void;
    disabled?: boolean;
}) => {
    const isMobile = React.useContext(MobileContext);

    return (
        <ChatButtonsContainer style={{ padding: isMobile ? 4 : 0 }}>
            {props.onUpload && (
                <ChatButton iconName="add" onClick={props.onUpload} disabled={props.disabled}>
                    {t("button.upload")}
                </ChatButton>
            )}
            {props.onArchive && (
                <ChatButton iconName="archive" onClick={props.onArchive} disabled={props.disabled}>
                    {t("button.archive")}
                </ChatButton>
            )}
            {props.onRelease && (
                <ChatButton iconName="checkmark" onClick={props.onRelease} disabled={props.disabled}>
                    {t("button.release")}
                </ChatButton>
            )}
            {/* {props.onAssign && ( -> disable assign for phase 1
                <ChatButton iconName="assign" onClick={props.onAssign} disabled={props.disabled}>
                    {t("button.assign")}
                </ChatButton>
            )} */}
            {props.onCloseTicket && (
                <ChatButton iconName="pin" onClick={props.onCloseTicket} disabled={props.disabled}>
                    {t("button.closeTicket")}
                </ChatButton>
            )}
            {props.onTogglePrivate && (
                <ChatButton iconName="locked" onClick={props.onTogglePrivate} disabled={props.disabled}>
                    {t("button.markAsPrivate")}
                </ChatButton>
            )}
        </ChatButtonsContainer>
    );
};
