import * as React from "react";
import { ConfirmationDialog } from "../ui/ConfirmationDialog";
import { useAwaitableDialog } from "./useAwaitableDialog";

interface Props {
    title: string;
    message?: React.ReactNode;
    confirmLabel?: string;
    cancelLabel?: string;
    onConfirm?: () => void;
    onCancel?: false | (() => void);
    danger?: boolean;
}

export const useConfirmationDialog = ({
    title,
    message,
    confirmLabel,
    cancelLabel,
    onConfirm,
    danger,
    onCancel,
}: Props) => {
    type AdditionalProps = Partial<Pick<Props, "title" | "message" | "confirmLabel" | "cancelLabel">>;

    const { open, dialogProps, additionalProps } = useAwaitableDialog<undefined, AdditionalProps>({});

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        dialogProps.onClose();
    };

    const dialog = (
        <ConfirmationDialog
            open={dialogProps.open}
            title={additionalProps.title ?? title}
            message={additionalProps.message ?? message}
            confirmLabel={additionalProps.confirmLabel ?? confirmLabel}
            cancelLabel={additionalProps.cancelLabel ?? cancelLabel}
            onConfirm={() => {
                dialogProps.onSubmit(undefined);
                if (onConfirm) {
                    onConfirm();
                }
            }}
            danger={danger}
            onCancel={onCancel !== false ? handleCancel : undefined}
        />
    );

    return {
        open,
        dialog,
    };
};
