import * as React from "react";
import { MobileContext } from "../util/MobileContext";
import { CenteredContent } from "./CenteredContent";
import { VCenter } from "./Primitives";

interface IProps {
    children: React.ReactNode;
}

export const FullScreenDocumentUploadContainer = ({ children }: IProps) => {
    const isMobile = React.useContext(MobileContext);

    return isMobile ? (
        <div style={{ padding: "24px 16px" }}>{children}</div>
    ) : (
        <CenteredContent>
            <VCenter style={{ alignItems: "center" }}>{children}</VCenter>
        </CenteredContent>
    );
};
