import * as React from "react";
import { t } from "../../i18n/util";
import { Report } from "../../network/APITypes";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";
import { IReportsConfig } from "../hooks/useReports";
import { ConfirmationDialog } from "../ui/ConfirmationDialog";

const ConfirmDeleteReportDialog = (props: {
    module: Module;
    open: boolean;
    reportName?: string;
    onClose: () => void;
    onDelete: () => void;
}) => {
    const store = getModuleStore(props.module);
    return (
        <ConfirmationDialog
            open={props.open}
            title={store.t("modules.delete.report.title")}
            message={store.t("modules.delete.report.message", { name: props.reportName })}
            confirmLabel={t("common.delete")}
            onCancel={props.onClose}
            onConfirm={props.onDelete}
        />
    );
};

// Delete report handling including confirmation dialog
export const useDeleteReport = (module: Module) => {
    const moduleStore = getModuleStore(module);

    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [reportToDelete, setReportToDelete] = React.useState<Report | null>(null);
    const [reportToDeleteReportsConfig, setReportToDeleteReportsConfig] = React.useState<IReportsConfig | null>(null);

    const handleDelete = (reportsConfig: IReportsConfig) => (report: Report) => {
        setReportToDelete(report);
        setReportToDeleteReportsConfig(reportsConfig);
        setDeleteDialogOpen(true);
    };

    const doDelete = async () => {
        if (reportToDelete && reportToDeleteReportsConfig) {
            await moduleStore.deleteReport(reportToDelete.id, reportToDeleteReportsConfig.global);

            // Now reload the report list
            await reportToDeleteReportsConfig.loadReports();
        }

        setReportToDelete(null);
        setReportToDeleteReportsConfig(null);
        setDeleteDialogOpen(false);
    };

    const dialog = (
        <ConfirmDeleteReportDialog
            module={moduleStore.module}
            reportName={reportToDelete?.document.name}
            open={deleteDialogOpen}
            onClose={() => {
                setDeleteDialogOpen(false);
            }}
            onDelete={doDelete}
        />
    );

    return {
        handleDelete,
        dialog,
    };
};
