import { LocationDescriptor } from "history";
import * as React from "react";
import { NAVBAR_INFO_HEIGHT } from "../../config";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { CenteredContent } from "./CenteredContent";
import { RouterLink, TableRowButton } from "./Primitives";

export const NavBarInfo = (props: {
    children?: React.ReactNode;
    fixed?: boolean;
    onClick?: () => void;
    centered?: boolean;
}) => {
    const isMobile = React.useContext(MobileContext);

    const content = (
        <div
            style={{
                display: "flex",
                padding: isMobile ? 16 : "16px 24px",
                color: "white",
                alignItems: "center",
            }}
        >
            {props.children}
        </div>
    );

    return (
        <>
            <div
                className={props.fixed ? "mui-fixed" : undefined}
                style={{
                    ...(props.fixed
                        ? { position: "fixed", top: `calc(var(--navBarHeight) - ${NAVBAR_INFO_HEIGHT}px)`, zIndex: 100 }
                        : {}),
                    width: "100%",
                    minHeight: NAVBAR_INFO_HEIGHT,
                    color: "white",
                    backgroundColor: customColors.primaryColorLight,
                    cursor: props.onClick ? "pointer" : undefined,
                }}
                onClick={props.onClick}
            >
                {props.centered === false ? content : <CenteredContent>{content}</CenteredContent>}
            </div>
            {props.fixed && <div style={{ height: NAVBAR_INFO_HEIGHT }} />}
        </>
    );
};

export const NavBarInfoMessage = (props: { children: React.ReactNode }) => {
    return <p style={{ fontWeight: "bold", color: "inherit", flex: 1, flexShrink: 0 }}>{props.children}</p>;
};

export const NavBarInfoButton = (props: {
    onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
}) => {
    return (
        <TableRowButton onClick={props.onClick} style={{ color: "inherit", textTransform: "uppercase" }}>
            {props.children}
        </TableRowButton>
    );
};

export const NavBarInfoLinkButton = (props: { to: LocationDescriptor; children: React.ReactNode }) => {
    return (
        <RouterLink to={props.to} style={{ color: "inherit" }}>
            <NavBarInfoButton>{props.children}</NavBarInfoButton>
        </RouterLink>
    );
};
