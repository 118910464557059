import * as React from "react";
import { t } from "../../i18n/util";
import { companiesStore } from "../../stores/CompaniesStore";
import { coordinator } from "../../stores/Coordinator";
import { generalStore } from "../../stores/GeneralStore";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";

export const refreshRecordTypes = async (module: Module) => {
    const moduleStore = getModuleStore(module);
    try {
        generalStore.isLoading = true;
        await moduleStore.loadRecordTypes(companiesStore.selectedCompanyId);
    } catch (err) {
        generalStore.setError(t("error.general"), err);
    } finally {
        generalStore.isLoading = false;
    }
};

export const useRefreshRecordTypes = (module: Module, condition?: boolean) => {
    const isSyncing = coordinator.isSyncing;

    React.useEffect(() => {
        if (!isSyncing && condition !== false) {
            refreshRecordTypes(module);
        }
    }, [condition, isSyncing, module]);
};
