import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Routes } from "../../app/router/Routes";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { CockpitEventsSite } from "../sites/CockpitEventsSite";
import { CockpitNewsArticleSite } from "../sites/CockpitNewsArticleSite";
import { CockpitNewsSite } from "../sites/CockpitNewsSite";
import { CockpitOpenTicketsSite } from "../sites/CockpitOpenTicketsSite";
import { CockpitOverviewSite } from "../sites/CockpitOverviewSite";
import { CockpitRoutes } from "./CockpitRoutes";
import { CockpitDueDatesSite } from "../sites/CockpitDueDatesSite";

export const CockpitRouter = () => (
    <Switch>
        <Route exact path={Routes.COCKPIT}>
            <CockpitOverviewSite />
        </Route>
        <Route path={CockpitRoutes.TICKET_OVERVIEW}>
            <CockpitOpenTicketsSite />
        </Route>
        <Route path={CockpitRoutes.NEWS_LIST_ARTICLE}>
            <CockpitNewsArticleSite />
        </Route>
        <Route path={CockpitRoutes.EVENTS_LIST_ARTICLE}>
            <CockpitNewsArticleSite />
        </Route>
        <Route path={CockpitRoutes.NEWS_LIST}>
            <CockpitNewsSite />
        </Route>
        <Route path={CockpitRoutes.EVENTS_LIST}>
            <CockpitEventsSite />
        </Route>
        <Route path={CockpitRoutes.NEWS_ARTICLE}>
            <CockpitNewsArticleSite />
        </Route>
        <Route path={CockpitRoutes.EVENT_ARTICLE}>
            <CockpitNewsArticleSite />
        </Route>
        <Route path={CockpitRoutes.DUE_DATES}>
            <CockpitDueDatesSite />
        </Route>
        <Route>
            <NotFoundSite />
        </Route>
    </Switch>
);
