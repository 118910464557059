import { FinancialAccountancy } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { Views } from "../types";
import { useFinancialAccountancies } from "./useFinancialAccountancies";

export function useResultViews({ financialAccountancy }: { financialAccountancy?: FinancialAccountancy }) {
    const companyStore = companiesStore.selectedCompanyStore;

    const financialAccountancies = useFinancialAccountancies();
    if (!financialAccountancies) {
        return undefined;
    }

    // use the currently selected one
    // or fall back to the current one (isLatest)
    if (!financialAccountancy) {
        financialAccountancy = financialAccountancies?.find(fa => fa.isLatest);
    }
    // or the most recent one
    if (!financialAccountancy) {
        financialAccountancy = financialAccountancies?.reduce<FinancialAccountancy | undefined>((recent, fa) => {
            return !recent || new Date(fa.startsAt) > new Date(recent.startsAt) ? fa : recent;
        }, undefined);
    }

    const views: Views[] = [];

    if (financialAccountancy?.profitAssessmentType === "CashAccounting") {
        views.push("cashAccounting", "accountListing", "customerListing", "vendorListing");
    } else if (financialAccountancy?.profitAssessmentType === "CostAccounting") {
        views.push("balanceSheet", "profitAndLoss", "accountListing", "customerListing", "vendorListing");
    }

    if (companyStore?.canReadEmployerPayrollAccount) {
        views.push("employerPayrollAccount");
    }

    return views;
}
