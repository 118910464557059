import { Button, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { Redirect, useLocation } from "react-router";
import styled from "styled-components";
import { t } from "../../../i18n/util";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { pushRoute } from "../../app/router/history";
import { Routes } from "../../app/router/Routes";
import { NavBarBack } from "../../ui/NavBarBack";
import { PageWithStepper } from "../../ui/PageWithStepper";
import { RouterLinkButton, TableLabel } from "../../ui/Primitives";
import { Icon } from "../../util/Icon";
import { BOX_SHADOW_LIGHT, customColors } from "../../util/Theme";
import { AdvisorConfigCompaniesStepper } from "../AdvisorConfigCompaniesStepper";
import { AdvisorRoutes } from "../router/AdvisorRoutes";
import { activateCompanies, ActivateCompaniesProgressStatus, BulkState } from "../util/bulk";

export const AdvisorConfigBulkFinishSite = () => {
    useHideSideBar();

    const { state } = useLocation<BulkState | undefined>();
    const companies = state?.companies;
    const documentVersion = state?.documentVersion;
    const signees = state?.signees;
    const skipTermsOfUse = state?.skipTermsOfUse;

    if (!companies?.length || (!skipTermsOfUse && (!documentVersion || !signees))) {
        // No companies in state or required terms of use details missing -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    return <AdvisorConfigBulkFinishContent state={state} />;
};

const AdvisorConfigBulkFinishContent = ({ state }: { state: BulkState }) => {
    const [companies, setCompanies] = useState<Company[]>(() => {
        return state.companies.map(company => ({ ...company, status: { type: "idle" } }));
    });

    const [activateStatus, setActivateStatus] = useState<"idle" | "activating" | "done">("idle");

    const handleStart = async () => {
        setActivateStatus("activating");

        await activateCompanies({
            companies: state.companies,
            coverImage: state.coverImage?.file,
            logoImage: state.logoImage?.file,
            documentVersion: state.documentVersion ?? "",
            signees: state.signees ?? [],
            skipTermsOfUse: state.skipTermsOfUse ?? false,
            progress(company, status) {
                setCompanies(companies => {
                    return companies.map(c => (c.id === company.id ? { ...c, status } : c));
                });
            },
        });

        setActivateStatus("done");
    };

    return (
        <>
            <NavBarBack
                title={t("config.bulk.finish.title")}
                companyName={t("config.companies.count", { count: companies.length })}
            />
            <PageWithStepper stepper={<AdvisorConfigCompaniesStepper state={state} disabled />}>
                <h1 style={{ marginBottom: 8, hyphens: "auto", wordBreak: "break-all" }}>
                    {t("config.bulk.finish.title")}
                </h1>
                <p style={{ marginTop: 16, marginBottom: 16, fontSize: 12 }}>{t("config.bulk.finish.description")}</p>
                <Button
                    style={{ marginBottom: 16 }}
                    color="primary"
                    variant="contained"
                    onClick={handleStart}
                    disabled={activateStatus !== "idle"}
                    startIcon={activateStatus === "activating" ? <CircularProgress size={16} /> : null}
                >
                    {t("config.bulk.finish.start")}
                </Button>
                <Companies companies={companies} showIcons={activateStatus !== "idle"} />
                <div style={{ marginTop: 32, textAlign: "right" }}>
                    <RouterLinkButton
                        color="primary"
                        variant="contained"
                        to={AdvisorRoutes.COMPANIES.INACTIVE}
                        disabled={activateStatus !== "done"}
                    >
                        {t("config.finishConfiguration.continue")}
                    </RouterLinkButton>
                </div>
            </PageWithStepper>
        </>
    );
};

interface Company {
    id: string;
    name: string;
    status: ActivateCompaniesProgressStatus;
}

interface CompaniesProps {
    companies: Company[];
    showIcons: boolean;
}

const CompanyRoot = styled.div`
    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-template-rows: 40px;
    grid-template-areas:
        "icon name open"
        ". error error";
    // similar style as "StyledTableRow"
    background: white;
    box-shadow: ${BOX_SHADOW_LIGHT};
    border-radius: 4px;
    margin-bottom: 8px;

    & > * {
        display: flex;
        align-items: center;
        padding: 8px 4px;
    }
`;

const Companies = ({ companies, showIcons }: CompaniesProps) => {
    const handleOpenCompany = async (company: Company) => {
        try {
            await coordinator.selectCompanyById(company.id);
            pushRoute(Routes.COCKPIT);
        } catch (err) {
            generalStore.setError(t("error.general"), err);
        }
    };

    return (
        <>
            {companies.map(company => {
                const translatedError = translateError(company.status);

                let isDone = false;
                let icon;
                if (!showIcons) {
                    icon = null;
                } else if (company.status.type === "done") {
                    isDone = true;
                    icon = <Icon name="checkmark" style={{ color: customColors.primaryColor }} />;
                } else if (translatedError) {
                    icon = <Icon name="close" style={{ color: customColors.error }} />;
                } else if (company.status.type === "idle") {
                    icon = <Icon name="hours" />;
                } else {
                    icon = <CircularProgress size={24} />;
                }
                return (
                    <CompanyRoot key={company.id}>
                        <div style={{ gridArea: "icon" }}>{icon}</div>
                        <div style={{ gridArea: "name" }}>
                            <TableLabel style={{ maxWidth: 450 }} tooltip={company.name}>
                                {company.name}
                            </TableLabel>
                        </div>
                        <div style={{ gridArea: "open" }}>
                            {isDone ? (
                                <Tooltip title={t("config.finishConfiguration.cockpit")}>
                                    <IconButton onClick={() => handleOpenCompany(company)} size="small">
                                        <Icon name="open" />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                        </div>
                        {translatedError ? (
                            <div style={{ gridArea: "error", color: customColors.error, paddingTop: 0 }}>
                                {translatedError}
                            </div>
                        ) : null}
                    </CompanyRoot>
                );
            })}
        </>
    );
};

const translateError = (status: ActivateCompaniesProgressStatus) => {
    if (!("error" in status) || !status.error) {
        return null;
    }

    const errorType = status.error.response.type;
    switch (true) {
        case status.type === "settingTermsOfUseAndSignees" && errorType === "TERMS_OF_USE_DUPLICATE":
            return t("error.termsOfUseDuplicateFile");
        case status.type === "settingTermsOfUseAndSignees":
            return t("error.setDocumentOrSignees");
        case status.type === "activating" && errorType === "COMPANY_NOT_READY":
            return t("error.config.activateCompanyFailed");
    }

    return;
};
