import { parse } from "ini";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { t } from "../i18n/util";
import { DocumentModule, ICompany, IEmployee, IPermissions, IRecord, Module, NotificationState } from "../types/models";
import { formatISODateOnly, getInitialPeriodRange } from "../util/date";
import { debug } from "../util/debug";
import { sanitizeFilename } from "../util/files";
import { apiClient } from "./APIClient";
import {
    Chat,
    Company,
    CompanyDocumentScope,
    CreateFolderPayload,
    CustomMenuItem,
    DetailedEmployee,
    DetailedEmployeePreRegistration,
    DocumentSignaturePosition,
    Download,
    Draft,
    DraftRequest,
    DraftSignResponse,
    EmployeeDocument,
    EmployeeDocumentDetails,
    EmployeeDocumentSignResponse,
    EmployeeDocumentType,
    EmployeeDocumentWebhook,
    EmployeeStatus,
    ExternalEmployeePreRegistration,
    GeneralDocumentType,
    GeneralDocumentWebhook,
    GetAccountListingResponse,
    GetAccountResponse,
    GetAccountTransactionsResponse,
    GetAccountingDocumentDestinationsResponse,
    GetAccountsResponse,
    GetAppNotificationsResponse,
    GetAuthMicrosoftInviteResponse,
    GetBalanceSheetResponse,
    GetBankAccountTransactionsResponse,
    GetBankConnectionsResponse,
    GetBranchNamesResponse,
    GetCashAccountingResponse,
    GetChatMessagesResponse,
    GetChatUsersResponse,
    GetCompaniesForPersonResponse,
    GetCompaniesProjectsOwnerResponse,
    GetCompaniesProjectsResponse,
    GetCompaniesResponse,
    GetCompaniesUsersToReleaseResponse,
    GetCompanyDeadlinesResponse,
    GetCompanyDocumentsResponse,
    GetCompanyFeaturesResponse,
    GetCompanyKPIConfigurationsResponse,
    GetCompanyKPIsResponse,
    GetCompanyPeopleResponse,
    GetCompanyUsersResponse,
    GetCostCentersFullResponse,
    GetCountriesResponse,
    GetCurrentEmployeesResponse,
    GetCustomerListingResponse,
    GetDocumentDestinationsResponse,
    GetDocumentsResponse,
    GetDraftsResponse,
    GetEmployeeDocumentsResponse,
    GetEmployeePayrollAccountResponse,
    GetEmployeePreRegistrationsResponse,
    GetEmployeesDocumentsResponse,
    GetEmployerPayrollAccountDetailsResponse,
    GetEmployerPayrollAccountResponse,
    GetEventsFeedResponse,
    GetFeatureFlagsResponse,
    GetFeedCountriesResponse,
    GetFinancialAccountanciesResponse,
    GetFundersFullResponse,
    GetInsuranceCarrierBalanceResponse,
    GetInsuranceCarriersResponse,
    GetLROResponse,
    GetMainResponsibleUsersResponse,
    GetModuleTypeDetailsResponse,
    GetNewsFeedResponse,
    GetPastEmployeesResponse,
    GetPendingMicrosoftActionsResponse,
    GetPeriodsResponse,
    GetPowerBIReportsResponse,
    GetProfitAndLossResponse,
    GetProjectUsersResponse,
    GetProjectsCompaniesResponse,
    GetProjectsResponse,
    GetProjectsToConfigureResponse,
    GetPromotionsFeedResponse,
    GetPublicationsFeedResponse,
    GetReasonsForLeavingResponse,
    GetRecordTypesResponseSlice,
    GetRecordsResponse,
    GetReligionsResponse,
    GetReportsResponse,
    GetResponsibleUsersResponse,
    GetSubsidiariesResponse,
    GetSubsidiaryInsuranceCarriersResponse,
    GetTaxAccountResponse,
    GetTermsOfUseResponse,
    GetTermsOfUseSigneesResponse,
    GetTicketCountGroupedResponse,
    GetTicketMessagesResponse,
    GetTicketsResponse,
    GetTitlesResponse,
    GetUnpaidAccountsResponse,
    GetUserCompaniesResponse,
    GetUserInfoResponse,
    GetUserInterestsResponse,
    GetVendorListingResponse,
    Image,
    Knoedels,
    Message,
    NotificationModule,
    NotificationType,
    PatchCompanyPayload,
    PatchRecordPayload,
    PatchSubsidiaryPayload,
    PatchUserCompanySettingsPayload,
    PatchUserSettingsPayload,
    PaySlipType,
    PermissionsInvitationRead,
    PermissionsInvitationResultResponse,
    PermissionsRequest,
    PermissionsWithChanges,
    PostAuthInitResponse,
    PostBankAccountTransactionInvoicePayload,
    PostBankConnectionResponse,
    PostBugsPayload,
    PostBugsResponse,
    PostForgotPasswordCompleteMfaPayload,
    PostLocalLoginResponse,
    PostLoginResponse,
    PostMicrosoftRefreshResponse,
    PostOAuthRequest,
    PostOAuthResponse,
    PostPowerBIReportEmbedTokenResponse,
    PostProjectPayload,
    PostProjectUploadSessionResponse,
    PostProjectUsersIntersectionPayload,
    PostRecordResponse,
    PostReportWebhookResponse,
    PostSubsidiaryPayload,
    PostSubsidiaryResponse,
    PostTaxAccountInitPaymentResponse,
    PostTicketPayload,
    PostUnpaidAccountInitPaymentResponse,
    PostUserPayload,
    PostUserResponse,
    PostUserSettingsMfaCompletePayload,
    PostUserSettingsMfaResponse,
    Project,
    ProjectClipboardContentPayload,
    ProjectClipboardContentResponse,
    ProjectConfiguration,
    ProjectItem,
    ProjectItemPermissionSlice,
    ProjectItemSlice,
    ProjectStatusEnum,
    PutActivateCompanyPayload,
    PutArchiveChatMessagesPayload,
    PutBankAccountAccountNumberPayload,
    PutBankConnectionResponse,
    PutBulkTermsOfUseSigneesPayload,
    PutCompanyFeatureToggleResponse,
    PutCompanyKPIConfigurationsPayload,
    PutEmployeePreRegistrationTransferPayload,
    PutEmployeePreRegistrationTransferResponse,
    PutPrepareAccountTransactionDocumentsDownloadPayload,
    PutPrepareBankAccountTransactionsDownloadBankStatementPayload,
    PutPrepareBankAccountTransactionsDownloadBuerfPayload,
    PutPrepareEmployeesDocumentsDownloadPayload,
    PutTermsOfUseSigneesPayload,
    PutUserInterestToggleResponse,
    RecordType,
    RecordTypesSlice,
    RenameItemPayload,
    Report,
    ReportDetails,
    ReportSignResponse,
    ReportTypeEnum,
    ReportWebhook,
    ReportWebhookDocuments,
    Role,
    SubsidiaryType,
    TermsOfUseDownload,
    Ticket,
    TicketDetails,
    TicketRelation,
    UserCompanySettings,
    UserNotifications,
} from "./APITypes";
import { IAPITarget, ITypedAPITarget } from "./NetworkStapler";

export interface ITableParams<Item = never> {
    search?: string;
    offset?: number;
    limit?: number;
    orderBy?: keyof Item;
    orderDir?: "asc" | "desc";
}

export interface IGetRecordsParams {
    companyId: string;
    module: Module;
    subsidiaryId: string;
    periodId: string;
    recordTypeId: string;
    options?: ITableParams & { failedOnly?: boolean; skipIfAssignedToBankAccountTransaction?: boolean };
}

export interface IGetEmployeesParams {
    options: ITableParams & {
        status?: EmployeeStatus[];
        onlyMissingDetails?: boolean;
        startDate?: string;
        endDate?: string;
        activeDays?: number;
    };
}

export type IGetInternationalUsersParams = ITableParams & {
    userType?: "all" | "tpa" | "customer";
    filterCompanyId?: string;
    countryCode?: string;
};

export type IGetEmployeeDocumentsParams = ITableParams & {
    startDate?: string;
    endDate?: string;
    category?: "all" | "masterData" | "ongoing";
    types?: EmployeeDocumentType[];
};

export type IGetEmployeesDocumentsParams = ITableParams & {
    startDate?: string;
    endDate?: string;
    types?: EmployeeDocumentType[];
};

function employeeToPayload(employee: Partial<IEmployee>) {
    const body = cloneDeep(employee);

    if (employee.bic) {
        employee.bic = employee.bic.toUpperCase();
    }

    if (body.email === "") {
        body.email = null;
    }
    if (body.weeklyWorkDays === "") {
        body.weeklyWorkDays = null;
    }
    if (body.isGrossSalary === "true") {
        body.isGrossSalary = true;
    }
    if (body.isGrossSalary === "false") {
        body.isGrossSalary = false;
    }
    if (body.costCenter === t("common.notSpecified")) {
        body.costCenter = "";
    }
    if (body.funder === t("common.notSpecified")) {
        body.funder = "";
    }

    const toNumber = (valueString?: string | number | null) =>
        valueString ? Number(valueString.toString().replace(",", ".")) : null;

    body.monthlySalary = toNumber(body.monthlySalary);
    body.workingHours = toNumber(body.workingHours);
    body.remainingOvertime = toNumber(body.remainingOvertime);
    body.remainingVacationDays = toNumber(body.remainingVacationDays);
    body.kilometersForCommuterEuro = toNumber(body.kilometersForCommuterEuro);
    body.familyBonusPlusAmount = toNumber(body.familyBonusPlusAmount);
    if (!body.soleEarnerStatus) {
        body.soleEarnerStatus = null;
    }
    if (!body.commuterAllowance) {
        body.commuterAllowance = null;
    }

    if (body.bonuses) {
        body.bonuses = body.bonuses
            .filter(bonuses => !!bonuses.designator)
            .map(bonus => ({
                designator: bonus.designator,
                amount: toNumber(bonus.amount),
            }));
    }

    delete body.id;
    if (body.dateOfBirth) {
        body.dateOfBirth = moment(body.dateOfBirth).format("YYYY-MM-DD");
    }
    if (body.dateOfJoining) {
        body.dateOfJoining = moment(body.dateOfJoining).format("YYYY-MM-DD");
    }
    if (body.dateOfLeaving) {
        body.dateOfLeaving = moment(body.dateOfLeaving).format("YYYY-MM-DD");
    }
    if (!body.citizenship) {
        body.citizenship = null;
    }
    if (!body.country) {
        body.country = null;
    }

    return body;
}

const APITarget = {
    putCompanyImage(companyId: string, file: File, type: "logo" | "cover"): ITypedAPITarget<Image> {
        const data = new FormData();
        data.append("file", file);

        return {
            url: `/api/v1/companies/${companyId}/images/${type}`,
            method: "PUT",
            body: data,
        };
    },
};

const ignoredNewsCategories = ["News", "Newsblog", "Presseaussendungen"];

export interface AccountListingQuery extends ProfitAndLossQuery {}
export interface CustomerListingQuery extends ProfitAndLossQuery {}
export interface VendorListingQuery extends ProfitAndLossQuery {}
export interface BalanceSheetQuery extends ProfitAndLossQuery {}
export interface CashAccountingQuery extends ProfitAndLossQuery {}
export interface ProfitAndLossQuery {
    interval?: "Month" | "Year";
    previousYears?: number;
    useFullPreviousYears?: boolean;
}

export interface EmployerPayrollAccountQuery {
    filter?: string;
    previousYears?: number;
}

export const API = {
    loginWithPassword(options: { username: string; password: string }): Promise<PostLocalLoginResponse> {
        return apiClient.requestType({
            url: "/api/v1/auth/login",
            method: "POST",
            body: {
                username: options.username,
                password: options.password,
            },
        });
    },

    loginWithMultiFactorAuthCode(body: { flowToken: string; code: string }): Promise<PostLoginResponse> {
        return apiClient.requestType({
            url: "/api/v1/auth/login/complete",
            method: "POST",
            body: body,
        });
    },

    logout(refreshToken?: string) {
        return apiClient.request({
            url: "api/v1/auth/logout",
            method: "POST",
            body: {
                refresh_token: refreshToken,
            },
        });
    },

    postAuthInit(options: { username: string; azureConfirmationCode?: string }): Promise<PostAuthInitResponse> {
        return apiClient.requestType({
            url: "/api/v1/auth/init",
            method: "POST",
            body: {
                username: options.username,
                azureConfirmationCode: options.azureConfirmationCode,
            },
        });
    },

    tokenRefresh(refreshToken: string, keepRefresh?: boolean): Promise<PostLoginResponse> {
        const body = {
            refresh_token: refreshToken,
            keep_refresh: keepRefresh,
        };

        return apiClient.requestType(
            {
                url: "/api/v1/auth/refresh",
                method: "POST",
                body: body,
            },
            true,
        );
    },

    getUserInfo(noCache?: boolean): Promise<GetUserInfoResponse> {
        return apiClient.requestType({
            url: "/api/v1/auth/userinfo",
            headers: noCache
                ? {
                      "Cache-Control": "no-cache",
                  }
                : undefined,
        });
    },

    getUserInterests(): Promise<GetUserInterestsResponse> {
        return apiClient.requestType({
            url: "/api/v1/user/interests",
        });
    },

    putUserInterestToggle(interestId: string): Promise<PutUserInterestToggleResponse> {
        return apiClient.requestType({
            url: `/api/v1/user/interests/${interestId}/toggle`,
            method: "PUT",
        });
    },

    getDocuments(
        companyId: string,
        options: ITableParams & {
            startDate?: string;
            endDate?: string;
            module?: "all" | DocumentModule;
            scope?: CompanyDocumentScope;
            generalDocumentTypes?: GeneralDocumentType[];
            subsidiaryID?: string;
        },
    ): Promise<GetCompanyDocumentsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/documents`,
            queryParameters: options,
        });
    },

    putDownloadGeneralDocument(companyId: string, generalDocumentIds: string[], reportIds: string[]) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${companyId}/documents/download`,
            body: { generalDocumentIds, reportIds },
            method: "PUT",
        });
    },
    async getGeneralDocumentDownloadUrl(companyId: string, generalDocumentIds: string[], reportIds: string[]) {
        const link = await apiClient.requestType<Download>({
            url: `/api/v1/companies/${companyId}/documents/download`,
            body: { generalDocumentIds, reportIds },
            method: "PUT",
        });
        return link.downloadUrl;
    },

    getDueDates(companyId: string): Promise<GetCompanyDeadlinesResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/deadlines`,
        });
    },

    getTitles(): Promise<GetTitlesResponse> {
        return apiClient.requestType({ url: "/api/v1/core-data/titles" });
    },

    getReligions(): Promise<GetReligionsResponse> {
        return apiClient.requestType({ url: "/api/v1/core-data/religions" });
    },

    getReasonsForLeaving(): Promise<GetReasonsForLeavingResponse> {
        return apiClient.requestType({ url: "/api/v1/core-data/reasons-for-leaving" });
    },

    getCountries(): Promise<GetCountriesResponse> {
        return apiClient.requestType({ url: "/api/v1/core-data/countries" });
    },

    getPeriods(companyId: string, module: Module, range?: { start: moment.Moment; end: moment.Moment }) {
        const periodRange = range ?? getInitialPeriodRange();

        const target: ITypedAPITarget<GetPeriodsResponse[]> = {
            url: `/api/v1/companies/${companyId}/modules/${module}/periods`,
            queryParameters: {
                periodStart: formatISODateOnly(periodRange.start),
                periodEnd: formatISODateOnly(periodRange.end),
            },
        };

        return apiClient.requestType(target);
    },

    getPeriod(companyId: string, module: Module, periodId: string): Promise<GetPeriodsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}`,
        });
    },

    getModuleTypeDetails(companyId: string, module: Module): Promise<GetModuleTypeDetailsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/${module}`,
        });
    },

    getSubsidiaries({
        companyId,
        module,
        periodId,
        all,
        includeKnoedels,
    }: {
        companyId: string;
        module: Module;
        periodId?: string;
        all?: boolean;
        includeKnoedels?: boolean;
    }) {
        const target: ITypedAPITarget<GetSubsidiariesResponse[]> = periodId
            ? {
                  url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}/subsidiaries`,
                  queryParameters: { includeKnoedels },
              }
            : {
                  url: `/api/v1/companies/${companyId}/modules/${module}/subsidiaries`,
                  queryParameters: {
                      all,
                      includeKnoedels,
                  },
              };

        return apiClient.requestType(target);
    },

    getRecordTypes(p: { companyId: string; module: Module; periodId: string; subsidiaryId: string }) {
        const target: ITypedAPITarget<GetRecordTypesResponseSlice> = {
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/subsidiaries/${p.subsidiaryId}/record-types`,
            queryParameters: { includeKnoedels: true },
        };
        return apiClient.requestType(target);
    },

    getSubsidiaryRecordTypes(p: { companyId: string; module: Module; subsidiaryId: string }) {
        const target: ITypedAPITarget<RecordTypesSlice> = {
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/subsidiaries/${p.subsidiaryId}/record-types`,
        };
        return apiClient.requestType(target);
    },

    getCompanyRecordTypes(p: { companyId: string; module: Module }) {
        const target: ITypedAPITarget<RecordType[]> = {
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/record-types`,
        };
        return apiClient.requestType(target);
    },

    getRecords(params: IGetRecordsParams) {
        const p = params;
        const target: ITypedAPITarget<GetRecordsResponse> = {
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/subsidiaries/${p.subsidiaryId}/record-types/${p.recordTypeId}/records`,
            queryParameters: p.options,
        };

        return apiClient.requestType(target);
    },

    putDownloadRecords(p: {
        companyId: string;
        module: Module;
        subsidiaryId: string;
        periodId: string;
        recordTypeId: string;
        failedOnly?: boolean;
        recordIds?: string[];
    }) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/subsidiaries/${p.subsidiaryId}/record-types/${p.recordTypeId}/records/download`,
            queryParameters: { failedOnly: p.failedOnly },
            body: p.recordIds ?? [],
            method: "PUT",
        });
    },

    async getRecordDownloadUrl(p: {
        companyId: string;
        module: Module;
        subsidiaryId: string;
        periodId: string;
        recordTypeId: string;
        recordId: string;
    }) {
        const link = await apiClient.requestType<Download>({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/subsidiaries/${p.subsidiaryId}/record-types/${p.recordTypeId}/records/download`,
            method: "PUT",
            body: [p.recordId],
        });
        return link.downloadUrl;
    },

    getRecord(params: {
        companyId?: string;
        module: Module;
        periodId?: string;
        subsidiaryId?: string;
        recordTypeId?: string;
        recordId?: string;
    }) {
        const { companyId, periodId, subsidiaryId, recordTypeId, recordId } = params;
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }
        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }
        if (!periodId || !recordTypeId || !recordId) {
            throw new Error(t("error.missingParams"));
        }

        const p = params;
        const target: ITypedAPITarget<IRecord> = {
            url: `/api/v1/companies/${companyId}/modules/${p.module}/periods/${periodId}/subsidiaries/${subsidiaryId}/record-types/${recordTypeId}/records/${recordId}`,
        };

        return apiClient.requestType(target);
    },

    deleteRecord(params: {
        companyId?: string;
        module: Module;
        periodId?: string;
        subsidiaryId?: string;
        recordTypeId?: string;
        recordId: string;
    }) {
        const { companyId, module, periodId, subsidiaryId, recordTypeId, recordId } = params;
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }
        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }
        if (!periodId || !recordTypeId) {
            throw new Error(t("error.missingParams"));
        }

        return apiClient.request({
            url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}/subsidiaries/${subsidiaryId}/record-types/${recordTypeId}/records/${recordId}`,
            method: "DELETE",
        });
    },

    patchRecord(
        body: PatchRecordPayload,
        params: {
            companyId?: string;
            module: Module;
            periodId?: string;
            subsidiaryId?: string;
            recordTypeId?: string;
            recordId?: string;
        },
    ) {
        const { companyId, module, periodId, subsidiaryId, recordTypeId, recordId } = params;
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }
        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }
        if (!periodId || !recordTypeId || !recordId) {
            throw new Error(t("error.missingParams"));
        }

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}/subsidiaries/${subsidiaryId}/record-types/${recordTypeId}/records/${recordId}`,
            method: "PATCH",
            body,
        });
    },

    // List of individuals that can be invited as platform users
    getCompanyPeople(
        companyId: string | undefined,
        params: IGetEmployeesParams,
        noCache?: boolean,
    ): Promise<GetCompanyPeopleResponse> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/people`,
            queryParameters: params.options,
            headers: noCache
                ? {
                      "Cache-Control": "no-cache",
                  }
                : undefined,
        });
    },

    getCurrentEmployees(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        params: IGetEmployeesParams,
    ): Promise<GetCurrentEmployeesResponse> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/current`,
            queryParameters: params.options,
        });
    },

    getPreRegistrations(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        params: IGetEmployeesParams,
    ): Promise<GetEmployeePreRegistrationsResponse> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/pre-registrations`,
            queryParameters: params.options,
        });
    },

    getPastEmployees(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        params: IGetEmployeesParams,
    ): Promise<GetPastEmployeesResponse> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/past`,
            queryParameters: params.options,
        });
    },

    async postEmployee(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        employee: IEmployee,
    ): Promise<DetailedEmployeePreRegistration> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        const body = employeeToPayload(employee);

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/pre-registrations`,
            method: "POST",
            body,
        });
    },

    patchPreRegistration(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        preRegistration: Partial<IEmployee>,
    ): Promise<DetailedEmployeePreRegistration> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }
        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }
        if (!preRegistration.id) {
            throw new Error(t("error.missingParams"));
        }

        const body = employeeToPayload(preRegistration);

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/pre-registrations/${preRegistration.id}`,
            method: "PATCH",
            body,
        });
    },

    deletePreRegistration(companyId: string | undefined, subsidiaryId: string | undefined, preRegistrationId: string) {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }
        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        return apiClient.request({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/pre-registrations/${preRegistrationId}`,
            method: "DELETE",
        });
    },

    getEmployee(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        employeeId?: string,
    ): Promise<DetailedEmployee> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }
        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }
        if (!employeeId) {
            throw new Error(t("error.missingParams"));
        }

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/${employeeId}`,
        });
    },

    getPreRegistration(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        preRegistrationId?: string,
    ): Promise<DetailedEmployeePreRegistration> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }
        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }
        if (!preRegistrationId) {
            throw new Error(t("error.missingParams"));
        }

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/pre-registrations/${preRegistrationId}`,
        });
    },

    transferPreRegistration(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        preRegistration: IEmployee,
        directTransfer: PutEmployeePreRegistrationTransferPayload,
    ) {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        return apiClient.requestType<PutEmployeePreRegistrationTransferResponse>({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/pre-registrations/${preRegistration.id}/transfer`,
            method: "PUT",
            body: directTransfer,
        });
    },

    reregisterEmployee(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        employee: IEmployee,
    ): Promise<DetailedEmployeePreRegistration> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        const body = employeeToPayload(employee);

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/${employee.id}/re-register`,
            method: "POST",
            body,
        });
    },

    postEmployeeDataChange(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        employee: IEmployee,
    ): Promise<DetailedEmployeePreRegistration> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        const body = employeeToPayload(employee);

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/${employee.id}/data-change`,
            method: "POST",
            body,
        });
    },

    postEmployeeDeregistration(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        employee: IEmployee,
    ): Promise<DetailedEmployeePreRegistration> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        const body = employeeToPayload(employee);

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/${employee.id}/de-register`,
            method: "POST",
            body,
        });
    },

    postEmployeeDocument(p: {
        companyId: string | undefined;
        subsidiaryId: string | undefined;
        employeeId: string;
        file?: File;
        type: EmployeeDocumentType;
        staffCanRead: boolean;
        isPreRegistration?: boolean;
    }) {
        if (!p.companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!p.subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        if (!p.file) {
            debug.error("### postEmployeeDocument no file", p);
            throw new Error(t("error.missingDocument"));
        }

        const data = new FormData();
        data.append("file", p.file);
        data.append("name", sanitizeFilename(p.file.name));
        data.append("type", p.type);
        data.append("staffCanRead", p.staffCanRead ? "true" : "false");

        const target: IAPITarget = {
            url: p.isPreRegistration
                ? `/api/v1/companies/${p.companyId}/modules/hr/subsidiaries/${p.subsidiaryId}/pre-registrations/${p.employeeId}/documents`
                : `/api/v1/companies/${p.companyId}/modules/hr/subsidiaries/${p.subsidiaryId}/employees/${p.employeeId}/documents`,
            method: "POST",
            body: data,
        };

        return apiClient.uploadFormDataJSON<EmployeeDocument>(target);
    },

    postEmployeeDocumentsWebhook(p: {
        companyId: string;
        subsidiaryId: string;
        archiveDocuments: {
            archiveId: string;
            documentId: string;
            employeeId: string;
            name: string;
            type: EmployeeDocumentType;
            staffCanRead: boolean;
        }[];
        sendEmail: boolean;
    }) {
        if (!p.archiveDocuments || p.archiveDocuments.length === 0) {
            debug.error("### postEmployeeDocument - no documents", p);
            throw new Error(t("error.missingDocument"));
        }

        const body: EmployeeDocumentWebhook = {
            documents: p.archiveDocuments.map(d => ({
                archiveID: d.archiveId,
                documentID: d.documentId,
                employeeId: d.employeeId,
                name: d.name,
                type: d.type,
                staffCanRead: d.staffCanRead,
            })),
            sendEmail: p.sendEmail,
        };

        return apiClient.requestType({
            url: `/api/v1/companies/${p.companyId}/modules/hr/subsidiaries/${p.subsidiaryId}/employees/documents/webhook`,
            method: "POST",
            body,
        });
    },

    patchEmployeeDocument(p: {
        companyId: string | undefined;
        subsidiaryId: string | undefined;
        employeeId: string;
        documentId: string;
        isPreRegistration?: boolean;
        staffCanRead?: boolean;
    }): Promise<EmployeeDocumentDetails> {
        if (!p.companyId) {
            throw new Error(t("error.noCompanySelected"));
        }
        if (!p.subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        return apiClient.requestType({
            url: p.isPreRegistration
                ? `/api/v1/companies/${p.companyId}/modules/hr/subsidiaries/${p.subsidiaryId}/pre-registrations/${p.employeeId}/documents/${p.documentId}`
                : `/api/v1/companies/${p.companyId}/modules/hr/subsidiaries/${p.subsidiaryId}/employees/${p.employeeId}/documents/${p.documentId}`,
            method: "PATCH",
            body: {
                staffCanRead: p.staffCanRead,
            },
        });
    },

    postDocumentsWebhook(p: { companyId: string; body: GeneralDocumentWebhook }) {
        if (!p.body.documents || p.body.documents.length === 0) {
            debug.error("### postDocumentsWebhook - no documents", p);
            throw new Error(t("error.missingDocument"));
        }
        return apiClient.requestType({
            url: `/api/v1/companies/${p.companyId}/documents/webhook`,
            method: "POST",
            body: p.body,
        });
    },

    // Triggers immediate employee document transfer to BMD
    putEmployeeDocuments(companyId: string, module: Module, subsidiaryId: string, employeeId: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/modules/${module}/subsidiaries/${subsidiaryId}/employees/${employeeId}/documents`,
            method: "PUT",
        });
    },

    putDownloadEmployeeDocuments(
        companyId: string,
        module: Module,
        subsidiaryId: string,
        employeeId: string,
        isPreRegistration?: boolean,
        documentIds?: string[],
    ) {
        const endpoint = isPreRegistration ? "pre-registrations" : "employees";
        return apiClient.browserDownload({
            url: `/api/v1/companies/${companyId}/modules/${module}/subsidiaries/${subsidiaryId}/${endpoint}/${employeeId}/documents/download`,
            method: "PUT",
            body: documentIds,
        });
    },

    deleteEmployeeDocument(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        documentId: string,
        employeeId: string,
        isPreRegistration?: boolean,
    ) {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        return apiClient.request({
            url: isPreRegistration
                ? `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/pre-registrations/${employeeId}/documents/${documentId}`
                : `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/${employeeId}/documents/${documentId}`,
            method: "DELETE",
        });
    },

    getEmployeeDocuments(
        companyId: string | undefined,
        subsidiaryId: string | undefined,
        employeeId: string,
        isPreRegistration: boolean,
        params: IGetEmployeeDocumentsParams,
    ): Promise<GetEmployeeDocumentsResponse> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        const target = {
            url: isPreRegistration
                ? `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/pre-registrations/${employeeId}/documents`
                : `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/${employeeId}/documents`,
            queryParameters: params,
        };

        return apiClient.requestType(target);
    },

    getEmployeesDocuments(
        companyId: string,
        subsidiaryId: string,
        params: IGetEmployeeDocumentsParams,
    ): Promise<GetEmployeesDocumentsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/documents`,
            queryParameters: params,
        });
    },

    putDownloadEmployeesDocuments(companyId: string, subsidiaryId: string, documentIDs?: string[]) {
        const body: PutPrepareEmployeesDocumentsDownloadPayload = { documentIDs };
        return apiClient.browserDownload({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/employees/documents/download`,
            method: "PUT",
            body,
        });
    },

    getCompanies(
        params?: ITableParams & { toConfigure?: boolean; logoImage?: boolean; includeKnoedels?: boolean },
        noCache?: boolean,
    ) {
        const target: ITypedAPITarget<GetCompaniesResponse> = {
            url: "/api/v1/companies",
            queryParameters: params,
            headers: noCache ? { "Cache-Control": "no-cache" } : undefined,
        };

        // Use this to test empty company state
        // return { limit: 0, offset: 0, total: 0, companies: [] };

        return apiClient.requestType(target);
    },

    getCompany(companyId: string, noCache?: boolean, includeReportsExist?: boolean) {
        const target: ITypedAPITarget<Company> = {
            url: `/api/v1/companies/${companyId}`,
            headers: noCache ? { "Cache-Control": "no-cache" } : undefined,
            queryParameters: { includeReportsExist },
        };
        return apiClient.requestType(target);
    },

    postReport(params: {
        companyId: string;
        module: Module;
        periodId: string;
        subsidiaryId?: string;
        reportType?: ReportTypeEnum;
        file?: File;
        needsRelease: boolean;
        requiresQes?: boolean;
        qesPositions?: DocumentSignaturePosition[];
        comment?: string;
        sendEmail: boolean;
    }): Promise<Report> {
        const data = new FormData();
        const p = params;

        if (!p.file) {
            throw new Error(t("error.missingDocument"));
        }

        data.append("file", p.file);
        data.append("name", sanitizeFilename(p.file.name));
        data.append("needsRelease", p.needsRelease.toString());
        data.append("sendEmail", p.sendEmail.toString());
        data.append("requiresQes", p.requiresQes?.toString() ?? "false");
        if (p.qesPositions) {
            data.append("qesPositions", JSON.stringify(p.qesPositions));
        }
        if (p.comment) {
            data.append("comment", p.comment);
        }

        if (p.subsidiaryId) {
            data.append("subsidiaryID", p.subsidiaryId);
        }

        const target: IAPITarget = {
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/reports`,
            method: "POST",
            queryParameters: { reportType: params.reportType },
            body: data,
        };

        return apiClient.uploadFormDataJSON<Report>(target);
    },

    postReportsWebhook(params: {
        companyId: string;
        module: Module;
        periodId: string;
        subsidiaryId?: string;
        reportType: ReportTypeEnum;
        archiveDocuments: {
            archiveId: string;
            documentId: string;
            name: string;
            needsRelease: boolean;
            requiresQes: boolean;
            qesPositions?: DocumentSignaturePosition[];
        }[];
        sendEmail: boolean;
        comment: string;
    }): Promise<PostReportWebhookResponse> {
        const p = params;

        if (!p.companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        if (!p.archiveDocuments || p.archiveDocuments.length === 0) {
            throw new Error(t("error.missingDocument"));
        }

        const body: ReportWebhook = {
            documents: p.archiveDocuments.map<ReportWebhookDocuments>(d => ({
                archiveID: d.archiveId,
                documentID: d.documentId,
                name: d.name,
                needsRelease: d.needsRelease,
                requiresQes: d.requiresQes,
                reportType: p.reportType,
                qesPositions: d.qesPositions,
            })),
            sendEmail: p.sendEmail,
            comment: p.comment,
        };

        return apiClient.requestType({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/reports/webhook`,
            method: "POST",
            body,
            queryParameters: { subsidiaryId: p.subsidiaryId },
        });
    },

    getReports(p: {
        companyId: string;
        module: Module;
        periodId: string;
        subsidiaryId?: string;
        reportType?: ReportTypeEnum;
    }): Promise<GetReportsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/reports`,
            queryParameters: { subsidiaryId: p.subsidiaryId, reportType: p.reportType },
        });
    },

    getReport(p: {
        reportId: string;
        companyId: string;
        module: Module;
        periodId: string;
        subsidiaryId?: string;
        reportType?: ReportTypeEnum;
    }): Promise<ReportDetails> {
        return apiClient.requestType({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/reports/${p.reportId}`,
            queryParameters: { subsidiaryId: p.subsidiaryId, reportType: p.reportType },
        });
    },

    releaseReport(companyId: string, module: Module, periodId: string, reportId: string, subsidiaryId?: string) {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}/reports/${reportId}/release`,
            queryParameters: { subsidiaryId: subsidiaryId },
            method: "PUT",
        };
        return apiClient.request(target);
    },

    signReport(
        redirectUrl: string,
        companyId: string,
        module: Module,
        periodId: string,
        reportId: string,
        subsidiaryId?: string,
    ): Promise<ReportSignResponse> {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}/reports/${reportId}/sign`,
            queryParameters: { subsidiaryID: subsidiaryId, redirectURL: redirectUrl },
            method: "POST",
        };
        return apiClient.requestType(target);
    },

    completeSignReport(
        documentId: string,
        companyId: string,
        module: Module,
        periodId: string,
        reportId: string,
        subsidiaryId?: string,
        success?: boolean,
        error?: string,
    ) {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}/reports/${reportId}/sign/complete`,
            queryParameters: { subsidiaryID: subsidiaryId, documentId, success, error },
            method: "POST",
        };
        return apiClient.request(target);
    },

    releaseGeneralDocument(companyId: string, generalDocumentId: string) {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/documents/${generalDocumentId}/release`,
            method: "PUT",
        };
        return apiClient.request(target);
    },

    signGeneralDocument(
        redirectUrl: string,
        companyId: string,
        generalDocumentId?: string,
    ): Promise<ReportSignResponse> {
        if (!generalDocumentId) {
            throw new Error(t("error.missingParams"));
        }

        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/documents/${generalDocumentId}/sign`,
            queryParameters: { redirectURL: redirectUrl },
            method: "POST",
        };
        return apiClient.requestType(target);
    },

    completeSignGeneralDocument(companyId: string, generalDocumentId?: string) {
        if (!generalDocumentId) {
            throw new Error(t("error.missingParams"));
        }

        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/documents/${generalDocumentId}/sign/complete`,
            method: "POST",
        };
        return apiClient.request(target);
    },

    deleteReport(companyId: string, module: Module, periodId: string, reportId: string, subsidiaryId?: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}/reports/${reportId}`,
            queryParameters: { subsidiaryId: subsidiaryId },
            method: "DELETE",
        });
    },

    putDownloadReport(p: {
        companyId: string;
        module: Module;
        periodId: string;
        subsidiaryId?: string;
        reportIds?: string[];
    }) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/reports/download`,
            queryParameters: { subsidiaryID: p.subsidiaryId },
            body: p.reportIds ?? [],
            method: "PUT",
        });
    },

    deleteGeneralDocument(companyId: string, documentId: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/documents/${documentId}`,
            method: "DELETE",
        });
    },

    putCoverImage(companyId: string, file: File) {
        const target = APITarget.putCompanyImage(companyId, file, "cover");
        return apiClient.uploadFormDataJSON<Image>(target);
    },

    putLogoImage(companyId: string, file: File) {
        const target = APITarget.putCompanyImage(companyId, file, "logo");
        return apiClient.uploadFormDataJSON<Image>(target);
    },

    deleteCoverImage(companyId: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/images/cover`,
            method: "DELETE",
        });
    },

    deleteLogoImage(companyId: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/images/logo`,
            method: "DELETE",
        });
    },

    addCompanyToUserFavorites(companyId: string) {
        return apiClient.request({
            url: `/api/v1/user/favorites/companies/${companyId}`,
            method: "PUT",
        });
    },
    removeCompanyFromUserFavorites(companyId: string) {
        return apiClient.request({
            url: `/api/v1/user/favorites/companies/${companyId}`,
            method: "DELETE",
        });
    },

    putAvatarImage(file: File) {
        const data = new FormData();
        data.append("file", file);

        const target: ITypedAPITarget<Image> = {
            url: `/api/v1/user/avatar`,
            method: "PUT",
            body: data,
        };

        return apiClient.uploadFormDataJSON<Image>(target);
    },

    getFeedCountries(): Promise<GetFeedCountriesResponse> {
        return apiClient.requestType({ url: "/api/v1/feed/countries" });
    },

    async getNews(companyId: string, params?: { offset?: number; limit?: number }): Promise<GetNewsFeedResponse> {
        if (!params) {
            params = { offset: 0, limit: 10 };
        }
        const response: GetNewsFeedResponse = await apiClient.requestType({
            url: `/api/v1/companies/${companyId}/feed/news`,
            queryParameters: params,
        });

        const sanitizedNews =
            response.entries?.map(newsItem => ({
                ...newsItem,
                // Ignore categories like "News", "Newsblog", "Presseaussendungen". They should not appear
                // as categories in the news view.
                categories: newsItem.categories?.filter(category => !ignoredNewsCategories.includes(category)),
            })) ?? [];

        response.entries = sanitizedNews;
        return response;
    },

    async getPromotions(companyId: string): Promise<GetPromotionsFeedResponse> {
        const promotions: GetPromotionsFeedResponse = await apiClient.requestType({
            url: `/api/v1/companies/${companyId}/feed/promotions`,
        });
        const sanitizedPromotions = promotions.map(promotion => ({
            ...promotion,
            // Ignore categories like "News", "Newsblog", "Presseaussendungen". They should not appear
            // as categories in the news view.
            categories: promotion.categories?.filter(category => !ignoredNewsCategories.includes(category)),
        }));

        return sanitizedPromotions;
    },

    getEvents(companyId: string): Promise<GetEventsFeedResponse> {
        return apiClient.requestType({ url: `/api/v1/companies/${companyId}/feed/events` });
    },

    getPublications(companyId: string): Promise<GetPublicationsFeedResponse> {
        return apiClient.requestType({ url: `/api/v1/companies/${companyId}/feed/publications` });
    },

    getPublication({ companyID, publicationID, lang }: { companyID: string; publicationID: string; lang: string }) {
        return apiClient.request({
            url: `/api/v1/companies/${companyID}/feed/publications/${publicationID}`,
            queryParameters: {
                lang,
            },
        });
    },

    deletePromotion(companyId: string, promotionId: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/feed/promotions/${promotionId}`,
            method: "DELETE",
        });
    },

    postSubsidiary(p: {
        companyId: string;
        module: Module;
        name: string;
        type?: SubsidiaryType;
        typeId?: string;
        recordTypes: RecordType[];
    }): Promise<PostSubsidiaryResponse> {
        const body: PostSubsidiaryPayload = {
            name: p.name,
            type: p.type,
            typeId: p.typeId,
            recordTypes: p.recordTypes.map(recordType => {
                return {
                    id: recordType.id,
                    destinationID: recordType.destination?.id,
                    ocrEnabled: recordType.ocrEnabled,
                };
            }),
        };

        return apiClient.requestType({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/subsidiaries`,
            method: "POST",
            body,
        });
    },

    patchSubsidiary(p: {
        companyId: string;
        module: Module;
        subsidiaryId: string;
        name: string;
        type?: SubsidiaryType;
        typeId?: string;
        recordTypes: RecordType[];
    }) {
        const body: PatchSubsidiaryPayload = {
            name: p.name,
            type: p.type,
            typeId: p.typeId,
            recordTypes: p.recordTypes.map(recordType => {
                return {
                    id: recordType.id,
                    destinationID: recordType.destination?.id,
                    ocrEnabled: recordType.ocrEnabled,
                };
            }),
        };

        return apiClient.requestType({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/subsidiaries/${p.subsidiaryId}`,
            method: "PATCH",
            body,
        });
    },

    patchCompany(companyId: string, body: PatchCompanyPayload): Promise<ICompany> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}`,
            method: "PATCH",
            body,
        });
    },

    transferRecords(params: { companyId: string; module: Module; periodId: string; subsidiaryId: string }) {
        const p = params;
        return apiClient.request({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/subsidiaries/${p.subsidiaryId}/transfer`,
            method: "PUT",
        });
    },
    transferRecordTypeRecords(params: {
        companyId: string;
        module: Module;
        periodId: string;
        subsidiaryId: string;
        recordTypeID: string;
    }) {
        const p = params;
        return apiClient.request({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/subsidiaries/${p.subsidiaryId}/record-types/${p.recordTypeID}/records/transfer`,
            method: "PUT",
        });
    },

    deleteSubsidiary(companyId: string, module: Module, subsidiaryId: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/modules/${module}/subsidiaries/${subsidiaryId}`,
            method: "DELETE",
        });
    },

    getGlobalFeatureFlags(): Promise<GetFeatureFlagsResponse> {
        return apiClient.requestType({ url: "/api/v1/feature-flags" });
    },

    getCompanyFeatureFlags(companyId: string): Promise<GetFeatureFlagsResponse> {
        return apiClient.requestType({ url: `/api/v1/feature-flags`, queryParameters: { companyId } });
    },

    getRolePermissions(companyId: string, role: Role): Promise<PermissionsRequest> {
        return apiClient.requestType({ url: `/api/v1/companies/${companyId}/roles/${role}` });
    },

    // We use IPermissions alias, because the name Permissions is a basic typescript type
    async getCurrentUserPermissions(companyId: string): Promise<IPermissions> {
        const permissions: IPermissions = await apiClient.requestType({
            url: `/api/v1/companies/${companyId}/user/permissions`,
        });

        // Filter out "international-projects" role
        // permissions.roles = permissions.roles.filter(role => !ignoredRoles.includes(role));

        return permissions;
    },

    async getUserPermissions(companyId: string, userId: string): Promise<PermissionsWithChanges> {
        const response: PermissionsWithChanges = await apiClient.requestType({
            url: `/api/v1/companies/${companyId}/users/${userId}/permissions`,
        });

        // Filter out "staff" role, because we only need this role for the current logged in user
        // who gets permissions via getCurrentUserPermissions()
        response.roles = response.roles.filter(role => role !== "staff");

        // Filter out "international-projects" role
        // response.roles = response.roles.filter(role => !ignoredRoles.includes(role));

        return response;
    },

    putUserPermissions(companyId: string, userId: string, body: PermissionsRequest) {
        // CONNECT-245: Filter "staff" and "international-projects" roles
        body.roles = body.roles.filter((role: string) => role !== "staff" && role !== "international-projects");

        const target: ITypedAPITarget<PermissionsRequest> = {
            url: `/api/v1/companies/${companyId}/users/${userId}/permissions`,
            method: "PUT",
            body,
        };

        return apiClient.requestType(target);
    },

    releaseUserPermissions(companyId: string, userId: string) {
        const target: ITypedAPITarget<Permissions> = {
            url: `/api/v1/companies/${companyId}/users/${userId}/permissions/release`,
            method: "PUT",
        };

        return apiClient.requestType(target);
    },

    rejectUserPermissions(companyId: string, userId: string) {
        const target: ITypedAPITarget<Permissions> = {
            url: `/api/v1/companies/${companyId}/users/${userId}/permissions/reject`,
            method: "PUT",
        };

        return apiClient.requestType(target);
    },

    getCompaniesForPerson(companyId: string, query: { personID: string } | { userID: string }) {
        const target: ITypedAPITarget<GetCompaniesForPersonResponse> = {
            url: `/api/v1/companies/${companyId}/companies-for-person`,
            method: "GET",
            queryParameters: query,
        };
        return apiClient.requestType(target);
    },

    inviteUser(companyId: string, userId: string) {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/users/${userId}/invitation`,
            method: "PUT",
        };

        return apiClient.request(target);
    },

    deleteUserFromCompany(companyId: string, userId: string) {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/users/${userId}`,
            method: "DELETE",
        };

        return apiClient.request(target);
    },

    deleteUser() {
        const target: IAPITarget = {
            url: `/api/v1/user/delete`,
            method: "DELETE",
        };

        return apiClient.request(target);
    },

    getUserCompanies(userId: string) {
        const target: ITypedAPITarget<GetUserCompaniesResponse> = {
            url: `/api/v1/user/${userId}/companies`,
            method: "GET",
        };

        return apiClient.requestType(target);
    },

    putChangeUsernameEmail(companyId: string, userId: string) {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/users/${userId}/email`,
            method: "PUT",
            body: { emailType: "usernameChangeConfirmation" },
        };

        return apiClient.request(target);
    },

    postChangeUsernameConfirm(token: string, newUsername: string) {
        const target: IAPITarget = {
            url: "/api/v1/auth/change-username/complete",
            method: "POST",
            body: { token, newUsername },
        };

        return apiClient.request(target);
    },

    getInvitationResults(companyId: string) {
        const target: ITypedAPITarget<PermissionsInvitationResultResponse> = {
            url: `/api/v1/companies/${companyId}/users/invitationresults`,
        };

        return apiClient.requestType(target);
    },

    putInvitationResultsRead(companyId: string, body: PermissionsInvitationRead) {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/users/invitationresults/read`,
            body,
            method: "PUT",
        };

        return apiClient.request(target);
    },

    postUser(companyId: string, body: PostUserPayload) {
        const target: ITypedAPITarget<PostUserResponse> = {
            url: `/api/v1/companies/${companyId}/users`,
            method: "POST",
            body,
        };

        return apiClient.requestType(target);
    },

    getArchiveDocument(archiveId?: string, documentIds?: string[], databoxIds?: string[], generateThumbnails = false) {
        const target: ITypedAPITarget<GetDocumentsResponse> = {
            url: `/api/v1/documents`,
            queryParameters: {
                archiveID: archiveId,
                documentID: documentIds,
                uploadID: databoxIds,
                generateThumbnails,
            },
        };

        return apiClient.requestType(target);
    },

    downloadExternalDocuments(archiveId: string, documentIds: string[]): Promise<Download> {
        const target: ITypedAPITarget<Download> = {
            url: `/api/v1/documents/external/download`,
            method: "PUT",
            body: {
                archiveID: archiveId,
                documentIDs: documentIds,
            },
        };

        return apiClient.requestType(target);
    },

    getBranchNames(companyId: string, module: Module): Promise<GetBranchNamesResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/${module}/branch-names`,
        });
    },

    postForgotPassword(email: string) {
        return apiClient.request({
            url: "api/v1/auth/forgot-password",
            method: "POST",
            body: {
                username: email,
            },
        });
    },

    async getUsers(
        companyId: string,
        options: ITableParams & { permissions?: "all" | "toRelease" | "noChanges" },
        noCache?: boolean,
    ): Promise<GetCompanyUsersResponse> {
        if (!options.permissions) {
            options.permissions = "all";
        }

        const response: GetCompanyUsersResponse = await apiClient.requestType({
            url: `/api/v1/companies/${companyId}/users`,
            queryParameters: options,
            headers: noCache ? { "Cache-Control": "no-cache" } : undefined,
        });

        // Filter out "staff" role, because we only need this role for the current logged in user
        // who gets permissions via getCurrentUserPermissions()
        response.users = response.users?.map(user => {
            user.permissions.roles = user.permissions.roles.filter(
                role => role !== "staff" /*&& !ignoredRoles.includes(role)*/,
            );
            return user;
        });

        return response;
    },

    async getCompaniesUsersToRelease() {
        const response: GetCompaniesUsersToReleaseResponse = await apiClient.requestType({
            url: "/api/v1/companies/users/to-release",
        });

        // Filter out "staff" role, because we only need this role for the current logged in user
        // who gets permissions via getCurrentUserPermissions()
        response.companies.forEach(company => {
            company.users.forEach(user => {
                user.permissions.roles = user.permissions.roles.filter(
                    role => role !== "staff" /*&& !ignoredRoles.includes(role)*/,
                );
                return user;
            });
        });

        return response;
    },

    postMSLogin(code: string, state: string): Promise<PostLoginResponse> {
        return apiClient.requestType({
            url: "/api/v1/auth/login-with-microsoft",
            method: "POST",
            body: {
                code,
                state,
            },
        });
    },

    postChangePassword(password: string, token: string): Promise<PostLocalLoginResponse> {
        return apiClient.requestType({
            url: "/api/v1/auth/forgot-password/complete",
            method: "POST",
            body: {
                password,
                token,
            },
        });
    },

    postCompleteRegistration(password: string, token: string): Promise<PostLocalLoginResponse> {
        return apiClient.requestType({
            url: "/api/v1/auth/invite/complete",
            method: "POST",
            body: {
                password,
                token,
            },
        });
    },

    postCompleteRegistrationMfa(body: PostForgotPasswordCompleteMfaPayload): Promise<PostLoginResponse> {
        return apiClient.requestType({
            url: `/api/v1/auth/invite/complete/mfa`,
            method: "POST",
            body,
        });
    },

    postCompleteForgotPasswordMfa(body: PostForgotPasswordCompleteMfaPayload): Promise<PostLoginResponse> {
        return apiClient.requestType({
            url: `/api/v1/auth/forgot-password/complete/mfa`,
            method: "POST",
            body,
        });
    },

    getInviteTokenValid(inviteToken: string) {
        return apiClient.request({
            url: `/api/v1/auth/invite/valid`,
            queryParameters: { inviteToken },
        });
    },

    getMSInvite({
        inviteOriginCompanyId,
        inviteToken,
        international,
    }: {
        inviteOriginCompanyId: string;
        inviteToken?: string;
        international?: boolean;
    }): Promise<GetAuthMicrosoftInviteResponse> {
        return apiClient.requestType({
            url: "/api/v1/auth/microsoft-invite",
            queryParameters: { inviteOriginCompanyId, inviteToken, international },
        });
    },

    postMSRefresh(countryCode: string): Promise<PostMicrosoftRefreshResponse> {
        return apiClient.requestType({
            method: "POST",
            url: "/api/v1/auth/microsoft-refresh",
            body: { countryCode },
        });
    },

    getPendingMicrosoftActions: () => {
        return apiClient.requestType<GetPendingMicrosoftActionsResponse>({
            url: "/api/v1/auth/microsoft/pending-actions",
        });
    },

    getUserNotifications(companyId: string): Promise<UserNotifications> {
        return apiClient.requestType({ url: `/api/v1/companies/${companyId}/user/notifications` });
    },

    patchUserNotifications(companyId: string, body: Partial<UserNotifications>): Promise<UserNotifications> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/user/notifications`,
            method: "PATCH",
            body,
        });
    },

    patchUserSettings(body: PatchUserSettingsPayload): Promise<GetUserInfoResponse> {
        return apiClient.requestType({
            url: `/api/v1/user/settings`,
            method: "PATCH",
            body,
        });
    },

    changeMfaSetting(state: "enable" | "disable"): Promise<PostUserSettingsMfaResponse> {
        const body = state === "enable" ? { newState: true } : { newState: false };
        return apiClient.requestType({
            url: `/api/v1/user/settings/mfa`,
            method: "POST",
            body: body,
        });
    },

    completeMfaSetting(body: PostUserSettingsMfaCompletePayload) {
        return apiClient.request({
            url: `/api/v1/user/settings/mfa/complete`,
            method: "POST",
            body,
        });
    },

    getUserCompanySettings(companyId: string): Promise<UserCompanySettings> {
        return apiClient.requestType({ url: `/api/v1/companies/${companyId}/user/settings` });
    },

    patchUserCompanySettings(companyId: string, body: PatchUserCompanySettingsPayload): Promise<UserCompanySettings> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/user/settings`,
            method: "PATCH",
            body,
        });
    },

    getCostCenters(companyId: string, module: Module, subsidiaryId: string): Promise<GetCostCentersFullResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/${module}/subsidiaries/${subsidiaryId}/cost-centers-full`,
        });
    },

    getFunders(companyId: string, module: Module, subsidiaryId: string): Promise<GetFundersFullResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/${module}/subsidiaries/${subsidiaryId}/funders-full`,
        });
    },

    getAccounts(companyId: string, module: Module, subsidiaryId: string): Promise<GetAccountsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/${module}/subsidiaries/${subsidiaryId}/accounts`,
        });
    },

    postRecords(params: {
        file: File;
        companyId: string;
        module: Module;
        periodId: string;
        subsidiaryId: string;
        recordTypeId: string;
    }): Promise<PostRecordResponse> {
        const data = new FormData();
        const p = params;
        data.append("file", p.file);
        data.append("name", sanitizeFilename(p.file.name));

        return apiClient.uploadFormDataJSON({
            url: `/api/v1/companies/${p.companyId}/modules/${p.module}/periods/${p.periodId}/subsidiaries/${p.subsidiaryId}/record-types/${p.recordTypeId}/records`,
            method: "POST",
            body: data,
        });
    },

    putActivateCompany(companyId: string, body?: PutActivateCompanyPayload): Promise<Company> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/activate`,
            method: "PUT",
            body,
        });
    },

    getResponsibleUsers(companyId: string, includeKnoedels?: boolean): Promise<GetResponsibleUsersResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/responsible-users`,
            queryParameters: { includeKnoedels },
        });
    },

    getMainResponsibleUsers(companyId: string): Promise<GetMainResponsibleUsersResponse> {
        return apiClient.requestType({ url: `/api/v1/companies/${companyId}/main-responsible-users` });
    },

    getProjectsToConfigure(
        companyId: string,
        params?: ITableParams,
        noCache?: boolean,
    ): Promise<GetProjectsToConfigureResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/to-configure`,
            queryParameters: params,
            headers: noCache
                ? {
                      "Cache-Control": "no-cache",
                  }
                : undefined,
        });
    },

    getProjectToConfigure(companyId: string, externalProjectId: string): Promise<ProjectConfiguration> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/to-configure/${externalProjectId}`,
        });
    },

    getProjects(
        companyId: string,
        params?: ITableParams & { status?: ProjectStatusEnum },
    ): Promise<GetProjectsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects`,
            queryParameters: {
                status: "active",
                ...params,
            },
        });
    },

    async getCompaniesProjects(queryParameters: {
        includeInternational: boolean;
        limit: number;
        cursor: string | undefined;
        search: string | undefined;
    }) {
        return apiClient.requestType<GetCompaniesProjectsResponse>({
            url: `/api/v1/companies/projects`,
            queryParameters,
        });
    },

    async getCompaniesProjectsOwner(queryParameters: {
        limit: number;
        cursor: string | undefined;
        search: string | undefined;
    }) {
        return apiClient.requestType<GetCompaniesProjectsOwnerResponse>({
            url: `/api/v1/companies/projects/owner`,
            queryParameters,
        });
    },

    getUsersForProjects({
        companyId,
        ...tableParams
    }: ITableParams & { companyId: string }): Promise<GetProjectUsersResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/users`,
            queryParameters: tableParams,
        });
    },

    getProjectsCompanies() {
        return apiClient.requestType<GetProjectsCompaniesResponse>({
            url: "/api/v1/projects/companies",
        });
    },

    getProject(companyId: string, projectId: string): Promise<Project> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}`,
        });
    },

    postProject(companyId: string, body: PostProjectPayload) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/projects`,
            method: "POST",
            body,
        });
    },

    putProject(companyId: string, projectId: string, body: Partial<Project>) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/projects/${projectId}`,
            method: "PUT",
            body,
        });
    },

    deleteProject(companyId: string, projectId: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/projects/${projectId}`,
            method: "DELETE",
        });
    },

    restoreProject(companyId: string, projectId: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/restore`,
            method: "PUT",
        });
    },

    putProjectFinished(companyId: string, projectId: string, archiveId?: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/close`,
            method: "PUT",
            body: {
                archiveId,
            },
        });
    },

    postProjectFolder(
        companyId: string,
        projectId: string,
        projectItemId: string | undefined,
        body: CreateFolderPayload,
    ): Promise<ProjectItem> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}${
                projectItemId ? `/items/${projectItemId}` : ""
            }/folders`,
            method: "POST",
            body,
        });
    },

    postProjectItem({
        file,
        companyId,
        projectId,
        projectItemId,
    }: {
        file: File;
        companyId: string;
        projectId: string;
        projectItemId?: string;
    }) {
        const data = new FormData();
        data.append("file", file);
        data.append("name", sanitizeFilename(file.name));

        return apiClient.uploadFormData({
            url: `/api/v1/companies/${companyId}/projects/${projectId}${
                projectItemId ? `/items/${projectItemId}` : ""
            }/upload`,
            method: "POST",
            body: data,
        });
    },

    postProjectUploadSession({
        companyId,
        projectId,
        file,
        projectItemId,
    }: {
        companyId: string;
        projectId: string;
        file: File;
        projectItemId?: string;
    }): Promise<PostProjectUploadSessionResponse> {
        return apiClient.requestType<PostProjectUploadSessionResponse>({
            url: `/api/v1/companies/${companyId}/projects/${projectId}${
                projectItemId ? `/items/${projectItemId}` : ""
            }/upload/session`,
            method: "POST",
            body: {
                fileName: sanitizeFilename(file.name),
                fileSize: file.size,
            },
        });
    },

    putProjectUploadSessionItem({
        url,
        contentLength,
        contentRange,
        body,
    }: {
        url: string;
        contentLength: number;
        contentRange: string;
        body: Blob;
    }) {
        // Using fetch here because as the url is external and the apiClient adds unnecessary headers and attaches the base url
        return fetch(url, {
            method: "PUT",
            headers: {
                "Content-Length": `${contentLength}`,
                "Content-Range": contentRange,
            },
            body,
        });
    },

    async postProjectUploadSessionComplete({
        companyId,
        projectId,
        projectItemId,
    }: {
        companyId: string;
        projectId: string;
        projectItemId: string;
    }): Promise<void> {
        await apiClient.request({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/items/${projectItemId}/upload/session/complete`,
            method: "POST",
        });
    },

    deleteProjectItem(companyId: string, projectId: string, projectItemId?: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/projects/${projectId}${
                projectItemId ? `/items/${projectItemId}` : ""
            }`,
            method: "DELETE",
        });
    },

    patchProjectItem(companyId: string, projectId: string, projectItemId: string, body: RenameItemPayload) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/items/${projectItemId}`,
            method: "PATCH",
            body,
        });
    },

    getProjectItems(
        companyId: string,
        projectId: string,
        projectItemId?: string,
        options?: ITableParams,
    ): Promise<ProjectItemSlice> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/items${
                projectItemId ? `/${projectItemId}/items` : ""
            }`,
            queryParameters: options,
        });
    },

    getProjectItemPermissions(
        companyId: string,
        projectId: string,
        projectItemId?: string,
    ): Promise<ProjectItemPermissionSlice> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}${
                projectItemId ? `/items/${projectItemId}` : ""
            }/permissions`,
        });
    },

    getProjectItemDrafts(
        companyId: string,
        projectId: string,
        projectItemId?: string,
        options?: ITableParams,
    ): Promise<GetDraftsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}${
                projectItemId ? `/items/${projectItemId}` : ""
            }/drafts`,
            queryParameters: options,
        });
    },

    getProjectItemDraft(
        companyId: string,
        projectId: string,
        projectItemId: string,
        projectItemDraftId: string,
    ): Promise<Draft> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/items/${projectItemId}/drafts/${projectItemDraftId}`,
            method: "GET",
        });
    },

    postProjectItemDraft(companyId: string, projectId: string, body: DraftRequest): Promise<Draft> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/drafts`,
            body,
            method: "POST",
        });
    },

    putProjectItemDraft(companyId: string, projectId: string, body: DraftRequest): Promise<Draft> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/drafts`,
            body,
            method: "PUT",
        });
    },

    putProjectItemDraftAction(
        companyId: string,
        projectId: string,
        projectItemId: string,
        draftId: string,
        action: "reject" | "release" | "cancel",
    ) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/drafts/${action}`,
            method: "POST",
            queryParameters: { draftId, itemId: projectItemId },
        });
    },

    signDraft(
        redirectUrl: string,
        companyId: string,
        projectId: string,
        projectItemId: string,
        draftId: string,
    ): Promise<DraftSignResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/items/${projectItemId}/drafts/${draftId}/sign`,
            method: "POST",
            queryParameters: { redirectURL: redirectUrl },
        });
    },

    completeSignDraft(
        documentId: string,
        companyId: string,
        projectId: string,
        projectItemId: string,
        draftId: string,
        error?: string,
        success?: boolean,
    ) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/items/${projectItemId}/drafts/${draftId}/sign/complete`,
            method: "POST",
            queryParameters: { documentId, error, success },
        });
    },

    getProjectItemDraftPermittedCustomers(
        companyId: string,
        projectId: string,
        projectItemIds?: string[],
        limit = 0,
    ): Promise<GetProjectUsersResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/items/permitted-customers`,
            queryParameters: { itemId: projectItemIds, limit },
        });
    },

    putDownloadProject(companyId: string, projectId: string, itemIds?: string[]) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/download`,
            method: "PUT",
            body: itemIds,
        });
    },

    putDownloadProjectUrl(companyId: string, projectId: string, itemIds?: string[]) {
        return apiClient.requestType<Download>({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/download`,
            method: "PUT",
            body: itemIds,
        });
    },

    async getBookmarkUrl(companyId: string, projectId: string, itemId: string): Promise<string | undefined> {
        const target: ITypedAPITarget<Download> = {
            url: `/api/v1/companies/${companyId}/projects/${projectId}/download`,
            method: "PUT",
            body: [itemId],
        };

        const link = await apiClient.requestType(target);

        const downloadLink = new URL(link.downloadUrl);

        const file = await apiClient.request({
            url: `${downloadLink.pathname}${downloadLink.search}`,
            method: "GET",
        });

        const iniBodyFromFile = await file.text();
        const iniObject = parse(iniBodyFromFile);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const iniObjectUrl = iniObject?.InternetShortcut?.URL as string | undefined;

        return iniObjectUrl;
    },

    putDownloadProjectDraft(companyId: string, projectId: string, draftIds?: string[]) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${companyId}/projects/${projectId}/drafts/download`,
            method: "PUT",
            body: draftIds,
        });
    },

    getBadgeCounts(companyId: string): Promise<Knoedels> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/knödels`,
        });
    },

    getOptionalMenuItems(companyId: string): Promise<CustomMenuItem[]> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/custom-menu-items`,
        });
    },

    putOptionalMenuItems(companyId: string, items: CustomMenuItem[]) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/custom-menu-items`,
            method: "PUT",
            body: items,
        });
    },

    getTickets(
        companyId: string,
        params?: ITableParams & {
            startDate?: string;
            endDate?: string;
            module?: "all" | "projects" | Module;
            scope?: CompanyDocumentScope;
            subsidiaryId?: string;
            relation?: TicketRelation;
        },
    ): Promise<GetTicketsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/tickets`,
            queryParameters: params,
        });
    },

    getPreRegistrationTickets({
        companyId,
        subsidiaryId,
        ...tableParams
    }: ITableParams & { companyId: string; subsidiaryId: string }): Promise<GetTicketsResponse> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }
        if (!subsidiaryId) {
            throw new Error(t("error.hr.noSubsidiarySelected"));
        }

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/pre-registrations/tickets`,
            queryParameters: tableParams,
        });
    },

    getTicketDetails(companyId: string, ticketId: string): Promise<TicketDetails> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/tickets/${ticketId}`,
        });
    },

    putDownloadTicketAttachment(p: { companyId: string; ticketId: string; attachmentIds?: string[] }) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${p.companyId}/tickets/${p.ticketId}/attachments/download`,
            body: p.attachmentIds ?? [],
            method: "PUT",
        });
    },

    getTicketsGrouped(companyId: string): Promise<GetTicketCountGroupedResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/tickets/grouped`,
        });
    },

    getTermsOfUseDocuments(companyId: string): Promise<GetTermsOfUseResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/terms-of-use`,
        });
    },

    getTermsOfUseSignees(
        companyId: string,
        queryParameters?: { companyTermsId?: string },
    ): Promise<GetTermsOfUseSigneesResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/terms-of-use/signees`,
            queryParameters,
        });
    },

    putTermsOfUseSignees(
        companyId: string,
        body: PutTermsOfUseSigneesPayload,
        queryParameters?: { companyTermsId?: string },
    ) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/terms-of-use/signees`,
            method: "PUT",
            body,
            queryParameters,
        });
    },

    putBulkTermsOfUseSignees(body: PutBulkTermsOfUseSigneesPayload) {
        return apiClient.request({
            url: `/api/v1/companies/bulk/terms-of-use/signees`,
            method: "PUT",
            body,
        });
    },

    putTermsOfUseResendEmail(companyId: string, signeeId: string) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/terms-of-use/signees/${signeeId}/resend-email`,
            method: "PUT",
        });
    },

    putDownloadTermsOfUseDocument(token: string) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/terms-of-use/download`,
            method: "PUT",
            queryParameters: { token },
        });
    },

    putTermsOfUseSign(token: string) {
        return apiClient.request({
            url: `/api/v1/companies/terms-of-use/sign`,
            method: "PUT",
            queryParameters: { token },
        });
    },

    putReceiveTermsOfUseDownloadLink(token: string): Promise<TermsOfUseDownload> {
        return apiClient.requestType({
            url: `/api/v1/companies/terms-of-use/download`,
            method: "PUT",
            queryParameters: { token },
        });
    },

    getRecordTypeTickets(
        params: ITableParams & {
            companyId: string;
            module: Module;
            periodId: string;
            subsidiaryId: string;
            recordTypeId: string;
        },
    ): Promise<GetTicketsResponse> {
        const { companyId, module, periodId, subsidiaryId, recordTypeId, ...tableParams } = params;
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}/subsidiaries/${subsidiaryId}/record-types/${recordTypeId}/tickets`,
            queryParameters: tableParams,
        });
    },

    getRecordTicket({
        companyId,
        module,
        periodId,
        subsidiaryId,
        recordTypeId,
        recordId,
    }: {
        companyId: string;
        module: Module;
        periodId: string;
        subsidiaryId: string;
        recordTypeId: string;
        recordId: string;
    }) {
        return apiClient.requestType<Ticket>({
            url: `/api/v1/companies/${companyId}/modules/${module}/periods/${periodId}/subsidiaries/${subsidiaryId}/record-types/${recordTypeId}/records/${recordId}/tickets`,
        });
    },

    postTicket(companyId: string, payload: PostTicketPayload): Promise<Ticket> {
        const body = { ...payload };
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/tickets`,
            method: "POST",
            body,
        });
    },

    putCloseTickets(companyId: string, ticketIds: string[]) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/tickets/close`,
            method: "PUT",
            body: {
                ticketIds,
            },
        });
    },

    getTicketMessages(companyId: string, ticketId: string, params?: ITableParams): Promise<GetTicketMessagesResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/tickets/${ticketId}/messages`,
            queryParameters: params ? { offset: params.offset, limit: params.limit } : undefined,
        });
    },

    postTicketMessage(companyId: string, ticketId: string, message: { file?: File; text?: string }) {
        const data = new FormData();
        if (message.text) {
            data.append("text", message.text);
        }
        if (message.file) {
            data.append("file", message.file);
            data.append("name", sanitizeFilename(message.file.name));
        }

        return apiClient.uploadFormDataJSON<Message>({
            url: `/api/v1/companies/${companyId}/tickets/${ticketId}/messages`,
            method: "POST",
            body: data,
        });
    },

    searchChatMessages({
        companyId,
        params,
        colleagueId,
        contextCompanyIds,
    }: {
        companyId: string;
        params?: ITableParams;
        colleagueId?: string;
        contextCompanyIds?: string[];
    }): Promise<GetChatMessagesResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/chat-users/messages`,
            queryParameters: {
                ...params,
                substituteId: colleagueId,
                contextCompanyIDs: contextCompanyIds,
            },
        });
    },

    putDownloadChatAttachment(p: {
        companyId: string;
        userId: string;
        substituteId?: string;
        attachmentIds?: string[];
    }) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${p.companyId}/chat-users/${p.userId}/attachments/download`,
            queryParameters: { substituteId: p.substituteId },
            body: p.attachmentIds ?? [],
            method: "PUT",
        });
    },

    getChatMessages({
        companyId,
        userId,
        params,
        colleagueId,
    }: {
        companyId: string;
        userId: string;
        params?: ITableParams;
        colleagueId?: string;
    }): Promise<GetChatMessagesResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/chat-users/${userId}/messages`,
            queryParameters: {
                ...(params ? { offset: params.offset, limit: params.limit } : {}),
                substituteId: colleagueId,
            },
        });
    },

    getPreviousChatMessages({
        companyId,
        userId,
        messageId,
        params,
        colleagueId,
    }: {
        companyId: string;
        userId: string;
        messageId: string;
        params?: ITableParams;
        colleagueId?: string;
    }): Promise<GetChatMessagesResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/chat-users/${userId}/messages/${messageId}/previous`,
            queryParameters: {
                ...(params ? { limit: params.limit } : {}),
                substituteId: colleagueId,
            },
        });
    },

    postChatMessage({
        companyId,
        userId,
        message,
        colleagueId,
    }: {
        companyId: string;
        userId: string;
        message: {
            file?: File;
            text?: string;
        };
        colleagueId?: string;
    }) {
        const data = new FormData();

        if (message.text) {
            data.append("text", message.text);
        }
        if (message.file) {
            data.append("file", message.file);
            data.append("name", sanitizeFilename(message.file.name));
        }

        return apiClient.uploadFormDataJSON<Message>({
            url: `/api/v1/companies/${companyId}/chat-users/${userId}/messages`,
            method: "POST",
            body: data,
            queryParameters: { substituteId: colleagueId },
        });
    },

    getChatUsers({
        companyId,
        role,
        colleagueId,
    }: {
        companyId: string;
        role?: Role;
        colleagueId?: string;
    }): Promise<GetChatUsersResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/chat-users`,
            queryParameters: { role, substituteId: colleagueId },
        });
    },

    getErstwhileChatUsers({
        companyId,
        userType,
    }: {
        companyId: string;
        userType?: "tpa" | "customer" | "all"; // defaults to tpa on the backend
    }): Promise<GetChatUsersResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/erstwhile-chat-users`,
            queryParameters: { userType },
        });
    },

    putArchiveChatMessages({
        substituteId,
        downloadPDF,
        payload,
        companyId,
        userId,
    }: {
        substituteId?: string;
        downloadPDF: boolean;
        payload: PutArchiveChatMessagesPayload;
        companyId: string;
        userId: string;
    }) {
        return apiClient.request({
            url: `/api/v1/companies/${companyId}/chat-users/${userId}/messages/archive`,
            method: "PUT",
            body: payload,
            queryParameters: substituteId ? { substituteId, downloadPDF } : { downloadPDF },
        });
    },

    patchChat(companyId: string, userId: string, chat: { isConfidential: boolean }): Promise<Chat> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/chat-users/${userId}`,
            method: "PATCH",
            body: chat,
        });
    },

    getChatColleagues(companyId: string): Promise<GetChatUsersResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/substitute-users`,
        });
    },

    getAccountingDocumentDestinations(companyId: string): Promise<GetAccountingDocumentDestinationsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/modules/accounting/document-destinations`,
        });
    },

    getArchives(companyId: string): Promise<GetDocumentDestinationsResponse> {
        return apiClient.requestType({ url: `/api/v1/companies/${companyId}/document-destinations` });
    },

    getExternalPreRegistration(preRegistrationIds: string[]): Promise<ExternalEmployeePreRegistration[]> {
        return apiClient.requestType({
            url: `/api/v1/external/pre-registrations`,
            queryParameters: { id: preRegistrationIds },
        });
    },

    postBugs(body: PostBugsPayload): Promise<PostBugsResponse> {
        return apiClient.requestType({
            url: `api/v1/bugs`,
            method: "POST",
            body,
        });
    },

    getPersonnelFile(companyId: string): Promise<DetailedEmployee> {
        return apiClient.requestType({ url: `api/v1/companies/${companyId}/user/employee-details` });
    },

    getStaffDocuments(
        companyId: string | undefined,
        params: IGetEmployeeDocumentsParams,
    ): Promise<GetEmployeeDocumentsResponse> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        const target = {
            url: `/api/v1/companies/${companyId}/user/employee-details/documents`,
            queryParameters: params,
        };

        return apiClient.requestType(target);
    },

    uploadEmployeePayslip(props: {
        companyId: string;
        subsidiaryId: string;
        employeeId: string;
        file: File;
        sendEmail: boolean;
        year: number;
        month: number;
        payslipType: PaySlipType;
    }) {
        const body = new FormData();
        body.append("file", props.file);
        body.append("name", sanitizeFilename(props.file.name));
        body.append("sendEmail", props.sendEmail.toString());
        body.append("year", props.year.toString());
        body.append("month", props.month.toString());
        body.append("payslipType", props.payslipType);

        const target: IAPITarget = {
            url: `/api/v1/companies/${props.companyId}/modules/hr/subsidiaries/${props.subsidiaryId}/employees/${props.employeeId}/pay-slips`,
            body: body,
            method: "POST",
        };
        return apiClient.uploadFormData(target);
    },

    releaseEmployeeDocument(companyId: string, employeeDocumentId: string) {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/user/employee-details/documents/${employeeDocumentId}/release`,
            method: "PUT",
        };
        return apiClient.request(target);
    },

    signEmployeeDocument(
        redirectUrl: string,
        companyId: string,
        employeeDocumentId: string,
    ): Promise<EmployeeDocumentSignResponse> {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/user/employee-details/documents/${employeeDocumentId}/sign`,
            queryParameters: { redirectURL: redirectUrl },
            method: "POST",
        };
        return apiClient.requestType(target);
    },

    completeSignEmployeeDocument(companyId: string, employeeDocumentId: string) {
        const target: IAPITarget = {
            url: `/api/v1/companies/${companyId}/user/employee-details/documents/${employeeDocumentId}/sign/complete`,
            method: "POST",
        };
        return apiClient.request(target);
    },

    getKpis(companyId: string, queryParameters: { months: number }) {
        return apiClient.requestType<GetCompanyKPIsResponse>({
            url: `api/v1/companies/${companyId}/kpis`,
            queryParameters,
        });
    },

    getTaxAccount(companyId: string) {
        return apiClient.requestType<GetTaxAccountResponse>({
            url: `api/v1/companies/${companyId}/tax-account`,
        });
    },

    postTaxAccountPayment(companyId: string) {
        return apiClient.requestType<PostTaxAccountInitPaymentResponse>({
            url: `api/v1/companies/${companyId}/tax-account/init-payment`,
            method: "POST",
        });
    },

    getUnpaidAccounts(companyId: string) {
        return apiClient.requestType<GetUnpaidAccountsResponse>({
            url: `api/v1/companies/${companyId}/unpaid-accounts`,
        });
    },

    postUnpaidAccountPayment(
        companyId: string,
        tpaCompanyId: string,
        financialAccountancyId: string,
        bookingId: string,
    ) {
        return apiClient.requestType<PostUnpaidAccountInitPaymentResponse>({
            url: `api/v1/companies/${companyId}/unpaid-accounts/${tpaCompanyId}/${financialAccountancyId}/${bookingId}/init-payment`,
            method: "POST",
        });
    },

    getKPIConfiguration(companyId: string) {
        return apiClient.requestType<GetCompanyKPIConfigurationsResponse>({
            url: `api/v1/companies/${companyId}/kpis/configurations`,
        });
    },
    putKPIConfiguration(companyId: string, body: PutCompanyKPIConfigurationsPayload) {
        return apiClient.request({
            url: `api/v1/companies/${companyId}/kpis/configurations`,
            method: "PUT",
            body,
        });
    },

    getPowerBIReports(companyId: string): Promise<GetPowerBIReportsResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${companyId}/powerbi/reports`,
        });
    },
    postPowerBIReportEmbedToken(
        companyId: string,
        workspaceId: string,
        reportId: string,
    ): Promise<PostPowerBIReportEmbedTokenResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${companyId}/powerbi/reports/${workspaceId}/${reportId}/embed-token`,
            method: "POST",
        });
    },

    getFinancialAccountancies(params: { companyId: string }): Promise<GetFinancialAccountanciesResponse> {
        return apiClient.requestType({ url: `api/v1/companies/${params.companyId}/results/financial-accountancies` });
    },

    getAccountListing(params: {
        companyId: string;
        financialAccountancyId: string;
        query: AccountListingQuery;
    }): Promise<GetAccountListingResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/account-listing`,
            queryParameters: params.query,
        });
    },
    getCustomerListing(params: {
        companyId: string;
        financialAccountancyId: string;
        query: CustomerListingQuery;
    }): Promise<GetCustomerListingResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/customer-listing`,
            queryParameters: params.query,
        });
    },
    getVendorListing(params: {
        companyId: string;
        financialAccountancyId: string;
        query: VendorListingQuery;
    }): Promise<GetVendorListingResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/vendor-listing`,
            queryParameters: params.query,
        });
    },
    getBalanceSheet(params: {
        companyId: string;
        financialAccountancyId: string;
        query: BalanceSheetQuery;
    }): Promise<GetBalanceSheetResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/balance-sheet`,
            queryParameters: params.query,
        });
    },
    getCashAccounting(params: {
        companyId: string;
        financialAccountancyId: string;
        query: CashAccountingQuery;
    }): Promise<GetCashAccountingResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/cash-accounting`,
            queryParameters: params.query,
        });
    },
    getProfitAndLoss(params: {
        companyId: string;
        financialAccountancyId: string;
        query: ProfitAndLossQuery;
    }): Promise<GetProfitAndLossResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/profit-and-loss`,
            queryParameters: params.query,
        });
    },

    getAccount(params: {
        companyId: string;
        financialAccountancyId: string;
        accountNr: number;
    }): Promise<GetAccountResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/accounts/${params.accountNr}`,
        });
    },

    getAccountTransactions(params: {
        companyId: string;
        financialAccountancyId: string;
        accountNr: number;
    }): Promise<GetAccountTransactionsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/accounts/${params.accountNr}/transactions`,
        });
    },

    getEmployerPayrollAccount(params: {
        companyId: string;
        year: number;
        query: EmployerPayrollAccountQuery;
    }): Promise<GetEmployerPayrollAccountResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${params.companyId}/results/employer-payroll-account/${params.year}`,
            queryParameters: params.query,
        });
    },

    getEmployeePayrollAccount(params: {
        companyId: string;
        employeeId: string;
        year: number;
        query: EmployerPayrollAccountQuery;
    }): Promise<GetEmployeePayrollAccountResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${params.companyId}/results/${params.employeeId}/employee-payroll-account/${params.year}`,
            queryParameters: params.query,
        });
    },

    getEmployerPayrollAccountDetails(params: {
        companyId: string;
        year: number;
        payrollTypeId: number;
        query: EmployerPayrollAccountQuery;
    }): Promise<GetEmployerPayrollAccountDetailsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${params.companyId}/results/employer-payroll-account/${params.year}/payroll-types/${params.payrollTypeId}`,
            queryParameters: params.query,
        });
    },

    putDownloadAccountTransactionsDocuments(params: {
        companyId: string;
        financialAccountancyId: string;
        accountNr: number;
        body: PutPrepareAccountTransactionDocumentsDownloadPayload;
    }) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/accounts/${params.accountNr}/transactions/documents/download`,
            method: "PUT",
            body: params.body,
        });
    },

    async getAccountTransactionsDocumentDownloadUrl(params: {
        companyId: string;
        financialAccountancyId: string;
        accountNr: number;
        body: PutPrepareAccountTransactionDocumentsDownloadPayload;
    }) {
        const link = await apiClient.requestType<Download>({
            url: `/api/v1/companies/${params.companyId}/results/${params.financialAccountancyId}/accounts/${params.accountNr}/transactions/documents/download`,
            method: "PUT",
            body: params.body,
        });
        return link.downloadUrl;
    },

    postOAuthAuthorize(body: PostOAuthRequest): Promise<PostOAuthResponse> {
        return apiClient.requestType({
            url: `/api/v1/oauth/authorize`,
            method: "POST",
            body,
        });
    },

    // List of individuals that can be invited as international project users
    getInternationalUsers({
        companyId,
        ...params
    }: IGetInternationalUsersParams & { companyId: string }): Promise<GetProjectUsersResponse> {
        if (!companyId) {
            throw new Error(t("error.noCompanySelected"));
        }

        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/projects/international-users`,
            queryParameters: params,
        });
    },

    getProjectUsersIntersection(body: PostProjectUsersIntersectionPayload, queryParameters: ITableParams) {
        return apiClient.requestType<GetProjectUsersResponse>({
            method: "POST",
            url: `/api/v1/projects/users/intersection`,
            body,
            queryParameters,
        });
    },

    putCopyProjectFiles(
        companyId: string,
        projectId: string,
        clipboardContent: ProjectClipboardContentPayload,
        folderId?: string,
    ) {
        if (folderId) {
            return apiClient.requestType<ProjectClipboardContentResponse>({
                url: `/api/v1/companies/${companyId}/projects/${projectId}/items/${folderId}/copy`,
                method: "PUT",
                body: clipboardContent,
            });
        } else {
            return apiClient.requestType<ProjectClipboardContentResponse>({
                url: `/api/v1/companies/${companyId}/projects/${projectId}/items/copy`,
                method: "PUT",
                body: clipboardContent,
            });
        }
    },

    putMoveProjectFiles(
        companyId: string,
        projectId: string,
        clipboardContent: ProjectClipboardContentPayload,
        folderId?: string,
    ) {
        if (folderId) {
            return apiClient.requestType<ProjectClipboardContentResponse>({
                url: `/api/v1/companies/${companyId}/projects/${projectId}/items/${folderId}/move`,
                method: "PUT",
                body: clipboardContent,
            });
        } else {
            return apiClient.requestType<ProjectClipboardContentResponse>({
                url: `/api/v1/companies/${companyId}/projects/${projectId}/items/move`,
                method: "PUT",
                body: clipboardContent,
            });
        }
    },

    getInsuranceCarriersForSubsidiary(
        companyId: string,
        subsidiaryId: string,
    ): Promise<GetSubsidiaryInsuranceCarriersResponse[]> {
        return apiClient.requestType({
            url: `api/v1/companies/${companyId}/modules/hr/subsidiaries/${subsidiaryId}/insurance-carriers`,
        });
    },

    getInsuranceCarriers(companyId: string): Promise<GetInsuranceCarriersResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${companyId}/insurance-carriers`,
        });
    },
    getInsuranceCarrierBalance(params: {
        companyId: string;
        carrierNumber: string;
        accountNumber: string;
    }): Promise<GetInsuranceCarrierBalanceResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${params.companyId}/insurance-carriers/${params.carrierNumber}/${params.accountNumber}/balance`,
        });
    },

    getBankConnections(companyId: string): Promise<GetBankConnectionsResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${companyId}/bank-connections`,
        });
    },
    postBankConnections(companyId: string): Promise<PostBankConnectionResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${companyId}/bank-connections`,
            method: "POST",
        });
    },
    putBankConnections(companyId: string, bankConnectionId: number): Promise<PutBankConnectionResponse> {
        return apiClient.requestType({
            url: `api/v1/companies/${companyId}/bank-connections/${bankConnectionId}`,
            method: "PUT",
        });
    },
    deleteBankConnections(companyId: string, bankConnectionId: number): Promise<Response> {
        return apiClient.request({
            url: `api/v1/companies/${companyId}/bank-connections/${bankConnectionId}`,
            method: "DELETE",
        });
    },
    async putBankAccountAccountNumber(
        companyId: string,
        bankConnectionId: number,
        bankAccountId: number,
        body: PutBankAccountAccountNumberPayload,
    ): Promise<void> {
        await apiClient.request({
            url: `api/v1/companies/${companyId}/bank-connections/${bankConnectionId}/accounts/${bankAccountId}/account-number`,
            method: "PUT",
            body,
        });
    },
    getBankAccountTransactions(
        companyId: string,
        bankConnectionId: number,
        bankAccountId: number,
        queryParameters: {
            periodID?: string;
            startDate?: string;
            endDate?: string;
            includeInvoices?: boolean;
            shouldMatchInvoicesAndTransactions?: boolean;
            skipIfAssignedToInvoice?: boolean;
            updateTransactions?: boolean;
        },
    ): Promise<GetBankAccountTransactionsResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/bank-connections/${bankConnectionId}/accounts/${bankAccountId}/transactions`,
            queryParameters,
        });
    },
    async postBankAccountTransactionInvoice(
        companyId: string,
        bankConnectionId: number,
        bankAccountId: number,
        bankAccountTransactionId: number,
        body: PostBankAccountTransactionInvoicePayload,
    ): Promise<void> {
        await apiClient.request({
            method: "POST",
            url: `/api/v1/companies/${companyId}/bank-connections/${bankConnectionId}/accounts/${bankAccountId}/transactions/${bankAccountTransactionId}/invoices`,
            body,
        });
    },
    async postBankAccountTransactionPrivate(
        companyId: string,
        bankConnectionId: number,
        bankAccountId: number,
        bankAccountTransactionId: number,
    ): Promise<void> {
        await apiClient.request({
            method: "POST",
            url: `/api/v1/companies/${companyId}/bank-connections/${bankConnectionId}/accounts/${bankAccountId}/transactions/${bankAccountTransactionId}/private`,
        });
    },
    async deleteBankAccountTransaction(
        companyId: string,
        bankConnectionId: number,
        bankAccountId: number,
        bankAccountTransactionId: number,
    ): Promise<void> {
        await apiClient.request({
            method: "DELETE",
            url: `/api/v1/companies/${companyId}/bank-connections/${bankConnectionId}/accounts/${bankAccountId}/transactions/${bankAccountTransactionId}`,
        });
    },
    putDownloadBankAccountTransactionsBankStatement(
        companyId: string,
        bankConnectionId: number,
        bankAccountId: number,
        body: PutPrepareBankAccountTransactionsDownloadBankStatementPayload,
    ) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${companyId}/bank-connections/${bankConnectionId}/accounts/${bankAccountId}/transactions/download/bank-statement`,
            body,
            method: "PUT",
        });
    },
    putDownloadBankAccountTransactionsBuerf(
        companyId: string,
        bankConnectionId: number,
        bankAccountId: number,
        body: PutPrepareBankAccountTransactionsDownloadBuerfPayload,
    ) {
        return apiClient.browserDownload({
            url: `/api/v1/companies/${companyId}/bank-connections/${bankConnectionId}/accounts/${bankAccountId}/transactions/download/buerf`,
            body,
            method: "PUT",
        });
    },

    getLRO(id: string): Promise<GetLROResponse> {
        return apiClient.requestType<GetLROResponse>({
            url: `/api/v1/lro/${id}`,
        });
    },

    async getCompanyFeatures(companyId: string): Promise<GetCompanyFeaturesResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/features`,
        });
    },
    async toggleCompanyFeature(companyId: string, featureId: string): Promise<PutCompanyFeatureToggleResponse> {
        return apiClient.requestType({
            url: `/api/v1/companies/${companyId}/features/${featureId}/toggle`,
            method: "PUT",
        });
    },

    getNotifications({
        companyId,
        type,
        state,
        pagination,
        module,
    }: {
        companyId?: string;
        type?: NotificationType;
        state?: NotificationState;
        pagination?: { offset?: number; limit?: number };
        module?: NotificationModule;
    }): Promise<GetAppNotificationsResponse> {
        return apiClient.requestType({
            url: `/api/v1/user/notifications`,
            queryParameters: { module, type, companyId, state, ...pagination },
        });
    },

    putMarkNotificationAsRead({ notificationId }: { notificationId: string }) {
        return apiClient.request({
            url: `/api/v1/user/notifications/${notificationId}/state/read`,
            method: "PUT",
        });
    },

    putMarkAllNotificationAsRead() {
        return apiClient.request({
            url: `/api/v1/user/notifications/state/read`,
            method: "PUT",
        });
    },
};
