import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { generalStore } from "../../stores/GeneralStore";
import { Module } from "../../types/models";
import { DeleteRecordsDialog, IRecordToDelete } from "../shared/ModuleDialogs";

export const useDeleteRecords = ({
    companyId,
    module,
    subsidiaryId,
    periodId,
    recordTypeId,
    onDelete,
}: {
    companyId?: string;
    module: Module;
    subsidiaryId?: string;
    periodId?: string;
    recordTypeId?: string;
    onDelete?: (numDeleted: number) => void;
}) => {
    const [recordsToDelete, setRecordsToDelete] = React.useState<IRecordToDelete[] | null>(null);

    const handleDeleteRecords = (records: IRecordToDelete[]) => {
        setRecordsToDelete(records);
    };

    const doDeleteRecords = async (recordIds: string[]) => {
        try {
            await Promise.all(
                recordIds.map(recordId => {
                    return API.deleteRecord({
                        companyId,
                        module,
                        subsidiaryId,
                        periodId,
                        recordTypeId,
                        recordId,
                    });
                }),
            );

            setRecordsToDelete(null);
            onDelete?.(recordIds.length);
        } catch (err) {
            generalStore.setError(t("error.delete"), err);
        }
    };

    const dialog = (
        <DeleteRecordsDialog
            open={!!recordsToDelete}
            records={recordsToDelete ?? []}
            onCancel={() => {
                setRecordsToDelete(null);
            }}
            onDelete={() => {
                if (recordsToDelete) {
                    doDeleteRecords(recordsToDelete.map(record => record.id));
                }
            }}
        />
    );

    return {
        handleDeleteRecords,
        dialog,
    };
};
