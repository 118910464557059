import cloneDeep from "lodash/cloneDeep";
import { observable } from "mobx";
import { create } from "mobx-persist";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import {
    CommuterAllowance,
    EmploymentInsuranceType,
    EmploymentRelationship,
    Funder,
    GetRecordTypesResponse,
    MaritalStatus,
    PermissionsRecordTypes,
    RecordType,
    SoleEarnerStatus,
} from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { ModuleStore } from "../../stores/ModuleStore";
import { IEmployee, INonWorkingTimes, ISubsidiary } from "../../types/models";
import { addStoreToWindow, debug } from "../../util/debug";
import { toError } from "../../util/error";
import { getRecordTypeName } from "../../util/recordTypes";
import { getDeepLinkInfo } from "../../util/url";
import { APIResult } from "../hooks/useAPI";

// Data for employee registration
const EMPTY_EMPLOYEE: IEmployee = {
    id: "",
    gender: "male",
    firstName: "",
    lastName: "",
    country: "",
    zipCode: "",
    city: "",
    address: "",
    houseNumber: "",
    addressAddition: "",
    dateOfBirth: null,
    citizenship: "",
    ssn: "",
    employmentRelationship: undefined,
    workplaceState: "",
    dateOfJoining: null,
    previouslyEmployed: false,
    employmentInsuranceType: undefined,
    titlePrefix: "",
    titleSuffix: "",
    religiousConfession: "",
    maritalStatus: "unknown",
    email: "",
    jobDescription: "",
    monthlySalary: "",
    isGrossSalary: "true",
    bonuses: [{ designator: "", amount: "" }],
    iban: "",
    bic: "",
    workingHours: "",
    workingHoursInterval: "week",
    weeklyWorkDays: "",
    costCenter: "",
    funder: "",
    costCenterNote: "",
    wageAgreementClassification: "",
    dateOfLeaving: null,
    reasonForLeaving: "",
    remainingOvertime: "",
    remainingVacationDays: "",
    commuterAllowance: null,
    familyBonusPlusAmount: "",
    kilometersForCommuterEuro: "",
    soleEarnerStatus: null,
};

export function cloneEmptyEmployee() {
    return cloneDeep(EMPTY_EMPLOYEE);
}

class HrStore extends ModuleStore {
    // These stay hardcoded -> no options from BMD
    workingHoursInterval = ["day", "week", "month"];
    weeklyWorkDays = [1, 2, 3, 4, 5, 6, 7];

    // These are GET /company/subsidiaries with knoedels. Ie. independent of period including ticket information
    @observable employeeSubsidiaries: ISubsidiary[] = [];
    @observable isLoadingEmployeeSubsidiaries = false;

    @observable funders: APIResult<{
        funders: Funder[];
        byId: Record<string, Funder[]>;
        byName: Record<string, Funder[]>;
    }> = {
        state: "initial",
    };

    maritalStatus: MaritalStatus[] = [
        "unknown",
        "unmarried",
        "married",
        "widowed",
        "divorced",
        "separated",
        "livingInPartnership",
    ];

    commuterAllowance: CommuterAllowance[] = [
        "KeinePendlerpauschale",
        "Mindestens20kmBus",
        "MehrAls40kmBus",
        "MehrAls60kmBus",
        "Mindestens2kmKeinBus",
        "MehrAls20kmKeinBus",
        "MehrAls40kmKeinBus",
        "MehrAls60kmKeinBus",
        "IndividuellePendlerpauschale",
    ];

    soleEarnerStatus: SoleEarnerStatus[] = ["Nein", "Alleinverdiener", "Alleinerzieher"];

    employmentRelationship: EmploymentRelationship[] = ["worker", "employee"];

    employmentInsuranceType: EmploymentInsuranceType[] = [
        "fullTime",
        "marginally",
        "apprentice",
        "independentContractor",
        "marginalIndependentContractor",
    ];

    EMPTY_NONWORKINGTIMES: INonWorkingTimes = {
        holidays: [{ title: "holidays", fromDate: "", toDate: "", unit: "", length: 0 }],
        sickLeave: [{ title: "sickLeave", fromDate: "", toDate: "", unit: "", length: 0 }],
        others: [{ title: "", fromDate: "", toDate: "", unit: "", length: 0 }],
    };

    @observable nonWorkingTimeSelectedEmployees: IEmployee[];
    @observable currentNonWorkingTimes: INonWorkingTimes;

    constructor() {
        super("hr");
        this.nonWorkingTimeSelectedEmployees = [];
        this.currentNonWorkingTimes = cloneDeep(this.EMPTY_NONWORKINGTIMES);
    }

    loadFunders = async (companyId: string) => {
        if (!this.selectedSubsidiaryId) {
            // Funders are only needed for employee display
            // TPAPORTAL-1911: Commented out code below. But keep it for reference, because code was added
            // as fix for TPAPORTAL-851
            // if (authStore.canReadAnyEmployees) {
            //     generalStore.setError(t("error.loadFunders"));
            // }
            return;
        }

        if (this.module === "hr" && !authStore.canReadEmployees(this.selectedSubsidiaryId)) {
            return;
        }

        try {
            this.funders = { state: "loading" };
            let { funders } = await API.getFunders(companyId, this.module, this.selectedSubsidiaryId);

            // deduplicate by id and name
            funders = funders.filter((funder, index, self) => {
                return index === self.findIndex(f => f.id === funder.id && f.name === funder.name);
            });

            // sort by name and then by id
            funders.sort((a, b) => {
                if (a.name === b.name) {
                    return a.id.localeCompare(b.id);
                }
                return a.name.localeCompare(b.name);
            });

            // create quick lookup maps by id and name
            const byName: Record<string, Funder[]> = {};
            const byId: Record<string, Funder[]> = {};

            funders.forEach(funder => {
                const { id, name } = funder;
                byName[id] ??= [];
                byName[id].push(funder);
                byName[name] ??= [];
                byName[name].push(funder);
            });

            this.funders = { state: "success", data: { funders, byId, byName } };
        } catch (err) {
            this.funders = { state: "error", error: toError(err) };
            generalStore.setError(t("error.loadFunders"), err);
        }
    };
    getFunder(id: string | null | undefined, useNameFallback?: boolean) {
        if (id == null || this.funders.state !== "success") {
            return undefined;
        }
        let funder: Funder[] | undefined = this.funders.data.byId[id];
        if (!funder && useNameFallback === true) {
            funder = this.funders.data.byName[id];
        }
        return funder?.[0];
    }

    selectedSubsidiary() {
        let subsidiary = super.selectedSubsidiary();
        if (!subsidiary) {
            // Didn't find in normal subsidiaries -> search in employee subsidiaries
            subsidiary = this.employeeSubsidiaries.find(s => s.id === this.selectedSubsidiaryId);
        }

        return subsidiary;
    }

    get preRegistrationTicketCount() {
        // Pre registration tickets have to be in employee subsidiaries
        const found = this.employeeSubsidiaries.find(s => s.id === this.selectedSubsidiaryId);
        if (!found) {
            return 0;
        }

        return found.ticketCount ?? 0;
    }

    loadEmployeeSubsidiaries = async () => {
        const companyId = companiesStore.selectedCompanyId;
        if (companyId) {
            try {
                // Need this so that selectedSubsidiaryId and employeeSubsidiaries are in sync.
                // Otherwise useCurrentEmployees starts loading with invalid selectedSubsidiaryId
                // before subsidiaryToEmployeeSubsidiary() has completed.
                this.isLoadingEmployeeSubsidiaries = true;

                let subsidiaries = await API.getSubsidiaries({
                    companyId,
                    module: "hr",
                    includeKnoedels: true,
                });

                // Throw away subsidiaries where you are not allowed to read the employees.
                subsidiaries = subsidiaries.filter(s => authStore.canReadEmployees(s.id));

                debug.log("### loaded employee subsidiaries");

                if (subsidiaries.length > 0) {
                    this.employeeSubsidiaries = subsidiaries;
                    this.subsidiaryToEmployeeSubsidiary();
                } else {
                    generalStore.setError(t("error.noSubsidiaryWithEmployeeRead"));
                }
            } catch (err) {
                generalStore.setError(t("error.loadSubsidiaries"));
            } finally {
                this.isLoadingEmployeeSubsidiaries = false;
            }
        } else {
            generalStore.setError(t("error.noCompanySelected"));
        }
    };

    subsidiaryToEmployeeSubsidiary = () => {
        // See if the current selected subsidiary exists in the employee subsidiaries as well (this means our current selected
        // subsidiary is not a past subsidiary that was deleted since then)
        const foundSelectedSubsidiaryInEmployeeSubsidiaries =
            this.selectedSubsidiaryId && this.employeeSubsidiaries.find(s => s.id === this.selectedSubsidiaryId);

        // Couldn't find it -> take first entry
        if (!foundSelectedSubsidiaryInEmployeeSubsidiaries) {
            this.selectedSubsidiaryId = this.employeeSubsidiaries[0]?.id;
        }
    };

    cleanupEmployeeSubsidiaries = () => {
        debug.log("### cleanup employee subsidiaries");

        // Convert back selected employee subsidiary to normal subsidiary
        const foundSelectedSubsidiaryInSubsidiaries =
            this.selectedSubsidiaryId && this.subsidiaries.find(s => s.id === this.selectedSubsidiaryId);

        if (!foundSelectedSubsidiaryInSubsidiaries) {
            this.selectedSubsidiaryId = this.subsidiaries[0]?.id;
        }

        // Clear employee subsidiaries
        this.employeeSubsidiaries = [];
    };

    getRecordTypeName(recordType: RecordType | GetRecordTypesResponse | PermissionsRecordTypes): string {
        return getRecordTypeName("hr", recordType);
    }

    async downloadEmployeeFiles({
        employeeId,
        isPreRegistration,
        documentIds,
        subsidiaryId: employeeSubsidiaryId,
    }: {
        employeeId: string;
        isPreRegistration: boolean;
        documentIds: string[];
        subsidiaryId?: string;
    }) {
        const companyId = companiesStore.selectedCompanyId;
        const subsidiaryId = employeeSubsidiaryId ?? this.selectedSubsidiaryId;

        if (!companyId || !subsidiaryId || documentIds.length === 0) {
            return;
        }

        try {
            generalStore.isLoading = true;
            await API.putDownloadEmployeeDocuments(
                companyId,
                "hr",
                subsidiaryId,
                employeeId,
                isPreRegistration,
                documentIds,
            );
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }

    wipe() {
        super.wipe();

        this.employeeSubsidiaries = [];
    }
}

export const hrStore = new HrStore();

if (import.meta.env.NODE_ENV !== "test") {
    (async () => {
        const hydrate = create({
            storage: (await import("localforage")).default,
        });

        hydrate("hr", hrStore)
            .then(() => {
                // Override with query param if provided
                const deepLink = getDeepLinkInfo();
                if (deepLink.isDeepLink) {
                    hrStore.parseDeepLink(deepLink);
                }
                hrStore.isRehydrated = true;
            })
            .catch((error: unknown) => {
                console.error(error);
            });
    })();
}

addStoreToWindow("hrStore", hrStore);
