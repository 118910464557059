import React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Project } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useProject = (companyId?: string, projectId?: string) => {
    const [project, setProject] = React.useState<Project | null>(null);

    const loadProject = React.useCallback(async () => {
        if (projectId && companyId) {
            try {
                generalStore.isLoading = true;
                const projectResponse = await API.getProject(companyId, projectId);
                setProject(projectResponse);
            } catch (error) {
                generalStore.setError(t("error.general"), error);
            } finally {
                generalStore.isLoading = false;
            }
        }
    }, [companyId, projectId]);

    React.useEffect(() => {
        loadProject();
    }, [loadProject]);

    return {
        reload: loadProject,
        project,
    };
};
