import { observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import { companiesStore } from "../../../stores/CompaniesStore";
import { configStore } from "../../../stores/ConfigStore";
import { UserTable } from "../../shared/UserTable";
import { CenteredContent } from "../../ui/CenteredContent";
import { SettingsNavBar } from "../SettingsNavBar";
import { SettingsRoutes } from "../router/SettingsRoutes";

export const SettingsUserManagementSite = observer(function SettingsUserManagementSite() {
    React.useEffect(() => {
        configStore.wipe();
    }, []);

    const companyStore = companiesStore.selectedCompanyStore;
    if (!companyStore) {
        // No company selected -> get out
        return <Redirect to={SettingsRoutes.ROOT} />;
    }

    if (!companyStore.permissions.raw) {
        return <Redirect to={SettingsRoutes.ROOT} />;
    }

    return (
        <>
            <SettingsNavBar />
            <CenteredContent>
                <UserTable
                    companyId={companyStore.id}
                    companyName={companyStore.name ?? ""}
                    permissions={companyStore.permissions.raw}
                />
            </CenteredContent>
        </>
    );
});
