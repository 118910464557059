import { Button } from "@material-ui/core";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import styled from "styled-components";
import { ZOOM_DEFAULT } from "../../../../config";
import { t } from "../../../../i18n/util";
import { API, ITableParams } from "../../../../network/API";
import { GetRecordTypesResponse } from "../../../../network/APITypes";
import { generalStore } from "../../../../stores/GeneralStore";
import { TableStore, useTableStoreLoader } from "../../../../stores/TableStore";
import { ViewerFile } from "../../../../stores/ViewerStore";
import { IRecord } from "../../../../types/models";
import { formatDateTime } from "../../../../util/date";
import { getFullName } from "../../../../util/user";
import { Currency } from "../../../results/ResultsValue";
import { DocumentDownloadLine } from "../../../ui/DocumentLine/DocumentLine";
import { EmptyState } from "../../../ui/EmptyState";
import { Icon } from "../../../util/Icon";
import { customColors } from "../../../util/Theme";
import { Viewer } from "../../../viewer/InlineViewer";
import { accountingStore } from "../../AccountingStore";
import { InlineUploadRecords } from "../../InlineUploadRecords";
import { Dependencies } from "./types";

const module = accountingStore.module;

interface RecordsTableState {
    record: IRecord | undefined;
    nextIndex?: number;
}
type RecordsTableAction =
    | { type: "itemsChanged"; records: IRecord[] }
    | { type: "selectRecord"; record: IRecord | undefined }
    | { type: "setNextIndex"; nextIndex: number };

const recordsTableReducer = (state: RecordsTableState, action: RecordsTableAction): RecordsTableState => {
    switch (action.type) {
        case "itemsChanged": {
            const { records } = action;

            if (typeof state.nextIndex === "number") {
                const record = records[state.nextIndex];
                if (record) {
                    return { ...state, record, nextIndex: undefined };
                }
            }

            if (state.record) {
                const id = state.record.id;
                const record = records.find(r => r.id === id);
                if (record) {
                    return { ...state, record, nextIndex: undefined };
                }
            }

            if (records.length > 0) {
                return { ...state, record: records[0], nextIndex: undefined };
            }

            return state;
        }

        case "selectRecord":
            return { ...state, record: action.record, nextIndex: undefined };

        case "setNextIndex":
            return { ...state, nextIndex: action.nextIndex };

        default:
            return state;
    }
};

export const useRecordsTable = (deps: Dependencies) => {
    const tableStoreRef = useRef<TableStore<IRecord>>();
    if (!tableStoreRef.current) {
        tableStoreRef.current = new TableStore<IRecord>({ orderBy: "uploadedAt", orderDir: "desc" }, "RecordsTable");
    }
    const tableStore = tableStoreRef.current;

    const loader = useCallback(async (deps: Dependencies, tableParams: ITableParams<IRecord>) => {
        const { companyId, periodId, subsidiaryId, recordTypeId } = deps;
        if (!companyId || !periodId || !subsidiaryId || !recordTypeId) {
            return null;
        }

        try {
            generalStore.isLoading = true;

            const records = await API.getRecords({
                companyId,
                module,
                periodId,
                subsidiaryId,
                recordTypeId,
                options: {
                    offset: tableParams.offset,
                    limit: tableParams.limit,
                    skipIfAssignedToBankAccountTransaction: true,
                },
            });
            return { items: records.records, totalCount: records.total };
        } catch (err) {
            generalStore.setError(t("error.loadRecords"), err);
            return null;
        } finally {
            generalStore.isLoading = false;
        }
    }, []);
    const { reload } = useTableStoreLoader<IRecord, Dependencies>(tableStore, loader, deps);

    const [state, dispatch] = useReducer(recordsTableReducer, { record: undefined });

    useEffect(() => {
        return autorun(() => {
            dispatch({ type: "itemsChanged", records: tableStore.items });
        });
    }, [tableStore]);

    const setRecordWithPagination = useCallback(
        (record: IRecord | "nextPage" | "prevPage") => {
            if (record === "nextPage") {
                tableStore.page++;
                dispatch({ type: "setNextIndex", nextIndex: 0 });
            } else if (record === "prevPage") {
                tableStore.page--;
                dispatch({ type: "setNextIndex", nextIndex: tableStore.rowsPerPage - 1 });
            } else {
                dispatch({ type: "selectRecord", record });
            }
        },
        [tableStore],
    );

    const removeRecord = useCallback(
        (id: string) => {
            const index = tableStore.items.findIndex(r => r.id === id);
            if (index >= 0) {
                const absoluteCurrentIndex = index + tableStore.offset;
                const absoluteNextIndex = Math.max(0, Math.min(absoluteCurrentIndex, tableStore.totalCount - 2));
                const nextIndex = absoluteNextIndex % tableStore.rowsPerPage;
                dispatch({ type: "selectRecord", record: undefined });
                dispatch({ type: "setNextIndex", nextIndex });

                if (tableStore.items.length === 1 && tableStore.page > 1) {
                    tableStore.page--; // all items of the current page have been removed, go back one page
                } else {
                    reload(); // reload the current page to get the next record
                }
            }
        },
        [reload, tableStore],
    );

    return {
        tableStore,
        reload,
        state,
        setRecord: setRecordWithPagination,
        removeRecord,
    };
};

const RecordsTableRoot = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;
    overflow: hidden;
`;

export const RecordsTable = observer(function Records({
    tableStore,
    state,
    setRecord,
    recordType,
    onDone,
    deps,
}: {
    tableStore: TableStore<IRecord>;
    state: RecordsTableState;
    setRecord: (record: IRecord | "nextPage" | "prevPage") => void;
    recordType: GetRecordTypesResponse | undefined;
    onDone: () => void;
    deps: Dependencies;
}) {
    const { record } = state;

    const handleDownload = async () => {
        if (!record) {
            return;
        }
        const { companyId } = deps;
        const { periodId, subsidiaryId, recordTypeId } = record;
        if (!companyId || !periodId || !subsidiaryId || !recordTypeId) {
            return;
        }

        try {
            generalStore.isLoading = true;

            await API.putDownloadRecords({
                companyId,
                module,
                subsidiaryId,
                periodId,
                recordTypeId,
                recordIds: [record.id],
            });
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const { companyId, periodId, subsidiaryId } = deps;
    if (!companyId || !periodId || !subsidiaryId || !recordType) {
        return null;
    }

    let content;
    if (!tableStore.totalCount) {
        const canUpload = accountingStore.canEditRecords(subsidiaryId, recordType.id);
        if (!canUpload) {
            content = <EmptyState title={t("accounting.bankAccount.transactions.assignInvoices.records.empty")} />;
        } else {
            content = (
                <InlineUploadRecords
                    companyId={companyId}
                    module={module}
                    periodId={periodId}
                    subsidiaryId={subsidiaryId}
                    recordType={recordType}
                    multipleFiles={false}
                    skipDetails
                    onDone={onDone}
                />
            );
        }
    } else {
        content = (
            <>
                {record && <DocumentDownloadLine fileName={record.document?.name} onDownload={handleDownload} />}
                {record && <RecordDetails record={record} />}
                {record && <RecordViewer record={record} deps={deps} />}
                <RecordsPagination tableStore={tableStore} state={state} onChange={setRecord} />
            </>
        );
    }

    return <RecordsTableRoot>{content}</RecordsTableRoot>;
});

const DL = styled.dl`
    display: grid;
    grid-template-columns: min-content auto;
    gap: 8px 24px;
    grid-auto-flow: row;
    align-items: center;
    margin: 16px 0;
`;
const DT = styled.dt.attrs({ className: "caption" })`
    white-space: nowrap;
`;
const DD = styled.dd``;

const RecordDetails = observer(function RecordDetails({ record }: { record: IRecord }) {
    return (
        <DL>
            <DT>{t("table.label.createdAt.variant")}</DT>
            <DD>{formatDateTime(record.uploadedAt)}</DD>
            <DT>{t("table.label.uploadedBy")}</DT>
            <DD>{getFullName(record.uploader)}</DD>
            <DT>{t("accounting.table.label.totalGrossAmount")}</DT>
            <DD>
                {record.invoiceMetadata?.totalGrossAmount != null && (
                    <Currency value={record.invoiceMetadata.totalGrossAmount} />
                )}
            </DD>
            {/* Future: record.amount */}
        </DL>
    );
});

const RecordViewerRoot = styled.div`
    flex: 1;
    overflow: auto;
    display: flex;

    .mobile & {
        max-height: 100vh; // limit the height of the viewer to avoid excessive scrolling to reach the transactions
    }
`;

const RecordViewer = observer(function Viewer({ record, deps }: { record: IRecord; deps: Dependencies }) {
    const { companyId } = deps;

    const file = useMemo<ViewerFile | null>(() => {
        const { periodId, subsidiaryId, recordTypeId } = record;
        if (!companyId || !periodId || !subsidiaryId || !recordTypeId) {
            return null;
        }
        return {
            id: record.id,
            name: record.document?.name ?? "",
            src: () => {
                return API.getRecordDownloadUrl({
                    companyId,
                    module,
                    periodId,
                    subsidiaryId,
                    recordTypeId,
                    recordId: record.id,
                });
            },
        };
    }, [companyId, record]);

    return <RecordViewerRoot>{file && <StyledViewer file={file} zoomLevel={ZOOM_DEFAULT} />}</RecordViewerRoot>;
});

const StyledViewer = styled(Viewer)`
    overflow: initial; // managed by us
`;

const RecordsPaginationRoot = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 12px;
`;

const RecordsPaginationButton = styled(Button)`
    font-weight: normal;
    font-size: 10px;
`;

const RecordsPagination = observer(function RecordsPagination({
    tableStore,
    state,
    onChange,
}: {
    tableStore: TableStore<IRecord>;
    state: RecordsTableState;
    onChange: (record: IRecord | "nextPage" | "prevPage") => void;
}) {
    const total = tableStore.totalCount;
    if (total <= 1) {
        return null; // no record or only one record, no need to display pagination
    }

    const offset = tableStore.offset;

    let index = 0;
    if (typeof state.nextIndex === "number") {
        index = state.nextIndex;
    } else if (state.record) {
        const { id } = state.record;
        index = tableStore.items.findIndex(item => item.id === id);
    }

    const current = Math.min(index + offset + 1, total);

    const isFirst = offset === 0 && index === 0;
    const isLast = current === total;

    return (
        <RecordsPaginationRoot>
            <RecordsPaginationButton
                disabled={isFirst}
                onClick={() => {
                    onChange(tableStore.items[index - 1] ?? "prevPage");
                }}
                startIcon={
                    <Icon
                        name="chevronLeft"
                        style={{
                            color: customColors.primaryColor,
                            opacity: isFirst ? 0.7 : undefined,
                        }}
                    />
                }
                size="small"
            >
                {t("accounting.bankAccount.transactions.assignInvoices.records.pagination.previous")}
            </RecordsPaginationButton>
            <span>
                {current} / {total}
            </span>
            <RecordsPaginationButton
                disabled={isLast}
                onClick={() => {
                    onChange(tableStore.items[index + 1] ?? "nextPage");
                }}
                endIcon={
                    <Icon
                        name="chevronRight"
                        style={{ color: customColors.primaryColor, opacity: isLast ? 0.7 : undefined }}
                    />
                }
                size="small"
            >
                {t("accounting.bankAccount.transactions.assignInvoices.records.pagination.next")}
            </RecordsPaginationButton>
        </RecordsPaginationRoot>
    );
});
