import { Button, FormControlLabel, RadioGroup } from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers } from "formik";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import * as React from "react";
import * as Yup from "yup";
import { t } from "../../i18n/util";
import { IEmployee } from "../../types/models";
import { useCountries } from "../hooks/useCountries";
import { CustomCheckbox } from "../ui/CustomCheckbox";
import { CustomDatePicker } from "../ui/CustomDatePicker";
import { CustomInputField } from "../ui/CustomInputField";
import { CustomRadio } from "../ui/CustomRadio";
import { CustomSelect } from "../ui/CustomSelect";
import { InfoButton, SectionContainer, Subtitle } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { hrStore } from "./HrStore";

export const HrPersonalDataForm = (props: {
    employee: IEmployee;
    onSubmit: (employee: IEmployee, formikHelpers: FormikHelpers<IEmployee>) => Promise<void>;
    isLoading: boolean;
    isReRegistration?: boolean;
    buttonText: string;
}) => {
    const initialValues = cloneDeep(props.employee);
    const countries = useCountries();
    const isMobile = React.useContext(MobileContext);

    if (props.isReRegistration) {
        initialValues.dateOfJoining = null;
    }

    let dateOfJoiningValidation = Yup.date()
        .required(t("screen.hr.personaldata.entrydate.validation"))
        .typeError(t("common.dateValidation"))
        .nullable();

    if (props.employee.dateOfLeaving) {
        const leaveDate = moment(props.employee.dateOfLeaving).endOf("day").toDate();

        // Don't join before last leave date
        dateOfJoiningValidation = dateOfJoiningValidation.min(leaveDate, t("screen.hr.personaldata.entrydate.invalid"));
    }

    if (countries.selectOptions.length === 0) {
        // Countries not loaded yet -> get out
        return null;
    }

    // Info: When adding a new field make sure you also add it to EMPTY_EMPLOYEE in HrStore.ts otherwise validation won´t work
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={props.onSubmit}
            validationSchema={Yup.object().shape({
                gender: Yup.string().required(),
                firstName: Yup.string().required(t("screen.hr.personaldata.firstname.validation")).trim(),
                lastName: Yup.string().required(t("screen.hr.personaldata.lastname.validation")).trim(),
                country: Yup.string().required(t("screen.hr.personaldata.country.validation")).trim(),
                zipCode: Yup.string()
                    .required(t("screen.hr.personaldata.zipcode.validation"))
                    .when("country", {
                        is: "AT",
                        then: schema =>
                            schema.length(4, t("screen.hr.personaldata.zipcode.length.validation", { count: 4 })), // ZipCode must be 4 long for Austria
                        otherwise: schema => schema,
                    }),
                city: Yup.string().required(t("screen.hr.personaldata.area.validation")).trim(),
                address: Yup.string().required(t("screen.hr.personaldata.streetnr.validation")).trim(),
                houseNumber: Yup.string().required(t("screen.hr.personaldata.houseNumber.validation")).trim(),
                dateOfBirth: Yup.date()
                    .required(t("screen.hr.personaldata.birthdate.validation"))
                    .typeError(t("common.dateValidation"))
                    .nullable(),
                citizenship: Yup.string().required(t("screen.hr.personaldata.citizenship.validation")).trim(),
                ssn: Yup.string().trim(),
                employmentRelationship: Yup.string()
                    .required(t("screen.hr.personaldata.employmenttype.validation"))
                    .trim(),
                employmentInsuranceType: Yup.string()
                    .required(t("screen.hr.personaldata.insurancetype.validation"))
                    .trim(),
                workplaceState: Yup.string()
                    // .required(t("screen.hr.personaldata.state.validation"))
                    .trim(),
                dateOfJoining: dateOfJoiningValidation,
                previouslyEmployed: Yup.boolean(),
            })}
            validateOnBlur
            validateOnChange
        >
            {formikProps => {
                return (
                    <Form>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Subtitle>{t("screen.hr.personaldata.personalinformation")}</Subtitle>

                            <SectionContainer>
                                <RadioGroup
                                    name="gender"
                                    data-id="personaldata_gender"
                                    value={formikProps.values.gender}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginBottom: 16,
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Field component={CustomRadio} name="gender" value="male" data-id="male" />
                                        }
                                        label={t("screen.hr.personaldata.male")}
                                        style={{ height: 24 }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Field
                                                component={CustomRadio}
                                                name="gender"
                                                value="female"
                                                data-id="female"
                                            />
                                        }
                                        label={t("screen.hr.personaldata.female")}
                                        style={{ height: 24 }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Field
                                                component={CustomRadio}
                                                name="gender"
                                                value="diverse"
                                                data-id="diverse"
                                            />
                                        }
                                        label={t("screen.hr.personaldata.diverse")}
                                        style={{ height: 24 }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Field
                                                component={CustomRadio}
                                                name="gender"
                                                value="notSpecified"
                                                data-id="notSpecified"
                                            />
                                        }
                                        label={t("screen.hr.personaldata.notSpecified")}
                                        style={{ height: 24 }}
                                    />
                                </RadioGroup>

                                <div style={{ display: "flex" }}>
                                    <Field
                                        data-id="personaldata_firstName"
                                        component={CustomInputField}
                                        label={t("screen.hr.personaldata.firstname")}
                                        name="firstName"
                                        style={{ marginRight: 16, flexGrow: 1 }}
                                        required
                                    />
                                    <Field
                                        data-id="personaldata_lastName"
                                        component={CustomInputField}
                                        label={t("screen.hr.personaldata.lastname")}
                                        name="lastName"
                                        type="text"
                                        style={{ flexGrow: 1 }}
                                        required
                                    />
                                </div>
                            </SectionContainer>

                            <Subtitle>{t("screen.hr.personaldata.address")}</Subtitle>

                            <SectionContainer>
                                <div
                                    style={{
                                        display: isMobile ? "block" : "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Field
                                        style={{ flex: 2, marginRight: isMobile ? 0 : 16 }}
                                        component={CustomSelect}
                                        name="country"
                                        data-id="personaldata_country"
                                        label={t("screen.hr.personaldata.country")}
                                        options={countries.selectOptions}
                                        required
                                    />
                                    <Field
                                        style={{ flex: 1, marginRight: isMobile ? 0 : 16 }}
                                        name="zipCode"
                                        component={CustomInputField}
                                        data-id="personaldata_zipCode"
                                        label={t("screen.hr.personaldata.zipCode")}
                                        required
                                    />
                                    <Field
                                        style={{ flex: 2 }}
                                        component={CustomInputField}
                                        name="city"
                                        data-id="personaldata_city"
                                        label={t("screen.hr.personaldata.city")}
                                        required
                                    />
                                </div>
                                <Field
                                    component={CustomInputField}
                                    name="address"
                                    data-id="personaldata_address"
                                    label={t("screen.hr.personaldata.address")}
                                    required
                                />
                                <Field
                                    component={CustomInputField}
                                    name="houseNumber"
                                    data-id="personaldata_house_number"
                                    label={t("screen.hr.personaldata.houseNumber")}
                                    required
                                />
                                <Field
                                    component={CustomInputField}
                                    name="addressAddition"
                                    data-id="personaldata_addressAddition"
                                    label={t("screen.hr.personaldata.addressAddition")}
                                />
                            </SectionContainer>

                            <Subtitle>{t("screen.hr.personaldata.dateOfBirth")}</Subtitle>

                            <SectionContainer>
                                <Field
                                    component={CustomDatePicker}
                                    disableFuture
                                    data-id="personaldata_dateOfBirth"
                                    label={t("common.dateformat")}
                                    name="dateOfBirth"
                                    required
                                />
                            </SectionContainer>

                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Subtitle>{t("screen.hr.personaldata.insurancedata")}</Subtitle>
                                <InfoButton
                                    title={t("screen.hr.personaldata.insurancedatainformationhead")}
                                    body={t("screen.hr.personaldata.insurancedatainformationbody")}
                                    color="primary"
                                    style={{
                                        marginLeft: 4,
                                        padding: 0,
                                        marginBottom: 16,
                                    }}
                                    data-id="insurancedata_info"
                                />
                            </div>
                            <SectionContainer>
                                <Field
                                    component={CustomSelect}
                                    data-id="personaldata_citizenship"
                                    name="citizenship"
                                    label={t("screen.hr.personaldata.citizenship")}
                                    options={countries.selectOptions}
                                    required
                                />
                                <Field
                                    component={CustomInputField}
                                    name="ssn"
                                    data-id="personaldata_ssn"
                                    label={t("screen.hr.personaldata.ssn")}
                                />
                            </SectionContainer>

                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Subtitle>{t("screen.hr.personaldata.employmenttypeheader")}</Subtitle>
                                <InfoButton
                                    title={t("screen.hr.personaldata.employmenttypeinformationhead")}
                                    body={t("screen.hr.personaldata.employmenttypeinformationbody")}
                                    color="primary"
                                    style={{
                                        marginLeft: 4,
                                        padding: 0,
                                        marginBottom: 16,
                                    }}
                                    data-id="employment_type_info"
                                />
                            </div>

                            <SectionContainer>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Field
                                        style={{ minWidth: 150, flexGrow: 1 }}
                                        component={CustomSelect}
                                        data-id="personaldata_employmentRelationship"
                                        options={hrStore.employmentRelationship.map(entry => ({
                                            value: entry,
                                            label: t(`employmentRelationship.${entry}`),
                                        }))}
                                        label={t("screen.hr.personaldata.employmentRelationship")}
                                        name="employmentRelationship"
                                        required
                                    />
                                    <Field
                                        style={{ minWidth: 150, flexGrow: 1, marginLeft: 16 }}
                                        component={CustomSelect}
                                        data-id="employmentInsuranceType"
                                        options={hrStore.employmentInsuranceType.map(entry => ({
                                            value: entry,
                                            label: t(`employmentInsuranceType.${entry}`),
                                        }))}
                                        label={t("screen.hr.personaldata.employmentInsuranceType")}
                                        name="employmentInsuranceType"
                                        required
                                    />
                                </div>
                                <Field
                                    component={CustomInputField}
                                    data-id="personaldata_workplaceState"
                                    label={t("screen.hr.personaldata.workplaceState")}
                                    name="workplaceState"
                                />
                            </SectionContainer>

                            <Subtitle>{t("screen.hr.personaldata.dateOfJoining")}</Subtitle>

                            <SectionContainer>
                                <Field
                                    name="dateOfJoining"
                                    component={CustomDatePicker}
                                    data-id="personaldata_date_of_joining"
                                    label={t("common.dateformat")}
                                    value={formikProps.values.dateOfJoining}
                                    keyboardIcon={<Icon name="calendar"></Icon>}
                                    required
                                    minDate={
                                        props.employee.dateOfLeaving
                                            ? moment(props.employee.dateOfLeaving).add(1, "day").toDate()
                                            : undefined
                                    }
                                />
                                <Field
                                    name="previouslyEmployed"
                                    component={CustomCheckbox}
                                    data-id="personaldata_previously_employed"
                                    label={t("screen.hr.personaldata.previouslyEmployed")}
                                    value={formikProps.values.previouslyEmployed ?? false}
                                />
                            </SectionContainer>
                            <div style={{ textAlign: "right" }}>
                                <Button
                                    data-id="personaldata_submit"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={props.isLoading}
                                >
                                    {props.buttonText}
                                </Button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
