import * as React from "react";
import { ic_filter_inactive, ic_filter_active } from "../../util/Images";

interface IProps {
    active?: boolean;
    onClick: () => void;
}

export const TableFilterToggleButton = ({ active, onClick }: IProps) => (
    <img
        style={{ cursor: "pointer", display: "block" }}
        alt="filter"
        src={active ? ic_filter_active : ic_filter_inactive}
        onClick={onClick}
    />
);
