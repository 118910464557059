import { Radio, RadioProps } from "@material-ui/core";
import { FieldInputProps } from "formik";
import { InputHTMLAttributes } from "react";

type IProps = RadioProps & {
    field: FieldInputProps<string>;
    "data-id": string;
};

export const CustomRadio = ({ value, field, "data-id": dataId }: IProps) => {
    return (
        <Radio
            inputProps={{ "data-id": dataId } as InputHTMLAttributes<HTMLInputElement>}
            value={value}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            color="primary"
        />
    );
};
