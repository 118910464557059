import { useCallback } from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../config";
import { t, translateOrDefault } from "../../../i18n/util";
import { RenderExpanded } from "../../ui/GridTable";
import { customColors } from "../../util/Theme";
import { Currency, Pill, PillVariant } from "../ResultsValue";
import { EnhancedAccountTransaction } from "./useAccountTransactions";

export function useResultsAccountSheetTableRenderExpanded(): RenderExpanded<EnhancedAccountTransaction> {
    return useCallback(item => {
        return <ExpandedContent item={item} />;
    }, []);
}

const ExpandedContentRoot = styled.dl`
    align-items: flex-start;
    border-top: 1px solid ${customColors.greyLight};
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 8px 13px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;
const ExpandedContentRow = styled.div`
    display: flex;
    flex-direction: column;
`;
const ExpandedContentLabel = styled.dt`
    align-items: center;
    display: flex;
    font-size: 12px;
    white-space: nowrap;
`;
const ExpandedContentValue = styled.dd`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    gap: 0 8px;
    hyphens: auto;
    overflow-wrap: break-word;
    word-break: break-word;
`;

const taxCodeToPillVariant: Record<string, PillVariant> = {
    Ust: "red",
    VSt: "green",
    igEOhneVst: "blue",
    igEMitVSt: "cyan",
    RCOhneVSt: "magenta",
    RCMitVSt: "yellow",
    BauleistungenOhneVSt: "orange",
    BauleistungenMitVSt: "purple",
    EUSt: "brown",
    TeilabzugsfaehigeVSt: "grey",
};

const ExpandedContent = ({ item }: { item: EnhancedAccountTransaction }) => {
    const Row = ExpandedContentRow;
    const Label = ExpandedContentLabel;
    const Value = ExpandedContentValue;
    return (
        <ExpandedContentRoot>
            {item.serialNumber != null && (
                <Row>
                    <Value>{item.serialNumber}</Value>
                    <Label>{t("results.accountSheet.details.serialNumber")}</Label>
                </Row>
            )}
            {item.foreignCurrency && item.foreignCurrency !== "EUR" && !!item.foreignCurrencyAmount && (
                <Row>
                    <Value>
                        {item.foreignCurrency}
                        {item.exchangeRate ? ` / ${item.exchangeRate.toNumber()}` : null}
                    </Value>
                    <Label>{t("results.accountSheet.details.foreignCurrency")}</Label>
                </Row>
            )}
            {(!!item.taxAmount || !!item.taxCode) && (
                <Row>
                    <Value>
                        {item.taxAmount ? <Currency value={item.taxAmount} /> : null}
                        {item.taxCode ? (
                            <Pill variant={taxCodeToPillVariant[item.taxCode] ?? "grey"}>
                                {translateOrDefault(
                                    `results.accountSheet.details.taxCode.${item.taxCode}`,
                                    undefined,
                                    `${item.taxCode}${item.taxCodeNumber ? ` (${item.taxCodeNumber})` : ""}`,
                                )}
                            </Pill>
                        ) : null}
                        {!item.taxCode && item.taxCodeNumber ? <Pill variant="grey">{item.taxCodeNumber}</Pill> : null}
                    </Value>
                    <Label>{t("results.accountSheet.details.tax")}</Label>
                </Row>
            )}
            {item.taxPercent ? (
                <Row>
                    <Value>{item.taxPercent ? <span>{item.taxPercent.toFixed(2) + " %"}</span> : null}</Value>
                    <Label>{t("results.accountSheet.details.taxPercent")}</Label>
                </Row>
            ) : null}
            {item.discount && (
                <Row>
                    <Value>{<Currency value={item.discount} />}</Value>
                    <Label>{t("results.accountSheet.details.discount")}</Label>
                </Row>
            )}
            {item.externalDocumentNumber && (
                <Row>
                    <Value>{item.externalDocumentNumber}</Value>
                    <Label>{t("results.accountSheet.details.externalDocumentNumber")}</Label>
                </Row>
            )}
            {item.costCenter && (
                <Row>
                    <Value>{item.costCenter}</Value>
                    <Label>{t("results.accountSheet.details.costCenter")}</Label>
                </Row>
            )}
            {item.period && (
                <Row>
                    <Value>{periodToMonth(item.period)}</Value>
                    <Label>{t("results.accountSheet.details.period")}</Label>
                </Row>
            )}
            {item.vatPeriod && (
                <Row>
                    <Value>{periodToMonth(item.vatPeriod)}</Value>
                    <Label>{t("results.accountSheet.details.vatPeriod")}</Label>
                </Row>
            )}
        </ExpandedContentRoot>
    );
};

function periodToMonth(period: number) {
    if (period <= 12) {
        // already month
        return period;
    }
    // the number otherwise looks like YYYYMM, so 202305
    return period - Math.floor(period / 100) * 100;
}
