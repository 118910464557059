import { t } from "../../../i18n/util";
import { RendererProps } from "../types";
import { CenteredPanel } from "./Helper";

interface UnsupportedRendererProps extends RendererProps {}

export const UnsupportedRenderer = ({ file, align }: UnsupportedRendererProps) => {
    return (
        <CenteredPanel style={align === "top" ? { height: "unset" } : undefined}>
            <p>{t("viewer.unsupported.message", { name: file.name })}</p>
        </CenteredPanel>
    );
};
