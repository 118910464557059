import { alpha, CircularProgress, IconButton } from "@material-ui/core";
import React, { Suspense, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled from "styled-components";
import { t } from "../../../i18n/util";
import { KPIMetric } from "../../../network/APITypes";
import { Centered, InfoButton } from "../../ui/Primitives";
import { Icon } from "../../util/Icon";
import { BOX_SHADOW_LIGHT, customColors } from "../../util/Theme";
import { useKPIContext } from "./KPIContext";
import { formatMonthlyValueDate, formatMonthlyValuesDateRange } from "./utils";

import { LazyReactECharts } from "../../libs/echarts-for-react";

const CardRoot = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 6px;
    box-shadow: ${BOX_SHADOW_LIGHT};
    background-color: ${customColors.white};
`;

const EditButtons = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 4px;
    background: ${alpha(customColors.primaryColor, 0.15)};
    border-top-left-radius: 16px;
`;

interface DragItem {
    id: string;
}

interface EditableCardProps {
    onEdit: () => void;
    onDelete: () => void;
    id: string;
    onMove: (dragId: string, hoverId: string) => void;
    moveType: string;
}

export const Card = ({
    children,
    onEdit,
    onDelete,
    id,
    onMove,
    moveType,
    className,
    style,
}: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
} & Partial<EditableCardProps>) => {
    const { editing } = useKPIContext();

    const ref = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: moveType ?? "unknown",
        hover(item: DragItem) {
            if (!ref.current || !onMove) {
                return;
            }
            const dragId = item.id;
            const hoverId = id;
            if (hoverId === undefined) {
                return;
            }

            // Don't replace items with themselves
            if (dragId === hoverId) {
                return;
            }

            onMove(dragId, hoverId);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: moveType ?? "unknown",
        item: (): DragItem => {
            return { id: id ?? "" };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;

    // only connect the drag/drop handlers if the card can be edited and we are in edit mode
    if (id !== undefined && editing) {
        drag(drop(ref));
    }

    return (
        <CardRoot ref={ref} className={className} style={{ ...style, opacity }}>
            {children}
            {editing && onEdit && onDelete ? (
                <EditButtons>
                    <IconButton size="small" color="primary" onClick={onEdit}>
                        <Icon name="pen" />
                    </IconButton>
                    <IconButton size="small" color="primary" onClick={onDelete}>
                        <Icon name="delete" />
                    </IconButton>
                </EditButtons>
            ) : null}
        </CardRoot>
    );
};

export const LoadingCard = ({ onEdit, onDelete, id, onMove, moveType }: EditableCardProps) => {
    return (
        <Card onEdit={onEdit} onDelete={onDelete} id={id} onMove={onMove} moveType={moveType}>
            <Centered>
                <CircularProgress color="primary" />
            </Centered>
        </Card>
    );
};

export const EmptyCard = ({ name, onEdit, onDelete, id, onMove, moveType }: { name: string } & EditableCardProps) => {
    return (
        <Card onEdit={onEdit} onDelete={onDelete} id={id} onMove={onMove} moveType={moveType}>
            <Centered>
                <Icon name="warning" style={{ marginBottom: 8 }} />
                <div>{t("screen.cockpit.kpis.empty", { name })}</div>
            </Centered>
        </Card>
    );
};

const Name = styled.div`
    display: flex;
    align-items: center;
    min-height: 24px;
    color: ${customColors.primaryColor};

    .editing & {
        color: ${customColors.body1Dark};
    }
`;

const Value = styled.p`
    font-family: "Archivo";
    color: ${customColors.greyDarkIntense};
    font-weight: 500;
    font-size: 32px;
    line-height: 35px;
    font-style: normal;

    .editing & {
        color: ${customColors.greyLight};
    }
`;

const Caption = styled.p`
    margin-top: 10px;
    line-height: 24px; // enough space for an icon
    color: ${customColors.body2Dark};

    .editing & {
        color: ${customColors.greyLight};
    }
`;

export const SmallCard = ({
    name,
    tooltip,
    dateRange,
    value,
    caption,
    onEdit,
    onDelete,
    id,
    onMove,
    moveType,
}: {
    name: React.ReactNode;
    tooltip?: React.ReactNode;
    dateRange: React.ReactNode;
    value: React.ReactNode;
    caption: React.ReactNode;
} & EditableCardProps) => {
    return (
        <Card onEdit={onEdit} onDelete={onDelete} id={id} onMove={onMove} moveType={moveType}>
            <p className="caption" style={{ textAlign: "right", marginBottom: 4 }}>
                {dateRange}
            </p>
            <Name>
                <h4 style={{ color: "inherit" }}>{name}</h4>
                {tooltip && (
                    <InfoButton
                        title={<>{name}:</>}
                        body={tooltip}
                        color="inherit"
                        style={{
                            marginLeft: 4,
                            padding: 0,
                        }}
                    />
                )}
            </Name>
            <Value style={{ marginTop: 10 }}>{value}</Value>
            {caption && <Caption>{caption}</Caption>}
        </Card>
    );
};

export const ChartCard = ({
    metric,
    name,
    onEdit,
    onDelete,
    id,
    onMove,
    moveType,
}: {
    metric: KPIMetric;
    name: React.ReactNode;
} & EditableCardProps) => {
    const { editing } = useKPIContext();

    if (!metric.monthlyValues) {
        return (
            <Card
                onEdit={onEdit}
                onDelete={onDelete}
                id={id}
                onMove={onMove}
                style={{ color: customColors.placeholder, fontSize: 16, fontWeight: 500 }}
            >
                <Centered>{t("common.notAvailable")}</Centered>
            </Card>
        );
    }

    // the monthly values are sorted from newest to oldest but in the chart we want oldest to newest
    const values = [...metric.monthlyValues].reverse();

    const first = values[0];
    const last = values[values.length - 1];
    const hasMultipleYears = new Date(first.from).getFullYear() !== new Date(last.from).getFullYear();

    return (
        <Suspense
            fallback={<LoadingCard onEdit={onEdit} onDelete={onDelete} id={id} onMove={onMove} moveType={moveType} />}
        >
            <Card onEdit={onEdit} onDelete={onDelete} id={id} onMove={onMove} moveType={moveType}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <h4>{name}</h4>
                    <p className="caption" style={{ textAlign: "right" }}>
                        {formatMonthlyValuesDateRange(values)}
                    </p>
                </div>
                <p className="caption" style={{ color: customColors.body1Dark, marginTop: 6 }}>
                    {t("screen.cockpit.kpis.chart.legend")}
                </p>
                <LazyReactECharts
                    style={{ height: 162 }}
                    option={{
                        textStyle: {
                            fontFamily: "Roboto",
                        },
                        grid: {
                            left: 24,
                            top: 24,
                            right: 24,
                            bottom: 24,
                            containLabel: true,
                            borderColor: customColors.greyLight,
                        },
                        xAxis: {
                            type: "category",
                            data: values.map(value => formatMonthlyValueDate(value, hasMultipleYears)),
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    color: editing ? customColors.greyLight : customColors.body2Dark,
                                    borderColor: customColors.greyLight,
                                },
                            },
                            axisLabel: {
                                padding: [8, 4],
                                fontSize: 10,
                            },
                        },
                        yAxis: {
                            type: "value",
                            splitNumber: 3,
                            axisLabel: {
                                fontSize: 10,
                                formatter: function (value: number) {
                                    return value / 1000;
                                },
                            },
                            axisLine: {
                                lineStyle: {
                                    color: editing ? customColors.greyLight : customColors.body2Dark,
                                    borderColor: customColors.greyLight,
                                },
                            },
                        },
                        series: [
                            {
                                type: "bar",
                                barWidth: 8,
                                itemStyle: {
                                    borderRadius: 8,
                                },
                                data: values.map(value => value.value),
                            },
                        ],
                        tooltip: {
                            trigger: "item",
                        },
                        color: editing ? customColors.greyLight : customColors.primaryColor,
                    }}
                />
            </Card>
        </Suspense>
    );
};
