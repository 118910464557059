import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { companiesStore } from "../../../stores/CompaniesStore";
import { Routes } from "../../app/router/Routes";
import { useInitModule } from "../../hooks/useInitModule";
import { hrStore } from "../HrStore";
import { HrRouter } from "../router/HrRouter";

export const HrContainerSite = observer(function HrContainerSite() {
    useInitModule("hr");

    if (!companiesStore.selectedCompanyId) {
        return null;
    }

    if (!companiesStore.selectedCompanyStore?.hasModule("hr")) {
        return <Redirect to={Routes.COCKPIT} />;
    }

    if (!hrStore.isInitialized) {
        return null;
    }

    return <HrRouter />;
});
