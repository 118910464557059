import { observer } from "mobx-react";
import { t } from "../../i18n/util";
import { TPAEmployeePublicUserInfo } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { CustomDialog } from "../ui/CustomDialog";
import { TextDivider } from "../ui/TextDivider";
import { UserCard } from "../ui/UserCard";

// If GLOBAL_FEATURES.chat = false -> show popup with tpa contacts
export const SupportDialog = observer(function SupportDialog() {
    const advisors = generalStore.responsibleUsers?.partners ?? [];
    const responsible = generalStore.responsibleUsers?.responsible ?? [];

    const selectedSupportId = generalStore.selectedSupportId;

    const handleClickUser = (user: TPAEmployeePublicUserInfo) => {
        generalStore.selectedSupportId = user.id;
    };

    return (
        <CustomDialog
            open={!!generalStore.responsibleUsers}
            showCloseIcon
            onClose={generalStore.closeSupportDialog}
            maxWidth="xs"
            PaperProps={{ style: { background: "#f6f7f8" } }}
        >
            <div
                style={{
                    padding: 48,
                    paddingTop: 0,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <h4>{t("support.yourContacts")}</h4>
                {advisors.length > 0 && <TextDivider text={t("support.partners.title")} style={{ marginTop: 24 }} />}
                {advisors.map(user => (
                    <UserCard
                        onClick={() => {
                            handleClickUser(user);
                        }}
                        user={user}
                        status={user.id === selectedSupportId ? "inProgress" : undefined}
                        showDetail={user.id === selectedSupportId}
                        key={user.id}
                    />
                ))}
                {responsible.length > 0 && (
                    <TextDivider text={t("support.responsible.title")} style={{ marginTop: 24 }} />
                )}
                {responsible.map(user => (
                    <UserCard
                        onClick={() => {
                            handleClickUser(user);
                        }}
                        user={user}
                        status={user.id === selectedSupportId ? "inProgress" : undefined}
                        showDetail={user.id === selectedSupportId}
                        key={user.id}
                    />
                ))}
            </div>
        </CustomDialog>
    );
});
