import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Redirect, useLocation } from "react-router";
import styled from "styled-components";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Account as AccountType, BankAccountTransaction } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { debug } from "../../../util/debug";
import { NavBarBack } from "../../ui/NavBarBack";
import { NavBarChooseSubsidiary } from "../../ui/NavBarChooseSubsidiary";
import { MobileContext } from "../../util/MobileContext";
import { Account } from "../Account";
import { accountingStore } from "../AccountingStore";
import { AccountingRoutes } from "../router/AccountingRoutes";
import {
    BankAccountTransactionsTable,
    useBankAccountTransactionsTable,
} from "./assignInvoicesToBankAccountTransactions/BankAccountTransactions";
import { RecordsTable, useRecordsTable } from "./assignInvoicesToBankAccountTransactions/Records";
import {
    AssignInvoicesToBankAccountTransactionsState,
    Dependencies,
} from "./assignInvoicesToBankAccountTransactions/types";
import { useCombinedTableFilters } from "./assignInvoicesToBankAccountTransactions/useCombinedFilters";

const module = accountingStore.module;

const Root = styled.div`
    display: flex;
    flex-direction: row;
    height: calc(100vh - var(--navBarHeight));
    &.mobile {
        display: unset;
        height: unset;
    }
`;
const Left = styled.div`
    width: 50%;
    display: flex;
    ${Root}.mobile & {
        width: unset;
    }
`;
const Right = styled(Left)`
    background: white;
`;

const BankAccountTransactionsTableRoot = styled.div`
    width: 500px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    .mobile & {
        width: unset;
        flex: 1;
    }
`;

export const AssignInvoicesToBankAccountTransactionsSite = observer(
    function AssignInvoicesToBankAccountTransactionsSite() {
        const isAllowed = companiesStore.selectedCompanyStore?.canAssignBankAccountTransactionInvoices();
        if (!isAllowed) {
            debug.error("### user is not allowed to assign invoices to bank account transactions");
            return <Redirect to={AccountingRoutes.BANKING.CONNECTIONS.ROOT} />;
        }

        return <AssignInvoicesToBankAccountTransactions />;
    },
);

const AssignInvoicesToBankAccountTransactions = observer(function AssignInvoicesToBankAccountTransactions() {
    useHideSideBar();

    const isMobile = useContext(MobileContext);

    const companyId = companiesStore.selectedCompanyId;
    const periodId = accountingStore.selectedPeriodId;
    const subsidiaryId = accountingStore.selectedSubsidiaryId;

    const { tableFilters, bankConnectionAndAccount, recordType } = useCombinedTableFilters({
        companyId,
        periodId,
        subsidiaryId,
    });

    const recordTypeId = recordType?.id;

    const deps = useMemo<Dependencies>(
        () => ({ companyId, periodId, subsidiaryId, recordTypeId }),
        [companyId, periodId, recordTypeId, subsidiaryId],
    );

    // bank connection, account & transactions

    const transactionsTable = useBankAccountTransactionsTable();
    const transaction = transactionsTable.transaction;
    const removeTransaction = transactionsTable.removeTransaction;

    const orderByValue = tableFilters.activeFilters.orderBy.value;
    useEffect(() => {
        transactionsTable.tableStore.orderBy = orderByValue as keyof BankAccountTransaction;
        transactionsTable.tableStore.orderDir = "asc";
    }, [orderByValue, transactionsTable.tableStore]);

    // record types & records

    const recordsTable = useRecordsTable(deps);
    const record = recordsTable.state.record;
    const removeRecord = recordsTable.removeRecord;
    const reloadRecords = recordsTable.reload;

    // --------------------

    const account = useAccount(deps);

    // --------------------

    const addBankAccountTransactionInvoices = useCallback(
        async (transaction: BankAccountTransaction, invoiceIds: string[]) => {
            if (!companyId || !periodId || !subsidiaryId || !recordTypeId || !bankConnectionAndAccount) {
                return;
            }

            const bankConnectionId = bankConnectionAndAccount.bankConnection.bankConnectionId;
            const accountId = bankConnectionAndAccount.bankAccount.accountId;

            for (const invoiceId of invoiceIds) {
                try {
                    await API.postBankAccountTransactionInvoice(
                        companyId,
                        bankConnectionId,
                        accountId,
                        transaction.id,
                        { invoiceId, accountNumber: account.value?.number },
                    );

                    removeRecord(invoiceId);
                } catch (error) {
                    generalStore.setError(t("error.addBankAccountTransactionInvoices"), error);
                    continue;
                }
            }

            removeTransaction(transaction.id);
        },
        [
            account.value?.number,
            bankConnectionAndAccount,
            companyId,
            periodId,
            recordTypeId,
            removeRecord,
            removeTransaction,
            subsidiaryId,
        ],
    );

    const handleClickAssign = useCallback(() => {
        if (!transaction || !record) {
            return;
        }
        addBankAccountTransactionInvoices(transaction, [record.id]);
    }, [addBankAccountTransactionInvoices, record, transaction]);

    const { state } = useLocation<AssignInvoicesToBankAccountTransactionsState | undefined>();

    return (
        <>
            <NavBarBack
                title={t("accounting.bankAccount.transactions.assignInvoices.title")}
                moduleInfo={module}
                moduleInfoReadOnly={false} // let the user change the periods
                backLabel={state?.backLabel ?? t("common.bankConnections")}
                backTarget={state?.backTarget ?? AccountingRoutes.BANKING.CONNECTIONS.ROOT}
                subsidiariesComponent={
                    <NavBarChooseSubsidiary moduleStore={accountingStore} redirectOnChange={false} />
                }
                showCancel={false}
            />
            <Root className={isMobile ? "mobile" : undefined}>
                <Left>
                    <RecordsTable
                        tableStore={recordsTable.tableStore}
                        state={recordsTable.state}
                        recordType={recordType}
                        setRecord={recordsTable.setRecord}
                        onDone={reloadRecords}
                        deps={deps}
                    />
                </Left>
                <Right>
                    <BankAccountTransactionsTableRoot>
                        <h3 style={{ marginBottom: 32 }}>
                            {t("accounting.bankAccount.transactions.assignInvoices.subtitle")}
                        </h3>
                        <BankAccountTransactionsTable
                            bankConnectionAndAccount={bankConnectionAndAccount}
                            tableStore={transactionsTable.tableStore}
                            tableFilters={tableFilters}
                            transaction={transaction}
                            setTransaction={transactionsTable.setTransaction}
                            accountComponent={account.component}
                            deps={deps}
                            onClickAssign={handleClickAssign}
                        />
                    </BankAccountTransactionsTableRoot>
                    <div style={{ flex: 1, display: isMobile ? "none" : undefined }} />
                </Right>
            </Root>
        </>
    );
});

const useAccount = (deps: Dependencies) => {
    const { companyId, subsidiaryId } = deps;

    const [value, setValue] = useState<AccountType | null>(null);

    return {
        value,
        component: (
            <Account
                value={value}
                onChange={setValue}
                companyId={companyId}
                subsidiaryId={subsidiaryId}
                placeholder="accounting.bankAccount.transactions.assignInvoices.account.placeholder"
                errorMessage="accounting.bankAccount.transactions.assignInvoices.account.error"
            />
        ),
    };
};
