import { t } from "../../i18n/util";
import { withParamsAndQuery } from "../app/router/history";
import { Stepper } from "../ui/Stepper";
import { HrRoutes } from "./router/HrRoutes";
import { IRegistrationQuery } from "./types";

export const HrEmployeesDeregistrationStepper = (props: { employeeId: string; query: IRegistrationQuery }) => {
    const employeeId = props.employeeId;

    const hrEmployeesDeregistrationSteps = [
        {
            label: t("screen.hr.employee.deregistration.stepper.data"),
            url: withParamsAndQuery(HrRoutes.EMPLOYEES.DEREGISTRATION_DATA, { employeeId }, props.query),
        },
        {
            label: t("screen.hr.employee.deregistration.stepper.documents"),
            optional: true,
            url: withParamsAndQuery(HrRoutes.EMPLOYEES.DEREGISTRATION_DOCUMENTS, { employeeId }, props.query),
        },
    ];

    return <Stepper steps={hrEmployeesDeregistrationSteps} />;
};
