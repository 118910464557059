import { Drawer, DrawerProps } from "@material-ui/core";
import styled from "styled-components";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

interface IProps {
    showCloseIcon?: boolean;
    onClose?: () => void;
}

const CloseIcon = ({ onClick }: { onClick?: () => void }) => (
    <Icon name="close" style={{ color: customColors.primaryColor, cursor: "pointer" }} onClick={onClick} />
);

export const CustomDrawerContent = styled.div`
    padding: 24px;
`;

export const CustomDrawer = ({ showCloseIcon, onClose, children, ...props }: DrawerProps & IProps) => {
    return (
        <Drawer anchor="right" onClose={onClose} {...props}>
            {onClose && showCloseIcon && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 24,
                        paddingBottom: 0,
                    }}
                >
                    <CloseIcon onClick={onClose} />
                </div>
            )}
            {children}
        </Drawer>
    );
};
