import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { CenteredContent } from "../../ui/CenteredContent";
import { CompaniesUserReleaseInfoAdminActionsBar } from "../../ui/CompaniesUserReleaseInfoAdminActionsBar";
import { CompanyOverview } from "../../ui/CompanyOverview";
import { NavBarBack } from "../../ui/NavBarBack";
import { PendingMicrosoftActionsBar } from "../../ui/PendingMicrosoftActionsBar";
import { Routes } from "../router/Routes";

export const CompanyOverviewSite = observer(function CompanyOverviewSite() {
    // Non tpa users with only one company -> go to cockpit
    if (!authStore.isTpa && companiesStore.numCompanies === 1 && companiesStore.selectedCompanyId) {
        if (!companiesStore.canAccessCompany) {
            return <Redirect to={Routes.TERMS_NOT_ACCEPTED} />;
        } else {
            return <Redirect to={Routes.COCKPIT} />;
        }
    }

    return (
        <>
            <NavBarBack title={t("common.companyOverview")} />
            <PendingMicrosoftActionsBar />
            <CompaniesUserReleaseInfoAdminActionsBar />
            <CenteredContent>
                <CompanyOverview />
            </CenteredContent>
        </>
    );
});
