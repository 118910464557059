import React from "react";
import { t } from "../../i18n/util";
import { API, ITableParams } from "../../network/API";
import { Ticket } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { hrStore } from "../hr/HrStore";

export const usePreRegistrationTickets = ({
    companyId,
    tableParams,
}: {
    companyId?: string;
    tableParams: ITableParams;
}) => {
    const [tickets, setTickets] = React.useState<Ticket[]>([]);
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [total, setTotal] = React.useState<number>(0);

    const reloadTickets = React.useCallback(async () => {
        if (!companyId) {
            return;
        }

        if (!hrStore.selectedSubsidiaryId) {
            return;
        }

        try {
            generalStore.beginLoading("tickets");

            const ticketsResponse = await API.getPreRegistrationTickets({
                ...tableParams,
                companyId,
                subsidiaryId: hrStore.selectedSubsidiaryId,
            });

            setTickets(ticketsResponse.tickets ?? []);
            setTotal(ticketsResponse.total);
        } catch (err) {
            generalStore.setError(t("error.loadTickets"), err);
        } finally {
            generalStore.endLoading("tickets");
            setIsInitialized(true);
        }
    }, [companyId, tableParams]);

    React.useEffect(() => {
        reloadTickets();
    }, [reloadTickets]);

    return { tickets, reload: reloadTickets, isInitialized, total };
};
