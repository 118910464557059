import * as React from "react";
import { t } from "../../i18n/util";
import { IEmployee } from "../../types/models";
import { NavBarBack } from "../ui/NavBarBack";
import { PageWithStepper } from "../ui/PageWithStepper";
import { HrEmployeesDeregistrationStepper } from "./HrEmployeesDeregistrationStepper";
import { IRegistrationQuery } from "./types";

export const HrDeregistrationSiteContainer = (props: {
    children: React.ReactNode;
    backTarget?: string;
    cancelTarget?: string;
    backLabel?: string;
    employee: IEmployee;
    query: IRegistrationQuery;
}) => {
    return (
        <>
            <NavBarBack
                title={t("screen.hr.deregistration.navheader", {
                    firstname: props.employee.firstName,
                    lastname: props.employee.lastName,
                })}
                backLabel={props.backLabel ? props.backLabel : t("screen.hr.registration.flow.navback")}
                backTarget={props.backTarget}
                cancelTarget={props.cancelTarget}
            />
            <PageWithStepper
                stepper={<HrEmployeesDeregistrationStepper employeeId={props.employee.id} query={props.query} />}
            >
                {props.children}
            </PageWithStepper>
        </>
    );
};
