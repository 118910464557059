import { observer } from "mobx-react";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { accountingStore } from "../../accounting/AccountingStore";
import { Routes } from "../../app/router/Routes";
import { SideBarItems } from "./SideBarItems";

export const CustomerSideBarItems = observer(function CustomerSideBarItems(props: { onClickItem?: () => void }) {
    const companyStore = companiesStore.selectedCompanyStore;
    const badgeCounts = companyStore?.badgeCounts;

    return (
        <SideBarItems
            items={[
                {
                    iconName: "cockpit",
                    title: t("sidebar.list.cockpit"),
                    target: Routes.COCKPIT,
                    badgeCount:
                        (badgeCounts?.tickets ?? 0) +
                        (badgeCounts?.unreadChatMessages ?? 0) +
                        (badgeCounts?.deadlines ?? 0),
                    enabled: GLOBAL_FEATURES.cockpit,
                },
                {
                    iconName: "accounting",
                    title: t("sidebar.list.accounting"),
                    target: Routes.ACCOUNTING,
                    enabled: GLOBAL_FEATURES.accounting && accountingStore.canAccessSection(),
                },
                {
                    iconName: "personnel",
                    title: t("sidebar.list.hr"),
                    target: Routes.HR,
                    enabled: GLOBAL_FEATURES.hr && companyStore?.hasModule("hr"),
                },
                {
                    iconName: "results",
                    title: t("sidebar.list.results"),
                    target: Routes.RESULTS,
                    enabled: !!companyStore?.canReadResults,
                },
                {
                    iconName: "powerbi",
                    title: t("sidebar.list.analysis"),
                    target: Routes.ANALYSIS,
                    enabled: !!companyStore?.canReadAnalysis,
                },
                {
                    iconName: "projects",
                    title: t("sidebar.list.projects"),
                    target: Routes.PROJECTS,
                    enabled: GLOBAL_FEATURES.projects && !authStore.isTpa && authStore.canReadProjects,
                    badgeCount: (badgeCounts?.openProjectDrafts ?? 0) + (badgeCounts?.projectDraftTickets ?? 0),
                },
                {
                    iconName: "documents",
                    title: t("sidebar.list.documents"),
                    target: Routes.DOCS,
                    enabled: GLOBAL_FEATURES.docs && companyStore?.canReadDocs,
                    badgeCount: badgeCounts?.openGeneralDocumentReleases ?? 0,
                },
                {
                    iconName: "settings",
                    title: t("sidebar.list.settings"),
                    target: Routes.SETTINGS,
                    enabled: GLOBAL_FEATURES.settings && !authStore.isTpa,
                    badgeCount:
                        authStore.isSuperAdmin && companyStore
                            ? companiesStore.getWaitingUsers(companyStore.id).length
                            : undefined,
                },
            ]}
            onClickItem={props.onClickItem}
        />
    );
});
