import { Redirect } from "react-router";
import { authStore } from "../../../stores/AuthStore";
import { Routes } from "../../app/router/Routes";
import { useCopyToClipboard } from "../../hooks/useCopyToClipBoard";
import { ProjectsRouter } from "../router/ProjectsRouter";

export const ProjectsContainerSite = () => {
    const clipboardHook = useCopyToClipboard();
    // Early out if no permissions
    if (!authStore.canReadProjects) {
        return <Redirect to={Routes.COCKPIT} />;
    }

    return <ProjectsRouter clipboard={clipboardHook} />;
};
