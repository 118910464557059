import { useCallback, useRef, useState } from "react";

/**
 * This hook allows you to open a dialog and `await` for the user to submit it.
 *
 * It returns
 * * an `open` function to open the dialog
 * * `dialogProps` to pass directly to the `CustomDialog` component
 * * `additionalProps` that were passed to the `open` function
 */
export function useAwaitableDialog<Value, Props = Record<string, never>>(initialProps = {} as Props) {
    const [resolve, setResolve] = useState<(value: Value) => void>();
    const open = !!resolve;

    const initialPropsRef = useRef(initialProps); // we don't expect the initial props to change
    const [additionalProps, setAdditionalProps] = useState<Props>(initialPropsRef.current);

    const dialogProps = {
        open,
        onClose: useCallback(() => {
            setAdditionalProps(initialPropsRef.current);
            setResolve(undefined);
        }, []),
        onSubmit: useCallback(
            (value: Value) => {
                resolve?.(value);
                setAdditionalProps(initialPropsRef.current);
                setResolve(undefined);
            },
            [resolve],
        ),
    };

    return {
        open: (additionalProps?: Props) => {
            return new Promise<Value>(resolve => {
                if (additionalProps) {
                    setAdditionalProps(additionalProps);
                }
                setResolve(() => resolve);
            });
        },
        dialogProps,
        additionalProps,
    };
}
