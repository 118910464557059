const hungarian = {
  "accounting.bankAccount.account.label": "Számla",
  "accounting.bankAccount.account.placeholder": "Számla",
  "accounting.bankAccount.account.title": "Számla kiválasztása",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "A dokumentumkategóriákat nem sikerült betölteni",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "Dokumentum kategória",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "Kérjük, válassza ki a kezdési és befejezési dátumot.",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "Banki tranzakciók",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "Dokumentum szimbólum",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "Bevételek",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "Nem találtak bizonyítékot",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "Következő dokumentum",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "Előző dokumentum",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "Kérjük, rendelje a bizonylatot egy banki tranzakcióhoz.",
  "accounting.bankAccount.transactions.assignInvoices.title": "Az utalványok kiosztása",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "Nyitott utalványok hozzárendelése",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "Export a bankszámlakivonat könyveléséhez",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "Exportálás az NTCS-ben történő foglalási importáláshoz",
  "accounting.bankAccount.transactions.table.emptyState.title": "Nem talált tranzakciók",
  "accounting.bankAccount.transactions.table.label.amount": "Összeg",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "Dokumentum hozzárendelése",
  "accounting.bankAccount.transactions.table.label.date": "Foglalási dátum",
  "accounting.bankAccount.transactions.table.label.invoice": "Átvételi elismervény",
  "accounting.bankAccount.transactions.table.label.isPrivate": "Privát",
  "accounting.bankAccount.transactions.table.label.recipient": "Vevő",
  "accounting.bankAccount.transactions.table.label.text": "Foglalás szövege",
  "accounting.bankAccount.transactions.table.label.valueDate": "Értéknap",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "Dokumentum link eltávolítása",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "Magánügyletként jelölje meg",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "Magánmegjelölés törlése",
  "accounting.bankAccount.transactions.table.search.count": "Tranzakciók: <b>{szám}</b>",
  "accounting.bankAccount.transactions.table.search.placeholder": "Tranzakciók keresése",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "A nyugta feltöltésre került",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "Válassza ki a vállalat kódját és a dokumentum szimbólumát",
  "accounting.bankAccount.transactions.title": "Tranzakciók",
  "accounting.bankConnection.delete.message": "Tényleg törölni szeretné a {név} bankszámlát?",
  "accounting.bankConnection.delete.title": "Banki adatok törlése",
  "accounting.bankConnections.accountHolder": "Tulajdonos:",
  "accounting.bankConnections.accountType": "Számlatípus:",
  "accounting.bankConnections.accountType.Bausparen": "Építőipari megtakarítások",
  "accounting.bankConnections.accountType.Checking": "Folyószámla",
  "accounting.bankConnections.accountType.CreditCard": "Hitelkártya",
  "accounting.bankConnections.accountType.Loan": "Hitelszámla",
  "accounting.bankConnections.accountType.Membership": "Szövetkezeti részvények",
  "accounting.bankConnections.accountType.Savings": "Megtakarítási számla",
  "accounting.bankConnections.accountType.Security": "Értékpapírszámla",
  "accounting.bankConnections.addBankConnection": "Banki adatok hozzáadása",
  "accounting.bankConnections.balance": "Számlaegyenleg:",
  "accounting.bankConnections.emptyState.description": "Új bankszámla hozzáadása.",
  "accounting.bankConnections.emptyState.title": "Nincsenek banki adatok",
  "accounting.bankConnections.expired": "Lejárt",
  "accounting.bankConnections.expiredAt": "Lejárt a {dátum}",
  "accounting.bankConnections.expiresAt": "Lejár {dátum}",
  "accounting.bankConnections.iban": "IBAN:",
  "accounting.bankConnections.lastSuccessfulUpdate": "per {dátum}",
  "accounting.bankConnections.pending": "Kiváló",
  "accounting.bankConnections.refreshBankConnection": "Bankszámla megújítása",
  "accounting.bankConnections.refreshPending": "Folyamatos megújítások",
  "accounting.bankConnections.refreshPending.info": "A banki adatok megújítása egy külön ablakban fut. Ha az ablakot a frissítés befejezése előtt bezárta, akkor újra a \"Banki adatok megújítása\" gombra kell kattintania.",
  "accounting.bankConnections.refreshPending.label": "Megújítási folyamat folyamatban",
  "accounting.bankConnections.upToDate": "Megerősítve",
  "accounting.details.edit.header": "Dokumentum szerkesztése",
  "accounting.details.updatedAt": "utolsó módosítás",
  "accounting.details.upload.files": "Fájlok",
  "accounting.details.upload.skip": "Ugorj át mindent",
  "accounting.finished.reports": "Jelentések",
  "accounting.table.label.totalGrossAmount": "Számla összege",
  "addFolderDialog.button.text.create": "Létrehozás",
  "addFolderDialog.folderName": "Mappa neve",
  "addFolderDialog.info": "Ugrás Sharepointban a tagok kezeléséhez.",
  "addFolderDialog.title": "Mappa létrehozása",
  "advisor.uploads.uploadReports.title": "Jelentések elküldése",
  "analysis.powerBI.reports.table.label.description": "",
  "analysis.powerBI.reports.table.label.name": "",
  "analysis.powerBI.reports.table.search.count": "",
  "analysis.powerBI.reports.table.search.placeholder": "",
  "analysis.powerBI.reports.title": "",
  "analysis.powerBI.report.title": "",
  "appNotificationDialog.button.accept": "Értettem",
  "appNotificationDialog.title.maintenance": "Karbantartási munkák",
  "appNotificationDialog.title.newFeature": "Új funkciók",
  "appNotificationDialog.title.update": "",
  "branchType.allocationGroup": "Számlázási terület",
  "branchType.costCenter": "Költségbecslés",
  "branchType.department": "Részleg",
  "branchType.financialAccountancyCircle": "FIBU kör",
  "branchType.other": "Egyéb",
  "branchType.wageCompany": "Bérszámfejtő cég",
  "branchType.workplace": "Munkahely",
  "button.activateMfa": "Aktiválás most",
  "button.answer": "VÁLASZ",
  "button.archive": "Archívum",
  "button.assign": "Hozzárendelés",
  "button.back_to_login": "Vissza a bejelentkezéshez",
  "button.closeTicket": "Késznek jelölés",
  "button.configure": "BEÁLLÍTÁS",
  "button.continueInBackground": "Folytatás a háttérben",
  "button.continue_without_microsoft": "Folytatás Microsoft-fiók nélkül",
  "button.copy": "Másolás",
  "button.deactivateMfa": "Deaktiválás most",
  "button.enter": "BELÉPÉS",
  "button.markAsPrivate": "Bizalmasnak megjelölés",
  "button.moreInfo": "TOVÁBBI INFORMÁCIÓK",
  "button.move": "Áthelyezés",
  "button.nextStep": "következő lépés",
  "button.paste": "Beillesztés",
  "button.refresh": "Frissítés",
  "button.release": "Közzététel",
  "button.submitArchiveMultiple": "{count} üzenet archiválása",
  "button.submitArchiveSingle": "{count} üzenet archiválása",
  "button.toOverview": "Ugrás az áttekintéshez",
  "button.transfer": "Küldés",
  "button.unlock": "Feloldás",
  "button.upload": "Fájl feltöltése",
  "chatInput.deleteLink": "Törölje a  címet.",
  "chatInput.editLink": "Szerkesztés",
  "chatInput.enterLink": "Link",
  "chatInput.openLink": "URL megnyitása",
  "chatInput.saveLink": "Mentés",
  "common.abacus": "Abacus",
  "common.accounting": "Könyvelés",
  "common.accounting.period": "Könyvelési időszak",
  "common.accounting.recordType": "Dokumentum szimbólum",
  "common.accounting.subsidiary": "Könyvelési terület",
  "common.add": "Hozzáadás",
  "common.albanian": "Shqiptare",
  "common.all": "Összes",
  "common.amount": "Szám",
  "common.anyDateRange": "Bármely időszak",
  "common.apply": "Módosítások alkalmazása",
  "common.archive": "Archívum",
  "common.back": "Vissza",
  "common.bankConnection": "Banki adatok",
  "common.bankConnections": "Banki adatok",
  "common.bankContact": "Banki adatok",
  "common.bic": "BIC",
  "common.bmd": "BMD",
  "common.bulgarian": "Български",
  "common.cancel": "Mégsem",
  "common.cancelCaps": "Mégsem",
  "common.chat": "Chat",
  "common.clientNumber": "Ügyfélszám",
  "common.cockpit": "Irányítópult",
  "common.companies": "Cég",
  "common.company": "Cég",
  "common.companyNumber": "Cég száma",
  "common.companyOverview": "A vállalat áttekintése",
  "common.companyRegisterNumber": "Cégjegyzékszám",
  "common.continue": "Tovább",
  "common.country": "Ország",
  "common.croatian": "Hrvatski",
  "common.czech": "Česky",
  "common.dateformat": "NN.HH.ÉÉÉÉ",
  "common.dateValidation": "Kérjük, egy érvényes dátumot adjon meg",
  "common.defaultSignature": "Az Ön TPA irodája",
  "common.delete": "Törlés",
  "common.done": "Kész",
  "common.download": "Letöltés",
  "common.dueDate": "Határidő",
  "common.dueUntil": " legkésőbb {dueDate}-ig",
  "common.emailAddress": "E-mail cím",
  "common.english": "English",
  "common.errorOccurred": "Hiba történt",
  "common.events": "Események",
  "common.files": "Fájlok",
  "common.finished": "Kész",
  "common.folder": "Mappa",
  "common.from": "A  címről",
  "common.fullName": "{firstName} {lastName}",
  "common.generalData": "Általános adatok",
  "common.generalDocuments": "Általános fájlok",
  "common.german": "Deutsch",
  "common.global": "Vállalati szinten",
  "common.hr": "Bérszámfejtés",
  "common.hr.period": "Könyvelési időszak",
  "common.hrPreRegistrations": "Munkatársak - előzetes bejegyzések",
  "common.hr.short": "Munkatársak",
  "common.hr.subsidiary": "Számlázási terület",
  "common.hungarian": "Magyar",
  "common.invite": "Meghívás",
  "common.kpi": "Tények és számok",
  "common.kpi.long": "Tények és számok",
  "common.later": "Később",
  "common.logout": "Kijelentkezés",
  "common.me": "Én",
  "common.members": "Tagok",
  "common.moreInformation": "további információk",
  "common.mr": "{name}",
  "common.mrs": "{name}",
  "common.name": "Név",
  "common.no": "Nem",
  "common.none": "nincs",
  "common.noSearchResults": "Nincs találat",
  "common.notAvailable": "N/A",
  "common.notSpecified": "n.a.",
  "common.numberValidation": "Kérjük, adjon meg egy érvényes számot",
  "common.ok": "Ok",
  "common.optional": "(opcionális)",
  "common.orderBy": "Rendezés",
  "common.partial": "részben",
  "common.people": "Személyek",
  "common.polish": "Polski",
  "common.profile": "Profil",
  "common.project": "Projekt",
  "common.projects": "Projektek",
  "common.readingTimeMinutes": "Olvasási idő: {time} perc",
  "common.readingTimeMinutes.short": "{time} perc",
  "common.recipient": "Címzett",
  "common.refresh": "Frissítés",
  "common.releasedAt": "megjelent {dátum}",
  "common.releasedAtBy": "Jóváhagyta: {name}, dátum: {date}",
  "common.releaseRequired.plural": "{count} Releases required",
  "common.releaseRequired.singular": "Feloldás szükséges",
  "common.remainingItems": "+{count} több",
  "common.remainingItemsUnknown": "Töltsön többet",
  "common.retry": "Próbálja újra",
  "common.romanian": "Românesc",
  "common.russian": "Русский",
  "common.save": "Mentés",
  "common.sendCaps": "KÜLDÉS",
  "common.serbian": "Српски",
  "common.settings": "Beállítások",
  "common.showAll": "Mutasd mindet",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "Cím",
  "common.taxNumber": "Adószám",
  "common.termsOfUse": "Felhasználási feltételek",
  "common.ticket": "Lekérdezés",
  "common.timePeriod": "Időszak",
  "common.timePeriod.olderThan1Month": "Egy hónapnál régebbi",
  "common.timePeriod.olderThan1Week": "Egy hétnél régebbi",
  "common.timePeriod.olderThan1Year": "Egy évnél régebbi",
  "common.timePeriod.olderThan6Months": "6 hónapnál régebbi",
  "common.to": "Amíg",
  "common.tpaFirstName": "TPA",
  "common.tpaLastName": "Alkalmazottak",
  "common.unknown": "Ismeretlen",
  "common.upcomingTermsOfUse": "Jövőbeni felhasználási feltételek",
  "common.upload": "Feltöltés",
  "common.uploadFiles": "Fájlok feltöltése",
  "common.user": "Felhasználók",
  "common.vatNumber": "Adószám",
  "common.yes": "Igen",
  "commuterAllowance.IndividuellePendlerpauschale": "Egyéni ingázási támogatás",
  "commuterAllowance.KeinePendlerpauschale": "Nincs ingázási támogatás",
  "commuterAllowance.MehrAls20kmKeinBus": "Több mint 20km, nincs busz",
  "commuterAllowance.MehrAls40kmBus": "Több mint 40km, busz",
  "commuterAllowance.MehrAls40kmKeinBus": "Több mint 40km, nincs busz",
  "commuterAllowance.MehrAls60kmBus": "Több mint 60km, busz",
  "commuterAllowance.MehrAls60kmKeinBus": "Több mint 60km, nincs busz",
  "commuterAllowance.Mindestens20kmBus": "Legalább 20km, busz",
  "commuterAllowance.Mindestens2kmKeinBus": "Legalább 2km, nincs busz",
  "companiesUserReleaseInfoActionsBar.message": "Van {számlálható} új felhasználója, akit meg kell osztania",
  "companiesUserReleaseInfoActionsBar.submit": "Megtekintés és megosztás",
  "companiesUserRelease.permissions.navbar.back": "Felhasználók megosztása és meghívása / szerepkör hozzárendelése",
  "companiesUserRelease.title": "Megosztás és felhasználók meghívása",
  "companyImages.cover.error": "A borítókép nem tölthető be",
  "companyImages.cover.title": "Borítókép",
  "companyImages.delete.message": "Biztosan törölni akarja a képet?",
  "companyImages.delete.title": "Kép törlése",
  "companyImages.logo.error": "A vállalat logóját nem lehetett betölteni",
  "companyImages.logo.title": "A vállalat logója",
  "companyImages.title": "Borítókép és az Ön vállalatának logója (opcionális)",
  "companyList.remainingCompanies": "+{remainingCompanies} Továbbiak",
  "company.optionalSideBarMenuItems.maxLength.name": "A név legfeljebb 15 karaktert tartalmazhat",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "Az opcionális menüpont már elérhető",
  "company.optionalSideBarMenuItems.name.label": "Név",
  "company.optionalSideBarMenuItems.required.name": "A nevet meg kell adni",
  "company.optionalSideBarMenuItems.required.url": "Az URL-t meg kell adni",
  "company.optionalSideBarMenuItems.title": "A vállalat opcionális menüpontjai",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "Menüpont eltávolítása",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "Az opcionális menüponthoz tartozó link már létezik",
  "company.optionalSideBarMenuItems.url.label": "URL",
  "company.optionalSideBarMenuItems.urlNotValid": "Kérjük, érvényes címet adjon (pl. https://www.tpa-connect.app )",
  "companyOverview.emptyState.message": "Kérjük, forduljon tanácsadójához",
  "companyOverview.emptyState.title": "Nincs aktív vállalat",
  "companyOverview.emptyState.tpa.message": "Jelenleg nincsenek aktív ügyfelei",
  "companyOverview.emptyState.tpa.title": "Nincsenek aktív ügyfelek",
  "companyOverview.unlockCountry.tooltip": "Új országok feloldása<br /><br /> Ismerje meg <b>{country}</b> nemzetközi projektjeit hogy felhasználhassa a vállalat projektjeinek jellemzőit és megtekinthesse az összes lekérdezést.",
  "companyStatus.accountingSetupDone": "0/2 kész",
  "companyStatus.active": "aktív",
  "companyStatus.hrSetupDone": "1/2 kész",
  "companyStatus.inactive": "inaktív",
  "companyStatus.userSetupDone": "2/2 kész",
  "config.accounting.accountingByCustomer": "Az ügyfelektől a TPA Connect-en keresztül kapott könyvelési adatok továbbítása ",
  "config.accounting.delete.subsidiaries.last.message": "A(z) {name} könyvelési terület nem törölhető. Legalább egy könyvelési területnek léteznie kell.",
  "config.accounting.delete.subsidiaries.message": "Tényleg törölni szeretné a(z) {name} könyvelési területet?",
  "config.accounting.delete.subsidiaries.title": "Könyvelési terület törlése",
  "config.accounting.description": "Határozza meg a könyvelési terület jellegét és terjedelmét.",
  "config.accounting.description.customer": "A TPA tanácsadója beállította ezt a területet az Ön számára. Esetleges változtatásokért bármikor forduljon TPA-tanácsadójához.",
  "config.accounting.due.accounting.reports.select.title": "A könyvelés beküldése után eltelt napok",
  "config.accounting.dueDates.title": "Könyvelési anyagok beküldése",
  "config.accounting.due.reportRelease.select.subtitle": "Például 4 nappal a jelentések TPA általi feltöltése után. Ez a következő hónap 12. napját jelenti (ha a jelentések TPA általi feltöltése a következő hónap 8-án történt).",
  "config.accounting.due.reportRelease.select.title": "A jelentések feltöltése óta eltelt napok",
  "config.accounting.due.reportRelease.select.value": "{day} nap a jelentések feltöltését követően",
  "config.accounting.due.reportRelease.title": "Jelentések ügyfelek általi jóváhagyása",
  "config.accounting.due.reports.select.subtitle": "Például 3 nappal a könyvelés beküldése után. Ez azt jelenti, hogy a következő hónap 8-án (ha a könyvelés beküldése a következő hónap 5-én történt).",
  "config.accounting.due.reports.select.value": "{day} nap a könyvelés beküldését követően",
  "config.accounting.due.reports.title": "A jelentések továbbítása a TPA által",
  "config.accounting.due.transfer.select.subtitle": "Például a következő hónap 5-én.",
  "config.accounting.due.transfer.select.title": "Küldés a következő időpontig",
  "config.accounting.due.transfer.select.value": "{day} nap az időszak végét követően",
  "config.accounting.due.transfer.title": "Könyvelés beküldése ügyfelek által",
  "config.accounting.globalReports.description": "Az ügyfélnek vállalati szintű jelentései vannak",
  "config.accounting.globalReports.title": "Vállalati szintű jelentések",
  "config.accounting.hasAccounting": "Az ügyfélnek van könyvelése",
  "config.accounting.hasBankAccountTransactionInvoices": "Dokumentumok hozzárendelése banki tranzakciókhoz",
  "config.accounting.hasCockpitKpi": "Tények és számok az irányítópultban",
  "config.accounting.hasKpi": "Tények és számok",
  "config.accounting.hasResults": "Értékelések",
  "config.accounting.incaseoflaw.description": "Az ügyfél rendelkezik a 'Jogesetben' funkcióval",
  "config.accounting.incaseoflaw.label": "Jogesetben",
  "config.accounting.incaseoflaw.title": "Jogesetben",
  "config.accounting.month.description": "Egy hónapos időtartamot tartalmaz",
  "config.accounting.month.title": "Havi könyvelés",
  "config.accounting.quarter.description": "3 hónapos időtartamot foglal magában",
  "config.accounting.quarter.title": "Könyvelés negyedévente",
  "config.accounting.recordTypes": "Könyvelési bizonylatok",
  "config.accounting.recordTypes.ocrDisabled": "Automatikus dokumentumfelismerés (OCR) kikapcsolva",
  "config.accounting.recordTypes.ocrEnabled": "Automatikus dokumentumfelismerés (OCR) aktiválva",
  "config.accounting.requireTwoReportReleases": "A jelentések két felhasználó általi jóváhagyása szükséges (kettős ellenőrzési elv)",
  "config.accounting.subsidiaries.add": "Könyvelési terület hozzáadása",
  "config.accounting.subsidiaries.title": "Könyvelési területek meghatározása",
  "config.accounting.subsidiaries.title.customer": "Könyvelési területek",
  "config.accounting.subsidiary.maxReached": "Elérte a könyvelési területek maximális számát",
  "config.accounting.subsidiary.name": "Könyvelési terület neve",
  "config.accounting.week.description": "5 munkanapos időtartamú, naptári hetekre osztva.",
  "config.accounting.week.title": "Heti könyvelés",
  "config.bulk.button.configure": "A  beállítása",
  "config.bulk.data.description": "Itt tölthet fel egy borítóképet és a cégek logóját.",
  "config.bulk.data.title": "Törzsadatok",
  "config.bulk.finish.description": "A vállalatokat konfigurálják. Ez néhány percig eltarthat, kérjük, ne zárja be a böngészőablakot.",
  "config.bulk.finish.start": "Indítsa el a  oldalt.",
  "config.bulk.finish.title": "Konfiguráció véglegesítése",
  "config.companies": "Vállalat beállításai",
  "config.companies.buttonConfig": "Vállalat beállítása",
  "config.companies.count": "{szám} Vállalat",
  "config.companies.description": "Ebben a lépésben meghatározza a vállalatra jellemző folyamatokat. Ezek közé tartozik a TPA Connect megjelenítési felülete, a könyvelés és a bérszámfejtés típusa és terjedelme, valamint a határidők.",
  "config.companyData.cockpit": "Cockpit",
  "config.companyData.description": "Itt tölthet fel egy borítóképet és a vállalat logóját, valamint beállíthatja a könyvelési feltételeket.",
  "config.companyData.title": "Törzsadatok",
  "config.companyOverview.inactive": "A vállalat áttekintése / Inaktív",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "Igen, törlés",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "Valóban szeretné ezt a menüpontot törölni?",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "Menüpont törlése",
  "config.customMenuPoints.noMenuPointsAvailable": "Nincsenek elérhető menüpontok",
  "config.employees.assignRole": "Szerep hozzárendelése",
  "config.employees.emptyState.message": "Jelenleg nincsenek aktív alkalmazottak",
  "config.employees.emptyState.title": "Nincsenek aktív alkalmazottak",
  "config.employees.table.missingEmail": "E-mail cím hiányzik",
  "config.employees.table.missingEmail.tooltip": "Az e-mail címet egy TPA tanácsadónak kell beírnia a BMD-be.",
  "config.features.alreadyToggledToday.disabled": "A bővítményt ma már deaktiválták.",
  "config.features.alreadyToggledToday.enabled": "A bővítményt ma már aktiválták",
  "config.features.confirm.message.disabled": "Ha aktiválja a bővítményt, a díjköteles bővítmény azonnal elérhetővé válik, és a mai naptól kezdve számlázásra kerül. A bővítményt holnap újra deaktiválhatja.",
  "config.features.confirm.message.enabled": "A fizetős kiegészítő a nap végén deaktiválódik. A mai napra továbbra is felszámításra kerül. A bővítményt holnaptól újra aktiválhatja.",
  "config.features.confirm.title.disabled": "Aktiválja a kiegészítőt",
  "config.features.confirm.title.enabled": "Kiegészítő deaktiválása",
  "config.finishConfiguration": "Beállítás véglegesítése",
  "config.finishConfiguration.cockpit": "Az irányítópulthoz",
  "config.finishConfiguration.continue": "Új vállalat létrehozása",
  "config.finishConfiguration.emailNotification": "Felhasználók értesítése e-mailben",
  "config.finishConfiguration.message": "A(z) {company} beállításai sikeresek. Most már használható a TPA és az ügyfél közötti adatcserére.",
  "config.finishSetup": "KILÉPÉS A BEÁLLÍTÁSOKBÓL",
  "config.hr.chat.description": "A fizetett alkalmazottak felvehetik a kapcsolatot a TPA-tanácsadókkal.",
  "config.hr.chat.title": "Chat",
  "config.hr.delete.subsidiaries.last.message": "A(z) {name} számlázási terület nem törölhető. Legalább egy bérszámfejtési területnek léteznie kell.",
  "config.hr.delete.subsidiaries.message": "Tényleg törölni szeretné a(z) {name} számlázási területet?",
  "config.hr.delete.subsidiaries.title": "Számlázási terület törlése",
  "config.hr.description": "Határozza meg a bérszámfejtési terület jellegét és hatókörét.",
  "config.hr.dueDates.reminder": "Emlékeztető a határidőkre",
  "config.hr.dueDates.title": "A bérszámfejtés határideje",
  "config.hr.due.relativeDueDates": "A dokumentumok kézbesítése a következő hónapban",
  "config.hr.due.reports.select.subtitle": "Például 3 nappal a bérszámfejtés továbbítása után. Ez a következő hónap 8. napját jelenti (ha a bérszámfejtés továbbítása a következő hónap 5-én történt).",
  "config.hr.due.reports.select.title": "A bérszámfejtés beküldését követő napok",
  "config.hr.due.reports.select.value": "{day} nap a bérszámfejtés beküldését követően",
  "config.hr.due.select.value": "a hónap {day}. napja",
  "config.hr.due.transfer.select.subtitle": "Például a hónap 5-én.",
  "config.hr.due.transfer.select.value.relativeDueDates": "{day} nap a hónap végét követően",
  "config.hr.due.transfer.title": "A bérszámfejtés továbbítása az ügyfelek által",
  "config.hr.hasHr": "Az ügyfélnek van bérszámfejtése",
  "config.hr.hasResults": "Elemzések",
  "config.hr.recordTypes": "Bérszámfejtési dokumentumok",
  "config.hr.releaseSteps.oneStep.description": "A TPA minden havi értékelést egy lépésben továbbít az ügyfélnek. Az ügyfélnek egy meghatározott határidőn belül minden szükséges dokumentumot ki kell adnia.",
  "config.hr.releaseSteps.oneStep.title": "egy lépésben",
  "config.hr.releaseSteps.title": "A közzététel lépései",
  "config.hr.releaseSteps.twoSteps.description": "A havi értékelés továbbítása és közzététele két lépésben történik. Az ügyfél az összes többi havi értékelés kiadása előtt feloldhatja az elszámolásokat.",
  "config.hr.releaseSteps.twoSteps.title": "két lépésben",
  "config.hr.requireTwoReportReleases": "A jelentések két felhasználó általi jóváhagyása szükséges (kettős ellenőrzési elv)",
  "config.hr.sendPayroll.description": "Jövedelemigazolások benyújtása elektronikus úton",
  "config.hr.sendPayroll.title": "Elektronikus jövedelemigazolás",
  "config.hr.sendPayroll.tooltip": "A bérlap kiállításakor minden munkavállaló automatikusan hozzáférést kap a TPA Connect alkalmazáshoz, amelyen keresztül megtekinthetik saját elektronikus bérlapjukat, valamint munkavállalói fájljukat.",
  "config.hr.subsidiaries.add": "Számlázási terület hozzáadása",
  "config.hr.subsidiaries.info": "Az első számlázási terület kiválasztása után már csak az azonos típusú területek választhatók ki (pl. költségtulajdonos). Új típus (pl. FIBU-kör) kiválasztásához egy számlázási terület kivételével az összeset törölni kell.",
  "config.hr.subsidiaries.title": "A számlázási területek meghatározása",
  "config.hr.subsidiaries.title.customer": "Számlázási területek",
  "config.hr.subsidiary.maxReached": "Elérte a számlázási területek maximális számát",
  "config.hr.subsidiary.name": "A számlázási terület neve",
  "config.hr.upload.description": "A fizetett alkalmazottak dokumentumokat tölthetnek fel személyi aktájukba",
  "config.hr.upload.title": "Dokumentumok feltöltése a személyzeti fájlba",
  "config.hr.warning.noBranch": "A bérszámfejtés nem aktiválható. Kérjük, hozzon létre egy bérszámfejtő céget az NTCS-ben.",
  "config.moduleTypeDetails.asp.helpInstructions": "Utasítások ASP ügyfelek",
  "config.moduleTypeDetails.asp.helpInstructions.open": "Az utasításokhoz",
  "config.moduleTypeDetails.label.database": "Adatbázis",
  "config.moduleTypeDetails.label.dataSource": "Adatforrás",
  "config.moduleTypeDetails.label.url": "URL",
  "config.noDueDate": "Nincs határidő",
  "config.optionalMenuPoints.success.title": "A menüelemek sikeresen mentve",
  "config.overview": "Konfiguráció áttekintése",
  "config.permission.change": "új beállítás: \"{permission}\"",
  "config.permissions.accounting.globalReports": "Vállalati szintű számviteli jelentések",
  "config.permissions.deleted": "(törölve)",
  "config.permissions.description": "Válassza ki azokat a területeket, amelyekhez a felhasználó hozzáférhet. Az egyes számviteli területekhez különböző beállításokat végezhet.",
  "config.permissions.employees": "Alkalmazottak",
  "config.permissions.existingUser.navbar.back": "Konfiguráció áttekintése / Felhasználók kezelése / Szerepkör hozzárendelése",
  "config.permissions.existingUser.navbar.backToUser": "Konfiguráció áttekintése / Felhasználók kezelése / Szerepkör hozzárendelése / {employeeName}",
  "config.permissions.global": "Vállalati szintű",
  "config.permissions.internationalProjects.info": "Projektengedélyek nem vonhatók vissza: a nemzetközi projektekkel rendelkező felhasználók automatikusan megkapják az engedélyeket",
  "config.permissions.navbar.back": "Konfiguráció áttekintése / Felhasználók kezelése / Kapcsolattartó kiválasztása / Szerepkör hozzárendelése",
  "config.permissions.navbar.backToUser": "Konfiguráció áttekintése / Felhasználók kezelése / Kapcsolattartó kiválasztása / Szerepkör hozzárendelése / {employeeName}",
  "config.permissions.noPermissionSelected.message": "Válasszon ki legalább egy engedélyt.",
  "config.permissions.noPermissionSelected.title": "Nincs kiválasztott engedély",
  "config.permissions.title": "Engedélyek beállítása",
  "config.roles.change": "Szerep megváltozott {oldRole} szerepről {newRole} szerepre",
  "config.roles.customizePermissions": "Engedélyek adaptálása",
  "config.roles.description": "Válasszon ki egy előre meghatározott jogosultságokkal rendelkező szerepet. Szükség esetén egy további lépésben módosíthatja az engedélyeket.",
  "config.roles.multipleCompaniesDialog.message": "A {név} felhasználó a következő vállalatoknál létezik. Az összes vállalatnál el szeretné végezni a jogosultságok módosítását?",
  "config.roles.multipleCompaniesDialog.title": "Változások alkalmazása több vállalatnál",
  "config.roles.multipleCompaniesDialog.warning": "A vállalatokat nem lehetett betölteni. A módosítások csak az aktuális vállalatra vonatkoznak.",
  "config.roles.navbar.back": "Konfiguráció áttekintése / Felhasználók kezelése / Kapcsolattartók kiválasztása",
  "config.roles.navbar.heading": "{employeeCount} kiválasztott felhasználó",
  "config.roles.option.copy.description": "Adja meg a felhasználó nevét, hogy átvegye szerepét és jogosultságait. A következő lépésben szükség esetén módosíthatja az engedélyeket.",
  "config.roles.option.copy.label": "Szerepkör átvétele egy másik felhasználótól",
  "config.roles.option.copy.placeholder": "Felhasználó neve",
  "config.roles.releaseInformation": "A <b>{userName}</b> felhasználói beállításainak módosításához egy <b>Főadminisztrátor</b>nak jóvá kell hagynia/el kell utasítania a módosításokat.",
  "config.roles.releaseInformation.info.message": "Ha bárki (a főadminisztrátoron kívül) módosítja a felhasználói beállításokat, ezeket a módosításokat a főadminisztrátornak kell engedélyeznie vagy elutasítania. Addig a felhasználó beállításai zárolva vannak, és nem szerkeszthetők.",
  "config.roles.releaseInformation.info.title": "Miért nem lehet szerkeszteni a felhasználói beállításokat?",
  "config.roles.releaseInformation.superAdmin": "{name} változtatásokat hajtott végre <b>{userName}</b> felhasználónál. A folytatáshoz kérjük, hagyja jóvá a felhasználó módosításait.",
  "config.roles.sidebar.title": "Kiválasztott felhasználók",
  "config.roles.submit": "Felhasználó ajánlása",
  "config.roles.submit.existingUser": "változtatások ajánlása",
  "config.roles.submit.superAdmin": "Felhasználó meghívása",
  "config.roles.title": "Szerep hozzárendelése",
  "config.roles.viewPermissions": "Engedélyek megtekintése",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "Rendben, mentés",
  "config.terms.allSigneesAcceptedWarningDialog.message": "Figyelem: Mivel az összes hozzáadott személy már aláírta, a cég a mentés után aktiválódik.",
  "config.terms.allSigneesAcceptedWarningDialog.title": "Felhasználási feltételek mentése",
  "config.terms.alreadySigned": "A vállalkozása felhasználási feltételeit már aláírták.",
  "config.terms.button.revokeActivation": "Aktiválás visszavonása",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "igen, dokumentum módosítása",
  "config.terms.changeDocumentVersionDialog.message": "Ha folytatja, minden eddig hozzáadott e-mail címnek újra el kell fogadnia a felhasználási feltételeket. ",
  "config.terms.changeDocumentVersionDialog.title": "Dokumentum módosítása",
  "config.terms.deleteEmailDialog.confirmLabel": "igen, e-mail cím törlése",
  "config.terms.deleteEmailDialog.message": "Valóban törölni szeretné az e-mail címet?",
  "config.terms.deleteEmailDialog.title": "E-mail cím törlése",
  "config.terms.documentVersion": "Dokumentum verzió",
  "config.terms.emailAddressesInfo": "Adja hozzá a kívánt személyek e-mail címét, akiket e-mailben fel kell kérni a vonatkozó felhasználási feltételek aláírására. Csak az aláírásra jogosultak e-mail címét adja meg! A felhasználási feltételek csak akkor kerülnek elküldésre az alábbi e-mail címekre, amikor a vállalati és felhasználói konfiguráció véglegesítésre került.",
  "config.terms.emailAddressesTitle": "Email címek",
  "config.terms.emailAlreadyUsed": "Ezt az email címet már hozzáadták",
  "config.terms.invalidEmail": "Érvénytelen email cím",
  "config.terms.noDocumentsAvailable": "Jelenleg nincsenek elérhető dokumentumok",
  "config.terms.noSigneesAvailable": "Még nem érhetők el aláírók",
  "config.termsOfUse.navbar.back": "Beállítások áttekintése / Törzsadatok",
  "config.terms.removeEmail": "Email cím törlése",
  "config.terms.resendEmail.header": "Email elküldve",
  "config.terms.resendEmail.message": "A felhasználási feltételek elfogadására vonatkozó emailt ismét elküldtük",
  "config.terms.resendMail": "Küldje újra az emailt",
  "config.terms.revokeActivationDialog.confirmLabel": "igen, aktiválás visszavonása",
  "config.terms.revokeActivationDialog.message": "Biztos, hogy vissza akarja vonni a cég aktiválását? Minden regisztrált személynek újra alá kell írnia a felhasználási feltételeket, hogy újra aktiválhassa a vállalatot.",
  "config.terms.revokeActivationDialog.title": "Aktiválás visszavonása",
  "config.terms.signedVersion": "aláírt verzió:",
  "config.terms.skip.button": "ugorj át",
  "config.terms.skip.dialog.message": "Az ügyféloldali felhasználók csak akkor jelentkezhetnek be, ha elfogadták a felhasználási feltételeket. Addig csak TPA-belső célokra érhető el a Connect alkalmazásban.",
  "config.terms.skip.dialog.title": "Információ",
  "config.terms.success.header": "Mentés sikeres",
  "config.terms.termsOfUseInfo": "Válassza ki a megfelelő dokumentumverziót",
  "config.terms.termsOfUseTitle": "Felhasználási feltételek",
  "config.users": "Felhasználói adminisztráció",
  "config.users.active.title": "Aktív felhasználók",
  "config.users.assignRole": "Szerep hozzárendelése",
  "config.users.buttonConfig": "Felhasználók kezelése",
  "config.users.changeUsername": "Új email",
  "config.users.changeUsername.tooltip": "A felhasználó új e-mail címe {newUsername}. A gombra kattintva a felhasználónak meg kell erősítenie az új e-mail címet.",
  "config.users.description": "Felhasználók vagy felhasználói csoportok hozzárendelése az egyes folyamatokhoz. Ön határozza meg, hogy ki és milyen mértékben férhet hozzá mely területekhez.",
  "config.users.edit": "Változás",
  "config.users.emptyState.message": "Jelenleg nincsenek aktív felhasználók<br/>A szuperadmin létrehozásához használja a BMD NTCS-t.",
  "config.users.emptyState.title": "Nincsenek aktív felhasználók",
  "config.users.inviteAgain": "Újra meghívni",
  "config.users.navBar.back": "Konfigurációs áttekintés / felhasználói adminisztráció",
  "config.users.new": "Új",
  "config.users.newUsers": "Új felhasználó létrehozása",
  "config.users.newUsers.description": "Válassza ki azokat a kapcsolattartókat, akiket TPA Connect felhasználóként szeretne létrehozni. A következő lépésben a kiválasztott személyekhez szerepköröket rendelhet, és szükség esetén módosíthatja az előre definiált jogosultságokat.",
  "config.users.newUsers.title": "Kapcsolattartó személy kiválasztása",
  "config.users.reject": "Hanyatlás",
  "config.users.release": "Jóváhagyás",
  "config.users.releaseAndInvite": "Jóváhagyás és meghívás",
  "config.users.table.invalidEmail": "E-mail cím érvénytelen",
  "config.users.table.invalidEmail.tooltip": "Az e-mail címet a BMD TPA tanácsadójának kell kijavítania.",
  "config.users.waiting.title": "Várakozás jóváhagyásra",
  "cookies.acceptAll": "Minden cookie engedélyezése",
  "cookies.acceptFunctional": "Csak szükséges cookie-k használata",
  "cookies.cookieList": "Cookie nyilatkozat",
  "cookies.text": "Szolgáltatásunk optimalizálása érdekében sütiket használunk. Erről további részleteket a {link} oldalon talál.",
  "country.al": "Albánia",
  "country.at": "Ausztria",
  "country.bg": "Bulgária",
  "country.cz": "Cseh Köztársaság",
  "country.hr": "Horvátország",
  "country.hu": "Magyarország",
  "country.me": "Montenegró",
  "country.pl": "Lengyelország",
  "country.ro": "Románia",
  "country.rs": "Szerbia",
  "country.si": "Szlovénia",
  "country.sk": "Szlovákia",
  "deleteDialog.message.plural": "Tényleg törölni szeretné a <b>{documents}</b> dokumentumokat?<br/><br/>Ezt a műveletet nem lehet visszaonni.",
  "deleteDialog.message.singular": "Tényleg törölni szeretné a <b>{documents}</b> dokumentumot?<br/><br/>Ezt a műveletet nem lehet visszavonni.",
  "deleteDialog.title": "Dokumentumok törlése?",
  "deleteUser.message": "Biztos, hogy törölni szeretné a <b>{név}</b> felhasználót?",
  "deleteUser.multipleCompanies.confirmLabel": "A felhasználó törlése minden vállalatnál",
  "deleteUser.multipleCompanies.message": "A felhasználó a következő vállalatoknál van jelen:",
  "deleteUser.title": "Felhasználó törlése",
  "deleteUser.warning.superadminskipped": "Figyelem! A törléskor kihagyásra kerülnek azok a vállalatok, amelyekben a felhasználók Superadmin szerepkörrel rendelkeznek.",
  "dialog.archive.checkbox_pdf": "PDF helyi mentése",
  "dialog.archive.subject": "Tárgy",
  "dialog.archive.success.text": "Az archivált üzeneteket mostantól a {bmd}-ben érheti el.",
  "dialog.archive.success.title": "Üzenetek sikeresen archiválva!",
  "dialog.archive.text": "Válassza ki azt a vállalatot, amelynek kiválasztott üzeneteit a BMD-ben kívánja tárolni.",
  "dialog.archive.title_multiple": "{count} Archivált üzenetek",
  "dialog.archive.title_single": "1 archivált üzenet",
  "dialog.assignTicket.header": "Megkeresés hozzárendelése",
  "dialog.assignTicket.message": "Válassza ki azt a személyt, akinek a konzultációt ki akarja osztani.",
  "dialog.button.understood": "Értettem",
  "dialog.closeTicket.success.header": "Konzultáció megtörtént",
  "dialog.companyConfig.success.header": "A vállalati konfiguráció sikeresen mentve.",
  "dialog.companyConfig.success.message": "A {name} területei már készen állnak.",
  "dialog.eldaTransfer.andNext": " és tovább",
  "dialog.eldaTransfer.downloadLater": "Letöltés később",
  "dialog.eldaTransfer.success.header": "Sikeresen benyújtott pályázat",
  "dialog.loginError.multiFactorCodeWrong.title": "A megadott kód helytelen",
  "dialog.loginError.subtitle": "Kérjük, próbálja meg újra.",
  "dialog.loginError.title": "Ismeretlen e-mail cím vagy rossz jelszó",
  "dialog.noPhoneNumberWarning.message": "A kiválasztott kapcsolatok közül legalább egynek nincs beállított telefonszáma. Telefonszám nélkül a kétfaktoros hitelesítés nem érhető el, és a meghívás nem biztos, hogy biztonságos.",
  "dialog.noPhoneNumberWarning.title": "Nincs telefonszám beállítva",
  "dialog.passwordExpired.message": "A jelszava lejárt, és meg kell változtatni. Hamarosan kapni fog egy e-mailt a jelszó megváltoztatásáról. Ha bármilyen kérdése van, kérjük, lépjen kapcsolatba a TPA-val a {telefonszám} címen. Köszönjük szépen!",
  "dialog.passwordExpired.title": "Jelszó lejárt",
  "dialog.password_reset.success.header": "Jelszó sikeresen megváltozott",
  "dialog.registration.success.button": "Jelentkezzen be most",
  "dialog.registration.success.header": "Sikeresen regisztráltál!",
  "dialog.registration.success.message": "Jelentkezzen be a Microsoftnál a TPA Connect használatához.",
  "dialog.transferReports.success.message": "Ügyfele mostantól megtekintheti a fájlokat, és bármilyen kérdéssel kapcsolatba léphet Önnel.",
  "dialog.transfer.success.header": "Sikeresen továbbított fájlok",
  "dialog.userConfig.success.header": "Felhasználói beállítások mentése",
  "dialog.user_not_found.button": "Értettem",
  "dialog.user_not_found.subtitle": "Lépjen kapcsolatba a rendszeradminisztrátorral, vagy jelentkezzen be a TPA Connect fiókjával.",
  "dialog.user_not_found.title": "Nincs ismert felhasználói fiók ezen az e-mail címen",
  "documentUpload.error.amount": "<b>{failedUploads}</b> a {uploads} fájlokat nem sikerült feltölteni:",
  "documentUpload.error.button.text.continue": "Folytatás ezen fájlok nélkül",
  "documentUpload.error.button.text.retry": "Próbálja újra",
  "documentUpload.error.message": "Fájl feltöltési hiba",
  "documentUpload.error.rejection.amount": "<b>{failedUploads}</b> a {uploads} fájlokat nem lehet feltölteni:",
  "documentUpload.error.tooBigImages.amount": "<b>{failedUploads}</b> A képek nagyobbak, mint a maximális {maxImageDimension} pixel. Ezek mérete automatikusan lecsökken. Folytatni?",
  "documentUpload.error.tooBigImages.amount.singular": "A kép nagyobb, mint a maximális {maxImageDimension} pixel. Automatikusan lecsökken a mérete. Folytatni?",
  "documentUpload.error.tooBigImages.button.text.continue": "Csökkentés és folytatás",
  "documentUpload.error.tooBigImages.pdf": "A PDF-ben lévő képek nagyobbak, mint a max. {maxImageDimension} pixel. Kérjük, csökkentse a képek méretét.",
  "documentUpload.error.tooManyFiles.plural": "Legfeljebb <b>{szám}</b> fájlokat lehet feltölteni:",
  "documentUpload.error.tooManyFiles.singular": "Maximum <b>egy</b> fájl tölthető fel:",
  "documentUpload.max.size": "Maximális dokumentumméret {maxUploadSizeMb} Mb",
  "documentUpload.tooBigImages.message": "Túl nagy képek",
  "documentUpload.type.file.formats": "Támogatott formátumok",
  "dropzone.button.label.search": "keresés",
  "dropzone.image.text": "Egyszerűen húzza a képet erre a területre vagy",
  "dropzone.info.redo": "Újra feltöltés",
  "dropzone.info.text": "Egyszerűen húzza a fájlokat erre a területre vagy",
  "dueDate.answerAt": "{dátum}-ig kell válaszolni",
  "dueDate.closedAt": "befejeződött {dátum}",
  "dueDate.sentAt": "küldött {dátum}",
  "dueDate.sentAtWithTime": "elküldve {dátum} {időpontban}",
  "dueDate.transferredAt": "továbbított {dátum}",
  "dueDate.until": "{dátum}",
  "employmentInsuranceType.apprentice": "Szakmunkástanuló",
  "employmentInsuranceType.fullTime": "teljes körűen biztosított",
  "employmentInsuranceType.independentContractor": "önálló vállalkozó",
  "employmentInsuranceType.marginalIndependentContractor": "marginális önálló vállalkozók",
  "employmentInsuranceType.marginally": "marginálisan",
  "employmentRelationship.employee": "Alkalmazott",
  "employmentRelationship.worker": "Munkavállalók",
  "error.accounting.postSubsidiary": "A cégkódot nem sikerült létrehozni",
  "error.accounting.subsidiaryDoesNotExist": "Nem talált cégkód",
  "error.activate": "Aktiválási hiba",
  "error.addBankAccountTransactionInvoices": "A tranzakciót és a dokumentumokat nem lehetett összekapcsolni",
  "error.addBankConnection": "A banki adatokat nem lehetett hozzáadni",
  "error.addCompanyToUserFavorites": "A vállalatot nem lehetett hozzáadni a kedvenceihez",
  "error.addFolderBadRequest": "A mappát nem sikerült létrehozni. Győződjön meg róla, hogy nem használt engedély nélküli speciális karaktereket: \" * : < > ? / \\ |",
  "error.archiveDisabledForCompany": "Archiválás nem lehetséges ennél a cégnél",
  "error.archiveFailed": "Hiba az archiválás során",
  "error.archiveProjectFailed": "A projektet nem lehetett archiválni",
  "error.avatarUploadFailed": "A profilképet nem sikerült feltölteni. Győződjön meg róla, hogy a formátum JPEG vagy PNG és a fájl mérete max. {maxSize}MB.",
  "error.avatarUploadOnlyOneImage": "Csak egy képet tölthet fel",
  "error.changeUsernameEmail": "A felhasználónév megváltoztatására vonatkozó e-mailt nem lehetett elküldeni",
  "error.changeUsername.newUsernameConflict": "Az új e-mail nem felel meg a tárolt e-mailnek.",
  "error.changeUsername.newUsernameDuplicate": "Ez az e-mail cím már használatban van",
  "error.changeUsername.noNewUsername": "Nincs új e-mail cím hozzárendelve.",
  "error.changeUsername.tokenExpired": "Ez a link lejárt.",
  "error.changeUsername.tokenNotFound": "Link érvénytelen, az új e-mail címet már megerősítették?",
  "error.chatForbidden": "A Face to Face nincs engedélyezve Önnek ennél a cégnél.",
  "error.chatNoCompany": "Üzenet vállalati kontextus nélkül.",
  "error.chatNoRecipient": "Az üzenetnek nincs címzettje.",
  "error.chatSearch": "A keresés sikertelen. Kérjük, próbálja meg újra.",
  "error.completeSign": "Az elektronikus aláírást nem lehetett kitölteni",
  "error.config.accounting.duplicate": "Több azonos nevű cégkódot is megadott",
  "error.config.accounting.noSubsidiary": "Kérjük, a folytatáshoz legalább egy vállalati kódot határozzon meg.",
  "error.config.activateCompanyFailed": "Ez az ügyfél még nem készült fel technikailag a TPA Connect használatára. Kérjük, forduljon az informatikai támogatáshoz, hogy hozzon létre egy megfelelő felhasználót.",
  "error.config.corruptSubsidiary": "Cégkód azonosító nélkül",
  "error.config.hr.duplicate": "Ön több azonos nevű számlázási területet adott meg",
  "error.config.hr.noSubsidiary": "Kérjük, a folytatáshoz állítson be legalább egy számviteli területet.",
  "error.copy.alreadyExists.file": "Hiba másoláskor: A fájl ezzel a névvel már létezik",
  "error.copy.alreadyExists.folder": "Hiba másoláskor: A mappa ezzel a névvel már létezik",
  "error.copy.alreadyExists.unknown": "Hiba másoláskor: A mappa vagy fájl ezzel a névvel már létezik",
  "error.create": "Hiba a létrehozáskor",
  "error.create.alreadyExists.project": "Egy ilyen nevű projekt már létezik",
  "error.createEmployee": "Az alkalmazottat nem lehetett létrehozni",
  "error.delete": "Hiba a törlés során",
  "error.deleteBankAccountTransactionInvoices": "A linket nem lehetett törölni",
  "error.deleteBankConnection": "A banki adatokat nem lehetett törölni",
  "error.delete.fileLocked": "A fájl szerkesztése folyamatban van, ezért nem törölhető.",
  "error.deleteUser": "A felhasználót nem lehetett törölni. Kérjük, próbálja meg később újra.",
  "error.deregisterEmployee": "Az alkalmazottat nem lehetett kijelentkezni",
  "error.documentForbidden": "Nincs hozzáférése a dokumentumhoz",
  "error.documentInProgress": "A dokumentumot a TPA-nak küldik, és nem lehet letölteni. Próbálja meg rövidesen újra.",
  "error.documentNotFound": "Nem talált dokumentum",
  "error.documentsInProgress": "A dokumentumokat a TPA-nak küldik, és nem lehet letölteni. Próbálja meg rövidesen újra.",
  "error.download": "Hiba letöltése",
  "error.downloadTermsOfUseDocument": "Hiba történt a felhasználási feltételek letöltése közben",
  "error.download.transferInProgress": "A dokumentum átadása folyamatban van. Kérjük, próbálja meg egy kicsit később újra.",
  "error.dueDate.minDate": "A határidő dátuma nem lehet a múltban.",
  "error.duplicateName": "Már hozzárendelt név",
  "error.duplicateUsername": "Az újonnan kijelölt e-mail cím már használatban van",
  "error.edit": "Hiba szerkesztés közben",
  "error.employeeWithoutEmail": "A munkavállaló nem rendelkezik érvényes e-mail címmel",
  "error.folderIsEmpty": "A mappa üres, ezért nem lehet letölteni.",
  "error.foldersAreEmpty": "Minden kiválasztott mappa üres, ezért nem tölthető le",
  "error.forbidden": "Nincs engedély",
  "error.general": "Sajnáljuk, hiba történt. Kérjük, próbálja meg később újra.",
  "error.getSign": "Hiba történt az elektronikus aláíráshoz szükséges információk betöltése közben.",
  "error.hr.noSubsidiarySelected": "Nincs kiválasztott számlázási terület",
  "error.hr.postSubsidiary": "A számlázási terület nem hozható létre",
  "error.hr.subsidiaryDoesNotExist": "A számlázási terület nem található",
  "error.imageCrop": "Hibák a képfeldolgozásban",
  "error.imageTooBig": "Túl nagy kép",
  "error.invalidCharacters": "A bemenet érvénytelen karaktereket tartalmaz",
  "error.invalidCompanyId": "Érvénytelen cégazonosító",
  "error.invalid_credentials": "Helytelen felhasználói azonosító vagy jelszó",
  "error.invalidDateFormat": "Érvénytelen dátumformátum",
  "error.invalidEmployee": "A munkavállaló adatai hiányosak vagy helytelenek",
  "error.invalidPeriodId": "Érvénytelen időszak erre a vállalatra vonatkozóan",
  "error.invalidRolePermissions": "A szerepjogosultságok érvénytelenek",
  "error.invalidSsn": "Érvénytelen nemzeti biztosítási szám",
  "error.invalidTicket": "Érvénytelen jegy",
  "error.invalidWebhookParams": "Érvénytelen webhook paraméterek.",
  "error.invitationNoCompany": "Meghívó cégazonosító nélkül",
  "error.linkExpired": "Ez a link lejárt. Kérjük, kérjen egy újat.",
  "error.linkInvalid": "Ez a link érvénytelen.",
  "error.loadAccount": "A fiókot nem lehetett betölteni",
  "error.loadAccountListing": "Az egyenleglista nem tölthető be",
  "error.loadAccounts": "A számlákat nem lehetett betölteni",
  "error.loadAccountTransactions": "A foglalásokat nem lehetett betölteni",
  "error.loadArchiveDocuments": "Nem minden archív dokumentumot lehetett betölteni",
  "error.loadBadges": "Hiba az értesítések betöltésekor",
  "error.loadBalanceSheet": "Az egyenleg nem tölthető be",
  "error.loadBankAccountTransactions": "A tranzakciókat nem lehetett betölteni",
  "error.loadBankConnections": "A banki adatokat nem lehetett betölteni",
  "error.loadBranches": "Az ágakat nem lehetett betölteni",
  "error.loadCashAccounting": "A bevételi és kiadási számlát nem lehetett feltölteni",
  "error.loadCompany": "A vállalatot nem lehetett betölteni",
  "error.loadCompanyFeatures": "A bővítményeket nem lehetett betölteni",
  "error.loadContacts": "A kapcsolatokat nem lehetett betölteni",
  "error.loadCostCenters": "A költséghelyeket nem lehetett betölteni",
  "error.loadCountries": "Az országadatokat nem sikerült betölteni",
  "error.loadCustomerListing": "Az adóslista nem tölthető be",
  "error.loadDestinations": "A foglaltsági helyek nem tölthetők be. Kérjük, próbálja meg később újra",
  "error.loadDocument": "A dokumentumot nem lehetett betölteni",
  "error.loadDocuments": "A dokumentumokat nem lehetett betölteni",
  "error.loadDraft": "A jóváhagyást nem lehetett betölteni",
  "error.loadEmployee": "Az alkalmazottat nem lehetett betölteni",
  "error.loadEmployeeDocuments": "A fájlokat nem lehetett betölteni",
  "error.loadEmployees": "A munkavállalókat nem lehetett meghívni",
  "error.loadEvents": "Az eseményeket nem sikerült betölteni, próbálja meg később újra.",
  "error.loadFeatureFlags": "A funkciójelzőket nem lehetett betölteni",
  "error.loadFeedCountries": "Az országokat nem lehetett betölteni.",
  "error.loadFinancialAccountancies": "",
  "error.loadFormData": "Az űrlap adatait nem sikerült betölteni",
  "error.loadFunders": "A költségviselőt nem lehetett betölteni",
  "error.loadGeneralDocumentsTimeout": "A jelentések átmenetileg nem állnak rendelkezésre. Kérjük, próbálja meg később újra.",
  "error.loading": "Betöltési hiba",
  "error.loadInsuranceCarrierBalance": "Hiba az egészségbiztosítási egyenleg betöltésekor",
  "error.loadInsuranceCarriers": "Hiba az egészségbiztosítási pénztárak betöltésekor",
  "error.loadInvitationResults": "Hiba a felszabadult felhasználói módosítások betöltésekor",
  "error.loadKpiConfigurations": "Tények és számok A konfiguráció nem tölthető be",
  "error.loadKpis": "A tényeket és számadatokat nem lehetett betölteni, próbáld meg később újra.",
  "error.loadMessages": "Az üzeneteket nem lehetett betölteni",
  "error.loadModuleTypeDetails": "Hiba a modul adatainak betöltése",
  "error.loadNews": "Az üzeneteket nem lehetett betölteni, próbálja meg később újra.",
  "error.loadNews.noSource": "A hírfolyam jelenleg nem érhető el.",
  "error.loadNotifications": "Az értesítési beállításokat nem sikerült betölteni",
  "error.loadOptionalMenuItems": "A választható menüpontokat nem lehetett betölteni",
  "error.loadPayrollAccount": "A bérszámfejtési számla nem tölthető be",
  "error.loadPayrollAccountDetails": "A bérszámfejtési adatok nem tölthetők be",
  "error.loadPendingMicrosoftActions": "Meghívók és frissítési információk nem tölthetők be",
  "error.loadPeriod": "Hiba az időszak betöltésekor",
  "error.loadPeriodData": "Hiba az időszaki adatok betöltésekor",
  "error.loadPermissions": "Az engedélyeket nem sikerült betölteni",
  "error.loadPersonnelFile": "A személyzeti fájl nem tölthető be",
  "error.loadPersonnelFile.notStaff": "A felhasználó nem kereső",
  "error.loadPowerBIReportEmbedToken": "",
  "error.loadPowerBIReports": "",
  "error.loadPreRegistration": "Az előzetes felvétel nem tölthető be.",
  "error.loadProfile": "A profil nem tölthető be",
  "error.loadProfitAndLoss": "G&V nem tölthető be",
  "error.loadProjects": "A projekteket nem lehetett betölteni",
  "error.loadProjectsCompanies": "A projektekhez tartozó vállalatokat nem lehetett betölteni",
  "error.loadPromotions": "Az elemeket nem lehetett betölteni, próbálja meg később újra",
  "error.loadPublications": "A kiadványokat nem lehetett betölteni, próbáld meg később újra.",
  "error.loadRecord": "A dokumentumot nem lehetett betölteni",
  "error.loadRecords": "A nyugtákat nem lehetett betölteni",
  "error.loadRecordTypes": "Hiba a foglalási típusok betöltésekor",
  "error.loadReports": "A jelentéseket nem lehetett betölteni",
  "error.loadResponsibleUsers": "A tanácsadókat nem lehetett betölteni",
  "error.loadRolePermissions": "Hiba a szerepjogosultságok betöltésekor",
  "error.loadSubsidiaries": "Hiba a vállalati kódok betöltésekor",
  "error.loadSubsidiary": "Hiba a vállalati kód betöltésekor",
  "error.loadTeam": "A csapattagokat nem lehetett betölteni",
  "error.loadTermsofUse": "Hiba történt a felhasználási feltételek betöltése közben",
  "error.loadTicketDetails": "A konzultáció nem tölthető be",
  "error.loadTickets": "A lekérdezéseket nem lehetett betölteni",
  "error.loadUserCompanySettings": "Hiba a felhasználói beállítások betöltésekor",
  "error.loadUsers": "A felhasználókat nem lehetett betölteni",
  "error.loadVendorListing": "Az eladói listát nem sikerült betölteni",
  "error.loadWaitingUsers": "Hiba a felszabadítandó felhasználók betöltésekor",
  "error.loginAccountSetup": "Először van itt? Előkészítjük a felhasználói fiókját. Kérjük, néhány perc múlva próbálja meg újra.",
  "error.logout": "Kijelentkezési hiba",
  "error.markAsDone": "Csak olyan lekérdezéseket jelölhet befejezettnek, amelyeket Ön hozott létre.",
  "error.markBankAccountTransactionAsPrivate": "A tranzakciót nem lehetett privátként megjelölni",
  "error.maxCharacters": "Maximálisan megengedett {count} karakterek száma",
  "error.mfaActivation.codeNotMatched": "A megadott kód helytelen",
  "error.mfaCodeAlreadySent": "Egy kódot már elküldtünk, kérjük, várjon legalább 20 másodpercet, mielőtt a következőt kérné.",
  "error.mfaCodeWrong": "A megadott kód helytelen. Kérjük, próbálja újra",
  "error.mfaSettingChange": "A 2FA nem aktiválható vagy deaktiválható, mivel az aktiváláshoz már kértek kódot. Kérjük, várjon legalább 20 másodpercet, mielőtt újra megpróbálná.",
  "error.mfaSettingChange.codeAlreadySent": "Már kértek egy új kódot. Kérjük, várjon legalább 20 másodpercet, mielőtt újra próbálkozna.",
  "error.mfaSettingChange.codeNotMatched": "A beírt kód rossz",
  "error.microsoftLogin": "A Microsofttal való kommunikáció során probléma merült fel. Kérjük, próbáljon meg újra bejelentkezni.",
  "error.missingDocument": "Hiányzó dokumentum",
  "error.missingEmail": "Hiányzó e-mail cím",
  "error.missingEmployee": "Hiányzó alkalmazott",
  "error.missingParams": "Hiányzó paraméterek",
  "error.move": "Mozgási hiba",
  "error.move.alreadyExists.unknown": "Hiba mozgás közben: A mappa vagy fájl ezzel a névvel már létezik",
  "error.msTokenExpired": "Az Ön tokenje lejárt. Kérjük, újítsa meg.",
  "error.multipleArchives": "Számos ügyfélarchívum áll rendelkezésre a vállalat számára",
  "error.noArchive": "Ehhez a vállalathoz nem áll rendelkezésre ügyfélarchívum",
  "error.noCompanySelected": "Nincs kiválasztott vállalat",
  "error.noDocumentSelected": "Kérjük, válasszon egy dokumentumot a felhasználási feltételekhez",
  "error.noDotAtEnd": "Az utolsó karakter nem lehet pont",
  "error.noFinancialAccountancies": "A vállalat nem rendelkezik FIBU-kkal",
  "error.noMsAccountAvailable": "A felhasználót nem lehet meghívni. A TPA munkatársainak várhatóan már van Microsoft-fiókja.",
  "error.noRecordTypes": "Nem találtunk dokumentumkategóriákat",
  "error.noSignees": "Kérjük, válasszon egy dokumentumot a felhasználási feltételekhez",
  "error.noSubsidiaries.accounting": "Nem találtunk vállalati kódokat",
  "error.noSubsidiaries.hr": "Nem találtak számlázási területeket",
  "error.noSubsidiaryWithEmployeeRead": "Nincs bérszámfejtési kör a munkavállaló olvasási jogával.",
  "error.notAdvisor": "Nincsenek tanácsadói engedélyek",
  "error.notAllowed": "Az intézkedést nem szabad végrehajtani",
  "error.notificationCenter.load": "Az értesítéseket nem lehetett betölteni",
  "error.notificationCenter.markAllAsRead": "Az értesítéseket nem lehetett olvasottként megjelölni",
  "error.notificationCenter.markAsRead": "Az értesítést nem lehetett olvasottként megjelölni",
  "error.oauthApi": "Probléma volt a hitelesítéssel. Kérjük, próbálja meg újra.",
  "error.oauthCompany": "Nincs kiválasztott vállalat. Kérjük, válasszon ki egy vállalatot, és próbálja meg újra.",
  "error.oauthUrl": "Probléma merült fel a Tények és számok meghívásával. Kérjük, próbálja meg újra.",
  "error.overlappingSignature": "Átfedő aláírás. Kérjük, válasszon új pozíciót",
  "error.passwordReset.tokenExpired": "Ez a Jelszó-visszaállítási link lejárt",
  "error.passwordReset.tokenNotFound": "Érvénytelen Jelszó visszaállítása Link",
  "error.payTaxAccount": "Hibák az adófizetéskor",
  "error.payUnpaidAccount": "Hiba a TPA-számla kifizetésekor",
  "error.periodClosed": "Már teljesített időszak",
  "error.permission.corruptRoles": "Érvénytelen szerepek",
  "error.permission.onlyOneRoleAllowed": "Csak egy tekercset lehet beállítani",
  "error.postTicket": "Konzultációt nem lehetett létrehozni.",
  "error.projectForbidden": "Nincs hozzáférése a projektekhez",
  "error.projectIsEmpty": "A projekt üres, ezért nem tölthető le.",
  "error.projectPermissions": "A projekt engedélyeit nem sikerült betölteni",
  "error.projects.cantMove": "A fájlokat nem lehetett áthelyezni. Kérjük, próbálja meg később újra.",
  "error.projects.cantPaste": "A fájlokat nem lehet beilleszteni. Kérjük, próbálja meg később újra.",
  "error.projects.openBookmarkUrlFailed": "Az URL-t nem lehet megnyitni",
  "error.qes.missingSignaturePositions": "Kérjük, adja meg a szükséges aláírási pozíciókat",
  "error.recordForbidden": "Nincs hozzáférése az utalványhoz",
  "error.recordWithNotAllowedCompany": "Nincs felhatalmazás arra a vállalatra, amelyhez a dokumentumot hozzárendelték.",
  "error.refreshBankConnection": "A bankszámlát nem lehetett megújítani",
  "error.releaseEmployeeDocument": "Kioldási hiba",
  "error.releaseReport": "Hiba jóváhagyáskor",
  "error.removeBankAccountTransactionPrivateMark": "Nem tudta megszüntetni a kijelölést",
  "error.removeCompanyFromUserFavorites": "A vállalatot nem lehetett eltávolítani a kedvenceid közül",
  "error.rename.alreadyExists.file": "Hiba átnevezés közben: Egy ilyen nevű fájl már létezik",
  "error.rename.alreadyExists.folder": "Hiba átnevezéskor: Egy ilyen nevű mappa már létezik",
  "error.requiredField": "Szükséges bemenet",
  "error.resendTermsOfUseEmail": "Hiba történt az e-mail újraküldésekor",
  "error.save": "Hiba mentéskor",
  "error.saveKpiConfigurations": "Tények és számok A konfigurációt nem lehetett megmenteni",
  "error.saveOptionalMenuItems": "A választható menüpontokat nem sikerült betölteni. Kérjük, próbálja meg később újra",
  "error.saveTermsOfUse": "Hiba történt a felhasználási feltételek mentése közben. Kérjük, próbálja később újra",
  "error.send": "Hiba küldéskor.",
  "error.setDocumentOrSignees": "Hiba történt a dokumentum vagy az aláírandó személyek mentésekor",
  "error.settings": "Hiba a beállítások módosításakor",
  "error.signFileTooLarge": "A fájl túl nagy, és nem lehet elektronikusan aláírni.",
  "error.signReadOnlyDocument": "A dokumentum csak olvasható, ezért nem írható alá.",
  "error.signTermsOfUse": "Hiba történt a felhasználási feltételek elfogadása során. Kérjük, próbálja később újra",
  "error.subsidiaryWithoutRecordTypes": "A(z) {subsidiary} vállalatnak nincs aktív dokumentumszimbóluma",
  "error.termsOfUseDuplicateFile": "A fájl már létezik más néven",
  "error.termsOfUseFolderNotFound": "Még nincs létrehozva mappa a felhasználási feltételekkel ",
  "error.timeout": "Ez a folyamat jelenleg egy kicsit tovább tart. Kérjük, próbálja meg később újra.",
  "error.toggleCompanyFeature": "Hiba a bővítmény aktiválásakor/deaktiválásakor",
  "error.tokenRefresh": "Hitelesítési hiba",
  "error.tooManyCharacters": "Az üzenetben megengedett maximális {maxCharacters} karakterek száma.",
  "error.transfer": "Átviteli hiba",
  "error.transferEmployee": "A munkavállalót nem lehetett áthelyezni",
  "error.transferEmployeeDirect": "Az egészségbiztosítási pénztárnak történő átutalás nem sikerült. Kérjük, forduljon a tanácsadójához.",
  "error.unknown": "Ismeretlen hiba ({statusCode})",
  "error.unsupportedDocumentType": "Ez a dokumentumtípus nem támogatott",
  "error.updateBankAccountAccountNumber": "A számlát nem lehetett elmenteni.",
  "error.upgradeToMicrosoft": "A frissítést nem lehetett elvégezni. Kérjük, ellenőrizze jelszavát.",
  "error.upload": "Feltöltési hiba",
  "error.userDeactivated": "Ez a Microsoft-felhasználó deaktiválva lett.",
  "error.userNotRegistered": "A felhasználó nincs regisztrálva",
  "error.userUnknown": "Ismeretlen felhasználó",
  "externalLinkDialog.content": "",
  "externalLinkDialog.title": "",
  "failedUserDialog.amount": "<b>{failedUserCount}</b> a {userCount} felhasználók nem hozhatók létre/módosíthatók:",
  "failedUserDialog.button.text.continue": "Folytassa mégis",
  "failedUserDialog.button.text.retry": "Próbálja újra",
  "failedUserDialog.error.emailAlreadyExists": "Már használt e-mail cím",
  "failedUserDialog.error.pendingPermissionChanges": "Már jóváhagyásra vár",
  "failedUserDialog.message": "Hiba létrehozáskor/megváltoztatáskor",
  "filterDialog.apply": "Szűrő alkalmazása",
  "filterDialog.category.advanced": "Speciális szűrők",
  "filterDialog.category.assigned": "Hozzárendelt terület",
  "filterDialog.header": "Szűrési lehetőségek",
  "filterDialog.required": "Kérjük, válasszon egy értéket",
  "filterDialog.reset": "RESET FILTER",
  "finishProjectDialog.text": "Biztos, hogy be akarja fejezni a <b>{projectName}</b> projektet?",
  "finishProjectDialog.title": "Teljes projekt",
  "footer.copyright": "Minden jog fenntartva",
  "footer.disclaimer": "Felelősségi nyilatkozat",
  "footer.generalBusinessTerms": "AGB",
  "footer.legalInformation": "Impresszum",
  "footer.privacy": "Adatvédelem",
  "helpcenter.ideas": "Ötletek vagy kívánságok",
  "helpcenter.problems": "Problémák jelentése",
  "helpcenter.problems.email": "E-mail cím",
  "helpcenter.problems.send": "Probléma jelentése",
  "helpcenter.problems.upload.failed": "A problémát nem lehetett továbbítani",
  "helpcenter.problems.upload.success": "A problémát sikeresen benyújtották",
  "helpcenter.problems.whathappened": "Mi történt ehelyett?",
  "helpcenter.problems.whatshouldhappen": "Minek kellett volna történnie?",
  "helpcenter.problems.whatwasdone": "Mit próbáltak tenni?",
  "helpCenter.title": "Súgóközpont",
  "helpcenter.videos": "Segítség videók",
  "helpcenter.videos.tpaonly": "Segítség videók TPA alkalmazottak",
  "hr.casualEmployment": "Alkalmi munkavállalás",
  "hr.disclaimer.additionalData": "A közvetlen továbbításhoz még szükségünk van néhány további adatra. Kérjük, adja meg ezeket.",
  "hr.disclaimer.employmentDate": "Foglalkoztatás időpontja",
  "hr.disclaimer.subtitle": "A TPA-nak vagy közvetlenül az egészségbiztosítónak szeretné elküldeni az adatokat?",
  "hr.disclaimer.title": "Küldés",
  "hr.disclaimer.transfer.direct": "Az adattovábbítás közvetlenül az osztrák egészségbiztosítási pénztárnak történik.",
  "hr.disclaimer.transfer.direct.subtitle": "Megjegyezzük, hogy a TPA csak a következő napokban fogja ellenőrizni az adatokat, legkorábban az iroda nyitvatartásakor.",
  "hr.disclaimer.transfer.tpa": "Az adattovábbítás a TPA felé történik",
  "hr.disclaimer.transfer.tpa.subtitle": "Felhívjuk a figyelmet arra, hogy a munkavállaló regisztrációja az Osztrák Egészségbiztosítási Pénztárnál csak akkor történik meg, ha a TPA feldolgozta az adatokat.",
  "hr.documents.backLabel": "Személyzet/Fájlok",
  "hr.documents.emptyState.header": "rögzítés",
  "hr.documents.emptyState.message": "Kezdje el feltölteni fájljait most.",
  "hr.documents.list.backLabel": "Személyzet/ Dokumentumok",
  "hr.documents.nonWorkingTimes": "Nem teljesítési idők",
  "hr.documents.nonWorkingTimesUpload": "Fájlfeltöltés nem teljesítési idők",
  "hr.documents.upload.navHeader": "{documentType}",
  "hr.documents.upload.navLabel": "Személyzet/okmányok",
  "hr.employee.employment": ". Munkaviszony",
  "hr.employee.employmentRelationship": "Munkaviszony",
  "hr.employee.jobDescription": "Munkakör",
  "hr.employee.payslip.upload.date": "Időszak",
  "hr.employee.payslip.upload.title": "Fizetési bizonylat feltöltése",
  "hr.employee.payslip.upload.type": "Fizetési bizonylat típusa",
  "hr.employee.payslip.upload.type.monthly": "Havi fizetési bizonylat",
  "hr.employee.payslip.upload.type.yearly": "Éves fizetési jegyzék",
  "hr.employee.reasonForLeaving": "A távozás oka",
  "hr.employee.workplaceState": "A munkahely állapota",
  "hr.finished.analysis": "Havi értékelések",
  "hr.finished.billing": "Havi kimutatások",
  "hr.finished.globalAnalysis": "Vállalati szintű értékelés",
  "hr.finished.globalBilling": "Vállalati szintű könyvelés",
  "hr.insuranceCarrier": "Biztosító",
  "hr.overview.continuousActions": "Folyamatos funkciók",
  "hr.recordTypes.names.benefitsInKindCar": "Természetbeni juttatások autó",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "Szerződésmódosítások és különleges kifizetések",
  "hr.recordTypes.names.mealDeductions": "Étkezési levonások",
  "hr.recordTypes.names.nonWorkingTimes": "Nem teljesítési idők",
  "hr.recordTypes.names.overtimeAndBonusPayments": "Túlóra és bónusz kifizetések",
  "hr.recordTypes.names.supplementaryDocuments": "Kiegészítő dokumentumok",
  "hr.recordTypes.names.timeRecords": "Időrekordok",
  "hr.recordTypes.names.travelExpenseReport": "Utazási költségelszámolás",
  "hr.tickets.preRegistration.NavBar": "Érdeklődés a {preRegistration}",
  "hr.tickets.preRegistration.NavBarLabel": "Személyzet / Alkalmazottak / Kérdések",
  "hr.tickets.preRegistration.toOverview": "A SZEMÉLYI ANYAGHOZ",
  "imageCrop.description": "Húzza el a kép mozgatásához",
  "imageCrop.title": "Kép pozicionálása",
  "imageCrop.zoom": "Nagyítás",
  "language.german": "Német",
  "maritalStatus.divorced": "elvált",
  "maritalStatus.livingInPartnership": "partneri viszonyban él",
  "maritalStatus.married": "Házas/bejegyzett élettársi kapcsolat",
  "maritalStatus.separated": "külön él",
  "maritalStatus.unknown": "ismeretlen",
  "maritalStatus.unmarried": "egyedülálló",
  "maritalStatus.widowed": "özvegy",
  "menu.answer": "Válasz",
  "menu.assign": "Hozzárendelés",
  "menu.cancelRelease": "Jóváhagyás visszavonása",
  "menu.delete": "Törlés",
  "menu.details": "Részletek",
  "menu.download": "Letöltés",
  "menu.draft": "Jóváhagyás kérése",
  "menu.edit": "Szerkesztés",
  "menu.editRelease": "Jóváhagyás újbóli kérése",
  "menu.finish": "Lezárás",
  "menu.markAsDone": "Jelölje késznek",
  "menu.move": "Mozgatás",
  "menu.openInBrowser": "Megnyitás a böngészőben",
  "menu.openInSharepoint": "Megnyitás a Sharepointban",
  "menu.rename": "Átnevezés",
  "menu.requestRelease": "Jóváhagyás kérése",
  "menu.showReleases": "Jóváhagyások megjelenítése",
  "menu.view": "A  megtekintése",
  "mfa.activation.dialog.code.required": "Kérjük, adja meg a kódot",
  "mfa.activation.dialog.description": "Kérjük, adja meg a telefonszámára küldött kódot a kétfaktoros hitelesítés aktiválásához.",
  "mfa.activation.dialog.title": "2FA aktiválása",
  "mfa.activation.success.dialog.title": "A 2FA sikeresen aktiválódott",
  "mfa.changeSetting.dialog.code.field.label": "Kód",
  "mfa.changeSetting.dialog.resendCode": "Kód újra küldése",
  "mfa.codeResend.success.dialog.title": "A kódot újra elküldték",
  "mfa.deactivation.dialog.description": "Kérjük, adja meg a telefonszámára küldött kódot a kétfaktoros hitelesítés kikapcsolásához.",
  "mfa.deactivation.dialog.title": "2FA kikapcsolása",
  "mfa.deactivation.success.dialog.title": "A 2FA sikeresen deaktiválódott",
  "mfa.validation.code.length": "A kód 6 számjegyű",
  "mfa.validation.code.required": "A kódot be kell írni",
  "mfa.validation.code.type": "Csak számok engedélyezettek",
  "microsoft_oauth.button.login.title": "Bejelentkezés Microsoft fiókkal",
  "microsoft_oauth.button.send_code.title": "Kód elküldése",
  "microsoft_oauth.button.upgrade.title": "Frissítés ",
  "missingEmailDialog.description": "A <b>{name}</b> e-mail címe nem szerepel a nyilvántartásban. Kérjük, adjon meg egy érvényes e-mail címet, hogy elküldhessük a meghívót.",
  "missingEmailDialog.title": "E-mail cím hiányzik",
  "missingEmail.email.error": "Kérjük, helyes e-mail címet adjon meg",
  "missingEmail.email.label": "Adja meg az e-mail címét",
  "module.finished.globalReports": "Vállalati szintű jelentések",
  "modules.delete.report.message": "Tényleg törölni szeretné a {name} jelentést?",
  "modules.delete.report.title": "Jelentés törlése",
  "navbar.week": "KW {week} {year}",
  "newUsername.checkbox.error": "Kérjük, erősítse meg az új e-mail címét",
  "notificationCenter.emptyState": "Nincsenek értesítések",
  "notificationCenter.filter.accounting": "Számvitel",
  "notificationCenter.filter.appNotification:maintenance": "Karbantartási üzenetek",
  "notificationCenter.filter.appNotification:newFeature": "Új funkciók",
  "notificationCenter.filter.appNotification:update": "",
  "notificationCenter.filter.companySpecific": "Vállalatspecifikus",
  "notificationCenter.filter.employeeDocument": "Munkavállalói dokumentumok",
  "notificationCenter.filter.file": "Dokumentumok",
  "notificationCenter.filter.general": "Általános",
  "notificationCenter.filter.generalDocument": "Általános fájlok",
  "notificationCenter.filter.hr": "Bérszámfejtés",
  "notificationCenter.filter.invoice": "Bevételek",
  "notificationCenter.filter.login": "Bejelentkezés",
  "notificationCenter.filter.module": "Modul",
  "notificationCenter.filter.notificationType": "Értesítés típusa",
  "notificationCenter.filter.other": "Egyéb",
  "notificationCenter.filter.payslip": "fizetési bizonylat",
  "notificationCenter.filter.project": "Projektek",
  "notificationCenter.filter.report": "Jelentések",
  "notificationCenter.filter.ticket": "Érdeklődés",
  "notificationCenter.filter.unread": "Csak olvasatlanul",
  "notificationCenter.markAllAsRead": "Mindent olvasottnak jelölni",
  "notificationCenter.title": "Értesítési központ",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "A személyi aktájában <b>{megszámlált} új dokumentumok</b> vannak, amelyek az Ön jóváhagyását igénylik.",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "Van egy <b>új dokumentum</b> a személyi aktájában, amelyhez az Ön jóváhagyása szükséges.",
  "openEmployeeDocumentReleasesDialog.submit": "Megtekintés és megosztás",
  "openEmployeeDocumentReleasesDialog.title": "Dokumentumok megosztása",
  "pdfView.error.caption": "A dokumentumot nem sikerült betölteni, kérjük, próbálja meg újra.",
  "pendingUserCreationDialog.message": "A felhasználók létrehozása a Sharepointban akár 30 percet is igénybe vehet. Kérjük, legyen türelemmel, amíg a jogosultságok létrejönnek.",
  "pendingUserCreationDialog.title": "Felhasználók létrehozása",
  "permission.accounting.banking": "Banki adatok",
  "permission.accounting.global.reports": "Vállalati szintű számviteli jelentések",
  "permission.accounting.global.results": "Vállalati szintű számviteli értékelések",
  "permission.accounting.incaseoflaw": "Jogesetben",
  "permission.accounting.records": "A számvitel bejelentése",
  "permission.accounting.reports": "Számviteli jelentések",
  "permission.expenses.cockpit": "Tények és számok megtekintése az irányítópultban",
  "permission.expenses.kpi": "A Tények és számok modul használata",
  "permission.hr.employeePayrollAccount": "Munkavállalói bérszámfejtés megtekintése",
  "permission.hr.employerPayrollAccount": "Munkáltatói bérszámfejtési számla",
  "permission.hr.employerPayrollAccount.details": "Bérfajta részletek megtekintése",
  "permission.hr.global.reports": "Vállalati szintű bérszámfejtési jelentések",
  "permission.hr.global.results": "A bérszámfejtés vállalati szintű elemzései",
  "permission.hr.personnel": "Alkalmazottak",
  "permission.hr.records": "A személyzeti elszámolás bejelentése",
  "permission.hr.reports": "Bérszámfejtési jelentések",
  "permission.option.canAccess": "hozzáférhet",
  "permission.option.canEdit": "szerkesztheti",
  "permission.option.canEdit.all": "mindent szerkeszthet/jóváhagyhat",
  "permission.option.canEdit.none": "nem tud semmit szerkeszteni/jóváhagyni",
  "permission.option.canEdit.some": "Részlegesen szerkeszthető/kiadható",
  "permission.option.canPay": "fizethet",
  "permission.option.canRead": "láthatja",
  "permission.option.canRead.all": "mindent láthat",
  "permission.option.canRead.none": "nem lát semmit",
  "permission.option.canRead.some": "részben látom",
  "permission.option.canRelease": "jóváhagyhatja",
  "permission.option.canUpdate": "szerkesztheti",
  "permission.option.canUpload": "feltöltheti",
  "permission.option.mixed": "vegyes",
  "permission.option.none": "nincs engedély",
  "permission.projects": "A projektek modul használata",
  "permission.settings.company": "Vállalati beállítások megtekintése",
  "permission.settings.features": "Kiegészítők módosítása",
  "permission.settings.images": "Borítókép és logó módosítása",
  "permission.settings.user": "Javasolja a felhasználói jogok módosítását",
  "permission.taxAccount.payment": "Adótartozás kifizetése",
  "permission.unpaidAccount.payment": "Kintlévőségek kifizetése",
  "project.invitation": "Projekt meghívó",
  "projectItemPermission.owner": "Teljes hozzáférés",
  "projectItemPermission.owner.description": "Minden jog a mappák és fájlok szerkesztéséhez. A projekt szerkesztése és befejezése, valamint a tagok és jogosultságok kezelése.",
  "projectItemPermission.read": "Olvassa el",
  "projectItemPermission.read.description": "Mappák és fájlok megtekintése és fájlok letöltése",
  "projectItemPermission.readNoDownload": "Csak olvasás",
  "projectItemPermission.write": "Szerkesztés",
  "projectItemPermission.write.description": "Mappák megtekintése, létrehozása és átnevezése. Fájlok megtekintése, feltöltése, szerkesztése és törlése.",
  "projects.activate": "RESTORE",
  "projects.active.emptystate.buttonlabel": "Projekt létrehozása",
  "projects.active.emptystate.message": "Még nincs projekt ehhez a vállalathoz",
  "projects.addCompanies": "Vállalat hozzáadása",
  "projects.addCompanies.tooltip": "A projekt létrehozása több vállalatnál",
  "projects.addFolder": "Mappa létrehozása",
  "projects.addMembers": "Tagok hozzáadása",
  "projects.addMembers.bulk.success.title": "Sikeresen hozzáadott tagok",
  "projects.bulkDeleteDialog.message": "Biztos, hogy törölni szeretné a kiválasztott fájlokat és mappákat?",
  "projects.bulkDeleteDialog.title": "Fájlok és mappák törlése",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "Visszavonás",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "Biztos, hogy vissza akarja vonni a <b>{fileName}</b> nyitott jóváhagyását?",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "Visszavonja a meglévő jóváhagyást?",
  "projects.closed.emptystate.message": "Még nincs befejezett projektje ennek a vállalatnak",
  "projects.companies.emptyState.title": "Nincsenek elérhető vállalatok",
  "projects.companies.submit": "Add",
  "projects.copySuccessDialog.message": "A kiválasztott fájlok a vágólapra lettek másolva.",
  "projects.copySuccessDialog.title": "Vágólapra másolva",
  "projects.create.bulk.backToProjects": "Vissza a projektekhez",
  "projects.create.bulk.description": "A projekteket most hozzák létre. Ez eltarthat néhány percig, kérjük, ne zárja be a böngészőablakot.",
  "projects.create.bulk.start": "Indítsa el a  oldalt.",
  "projects.deleted.emptystate.message": "Még nincs törölt projektje ennek a vállalatnak",
  "projects.deleteDialog.message.file": "Biztos, hogy törölni szeretné a <b>{name}</b> fájlt?",
  "projects.deleteDialog.message.folder": "Biztos, hogy törölni akarja a <b>{name}</b> mappát?",
  "projects.deleteDialog.message.project": "Biztos, hogy törölni szeretné a <b>{name}</b> projektet?",
  "projects.deleteDialog.title.file": "Fájl törlése",
  "projects.deleteDialog.title.folder": "Mappa törlése",
  "projects.deleteDialog.title.project": "Projekt törlése",
  "projects.emptystate.title": "Nincsenek elérhető projektek",
  "projects.externalId": "Hivatkozási szám:",
  "projects.finish.successDialog.title": "A projekt sikeresen lezárult",
  "projects.folder.emptystate.message": "Most kezdje el feltölteni a mappát.",
  "projects.folder.emptystate.title": "Tartalom létrehozása és feltöltése",
  "projects.importUsers.add": "Add",
  "projects.importUsers.replace": "Cserélje ki a  címet.",
  "projects.importUsers.switch": "Tagok importálása egy másik projektből",
  "projects.international": "Nemzetközi projekt",
  "projects.international.activate": "Ahhoz, hogy egy projektet nemzetközi projektté alakítson át, engedélyeznie kell ezt a funkciót.",
  "projects.international.confirmButton": "Projekt átalakítása",
  "projects.international.disabled.refresh": "A nemzetközi MS bejelentkezése lejárt, kérjük, újítsa meg. Ezt követően alakítható a(z) \"{project}\" nemzetközi projektté.",
  "projects.international.disabled.unlock": "Ahhoz, hogy egy projektet nemzetközi projektté alakítson át, engedélyeznie kell ezt a funkciót a projekt áttekintésében.",
  "projects.international.info.message": "A projekt nemzetközi projektté alakítása lehetővé teszi, hogy a TPA más országokból származó munkatársainak a meghívását a projektbe, hogy az ügyféllel nemzetközi szinten együtt tudjanak dolgozni. Ennek érdekében a Microsoft SharePointon a projekt részeként tárolt összes fájl és dokumentum továbbításra kerül a különböző Microsoft felhasználóknak, és ennek során a projektben való munkavégzéshez szükséges összes felhasználói jogosultságot újraépítik. A folyamat során elveszhetnek a specifikus engedélyek. Ezért ajánlott a nemzetközi projektre való átállás után ellenőrizni a hozzáférési lehetőségeket. Ez a lépés nem vonható vissza.",
  "projects.international.info.subtitle": "Kérjük vegye figyelembe az alábbiakat:",
  "projects.international.invite.message": "Önt új nemzetközi projektekhez adták hozzá. Aktiválja egyszer a következő országokat, hogy megtekinthesse az ezekből az országokból származó projekteket.",
  "projects.international.invite.title": "Új országok feloldása",
  "projects.international.migrating": "Nemzetközi projekt (átalakítás folyamatban)",
  "projects.international.migrating.message": "A projektet jelenleg nemzetközi projektté alakulnak át. Az átalakulás befejezéséig a szerkesztés nem lesz lehetséges.",
  "projects.international.plural": "Nemzetközi projektek",
  "projects.international.switch": "A projektet nemzetközi projektté alakítani",
  "projects.international.undoWarning.message": "Biztos, hogy ezt a projektet nemzetközi projektté akarja alakítani? Az összes projektadat átmásolásra kerül a TPA AT Sharepoint fiókra. Ez a művelet nem vonható vissza, ha a projekt már létrejött.",
  "projects.international.undoWarning.message.at": "Biztos, hogy ezt a projektet nemzetközi projektté akarja alakítani? Ez a művelet nem vonható vissza, ha a projekt már létrejött.",
  "projects.international.undoWarning.title": "Ez a művelet nem vonható vissza",
  "projects.invite.international": "Önt felvették a nemzetközi projektek közé. Nyissa meg ezeket most.",
  "projects.invite.message": "Önt hozzáadták a(z) {numCountries} országok projektjeihez. Oldja fel a projekteket most.",
  "projects.invite.message.singular": "Önt hozzáadták a(z) {country} projektjeihez. Oldja fel a projekteket most.",
  "projects.members.assignPermission": "Engedélyezés",
  "projects.membersIncluded.multi": "Beleértve a {names} és a {name}.",
  "projects.membersIncluded.single": "Beleértve {name}.",
  "projects.moveConfirmationDialog.message": "Az áthelyezett fájlok törlődnek az eredeti mappából!",
  "projects.moveConfirmationDialog.title": "Fájlok áthelyezése?",
  "projects.moveSuccessDialog.message": "A fájlokat sikeresen áthelyezte",
  "projects.moveSuccessDialog.title": "Fájlok áthelyezése",
  "projects.navbar.activeProjects": "Aktív",
  "projects.navbar.deletedProjects": "Törölt",
  "projects.navbar.fileUpload": "Fájl feltöltés",
  "projects.navbar.finishedProjects": "Befejezett",
  "projects.navbar.heading": "Projektek",
  "projects.navbar.notConfiguredProjects": "Nincs konfigurálva",
  "projects.navbar.overview": "Áttekintés{útvonal}",
  "projects.navbar.project.fileTree": "Mappaszerkezet",
  "projects.navbar.project.heading": "Projekt: {projectName}",
  "projects.navbar.project.releases": "Jóváhagyások",
  "projects.navbar.releases.heading": "Jóváhagyások a(z) {fileName} dokumentumhoz",
  "projects.navbar.ticket.heading": "Kérdés a {fileName}-ről",
  "projects.new": "Új projekt",
  "projects.new.createNewProject": "Projekt létrehozása",
  "projects.new.externalId": "Hivatkozási szám",
  "projects.new.name": "A projekt neve",
  "projects.new.permissionContextMenu.remove": "Távolítsa el a",
  "projects.new.permissions.text": "Új tagok felvétele és felhasználói jogok hozzárendelése.",
  "projects.new.title": "Név projekt",
  "projects.new.userSearch.placeholder": "Meghívja a tagot...",
  "projects.notConfigured.emptystate.message": "Még nincs konfigurálható projekt ehhez a vállalathoz",
  "projects.openReleases.plural": "Nyitott jóváhagyások",
  "projects.openReleases.singular": "Nyitott jóváhagyás",
  "projects.pasteProgressDialog.title": "A fájlok beillesztése",
  "projects.pasteSuccessDialog.message": "A fájlok sikeresen beillesztésre kerültek",
  "projects.pasteSuccessDialog.title": "Beillesztett fájlok",
  "projects.refreshDialog.message": "A következő országok Microsoft bejelentkezései lejártak. Kérjük, újítsa meg őket.",
  "projects.refreshDialog.title": " Microsoft bejelentkezései lejártak",
  "projects.refresh.message": "A {numCountries} országok Microsoft bejelentkezései lejártak. Kérjük, újítsa meg őket.",
  "projects.refresh.message.singular": "{country} Microsoft bejelentkezése lejárt. Kérjük, újítsa meg.",
  "projects.releaseConfirmationDialog.message": "Kérjük, vegye figyelembe a következő megjegyzést {name}-től:",
  "projects.releaseConfirmationDialog.title": "Dokumentum jóváhagyása",
  "projects.releases.emptystate.message": "Jelenleg nincsenek jóváhagyásai.",
  "projects.releases.emptystate.title": "Nincsenek elérhető jóváhagyások",
  "projects.releases.reject": "elutasítás",
  "projects.releases.status.canceled": "visszavont",
  "projects.releases.status.canceledFrom": "{name} visszavonta",
  "projects.releases.status.canceled.tooltip": "visszavonták a {performedAt} által {name}",
  "projects.releases.status.open": "Várakozás jóváhagyásra",
  "projects.releases.status.openFrom": "várakozás {name} jóváhagyására",
  "projects.releases.status.open.tooltip": "várakozás {name} jóváhagyására (határidő: {dueDate})",
  "projects.releases.status.rejected": "Visszautasított",
  "projects.releases.status.rejectedFrom": "elutasította {name}",
  "projects.releases.status.rejected.tooltip": "elutasította a {performedAt} által {name}",
  "projects.releases.status.released": "jóváhagyva",
  "projects.releases.status.releasedFrom": "jóváhagyta: {name} {date}-n",
  "projects.releases.status.released.tooltip": "jóváhagyta {name} {performedAt} ",
  "projects.releaseSuccessDialog.message": "<b>{name}</b> átnézi a fájlokat, és felveszi Önnel a kapcsolatot, ha bármilyen kérdés felmerülne.",
  "projects.releaseSuccessDialog.multi.message": "A kijelölt személyek most átnézik az aktákat, és bármilyen kérdéssel kapcsolatba lépnek Önnel.",
  "projects.releaseSuccessDialog.title": "Sikeres jóváhagyás igénylés",
  "projects.requestReleaseConfirmationDialog.confirm": "Visszavonás és újra létrehozás",
  "projects.requestReleaseConfirmationDialog.message": "Már létezik nyitott jóváhagyás a(z) <b>{fileName}</b> dokumentumhoz. Új jóváhagyás létrehozásához a meglévő jóváhagyást vissza kell vonni.",
  "projects.requestReleaseConfirmationDialog.multi.message": "Már léteznek nyitott jóváhagyások a kiválasztására. Új jóváhagyások létrehozásához a meglévő jóváhagyásokat vissza kell vonni.",
  "projects.requestReleaseConfirmationDialog.multi.title": "Visszavonja a meglévő jóváhagyásokat?",
  "projects.requestReleaseConfirmationDialog.title": "Visszavonja a meglévő jóváhagyást?",
  "projects.restoreDialog.message.project": "Biztos, hogy aktiválni szeretné a <b>{name}</b> projektet?",
  "projects.restoreDialog.title.project": "Projekt aktiválása",
  "projects.statusClosed": "Befejeződött a",
  "projects.statusClosed.personalized": "Teljesítette: {name}",
  "projects.statusDeleted": "Törölték a",
  "projects.statusDeleted.personalized": "Törölte {name} on",
  "projects.statusInProgress": "Utoljára módosítva",
  "projects.statusInProgress.personalized": "Utoljára módosította: {name} a következő dátumon",
  "projects.tickets.plural": "Érdeklődés",
  "projects.tickets.singular": "Konzultáció",
  "records.details.upload.header": "Rekord {recordType}",
  "recordType.statusClosed": "Továbbítva",
  "recordType.statusInProgress": "Szerkesztve a",
  "recordType.statusOpen": "Szerkesztetlen",
  "renameDialog.button.text.rename": "Átnevezés",
  "renameDialog.label.document": "Megnevezés",
  "renameDialog.label.file": "Fájlnév",
  "renameDialog.label.folder": "Mappa neve",
  "renameDialog.title.document": "Dokumentum átnevezése",
  "renameDialog.title.file": "Fájl átnevezése",
  "renameDialog.title.folder": "Mappa átnevezése",
  "renameProjectItemDialog.button.text.rename": "Átnevezés",
  "renameProjectItemDialog.itemName.file": "Fájlnév",
  "renameProjectItemDialog.itemName.folder": "Mappa neve",
  "renameProjectItemDialog.title.file": "Fájl átnevezése",
  "renameProjectItemDialog.title.folder": "Mappa átnevezése",
  "renameRecordDialog.button.text.rename": "Átnevezés",
  "renameRecordDialog.name": "Megnevezés",
  "renameRecordDialog.title": "Dokumentum átnevezése",
  "requestReleaseProjectItemDialog.button.text.request": "Érdeklődés",
  "requestReleaseProjectItemDialog.info": "Mikor van szükségem minősített aláírásra?",
  "requestReleaseProjectItemDialog.label.comment": "Comment",
  "requestReleaseProjectItemDialog.label.dueDate": "Határidő",
  "requestReleaseProjectItemDialog.label.requiresQes": "Jóváhagyás elektronikus aláírással",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "Adja meg a nevét...",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "Minden személynek el kell engednie",
  "requestReleaseProjectItemDialog.qes.collective.title": "Kollektív kiadás",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "Csak egy személynek kell elengednie",
  "requestReleaseProjectItemDialog.qes.single.title": "Egyszeri kiadás",
  "requestReleaseProjectItemDialog.searchForUsers.error": "Kérjük, válasszon egy nevet a listából",
  "requestReleaseProjectItemDialog.text": "Válassza ki azokat a személyeket, akiknek a felsorolt dokumentumok jóváhagyását ki szeretné osztani, és adja meg a határidőt.",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "További személy hozzáadása",
  "requestReleaseProjectItemDialog.title.create": "Jóváhagyás kérése",
  "requestReleaseProjectItemDialog.title.edit": "Jóváhagyás szerkesztése",
  "results.accountSheet.backLabel.accountSheet": "Számlalap {accountNr} \"{name}\"",
  "results.accountSheet.backLabel.results.accountListing": "Egyensúlyi lista",
  "results.accountSheet.backLabel.results.balanceSheet": "Mérleg",
  "results.accountSheet.backLabel.results.cashAccounting": "Bevételi és kiadási kimutatás",
  "results.accountSheet.backLabel.results.customerListing": "Adóslista",
  "results.accountSheet.backLabel.results.profitAndLoss": "G&V",
  "results.accountSheet.backLabel.results.vendorListing": "Fizetendő számlák listája",
  "results.accountSheet.columns.amount": "Összeg",
  "results.accountSheet.columns.documentDate": "Dokumentum dátuma",
  "results.accountSheet.columns.documentNumber": "Dokumentumszám.",
  "results.accountSheet.columns.documents": "Bevételek",
  "results.accountSheet.columns.offsettingAccounts": "Kontra számlák",
  "results.accountSheet.columns.openItemAmount": "Nyitott összeg",
  "results.accountSheet.columns.postingSymbol": "BS",
  "results.accountSheet.columns.postingText": "Szöveg kiküldése",
  "results.accountSheet.details.costCenter": "Költségközpont",
  "results.accountSheet.details.discount": "Készpénzkedvezmény",
  "results.accountSheet.details.externalDocumentNumber": "Külső dokumentum száma.",
  "results.accountSheet.details.foreignCurrency": "Külföldi valuta / árfolyam",
  "results.accountSheet.details.period": "Időszak",
  "results.accountSheet.details.serialNumber": "Folyóiratszám.",
  "results.accountSheet.details.tax": "Adó",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "Exportszállítások",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "HÉA-s építési munkák",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "HÉA nélküli építési munkák",
  "results.accountSheet.details.taxCode.EUSt": "EUSt",
  "results.accountSheet.details.taxCode.igEMitVSt": "igE áfával",
  "results.accountSheet.details.taxCode.igEOhneVst": "igE áfa nélkül",
  "results.accountSheet.details.taxCode.igLieferung": "ig szállítás",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "Kisvállalkozások",
  "results.accountSheet.details.taxCode.RCMitVSt": "RC VSt-vel",
  "results.accountSheet.details.taxCode.RCOhneVSt": "RC HÉA nélkül",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "Egyéb szolgáltatások EU (a ZM függvényében)",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "részben levonható HÉA",
  "results.accountSheet.details.taxCode.Ust": "HÉA",
  "results.accountSheet.details.taxCode.VSt": "VSt",
  "results.accountSheet.details.taxPercent": "Adó százalék",
  "results.accountSheet.details.vatPeriod": "UVA időszak",
  "results.accountSheet.documents.downloadAll": "Minden fájl letöltése",
  "results.accountSheet.filters.amount": "Összeg",
  "results.accountSheet.filters.documentDate": "Dokumentum dátuma",
  "results.accountSheet.filters.postingSymbol": "Foglalási szimbólum",
  "results.accountSheet.noResults.message": "A kiválasztott számlához nem találtak elemeket",
  "results.accountSheet.noResults.open.message": "A kiválasztott számlához nem találtak nyitott tételeket",
  "results.accountSheet.noResults.open.title": "Nem találtak nyitott tételeket",
  "results.accountSheet.noResults.title": "Nincs talált álláshely",
  "results.accountSheet.offsettingAccounts.more": "{count} több",
  "results.accountSheet.paid": "fizetett",
  "results.accountSheet.search.count": "Foglalások: <b>{szám}</b>",
  "results.accountSheet.search.placeholder": "Keresés foglalási szöveg...",
  "results.accountSheet.sum.title": "Σ Összesen",
  "results.accountSheet.tabs.all": "Minden tétel",
  "results.accountSheet.tabs.open": "Csak nyitott tételek",
  "results.accountSheet.title": "Számlakivonat a {számlaNr} \"{név}\" számlához.",
  "results.columns.changeAbsolute": "Abw.",
  "results.columns.changePercentage": "Dev. (%)",
  "results.columns.credit": "Van",
  "results.columns.debit": "Cél",
  "results.common.accounts": "Számlák",
  "results.common.quickExpand.expandAccounts": "Minden (beleértve a számlákat is)",
  "results.common.quickExpand.expandNthDepth": "Szint {mélység}",
  "results.common.quickExpand.expandRoot": "Fő bontási szint",
  "results.common.quickExpand.expandRows": "Hajtsa ki a vonalakat:",
  "results.disclaimer": "Fontos megjegyzés",
  "results.disclaimerMessage": "Azon időszakok esetében, amelyekre vonatkozóan az éves pénzügyi kimutatásokat még nem véglegesítették, a bemutatott elemzések az adott beszámolási időpontra vonatkozó aktuális egyenleglistából származó adatokon alapulnak. Az elemzések a rendelkezésünkre bocsátott dokumentumokból készültek, azok érdemi felülvizsgálata nélkül, és az ügyfél számára időben további információkat nyújtanak az aktuális üzleti fejlődésről. Különösen az időbeli elhatárolásokat, a céltartalékok változását, a készletek változását, a követelések értékelését és az értékcsökkenési leírást csak azokban az üzleti években vettük figyelembe, amelyekre vonatkozóan az éves pénzügyi kimutatások még nem készültek el, amennyiben erről az ügyféllel megállapodtunk. Ez eltéréseket eredményez a bemutatott elemzések és a végleges éves pénzügyi kimutatások között.\n\nEzért nem vállalunk felelősséget az elemzések pontosságáért és teljességéért az ügyféllel vagy harmadik felekkel szemben. Minden más tekintetben a Könyvvizsgálói Szakmák Általános Szerződési Feltételei (AAB) vonatkoznak megbízásainkra és felelősségünkre, beleértve a harmadik felekkel szembeni felelősséget is.",
  "results.navbar.buttons.accountListing": "Egyensúlyi lista",
  "results.navbar.buttons.balanceSheet": "Mérleg",
  "results.navbar.buttons.cashAccounting": "Bevételi és kiadási kimutatás",
  "results.navbar.buttons.customerListing": "Adóslista",
  "results.navbar.buttons.payrollAccount": "Bérszámfejtési számla",
  "results.navbar.buttons.profitAndLoss": "G&V",
  "results.navbar.buttons.vendorListing": "Fizetendő számlák listája",
  "results.noFinancialAccountancies.message": "Jelenleg nem állnak rendelkezésre értékelések.",
  "results.noFinancialAccountancies.title": "Nincsenek értékelések",
  "results.payrollAccountSheet.dataTable.month": "hónap",
  "results.payrollAccountSheet.dataTable.number": "Nem.",
  "results.payrollAccountSheet.dataTable.payrollType": "Bér típusa",
  "results.payrollAccountSheet.dataTable.total": "Összesen",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "Összesen előző év",
  "results.payrollAccountSheet.noPermission.message": "Ön nem jogosult a bérszámla megtekintésére.",
  "results.payrollAccountSheet.noPermission.title": "Nincs engedély",
  "results.payrollAccountSheet.noResults.details": "Ez a bérszámfejtési számla nem tartalmaz semmilyen adatot",
  "results.payrollAccountSheet.noResultsYear.message": "A kiválasztott évre vonatkozóan nem találtak adatokat.",
  "results.payrollAccountSheet.noResultsYear.title": "Nem találtak adatot",
  "results.settings.accountsVisibility.hideAccounts": "Fiókok elrejtése",
  "results.settings.accountsVisibility.showAccounts": "Számlák megjelenítése",
  "results.settings.orderBy.accountNameAsc": "Fióknév növekvőben",
  "results.settings.orderBy.accountNameDesc": "Fióknév csökkenő",
  "results.settings.orderBy.accountNrAsc": "Számlaszám növekvőben",
  "results.settings.orderBy.accountNrDesc": "Számlaszám csökkenő",
  "results.settings.orderBy.amountAsc": "Összeg emelkedő",
  "results.settings.orderBy.amountDesc": "Összeg csökkenő",
  "results.settings.orderBy.title": "Rendezés: {orderBy}",
  "results.settings.previousYearRanges.fullYear": "Összes előző év megjelenítése",
  "results.settings.previousYearRanges.partialYear": "Az előző évet csak {previousYearRangeUntil}-ig vegye figyelembe.",
  "results.settings.variant.compactView": "Kompakt nézet",
  "results.settings.variant.currentAndPastYear": "Hírek és előző év",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "Jelenlegi és előző év abszolút változással",
  "results.settings.variant.currentAndPastYearPercentageChange": "A folyó és az előző év százalékos változásával",
  "results.settings.variant.currentYear": "Tárgyév",
  "results.settings.variant.detailView": "Részletes nézet",
  "results.title": "Értékelések",
  "role.accountingAssistent": "Könyvelési asszisztens",
  "role.accountingAssistent.description": "Hozzáférés a könyveléshez jóváhagyási jogok nélkül",
  "role.accountingManagement": "Pénzügyi menedzsment",
  "role.accountingManagement.description": "Hozzáférés a könyveléshez jóváhagyási jogosultsággal",
  "role.admin": "Adminisztráció / Menedzsment",
  "role.admin.description": "Minden területhez való hozzáférési jog",
  "role.assistent": "Segítségnyújtás",
  "role.assistent.description": "Hozzáférés a könyveléshez és a bérszámfejtéshez jóváhagyási jogosultságok nélkül<br />A felhasználók módosítását jelentheti",
  "role.custom": "Custom",
  "role.custom.description": "Felhasználó által meghatározott hozzáférési jogok",
  "role.custom.jobTitle": "Alkalmazottak",
  "role.custom.jobTitle.female": "Személyzet",
  "role.hrAssistent": "Bérszámfejtő asszisztens",
  "role.hrAssistent.description": "Hozzáférés a bérszámfejtéshez jóváhagyási jogosultságok nélkül",
  "role.hrManagement": "HR menedzsment",
  "role.hrManagement.description": "Hozzáférés a bérszámfejtéshez jóváhagyási jogosultságokkal",
  "role.internationalProjects": "Projekt munkatárs",
  "role.internationalProjects.female": "Projekt munkatárs",
  "role.legalAdvisor": "Jogi tanácsadó",
  "role.noRole": "nincs rész",
  "role.noRole.description": "Nincsenek hozzáférési jogok a TPA Connecthez",
  "role.staff": "Alkalmazott",
  "role.staff.description": "Elektronikus bevételi kimutatásokat kap",
  "role.staff.female": "Alkalmazott",
  "role.superAdmin": "Superadmin",
  "role.superAdmin.description": "Hozzáférés minden területhez, beleértve a felhasználók kezelését is",
  "role.tpaAccounting": "Számvitel",
  "role.tpaAccounting.description": "Tanácsadói hozzáférés a könyveléshez",
  "role.tpaAdvisor": "Tanácsadó",
  "role.tpaAdvisor.description": "Tanácsadói hozzáférés minden területhez",
  "role.tpaAdvisor.female": "Tanácsadó",
  "role.tpaAnnualAccounts": "Éves beszámoló",
  "role.tpaAnnualAccounts.description": "Felelős az éves beszámolóért",
  "role.tpaAuditor": "Auditálás",
  "role.tpaBusinessConsultant": "Vezetői tanácsadás",
  "role.tpaBusinessConsultant.description": "Vezetői tanácsadásért felelős",
  "role.tpaChat": "Kapcsolat",
  "role.tpaChatSecret": "Kapcsolat",
  "role.tpaHr": "Bérszámfejtés",
  "role.tpaHr.description": "Tanácsadói hozzáférés a bérszámfejtéshez",
  "role.tpaOther": "A projekt munkatársai",
  "role.tpaOther.female": "Projekt alkalmazott",
  "role.tpaPartner": "Partner",
  "role.tpaPartner.description": "Tanácsadói hozzáférés minden területhez",
  "role.tpaPartner.female": "Partner",
  "role.tpaPayrollAccountant": "Bérszámfejtés",
  "role.tpaReporting": "Jelentés",
  "role.tpaReporting.description": "Felelős a jelentéstételért",
  "role.tpaSpecialAdvisor": "Különleges konzultáció",
  "role.tpaSpecialAdvisor.description": "Különleges tanácsadásért felelős",
  "role.tpaTaxAccountant": "Adótanácsadás",
  "role.tpaTaxAccountant.description": "Adótanácsadásért felelős",
  "screen.accounting.navbar.bankConnections": "Banki adatok",
  "screen.accounting.navbar.heading": "Számvitel",
  "screen.accounting.navbar.overview": "Áttekintés",
  "screen.accounting.navbar.records": "Bevételek",
  "screen.accounting.overview.bankConnection": "{salutation}, <br /> <br /> Egy vagy több bankszámlája ebben az elszámolási időszakban lejár. Kérjük, újítsa meg őket.<br /> <br /> Tisztelettel<br /> {aláírás}",
  "screen.accounting.overview.bankConnection.expired": "{salutation}, <br /> <br /> Egy vagy több bankadata lejárt. Kérjük, újítsa meg őket.<br /> <br /> Tisztelettel<br /> {aláírás}",
  "screen.accounting.overview.bankConnection.expired.title": "Banki adatok lejártak",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "Bankszámlák megújítása",
  "screen.accounting.overview.bankConnection.title": "A bankszámlák lejárnak",
  "screen.accounting.overview.card.analysis": "Jelentések jóváhagyása a következőknek: ",
  "screen.accounting.overview.card.globalAnalysis": "Vállalati szintű jelentések jóváhagyása",
  "screen.accounting.overview.card.overview": "Közlemény elszámolása",
  "screen.accounting.overview.finished.download": "Minden fájl letöltése",
  "screen.accounting.overview.finished.message": "{<br /> <br /> Köszönöm, hogy ellenőrizte és jóváhagyta a számlákat. Kérjük, az alábbiakban küldje el jelentéseit.<br /> <br /> Tisztelettel, <br />{aláírás}",
  "screen.accounting.overview.reports": "{<br /> <br /> Mellékelve találja a következő dokumentumokat a {időszakra} vonatkozóan.} A dokumentumok az Ön által megadott információk és az Ön által benyújtott dokumentumok alapján készültek. Ha bármilyen pontatlanságot vagy hiányosságot talál a dokumentumokban, kérjük, értesítsen minket, hogy elvégezhessük a megfelelő javításokat. <br /> <br /> Ha bármilyen kérdése van, kérjük, forduljon hozzánk bizalommal.<br /> <br /> <br /> Tisztelettel, <br />{aláírás}",
  "screen.accounting.overview.ticketsWelcome.inProgress": "{<br /> <br /> Köszönöm az utalványok továbbítását. A könyvelés során felmerült néhány kérdés:",
  "screen.accounting.overview.welcome.closed": "{<br /> <br /> Köszönjük, hogy benyújtotta az összes vonatkozó igazoló dokumentumot. Ezeket a megbeszélt határidők szerint feldolgozzuk, és bármilyen kérdéssel kapcsolatban visszajelzünk Önnek.",
  "screen.accounting.overview.welcome.inProgress": "{<br /> <br /> Kérjük, hogy töltse fel a foglalási dokumentumait {időszak}{lejárat}-ra vonatkozóan. Kérjük, küldje el nekünk az összes szükséges dokumentumot. <br /> <br />Minden jót, <br />{aláírás}",
  "screen.accounting.records.banner.selectedAll": "A \"{recordType}\" összes {count} fájlja ki van választva.",
  "screen.accounting.records.banner.selectedAll.transferFailed": "Az összes {count} sikertelen fájl a \"{recordType}\"-ban ki van választva.",
  "screen.accounting.records.banner.selectedCount": "Az összes {count} fájl ezen az oldalon ki van választva",
  "screen.accounting.records.banner.totalCount": "Válassza ki az összes {count} fájlt a \"{recordType}\"-ban.",
  "screen.accounting.records.banner.totalCount.transferFailed": "Válassza ki az összes {count} sikertelen fájlt a \"{recordType}\"-ban.",
  "screen.accounting.records.banner.unselect": "A kiválasztás törlése",
  "screen.accounting.records.documents": "Fájlok:",
  "screen.accounting.records.empty": "A fájl üres",
  "screen.accounting.records.filter.transferFailed": "Az átvitel sikertelen",
  "screen.accounting.records.format.error": "Ellenőrizze a formátumot",
  "screen.accounting.records.move.dialog.button.text": "Mozgás",
  "screen.accounting.records.move.multiple.dialog.title": "Dokumentumok áthelyezése",
  "screen.accounting.records.move.single.dialog.title": "Dokumentum áthelyezése",
  "screen.accounting.records.navbar.tab.list": "Bevételek ({count})",
  "screen.accounting.records.navbar.tab.tickets": "Lekérdezések ({count})",
  "screen.accounting.records.person.responsible": "Felelősség",
  "screen.accounting.records.serverUpload.error": "A fájlt nem sikerült feltölteni a szerverre",
  "screen.accounting.records.serverUpload.typeError": "Nem elfogadott formátum",
  "screen.accounting.records.sizeAndFormat.error": "A fájl méretének és formátumának ellenőrzése",
  "screen.accounting.records.size.error": "Fájlméret ellenőrzése",
  "screen.accounting.records.tickets": "| Érdeklődés: {numTickets}",
  "screen.accounting.records.transfer.button.text": "Továbbítás a TPA-nak",
  "screen.accounting.records.transfer.dialog.text": "Szeretné az {periodStart} - {periodEnd} időszakra vonatkozó <b>minden könyvelési</b> fájlt átvinni a TPA-ba könyvelés céljából?",
  "screen.accounting.records.transfer.dialog.textRecordType": "Szeretné átvinni <b>\"{név}\"</b> összes fájlját a {időszakKezdés} - {időszakVég} időszakra vonatkozóan a TPA-ba kiküldetés céljából?",
  "screen.accounting.records.transfer.dialog.title": "Könyvelés továbbítása",
  "screen.accounting.records.transfer.retryDialog.button.text": "Próbálja újra.",
  "screen.accounting.records.transfer.retryDialog.file": "Hiba a {failedFile} átvitelében.",
  "screen.accounting.records.transfer.retryDialog.text": "Hoppá. Itt valami rosszul sült el. Kérjük, próbálja meg újra.",
  "screen.accounting.records.transfer.retryDialog.title": "A sebességváltó meghibásodott",
  "screen.accounting.records.transfer.successDialog.text": "A TPA tanácsadója most átnézi az adatokat, és felveszi Önnel a kapcsolatot az esetleges kérdésekkel kapcsolatban.",
  "screen.accounting.records.transfer.successDialog.title": "Sikeresen továbbított adatok",
  "screen.accounting.records.types.header": "Számvitel",
  "screen.accounting.records.unknown.error": "Ismeretlen hiba",
  "screen.accounting.records.upload.button": "Bizonylatok feltöltése",
  "screen.accounting.records.upload.navbar.back": "Könyvelés / utalványok",
  "screen.accounting.recordTypes.title": "Válassza ki az utalványok típusát",
  "screen.advisor.tabbar.active": "Aktív",
  "screen.advisor.tabbar.notConfigured": "Nincs konfigurálva",
  "screen.advisor.upload.approve.info": "Mikor van szükségem minősített aláírásra?",
  "screen.advisor.uploads.upload.approve": "Ügyfél jóváhagyása",
  "screen.advisor.uploads.upload.approve.option.click": "Jóváhagyás kattintással",
  "screen.advisor.uploads.upload.approve.option.none": "Nincs ügyfél jóváhagyás",
  "screen.advisor.uploads.upload.approve.option.qes": "Jóváhagyás elektronikus aláírással",
  "screen.advisor.uploads.upload.buttonText": "Átvitel",
  "screen.advisor.uploads.upload.category": "Kategória",
  "screen.advisor.uploads.upload.category.validation": "Kérjük, válasszon ki egy kategóriát",
  "screen.advisor.uploads.upload.comment": "Átviteli szöveg",
  "screen.advisor.uploads.upload.comment.downloadError": "Hiba a megjegyzés betöltése",
  "screen.advisor.uploads.upload.dueDate": "Esedékes",
  "screen.advisor.uploads.upload.dueDate.validation": "Kérjük, adja meg az esedékesség dátumát",
  "screen.advisor.uploads.upload.global": "Vállalati szintű",
  "screen.advisor.uploads.upload.module": "Terület",
  "screen.advisor.uploads.upload.module.validation": "Kérjük, válasszon ki egy területet",
  "screen.advisor.uploads.upload.navBack": "Feltöltések",
  "screen.advisor.uploads.upload.navHeader": "Fájlok feltöltése: {kategória}",
  "screen.advisor.uploads.upload.notifyCustomer": "Értesítse az ügyfeleket",
  "screen.advisor.uploads.upload.subsidiary.validation": "Kérjük, válasszon egy vállalati kódot",
  "screen.advisor.uploads.upload.visibleToEmployee": "Jóváhagyás munkavállalónak",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "Ha találtunk egy felhasználót az {email} e-mail címmel, hamarosan kap egy e-mailt a jelszó visszaállításához szükséges linkkel.",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "Nem kapott levelet? Ellenőrizze a spam mappát, vagy lépjen kapcsolatba a rendszergazdával.",
  "screen.auth.forgot_password_confirm.title": "Hamarosan kap egy e-mailt",
  "screen.auth.forgot_password_email.form.button": "Link küldése a visszaállításhoz",
  "screen.auth.forgot_password_email.form.label.email": "Felhasználói azonosító / e-mail cím",
  "screen.auth.forgot_password_email.subtitle": "Adja meg az e-mail címét, és küldünk egy e-mailt a jelszó visszaállításához szükséges linkkel.",
  "screen.auth.forgot_password_email.title": "Jelszó visszaállítása",
  "screen.auth.forgot_password_email.validation_error": "Kérjük, adja meg az e-mail címét",
  "screen.auth.forgot_password_reset.error.password_not_set": "Kérjük, adjon meg egy jelszót",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "A jelszavak nem egyeznek",
  "screen.auth.forgot_password_reset.error.password_weak": "A jelszavad még mindig túl gyenge.",
  "screen.auth.forgot_password_reset.form.button": "Jelszó módosítása",
  "screen.auth.forgot_password_reset.form.button.mfa": "Kód elküldése",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "Küldje el újra a kódot",
  "screen.auth.forgot_password_reset.form.mfa": "Kód",
  "screen.auth.forgot_password_reset.form.password": "Jelszó",
  "screen.auth.forgot_password_reset.form.repeat_password": "Jelszó megismétlése",
  "screen.auth.forgot_password_reset.subtitle": "Állítson be új jelszót a TPA Connect fiókjához.",
  "screen.auth.forgot_password_reset.subtitle.mfa": "Kérjük, írja be a kódot, amelyet SMS-ben küldtünk a telefonszámára.",
  "screen.auth.forgot_password_reset.title": "Jelszó módosítása",
  "screen.auth.forgot_password_reset.title.mfa": "Jelszóváltás megerősítése kétfaktoros hitelesítéssel",
  "screen.auth.registration.form.button": "Regisztráció",
  "screen.auth.registration.form.button.mfa": "Kód elküldése",
  "screen.auth.registration.form.button.mfa.resendCode": "Küldje el újra a kódot",
  "screen.auth.registration.form.label.mfa": "Kód",
  "screen.auth.registration.form.label.password": "Jelszó",
  "screen.auth.registration.form.label.repeat_password": "Jelszó megismétlése",
  "screen.auth.registration.form.section_1": "Legalább 8 karaktert használjon.",
  "screen.auth.registration.form.section_2": "A jelszó megerősítéséhez használhat kis- és nagybetűket, számokat és speciális karaktereket.",
  "screen.auth.registration.form.section_3": "Gondoljon egy mnemonikus módszerre, amely segít megjegyezni a jelszavát.",
  "screen.auth.registration.mfa.subtitle": "Kérjük, írja be a kódot, amelyet SMS-ben küldtünk a telefonszámára.",
  "screen.auth.registration.subtitle": "Állítson be jelszót a TPA Connect fiókjához.",
  "screen.auth.registration.title": "Jelszó beállítása és regisztrálása",
  "screen.auth.welcome.subtitle": "Kapcsolja össze TPA Connect-fiókját Microsoft-fiókjával, hogy minden funkciót teljes mértékben kihasználhasson. Fiókját később is összekapcsolhatja.",
  "screen.auth.welcome.subtitle.msOnly": "Kapcsolja össze TPA Connect fiókját Microsoft fiókjával, hogy az összes funkciót teljes mértékben kihasználhassa.",
  "screen.auth.welcome.title": "Üdvözöljük a TPA Connect-en!",
  "screen.changeUsername.checkbox": "Ezúton megerősítem új e-mail címemet",
  "screen.changeUsername.message": "A TPA Connect rendszere észrevette, hogy az Ön e-mail címe a következőre változott: <b>{newUsername}</b>. Ahhoz, hogy biztosak lehessünk abban, hogy ez a változtatás helyes, fontos, hogy ezt egy egyszerű kattintással megerősítse.",
  "screen.changeUsername.title": "Új email cím",
  "screen.cockpit.allEvents": "Események: Minden esemény",
  "screen.cockpit.allNews": "Hírek: Minden cikk",
  "screen.cockpit.dueDates.export": "Határidők exportálása",
  "screen.cockpit.dueDates.navbar.heading": "Nyitott határidők",
  "screen.cockpit.dueDates.projects.text": "A(z) {documentName} jóváhagyása",
  "screen.cockpit.dueDates.records.accounting.text": "Számviteli közzététel a {leányvállalat neve} - {időszak} számára",
  "screen.cockpit.dueDates.records.hr.text": "A {leányvállalat neve} - {időszak} bérszámfejtési adatainak közzététele",
  "screen.cockpit.dueDates.reports.global.text": "A \"vállalati szintű jelentések\" jóváhagyása - {period}",
  "screen.cockpit.dueDates.reports.subsidiary.text": "Jelentés jóváhagyása a következőnek: {subsidiaryName} - {period} ",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "Kérjük, a jelentések benyújtásához használja a BMD NTCS-t.",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "Jelentés feltöltése",
  "screen.cockpit.dueDates.reportUpload.global.text": "Jelentésfeltöltés a \"vállalati szintű jelentésekhez\" - {időszak}",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "Jelentésfeltöltés {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.tickets.global.text": "A dokumentum {documentName} megtekintésének határideje",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "A {documentName} dokumentumba való betekintés határideje a {subsidiaryName} számára",
  "screen.cockpit.emptyState.message": "Gyere vissza később",
  "screen.cockpit.emptyState.title": "Jelenleg nincsenek hírek",
  "screen.cockpit.eventDetail": "Esemény részletei",
  "screen.cockpit.eventDetails.heading": "Részletek és regisztráció",
  "screen.cockpit.eventDetails.lecturer.email": "E: {email}",
  "screen.cockpit.eventDetails.lecturers": "Bemutató",
  "screen.cockpit.eventDetails.lecturer.telephone": "T: {telefon}",
  "screen.cockpit.eventDetails.register": "Regisztráljon most",
  "screen.cockpit.event.moreInformation": "INFO & REGISZTRÁCIÓ",
  "screen.cockpit.kpis.add": "Adja hozzá a kulcsszámot",
  "screen.cockpit.kpis.chart.legend": "1000 €-ban",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "Egészségbiztosítás",
  "screen.cockpit.kpis.configurationDialog.metric.label": "KPI",
  "screen.cockpit.kpis.configurationDialog.months.label": "Hónapok",
  "screen.cockpit.kpis.configurationDialog.title": "KPI widget konfigurálása",
  "screen.cockpit.kpis.ebit.chart.name": "EBIT alakulása",
  "screen.cockpit.kpis.ebit.kpi.name": "Eddigi EBIT",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "Az üzleti év kezdete óta már teljes mértékben lekönyvelt hónapok EBIT-értékét mutatja.",
  "screen.cockpit.kpis.empty": "Az Ön \"{name}\" cégkódja jelenleg nem elérhető.",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "Egészségbiztosítási egyenleg",
  "screen.cockpit.kpis.noneConfigured": "Adja hozzá a kulcsszámot",
  "screen.cockpit.kpis.revenue.chart.name": "Forgalom alakulása",
  "screen.cockpit.kpis.revenue.kpi.name": "Eddigi forgalom",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "Az üzleti év kezdete óta a már teljesen lekötött hónapok forgalmát mutatja.",
  "screen.cockpit.kpis.save": "Változások alkalmazása",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "Hátralék",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "Hitelfelvétel",
  "screen.cockpit.kpis.taxAccount.kpi.label": "Állapot {dátum}",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "[összeg] utoljára {dátum}",
  "screen.cockpit.kpis.taxAccount.kpi.name": "Egyenleg adószámla",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "Már kifizetett {dátum}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "{invoicesCount} Számlák",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "Állapot {dátum}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "Kintlévőségek a TPA-nál",
  "screen.cockpit.navbar.heading": "Cockpit",
  "screen.cockpit.overview.kpis.title": "Tények és számok",
  "screen.cockpit.overview.newestPublications": "Legutóbbi publikációk",
  "screen.cockpit.overview.notifications.dueDates": "Nyílt határidők",
  "screen.cockpit.overview.notifications.messages": "Hírek",
  "screen.cockpit.overview.notifications.tickets": "Érdeklődés",
  "screen.cockpit.overview.notifications.title": "Értesítések",
  "screen.cockpit.overview.promotions.title": "Hírek",
  "screen.cockpit.overview.relevantNews.title": "Releváns hírek",
  "screen.cockpit.overview.relevantPublications.description": "Kérhet nyomtatott TPA-kiadványt, amelyet néhány napon belül elküldünk Önnek. Vagy: Letöltheti a kívánt TPA-kiadvány elektronikus változatát (PDF).",
  "screen.cockpit.overview.relevantPublications.title": "Releváns publikációk",
  "screen.cockpit.promotion": "Hírek: cikk",
  "screen.cockpit.promotion.additionalNews": "Hírek",
  "screen.cockpit.relevantNews": "Hírek: Cikk",
  "screen.cockpit.relevantNews.additionalNews": "További hírek",
  "screen.cockpit.tickets.navbar.heading": "Nyitott lekérdezések",
  "screen.hr.additionaldata.addBonus": "Adjon hozzá még egy juttatást",
  "screen.hr.additionaldata.additionaldata": "Kiegészítő információk",
  "screen.hr.additionaldata.additionalInformation": "További információk",
  "screen.hr.additionaldata.amount": "Összeg {pénzben} havonta",
  "screen.hr.additionaldata.amount_error": "Kérjük, adjon meg egy összeget",
  "screen.hr.additionaldata.bankDetails": "Banki adatok",
  "screen.hr.additionaldata.bic": "BIC",
  "screen.hr.additionaldata.bic.validation": "Érvénytelen BIC",
  "screen.hr.additionaldata.commuterAllowance": "Ingázási támogatás",
  "screen.hr.additionaldata.costCenter": "Költségközpont",
  "screen.hr.additionaldata.costCenterNote": "Megjegyzés a költséghelyről",
  "screen.hr.additionaldata.description": "A következő információkat is ki lehet tölteni a szolgáltatás megkezdésekor, ha szükséges.",
  "screen.hr.additionaldata.designator": "Támogatás",
  "screen.hr.additionaldata.designator_error": "Kérjük, adjon meg egy juttatást",
  "screen.hr.additionaldata.emailAddress": "E-mail cím",
  "screen.hr.additionaldata.email_error": "Kérjük, adja meg a helyes e-mail címet",
  "screen.hr.additionaldata.familyBonusPlusAmount": "Fabo Plus Összeg EUR-ban",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "Kérjük, adjon meg legalább 0",
  "screen.hr.additionaldata.funder": "Költségviselő",
  "screen.hr.additionaldata.gross": "Bruttó",
  "screen.hr.additionaldata.hours_error": "Kérjük, adjon meg egy számot {minHours} és {maxHours} között.",
  "screen.hr.additionaldata.iban": "IBAN",
  "screen.hr.additionaldata.iban_error": "Érvénytelen IBAN",
  "screen.hr.additionaldata.jobDescription": "Munkakör",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "Kilométer az ingázó euró esetében",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "Kérjük, adjon meg legalább 0",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "A kilométereket csak egész számokban lehet megadni.",
  "screen.hr.additionaldata.maritalStatus": "Családi állapot",
  "screen.hr.additionaldata.monthlySalary": "Fizetés {pénzben} havonta",
  "screen.hr.additionaldata.monthlySalary_error": "Kérjük, adjon meg legalább 0",
  "screen.hr.additionaldata.net": "Net",
  "screen.hr.additionaldata.nextPage": "Tovább a dokumentumokhoz",
  "screen.hr.additionaldata.number_error": "Kérjük, adjon meg egy számot",
  "screen.hr.additionaldata.per": "per",
  "screen.hr.additionaldata.personaldata": "Személyes adatok",
  "screen.hr.additionaldata.religiousConfession": "Vallási vallásgyakorlat",
  "screen.hr.additionaldata.salarayAndPosition": "Javadalmazás és beosztás",
  "screen.hr.additionaldata.saveChanges": "Változások mentése",
  "screen.hr.additionaldata.soleEarnerStatus": "Egyedüli kereső",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "Címek és tudományos fokozatok",
  "screen.hr.additionaldata.titlePrefix": "Prefixed",
  "screen.hr.additionaldata.titleSuffix": "A  alatt.",
  "screen.hr.additionaldata.wageAgreementClassification": "Osztályozási kollektív szerződés",
  "screen.hr.additionaldata.weeklyWorkDays": "Heti munkanapok száma",
  "screen.hr.additionaldata.weeklyWorkDays_error": "Kérjük, válasszon egy érvényes értéket",
  "screen.hr.additionaldata.workingHours": "Órák száma",
  "screen.hr.additionaldata.workingHoursInterval": "Időszak",
  "screen.hr.additionaldata.workingTimes": "Munkaidő",
  "screen.hr.cancelRegistration.dataSaved": "Ha megszakítja, a megadott adatok továbbra is elmentve maradnak. A regisztráció egy későbbi időpontban is folytatható.",
  "screen.hr.cancelRegistration.header": "Regisztráció törlése",
  "screen.hr.cancelRegistration.question1": "Biztos, hogy le akarja mondani a regisztrációs folyamatot? Az eddig megadott adatokat felhasználhatja arra, hogy",
  "screen.hr.cancelRegistration.question2": " és nyújtsa be a TPA adótanácsadójának regisztráció céljából.",
  "screen.hr.deregistration.data.navback": "Személyzet / Alkalmazottak / Regisztrált / {firstname} {lastname}",
  "screen.hr.deregistration.documents.navback": "Személyzet / Alkalmazottak / Nyilvántartásba vett / {firstname} {lastname} / A nyilvántartásból való törlés adatai",
  "screen.hr.deregistration.navheader": "{firstname} {lastname} kijelentkezés",
  "screen.hr.deregistration.validation.deregistrationDate": "Kérjük, adja meg a kilépés dátumát",
  "screen.hr.deregistration.validation.deregistrationDateMin": "A kilépés dátumának nagyobbnak kell lennie, mint a belépés dátumának.",
  "screen.hr.deregistration.validation.deregistrationReason": "Kérjük, adja meg a távozás okát",
  "screen.hr.deregistration.validation.remainingHolidays": "Kérjük, adja meg a fennmaradó szabadságot",
  "screen.hr.deregistration.validation.remainingOvertime": "Kérjük, jelölje meg a nyitott túlórákat",
  "screen.hr.documents.citizenship": "Állampolgársági bizonyítvány",
  "screen.hr.documents.contract": "Szolgáltatás&shy;szerződés",
  "screen.hr.documents.description": "További dokumentumok feltöltése, például: munkaszerződés, személyi igazolvány, állampolgárság igazolása, regisztrációs űrlap vagy egyéb dokumentumok.",
  "screen.hr.documents.emptystate.buttonlabel": "Dokumentumok feltöltése",
  "screen.hr.documents.emptystate.message": "Ehhez a munkavállalóhoz nem állnak rendelkezésre dokumentumok",
  "screen.hr.documents.emptystate.title": "Nincsenek rendelkezésre álló dokumentumok",
  "screen.hr.documents.enterManually": "Kézi adatbevitel",
  "screen.hr.documents.idcard": "Személyi&shy;személyi igazolvány",
  "screen.hr.documents.logTime": "Rekordidők",
  "screen.hr.documents.needsRelease.notReleased": "Várakozás a kiadásra",
  "screen.hr.documents.needsRelease.released": "megjelent",
  "screen.hr.documents.needsRelease.released.tooltip": "megjelent {releasedAt} a {név} által {név}",
  "screen.hr.documents.nextButton": "Az összefoglalóhoz",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "Kezdje el most feltölteni fájljait, vagy adja meg manuálisan a nem teljesítési időket.",
  "screen.hr.documents.others": "Egyéb",
  "screen.hr.documents.registrationslip": "Üzenet&shy;lap",
  "screen.hr.documents.uploadDocuments": "Dokumentumok feltöltése",
  "screen.hr.edit.flow.navback": "Személyzet / Alkalmazottak / Tennivalók: Állapot / {firstName} {lastName}",
  "screen.hr.edit.flow.navheader": "{firstName} {lastName} szerkesztés",
  "screen.hr.employee.current.all": "Minden",
  "screen.hr.employee.current.anyDateOfJoining": "Bármelyik belépési dátum",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "Bármely belépési/kilépési dátum",
  "screen.hr.employee.current.cancellation": "Törlések",
  "screen.hr.employee.current.changeUsername": "Új e-mail",
  "screen.hr.employee.current.changeUsername.successDialog.message": "<b>{felhasználónév}</b> kap egy kérést az újonnan megadott e-mail cím megerősítésére.",
  "screen.hr.employee.current.changeUsername.successDialog.title": "Az e-mail cím megváltoztatására szolgáló e-mail sikeresen elküldésre került.",
  "screen.hr.employee.current.changeUsername.tooltip": "Az alkalmazott új e-mail címe {newUsername}. A gombra kattintva a munkavállalónak meg kell erősítenie az új e-mail címet.",
  "screen.hr.employee.current.dataChange": "Törzsadat változások",
  "screen.hr.employee.current.notTransferred": "Nem továbbított",
  "screen.hr.employee.current.registration": "Regisztrációk",
  "screen.hr.employee.deregistration.data.date": "Kilépés időpontja",
  "screen.hr.employee.deregistration.data.header": "Kijelentkezési adatok",
  "screen.hr.employee.deregistration.data.reason": "A távozás oka",
  "screen.hr.employee.deregistration.data.remainingHolidays": "Maradék szabadság napokban kifejezve",
  "screen.hr.employee.deregistration.data.remainingOvertime": "Nyílt túlóra",
  "screen.hr.employee.deregistration.data.subtitle": "Ha ezek rendelkezésre állnak, töltse fel a kilépési dokumentumokat a végső elszámoláshoz.",
  "screen.hr.employee.deregistration.documents.submitButton": "A törlés továbbítása",
  "screen.hr.employee.deregistration.stepper.data": "Kijelentkezési adatok",
  "screen.hr.employee.deregistration.stepper.documents": "Kilépési dokumentumok",
  "screen.hr.employee.details.addFiles": "Dokumentumok hozzáadása",
  "screen.hr.employee.details.currentEmployment": "Jelenlegi munkaviszony",
  "screen.hr.employee.details.deregisterEmployee": "A munkavállaló törlése",
  "screen.hr.employee.details.employments": "Foglalkoztatási kapcsolatok",
  "screen.hr.employee.details.fileProvidedBy": "feltéve, hogy {name}",
  "screen.hr.employee.details.fileReleased": "Látható az alkalmazottak számára",
  "screen.hr.employee.details.linkedFiles": "Kapcsolódó fájlok",
  "screen.hr.employee.details.navBack": "Alkalmazottak / {list}",
  "screen.hr.employee.details.pastEmployments": "Korábbi munkaviszony",
  "screen.hr.employee.details.payrollAccount.navBack": "Személyzet / Aktuális DN / {teljesnév}",
  "screen.hr.employee.details.personnelFile": "Személyzeti dosszié",
  "screen.hr.employee.details.personnelNumber": "Személyzeti szám:",
  "screen.hr.employee.details.records": "A jövedelem igazolása",
  "screen.hr.employee.details.reregisterEmployee": "Újraregisztrálja a munkavállalót",
  "screen.hr.employee.details.showAll": "Mindent megmutatni",
  "screen.hr.employee.details.showEmployeePayrollAccount": "Fizetési számla megjelenítése",
  "screen.hr.employee.details.status": "Állapot",
  "screen.hr.employee.details.weeklyWorkDays": "{weeklyWorkDays} napok hetente",
  "screen.hr.employee.details.workingHours": "{workingHours} órák per {workingHoursInterval};",
  "screen.hr.employee.documents.all": "Összes",
  "screen.hr.employee.documents.annualPayslips": "Éves fizetési bizonylat",
  "screen.hr.employee.documents.anyDate": "Bármely időszak",
  "screen.hr.employee.documents.backTargetDescription": "Munkatársak / Alkalmazottak / {status} / {firstName} {lastName}",
  "screen.hr.employee.documents.certificateOfCitizenship": "Állampolgárság igazolása",
  "screen.hr.employee.documents.certificatesOfRegistration": "Regisztrációs visszaigazolások",
  "screen.hr.employee.documents.contracts": "Szolgáltatási szerződés",
  "screen.hr.employee.documents.deregisterDocument": "A nyilvántartásból való törlésre vonatkozó dokumentumok",
  "screen.hr.employee.documents.employeeDocuments": "{firstName} {lastName} fájljai",
  "screen.hr.employee.documents.employmentPermit": "Munkavállalási engedély",
  "screen.hr.employee.documents.generalDocuments": "Egyéb",
  "screen.hr.employee.documents.identityCard": "Személyi igazolvány",
  "screen.hr.employee.documents.payslips": "A jövedelem igazolása",
  "screen.hr.employee.emptystate.buttonlabel": "Alkalmazottak felvétele",
  "screen.hr.employee.emptystate.message": "Kezdje el az új alkalmazottak regisztrálását most",
  "screen.hr.employee.emptystate.title": "Alkalmazottak nyilvántartásba vétele",
  "screen.hr.employee.former.anyDateOfLeaving": "Bármely kilépési dátum",
  "screen.hr.employee.registration.stepper.additional": "Kiegészítő információk",
  "screen.hr.employee.registration.stepper.documents": "Dokumentumok feltöltése",
  "screen.hr.employee.registration.stepper.personal": "Személyes adatok",
  "screen.hr.employee.registration.stepper.status": "A {totalSteps} {aktívStep} lépése",
  "screen.hr.employee.registration.stepper.summary": "Összefoglaló",
  "screen.hr.employees.current.registerEmployee": "Alkalmazott regisztrálása",
  "screen.hr.employees.current.uploadPayslip": "Fizetési bizonylat feltöltése",
  "screen.hr.employees.documents.recordType.title": "A személyi állományba feltöltött fájlok {dátum}",
  "screen.hr.employees.documents.title": "Fájlok a személyi aktában",
  "screen.hr.navbar.documents": "Fájlok",
  "screen.hr.navbar.employees": "Alkalmazottak",
  "screen.hr.navbar.heading": "Személyzet",
  "screen.hr.navbar.overview": "Áttekintés",
  "screen.hr.navbar.tabbar.current": "Jelenlegi DN",
  "screen.hr.navbar.tabbar.documents": "Fájlok",
  "screen.hr.navbar.tabbar.employees": "Alkalmazottak",
  "screen.hr.navbar.tabbar.former": "Nyugdíjas DN",
  "screen.hr.navbar.tabbar.inprogress": "Folyamatban",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "Érdeklődés ({preRegistrationTicketCount})",
  "screen.hr.overview.card.analysis": "Havi értékelések jóváhagyása a következőknek",
  "screen.hr.overview.card.billing": "Havi kimutatások jóváhagyása a következőknek",
  "screen.hr.overview.card.globalAnalysis": "Vállalati szintű értékelések jóváhagyása ",
  "screen.hr.overview.card.globalBilling": "Vállalati szintű elszámolások jóváhagyása",
  "screen.hr.overview.card.overview": "A havi személyzeti adatok közzététele a következőkre vonatkozóan",
  "screen.hr.overview.card.registration": "Alkalmazottak be- és kijelentkezése",
  "screen.hr.overview.finished.message": "{<br /> <br /> Köszönöm, hogy ellenőrizte és jóváhagyta a bérszámfejtést. Kérjük, az alábbiakban küldje el jelentéseit.<br /> <br /> Tisztelettel, <br />{aláírás}",
  "screen.hr.overview.registration": "{<br /> <br /> kérem, hogy a szolgáltatást igénybe vevőkben bekövetkezett változásokról értesítsen, hogy a regisztrációt és a törlést el tudjuk végezni.<br /> <br /> <br /> Tisztelettel,<br /> {aláírás}",
  "screen.hr.overview.registration.button": "Alkalmazottak nyilvántartásba vétele/lejelentése",
  "screen.hr.overview.ticketsWelcome.inProgress": "{beszélgetés}, <br /> <br /> Köszönjük, hogy elküldte személyes adatait. A feldolgozás során felmerült néhány kérdés:",
  "screen.hr.overview.welcome.closed": "{alutation}, <br /> <br /> Köszönjük, hogy minden vonatkozó személyi adatot benyújtott. Ezeket az egyeztetett határidők szerint feldolgozzuk, és bármilyen kérdéssel kapcsolatban felvesszük Önnel a kapcsolatot.",
  "screen.hr.overview.welcome.inProgress": "{<br /> <br /> Kérem, hogy a {időszak}{dueDate} bérszámfejtéshez szükséges összes személyzeti adatot adja meg nekem.} <br><br>Őszintén,<br>{aláírás}",
  "screen.hr.personaldata.address": "Utca és házszám",
  "screen.hr.personaldata.addressAddition": "Cím utótag",
  "screen.hr.personaldata.addressHead": "Cím:",
  "screen.hr.personaldata.area.validation": "Kérjük, adja meg a helyszínt",
  "screen.hr.personaldata.birthdate.validation": "Kérjük, adja meg a születési dátumot",
  "screen.hr.personaldata.citizenship": "Állampolgárság",
  "screen.hr.personaldata.citizenship.validation": "Kérjük, jelölje meg az állampolgárságot",
  "screen.hr.personaldata.city": "Helyszín",
  "screen.hr.personaldata.country": "Ország",
  "screen.hr.personaldata.country.validation": "Kérjük, válasszon egy országot",
  "screen.hr.personaldata.dateOfBirth": "Születési dátum",
  "screen.hr.personaldata.dateOfJoining": "Belépés dátuma",
  "screen.hr.personaldata.description": "A szolgáltatás megkezdése előtt a következő információk megadása kötelező a regisztrációhoz.",
  "screen.hr.personaldata.diverse": "Búvárok",
  "screen.hr.personaldata.employmentInsuranceType": "Biztosítási csoport",
  "screen.hr.personaldata.employmentRelationship": "Munkaviszony",
  "screen.hr.personaldata.employmenttypeheader": "A foglalkoztatás típusa",
  "screen.hr.personaldata.employmenttypeinformationbody": "Minden, az alacsony jövedelemhatár feletti bruttó havi jövedelem kötelezően teljes körűen biztosítottnak minősül.",
  "screen.hr.personaldata.employmenttypeinformationhead": "Teljesen biztosított / marginálisan biztosított:",
  "screen.hr.personaldata.employmenttype.validation": "Kérjük, válasszon ki egy munkaviszonyt",
  "screen.hr.personaldata.entrydate.invalid": "Érvénytelen belépési dátum",
  "screen.hr.personaldata.entrydate.validation": "Kérjük, adja meg a belépési dátumot",
  "screen.hr.personaldata.female": "Női",
  "screen.hr.personaldata.firstname": "Keresztnév",
  "screen.hr.personaldata.firstname.validation": "Kérjük, adja meg a keresztnevét",
  "screen.hr.personaldata.fullTime": "Teljesen biztosított",
  "screen.hr.personaldata.houseNumber": "házszám",
  "screen.hr.personaldata.houseNumber.validation": "Kérjük, adja meg a házszámot",
  "screen.hr.personaldata.insurancedata": "Biztosítási adatok",
  "screen.hr.personaldata.insurancedatainformationbody": "Ha nincs SI-szám, kérjük, hagyja üresen a nemzeti biztosítási szám mezőt.",
  "screen.hr.personaldata.insurancedatainformationhead": "Tipp:",
  "screen.hr.personaldata.insurancetype.validation": "Kérjük, válasszon egy biztosítást",
  "screen.hr.personaldata.lastname": "Vezetéknév",
  "screen.hr.personaldata.lastname.validation": "Kérjük, adja meg a vezetéknevét",
  "screen.hr.personaldata.male": "Férfi",
  "screen.hr.personaldata.marginally": "Kisebb",
  "screen.hr.personaldata.navback": "Személyzet / Alkalmazottak",
  "screen.hr.personaldata.navheader": "Alkalmazottak nyilvántartásba vétele",
  "screen.hr.personaldata.nextpage": "A kiegészítő információkhoz kapcsolódóan",
  "screen.hr.personaldata.notSpecified": "Nincs megadva",
  "screen.hr.personaldata.personaldata": "Személyes adatok",
  "screen.hr.personaldata.personalinformation": "Személyes adatok",
  "screen.hr.personaldata.previouslyEmployed": "Volt-e ez a személy az elmúlt 12 hónapban valaha is az Ön alkalmazásában?",
  "screen.hr.personaldata.socialsecuritynumber.validation": "Kérjük, adja meg nemzeti biztosítási számát",
  "screen.hr.personaldata.ssn": "Nemzeti biztosítási szám",
  "screen.hr.personaldata.state.validation": "Kérjük, adjon meg egy szövetségi államot",
  "screen.hr.personaldata.streetnr.validation": "Kérjük, adjon meg egy címet",
  "screen.hr.personaldata.workplaceState": "A munkahely állapota",
  "screen.hr.personaldata.zipCode": "POSTACÍM",
  "screen.hr.personaldata.zipcode.length.validation": "",
  "screen.hr.personaldata.zipcode.validation": "Kérjük, adja meg az irányítószámot",
  "screen.hr.records.move.multiple.dialog.title": "Fájlok áthelyezése",
  "screen.hr.records.move.single.dialog.title": "Fájl áthelyezése",
  "screen.hr.records.navbar.tab.list": "Fájlok ({count})",
  "screen.hr.records.navbar.tab.tickets": "Lekérdezések ({count})",
  "screen.hr.records.transfer.dialog.text": "Szeretné átküldeni <b>az összes bérszámfejtési fájlt és információt</b> a {periodStart} - {periodEnd} időszakra vonatkozóan a TPA-nak könyvelés céljából?",
  "screen.hr.records.transfer.dialog.textRecordType": "Szeretné átvinni <b>a \"{név}\"</b> összes fájlját és információját a {periodStart} - {periodEnd} időszakra vonatkozóan a TPA-ba kiküldetés céljából?",
  "screen.hr.records.transfer.dialog.title": "Fájlok továbbítása",
  "screen.hr.records.types.header": "Személyzet",
  "screen.hr.records.upload.button": "Fájlok feltöltése",
  "screen.hr.records.upload.navbar.back": "Személyzet / Fájlok",
  "screen.hr.recordTypes.title": "Válassza ki a dokumentumok típusát",
  "screen.hr.registration.flow.navback": "Lépjen hátra",
  "screen.hr.registration.flow.navheader": "{firstName} {lastName} bejelentkezés",
  "screen.hr.summary.additionalinformation": "Kiegészítő információk",
  "screen.hr.summary.bonuses": "Juttatások",
  "screen.hr.summary.changeData": "Hozzáadás a listához",
  "screen.hr.summary.completeAdditionalData": "Ön minden kiegészítő információt megadott.",
  "screen.hr.summary.completePersonalData": "A regionális egészségbiztosítási pénztárnál történő regisztrációhoz szükséges összes kötelező adatot megadta.",
  "screen.hr.summary.day": "Nap",
  "screen.hr.summary.daysPerWeek": "Heti napok",
  "screen.hr.summary.description": "Ellenőrizze, hogy a megadott információk helyesek-e, hogy a következő lépésben elküldhesse őket a TPA-nak.",
  "screen.hr.summary.dialogQuestion1": "Akarod, hogy",
  "screen.hr.summary.dialogQuestion2": " és elküldi a TPA adótanácsadójának regisztráció céljából?",
  "screen.hr.summary.documents": "Fájlok",
  "screen.hr.summary.gender": "Gender",
  "screen.hr.summary.grossSalary": "Bruttó fizetés {pénzben}",
  "screen.hr.summary.hours": "Órák",
  "screen.hr.summary.incompleteAdditionalData": "Még nem adta meg az összes további információt. Felhívjuk figyelmét, hogy egyes kiegészítő adatokra az első bérkifizetésig szükség lehet. Ezeket bármikor hozzáadhatja.",
  "screen.hr.summary.month": "Hónap",
  "screen.hr.summary.name": "{firstName} {lastName}",
  "screen.hr.summary.netSalary": "Nettó fizetés {pénzben}",
  "screen.hr.summary.noPreregistration": "A törzsadatok nem változtak. Az adatátvitelre nincs szükség.",
  "screen.hr.summary.openDialog": "Jelentkezés benyújtása",
  "screen.hr.summary.summary": "Összefoglaló",
  "screen.hr.summary.transferDataChange": "Törzsadatok változásának továbbítása",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": " létrehozták és benyújtották a TPA adótanácsadójának regisztráció céljából.",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "{név} jött létre. Az alábbiakban megtekintheti és letöltheti az ELDA protokollt. Alternatívaként a jegyzőkönyv letölthető a munkavállaló személyes fájljából is.",
  "screen.hr.summary.week": "Hét",
  "screen.login.button.forgot_password": "ELFELEJTETTE A JELSZAVÁT?",
  "screen.login.button.resendCode": "Kód újraküldése ",
  "screen.login.form.divider": "vagy",
  "screen.login.form.multiFactorAuth.label": "kód",
  "screen.login.form.multiFactorAuth.validation_error": "Kérjük, adja meg a kódot",
  "screen.login.form.password.label": "Jelszó",
  "screen.login.form.password.validation_error": "Kérjük, adjon meg egy jelszót",
  "screen.login.form.submit": "Bejelentkezés",
  "screen.login.form.title": "Bejelentkezés",
  "screen.login.form.username.label": "Felhasználói azonosító / e-mail cím",
  "screen.login.form.username.validation_error": "Kérjük, adja meg felhasználói azonosítóját",
  "screen.login.multiFactorAuth": "Kérjük, adja meg a kódot, amelyet SMS-ben küldtünk az Ön telefonszámára.",
  "screen.login.welcome": "Üdvözöljük! Kérjük, adja meg e-mail címét.",
  "screen.module.overview.report.empty.message": "Még nem töltöttek fel jelentéseket",
  "screen.module.overview.report.empty.title": "Nem állnak rendelkezésre jelentések",
  "screen.module.overview.report.upload": "Jelentés feltöltése",
  "screen.moduleReportUpload.comment": "Átviteli szöveg",
  "screen.moduleReportUpload.title": "Jelentések feltöltése",
  "screen.not_found.button.text": "Indítás irányítópultból",
  "screen.not_found.header": "404 hiba",
  "screen.not_found.message": "Nagyon jók vagyunk a számokkal, de valójában ettől szerettük volna megkímélni Önöket.",
  "screen.overview.salutation.female": "Kedves {lastName} {firstName}",
  "screen.overview.salutation.general": "Tisztelt Hölgyem / Uram!",
  "screen.overview.salutation.male": "Kedves {lastName} {firstName}",
  "screen.overview.salutation.notSpecified": "Jó napot {lastName} {firstName}",
  "screen.records.upload.header": "Fájl feltöltés {recordType}",
  "screen.records.upload.message": "Most kezdje el az első fájlok feltöltését.",
  "screen.records.upload.title": "Feltöltés {recordType}",
  "screen.server_error.button.text": "Az irányítópulthoz",
  "screen.server_error.header": "505 Hiba",
  "screen.server_error.message": "Nagyon jók vagyunk a számokkal, de valójában ettől szerettük volna megkímélni Önöket.",
  "screen.termsNotAccepted.button": "Vissza a vállalat áttekintéséhez",
  "screen.termsNotAccepted.buttonAcceptNow": "Elfogadom most",
  "screen.termsNotAccepted.header": "A használati feltételek elfogadása függőben",
  "screen.termsNotAccepted.message": "Az Ön vállalatának felhasználási feltételeit még nem fogadták el. Ha bármilyen kérdése van, kérjük, forduljon a TPA tanácsadójához.",
  "screen.termsOfUse.accept": "Aláírás",
  "screen.termsOfUse.alreadySignedInfo": "A felhasználási feltételek már elfogadásra kerültek, vagy elfogadásukra már nincs szükség.",
  "screen.termsOfUse.backToLogin": "Vissza a bejelentkezéshez",
  "screen.termsOfUse.checkbox": "Elfogadom a felhasználási feltételeket",
  "screen.termsOfUse.success.sign": "Felhasználási feltételek elfogadása sikeres",
  "screen.termsOfUse.title": "Felhasználási feltételek elfogadása",
  "screen.termsOfUseWithCompanyNames.title": "A [szám] vállalatok felhasználási feltételeinek elfogadása",
  "screen.termsOfUseWithCompanyName.title": "{companyName} felhasználási feltételeinek elfogadása",
  "screen.tickets.navbar.tabbar.closed": "Kész",
  "screen.tickets.navbar.tabbar.open": "Megnyitás",
  "screen.tickets.navbar.tabbar.reported": "Általam feltett kérdések",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "Ez a Microsoft felhasználó már regisztrált a {existingUsername} e-mail címmel.",
  "screen.upgradeToMicrosoft.feature.projects.text": "Kérjük, frissítse fiókját, hogy hozzáférjen a \"Projektek\" funkcióhoz.",
  "screen.upgradeToMicrosoft.form.code.description": "Kérjük, adja meg az SMS-ben küldött kódot a Microsoft frissítés folytatásához.",
  "screen.upgradeToMicrosoft.form.code.label": "Kód",
  "screen.upgradeToMicrosoft.form.password.label": "Jelszó",
  "screen.upgradeToMicrosoft.form.password.validation_error": "Kérjük, adja meg jelszavát",
  "screen.upgradeToMicrosoft.needCompany": "A MS fiókhoz való kapcsolódás csak egy vállalat kiválasztása esetén lehetséges",
  "screen.upgradeToMicrosoft.resendButton.label": "Kód újra-küldése",
  "screen.upgradeToMicrosoft.title": "Microsoft-fiók összekapcsolása",
  "search.caption.numCompanies": "Vállalatok: <b>{count}</b>",
  "search.caption.numDespositedPersons": "Letétbe helyezett személyek: <b>{count}</b>",
  "search.caption.numDocuments": "Fájlok: <b>{count}</b>",
  "search.caption.numPersons": "Személyek: <b>{count}</b>",
  "search.caption.numProjects": "Projektek: <b>{count}</b>",
  "search.caption.numResults": "Eredmények: <b>{count}</b>",
  "search.caption.numSelected": "Kiválasztott bejegyzések: <b>{count}</b>",
  "search.caption.numTickets": "Kérdések: <b>{count}</b>",
  "search.caption.numUploads": "Feltöltések: <b>{count}</b>",
  "search.caption.numUsers": "Felhasználó: <b>{szám}</b>",
  "search.placeholder.companies": "A vállalatok keresnek ...",
  "search.placeholder.projects": "projekt keresése ...",
  "search.placeholder.search": "Keresés ...",
  "search.placeholder.searchForActiveUsers": "Aktív felhasználók keresése név szerint...",
  "search.placeholder.searchForEmployees": "nevek keresése...",
  "search.placeholder.searchForEmployeesPersonalNumber": "nevek vagy személyi számok keresése...",
  "search.placeholder.searchForFileName": "Fájlnév keresés...",
  "search.placeholder.searchForFiles": "Fájlnevek keresése...",
  "search.placeholder.searchForUsers": "nevek keresése...",
  "search.placeholder.searchForUsersFileName": "Keresés név vagy fájlnév alapján...",
  "selectQesPosition.message": "Válassza ki az aláírás helyét, majd kattintson az {button} gombra.",
  "selectQesPosition.message.collective": "Minden felhasználó esetében válassza ki az aláírásuk pozícióját, majd kattintson a Tovább gombra.",
  "selectQesPosition.title": "Aláírás pozíció kiválasztása",
  "settings.navbar.company": "Cég",
  "settings.navbar.companyData": "Törzsadatok",
  "settings.navbar.features": "Kiegészítők",
  "settings.navbar.general": "Általános",
  "settings.navbar.optionalSideBarItems": "Opcionális menüpontok",
  "settings.navbar.userManagement": "Felhasználói adminisztráció",
  "settings.notifications.activateAll": "MINDEGYIK AKTIVÁLÁSA",
  "settings.notifications.assignments": "Kiutalások",
  "settings.notifications.deactivateAll": "MINDEGYIK DEAKTIVÁLÁSA",
  "settings.notifications.deadlineExceeded": "Kihagyott határidő",
  "settings.notifications.deadlineReminder": "Határidő emlékeztető",
  "settings.notifications.generalDocumentUpload": "Általános fájlok feltöltése",
  "settings.notifications.label": "E-mail értesítések, ha:",
  "settings.notifications.messages": "Hírek",
  "settings.notifications.modules.accounting": "Számvitel",
  "settings.notifications.modules.hr": "Személyzet",
  "settings.notifications.modules.other": "Egyéb területek",
  "settings.notifications.project.items.uploaded": "Projekt feltöltések",
  "settings.notifications.recordProvision": "Az ügyfél feltöltései",
  "settings.notifications.release": "Jóváhagyások",
  "settings.notifications.remindAfterHours.label": "Memória {órák}h után",
  "settings.notifications.reportProvision": "Jelentés szolgáltatás",
  "settings.notifications.tickets": "Érdeklődés",
  "settings.notifications.title": "Értesítések",
  "settings.personalData.email": "E-mail cím",
  "settings.personalData.language": "Nyelv",
  "settings.personalData.location": "Helyszín",
  "settings.personalData.microsoftAccount": "Microsoft fiók",
  "settings.personalData.microsoftUpgrade.info": "Microsoft-fiók:<br /><br />A TPA Connect fiókját összekapcsolhatja Microsoft-fiókjával. Ez lehetővé teszi a TPA Connect összes funkciójának használatát.<br /><br />Amint összekapcsolta fiókját a Microsofttal, az e-mail címe helyett a Microsoft-fiókjával kell bejelentkeznie.",
  "settings.personalData.microsoftUpgrade.label": "UPGRADE",
  "settings.personalData.phoneNumber": "Telefonszám",
  "settings.personalData.role": "Roll",
  "settings.personalData.title": "Személyes adatok",
  "settings.personalData.username": "Felhasználónév",
  "settings.userManagement.permissions.existingUser.navbar.back": "Felhasználói adminisztráció / Szerep hozzárendelése",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "Felhasználói adminisztráció / Szerep hozzárendelése / {employeeName}",
  "settings.userManagement.permissions.navbar.back": "Felhasználói adminisztráció / Kapcsolattartó személy kiválasztása / Szerepkör hozzárendelése",
  "settings.userManagement.permissions.navbar.backToUser": "Felhasználói adminisztráció / Kapcsolattartó kiválasztása / Szerep hozzárendelése / {employeeName}",
  "settings.userManagement.roles.navbar.back": "Felhasználói adminisztráció / Kapcsolattartó személy kiválasztása",
  "sidebar.advisor.employees": "Személyzeti lista",
  "sidebar.advisor.inbox": "Postafiók",
  "sidebar.advisor.tickets": "Érdeklődés",
  "sidebar.advisor.uploads": "Feltöltések",
  "sidebar.customerView": "Ügyfelek véleménye",
  "sidebar.faceToFace.button": "Kapcsolat",
  "sidebar.faceToFace.title": "Szemtől szemben",
  "sidebar.list.accounting": "Számvitel",
  "sidebar.list.analysis": "",
  "sidebar.list.cockpit": "Cockpit",
  "sidebar.list.documents": "Jelentések",
  "sidebar.list.faceToFace": "Szemtől szemben",
  "sidebar.list.hr": "Személyzet",
  "sidebar.list.kpi": "Tények és számok",
  "sidebar.list.personnelFile": "Személyzeti dosszié",
  "sidebar.list.projects": "Projektek",
  "sidebar.list.results": "Értékelések",
  "sidebar.list.settings": "Beállítások",
  "sidebar.list.support": "Támogatás",
  "sidebar.list.tickets": "Érdeklődés",
  "signDialog.noQesEnabled.message": "Ezennel megerősítem a dokumentum kiadását.",
  "signDialog.noQesRequired.button.click": "Jóváhagyás kattintással",
  "signDialog.noQesRequired.button.qes": "Elektronikus aláírás",
  "signDialog.noQesRequired.message": "A jelentés közzétételéhez választhat a kattintással vagy elektronikus aláírással történő közzététel között.",
  "signDialog.qesblocked.message": "Az elektronikus aláírás létrehozása jelenleg nem lehetséges, mivel az aláírási folyamatot egy másik személy már elindította. Kérjük, 15 perc múlva próbálja meg újra.",
  "signDialog.qesblocked.title": "Jóváhagyás jelenleg nem lehetséges",
  "signDialog.qesRequired.confirm": "Rendben, elektronikus aláírás hozzáadása",
  "signDialog.qesRequired.message": "A jelentés jóváhagyásához elektronikus aláírás szükséges. Csak elektronikus aláírással kerül továbbításra",
  "signDialog.title": "Jóváhagyás",
  "soleEarnerStatus.Alleinerzieher": "Egyedülálló szülő",
  "soleEarnerStatus.Alleinverdiener": "Egyedüli kereső",
  "soleEarnerStatus.Nein": "Nem",
  "staff.documents.navbar.heading": "Az én fájljaim",
  "staff.personnelFile.navbar.heading": "Üdv, {name}!",
  "stepper.optional": "opcionális",
  "stepper.progress": "Progress",
  "support.admins.title": "Adminisztrátorok",
  "support.filter.option.activeChats": "Csak aktív csevegések",
  "support.filter.option.allChats": "Minden csevegés",
  "support.filter.option.inactiveChats": "Csak inaktív csevegések",
  "support.filter.setting.showInactiveColleagues": "Inaktív alkalmazottak megjelenítése",
  "support.inactiveEmployee": "Volt alkalmazott",
  "support.partners.title": "Partnerek",
  "support.privateChat.dialog.markAsPrivate.confirm": "Bizalmasnak jelölje meg",
  "support.privateChat.dialog.markAsPrivate.message": "Ha egy csevegés bizalmasnak van jelölve, <b>más személy</b> nem tekintheti meg a csevegést, és nem válaszolhat az üzenetekre az Ön távollétében.<br/><br/>Az ügyfél <b>nem</b> értesül</b> erről a beállításról.",
  "support.privateChat.dialog.markAsPrivate.title": "A csevegést bizalmasként jelölje meg?",
  "support.privateChat.dialog.markAsPublic.confirm": "Lemond a titoktartásról",
  "support.privateChat.dialog.markAsPublic.message": "Ha egy csevegés már nincs bizalmasként megjelölve, <b>más személyek</b> megtekinthetik a csevegést és válaszolhatnak az üzenetekre az Ön távollétében.<br/><br/>Az ügyfél <b>nem</b> értesül</b> erről a beállításról.",
  "support.privateChat.dialog.markAsPublic.title": "Már nem jelölhetem bizalmasnak a csevegést?",
  "support.privateChat.info": "A csevegés <b>bizalmas</b> jelzéssel van ellátva, és mások nem láthatják.",
  "support.privateChat.info.markAsPublic": "LIFT UP",
  "support.privateChat.info.mobile": "A csevegés <b>bizalmas</b>.",
  "support.privateChat.placeholder": "Üzenet megadása ...",
  "support.responsible.title": "Csapattagok",
  "support.search.navbar.title": "Keresési eredmények",
  "support.substitute.author": "{substituteName} (a {authorName} helyettesítője)",
  "support.substitute.back": "VISSZA A CSEVEGÉSEMHEZ",
  "support.substitute.back.mobile": "AZ ÉN CSEVEGÉSEM",
  "support.substitute.warning": "<b>Figyelem:</b> Ön <b>{name}</b> beszélgetésében van.",
  "support.substitute.warning.mobile": "Csevegést indította: <b>{name}</b>",
  "support.yourContacts": "Az Ön kapcsolattartói",
  "table.button.release": "RELEASE",
  "table.employees.dateOfJoining": "Belépés dátuma",
  "table.employees.dateOfJoiningExiting": "Belépés/kilépés dátuma",
  "table.employees.dateOfLeaving": "Kilépés időpontja",
  "table.employees.dateOfTransmitting": "Továbbítva",
  "table.employees.documents.date": "Dátum",
  "table.employees.documents.document": "Fájl",
  "table.employees.documents.documentName": "Megnevezés",
  "table.employees.documents.downloadDocuments": "Fájlok letöltése",
  "table.employees.documents.uploadedBy": "Feltöltötte",
  "table.employees.employee": "Alkalmazott",
  "table.employees.jobDescription": "Munkakör",
  "table.employees.missingDetails": "Hiányzó adatok",
  "table.employees.personalId": "Személyzeti szám",
  "table.employees.reasonForLeaving": "A távozás oka",
  "table.employees.status": "Állapot",
  "table.employees.status.active": "Aktív",
  "table.employees.status.inactive": "Inaktív",
  "table.employees.status.inDataChange": "Törzsadatok módosítása",
  "table.employees.status.inDataChangeNotTransferred": "Módosítás (tervezet)",
  "table.employees.status.inDeregistration": "A nyilvántartásból való törléskor",
  "table.employees.status.inDeregistrationNotTransferred": "A nyilvántartásból való törlés (tervezet)",
  "table.employees.status.inRegistration": "A regisztráció során",
  "table.employees.status.inRegistrationNotTransferred": "Alkalmazás (tervezet)",
  "table.label.changes": "Változások",
  "table.label.changeUsername": "E-mail küldése a felhasználónevek megváltoztatásához",
  "table.label.comment": "Megjegyzés",
  "table.label.companyName": "Cégnév",
  "table.label.contactPerson": "Kapcsolat",
  "table.label.contactPersonId": "Személyes szám",
  "table.label.costCenter": "Költségközpont",
  "table.label.createdAt": "Feltöltés dátuma",
  "table.label.createdAt.variant": "Létrehozta a",
  "table.label.createdBy": "Létrehozta",
  "table.label.date": "Dátum",
  "table.label.dateOfJoining": "Belépés dátuma",
  "table.label.dateOfJoiningExiting": "Belépés/kilépés dátuma",
  "table.label.dateOfLeaving": "Kilépés időpontja",
  "table.label.dateOfTransmitting": "Továbbítva",
  "table.label.deleteUser": "Felhasználó törlése",
  "table.label.document": "Fájl",
  "table.label.documentName": "Megnevezés",
  "table.label.documentType": "Fájl",
  "table.label.downloadDocuments": "Fájlok letöltése",
  "table.label.dueDate": "Határidő",
  "table.label.dueDateForDraft": "Jóváhagyás határideje",
  "table.label.email": "E-mail cím",
  "table.label.employee": "Alkalmazott",
  "table.label.file": "Fájl",
  "table.label.fileName": "Megnevezés",
  "table.label.folder": "Mappa",
  "table.label.holiday": "Nyaralás",
  "table.label.info": "Info",
  "table.label.jobDescription": "Munkakör",
  "table.label.lastAuthenticatedAt": "Utolsó bejelentkezés",
  "table.label.lastAuthenticatedAt.registered.tooltip": "Regisztrált felhasználó",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "Nem regisztrált felhasználó",
  "table.label.lastCreated": "Utoljára létrehozott",
  "table.label.members": "Tagok",
  "table.label.message": "Üzenet",
  "table.label.messages": "Hírek",
  "table.label.missingDetails": "Hiányzó adatok",
  "table.label.modulePermissions": "Területi szintű engedélyek",
  "table.label.modules": "Területek",
  "table.label.needsRelease": "Felszabadítás szükséges",
  "table.label.openReleases": "Nyitott jóváhagyások",
  "table.label.otherAbsence": "Egyéb távollét",
  "table.label.personalId": "Személyzeti szám",
  "table.label.personalNumber": "Személyzeti szám",
  "table.label.phoneNumber": "Telefonszám",
  "table.label.reasonForLeaving": "A távozás oka",
  "table.label.recordedBy": "Captured from",
  "table.label.release": "Kiadványok",
  "table.label.released": "Továbbított",
  "table.label.releasedAt": "Továbbítva",
  "table.label.releaseRequestedBy": "Jóváhagyást kiállította: ",
  "table.label.role": "Roll",
  "table.label.sentBy": "Küldve a",
  "table.label.sickLeave": "Betegszabadság",
  "table.label.status": "Állapot",
  "table.label.teamMessages": "Team hírek",
  "table.label.ticket": "Kérdés",
  "table.label.ticketBy": "A kérdést feltette",
  "table.label.ticketDescription": "Megnevezés",
  "table.label.ticketDueDate": "Határidő",
  "table.label.tickets": "Érdeklődés",
  "table.label.transferStatus": "Küldés",
  "table.label.transmittedAt": "Továbbítva",
  "table.label.type": "Art",
  "table.label.updatedAt": "Utoljára szerkesztett",
  "table.label.updatedAt.variant": "Utoljára módosítva",
  "table.label.uploadedBy": "Feltöltötte",
  "table.label.username": "Felhasználónév",
  "table.noResults.message": "A keresés nem adott eredményt.",
  "table.noResults.title": "Nincs keresési eredmény",
  "taxAccount.pay.button": "Adótartozás kifizetése",
  "taxAccount.transactions.table.filters.date": "Dátum",
  "taxAccount.transactions.table.filters.tax": "Adó",
  "taxAccount.transactions.table.label.amount": "Összeg",
  "taxAccount.transactions.table.label.date": "Dátum",
  "taxAccount.transactions.table.label.due": "Érettség",
  "taxAccount.transactions.table.label.taxPeriod": "Időszak",
  "taxAccount.transactions.table.label.text": "Megnevezés",
  "taxAccount.transactions.table.search.count": "Tranzakciók: <b>{szám}</b>",
  "taxAccount.transactions.table.search.placeholder": "Tranzakciók keresése",
  "taxAccount.transactions.title": "Adószámla egyenlege: [érték]",
  "termsOfUse.checkbox.error": "Kérjük, fogadja el a felhasználási feltételeket",
  "termsOfUse.upcoming.navbar.button": "Elfogadom",
  "termsOfUse.upcoming.navbar.message": "A TPA Connect alkalmazás használati feltételei {dátum}-tól megváltoznak.",
  "ticketList.emptystate.title": "Nem állnak rendelkezésre lekérdezések",
  "unpaidAccounts.invoices.pay": "Fizetés",
  "unpaidAccounts.invoices.table.filters.date": "Dokumentum dátuma",
  "unpaidAccounts.invoices.table.label.documentDate": "Dokumentum dátuma",
  "unpaidAccounts.invoices.table.label.documentNumber": "Dokumentum száma",
  "unpaidAccounts.invoices.table.label.documentText": "Dokumentum szövege",
  "unpaidAccounts.invoices.table.label.netAmount": "Nettó összeg",
  "unpaidAccounts.invoices.table.label.opAmount": "Nyitott összeg",
  "unpaidAccounts.invoices.table.label.receiptDate": "Dokumentum dátuma",
  "unpaidAccounts.invoices.table.label.receiptNumber": "Dokumentum száma",
  "unpaidAccounts.invoices.table.label.receiptText": "Dokumentum szövege",
  "unpaidAccounts.invoices.table.search.count": "Számlák: <b>{számlák száma}</b>",
  "unpaidAccounts.invoices.table.search.placeholder": "Számlák keresése",
  "unpaidAccounts.invoices.title": "Kintlévőségek a TPA-nál: [érték]",
  "upload.category.analysis": "Jelentés: Havi értékelés",
  "upload.category.annualReports": "Éves beszámoló",
  "upload.category.articles": "Elaborációk",
  "upload.category.billing": "Jelentés: Havi kimutatás",
  "upload.category.certificateOfCitizenship": "Állampolgárság igazolása",
  "upload.category.certificateOfRegistration": "A regisztráció megerősítése",
  "upload.category.contractOfEmployment": "Szerződés",
  "upload.category.contracts": "Szerződések",
  "upload.category.correspondence": "Levelezés",
  "upload.category.dataChangeConfirmation": "Törzsadatok módosítása",
  "upload.category.deregisterDocument": "A nyilvántartásból való törlésre vonatkozó dokumentumok",
  "upload.category.deregistrationConfirmation": "A munkavállaló kijelentkezése",
  "upload.category.employmentPermit": "Munkavállalási engedély",
  "upload.category.identityCard": "Személyi igazolvány",
  "upload.category.information": "Információ",
  "upload.category.notices": "Közlemények",
  "upload.category.other": "Egyéb",
  "upload.category.payslip": "fizetési bizonylat",
  "upload.category.registrationConfirmation": "Alkalmazotti regisztráció",
  "upload.category.report": "Jelentések",
  "upload.category.taxOffice": "Adóhivatal",
  "upload.category.taxReturn": "Adóbevallások",
  "userManagement.successDialog.changeUsername.message": "{userName} értesítést kap, hogy erősítse meg az újonnan bejelentett e-mail címet.",
  "userManagement.successDialog.deleteUser.single.message": "<b>{userName}</b> törölve",
  "userManagement.successDialog.deleteUser.title": "Felhasználó törölve",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "A <b>{count} felhasználók</b> beállításai elfogadásra kerültek.",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "A <b>{userName}</b> beállításai elfogadásra kerültek.",
  "userManagement.successDialog.existingUser.superAdmin.title": "A felhasználói beállítások sikeresen mentve",
  "userManagement.successDialog.existingUser.title": "Felhasználói beállítások sikeresen javasoltak",
  "userManagement.successDialog.message": "Javaslatát most egy <b>superadmin</b>-nak kell jóváhagynia.",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "<b>{count} felhasználók</b> e-mailben kapnak meghívót.",
  "userManagement.successDialog.newUser.superAdmin.single.message": "<b>{userName}</b> e-mailben meghívót kap.",
  "userManagement.successDialog.newUser.superAdmin.title": "Felhasználó sikeresen meghívott",
  "userManagement.successDialog.newUser.title": "A felhasználó sikeresen javasolta",
  "userManagement.successDialog.rejectUser.title": "Felhasználó sikeresen elutasítva",
  "userManagement.successDialog.releaseExistingUser.title": "Felhasználó sikeresen jóváhagyva",
  "userManagement.successDialog.releaseNewUser.title": "Felhasználó jóváhagyása és meghívása sikeres",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "A felhasználónév módosítására vonatkozó e-mailt sikeresen elküldtük.",
  "userProfile.createBmdTodos": "Feladatok létrehozása a BMD-ben a lekérdezésekhez",
  "userProfile.deleteAccount.message": "Biztos, hogy törölni szeretné a fiókját? Ezt a műveletet nem lehet visszacsinálni!",
  "userProfile.deleteAccount.title": "Fiók törlése",
  "userProfile.multiFactorAuth": "Telefonszámmal történő kétfaktoros hitelesítés aktiválása",
  "userProfile.navbar.heading": "Profil",
  "userProfile.upgrade.navbar.heading": "Frissítés",
  "userProfile.userInterests.title": "Érdekeltségek",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "Meghívó elküldéséhez kérjük, hagyja jóvá az új felhasználókat",
  "userReleaseInfoAdminDialog.releaseInfo.single": "Meghívó elküldéséhez kérjük, hagyja jóvá az új felhasználót.",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "{userName} <b>{count} új felhasználót</b> ajánlott.",
  "userReleaseInfoAdminDialog.releaseMessage.single": "{userName} <b>{count} új felhasználót</b> ajánlott.",
  "userReleaseInfoAdminDialog.submit": "Megtekintés és jóváhagyás",
  "userReleaseInfoAdminDialog.title": "Felhasználók jóváhagyása és meghívása",
  "userReleaseInfoDialog.cancel": "A felhasználói adminisztrációhoz",
  "userReleaseInfoDialog.from": "a  címről",
  "userReleaseInfoDialog.message": "A következő felhasználókkal kapcsolatban javasolt módosításokat jóváhagytuk/elutasítottuk:",
  "userReleaseInfoDialog.rejected": "visszautasítva",
  "userReleaseInfoDialog.released": "jóváhagyva",
  "userReleaseInfoDialog.title": "Felhasználó módosítása",
  "viewer.unsupported.message": "{név} jelenleg nem jeleníthető meg.",
  "webhook.employee.noSearch": "Keresési kifejezés megadása szükséges",
  "webhook.employee.placeholder": "A munkavállaló neve",
  "webhook.noCompanies": "Nincsenek vállalati engedélyek",
  "webhook.tickets.button": "Kérdés létrehozása",
  "webhook.tickets.info": "A dokumentum hozzárendelése egy területhez és a kategória kiválasztása",
  "webhook.tickets.navbar": "Kérdés a(z) {document} dokumentummal kapcsolatban",
  "webhook.tickets.taskTitle": "Feladat {name}-nek",
  "webhook.tickets.title": "Kérdés létrehozása",
  "webhook.uploads.navbar": "Fájlok továbbítása",
  "welcomeCard.accounting.addMoreRecords.button": "További dokumentumok megadása",
  "welcomeCard.accounting.addRecords.button": "Dokumentumok bevitele",
  "welcomeCard.hr.addMoreRecords.button": "Több fájl beküldése",
  "welcomeCard.hr.addRecords.button": "Fájlok beküldése",
  "texterify_timestamp": "2024-11-18T09:59:58Z"
} as const;

export { hungarian };
