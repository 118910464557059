import React from "react";
import { t } from "../../i18n/util";
import { ConfirmationDialog } from "../ui/ConfirmationDialog";
import { useConfirmationDialog } from "./useConfirmationDialog";

export const usePhoneNumberMissingDialog = ({
    onCancel,
    onConfirm,
}: {
    onCancel?: () => void;
    onConfirm: () => void;
}) => {
    return useConfirmationDialog({
        title: t("dialog.noPhoneNumberWarning.title"),
        message: t("dialog.noPhoneNumberWarning.message"),
        confirmLabel: t("common.continue"),
        onCancel,
        onConfirm,
    });
};
