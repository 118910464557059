import { Button, LinearProgress } from "@material-ui/core";
import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { ProjectClipboardContentPayload, ProjectItem, PublicHttpError } from "../../network/APITypes";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { getApiError } from "../../network/NetworkStapler";
import { generalStore } from "../../stores/GeneralStore";
import { getFileIconName } from "../../util/helpers";
import { allDone } from "../../util/lro";
import { CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle } from "../ui/CustomDialog";
import { TpaWhiteButton } from "../ui/Primitives";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { useAwaitableDialog } from "./useAwaitableDialog";
import { useLROs } from "./useLROs";

export interface IClipboardContent {
    projectItems: ProjectItem[];
    sourceProjectId: string;
}

export const useCopyToClipboard = () => {
    const [clipboardContent, setClipboardContent] = React.useState<IClipboardContent | null>(null);
    const [canMove, setCanMove] = React.useState(false);

    const copyToClipboard = (projectItems: ProjectItem[], sourceProjectId: string, canMove: boolean) => {
        setClipboardContent({ projectItems, sourceProjectId });
        setCanMove(canMove);
    };

    const clearClipboard = () => {
        setClipboardContent(null);
    };

    const awaitableProgressDialog = useAwaitableDialog<
        "without-dialog" | "with-dialog",
        { items: ProjectItem[] | undefined; lroIds: string[] | undefined }
    >();
    const progressDialog = (
        <ProgressDialog {...awaitableProgressDialog.additionalProps} {...awaitableProgressDialog.dialogProps} />
    );

    const moveFiles = async (companyId: string, destinationProjectId: string, destinationFolderId?: string) => {
        if (!clipboardContent) {
            return false;
        }

        const body: ProjectClipboardContentPayload = {
            projectItemIds: clipboardContent.projectItems.map(item => item.id),
            sourceProjectId: clipboardContent.sourceProjectId,
        };

        let response;
        try {
            generalStore.isLoading = true;
            response = await API.putMoveProjectFiles(companyId, destinationProjectId, body, destinationFolderId);
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.Conflict_409) {
                generalStore.setError(t("error.move.alreadyExists.unknown"), error);
            } else {
                generalStore.setError(t("error.projects.cantMove"));
            }
            return false;
        } finally {
            generalStore.isLoading = false;
        }

        const finished = await awaitableProgressDialog.open({
            items: clipboardContent.projectItems,
            lroIds: response.lroIds,
        });

        return finished;
    };

    const pasteFiles = async (companyId: string, destinationProjectId: string, destinationFolderId?: string) => {
        if (!clipboardContent) {
            return false;
        }

        const body: ProjectClipboardContentPayload = {
            projectItemIds: clipboardContent.projectItems.map(item => item.id),
            sourceProjectId: clipboardContent.sourceProjectId,
        };

        let response;
        try {
            generalStore.isLoading = true;
            response = await API.putCopyProjectFiles(companyId, destinationProjectId, body, destinationFolderId);
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.Conflict_409) {
                generalStore.setError(t("error.copy.alreadyExists.unknown"), error);
            } else {
                generalStore.setError(t("error.projects.cantPaste"));
            }
            return false;
        } finally {
            generalStore.isLoading = false;
        }

        const finished = await awaitableProgressDialog.open({
            items: clipboardContent.projectItems,
            lroIds: response.lroIds,
        });

        return finished;
    };

    return { clipboardContent, copyToClipboard, clearClipboard, moveFiles, pasteFiles, progressDialog, canMove };
};

export type ClipboardHook = ReturnType<typeof useCopyToClipboard>;

const ProgressDialog = ({
    items,
    lroIds,
    open: outerOpen,
    onClose,
    onSubmit,
}: {
    items: ProjectItem[] | undefined;
    lroIds: string[] | undefined;
    open: boolean;
    onClose: () => void;
    onSubmit: (finished: "without-dialog" | "with-dialog") => void;
}) => {
    const { lros, completedOnFirstLoad } = useLROs(lroIds);

    const open = outerOpen && !!lros && !completedOnFirstLoad;

    React.useEffect(() => {
        if (completedOnFirstLoad) {
            // happy path: we can skip opening the dialog because all copy/move operations
            //             finished before we even started polling
            onSubmit("without-dialog");
            return;
        }
    }, [completedOnFirstLoad, onSubmit]);

    const handleClose = () => {
        onClose();
    };

    const done = !!lros && allDone(lros);

    return (
        <CustomDialog open={open} onClose={handleClose}>
            <CustomDialogContent>
                <CustomDialogTitle>{t("projects.pasteProgressDialog.title")}</CustomDialogTitle>
                <div>
                    {items?.map((item, index) => {
                        const lro = lros?.[index];
                        if (!lro) {
                            return null;
                        }
                        const { percentageComplete, error } = lro;
                        return (
                            <div key={item.id} style={{ marginTop: 16 }}>
                                <div key={index} style={{ display: "flex", alignItems: "center", gap: 16 }}>
                                    <Icon name={item.isFolder ? "folder" : getFileIconName(item.name)} />
                                    <div style={{ flex: 1 }}>{item.name}</div>
                                </div>
                                {error && <ProgressDialogError item={item} error={error} />}
                                <div style={{ display: "flex", alignItems: "center", gap: 16, height: 20 }}>
                                    {/* -1 means unknown percentage, hence display no percentage and make the progress indeterminate */}
                                    <LinearProgress
                                        variant={percentageComplete === -1 ? "indeterminate" : "determinate"}
                                        value={percentageComplete}
                                        style={{ flex: 1 }}
                                    />
                                    {percentageComplete === -1 ? null : (
                                        <span style={{ width: 30 }}>{percentageComplete}%</span>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <CustomDialogActions>
                    {!done ? (
                        <TpaWhiteButton onClick={handleClose}>{t("button.continueInBackground")}</TpaWhiteButton>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onSubmit("with-dialog");
                            }}
                        >
                            {t("common.ok")}
                        </Button>
                    )}
                </CustomDialogActions>
            </CustomDialogContent>
        </CustomDialog>
    );
};

const ProgressDialogError = ({ item, error }: { item: ProjectItem; error: PublicHttpError }) => {
    let title = error.title;
    if (error.type === "PROJECT_NAME_EXISTS_CONFLICT") {
        title = item.isFolder ? t("error.copy.alreadyExists.folder") : t("error.copy.alreadyExists.file");
    }
    return <div style={{ color: customColors.error, marginTop: 8 }}>{title}</div>;
};
