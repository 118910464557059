import * as React from "react";
import { t } from "../../i18n/util";
import { API, IGetEmployeeDocumentsParams } from "../../network/API";
import { GetEmployeeDocumentsResponse } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

export const useStaffDocuments = (params: IGetEmployeeDocumentsParams, companyId?: string) => {
    const [documents, setDocuments] = React.useState<GetEmployeeDocumentsResponse | null>(null);

    React.useEffect(() => {
        const loadEmployeeDocuments = async () => {
            try {
                generalStore.isLoading = true;
                const documents = await API.getStaffDocuments(companyId, params);
                if (documents) {
                    setDocuments(documents);
                }
            } catch (error) {
                generalStore.setError(t("error.loadEmployeeDocuments"), error);
            } finally {
                generalStore.isLoading = false;
            }
        };

        loadEmployeeDocuments();
        // Needed because params should not be dependency, otherwise you run into an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);
    return documents;
};
