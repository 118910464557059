import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import * as React from "react";
import { t } from "../../i18n/util";
import { ProjectUser } from "../../network/APITypes";
import { debug } from "../../util/debug";
import { getFullName } from "../../util/user";
import { FieldError } from "../ui/CustomInputField";
import { Icon } from "../util/Icon";

interface IProps {
    style?: React.CSSProperties;
    selectedUser: ProjectUser | null;
    users: ProjectUser[];
    userRowCount: number;
    isTouched: boolean;
    onAddUser: (user: ProjectUser | null) => void;
    onDeleteUser: (user: ProjectUser | null) => void;
}

export const ResponsibleUserField = ({
    style,
    selectedUser,
    users,
    userRowCount,
    isTouched,
    onAddUser,
    onDeleteUser,
}: IProps) => {
    const [isUserTouched, setIsUserTouched] = React.useState(false);

    React.useEffect(() => {
        setIsUserTouched(isTouched);
    }, [isTouched, selectedUser]);

    const handleBlurUserSelect = () => {
        setIsUserTouched(true);
    };

    const handleDelete = () => {
        debug.log("### handleDeleteRow", selectedUser);
        if (selectedUser) {
            onDeleteUser(selectedUser);
        } else {
            onDeleteUser(null);
        }
    };

    return (
        <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Autocomplete
                    data-id="release_project_item_dialog_customer"
                    getOptionLabel={user => (user.firstName && user.lastName ? getFullName(user) : "")}
                    getOptionSelected={(user, value) => {
                        // Compare entries by id
                        return user.id === value.id;
                    }}
                    onBlur={handleBlurUserSelect}
                    options={users}
                    noOptionsText={t("common.noSearchResults")}
                    onChange={(event, user) => {
                        onAddUser(user);
                    }}
                    value={selectedUser}
                    fullWidth
                    filterSelectedOptions
                    renderInput={params => (
                        <TextField
                            {...params}
                            placeholder={t("requestReleaseProjectItemDialog.placeholder.searchForUsers")}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon name="search" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    style={style}
                />
                {userRowCount > 1 && (
                    <IconButton aria-label="delete-person-row" onClick={handleDelete}>
                        <Icon name="delete" />
                    </IconButton>
                )}
            </div>
            <FieldError>
                {isUserTouched && !selectedUser ? t("requestReleaseProjectItemDialog.searchForUsers.error") : undefined}
            </FieldError>
        </>
    );
};
