import * as React from "react";
import { SIDEBAR_WIDTH_OPEN } from "../../stores/SideBarStore";
import { useResize } from "../hooks/useResize";
import { MobileContext } from "../util/MobileContext";
import { CONTENT_MAX_WIDTH, CONTENT_MIN_WIDTH, customColors } from "../util/Theme";
import { DesktopOnly } from "./responsive/DesktopOnly";

const CONTAINER_STYLE_DESKTOP: React.CSSProperties = {
    display: "flex",
    width: "100%",
    flexGrow: 1,
    justifyContent: "center",
};
const CONTAINER_STYLE_MOBILE: React.CSSProperties = { display: "flex", width: "100%", flexGrow: 1 };

const CONTENT_STYLE_DESKTOP: React.CSSProperties = {
    width: "100%",
    maxWidth: CONTENT_MAX_WIDTH,
    minWidth: CONTENT_MIN_WIDTH,
    display: "flex",
    flexDirection: "column",
};

const CONTENT_STYLE_MOBILE: React.CSSProperties = { width: "100%", display: "flex", flexDirection: "column" };

export const CenteredContent = (props: {
    children?: React.ReactNode;
    getLeftWidth?: (width: number) => void;
    getRightWidth?: (width: number) => void;
    ignoreSideBar?: boolean;
    ticketBackground?: boolean;
    style?: React.CSSProperties;
}) => {
    const leftComponentRef = React.useRef<HTMLDivElement>(null);
    const rightComponentRef = React.useRef<HTMLDivElement>(null);
    const { width: leftWidth } = useResize(leftComponentRef);
    const { width: rightWidth } = useResize(rightComponentRef);
    const isMobile = React.useContext(MobileContext);

    const { getLeftWidth, getRightWidth } = props;
    React.useEffect(() => {
        if (getLeftWidth) {
            getLeftWidth(leftWidth);
        }

        if (getRightWidth) {
            getRightWidth(rightWidth);
        }
    }, [getLeftWidth, getRightWidth, leftWidth, rightWidth]);

    return (
        <div
            style={{
                ...(isMobile ? CONTAINER_STYLE_MOBILE : CONTAINER_STYLE_DESKTOP),
                background: props.ticketBackground
                    ? `linear-gradient(90deg, ${customColors.greyBackground} 70%, rgba(255,255,255,1) 70%)`
                    : undefined,
                ...props.style,
            }}
        >
            {!props.ignoreSideBar && (
                <DesktopOnly>
                    <div ref={leftComponentRef} style={{ flexGrow: 1, flexBasis: 0, minWidth: SIDEBAR_WIDTH_OPEN }} />
                </DesktopOnly>
            )}
            <div
                ref={isMobile ? leftComponentRef : undefined}
                style={isMobile ? CONTENT_STYLE_MOBILE : CONTENT_STYLE_DESKTOP}
            >
                {props.children}
            </div>
            {!props.ignoreSideBar && (
                <DesktopOnly>
                    <div ref={rightComponentRef} style={{ flexGrow: 1, flexBasis: 0, pointerEvents: "none" }} />
                </DesktopOnly>
            )}
        </div>
    );
};
