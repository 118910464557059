import * as React from "react";
import styled from "styled-components";
import { useResize } from "../hooks/useResize";

const Container = styled.div`
    padding: 8px 8px 32px 0;
    background-color: white;
    max-height: 400px;
    overflow: auto;
    flex-shrink: 0;
`;

export const ChatInteractionContainer = (props: {
    onHeightChange?: (height: number) => void;
    children?: React.ReactNode;
    fixed?: boolean;
}) => {
    const [inputHeight, setInputHeight] = React.useState(0);

    // If our chat div contains all messages,
    // then the input container has to be a fixed overlay
    const fixedStyle: React.CSSProperties = {
        position: "fixed",
        bottom: 0,
        width: "100%",
        left: 0,
        padding: 0,
        zIndex: 2,
    };

    // Need this to communicate dynamic input size to outside world
    const inputRef = React.useRef<HTMLDivElement | null>(null);
    const size = useResize(inputRef);
    React.useEffect(() => {
        if (size && size.height !== inputHeight) {
            setInputHeight(size.height);
            props.onHeightChange?.(size.height);
        }
    }, [inputHeight, props, size]);

    return (
        <Container style={props.fixed ? fixedStyle : undefined} ref={inputRef}>
            {props.children}
        </Container>
    );
};
