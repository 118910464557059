import { Badge } from "@material-ui/core";
import * as React from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import styled from "styled-components";
import { ACCEPTED_IMAGE_FORMATS, MAX_IMAGE_UPLOAD_SIZE } from "../../config";
import { authStore } from "../../stores/AuthStore";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { User } from "./User";

const AVATAR_SIZE = 80;
const UPLOAD_CONTAINER_HEIGHT = 32;

const UploadContainer = styled.div`
    background-color: ${customColors.primaryColor};
    width: ${UPLOAD_CONTAINER_HEIGHT}px;
    height: ${UPLOAD_CONTAINER_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
`;

export const DropZoneAvatar = (props: {
    currentUploadFile?: number;
    totalFileCount?: number;
    isUploading: boolean;
    onDrop<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent): void;
    "data-id"?: string;
    fileTypes?: string;
    children?: React.ReactNode;
    imageUrl?: string;
    disabled?: boolean;
}) => {
    const [highlight, setHighlight] = React.useState(false);

    const firstName = authStore.userInfo?.given_name ?? "";
    const lastName = authStore.userInfo?.family_name ?? "";

    const onDragOver = () => {
        setHighlight(true);
    };

    const onDragLeave = () => {
        setHighlight(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: props.fileTypes ?? ACCEPTED_IMAGE_FORMATS,
        noKeyboard: true,
        maxSize: MAX_IMAGE_UPLOAD_SIZE,
        disabled: !!props.disabled || props.isUploading,
        onDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
            setHighlight(false);
            props.onDrop(acceptedFiles, fileRejections, event);
        },
        onDragOver: onDragOver,
        onDragLeave: onDragLeave,
        multiple: false,
    });

    return (
        <div style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}>
            <div
                style={{
                    cursor: props.disabled ? "auto" : "pointer",
                }}
                {...getRootProps()}
                role="button"
                data-id={(props["data-id"] ?? "") + "_dropzone"}
            >
                <Badge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    badgeContent={
                        <UploadContainer>
                            <Icon name="camera" style={{ color: "white" }} />
                        </UploadContainer>
                    }
                >
                    <User
                        firstName={firstName}
                        lastName={lastName}
                        imageUrl={props.imageUrl ? props.imageUrl : authStore.userInfo?.profile_picture_url}
                        isTpaStaff={false}
                        size={AVATAR_SIZE}
                        showAvatarOnly
                        highlight={highlight}
                    />
                </Badge>
                {props.children}
                <input {...getInputProps()} />
            </div>
        </div>
    );
};
