import * as React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { ExternalEmployeePreRegistration } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";

// Prepared for array use, but in reality you cannot select more than one preRegistration in NTCS
export const useExternalPreRegistration = (
    preRegistrationIds?: string[],
    preloaded?: ExternalEmployeePreRegistration[],
) => {
    const [preRegistrations, setPreRegistrations] = React.useState<ExternalEmployeePreRegistration[]>(preloaded ?? []);

    useDeepCompareEffect(() => {
        const load = async () => {
            if (!preRegistrationIds || preRegistrationIds.length === 0) {
                return;
            }

            if (preloaded) {
                // We have a preloaded preReg -> no need to load
                // If we really need that later -> add a force flag
                return;
            }

            try {
                generalStore.isLoading = true;
                const response = await API.getExternalPreRegistration(preRegistrationIds);
                setPreRegistrations(response);
            } catch (error) {
                generalStore.setError(t("error.loadPreRegistration"), error);
            } finally {
                generalStore.isLoading = false;
            }
        };

        load();
    }, [preRegistrationIds, preloaded]);

    return preRegistrations;
};
