import { Button, Divider } from "@material-ui/core";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import * as React from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { PermissionsInvitationResultResponse } from "../../network/APITypes";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { CustomDialog, CustomDialogContent } from "./CustomDialog";
import { TpaWhiteButton } from "./Primitives";
import { ResponsiveButtonContainer } from "./ResponsiveButtonContainer";

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
`;

export const UserReleaseInfoDialog = (props: {
    open: boolean;
    invitationResults: PermissionsInvitationResultResponse;
    onSubmit: (ids: string[]) => void;
    onCancel: (ids: string[]) => void;
}) => {
    const invitationResultIds = props.invitationResults.map(invitationResult => invitationResult.id);

    const groupedInvitationResults = groupBy(
        props.invitationResults,
        invitationResult => invitationResult.releasedBy.id,
    );

    const superAdmins = uniqBy(
        props.invitationResults.map(invitationResult => invitationResult.releasedBy),
        superAdmin => superAdmin.id,
    );

    return (
        <CustomDialog open={props.open}>
            <CustomDialogContent style={{ paddingBottom: 0 }}>
                <h1>{t("userReleaseInfoDialog.title")}</h1>
            </CustomDialogContent>
            <div style={{ padding: "0 42px", marginTop: 24 }}>{t("userReleaseInfoDialog.message")}</div>
            <div
                style={{
                    padding: "0 42px",
                    marginTop: 24,
                    minHeight: props.invitationResults.length >= 2 ? undefined : props.invitationResults.length * 20,
                    overflow: "auto",
                }}
            >
                {superAdmins.map(superAdmin => (
                    <React.Fragment key={superAdmin.id}>
                        <Row>
                            <span>
                                <b>
                                    {t("userReleaseInfoDialog.from")} {superAdmin.firstName} {superAdmin.lastName}
                                </b>
                            </span>
                        </Row>
                        <Divider />
                        {groupedInvitationResults[superAdmin.id].map(invitationResult => (
                            <React.Fragment key={invitationResult.user.id}>
                                <Row style={{ marginLeft: 32 }}>
                                    <span>
                                        {invitationResult.user.firstName} {invitationResult.user.lastName}
                                    </span>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span>
                                            {invitationResult.released
                                                ? t("userReleaseInfoDialog.released")
                                                : t("userReleaseInfoDialog.rejected")}
                                        </span>
                                        <div>
                                            <Icon
                                                name={invitationResult.released ? "checkmark" : "closeSmall"}
                                                style={{
                                                    display: "block",
                                                    marginLeft: 8,
                                                    color: invitationResult.released
                                                        ? customColors.secondaryColor
                                                        : customColors.redUrgent,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Row>
                                <Divider style={{ marginLeft: 32 }} />
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </div>
            <CustomDialogContent style={{ paddingTop: 0 }}>
                <ResponsiveButtonContainer style={{ marginTop: 42 }}>
                    <TpaWhiteButton
                        onClick={() => {
                            props.onCancel(invitationResultIds);
                        }}
                        data-id="button_cancel"
                    >
                        {t("userReleaseInfoDialog.cancel")}
                    </TpaWhiteButton>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.onSubmit(invitationResultIds);
                        }}
                        data-id="button_confirm"
                    >
                        {t("common.ok")}
                    </Button>
                </ResponsiveButtonContainer>
            </CustomDialogContent>
        </CustomDialog>
    );
};
