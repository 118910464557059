// CONNECT-493: Sometimes signature library reports out of bounds dimensions.

import { t } from "../i18n/util";

// Add error margin to avoid this.
const ERROR_MARGIN = 4;
export const SIGNATURE_WIDTH = 128 + ERROR_MARGIN;
export const SIGNATURE_HEIGHT = 62 + ERROR_MARGIN;

// Measured in 72 dpi
export const A4_WIDTH = 595;
export const A4_HEIGHT = 842;

export interface ISignaturePosition {
    page: number;
    x: number;
    y: number;
}
export interface ISignatureUserKnown {
    id: string;
    firstName: string;
    lastName: string;
}
export interface ISignatureUserAnonymous {
    id: string;
    anonymous: true;
    index: number;
}
export type ISignatureUser = ISignatureUserKnown | ISignatureUserAnonymous;
export interface ISignatureUserSignature {
    user?: ISignatureUser;
    signaturePosition: ISignaturePosition;
}

/** Check if two signatures overlap (simple AABB test) */
export const areSignaturesOverlapping = (lhs: ISignaturePosition, rhs: ISignaturePosition) => {
    if (lhs.page !== rhs.page) {
        return false;
    }

    const rectA_X1 = lhs.x;
    const rectA_Y2 = lhs.y;
    const rectA_X2 = rectA_X1 + SIGNATURE_WIDTH;
    const rectA_Y1 = rectA_Y2 + SIGNATURE_HEIGHT;

    const rectB_X1 = rhs.x;
    const rectB_Y2 = rhs.y;
    const rectB_X2 = rectB_X1 + SIGNATURE_WIDTH;
    const rectB_Y1 = rectB_Y2 + SIGNATURE_HEIGHT;

    return rectA_X1 < rectB_X2 && rectA_X2 > rectB_X1 && rectA_Y1 > rectB_Y2 && rectA_Y2 < rectB_Y1;
};

// Check that no signatures overlap
export const checkForOverlappingSignatures = (
    collectiveRelease: boolean | undefined,
    signatures: ISignatureUserSignature[] | undefined,
) => {
    // Only necessary for collective release
    if (!collectiveRelease) {
        return false;
    }

    if (!signatures) {
        return false;
    }

    // Pairwise check for overlapping signatures. This is O(n^2), but since
    // we don't have that many signatures, this should be ok.
    // No need to do smarter stuff like presorting by axes.
    for (let i = 0; i < signatures.length; ++i) {
        for (let j = i + 1; j < signatures.length; ++j) {
            const signatureA = signatures[i];
            const signatureB = signatures[j];

            if (areSignaturesOverlapping(signatureA.signaturePosition, signatureB.signaturePosition)) {
                return true;
            }
        }
    }

    return false;
};

/** Create users that can be used with {@link ISignatureUserSignature} if the exact signature users are not known/relevant */
export const createAnonymousSignatureUsers = (count: number): ISignatureUser[] => {
    const users: ISignatureUser[] = [];
    for (let i = 0; i < count; i++) {
        users.push({ id: i.toString(), anonymous: true, index: i });
    }
    return users;
};

export const getSignatureUserName = (signee: ISignatureUser): { firstName: string; lastName: string } => {
    if ("anonymous" in signee) {
        return { firstName: t("common.user"), lastName: (signee.index + 1).toString() };
    }
    return signee;
};
